import React from "react";
import BackToUpButton from "../GuideTools/BackToUpButton";
import AddAlarmIcon from '@mui/icons-material/AddAlarm'
import Languages from "../../../translations";
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import SelectAllIcon from '@mui/icons-material/SelectAll'



const AvailableCareTimeAccordionText = () => {
    return(
      <div>
        <br />
        <p className="guide-accordion-details-texts">{Languages.availableCareTimeText1}</p>
        <br />
        <br />
        <p className="guide-accordion-details-texts">{Languages.availableCareTimeText2}</p>
        <br />
        <br />
        <p className="guide-accordion-details-texts">{Languages.availableCareTimeText3}</p>
        <br />
        <br />
        <BackToUpButton />
      </div>
    )
}

export default AvailableCareTimeAccordionText
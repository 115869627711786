import { ApiBase } from "../internal";

export class VasuApi extends ApiBase {
  /*constructor() {
    super();
  }*/

  async loadVasu(VasuID) {
    try {
      const response = await this.httpClient.get("/vasu/getvasu/" + VasuID,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": sessionStorage.getItem('language') === "en" ? "en-US" : sessionStorage.getItem('language')
          }
        });

      // console.log("VASU API. Load Vasu response: ", response);

      return response;
    }
    catch (error) {
      console.error("VASU API. Load Vasu ERROR: ", error, "\n" +
        "response.status: ", error.response === undefined ? 502 : error.response.status === undefined ? 502 : error.response.status);
      var responseData = { status: error.response === undefined || error.response.status === undefined ? 502 : error.response.status };
      return responseData;
    }
  }

  async saveVasu(VasuData) {
    const response = await this.httpClient.post("/vasu/savevasu/", VasuData,
      {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": sessionStorage.getItem('language') === "en" ? "en-US" : sessionStorage.getItem('language')
        }
      });
    return response;
  }
}

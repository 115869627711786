import { types } from "./actionTypes";
import Moment from "moment";

const NursingTimesReducer = (state, action) => {
  const payload = action.data;

  /*console.log("'NURSINGTIMES REDUCER"+
              "\n payload: ", payload+
              "\n state: ", state,
              '\n action ', action+
              "\n action.type ", action.type);*/

  switch (action.type) {
    case types.DEFAULT_NURSINGTIMES_HANDLED:
      return {
        ...state,
        defaultNursingTimes: {
          ...state.defaultNursingTimes,
          state: types.DEFAULT_NURSINGTIMES_HANDLED,
        },
      };
    case types.LOADING_DEFAULT_NURSINGTIMES:
      return {
        ...state,
        defaultNursingTimes: { state: types.LOADING_DEFAULT_NURSINGTIMES },
      };
    case types.LOAD_SUCCESS_DEFAULT_NURSINGTIMES:
      //console.log('DEFAULT_NURSINGTIMES REDUCER. LOAD OK payload: ', payload);
      //payload.Start = Moment (payload.Start); //new Date().setHours(8,0,0);//setHours(8,15,0) asettaa myös defaultti minuutit
      //payload.End   = Moment (payload.End);   //new Date().setHours(16,0,0);

      return {
        ...state,
        defaultNursingTimes: {
          Start: Moment(payload.Start),
          End: Moment(payload.End),
          state: types.LOAD_SUCCESS_DEFAULT_NURSINGTIMES,
        },
      };
    case types.LOAD_FAILED_DEFAULT_NURSINGTIMES:
      //console.log('DEFAULT_NURSINGTIMES REDUCER. LOAD FAILED payload: ', payload);
      return {
        ...state,
        defaultNursingTimes: {
          Start: Moment(new Date().setHours(8, 0, 0)),
          End: Moment(new Date().setHours(16, 0, 0)),
          Code: payload.Code,
          state: types.LOAD_FAILED_DEFAULT_NURSINGTIMES,
        },
      };

    case types.SAVING_DEFAULT_NURSINGTIMES:
      return {
        ...state,
        defaultNursingTimes: { state: types.SAVING_DEFAULT_NURSINGTIMES },
      };
    case types.SAVING_SUCCESS_DEFAULT_NURSINGTIMES:
      //console.log('DEFAULT_NURSINGTIMES REDUCER. SAVING OK payload: ', payload);
      return {
        ...state,
        defaultNursingTimes: {
          Start: payload.Start,
          End: payload.End,
          state: types.SAVING_SUCCESS_DEFAULT_NURSINGTIMES,
        },
      };
    case types.SAVING_FAILED_DEFAULT_NURSINGTIMES:
      //console.log('DEFAULT_NURSINGTIMES REDUCER. SAVING FAILED payload: ', payload);
      return {
        ...state,
        defaultNursingTimes: {
          Code: payload.Code,
          state: types.SAVING_FAILED_DEFAULT_NURSINGTIMES,
        },
      };

    case types.READING_NEXTNURSINGTIMES:
      //console.log('NURSINGTIMES REDUCER. READING NEXT NURSINGTIMES. payload: ', payload);
      return {
        ...state,
        nextNursingTimes: {
          TimesList: [],
          state: undefined,
        },
      };
    case types.NEXTNURSINGTIMES_HANDLED:
      //console.log("NURSINGTIMES REDUCER. NEXT NURSINGTIMES HANDLED");
      return {
        ...state,
        nextNursingTimes: {
          ...state.nextNursingTimes,
          state: types.NEXTNURSINGTIMES_HANDLED,
        },
      };
    case types.NEXTNURSINGTIMES_READ:
      //console.log('NURSINGTIMES REDUCER. NEXT NURSINGTIMES READ OK payload: ', payload);
      return {
        ...state,
        nextNursingTimes: {
          TimesList: payload,
          state: types.NEXTNURSINGTIMES_READ,
        },
      };
    case types.NEXTNURSINGTIMES_FAILED:
      console.log(
        "NURSINGTIMES REDUCER. NEXT NURSINGTIMES READ FAILED payload: ",
        payload
      );
      return {
        ...state,
        nextNursingTimes: {
          Code: payload.Code,
          state: types.NEXTNURSINGTIMES_FAILED,
        },
      };

    case types.NURSINGTIMES_READ_HANDLED:
      return {
        ...state,
        List: { ...state.List, state: types.NURSINGTIMES_READ_HANDLED },
      };
    case types.NURSINGTIMES_FAILED_HANDLED:
      return {
        ...state,
        List: { ...state.List, state: types.NURSINGTIMES_FAILED_HANDLED },
      };
    case types.READING_NURSINGTIMES:
      return {
        ...state,
        List: { state: types.READING_NURSINGTIMES },
      };
    case types.NURSINGTIMES_READ:
      //console.log("NURSINGTIMES REDUCER. NURSINGTIMES READ. payload: ", payload);
      payload.WeekList.forEach((week) => {
        week.DayList.forEach((dayinfo) => {
          dayinfo.Date = Moment(dayinfo.Date);
        });
      });
      return {
        ...state,
        List: { WeekData: payload, state: types.NURSINGTIMES_READ },
      };
    case types.NURSINGTIMES_FAILED:
      //console.log('NURSINGTIMES REDUCER. NURSINGTIMES READ FAILED payload: ', payload);
      return {
        ...state,
        List: { Code: payload.Code, state: types.NURSINGTIMES_FAILED },
      };
    case types.DELETING_NURSINGTIMES:
      console.log("NURSING REDUCER  CARETIMES DELETing");
      return {
        ...state,
        Deleted: { state: types.DELETING_NURSINGTIMES },
      };
    case types.NURSINGTIMES_DELETED_OK:
      console.log("NURSING REDUCER  CARETIMES DELETED OK");
      //console.log('NURSINGTIMES REDUCER. NURSINGTIMES DELETE OK payload: ', payload);

      var selectedData = {
        Code: payload === "" ? null : payload.Code,
        Erros:
          payload === "" || payload.listErrors.length === 0
            ? null
            : payload.listErrors,
      };
      return {
        ...state,
        Deleted: { Data: selectedData, state: types.NURSINGTIMES_DELETED_OK },
      };
    case types.NURSINGTIMES_DELETED_FAILED:
      console.log("NURSING REDUCER  CARETIMES DELETED FAILED");
      //console.log('NURSINGTIMES REDUCER. NURSINGTIMES_DELETED_FAILED payload: ', payload);
      return {
        ...state,
        Deleted: {
          Code: payload.Code,
          Message: payload.Message,
          state: types.NURSINGTIMES_DELETED_FAILED,
        },
      };
    case types.NURSINGTIMES_DELETED_HANDLED:
      return {
        ...state,
        Deleted: {
          ...state.Deleted,
          state: types.NURSINGTIMES_DELETED_HANDLED,
        },
      };

    case types.NURSINGTIMES_SAVED_OK_HANDLED:
      return {
        ...state,
        Saved: { ...state.Saved, state: types.NURSINGTIMES_SAVED_OK_HANDLED },
      };
    case types.NURSINGTIMES_SAVED_FAILED_HANDLED:
      return {
        ...state,
        Saved: {
          ...state.Saved,
          state: types.NURSINGTIMES_SAVED_FAILED_HANDLED,
        },
      };
    case types.SAVING_NURSINGTIMES:
      return {
        ...state,
        Saved: { state: types.SAVING_NURSINGTIMES },
      };
    case types.NURSINGTIMES_SAVED_OK:
      var selectedData2 = null;
      if (payload !== "") {
        /*console.log("NURSINGTIMES REDUCER. NURSINGTIMES SAVED OK."+
                    "\n payload: "+
                    "\n payload[0]: ", payload[0],   // delete model
                    "\n payload[1]: ", payload[1]); //  save model*/
        selectedData2 = {
          DeleteCode: payload[0] === null ? null : payload[0].Code,
          DeleteErros:
            payload[0] === null || payload[0].listErrors.length === 0
              ? null
              : payload[0].listErrors,
          DeleteGroupedErros:
            payload[0] === null || payload[0].GroupedErrors.length === 0
              ? null
              : payload[0].GroupedErrors,
          SavedCode: payload[1] === null ? null : payload[1].Code,
          SaveErros:
            payload[1] === null || payload[1].listErrors.length === 0
              ? null
              : payload[1].listErrors,
          SaveGroupedErros:
            payload[1] === null || payload[1].GroupedErrors.length === 0
              ? null
              : payload[1].GroupedErrors,
        };
      }

      //console.log('NURSINGTIMES REDUCER. NURSINGTIMES SAVED OK. sortedData: ',selectedData);
      return {
        ...state,
        Saved: { Data: selectedData2, state: types.NURSINGTIMES_SAVED_OK },
      };
    case types.NURSINGTIMES_SAVED_FAILED:
      console.log(
        "NURSINGTIMES REDUCER. NURSINGTIMES SAVED FAILED payload: ",
        payload
      );
      return {
        ...state,
        Saved: {
          Message: payload.Message,
          Code: payload.Code,
          state: types.NURSINGTIMES_SAVED_FAILED,
        },
      };

    default:
      return state;
  }
};

export default NursingTimesReducer;

import React, { useMemo, useEffect, useState } from 'react';
import BulletinBoardSlideView from './BulletinBoardSlideView';
import BulletinBoardCardView from './BulletinBoardCardView';
import { useStateValue } from '../../State';
import { ATTACHMENT_TYPES } from '../../Api';
import { ServerApi } from "../../internal";
import { downloadBase64File } from '../../utils';
import { previewBase64File } from '../../utils';
import { types } from "../../Reducers/actionTypes";
// Container component for bulletinBoard. This renders actual view components and fetches needed data
const BulletinBoard = ({ fullViewOnClose, slideViewOnClick, mode, filter }) => {

  const { state, actions } = useStateValue();

  const { bulletinBoards, children } = state;

  const [boardsLoaded, setBoardsLoaded] = useState(false)

  useEffect(() => {
    if (!boardsLoaded) {
      if(bulletinBoards.status!== types.LOADING_BULLETINBOARDS)
        fetchBB(actions);
      setBoardsLoaded(true);
    }
  }, [bulletinBoards]);  
  // https://github.com/facebook/create-react-app/issues/6880

  const messages = useMemo(() => generateMessages(bulletinBoards, children, filter), [bulletinBoards, filter]);

  if (mode == "full") {
    return (
      <BulletinBoardCardView messages={messages} close={fullViewOnClose} attachmentOnClick={attachmentOnClick} previewFileOnClick={previewFileOnClick}/>
    );
  } else {
    return (
      <BulletinBoardSlideView messages={messages} onClick={slideViewOnClick} />
    )
  }
}

const fetchBB = (actions) => {
  const loggedInPersonId = sessionStorage.getItem('personID');
  actions.triggerLoadBulletinBoards({ parentPersonId: loggedInPersonId });
}

export const generateMessages = (data, childrenState, personIdFilter) => {
  const result = [];
  if (data) {
    //console.log("generateMessages data",data);
    data.notes.forEach((item, index) => {
      if (personIdFilter) {
        const childrenForRcp = childrenState.ChildList.filter(child => {
          const placements = child.Placements.filter(pl => {
            const compareId = item.RecipientType === "group" ? pl.GroupId : pl.CentreId;
            return compareId === item.RecipientId
          });

          return placements.length > 0 && child.Id === personIdFilter;
        });

        if (childrenForRcp.length > 0) {
          result.push(createNote(item, index));
        }
      } else {
        result.push(createNote(item, index));
      }

    });

  }
  return result;
}

const createNote = (val, index) => {

  return {
    id: index,
    recipient: val.RecipientName,
    header: val.Title,
    body: val.Body,
    date: val.Date.format("DD.MM.Y"),
    available: val.Available.format("DD.MM.Y"),
    recipientType: val.RecipientType,
    attachments: val.Attachments,
  }
}

const previewFileOnClick = async (FileId, FileMimeType, FileName) => {
  const response = await new ServerApi().previewFile(FileId, ATTACHMENT_TYPES.attachment, FileMimeType);
  previewBase64File(response, FileMimeType, FileName);
}


const attachmentOnClick = async (attachmentData) => {
  console.log("attachment dl click", attachmentData);
  const response = await new ServerApi().downloadFile(attachmentData.Id, ATTACHMENT_TYPES.attachment);
  downloadBase64File(response.data, attachmentData.MimeType, attachmentData.FileName);
}

export default BulletinBoard;

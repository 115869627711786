import React, {useEffect, useState} from "react";
import {useStateValue}        from "../../State";
import ContentBoxMUI from "../../Components/ContentBoxMUI";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardHeader'
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Languages from "../../translations";
import {types} from "../../Reducers/actionTypes";
import ChildProfilePicture from "../../Components/ChildProfilePicture";
import Moment from "moment";
import { history } from "../../Router";
import DiscussionCard from "../../Components/Discussion/DiscussionCard";
import DiscussionSpeedDial from "../../Components/Discussion/DiscussionSpeedDial";
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import InfoIcon from '@mui/icons-material/Info';
import ErrorIcon from '@mui/icons-material/Error';



const DiscussionBookingLayout = () => {

  const {state, actions} = useStateValue();
  const {children} = state;
  

  useEffect(() => {
    if ( typeof children === "undefined") {
      actions.triggerLoadChildren();
    }
  }, [])

  

  const bookDiscussion = () => {
    history.push({pathname:"/bookDiscussion", 
    state:
     {child: null}});
  };

  return(
    <ContentBoxMUI header={Languages.discDiscussionTimes}>
      <br/>
      <Typography variant="h4" component="h1">{Languages.discDiscussionTimes}</Typography> 
      <MainDiscussionCard
        status={state.discussion.discussionTimes.status}
        data={state.discussion.discussionTimes.data}
      />
      <DiscussionSpeedDial />
    </ContentBoxMUI>
  )
}

const MainDiscussionCard = ({data, status}) => {
    
  const {state, actions} = useStateValue();
  const {children, discussion} = state;

  useEffect(() => {
    actions.triggerLoadDiscussionTimes();
  }, []);

  useEffect(()=>{
    if(discussion.editDiscussionBooking.status == types.EDIT_DISCUSSION_BOOKING_SUCCESS){
      actions.triggerLoadDiscussionTimes();
    }
  },[discussion.editDiscussionBooking.status])

  useEffect(()=>{
    if(discussion.deleteDiscussionBooking.status == types.DELETE_DISCUSSION_BOOKING_SUCCESS){
      actions.triggerLoadDiscussionTimes();
    }
  },[discussion.deleteDiscussionBooking.status])

  useEffect(() => {

  }, [state.discussion.discussionTimes.status]);

  const onChildArrowClick = (childId) => {
    history.push("/discussions", {childId: childId});
  }

  const copyUrlToClipboard = (dataToCopy) => {
    navigator.clipboard.writeText(dataToCopy);
    actions.triggerAddToSnackBarQueue({color: "#000000", message: Languages.discCopied});
  }

  const getChildName = (childId) => {
    const child = children.ChildList.find(x => x.Id == childId);
    if (typeof child === "undefined") {
      return("");
    }
    return child.FirstName;
  }

  const handleParentClick = event => {
    if (event.target === event.currentTarget) {
      onChildArrowClick(event.currentTarget.getAttribute('data-childId'))
    }
  }

  if(status === types.LOADING_DISCUSSION_TIMES) {
    return(<div style={{textAlign: "center", justifyContent: "center", width: "100%", padding: "100px"}}>
    <CircularProgress />
    </div>)
  }

  if (status === types.LOAD_DISCUSSION_TIMES_SUCCESS) {
    const headerstyle = {fontWeight: "bold", fontVariant: "small-caps", /*textDecoration:"underline"*/}

    return(<>
      {data.ChildDiscussions.map((card, index) => {
        return(
          <div key={card.ChildId}>
          <br/>
          <Card sx={{backgroundColor: "white", color: "black", border: "1px solid #002E6D", }} >
              <CardHeader sx={{backgroundColor: "#D7E3ED"}} avatar={<ChildProfilePicture discussionAvatar={true} childId={card.ChildId}/>} title={<Typography variant="h5">{getChildName(card.ChildId)}</Typography>} 
              action={
                               <Button
                               size="large"
                               variant="contained"
                               sx={{backgroundColor: "#002E6D"}}
                               aria-label={"Lapsen tiedot: lapsenNimi"}
                               onClick={() => onChildArrowClick(card.ChildId)}>
                               {Languages.awShowMore}&nbsp;&nbsp;<ArrowForwardIosIcon fontSize="small"/>
                             </Button>
        }/>
            <br />
            <CardActions sx={{backgroundColor: "white"}}>{card.BookableTimesAvailable 
              ? 
              <Paper elevation={3} sx={{backgroundColor: "#FFEBD4", color: "black", border: "1px solid #E59F7E", borderLeft: "5px solid #EE5711", padding: "10px"}}>
                <Typography>
                  <ErrorIcon sx={{color: "#EE5711"}}/>&nbsp;&nbsp;{Languages.discTimesAvailable}
                </Typography>
              </Paper> 
              : 
              <Paper elevation={3} sx={{backgroundColor: "#D7E3ED", color: "black", border: "1px solid #002E6D", borderLeft: "5px solid #002E6D", padding: "10px"}}>
                <Typography>
                  <InfoIcon sx={{color: "#005AA3"}}/>&nbsp;&nbsp;{Languages.discTimesUnabailable}
                </Typography>
              </Paper> }
              {card.BookedTimesInFuture > 0 ?
              <Paper elevation={3} sx={{backgroundColor: "#FFEBD4", color: "black", border: "1px solid #E59F7E", borderLeft: "5px solid #EE5711", padding: "10px"}}>
              <Typography>
                <ErrorIcon sx={{color: "#EE5711"}}/>&nbsp;&nbsp;{Languages.discUpcomingDiscussions + "("+ card.BookedTimesInFuture +")"}
              </Typography>
            </Paper> 
            : 
            <Paper elevation={3} sx={{backgroundColor: "#D7E3ED", color: "black", border: "1px solid #002E6D", borderLeft: "5px solid #002E6D", padding: "10px"}}>
              <Typography>
                <InfoIcon sx={{color: "#005AA3"}}/>&nbsp;&nbsp;{Languages.discNoActiveBookings}
              </Typography>
            </Paper> }
              </CardActions>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                
                {card.Discussions && card.Discussions.length > 0 ? 
                <>
                  <Paper elevation={3} sx={{backgroundColor: "#FFEBD4", color: "black", border: "1px solid #E59F7E", borderLeft: "5px solid #EE5711", padding: "10px"}}>
                    <Typography>
                      <ErrorIcon sx={{color: "#EE5711"}}/>&nbsp;&nbsp;{Languages.discReminder}
                    </Typography>
                  </Paper> 
                  <DiscussionCard
                    provideKey={card.Discussions[0].DiscussionId}
                    discussionId={card.Discussions[0].DiscussionId}
                    type={card.Discussions[0].DiscussionType} 
                    dateData={card.Discussions[0]}
                    cancelDate={card.Discussions[0].CancelableUntil}
                    parents={card.Discussions[0].Parents} 
                    staff={card.Discussions[0].Employees}
                    remote={card.Discussions[0].IsRemoteMeeting}
                    address={card.Discussions[0].DiscussionAddress}
                    url={card.Discussions[0].MeetingUrl}
                    employeeInfoText={card.Discussions[0].ExtraInfoShared}
                    parentInfoText={card.Discussions[0].ExtraInfoParent}
                    data={card.Discussions[0]}
                  />
                  
                
                </>
                : null
                }
                
                </Grid>
                
              </Grid>
            </CardContent>
          </Card>
      </div>)}
      )}</>)
  }

  return(<div><p>{Languages.discInformationFailed}</p></div>)
}


export const DiscType = ({type}) => {
  if (type == 0) return Languages.discTypeVASU
  if (type == 1) return Languages.discTypeLapu
  if (type == 2) return Languages.discTypeMuu
  if (type == 3) return Languages.discTypeLEOPS
  return ""
}

export const NextTime = (data) => {
  const start = Moment(data.data.DiscussionStart).format('ddd DD.MM.YYYY | HH:mm').toUpperCase();
  const end = Moment(data.data.DiscussionEnd).format('HH:mm');
  return (start + " - " + end);
}

export default DiscussionBookingLayout;
import React from "react";
import { BrowserRouter } from "react-router-dom";
export { default as PrivateRoute } from "./PrivateRoute";
export { default as TestRouter } from "./TestRouter";

// export const history = createBrowserHistory();
let exportHistory;
export {exportHistory as history}

class DebugRouter extends BrowserRouter {
  constructor(props){
    super(props);
    // console.log('initial history is: ', JSON.stringify(this.history, null,2))
    exportHistory = this.history;
    this.history.listen((location, action)=>{
      // console.log(
      //   `The current URL is ${location.pathname}${location.search}${location.hash}`
      // )
      // console.log(`The last navigation action was ${action}`, JSON.stringify(this.history, null,2));
    });
  }

}

export default DebugRouter;
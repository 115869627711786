import React                  from "react";
import Languages              from "../../../translations";
import "../invoiceBasis.css";
import TableBody              from "@mui/material/TableBody";
import TableCell              from "@mui/material/TableCell";
import TableHead              from "@mui/material/TableHead";
import TableRow               from "@mui/material/TableRow";
import moment                 from "moment";


const InvoicePreSchoolView = ({nursingDays}) => {

  const checkWhetherZero = (hoursAndMinutes) => {
    if (hoursAndMinutes === "0 t 00 min" || hoursAndMinutes === "0 h 00 min") {
      return(true);
    }
    else {
      return(false);
    }
  }

  const preschoolColor = "#ff4d88"
  const basicCell = {backgroundColor: "#FFFFFF", color: "#000000", wordWrap: "break-word"};
  const basicCellStrong = {backgroundColor: "#FFFFFF", color: "#000000", wordWrap: "break-word", fontWeight: "bold"};
  const bookingsHighlight = {backgroundColor: "#ffc000", fontWeight: "bold"}
  const toteumaHighlight= {backgroundColor: "#6BAF6E", fontWeight: "bold"}

  return(
    <React.Fragment>
      <TableHead id="test_Invoice_PreschoolView">
        <TableRow>
          <TableCell padding="none" align="center" style={basicCellStrong}>{Languages.uctDateAbbr}</TableCell>
          <TableCell padding="none" align="center" style={{backgroundColor: preschoolColor, fontWeight: "bold"}}>{Languages.uctPreSchoolDuration}</TableCell>
          <TableCell padding="none" align="center" style={basicCellStrong}>{Languages.uctBilling}</TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
      {nursingDays.map((row, index) => {

        return(
          <TableRow hover key={"row3" + index}>
            <TableCell 
              padding="none" 
              align="center" 
              style={basicCell}>{moment(row.Date).format("DD.MM.")}</TableCell>
            <TableCell 
              padding="none" 
              align="center"
              style={basicCell}
              >{checkWhetherZero(row.PreSchoolHours) ? "-" : row.PreSchoolHours}</TableCell>
            <TableCell 
              padding="none" 
              align="center" 
              style={row.ReservationLonger ? bookingsHighlight : 
                    row.RealizationLonger ? toteumaHighlight :
                    basicCell}>{row.PaymentHours}</TableCell>
          </TableRow>
        )
        })
      }
      </TableBody>
    </React.Fragment>
  )

}

export default InvoicePreSchoolView;
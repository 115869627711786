import React, { /*useState,*/ useEffect, useState } from "react";
import {useStateValue} from "../../../State";
import "../../Vasu/vasu.css";

import ContentBoxMUI    from "../../../Components/ContentBoxMUI";
import Languages        from "../../../translations";
import ChildRow         from '../../Vasu/ChildRow';
import DiscussionSubPart from "./DiscussionSubPart";
import List             from '@mui/material/List';
import ListItemButton   from '@mui/material/ListItemButton';
import ListItemIcon     from '@mui/material/ListItemIcon';
import ListItemText     from '@mui/material/ListItemText';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Box              from '@mui/material/Box'
// import IconButton       from "@mui/material/IconButton";
import Link             from '@mui/material/Link'

const DiscussionPart = (props) => {

  const { state/*, actions*/ } = useStateValue();
  
  const GeneralPart = state.leops.GeneralPart;

  const { history, location } = props;
  
  const [ShowSlideIndex, setShowSlideIndex] = useState(-1);

  if (location.state === undefined)
  {
    console.log("DiscussionPart. MOUNT. location.state === undefined. GO BACK");
    history.goBack(); 
  }

  useEffect( () => {
    switch(state.leops.state)
    {
      case "INITIAL_STATE":
        console.log("DiscussionPart INITIAL_STATE" );
        break;
      default:
        console.log("DiscussionPart ",state.leops.state );
        break;
    }
  }, [state.leops]);
  
  const handleBackClick = () => {
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = () => {
      window.history.pushState(null, "", window.location.href);
      setShowSlideIndex(-1);
      window.onpopstate = undefined;
      window.history.back();
    };
  };

  const handleBoxClick = (index) => {
    //console.log("PreparingPart. handleBoxClick. index: ",index," vs ShowSlideIndex: ",ShowSlideIndex);
    setShowSlideIndex(index === 0 ? 0 :
                      index === 1 ? 1 : 
                      index === 2 ? 5 :
                      index === 3 ? 6 : 7);
    handleBackClick();
  };

  if (state.leops.state === "INITIAL_STATE" || location.state === undefined)
  {
    console.log("DiscussionPart. INITIAL_STATE RENDER EMPTY");

    return (<div className="DiscussionPart"></div>);
  }
  else
  {
    const { childpicture, childFirstName, childLastName, childColor} = location.state;
  
  return (
    <div className="DiscussionPart">
      <ContentBoxMUI>
        <Box sx ={{mt: 1}}>
          <ChildRow childpicture   = {childpicture}
                    childFirstName = {childFirstName}
                    childLastName  = {childLastName}
                    childColor     = {childColor}
                    FormId         = {GeneralPart.Id}
                    history        = {history}>
          </ChildRow>
        </Box>

        {ShowSlideIndex === -1 ?
        <div>
        <h4 className="textCenter">2. {Languages.eduPlanTitle2}</h4>

        <div style={{opacity: GeneralPart.Locked === null ? "40%" : ""}}>
        
        <List className="col-12" sx={{ mt: -1}}>
          <ListItemButton className="boxed" sx={{backgroundColor: "#afdfc8", borderRadius: .5, mt: 1.5, boxShadow: 2}} onClick={(event) => handleBoxClick(0)}>
            <ListItemText 
              primaryTypographyProps={{fontWeight: 'Bold', fontSize: 16, ml: 2}} primary={Languages.ThingsAriseFromPreviousECEC}/>
            <ListItemIcon><ArrowForwardIosIcon fontWeight='Bold' fontSize="small"/></ListItemIcon>
          </ListItemButton>
          <ListItemButton className="boxed" sx={{ backgroundColor: "#afdfc8", borderRadius: .5, mt: 1.5, boxShadow: 2}} onClick={(event) => handleBoxClick(1)}>
            <ListItemText 
              primaryTypographyProps={{fontWeight: 'Bold', fontSize: 16, ml: 2}} primary={Languages.ObjectivesForPedagogicalActivitiesAndMeasuresToAchieve} />
            <ListItemIcon><ArrowForwardIosIcon fontWeight='Bold' fontSize="small"/></ListItemIcon>
          </ListItemButton>
          <ListItemButton className="boxed" sx={{ backgroundColor: "#afdfc8", borderRadius: .5, mt: 1.5, boxShadow: 2}} onClick={(event) => handleBoxClick(2)}>
            <ListItemText 
              primaryTypographyProps={{fontWeight: 'Bold', fontSize: 16, ml: 2}} primary={Languages.OtherPartnersAndDocuments}/>
            <ListItemIcon><ArrowForwardIosIcon fontSize="small" /></ListItemIcon>
          </ListItemButton>
          <ListItemButton className="boxed" sx={{ backgroundColor: "#afdfc8", borderRadius: .5, mt: 1.5, boxShadow: 2}} onClick={(event) => handleBoxClick(3)}>
            <ListItemText 
              primaryTypographyProps={{fontWeight: 'Bold', fontSize: 16, ml: 2}} primary={Languages.StudentCareMeasures}/>
            <ListItemIcon><ArrowForwardIosIcon fontSize="small" /></ListItemIcon>
          </ListItemButton>
          <ListItemButton className="boxed" sx={{ backgroundColor: "#afdfc8", borderRadius: .5, mt: 1.5, boxShadow: 2}} onClick={(event) => handleBoxClick(4)}>
            <ListItemText 
              primaryTypographyProps={{fontWeight: 'Bold', fontSize: 16, ml: 2}} primary={Languages.AgreedCooperationWithGuardians}/>
            <ListItemIcon><ArrowForwardIosIcon fontSize="small" /></ListItemIcon>
          </ListItemButton>
        </List>
        </div>
        {GeneralPart.Locked === null ?
            <div className="fixed-bottom" style={{left:"auto", fontWeight: "bold", color: "#D500F9"}}>
              <div className="float-right">
                {Languages.logged}
                <LockIcon style={{color: "#D500F9"}} fontSize="large"></LockIcon>
              </div>
            </div>
          : null}
          
        </div>
        : 
          <DiscussionSubPart SelectedSlideIndex = {ShowSlideIndex} > </DiscussionSubPart>
        }
     
      </ContentBoxMUI>
      </div>
  );
  }
}

export default DiscussionPart;
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useStateValue } from "../../State";
import { useAuth } from "../../Auth/AuthProvider";
import { useEventListener, useInterval } from "../../Hooks";
import { types } from "../../Reducers/actionTypes";
import { MAINSTAT_CONSTS } from "../../Contants";
//import TimerWorker from "worker-loader!./timerWorker.js";
import * as workerTimers from "worker-timers";
//import WebWorker from "../../workerSetup";
//import timerWorker from "./timerWorker";

const BackgroundSchedulerFn = ({ autoLogout }) => {
  const {
    isAuthenticated,
    authActions: { showWarningPopup },
  } = useAuth();
  const { actions, state } = useStateValue();
  const { dFSettings, bulletinBoards } = state;
  const [bulletinBoardInterval] = useState(5 * 60 * 1000);
  const [msgReadWaiting, setMsgReadWaiting] = useState(false);
  //const [postGuardianConsentWaiting, setPostGuardianConsentWaiting] = useState(false);
  const [nursingTimesSaving, setNursingTimesSaving] = useState(false);
  const [nursingTimesDeleting, setNursingTimesDeleting] = useState(false);
  const bulletinTimer = useRef(null);
  const mainStatsTimer = useRef(null);

  useEffect(() => {
    const { dFSettings, children } = state;
    if (isAuthenticated) {
      console.log("BackgroundScheduler. is authenticated. Load children");

      // This only works when the user is authenticated (like when the user has closed the tab but the token is still ok)
      if (children.state != types.LOADING_CHILDREN)
        actions.triggerLoadChildren();

      if (dFSettings.state === "NEVER_LOADED") {
        actions.triggerLoadDFSettings();
        actions.triggerLoadCommuneSettings([
          "DaisyFamily/ParentAddressEditEnabled",
          "DaisyFamily/ShowGrowthfolder",
          "mailServiceEnabled",
          "Documents/EmailNotificationEnabled",
          "EDaisy/AppEmailEnabled",
          "DaisyFamily/DiscussionTimesEnabled",
          "DaisyFamily/DevelopmentNotificationTextFI",
          "DaisyFamily/DevelopmentNotificationTextEN",
          "DaisyFamily/DevelopmentNotificationTextSV",
          "DaisyFamily/DevelopmentNotification/StartTime",
          "DaisyFamily/DevelopmentNotification/EndTime",
          "DaisyFamily/ClientNotificationTextFI",
          "DaisyFamily/ClientNotificationTextEN",
          "DaisyFamily/ClientNotificationTextSV",
          "DaisyFamily/ClientNotification/StartTime",
          "DaisyFamily/ClientNotification/EndTime",
          "DaisyFamily/NewCaretimeCalendarEnabled",
          "DaisyFamily/OldCaretimeCalendarEnabled",
          "Daisyfamily/UseOptimizedMessaging",
        ]);
      }

      if (
        state.smallRequests.vacationNotificationStatus.status === "NEVER_LOADED"
      ) {
        actions.triggerLoadVacationNotificationStatus();
      }

      if (process.env.REACT_APP_AUTO_LOGOUT_ENABLED === "false") {
        console.log("***Autologout disabled.***");
      } else {
        if (!autoLogout.isRunning()) {
          autoLogout.start();
        }
      }
    } else {
      if (autoLogout.isRunning()) {
        autoLogout.cancel();
      }
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      actions.triggerLoadMessageData();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      if (
        state.messaging.setThisThreadRead.state ===
        types.WAITING_SET_THREAD_READ
      ) {
        setMsgReadWaiting(true);
      }
      if (
        msgReadWaiting &&
        state.messaging.setThisThreadRead.state ===
          types.SET_THREAD_READ_SUCCESS
      ) {
        setMsgReadWaiting(false);
        let optMessaging = state.settings.communeSettings.find(
          (setting) => setting.Key === "Daisyfamily/UseOptimizedMessaging"
        );

        if (
          optMessaging != null &&
          typeof optMessaging != undefined &&
          optMessaging.ValueBool === false
        ) {
          actions.triggerLoadThreadMainStats();
        }
      }
    }
  }, [isAuthenticated, state.messaging.setThisThreadRead.state, actions]);

  useEffect(() => {
    if (isAuthenticated) {
      if (state.nursingtimes.Saved.state === types.SAVING_NURSINGTIMES) {
        setNursingTimesSaving(true);
      }
      if (
        nursingTimesSaving &&
        state.nursingtimes.Saved.state === types.NURSINGTIMES_SAVED_OK
      ) {
        setNursingTimesSaving(false);
        actions.triggerLoadNursingtimeMainStats();
      }
    }
  }, [isAuthenticated, state.nursingtimes.Saved.state, actions]);

  useEffect(() => {
    if (isAuthenticated) {
      if (state.nursingtimes.Deleted.state === types.DELETING_NURSINGTIMES) {
        setNursingTimesDeleting(true);
      }
      if (
        nursingTimesDeleting &&
        state.nursingtimes.Deleted.state === types.NURSINGTIMES_DELETED_OK
      ) {
        setNursingTimesDeleting(false);
        actions.triggerLoadNursingtimeMainStats();
      }
    }
  }, [isAuthenticated, state.nursingtimes.Deleted.state, actions]);

  /*useEffect(() => {
    if (isAuthenticated) {
      const postGuardianConsentStatus = state.municipalpermissionquestions.saveGuardianConsent.status;
      if (postGuardianConsentStatus === types.LOADING_POST_GUARDIAN_CONSENTS) {
        setPostGuardianConsentWaiting(true);
      }
      if (postGuardianConsentWaiting && postGuardianConsentStatus === types.POST_GUARDIAN_CONSENTS_SUCCESS) {
        setPostGuardianConsentWaiting(false);
        actions.triggerLoadPermissionsMainStats();
      }
    }
  }, [isAuthenticated, state.municipalpermissionquestions.saveGuardianConsent.status, actions]);
  Tämä tsekataan nyt komponentissa itsessään
  */

  // Start timers on first mount
  useEffect(() => {
    if (window.Worker) {
      if (isAuthenticated) {
        console.log("Starting background jobs...");
        // Initial triggers, trigger api calls which are not triggered by ANY component
        updateMainStats();

        bulletinTimer.current = workerTimers.setInterval(() => {
          updateBulletinBoard();
        }, bulletinBoardInterval);

        mainStatsTimer.current = workerTimers.setInterval(() => {
          updateMainStats();
        }, dFSettings.updateMainStatsTimerSecs * 1000);
      } else {
        if (bulletinTimer.current) {
          workerTimers.clearInterval(bulletinTimer.current);
          console.log("BulletinTimer Stopped.");
        }
        if (mainStatsTimer.current) {
          workerTimers.clearInterval(mainStatsTimer.current);
          console.log("MainStatsTimer Stopped.");
        }
      }
    } else {
      console.warn("Browser doesn't support Webworkers!");
    }
  }, [isAuthenticated]);

  // useInterval(bulletinBoardInterval, () => {

  // })

  const updateBulletinBoard = () => {
    if (isAuthenticated) {
      //console.log("BackgroundScheduler: Triggering load bulletins");
      // TODO: change to read thru authProvider
      if (bulletinBoards.status !== types.LOADING_BULLETINBOARDS) {
        const loggedInPersonId = sessionStorage.getItem("personID");
        actions.triggerLoadBulletinBoards({ parentPersonId: loggedInPersonId });
      }
    }
  };

  const updateMainStats = () => {
    const { dFSettings } = state;
    if (isAuthenticated) {
      const d1 = new Date();
      const timeNow = d1.getTime();

      const timeSinceLastSuccess = timeNow - state.mainstats.lastSuccessTime;
      // do not call too often if something else has already called this

      let minimumTime = MAINSTAT_CONSTS.backgroundLoadAllMinimumTimeSecs * 1000;

      const secNotUndefined =
        typeof state.dFSettings?.updateMainStatsTimerSecs !== "undefined";
      //console.log("secNotUndefined", secNotUndefined);

      if (
        secNotUndefined &&
        state.dFSettings.updateMainStatsTimerSecs * 1000 < minimumTime
      ) {
        // if DaisyFamily/MainStatsTimerSeconds has been set as shorter period than the
        // standard minimumTime here, DaisyFamily/MainStatsTimerSeconds - 10 secs will be used instead
        const potentialMinimumTime =
          state.dFSettings.updateMainStatsTimerSecs * 1000 - 10 * 1000;
        if (potentialMinimumTime > 0) {
          minimumTime = potentialMinimumTime;
          //console.log("SETTING (UPDATEMAINSTATSTIMERSECS -10secs) AS MINIMUMTIME. minimumTime is now: ", minimumTime);
        }
      }

      if (
        dFSettings.state !== "LOAD_FAILED_DF_SETTINGS" &&
        state.mainstats.state !== types.LOADING_MAINSTATS &&
        timeSinceLastSuccess > minimumTime
      ) {
        //console.log("%c Triggering load mainstats from Scheduler, time since all were last loaded in seconds: " + timeSinceLastSuccess / 1000, "color: #2F4F4F");
        actions.triggerLoadMainStats();
      } else {
        //console.log("%c *NOT* triggering load mainstats from Scheduler, needs to be" + minimumTime / 1000 + "secs since last time, time since all were last loaded in seconds: " + timeSinceLastSuccess / 1000, "color: #9932CC");
      }
    }
  };

  const globalUserActionListener = useCallback(
    (event) => {
      if (event.type == "keyup") {
        //console.log("Global Listener got keyboard event", event);
      }

      if (isAuthenticated) {
        if (autoLogout) {
          autoLogout.start();
        }

        showWarningPopup(false);
      }
    },
    [isAuthenticated]
  );

  useEventListener("click", globalUserActionListener);
  useEventListener("scroll", globalUserActionListener);
  useEventListener("keyup", globalUserActionListener);

  return null;
};

export default BackgroundSchedulerFn;

import React      from  'react';
import Skeleton   from  '@mui/material/Skeleton';
import                  '../pedagdocimagefolder.css';


const PedagDocImageFolderLoading = ( ) => {
  return (
    <div title="imagefolder-loading__container" className="imagefolder-loading__container">
      <div className="imagefolder-loading__desc">
        <Skeleton variant="rectangular" animation="wave" height={50} width={350} />
      </div>
      <div className="imagefolder-loading__imagegrid__container" >  
        <div className="imagefolder-loading__grid">
          <div className="imagefolder-loading__image-tile">
            <Skeleton variant="rectangular" animation="wave" width={100} height={100} />
          </div>
          <div className="imagefolder-loading__image-tile">               
            <Skeleton variant="rectangular" animation="wave" width={100} height={100} />
          </div>
          <div className="imagefolder-loading__image-tile">
            <Skeleton variant="rectangular" animation="wave" width={100} height={100} />
          </div>
        </div>  
      </div>
    </div>
  );
}
  
export default PedagDocImageFolderLoading;
import React from "react";
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import MessagingFileDownload from "../MessagingFileDownload";



const PDFListItem = ( { attachmentData } ) => {
  return(
    <>
      <ListItemIcon><PictureAsPdfIcon sx={{ fontSize: 30, color: "white" }}/></ListItemIcon> 
      <ListItemText sx={{width: "50%", overflow: "hidden", color: "white"}}>{attachmentData.FileName}</ListItemText>
        <MessagingFileDownload 
          compactMode={true}
          FileName={attachmentData.FileName}
          FileMimeType={attachmentData.MimeType}
          FileId={attachmentData.Id}
          />
    </>
  )
}


export default PDFListItem
import React from "react";
import ReactDOM from "react-dom";
import "@fontsource/roboto";
import "@fontsource/news-cycle";
import "@fontsource/boogaloo"
import "bootstrap/dist/css/bootstrap.css";
import "./index.css";
import App from "./App";
import /*registerServiceWorker,*/ { unregister } from "./registerServiceWorker";
import moment from "moment";
import "moment/min/locales";
import Languages from "./translations";
import { AuthProvider } from "./Auth/AuthProvider";
import { StateProvider, initialState } from './State';
import MainReducer from './Reducers';
import { ESUOMI_AUTH_TYPE_VALUES } from "./Auth/AuthClient";
import { SessionManager } from "./Auth";
// import browserHistory from "history/createBrowserHistory";
//import * as qs from 'query-string';


if ((window.navigator.cookieEnabled === false || window.navigator.cookieEnabled == null ) ) {
  console.log(window.navigator.cookieEnabled);
  IS_Cookies_Enabled();
} 


function IS_Cookies_Enabled(Show_Message, Stop_Script)
{
    if(Show_Message == undefined){
        Show_Message = true;
    }

    if(Stop_Script == undefined){
        Stop_Script = false;
    }


    var Cookie_Status = navigator.cookieEnabled;
    if (!Cookie_Status)
    { 
        document.cookie = "Test";
        Cookie_Status = document.cookie.indexOf("Test") != -1;
        // Delete test cookies
        document.cookie = "Test=1; expires=Thu, 01-Jan-1970 00:00:01 GMT";

    }

    if((!Cookie_Status) && (Show_Message))
    {
        document.getElementsByTagName('body')[0].innerHTML = 
        "<div style='width: 600px; max-width: 100%; margin: 100px auto;'><h1 style='color: red'>Cookies Required</h1><p style='font-size: 18px;'>Cookies are not enabled on your browser. <br>Please enable cookies in your browser preferences to continue.</p><button style= 'margin-bottom: 15px; text-align: center; border-radius: 8px; color: white; background-color: rgb(0, 149, 216); width: 50%; border: none;' onclick=window.location.reload();>Try again</button>";
    }

    if((!Cookie_Status) && (Stop_Script))
    {
        throw new Error('Cookies is disabled');
    }
    console.log(Cookie_status);
    return Cookie_Status;
}

console.log(`This is a ${process.env.NODE_ENV} build`);
console.log("Frontend version:", process.env.REACT_APP_VERSION);
console.log("Backend version:", sessionStorage.getItem('RESTAPIVersion'));

const userLang = SessionManager.getUserLanguage(); 

console.log("INDEX. navigator.language: ", navigator.language, " navigator.userLanguage: ", navigator.userLanguage);

// Hides console.logs from all components that come after this / descend from here. However, always make sure and test this is the case
// Does not hide console.errors 
if ((process.env.NODE_ENV === "production" && window.location.hostname !== "test.daisyfamily.fi") ) {
  console.log("0");
  console.log = function () {};
} 
// || (process.env.NODE_ENV === "development" && window.location.hostname === "localhost")

//do not ever comment out the next line because it is for verifying whether console.logs are on or off
console.log("Console.logs are active. This text should not be visible on customer production builds, only on the production test site and development builds");




// Note: SessionManager will set language to localstorage if needed. No need to do it here.
switch (userLang)
{
  case "en":
  case "en-US":
  case "en-GB":
    moment.locale("en-gb");
    Languages.setLanguage("en-gb");
    break;
  case "sv":
    moment.locale("sv");
    Languages.setLanguage("sv");
    break;
  default:
    moment.locale("fi");
    Languages.setLanguage("fi");
    break;
}

// A function that routes the user to the right place
// after login when query parameters detected
// authStatus: { 
//  authType: esuomi_register|esuomi_normal,
//  userName: username
// }




ReactDOM.render(
  <AuthProvider>
    <StateProvider reducer={MainReducer} initialState={initialState}>
        <App />
    </StateProvider>
  </AuthProvider>
  ,
  document.getElementById("root")
);
//registerServiceWorker();
unregister();

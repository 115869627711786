import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./contentboxmui.css";
import { StateContext } from "../../State";
import MainNav from "../../Components/MainNav";
import { styled } from "@mui/styles";
import { ActionView } from "../ActionView/ActionView";

//replacement for css class .maxwidthmui
const RootDiv = styled("div")(({ theme }) => ({
  margin: "auto",
  position: "relative",
  overflowX: "hidden",
  overflowY: "hidden",
}));

//replacement for css class .contentRowMUI
const ContentDiv = styled("div")(({ theme }) => ({
  background:
    window.location.pathname === "/nursingtimes"
      ? "rgb(255, 255, 255)"
      : "#ECF3FB",
  //marginTop: '-2px',
  marginLeft: "auto",
  marginRight: "auto",
  marginBottom: "20px",
  boxShadow: "0px 0px 2px 3px #0000001f",
  padding: "10px",
  paddingTop: "64px",
  minHeight: "100vh",
  maxWidth: "800px",
}));

class ContentBoxMUI extends Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);
    this.state = {
      actionViewOpen: false,
    };
  }

  componentDidMount() {
    this.context.actions.triggerSaveHeaderTitle(this.props.header);
    this.context.actions.triggerSaveArrowCloseIconBooleans({
      leftArrow: this.props.showArrowBack,
      closeIcon: this.props.showCloseicon,
      function: this.props.setfunction,
    });
  }

  componentDidUpdate() {
    if (
      this.context.state.header.title !== undefined &&
      this.context.state.header.title !== this.props.header
    ) {
      this.context.actions.triggerSaveHeaderTitle(this.props.header);
    }
  }

  componentWillUnmount() {}

  render() {
    const { onNavBack } = this.props;

    const onActionViewButtonClick = () => {
      this.setState((prev) => ({
        actionViewOpen: !prev.actionViewOpen,
      }));
    };

    return (
      <React.Fragment>
        <MainNav
          onBackClick={onNavBack}
          onActionViewButtonClick={onActionViewButtonClick}
        />
        {this.props.secondaryNav}
        <RootDiv>
          <ActionView
            onClose={onActionViewButtonClick}
            open={this.state.actionViewOpen}
          />

          <ContentDiv>
            <div className="whiteSpaceTop"></div>
            {this.props.children}
          </ContentDiv>
        </RootDiv>
      </React.Fragment>
    );
  }
}

export default withRouter(ContentBoxMUI);

import React, { useEffect,useState } from "react";
import { initLoginSettingsState, useStateValue } from "../../State";
import { history } from "../../Router";
import Tooltip from '@mui/material/Tooltip';
import { types } from '../../Reducers/actionTypes';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import GradingIcon from '@mui/icons-material/Grading';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AddCommentIcon from '@mui/icons-material/AddComment';
import moment from "moment";
import "./actionview.css"
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import Languages  from "../../translations"
import InterpreterModeIcon from '@mui/icons-material/InterpreterMode'
import ListItemButton from '@mui/material/ListItemButton'
import ChildProfilePicture from "../ChildProfilePicture";


const AvailableDiscussionNotifications = () =>{
    const {state,actions} = useStateValue()
    const {mainstats,children,settings} = state

    //Is discussionTimes enabled?
    if(!settings.communeSettings.find(setting => setting.Key === "DaisyFamily/DiscussionTimesEnabled").ValueBool)
        return null
    
    const [expanded, setExpanded] = useState(false)

    const handleExpandClick = () => {
        if(!expanded){
            actions.triggerLoadDiscussionNotificationStats()
        }
        setExpanded(!expanded)
    }

    const handleRedirectToDiscussions = () => {
        if(history.location.pathname === '/discussionBooking') {
            onClose()
        }
        else {
            history.push("/discussionBooking")
        }
    }

    const bookDiscussion = (childId) => {
      history.push({pathname:"/bookDiscussion", 
      state:
       {child: childId}});
    }
    
    if(mainstats.AvailableDiscussionBookableDatesCount > 0){
        const status = state.discussion.discNotiStats.status;
        const data = state.discussion.discNotiStats.data
        console.log(data)
        console.log(status)
        console.log(expanded)
        return(<>
            <Divider textAlign="left"><Chip label={Languages.discDiscussionTimes} sx={{bgcolor: "#002E6D", textTransform: "uppercase"}} /></Divider>
            <Card sx={{ bgcolor: "#FFEBD4", border: "1px solid #EE5711", borderLeft: "7px solid #EE5711", margin: "5px" }}>
            <CardHeader
              avatar={
                <Avatar sx={{ bgcolor: "#EE5711" }} aria-label="Daycare icon">
                    <InterpreterModeIcon /> 
                </Avatar>
              }
              action={
                <Tooltip title={Languages.discMoveToBooking} arrow>
                <IconButton aria-label={Languages.discMoveToBooking} onClick={handleRedirectToDiscussions}>
                  <FollowTheSignsIcon sx={{color: "#EE5711"}}/>
                </IconButton>
                </Tooltip>
              }
              title={<p style={{color: "black", fontSize: "large", marginBottom: "0"}}><b>{Languages.discTimesAvailable}</b></p>}
              subheader={Languages.discYouHaveAppointments}
            />
            <CardActions disableSpacing>
              <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label={Languages.awShowMore}
                title={Languages.awShowMore}
              >
                <ExpandMoreIcon sx={{color: "#EE5711" }}/>
              </ExpandMore>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent>  
                {status == types.LOADING_DISCUSSIONNOTIFICATION_STATS && <CircularProgress sx={{color: "#EE5711"}} />}
                {status == types.GET_PARENTS_FOR_DISCUSSION_EDITING_FAILED && <h1>{Languages.discSomethingWentWrong}</h1>}
                {status == types.GET_DISCUSSIONNOTIFICATION_STATS_SUCCESS && 
                <List>
                    {data.ChildsNotiStats.map((child)=>{
                        return( 
                        <ListItem
                            key={child.ChildId} button divider 
                            sx={{borderBottom: "1px solid black"}}
                            secondaryAction={
                                <ChevronRightIcon sx={{color: "#EE5711"}}/>
                            }
                            onClick={()=>bookDiscussion(child.ChildId)}
                            >
                            <ListItemAvatar>
                                <ChildProfilePicture childId={child.ChildId}/>
                            </ListItemAvatar>
                            <ListItemText sx={{color: "black"}} primary={child.ChildName} secondary={<Typography>{child.DiscussionForDays == 0 ? Languages.discTimesUnabailable : Languages.discAvailableForXDays(child.DiscussionForDays)} </Typography>} />

                        </ListItem>)
                    })}
                    
                </List>
                }
                
              </CardContent>
            </Collapse>
          </Card>
          </>)
    }
    return null
}

export default AvailableDiscussionNotifications

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
}));
import React, { Component } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import Languages from "../../translations";
import "./dropdown.css";
import PartiallyValidIcon from "../../images/pinkwarning.svg";


class DropDown extends Component {
  constructor(props) {
    super(props);
    this.strings = Languages;

    this.toggle = this.toggle.bind(this);
   // this.onSelected = this.onSelected.bind(this);

    this.state = {
      dropdownOpen: false,
      data: this.props.data,
      component: this.props.component
    };
  }

  componentDidMount() {
  /*console.log("DropDown/DidMount"+
               "\n state.data: ", this.state.data,
               "\n props.title: ", this.props.title+ 
               "\n state.component: ",this.state.component);*/
  }
 
  componentDidUpdate() {
   /* console.log("DropDown. Update.\n"+
                "state.data: ", this.state.data,"\n"+
                " props.title: ", this.props.title,"\n"+ 
                " state.dropdownOpen: ", this.state.dropdownOpen,"\n"+
                " state.component: ",this.state.component);*/
  }
 
  onSelected= (event) => {
    var pvalid = false;
    this.state.data.forEach(d => {
      if (d.value === event.target.value)
      {
        pvalid = d.validpartially;
        //console.log("Partially valid placement: ",pvalid);
      }
    }); 
    var selection = [{component: this.state.component, id: event.target.value, name: event.target.innerText, partiallyvalid: pvalid, childId: this.props.childID} ];
    /*console.log("DropDown. onSelected.\n"+
                "event: ", event,"\n"+
                "event.target: ", event.target,"\n"+
                "state.data: ", this.state.data,"\n"+
                "selection: ", selection);*/

    this.props.callbackFromDropDown(selection);
  }

  toggle() {
   /*console.log("Dropdown. Toogle.\n"+
               " props.title: ", this.props.title,"\n"+ 
               " state.component: ",this.state.component);*/

    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  /*componentWillReceiveProps(props) {
    this.setState({ data: props.data || []
    });
  }*/
  render() {
    if (!this.state.data) return null;
    return (
      <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} >
        <DropdownToggle id={this.props.component === this.strings.bookingType ? "test_DropDown_Toggle_BookingType" : "test_DropDown_Toggle_Placement_"+this.props.childIndex} 
                        style={{width: this.state.component === this.strings.bookingType ? "100%" : "100%",
                        maxWidth: "100%",
                        whiteSpace:"pre-line"}}
                        caret>
          {this.props.title}
        </DropdownToggle>
        <DropdownMenu style={{width: this.state.component === this.strings.bookingType ? "100%" : "",
                              maxWidth: "100%",
                              textAlign:"center"}}>
          {this.state.data.map((o, index) => {
            return (
              <DropdownItem key={o.value} 
                            value={o.value}
                            id = {this.props.component === this.strings.bookingType ? "test_DropDownItemType_" + index : "test_DropDownItemPlacement_" + index}
                            onClick={this.onSelected} 
                            style={{whiteSpace:"pre-line",
                                    backgroundColor: o.text === this.props.title ? "darkgray": ""}}>
                {o.validpartially === true ? o.text + " " + o.Priority + " " : 
                  this.state.component === this.strings.bookingType ? o.text : o.text + " " + o.Priority } 
                {o.validpartially === true ?
                <img key={o.value} src={PartiallyValidIcon} alt="PV" width="15px" />
                : null}
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </Dropdown>
    );
  }
}

export default DropDown;

import { ApiBase } from "../internal";
import moment from "moment";
export class MessagesApi extends ApiBase {
  constructor() {
    super();
  }

  async loadMessages() {
    const data = {};

    let responseData = {};

    try {
      const response = await this.httpClient.post(
        "/Messages/GetMessageList2/",
        data
      );

      responseData = {
        status: response.status,
        data: response.data.listMessage,
      };

      return responseData;

      // EI TIETOA TARVITAANKO:
      /*for (var j = 0; j < this.messages.length; j++) {
        for (var k = 0; k < this.messages[j].ChildRecipients.length; k++) {
          this.messages[j].ChildRecipients[k].ProfilePictureBase64 = "";
        };
      }*/
    } catch (error) {
      console.error(
        "MESSAGES API Load messages ERROR: ",
        error,
        "\n" + " response.status: ",
        error.response === undefined
          ? 502
          : error.response.status === undefined
          ? 502
          : error.response.status
      );

      responseData = {
        status:
          error.response === undefined
            ? 502
            : error.response.status === undefined
            ? 502
            : error.response.status,
      };

      return responseData;
    }
  }

  //NEW MESSAGES LOAD
  async loadMessageData() {
    const data = {};

    let responseData = {};

    try {
      const response = await this.httpClient.post(
        "/Messages/MessageData/",
        data
      );

      responseData = {
        status: response.status,
        data: response.data,
      };

      return responseData;
    } catch (error) {
      console.error(
        "MESSAGES API Load messages ERROR: ",
        error,
        "\n" + " response.status: ",
        error.response === undefined
          ? 502
          : error.response.status === undefined
          ? 502
          : error.response.status
      );

      responseData = {
        status:
          error.response === undefined
            ? 502
            : error.response.status === undefined
            ? 502
            : error.response.status,
      };

      return responseData;
    }
  }

  ////////////////////////////////////////////////////////////////////////

  async deleteThread(threadId) {
    if (!threadId) {
      console.error("MESSAGESAPI ERROR, threadId is UNDEFINED");
    }
    console.log("threadId", threadId);

    //try {
    const response = await this.httpClient.delete(
      "/messages/thread/" + threadId
    );

    let responseData = {
      status: response.status,
      data: response.data,
    };
    return responseData;
    //}
    // jos poistat tästä try-catchin, testaa, että jos request feilaa serverillä ja palauttaa esim. 500, poistettava viestiketju näyttää, että
    // viestiketjua ei voitu poistaa (eli info ei jää loading-tilaan)
    /*catch(error) {
      console.log(error)
      let responseData = {
        status: error.response === undefined ? 502 : 
                  error.response.status === undefined ? 502 : 
                    error.response.status,
        data: error.response.data,
                  }
      return responseData;
    }*/
  }

  async loadOneMessage(threadId) {
    if (typeof threadId === "undefined") {
      console.error("MESSAGESAPI ERROR, threadId is UNDEFINED");
    }
    const data = { ThreadId: threadId, MessageId: "", HideRecipients: true };

    try {
      const response = await this.httpClient.post(
        "/Messages/GetMessageThread2",
        data
      );

      let responseData = {
        status: response.status,
        data: response.data,
      };
      return responseData;
    } catch (error) {
      let responseData = {
        status:
          error.response === undefined
            ? 502
            : error.response.status === undefined
            ? 502
            : error.response.status,
      };
      return responseData;
      //THIS DOES NOT RETURN DATA
    }
  }

  async ReplyToMassThread(datafromelsewhere) {
    // SplitThreadId must be sent. SplitThreadId will indicate that the message thread
    // must be split because it has been sent to a group/center and is thus a "massThread"
    // (massThread === true).

    try {
      let d = new Date();
      let data = {
        SplitThreadId: datafromelsewhere.SplitThreadId,
        ThreadId: null,
        Content: datafromelsewhere.Content,
        Start: moment().startOf("day").format("YYYY-M-DDTHH:mm:ss"),
        End: moment()
          .add(1, "year")
          .startOf("day")
          .format("YYYY-M-DDTHH:mm:ss"),
      };

      const response = await this.httpClient.post(
        "/messages/PostSendMessage",
        data
      );

      let responseData = {
        status: response.status,
        data: response.data,
        newOrOldThread: "NewThread",
      };

      return responseData;
    } catch (error) {
      let responseData = {
        status:
          error.response === undefined
            ? 502
            : error.response.status === undefined
            ? 502
            : error.response.status,
      };

      return responseData;
    }
  }

  async ReplyToNonmassThread(datafromelsewhere) {
    try {
      let d = new Date();
      let data = {
        ThreadId: datafromelsewhere.ThreadId,
        Content: datafromelsewhere.Content,
        Start: moment().startOf("day").format("YYYY-M-DDTHH:mm:ss"),
        End: moment()
          .add(1, "year")
          .startOf("day")
          .format("YYYY-M-DDTHH:mm:ss"),
      };

      const response = await this.httpClient.post(
        "/messages/PostSendMessage",
        data
      );

      let responseData = {
        status: response.status,
        data: response.data,
        newOrOldThread: "OldThread",
      };

      return responseData;
    } catch (error) {
      let responseData = {
        status:
          error.response === undefined
            ? 502
            : error.response.status === undefined
            ? 502
            : error.response.status,
      };

      return responseData;
    }
  }

  async SetThisThreadRead(threadId) {
    //ei testattu tämän tallentamista yleis-stateen. tästä ei kuitenkaan tarvitse handlata muuta kuin 401

    const data = { Data: threadId };

    try {
      const response = await this.httpClient.post(
        "/messages/setthreadread/",
        data
      );

      let responseData = {
        status: response.status,
        data: response.data,
      };

      return responseData;
    } catch (error) {
      let responseData = {
        status:
          error.response === undefined
            ? 502
            : error.response.status === undefined
            ? 502
            : error.response.status,
      };

      return responseData;
    }
  }

  async setAllMessagesRead() {
    const response = await this.httpClient.post("/messages/setthreadread/all");

    let responseData = {
      status: response.status,
      //data: response.data,
    };
    return responseData;
  }

  async LoadPotentialRecipients() {
    try {
      const response = await this.httpClient.get(
        "/messages/getlistofmessagerecipients/"
      );

      let responseData = {
        status: response.status,
        data: response.data,
      };

      return responseData;
    } catch (error) {
      let responseData = {
        status:
          error.response === undefined
            ? 502
            : error.response.status === undefined
            ? 502
            : error.response.status,
      };

      return responseData;
    }
  }

  async PostNewMessage(datafromnewmessage) {
    // Recipient type must be parent_person, if it is parent_person.

    const d = new Date();
    const data = {
      ListRecipients: datafromnewmessage[0].ListRecipients,
      Title: datafromnewmessage[0].Title,
      Content: datafromnewmessage[0].Content,
      Start: moment().startOf("day").format("YYYY-M-DDTHH:mm:ss"),
      End: moment().add(1, "year").startOf("day").format("YYYY-M-DDTHH:mm:ss"),
      // "2019-01-21T00:00:00",
    };

    try {
      const response = await this.httpClient.post(
        "/Messages/PostSendMessage",
        data
      );

      let responseData = {
        status: response.status,
      };

      return responseData;
    } catch (error) {
      let responseData = {
        status:
          error.response === undefined
            ? 502
            : error.response.status === undefined
            ? 502
            : error.response.status,
      };

      return responseData;
    }
  }

  // type: full, thumbnail, custom
  getAttachment(attachmentId, type, size) {
    /*
      {
        attachmentData:
        type: thumnail, customsize, full  

      }
    */
  }
}

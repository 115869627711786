import React, { useState, useEffect } from "react";
import { emphasize } from "@mui/material/styles";
import withStyles from "@mui/styles/withStyles";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Chip from "@mui/material/Chip";
import HomeIcon from "@mui/icons-material/Home";
import ChatBubble from "@mui/icons-material/ChatBubbleRounded";
import Languages from "../../../translations";
import { useStateValue } from "../../../State";
import EventNoteRounded from "@mui/icons-material/EventNoteRounded";
import LiveHelp from "@mui/icons-material/LiveHelp";
import ChildCareRounded from "@mui/icons-material/ChildCareRounded";
import KasvunkansioIcon from "../../../images/growthfolder.png";
import FaceIcon from "@mui/icons-material/Face";
import Avatar from "@mui/material/Avatar";
import SettingsRounded from "@mui/icons-material/SettingsRounded";
import FolderIcon from "@mui/icons-material/Folder";
import ListAltIcon from "@mui/icons-material/ListAlt";
import LEOPSIcon from "../../../images/leops.png";
import VASUIcon from "../../../images/vasuicon.png";
import ImageIcon from "@mui/icons-material/Image";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import InterpreterMode from "@mui/icons-material/InterpreterMode";
import ChildCareIcon from "@mui/icons-material/ChildCare";
import AddIcon from "@mui/icons-material/Add";
import { getProfilePictureData } from "../../../utils";
import { useParams } from "react-router-dom";
import { types } from "../../../Reducers/actionTypes";
const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip);

const RouteNavigation = ({
  children,
  location,
  history,
  getName,
  openGrowthFolder,
}) => {
  const { state, actions } = useStateValue();
  const { profilePictures } = state;

  const getProfileImage = (locationCase) => {
    switch (locationCase) {
      case "childInfo":
        const profilePicBase64 = getProfilePictureData(
          state,
          location.state.childId
        );
        if (profilePicBase64) {
          return (
            <Avatar
              src={"data:image/jpeg;" + profilePicBase64}
              style={{ height: "20px", width: "20px" }}
            />
          );
        } else {
          return <FaceIcon fontSize="small" />;
        }
      case "growthfolder":
        if (profilePictures.state === "LOAD_SUCCESS_PROFILEPICTURES") {
          const profilePicBase64 = getProfilePictureData(
            state,
            location.state.Id
          );

          if (profilePicBase64) {
            return (
              <Avatar
                src={"data:image/jpeg;" + profilePicBase64}
                style={{ height: "20px", width: "20px" }}
              />
            );
          } else {
            return <FaceIcon fontSize="small" />;
          }
        } else {
          return <FaceIcon fontSize="small" />;
        }
      case "growthfolderImagefolder":
        if (profilePictures.state === "LOAD_SUCCESS_PROFILEPICTURES") {
          const profilePicBase64 = getProfilePictureData(
            state,
            location.state.childId
          );

          if (profilePicBase64) {
            return (
              <Avatar
                src={"data:image/jpeg;" + profilePicBase64}
                style={{ height: "20px", width: "20px" }}
              />
            );
          } else {
            return <FaceIcon fontSize="small" />;
          }
        } else {
          return <FaceIcon fontSize="small" />;
        }
      default:
        return <FaceIcon fontSize="small" />;
    }
  };

  function returnCorrectBreadcrumbTrail(children, location, history, getName) {
    const { state, actions } = useStateValue();
    const { ThreadId } = useParams();
    const MessageData = state.messaging.messageData;
    var trueLocation =
      location.pathname.substring(0, 6) === "/vasu/"
        ? "/vasu/"
        : location.pathname.substring(0, 7) === "/leops/"
        ? "/leops/"
        : ThreadId !== null &&
          location.pathname.includes("openmessage") &&
          location.pathname.includes(ThreadId)
        ? "/messaging/openmessage2"
        : location.pathname;
    switch (trueLocation) {
      case "/":
        return (
          <Breadcrumbs aria-label="breadcrumb" separator="/">
            <StyledBreadcrumb
              component="a"
              label={Languages.menuHome}
              icon={<HomeIcon fontSize="small" />}
              onClick={() => history.push("/")}
              sx={{
                border: "#86b8ff",
                borderStyle: "solid",
                cursor: "pointer",
              }}
            />
          </Breadcrumbs>
        );
      case "/childInfo":
        var childName = getName();
        if (children.ChildList.length > 1) {
          return (
            <Breadcrumbs aria-label="breadcrumb" separator="/">
              <StyledBreadcrumb
                component="a"
                //href=""
                label={Languages.menuHome}
                //style={{backgroundColor: "#b5b2b2"}}
                icon={<HomeIcon fontSize="small" />}
                onClick={() => history.push("/")}
              />
              <StyledBreadcrumb
                sx={{
                  border: "#86b8ff",
                  borderStyle: "solid",
                  cursor: "pointer",
                }}
                component="a"
                label={childName}
                icon={getProfileImage("childInfo")}
              />
            </Breadcrumbs>
          );
        } else {
          <Breadcrumbs aria-label="breadcrumb" separator="/">
            <StyledBreadcrumb
              component="a"
              label={Languages.menuHome}
              icon={<HomeIcon fontSize="small" />}
              onClick={() => history.push("/")}
              sx={{
                border: "#86b8ff",
                borderStyle: "solid",
                cursor: "pointer",
              }}
            />
          </Breadcrumbs>;
        }
      case "/childList":
        return (
          <Breadcrumbs aria-label="breadcrumb" separator="/">
            <StyledBreadcrumb
              sx={{
                border: "#86b8ff",
                borderStyle: "solid",
                cursor: "pointer",
              }}
              component="a"
              //href="#"
              label={Languages.menuHome}
              icon={<HomeIcon fontSize="small" />}
              onClick={() => history.push("/")}
            />
          </Breadcrumbs>
        );
      case "/discussionBooking":
        return (
          <Breadcrumbs aria-label="breadcrumb" separator="/">
            <StyledBreadcrumb
              component="a"
              label={Languages.menuHome}
              icon={<HomeIcon fontSize="small" />}
              onClick={() => history.push("/")}
            />
            <StyledBreadcrumb
              component="a"
              style={{ cursor: "pointer" }}
              label={Languages.discDiscussionBooking}
              icon={<InterpreterMode fontSize="small" />}
            />
          </Breadcrumbs>
        );
      case "/discussions":
        return (
          <Breadcrumbs arial-label="breadcrumb" separator="/">
            <StyledBreadcrumb
              component="a"
              label={Languages.menuHome}
              icon={<HomeIcon fontSize="small" />}
              onClick={() => history.push("/")}
            />
            <StyledBreadcrumb
              component="a"
              style={{ cursor: "pointer" }}
              label={Languages.discDiscussionBooking}
              icon={<InterpreterMode fontSize="small" />}
              onClick={() => history.push("/discussionBooking")}
            />
            <StyledBreadcrumb
              component="a"
              style={{ cursor: "pointer" }}
              label={Languages.child}
              icon={<ChildCareIcon fontSize="small" />}
            />
          </Breadcrumbs>
        );
      case "/bookDiscussion":
        return (
          <Breadcrumbs arial-label="breadcrumb" separator="/">
            <StyledBreadcrumb
              component="a"
              label={Languages.menuHome}
              icon={<HomeIcon fontSize="small" />}
              onClick={() => history.push("/")}
            />
            <StyledBreadcrumb
              component="a"
              style={{ cursor: "pointer" }}
              label={Languages.discDiscussionBooking}
              icon={<InterpreterMode fontSize="small" />}
              onClick={() => history.push("/discussionBooking")}
            />
            <StyledBreadcrumb
              component="a"
              style={{ cursor: "pointer" }}
              label={Languages.bookingAddMoreBtn}
              icon={<AddIcon fontSize="small" />}
            />
          </Breadcrumbs>
        );
      case "/messaging":
        return (
          <Breadcrumbs aria-label="breadcrumb" separator="/">
            <StyledBreadcrumb
              component="a"
              href="#"
              label={Languages.menuHome}
              //style={{backgroundColor: "#b5b2b2"}}
              icon={<HomeIcon fontSize="small" />}
              onClick={() => history.push("/")}
            />
            <StyledBreadcrumb
              component="a"
              sx={{
                border: "#86b8ff",
                borderStyle: "solid",
                cursor: "pointer",
              }}
              label={Languages.menuMessaging}
              icon={<ChatBubble fontSize="small" />}
            />
          </Breadcrumbs>
        );
      case "/messaging2":
        return (
          <Breadcrumbs aria-label="breadcrumb" separator="/">
            <StyledBreadcrumb
              component="a"
              href="#"
              label={Languages.menuHome}
              //style={{backgroundColor: "#b5b2b2"}}
              icon={<HomeIcon fontSize="small" />}
              onClick={() => history.push("/")}
            />
            <StyledBreadcrumb
              component="a"
              sx={{
                border: "#86b8ff",
                borderStyle: "solid",
                cursor: "pointer",
              }}
              label={Languages.menuMessaging}
              icon={<ChatBubble fontSize="small" />}
            />
          </Breadcrumbs>
        );
      case "/messaging/openmessage":
        return (
          <Breadcrumbs aria-label="breadcrumb" separator="/">
            <StyledBreadcrumb
              component="a"
              href="#"
              label={Languages.menuHome}
              //style={{backgroundColor: "#b5b2b2"}}
              icon={<HomeIcon fontSize="small" />}
              onClick={() => history.push("/")}
            />
            <StyledBreadcrumb
              component="a"
              href="#"
              label={Languages.menuMessaging}
              //style={{backgroundColor: "#b5b2b2"}}
              onClick={() => history.push("/messaging")}
              icon={<ChatBubble fontSize="small" />}
            />
            <StyledBreadcrumb
              component="a"
              //style={{cursor:'pointer', backgroundColor: 'blue'}}
              sx={{
                border: "#86b8ff",
                borderStyle: "solid",
                cursor: "pointer",
              }}
              label={
                location.state && location.state.messageThread
                  ? location.state.messageThread.Title
                  : state.header.title
                  ? state.header.title
                  : "..."
              }
              icon={<ChatBubble fontSize="small" />}
            />
          </Breadcrumbs>
        );
      case "/messaging/openmessage2":
        return (
          <Breadcrumbs aria-label="breadcrumb" separator="/">
            <StyledBreadcrumb
              component="a"
              href="#"
              label={Languages.menuHome}
              //style={{backgroundColor: "#b5b2b2"}}
              icon={<HomeIcon fontSize="small" />}
              onClick={() => history.push("/")}
            />
            <StyledBreadcrumb
              component="a"
              href="#"
              label={Languages.menuMessaging}
              //style={{backgroundColor: "#b5b2b2"}}
              onClick={() => history.push("/messaging2")}
              icon={<ChatBubble fontSize="small" />}
            />
            <StyledBreadcrumb
              component="a"
              //style={{cursor:'pointer', backgroundColor: 'blue'}}
              sx={{
                border: "#86b8ff",
                borderStyle: "solid",
                cursor: "pointer",
              }}
              label={
                MessageData.state === types.MESSAGE_DATA_LOAD_OK
                  ? (
                      MessageData.messages.find(
                        (message) => message.ThreadId === ThreadId
                      ) || {}
                    ).Title
                  : "..."
              }
              icon={<ChatBubble fontSize="small" />}
            />
          </Breadcrumbs>
        );
      case "/messaging/newmessage":
        return (
          <Breadcrumbs aria-label="breadcrumb" separator="/">
            <StyledBreadcrumb
              component="a"
              href="#"
              label={Languages.menuHome}
              //style={{backgroundColor: "#b5b2b2"}}
              icon={<HomeIcon fontSize="small" />}
              onClick={() => history.push("/")}
            />
            <StyledBreadcrumb
              component="a"
              href="#"
              //style={{backgroundColor: "#b5b2b2"}}
              label={Languages.menuMessaging}
              onClick={() => history.push("/messaging")}
              icon={<ChatBubble fontSize="small" />}
            />
            <StyledBreadcrumb
              component="a"
              sx={{
                border: "#86b8ff",
                borderStyle: "solid",
                cursor: "pointer",
              }}
              style={{ cursor: "pointer" }}
              label={Languages.msgMessageNew}
              icon={<ChatBubble fontSize="small" />}
            />
          </Breadcrumbs>
        );
      case "/nursingtimes":
        return (
          <Breadcrumbs aria-label="breadcrumb" separator="/">
            <StyledBreadcrumb
              component="a"
              href="#"
              label={Languages.menuHome}
              //style={{backgroundColor: "#b5b2b2"}}
              icon={<HomeIcon fontSize="small" />}
              onClick={() => history.push("/")}
            />
            <StyledBreadcrumb
              sx={{
                border: "#86b8ff",
                borderStyle: "solid",
                cursor: "pointer",
              }}
              component="a"
              label={Languages.bookingMake}
              icon={<EventNoteRounded fontSize="small" />}
            />
          </Breadcrumbs>
        );

      case "/caretimecalendar":
        return (
          <Breadcrumbs aria-label="breadcrumb" separator="/">
            <StyledBreadcrumb
              component="a"
              href="#"
              label={Languages.menuHome}
              //style={{backgroundColor: "#b5b2b2"}}
              icon={<HomeIcon fontSize="small" />}
              onClick={() => history.push("/")}
            />
            <StyledBreadcrumb
              sx={{
                border: "#86b8ff",
                borderStyle: "solid",
                cursor: "pointer",
              }}
              component="a"
              label={Languages.bookingMake}
              icon={<EventNoteRounded fontSize="small" />}
            />
          </Breadcrumbs>
        );
      case "/permissions":
        return (
          <Breadcrumbs aria-label="breadcrumb" separator="/">
            <StyledBreadcrumb
              component="a"
              href="#"
              label={Languages.menuHome}
              //style={{backgroundColor: "#b5b2b2"}}
              icon={<HomeIcon fontSize="small" />}
              onClick={() => history.push("/")}
            />
            <StyledBreadcrumb
              component="a"
              style={{ cursor: "pointer" }}
              sx={{
                border: "#86b8ff",
                borderStyle: "solid",
                cursor: "pointer",
              }}
              label={Languages.permissionQuestionsGen}
              icon={<LiveHelp fontSize="small" />}
            />
          </Breadcrumbs>
        );
      case "/permissionqueries":
        return (
          <Breadcrumbs aria-label="breadcrumb" separator="/">
            <StyledBreadcrumb
              component="a"
              href="#"
              label={Languages.menuHome}
              //style={{backgroundColor: "#b5b2b2"}}
              icon={<HomeIcon fontSize="small" />}
              onClick={() => history.push("/")}
            />
            <StyledBreadcrumb
              component="a"
              label={Languages.permissionQuestionsGen}
              //style={{backgroundColor: "#b5b2b2"}}
              onClick={() => history.push("/permissions")}
              icon={<LiveHelp fontSize="small" />}
            />
            <StyledBreadcrumb
              sx={{
                border: "#86b8ff",
                borderStyle: "solid",
                cursor: "pointer",
              }}
              component="a"
              label={Languages.permPermissionQueries}
              icon={<LiveHelp fontSize="small" />}
            />
          </Breadcrumbs>
        );
      case "/placementinformation":
        return (
          <Breadcrumbs aria-label="breadcrumb" separator="/">
            <StyledBreadcrumb
              component="a"
              href="#"
              label={Languages.menuHome}
              //style={{backgroundColor: "#b5b2b2"}}
              icon={<HomeIcon fontSize="small" />}
              onClick={() => history.push("/")}
            />
            <StyledBreadcrumb
              component="a"
              sx={{
                border: "#86b8ff",
                borderStyle: "solid",
                cursor: "pointer",
              }}
              label={Languages.placementDayCareCentre}
              icon={<HomeWorkIcon fontSize="small" />}
            />
          </Breadcrumbs>
        );
      case "/growthfolder":
        return (
          <Breadcrumbs aria-label="breadcrumb" separator="/">
            <StyledBreadcrumb
              component="a"
              href="#"
              label={Languages.menuHome}
              //style={{backgroundColor: "#b5b2b2"}}
              icon={<HomeIcon fontSize="small" />}
              onClick={() => history.push("/")}
            />
            <StyledBreadcrumb
              component="a"
              sx={{
                border: "#86b8ff",
                borderStyle: "solid",
                cursor: "pointer",
              }}
              label={Languages.pdGrowthFolderTitle}
              icon={getProfileImage("growthfolder")}
            />
          </Breadcrumbs>
        );
      case "/growthfolder/taskfolder":
      case "/growthfolder/imagefolder":
        return (
          <Breadcrumbs aria-label="breadcrumb" separator="/">
            <StyledBreadcrumb
              component="a"
              href="#"
              label={Languages.menuHome}
              //style={{backgroundColor: "#b5b2b2"}}
              icon={<HomeIcon fontSize="small" />}
              onClick={() => history.push("/")}
            />
            <StyledBreadcrumb
              component="a"
              label={Languages.pdGrowthFolderTitle}
              //style={{backgroundColor: "#b5b2b2"}}
              onClick={() => openGrowthFolder(location.state.childId)}
              icon={getProfileImage("growthfolderImagefolder")}
            />
            <StyledBreadcrumb
              component="a"
              sx={{
                border: "#86b8ff",
                borderStyle: "solid",
                cursor: "pointer",
              }}
              label={
                location.state && location.state.folder
                  ? location.state.folder.Name
                  : "..."
              }
              icon={<FolderIcon fontSize="small" />}
            />
          </Breadcrumbs>
        );
      case "/growthfolder/media":
        return (
          <Breadcrumbs aria-label="breadcrumb" separator="/">
            <StyledBreadcrumb
              component="a"
              href="#"
              label={Languages.menuHome}
              //style={{backgroundColor: "#b5b2b2"}}
              icon={<HomeIcon fontSize="small" />}
              onClick={() => history.push("/")}
            />
            <StyledBreadcrumb
              component="a"
              label={Languages.pdGrowthFolderTitle}
              //style={{backgroundColor: "#b5b2b2"}}
              onClick={() => openGrowthFolder(location.state.childId)}
              icon={getProfileImage("growthfolderImagefolder")}
            />
            <StyledBreadcrumb
              component="a"
              //style={{backgroundColor: "#b5b2b2"}}
              label={
                location.state && location.state.folder
                  ? location.state.folder.Name
                  : "..."
              }
              onClick={() => history.goBack()}
              icon={<FolderIcon fontSize="small" />}
            />
            <StyledBreadcrumb
              component="a"
              sx={{
                border: "#86b8ff",
                borderStyle: "solid",
                cursor: "pointer",
              }}
              label={
                state.growthFolder.image && state.growthFolder.image[0].Header
                  ? state.growthFolder.image[0].Header
                  : state.growthFolder.image &&
                    state.growthFolder.image[0].Header == null
                  ? state.growthFolder.image[0].FileName
                  : "..."
              }
              icon={<ImageIcon fontSize="small" />}
            />
          </Breadcrumbs>
        );
      case "/settings":
        return (
          <Breadcrumbs aria-label="breadcrumb" separator="/">
            <StyledBreadcrumb
              component="a"
              href="#"
              label={Languages.menuHome}
              //style={{backgroundColor: "#b5b2b2"}}
              icon={<HomeIcon fontSize="small" />}
              onClick={() => history.push("/")}
            />
            <StyledBreadcrumb
              component="a"
              sx={{
                border: "#86b8ff",
                borderStyle: "solid",
                cursor: "pointer",
              }}
              label={Languages.menuSettings}
              icon={<SettingsRounded fontSize="small" />}
            />
          </Breadcrumbs>
        );
      case "/leops/":
        return (
          <Breadcrumbs aria-label="breadcrumb" separator="/">
            <StyledBreadcrumb
              component="a"
              href="#"
              label={Languages.menuHome}
              //style={{backgroundColor: "#b5b2b2"}}
              icon={<HomeIcon fontSize="small" />}
              onClick={() => history.push("/")}
            />
            <StyledBreadcrumb
              component="a"
              label="Leops"
              sx={{
                border: "#86b8ff",
                borderStyle: "solid",
                cursor: "pointer",
              }}
              icon={<FaceIcon fontSize="small" />}
            />
          </Breadcrumbs>
        );
      case "/leopsPreparingPart":
        return (
          <Breadcrumbs aria-label="breadcrumb" separator="/">
            <StyledBreadcrumb
              component="a"
              href="#"
              label={Languages.menuHome}
              //style={{backgroundColor: "#b5b2b2"}}
              icon={<HomeIcon fontSize="small" />}
              onClick={() => history.push("/")}
            />
            <StyledBreadcrumb
              component="a"
              label={"Leops"}
              //style={{backgroundColor: "#b5b2b2"}}
              onClick={() => history.goBack()}
              icon={<FaceIcon fontSize="small" />}
            />
            <StyledBreadcrumb
              component="a"
              sx={{
                border: "#86b8ff",
                borderStyle: "solid",
                cursor: "pointer",
              }}
              label={Languages.eduPlanTitle1}
              //onClick={() => history.push("/leopsPreparingPart")}
              icon={<FaceIcon fontSize="small" />}
            />
          </Breadcrumbs>
        );
      case "/leopsDiscussionPart":
        return (
          <Breadcrumbs aria-label="breadcrumb" separator="/">
            <StyledBreadcrumb
              component="a"
              href="#"
              label={Languages.menuHome}
              //style={{backgroundColor: "#b5b2b2"}}
              icon={<HomeIcon fontSize="small" />}
              onClick={() => history.push("/")}
            />
            <StyledBreadcrumb
              component="a"
              label={"Leops"}
              //style={{backgroundColor: "#b5b2b2"}}
              onClick={() => history.goBack()}
              icon={<FaceIcon fontSize="small" />}
            />
            <StyledBreadcrumb
              component="a"
              label={Languages.eduPlanTitle2}
              sx={{
                border: "#86b8ff",
                borderStyle: "solid",
                cursor: "pointer",
              }}
              //onClick={() => history.push("/leopsDiscussionPart")}
              icon={<FaceIcon fontSize="small" />}
            />
          </Breadcrumbs>
        );
      case "/leopsSupportPart":
        return (
          <Breadcrumbs aria-label="breadcrumb" separator="/">
            <StyledBreadcrumb
              component="a"
              href="#"
              //style={{backgroundColor: "#b5b2b2"}}
              label={Languages.menuHome}
              icon={<HomeIcon fontSize="small" />}
              onClick={() => history.push("/")}
            />
            <StyledBreadcrumb
              component="a"
              label={"Leops"}
              //style={{backgroundColor: "#b5b2b2"}}
              onClick={() => history.goBack()}
              icon={<FaceIcon fontSize="small" />}
            />
            <StyledBreadcrumb
              component="a"
              label={Languages.eduPlanTitle3}
              sx={{
                border: "#86b8ff",
                borderStyle: "solid",
                cursor: "pointer",
              }}
              //onClick={() => history.push("/leopsSupportPart")}
              icon={<FaceIcon fontSize="small" />}
            />
          </Breadcrumbs>
        );
      case "/leopsSpringEvaluationPart":
        return (
          <Breadcrumbs aria-label="breadcrumb" separator="/">
            <StyledBreadcrumb
              component="a"
              href="#"
              label={Languages.menuHome}
              //style={{backgroundColor: "#b5b2b2"}}
              icon={<HomeIcon fontSize="small" />}
              onClick={() => history.push("/")}
            />
            <StyledBreadcrumb
              component="a"
              label={"Leops"}
              //style={{backgroundColor: "#b5b2b2"}}
              onClick={() => history.goBack()}
              icon={<FaceIcon fontSize="small" />}
            />
            <StyledBreadcrumb
              component="a"
              label={Languages.eduPlanTitle4}
              sx={{
                border: "#86b8ff",
                borderStyle: "solid",
                cursor: "pointer",
              }}
              //onClick={() => history.goBack()}
              icon={<FaceIcon fontSize="small" />}
            />
          </Breadcrumbs>
        );
      case "/descriptionPart":
        return (
          <Breadcrumbs aria-label="breadcrumb" separator="/">
            <StyledBreadcrumb
              component="a"
              href="#"
              label={Languages.menuHome}
              //style={{backgroundColor: "#b5b2b2"}}
              icon={<HomeIcon fontSize="small" />}
              onClick={() => history.push("/")}
            />
            <StyledBreadcrumb
              component="a"
              label={"Leops / Vasu"}
              //style={{backgroundColor: "#b5b2b2"}}
              onClick={() => history.push("/descriptionPart")}
              icon={<FaceIcon fontSize="small" />}
            />
            <StyledBreadcrumb
              component="a"
              label={Languages.eduPlanChildDescription}
              sx={{
                border: "#86b8ff",
                borderStyle: "solid",
                cursor: "pointer",
              }}
              icon={<FaceIcon fontSize="small" />}
            />
          </Breadcrumbs>
        );
      case "/bulletinboard":
        return (
          <Breadcrumbs aria-label="breadcrumb" separator="/">
            <StyledBreadcrumb
              component="a"
              href="#"
              //style={{backgroundColor: "#b5b2b2"}}
              label={Languages.menuHome}
              icon={<HomeIcon fontSize="small" />}
              onClick={() => history.push("/")}
            />
            <StyledBreadcrumb
              component="a"
              label={Languages.bbTitle}
              sx={{
                border: "#86b8ff",
                borderStyle: "solid",
                cursor: "pointer",
              }}
              icon={<ListAltIcon fontSize="small" />}
            />
          </Breadcrumbs>
        );
      case "/vasu/":
        return (
          <Breadcrumbs aria-label="breadcrumb" separator="/">
            <StyledBreadcrumb
              component="a"
              href="#"
              label={Languages.menuHome}
              //style={{backgroundColor: "#b5b2b2"}}
              icon={<HomeIcon fontSize="small" />}
              onClick={() => history.push("/")}
            />
            <StyledBreadcrumb
              component="a"
              label={Languages.vasu}
              sx={{
                border: "#86b8ff",
                borderStyle: "solid",
                cursor: "pointer",
              }}
              icon={<ListAltIcon fontSize="small" />}
            />
          </Breadcrumbs>
        );
      case "/vasuPreparingPart":
        return (
          <Breadcrumbs aria-label="breadcrumb" separator="/">
            <StyledBreadcrumb
              component="a"
              href="#"
              label={Languages.menuHome}
              //style={{backgroundColor: "#b5b2b2"}}
              icon={<HomeIcon fontSize="small" />}
              onClick={() => history.push("/")}
            />
            <StyledBreadcrumb
              component="a"
              //style={{backgroundColor: "#b5b2b2"}}
              label={"Vasu"}
              onClick={() => history.goBack()}
              icon={<ListAltIcon fontSize="small" />}
            />
            <StyledBreadcrumb
              component="a"
              label={Languages.eduPlanTitle1}
              sx={{
                border: "#86b8ff",
                borderStyle: "solid",
                cursor: "pointer",
              }}
              //onClick={() => history.goBack()}
              icon={<ListAltIcon fontSize="small" />}
            />
          </Breadcrumbs>
        );
      case "/vasuDiscussionPart":
        return (
          <Breadcrumbs aria-label="breadcrumb" separator="/">
            <StyledBreadcrumb
              component="a"
              href="#"
              label={Languages.menuHome}
              //style={{backgroundColor: "#b5b2b2"}}
              icon={<HomeIcon fontSize="small" />}
              onClick={() => history.push("/")}
            />
            <StyledBreadcrumb
              component="a"
              label={"Vasu"}
              //style={{backgroundColor: "#b5b2b2"}}
              onClick={() => history.goBack()}
              icon={<ListAltIcon fontSize="small" />}
            />
            <StyledBreadcrumb
              component="a"
              label={Languages.eduPlanTitle2}
              sx={{
                border: "#86b8ff",
                borderStyle: "solid",
                cursor: "pointer",
              }}
              //onClick={() => history.goBack()}
              icon={<ListAltIcon fontSize="small" />}
            />
          </Breadcrumbs>
        );
      case "/vasuSupportPart":
        return (
          <Breadcrumbs aria-label="breadcrumb" separator="/">
            <StyledBreadcrumb
              component="a"
              href="#"
              label={Languages.menuHome}
              //style={{backgroundColor: "#b5b2b2"}}
              icon={<HomeIcon fontSize="small" />}
              onClick={() => history.push("/")}
            />
            <StyledBreadcrumb
              component="a"
              //style={{backgroundColor: "#b5b2b2"}}
              label={"Vasu"}
              onClick={() => history.goBack()}
              icon={<ListAltIcon fontSize="small" />}
            />
            <StyledBreadcrumb
              component="a"
              label={Languages.eduPlanTitle3}
              sx={{
                border: "#86b8ff",
                borderStyle: "solid",
                cursor: "pointer",
              }}
              //onClick={() => history.goBack()}
              icon={<ListAltIcon fontSize="small" />}
            />
          </Breadcrumbs>
        );
      case "/vasuEnhancedSupport":
        return (
          <Breadcrumbs aria-label="breadcrumb" separator="/">
            <StyledBreadcrumb
              component="a"
              href="#"
              label={Languages.menuHome}
              //style={{backgroundColor: "#b5b2b2"}}
              icon={<HomeIcon fontSize="small" />}
              onClick={() => history.push("/")}
            />
            <StyledBreadcrumb
              component="a"
              //style={{backgroundColor: "#b5b2b2"}}
              label={"Vasu"}
              onClick={() => history.goBack()}
              icon={<ListAltIcon fontSize="small" />}
            />
            <StyledBreadcrumb
              component="a"
              label={Languages.eduPlanTitle3}
              sx={{
                border: "#86b8ff",
                borderStyle: "solid",
                cursor: "pointer",
              }}
              onClick={() => history.goBack()}
              icon={<ListAltIcon fontSize="small" />}
            />
            <StyledBreadcrumb
              component="a"
              label={Languages.VasuEnhancedSupport}
              sx={{
                border: "#86b8ff",
                borderStyle: "solid",
                cursor: "pointer",
              }}
              onClick={() => history.goBack()}
              icon={<ListAltIcon fontSize="small" />}
            />
          </Breadcrumbs>
        );
      case "/vasuSpecialSupport":
        return (
          <Breadcrumbs aria-label="breadcrumb" separator="/">
            <StyledBreadcrumb
              component="a"
              href="#"
              label={Languages.menuHome}
              //style={{backgroundColor: "#b5b2b2"}}
              icon={<HomeIcon fontSize="small" />}
              onClick={() => history.push("/")}
            />
            <StyledBreadcrumb
              component="a"
              //style={{backgroundColor: "#b5b2b2"}}
              label={"Vasu"}
              onClick={() => history.goBack()}
              icon={<ListAltIcon fontSize="small" />}
            />
            <StyledBreadcrumb
              component="a"
              label={Languages.eduPlanTitle3}
              sx={{
                border: "#86b8ff",
                borderStyle: "solid",
                cursor: "pointer",
              }}
              onClick={() => history.goBack()}
              icon={<ListAltIcon fontSize="small" />}
            />
            <StyledBreadcrumb
              component="a"
              label={Languages.VasuSpecialSupport}
              sx={{
                border: "#86b8ff",
                borderStyle: "solid",
                cursor: "pointer",
              }}
              onClick={() => history.goBack()}
              icon={<ListAltIcon fontSize="small" />}
            />
          </Breadcrumbs>
        );

      default:
        return <>Default</>;
    }
  }

  return returnCorrectBreadcrumbTrail(children, location, history, getName);
};

export default RouteNavigation;

import React, { /*useMemo,*/ useEffect, useState } from 'react';
import { useStateValue } from '../../../State';
import { ATTACHMENT_TYPES } from "../../../Api";
import CircularProgress from "@mui/material/CircularProgress";
import { downloadBase64File } from "../../../utils";
import Languages from "../../../translations";

// This renders actual preview picture for messaging, fetches needed data for it, and shows a download button
//  preview-kuvan leveys määritetään kunta-asetuksissa

const PreviewPicture = ({ imgFileName, imgMimeType, imgId }) => {

  const { state, actions } = useStateValue();

  const { loadPreviewPicture } = state.files;

  const [imgLoaded, setImgLoaded] = useState(false);

  useEffect(() => {
    //console.log("PreviewPicture useEffect");
    if (!imgLoaded) {
      fetchImage(state, actions, imgId);
      setImgLoaded(true);
    }
  }, [state, actions, imgId, imgLoaded])


  return (

    <div >
      { Object.keys(loadPreviewPicture.savedPreviewPictures).includes(imgId) ?
        <img  
        style={{width: "100%", height: "100%", cursor: "zoom-in"}}
          alt={imgFileName}
          src={"data:" + imgMimeType + ";base64," + loadPreviewPicture.savedPreviewPictures[imgId]}
          id="test_showFullImage"
        />
        :
        <CircularProgress/>
      }
    </div>

  )
}

const fetchImage = async (state, actions, imgId) => {

  const { loadPreviewPicture } = state.files;
  //fetching only if that imageId is not found on the list of downloaded/saved pictures in general app state
  if (Object.keys(loadPreviewPicture.savedPreviewPictures).includes(imgId) !== true) {
    actions.triggerLoadPreviewPicture({ imageId: imgId, type: ATTACHMENT_TYPES.thumbnail });
  }
}


export default PreviewPicture;
import React, {useEffect, useState} from "react";
import { useAuth } from '../../../Auth/AuthProvider';
import {detect} from "detect-browser";
import * as semver from "semver";



const VersionNumber = () => {

  const browser = detect();
  const [browserName, setBrowserName] = useState("");
  const [browserVersion, setBrowserVersion] = useState("");
  const [browserOS, setBrowserOS] = useState("");

  const {loginSettings} = useAuth();

  useEffect(() => {
    if (browser) {
      setBrowserName(browser.name);
      setBrowserVersion(browser.version);
      setBrowserOS(browser.os);
    }
  }, []);

    return(
      <>
        <div style={{fontSize: "x-small", color: "#DDDDDD"}}>Frontend: {sessionStorage.getItem('WebAppVersion')}. {loginSettings.DaisyRestApiVersion}</div>
        <div style={{fontSize: "x-small", color: "#DDDDDD"}}>Selain: {browserName}. Versio: {browserVersion}. OS: {browserOS}</div>
      </>
    )

}

export default VersionNumber;
export const AuthStatus = {
  NOT_LOADED: 'NOT_LOADED',
  AUTH_IN_PROGRESS: 'AUTH_IN_PROGRESS',
  AUTH_SUCCESS: 'AUTH_SUCCESS',
  AUTH_FAILED: 'AUTH_FAILED',
  AUTH_LOGOUT_COMPLETE: 'AUTH_LOGOUT_COMPLETE',
  AUTH_LOGOUT_PROGRESS: 'AUTH_LOGOUT_PROGRESS',
  AUTH_LOGOUT_SSO_REQ: 'AUTH_LOGOUT_SSO_REQ',
  ESUOMI_SETTINGS_LOADING: 'ESUOMI_SETTINGS_LOADING',
  ESUOMI_SETTINGS_LOADED: 'ESUOMI_SETTINGS_LOADED',
  ESUOMI_SETTINGS_LOAD_FAILED: 'ESUOMI_SETTINGS_LOAD_FAILED',
  LOGIN_OPTIONS_LOADING: 'LOGIN_OPTIONS_LOADING',
  LOGIN_OPTIONS_LOADED: 'LOGIN_OPTIONS_LOADED',
  LOGIN_OPTIONS_LOAD_FAILED: 'LOGIN_OPTIONS_LOAD_FAILED',
  ESUOMI_AUTH_INITIATED: 'ESUOMI_AUTH_INITIATED',
}
import React, {useState, useEffect} from "react";
import DownloadIcon from '@mui/icons-material/Download';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import PreviewThumbnail from "../PreviewPicture/PreviewThumbnail";
import CircularProgress from '@mui/material/CircularProgress';
import ReplayIcon from '@mui/icons-material/Replay';
import DoneIcon from '@mui/icons-material/Done';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';


import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade'

import { downloadBase64File } from "../../../utils";
import { ServerApi } from "../../../internal";
import { ATTACHMENT_TYPES } from "../../../Api";
import FullImageModal from "../FullImageModal";
import Languages from "../../../translations";
import { useStateValue } from "../../../State";
import { types } from "../../../Reducers/actionTypes";



const ImageListItem = ( { attachmentData } ) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [fileStats, setFileStats] = useState({fileId: undefined, imgMimeType: undefined, imgFileName: undefined})
  const {state,actions} = useStateValue()
  const {files} = state
  const fileDownloadStats = files.fileDownloadStats

  const pictureDownload = fileDownloadStats.downloads.find(x => x.fileId == attachmentData.Id)

  useEffect(()=>{
    const index = fileDownloadStats.downloads.findIndex(x => x.fileId == fileStats.fileId)
    if(index != -1){

      if(fileDownloadStats.downloads[index].status == types.DOWNLOAD_FILE_SUCCESS){
          downloadBase64File(fileDownloadStats.response, fileStats.imgMimeType, fileStats.imgFileName)
          setFileStats({fileId: undefined, imgMimeType: undefined, imgFileName: undefined})
      }
    }
  },[fileDownloadStats])

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

    return(
      <>
      {modalOpen &&
        <FullImageModal 
          imageId={attachmentData.Id}
          imageMimeType={attachmentData.MimeType}
          imagename={attachmentData.FileName}
          closefunction={() => handleClose()}
        />
    }
        <ListItemIcon style={{width: "25px", height: "25px"}} onClick={() => handleOpen()}><PreviewThumbnail imgFileName={attachmentData.FileName} imgMimeType={attachmentData.imgMimeType} imgId={attachmentData.Id} sx={{ fontSize: 50 }}/></ListItemIcon> 
        <ListItemText sx={{width: "50%", overflow: "hidden", color: "white", marginLeft: "5px"}}>{attachmentData.FileName}</ListItemText>
        <Tooltip 
          arrow 
          title={<div style={{fontSize: "0.8rem"}}>{Languages.generalDownloadFileAttachment2}.<hr /> <li>{attachmentData.FileName}</li></div>}
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
        >
          {/* <ListItemButton disabled={fileDownloadStats.status == types.DOWNLOADING_FILE} onClick={() => attachmentOnClick(attachmentData.Id, attachmentData.MimeType, attachmentData.FileName,actions,setFileStats, fileDownloadStats)} divider="true" sx={{flexDirection: "column", color: "white", fontSize: 12}}>
            {(fileDownloadStats.status == "NEVER_DOWNLOADED" || (fileDownloadStats.status == types.DOWNLOAD_FILE_SUCCESS &&  fileDownloadStats.fileId != attachmentData.Id)) && <><DownloadIcon />{Languages.downloadMedia}</>}
            {fileDownloadStats.status == types.DOWNLOADING_FILE && fileDownloadStats.fileId == attachmentData.Id && <CircularProgress />}
            {fileDownloadStats.status == types.DOWNLOAD_FILE_FAILED && fileDownloadStats.fileId == attachmentData.Id && <><ReplayIcon /> {Languages.retry}</>}
            {fileDownloadStats.status == types.DOWNLOAD_FILE_SUCCESS && fileDownloadStats.fileId == attachmentData.Id && <><DoneIcon/> {Languages.downloadMedia}</>}
          </ListItemButton>  */}
          <ListItemButton disabled={pictureDownload?.status == types.DOWNLOADING_FILE} onClick={() => attachmentOnClick(attachmentData.Id, attachmentData.MimeType, attachmentData.FileName,actions,setFileStats, fileDownloadStats)} divider="true" sx={{flexDirection: "column", color: "white", fontSize: 12}}>
            {!pictureDownload && <><DownloadIcon />{Languages.downloadMedia}</>}
            {pictureDownload?.status == types.DOWNLOADING_FILE && <CircularProgress />}
            {pictureDownload?.status == types.DOWNLOAD_FILE_FAILED && <><ReplayIcon /> {Languages.retry}</>}
            {pictureDownload?.status == types.DOWNLOAD_FILE_SUCCESS && <><DoneIcon/> {Languages.downloadMedia}</>}
          </ListItemButton> 
        </Tooltip>
        <Tooltip 
          arrow 
          title={<div style={{fontSize: "0.8rem"}}>{Languages.generalOpenText}<hr /><li>{attachmentData.FileName}</li></div>}
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}  
        >
          <ListItemButton disabled={pictureDownload?.status == types.DOWNLOADING_FILE} onClick={() => handleOpen()} divider="true" sx={{flexDirection: "column", color: "white", fontSize: 12}}><FileOpenIcon/>{Languages.generalOpen}</ListItemButton>
        </Tooltip>
      </>
    )
}

const attachmentOnClick = async (imgId, imgMimeType, imgFileName, actions, setFileStats, fileDownloadStats) => {
  actions.triggerDownloadFile({FileId: imgId, ATTACHMENT_TYPES: ATTACHMENT_TYPES.fullImage, FileMimeType: "", fileDownloadStats: fileDownloadStats,})
  setFileStats({fileId: imgId, imgMimeType: imgMimeType, imgFileName: imgFileName})
}

export default ImageListItem
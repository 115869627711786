import React, { useEffect, useState } from "react";
import { types } from "../../Reducers/actionTypes";
import { useStateValue } from "../../State";
import Languages from "../../translations";
import Moment from "moment";
import {
  /*ListGroup,*/ Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter /*, Input*/,
} from "reactstrap";
import ContentBoxMUI from "../../Components/ContentBoxMUI";
import ContentBox from "../../Components/ContentBox";
import PermPermissionNote from "../PermPermissionPopUp/PermPermissionNote";
import { Grid } from "@mui/material";
import ChildBasicInfo from "./Components/ChildBasicInfo";
import Typography from "@mui/material/Typography";
import OneChildItem from "./Components/OneChildItem";
import BulletinBoard from "../../Components/BulletinBoard";
import FloatingNav from "../../Components/FloatingNav";
import { Redirect } from "react-router";
import MissingVacationBookingsNote from "../../Components/MissingVacationBookingsNote/MissingVacationBookingsNote";
import { MAINSTAT_CONSTS } from "../../Contants";
import useMediaQuery from "@mui/material/useMediaQuery";
import BottomNavigation from "../../Components/BottomNavigation";
import { set } from "lodash";

const styles = {
  color: "#4377BA",
  fontSize: "18pt",
  fontWeight: "bold",
};

// MainStats listChildNursingTimeStatus jos listassa on jotain niin varoitus?
const handleMainStats = (childId, listChildNursingTimeStatus, updateState) => {
  let nursingtimeNotReservedFound = false;
  let nursingtimeNotReservedFoundText = "";
  let month = 0;
  let year = 0;

  if (!childId) {
    return;
  }

  if (listChildNursingTimeStatus !== undefined) {
    for (let i = 0; i < listChildNursingTimeStatus.length; i++) {
      if (listChildNursingTimeStatus[i].ChildPersonId === childId) {
        nursingtimeNotReservedFound = true;
        nursingtimeNotReservedFoundText =
          Moment(listChildNursingTimeStatus[i].CheckedStartDate).format(
            "DD.MM"
          ) +
          " - " +
          Moment(listChildNursingTimeStatus[i].CheckedEndDate).format(
            "DD.MM.YYYY"
          );
        month =
          Moment(listChildNursingTimeStatus[i].CheckedStartDate).format("MM") -
          1; // 0=tammikuu, 11=joulukuu
        year = Moment(listChildNursingTimeStatus[i].CheckedStartDate).format(
          "YYYY"
        );
        break;
      }
    }

    updateState({
      found: nursingtimeNotReservedFound,
      timeSpan: nursingtimeNotReservedFoundText,
    });
  }
};

// TODO: selvitä miten tämän logiikka oikein menee
// getNextNursingtimes api palauttaa varatut hoitoajat jotka tulevaisuudessa
// Make sure this is function is only called on useEffect! to avoid infinite loop
const handleNextNursingTimes = (childId, nextNursingTimes, updateState) => {
  if (!childId) {
    return;
  }
  //const children = chilrenState;

  if (nextNursingTimes.state === types.NEXTNURSINGTIMES_READ) {
    nextNursingTimes.TimesList.forEach((ntChild) => {
      if (ntChild.PersonId === childId) {
        updateState({
          nextNTTime: ntChild.Time,
          nextNTPlacement: ntChild.Placement,
        });
      }
    });
  }
  return;
};

const mapHttpCodeToText = (code) => {
  if (code === 400) {
    return Languages.errorInformationMissing;
  }

  if (code === 404) {
    return Languages.errorNotFound;
  }

  if (code === 500) {
    return Languages.errorInDB;
  }

  if (code === 502) {
    return Languages.errorNetwork;
  }

  return Languages.errorUnknown;
};

const handleChildInfoStatus = (
  childInfoState,
  setInfoModal,
  setErrorModal,
  setInfoText
) => {
  //const { ChildInfo } = this.context.state.children;
  if (childInfoState === types.LOAD_FAILED_CHILDINFO) {
    setInfoModal(false);
    setErrorModal(true);
    setInfoText(mapHttpCodeToText(ChildInfo.Code));
  }

  if (childInfoState === types.LOAD_SUCCESS_CHILDINFO) {
    setInfoModal(false);
    setErrorModal(false);
  }
};

const handleSaveChildInfo = () => {
  const { childId } = this.props.location.state;
  const { ChildInfo } = this.context.state.children;

  /*console.log("ChildInfo. handleSaveChildInfo: "+
              "\n ChildInfo.state: ", ChildInfo.state,
              "\n ChildInfo.Code: ",ChildInfo.Code,
              "\n ChildInfo.Info: ", ChildInfo.Info);*/

  switch (ChildInfo.state) {
    case types.SAVING_CHILDINFO:
      //console.log("ChildInfo. SAVING_CHILDINFO");
      break;
    case types.SAVE_FAILED_CHILDINFO:
      //console.log("ChildInfo. SAVE_FAILED_CHILDINFO: ",ChildInfo.Info);
      if (this.state.errorModalOpen === false) {
        this.setState({
          infoedited: false,
          infoModalOpen: false,
          errorModalOpen: true,
          infoText:
            ChildInfo.Code === 400
              ? this.strings.errorInformationMissing
              : ChildInfo.Code === 404
              ? this.strings.errorNotFound
              : ChildInfo.Code === 500
              ? this.strings.errorInDB
              : ChildInfo.Code === 502
              ? this.strings.errorNetwork
              : this.strings.errorUnknown,
        });
      }
      break;
    case types.SAVE_SUCCESS_CHILDINFO:
      //console.log("ChildInfo. SAVE_SUCCESS_CHILDINFO: ",ChildInfo.Info);
      //SAVE_HANDLED_CHILDINFO state ei käytetä vielä, koska ok/fail casen jälkeen tiedot vain luetaan uudestaan
      this.context.actions.triggerLoadChildinfo(childId);
      this.setState({
        infoedited: false,
        infoModalOpen: false,
        errorModalOpen: false,
      });
      break;
    default:
      break;
  }
};

const locationStateSafeRead = (location, key) => {
  if (location.state) {
    return location.state[key];
  }

  return undefined;
};

const ChildInfo = ({ location, history }) => {
  const { state: appState, actions } = useStateValue();
  const { state } = useStateValue();
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [infoModalOpen, setInfoModalOpen] = useState(false);

  const [infoText, setInfoText] = useState("");
  const [nursingtimeNotReserved, setNursingtimeNotReserved] = useState({
    found: false,
    timeSpan: "",
  });
  const [nextNursingTime, setNextNursingTime] = useState({
    nextNTTime: "",
    nextNTPlacement: "",
  });

  const [profilePicture, setProfilePicture] = useState();

  const { listChildNursingTimeStatus } = appState.mainstats;
  const { nextNursingTimes } = appState.nursingtimes;
  const { children, mainstats } = appState;
  const { profilePicturesList } = appState.profilePictures;

  const isDesktop = useMediaQuery("(min-width:1200px)");

  useEffect(() => {
    sessionStorage.setItem("prev", "childinfo");
  }, []);

  useEffect(() => {
    const childId = locationStateSafeRead(location, "childId");
    if (childId) {
      actions.triggerLoadChildinfo(childId);
    }

    const pp = profilePicturesList?.find((pp) => pp.PersonID === childId);
    setProfilePicture(pp);
  }, [location.state, profilePicturesList]);

  useEffect(() => {
    const d1 = new Date();
    const timeNow = d1.getTime();

    const timeSinceLastSuccess = timeNow - appState.mainstats.lastSuccessTime;
    // do not call too often if something else has already called this
    const minimumTimeChildInfoMainStatsInSeconds =
      MAINSTAT_CONSTS.childInfoMinimumTimeSecs;
    if (
      (timeSinceLastSuccess > minimumTimeChildInfoMainStatsInSeconds * 1000 &&
        appState.mainstats.state !== types.LOADING_MAINSTATS) ||
      mainstats.state === types.NOT_LOADED
    ) {
      //console.log("Triggering load ALL mainstats from CHILDINFO, time since all were last loaded in seconds: ", timeSinceLastSuccess / 1000);
      actions.triggerLoadMainStats();
    } else {
      //console.log("*NOT* triggering load ALL mainstats from CHILDINFO, needs to be more than " + minimumTimeChildInfoMainStatsInSeconds + " secs since last, time since all were last loaded in seconds: ", timeSinceLastSuccess / 1000 +
      //"Mainstats loading status is: " + appState.mainstats.state);
    }
  }, []);

  useEffect(() => {
    handleMainStats(
      locationStateSafeRead(location, "childId"),
      listChildNursingTimeStatus,
      setNursingtimeNotReserved
    );
  }, [location.state, listChildNursingTimeStatus]);

  useEffect(() => {
    handleNextNursingTimes(
      locationStateSafeRead(location, "childId"),
      nextNursingTimes,
      setNextNursingTime
    );
  }, [location.state, nextNursingTimes]);

  useEffect(() => {
    handleChildInfoStatus(
      children.childInfoState,
      setInfoModalOpen,
      setErrorModalOpen,
      setInfoText
    );
  }, [children.childInfoState]);

  useEffect(() => {
    if (children.childInfoState === types.SAVE_FAILED_CHILDINFO) {
      if (errorModalOpen === false) {
        setInfoModalOpen(false);
        // TODO: Add childInfo http codes to somewhere if really needed
        setInfoText(mapHttpCodeToText(children.childInfoState));
        setErrorModalOpen(true);
        // TODO: tarkista tarviiko tätä täällä
        // this.setState({
        //   infoedited: false,
        // });
      }
    }
    if (children.childInfoState === types.SAVE_SUCCESS_CHILDINFO) {
      actions.triggerLoadChildinfo(locationStateSafeRead(location, "childId"));
      setInfoModalOpen(false);
      setErrorModalOpen(false);
      // this.setState({
      //   infoedited: false,
      // });
    }
  }, [children.childInfoState]);

  if (location.state === undefined) {
    return <Redirect to="/" />;
  }

  if (errorModalOpen) {
    return (
      <Modal isOpen={errorModalOpen}>
        <ModalBody>
          <div className="InfoMessage textCenter">
            <ContentBox
              header={Languages.ciBasicInfo}
              showCloseicon={true}
              setfunction={() => setErrorModalOpen(false)}
            >
              <h4 style={{ whiteSpace: "pre-line" }}>{infoText}</h4>
            </ContentBox>
          </div>
        </ModalBody>
      </Modal>
    );
  }

  return (
    <div id="test_ChildInfo" className="ChildInfo">
      <div /* className="maxwidth" */>
        <ContentBoxMUI
          hideMUIHeader={null}
          header={locationStateSafeRead(location, "childName")}
          childcolor={locationStateSafeRead(location, "childColor")}
          showArrowBack={locationStateSafeRead(location, "showBackArrow")}
          //setfunction    = {(status) => this.handleCB("CLOSECHILDINFO")}
          hideHeader={true}
        >
          {/*      Removed 13.8.2022 bc actionView launch. Deprecated, can be removed completely after sure everything works in production

          {nursingtimeNotReserved.found &&
            <div className="nappula" tabIndex="0" onClick={() => history.push("/nursingtimes", { readNursingTimes: true })}>
              <div className="container no-gutters">
                <div className="row no-gutters">
                  <div className="col-12">
                    {Languages.bookingCareHoursMissingTimeSpan}
                    <br />
                    {nursingtimeNotReserved.timeSpan}
                  </div>
                </div>
              </div>
            </div>}
*/}
          <Grid container spacing={3}>
            {/*
            <Grid item xs={12} spacing={1}>
              <PermPermissionNote/>
            </Grid>
            <Grid item xs={12} spacing={1}>
              <MissingVacationBookingsNote/>
            </Grid>
*/}

            <Grid item xs={12} sm={8} md={8} lg={8} className="mt-3">
              <Typography
                id="test_ChildInfo_BasicInfo"
                variant="h6"
                component="h2"
                style={styles}
              >
                {" "}
                {locationStateSafeRead(location, "childName")}{" "}
              </Typography>
              <OneChildItem
                profilepicture={profilePicture?.ImageBase64}
                childFirstName={locationStateSafeRead(
                  location,
                  "childFirstName"
                )}
                childColor={locationStateSafeRead(location, "childColor")}
                childId={locationStateSafeRead(location, "childId")}
                history={history}
                //nextNursingTimeTime={nextNTTime}
                //nextNursingTimeTime={nextNursingTime.nextNTTime}
                nextNursingTimePL={nextNursingTime.nextNTPlacement}
                VasuId={locationStateSafeRead(location, "VasuId")}
                LeopsId={locationStateSafeRead(location, "LeopsId")}
                filter={locationStateSafeRead(location, "childId")}
              ></OneChildItem>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4} className="mt-3">
              <BulletinBoard
                slideViewOnClick={() => history.push("/bulletinboard")}
                mode="slide"
                filter={locationStateSafeRead(location, "childId")}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} className="mt-3">
              <ChildBasicInfo
                childId={locationStateSafeRead(location, "childId")}
                childColor={locationStateSafeRead(location, "childColor")}
              />
            </Grid>
          </Grid>
          {!isDesktop && (
            //<FloatingNav notReadThreads={mainstats.notReadThreads} />
            <BottomNavigation
              notReadThreads={
                state.messaging.messageData.state == "MESSAGE_DATA_LOAD_OK"
                  ? state.messaging.messageData.notReadThreads
                  : 0
              }
            />
          )}
        </ContentBoxMUI>
      </div>
    </div>
  );
};

export default ChildInfo;

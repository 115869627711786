import React                from "react";
import PDFListItem from "./PDFListItem";
import WordListItem from "./WordListItem";
import ImageListItem from "./ImageListItem";

const ListItemContent = ( { attachmentData, showFullImage } ) => {

  const itemType = attachmentData.MimeType
  
  function returnListItem (itemType) {

    const listItemOptions = {

      "application/pdf": function() {
        return(<PDFListItem attachmentData = {attachmentData} />);
      },

      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": function() {
        return(<WordListItem attachmentData={attachmentData} />);
      },

      "image/jpeg": function() {
        return(<ImageListItem attachmentData={attachmentData} showFullImage={showFullImage} />);
      },

      "image/png": function () {
        return(<ImageListItem attachmentData={attachmentData} showFullImage={showFullImage} />)
      },

      "default": function() {
        return("Liitetiedoston formaattia ei tueta.");
      },
    };
    return (listItemOptions[itemType] || listItemOptions["default"])();

  }

  return(
    <React.Fragment>
      {returnListItem(itemType)}
    </React.Fragment>
  );
}


export default ListItemContent
import React, { useState, useEffect } from "react";
import {useStateValue} from "../../../State";
import "../leops.css";

import Moment from 'moment';

import ChildSelfAssessment from '../ChildSelfAssessment';
import CommentsReadOnly from '../../Vasu/CommentsReadOnly';
import CommentsEditing  from '../../Vasu/CommentsEditing';

import SlideConfirmationNote  from "../../../Components/SlideConfirmationNote";

import Languages      from "../../../translations";

import SwipeableViews from 'react-swipeable-views';

import MobileStepper    from '@mui/material/MobileStepper';
import Box              from '@mui/material/Box'
import { Typography }    from "@mui/material";
import Button           from '@mui/material/Button';
import Divider          from '@mui/material/Divider';
import Backdrop         from '@mui/material/Backdrop';
import CircularProgress from "@mui/material/CircularProgress";
import makeStyles from '@mui/styles/makeStyles';

import KeyboardArrowLeft  from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

import _cloneDeep         from "lodash/cloneDeep"; // deep clones an array so that the copy is not just shallow
import { types } from '../../../Reducers/actionTypes';

const useStyles = makeStyles(theme => ({
  backdrop: {
    //zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: "#75C9CC !important"
  },
  fixedbottom: {
    float: "right !important",
    fontSize: "x-large",
    position: "fixed",
    fontWeight: "bold", 
    color: "#D500F9",
    right: "0",
    bottom: "0",
    zIndex: "1040 !important"
  },
  displaynone:{
    display: "none"
  }
}));

const PreparingSubPart = (props) => {

  const classes = useStyles();
  const { state, actions } = useStateValue();
  
  const GeneralPart = state.leops.GeneralPart;
  const Table1Rows  = state.leops.Table1.Data.TableRow;

  const Data        = state.leops.Table1.Data.AssesmentRows;

  //console.log("PreparingSubPart. MOUNT. Table1Rows: ",Table1Rows );

  //const { history, location } = props;
  const MaxSlideCount = 8;
  let slides = [];

  var Titles = [Languages.ManyFormsOfExpression,
                Languages.leopsRichWorldOfLanguage,
                Languages.leopsMeAndOurCommunity,
                Languages.leopsIStudyAndWork,
                Languages.leopsIGrowAndDevelop,
                Languages.leopsIWantToLearnInPreschool,
                Languages.StaffNotes,
                Languages.leopsLeopsTitle1_3,
                Languages.leopsChildStrengthsAndIterestsParent,
                Languages.leopsObjectivesAndConsiderationsForThePreschool,
                Languages.HopesForCooperation];

  const { SelectedSlideIndex } = props;

  const [SlideIndex,   setSlideIndex] = useState(SelectedSlideIndex);
  const [SlideAssessmentData, setslideData] = useState(Data.find(item => { 
    return ( (item.AssessmentItem[0].Row-1) === SlideIndex ) }
  ));
  const [BGColor,      setBGColor]    = useState(/*SelectedSlideIndex === 0 ? */"#75C9CC"/* : '#FBE4FF'*/);
  const [SavingData,   setSavingData]   = useState(false);

  const [FormChanged,     setFormChanged]     = useState(false);
  const [showSlideNote,   setshowSlideNote]   = useState(false);
  const [EditingTextOpen, setEditingTextOpen] = useState(null); // häkki, kunnes useamman tekstikentän yhtäaikainen editointi ja tallennus speksattu

  const [ParentNotes1, setParentNotes1] = useState(_cloneDeep(Table1Rows[0].RowItem[1]));
  const [ParentNotes3, setParentNotes3] = useState(_cloneDeep(Table1Rows[2].RowItem[1]));
  const [ParentNotes4, setParentNotes4] = useState(_cloneDeep(Table1Rows[3].RowItem[1]));
  const [ParentNotes5, setParentNotes5] = useState(_cloneDeep(Table1Rows[4].RowItem[1]));
    
  useEffect( () => {
    //console.log("PreparingSubPart. Leops state: ", state.leops.state, " FormChanged: ", FormChanged);
    
    switch(state.leops.state)
    {
      case "INITIAL_STATE":
      case types.SAVING_LEOPS:
        //console.log("PreparingSubPart SAVING_LEOPS -> Set Saving ON, if not already on");
        setSavingData(true);
        break;
      case types.LEOPS_LOAD_OK:
        /*console.log("PreparingSubPart LEOPS_LOAD_OK -> Set SlideData \n ArvioData: ",
        state.leops.Table1.Data.AssesmentRows.find(item => { 
          return ( (item.AssessmentItem[0].Row-1) === SlideIndex ) }
        ));*/

        setSavingData(false);
        setslideData(state.leops.Table1.Data.AssesmentRows.find(item => { 
          return ( (item.AssessmentItem[0].Row-1) === SlideIndex ) }
        ));
        setParentNotes1(_cloneDeep(Table1Rows[0].RowItem[1]));
        setParentNotes3(_cloneDeep(Table1Rows[2].RowItem[1]));
        setParentNotes4(_cloneDeep(Table1Rows[3].RowItem[1]));
        setParentNotes5(_cloneDeep(Table1Rows[4].RowItem[1]));
        actions.triggerLeopsHandled();
        break;
      case types.LEOPS_SAVED_OK:
        //console.log("PreparingSubPart. LEOPS_SAVED_OK -> Show ok note and load leops");
        //setSavingData(false);
        setFormChanged(false);
        setEditingTextOpen(null);
        actions.triggerAddToSnackBarQueue({color: "#000000", message: Languages.formSaved});
        actions.triggerLoadLeops(GeneralPart.Id);
        //actions.triggerLeopsHandled();
        break;
      case types.LEOPS_SAVED_FAILED:
        console.log("PreparingSubPart. LEOPS_SAVED_FAILED. Code: ", state.leops.Code, " Message: ", state.leops.Message);
        var codetext = state.leops.Code === undefined ? "\n" + Languages.errorUnknown : "\n" + Languages.errorCode + ": " + state.leops.Code;
        var msg = state.leops.Message === undefined ? "" : "\n" + state.leops.Message;
        setSavingData(false);
        actions.triggerAddToSnackBarQueue({color: "#000000", message: "Kommentin tallentaminen epäonnistui" + codetext + msg});
        actions.triggerLeopsHandled();
        break;
      default:
        //console.log("PreparingSubPart. Default Leops resp");
        break;
    }
  }, [state.leops.state]);

  const handlePrev = () =>
  {
    var index = SlideIndex;
    //console.log("PreparingSubPart. PREV Current index: ", SlideIndex, " Oikea max slide count: ",Data.length + 3);

    if (FormChanged)
    {
      setshowSlideNote(true);
    }
    else if (SlideIndex > 5)
    {
      index--;
      setSlideIndex(index);
    }
    else
    {
      // first 5 slides, check if question is found
      var looped = Data.filter((item, ind) => { 
        return ( (item.AssessmentItem[0].Row-1) < index) }
      );

     // console.log("looped last: ", looped === undefined ? undefined : looped[looped.length-1]);
      if (looped.length === 0 )
      {
        index = index;
      }
      else
      {
        index = looped[looped.length-1].AssessmentItem[0].Row-1;
        setslideData(looped[looped.length-1]);
      }

      //console.log("PreparingSubPart. PREV New index: ", index);

      setSlideIndex(index);
    }
  };

  const handleNext = () =>
  {
    var index = SlideIndex;
    //console.log("PreparingSubPart. NEXT Current index: ", SlideIndex, " Oikea max slide count: ",Data.length + 3);

    if (FormChanged)
    {
      setshowSlideNote(true);
    }
    else if (SlideIndex < (MaxSlideCount-1) && index > 4)
    {
      index++;
      setSlideIndex(index);
    }
    else
    {
      // first 5 slides, check if question is found
      var found = Data.find(item => { 
        return ( (item.AssessmentItem[0].Row-1) > index ) }
      );

      /*console.log("Current index: ", index,
                  "\n found next: ", found,
                  "\n next slide: ",found === undefined ? undefined : found.AssessmentItem[0].Row);*/
      if (found === undefined )
      {
        index = 5;
      }
      else
      {
        index = found.AssessmentItem[0].Row -1;
        setslideData(found);
      }
      setSlideIndex(index);
    }
  };

  const handleClick = () =>
  {
    console.log("PreparingSubPart. Handle Click event. FormChanged: ", FormChanged);

    if (FormChanged)
    {
      setshowSlideNote(true);
    }
  };

  const handleChangeIndex = (e, index) => {
    console.log("PreparingSubPart. Handle swipe. Nykyinen Slide: ",SlideIndex,"\n Uusi slide: ",e,"\n Edellinen : ",index, "\n Max slide count: ",Data.length + 3);

    if (FormChanged)
    {
      setshowSlideNote(true);
    }
    else if (e < index) // PREV
    {
      if (SlideIndex > 5)
      { setSlideIndex(e); }
      else
      {
        // first 5 slides, check if question is found
        var looped = Data.filter((item, ind) => { 
          return ( (item.AssessmentItem[0].Row-1) < index) }
        );
  
        if (looped.length === 0 )
        {
          setSlideIndex(e);
        }
        else
        {
          setslideData(looped[looped.length-1]);
          setSlideIndex(looped[looped.length-1].AssessmentItem[0].Row-1);
        }
      }
    }
    else // NEXT
    {
      if (SlideIndex < (MaxSlideCount-1) && SlideIndex > 4)
      { setSlideIndex(e); }
      else
      {
        // first 5 slides, check if question is found
        var found = Data.find(item => { 
          return ( (item.AssessmentItem[0].Row-1) > index ) }
        );

        /*console.log("Current index: ", index,
                    "\n found next: ", found,
                    "\n next slide: ",found === undefined ? undefined : found.AssessmentItem[0].Row);*/
        if (found === undefined )
        { setSlideIndex(5); }
        else
        { 
          setslideData(found);
          setSlideIndex(found.AssessmentItem[0].Row -1);
        }
      }
    }
  };

  const handleFormChange = (status, component) =>
  {
    //console.log("PreparingSubPart. Handle change. \nStatus: ", status, " \ncomponent: ", component, "\nPrevOpen: ", EditingTextOpen);

    if (EditingTextOpen === null && status === "EDITED")
    {
      setFormChanged(true);
      setEditingTextOpen(component);
    }
    else if (EditingTextOpen !== null && EditingTextOpen !== component && status === "EDITED")
    {
      setshowSlideNote(true);
    }
    else if (EditingTextOpen === component && status === "EDIT CANCELED")
    {
      setFormChanged(false);
      setEditingTextOpen(null);
    }
  };

  const handleSlideConfirmationNoteCB = (status) =>
  {
    console.log("PreparingSubPart. Handle slide confnote callback. status: ", status);
    setshowSlideNote(false);
  };

  const handleCommentsEditingCB = (type, mode, newData) => {
    /*console.log("PreparingSubPart. Comments edited callback:"+
                //"\n SlideIndex: "+SlideIndex+
                "\n type: "+ type +
                "\n mode: "+ mode +
                "\n newData: ", newData);*/

    let EditedParentComment = [];
    if (mode === "ShowFormChangedNote") // häkki, kunnes useamman tekstikentän yhtäaikainen editointi ja tallennus speksattu
    {
      setshowSlideNote(true);
    }
    else if ( mode === "Save" )
    {
      EditedParentComment = { LeopsId:  GeneralPart.Id,
                              Table:    1,
                              Row:      type === "ParentNotes_Row6" ? 6 :
                                        type === "ParentNotes_Row8" ? 8 :
                                        type === "ParentNotes_Row9" ? 9 : 10,
                              Text:     newData};
      setSavingData(true);
      actions.triggerSaveLeopsParentComments(EditedParentComment);
    }
  };

  const SlideText = () => {
    var slides = [];
    slides = [{Text: Languages.ManyFormsOfExpressionInfo}, 
              {Text: Languages.RichWorldOfLanguageInfo},
              {Text: Languages.MeAndOurCommunityInfo},
              {Text: Languages.IStudyAndWorkInfo},
              {Text: Languages.IGrowAndDevelopInfo},
              {Text: ""},
              {Text: ""},
              {Text: ""}];
    return slides;
  };
  
  if (state.leops.state === "INITIAL_STATE")
  {
    //console.log("PreparingSubPart. INITIAL STATE. RENDER EMPTY");

    return (<div className="PreparingSubPart"></div>);
  }
  else 
  {
    if (slides.length === 0)
    {
      //console.log("VasuPreparingSubPart. RENDER. Create slides");
      slides = SlideText();
    }
    return (
    <div className="PreparingSubPart">
      <Box>
      
      {SavingData ?
        <div style={{opacity: "0.5"}}>
            <Backdrop open={true} className={classes.backdrop}>
              <CircularProgress />
            </Backdrop>
        </div>
      :
        <div style={{opacity: GeneralPart.FormLocked === true ? "60%" : ""}} >
        <SwipeableViews className     = {"col-12 textCenter slide pt-3"}
                        index         = {SlideIndex} 
                        key           = {SlideIndex}
                        onChangeIndex = {handleChangeIndex} 
                        disabled      = {FormChanged ? true : false}
                        /*onClick       = {handleClick}*/>
          {slides.map((slide, index) => { 
            return ( 
              <div key={index}>
                <Box  className="br-top col-12" sx={{ backgroundColor: BGColor}}>
                  <Typography color="textSecondary" sx={{textAlign:"center", fontWeight: 'Bold', fontSize: 18, color: "#424242", pt: 1.5}}>{Titles[SlideIndex]}</Typography>
                  <MobileStepper style={{backgroundColor: BGColor, color: "black"}}
                                //  variant="progress"
                                variant="text"
                                steps={MaxSlideCount}
                                position="static"
                                //LinearProgressProps={{width: "100%"}}
                                activeStep={SlideIndex}
                                //className={classes.root}
                                nextButton={ <Button size="small" style={{color: "black", opacity: SlideIndex === (MaxSlideCount-1) ? "0.5" : "" }} onClick={handleNext} disabled={SlideIndex === (MaxSlideCount-1)}>{Languages.next} <KeyboardArrowRight /> </Button> }
                                backButton={ <Button size="small" style={{color: "black", opacity: SlideIndex === 0 ? "0.5" : ""}} onClick={handlePrev} disabled={SlideIndex === 0}> <KeyboardArrowLeft /> {Languages.prev}  </Button> }/>
                </Box>
                <Box className="br-btm col-12" sx={{boxShadow: 2, mb: 3, pb: 1, backgroundColor: "white"}}>
                      <Typography color="textSecondary" sx={{textAlign:"center", fontWeight: 'Medium', fontSize: 16, color: "#424242", pt:1}}>{slide.Text}</Typography>
                </Box>
              </div>
            );                  
          })}
        </SwipeableViews>

        {showSlideNote ?
            <SlideConfirmationNote openNote     ={showSlideNote}
                                   title        ={Languages.formChange}
                                   text         ={Languages.generalSaveOrCancel}
                                   leftButton   ={Languages.generalOkBtn}
                                   rightButton  ={null}
                                   middleButton ={null}
                                   callback     ={(status) => handleSlideConfirmationNoteCB(status)}></SlideConfirmationNote>
        : null }

        {SlideIndex < 5 ? <ChildSelfAssessment  slide={SlideIndex} 
                                                slideData={SlideAssessmentData} 
                                                title={Languages.LeopsTitle1_2_1} 
                                                table={1} 
                                                color={BGColor}
                                                callbackEdit ={(status, component) => handleFormChange(status, component)}> </ChildSelfAssessment> : 
         SlideIndex === 5 ?
          <div>
            <CommentsReadOnly Title1  = {<Typography color="textSecondary" sx={{textAlign:"left", fontWeight: 'Bold', fontSize: 16, color: "#424242", pt:1, ml:1}}>{Languages.OwnNotes} </Typography>} // Muiden kommentit
                              Title2  = {null}
                              Title3  = {null}
                              BgColor = {BGColor} 
                              Data    = {{Text: Table1Rows[0].RowItem[0].RowText}}>
            </CommentsReadOnly>

            {GeneralPart.FormLocked === true ?
              <CommentsReadOnly Title1  = {<Typography color="textSecondary" sx={{textAlign:"left", fontWeight: 'Bold', fontSize: 16, color: "#424242", pt:1, ml:1}}>{Languages.OwnNotes} </Typography>}
                                Title2  = {null}
                                Title3  = {null}
                                BgColor = {BGColor} 
                                Data    = {{Text: ParentNotes1.RowText === null ? null : ParentNotes1.RowText + "\n"+ ParentNotes1.Editor }}>
              </CommentsReadOnly>
            :
              <CommentsEditing key          = {"Own1_"+SlideIndex}
                               Type         = {"ParentNotes_Row6"}
                               PrevType     = {EditingTextOpen} // hakki
                               BgColor      = {BGColor} 
                               Data         = {{Text: ParentNotes1.RowText, Saved: ParentNotes1.Editor }}
                               callback     = {(type, mode, newData) => handleCommentsEditingCB(type, mode, newData)}
                               Title1       = {null}
                               callbackEdit = {(status, component) => handleFormChange(status, component)}>
              </CommentsEditing>
            }

          </div>
         :
         SlideIndex === 6 ? 
          <div>
            <CommentsReadOnly Title1  = {<Typography color="textSecondary" sx={{textAlign:"left", fontWeight: 'Bold', fontSize: 16, color: "#424242", pt:1, ml:1}}>{Languages.StaffNotes} </Typography>} //Varhaiskasvatushenkilöstön 
                              Title2  = {null}
                              Title3  = {null}
                              BgColor = {BGColor} 
                              Data    = {{Text: Table1Rows[1].RowItem[0].RowText}}>
            </CommentsReadOnly>
          </div>
         : // huoltajan osio
          <div> 
            <CommentsReadOnly Title1  = {<Typography color="textSecondary" sx={{textAlign:"left", fontWeight: 'Bold', fontSize: 16, color: "#424242", pt:1, ml:1}}>{Titles[8]}</Typography>} //muiden 
                              Title2  = {null}
                              Title3  = {null}
                              BgColor = {BGColor} 
                              Data    = {{Text: Table1Rows[2].RowItem[0].RowText}}>
            </CommentsReadOnly>
            {GeneralPart.FormLocked === true ?
              <CommentsReadOnly Title1  = {null} // omat 
                                Title2  = {null}
                                Title3  = {null}
                                BgColor = {BGColor} 
                                Data    = {{Text:  ParentNotes3.RowText === null ? null : ParentNotes3.RowText + "\n" + ParentNotes3.Editor }}>
              </CommentsReadOnly>
            :
              <CommentsEditing key = {"Own2_"+SlideIndex}
                               Type       = {"ParentNotes_Row8"}
                               PrevType     = {EditingTextOpen} // hakki
                               BgColor    = {BGColor} 
                               Data       = {{Text: ParentNotes3.RowText, Saved: ParentNotes3.Editor }}
                               callback     = {(type, mode, newData) => handleCommentsEditingCB(type, mode, newData)}
                               Title1       = {null}
                               callbackEdit = {(status, component) => handleFormChange(status, component)}>
              </CommentsEditing>
            }

            <CommentsReadOnly Title1  = {<Typography color="textSecondary" sx={{textAlign:"left", fontWeight: 'Bold', fontSize: 16, color: "#424242", pt:1, ml:1}}>{Titles[9]}</Typography>} // muiden 
                              Title2  = {null}
                              Title3  = {null}
                              BgColor = {BGColor} 
                              Data    = {{Text: Table1Rows[3].RowItem[0].RowText}}>
            </CommentsReadOnly>

            {GeneralPart.FormLocked === true ?
              <CommentsReadOnly Title1  = {null} //omat 
                                Title2  = {null}
                                Title3  = {null}
                                BgColor = {BGColor} 
                                Data    = {{Text: ParentNotes4.RowText === null ? null : ParentNotes4.RowText + "\n" + ParentNotes4.Editor }}>
              </CommentsReadOnly>
            :
              <CommentsEditing key = {"Own3_"+SlideIndex}
                               Type       = {"ParentNotes_Row9"}
                               PrevType     = {EditingTextOpen} // hakki
                               BgColor    = {BGColor} 
                               Data       = {{Text: ParentNotes4.RowText, Saved: ParentNotes4.Editor }}
                               callback     = {(type, mode, newData) => handleCommentsEditingCB(type, mode, newData)}
                               Title1       = {null}
                               callbackEdit = {(status, component) => handleFormChange(status, component)}>
              </CommentsEditing>
            }

            <CommentsReadOnly Title1  = {<Typography color="textSecondary" sx={{textAlign:"left", fontWeight: 'Bold', fontSize: 16, color: "#424242", pt:1, ml:1}}>{Titles[10]}</Typography>} //muiden 
                              Title2  = {null}
                              Title3  = {null}
                              BgColor = {BGColor} 
                              Data    = {{Text: Table1Rows[4].RowItem[0].RowText}}>
            </CommentsReadOnly>


            {GeneralPart.FormLocked === true ?
              <CommentsReadOnly Title1  = {null} //omat 
                                Title2  = {null}
                                Title3  = {null}
                                BgColor = {BGColor} 
                                Data    = {{Text: ParentNotes5.RowText === null ? null : ParentNotes5.RowText + "\n" + ParentNotes5.Editor }}>
              </CommentsReadOnly>
            :
              <CommentsEditing key = {"Own4_"+SlideIndex}
                               Type       = {"ParentNotes_Row10"}
                               PrevType     = {EditingTextOpen} // hakki
                               BgColor    = {BGColor} 
                               Data       = {{Text: ParentNotes5.RowText, Saved: ParentNotes5.Editor }}
                               callback     = {(type, mode, newData) => handleCommentsEditingCB(type, mode, newData)}
                               Title1       = {null}
                               callbackEdit = {(status, component) => handleFormChange(status, component)}>
              </CommentsEditing>
            }
          </div>
         }
        </div>
      }
      </Box>
    </div>
  );
  }
}

export default PreparingSubPart;
import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import {types} from "../../../Reducers/actionTypes";
import CircularProgress from "@mui/material/CircularProgress";
import {useStateValue} from "../../../State";
import IconButton             from "@mui/material/IconButton";
import Card                   from "@mui/material/Card";
import CardContent            from "@mui/material/CardContent";
import ChildListPermissionQuery2 from "./ChildListPermissionQuery2";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Languages from "../../../translations";
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import LockIcon from '@mui/icons-material/Lock';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import Avatar from '@mui/material/Avatar';
import CloseIcon from '@mui/icons-material/Close';
import PermQueryLockedStatus from "./PermQueryLockedStatus";


const SinglePermQueryView = ({data, closeSinglePermView}) => {

  const { state, actions } = useStateValue();
/*
  useEffect(() => {
    if() {
      actions.triggerLoadProfilePictures();
    }
  }, []);
*/
  const loadingState = state.municipalpermissionquestions.onePermissionQuery.status;
  const postingState = state.municipalpermissionquestions.savePermissionQuery.status;

  const [postIsLoading, setPostIsLoading] = useState(false);
  const [showPostingErrorMessage, setShowPostingErrorMessage] = useState(false);


  useEffect(() => {
    //console.log("useEffect", postIsLoading, postingState)
    if (postIsLoading && postingState !== types.LOADING_POST_SINGLE_PERMISSION_QUERY) {
      if (postingState === types.POST_SINGLE_PERMISSION_QUERY_SUCCESS) {
        actions.triggerAddToSnackBarQueue({message: Languages.permAnswersWereSaved, color: "#000000"});
        actions.triggerLoadPermissionsMainStats();
      }
      if (postingState === types.POST_SINGLE_PERMISSION_QUERY_FAILED) {
        setShowPostingErrorMessage(true);
        actions.triggerLoadPermissionsMainStats();
      }
      
      setPostIsLoading(false);
    }
  
  }, [postingState]);

  if (loadingState === types.LOADING_SINGLE_PERMISSION_QUERY) {
    return(
    <div style={{width: '100%', textAlign: "center", paddingTop: "10vh"}}>
    <CircularProgress />
    </div>
    )
  }

  if (!data) {
    return(<Typography>{Languages.permNoData}</Typography>);
  }

  const closePostingErrorMessage = () => {

    setShowPostingErrorMessage(false);
    actions.triggerLoadSinglePermissionQuery(data.MessageId);
  }

  //TODO: muuta css:t olemasta riippuvaisia messagingin css:stä
  // Tee inboxiin refresh-nappi
  // lataa kuvat aina kun refresh

  const sendPermissions = (permissions) => {
    actions.triggerPostSinglePermissionQuery(permissions);
    setPostIsLoading(true);
  }


  return(
    <React.Fragment>
        <Dialog color="error" sx={{backgroundColor: "red"}}onClose={closePostingErrorMessage} aria-labelledby="simple-dialog-title" open={showPostingErrorMessage}>
          <DialogContent color="error"> 
            <div style={{textAlign: "center"}}>
              <Typography>{Languages.permPermSavingFailed}</Typography>
              <Typography>{Languages.permOneOrMoreSavesFailed}</Typography>
              <Button color="secondary" variant="outlined" onClick={closePostingErrorMessage} aria-label={Languages.generalClose}>OK</Button>
            </div>
          </DialogContent>
        </Dialog>

      <Card sx={{backgroundColor: "white"}}>
        <CardHeader sx={{fontWeight: "800", backgroundColor: "#4377BA"}}
          avatar={<div style={{display: "block"}}><Avatar><QuestionAnswerIcon /></Avatar>{data.Sender}</div>}
          title={<Typography variant="h5" >{data.Title}</Typography>}
          subheader={<i style={{color: "white"}}>{Languages.permLastDayToAnswer} {data.Sent}</i>}
          action={
            <IconButton 
          id="test_closeSinglePermQueryView_btn" 
          style={{display: "inline-block", float: "right"}} 
          size="medium" 
          aria-label={Languages.permBackTo}
          title={Languages.permBackTo}
          onClick={closeSinglePermView}>
          <Typography color="primary" >
            <CloseIcon sx={{color: "white"}} data-testid="closeBtn-osdih3lknsd"/>
          </Typography>
        </IconButton>
          }
          />
        
        <CardContent>
          <Typography  sx={{wordWrap: "break-word", whiteSpace: "pre-line", color: "black"}}>{data.MessageContent}</Typography>
        </CardContent>
        <CardActions sx={{backgroundColor: "#4377BA"}}>
          {data.IsValid ? 
            <div style={{display: "block", width: "100%", backgroundColor: "white"}}>
              <ChildListPermissionQuery2 
                data={data} 
                sendPermissions={(permissions) => sendPermissions(permissions)} 
                postIsLoading={postIsLoading}
              />
            </div>
          :
            <div style={{backgroundColor: "#4377BA", width: "100%"}}>
              <div style={{display: "inline-flex", }}>
              <LockIcon fontSize="large"/>&nbsp;
              <Typography sx={{padding: "5px"}}>{Languages.permCannotAnswerAnymore}</Typography>
              </div>
              <br />
              <hr />
              <h5>{Languages.permsPermitsGiven}</h5>
              <PermQueryLockedStatus 
                data={data} 
              />
            </div>
          }
        </CardActions>
        </Card>
    </React.Fragment>
  )
}

export default SinglePermQueryView;
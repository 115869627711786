import { types } from './actionTypes';

const NTSummaryReducer = (state, action) => {
  //const payload = action.data;

  switch(action.type) {

    case types.LOADING_NT_SUMMARY:
      return {
        ...state,
          NT: 
            {
              status: types.LOADING_NT_SUMMARY,
            }
        }

    case types.NT_SUMMARY_LOAD_SUCCESS:
      return {
        ...state,
          NT: 
          {
          status: "NT_SUMMARY_LOAD_SUCCESS",
          Code: action.Code,
          overbookingWarning: action.data.OneOrMoreChildrenOverBookingWarning,
          ListChildStats: action.data.ListChildStats,
          }
      }
    case types.NT_SUMMARY_LOAD_FAILED:
      return {
        ...state,
          NT: {
            status: "NT_SUMMARY_LOAD_FAILED",
            Code: action.data.Code !== undefined ? action.data.Code : 777
          }
      }

    case types.LOADING_INVOICE_SUMMARY_TABLE:
      return {
        ...state,
        InvoiceBasisData: {
            status: types.LOADING_INVOICE_SUMMARY_TABLE,
            Code: undefined,
            data: undefined
        }
      }

    case types.INVOICE_SUMMARY_TABLE_LOAD_SUCCESS:
      console.log("INVOICE SUMMARY TABLE DATA, action", action);
      return {
        ...state,
        InvoiceBasisData: {
          status: types.INVOICE_SUMMARY_TABLE_LOAD_SUCCESS,
          Code: action.Code,
          data: action.data
        }
      }

    case types.INVOICE_SUMMARY_TABLE_LOAD_FAILED:
      return {
        ...state,
        InvoiceBasisData: {
          status: types.INVOICE_SUMMARY_TABLE_LOAD_FAILED,
          Code: action.data.Code,
          data: undefined
        }
      }

    default:
  }
    
  return state;
}


export default NTSummaryReducer;

import "../../childinfo.css";
import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import { useStateValue } from "../../../../State";
import { types } from '../../../../Reducers/actionTypes';
import Languages from "../../../../translations";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import PlacementInfo from "../PlacementInfo";
import ChildFiles from "../../ChildFiles/ChildFiles";
import MunicipalPermissionQs from "../../../../Layouts/MunicipalPermissionQuestions/MunicipalPermissionQs";
import BackupParentInfo from "./BackupParentInfo";
import ParentsInfo from "./ParentsInfo";
import AllergyInfo from "./AllergyInfo";
import PriorityPhoneNumbers from "./PriorityPhoneNumbers";
import { validatePhoneNumber } from "./";

const styles = {
  color: "#4377BA",
  fontSize: "18pt",
  fontWeight: "bold",
}

const useStyles = makeStyles({
  loadingContainer: {
    justifyContent: 'center', // aligin child elements horizontally center
    alignItems: 'center', // align child elements vertically center
    height: '90%',
    display: 'flex', // enables alignItems, justifyContent
  },
})

const ChildBasicInfo = ({ childId, childColor }) => {
  const { state: { children } } = useStateValue();
  const classes = useStyles();
  const { childInfoState } = children;

  if (childInfoState === types.LOADING_CHILDINFO || childInfoState === types.SAVING_CHILDINFO || !childInfoState) {
    return (
      <>
      <hr />
        <Typography id="test_ChildInfo_BasicInfo" variant="h6" component="h2" style={styles}>{Languages.ciBasicInfo}</Typography>
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      </>
    )
  }

  return (
    <>
    <hr />
      <Typography id="test_ChildInfo_BasicInfo" variant="h6" component="h2" style={styles}> {Languages.ciBasicInfo}</Typography>
      <div>
        <PriorityPhoneNumbers childId={childId} childColor={childColor}/>
        <AllergyInfo childId={childId} childColor={childColor}/>
        <ParentsInfo childId={childId} childColor={childColor}/>
        <BackupParentInfo childId={childId} childColor={childColor}/>
        <PlacementInfo oneChild={true} childId={childId} childColor={childColor}/>
        <ChildFiles childId={childId} childColor={childColor}/>
        <MunicipalPermissionQs childId={childId} childColor={childColor} />
      </div>
      <br />
    </>
  );
}

export default ChildBasicInfo;
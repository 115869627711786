import React from "react";
import Typography from "@mui/material/Typography";
import TextField from '@mui/material/TextField';

import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import Languages from "../../../../translations";
import { SessionManager } from '../../../../Auth/';

const ParentsInfoReadOnly = ({ isEmailServiceEnabled, formStatus, parents }) => {
  //const { state: { children } } = useStateValue();
  //const { ChildInfo } = children;
  
  //const parents = ChildInfo.Info.ParentsInfo.filter(parent => { return parent.Type !== "PB"; });
  if (!parents) {
    return (
      <CircularProgress />
    )
  }

  return (
    <Typography component={'span'}>
      {parents.map((parent, parentIndex) => {

        const invalidPhoneNumberFound = formStatus.find(status => status.field === "ParentPhoneNumber" && status.personId === parent.personId && status.valid === false);

        return (
          <div key={parent.personId}>
            <Divider></Divider>
            <br></br>

            {invalidPhoneNumberFound && parentIndex === 0 ?
              <div>
                <TextField error={true}
                  name={"ParentPhoneNumberErrorInfoText"}
                  key={"ParentPhoneNumberErrorInfoText"}
                  id="outlined-read-only-input"
                  InputProps={{ readOnly: true, disableunderline: "true", className: "marginTop"/*, attrid: pn.ID*/ }}
                  type="text"
                  helperText={Languages.ciInfo1} />
                <br></br><br></br>
              </div>
              : null
            }

            <div className="row">
              <div className="col-4"><p>{Languages.ciName}</p></div>
              <div className="col-8">{parent.Name === null ? "" : <p>{parent.Name}</p>}
            </div>

              <div className="col-4">
                {Languages.ciShortPhoneNumber}
              </div>

              <div className="col-8">
                <div>{parent.ParentPhoneNumber?.Number}</div>
              </div>

              <div className="col-4">
                {Languages.ciShortWorkNumber}
              </div>

              <div className="col-8">
                <div>{parent.ParentWorkNumber?.Number}</div>
              </div>

              <div className="col-4">
                {parent.ParentEmail !== null ? <p>{Languages.ciShortEmailAddress}</p> : null}
              </div>

              <div className="col-8" style={{wordBreak: "break-word"}}>
                <p>{parent.ParentEmail !== null ? parent.ParentEmail : ""}</p>
              </div>

              <div className="col-4">
                <p>{Languages.ciAddressStreet}</p>
                <p>{Languages.ciAddressPostalcode}</p>
                <p>{Languages.ciAddressCity}</p>
              </div>

              <div className="col-8">
                <div>
                  <p>{parent.ParentStreetAddress !== null ? parent.ParentStreetAddress : ""}</p>
                  <p>{parent.ParentPostalCode !== null ? parent.ParentPostalCode : ""}</p>
                  <p>{parent.ParentCity !== null ? parent.ParentCity : ""}</p>
                </div>
              </div>

              <div className="col-4">
                <p>{Languages.allowViewLabel}</p>
              </div>

              <div className="col-8">
                {/*<FormControlLabel
                  control={<Switch disabled checked={parent.ParentAllowView} />}
                  label={""}
                  labelPlacement="end"
                />*/}
                <Typography component="span">{parent.ParentAllowView ? Languages.allowed : Languages.notAllowed}</Typography>
              </div>
            </div>

          </div>
        );
      })}
    </Typography>
  );
}

export default ParentsInfoReadOnly;
import React from 'react';
import Fab                    from "@mui/material/Fab";
import SaveIcon               from "@mui/icons-material/Save";
import Languages              from "../../../translations";


const SaveChildPermPermsButton = ({saveChanges, showButton}) => {
      return(        
          <Fab
            disabled={!showButton}
            variant="extended"
            color="secondary"
            aria-label="save-child-permissions"
            onClick={saveChanges}
            style={{marginBottom:"15px"}}
          >
            <SaveIcon/>&nbsp;&nbsp;{Languages.permSaveChildPermissions}
          </Fab>
      )
}

export default SaveChildPermPermsButton;
import React, { Component }     from  "react";
import { StateContext}          from  "../../../State";
import ContentBoxMUI            from  "../../../Components/ContentBoxMUI"; 
import { downloadBase64File }   from  '../../../utils';
import PedagDocImageViewManager from  "./PedagDocImageViewManager";
import                                './pedagdocimage.css';


class PedagDocImage extends Component {

  static contextType = StateContext;

  componentDidMount() {
    window.scrollTo(0, 0);
    this.context.actions.triggerLoadImage({ChildId: this.props.location.state.childId, Id: this.props.location.state.Id});
  }

  tryReloadImage = () => {
    this.context.actions.triggerLoadImage({ChildId: this.props.location.state.childId, Id: this.props.location.state.Id});
  }

  downloadImage = () => {
    downloadBase64File(this.context.state.growthFolder.image[0].Data, this.context.state.growthFolder.image[0].MimeType, this.context.state.growthFolder.image[0].FileName)
  }

  render(){
    const {growthFolder} = this.context.state;
    const pedagDocImageFunctions = {
      tryReloadImage: this.tryReloadImage,
      downloadImage: this.downloadImage,
    };

    return (
      <ContentBoxMUI>
        <PedagDocImageViewManager 
          errorCode = {growthFolder.errorCode}
          growthFolder = {growthFolder}
          pedagDocImageFunctions = {pedagDocImageFunctions}
        />
      </ContentBoxMUI>
    );
  }
}

export default PedagDocImage;
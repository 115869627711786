import React, { /*useState,*/ useEffect } from "react";
import {useStateValue} from "../../State";
import "./vasu.css";
import { Typography }    from "@mui/material";
import Divider        from '@mui/material/Divider';
import Box              from '@mui/material/Box'
import Chip from '@mui/material/Chip';

const CommentsReadOnly = (props) => {

  const { state/*, actions*/ } = useStateValue();
  const { Title1, Title2, Title3, BgColor, Data } = props;

  //console.log("CommentsReadOnly. Mount: props: ", props);

  return (
    <div className="CommentsReadOnly">
       {Title1 === null ? null :
        <Divider textAlign="left">
          <Chip label={Title1 === null ? null : <h6 >{Title1}</h6> } variant="outlined"/>
        </Divider>
      }
      
      {Data.Text === null ? null :
        <div>
          <Box className="col-12" sx={{ backgroundColor: BgColor, borderRadius: 2, boxShadow: 2, whiteSpace: "pre-line", overflowWrap: 'break-word', mt: 2, pt: 3, pb: 3}}>
            <Typography sx={{textAlign:"left", fontWeight: 'Medium', fontSize: 16, color: "#424242", pt:1, ml:1}}>{Data.Text} </Typography>
          </Box>
          
        </div>
      }
    </div>

    /*<div className="CommentsReadOnly">
    {Title1 === null ? null : <h6 className="textLeft mt-2" style={{whiteSpace: "pre-wrap"}}>{Title1}</h6> }
    {Title2 === null ? null : <h6 className="textLeft mt-2">{Title2}</h6> }
    {Title3 === null ? null : <h6 className="textLeft mt-2">{Title3}</h6> }
    <Divider />
    {Data.Text === null ? null :
      <div className="col-12 boxed" style={{backgroundColor: BgColor, fontWeight: "Bold", whiteSpace: "pre-line", overflowWrap: 'break-word'}} >
        {Data.Text}
      </div>
    }
  </div>*/
  );
}

export default CommentsReadOnly;

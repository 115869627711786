import React, { useState, useCallback, useEffect } from "react";
// import './onechilditem.css';
import { Card, CardContent, CardActions, Typography/*, Button*/ } from "@mui/material";
import VASUIcon from '../../../../images/vasuicon.png';
import KasvunkansioIcon from '../../../../images/growthfolder.png';
import LEOPSIcon from '../../../../images/leops.png';
//import ButtonGroup from '@mui/material/ButtonGroup'
import IconButton from '@mui/material/IconButton';
import Languages from "../../../../translations";
import ProfilePicture from "../../../../Components/ProfilePicture";
import makeStyles from '@mui/styles/makeStyles';
import InfoModal from "../../../../Components/Modals/InfoModal";
import { history } from "../../../../Router";
import { useStateValue } from "../../../../State";
import GrowthFolder from "../../../GrowthFolder";


const getChildBgColor = (childColor) => {
  const colors = {
    "#4377BA": () => ("#64CAF9"),
    "#81C784": () => ("#79BD7C"),
    "#FFCA28": () => ("#EAB726"),
    "#9588E2": () => ("#7E75D3"),
    "#83E2B5": () => ("#7CCEA1"),
    "#AAEEF9": () => ("#94E2EA"),
    "#DB9595": () => ("#CE8686"),
    "#00C9FF": () => ("#03BBE5"),
    "#20CE20": () => ("#1AC11A"),
    "#FF9D00": () => ("#EA8B03"),
    "#7F4EDD": () => ("#6C45CE"),
    "#36FFA8": () => ("#2FF49A"),
    "#73E0F9": () => ("#64D6E2"),
    "#DD5252": () => ("#D34848"),
    "#C0EAF9": () => ("#A7DDEA"),
    "#B1DDB1": () => ("#A1D1A1"),
    "#FFE18D": () => ("#EFCD78"),
    "#BEBAE2": () => ("#A9A7D3"),
    "#CFFFE8": () => ("#B0EDCE"),
    "#6BC0CE": () => ("#62ADB5"),
    "#E2BABA": () => ("#D1A1A1"),
    "default": () => ("#4377BA"),
  }
  let fn;

  if (colors[childColor]) {
    fn = colors[childColor]
  } else {
    fn = colors["default"];
  }
  return fn();
};


const useStyles = makeStyles((theme) => ({
  cardRoot: ({ childColor }) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    // height: '100%',
    backgroundColor: childColor,
  }),
  profile: () => ({
    padding: '0 0 0 0',
    flexGrow: 1,
    flexBasis: '50%',
  })
}));

const OneChildItem = ({LeopsId,childId, VasuId, childColor, profilepicture, nextNursingTimeTime, nextNursingTimePL }) => {
 
  const classes = useStyles({ childColor });
  const [ShowNote, setShowNote] = useState(false);
  const [NoteText, setNoteText] = useState(null);
  const [NoteTitle, setNoteTitle] = useState(null);
  const { state,  actions  } = useStateValue();
  const { settings } = state;
  const VasuData = state.vasu;
  const LeopsData = state.leops;
  const GrowthFolderData = state.growthFolder;
  //console.log("nextiänursaria " + {nextNursingTimeTime});

  const getGrowthfolderEnabledSetting = () => {
    var growthFolderEnabled = settings.communeSettings.find(setting => setting.Key === "DaisyFamily/ShowGrowthfolder");
    if(growthFolderEnabled != null && typeof growthFolderEnabled != undefined && growthFolderEnabled.ValueBool === true) {
      return true;
    }
    else {
      return false;
    }
  }

  const openGrowthFolder = useCallback(() => {
    GrowthFolderData.state = "LOADING_FOLDERS";
    history.push("/growthfolder", {
      Id: childId });
  });

  const onOpenVasu = useCallback(() => {
    VasuData.state = "INITIAL_STATE";
    history.push("/vasu/"+childId);
  }, [state.childId]);

  const onOpenLeops = useCallback(() => {
    //console.log("ONECHILD ITEM. Open leops");
    LeopsData.state = "INITIAL_STATE";
    history.push("/leops/"+childId);
  }, [childId]);

  const onCloseNote = () => {
    setShowNote(false);
  }

  const  doNothing = (status) => {
    //console.log("SlideConfirmationNote. handleRightButtonn: ", status);
  };


  return (
    <React.Fragment>
      <InfoModal modalOpen={ShowNote} headerText={NoteTitle} onClose={onCloseNote} allowClose={true}>
        <Typography>{NoteText}</Typography>
      </InfoModal>
      

      <Card className={classes.cardRoot}>
        <CardContent className={classes.profile}>
          <ProfilePicture profilepicture={profilepicture} />
        </CardContent>

        {/* <CardContent>
          {nextNursingTimeTime === null ? null
            :
            <div>
              <Typography gutterBottom variant="body2" color="textPrimary" component="p"> {Languages.bookingNextBooked}:</Typography>
              <br></br>
              <Typography variant="body1" color="textPrimary"> {nextNursingTimeTime}
                <br></br>
                {nextNursingTimePL}
              </Typography>
            </div>
          }
        </CardContent> */}

        <CardActions
          className="text-right"
          style={{ backgroundColor: getChildBgColor(childColor) }}
        >

          {getGrowthfolderEnabledSetting() ?
          <div>
          <IconButton id={"test_ChildIngo_GFIcon_"+childId} size="small" color="secondary" variant="outlined" onClick={openGrowthFolder}>
            <img id="test_ChildInfo_PedagogyIco" src={KasvunkansioIcon} alt={"Kasvunkansio"} height="40px"></img>
          </IconButton>
          </div>
          :
          <div>
          <IconButton disabled={true} style={{opacity: 0.5}} size="small" color="secondary" variant="outlined">
            <img id="test_ChildInfo_PedagogyIco" src={KasvunkansioIcon} alt={"Kasvunkansio"} height="40px"></img>
          </IconButton>
          </div>
          }
          <IconButton
            id={"test_ChildInfo_LeopsIco"}
            style={{ opacity: LeopsId === null ? "0.2" : "" }}
            disabled={LeopsId === null ? true : false}
            onClick={LeopsId === null ? doNothing : onOpenLeops}
            size="large">
            <img src={LEOPSIcon} alt={"LEOPS"} ></img>
          </IconButton>
          <IconButton
            id={"test_ChildInfo_VaSuIco"}
            style={{ opacity: VasuId === null ? "0.2" : "" }}
            disabled={VasuId === null ? true : false}
            onClick={VasuId === null ? doNothing : onOpenVasu}
            size="large">
            <img src={VASUIcon} alt={"VASU"}></img>
          </IconButton>
        </CardActions>
      </Card>
    </React.Fragment>
  );


}

export default OneChildItem;
import React                              from  'react';
import PedagDocImageFolderEmpty           from  './PedagDocImageFolderEmpty';
import PedagDocImageFolderFixedBottomBar  from  './PedagDocImageFolderFixedBottomBar';
import Languages                          from  "../../../../translations";
import                                          '../pedagdocimagefolder.css';


const PedagDocImageFolderSuccess = ({ imagefolderState, growthfolderState, imageFolderFunctions, imageFolderInformation }) => {

  if (typeof imagefolderState       === "undefined" || imagefolderState       === null || 
      typeof imageFolderFunctions   === "undefined" || imageFolderFunctions   === null || 
      typeof growthfolderState      === "undefined" || growthfolderState      === null ||
      typeof imageFolderInformation === "undefined" || imageFolderInformation === null) {

    return(<div> {Languages.errorUnknown + "(777)."} </div> );
  }

  else if (growthfolderState.images.length === 0){
    return(
      <PedagDocImageFolderEmpty />  
    );
  }    
  
  else {
    return(
      <div className="imagefolder__container" title = "pedagdoc-image-folder-container">
        {imageFolderInformation.Description ?
        <div className="imagefolder__desc">
          {imageFolderInformation.Description}
        </div>
        :
        null}
        <hr/>
        <div className="imagefolder__imagegrid__container">
          <div className="imagefolder__grid" title="PD_image_grid">
            {growthfolderState.images.map(imageFolderFunctions["renderImageThumbnail"])}
          </div>
        </div>
        <PedagDocImageFolderFixedBottomBar
          imageFolderFunctions = {imageFolderFunctions}
          imagefolderState = {imagefolderState}>
        </PedagDocImageFolderFixedBottomBar>
      </div>    
    );
  }
}

export default PedagDocImageFolderSuccess;
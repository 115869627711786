import React, { useEffect, useState } from "react";

import { useStateValue } from "../../../../State";
import Languages from "../../../../translations";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";

import IconButton from "@mui/material/IconButton";
import moment from "moment";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ChildProfilePicture from "../../../ChildProfilePicture";
import CloseIcon from "@mui/icons-material/Close";

import CheckIcon from "@mui/icons-material/Check";

import Divider from "@mui/material/Divider";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import DialogActions from "@mui/material/DialogActions";

import Chip from "@mui/material/Chip";

import { types } from "../../../../Reducers/actionTypes";

import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import CalendarDateSelectTabs from "../common/CalendarDateSelectTabs";
import CalendarTimeSlider from "../common/CalendarTimeSlider";
import CalendarChildSelection from "../common/CalendarChildSelection";
import CalendarCentreSelection from "../common/CalendarCentreSelection";
const CareTimeCalendarDeleteDialog = ({
  handleClick,
  newResDialogOpen,
  names,
  personName,
  handleFocusDay,
  LoadCareTimesCuick,
}) => {
  const { state, actions } = useStateValue();
  const deleted = state.caretimes.Deleted;
  const [saving, setSaving] = React.useState(false);
  const [saved2, setSaved2] = React.useState(false);
  const [holidays, setHolidays] = React.useState([]);

  const [resCount, setResCount] = React.useState(0);
  useEffect(() => {
    switch (state.caretimes.Quick.state) {
      case undefined:
        break;
      case types.CARETIMES_QUICK_LOAD_OK:
        setExtRes(state.caretimes.Quick.DayList);
        setHolidays(state.caretimes.Quick.HolidayList);
        break;
      case types.CARETIMES_QUICK_LOAD_FAILED:
        break;
      default:
        break;
    }
  }, [state.caretimes.Quick.state]);

  useEffect(() => {
    switch (state.caretimes.Deleted.state) {
      case types.CARETIMES_DELETED_OK:
        setSaving(false);
        setSaved2(true);
        break;
      case types.DELETING_CARETIMES:
        setSaving(true);
        break;
      case types.CARETIMES_DELETED_FAILED:
        setSaving(false);
        setSaved2(true);
        break;
      default:
        break;
    }
  }, [state.caretimes.Deleted.state]);

  useEffect(() => {
    setExtRes(state.caretimes.Quick.DayList);
    setListOfChildren(names.length == 1 ? names.map((child) => child.Id) : []);
    setDeleteOld("");
    setHighlightedDays([]);
    setClosePopUp(false);
    setResChildren([]);
    setExistSelected(false);
    setSaving(false);
    setSaved2(false);
  }, [newResDialogOpen]);

  //Reservation start/end times stored as minutes for the slider component
  const [value, setValue] = React.useState([
    moment.duration(moment("2020-01-01 08:00").format("HH:mm")).asMinutes(),
    moment.duration(moment("2020-01-01 16:00").format("HH:mm")).asMinutes(),
  ]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [existSelected, setExistSelected] = React.useState(false);

  const handleExistSelected = () => {
    setExistSelected(!existSelected);
  };
  //Start and end times stored as minutes
  const [ResLenght, setResLenght] = React.useState([
    moment.duration(moment("2020-01-01 08:00").format("HH:mm")).asMinutes(),
    moment.duration(moment("2020-01-01 16:00").format("HH:mm")).asMinutes(),
  ]);

  const handleResLenght = (newLenght) => {
    //console.log(moment(newLenght[0]));
    setResLenght(newLenght);
  };

  const [resChildren, setResChildren] = React.useState([]);

  const handleResChildren = (child, centre) => {
    if (resChildren.some((resChild) => resChild.childId === child)) {
      var updatedresChildren = resChildren.filter(
        (resChild) => resChild.childId !== child
      );
      updatedresChildren.push({ childId: child, resId: centre });
      setResChildren(updatedresChildren);
    } else {
      resChildren.push({ childId: child, resId: centre });
    }

    //console.log(resChildren);
  };

  //Handle selected Children
  const [listOfChildren, setListOfChildren] = React.useState(
    names.length == 1 ? names.map((child) => child.Id) : []
  );

  const handleChildren = (event, newPersonNames) => {
    setListOfChildren(newPersonNames);

    console.log(newPersonNames);
  };

  //Handle DeleteOld if existing res days selected
  const [DeleteOld, setDeleteOld] = React.useState("");

  const handleDeleteOld = (event, newReason) => {
    if (newReason !== null) {
      setDeleteOld(newReason);
    }
  };

  const [extRes, setExtRes] = React.useState([]);

  //Handle single selected days
  const [highlightedDays, setHighlightedDays] = React.useState([]);

  const handleHighLightedDays = (newDay) => {
    if (highlightedDays.some((day) => day.isSame(newDay, "day"))) {
      var updated = highlightedDays.filter(
        (day) => day.isSame(newDay, "day") == false
      );
      setHighlightedDays(updated);
    } else {
      highlightedDays.push(moment(newDay));
    }
    //console.log(highlightedDays);
    //console.log(extRes);
  };

  //Handle Week Selection
  const [startDate, setStartDate] = React.useState(null);

  const handleWeek = (date) => {
    setStartDate(date);
  };

  //Handle date Range

  const [startDay, setStartDay] = React.useState(null);
  const [endDay, setEndDay] = React.useState(null);

  const handleDayRange = (newDay) => {
    if (startDay == null) {
      setStartDay(newDay);
    } else {
      if (endDay == null) {
        if (newDay.isSameOrBefore(startDay)) {
          setStartDay(newDay);
        } else {
          setEndDay(newDay);
        }
      } else {
        setStartDay(newDay);
        setEndDay(null);
      }
    }
  };

  const checkExistingRes = () => {
    let ListDates = [];

    switch (mode) {
      case 0:
        ListDates = highlightedDays;
        break;
      case 1:
        for (
          var m = moment(startDate).startOf("week");
          m.isSameOrBefore(moment(startDate).endOf("week"));
          m.add(1, "days")
        ) {
          ListDates.push(moment(m));
        }
        break;
      case 2:
        for (
          var m = moment(startDay);
          m.isSameOrBefore(moment(endDay));
          m.add(1, "days")
        ) {
          if (moment(m).isoWeekday() !== 6 && moment(m).isoWeekday() !== 7) {
            ListDates.push(moment(m));
          }
        }
        break;
      default:
        console.log("mitä vittua");
        break;
    }
    return ListDates.some(
      (r) => extRes.indexOf(moment(r).format("YYYY-MM-DDTHH:mm:ss")) >= 0
    );
  };
  //Handle mode days/weeks/range for saving the dates
  const [mode, setMode] = React.useState(0);

  const handleMode = (value) => {
    //Clear all selections when changing mode // WIP -- Add confirmation if dates selected
    setHighlightedDays([]);
    setStartDay(null);
    setEndDay(null);
    setStartDate(null);
    setMode(value);
    setExistSelected(false);
  };

  //handle closing if user has made any actions popup

  const [closePopUp, setClosePopUp] = React.useState(false);
  const handleCloseClick = () => {
    if (
      listOfChildren.length > 0 ||
      DeleteOld !== "" ||
      highlightedDays.length > 0 ||
      startDate !== null ||
      startDay !== null ||
      endDay !== null
    ) {
      setClosePopUp(true);
    } else {
      handleClick();
    }
  };

  const handleClosePopUp = () => {
    setClosePopUp(!closePopUp);
  };

  const saveAbsent = () => {
    let ListDates = [];
    let ListIsoDates = [];
    switch (mode) {
      case 0:
        ListDates = highlightedDays;
        ListDates.forEach((m) =>
          ListIsoDates.push(moment(m).toISOString(true))
        );
        break;
      case 1:
        for (
          var m = moment(startDate).startOf("week");
          m.isSameOrBefore(moment(startDate).endOf("week"));
          m.add(1, "days")
        ) {
          ListDates.push(moment(m));
          ListIsoDates.push(moment(m).toISOString(true));
        }
        break;
      case 2:
        for (
          var m = moment(startDay);
          m.isSameOrBefore(moment(endDay));
          m.add(1, "days")
        ) {
          if (moment(m).isoWeekday() !== 6 && moment(m).isoWeekday() !== 7) {
            ListDates.push(moment(m));
            ListIsoDates.push(moment(m).toISOString(true));
          }
        }
        break;
      default:
        console.log("mitä vittua");
        break;
    }

    setResCount(ListDates.length * listOfChildren.length);
    handleFocusDay(moment.min(ListDates));

    actions.triggerDeleteCareTimes({
      ChildIds: listOfChildren,
      ListDatesToDelete: ListIsoDates,
      FocusDate: moment.min(ListDates).format("DD.MM.YYYY"),
    });
  };

  return (
    <Dialog
      disablePadding
      disableGutters
      fullWidth
      open={newResDialogOpen}
      sx={{
        "& .MuiDialog-container .MuiDialog-paper": {
          margin: 0,
          borderRadius: 2,
          width: "96%",
        },
        width: "100%",
      }}
    >
      <DialogTitle textAlign="center" style={{ backgroundColor: "#FFFFFF" }}>
        {!saved2 && !saving && (
          <Typography color="textSecondary" fontWeight="bold">
            {Languages.newCalendarDeleteCareTimes2}
          </Typography>
        )}

        {saving && (
          <Typography color="textSecondary" fontWeight="bold">
            {Languages.newCalendarDeletingCareTimes}
          </Typography>
        )}

        {saved2 &&
          state.caretimes.Deleted.state === types.CARETIMES_DELETED_OK && (
            <Typography color="textSecondary" fontWeight="bold">
              {Languages.newCalendarCareTimesDeletedSuccess2}
            </Typography>
          )}

        {saved2 &&
          state.caretimes.Deleted.state === types.CARETIMES_DELETED_FAILED && (
            <Typography color="textSecondary" fontWeight="bold">
              {Languages.newCalendarDeletingCareTimesFailed2}
            </Typography>
          )}

        {!saving && (
          <IconButton
            aria-label="close"
            onClick={saved2 ? handleClick : handleCloseClick}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "success",
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent
        dividers
        disableGutters
        disablePadding
        sx={{ padding: 0 }}
        style={{ backgroundColor: "#FFFFFF" }}
      >
        {saving === true || saved2 === true ? (
          <>
            <Stack spacing={2} alignItems="center" sx={{ mt: 2, mb: 2 }}>
              <ProgressBar
                saving={saving}
                saved={saved2}
                count={resCount}
                handleClick={handleClick}
              ></ProgressBar>
            </Stack>
          </>
        ) : (
          <Box sx={{ mb: 2 }}>
            <div>
              <>
                <Divider
                  sx={{
                    m: 2,
                    "&::before, &::after": {
                      borderColor: "#0074C8",
                    },
                  }}
                  textAlign="center"
                >
                  <Chip
                    label={"1. " + Languages.newCalendarSelectChildren}
                    sx={{ backgroundColor: "#0074C8" }}
                  />
                </Divider>
                <CalendarChildSelection
                  names={names}
                  listOfChildren={listOfChildren}
                  handleFormat={handleChildren}
                ></CalendarChildSelection>
              </>

              <>
                <Divider
                  sx={{
                    m: 2,
                    "&::before, &::after": {
                      borderColor: "#0074C8",
                    },
                  }}
                  textAlign="center"
                >
                  <Chip
                    label={"2. " + Languages.newCalendarSelectDates}
                    sx={{ backgroundColor: "#0074C8" }}
                  />
                </Divider>

                <CalendarDateSelectTabs
                  handleHighLightedDays={handleHighLightedDays}
                  highlightedDays={highlightedDays}
                  handleMode={handleMode}
                  handleDayRange={handleDayRange}
                  startDay={startDay}
                  endDay={endDay}
                  startDate={startDate}
                  handleWeek={handleWeek}
                  shortestLockTime={moment(state.children.ShortestLockTime)}
                  LoadCareTimesCuick={LoadCareTimesCuick}
                  allowWeekEnds={true}
                ></CalendarDateSelectTabs>
              </>
            </div>
          </Box>
        )}
      </DialogContent>
      {!saving && !saved2 && (
        <DialogActions
          sx={{
            width: "100%",
            margin: 0,
            backgroundColor:
              listOfChildren.length < 1 ? "#8c8c8b" : "success.main",
          }}
          style={{ justifyContent: "center", padding: 0 }}
        >
          <Button
            sx={{
              width: "100%",
              height: "55px",
              margin: 0,
              backgroundColor: "success.main",
              "&:disabled": { backgroundColor: "#8c8c8b" },
            }}
            variant="contained"
            onClick={saveAbsent}
            disabled={
              listOfChildren.length < 1 ||
              (mode === 0 && highlightedDays.length < 1) ||
              (mode === 1 && startDate === null) ||
              (mode === 2 && (startDay === null || endDay === null))
                ? true
                : false
            }
          >
            {Languages.newCalendarDeleteCareTimes}
          </Button>
        </DialogActions>
      )}

      <Dialog onClose={handleClosePopUp} open={closePopUp}>
        <DialogTitle textAlign="center" style={{ backgroundColor: "#FFFFFF" }}>
          <Typography color="textSecondary" fontWeight="bold">
            {Languages.newCalendarAreYouSureExit}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClosePopUp}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "success",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ backgroundColor: "#FFFFFF" }}>
          <Typography sx={{ mt: 2 }} color="textSecondary" fontWeight="bold">
            {Languages.newCalendarEditLoss}
          </Typography>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: "center",

            backgroundColor: "#FFFFFF",
          }}
        >
          <Button
            variant="contained"
            sx={{ backgroundColor: "#0074C8" }}
            onClick={handleClosePopUp}
          >
            {Languages.newCalendarCancel}
          </Button>
          <Button
            variant="contained"
            sx={{ backgroundColor: "#0074C8" }}
            onClick={handleClick}
          >
            {Languages.newCalendarExit}
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default CareTimeCalendarDeleteDialog;

const ProgressBar = (props) => {
  const { state, actions } = useStateValue();
  const saved = state.caretimes.CTSaved;

  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress >= 90) {
          return 90;
        }
        const diff = props.count / 100;
        return Math.min(oldProgress + diff, 100);
      });
    }, 100);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const [closeProgress, setCloseProgress] = React.useState(0);
  React.useEffect(() => {
    const timer = setInterval(() => {
      setCloseProgress((oldProgress) => {
        if (
          oldProgress >= 5 &&
          state.caretimes.Deleted.state === types.DELETED_SAVED_OK
        ) {
          props.handleClick;
        }
        return oldProgress + 1;
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [saved]);

  return (
    <Box sx={{ width: "80%" }}>
      {props.saving && !props.saved && (
        <>
          {/*<LinearProgress
            sx={{
              "& .MuiLinearProgress-colorPrimary": {
                backgroundColor: "#0074C8",
              },
              "& .MuiLinearProgress-barColorPrimary": {
                backgroundColor: "#0074C8",
              },
              "& .MuiLinearProgress-dashedColorPrimary": {
                backgroundImage:
                  "radial-gradient(#0074C8 0%, #0074C8 16%, transparent 42%)",
              },
            }}
            dashedColorPrimary="red"
            dashedColorSecondary="green"
            variant="buffer"
            value={progress}
            valueBuffer={progress}
          />
          
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
            sx={{ ml: `${progress - 10}%` }}
          >
            {Math.round((progress / 100) * props.count) + " / " + props.count}
          </Typography>
          
          */}
          <Stack spacing={1} alignItems="center">
            <CircularProgress></CircularProgress>
            <Typography color="textSecondary" fontSize="medium">
              {Languages.newCalendarDeletingCareTimes}
            </Typography>
          </Stack>
        </>
      )}

      {!props.saving && props.saved && (
        <>
          {state.caretimes.Deleted.state === types.CARETIMES_DELETED_FAILED && (
            <>
              <Stack spacing={1} alignItems="center">
                <Typography color="textSecondary" fontSize="medium">
                  {Languages.newCalendarDeletingCareTimesFailed}
                </Typography>

                <Button
                  variant="contained"
                  sx={{ backgroundColor: "#0074C8" }}
                  onClick={props.handleClick}
                >
                  {Languages.newCalendarClose}
                </Button>
              </Stack>
            </>
          )}

          {state.caretimes.Deleted.state === types.CARETIMES_DELETED_OK && (
            <>
              <Stack spacing={1} alignItems="center">
                <Typography color="textSecondary" fontSize="medium">
                  {Languages.newCalendarCareTimesDeletedSuccess}
                </Typography>
                <AutoClose handleClick={props.handleClick}></AutoClose>
                <Button
                  variant="contained"
                  sx={{ backgroundColor: "#0074C8" }}
                  onClick={props.handleClick}
                >
                  {Languages.newCalendarClose}
                </Button>
              </Stack>
            </>
          )}
        </>
      )}
    </Box>
  );
};

const AutoClose = (props) => {
  const [progress, setProgress] = React.useState(5);
  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress <= 0) {
          props.handleClick();
        }
        return oldProgress - 1;
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box sx={{ width: "80%" }}>
      <Typography color="textSecondary" fontSize="medium">
        {Languages.newCalendatAutomaticClose +
          " " +
          progress +
          Languages.newCalendarAfterSeconds}
      </Typography>
    </Box>
  );
};

import React, { useState } from "react";
import Languages from "../../translations";
import GuideModal from "./GuideModal";
import HelpButton from "./GuideTools/HelpButton";
import ScrollToTopButton from "./GuideTools/ScrollToTopButton";

const GuideModalsManager = ({ modalType, errorCode, modalTitle }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleClick = () => {
    setModalOpen(!modalOpen);
  };

  function returnCorrectGuideModal(modalType) {
    const guideModalOptions = {
      BOOKING_GUIDE: function () {
        return (
          <>
            {/*<HelpButton handleClick={handleClick}/>*/}
            <GuideModal
              modalOpen={modalOpen}
              modalType={modalType}
              modalTitle="Hoitoajan varauskalenteri"
              modalKeywords="Ei valmis"
              modalContent="Ei valmis"
              handleClick={handleClick}
            />
          </>
        );
      },

      CARETIME_GUIDE: function () {
        return (
          <>
            {/*<HelpButton handleClick={handleClick}/>*/}
            <GuideModal
              modalOpen={modalOpen}
              modalType={modalType}
              modalTitle="Uusi Hoitoajan varauskalenteri"
              modalKeywords="Ei valmis"
              modalContent="Ei valmis"
              handleClick={handleClick}
            />
          </>
        );
      },

      MESSAGING_GUIDE: function () {
        return (
          <>
            {/*<HelpButton handleClick={handleClick}/>*/}
            <GuideModal
              modalOpen={modalOpen}
              modalType={modalType}
              modalTitle={modalTitle}
              modalKeywords="Ei valmis"
              modalContent="Ei valmis"
              handleClick={handleClick}
            />
          </>
        );
      },

      PERMISSION_GUIDE: function () {
        return (
          <>
            {/*<HelpButton handleClick={handleClick}/>*/}
            <GuideModal
              modalOpen={modalOpen}
              modalType={modalType}
              modalTitle={modalTitle}
              modalKeywords="Ei valmis"
              modalContent="Ei valmis"
              handleClick={handleClick}
            />
          </>
        );
      },

      DISCUSSION_GUIDE: function () {
        return (
          <>
            {/*<HelpButton handleClick={handleClick}/>*/}
            <GuideModal
              modalOpen={modalOpen}
              modalType={modalType}
              modalTitle={modalTitle}
              modalKeywords="Ei valmis"
              modalContent="Ei valmis"
              handleClick={handleClick}
            />
          </>
        );
      },

      default: function () {
        return <div>{Languages.errorUnknown + "(777)."}</div>;
      },
    };

    return (guideModalOptions[modalType] || guideModalOptions["default"])();
  }

  return (
    <>
      <HelpButton handleClick={handleClick} />
      {returnCorrectGuideModal(modalType)}
    </>
  );
};

export default GuideModalsManager;

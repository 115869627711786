import { types } from "./actionTypes";

const SettingsReducer = (state, action) => {
  const payload = action.data;

  switch (action.type) {
    case types.SETTINGS_HANDLED:
      //console.log('SETTINGS REDUCER. HANDLED SETTINGS payload: ', payload);
      return {
        ...state,
        state:
          payload === "SAVE_HANDLED_PASSWORD"
            ? types.SAVE_HANDLED_PASSWORD
            : types.SETTINGS_HANDLED,
        //  Settings: { state: payload === "SAVE_HANDLED_PASSWORD" ? types.SAVE_HANDLED_PASSWORD : types.SETTINGS_HANDLED }
      };
    case types.SAVING_PASSWORD:
      //console.log('SETTINGS REDUCER. SAVING PASSWORD');
      return {
        ...state,
        state: types.SAVING_PASSWORD,
      };
    case types.SAVE_SUCCESS_PASSWORD:
      //console.log('SETTINGS REDUCER. SAVE PASSWORD SUCCESS payload: ', payload);
      return {
        ...state,
        state: types.SAVE_SUCCESS_PASSWORD,
      };
    case types.SAVE_FAILED_PASSWORD:
      //console.log('SETTINGS REDUCER. SAVE PASSWORD FAILED payload: ', payload);
      return {
        ...state,
        Code: payload.Code,
        state: types.SAVE_FAILED_PASSWORD,
      };

    case types.LOAD_COMMUNESETTINGS_SUCCESS:
      return {
        ...state,
        communeSettings: payload,
      };

    case types.LOAD_COMMUNESETTINGS_FAILED:
      return {
        ...state,
        state: types.LOAD_COMMUNESETTINGS_FAILED,
        Code: payload.Code,
      };

    case types.LOAD_PERSON_SETTINGS_SUCCESS:
      return {
        ...state,
        personSettings: {
          ...parentsSettingsReducer(state.personSettings, action),
          status: action.type,
        },
      };
    case types.LOAD_PERSON_SETTINGS_FAILED:
      return {
        ...state,
        personSettings: {
          ...state.personSettings,
          status: action.type,
        },
      };
    case types.LOADING_PERSON_SETTINGS:
      return {
        ...state,
        personSettings: {
          ...state.personSettings,
          status: action.type,
        },
      };
    case types.SAVING_PERSON_SETTINGS:
      return {
        ...state,
        personSettings: {
          ...state.personSettings,
          status: action.type,
        },
      };
    case types.SAVE_PERSON_SETTINGS_SUCCESS:
      return {
        ...state,
        personSettings: {
          ...parentsSettingsReducer(state.personSettings, action),
        },
      };
    case types.SAVE_PERSON_SETTINGS_FAILED:
      return {
        ...state,
        personSettings: {
          ...state.personSettings,
          status: action.type,
        },
      };

    default:
      return state;
  }
};

const parentsSettingsReducer = (personSettings, action) => {
  let payload;

  if (action.data instanceof Array) {
    payload = action.data[0];
  } else {
    payload = action.data;
  }

  const result = {
    ...personSettings,
    allowEmailMessage: payload.NotificationsSettings.EmailEnabled,
    allowPushMessage: payload.NotificationsSettings.PushEnabled,
    EmailAddress: payload.HomeEmail,
    status: action.type,
  };

  return result;
};

export default SettingsReducer;

import React , { useState, useEffect, }from "react";
import Languages from "../../../translations";
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ChildProfilePicture from "../../../Components/ChildProfilePicture";
import SingleChildPermissionList from "../../PermissionQueries/Components/SingleChildPermissionList";
import GeneralPermDialog from "../GeneralPermDialog";
import ReportIcon from '@mui/icons-material/Report';






const ChildPermPermissionSummary2 = ({children, mainstats, profilePictures}) => {
const [showList, setShowList] = useState(true);
const [clickedChild, setClickedChild] = useState({});
const [editStatus, setEditStatus] = useState(false)
const [isVisible, setIsVisible] = useState(false)


useEffect(() => {
handleOutsideOpen(mainstats)
}, [mainstats.childToOpen]);

const handleOutsideOpen = (mainstats) => {
  if(mainstats.childToOpen != undefined) {
  setShowList(false)
  const clickedChildOutside = children.ChildList.find(child => child.Id === mainstats.childToOpen);
  setClickedChild(clickedChildOutside)
  setTimeout(scrollToChildPermissions, 600)
  mainstats.childToOpen = undefined
  }
  else null
}


  const closeGeneralPermDialog = () => {
    setIsVisible(false)
  }

  const handleOpen = (child) => {
    setShowList(false)
    const clickedChild = child
    console.log(clickedChild)
    setClickedChild(clickedChild)
    setTimeout(scrollToChildPermissions, 600)
  }

  const changeEditStatusOn = () => {
    setEditStatus(true)
  }

  const changeEditStatusOff = () => {
    setEditStatus(false)
  }

  const handleClose = () => {
    console.log(editStatus)
    if(editStatus === false) {
    setShowList(true)
    setClickedChild({})
    scrollPermissionTop()
    }
    else if(editStatus === true) {
      setIsVisible(true)
    }
  }

  const handleForceClose = () => {
    setShowList(true)
    setClickedChild({})
    scrollPermissionTop()
  }

  const scrollPermissionTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  const scrollToChildPermissions = () => {
    const section = document.querySelector("#child-permissions-header-2-scroll");
    section.scrollIntoView( { behavior: 'smooth', block: 'start' } )
  };

    if (!children || !children.ChildList || !mainstats || !mainstats.UnansweredChildPermPermissions) {
      return(null);
    }
    
  if(showList === true) {
    return(children.ChildList.map((child) => {  
      return(
        <ListItem button divider key={child.Id} id={child.Id} onClick={() => handleOpen(child)}
        secondaryAction={
            <ArrowForwardIosIcon color="secondary" />
        }   
        >
          <ListItemAvatar>
            <ChildProfilePicture childId={child.Id}/>
        </ListItemAvatar>
          {mainstats.UnansweredChildPermPermissions.map((numberPerChild) => {
            return(
              <div key={numberPerChild.ChildId}>
                {numberPerChild.ChildId === child.Id && (numberPerChild.UnansweredPermPerms === 0 
                  ?
                  <ListItemText primary={child.FirstName} secondary={Languages.permNoUnansweredPermissions} /> 
                  : 
                <div style={{display: "flex"}}>
                  <ListItemText primary={child.FirstName} secondary={numberPerChild.UnansweredPermPerms + ' ' + Languages.permUnansweredPerms} /> 
                  &nbsp;&nbsp;&nbsp;&nbsp;<ReportIcon sx={{color: "#EE5711", display: "inline", margin: "auto"}}/>
                                    </div>        
                )}
              </div>
            )
          })}
        </ListItem>              
      )     
    }))
  }
    else {
      return(
        <>
        <GeneralPermDialog isVisible={isVisible} closeGeneralPermDialog={closeGeneralPermDialog} handleForceClose={handleForceClose} changeEditStatusOff={changeEditStatusOff} />
        <SingleChildPermissionList handleClose={handleClose} child={clickedChild} changeEditStatusOn={changeEditStatusOn} changeEditStatusOff={changeEditStatusOff} mainstats={mainstats}/>
        </>
    )}
}
  
export default ChildPermPermissionSummary2;
import React, { useEffect, useState } from "react";

import { useStateValue } from "../../../../State";
import Languages from "../../../../translations";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";

import IconButton from "@mui/material/IconButton";
import moment from "moment";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ChildProfilePicture from "../../../ChildProfilePicture";
import CloseIcon from "@mui/icons-material/Close";

import CheckIcon from "@mui/icons-material/Check";

import Divider from "@mui/material/Divider";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import DialogActions from "@mui/material/DialogActions";

import Chip from "@mui/material/Chip";

import { types } from "../../../../Reducers/actionTypes";

import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import CalendarDateSelectTabs from "../common/CalendarDateSelectTabs";
import CalendarTimeSlider from "../common/CalendarTimeSlider";
import CalendarChildSelection from "../common/CalendarChildSelection";
import CalendarCentreSelection from "../common/CalendarCentreSelection";

const CareTimeCalendarNewAbsentDialog = ({
  handleClick,
  newResDialogOpen,
  names,
  personName,
  open,
  handleFocusDay,
}) => {
  const { state, actions } = useStateValue();
  const absent = state.caretimes.Absent;
  const [holidays, setHolidays] = React.useState([]);
  const [saving, setSaving] = React.useState(false);
  const [saved2, setSaved2] = React.useState(false);

  {
    /*useEffect(() => {
    switch (absent.state) {
      case types.CARETIMES_ABSENT_SAVED_OK:
        console.log(absent);
        actions.triggerAddToSnackBarQueue({
          message: "Poissaolon tallennus onnistui",
          severity: "success",
        });
        handleClick();

        break;
      case types.CARETIMES_ABSENT_SAVED_FAILED:
        console.log(absent);
        actions.triggerAddToSnackBarQueue({
          message: "Poissaolon tallennus epäonnistui",
          severity: "success",
        });
        handleClick();

        break;
      default:
        break;
    }
  }, [absent.state]);*/
  }

  useEffect(() => {
    switch (state.caretimes.Quick.state) {
      case undefined:
        break;
      case types.CARETIMES_QUICK_LOAD_OK:
        setHolidays(state.caretimes.Quick.HolidayList);
        break;
      case types.CARETIMES_QUICK_LOAD_FAILED:
        break;
      default:
        break;
    }
  }, [state.caretimes.Quick.state]);

  useEffect(() => {
    switch (absent.state) {
      case types.CARETIMES_ABSENT_SAVED_OK:
        setSaving(false);
        setSaved2(true);

        break;
      case types.SAVING_CARETIMES_ABSENT:
        setSaving(true);
        break;
      case types.CARETIMES_ABSENT_SAVED_FAILED:
        setSaving(false);
        setSaved2(true);
        break;
      default:
        break;
    }
  }, [absent.state]);

  useEffect(() => {
    console.log();
    setExtRes(state.caretimes.Quick.DayList);
    setAllowWeekends(false);
    setAllowSaturday(false);
    setAllowSunday(false);
    setIncludeWeekEnd("");
    setListOfChildren(names.length == 1 ? names.map((child) => child.Id) : []);
    setAbsentReason("");
    setHighlightedDays([]);
    setClosePopUp(false);
    setSaved2(false);
    setSaving(false);
  }, [newResDialogOpen]);

  //handle closing if user has made any actions popup

  const [closePopUp, setClosePopUp] = React.useState(false);
  const handleCloseClick = () => {
    if (
      listOfChildren.length > 0 ||
      absentReason !== "" ||
      highlightedDays.length > 0 ||
      startDate !== null ||
      startDay !== null ||
      endDay !== null
    ) {
      setClosePopUp(true);
    } else {
      handleClick();
    }
  };

  const handleClosePopUp = () => {
    setClosePopUp(!closePopUp);
  };

  //Handle selected Children
  const [listOfChildren, setListOfChildren] = React.useState(
    names.length == 1 ? names.map((child) => child.Id) : []
  );

  const handleChildren = (event, newPersonNames) => {
    console.log(newPersonNames);
    console.log(names);
    const filteredNames = names.filter((person) => {
      // Check if the person's Id is included in the newPersonNamesList
      return newPersonNames.includes(person.Id);
    });
    console.log(filteredNames);
    setAllowWeekends(
      filteredNames.some((child) =>
        child.Placements.some(
          (placement) =>
            (placement.Type === "ROUND_THE_CLOCK" ||
              placement.Type === "EXTENDED") &&
            ((placement.OpenType === "MONSUN" &&
              placement.OpenTimes === null) ||
              (placement.OpenType === "MONSUN" &&
                placement.OpenTimes !== null &&
                ((placement.OpenTimes.toLowerCase().includes("ma") &&
                  placement.OpenTimes.toLowerCase().includes("su")) ||
                  placement.OpenTimes.toLowerCase().includes("la") ||
                  placement.OpenTimes.toLowerCase().includes("su") ||
                  (placement.OpenTimes.toLowerCase().includes("la") &&
                    placement.OpenTimes.toLowerCase().includes("su")))))
        )
      )
    );
    setAllowSaturday(
      filteredNames.some((child) =>
        child.Placements.some(
          (placement) =>
            (placement.Type === "ROUND_THE_CLOCK" ||
              placement.Type === "EXTENDED") &&
            ((placement.OpenType === "MONSUN" &&
              placement.OpenTimes === null) ||
              (placement.OpenType === "MONSUN" &&
                placement.OpenTimes !== null &&
                (placement.OpenTimes.toLowerCase().includes("ma-su") ||
                  placement.OpenTimes.toLowerCase().includes("mon-sun") ||
                  placement.OpenTimes.toLowerCase().includes("må-sö") ||
                  placement.OpenTimes.toLowerCase().includes("la") ||
                  placement.OpenTimes.toLowerCase().includes("sat") ||
                  placement.OpenTimes.toLowerCase().includes("lö"))))
        )
      )
    );

    setAllowSunday(
      filteredNames.some((child) =>
        child.Placements.some(
          (placement) =>
            (placement.Type === "ROUND_THE_CLOCK" ||
              placement.Type === "EXTENDED") &&
            ((placement.OpenType === "MONSUN" &&
              placement.OpenTimes === null) ||
              (placement.OpenType === "MONSUN" &&
                placement.OpenTimes !== null &&
                (placement.OpenTimes.toLowerCase().includes("sun") ||
                  placement.OpenTimes.toLowerCase().includes("su") ||
                  placement.OpenTimes.toLowerCase().includes("sö"))))
        )
      )
    );
    setListOfChildren(newPersonNames);
    console.log(newPersonNames);
  };

  //Handle absent reason sick/absent
  const [absentReason, setAbsentReason] = React.useState("");

  const handleReason = (event, newReason) => {
    if (newReason !== null) {
      setAbsentReason(newReason);
    }
  };

  const [extRes, setExtRes] = React.useState([]);

  //Handle single selected days
  const [highlightedDays, setHighlightedDays] = React.useState([]);

  const handleHighLightedDays = (newDay) => {
    if (highlightedDays.some((day) => day.isSame(newDay, "day"))) {
      var updated = highlightedDays.filter(
        (day) => day.isSame(newDay, "day") == false
      );
      setHighlightedDays(updated);
    } else {
      highlightedDays.push(moment(newDay));
    }
  };

  //Handle Week Selection
  const [startDate, setStartDate] = React.useState(null);

  const handleWeek = (date) => {
    setStartDate(date);
  };
  //Handle date Range

  const [startDay, setStartDay] = React.useState(null);
  const [endDay, setEndDay] = React.useState(null);

  const handleDayRange = (newDay) => {
    if (startDay == null) {
      setStartDay(newDay);
    } else {
      if (endDay == null) {
        setEndDay(newDay);
      } else {
        setStartDay(newDay);
        setEndDay(null);
      }
    }
  };

  //Handle mode days/weeks/range for saving the dates
  const [mode, setMode] = React.useState(0);

  const handleMode = (value) => {
    //Clear all selections when changing mode // WIP -- Add confirmation if dates selected
    setHighlightedDays([]);
    setStartDay(null);
    setEndDay(null);
    setStartDate(null);
    setMode(value);
  };

  const saveAbsent = () => {
    let ListDates = [];

    switch (mode) {
      case 0:
        console.log("päivät");
        ListDates = highlightedDays;
        break;
      case 1:
        console.log("viikot");
        for (
          var m = moment(startDate).startOf("week");
          m.isSameOrBefore(moment(startDate).endOf("week"));
          m.add(1, "days")
        ) {
          ListDates.push(moment(m));
        }
        break;
      case 2:
        console.log("väli");
        for (
          var m = moment(startDay);
          m.isSameOrBefore(moment(endDay));
          m.add(1, "days")
        ) {
          ListDates.push(moment(m));
        }
        break;
      default:
        console.log("default");
        break;
    }

    //handleFocusDay(moment.min(ListDates));
    var personAbsent = [];

    listOfChildren.forEach((child) => {
      let childData = names.filter((person) => {
        return person.Id == child;
      })[0];
      let allowsaturday = childData.Placements.some(
        (placement) =>
          (placement.Type === "ROUND_THE_CLOCK" ||
            placement.Type === "EXTENDED") &&
          ((placement.OpenType === "MONSUN" && placement.OpenTimes === null) ||
            (placement.OpenType === "MONSUN" &&
              placement.OpenTimes !== null &&
              (placement.OpenTimes.toLowerCase().includes("ma-su") ||
                placement.OpenTimes.toLowerCase().includes("mon-sun") ||
                placement.OpenTimes.toLowerCase().includes("må-sö") ||
                placement.OpenTimes.toLowerCase().includes("la") ||
                placement.OpenTimes.toLowerCase().includes("sat") ||
                placement.OpenTimes.toLowerCase().includes("lö"))))
      );
      let allowsSunday = childData.Placements.some(
        (placement) =>
          (placement.Type === "ROUND_THE_CLOCK" ||
            placement.Type === "EXTENDED") &&
          ((placement.OpenType === "MONSUN" && placement.OpenTimes === null) ||
            (placement.OpenType === "MONSUN" &&
              placement.OpenTimes !== null &&
              (placement.OpenTimes.toLowerCase().includes("sun") ||
                placement.OpenTimes.toLowerCase().includes("su") ||
                placement.OpenTimes.toLowerCase().includes("sö"))))
      );
      let allowWeekEnd = childData.Placements.some(
        (placement) =>
          (placement.Type === "ROUND_THE_CLOCK" ||
            placement.Type === "EXTENDED") &&
          ((placement.OpenType === "MONSUN" && placement.OpenTimes === null) ||
            (placement.OpenType === "MONSUN" &&
              placement.OpenTimes !== null &&
              ((placement.OpenTimes.toLowerCase().includes("ma") &&
                placement.OpenTimes.toLowerCase().includes("su")) ||
                placement.OpenTimes.toLowerCase().includes("la") ||
                placement.OpenTimes.toLowerCase().includes("su") ||
                (placement.OpenTimes.toLowerCase().includes("la") &&
                  placement.OpenTimes.toLowerCase().includes("su")))))
      );
      console.log(childData);
      personAbsent.push({
        PersonId: child,
        AbsentReason: absentReason,
        allowWeekends: includeWeekEnd === "Ei" ? false : allowWeekEnd,
        allowSaturday: includeWeekEnd === "Ei" ? false : allowsaturday,
        allowSunday: includeWeekEnd === "Ei" ? false : allowsSunday,
      });
    });

    console.log({
      PersonList: personAbsent,
      Dates: ListDates,
      DeleteOld: false,
      EveningPreschool: false,
      Holidays: holidays,
    });
    actions.triggerSaveCareTimesAbsent({
      PersonList: personAbsent,
      Dates: ListDates,
      DeleteOld: false,
      EveningPreschool: false,
      Holidays: holidays,
    });
  };

  const [allowWeekends, setAllowWeekends] = useState(false);
  const [allowSaturday, setAllowSaturday] = useState(false);
  const [allowSunday, setAllowSunday] = useState(false);
  const [includeWeekEnd, setIncludeWeekEnd] = React.useState("");

  const handleWeekEndInclude = (event, newReason) => {
    if (newReason !== null) {
      setIncludeWeekEnd(newReason);
    }
  };
  return (
    <Dialog
      disablePadding
      disableGutters
      fullWidth
      open={newResDialogOpen}
      sx={{
        "& .MuiDialog-container .MuiDialog-paper": {
          margin: 0,
          borderRadius: 2,
          width: "96%",
        },
        width: "100%",
        borderRadius: 2,
      }}
    >
      <DialogTitle textAlign="center" style={{ backgroundColor: "#FFFFFF" }}>
        {!saved2 && !saving && (
          <Typography color="textSecondary" fontWeight="bold">
            {Languages.newCalendarSaveNewAbsence}
          </Typography>
        )}

        {saving && (
          <Typography color="textSecondary" fontWeight="bold">
            {Languages.newCalendarSavingAbsence}
          </Typography>
        )}

        {saved2 &&
          state.caretimes.Absent.state === types.CARETIMES_ABSENT_SAVED_OK && (
            <Typography color="textSecondary" fontWeight="bold">
              {Languages.newCalendarAbsentSaved}
            </Typography>
          )}

        {saved2 &&
          state.caretimes.Absent.state ===
            types.CARETIMES_ABSENT_SAVED_FAILED && (
            <Typography color="textSecondary" fontWeight="bold">
              {Languages.newCalendarSaveFailed}
            </Typography>
          )}

        {!saving && (
          <IconButton
            aria-label="close"
            onClick={saved2 ? handleClick : handleCloseClick}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "success",
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent
        dividers
        disableGutters
        disablePadding
        sx={{ padding: 0 }}
        style={{ backgroundColor: "#FFFFFF" }}
      >
        {saving === true || saved2 === true ? (
          <>
            <Stack spacing={2} alignItems="center" sx={{ mt: 2, mb: 2 }}>
              <ProgressBar
                saving={saving}
                saved={saved2}
                count={10}
                handleClick={handleClick}
              ></ProgressBar>
            </Stack>
          </>
        ) : (
          <Box sx={{ mb: 2, padding: 0 }}>
            <div>
              <Divider
                sx={{
                  m: 2,
                  "&::before, &::after": {
                    borderColor: "#0074C8",
                  },
                }}
                textAlign="center"
              >
                <Chip
                  label={"1. " + Languages.newCalendarSelectChildren}
                  sx={{ backgroundColor: "#0074C8" }}
                />
              </Divider>
              <>
                <CalendarChildSelection
                  names={names}
                  listOfChildren={listOfChildren}
                  handleFormat={handleChildren}
                ></CalendarChildSelection>
              </>

              <Divider
                sx={{
                  m: 2,
                  "&::before, &::after": {
                    borderColor: "#0074C8",
                  },
                }}
                textAlign="center"
              >
                <Chip
                  label={"2. " + Languages.newCalendarAbsentReasonSelect}
                  sx={{ backgroundColor: "#0074C8" }}
                />
              </Divider>
              <ToggleButtonGroup
                sx={{ width: "80%", ml: "10%" }}
                value={absentReason}
                exclusive
                onChange={handleReason}
                orientation="vertical"
              >
                <ToggleButton value="Poissa">
                  <Box sx={{ width: "100%" }}>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Box sx={{ width: "20%" }}></Box>
                      <Box sx={{ width: "60%" }}>
                        <Typography
                          color="textSecondary"
                          fontSize="medium"
                          textAlign="center"
                        >
                          {Languages.newCalendarAbsent}
                        </Typography>
                      </Box>
                      <Box sx={{ width: "20%" }}>
                        {absentReason === "Poissa" && (
                          <CheckIcon color="success" />
                        )}
                      </Box>
                    </Stack>
                  </Box>
                </ToggleButton>
                <ToggleButton value="Sairas">
                  <Box sx={{ width: "100%" }}>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Box sx={{ width: "20%" }}></Box>
                      <Box sx={{ width: "60%" }}>
                        <Typography
                          color="textSecondary"
                          fontSize="medium"
                          textAlign="center"
                        >
                          {Languages.newCalendarSick}
                        </Typography>
                      </Box>
                      <Box sx={{ width: "20%" }}>
                        {absentReason === "Sairas" && (
                          <CheckIcon color="success" />
                        )}
                      </Box>
                    </Stack>
                  </Box>
                </ToggleButton>
              </ToggleButtonGroup>
              {absentReason === "" && (
                <Typography
                  sx={{ mt: 1 }}
                  color="warning.main"
                  fontSize="medium"
                  textAlign="center"
                >
                  {Languages.newCalendarAbsentReasonSelect}
                </Typography>
              )}

              <Divider
                sx={{
                  m: 2,
                  "&::before, &::after": {
                    borderColor: "#0074C8",
                  },
                }}
                textAlign="center"
              >
                <Chip
                  label={"3. " + Languages.newCalendarSelectDates}
                  sx={{ backgroundColor: "#0074C8" }}
                />
              </Divider>
              {(allowSaturday ||
                allowSunday ||
                (allowSaturday && allowSunday)) && (
                <>
                  <Typography color="textSecondary" textAlign="center">
                    {allowSaturday && allowSunday
                      ? Languages.newCalendarAbsentWeekEnds
                      : allowSaturday
                      ? Languages.newCalendarAbsentSaturdays
                      : allowSunday
                      ? Languages.newCalendarAbsentSundays
                      : "test"}
                  </Typography>
                  <ToggleButtonGroup
                    sx={{ width: "100%", mt: 0.5 }}
                    value={includeWeekEnd}
                    exclusive
                    onChange={handleWeekEndInclude}
                  >
                    <ToggleButton
                      value={"Kyllä"}
                      sx={{
                        width: "40%",
                        ml: "10%",
                      }}
                    >
                      <Box sx={{ width: "100%" }}>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <Box sx={{ width: "20%" }}></Box>
                          <Box sx={{ width: "60%" }}>
                            <Typography
                              color="textSecondary"
                              fontSize="medium"
                              textAlign="center"
                            >
                              {Languages.NewCalendarYes}
                            </Typography>
                          </Box>
                          <Box sx={{ width: "20%" }}>
                            {includeWeekEnd === "Kyllä" && (
                              <CheckIcon color="success" />
                            )}
                          </Box>
                        </Stack>
                      </Box>
                    </ToggleButton>
                    <ToggleButton
                      value={"Ei"}
                      sx={{
                        width: "40%",
                      }}
                    >
                      <Box sx={{ width: "100%" }}>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <Box sx={{ width: "20%" }}></Box>
                          <Box sx={{ width: "60%" }}>
                            <Typography
                              color="textSecondary"
                              fontSize="medium"
                              textAlign="center"
                            >
                              {Languages.newCalendarNo}
                            </Typography>
                          </Box>
                          <Box sx={{ width: "20%" }}>
                            {includeWeekEnd === "Ei" && (
                              <CheckIcon color="success" />
                            )}
                          </Box>
                        </Stack>
                      </Box>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </>
              )}
              <CalendarDateSelectTabs
                handleHighLightedDays={handleHighLightedDays}
                highlightedDays={highlightedDays}
                extRes={extRes}
                handleMode={handleMode}
                handleDayRange={handleDayRange}
                startDay={startDay}
                endDay={endDay}
                startDate={startDate}
                handleWeek={handleWeek}
                allowWeekEnds={includeWeekEnd === "Kyllä" ? true : false}
                allowSaturday={
                  includeWeekEnd === "Kyllä" && allowSaturday ? true : false
                }
                allowSunday={
                  includeWeekEnd === "Kyllä" && allowSunday ? true : false
                }
              ></CalendarDateSelectTabs>
            </div>
          </Box>
        )}
      </DialogContent>
      {!saving && !saved2 && (
        <DialogActions
          sx={{
            width: "100%",
            height: "55px",
            margin: 0,
            backgroundColor:
              listOfChildren.length < 1 ? "#8c8c8b" : "success.main",
          }}
          style={{ justifyContent: "center", padding: 0 }}
        >
          <Button
            sx={{
              width: "100%",
              height: "55px",
              margin: 0,
              backgroundColor: "success.main",
              "&:disabled": { backgroundColor: "#8c8c8b" },
            }}
            variant="contained"
            onClick={saveAbsent}
            disabled={
              listOfChildren.length < 1 ||
              absentReason === "" ||
              (mode === 0 && highlightedDays.length < 1) ||
              (mode === 1 && startDate === null) ||
              (mode === 2 && (startDay === null || endDay === null))
                ? true
                : false
            }
          >
            {Languages.newCalendarSaveAbsence}
          </Button>
        </DialogActions>
      )}
      <Dialog onClose={handleClosePopUp} open={closePopUp}>
        <DialogTitle textAlign="center" style={{ backgroundColor: "#FFFFFF" }}>
          <Typography color="textSecondary" fontWeight="bold">
            {Languages.newCalendarAreYouSureExit}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClosePopUp}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "success",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ backgroundColor: "#FFFFFF" }}>
          <Typography sx={{ mt: 2 }} color="textSecondary" fontWeight="bold">
            {Languages.newCalendarEditLoss}
          </Typography>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: "center",

            backgroundColor: "#FFFFFF",
          }}
        >
          <Button
            variant="contained"
            sx={{ backgroundColor: "#0074C8" }}
            onClick={handleClosePopUp}
          >
            {Languages.newCalendarCancel}
          </Button>
          <Button
            variant="contained"
            sx={{ backgroundColor: "#0074C8" }}
            onClick={handleClick}
          >
            {Languages.newCalendarExit}
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default CareTimeCalendarNewAbsentDialog;

const ProgressBar = (props) => {
  const { state, actions } = useStateValue();

  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress >= 90) {
          return 90;
        }
        const diff = props.count / 100;
        return Math.min(oldProgress + diff, 100);
      });
    }, 100);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const [closeProgress, setCloseProgress] = React.useState(0);
  React.useEffect(() => {
    const timer = setInterval(() => {
      setCloseProgress((oldProgress) => {
        if (
          oldProgress >= 5 &&
          state.caretimes.Absent.state === types.CARETIMES_ABSENT_SAVED_OK
        ) {
          props.handleClick;
        }
        return oldProgress + 1;
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [state.caretimes.Absent]);

  return (
    <Box sx={{ width: "80%" }}>
      {props.saving && !props.saved && (
        <>
          {/*<LinearProgress
            sx={{
              "& .MuiLinearProgress-colorPrimary": {
                backgroundColor: "#0074C8",
              },
              "& .MuiLinearProgress-barColorPrimary": {
                backgroundColor: "#0074C8",
              },
              "& .MuiLinearProgress-dashedColorPrimary": {
                backgroundImage:
                  "radial-gradient(#0074C8 0%, #0074C8 16%, transparent 42%)",
              },
            }}
            dashedColorPrimary="red"
            dashedColorSecondary="green"
            variant="buffer"
            value={progress}
            valueBuffer={progress}
          />
          
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
            sx={{ ml: `${progress - 10}%` }}
          >
            {Math.round((progress / 100) * props.count) + " / " + props.count}
          </Typography>
          
          */}
          <Stack spacing={1} alignItems="center">
            <CircularProgress></CircularProgress>
            <Typography color="textSecondary" fontSize="medium">
              {Languages.newCalendarSavingAbsence}
            </Typography>
          </Stack>
        </>
      )}

      {!props.saving && props.saved && (
        <>
          {state.caretimes.Absent.state ===
            types.CARETIMES_ABSENT_SAVED_FAILED && (
            <>
              <Stack spacing={1} alignItems="center">
                <Typography color="textSecondary" fontSize="medium">
                  {Languages.newCalendarSavingAbsenceFailed}
                </Typography>
                {state.caretimes.Absent.Data.SaveGroupedErros.map((error) => (
                  <Typography color="textSecondary" fontSize="medium">
                    {error.ErrorDate !== null
                      ? moment(error.ErrorDate).format("DD.MM.YYYY") +
                        " " +
                        error.ErrorString
                      : error.ErrorString}
                  </Typography>
                ))}
                <Button
                  variant="contained"
                  sx={{ backgroundColor: "#0074C8" }}
                  onClick={props.handleClick}
                >
                  {Languages.newCalendarClose}
                </Button>
              </Stack>
            </>
          )}

          {state.caretimes.Absent.state === types.CARETIMES_ABSENT_SAVED_OK && (
            <>
              <Stack spacing={1} alignItems="center">
                <Typography color="textSecondary" fontSize="medium">
                  {Languages.newCalendarAbsentSaved}
                </Typography>
                <AutoClose handleClick={props.handleClick}></AutoClose>
                <Button
                  variant="contained"
                  sx={{ backgroundColor: "#0074C8" }}
                  onClick={props.handleClick}
                >
                  {Languages.newCalendarClose}
                </Button>
              </Stack>
            </>
          )}
        </>
      )}
    </Box>
  );
};

const AutoClose = (props) => {
  const [progress, setProgress] = React.useState(5);
  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress <= 0) {
          props.handleClick();
        }
        return oldProgress - 1;
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box sx={{ width: "80%" }}>
      <Typography color="textSecondary" fontSize="medium">
        {Languages.newCalendatAutomaticClose +
          " " +
          progress +
          " " +
          Languages.newCalendarAfterSeconds}
      </Typography>
    </Box>
  );
};

import React, { useEffect, useState, useCallback } from "react";
import { useStateValue } from "../../State";
import "./messaging.css";
import MessagingSuccess from "./Components/MessagingSuccess";
import MessagingError from "./Components/MessagingError";
import MessagingLoading from "./Components/MessagingLoading";
import MessagingZeroMessages from "./Components/MessagingZeroMessages";
import ContentBoxMUI from "../../Components/ContentBoxMUI/";
import Languages from "../../translations";
import Typography from "@mui/material/Typography";
import { SESSION_KEYS } from "../../Contants";
import { types } from "../../Reducers/actionTypes";

export const MessagingErrorMsg = () => {
  return (
    <Typography color="textSecondary">{Languages.errorUnknown}</Typography>
  );
};

export const InboxView = ({
  messages,
  loadMessagesState,
  errorCode,
  refresh,
  showNew,
  setAllRead,
  setAllReadLoading,
  openMessageThread,
}) => {
  switch (loadMessagesState) {
    case "NEVER_LOADED":
      if (
        typeof refresh === "undefined" ||
        refresh === null ||
        typeof showNew === "undefined" ||
        showNew === null
      ) {
        return <MessagingErrorMsg />;
      } else {
        return <MessagingLoading refresh={refresh} showNew={showNew} />;
      }

    case "LOADING_MESSAGES":
      if (
        typeof refresh === "undefined" ||
        refresh === null ||
        typeof showNew === "undefined" ||
        showNew === null
      ) {
        return <MessagingErrorMsg />;
      } else {
        return <MessagingLoading refresh={refresh} showNew={showNew} />;
      }

    case "LOAD_MESSAGES_SUCCESS":
      if (
        typeof refresh === "undefined" ||
        refresh === null ||
        typeof showNew === "undefined" ||
        showNew === null ||
        typeof messages === "undefined" ||
        messages === null ||
        typeof openMessageThread === "undefined" ||
        openMessageThread === null
      ) {
        return <MessagingErrorMsg />;
      } else {
        if (messages.length === 0) {
          return <MessagingZeroMessages refresh={refresh} showNew={showNew} />;
        } else {
          return (
            <MessagingSuccess
              messages={messages}
              refresh={refresh}
              showNew={showNew}
              setAllRead={setAllRead}
              setAllReadLoading={setAllReadLoading}
              openMessageThread={openMessageThread}
            />
          );
        }
      }
    default: // GENERAL ERROR CASE
      return (
        <MessagingError
          refresh={refresh}
          showNew={showNew}
          errorCode={errorCode}
        />
      );
  }
};

const Messaging = (props) => {
  const { state, actions } = useStateValue();
  const { messaging } = state;

  const [mount, setMount] = useState(true);
  const [waitingAllReadInfo, setWaitingAllReadInfo] = useState(false);

  const loadData = useCallback(() => {
    actions.triggerLoadMessages();
  }, [actions]);

  /*const loadMainStats = useCallback(() => {
    actions.triggerLoadMainStats();
  }, [actions]);*/

  useEffect(() => {
    if (mount) {
      //loadMainStats();

      sessionStorage.setItem("messagingNew", JSON.stringify(true));
      setMount(false);
      //console.log("donotload", state.messaging.doNotLoadInboxAfterOpenThread);
      //do not load inbox if we are coming back from openmessage and the user has not replied to the thread
      console.log(
        "lastLocation",
        sessionStorage.getItem(SESSION_KEYS.lastLocation)
      );
      if (
        !state.messaging.doNotLoadInboxAfterOpenThread ||
        (sessionStorage.getItem(SESSION_KEYS.lastLocation) === "/messaging" &&
          state.messaging.doNotLoadInboxAfterOpenThread)
      ) {
        loadData();
      } else {
        actions.triggerUpdateDoNotLoadInboxVariable(false);
      }

      //window.scrollTo(0,0);
    }
  }, [mount, loadData /*, loadMainStats*/]);

  useEffect(() => {
    if (waitingAllReadInfo) {
      if (
        state.messaging.setAllMessagesRead.status ===
        types.SET_ALL_MESSAGES_READ_SUCCESS
      ) {
        actions.triggerAddToSnackBarQueue({
          color: "#000000",
          message: Languages.msgMarkMessagesSuccess,
        });
        setWaitingAllReadInfo(false);
        sessionStorage.setItem("messagingNew", JSON.stringify(true));
        console.log("IF SUCCESS");
        actions.triggerLoadMessages();
      }
      if (
        state.messaging.setAllMessagesRead.status ===
        types.SET_ALL_MESSAGES_READ_FAILED
      ) {
        actions.triggerAddToSnackBarQueue({
          color: "#000000",
          message:
            Languages.msgMarkMessagesFailed +
            " " +
            state.messaging.setAllMessagesRead.Code,
        });
        setWaitingAllReadInfo(false);
        sessionStorage.setItem("messagingNew", JSON.stringify(true));
        console.log("IF FAIL");
        actions.triggerLoadMessages();
      }
    }
  }, [
    actions,
    state.messaging.setAllMessagesRead.status,
    state.messaging.setAllMessagesRead.Code,
    waitingAllReadInfo,
  ]);

  const openMessageThread = (messageThread) => {
    actions.triggerUpdateMessageThreadId(messageThread);
    props.history.push("/messaging/openmessage");
    console.log("I*M TIRGGERED");
  };

  /*scrollToTop = () => {
    let top = this.topFocus.current;
        top.scrollIntoView({alignToTop: true});
  }*/

  const showNew = () => {
    props.history.push({
      pathname: "/messaging/newmessage",
      state: { from: props.history.location.pathname },
    });
  };

  const refresh = () => {
    loadData();
    actions.triggerLoadThreadMainStats();
  };

  const setAllRead = () => {
    actions.triggerSetAllMessagesRead();
    setWaitingAllReadInfo(true);
  };

  return (
    <ContentBoxMUI header={Languages.msgInboxTitle}>
      <div className="messagingMinHeight" /*ref={this.topFocus}*/>
        <InboxView
          loadMessagesState={messaging.loadMessages.state}
          messages={messaging.loadMessages.messages}
          errorCode={messaging.loadMessages.Code}
          refresh={refresh}
          showNew={showNew}
          setAllRead={setAllRead}
          openMessageThread={openMessageThread}
          setAllReadLoading={
            state.messaging.setAllMessagesRead.status ===
            types.LOADING_SET_ALL_MESSAGES_READ
          }
        />
      </div>
    </ContentBoxMUI>
  );
};

export default Messaging;

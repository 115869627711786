import MessagesReducer from './MessagesReducer';
import NursingTimesReducer from './NursingTimesReducer';
import HeaderReducer from './HeaderReducer';
import ChildrenReducer from './ChildrenReducer';
import LeopsReducer from './LeopsReducer';
import VasuReducer from './VasuReducer';
import ApprovalReducer from './ApprovalReducer';
import DailyNotesReducer from './DailyNotesReducer';
import MainStatsReducer from "./MainStatsReducer";
import ProfilePictureReducer from "./ProfilePictureReducer";
import BulletingBoardReducer from "./BulletinBoardReducer";
import DFSettingsReducer from "./DFSettingsReducer";
import GrowthFolderReducer from "./GrowthFolderReducer";
import SnackbarReducer from "./SnackbarReducer";
import FileReducer from "./FileReducer";
import SettingsReducer from "./SettingsReducer";
import NTSummaryReducer from "./NTSummaryReducer";
import LoginEmailReducer from "./LoginEmailReducer";
import NotificationReducer from "./NotificationReducer";
import CentreInfoReducer from "./CentreInfoReducer";
import ChildFilesReducer from "./ChildFilesReducer";
import MunicipalPermissionsReducer  from "./MunicipalPermissionsReducer";
import SmallRequestReducer    from "./SmallRequestReducer";
import DiscussionReducer      from "./DiscussionReducer";
import CareTimeReducer from './CareTimeReducer';
import { initialState } from "../State";
import { types } from "./actionTypes";

const mainReducer = (appState, action) => {
  const {
    loginEmail,
    messaging,
    nursingtimes,
    nursingTimesSummary,
    settings,
    header,
    children,
    approval,
    dailynote,
    vasu,
    leops,
    mainstats,
    profilePictures,
    bulletinBoards,
    dFSettings,
    snackbar,
    files,
    notifications,
    centreinfos,
    growthFolder,
    childFiles,
    municipalpermissionquestions,
    smallRequests,
    discussion,
    caretimes
  } = appState;

  if (action.type === types.APP_RESET) {
    return {
      ...initialState,
    }
  }

  return {
    dFSettings: DFSettingsReducer(dFSettings, action),
    loginEmail: LoginEmailReducer(loginEmail, action),
    messaging: MessagesReducer(messaging, action),
    nursingtimes: NursingTimesReducer(nursingtimes, action),
    growthFolder: GrowthFolderReducer(growthFolder, action),
    nursingTimesSummary: NTSummaryReducer(nursingTimesSummary, action),
    settings: SettingsReducer(settings, action),
    header: HeaderReducer(header, action),
    children: ChildrenReducer(children, action),
    approval: ApprovalReducer(approval, action),
    dailynote: DailyNotesReducer(approval, action),
    vasu: VasuReducer(vasu, action),
    leops: LeopsReducer(leops, action),
    mainstats: MainStatsReducer(mainstats, action),
    profilePictures: ProfilePictureReducer(profilePictures, action),
    bulletinBoards: BulletingBoardReducer(bulletinBoards, action, children),
    snackbar: SnackbarReducer(snackbar, action),
    files: FileReducer(files, action),
    notifications: NotificationReducer(notifications, action),
    centreinfos: CentreInfoReducer(centreinfos, action),
    childFiles: ChildFilesReducer(childFiles, action),
    municipalpermissionquestions: MunicipalPermissionsReducer(municipalpermissionquestions, action),
    smallRequests: SmallRequestReducer(smallRequests, action),
    discussion: DiscussionReducer(discussion, action),
    caretimes: CareTimeReducer(caretimes, action)
  }
};

export default mainReducer;
import React from "react";
import BackToUpButton from "../GuideTools/BackToUpButton";
import DeleteIcon from '@mui/icons-material/Delete'
import Languages from "../../../translations";
import makeStyles from '@mui/styles/makeStyles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import SelectAllIcon from '@mui/icons-material/SelectAll'
import ControlPointIcon from '@mui/icons-material/ControlPoint'

const useStyles = makeStyles({
  success: {
    color: 'rgb(129, 199, 132)',
    fontSize: 'xx-large',
  }
});



const EditBookingAccordionText = () => {
  const classes = useStyles();
    return(
      <>
      <div>
      <h4>{Languages.editBookingGuideTitle}</h4>
          <br />
          <p className="guide-accordion-details-texts">{Languages.editBookingGuideIntro} <DeleteIcon /> {Languages.editBookingGuideIntro2}</p>
          <br />
          <br />
         <hr className="guide-accordion-details-line"/>
          <h4>{Languages.editBookingMiddleTitle}</h4>
          <br/>
          <div>
          <ol>
          <li className="guide-list-item-styles">{Languages.editBookingList1} <SelectAllIcon /> {Languages.editBookingList1_2}</li>
          <br />
          <li className="guide-list-item-styles">{Languages.editBookingList2} <ControlPointIcon className={classes.success}/></li>
          <br />
          <li className="guide-list-item-styles">{Languages.editBookingList3}</li>
          <br />
          <li className="guide-list-item-styles">{Languages.editBookingList4} <input className="PrivateSwitchBase-input-81" style={{width: '20px', height: '20px'}}id="test_NewBooking_Child0" type="checkbox" data-indeterminate="false" defaultChecked={true} readOnly /></li>
          <br />
          <li className="guide-list-item-styles">{Languages.editBookingList5}</li>
          <br />
          <li className="guide-list-item-styles">{Languages.editBookingList6}</li>
          <br />
          <li className="guide-list-item-styles">{Languages.editBookingList7}</li>
          <br />
          <li className="guide-list-item-styles">{Languages.editBookingList8}</li>
          <br />
          <li className="guide-list-item-styles">{Languages.editBookingList9} <CheckCircleIcon className={classes.success}/></li>
          </ol>
          </div>
        <br />
        <BackToUpButton />
        </div>
      </>
    )
}

export default EditBookingAccordionText
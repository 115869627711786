import React, { useEffect, useState } from 'react';
import {StateContext, useStateValue} from "../../State";
import { types } from '../../Reducers/actionTypes';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import translations from '../../translations';
import { styled } from '@mui/material/styles';
import { history } from "../../Router";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DaisyLogo from '../../images/Family-21.png';
import CardMedia from '@mui/material/CardMedia';
import InfoIcon from '@mui/icons-material/Info';
import Button from '@mui/material/Button';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import AddToHomeScreenIcon from '@mui/icons-material/AddToHomeScreen';
import { atcb_action } from 'add-to-calendar-button';
import 'add-to-calendar-button/assets/css/atcb.css';
import Avatar from '@mui/material/Avatar';
import ChildProfilePicture from '../ChildProfilePicture';
import Chip from '@mui/material/Chip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Languages from "../../translations";
import SickIcon from '@mui/icons-material/Sick';
import SickOutlinedIcon from '@mui/icons-material/SickOutlined';
import BeachAccessOutlinedIcon from '@mui/icons-material/BeachAccessOutlined';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import GroupIcon from '@mui/icons-material/Group';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import NoAccountsOutlinedIcon from '@mui/icons-material/NoAccountsOutlined';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import LockClockIcon from '@mui/icons-material/LockClock';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import Popover from '@mui/material/Popover';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';




const DataRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    //backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const SecondHeaderRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.action.hover,
  '& td, & th': {
    border: 0,
  },
}));


const TablePage = ({ pageData, showRemoveBoxes, onClose, handleReload,  handleRemoveBoxes }) => {
  const [deletableCareTimes, setDeletableCareTimes ] = React.useState([]); 
  const [confirmAnchorEl, setConfirmAnchorEl] = React.useState(null);
  const [cancelAnchorEl, setCancelAnchorEl] = React.useState(null);
  const [infoAnchorEl, setInfoAnchorEl] = React.useState(null);
  const {state, actions} = useStateValue();

  

  


  const handleRemoveClick = (id) => {
    var updatedList = [...deletableCareTimes]
    setDeletableCareTimes(updatedList.filter(careTime => careTime.deleteId !== id))

  }

  

  const handleDeleteClick = (props) => {    

    var isDuplicate = deletableCareTimes.some(dTime => dTime.deleteId === props.deleteId && dTime.deleteType === props.deleteType)

    if(isDuplicate === true) {
      var updateList = [...deletableCareTimes]
      setDeletableCareTimes(updateList.filter(careTime => careTime.deleteId !== props.deleteId))
    }
    else{

      const index = deletableCareTimes.findIndex(object => {
        return (object.deleteId === props.deleteId)
      })
      if(index !== -1){
        var copiedState = [...deletableCareTimes]
        copiedState[index].deleteType = props.deleteType
        setDeletableCareTimes(copiedState)
      }
      else {
        setDeletableCareTimes(arr => [...arr, props])

      }
    }
  }

  const confirmOpen = Boolean(confirmAnchorEl);
  const openId = confirmOpen ? 'confirmOpenSaveButton' : undefined;

  const cancelOpen = Boolean(cancelAnchorEl)
  const cancelId = cancelOpen ? 'cancelOpenButton' : undefined;

  const infoOpen = Boolean(infoAnchorEl)
  const infoId = infoOpen ? 'infoOpenButton' : undefined;

  const infoPopoverClose = () => {
    setInfoAnchorEl(null);
  }

  const cancelPopoverClose = () => {
    setCancelAnchorEl(null);
  }

  const confirmPopoverClose = () => {
    setConfirmAnchorEl(null);
  };

  const showInfoPopover = (event) => {
    setInfoAnchorEl(event.currentTarget)
  }

  const showDeclineConfirmation = (event) => {
    if(deletableCareTimes.length === 0){
      handleRemoveBoxes()
    }
    else {
      setCancelAnchorEl(event.currentTarget)
    }

  }

  const showSaveConfirmation = (event) => {
    setConfirmAnchorEl(event.currentTarget)
  }

  const handleConfirmCancel = () => {
    setDeletableCareTimes([])
    setCancelAnchorEl(null);
    handleRemoveBoxes()
  }

  const getCareTimeType = (deleteType) => {
    if(deleteType === "S"){
      return(
      <>
      <SickIcon sx={{color: "#002E6D"}} fontSize="small"/>&nbsp;&nbsp;
      <Typography variant="caption">{Languages.bookingTypeSick}</Typography>
      </>
      )
    }
    if(deleteType === "A"){
      return(
      <>
      <NoAccountsIcon sx={{color: "#002E6D"}} fontSize="small"/>&nbsp;&nbsp;
      <Typography variant="caption">{Languages.bookingTypeAbsent}</Typography>
      </>)
    }
    if(deleteType === "H"){
      return(
      <>
      <BeachAccessIcon sx={{color: "#002E6D"}} fontSize="small"/>&nbsp;&nbsp;
      <Typography variant="caption">{Languages.bookingTypeHoliday}</Typography>
      </>
      )
    }
  }

  const checkDateValidity = (listDate) => {
    var presentDate = moment();
    var dayToBeChecked = moment(listDate)
    
    if(dayToBeChecked.diff(presentDate, "days") >= 0)
    return true
    else return false
  }


  const handleSaveClick = () => {

    let children = [];

    deletableCareTimes.forEach(careTime =>{
      if(children.some(dTime => dTime.Id === careTime.deletePersonId) === false){
        children.push({Id: careTime.deletePersonId});
      }
    });

    children.forEach(child => {
      var childs = [];
      var reservationsChilds =[];
      var selectedDates = [];
      var ListOfReservationsTimes = [];
      var Types = [];
      var DeleteOld = true; 

      deletableCareTimes.forEach(careTime => {
        const isDuplicate = childs.some(dChild => dChild.Id === careTime.deletePersonId);
        if(child.Id == careTime.deletePersonId){
          if(isDuplicate == false){
            childs.push({   Id:     careTime.deletePersonId, 
              Name:   careTime.deleteFirstName,
              PlId:   careTime.deletePlacementId, 
              PlName: careTime.deleteCentre +"-"+ careTime.deleteGroup});
          }
          if(selectedDates.some(dTime => dTime.Date === careTime.deleteDate) === false){
            selectedDates.push({Date:  careTime.deleteDate});
          }
          Types.push(careTime.deleteType);
        }
        

        if(ListOfReservationsTimes.length === 0){
          ListOfReservationsTimes.push({Start: careTime.deleteStart,
                                        End: careTime.deleteEnd,                              
                                        StartNotValid: false,
                                        EndNotValid: false});
        }
        
      });
      reservationsChilds.push(childs);
      actions.triggerSaveNursingTimes({reservationsChilds, selectedDates, ListOfReservationsTimes, Types, DeleteOld});
      handleReload()
      handleRemoveBoxes()
      confirmPopoverClose()
      
      
      
    });

  }

  const handleCalendarSave = (props) => {
    onClose();
    atcb_action({
      name: props.iName,
      startDate: moment(props.iDate).format("YYYY-MM-DD"),
      startTime: moment(props.iStartTime).format("HH:mm"),
      endTime: moment(props.iEndTime).format("HH:mm"),
      //Whew päiväkohtainen merkintä ready, insert here
      //description: props.iDescription,
      location: props.iLocation,
      options: ['Apple', 'Google', 'iCal', 'Microsoft365', 'Outlook.com', 'Yahoo'],
      timeZone: "Europe/Helsinki",
      iCalFileName: props.iName + " " + props.iGroup,
    });
  }

  const handleDayName = (date) => {
    var daynumber = moment(date).isoWeekday()
    switch (daynumber) {
        case 1:
            return Languages.monday
        case 2:
            return Languages.tuesday
        case 3:
            return Languages.wednesday
        case 4:
            return Languages.thursday
        case 5:
            return Languages.friday
        case 6:
            return Languages.saturday
        case 7:
            return Languages.sunday
    }     
  }

  return (
    <React.Fragment>
      {showRemoveBoxes &&
      <>
      <Popover id={infoId} open={infoOpen} anchorEl={infoAnchorEl} onClose={infoPopoverClose} anchorOrigin={{vertical: "bottom", horizontal: "left"}}>
        <List dense sx={{backgroundColor: "#D7E3ED", minWidth: "250px"}}>
          <ListSubheader sx={{backgroundColor: "#D7E3ED", borderBottom: "1px solid black"}}>{Languages.bookingYoureEditingFollowing} ({deletableCareTimes.length}):</ListSubheader>
        {deletableCareTimes.sort((a,b) => a.deleteDate.localeCompare(b.deleteDate)).map((careTime, index) => (
          <ListItem divider key={careTime.deleteId} alignItems="flex-start" /*secondaryAction={<IconButton><PlaylistRemoveIcon sx={{color: "#002E6D", }} /></IconButton>}*/>
            <ListItemSecondaryAction sx={{top: "15%", right: "6px"}}><IconButton onClick={() => handleRemoveClick(careTime.deleteId)}><PlaylistRemoveIcon sx={{color: "#002E6D", }} /></IconButton></ListItemSecondaryAction>
            <ListItemText 
              primary={<Typography sx={{color: "black"}}>{careTime.deleteFirstName}</Typography>} 
              secondary={
                <>
                <Typography variant="caption">
                  {getCareTimeType(careTime.deleteType)}<br />
                  <AccessTimeIcon sx={{color: "#002E6D"}} fontSize="small"/>&nbsp;&nbsp;{handleDayName(careTime.deleteDate).substring(0,2)} {moment(careTime.deleteDate).format('DD.MM')}&nbsp;&nbsp;|&nbsp;&nbsp;{moment(careTime.deleteStart).format("LT")} - {moment(careTime.deleteEnd).format("LT")}
                  <br />
                  <GroupIcon sx={{color: "#002E6D"}} fontSize="small"/>&nbsp;&nbsp;{careTime.deleteGroup}
                <br />
                <MapsHomeWorkIcon sx={{color: "#002E6D"}} fontSize="small"/>&nbsp;&nbsp;{careTime.deleteCentre}
                </Typography>
                </>}/>
          </ListItem>
        ))}
        </List>
        <Box sx={{backgroundColor: "#D7E3ED", textAlign: "center"}}>
        <Button disabled={deletableCareTimes.length < 1} variant="contained" sx={{backgroundColor: "#006633", color: "white", ":hover": {backgroundColor: "#DCEEDF", color: "#006633"}}} onClick={handleSaveClick}>{Languages.bookingCareHoursDefaultSave}</Button>
        <Button variant="text" sx={{color: "#002E6D", ":hover": {backgroundColor: "white",}}} onClick={infoPopoverClose}>{Languages.generalClose}</Button>
        </Box>
      </Popover>
      <Popover id={openId} open={confirmOpen} anchorEl={confirmAnchorEl} onClose={confirmPopoverClose} anchorOrigin={{vertical: "bottom", horizontal: "left"}}>
      <Box sx={{padding: "10px", backgroundColor: "#D7E3ED", color: "#002E6D", border: "3px solid #002E6D"}}>
        <Typography><b>{Languages.bookingCareHoursSaveInformation}</b></Typography>
        <br />
        <Button variant="contained" sx={{backgroundColor: "#002E6D", color: "white", }} onClick={handleSaveClick}>{Languages.permYes}</Button>&nbsp;&nbsp;
        <Button variant="text" sx={{backgroundColor: "#D7E3ED", color: "#002E6D", ":hover": {backgroundColor: "white",}}} onClick={confirmPopoverClose}>{Languages.generalBack}</Button>
      </Box>
      </Popover>
      <Popover id={cancelId} open={cancelOpen} anchorEl={cancelAnchorEl} onClose={cancelPopoverClose} anchorOrigin={{vertical: "bottom", horizontal: "left"}}>
      <Box sx={{padding: "10px", backgroundColor: "#FFEBD4", color: "#EE5711", border: "3px solid #EE5711"}}>
        <Typography><b>{Languages.generalSaveModifications}</b></Typography>
        <br />
        <Button id="confirmOpenSaveButton" variant="contained" sx={{backgroundColor: "#EE5711", color: "#FFEBD4", ':hover': {backgroundColor: "#FFEBD4", color: "#EE5711",}}} onClick={handleConfirmCancel}>{Languages.permYes}</Button>&nbsp;&nbsp;
        <Button id="cancelOpenButton" variant="text" sx={{backgroundColor: "#FFEBD4", color: "#EE5711", ":hover": {backgroundColor: "white",}}} onClick={cancelPopoverClose}>{Languages.generalBack}</Button>
      </Box>
      </Popover>
      <TableRow sx={{textAlign: "center", backgroundColor: "#D7E3ED", borderTop: "2px solid #002E6D"}}>
            <TableCell colSpan={3} sx={{backgroundColor: "#D7E3ED", padding: "10px", textAlign: "center"}}>
              <Button onClick={showInfoPopover} disabled={deletableCareTimes.length < 1}size="small" variant="outlined" sx={{float: "left", backgroundColor: "#D7E3ED", color: "#002E6D", ':hover': {backgroundColor: "#002E6D", color: "white"}}}>
                <GroupRemoveIcon fontSize="small"/>&nbsp;&nbsp;{Languages.bookingShowTimes} ({deletableCareTimes.length})
              </Button>
              <Button id="cancelPopover" onClick={showDeclineConfirmation} size="small" variant="outlined" sx={{float: "right", marginLeft: "5px", backgroundColor: "#F9E0E5", color: "#B30036", border: "1px solid #B30036", ':hover': {backgroundColor: "#B30036", color: "white"}}}>
                {Languages.generalBack}
             </Button>
              <Button id="showSaveConfirmation" onClick={showSaveConfirmation} disabled={deletableCareTimes.length < 1} size="small" variant="contained" sx={{float: "right", backgroundColor: "#00572B", color: "white", ':hover': {backgroundColor: "#DCEEDF", color: "#006633", }}}>
                {Languages.bookingCareHoursDefaultSave}    
              </Button>
            </TableCell>
          </TableRow>
          <br />
          </>
      }
      <TableRow sx={{padding: "0px"}}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, color: "#002E6D" }} colSpan={3}>
          <Box sx={{ margin: 1 }}>
            <Table size="small" aria-label="week nursing times" sx={{width: "100%",}}>
              <TableBody>
                {Object.keys(pageData).map((day,index) => (
                  <React.Fragment key={day + 'dd'+index}>
                    <SecondHeaderRow scope="row" sx={{backgroundColor: "#002E6D", width: "100%"}}>
                      <TableCell /*colSpan={4}*/><b>{/*translations.uctDateAbbr + ' ' +  */ moment(day).format('llll').substring(0,2) + ' ' + moment(day).format('DD.MM')}</b></TableCell>
                      <TableCell><AccessTimeIcon /></TableCell>
                    </SecondHeaderRow>
                    {pageData[day].map((time,  index) => (
                      <DataRow key={time+'nt_cell'+index} sx={{backgroundColor: "#D7E3ED", }}>
                        <TableCell component="th" scope="row" sx={{color: "black"}}>
                          <Chip
                          sx={{color: "black"}}
                          avatar={<Avatar><ChildProfilePicture childId={time.PersonId} /></Avatar>} label={time.FirstName}></Chip>
                          {/*time.FirstName*/}
                        </TableCell>
                        <TableCell sx={{color: "black"}}>{moment(time.Start).format("LT") +'-'+moment(time.End).format("LT")}</TableCell>
                        {time.Type === "S" &&
                          <TableCell>
                            <SickIcon sx={{color: "#EE5711"}} /><Typography variant="caption" sx={{color: "#EE5711"}}>{Languages.bookingTypeSick}</Typography>
                          </TableCell>
                        }
                        {time.Type === "A" &&
                          <TableCell>
                            <NoAccountsIcon sx={{color: "#EE5711"}}/><Typography variant="caption" sx={{color: "#EE5711"}} >{Languages.bookingTypeAbsent}</Typography>
                          </TableCell>
                        }
                        {time.Type === "H" &&
                          <TableCell>
                            <BeachAccessIcon sx={{color: "#EE5711"}}/><Typography variant="caption" sx={{color: "#EE5711"}} >{Languages.bookingTypeAbsent}</Typography>
                          </TableCell>
                        }
                        {time.Type === null &&
                          <TableCell>
                            
                          </TableCell>
                        }
                        
                        {!showRemoveBoxes &&
                        <TableCell>
                          <IconButton 
                            sx={{float: "right", color: "#002E6D"}} 
                            onClick={() => handleCalendarSave(
                              {
                                iName: time.FirstName,
                                iLocation: time.Centre,
                                iDate: time.Date,
                                //when päiväkohtainen merkintä ready, insert here
                                //iDescription: time.dayNotification,
                                iStartTime: time.Start,
                                iEndTime: time.End,
                                iGroup: time.Group,
                              })
                            }>
                            <AddToHomeScreenIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                        }
                        {showRemoveBoxes &&
                          
                          <TableCell sx={{textAlign: "center"}}>
                            {(checkDateValidity(time.Date) && time.Type === null) ?
                            <>
                          <IconButton 
                            sx={{ color: "#002E6D", display: "none"}}
                            onClick={() => handleDeleteClick(
                              {
                                deleteId: time.Id,
                                deleteDate: time.Date,
                                deleteEnd: time.End,
                                deletePersonId: time.PersonId,
                                deletePlacementId: time.PlacementId,
                                deleteStart: time.Start,
                                deleteType: "H",
                                //Other stuff for users
                                deleteFirstName: time.FirstName,
                                deleteGroup: time.Group,
                                deleteCentre: time.Centre,
                              })
                            } 
                          >
                            {(deletableCareTimes.some(dTime => dTime.deleteId === time.Id && dTime.deleteType === "H")) ?
                            <>
                            <BeachAccessIcon sx={{color: "#EE5711"}} /><Typography sx={{color: "#EE5711"}} variant="caption">{Languages.bookingTypeHoliday}</Typography>
                            </>
                            :
                            <>
                            <BeachAccessOutlinedIcon /><Typography variant="caption">{Languages.bookingTypeHoliday}</Typography>
                            </>
                            }
                          </IconButton>
                          <IconButton 
                            sx={{ color: "#002E6D",}}
                            onClick={() => handleDeleteClick(
                              {
                                deleteId: time.Id,
                                deleteDate: time.Date,
                                deleteEnd: time.End,
                                deletePersonId: time.PersonId,
                                deletePlacementId: time.PlacementId,
                                deleteStart: time.Start,
                                deleteType: "S",
                                //Other stuff for users
                                deleteFirstName: time.FirstName,
                                deleteGroup: time.Group,
                                deleteCentre: time.Centre,
                              })
                            }  
                          >
                            {(deletableCareTimes.some(dTime => dTime.deleteId === time.Id && dTime.deleteType === "S")) ?
                            <>
                            <SickIcon sx={{color: "#EE5711"}} /><Typography variant="caption" sx={{color: "#EE5711"}}>{Languages.bookingTypeSick}</Typography>
                            </>
                            :
                            <>
                            <SickOutlinedIcon /*fontSize="small"*/ /><Typography variant="caption">{Languages.bookingTypeSick}</Typography>
                            </>
                            }
                          </IconButton>
                          
                          <IconButton 
                            sx={{ color: "#002E6D"}}
                            onClick={() => handleDeleteClick(
                              {
                                deleteId: time.Id,
                                deleteDate: time.Date,
                                deleteEnd: time.End,
                                deletePersonId: time.PersonId,
                                deletePlacementId: time.PlacementId,
                                deleteStart: time.Start,
                                deleteType: "A",
                                //Other stuff for users
                                deleteFirstName: time.FirstName,
                                deleteGroup: time.Group,
                                deleteCentre: time.Centre,
                              })
                            }  
                          >
                            {(deletableCareTimes.some(dTime => dTime.deleteId === time.Id && dTime.deleteType === "A")) ?
                            <>
                            <NoAccountsIcon sx={{color: "#EE5711"}}/>
                            <Typography variant="caption" sx={{color: "#EE5711"}} >{Languages.bookingTypeAbsent}</Typography>
                            </>
                            :
                            <>
                            <NoAccountsOutlinedIcon /*fontSize="small"*/ />
                            <Typography variant="caption">{Languages.bookingTypeAbsent}</Typography>
                            </>
                            }
                          </IconButton>
                          </>
                          :
                          <><LockClockIcon sx={{textAlign: "center", color: "#002E6D"}}/><Typography variant="caption" sx={{textAlign: "center", color: "#002E6D"}}><br />{Languages.bookingsLocked}</Typography></>
                        }
                        </TableCell>
                        }
                        
                       
                      </DataRow>
                    ))}
                  </React.Fragment>
                ))}
                
              </TableBody>
             
            </Table>
            
          </Box>
        </TableCell>
      </TableRow>
      {showRemoveBoxes &&
      <>
                <TableRow sx={{textAlign: "center", backgroundColor: "#D7E3ED",}}>
                  
            <TableCell colSpan={3} sx={{backgroundColor: "#D7E3ED", /*padding: "10px",*/ textAlign: "center"}}>
            <Button onClick={showInfoPopover} disabled={deletableCareTimes.length < 1}size="small" variant="outlined" sx={{float: "left", backgroundColor: "#D7E3ED", color: "#002E6D", ':hover': {backgroundColor: "#002E6D", color: "white"}}}>
                <GroupRemoveIcon fontSize="small"/>&nbsp;&nbsp;{Languages.bookingShowTimes} ({deletableCareTimes.length})
              </Button>
              <Button id="cancelPopover" onClick={showDeclineConfirmation} size="small" variant="outlined" sx={{float: "right", marginLeft: "5px", backgroundColor: "#F9E0E5", color: "#B30036", border: "1px solid #B30036", ':hover': {backgroundColor: "#B30036", color: "white"}}}>
              {Languages.generalBack} 
             </Button>
             <Button id="showSaveConfirmation" onClick={showSaveConfirmation} disabled={deletableCareTimes.length < 1} size="small" variant="contained" sx={{float: "right", backgroundColor: "#00572B", color: "white", ':hover': {backgroundColor: "#DCEEDF", color: "#006633", }}}>
             {Languages.bookingCareHoursDefaultSave}    
              </Button>
            </TableCell>
          </TableRow>
          </>}
    </React.Fragment>
  );
}


export default function ({ nursingTimes, onClose, handleReload }) {
  const [weeks] = useState(Object.keys(nursingTimes))
  const [page, setPage] = useState(0)
  const [ showRemoveBoxes, setShowRemoveBoxes ] = React.useState(false)

  const handleRemoveBoxes = () => {
    setShowRemoveBoxes(!showRemoveBoxes)
  }

  const handlePage = () =>{
    weeks.forEach((element, index)=> {  
      if(element == moment().week()){
        setPage(index)
      }
    });
    
  }

  useEffect(() => {
    
    handlePage();
    
  },[])
   
  if (!nursingTimes[weeks[page]]) {
    return (
      <>
      <Typography sx={{backgroundColor: "#D7E3ED", color: "black", display: "inline-block", padding: "15px", borderLeft: "solid 3px #005AA3"}}>
        <InfoIcon sx={{color: "#005AA3"}} />&nbsp;&nbsp;{translations.noReservedNursingTimes}
      </Typography>
      <CardMedia 
            component="img"
            image={DaisyLogo}
            alt="DaisyFamily logo"
          />
      </>
    );
  }

  const onWeekChange = (dir) => {
    const weeks = Object.keys(nursingTimes)
    
    if (dir === "left") {
      const pg = page - 1
      if (pg < 0) {
        setPage(0)
      } else {
        setPage(pg)
      }
    } else if (dir === "right") {
      const pg = page + 1

      if (pg > weeks.length - 1) {
        setPage(weeks.length - 1)
      } else {
        setPage(pg)
      }
    }
  }


const handleRedirectToCaretimes = () => {
  if(history.location.pathname === '/nursingtimes') {
    onClose()
  }
  else {
    history.push("/nursingtimes")
  }
}


  return (
    <TableContainer component={Paper}>
      <Table aria-label="nursing times" sx={{backgroundColor: "#002E6D"}}>
        <TableHead sx={{textAlign: "center", backgroundColor: "#D7E3ED"}}>
          <TableRow sx={{textAlign: "center", backgroundColor: "#D7E3ED"}}>
            <TableCell sx={{textAlign: "center"}} colSpan={4}>
              <BottomNavigation showLabels sx={{backgroundColor: "#D7E3ED", borderBottom: "3px solid #002E6D"}}>
                <BottomNavigationAction sx={{color: "#002E6D"}} label={Languages.addBookingKeywordButton2} icon={<EventAvailableIcon />} onClick={handleRedirectToCaretimes}/>
                <BottomNavigationAction sx={{color: "#002E6D"}} label={showRemoveBoxes === false ? Languages.absentBookingKeywordButton2 : Languages.bookingCareHoursTitle} icon={<EventBusyIcon />} onClick={handleRemoveBoxes}/>
              </BottomNavigation>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <IconButton
                aria-label="expand row"
                sx={{color: "#002E6D"}}
                size="small"
                //color="primary"
                onClick={() => onWeekChange("left")}
              >
                <ArrowCircleLeftOutlinedIcon fontSize="large" />
              </IconButton>
            </TableCell>
            <TableCell align="center">
              <Typography sx={{color: "#002E6D"}}>
                <b>{translations.generalWeek + ' ' + weeks[page]}</b>
              </Typography>
            </TableCell>
            <TableCell align="right">
              <IconButton
                aria-label="expand row"
                sx={{color: "#002E6D"}}
                size="small"
                //color="primary"
                onClick={() => onWeekChange("right")}
              >
                <ArrowCircleRightOutlinedIcon fontSize="large"/>
              </IconButton>
            </TableCell>
          </TableRow>
          
        </TableHead>
        <TableBody>
          <TablePage
            pageData={nursingTimes[weeks[page]]}
            rowName={weeks[page]}
            showRemoveBoxes={showRemoveBoxes}
            onClose={onClose}
            handleReload={handleReload}
            handleRemoveBoxes={handleRemoveBoxes}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
}

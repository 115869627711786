import React, { useEffect, useState, useCallback } from "react";
import { useStateValue } from "../../State";
import "./messaging.css";
import MessagingSuccess from "./Components/MessagingSuccess";
import MessagingError from "./Components/MessagingError";
import MessagingLoading from "./Components/MessagingLoading";
import MessagingZeroMessages from "./Components/MessagingZeroMessages";
import ContentBoxMUI from "../../Components/ContentBoxMUI";
import Languages from "../../translations";
import Typography from "@mui/material/Typography";
import { SESSION_KEYS } from "../../Contants";
import { types } from "../../Reducers/actionTypes";
import moment from "moment";
export const MessagingErrorMsg = () => {
  return (
    <Typography color="textSecondary">{Languages.errorUnknown}</Typography>
  );
};

export const InboxView = ({
  messages,
  loadMessagesState,
  errorCode,
  refresh,
  showNew,
  setAllRead,
  setAllReadLoading,
  openMessageThread,
}) => {
  switch (loadMessagesState) {
    case undefined:
      return (
        <MessagingError
          refresh={refresh}
          showNew={showNew}
          errorCode={errorCode}
        />
      );
    case "NEVER_LOADED":
      return <MessagingLoading refresh={refresh} showNew={showNew} />;
    case types.LOADING_MESSAGE_DATA:
      return <MessagingLoading refresh={refresh} showNew={showNew} />;
    case types.MESSAGE_DATA_LOAD_OK:
      return (
        <MessagingSuccess
          messages={messages}
          refresh={refresh}
          showNew={showNew}
          setAllRead={setAllRead}
          setAllReadLoading={setAllReadLoading}
          openMessageThread={openMessageThread}
        />
      );
    case types.MESSAGE_DATA_LOAD_FAILED:
      return (
        <MessagingError
          refresh={refresh}
          showNew={showNew}
          errorCode={errorCode}
        />
      );

    default:
      return <MessagingLoading refresh={refresh} showNew={showNew} />;
  }
};

const Messaging = (props) => {
  const { state, actions } = useStateValue();
  const { messaging } = state;
  console.log(messaging);
  const [mount, setMount] = useState(true);
  const [waitingAllReadInfo, setWaitingAllReadInfo] = useState(false);
  const fiveMinutesAgo = moment().subtract(5, "minutes");
  useEffect(() => {
    if (
      messaging.messageData.messageThreadAnswered ||
      moment(messaging.messageData.lastLoaded).isBefore(fiveMinutesAgo)
    ) {
      refresh();
    }
  }, [messaging.messageData.messageThreadAnswered]);

  const openMessageThread = (messageThread) => {
    console.log(messageThread);
    actions.triggerUpdateMessageThreadId(messageThread);
    props.history.push("/messaging/openmessage/" + messageThread);
  };

  /*scrollToTop = () => {
    let top = this.topFocus.current;
        top.scrollIntoView({alignToTop: true});
  }*/

  useEffect(() => {
    if (waitingAllReadInfo) {
      if (
        state.messaging.setAllMessagesRead.status ===
        types.SET_ALL_MESSAGES_READ_SUCCESS
      ) {
        actions.triggerAddToSnackBarQueue({
          color: "#000000",
          message: Languages.msgMarkMessagesSuccess,
        });
        setWaitingAllReadInfo(false);
        sessionStorage.setItem("messagingNew", JSON.stringify(true));
      }
      if (
        state.messaging.setAllMessagesRead.status ===
        types.SET_ALL_MESSAGES_READ_FAILED
      ) {
        actions.triggerAddToSnackBarQueue({
          color: "#000000",
          message:
            Languages.msgMarkMessagesFailed +
            " " +
            state.messaging.setAllMessagesRead.Code,
        });
        setWaitingAllReadInfo(false);
        sessionStorage.setItem("messagingNew", JSON.stringify(true));
      }
    }
  }, [
    actions,
    state.messaging.setAllMessagesRead.status,
    state.messaging.setAllMessagesRead.Code,
    waitingAllReadInfo,
  ]);

  const showNew = () => {
    props.history.push({
      pathname: "/messaging/newmessage",
      state: { from: props.history.location.pathname },
    });
  };

  const refresh = () => {
    actions.triggerLoadMessageData();
  };

  const setAllRead = () => {
    actions.triggerSetAllMessagesRead();
    setWaitingAllReadInfo(true);
  };

  const [forceRerender, setForceRerender] = useState(false);

  return (
    <ContentBoxMUI header={Languages.msgInboxTitle}>
      <div className="messagingMinHeight" /*ref={this.topFocus}*/>
        <InboxView
          loadMessagesState={messaging.messageData.state}
          messages={messaging.messageData.messages}
          errorCode={messaging.messageData.Code}
          refresh={refresh}
          showNew={showNew}
          setAllRead={setAllRead}
          openMessageThread={openMessageThread}
          setAllReadLoading={
            state.messaging.setAllMessagesRead.status ===
            types.LOADING_SET_ALL_MESSAGES_READ
          }
        />
      </div>
    </ContentBoxMUI>
  );
};

export default Messaging;

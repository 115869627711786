import { createTheme, adaptV4Theme } from '@mui/material/styles';
// import { purple, green } from "@material-ui/core/colors";
//import purple from "@material-ui/core/colors/purple";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import orange from "@material-ui/core/colors/orange";
import pink from "@material-ui/core/colors/pink";
import grey from "@material-ui/core/colors/grey";
//import white from "@material-ui/core/colors/grey";
//import blue from "@material-ui/core/colors/blue"
// import deeppurple from "@material-ui/core/colors/deepPurple";
import indigo from "@material-ui/core/colors/indigo";
//import { lightBlue } from "@material-ui/core/colors";

export const theme = createTheme(adaptV4Theme({

  overrides: {
    MuiButton: {
      text: {
        borderRadius: 4,
        border: 0,
        color: "white",
        height: 40,
        padding: "0 30px",
        margin: 5,
      }
    },
    MuiInput: {
      input: {
        color: "black"
      }
    },
  },
  palette: {
    common: {
      black: '#000',
      white: '#fff',
      blue: '#4377BA',
      green: "#81C784", // only for contrast with black
      lightblue: "#86B8FF",
      greenwithblackorwhite: "#508053", // enough contrast with black or white both
    },
    background: {
      paper: "#4377BA", //'#4377BA',
      default: '#fafafa'
    },
    primary: {
      light: 'rgba(225, 245, 254, 1)',
      main: "#86B8FF",//"#6E7599", //"#0D1B2E",//'#000000', //"#000001", //"#6E7599",
      dark: 'rgba(2, 136, 209, 1)',
      contrastText: '#ffffff'
    },
    secondary: {
      light: '#ff4081',
      main: "#4377BA", // "#4377BA",//'#4377BA',
      dark: "#315687", //'#86B8FF',
      contrastText: '#fff'
    },
    error: {
      light: '#e57373',
      //main: '#e036da',
      main: "#EE5711",
      dark: '#d32f2f',
      contrastText: '#fff'
    }, // #C700EB on valkoisen ja täysin mustan kanssa tarpeeksi kontrastia oleva "violetti/magenta" (mutta #181818 mustan kanssa ei tarpeeksi kontrastia, ellei iso fontti 3.91)
    text: {
      primary: '#FFFFFF',
      secondary: "#181818", //"#0D1B2E",// "#000001", //'#58595A',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)'
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  status: {
    danger: "orange",
  },
  typography: {
    useNextVariants: true,
  }
}));

// search the app for sporttheme to find all the place where you need to add changes if you add a completely new theme

export const orangetheme = createTheme({
  palette: {
    primary: orange,
    secondary: pink,
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  status: {
    danger: "red",
  },
  typography: {
    useNextVariants: true, // migration to typography v2. don't remove this typography section unless documentation tells you to
  },
  background: {
    paper: '#000001',
    default: '#fafafa'
  },
})

export const sporttheme = createTheme({
  palette: {
    primary: green,
    secondary: orange,
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  status: {
    danger: "red",
  },
  typography: {
    useNextVariants: true,
  }
})


export const greytheme = createTheme({
  palette: {
    primary: grey,
    secondary: indigo,
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  status: {
    danger: "red",
  },
  typography: {
    useNextVariants: true,
  }
})
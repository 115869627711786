import React from "react";
import {DiscType} from "../../Layouts/DiscussionBooking/DiscussionBookingLayout";
import Languages from "../../translations";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import Grid from '@mui/material/Grid';
import DaisyLogo from '../../images/Family-21.png';
import MoreTimeIcon from '@mui/icons-material/MoreTime';



const BookableButtonItem = ({ bookableType, bookDiscussion }) => {
  return(
    <Card>
      <CardActionArea onClick={bookDiscussion}>
        <CardMedia 
          sx={{backgroundColor: "#D7E3ED"}}  
          component="img"
          image={DaisyLogo}
          alt="DaisyFamily logo"
        />
      <CardContent sx={{textAlign: "left", backgroundColor: "#002E6D"}}>
        <b><DiscType type={bookableType} /></b>
        <br />
        <br />
        <Typography variant="body2">{Languages.discMakeAnAppointment} <MoreTimeIcon/></Typography>
      </CardContent>
      </CardActionArea>
    </Card>
  )
}


export default BookableButtonItem
import React, { useState, useEffect } from "react";
import {useStateValue} from "../../../State";
import "../vasu.css";

import Moment from 'moment';

import CommentsReadOnly from '../CommentsReadOnly';

import ContentBoxMUI  from "../../../Components/ContentBoxMUI";
import Languages      from "../../../translations";

import SwipeableViews from 'react-swipeable-views';

import MobileStepper  from '@mui/material/MobileStepper';
import Box              from '@mui/material/Box'
import Button         from '@mui/material/Button';
import Divider        from '@mui/material/Divider';
import { Typography }    from "@mui/material";
import KeyboardArrowLeft  from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

const VasuSpecialSupportSubPart = (props) => {

  const { state/*, actions*/ } = useStateValue();
  const VasuData = state.vasu;
  const Table5      = state.vasu.Table5;
  
  const { SelectedSlideIndex } = props;

  //const [VasuChildNotes,  setVasuChildNotes]  = useState(_cloneDeep(Table1.Data.VasuChildNotes));
  const [SlideIndex, setSlideIndex]       = useState(SelectedSlideIndex);
  const [MaxSlideCount, setMaxSlideCount] = useState(7);
  const [Title, setTitleText]             = useState(null);
  
  useEffect( () => {
    //console.log("VasuPreparingSubPart. useEffect. SlideIndex: "+SlideIndex+"\n Table2: ", Table2);
    setTitleText(TitleText(SlideIndex, Table5));
  },[SlideIndex, Table5]);

  const handleChangeIndex = (e, index) => {
    //console.log("VasuDiscussionSubPart. Handle swipe. uusi slide: ",e," edellinen : ",index);
    setSlideIndex(e);
  };
  
  const handlePrev = () =>
  {
    var index = SlideIndex;
    if (SlideIndex > 0)
    {
      index--;
      setSlideIndex(index);
    }
  };

  const handleNext = (maxCount) =>
  {
    var index = SlideIndex;
    if (SlideIndex < (MaxSlideCount-1))
    {
      index++;
      setSlideIndex(index);
    }
  };

  const SlideText = () => {
    let slides = [{Text: Languages.VasuSupportSubTitle1}, 
                  {Text: Languages.VasuSupportSubTitle2},
                  {Text: Languages.VasuSupportSubTitle3},
                  {Text: Languages.VasuSupportSubTitle4},
                  {Text: Languages.VasuSupportSubTitle5},
                  {Text: Languages.VasuSupportSubTitle6},
                  {Text: Languages.VasuSupportSubTitle7}];
    return slides;
  };
  
  const TitleText = (Index, Table2) => {
    //console.log("VasuDiscussionSubPart. TitleText. Index: ", Index);
    let Title = Index === 0 ? Languages.VasuSpecialSupport:
                Index === 1 || Index === 2 || Index === 3 ? Languages.VasuSupportTitle1 : Languages.VasuSupportTitle2;
    return Title;
  };

  if (VasuData.state === "INITIAL_STATE" )
  {
    return (
      <div className="VasuSpecialSupportSubPart"></div>
    );
  }
  else 
  {
    let slides = SlideText();
    return (
      <div className="VasuSpecialSupportSubPart">
        <Box>
          <SwipeableViews className     = {"col-12 textCenter slide pt-3"}
                          index         = {SlideIndex} 
                          key           = {SlideIndex}
                          onChangeIndex = {handleChangeIndex} >
            {slides.map((slide, index) => { 
              return ( 
                <div key={index}>
                  <Box  className="br-top col-12" sx={{ backgroundColor: "#fceebf"}}>
                    <Typography color="textSecondary" sx={{textAlign:"center", fontWeight: 'Bold', fontSize: 18, color: "#424242", pt:1}}>{Title}</Typography>
                    <MobileStepper style={{backgroundColor: "#fceebf", color: "black", padding: 0}}
                                  //variant="progress" <= viiva
                                  variant="text"
                                  steps={MaxSlideCount}
                                  position="static"
                                  //LinearProgressProps={{width: "100%"}}
                                  activeStep={SlideIndex}
                                  //className={classes.root}
                                  nextButton={ <Button size="small" style={{color: "black", opacity: SlideIndex === (MaxSlideCount-1) ? "0.5" : "" }} onClick={handleNext} disabled={SlideIndex === (MaxSlideCount-1)}>{Languages.next} <KeyboardArrowRight /> </Button> }
                                  backButton={ <Button size="small" style={{color: "black", opacity: SlideIndex === 0 ? "0.5" : ""}} onClick={handlePrev} disabled={SlideIndex === 0}> <KeyboardArrowLeft /> {Languages.prev}  </Button> }/>
                  </Box>
                  <Box className="br-btm col-12" sx={{boxShadow: 2, mb: 3, pb: 1, backgroundColor: "white"}}>
                    <Typography color="textSecondary" sx={{textAlign:"center", fontWeight: 'Medium', fontSize: 16, color: "#424242", pt:1}}>{slide.Text}</Typography>
                  </Box>
                  </div>
              );                  
            })}
          </SwipeableViews>
            
          {SlideIndex > 7 ? null :
            <Box  className="col-12">
              <CommentsReadOnly Title1  = {<Typography color="textSecondary" sx={{textAlign:"left", fontWeight: 'Bold', fontSize: 16, color: "#424242", pt:1, ml:1}}>{Languages.StaffNotes} </Typography>}
                                Title2  = {SlideIndex === 1 || SlideIndex === 2 || SlideIndex === 3 || SlideIndex === 4 ? <Typography color="textSecondary" sx={{textAlign:"left", fontWeight: 'medium', fontSize: 14, color: "#424242", ml:1}}>{Languages.IssuesRaisedAgreed} </Typography> : null}
                                Title3  = {null}
                                BgColor = {'#fceebf'} 
                                Data    = {{Text: Table5.Data[SlideIndex].StaffNotes.Text, /* Saved: Moment(Table2.Data[SlideIndex].StaffNotes.Saved).format('DD.MM.YYYY')*/}}>
              </CommentsReadOnly>
            </Box>
            
          }
          
          
          {SlideIndex === 0 ? null :
          <div>

          {Table5.Data[SlideIndex].Evaluations.map((evaluation, eindex) => { 
            return ( 
              <div key={eindex}>
                <Box  className="col-12">
                  <CommentsReadOnly Title1  = {SlideIndex >= 5 && eindex === 0 ? <Typography color="textSecondary" sx={{textAlign:"left", fontWeight: 'Bold', fontSize: 16, color: "#424242", pt:1, ml:1}}>{Languages.StaffNotes} </Typography> : null} 
                                    Title2  = {null}
                                    Title3  = {(SlideIndex < 5 && eindex === 0 ) || (SlideIndex >= 5 && eindex === 1) ? <Typography color="textSecondary" sx={{textAlign:"left", fontWeight: 'Bold', fontSize: 16, color: "#424242", pt:1, ml:1}}>{Languages.Evaluation} </Typography> : null}
                                    BgColor = {'#fceebf'} 
                                    Data    = {{Text: evaluation.Text, /* Saved: Moment(evaluation.Saved).format('DD.MM.YYYY')*/}}>
                  </CommentsReadOnly>
                </Box>
              </div>
            );                  
          })}
          </div>
          }

        </Box>
      </div>
    );
  }
}

export default VasuSpecialSupportSubPart;
import React from 'react';
import { Redirect } from 'react-router-dom';
import { LOGOUT_REASONS } from "../../Api";
import InfoModal from '../../Components/Modals/InfoModal';
import Languages from '../../translations';
import Button from "@mui/material/Button";
import { useAuth } from '../../Auth/AuthProvider';

const LogoutLayout = ({ history }) => {
  const { isAuthenticated, lastLogoutReason } = useAuth();

  if (!isAuthenticated && lastLogoutReason === LOGOUT_REASONS.automatic) {
    return (
      <div>
        <InfoModal modalOpen={true} headerText={Languages.logoutAutoTitle} allowClose={false}
          actions={
            <Button
              color="secondary"
              variant="contained"
              size="medium"
              onClick={() => history.push("/login")}
              disabled={false}
            >
              OK
        </Button>
          }
        >
          {Languages.logoutAutoSessionOldInfo}
        </InfoModal>
      </div>
    )
  }

  return (<Redirect to={{
    pathname: "/login"
  }} />)
}

export default LogoutLayout;
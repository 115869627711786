import React from "react";
import { Router } from "react-router";

class TestRouter extends Router {
  constructor(props) {
    super(props);
    props.history.listen((location, action) => {
      // console.log(`The last navigation action was ${action}`, JSON.stringify(props.history, null,2));
    });
  }
}

export default TestRouter;
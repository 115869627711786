import React, { useState, useEffect } from "react";
import {useStateValue} from "../../State";
import Typography from "@mui/material/Typography";
import Accordion         from '@mui/material/Accordion';
import AccordionSummary  from '@mui/material/AccordionSummary';
import AccordionDetails  from '@mui/material/AccordionDetails';
import ExpandMoreIcon         from '@mui/icons-material/ExpandMore';
import Languages              from '../../translations';
//import {isAnyPropUndefinedOrNull} from "../../utils";
import ContentBoxMUI          from "../../Components/ContentBoxMUI";
import Button                 from "@mui/material/Button";
import GuardianConsents       from "../GuardianConsents/GuardianConsents.jsx";
import Badge                  from "@mui/material/Badge";
import withStyles from '@mui/styles/withStyles';
import { history }            from "../../Router";
//import Icon                   from "@mui/material/Icon";
import ChildPermPermissionSummary from "./Components/ChildPermPermissionSummary";
import ChildPermPermissionSummary2 from "./Components/ChildPermPermissionSummary2";
import Divider from '@mui/material/Divider';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
import AttributionIcon from '@mui/icons-material/Attribution';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import { CardActionArea } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ReportIcon from '@mui/icons-material/Report';
//import PermissionsLoading from "./Components/PermissionsLoading";






const GeneralPerm = (props) => {

  
  const StyledBadge = withStyles(theme => ({
    badge: {
      //right: -20,
      ////top: 5,
      padding: '0 4px',
      //width: 25,
      //height: 25,
      borderRadius: 50,
      backgroundColor: "#EE5711",
    },
  }))(Badge);

  const StyledBadge2 = withStyles(theme => ({
    badge: {
      //right: -20,
      top: 5,
      padding: '0 4px',
      width: 25,
      height: 25,
      borderRadius: 50,
      backgroundColor: "#EE5711",
    },
  }))(Badge);

  const StyledBadge3 = withStyles(theme => ({
    badge: {
      right: -20,
     // top: 5,
      padding: '0 4px',
      width: 25,
      height: 25,
      borderRadius: 50,
      backgroundColor: "#EE5711",
    },
  }))(Badge);


  const [adultAgreement, setAdulAgreement] = useState('none');
  const [parentPanelOpen, setParentPanelOpen] = useState(true);
  const [childPermPermissionsPanelOpen, setChildPermPermissionsPanelOpen] = useState("block");

  const {state, actions} = useStateValue();
  const {mainstats, children, profilePictures} = state; 
  //console.log("mainstats", mainstats)
  const [mount, setMount] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (mount && (!mainstats.UnansweredParentConsents || !mainstats.UnansweredShortTermPermissions)) {
      actions.triggerLoadPermissionsMainStats();
      actions.triggerLoadChildren();
      setMount(false);
     
    }
  }, [mount, setMount, mainstats, actions]);

  useEffect(() => {
    handleOutsideOpen(mainstats)
    console.log(mainstats.openAdultConsents)
    }, [mainstats.openAdultConsents]);
    
    const handleOutsideOpen = (mainstats) => {
      if(mainstats.openAdultConsents === true) {
      openAdultCard()
      mainstats.openAdultConsents = false
      }
      else null
    }
    

  const openAdultCard = () => {
    setAdulAgreement("block")
    setChildPermPermissionsPanelOpen("none")
    setTimeout(scrollToAdultAccordion, 600)
  }

  const scrollToAdultAccordion = () => {
    const section = document.querySelector("#adulCardScroll");
    section.scrollIntoView( { behavior: 'smooth', block: 'start' } )
  };

  const scrollPermissionTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  const closeAdultCard = () => {
    setAdulAgreement("none")
    setChildPermPermissionsPanelOpen("block")
    scrollPermissionTop()
  }

  const moveToPermQueries = () => {
    history.push("/permissionqueries");
  }

  const loadChildrenStatus = state.children.status;
  const loadingPermissionsStatus = state.municipalpermissionquestions.guardianConsent.status;
  const loadMainstatsPerms = state.mainstats.PermissionsStatus;

  //console.log(state)

  //if(loadChildrenStatus === 'LOADING_CHILDREN' || loadingPermissionsStatus === 'LOADING_GUARDIAN_CONSENTS' || loadMainstatsPerms === 'LOADING_PERMISSIONS_MAINSTATS' ? 
    //  <p>hiiohoi</p>
    //{loadChildrenStatus === 'LOADING_CHILDREN' || loadMainstatsPerms === 'LOADING_PERMISSIONS_MAINSTATS' ?
 //<PermissionsLoading />
  
 // else if(loadChildrenStatus === 'LOADING_CHILDREN_SUCCESS' && loadingPermissionsStatus === 'LOADING_GUARDIAN_CONSENTS_SUCCESS' && loadMainstatsPerms === 'LOADING_PERMISSIONS_MAINSTATS_SUCCESS') {
  return (
    <ContentBoxMUI>
   <div style={{display: "inline-flex", width: "100%"}}>
   <Card sx={{width: "48%", marginRight: "2vw", '&:hover': {backgroundColor: "#002E6D", boxShadow: "20px 20px 50px grey"}}}>
     <CardActionArea onClick={moveToPermQueries}>
     <CardContent sx={{textAlign: "-webkit-center", textAlign: "center", justifyContent: "center", /*display: "grid",*/ width: "100%", minHeight: "11rem"}}>
        <StyledBadge2 
          data-testid="unansweredShortTermPermissions-title" 
          badgeContent={mainstats.UnansweredShortTermPermissions === 0 ? null : mainstats.UnansweredShortTermPermissions}
          color="error"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Avatar sx={{ bgcolor: "#4377BA", border: "2px solid white", marginInline: "auto", width: 56, height: 56 }}>
            <QuestionAnswerIcon fontSize="large" />
          </Avatar>
        </StyledBadge2>
        <Typography sx={{marginTop: "revert"}} variant="h6" component="h2">{Languages.permPermissionQueries}</Typography>
     </CardContent>
      <CardActions sx={{backgroundColor: "white"}}>
      <p style={{color: "black", fontSize: "large", textAlign: "center", width: "100%", minHeight: "100%"}}>{mainstats.UnansweredShortTermPermissions === 0 ? <i>{Languages.permNoUnansweredPermissions}</i> : <b>{Languages.permUnansweredPermissions}!</b>}</p>
      </CardActions>
      </CardActionArea>
   </Card>

   <Card sx={{width: "48%", '&:hover': {backgroundColor: "#002E6D", boxShadow: "20px 20px 50px grey"}}}>
     <CardActionArea onClick={openAdultCard}>
     <CardContent sx={{textAlign: "-webkit-center", textAlign: "center", justifyContent: "center", /*display: "grid",*/ width: "100%", minHeight: "11rem"}}>
     <StyledBadge2 
              data-testid="unansweredParentConsents-title" 
              badgeContent={mainstats.UnansweredParentConsents === 0 ? null : mainstats.UnansweredParentConsents}
              color="error"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
       <Avatar sx={{ bgcolor: "#4377BA", border: "2px solid white", marginInline: "auto", width: 56, height: 56, }}>
        <AttributionIcon fontSize="large" />
        </Avatar>
        </StyledBadge2>
        <Typography sx={{marginTop: "revert"}} variant="h6" component="h2" >{Languages.permGuardiansConsents}</Typography>
     </CardContent>
      <CardActions sx={{backgroundColor: "white"}}>
      <p style={{color: "black", fontSize: "large", textAlign: "center", width: "100%", height: "100%"}}>{mainstats.UnansweredParentConsents === 0 ? <i>{Languages.permNoUnansweredPermissions}</i> : <b>{Languages.permUnansweredPermissions}!</b>}</p>
      </CardActions>
      </CardActionArea>
   </Card>
   </div>


      <div style={{display: adultAgreement}}>
      <Typography id="adulCardScroll" style={{marginTop: "10px"}} variant="h6" component="h2">{Languages.permGuardiansConsents}</Typography>
      

        
        <Accordion id="test_Parent_PermQuestions"
          style={{ border: "10px", borderStyle:"solid", borderColor: "#4377BA", backgroundColor: "#ffffff", color: "#181818", marginTop:"10px" }}
          expanded={parentPanelOpen}
          //onChange={() => setParentPanelOpen(!parentPanelOpen)}
          onChange={closeAdultCard}
        >

          <AccordionSummary
            expandIcon={<CloseIcon title={Languages.generalClose} sx={{color: "#4377BA"}} />}
            aria-controls={Languages.permGuardianPermQPanel}
            id="test_parental-perm-permissions"
            data-testid={"parent-perm-permissions"}
            
          >
          {/*  <StyledBadge 
              data-testid="unansweredParentConsents-title" 
              badgeContent={mainstats.UnansweredParentConsents === 0 ? null : mainstats.UnansweredParentConsents}
              color="error"
            >
              <Typography>{Languages.permGuardiansConsents}</Typography>
            </StyledBadge>
        */}
            {mainstats.UnansweredParentConsents === 0 ?
            <div style={{borderLeft: "solid 6px #006633", backgroundColor: "#DCEEDF", display: "-webkit-box", padding: "10px"}}>
                    <DoneAllIcon sx={{color: "#006633"}}fontSize="medium"/>&nbsp;&nbsp;<Typography>{Languages.permNoUnansweredPermissions}</Typography> 
                  {/*<Typography>{child.FirstName + ": " + Languages.permNoUnansweredPermissions}</Typography>*/} 
                  </div>
                  
                  : 

                  <div style={{borderLeft: "solid 6px #EE5711", backgroundColor: "#FFEBD4", display: "-webkit-box", minWidth: "40%", padding: "10px"}}>
                    <ReportIcon sx={{color: "#EE5711"}}fontSize="medium"/>&nbsp;&nbsp;<Typography>{mainstats.UnansweredParentConsents + ' ' + "vastaamatonta lupaa!" }</Typography> 
                  {/*<Typography>{child.FirstName + ": " + Languages.permNoUnansweredPermissions}</Typography>*/} 
                  </div>
              }


          </AccordionSummary>

          <AccordionDetails>
            <GuardianConsents 
              gcPanelOpen={parentPanelOpen}
            />
          </AccordionDetails>
          <Button onClick={closeAdultCard} sx={{color: "#4377BA", justifyContent: "center", display: "flex"}} aria-label="Hide parent's agreements" component="span" title={Languages.generalClose}>
            {Languages.generalClose}
          </Button>
        </Accordion>
        </div>

        <br />
   <br />
        <Divider />
        <br />
      <div style={{display: childPermPermissionsPanelOpen}}>
      <Typography 
      variant="h6" 
      component="h2"
      id="child-permissions-header-2-scroll"
      >{Languages.permChildrenPermissions}
      
      <StyledBadge3 
            data-testid="unansweredParentConsents-title" 
            badgeContent={mainstats.TotalUnansweredChildPermPermissions === 0 ? null : mainstats.TotalUnansweredChildPermPermissions}
            color="error"
          ></StyledBadge3>
      </Typography>

{/*}
      <Accordion
        style={{backgroundColor: "#4377BA"}}
        id="test_Childrens_perm_permission_panel"
        expanded={childPermPermissionsPanelOpen}
        onChange={() => setChildPermPermissionsPanelOpen(!childPermPermissionsPanelOpen)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="childrens-permission-questions-panel"
          id="test_childrens-perm-permissions"
          data-testid={"parent-perm-permissions"}
        >
          <StyledBadge 
            data-testid="unansweredParentConsents-title" 
            badgeContent={mainstats.TotalUnansweredChildPermPermissions === 0 ? null : mainstats.TotalUnansweredChildPermPermissions}
            color="error"
          >
            <Typography>{Languages.permChildrenPermissions}</Typography>
          </StyledBadge>
        </AccordionSummary>

        <AccordionDetails>
          <div>
            <Typography
            className="mb-3">{Languages.permEachChildPage}</Typography>

            <Typography>{mainstats.TotalUnansweredChildPermPermissions === 0 ? null : Languages.permUnansweredPermissions}</Typography>

            <ChildPermPermissionSummary
              children={children}
              mainstats={mainstats}
            />
            
          </div>
        </AccordionDetails>


      </Accordion>*/}
          
      <List>
      <ChildPermPermissionSummary2
              children={children}
              mainstats={mainstats}
              profilePictures={profilePictures}
            />
            </List>
            </div>
    </ContentBoxMUI>


    

  )
  
        

}

export default GeneralPerm;
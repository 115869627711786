import isFunction from "lodash/isFunction";
import { LOGOUT_REASONS } from "../Api";
import { authActionTypes } from "../Reducers/authActionTypes";
import { getBackendPublicUrl, getMyUrl, redirectBrowser } from "../utils";
import { AuthStatus } from '../State';
import Languages from "../translations";
import { SessionManager } from ".";

const buildSamlRedirectUrl = (samlOpts) => {
  const params = [
    {
      key: "SAMLRequest",
      value: samlOpts.SamlRequest
    },
    {
      key: "Signature",
      value: samlOpts.Signature
    },
    {
      key: "SigAlg",
      value: samlOpts.SigAlg
    },
    {
      key: "RelayState",
      value: samlOpts.RelayState
    }
  ]
  // let samlRequestUrl = buildEncodedUrl(samlOpts.CallUrl, params, "SAMLRequest");
  let samlRequestUrl = samlOpts.CallUrl + "?" + samlOpts.SignedString + "&Signature=" + encodeURIComponent(samlOpts.Signature)
  // samlRequestUrl = `&SAMLRequest=${samlOpts.SamlRequest}`;

  return samlRequestUrl;
}

export const loginAction = async (authClient, setLoading, dispatchAuthState, params = {}) => {
  setLoading(true);

  const result = await authClient.authenticate(params);

  setLoading(false);

  if (result.authenticated) {
    dispatchAuthState({
      type: authActionTypes.LOGIN_USER,
      payload: {
        user: authClient.getUser(),
        personId: authClient.getUserPersonId(),
        ...result
      }
    });
  } else {
    dispatchAuthState({
      type: authActionTypes.LOGIN_USER_FAILED,
      payload: result
    });
  }
}

export const refreshSessionAction = async (authClient, setLoading, dispatchAuthState) => {
  setLoading(true);

  const result = await authClient.refreshSession();

  setLoading(false);

  if (result.authenticated) {
    const user = authClient.getUser();
    const userPersonId = authClient.getUserPersonId();

    dispatchAuthState({
      type: authActionTypes.LOGIN_USER,
      payload: {
        user: user,
        personId: userPersonId,
        ...result
      }
    })
  }
  
  /*
  Ei lähetetä login failed reducer actionia koska käyttäjä ei aloittanut loginia 
  vaan sovellus kokeili refresh tokenilla että onko se vielä voimassa.
  Tätä funktiota pitäisi kutsua vain selain refreshin jälkeen. Muulloin axioksen interceptor 
  hoitaa tokenin refreshin automaattisesti.
  */ 
}

export const logoutAction = async (authClient, setLoading, appReset, dispatchAuthState, setWarningPopup, reason) => {
  setLoading(true);
  const result = await authClient.logout(reason);

  if (isFunction(appReset))
    appReset();

  if (result.ssoLogoutRequired) {
    setLoading(false);
    //console.log(result.ssoSettings);
    //const samlRequestUrl = buildSamlRedirectUrl(result.ssoSettings);
    const logoutUrl = getBackendPublicUrl() + "/api/esuomi/samlLogout";

    redirectBrowser(logoutUrl);
  }

  dispatchAuthState({
    type: authActionTypes.LOGOUT_USER,
    payload: {
      ...result,
      lastLogoutReason: reason,
    }
  });
  setWarningPopup({
    show: false
  })

  setLoading(false);
}

export const samlLogoutAction = async () => {
  const logoutUrl = getBackendPublicUrl() + "/api/esuomi/samlLogout";
  console.log("samlLogoutAction SSO Logout => Redirecting to", logoutUrl);
  redirectBrowser(logoutUrl);
}

export const resetPasswordAction = async (authClient, setSaving, newPassword, setPasswordChangeSuccess) => {

  setSaving({ onGoing: true });
  console.log("Reseting password");
  const result = await authClient.setPassword(newPassword);
  console.log("Reseting password done", result);
  if (!result.error) {

    setPasswordChangeSuccess("success");
    console.log("resetPassword => success");
  } else {
    setPasswordChangeSuccess("failed");
    console.error("resetPassword => failed", result.errorDescription);
    setSaving({ onGoing: false, lastError: result.errorDescription });
  }
  setSaving({ onGoing: false });
}
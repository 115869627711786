import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ButtonGroup from "@mui/material/ButtonGroup";
import { styled } from "@mui/material/styles";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import NightShelterIcon from "@mui/icons-material/NightShelter";
import Drawer from "@mui/material/Drawer";

const CTBAddDrawer = ({ open, onClose, HandleNewRes, handleNewAbs }, props) => {
  const handleNewResClick = () => {
    HandleNewRes();
    onClose();
  };

  const handleNewAbsClick = () => {
    handleNewAbs();
    onClose();
  };

  return (
    <Drawer
      anchor={"bottom"}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          mb: "40px",
        },
      }}
      sx={{ zIndex: 0 }}
      transitionDuration={{ enter: 600, exit: 400 }}
    >
      <Box
        sx={{
          width: "auto",
          height: "auto",
          backgroundColor: "white",
          borderTopLeftRadius: "inherit",
          borderTopRightRadius: "inherit",
        }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        role="presentation"
      >
        <Puller onClick={onClose} />
        <Box sx={{ width: "100%", mt: 4, mb: 1, display: "flex" }}>
          <ButtonGroup
            orientation="vertical"
            aria-label="vertical contained button group"
            variant="text"
            sx={{
              width: "100%",
              "& .MuiButtonGroup-grouped:not(:last-of-type)": {
                borderColor: "#ffffff",
              },
              mb: 2,
            }}
            disableElevation
            size="large"
          >
            <Button
              onClick={handleNewResClick}
              sx={{
                height: 80,
                "& .MuiButton-endIcon": {
                  position: "absolute",
                  right: "3rem",
                },
                "& .MuiButton-startIcon": {
                  position: "absolute",
                  left: "3rem",
                },
                mr: 1,
              }}
              endIcon={
                <Typography textAlign="center" color="#8a8a8a">
                  <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
                </Typography>
              }
              startIcon={
                <Avatar
                  sx={{
                    bgcolor: "#f0f2f5",
                    width: 56,
                    height: 56,
                  }}
                >
                  <MoreTimeIcon
                    size="large"
                    color="success"
                    sx={{ width: 38, height: 38 }}
                  />
                </Avatar>
              }
            >
              <Typography
                color="textSecondary"
                fontWeight="bold"
                fontSize="14px"
              >
                {"Uusi Hoitoaika"}
              </Typography>
            </Button>
            <Button
              onClick={handleNewAbsClick}
              sx={{
                height: 80,
                "& .MuiButton-endIcon": {
                  position: "absolute",
                  right: "3rem",
                },
                "& .MuiButton-startIcon": {
                  position: "absolute",
                  left: "3rem",
                },
              }}
              startIcon={
                <Avatar sx={{ bgcolor: "#f0f2f5", width: 56, height: 56 }}>
                  <NightShelterIcon
                    color="warning"
                    sx={{ width: 42, height: 42 }}
                  />
                </Avatar>
              }
              endIcon={
                <Typography textAlign="center" color="#8a8a8a">
                  <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
                </Typography>
              }
            >
              <Typography
                color="textSecondary"
                fontWeight="bold"
                fontSize="14px"
              >
                {"Uusi Poissaolo"}
              </Typography>
            </Button>
          </ButtonGroup>
        </Box>
      </Box>
    </Drawer>
  );
};

export default CTBAddDrawer;

const Puller = styled(Box)(() => ({
  width: 30,
  height: 6,
  backgroundColor: "#cccccc",
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

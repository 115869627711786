import React, { useEffect } from "react"
import {useStateValue} from "../../State";
import { SessionManager } from "../../Auth";
import Languages from "../../translations"
import moment from "moment"
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HomeWorkIcon from '@mui/icons-material/HomeWork';


const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const DevelopmentNotification = () => {
  const [expanded, setExpanded] = React.useState(false);
  const { state } = useStateValue();
  const { settings } = state;
  const [ clientNotificationTexts, setClientTexts ] = React.useState([]);
  const [ language, setLanguage ] = React.useState("en");
  const [ validNotification, setValidNotification ] = React.useState(false)
  const [ startTime, setStartTime ] = React.useState("Invalid start time");
  const [ endTime, setEndTime ] = React.useState("Invalid end time");

  const userLang = SessionManager.getUserLanguage(); 

  useEffect(() => {
    validityChecker()
    getClientNotificationTexts()
    setLanguage(userLang)
  }, [])


  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const validityChecker = () => {
    let startTimeSetting =  settings.communeSettings.find(setting => setting.Key === "DaisyFamily/ClientNotification/StartTime");
    let endTimeSetting =  settings.communeSettings.find(setting => setting.Key === "DaisyFamily/ClientNotification/EndTime");
  
    if(startTimeSetting !== null && typeof startTimeSetting !== undefined && endTimeSetting !== null && typeof endTimeSetting !== undefined) {
      let formatStartTime = moment(startTimeSetting.ValueString, "YYYY-MM-DD").toDate()
      setStartTime(formatStartTime)
      let formatEndTime = moment(endTimeSetting.ValueString, "YYYY-MM-DD").toDate()
      setEndTime(formatEndTime);
      const dateToday = moment().toDate();
      const valid = moment(dateToday).isBetween(formatStartTime, formatEndTime)
      setValidNotification(valid)
    }
    else {
      setValidNotification(false)
    }
  }

  const getClientNotificationTexts = () => {
    const copyClientNotificationTexts = [...clientNotificationTexts]
    let clientTextFI = settings.communeSettings.find(setting => setting.Key === "DaisyFamily/ClientNotificationTextFI");
    if(clientTextFI != null && typeof clientTextFI != undefined) {
      copyClientNotificationTexts[0] = clientTextFI.ValueString
    }
    else {
      copyClientNotificationTexts[0] = Languages.awNotAvailableDevTextTrans
    }
    let clientTextEN = settings.communeSettings.find(setting => setting.Key === "DaisyFamily/ClientNotificationTextEN");
    if(clientTextEN != null && typeof clientTextEN != undefined) {
      copyClientNotificationTexts[1] = clientTextEN.ValueString
    }
    else {
      copyClientNotificationTexts[1] = Languages.awNotAvailableDevTextTrans + clientTextFI.ValueString
    }
    let clientTextSV = settings.communeSettings.find(setting => setting.Key === "DaisyFamily/ClientNotificationTextSV");
    if(clientTextSV != null && typeof clientTextSV != undefined) {
      copyClientNotificationTexts[2] = clientTextSV.ValueString
    }
    else {
      copyClientNotificationTexts[2] = Languages.awNotAvailableDevTextTrans + clientTextFI.ValueString
    }
    setClientTexts(copyClientNotificationTexts)
  }

  
  if(validNotification === true) {
    return(
      <>
        <Card sx={{ bgcolor: "#FFEBD4", border: "1px solid #EE5711", borderLeft: "7px solid #EE5711", margin: "5px" }}>
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: "#EE5711" }} aria-label="Daycare icon">
                <HomeWorkIcon />
              </Avatar>
            }
            title={<p style={{color: "black", fontSize: "large", marginBottom: "0"}}><b>{Languages.awClientsInform}</b></p>}
            subheader={moment(startTime).format("LL")}
          />
          <CardActions disableSpacing>
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label={Languages.awShowMore}
              title={Languages.awShowMore}
            >
              <ExpandMoreIcon sx={{color: "#EE5711" }}/>
            </ExpandMore>
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>  
              <Typography paragraph sx={{color: "black", }}>
                {language === "fi" &&
                  clientNotificationTexts[0]
                }
                {language === "en" &&
                  clientNotificationTexts[1]
                }
                {language === "sv" &&
                  clientNotificationTexts[2]
                }
              </Typography>
              <Typography variant="caption" sx={{color: "black"}}>
                {Languages.formatString(Languages.bbValidUntilBBCard, moment(endTime).format("LL"))}
              </Typography>
            </CardContent>
          </Collapse>
        </Card>
      </>
    )
  }   else return null
}


export default DevelopmentNotification
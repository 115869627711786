import React from 'react';
import AppBar from "@mui/material/AppBar";
import ToolBar from "@mui/material/Toolbar";
import GroupTwoTone from "@mui/icons-material/GroupTwoTone";
import FaceTwoTone from "@mui/icons-material/FaceTwoTone";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import withStyles from '@mui/styles/withStyles';
import Languages from "../../../translations";
import { useStateValue }  from "../../../State";
import CircularProgress from "@mui/material/CircularProgress";
import SupervisedUserCircleRoundedIcon from '@mui/icons-material/SupervisedUserCircleRounded';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material';

import PropTypes from 'prop-types';
// import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';

const styles = (theme) => ({

  participants: {
      paddingLeft:"15%",
        [theme.breakpoints.down('md')]: {
          paddingLeft:"0%", 
    },
  },
})

// function HideOnScroll(props) {
//   const { children, window } = props;
//   // Note that you normally won't need to set the window ref as useScrollTrigger
//   // will default to window.
//   // This is only being set here because the demo is in an iframe.
//   const trigger = useScrollTrigger({ target: window ? window() : undefined });

//   return (
//     <Slide appear={false} direction="down" in={!trigger}>
//       {children}
//     </Slide>
//   );
// }

// HideOnScroll.propTypes = {
//   children: PropTypes.element.isRequired,
//   /**
//    * Injected by the documentation to work in an iframe.
//    * You won't need it on your project.
//    */
//   window: PropTypes.func,
// };

const MessagingBar = React.forwardRef(({onButtonClick, showParticipants, showLoadingIcon, classes}, ref) => {
  
  const { state } = useStateValue();
  //const topMargin = useMediaQuery('(min-width:600px)') ? "58px" : "58px";

  return(
  <div>
{/* <HideOnScroll> */}
    <AppBar
      className="propsheader3"
      ref={ref}
      position="static"
      color="primary"
      style={{
        //marginTop: topMargin,
        backgroundColor: "#86B8FF",
        fontSize: "large",
        height: "auto",
        width: 'inherit',
        marginBottom: "10px"
      }}>
      <ToolBar color="primary">

      {showParticipants === true ? 

        <div style={{margin: "auto", maxWidth:"800px", cursor: "pointer"}}>

          <Button style={{ height: "auto", cursor: "pointer"}} color="secondary" onClick={onButtonClick}>
            <div style={{ display: "block", height: "auto", marginBottom: "-3px", cursor: "pointer" }}>
              { state.messaging.loadThread.truncatedParticipantsList !== undefined ?
                  state.messaging.loadThread.truncatedParticipantsList.map((participant, index) => {
                    return (

                      <Chip style={{ marginBottom: "3px", marginRight: "2px", cursor: "pointer" }}
                        size="small"
                        display="inline"
                        avatar={
                          <Avatar>
                            {participant.Type === "groupparent" ? <GroupTwoTone /> :
                              participant.Type === "centreparent" ? <GroupTwoTone /> :
                                participant.Type === "person" ? <FaceTwoTone /> :
                                  participant.Type === "parent_person" ? <FaceTwoTone /> :
                                    participant.Type === "groupemployee" ? <SupervisedUserCircleRoundedIcon /> :
                                      participant.Type === "centreemployee" ? <SupervisedUserCircleRoundedIcon /> : 
                                        participant.Type === "child_person" ? <GroupTwoTone /> :
                                      <GroupTwoTone />}
                          </Avatar>
                        }
                        id={participant.Id + "r"}
                        color={participant.Type === "parent_person" ? "secondary" : "secondary"}
                        key={participant.Id + index}
                        label={typeof participant.Name === "undefined" || participant.Name === null ? 
                                        Languages.msgUnknownPersonOrGroup : 
                                        (participant.Type === "child_person" ? participant.Name + " (" + Languages.msgCaretakers + ")" : participant.Name )}
                      />

                    )
                  })
              : null}
              <Chip style={{ marginBottom: "3px", marginRight: "2px", cursor: "pointer" }}
                size="small"
                display="inline"
                avatar={
                  <Avatar>
                    <FaceTwoTone />
                  </Avatar>}
                id="mer"
                color="secondary"
                label={Languages.msgMe} />
              {state.messaging.loadThread.numberOfNamesNotShown !== 0 &&
                <Chip style={{ marginBottom: "3px", marginRight: "2px", cursor: "pointer" }}
                  size="small"
                  display="inline"
                  id="howmanyothers"
                  color="secondary"
                  label={"+ " + state.messaging.loadThread.numberOfNamesNotShown + " " + Languages.msgOthers}>
                </Chip>}
            </div>
          </Button>
        </div>

      : null}

      {
        showLoadingIcon === true ? 
        <><div style={{textAlign: "center", width: "100%"}}>
          <CircularProgress color="secondary" style={{textAlign: "center"}}/>
        </div></>
        :
        null
      }

      </ToolBar>
    </AppBar>
    {/* </HideOnScroll> */}
  </div>
  );
})

export default withStyles(styles)(MessagingBar);
import React from "react";
import { useStateValue } from "../../State/";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import WarningIcon from "@mui/icons-material/Warning";
import { history } from "../../Router";
import Button from "@mui/material/Button";
import Languages from "../../translations";

const PermPermissionNote = () => {

  const { state, actions } = useStateValue();
  const { mainstats, children } = state;

  const answerNow = () => {
    history.push("/permissions", {})
  }

  if (!mainstats || !mainstats.UnansweredParentConsents) {
    return (null);
  }

  if (mainstats.UnansweredParentConsents > 0) {

    return (
      <Card style={{ backgroundColor: "#e036da" }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid container item xs={3} alignContent="center" justifyContent="center" alignItems="center">
              <WarningIcon />
            </Grid>
            <Grid item xs={9}>
              <Typography>{Languages.permInfoNeedsUpdating}</Typography>
              <Typography>{Languages.permNeedConsent}</Typography>
              <Button style={{ backgroundColor: "#ffca28", marginTop: "10px" }} onClick={answerNow} variant="contained">{Languages.permGoToPermissions}</Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

export default PermPermissionNote;
import React, { Component } from "react";
import Languages from "../../translations";
import ContentBoxMUI from "../../Components/ContentBoxMUI";
import BulletinBoard from "../../Components/BulletinBoard"


class BulletinBoardLayout extends Component {
  // Poista warninki tyhjästä constructorista
  // eslint-disable-next-line
  constructor(props) {
    super(props);
    /*this.state = {
      modalOpen: true
    };*/
  }

  close = () => {
    this.props.callback("CLOSEBULLETINBOARD");
  };

  render() {
    const { childId } = this.props; // TODO: read from route state

    return (
      <ContentBoxMUI hideMUIHeader={null}
        header={Languages.bbTitle}
        showArrowBack={true}
        setfunction={(status) => this.close("CLOSEBULLETIONBOARD")}
        hideHeader={true}>
        <div className="BulletinBoard-header textCenter">
          {Languages.bbHeaderText}
        </div>

        <BulletinBoard mode="full" fullViewOnClose={this.close} filter={childId} />
      </ContentBoxMUI>
    );
  }
}

export default BulletinBoardLayout;

import React                from  'react';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import './guidemodals.css'
import DaisyLogo from "../../images/DaisyFamily45px.png";
import Languages from "../../translations";



const GuideModalTitle = ({ handleClick, modalTitle }) => {
    return (
      <div className="/" id="toTheTopInGuide" >
      <div className="guide-modal-title-container">
        <div className="guide-modal-title-left-column" id="guide-modal-title-left-column">
          <img src={DaisyLogo} alt="Daisy-logo" /><h5>{Languages.guide}:</h5>
        </div>
        <div className="guide-modal-title-center-column">
          <h5>{modalTitle}</h5>
        </div>
        <div className="guide-modal-title-right-column">
          <IconButton
            onClick={() => handleClick()}
            title={Languages.generalClose}
            aria-label={Languages.generalClose}
            size="large"><CloseIcon style={{color: "#ffffff"}}/></IconButton>
        </div>
      </div>
      </div>
    );
}

export default GuideModalTitle
import React, {useState, useEffect} from "react";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import ChildNamePicture from "../../../Components/ChildNamePicture";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import _cloneDeep             from "lodash/cloneDeep";
import Languages from "../../../translations";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import ChildProfilePicture from "../../../Components/ChildProfilePicture";
import SendIcon from '@mui/icons-material/Send';

const ChildListPermissionQuery2 = ({data, sendPermissions, postIsLoading}) => {

  if (!data.IsValid) {
    return(null)
  }

  const [permissions, setPermissions] = useState(_cloneDeep(data.SentTo));
  const [warningPopUp, setWarningPopUp] = useState(false);

  const processChangingPermissionStatus = (childId, permissionBool) => {

    permissions.forEach((permission) => {
      if (permission.PersonId === childId) {
        permission.PermissionGranted = permissionBool;
      }
    })

    setPermissions(permissions);
    //console.log(permissions);
  }

  const checkValidity = () => {
    let isValidToSend = true;
    permissions.forEach((permission) => {
      if(permission.PermissionGranted === "null") {
        isValidToSend = false;
      }
    });
  
    if (!isValidToSend) {
      setWarningPopUp(true);
    }
    else {
      setWarningPopUp(false);
      formPermissionsToSend();
    }
  
  }
  
  const formPermissionsToSend = () => {
    let dataToSend = [];

    permissions.forEach((permission) => {
      dataToSend.push({
        ChildPersonId: permission.PersonId,
        Permission: permission.PermissionGranted,
        QueryPermId: data.MessageId,
      });
    })

    sendPermissions(dataToSend);
  }

  const closeNullWarningPopUp = () => {
    setWarningPopUp(false);
  }

  if (data.IsValid) {
    return(
      <React.Fragment>
        {permissions.map((child) => {
          return(
            <div key={child.PersonId} style={{marginTop: "15px", margin: "15px", display: "block", width: "95%", textAlign: "center"}}>
              <Grid container  >
              <Grid item xs={12}>
              
              <Card color="primary"  style={{margin: "auto", textAlign: "center", display: "inline-flex", width:"100%"}}>
                <CardContent style={{backgroundColor: "#4377BA", width: "90%", display: "inline-flex", textAlign:"center"}}>
                  <div style={{width: "25%", display: "block"}}>
                  <ChildProfilePicture childId={child.PersonId} /*childName={child.PersonName}*//>
                  <p>{child.PersonName}</p>
                  </div>
                  <div style={{textAlign: "center", width: "100%"}}>
                  <FormControl style={{/*marginTop: "24px",*/ textAlign: "center", width: "100%"}} component="fieldset" fullWidth={true} >
                    <FormLabel labelplacement="start" component="legend" style={{color: "#FFFFFF"}}></FormLabel>
                    <RadioGroup row aria-label={data.Title + " " + Languages.permIGivePermission} name={Languages.permIGivePermission} style={{display: "block"}}>
                      <FormControlLabel 
                        value={data.MessageId} 
                        checked={child.PermissionGranted === true ? true : false} 
                        control={<Radio color="primary" disableRipple={false}/>} 
                        label={Languages.permYes}
                        onChange={() => processChangingPermissionStatus(child.PersonId, true)}/>
                      <FormControlLabel 
                        value={data.MessageId} 
                        checked={child.PermissionGranted === false ? true : false}
                        control={<Radio color="primary" disableRipple={false}/>} 
                        label={Languages.permNo}
                        onChange={() => processChangingPermissionStatus(child.PersonId, false)}/>
                    </RadioGroup>
                  </FormControl>
                  </div>
                </CardContent>
              </Card>
              <Grid item xs={1}></Grid>
              </Grid></Grid>
            </div>
          )
        })
        }
        <br/>
        <div style={{textAlign: "center", marginBottom: "5px"}}>
          <Button disabled={postIsLoading} sx={{color:"white", backgroundColor: "#002E6D"}} variant="contained" onClick={checkValidity}><SendIcon />&nbsp;{Languages.msgSend}</Button>
        </div>

        <Dialog color="error" onClose={closeNullWarningPopUp} aria-labelledby="simple-dialog-title" open={warningPopUp}>
          <DialogContent sx={{backgroundColor: "#F9E0E5", borderLeft: "solid 5px #B30036"}}> 
            <div style={{textAlign: "center"}}>
              <Typography sx={{color: "black"}}>{Languages.permOneOrMoreChildrenNoAnswer}</Typography>
              <Typography sx={{color: "black"}}>{Languages.permAnswerAllChildren}</Typography>
              <br />
              <Button sx={{color:"#8F002B", border: "solid 1px #8F002B"}} variant="outlined" onClick={closeNullWarningPopUp}>OK</Button>
            </div>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    )
  }
}

export default ChildListPermissionQuery2;
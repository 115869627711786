import React from "react";
import BackToUpButton from "../GuideTools/BackToUpButton";
import AddAlarmIcon from '@mui/icons-material/AddAlarm'
import Languages from "../../../translations";
import makeStyles from '@mui/styles/makeStyles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import EventBusyIcon from '@mui/icons-material/EventBusy'
import SelectAllIcon from '@mui/icons-material/SelectAll'
import MenuIcon from '@mui/icons-material/Menu'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'


const useStyles = makeStyles({
  success: {
    color: 'rgb(129, 199, 132)',
    fontSize: 'xx-large',
  }
});



const AbsentBookingAccordionText = () => {
  const classes = useStyles();
    return(
        <div>
          <h4>{Languages.absentBookingIntroTitle}</h4>
          <br />
          <p className="guide-accordion-details-texts">{Languages.absentBookingIntro1} </p>
          <br />
          <br />
          <p className="guide-accordion-details-texts">{Languages.absentBookingIntro2}</p>
          <br />
          <br />
          <p className="guide-accordion-details-texts">{Languages.absentBookingIntro3}</p>
          <br />
          <br />
         <hr className="guide-accordion-details-line"/>
          <h4>{Languages.absentBookingMiddleTitle1}</h4>
          <br/>
          <div>
            <ol>
              <li className="guide-list-item-styles">{Languages.absentBookingListOne1} <SelectAllIcon /> {Languages.absentBookingListOne1_2}</li>
              <br />
              <li className="guide-list-item-styles">{Languages.absentBookingListOne2} <CheckCircleIcon className={classes.success}/> / <EventBusyIcon className={classes.success}/> {Languages.absentBookingListOne2_2}</li>
              <br />
              <li className="guide-list-item-styles">{Languages.absentBookingListOne3} <input className="PrivateSwitchBase-input-81" style={{width: '20px', height: '20px'}}id="test_NewBooking_Child0" type="checkbox" data-indeterminate="false" defaultChecked={true} readOnly /></li>
              <br />
              <li className="guide-list-item-styles">{Languages.absentBookingListOne4}</li>
              <br />
              <li className="guide-list-item-styles">{Languages.absentBookingListOne5}</li>
              <br />
              <li className="guide-list-item-styles">{Languages.absentBookingListOne6} <CheckCircleIcon className={classes.success}/></li>
            </ol>
          </div>
          <br />
          <hr className="guide-accordion-details-line"/>
          <h4>{Languages.absentBookingMiddleTitle2}</h4>
          <br />
          <div>
            <ol>
              <li className="guide-list-item-styles">{Languages.absentBookingListTwo1} <MenuIcon /> <NavigateNextIcon /> <EventBusyIcon /></li>
              <br />
              <li className="guide-list-item-styles">{Languages.absentBookingListTwo2} <input className="PrivateSwitchBase-input-81" style={{width: '20px', height: '20px'}}id="test_NewBooking_Child0" type="checkbox" data-indeterminate="false" checked={true} readOnly /></li>
              <br />
              <li className="guide-list-item-styles">{Languages.absentBookingListTwo3}</li>
              <br />
              <li className="guide-list-item-styles">{Languages.absentBookingListTwo4}</li>
              <br />
              <li className="guide-list-item-styles">{Languages.absentBookingListTwo5} <CheckCircleIcon className={classes.success}/></li>
            </ol>
          </div>
          <br />
          <BackToUpButton />
        </div>
    )
}

export default AbsentBookingAccordionText
import React, {useState, useEffect}                from  'react';
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import GuideModalTitle from './GuideModalTitle';
//import GuideModalKeywords from './BookingGuide/BookingGuideKeywords';
import GuideModalFooter from './GuideModalFooter';
import GuideModalContentManager from './GuideModalContentManager';
import Slide from '@mui/material/Slide';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import './guidemodals.css'
import ScrollToTopButton from "./GuideTools/ScrollToTopButton";
import Languages from '../../translations';



const Transition = React.forwardRef(function Transition(props, ref) {
  return (<Slide direction="down" ref={ref} {...props} />);
});

const GuideModal = ({ modalOpen=false, modalType, modalTitle, modalKeywords, modalContent, handleClick, allowClose=false }) => {

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));


    return (
      <>

      <Dialog TransitionComponent={Transition} fullScreen={fullScreen} open={modalOpen} id={"test_InfoModal"} /*maxWidth="xl" fullWidth={true}*/ scroll="paper" /*color="secondary.ligth"*/ aria-labelledby="df-guide-modal-dialog">

                <GuideModalTitle handleClick={handleClick} modalTitle={modalTitle}/>
                <GuideModalContentManager modalType={modalType}/>

                <GuideModalFooter handleClick={handleClick} />

      </Dialog>
      </>
    );
  };

  
  export default GuideModal;

 
import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Languages from "../../translations.js";

const setWomNoteOff = () => {
  localStorage.setItem("womDialogIsOpen", "off");
  localStorage.setItem("womDialogVersion", sessionStorage.getItem("WebAppVersion"));
}

const WomNotification = () => {

  const [womDialogIsOpen, setWomDialogIsOpen] = useState(true);


  // ***************************************************************

  // Tämä notifikaatio näytetään potentiaalisesti aina kun 
  // 1) Selaimella ei ole käyty tässä osoitteessa aiemmin
  // 2) LocalStorage on tyhjennetty
  // 3) .env-tiedostossa on päivitetty applikaation versionumeroa 

  // Kun haluat, että viestiä womilta ei näytetä, koska ei ole mitään viestitettävää, ota seuraava rivi pois kommenteista:
  // const messageFromWOM = null;
  // Ja kommentoi seuraava rivi pois:
  const messageFromWOM = ""

  // Kappalejaon saat laittamalla alempana koodissa /*messageFromWOM*/-kohdan alle <Typography paragraph={true}> Yksi tekstisi kappale tähän. </Typography> Seuraava kappale samalla tavalla.

  // ***************************************************************


  return localStorage.getItem('womDialogIsOpen') === "on" && messageFromWOM !== null &&
  <Dialog
    id={"test_WomNotification"}
    open={womDialogIsOpen}
    onClose={() => {
      setWomDialogIsOpen(false); 
      setWomNoteOff();
    }}
    scroll={"paper"}>
    <DialogTitle style={{ textAlign: "center" }}> {Languages.womNotification} </DialogTitle>

    <DialogContent>
      <DialogContentText>
        <Typography component={'span'} color="textPrimary">
          {/*messageFromWOM*/}
          <Typography component={'span'} style={{ display: "block", fontSize: "11pt", textAlign: "center" }} paragraph={true}> {Languages.womNotificationPara1} </Typography>
          <Typography component={'span'} style={{ display: "block", fontSize: "11pt", textAlign: "center" }} paragraph={true}> {Languages.womNotificationPara2} </Typography>
          <Typography component={'span'} style={{ display: "block", fontSize: "11pt", textAlign: "center" }} paragraph={false}> {Languages.womNotificationPara3} </Typography>
        </Typography>
      </DialogContentText>
      <Button
        id="test_DF_Notification_CloseBtn"
        onClick={() => {
          setWomDialogIsOpen(false);
          setWomNoteOff();
        }}>{Languages.generalClose}</Button>
    </DialogContent>
  </Dialog>;
}

export default WomNotification;
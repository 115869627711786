import { ApiBase } from "../internal";
import Moment from "moment";
import SessionManager from "../Auth/SessionManager";

export class NursingTimesApi extends ApiBase {
  constructor() {
    super();
    //this.loadDefaultNursingTimes = this.loadDefaultNursingTimes.bind(this);
  }

  async loadDefaultNursingTimes() {
    //console.log("NURSINGTIMES API. loadDefaultNursingTimes");
    const userLang = SessionManager.getUserLanguage();

    try {
      const response = await this.httpClient.get(
        "/nursingtimes/getdefaultnursingtimes/",
        {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": userLang === "en" ? "en-US" : userLang,
          },
        }
      );
      //console.log("NURSINGTIMES API. Response: ", response);
      const responseData = { status: 200, data: response.data };
      return responseData;
    } catch (error) {
      console.log(
        "NURSINGTIMES API. loadDefaultNursingTimes ERROR: ",
        error,
        "\n" + "response.status: ",
        error.response === undefined
          ? 502
          : error.response.status === undefined
          ? 502
          : error.response.status
      );
      // 502 Bad Gateway
      const responseData = {
        status:
          error.response === undefined || error.response.status === undefined
            ? 502
            : error.response.status,
      };
      return responseData;
    }
  }

  async saveDefaultNursingTimes(DefaultTimesData) {
    //= async () => {
    /*console.log("NURSINGTIMES API. saveDefaultNursingtimes: "+
                "\n StartTime: ", Moment(DefaultTimesData.StartTime).format('HH:mm'),
                "\n EndTime: ",   Moment(DefaultTimesData.EndTime).format('HH:mm'));*/
    const userLang = SessionManager.getUserLanguage();

    const DefaultTimes = {
      Start: Moment(DefaultTimesData.StartTime),
      End: Moment(DefaultTimesData.EndTime),
    };

    try {
      const response = await this.httpClient.post(
        "/nursingtimes/savedefaultnursingtimes/",
        DefaultTimes,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": userLang === "en" ? "en-US" : userLang,
          },
        }
      );
      //console.log("NURSINGTIMES API. Save new default nursingtimes. OK: ", response);
      const responseData = {
        status: 200,
        Start: Moment(DefaultTimesData.StartTime),
        End: Moment(DefaultTimesData.EndTime),
      };
      return responseData;
    } catch (error) {
      /*console.log("NURSINGTIMES API. Save new default nursingtimes. ERROR: ", error, "\n"+
      " response.status: ", error.response === undefined ? 502 : error.response.status === undefined ? 502 : error.response.status);*/
      const responseData = {
        status:
          error.response === undefined || error.response.status === undefined
            ? 502
            : error.response.status,
      };
      return responseData;
    }
  }

  async readNextNursingTimes() {
    //console.log("NURSINGTIMES API. read next nursingtimes");
    try {
      const response = await this.httpClient.get(
        "/nursingtimes/getnextnursingtimes/",
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      //console.log("NURSINGTIMES API. Next nursingtimes Response: ", response);
      return response;
    } catch (error) {
      //console.log("NURSINGTIMES API CATCH ERROR. read next nursingtimes: ", error);

      const responseData = {
        status:
          error.response === undefined
            ? 502
            : error.response.status === undefined
            ? 502
            : error.response.status,
      };
      return responseData;
    }
  }

  async readNursingTimes(date) {
    const userLang = SessionManager.getUserLanguage();
    //console.log("NURSINGTIMESAPI. Read nursingtimes. userLang: ",userLang );

    //console.log("NURSINGTIMESAPI. Read nursingtimes. date: ",Moment(date).startOf("month").format('M') );

    const data = {
      requestedMonth: Moment(date).startOf("month").format("MM"),
      start: Moment(date)
        .startOf("month")
        .startOf("isoWeek")
        .format("YYYY-MM-DD"),
      end: Moment(date)
        .endOf("month")
        .endOf("isoWeek")
        .add(1, "weeks")
        .format("YYYY-MM-DD"),
    };

    try {
      const response = await this.httpClient.get(
        "/nursingtimes/getnursingtimes/" +
          data.requestedMonth +
          "/" +
          data.start +
          "/" +
          data.end,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": userLang === "en" ? "en-US" : userLang,
          },
          /*params: {
          personId: sessionStorage.getItem('personID'),
          start: Moment(attr.Start).startOf("month").startOf("isoWeek").format('YYYY-MM-DD'),
          end:  Moment(attr.End).endOf("month").endOf("isoWeek").add(1, "weeks").format('YYYY-MM-DD')
        }*/
        }
      );
      //console.log("NURSINGTIMES API. Nursingtimes Response: ", response);
      return response;
    } catch (error) {
      //console.log("NURSINGTIMES API CATCH ERROR. Read Nursingtimes: ", error);

      const responseData = {
        status:
          error.response === undefined
            ? 502
            : error.response.status === undefined
            ? 502
            : error.response.status,
      };
      return responseData;
    }
  }

  async saveNursingTimes(Data) {
    const userLang = SessionManager.getUserLanguage();
    //let MainObject = {};
    let NewReservations = [];
    let NewReservationInfo = [];
    let RequestData = {};
    console.log(Data);
    Data.selectedDates.forEach((date) => {
      Data.ListOfReservationsTimes.forEach((time, timeIndex) => {
        Data.reservationsChilds
          .find((child, cListIndex) => {
            return cListIndex === timeIndex;
          })
          .forEach((c, cIndex) => {
            //console.log("NURSINGTIMESAPI. Save nursingtimes. Create server data. Endtime: ", Moment(time.End).format('HH:mm'));

            NewReservationInfo = {
              PersonId: c.Id,
              PlacementId: c.PlId,
              Start: Moment(time.Start).format("HH:mm"),
              End:
                Moment(time.End).format("HH:mm") === "00:00"
                  ? "23:59:59"
                  : Moment(time.End).format("HH:mm"),
              Date: Moment(date.Date).format("DD.MM.YYYY"),
            };
            NewReservations.push(NewReservationInfo);
          });
      });
    });

    RequestData = {
      MultiReservations: NewReservations,
      Types: Data.Types,
      DeleteOld: Data.DeletedOld,
      EveningPreSchool: Data.eveningPreSchool,
    };

    // console.log("NURSINGTIMEAPI. Save nursingtimes. New Reservations: ", NewReservations);
    // console.log("NURSINGTIMEAPI. Save nursingtimes. New MainObject: ", NewReservations2);
    // try {
    const response = await this.httpClient.post(
      "/nursingtimes/savenursingtimes/",
      RequestData,
      {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": userLang === "en" ? "en-US" : userLang,
        },
      }
    );

    //console.log("NURSINGTIMES API. Save Nursingtimes Response: ", response);

    return response;
    /*   }
       catch (error) {
         console.log("NURSINGTIMES API CATCH ERROR. Save Nursingtimes. error.response: ", error.response);
         let responseData = { status: error.response === undefined ? 502 : 
                                       error.response.status === undefined ? 502 : 
                                       error.response.status,
                              message: error.response === undefined || error.response.data === "" ? null : error.response.data.Message }
         return responseData;
       }*/
  }
  async deleteNursingTimes(Data) {
    try {
      const response = await this.httpClient.delete(
        "/nursingtimes/deletenursingtimes/",
        Data
      );

      //console.log("NURSINGTIMES API. Response: ", response);
      return response;
    } catch (error) {
      //console.error("NURSINGTIMES API CATCH ERROR. Delete nursingtimes. error.response: ", error.response);
      let responseData = {
        status: error.response.status,
        message: error.response.data.Message,
      };
      //console.error("NURSINGTIMES API CATCH ERROR. Delete nursingtimes. response: ", responseData );

      return responseData;
    }
  }
  async deleteNursingTimesMany(Data) {
    try {
      const response = await this.httpClient.delete(
        "/nursingtimes/deletemany/",
        Data
      );

      //console.log("NURSINGTIMES API. Response: ", response);
      return response;
    } catch (error) {
      console.error(
        "NURSINGTIMES API CATCH ERROR. Delete nursingtimes. error.response: ",
        error.response
      );
      let responseData = {
        status: error.response.status,
        message: error.response.data.Message,
      };
      //console.error("NURSINGTIMES API CATCH ERROR. Delete nursingtimes. response: ", responseData );

      return responseData;
    }
  }
}

import React from "react";
import Typography from "@mui/material/Typography";
import Languages from "../../translations";

function AccessibilityContent() {
  return (
    <React.Fragment>
      <hr />
      <br/>
      <Typography variant="h4" component="h1" color="textSecondary"
                  display="inline" style={{wordWrap:"break-word"}}>{Languages.accHeader1}</Typography>
      <br/>
      <br/>
      <Typography variant="h5" component="h2" color="textSecondary" 
                  display="inline" style={{wordWrap:"break-word"}}>{Languages.accHeader2a}</Typography>
      <br/>
      <br />
      <Typography variant="body1" color="textSecondary">{Languages.accPara1}</Typography>
      <br />
      <Typography variant="body1" color="textSecondary">{Languages.accPara2}</Typography>
      <br/>
      <Typography variant="h5" component="h2" color="textSecondary"
                  display="inline" style={{wordWrap:"break-word"}}>{Languages.accHeader2b}</Typography>
      <br/>
      <br />
      <Typography variant="body1" color="textSecondary">{Languages.accPara3} <a href="mailto:saavutettavuus@daisyvarhaiskasvatus.fi">{Languages.accPara4}</a>.</Typography> 
      <br/>
      <Typography variant="h5" component="h2" color="textSecondary"
                  display="inline" style={{wordWrap:"break-word"}}>{Languages.accHeader2c}</Typography>
      <br/>
      <br />
      <Typography variant="body1" color="textSecondary">{Languages.accPara5}</Typography>
      <br/>
      <Typography variant="h6" component="h3" color="textSecondary">{Languages.accHeader3a}</Typography>
      <br/>
      
      <Typography variant="body1" color="textSecondary">{Languages.accPara6} <a href="mailto:saavutettavuus@daisyvarhaiskasvatus.fi">{Languages.accPara7}</a>.</Typography> 
      <br/>
      <Typography variant="body1" color="textSecondary">{Languages.accPara8}</Typography>

        <ul>
          <Typography variant="body1" color="textSecondary">
            <li>{Languages.accUL1a}</li>
            <li>{Languages.accUL1b}</li>
            <li>{Languages.accUL1c}</li>
            <li>{Languages.accUL1d}</li>
          </Typography>
        </ul>
      <br/>

      <Typography variant="h6" component="h3" color="textSecondary">{Languages.accHeader3b}</Typography>
      <br/>
      <Typography variant="body1" color="textSecondary">{Languages.accPara9}</Typography>
        
        <ul>
          <Typography variant="body1" color="textSecondary">
            <li>{Languages.accUL2a}</li>
            <li>{Languages.accUL2b}</li>          
            <li>{Languages.accUL2c}</li>
          </Typography>
        </ul>
      <br/>

      <Typography variant="h5" component="h2" color="textSecondary"
                  display="inline" style={{wordWrap:"break-word"}}>{Languages.accHeader2d}</Typography>
      <br/>
      <br />
      <Typography variant="body1" color="textSecondary">{Languages.accPara10}</Typography>
      <br />
      <Typography variant="body1" color="textSecondary">{Languages.accPara11} <a href="mailto:saavutettavuus@daisyvarhaiskasvatus.fi">saavutettavuus@daisyvarhaiskasvatus.fi</a>.</Typography>
      <br />
      <Typography variant="body1" color="textSecondary">{Languages.accPara12}</Typography>
      <br/>
      <Typography variant="h5" component="h2" color="textSecondary"
                  display="inline" style={{wordWrap:"break-word"}}>{Languages.accHeader2e}</Typography>
      <br/>
      <br />
      <Typography variant="body1" color="textSecondary">{Languages.accPara13}</Typography>
      <br />
      <Typography variant="body1" color="textSecondary">{Languages.accPara14}</Typography>
      <br/>
      <Typography variant="h6" component="h3" color="textSecondary">{Languages.accHeader3c}</Typography>
      <Typography variant="body1" color="textSecondary">{Languages.accPara15}</Typography>
      <Typography variant="body1" color="textSecondary">{Languages.accPara16}</Typography>
      <Typography variant="body1" color="textSecondary"><a href="https://www.saavutettavuusvaatimukset.fi" target="_blank" rel="noopener noreferrer">www.saavutettavuusvaatimukset.fi</a></Typography>
      <Typography variant="body1" color="textSecondary"><a href="mailto:saavutettavuus@avi.fi">saavutettavuus@avi.fi</a></Typography>
      <Typography variant="body1" color="textSecondary">{Languages.accPara17} 0295 016 000</Typography>
      <br/>
      <Typography variant="h5" component="h2" color="textSecondary"
                  display="inline" style={{wordWrap:"break-word"}}>{Languages.accHeader2f}</Typography>
      <br/>
      <br />
      <Typography variant="body1" color="textSecondary">{Languages.accPara18}</Typography>
      <br />
      <Typography variant="body1" color="textSecondary">{Languages.accPara19}</Typography>

        <ul>
        <Typography variant="body1" color="textSecondary">
            <li>{Languages.accUL3a}</li>
            <li>{Languages.accUL3b}</li>
            <li>{Languages.accUL3c}</li>
          </Typography>
        </ul>
      <br/>
      <Typography variant="h5" component="h2" color="textSecondary">{Languages.accPara20}</Typography>
      <br />
        <ul>
          <Typography variant="body1" color="textSecondary">
            <li><a href="https://saavutettavuusvaatimukset.fi" target="_blank" rel="noopener noreferrer">{Languages.accPara21}</a></li>
            <li><a href="https://www.finlex.fi/fi/laki/alkup/2019/20190306" target="_blank" rel="noopener noreferrer">{Languages.accPara22}</a></li>
            <li><a href="https://eur-lex.europa.eu/legal-content/FI/TXT/?uri=CELEX%3A32016L2102" target="_blank" rel="noopener noreferrer">{Languages.accPara23}</a></li>
          </Typography>
        </ul>
      <br/>
      <br/>
      <hr />
    </React.Fragment>
  );
}

export default AccessibilityContent;
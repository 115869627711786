import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import AccordionActions from "@mui/material/AccordionActions";
import Check from "@mui/icons-material/Check";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { SettingOptionPanel } from "../SettingOption";
import Languages from "../../../../translations";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import { useStateValue } from "../../../../State";
import { SessionManager } from "../../../../Auth";
import { types } from "../../../../Reducers/actionTypes";
import {
  EmailNotificationsHelpModal,
  PushNotificationsHelpModal,
} from "./HelpModals";

const SaveActions = ({ show = false, onClick }) => {
  if (show) {
    return (
      <AccordionActions className="painikkeet">
        <p className="mt-4">{Languages.passwordSaveInfoText}!</p>
        <IconButton
          id="test_LoginSettings_SaveNotificiationsBtn"
          onClick={onClick}
          size="large"
        >
          <Check />
        </IconButton>
      </AccordionActions>
    );
  }
};

const GridTitle = styled(Grid)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
  marginTop: "auto",
  marginBottom: "auto",
  //backgroundColor: theme.palette.background.default,
}));

const GridInput = styled(Grid)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

const ColorSwitch = styled(Switch)(() => {
  return {
    "& .MuiSwitch-switchBase": {
      color: "red",
      "&.Mui-checked": {
        color: "#5EFF33",
      },
    },
  };
});

const StyleFormControlLabel = styled(FormControlLabel)(() => ({
  "& .MuiFormControlLabel-label": {
    width: "5em",
  },
}));

const NotificationsSettings = ({ isEmailServiceEnabled }) => {
  const [expanded, setExpanded] = useState(false);
  const [emailEnabled, setEmailEnabled] = useState(false);
  const [pushEnabled, setPushEnabled] = useState(false);
  const {
    state: { settings },
    actions,
  } = useStateValue();
  const [helpModalEmail, setHelpModalEmail] = useState(false);
  const [helpModalPush, setHelpModalPush] = useState(false);

  const onExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    // Don't update when saving because it looks like radio button changes it state during saving
    if (settings.personSettings?.status !== types.SAVING_PERSON_SETTINGS) {
      if (settings.personSettings) {
        setEmailEnabled(settings.personSettings?.allowEmailMessage);
        setPushEnabled(settings.personSettings?.allowPushMessage);
      }
    }
  }, [settings.personSettings]);

  const save = async () => {
    const personId = SessionManager.getPersonId();
    const requestData = {
      notificationsSettings: {
        emailEnabled: emailEnabled,
        pushEnabled: pushEnabled,
      },
    };

    await actions.triggerSavePersonSettings(personId, requestData);
    setExpanded(false);
  };

  return (
    <React.StrictMode>
      <SettingOptionPanel
        showActions={true}
        actions={<SaveActions show={true} onClick={save} />}
        headerText={Languages.notificationsSettings}
        expanded={expanded}
        onExpandClick={onExpandClick}
        idForButton={"test_Settings_Notifications"}
        ariaControls={"panel1a-content"}
        settingsIcon="notification"
      >
        <Grid container spacing={2}>
          {isEmailServiceEnabled && (
            <>
              <GridTitle item xs={6}>
                {Languages.emailNotifications}
              </GridTitle>
              <GridInput item xs={6}>
                <StyleFormControlLabel
                  control={
                    <ColorSwitch
                      key="emailNotification"
                      name="emailNotification"
                      checked={!!emailEnabled}
                      inputProps={{ "aria-label": "controlled" }}
                      disabled={false}
                      onChange={() => {
                        setEmailEnabled(!emailEnabled);
                      }}
                    />
                  }
                  label={
                    emailEnabled ? Languages.allowed : Languages.notAllowed
                  }
                  labelPlacement="end"
                />
                <IconButton
                  color="secondary"
                  size="large"
                  onClick={() => setHelpModalEmail(true)}
                >
                  <HelpOutlineIcon />
                </IconButton>
              </GridInput>
            </>
          )}
          <Grid item xs={12}>
            <Divider />
          </Grid>

          <GridTitle item xs={6}>
            {Languages.pushNotifications}
          </GridTitle>

          <GridInput item xs={6}>
            <StyleFormControlLabel
              control={
                <ColorSwitch
                  key="pushNotification"
                  name="pushNotification"
                  checked={!!pushEnabled}
                  disabled={false}
                  inputProps={{ "aria-label": "controlled" }}
                  onChange={() => {
                    setPushEnabled(!pushEnabled);
                  }}
                />
              }
              label={pushEnabled ? Languages.allowed : Languages.notAllowed}
              labelPlacement="end"
            />
            <IconButton
              color="secondary"
              size="large"
              onClick={() => setHelpModalPush(true)}
            >
              <HelpOutlineIcon />
            </IconButton>
          </GridInput>
        </Grid>
      </SettingOptionPanel>
      <EmailNotificationsHelpModal
        open={helpModalEmail}
        onClose={() => setHelpModalEmail(false)}
      />
      <PushNotificationsHelpModal
        open={helpModalPush}
        onClose={() => setHelpModalPush(false)}
      />
    </React.StrictMode>
  );
};

export { NotificationsSettings };

import { types } from './actionTypes';
import Languages from "../translations";
import _cloneDeep from "lodash/cloneDeep";

const SnackbarReducer = (state, action) => {
  //console.log("SNACKBARREDUCER action,", action);
  //const payload = action.payload
  //console.log('Header reducer got action', action);
  //console.log("Header got payload: ", payload);
  //console.log("ministate", state);
  //console.log("action", action);
  switch (action.type) {
    
    case types.ADDTO_SNACKBAR_QUEUE:
   
      const newArray = _cloneDeep(state.queue);
      newArray.push(action.payload);

      return {
        ...state,
            queue: newArray
          
        }
     

    case types.REDUCE_SNACKBAR_QUEUE:
      let arrayCopy = _cloneDeep(state.queue);
      console.log("reduce triggered");
      //const updatedCopy = arrayCopy.splice(1, arrayCopy.length-1);
      if (arrayCopy.length === 1) {
        arrayCopy = [];
      }
      else {
        arrayCopy.splice(0, 1);
      }

      return {
        ...state,
              queue: arrayCopy,
          
        }

    case types.OPEN_POST_NEW_MESSAGE_SNACKBAR:
      console.log("ACTION", action);
      return {
        ...state,
                postNewMessage: {
                  status: "success", //action.status,
                  message: Languages.msgYourMsgWasSent //action.message
                }
      }

    /*case types.CLOSE_POST_NEW_MESSAGE_SNACKBAR:
      console.log("SNACKBAR REDUCER CLOSE POST NEW MESSAGE SNACKBAR", action);
      return {
        ...state,
                postNewMessage: {
                  status: "notification_shown",
                  message: ""
                },
                forSnackBar: {
                  state: "INIT_VALUE"
                }
      } */

    default: return state;
  }
}


export default SnackbarReducer;


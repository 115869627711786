import React from "react";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Button from '@mui/material/Button';
import Languages from "../../../translations";


const BackToUpButton = () => {

  const scroll = () => {
    const section = document.querySelector('#toTheTopInGuide');
    section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  };

  return(
    <div className="accordionTextBottomUpButton">
      <Button style={{display: "block"}} align="center" aria-label={Languages.backToTop} title={Languages.backToTop} color="secondary" onClick={() => scroll()}><ArrowUpwardIcon color="primary"/></Button>
    </div>
  )
}

export default BackToUpButton
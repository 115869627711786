import React                  from "react";
import Grid                   from "@mui/material/Grid";
import Skeleton               from '@mui/material/Skeleton';

const InvoiceSummaryTableLoading = () => {

  return (
    <div style={{margin: "auto"}}>
      <Grid data-testid="InvoiceSummaryTableLoading-3hsd9nfl" style={{marginTop: "10px"}} container spacing={3}>
        <Grid container direction="row" spacing={1}>
          <Grid item xs={1}>
          </Grid>
          <Grid item xs={2}>  
            <Skeleton variant="circular" width={40} height={40}/>
          </Grid>
          <Grid item xs={7}>
            <Skeleton variant="rectangular" height={40}/>
            <br/>
          </Grid>
          <Grid item xs={2}>

          </Grid>
        </Grid>
        
        <Grid container direction="row" spacing={1}>
          <Grid item xs={1}>

          </Grid>
          <Grid item xs={3}>
            <Skeleton variant="rectangular" width={80} height={40}/>
          </Grid>
          <Grid item xs={3}>
            <Skeleton variant="rectangular" width={80} height={40}/>
          </Grid>
          <Grid item xs={3}>
            <Skeleton variant="rectangular" width={80} height={40}/>
          </Grid>
          <Grid item xs={3}>

          </Grid>
          <br/>
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={480}/>
        </Grid>
      </Grid>
    </div>
  );

}

export default InvoiceSummaryTableLoading;
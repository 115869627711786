import React from "react";
import Button from '@mui/material/Button';
import PropTypes from 'prop-types'
import Languages from "../../../translations";



const KeywordButton = ({ handleClick, buttonText, panelId, buttonVariant }) => {
  const setUpAnchor = '#' + panelId

  const scroll = () => {
    const section = document.querySelector(setUpAnchor);
    section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  };

  return(
    <div className="guide-modal-keyword-button">
      <Button
        aria-label={Languages.keywordButtonTitle1 + buttonText + Languages.keywordButtonTitle2}
        title={Languages.keywordButtonTitle1 + buttonText + Languages.keywordButtonTitle2}
        color="secondary"
        variant={buttonVariant}
        onClick={() => {handleClick(panelId); setTimeout(scroll, 600)}}
      >{buttonText}
      </Button>
    </div>
  )
}


KeywordButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  panelId: PropTypes.string.isRequired,
  buttonVariant: PropTypes.string.isRequired
}

export default KeywordButton
import React    from "react";
import Skeleton from '@mui/material/Skeleton';
import               "../growthfolder.css";


const GrowthFolderLoading = () => {

  return (
    <React.Fragment>
      <div title="skeletonGroup">
        <div className= "growthfolder-sorting-buttons-area-loading">
          <Skeleton variant="rectangular"animation="wave" height={50} width={360}></Skeleton>
        </div>
        <div className="growthfolder-loading__folderblock-marginBottom">
          <Skeleton variant="rectangular" animation="wave" height={150} ></Skeleton>
        </div>
        <div className="growthfolder-loading__folderblock-marginBottom">
          <Skeleton variant="rectangular" animation="wave" height={150}></Skeleton>
        </div>
        <div className="growthfolder-loading__folderblock-marginBottom">
          <Skeleton variant="rectangular" animation="wave" height={150} ></Skeleton>
        </div>
      </div>
    </React.Fragment>
  );
}

export default GrowthFolderLoading;
import React from 'react';

import AppBar from "@mui/material/AppBar";
import ToolBar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';

import PropTypes from 'prop-types';
// import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';

//import Arrowleft from "../../images/arrowleft.svg";
//import ArrowBack from "@mui/icons-material/ArrowBack";
//import { useStateValue } from '../../State';



const styles = (theme) => ({

  monthsrow: {
    maxWidth: '100%', 
    textAlign:"center", 
    marginRight:"15%",
    marginLeft:"15%", 
    alignItems: "end", 
    paddingTop:"6px",
        [theme.breakpoints.down('md')]: {
          marginRight:"3%",
          marginLeft:"3%", 
    },
    [theme.breakpoints.up('lg')]: {
      marginRight:"25%",
      marginLeft:"25%", 
},
  },
  months: {
    textTransform: 'uppercase',
  },
  Rightmonth: {
  },
})

// function HideOnScroll(props) {
//   const { children, window } = props;
//   // Note that you normally won't need to set the window ref as useScrollTrigger
//   // will default to window.
//   // This is only being set here because the demo is in an iframe.
//   const trigger = useScrollTrigger({ target: window ? window() : undefined });

//   return (
//     <Slide appear={false} direction="down" in={!trigger}>
//       {children}
//     </Slide>
//   );
// }

// HideOnScroll.propTypes = {
//   children: PropTypes.element.isRequired,
//   /**
//    * Injected by the documentation to work in an iframe.
//    * You won't need it on your project.
//    */
//   window: PropTypes.func,
// };

const CalendarNav = (props) => {
  const { positionFix, childcolor, prevText, nextText, onPrevClick, onNextClick, centerText, helperText, opacity, classes } = props;
  //const { state, action } = useStateValue();
  //const { header } = state;

  return (
    // <HideOnScroll {...props}>
    <AppBar
      id={"test_CalendarTitle"}
      position="fixed"
      color="secondary"
      style={{
        opacity: opacity,
        backgroundColor: childcolor === undefined ? '#86B8FF' : childcolor, 
        fontSize: "large",
        top:positionFix,
        width: 'inherit',
        display: 'contents',

        
        
      }}>
      {helperText === null ? null : <div className="textCenter" style={{ whiteSpace: "pre-line", fontSize: "15px" }}>{helperText}</div>}

      <ToolBar className={classes.monthsrow}>
          <div style={{marginBottom: "6px"}} id="test_Main_LeftArrow" onClick={onPrevClick}>
          <IconButton size="medium" color="primary" >
            <Typography color="textSecondary" className={classes.months} >
              &#9668;
              {prevText}
            </Typography>
          </IconButton>
          </div>

        <div className="text smallcaps" ><Typography color="textSecondary">{/*this.props.header*/ centerText}</Typography></div>

          <div style={{marginBottom: "6px"}} id="test_Main_RightArrow" onClick={onNextClick} >
          <IconButton size="medium" color="primary" >
            <Typography color="textSecondary" className={classes.months} >
            {nextText}
            &#9658;
            </Typography>
          </IconButton>
        </div>

      </ToolBar>
    </AppBar>
    // </HideOnScroll>
  );
}

export default withStyles(styles)(CalendarNav);

import React          from  'react';
import Languages      from  "../../../../translations";
import Card           from  "@mui/material/Card";
import Button         from  "@mui/material/Button";
import AutorenewIcon  from  '@mui/icons-material/Autorenew';
import                      '../pedagdocimage.css';


const PedagDocImageFailed = ({ tryReloadImage, errorCode }) => {
  return(
    <React.Fragment>
      <Card title = {"errorCard"} style = {{backgroundColor:"#4377BA"}}>
        <p className = "pedagdoc-imagefailed-error-msg">{Languages.errorLoadImage}</p>
        <p className = "pedagdoc-imagefailed-error-msg">
          {typeof errorCode === "undefined" ? Languages.errorUnknown : 
                  errorCode === 400         ? Languages.errorCode + " 400 " :
                  errorCode === 500         ? Languages.errorCode + " 500: " + Languages.errorInDB :
                  errorCode === 502         ? " 502: " + Languages.errorNetwork : 
                  errorCode === 777         ? Languages.errorUnknown : Languages.errorUnknown
          }
        </p>
      </Card>
      <div className= "pedagdoc-imagefailed-error-msg-button"> 
        <Button 
          data-testid = {"pedagdocimage__error-message-react-button"}
          startIcon = {<AutorenewIcon/>}
          style = {{backgroundColor:"#4377BA", align:"center"}} 
          onClick = {tryReloadImage}>{Languages.retry}
        </Button>
      </div>
    </React.Fragment>
  );
}

export default PedagDocImageFailed;
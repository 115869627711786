import React from "react";
import Typography from "@mui/material/Typography";
import Languages              from '../../../translations';

const GuardianConsentLoading = ({isShown}) => {

    if (!isShown) {
        return(<div></div>)
    }

    return(
        <Typography id="test_guardian_consents_waitingservertoloaddata" component={"span"}>{Languages.waitingservertoloaddata}</Typography>
    )

}

export default GuardianConsentLoading;
import LocalizedStrings from "react-localization";

export const languageStrings = {
  fi: {
    awDevelopersInform: "Tuotekehitys tiedottaa",
    awClientsInform: "Päiväkodit tiedottaa",
    awNotAvailableDevTextTrans: "Käännöstä ei saatavilla suomeksi! ",
    awNewBulletin: " aktiivinen ilmoitus",
    awNewBulletins: " aktiivista ilmoitusta",
    awMoveToBulletin: "Siirry ilmoitustaululle",
    awNursingTimesLock: "Hoitoajat ovat lukkiutumassa",
    awShowMore: "Näytä lisää",
    awUnansConsent: " vastaamatonta suostumusta",
    awMoveToPerm: "Siirry Luvat & Suostumukset sivulle",
    awMove2Messaging: "Siirry viestitykseen",

    awNotReadMessages: " lukematonta viestiä",
    awNewMessages: "Uusia viestejä!",
    awAllMarkedAsRead: "Kaikki merkattu luetuksi!",
    awBulletins: "TIEDOTTEET",
    awAllDone: "Kaikki tehty, mahtavaa!",

    broSeems: "Näyttää siltä, että DaisyFamily ei tue selaintasi.",
    broForAttachments: "liitetiedostoja varten",
    broYourBrowserIs: "Selaimesi on",
    broYourVersionIs: "ja selaimesi versio on",
    broDaisyFamilySupports:
      "DaisyFamily tukee näitä selainversioita ja niitä uudempia",
    broAllSupportedVersions: "Kaikki tuetut selaimet",
    broDaisyFamilySupports2:
      "DaisyFamily tukee seuraavia selaimia ja niiden uudempia versioita",
    broOtherOperatingSystems:
      "Muut käyttöjärjestelmät: parhaan DaisyFamilyn käyttökokemuksen voi mahdollisesti saada Chromella tai Firefoxilla.",
    broUpdateYourBrowserRegularly:
      "Päivitä selaimesi säännöllisesti, niin parannat tietoturvaasi.",
    broUnknown: "Ei tiedossa",

    discDiscussionBooking: "Varaa keskustelu",
    discChildNoDiscussionsToBeBooked: (childName) => {
      return `Lapselle ${childName} ei ole varattavissa uusia keskusteluaikoja`;
    },
    discPastDiscussions: "Menneet keskustelut",
    discUpcomingDiscussions: "Tulevat keskustelut",
    discChildsPastDiscussions: "Lapsen menneet keskustelut",
    discChildsUpcomingDiscussions: "Lapsen tulevat keskustelut",
    discTimesCouldntBeLoaaded: "Lapsen keskusteluaikoja ei voitu ladata",
    discCancelDiscussionTime: "Peruuta keskusteluvaraus",
    discCancelConfimation: "Haluatko varmasti peruuttaa keskusteluvarauksen?",
    discDiscussionTimes: "Keskusteluajat",
    discTimeType: "Keskusteluajan tyyppi:",
    discType: "Keskustelutyyppi",
    discTypeVASU: "VASU",
    discTypeLEOPS: "LEOPS",
    discTypeLapu: "Lapset puheeksi",
    discTypeMuu: "Muu",
    discSaveConfirmation: "Haluatko tallentaa seuraavat tiedot?",
    discSaveError: (error) => {
      return `Virhe ${error}: keskusteluvarausta ei voitu tallentaa.`;
    },
    discBooking: "Ajanvaraus",
    discChooseTime: "Valitse aika",
    discChooseParticipant: "Valitse osallistujat",
    discTimesAvailable: "Aikoja varattavissa",
    discTimesUnabailable: "Aikoja ei varattavissa",
    discChildsUpcomingDiscussion: "Tulossa olevia keskusteluita",
    discNoActiveBookings: "Ei aktiivisia keskusteluvarauksia",
    discReminder:
      "Muistathan osallistua tulevaan keskusteluun tai perua sen ennen määräaikaa:",
    discInformationFailed: "Lasten keskusteluaikatietoja ei voitu ladata.",
    discYes: "Kyllä",
    discNo: "Ei",
    discCouldNotBeCanceled: "Keskusteluaikaa ei voitu peruuttaa",
    discCancelSuccess: "Keskusteluaika peruutettiin onnistuneesti",
    discRemoteMeeting: "Etätapaaminen",
    discCopy: "Kopioi",
    discCopied: "Kopioitu",
    discCancelableUntil: (date) => {
      return `Peruutettavissa ${date} asti`;
    },
    discCancelableUntil2: "Peruutettavissa asti",
    discInformationGivenByPersonel: "Työntekijöiden antama lisätieto",
    discInformationGivenByGuardians: "Huoltajien antama lisätieto",
    discNoInformation: "Ei lisätietoja...",
    discNoTimesToBeBooked: (childName) => {
      return `Lapsella ${childName} ei ole aikoja varattavissa`;
    },
    discStopEditing: "Lopeta muokkaaminen",
    discSaveChanges: "Tallenna muutokset",
    discSomethingWentWrong: "Jotain meni pieleen",
    discNewTime: "Uusi aika",
    discMakeAnAppointment: "Varaa aika",
    discEdit: "Muokkaa",
    discSubject: "Aihe",
    discAddress: "Osoite",
    discStaff: "Henkilökunta",
    discAlreadyBooked:
      "Keskusteluaika ei vapaana. Varaa toinen keskusteluaika.",
    discMoveToBooking: "Siirry keskusteluaikojen varaukseen",
    discYouHaveAppointments: "Sinulla on keskusteluaikoja varattavissa!",
    discAvailableForXDays: (count) => {
      return `Aikoja varattavissa ${count} päivälle`;
    },
    discEditSuccess: "Muokkaaminen onnistui!",
    discBookingSaved: "Varaus tallennettu",
    discBookingSaveError: "Varausta ei voitu tallentaa",
    discPreBookingConflict:
      "Joku muu ehti ennakkovarata ajan. Valitse jokin toinen ajankohta. ",
    discPreBookingError: "Ennakkovarauksen tallentamisessa tapahtui virhe.",
    discLeftOfPrebooking: (x, y) => {
      return `${x} minuuttia ${y} sekuntia ajanvarausta jäljellä.`;
    },
    discPrebookingTimeRanOut: "Ennakkovaraus aika loppui.",
    discPrebookingMoreTime: "Lisää aikaa",

    monday: "Maanantai",
    tuesday: "Tiistai",
    wednesday: "Keskiviikko",
    thursday: "Torstai",
    friday: "Perjantai",
    saturday: "Lauantai",
    sunday: "sunnuntai",

    selectall: "Valitse kaikki",
    selectallRemove: "Poista valinnat",

    permDialogNotSavedText:
      "Muutoksia ei ole tallennettu. Tallentamattomat tiedot menetetään.",
    permUnansweredPerms: "vastaamatonta lupaa!",
    permPermissionQueries: "Kyselyt",
    permPQTitle: "Otsikko",
    permAnswersWereSaved: "Vastaukset tallennettiin",
    permNoData: "Ei dataa",
    permPermSavingFailed: "Lupien tallennus epäonnistui",
    permOneOrMoreSavesFailed: "Yhden tai useamman luvan tallennus epäonnistui",
    permBackTo: "Takaisin lyhytaikaisten kyselyjen listalle",
    permLastDayToAnswer: "Viimeinen vastauspäivämäärä",
    permCannotAnswerAnymore: "Kyselyyn ei voi enää vastata",
    permToPermissions: "Siirry kyselyihin tästä",
    permOneOrMoreChildrenNoAnswer:
      "Yhdeltä tai useammalta lapselta puuttuu vastaus.",
    permAnswerAllChildren: "Vastaa kyselyyn kaikkien lasten osalta",
    permRefresh: "Päivitä",
    permSender: "Lähettäjä",
    permIGivePermission: "Annan luvan",

    accAccessibilityStatement: "Saavutettavuusseloste",
    accHeader1: "Saavutettavuus",
    accHeader2a: "DaisyFamily.fi-verkkosivuston saavutettavuusseloste",
    accPara1:
      "Tämä saavutettavuusseloste koskee daisyfamily.fi-verkkosivustoa (kunta.daisyfamily.fi). Seloste on laadittu 23.3.2021 tilanteen mukaisena ja sitä on viimeksi päivitetty 23.3.2021.",
    accPara2:
      "Verkkosivuston saavutettavuus on arvioitu DaisyFamilyn tuotekehittäjien toimesta.",
    accHeader2b: "Vaihtoehtoiset asiointitavat",
    accPara3:
      "Jos et pysty hoitamaan asiaasi verkossa, ole yhteydessä päiväkotiisi tai meihin sähköpostitse",
    accPara4: "saavutettavuus@daisyvarhaiskasvatus.fi",
    accHeader2c: "Miten saavutettava verkkopalvelu on?",
    accPara5:
      "Palvelu täyttää saavutettavuusvaatimukset osittain (WCAG-kriteeristö 2.1, A- ja AA-taso).",
    accHeader3a: "Sisällöt, jotka eivät ole saavutettavia",
    accPara6:
      "Käyttäjät voivat edelleen kohdata sivustolla joitakin saavutettavuusongelmia. Seuraavana luettelo ongelmista, mitkä olemme havainneet itse tai mistä olemme saaneet palautetta käyttäjiltä. Jos luettelosta ei löydä havaitsemaasi ongelmaa, ilmoitathan siitä meille sähköpostitse",
    accPara7: "saavutettavuus@daisyvarhaiskasvatus.fi",
    accPara8:
      "Sisällöt tai toiminnot, jotka eivät ole vielä täysin saavutettavia:",
    accUL1a:
      "Värikontrasteissa on joitakin puutteita tekstien ja linkkien osalta (WCAG 1.4.3)",
    accUL1b:
      "Otsikkotasot eivät etene kaikilla sivuilla järjestyksessä (WCAG 1.3.1)",
    accUL1c:
      "Kaikilla visuaalisilla sisällöillä ei ole tekstivastinetta, joka kertoo, mitä kuva tai graafinen elementti esittää (WCAG 1.1.1)",
    accUL1d:
      "Palveluita ei voi käyttää sujuvasti ruudunlukijalla tai näppäimistöllä. (WCAG 4.1 ja 2.1.1)",
    accHeader3b: "Saavutettavuusvaatimukset eivät koske tiettyjä sisältöjä",
    accPara9:
      "Seuraavana on listaus sivustollamme olevista sisällöistä, jotka eivät kuulu saavutettavuuslainsäädännön piiriin.",

    accUL2a:
      "Opetuksessa rajatussa ryhmässä määräaikaisesti käytettäviä sisältöjä",
    accUL2b:
      "Käyttäjien tai muiden ulkopuolisten tahojen palvelussa julkaisemia sisältöjä, jotka eivät ole palveluntarjoajan itsensä tuottamia, rahoittamia tai valvomia",
    accUL2c: "Verkkosisältö, joka on arkistoitu ennen 23.9.2019",

    accHeader2d: "Anna palautetta saavutettavuudesta",
    accPara10:
      "Huomasitko saavutettavuuspuutteen palvelussamme? Kerro se meille ja teemme parhaamme puutteen korjaamiseksi.",
    accPara11: "Anna palaute sähköpostilla osoitteeseen",
    accPara12:
      "Saavutettavuuspalautteet vastaanottaa DaisyFamilyn tuotekehitys.",

    accHeader2e: "Saavutettavuuden valvonta",
    accPara13:
      "Jos huomaat sivustolla saavutettavuusongelmia, anna ensin palautetta meille. Vastaamme kahden (2) viikon sisällä.",
    accPara14:
      "Jos et ole tyytyväinen saamaasi vastaukseen tai jos et saa vastausta kahden (2) viikon aikana, voit tehdä ilmoituksen Etelä-Suomen aluehallintovirastoon. Etelä-Suomen aluehallintoviraston sivulla kerrotaan tarkasti, miten voit tehdä ilmoituksen ja miten asia käsitellään.",

    accHeader3c: "Valvontaviranomaisen yhteystiedot",
    accPara15: "Etelä-Suomen aluehallintovirasto",
    accPara16: "Saavutettavuuden valvonnan yksikkö",
    accPara17: "puhelinnumeron vaihde",

    accHeader2f: "Teemme jatkuvasti töitä saavutettavuuden parantamiseksi",
    accPara18:
      "Päivitämme tätä selostetta sitä mukaan, kun korjaamme puutteita tai saamme niistä ilmoituksia. Pyrimme vuoden 2021 aikana korjaamaan havaitut puutteet.",
    accPara19:
      "Olemme sitoutuneet parantamaan verkkopalveluiden saavutettavuutta. Varmistamme saavutettavuuden muuan muassa seuraavilla toimenpiteillä:",

    accUL3a:
      "Otamme saavutettavuusvaatimukset huomioon, kun kehitämme palveluita",
    accUL3b:
      "Verkkosivujen sisältö tarjotaan selkokielellä suomeksi, ruotsiksi ja englanniksi",
    accUL3c:
      "Tarjoamme työntekijöillemme saavutettavuuskoulutusta ja perehdytystä",

    accPara20: "Lue lisää",
    accPara21: "Saavutettavuusvaatimukset.fi (Aluehallintovirasto AVI)",
    accPara22: "Laki digitaalisten palveluiden tarjoamisesta (finlex.fi)",
    accPara23: "EU:n saavutettavuusdirektiivi (eur-lex.europa.eu)",

    cookieHeader1: "Evästeet",
    cookieHeader2a: "Evästeet verkkosivuillamme",
    cookiePara1:
      "Vieraillessasi sivustollamme (https://kunta.daisyfamily.fi), sivustomme tallentaa käyttämällesi laitteelle evästeitä (ml. Local ja session storage) eli pieniä tekstitiedostoja. Evästeiden avulla ei voida vahingoittaa laitettasi tai levittää haittaohjelmia.",
    cookiePara2:
      "Sivustomme käyttää vain välttämättömiä teknisiä evästeitä, joiden tarkoituksena on mahdollistaa sivuston turvallinen, sujuva ja tarkoituksenmukainen käyttö. Esimerkiksi sivustolle kirjautumisessa ja kielivalinnan tallentamisessa käytämme hyväksi evästeitä.",
    cookiePara3:
      "Tekniset evästeet on mahdollista kytkeä pois käytöstä käyttämäsi selaimen asetuksista. Haluamme kuitenkin huomauttaa, että kytkemällä kaikki evästeet pois käytöstä, estyy sivustollemme kirjautuminen. Oman evästehistorian poistaminen on mahdollista selaimesi evästeasetusten kautta eikä se estä sivustolle kirjautumista.",
    cookiePara4:
      "Jokaisella selaimella on erilaiset tavat ja asetukset evästeiden hallinnoimiseen. Selainten omilla verkkosivuilla voit tutustua selaimesi evästeasetuksiin.",
    cookies: "Evästeet",

    allowed: "Sallittu",
    newNotifications: "Uusia ilmoituksia",
    allowEmailNotificationsExplanation:
      "Vastaanotat ilmoituksia sähköpostitse, kun sinulle saapuu uusia hakemuksia, viestejä tai ilmoituksia koskien lastasi.",
    allowPushNotificationsExplanation:
      "Vastaanotat ilmoituksia puhelimeen, kun sinulle saapuu uusia hakemuksia, viestejä tai ilmoituksia koskien lastasi. Huom! Vaatii että olet asentanut DaisyFamily sovelluksen puhelimeen!",
    allowViewLabel: "Näytä tiedot muille huoltajille",
    allowViewModalTitle: "Näkyvyys muille huoltajille",
    allowViewModalYesText:
      "Sallit, että lapsen muut huoltajat näkevät ja voivat muuttaa yhteystietojasi",
    allowViewModalNoText: "Yhteystietosi piiloitetaan toisilta huoltajilta",
    bbTitle: "Ilmoitustaulu",
    bbHeaderText: "Tässä näet päiväkodin ilmoitusluonteiset asiat.",
    bbSlideViewNoMessages: "Ei ilmoituksia",
    bbGroup: "Ryhmä",
    bbCentreShortText: "PK",
    bbValidUntilBBCard: "Voimassa {0} asti",
    bookingShowTimes: "Näytä ajat",
    bookingAddBookingTitle: "Lisää varaus",
    bookingAddBookingInfo:
      "Olet lisäämässä varausta valituille päiville.\nHaluatko tehdä kokonaan uudet varaukset (1 tai useampi)\nvai säilyttää nykyiset ja lisätä?",
    bookingAddBookingLeftBtn: "UUDET",
    bookingAddBookingRightBtn: "SÄILYTÄ",
    bookingAddBookingAndKeepPrevTitle: "Valittujen päivien tiedot",
    bookingCareHoursTitle: "Hoitoajat",
    bookingCareHoursTitle2: "Varauskalenteri",
    bookingCareHoursDefaultTitle: "Käytettävä oletushoitoaika",
    bookingCareHoursDefaultUse: "Käytetään oletuksena: 8-16", // tarkista
    bookingCareHoursDefaultMissingInfo:
      "Oletushoitoaikaa ei ole asetettu. Käytetään automaattisesti aikaa 8-16. \n Voit tallentaa oletushoitoajan vasemman alakulman kello-painikkeesta.",
    bookingCareHoursDefaultSave: "Tallenna",
    bookingCareHoursSaveInformation: "Tallenna tiedot?",
    bookingCareHoursMissing: "Hoitoaikoja varaamatta",
    bookingCareHoursMissingTimeSpan:
      "Hoitoaikoja tai poissaoloja ilmoittamatta lukkiutuvalle ajanjaksolle:",
    bookingChoosePlacement: "Valitse sijoitus",
    bookingDataSaving: "Tietojen tallennus",
    bookingDeleteBookingTitle: "Poista varaukset", //huom tätä käytetään myös trashcan-ikonin aria-labelin tekstinä
    bookingDeleteBookingInfo:
      "Olet poistamassa valittujen päivien varauksia.\nHaluatko varmasti poistaa varaukset?",
    bookingDeleteBookingLeftBtn: "PERUUTA",
    bookingDeleteBookingRightBtn: "POISTA",
    bookingDetailDelBookingTitle: "Merkintöjen poistaminen",
    bookingDetailDelBookingInfo: "Haluatko varmasti poistaa merkinnän?",
    bookingDetalDelBookingInfoMultiple:
      "Haluatko varmasti poistaa poissaolon (POISTA)\nHaluatko poistaa poissaolot kaikilta sijoituksilta (POISTA KAIKKI)",
    bookingDeleteBookingAll: "POISTA KAIKKI",
    bookingsLocked: "Varaus lukittu",
    bookingBookingsDeleted: "Varaukset poistettu",
    bookingAllCouldNOtBeDeleted: "Kaikkia ei voitu poistaa",
    bookingErrorDeleteDetails: "\nPoistamisessa esiintyvät virheet:\n",
    bookingErrorSaving: "\nTallennuksessa esiintynyt virhe:\n",
    bookingErrorSavingDetailed: "\nTallennuksessa esiintyvät virheet:\n",
    bookingBookingsSaved: "Varaukset tallennettu",
    bookingAddMoreBtn: "Lisää uusi varaus",
    bookingErrorNoValidPlacement:
      "Varausten/poissaolojen tekeminen \n estetty, koska ei voimassa olevaa sijoitusta.",
    bookingYoureEditingFollowing: "Olet muokkaamassa seuraavia aikoja",
    bookingErrorBadRequest: "Tallennus epäonnistui. Päällekkäisiä varauksia! ",
    bookingNextBooked: "Seuraava varattu hoitoaika",
    bookingMake: "Tee hoitoaikavaraus",
    bookingSavingAbsenceFailedPartially:
      "Kaikkia poissaoloja ei voitu tallettaa, tarkista varaukset!",
    bookingSavingFailedPartially:
      "Kaikkia varauksia ei voitu tallentaa, tarkista varaukset!",
    bookingSavingFailedPartially2:
      "Kaikkia varauksia ei voida tallentaa, koska sijoitus paikkaan: ",
    bookingType: "Varauksen tyyppi",
    bookingTypeAbsent: "Poissa",
    bookingTypeHoliday: "Loma",
    bookingTypeNT: "Hoitoaika",
    bookingTypeSick: "Sairas",
    bookingTypeSNT: "Vuorohoito", //JMa?
    child: "Lapsi",
    childFiles: "Lapseen liittyvät tiedostot",
    ChildrenTalkMethodUsed1: "'Lapset puheeksi'",
    ChildrenTalkMethodUsed2: "-keskustelu pidetty",
    ci_CentreName: "Päiväkoti",
    ci_CentreAddress: "Päiväkodin osoite",
    ci_CentrePhone: "Päiväkodin puhelinnumero",
    ci_ManagerName: "Päiväkodin johtaja",
    ci_OpenType: "Aukiolotyyppi",
    ci_OpenTimes: "Aukioloajat",
    ci_GroupName: "Ryhmän nimi",
    ci_GroupPhone: "Ryhmän puh.nro",
    ci_placementGoodUntil: "Sijoituksen voimassaoloaika",
    choosePicturesToDownload: "Valitse kuvat, jotka haluat ladata.",
    placementInformation: "Sijoitustiedot",
    placementDayCareCentre: "Päiväkotitiedot",
    menuPlacementInformation: "Sijoitustiedot",
    ciAddressCity: "Kaupunki",
    ciAddressPostalcode: "Postinumero",
    ciAddressStreet: "Katuosoite",
    ciAllergies: "Allergiat",
    ciBackupPersons: "Varahakijat",
    ciBackupPersonsNone: "Lapselle ei ole lisätty varahakijoita",
    ciBackupPersonRemovalSuccess: "Poisto onnistui",
    ciBackupPersonRemovalFail: "Poisto epäonnistui",
    ciBackupPersonRemoveConfirmation: "Poista varahakija?",
    ciDeleteBackupParent: "Poista varahakija",
    ciDeleteYes: "Kyllä",
    ciDeleteNo: "Ei",
    ciBasicInfo: "Perustiedot",
    ciContactInformation: "Yhteystiedot",
    ciInfo1:
      "Puhelinnumeron on oltava 7-12 numeroa pitkä.\nJa voi olla muotoa +358 44 1234567 tai muotoa 044 1234567.\nVälilyönnit ovat sallittuja.",
    ciName: "Nimi",
    ciFirstName: "Etunimi",
    ciLastName: "Sukunimi",
    ciNoNumber: "Ei numeroa",
    ciShortEmailAddress: "@mail",
    ciShortPhoneNumber: "Puh.nro.",
    ciShortPrimaryNumber: "Ensisijainen puhelinnumero",
    ciShortWorkNumber: "Työnro.",
    ciAlbum: "Valokuvakansiot",
    ciAlbumNote:
      "Valokuvakansiot löytyvät toistaiseksi vain DaisyNet:stä.\nNämä lisätään seuraavaan DaisyFamilyn beta versioon",
    clChildren: "Lapset",
    clChildrenNotFound: "Henkilölle ei löydy lapsia",
    clChildrenLoadingData: "Lasten tietojen hakeminen",
    cookieMoreInfo: "Lisätietoja",
    cookieNote:
      "Käyttämällä DaisyFamily-palvelua hyväksyt evästeiden tallentamisen laitteellesi.",
    downloadMedia: "Lataa",
    emailNotifications: "Sähköposti-ilmoitukset",
    oldMessagingSetting: "Näytä vanha viestitys toteutus",
    oldMessages: "Vanhat viestit",
    newMessages: "Uudet viestit",
    emptyFolder: "Kansio on tyhjä",
    emptyGrowthFolder: "Kasvunkansio on tyhjä",
    errorBackend: "Virhe taustapalvelussa. Yritä hetken kuluttua uudelleen.",
    errorBackendContactSupport:
      "Virhe taustapalvelussa. Ota yhteyttä kunnan Daisy-pääkäyttäjään.",
    errorBadRequest: "Virheellinen hakuperuste",
    errorCode: "Virhekoodi",
    error: "Virhe",
    errorChangingFailed: "Tallennus palvelimelle epäonnistui",
    errorCityName: "Virheellinen kaupungin nimi",
    errorCommuneNotAvailable: "Paikkakunnan nimeä ei saatavilla",
    errorCommuneNotFound: "Paikkakuntaa ei löydy",
    errorDataCouldNotBeDownloaded: "Tietoja ei voitu ladata",
    errorDelBooking:
      "Varaus/poissaolo merkintä on yksikön lukitusajalla, jonka vuoksi sitä ei voida poistaa.",
    errorInDB: "Tietokantavirhe",
    errorInformationMissing: "Tietoja puuttuu",
    errorInLogin: "Tunnus ja/tai salasana väärin!",
    errorLoadImage: "Kuvan lataus epäonnistui.",
    errorLoadImageFolders: "Kuvakansioiden lataus epäonnistui.",
    errorLoadImages: "Kuvien lataus epäonnistui.",
    errorLockedDaysOnly:
      "Olet valinnut lukitun päivän/päiviä, \netkä voi samaan aikaan valita lukkiutumattomia päiviä.",
    errorLoginOptionsNotFound:
      "Palveluun ei löydy kirjautumisasetuksia.\nOta yhteyttä palvelun tarjoajaan.",
    errorMessageSending:
      "Viestin lähetys palvelimelle epäonnistui. Kopioi viestisi sisältö ja yritä myöhemmin uudelleen",
    errorMsgProbablyNotSent:
      "Viestin lähetyksessä tapahtui virhe. Viestiä ei todennäköisesti ole lähetetty. Kopioi viestisi sisältö talteen ja yritä myöhemmin uudelleen",
    errorNetwork: "Verkkovirhe",
    errorNotFound: "Tietoa ei löydy!",
    errorNoPlacements: "Ei voimassa olevia sijoituksia",
    errorNTsummary:
      "Käytettävissä olevan hoitoajan tietoja ei voitu ladata. Yritä myöhemmin uudelleen.",
    errorNameCantBeEmpty: "Henkilön nimi ei saa olla tyhjä",
    errorPostalCode: "Virheellinen postinumero",
    errorSendingFailed: "Lähettäminen epäonnistui",
    errorStreetAddress: "Virheellinen katuosoite",
    errorTimesInvalid: "Hoitoajat asetettu väärin",
    errorUnauthorizedNoActiveChilds:
      "Ei DaisyFamilyn käyttöoikeutta, ei aktiivisia lapsia.",
    errorUnknown: "Tuntematon virhe",
    errorUnLockedDaysOnly:
      "Lukkiutumattoman päivän kanssa ei voi yhtä aikaa valita lukittuja päiviä.",
    errorWithMsgSending: "Virhe viestiä lähetettäessä",
    errorEsuomiLoginNoChild: "Yhtään lasta ei löytynyt",
    errorEsuomiLoginNoActive: "Käyttöoikeus on loppunut",
    errorEsuomiLoginUserNotFound: "Käyttäjää ei löytynyt",
    errorEsuomiLoginUnknown: "Kirjautumisessa tapahtui odottamaton virhe",
    errorEsuomiLogin: "Virhe kirjauduttaessa",
    errorEsuomiClientCancel: "Tunnistus peruttiin",
    errorEsuomiAuthFail:
      "Tunnistus epäonnistui valitulla tunnistusmenetelmällä",
    errorEmailWrongFormat: "Sähköposti osoite väärää muotoa",
    fileModalHeader: "Tiedosto",
    fileEmptyDesc: "Ei kuvausta saatavilla.",
    fileTypeWrong:
      "Tiedostomuotoa ei tueta tai tiedosto korruptoitunut. Tiedoston latauksessa tai avaamissa voi ilmetä ongelmia.",
    fileUploaded: "Tallennettu",
    fileDescription: "Kuvaus",
    fileFileName: "Tiedoston nimi",
    fileSaved: "Tallennettu",
    fileType: "Tiedostomuoto",
    generalOpen: "Avaa",
    generalOpenText:
      "Avaa tiedosto selaimeesi. Ponnahdusikkunat täytyy olla sallittuna selaimesi asetuksissa.",
    generalBack: "Peruuta",
    generalClose: "Sulje",
    generalOkBtn: "OK",
    generalDataSaved: "Tiedot tallennettu",
    generalDownloadFileAttachment: "Lataa liitetiedosto",
    generalDownloadFileAttachment2: "Lataa tiedosto laitteellesi",
    generalDownloadImage: "Lataa kuva",
    generalPassword: "Salasana",
    generalLoadingTitle: "Ladataan",
    generalPasswordExpiringTitle: "Salasana vanhenemassa",
    generalPasswordExpiringWarning:
      "Salasanasi vanhenee {0}. Haluat vaihtaa salasanan nyt? Jos salasana vanhenee teidän täytyy olla yhteydessä päiväkotiin",
    generalSaveOrCancel:
      "Lomaketta muutettu. Tallenna tai peruuta muutos ensin",
    generalSaveModifications: "Peruuta muutokset?",
    generalWeek: "Viikko",
    generalName: "Nimi",
    generalDate: "Päivä",
    generalStarts: "Alkaa",
    generalEnds: "Loppuu",
    generalNotifications: "Ilmoitukset",
    generalAreYouSure2Leave: "Haluatko varmasti poistua?",
    generalInfo: "Tiedot",
    generalLastModified: "Viimeksi muokattu",
    generalQuestionsForParents: "Kysymyksiä vanhemmille",
    formChange: "Lomakkeen muutos",
    logged: "Lomake lukittu lukutilaan",
    loginBtn: "Kirjaudu",
    loginCapsLockAlert: "Caps Lock päällä!",
    loginGeneralInfo: "Ole hyvä ja kirjaudu sisään!",
    loginHere: "Kirjaudu sisään tästä", //todo FirstTime?
    loginLoadingData: "Ladataan tietoja",
    loginOptionsInfo: "Valitse kirjautumistapa",
    loginProcessingInfo: "Kirjaudutaan",
    loginUserName: "Käyttäjätunnus",
    loginUsernameAndPassword: "Käyttäjätunnus ja salasana",
    loginPasswordMustBeChanged:
      "Pääkäyttäjä on asettanut että salasanasi pitää vaihtaa. Paina OK siirtyäksesi salasanan vaihto sivulle",
    loginPasswordChangeTitle: "Salasanan vaihto",
    logoutAutoTitle: "Automaattinen uloskirjautuminen",
    logoutAutoInfo:
      "Ei toimintoja.\nAutomaattinen uloskirjautuminen {0} minuutin {1} sekunnin päästä",
    logoutAutoSessionOldInfo:
      "Istunto vanhentunut. Paina ok kirjautuaksesi uudelleen.",
    logoutBtn: "Kirjaudu ulos",
    logoutEsuomiSSOTitle: "eSuomi SSO uloskirjautuminen",
    logoutEsuomiSSOBody:
      "Kirjaudutaan ulos esuomi palveluista. Klikkaa OK jatkaaksesi.",
    logoutEsuomiBtn: "Kirjaudu ulos eSuomesta",
    menuChildren: "Lapset",
    menuHome: "Koti",
    menuLogout: "Kirjaudu ulos",
    menuMessaging: "Viestitys",
    menuNotifyAbsence: "Tänään poissa",
    menuNotifyAbsenceToday: "Tänään poissa",
    menuSettings: "Asetukset",
    msgWishToRemoveThread: "Haluatko poistaa inboxistasi viestiketjun",
    msgYesRemove: "Kyllä, poista",
    msgCancel: "Peruuta",
    msgChooseChild: "Valitse lapsi",
    msgChooseRecipients: "Valitse vastaanottaja(t)",
    msgWriteAndSend: "Kirjoita ja lähetä",
    msgGoToMessage: "Siirry viestiketjuun",
    msgDeleteThread: "Poista viestiketju",
    msgThreadRemovalNotSuccessful:
      "Viestiketjun poistaminen inboxista ei onnistunut",
    msgThreadRemovalSucceeded: "Viestiketjun poistaminen inboxista onnistui",
    msgRemovingThreadFromInbox: "Poistetaan viestiketjua inboxista",
    msgCaretakers: "Huoltajat",
    msgCharacterLimitWarning:
      "Varmistathan, että viesti ei ole pidempi kuin 5000 merkkiä.",
    msgCouldNotLoadRecipientsFromServer:
      "Vastaanottajatietoja ei voitu hakea palvelimelta.",
    msgDenyReply:
      "Lähettäjä on valinnut, että tähän viestiketjuun ei voi vastata",
    msgGroupsAndDaycares: "Ryhmät ja päiväkodit",
    msgInboxTitle: "Viestit",
    msgIncludeStaffTitle: "Liitä viestiin työntekijä tai ryhmä",
    msgIncludeStaffBody:
      "Viestin lähetystä varten tarvitaan ainakin yksi työntekijä tai ryhmä",
    msgInfo1:
      "Valitse yksittäinen työntekijä vastaanottajaksi vain,\njos siihen on jokin erityinen syy.\nHenkilökohtaisia viestejä luetaan harvemmin, eivätkä\nne päädy suoraan päiväkotiryhmän senhetkisille työntekijöille.",
    msgInfo2:
      "Valitse ryhmä tai päiväkoti vastaanottajaksi, kun haluat,\nettä viesti saavuttaa lasta hoitavan työntekijän/työntekijät\nmahdollisimman nopeasti ja luotettavasti.\nRyhmän viestit välitetään päiväkotiryhmän työntekijäpuhelimeen (suositeltu vaihtoehto).",
    msgLoadingThread: "Ladataan viestiketjua",
    msgMarkAllAsRead: "Merkkaa kaikki luetuiksi",
    msgMarkAllAsReadShort: "Kaikki luetuiksi",
    msgMarkAllAsReadConfirmation:
      "Haluatko varmasti merkitä kaikki viestit luetuiksi?",
    msgMarkMessagesSuccess: "Viestit merkittiin luetuiksi",
    msgMarkMessagesFailed: "Viestien merkitseminen luetuiksi epäonnistui",
    msgMe: "Minä",
    msgNewMsgBodyInfo: "Kirjoita viesti",
    msgMessageNew: "Uusi viesti",
    msgCouldNotBeLoaded: "Viestejä ei voitu ladata palvelimelta",
    msgNewMsgTitleInfo: "Viestin aihe",
    msgNewMsgThreadTitle: "Viestiketjun otsikko",
    msgAddrecipientshere: "Muuta vastaanottajia",
    msgAttachment1singular: "Viestiin on liitetty",
    msgAttachment2singular: "liitetiedosto, voit ladata sen alta",
    msgAttachment1: "Viestiin on liitetty",
    msgAttachment2: "liitetiedostoa, voit ladata ne alta", //= Viestiin on liitetty 3 liitetiedostoa, voit ladata ne alta
    msgRecipients: "Vastaanottajat",
    msgSending: "Lähetetään viestiä",
    msgSentWaitingForServer:
      "Lähetetään viestiä, odotetaan palvelimen vastausta", //??
    msgTitleTooLong: "Otsikko on liian pitkä",
    msgyouHaveRemovedThisMessage: "Olet poistanut tämän viestin",
    msgNoContent: "Viestillä ei ole sisältöä.",
    msgNoMessagesInfo: "Ei viestejä",
    msgNumber: "Lkm",
    msgOthers: "muut",
    msgParticipants: "osallistujaa",
    msgReplyToMessageThread: "Vastaa viestiketjuun",
    newest: "Uusin",
    notAllowed: "Ei sallittu",
    notAllowEmailNotificationsExplanation:
      "Et vastaanota ilmoituksia sähköpostitse uusista hakemuksista, viesteistä tai ilmoituksista koskien lastasi.",
    notAllowPushNotificationsExplanation:
      "Et vastaanota push ilmoituksia mobiili laitteeseen uusista viesteistä tai ilmoituksista koskien lastasi.",
    noteGoToBookings: "Siirry hoitoaikakalenteriin",
    noFilesToShow: "Ei näytettäviä tiedostoja",
    noReservedNursingTimes: "Ei varattuja hoitoaikoja",
    oldest: "Vanhin",

    passwordsMatch: "Salasanat täsmäävät",
    passwordTenChars: "Ainakin 10 merkkiä",
    passwordOneCapital: "Ainakin 1 iso kirjain",
    passwordOneNumber: "Ainakin 1 numero",

    //perms and consents
    noAnswer: "Ei vastattu",
    permsPermitsGiven: "Annetut luvat:",
    permAnswersNotSaved: "Vastauksia ei tallennettu!",
    permAnswerAllQuestions: "Vastaa jokaiseen kysymykseen ennen tallentamista",
    permSaveConsents: "Tallenna suostumukset",
    permSaving: "Tallennetaan",
    permSaved: "Tallennettu!",
    permParentConsentSaved: "Suostumusten tallennus onnistui!",
    permChildPermPermsSaved: "Lapsen luvat tallennettu",
    permSavingFailed:
      "Tallennus epäonnistui! Yritä myöhemmin uudelleen. Virhe:",
    permYes: "Kyllä",
    permNo: "Ei",
    permQuestionName: "Kysymyksen nimi",
    permPermissionQuestionsAria: "lupa-kysymykset",
    permGuardiansConsents: "Huoltajan suostumukset",
    permGuardianPermQPanel: "huoltajan-suostumukset-paneeli",
    permChildrenPermissions: "Lasten luvat",
    permEachChildPage: "Lasten luvat löytyvät kunkin lapsen omalta sivulta",
    permUnansweredPermissions: "Vastaamattomia lupia",
    permNoUnansweredPermissions: "Ei vastaamattomia lupia",
    permInfoNeedsUpdating: "Tietosi kaipaavat päivitystä!",
    permNeedConsent: "Varhaiskasvatuspalvelut tarvitsevat suostumustanne",
    permGoToPermissions: "Siirry suostumuksiin",
    permSaveChildPermissions: "Tallenna lapsen luvat",
    permMunicipalPermissionQuestions: "Lapsen luvat",
    permNoQuestionsToShow: "Ei näytettäviä kysymyksiä",
    permissionQuestionsGen: "Luvat ja suostumukset", // pitkäaikaiset, lyhytaikaiset ja huoltajan suostumukset
    permissions: "Luvat",
    pushNotifications: "Push ilmoitukset",
    pdGrowthFolderTitle: "Pedagoginen dokumentaatio",
    retry: "Yritä uudestaan",
    taskFolder: "Tehtäväkansio",
    settingEmptyInformation: "Tyhjennä tiedot",
    settingEmptyInformationInfo:
      "Voit tyhjentää sovelluksen tiedot täydellisesti tästä (kuntatiedot, kirjautumistiedot)",
    settingLanguagesTitle: "Kielet",
    settingLoginTitle: "Kirjautuminen",
    settingLoginInfo:
      "Kirjautuminen sisään sovellukseen tapahtuu aina vahvalla tunnistautumisella.\n(pankkitunnistauminen, mobiilitunnistautuminen)",
    settingLoginSettings: "Kirjautumisasetukset",
    settingPasswordChangeOK: "Salasanan vaihto onnistui",
    settingPasswordChangeFAIL: "Salasanan vaihto epäonnistui",
    settingPasswordChangeInvalid:
      "Uuden salasanan pitää olla vähintään 10 merkkiä pitkä,\nsisältää vähintään yhden numeron\nja yhden isonkirjaimen!",
    settingPasswordDoesntMatch: "Antamasi salasanat eivät täsmää!",
    settingPasswordNew: "Kirjoita uusi salasana",
    settingPasswordNewAgain: "Salasana uudelleen",
    settingNewUserNote:
      "Ole hyvä ja aseta käyttäjätunnuksellesi salasana. Jatkossa voit kirjautua käyttäjätunnuksella/salasanalla tai vahvan tunnistuksen kautta.",

    termsHeaderMain: "DaisyFamily-verkkopalvelun käyttöehdot",
    termsHeader1: "1.	Yleistä verkkopalvelun käyttämisestä",
    termsHeader2: "2.	Palveluntarjoaja",
    termsHeader3: "3.	Henkilötietojen käsittely",
    termsHeader4: "4.	Evästeet",
    termsHeader5: "5.	Palvelun käyttäminen",
    termsHeader5sub1:
      "5.1	Palvelun käyttämistä koskevat oikeudet ja rajoitukset",
    termsHeader5sub2: "5.2	Palvelun muutokset",
    termsHeader5sub3: "5.3	Rekisteröityminen",
    termsHeader6:
      "6.	Palvelun sisältämät linkit ulkopuolisille sivustoille ja ulkoiset linkit Palveluun",
    termsHeader7: "7.	Immateriaalioikeudet",
    termsHeader8: "8.	Vastuunrajoitukset",
    termsHeader9: "9.	Ehtojen muutokset ja ilmoitukset",
    termsHeader10: "10.	Sovellettava lainsäädäntö ja riitojenratkaisu",

    termsPara1:
      "Tervetuloa WhileOnTheMove Oy:n (jäljempänä “WOM”) verkkopalveluun (jäljempänä “Palvelu”). Käyttäjää kehotetaan tutustumaan näihin käyttöehtoihin (jäljempänä ”Ehdot”) huolellisesti ennen Palvelun käyttämistä.",
    termsPara2:
      "Nämä Ehdot soveltuvat kaikkiin käyttäjän toimiin Palvelussa riippumatta siitä, millä päälaitteella Palvelua käytetään. Käyttäjän on sallittua käyttää Palvelua ainoastaan kulloinkin voimassa olevien Ehtojen ja soveltuvan lainsäädännön sekä hyvän tavan mukaisesti. Palvelu voi lisäksi sisältää osioita tai toimintoja, joiden käyttämiseen sovelletaan näiden Ehtojen lisäksi Palvelussa erikseen annettavia erityisehtoja tai -ohjeita, ja niiden käyttäminen saattaa edellyttää rekisteröitymistä. Jokin Palvelun sisältö saattaa olla vain joidenkin maantieteellisten alueiden asukkaiden saatavilla.",
    termsPara3:
      "Ehdot muodostavat sopimuksen käyttäjän ja WOM:n välillä, ja määrittelevät käyttäjän ja WOM:n Palvelua koskevat oikeudet ja velvollisuudet.",
    termsPara4:
      "Palvelun käyttäminen on osoitus siitä, että käyttäjä hyväksyy kulloinkin voimassa olevat Ehdot ja sitoutuu noudattamaan niitä sekä Palvelussa erikseen annettavia ehtoja ja ohjeita.",
    termsPara5: "y-tunnus:",
    termsPara6: "Puhelin:",
    termsPara7:
      "WOM käsittelee Palvelun välityksellä saatavia, käyttäjää koskevia henkilötietoja kulloinkin voimassa olevan henkilötietolainsäädännön sallimalla ja velvoittamalla tavalla. WOM:n asiakkuuteen ja muuhun asialliseen yhteyteen perustuvan rekisterin tietosuojaselosteessa kuvataan henkilötietojen käsittelyä tarkemmin.",
    termsPara8:
      "Vieraillessasi sivustollamme (https://kunta.daisyfamily.fi), sivustomme tallentaa käyttämällesi laitteelle evästeitä (ml. Local ja session storage) eli pieniä tekstitiedostoja. Evästeiden avulla ei voida vahingoittaa laitettasi tai levittää haittaohjelmia.",
    termsPara9:
      "Sivustomme käyttää vain välttämättömiä teknisiä evästeitä, joiden tarkoituksena on mahdollistaa sivuston turvallinen, sujuva ja tarkoituksenmukainen käyttö. Esimerkiksi sivustolle kirjautumisessa ja kielivalinnan tallentamisessa käytämme hyväksi evästeitä.",
    termsPara10:
      "Tekniset evästeet on mahdollista kytkeä pois käytöstä käyttämäsi selaimen asetuksista. Haluamme kuitenkin huomauttaa, että kytkemällä kaikki evästeet pois käytöstä, estyy sivustollemme kirjautuminen. Oman evästehistorian poistaminen on mahdollista selaimesi evästeasetusten kautta eikä se estä sivustolle kirjautumista.",
    termsPara11:
      "WOM myöntää käyttäjälle näissä Ehdoissa määritellyn rajoitetun, peruutettavissa olevan, ei-yksinomaisen ja henkilökohtaisen oikeuden käyttää Palvelua ja sen sisältöä ainoastaan näiden Ehtojen ja Palvelussa mahdollisesti erikseen annettavien ehtojen ja ohjeiden mukaisesti. Käyttäjällä on oikeus käyttää Palvelua ja sen sisältöä ainoastaan Käyttäjän omaan, ei-kaupalliseen käyttöön.",
    termsPara12:
      "Käyttäjä ei saa muokata tai jäljentää Palvelua tai sen sisältöä taikka niiden osaa. Käyttäjällä ei myöskään ole oikeutta sellaisenaan tai muokattuna jakaa, levittää, välittää, esittää julkisesti tai muulla tavoin saattaa Palvelua tai sen sisältöä yleisön saataviin, ellei siihen ole näissä Ehdoissa nimenomaisesti annettu lupaa.",
    termsPara13:
      "Käyttäjällä on oikeus ottaa Palvelun sisällöstä tuloste tai kopio tai tallentaa kopio omalle päälaitteelleen omaa yksityistä käyttöään varten. Käyttäjän tulee säilyttää kaikissa tälläisissa tulosteissa ja tallenteissa Palvelun sisältämät tekijänoikeuksiin ja muihin oikeuksiin liittyvät ilmoitukset.",
    termsPara14:
      "Käyttäjä ei saa keskeyttää tai yrittää keskeyttää tai muutoin häiritä tai yrittää häiritä Palvelun toimintaa tai toisten käyttäjien mahdollisuuttaa käyttää Palvelua millään tavoin. Kiellettyjä toimia ovat esimerkiksi ajastattuja häiriöohjelmia, viruksia tai muuta Palvelun ohjelmalliseen rakenteeseen vaikuttamaan pystyviä osia sisältävän aineiston sijoittaminen Palveluun.",
    termsPara15: "Käyttäjä ei saa käyttää Palvelua",
    termsPara16: "esiintyäkseen WOM:n edustajana",
    termsPara17:
      "otsikko- tai tunnistetietojen väärentämiseen tai käsittelemiseen tavalla, jolla pyritään peittämään Palvelun kautta välitetyn sisällön alkuperä",
    termsPara18: "muunlaiseen väärien tietojen antamiseen",
    termsPara19:
      "muiden käyttäjien henkilötietojen tallentamiseen tai keräämiseen",
    termsPara20:
      "roskapostin tai muun luvattoman tietoliikenteen välittämiseen",
    termsPara21: "muihin lain tai hyvän tavan vastaisiin toimiin.",
    termsPara22:
      "Palvelu sisältöineen tarjotaan ”sellaisena kuin se on”. WOM on oikeutettu milloin tahansa muokkaamaan tai muuttamaan Palvelua tai sen sisältöä ilman ennakkoilmoitusta tai korvausvelvollisuutta. WOM:lla on siten oikeus mm. muokata, poistaa tai lisätä osia Palvelusta sekä muuttaa maksuttomia osioita maksullisiksi tai päinvastoin. WOM:lla on myös oikeus milloin tahansa keskeyttää tai lopettaa Palvelun, sen sisällön tai minkä tahansa osion tarjoaminen kokonaan taikka rajoittaa Palvelun tai sen osioiden käyttämistä. Jos käyttäjä on toiminut näiden Ehtojen, Palvelussa erikseen annettujen ehtojen tai ohjeiden taikka lakien tai hyvien tapojen vastaisesti, WOM:lla on oikeus estää käyttäjän pääsy Palveluun ja kieltää Palvelun käyttäminen jatkossa.",
    termsPara23:
      "Edellä kuvatun käyttöoikeuden saaminen tietyissä Palvelun osioissa saattaa edellyttää käyttäjän rekisteröitymistä. Oikeus käyttää rekisteröintiä edellyttäviä osioita voidaan rajata koskemaan ainoastaan luonnollisia henkilöitä tai oikeushenkilöitä osiokohtaisesti.",
    termsPara24:
      "Rekisteröityessään Palvelun käyttäjäksi käyttäjän tulee antaa itsestään oikeat pyydetyt tiedot. Väärien tietojen antaminen on kielletty, ja se saattaa johtaa seuraamuksiin.",
    termsPara25:
      "Rekisteröityessään käyttäjälle annetaan käyttäjätunnus ja käyttäjä valitsee itselleen salasanan. Käyttäjä on vastuussa salasanan huolellisesta säilyttämisestä ja salassapidosta ja vastaa kaikesta käyttäjätunnuksillaan tapahtuvasta asioinnista Palvelussa. Käyttäjän tulee olla välittömästi yhteydessä WOM:iin, jos käyttäjä havaitsee tai epäilee, että hänen tunnuksensa ovat paljastuneet tai että hänen tunnuksiaan käytetään väärin. WOM:lla on oikeus jäädyttää tai sulkea käyttäjän tunnukset.",
    termsPara26:
      "Palvelu voi sisältää linkkejä verkkosivustoille, jotka ovat kolmansien osapuolien omistamia tai ylläpitämiä. Linkitetyt sivustot eivät ole millään lailla WOM:n hallinnassa eikä WOM valvo tai tarkista linkitettyjen sivustojen sisältöä. WOM ei vastaa linkitettyjen sivustojen sisällöstä, oikeellisuudesta, luotettavuudesta tai tietoturvasta.",
    termsPara27:
      "Ulkopuolisille sivustoille johtavien linkkien käyttäminen siirtää käyttäjän pois Palvelusta, ja käyttäjä käyttää linkitettyjä sivustoja omalla vastuullaan. Käyttäjää kehotetaan tutustumaan huolellisesti linkitetyn sivuston käyttöehtoihin ja ohjeistuksiin ennen kyseisen sivuston käytön aloittamista.",
    termsPara28:
      "Ulkoisen linkin tähän Palveluun tulee viitata suoraan Palvelun pääsivulle, ja avautua itsenäiseen selainikkunaan eikä linkittävän sivuston omaan kehykseen. Linkin ulko-asu, sijoitus ja muut ominaisuudet eivät saa vaikuttaa haitallisesti WOM:n maineeseen tai luoda virheellistä vaikutelmaa siitä, että linkittävä taho tai sen palvelut tai tuotteet liittyisivät WOM:n toimintaan tai olisivat sen sponsoroimia. Linkki ei saa myöskään sisältää WOM:n tavaramerkkejä kuviomuodossa.",
    termsPara29:
      "Palvelun sisältämät linkit ulkopuolisille sivustoille ja ulkopuoliset linkit Palveluun eivät osoita mitään WOM:n ja linkittävän tai linkitetyn kolmannen tahon välisestä suhteesta.",
    termsPara30:
      "Palvelu ja sen sisältö, kuten tekstit, kuvat ja tunnukset ovat WOM:n tai sen yhteystyökumppaneiden omaisuutta, joka on suojattu tekijänoikeuslaeilla ja kansainvälisillä tekijänoikeussopimuksilla, sekä muulla immateriaalioikeuslainsäädännöillä ja -rekisteröinneillä.",
    termsPara31:
      "Kaikki Palveluun ja sisältöön liittyvät immateriaalioikeudet (kuten tekijänoikeudet, rekisteröidyt ja rekisteröimättömät tavaramerkki- ja mallioikeudet, domain-nimet, patentit, tietokantaoikeudet sekä liikesalaisuudet) ja niiden käytöstä seuraava goodwill-arvo kuuluvat WOM:lle tai sen yhteystyökumppaneille. WOM ei myönnä käyttäjälle mitään suoria tai epäsuoria oikeuksia mihinkään immateriaalioikeuksiin.",
    termsPara32:
      "WOM pyrkii pitämään Palvelun käyttäjän käytettävissä jatkuvasti ja häiriöittä. WOM ei kuitenkaan takaa, että Palvelu on käyttäjän käytettävissä keskeytyksettömästi, oikea-aikaisesti tai virheettömästi. Palvelu sisältöineen tarjotaan käyttäjälle ”sellaisena kuin se on”. WOM ei ole vastuussa Palvelussa välitettävien tietojen tai sisällön oikeellisuudesta, luotettavuudesta, virheistä, puutteista, epätarkkuuksista tai muista vioista.",
    termsPara33:
      "WOM ei voi taata Palvelun, sen sisällön tai Palvelun kautta välitettävien tilaajan vastuulla olevien viestien, liitteiden tai linkkien tietojen tietoturvaa, eikä vastaa siitä, että Palvelun kautta lähetettävät sähköiset tiedotteet eivät sisällä viruksia tai muita haitallisia osia. WOM ei myöskään vastaa käyttäjän Palvelun välityksellä lähettämistä tai vastaanottamista taikka lähettämättä tai vastaanottamatta jääneistä tiedoista tai sisällöstä.",
    termsPara34:
      "Palvelu ei välttämättä ole saatavilla kaikissa maissa ja se voi olla saatavilla vain tietyillä kielillä. Palvelu, sen toiminta tai jotkut ominaisuudet voivat olla riippuvaisia käytettävien laitteiden yhteensopivuudesta, verkkoyhteyksistä, ja sisällön formaateista.",
    termsPara35:
      "WOM ei ole vastuussa mistään suorista, epäsuorista, välittömistä tai välillisistä tai vahingonkorvausvelvollisuuteen perustuvista vahingoista tai erityisvahingoista, mukaan lukien liiketoiminnan, sopimuksen, tuoton tai tietojen menettämsiestä tai liiketoiminnan keskeytymisestä aiheutuvat vahingot, jotka johtuvat Palvelun tai sen sisällön käyttämisestä tai käytön estymisestä taikka näistä Ehdoista, riippumatta siitä, perustuvatko vahingot sopimukseen, oikeudenloukkaukseen, huolimattomuuteen tai muuhun perusteeseen, vaikka WOM:lle olisi etukäteen ilmoitettu tällaisten vahinkojen mahdollisuudesta. Kaikissa tapauksissa WOM:n vastuu rajoittuu aina korkeintaan pakottavan lainsäädännön mukaiseen vastuuseen.",
    termsPara36:
      "Käyttäjä vapauttaa WOM:n ja kaikki sen työntekijät, johtajat ja sen lukuun toimivat vastuusta kaikkien sellaisten kanteiden ja vaateiden osalta, jotka aiheutuvat käyttäjän Palvelun käytöstä.",
    termsPara37:
      "WOM pidättää oikeuden muuttaa näitä Ehtoja harkintansa mukaan ilmoittamalla siitä käyttäjälle sähköpostitse tai Palvelun välityksellä. Ehtojen muutokset tulevat voimaan, kun niistä on ilmoitettu. Palvelun käyttämistä ilmoitushetken jälkeen pidetään ilmoituksena siitä, että käyttäjä hyväksyy Ehtoihin tehdyt muutokset.",
    termsPara38:
      "WOM voi sisällyttää Palveluun ilmoituksia. WOM voi myös lähettää käyttäjälle Palvelua koskevia ilmoituksia käyttäjän ilmoittamaan sähköpostiosoitteeseen tai puhelinnumeroon. Ilmoitukset katsotaan vastaanotetuiksi (7) päivän kuluttua siitä, kun WOM on ne lähettänyt tai laittanut esille. Palvelun käytön jatkaminen tarkoittaa, että käyttäjän katsotaan vastaanottaneen kaikki ilmoitukset lähetystavasta huolimatta.",
    termsPara39:
      "Palvelun käyttämiseen ja näihin Ehtoihin sovelletaan Suomen lakia, lukuun ottamatta sen lainvalintasäännöksiä.",
    termsPara40:
      "Osapuolet pyrkivät ratkaisemaan erimielisyydet ensisijaisesti neuvotteluteitse. Mikäli sovintoratkaisuun ei päästä, erimielisyydet ratkaistaan Oulun käräjäoikeudessa Suomessa. Kuluttajakäyttäjä voi viedä erimielisyyden myös kotipaikkansa mukaan määräytävän toimivaltaisen käräjäoikeuden tai kuluttajariitalautakunnan ratkaistavaksi.",
    termsPara41: "Käyttöehdot on laadittu ja/tai päivitetty:",
    termsPara42: "Oulussa 30. maaliskuuta 2021",

    uctAvailableTime: "Käytettävissä oleva hoitoaika",
    uctInfo1:
      "Tästä voit seurata kuinka paljon tunteja lapsellasi on jo käytettynä (= jo käytetyt tunnit + varatut tunnit) kuukaudelle. \n Jos maksuluokka on tyhjä eikä sinulla näy käytettävissä olevia tunteja, tarkoittaa se, ettei valitsemallasi sijoituksella ole lisätty hoidontarvetta DaisyManagerissa.",
    uctInvoiceBasis: "Laskutusperuste",
    uctPaymentCategory: "Maksuluokka",
    uctServiceNeedType: "Hoidontarve", //hoidontarpeen tyyppi?
    uctSpentTime: "Käytetty aika",
    uctBooker: "Varaaja",
    uctBookings: "Varaukset",
    uctBooking: "Varaus",
    uctBilling: "Laskutus",
    uctBillingCaps: "LASKUTUS",
    uctDateAbbr: "Pvm",
    uctDuration: "Kesto",
    uctHoursTotal: "Tunnit yhteensä",
    uctPaymentFree: "Ilmaiset",
    uctPreSchoolDuration: "Esiopetuksen kesto",
    uctPreSchoolTeaching: "Esiopetus",
    uctRealizedCareTimes: "Toteuma",
    womNotification: "Viesti Daisy Varhaiskasvatukselta",
    womNotificationPara1: "Tervetuloa DaisyFamilyyn!",
    womNotificationPara2: "Käytössäsi on versio 1.2.1.0.",
    womNotificationPara3:
      "DaisyFamilyn uudessa versiossa on mukana käyttöliittymän sekä taustapalveluiden optimointia",

    changePasswordBy: "Vaihda salasana antamalla sähköpostiosoitteesi",
    childsBirthdate: "Lapsen syntymäaika",
    firstTimer: "Kirjaudutko Daisyyn ensimmäistä kertaa?",
    firstTimerInfo1:
      "Kirjaudutko Daisyyn ensimmäistä kertaa? Luo tästä itsellesi käyttäjätunnus.",
    firstTimerInfo2:
      "Syötä lapsesi syntymäaika ja käytä sitä sähköpostiosoitetta, jonka olet antanut aiemmin hakiessasi hoitopaikkaa.",
    forgotten: "Unohtuiko salasana?",
    iHaveCopiedMyUserName: "Olen kopioinut käyttäjätunnukseni talteen.",
    incorrectBirthDate: "Syntymäpäivä ei oikein",
    incorrectEmailAddress: "Sähköpostiosoite ei oikein",
    infoNewUser1:
      "Käyttäjätunnuksesi näytetään vain kerran. Jos unohdat käyttäjätunnuksesi tulevaisuudessa, ota yhteyttä päiväkotiin.",
    infoNewUser2:
      "Salasanasi on lähetetty sähköpostiisi. Tarkista roskapostikansio, jos viestiä ei löydy.",
    orderUserNameAndPassword: "Tilaa käyttäjätunnus ja salasana",
    passwordReset: "Salasanan vaihtaminen",
    passwordResetInfo1:
      "Väliaikainen salasana on lähetetty antamaasi sähköpostiin. Jos sähköpostia ei löydy, tarkista roskapostisuodatin.",
    passwordResetInfo2:
      "Palaa kirjautumissivulle ja anna käyttäjätunnus ja väliaikainen salasana.",
    passwordRequirements:
      "Salasanan tulee olla vähintään 10 merkkiä pitkä ja sisältää vähintään 1 iso kirjain ja 1 numero.",
    passwordSaveInfoText: "Tallenna tästä",
    privacystatement: "Tietosuojaseloste",
    requestFailed: "Pyyntö epäonnistui",
    resetPassword: "Vaihda salasanasi",
    msgSearchForRecipient: "Hae vastaanottajaa",
    msgSelectRecipients: "Valitse vastaanottajat",
    msgSend: "Lähetä",
    msgStaffPersons: "Työntekijät ja esimiehet",
    loginStrongIdentification: "Vahva tunnistautuminen",
    termsOfUse: "Käyttöehdot",
    themeChange: "Vaihda teemaa",
    msgThreadParticipants: "Viestiketjun osallistujat",
    tips: "Vinkit",
    tipsInfo: "Näytä ohjevinkit sovelluksessa",
    msgTitleMaxLength: "Otsikon maksimipituus saavutettu",
    msgTitleMissing: "Viestistä puuttuu otsikko",
    toTheLoginPage: "Siirry sisäänkirjautumissivulle",
    tryAgainQ: "Yritä uudelleen?",
    msgUnknownPersonOrGroup: "Tuntematon henkilö tai ryhmä",
    msgUnreadMessages: "Lukemattomat viestit:",
    userManual: "Käyttöohje",
    signUp: "Luo tunnus",
    useStrongAuthenticationInstead:
      "Päiväkodillasi on käytössä vahva tunnistautuminen. Tunnistaudu sovellukseen käyttäen vahvaa tunnistautumista ja vaihda salasana DaisyFamilyssa asetukset-sivulta kirjauduttuasi sisään",
    useStrongIdentification:
      "Käytä vahvaa tunnistautumista kirjautuaksesi sisään ensimmäistä kertaa",
    observations: "Kirjoita havaintoja...",
    welcometodaisyfamily: "Tervetuloa DaisyFamilyyn",
    welcometodaisyfamily2:
      "DaisyFamily tuo tulevaisuuden varhaiskasvatuksen kotiisi!",
    welcomeToUsingDF: "Tervetuloa DaisyFamilyn käyttäjäksi!",
    msgWhomMessageConcerns: "Ketä viesti koskee?",
    settingYes: "Kyllä",
    msgYou: "Sinä",
    msgYourMsgWasSent: "Viesti on lähetetty",
    yourUserName: "Käyttäjätunnuksesi",
    prev: "EDELLINEN",
    next: "SEURAAVA",
    errCouldNotLoadData: "Tietoja ei voitu ladata palvelimelta",
    waitingservertoloaddata: "Haetaan tietoja palvelimelta",

    formSaved: "Lomake tallennettu",
    eduPlanChildDescription: "Kuvaus lapsesta",
    vasu: "Vasu",
    vasuInfo:
      "Vasu ominaisuutta ei ole asetettu päälle. Ota yhteys palvelun tarjoajaan",
    vasuLocked:
      "Vasu on lukittu ja se on lukutilassa. Voit Lukea VASU:n mutta et voi tehdä sen sisältöön muutoksia",
    Preinformation1: "Perustiedot",
    Preinformation2:
      "Päiväkotimme varhaiskasvatuksen arvot ja ryhmän tavoitteet",
    eduPlanTitle1: "Keskusteluun valmistautuminen",
    eduPlanTitle2: "Keskustelu",
    eduPlanTitle3: "Tuen tarpeet",
    eduPlanTitle4: "Kevään arvionti",
    eduPlanSubtitle1: "Henkilökunta täyttää",
    eduPlanSubtitle2: "Kysymyksiä vanhemmille",
    vasuInvoledPeople:
      "Varhaiskasvatussuunnitelman laatimiseen osallistuneet varhaiskasvatuksen henkilöt",
    medicalplan: "Lääkehoitosuunnitelma",
    attachments: "Liitteet",
    guardian: "Huoltaja",
    eceTeacher: "Varhaiskasvatuksen opettaja",
    otherParticipants:
      "Muu laatimiseen osallistunut henkilöstö ja/tai asiantuntijat",
    creationDate: "Luontipäivämäärä",
    vasuValues: "Päiväkotimme varhaiskasvatuksen arvot",
    groupObjectives: "Ryhmän tavoitteet",
    VasuChildSubTitle1: "Itsestä huolehtiminen ja arjen taidot",
    VasuChildSubTitle2: "Vuorovaikutus ja tunnetaidot",
    VasuChildSubTitle3: "Kieli",
    VasuChildSubTitle4: "Leikki",
    VasuChildSubTitle5: "Liikkuminen",
    VasuChildSubTitle7: "Tutkiminen",
    VasuChildSubTitle8:
      "Lapsen mahdollisen aiemman vasun tavoitteiden ja toimenpiteiden toteutumisen arviointi",
    VasuChildSubTitle1v2: "Kielten rikas maailma",
    VasuChildSubTitle2v2: "Ilmaisun monet muodot",
    VasuChildSubTitle3v2: "Minä ja meidän yhteisömme",
    VasuChildSubTitle4v2: "Tutkin ja toimin ympäristössäni",
    VasuChildSubTitle5v2: "kasvan, liikun ja kehityn",
    ObjectivesForPedagogicalActivitiesAndMeasuresToAchieve:
      "Tavoitteet pedagogiselle toiminnalle ja toimenpiteet tavoitteiden saavuttamiseksi",
    VasuChildSubTitle10: "Muut huomioitavat asiat",
    ChildWellbeingAndLearningObjectives:
      "Lapsen hyvinvoinnin ja oppimisen edistämisen tavoitteet ja toimenpiteet",
    OtherExtraInfo: "Muut lisätiedot",
    OtherImportanInfo: "Muut merkitykselliset asiat",
    ParentNotes: "Huoltajien havaintoja",
    VEONotes: "Varhaiskasvatuksen erityisopettajan havaintoja",
    ChildNotes: "Lapsen mielipide ja toiveet",
    OtherNotes: "Muiden havaintoja",
    OwnNotes: "Omat havainnot",
    IssuesRaisedAgreed: "Esille otetut / Sovitut asiat",
    Evaluation: "Arviointi",
    VasuChildSubTitle1Info:
      "Pukeutuminen, riisuminen, ruokailu, nukkuminen, wc-toiminnot, päivähoitoon tulo- ja lähtötilanteet",
    VasuChildSubTitle2Info:
      "Vuorovaikutus lasten ja aikuisten kanssa, suhtautuminen rajoihin ja sääntöihin, vuoron odottaminen, pettymysten sieto, tunteiden ilmaisu jne.",
    VasuChildSubTitle3Info:
      "Puheen ymmärtäminen ja tuottaminen, kielellinen muisti ja sanavarasto, lukemisen ja kirjoittamisen alkeet, mielenkiinto kirjoihin ja satuihin jne.",
    VasuChildSubTitle4Info:
      "Leikin kehitysvaihe, mielileikit, leikkikaverit, leikin kesto, mielikuvituksen käyttö, leikin mahdollisuudet ja merkitys kotona/päivähoidossa jne.",
    VasuChildSubTitle5Info: "Karkea- ja hienomotoriikka, päivittäinen liikunta",
    VasuChildSubTitle6Info:
      "Musiikillinen, kuvallinen, sanallinen ja kehollinen ilmaisu, kulttuuri",
    VasuChildSubTitle7Info:
      "Ympäristökasvatus, matemaattinen ajattelu, teknologiakasvatus",
    VasuChildSubTitleGeneralInfo1:
      "Lapselle ominaista?\n Mitä osaa, mitä opettelee, missä tuen tarvetta?",
    VasuChildSubTitleGeneralInfo2:
      "Lapselle ominaista?\n Lapsen vahvuudet, harrastuneisuus, missä tuen tarvetta?",
    VasuChildSubTitle8Info:
      "Tavoitteiden toteutuminen ja havainnot lapsen edellisestä vasusta",
    VasuChildSubTitle1Infov2:
      "Vuorovaikutus, kielen ymmärtäminen, puheen tuottaminen, muisti ja sanavarasto, kiinnostus lukemiseen ja kirjoittamiseen, opetuskieli.",
    VasuChildSubTitle2Infov2:
      "Musiikillinen, kuvallinen, käsityöllinen, sanallinen ja kehollinen ilmaisu, leikki.",
    VasuChildSubTitle3Infov2:
      "Perhe/lähiyhteisö, kulttuuri, katsomuskasvatus, media.",
    VasuChildSubTitle4Infov2:
      "Matemaattinen ajattelu, ympäristö- ja teknologiakasvatus.",
    VasuChildSubTitle5Infov2:
      "Liikkuminen, pukeutuminen, ruokailu, lepo, terveys",
    ObjectivesForPedagogicalActivitiesInfo1:
      "Lapsen vahvuudet, kiinnostuksen kohteet ja tarpeet sekä niiden huomioon ottaminen",
    ObjectivesForPedagogicalActivitiesInfo2:
      "Tavoitteet pedagogiselle toiminnalle",
    ObjectivesForPedagogicalActivitiesInfo3:
      "Toimenpiteet ja menetelmät tavoitteiden saavuttamiseksi",
    ObjectivesForPedagogicalActivitiesInfo4:
      "Mahdolliset muut kehityksen ja oppimisen tukeen liittyvät tarpeet sekä tuen toteuttamiseen liittyvät tavoitteet ja sovitut järjestelyt",
    VasuChildSubTitle10Info1:
      "Lapsen hyvinvoinnin tukemiseen liittyvät muut huomioitavat asiat",
    VasuChildSubTitle10Info2:
      "Laatimisessa hyödynnetyt muut mahdolliset asiakirjat ja suunnitelmat",
    VasuChildSubTitle10Info3: "Seuranta- ja arviointikohdat (muut lisätiedot)",
    VasuChildSubTitle10Info4: "Yhteistyö ja vastuut",
    VasuSpecialSupport: "Erityinen Tuki",
    VasuEnhancedSupport: "Tehostettu Tuki",
    VasuadministrativeDecision: "Hallintopäätös",
    VasuNoadministrativeDecision: "Ei hallintopäätöstä",
    VasuSupportTitle1: "Tuen muodot ja arviointi",
    VasuSupportTitle2: "Lapsen tuen muut huomioitavat asiat",
    VasuNoSpecialSupport:
      "Lapsi ei tarvitse erityistä tukea, tuetaan tehostetun tuen avulla",
    VasuNoEnhancedSupport:
      "Lapsi ei tarvitse tehostettua tukea, tuetaan yleisen tuen avulla",
    VasuSupportSubTitle1:
      "Lapsen kasvun, kehityksen ja hyvinvoinnin kokonaistilanne lapsen, huoltajan ja varhaiskasvatushenkilöstön näkökulmasta",
    VasuSupportSubTitle2: "Pedagogiset tuen muodot ja arviointi",
    VasuSupportSubTitle3: "Rakenteelliset tuen muodot ja arviointi",
    VasuSupportSubTitle4: "Hoidolliset tuen muodot ja arviointi",
    VasuSupportSubTitle5: "Yhteistyö ja vastuut",
    VasuSupportSubTitle6:
      "Laatimisessa hyödynnetyt muut mahdolliset asiakirjat ja suunnitelmat",
    VasuSupportSubTitle7: "Lisätiedot",

    leopsrequestFailed: "Pyyntö epäonnistui",
    leopsLockingSavedOk: "Lukitus tallennettu",
    leopsLockingSavedFailed: "Lukituksen tallennus epäonnistui",
    leopsChildEvaluationSaved: "Lapsen arvio tallennettu",
    leopsChildEvaluationSaveFailed: "Lapsen arvion tallentaminen epäonnistui",
    leopsAnswerSelectingBestChoice: "Vastaa koskettamalla sopivaa vaihtoehtoa",
    leopsStillTraining: "Harjoittelen vielä",
    leopsICanAlready: "Osaan jo",
    ManyFormsOfExpression: "Ilmaisun monet muodot",
    leopsRichWorldOfLanguage: "Kielen rikas maailma",
    leopsMeAndOurCommunity: "Minä ja meidän yhteisömme",
    leopsIStudyAndWork: "Tutkin ja toimin ympäristössäni",
    leopsIGrowAndDevelop: "Kasvan ja kehityn",
    leopsIWantToLearnInPreschool: "Haluan oppia esikoulussa",
    StaffNotes: "Varhaiskasvatushenkilöstön havaintoja",
    leopsLeopsTitle1_3: "Huoltajan osio",
    leopsChildStrengthsAndIterestsParent:
      "Lapseni vahvuudet ja mielenkiinnonkohteet",
    leopsObjectivesAndConsiderationsForThePreschool:
      "Tavoitteita ja muita huomioita esiopetusvuodelle",
    HopesForCooperation: "Toiveita yhteistyölle",
    leopsIHaveLearnedInPreschool: "Olen oppinut esikoulussa",
    leopsTitle4_3: "Opettajan kooste esiopetusvuodesta",
    ChildStrengthsAndIterests: "Lapsen vahvuudet ja mielenkiinnon kohteet",
    EvaluationPedagogicalActivitiesGoalsSupport:
      "Esiopetusvuoden pedagogisen toiminnan, tavoitteiden ja tukitoimenpiteiden arviointi",
    OtherAdditionalInfoAndCooperation:
      "Muut lisätiedot ja monialainen yhteistyö",
    GreetingsFromGuardian: "Huoltajan terveiset",
    LockLeops: "Lukitse",
    ThingsAriseFromPreviousECEC:
      "Mahdollisesta aiemmasta vasusta nousevat asiat",
    ObjectivesForPedagogicalActivitiesAndMeasuresToAchieve:
      "Tavoitteet pedagogiselle toiminnalle ja toimenpiteet tavoitteiden saavuttamiseksi",
    OtherPartnersAndDocuments: "Muut yhteistyötahot ja asiakirjat",
    StudentCareMeasures: "Oppilashuollolliset toimenpiteet",
    AgreedCooperationWithGuardians: "Sovittu yhteistyö huoltajien kanssa",
    ManyFormsOfExpressionInfo:
      "Suullinen ja kehollinen ilmaisu, monilukutaito, osallistuminen ja vaikuttaminen, keskittymiskyky, itsesäätelytaidot",
    RichWorldOfLanguageInfo:
      "Merkitysten hahmottaminen, kiinnostus kieleen, lukemiseen ja kirjoittamiseen",
    MeAndOurCommunityInfo:
      "Arjen eettiset valinnat, tunnetaidot, vuorovaikutustaidot, yhteiskunnassa ja lähiyhteisössä toimiminen",
    IStudyAndWorkInfo:
      "Matemaattinen ajattelu, arjen matematiikka ja teknologia, luontosuhde, tutkiminen",
    IGrowAndDevelopInfo:
      "Itsestä huolehtimisen taidot, terveelliset ja kestävät elämäntavat, turvataidot",
    LeopsTitle1_2: "Lapsen syksyn itsearviointi ja valmistavat havainnot",
    LeopsTitle1_2_1: "Lapsen itsearviointi (syksy)",
    EvaluationPedagogicalActivities: "Pedagogisen toiminnan arviointi",
    LockLeopsInfo:
      "Kevään arvointikeskustelu ja tiedonsiirto kouluun-osio on valmis ja haluan lukita sen. HUOM! Kun lukitset keskustelun, et voi enää lisätä tai muokata kommenttejasi.",
    LeopsTitle4_2: "Lapsen kevään itsearviointi",
    LeopsTitle4_2_1: "Lapsen itsearviointi (kevät)",
    PedagogicalEvaluation: "Pedagoginen arvio",
    PedagogicalStudy: "Pedagoginen selvitys",
    EnhancedSupportLearningPlan: "Tehostetun tuen oppimissuunnitelma",
    HOJKS: "HOJKS",
    removeFormLocking: "Poista lomakkeen lukitus",
    ChildStrengthsAndInterestsLearningNeeds:
      "Lapsen vahvuudet, kiinnostuksen kohteet, oppimisvalmiudet ja erityistarpeet",
    Confidental: "Salassa pidettävä.\nJulkisuuslaki 24 § 1 mom. 30 kohta",
    DocumentsUsedInDrafting: "Laatimisessa hyödynnetyt asiakirjat",
    EnhancedSupportInitiating: "Tehostetun tuen aloittaminen ja järjestäminen",
    EnhancedSupportAndArrangements:
      "Lapselle järjestettävä tehostettu tuki ja tukijärjestelyt kirjataan tarkemmin oppimissuunnitelmaan",
    EnhancedSupportStartingAndOrganization:
      "Tehostetun tuen aloittaminen ja järjestäminen / palaaminen takaisin yleisen tuen piiriin on käsitelty edellä olevan pedagogisen arvion perusteella",
    GeneralSupportAndAssessmentImpact:
      "Yleinen tuki ja arvio eri tukimuotojen vaikutuksista",
    PersonsInvolvedDraftingDocument:
      "Asiakirjan laadintaan osallistuneet henkilöt ja konsultointien päiväykset",
    PPH: "Perhepäiväkoti",
    PreschoolPlanCreated: "Esiopetussuunnitelma laadittu",
    AssessmentChildGrowthAndLearning: "Arvio lapsen kasvusta ja oppimisesta",
    OverallSituationChildGrowthAndLearning:
      "Lapsen kasvun ja oppimisen kokonaistilanne lapsen, huoltajan ja esiopetuksen henkilöstön näkökulmista",
    AssessmentEnhancedSupportAndArrangements:
      "Arvio tehostetun tuen tarpeesta ja tukijärjestelyistä",
    EnhancedSupportStart: "Tehostettu tuki aloitetaan",
    EnhancedSupportNotNeeded:
      "Lapsi ei tarvitse tehostettua tukea, tuetaan yleisen tuen avulla",
    EnhancedSupportLearningPlanCreated:
      "Tehostetun tuen oppimissuunnitelma laadittu",
    LeopsInfo:
      "Jos pedagoginen selvitys laaditaan erityisen tuen päätöksen tarkistamista varten tai jos opetuksen järjestämisen paikka vaihtuu, täytetään alla olevat kohdat",
    SpecialSupportFirstDate: "Erityisen tuen päätös tehty ensimmäisen kerran",
    SpecialSupportPrevDate: "Erityisen tuen päätöksen edellinen tarkistus",
    DecisionMaker: "Päätöksentekijä",
    PreschoolOrganizer: "Esiopetuksen järjestäjä",
    GeneralCompulsoryEducation: "Yleinen oppivelvollisuus",
    Started: "Alkanut",
    ExtendedCompulsoryEducation: "Pidennetty oppivelvollisuus",
    EnhancedSupportEvaluation:
      "Tehostettu tuki ja arvio eri tukimuotojen vaikutuksista",
    PreschooEducationlProviderAssessment:
      "Esiopetuksen järjestäjän arvio erityisen tuen tarpeesta",
    SpecialSupportStart: "Erityinen tuki aloitetaan",
    DateLong: "Päivämäärä",
    ResponsiblePreschollTeacher: "Vastuu esiopettaja",
    ConsultationChildAndGuardian: "Lapsen ja huoltajan kuuleminen",
    ConsultationChildAndGuardian2:
      "Lapsen ja huoltajan tai laillisen edustajan hallintolain mukainen kuuleminen",

    HOJKS_Title1:
      "Henkilökohtainen opetuksen järjestämistä koskeva suunnitelma (HOJKS)",
    LeopsFormTitle2: "Lapsen esiopetuksen ja tuen järjestäminen",
    LeopsFormTitle2_1: "Lapsen esiopetuksen tavoitteet",
    LeopsFormTitle2_2: "Lapsen oppimisvalmiudet ja tuen tarpeet",
    LeopsFormTitle3: "Pedagogiset ratkaisut",
    LeopsFormTitle3_1: "Vahvistava vuorovaikutus ja oppimisympäristö",
    LeopsFormTitle3_1_1:
      "Myönteinen ja hyväksyttävä ilmapiiri, kasvattajien aito läsnäolo, yhteiset toiminnan tavoitteet",
    LeopsFormTitle3_2: "Pedagogiset toimintamallit",
    LeopsFormTitle3_2_1:
      "Pienryhmätoiminta, kuvien käyttö, ennakointi,struktuuri",
    LeopsFormTitle3_3: "Monialainen kasvattajayhteisö",
    LeopsFormTitle3_3_1: "Vastuut ja roolit",
    LeopsHojksTitle3_4:
      "Erityistä tukea koskevan päätöksen mukaiset tulkitsemis- ja avustajapalvelut, muut opetuspalvelut sekä erityiset apuvälineet",
    LeopsEnhancedTitle3_4:
      "Perusopetuslain mukaiset tulkitsemis- ja avustajapalvelut, muut opetus palvelut sekä erityiset apuvälineet",
    LeopsFormTitle3_5:
      "Lapsen mahdollinen osallistuminen muuhun varhaiskasvatukseen",
    LeopsHojksTitle3_6:
      "Kuvaus lapsen esiopetuksen kuljetusten järjestelyistä ja vastuista sekä kuljetusta odottavan lapsen ohjatusta toiminnasta ja valvonnasta",
    LeopsHojksTitle4: "HOJKSin seuranta ja arviointi",
    LeopsEnhancedTitle4: "Oppimissuunnitelman seuranta ja arviointi",
    LeopsHojksTitle4_1: "Arvio HOJKSin tavoitteiden toteutumisesta",
    LeopsEnhancedTitle4_1:
      "Arvio oppimissuunnitelman tavoitteiden toteutumisesta",
    LeopsHojksTitle4_2: "Arvio HOJKSin toimenpiteiden vaikuttavuudesta",
    LeopsEnhancedTitle4_2:
      "Arvio oppimissuunnitelman toimenpiteiden vaikuttavuudesta",
    LeopsHojksTitle4_3:
      "Lapsen ja huoltajan kanssa yhdessä tehtävä arvio lapsen hyvinvoinnin ja oppimisen kokonaistilanteesta",
    LeopsEnhancedTitle4_3: "Seuraava seuranta ja arviointi",
    LeopsFormTitle5: "Arvioinnin tulos",
    LeopsEnhancedTitle5_2:
      "Oppimissuunnitelma vastaa lapsen tuen tarvetta ja tehostettu tuki jatkuu",
    LeopsEnhancedTitle5_3:
      "Lapselle laaditaan pedagoginen selvitys erityistä tukea varten",
    LeopsHojksTitle5_1:
      "Lapsi ei tarvitse erityistä tukea, sen lopettamisesta tehdään päätös ja lapselle laaditaan tehostetun tuen oppimissuunnitelma",
    LeopsHojksTitle5_2:
      "HOJKS vastaa lapsen tuen tarvetta ja erityinen tuki jatkuu",
    LeopsStudy6_2:
      "Dokumentti menee lukutilaan, kun huoltajan/huoltajien kuuleminen on vahvistettu",
    LeopsStudy2:
      "Erityisen tuen tarkistaminen/opetuksen järjestäjän vaihtuminen",

    //GUIDE: Booking
    guide: "OPAS",
    guideNoContent: "Ei sisältöä saatavilla",
    backToTop: "Vie takaisin ylös",
    keywordButtonTitle1: "Siirry ",
    keywordButtonTitle2: " osioon",
    help: "Apua?",
    press: "Paina",
    color: "Väri",
    text: "Teksti",
    symbol: "Symboli",
    description: "Selite",
    keywords: "Avainsanat",
    addBookingKeywordButton: "VARAAMINEN",
    addBookingKeywordButton2: "Varaaminen",
    editBookingKeywordButton: "MUOKKAAMINEN",
    absentBookingKeywordButton: "POISSAOLO",
    absentBookingKeywordButton2: "Poissaolo",
    defaultTimeBookingKeywordButton: "OLETUSHOITOAIKA",
    bookingIconsKeywordButton: "TULKINTA",
    careTimeLeftKeywordButton: "HOITOAIKA",
    invoiceDataKeywordButton: "LASKUTUSPERUSTE",
    guideFAQKeywordButton: "UKK",
    addBookingAccordionTitle: "Hoitovarausten tekeminen",
    editBookingAccordionTitle: "Hoitovarausten muokkaaminen",
    defaultTimeAccordionTitle: "Oletushoitoaika",
    absentAccordionTitle: "Poissaolosta ilmoittaminen",
    bookingIconsAccordionTitle: "Kalenterin kuvakkeet",
    careTimeLeftAccordionTitle: "Käytettävissä oleva hoitoaika",
    invoiceDataAccordionTitle: "Laskutusperuste",
    accordionTitleFAQ: "UKK",
    defaultTimeAccordionDesc: "Asettaminen ja tarkoitus",
    bookingIconsAccordionDesc: "Kuvakkeet ja värit",
    bookingAbsentAccordionDesc: "Poissa- tai sairasmerkintä",
    accordionDescFAQ: "Usein kysytyt kysymykset",
    addBookingTableTitle: "Varauskalenterin värit",
    addBookingTableDesc1:
      "Hoitovarauksia voit tehdä tulevaisuuden päiville, jotka näkyvät varauskalenterissa valkoisella pohjalla.",
    addBookingTableDesc2:
      "Mikäli lapsesi on vuorohoidossa, voi varauksia tehdä myös viikonlopuille, jotka ovat vaaleansinisellä värillä.",
    addBookingTableDesc3:
      "Varausten tekeminen on mahdollista kalenterissa myös vaaleanpunaiselle yleiselle loma-ajalle...",
    addBookingTableDesc4:
      "...ajalle ja oranssille päivystysajalle, jos varausaika ei ole vielä lukkiutunut.",
    addBookingTableDesc5: "Viikonloppu.",
    addBookingAfterTable1:
      "Hoitoaikakalenterissa voit asettaa varauksillesi oletushoitoajan vasemman alakulman ",
    addBookingAfterTable2:
      " -painikkeella, jolloin ohjelma ehdottaa oletushoitoaikaa aina varausta tehdessäsi. Oletushoitoajan voi asettaa 15 minuutin väleillä ja sen voit vaihtaa aina halutessasi.",
    addBookingAfterTable3:
      "Tämä toiminto helpottaa samanlaisten hoitovarausten tekemistä, eikä sen avulla voi tehdä varauksia.",
    addBookingMiddleTitle: "Hoitovarauksen tekeminen",
    addBookingList1:
      "Valitse kalenterista ensin haluamasi päivät, joille varaus tehdään. Voit valita yksittäisiä päiviä painamalla riviä päivän kohdalta, kokonaisia viikkoja painamalla viikkonumeroa tai kokonaisen kuukauden painamalla oikeasta alakulmasta ",
    addBookingList1_2:
      " -painiketta. Viikonloput tulee valita erikseen päiviä painamalla. Valitut päivät muuttuvat harmaiksi.",
    addBookingList2:
      "Hyväksy valinnat painamalla oikeasta alakulmasta vahvistus painiketta.",
    addBookingList3:
      "”Hoitoajat” varausnäkymässä merkitse oikeaan laitaan valinta lapsille, kenelle varaus tehdään.",
    addBookingList4:
      "Valitse lapsen nimen alapuolelta ensimmäisen valitun lapsen kohdalta, minne varaus tehdään. Valinta tehdään lapsen niin sanotun sijoitustärkeyden mukaan, eli esimerkiksi valittaessa lapsen ensisijainen sijoitus, valikoituu saman sijoitustärkeyden sijoitus myös toisille sisaruksille.",
    addBookingList5:
      "Valikossa on automaattisesti valittuna ”Hoitoaika” valinta. Tarvittaessa voit vaihtaa tähän ”Poissa” tai ”Sairas” merkinnän.",
    addBookingList6:
      "Varaus tehdään automaattisesti niille päiville, jotka valitsit kalenterista.",
    addBookingList7:
      "Merkitse haluamasi kellonaika varaukselle. Kellonajan voit merkitä 15 minuutin välein.",
    addBookingList8:
      "Jos haluat tehdä samoille päiville toisen varauksen eri kellonajoille, valitse ”+ Lisää uusi varaus” painike. Tee haluamasi valinnat toiselle varaukselle.",
    addBookingList9:
      "Tallenna varaus/varaukset painamalla alareunasta hyväksy painiketta.",
    addBooking2022Promise:
      "DaisyFamilyn hoitovarauskalenteria ollaan uudistamassa saadun palautteen perusteella vuoden 2022 aikana.",
    editBookingGuideTitle: "Olemassa olevan hoitoajan muokkaaminen",
    editBookingGuideIntro:
      "Voit muokata jo tehtyjä varauksia alla olevalla ohjeella tai halutessasi poistaa varaukset/merkinnät valitsemalla hoitoaikakalenterista haluamasi päivät ja painamalla oikeasta alakulmasta ",
    editBookingGuideIntro2: " -painiketta.",
    editBookingMiddleTitle: "Hoitovarausten muokkaaminen",
    editBookingList1:
      "Valitse kalenterista ensin haluamasi päivät, joiden varausta muokataan. Voit valita yksittäisiä päiviä painamalla riviä päivän kohdalta, kokonaisia viikkoja painamalla viikkonumeroa tai kokonaisen kuukauden painamalla oikeasta alakulmasta ",
    editBookingList1_2:
      " -painiketta. Viikonloput tulee valita erikseen päiviä painamalla. Valitut päivät muuttuvat harmaiksi.",
    editBookingList2:
      "Hyväksy valinnat painamalla oikeasta alakulmasta plus-painiketta.",
    editBookingList3:
      "Valitse ”UUDET”, jos haluat tehdä kokonaan uudet hoitovaraukset. Valitse ”SÄILYTÄ”, jos haluat tehdä nykyisten varausten lisäksi toiset varaukset valituille päiville.",
    editBookingList4:
      "Varausnäkymässä merkitse oikeaan laitaan  ruksivalinta lapsille, kenelle varaus tehdään.",
    editBookingList5:
      "Valitse lapsen nimen alapuolelta ensimmäisen valitun lapsen kohdalta, minne varaus tehdään. Valinta tehdään lapsen niin sanotun sijoitustärkeyden mukaan, eli esimerkiksi valitessa lapsen ensisijaisen sijoituksen, valikoituu saman sijoitustärkeyden sijoitus myös toisille sisaruksille.",
    editBookingList6:
      "Valikossa on automaattisesti valittuna ”Hoitoaika” valinta. Tarvittaessa voit vaihtaa tähän ”Poissa” tai ”Sairas” merkinnän.",
    editBookingList7:
      "Varaus tehdään automaattisesti niille päiville, jotka valitsit kalenterista.",
    editBookingList8:
      "Merkitse haluamasi kellonaika varaukselle. Kellonajan voit merkitä 15 minuutin välein.",
    editBookingList9:
      "Tallenna varaus/varaukset painamalla alareunasta hyväksy painiketta.",
    absentBookingIntroTitle: "Poissa- tai sairasmerkintä",
    absentBookingIntro1:
      "Poissa- ja sairasmerkintöjä voit tehdä hoitoaikakalenterissa sekä ”Tänään poissa” pikatoiminnolla, jolla voit ilmoittaa poissa- tai sairasmerkinnän kuluvalle päivälle.",
    absentBookingIntro2:
      "Poissa- ja sairasmerkinnän voit tehdä kuluvalle päivälle tai usealle päivälle tulevaisuuteen, myös lukkiutuneille päiville.",
    absentBookingIntro3:
      "Kun teet poissa- tai sairasmerkinnän lukittuneelle päivälle, jossa on jo hoitovaraus, jää hoitovaraus laskutusperusteeseen näkyviin.",
    absentBookingMiddleTitle1:
      "Poissa- tai sairasmerkinnän tekeminen hoitoaikakalenterissa",
    absentBookingMiddleTitle2:
      "Poissa- tai sairasmerkinnän tekeminen ”Tänään poissa” toiminnolla",
    absentBookingListOne1:
      "Valitse kalenterista ensin haluamasi päivät, joiden varausta muokataan. Voit valita yksittäisiä päiviä painamalla riviä päivän kohdalta, kokonaisia viikkoja painamalla viikkonumeroa tai kokonaisen kuukauden painamalla oikeasta alakulmasta ",
    absentBookingListOne1_2:
      " -painiketta. Viikonloput tulee valita erikseen päiviä painamalla. Valitut päivät muuttuvat harmaiksi.",
    absentBookingListOne2: "Hyväksy valinnat painamalla oikeasta alakulmasta ",
    absentBookingListOne2_2:
      " -painiketta. Painike vaihtelee riippuen siitä, onko kyseessä tulevaisuuden päivä vai lukkiutunut päivä.",
    absentBookingListOne3:
      "Varausnäkymässä merkitse oikeaan laitaan ruksivalinta lapsille, kenelle merkintä tehdään.",
    absentBookingListOne4:
      "Valitse lapsen nimen alapuolelta ensimmäisen valitun lapsen kohdalta, minne merkintä tehdään. Valinta tehdään lapsen niin sanotun sijoitustärkeyden mukaan, eli esimerkiksi valitessa lapsen ensisijaisen sijoituksen, valikoituu saman sijoitustärkeyden sijoitus myös toisille sisaruksille.",
    absentBookingListOne5: "Valitse valikosta ”Poissa” tai ”Sairas”.",
    absentBookingListOne6:
      "Tallenna merkintä painamalla alareunasta hyväksy-painiketta.",
    absentBookingListTwo1:
      "Valitse oikeasta yläkulmasta sivuvalikko ja sieltä ”Tänään poissa”.",
    absentBookingListTwo2:
      "Varausnäkymässä merkitse oikeaan laitaan  ruksivalinta lapsille, kenelle merkintä tehdään.",
    absentBookingListTwo3:
      "Valitse lapsen nimen alapuolelta ensimmäisen valitun lapsen kohdalta, minne merkintä tehdään. Valinta tehdään lapsen niin sanotun sijoitustärkeyden mukaan, eli esimerkiksi valitessa lapsen ensisijaisen sijoituksen, valikoituu saman sijoitustärkeyden sijoitus myös toisille sisaruksille.",
    absentBookingListTwo4: "Valitse valikosta ”Poissa” tai ”Sairas”.",
    absentBookingListTwo5:
      "Tallenna merkintä painamalla alareunasta hyväksy-painiketta.",
    BookingSymbolsTableTitle:
      "Hoitoaikakalenterin tulkinta (symbolit, värit & tekstit)",
    symbolBookingDesc1:
      "Kuvastavat perheen lapsia hoitoaikakalenterissa. Varatun päivän kohdalla näkyy niiden lasten väriympyrät, kenelle varaus on tehty. Ympyröistä painamalla näet tarkemmin valitun päivän varaustilanteen.",
    symbolBookingDesc2:
      "Kertoo poikkeuksesta. Hoitovaraus on tehty useammassa ei-peräkkäisessä pätkässä tai varatulle hoitopäivälle on tehty poissa- tai sairasmerkintä.",
    symbolBookingDesc3:
      "Oletushoitoaika – Voit asettaa tähän käyttämänne oletushoitoajan, jota ohjelma ehdottaa aina varausta tehdessäsi. Oletushoitoajan voi asettaa 15 minuutin väleillä ja sen voit vaihtaa aina halutessasi. Tämä toiminto helpottaa samanlaisten hoitovarausten tekemistä, eikä sen avulla voi tehdä varauksia.",
    symbolBookingDesc4:
      "Valintapainike – Kun olet valinnut kalenterissa päivät, joille haluat tehdä hoitovarauksia tai merkintöjä, valitse tämä valintapainike siirtyäksesi tekemään varausta tai merkintää.",
    symbolBookingDesc5:
      "Valitse kaikki – Toiminnolla voit valita koko kuukauden lukkiutumattomat arkipäivät tehdäksesi niille varauksia.",
    symbolBookingDesc6:
      "Poissaolo – Kun olet valinnut kalenterissa lukitun päivän/päiviä, joille haluat tehdä poissa- tai sairasmerkintöjä, valitse tämä poissaolopainike siirtyäksesi tekemään poissa- tai sairasmerkintöjä.",
    symbolBookingDesc7:
      "Lisää varaus – Uusien varaustenpätkien tekeminen tai nykyisten varausten korvaaminen valitsemillesi päiville.",
    symbolBookingDesc8:
      "Poista – Poistaa varaukset ja merkinnät valituilta päiviltä.",
    symbolBookingDesc9:
      "Käytettävissä oleva hoitoaika – Avaa sivuvalikon, josta näet kuukauden käytettävissä olevan hoitoajan. Täältä avaat myös lapsi- ja kuukausikohtaisen ”Laskutusperusteen”, josta näet koosteen tehdyistä varauksista ja toteutuneista hoitoajoista.",
    textBookingDesc1:
      "Sisarusten hoitovarauksen alku- ja/tai loppuaika eroavat toisistaan.",
    textBookingDesc2:
      "Tällä menneisyyden päivällä on hoitoaikavaraus ja/tai toteuma.",
    textBookingDesc3: "Viralliset pyhäpäivät, esim:",
    availableCareTimeText1:
      "Voit tarkastella käytettävissä olevaa hoitoaikaa hoitoaikakalenterin [€] painikkeen takaa. Sivuvalikossa näkyy kuukaudelle merkitty hoidontarve, maksuluokka sekä käytetty aika.",
    availableCareTimeText2:
      "Käytettyyn hoitoaikaan sisältyy kuukauden varatut tunnit sekä jo käytetyt tunnit, jotka ovat ylittäneet päiväkohtaisen varauksen. Tätä lukemaa tarkastelemalla näet, paljonko hoitoaikaa on tälle kuukaudelle vielä käytettävissä.",
    availableCareTimeText3:
      "Hoidontarpeen ja maksuluokan tiedot tulevat lapselle merkityistä taustatiedoista, joten jos ne puuttuvat, otathan yhteyttä lapsesi hoitopaikan henkilöstöön tai esihenkilöön.",
    invoiceDataText1:
      "Lapsi- ja kuukausikohtaisen laskutusperusteen löydät valitsemalla hoitovarauskalenterissa haluamasi kuukauden kohdalla [€] painikkeen ja avautuvasta sivuvalikosta ”Laskutusperuste” painikkeen.",
    invoiceDataText2:
      "Laskutusperusteessa voit selata ylälaidan painikkeista kolmea näkymää: varaukset, toteuma sekä esiopetus.",
    invoiceDataText3_1: "Varaukset ",
    invoiceDataText3_2:
      "näkymässä näet kuukaudelle tehdyt varaukset, niiden keston, varaajan sekä vaikutuksen kuukausilaskutukseen. Keltaisella pohjalla olevat merkinnät tarkoittavat, että laskutus perustuu kyseessä olevan päivän kohdalla tehtyyn hoitoaikavaraukseen.",
    invoiceDataText4_1: "Toteuma ",
    invoiceDataText4_2:
      "näkymässä näet toteutuneen hoitoajan minuutin tarkkuudella, toteutuneen päivän keston sekä vaikutuksen kuukausilaskutukseen. Vihreällä pohjalla olevat merkinnät tarkoittavat, että laskutus perustuu kyseessä olevan päivän kohdalla toteutuneeseen hoitoaikaan.",
    invoiceDataText5_1: "Esiopetus ",
    invoiceDataText5_2:
      "näkymässä voit tarkastella esiopetuksen kestoa sekä sen vaikutusta kuukausilaskutukseen. Esiopetusaika on maksutonta ja siksi vähennetään laskutuksesta. Huom! Kaikilla kunnilla ja yksityisillä päiväkodeilla ei ole käytössä tätä Daisyn esiopetusaikaa. Jos Daisyn esiopetusaika ei ole käytössä, ei lapsesi esiopetusajan kertymä näy tässä näkymässä.",
    invoiceDataText6:
      "Laskutusperusteen alareunassa näet kuukauden laskutuksen yhteenvedon.",
    guideDifferences: "Eri merkintöjä",
    guideTimeReserved: "Varaus/Toteuma",
    guideRedText: "Punainen teksti",
    redTextExample: "Jouluaatto",
    notificationsSettings: "Ilmoitus-asetukset",

    //GUIDE: Messaging
    //Viestinäkymä
    defaultMessagingAccordionTitle: "Viestinäkymä",
    defaultMessagingAccordionDesc: "Lisätietoa viestinnästä",
    defaultMessagingSubTitle1: "Viestitysnäkymä",
    defaultMessagingIntro:
      "Viestinäkymässä hoidat viestinnän lapsesi hoitopaikan henkilöstön kanssa.",
    defaultMessagingList1: "Viestitys avautuu",
    defaultMessagingList2:
      "-viestipainikkeesta, joka löytyy sivun vasemmasta laidasta.",
    defaultMessagingList3: "Voit myös avata viestit oikean yläkulman",
    defaultMessagingList4:
      "-tapahtumaseloste painikkeesta ja siirtymällä 'ilmoitukset' kohtaan, jonka jälkeen saat viestit auki painamalla",
    defaultMessagingList5:
      "-näytä lisää painiketta ja valitsemalla viestiketjun.",
    defaultMessagingMiddleTitle: "Viestien lukumäärä",
    defaultMessagingList6: "Oranssi pallo numeron kanssa ",
    defaultMessagingList7:
      "-viestipainikkeessa kertoo niiden viestilaatikossa olevien viestiketjujen lukumäärän, joissa on lukemattomia viestejä.",
    defaultMessagingList8:
      "Näet lukemattomien viestien lukumäärän myös oikean yläkulman 'ilmoitukset' valikosta.",
    defaultMessagingMiddleTitle2: "Lueutu ja lukemattomat viesti",
    defaultMessagingList9:
      "Viestilistauksen vaaleansiniset viestit on luettu ja tummansinisiä viestejä ei ole vielä avattu. Listauksessa tuoreimmat viestit näkyvät ylimmäisenä.",
    defaultMessagingMiddleTitle3: "Näkymä",
    defaultMessagingList10:
      "Viestintä näkyy pikaviestisovelluksista tuttuun tapaan viestiketjuina. Muiden viestit näkyvät viestiketjun vasemmassa laidassa ja omat viestisi ketjun oikeassa laidassa. Viestiketjun ylälaidassa näkyvät mukana olevat vastaanottajat.",

    //Viestityksen painikkeet
    messagingButtonsAccordionTitle: "Viestityksen painikkeet",
    messagingButtonsAccordionDesc: "Lisätietoa viestinnän painikkeista",
    messagingButtonsSubTitle1: "Viestityksen painikkeet",
    messagingButtonList1: "Uusi viesti",
    messagingButtonList2: " - aloita uusi viestiketju.",
    messagingButtonList3: "Päivitä",
    messagingButtonList4: " - päivittää viestityksen näkymän ajan tasalle.",
    messagingButtonList5: "Kaikki luetuiksi",
    messagingButtonList6: " - merkitsee kaikki lukemattomat viesti luetuiksi.",
    messagingButtonList7: "Poista",
    messagingButtonList8: " - poistaa viestin.",

    //Uusi viestiketju
    messageThreadAccordionTitle: "Uusi viestiketju",
    messageThreadAccordionDesc: "Lisätietoa uudesta viestiketjusta",
    messageThreadSubTitle1: "Uusi viestiketju",
    messageThreadList1: "Aloita",
    messageThreadList2: "uusi viestiketju",
    messageThreadList3: "painamalla viestinäkymässä",
    messageThreadList4: "painiketta.",
    messageThreadList5: "Valitse",
    messageThreadList6: "täppämerkinnällä",
    messageThreadList7: "ketä lasta tai lapsia viesti koskee.",
    messageThreadList8: "Valitse viestin",
    messageThreadList9: "vastaanottaja(t).",
    messageThreadList10: "Hyväksy valinta",
    messageThreadList11: "väkänen painikkeella.",
    messageThreadList12: "Valitse",
    messageThreadList13: "ryhmä,",
    messageThreadList14:
      "jos haluat henkilöstön näkevän viestin mahdollisimman pian ja/tai kun viesti ei sisällä arkaluonteista tietoa. Tällöin viesti tulee näkyviin yksikön henkilöstön puhelinsovellukseen.",
    messageThreadList15: "yksittäinen työntekijä tai työntekijöitä,",
    messageThreadList16:
      "jos haluat lähettää viestin vain tietyille henkilöille. Tämä valinta tehdään, jos viestisi on tarkoitus olla yksityinen. Viesti tulee näkyviin vain kyseiselle työntekijälle. Näitä henkilökohtaisia viestejä saatetaan lukea harvemmin, kuin ryhmälle tai yksikölle lähetettyjä viestejä.",
    messageThreadList17: "Kirjoita",
    messageThreadList18: "viestin aihe ja viesti.",
    messageThreadList19: "Tässä vaiheessa voit vielä poistaa vastaanottajia.",
    messageThreadList20: "Lähetä viesti painamalla",
    messageThreadList21: "'LÄHETÄ'",
    messageThreadList22: "button.",

    //Viestinnän näkyvyys
    whatKindOfMessagesAccordionTitle: "Viestinnän näkyvyys",
    whatKindOfMessagesAccordionDesc: "Lisätietoa viestinnän näkyvyydestä",
    whatKindOfMessagesList1:
      "Varhaiskasvatuksessa sinulle voidaan lähettää viestejä, joiden lähettäjänä on henkilöstön yksittäinen jäsen, päiväkoti tai päiväkodin ryhmä. Viestiketjussa ei näy, onko kyseessä niin sanottu massaviesti (ryhmä-, päiväkoti- tai aluekohtainen), koska viestintä on vain sinun ja varhaiskasvatuksen henkilöstön välistä. Kun siis vastaat saamaasi viestiin, se pysyy sinun ja lähettäjän välisenä.",
    whatKindOfMessagesList2: "Poikkeuksena tähän ovat perhekohtaiset viestit,",
    whatKindOfMessagesList3:
      "jotka on lähetetty perheen huoltajille lapsenne nimellä. Perhekohtaisiin viesteihin vastatessasi vastaus näkyy myös muille perheesi huoltajille",
    whatKindOfMessagesMiddleTitle: "DaisyFamilyssä voit saada:",
    whatKindOfMessagesList4: "Henkilökohtaisia",
    whatKindOfMessagesList5: "viestejä sinulle, sinun nimelläsi.",
    whatKindOfMessagesList6: "Perhekohtaisia",
    whatKindOfMessagesList7:
      "viestejä sinulle ja lapsesi muille huoltajille lapsenne nimellä.",
    whatKindOfMessagesList8: "Ryhmäkohtaisia",
    whatKindOfMessagesList9:
      "viestejä sinulle ja lapsesi ryhmän muille huoltajille lapsen hoitoryhmän nimellä.",
    whatKindOfMessagesList10: "Yksityiskohtaisia",
    whatKindOfMessagesList11:
      "viestejä sinulle ja lapsesi päiväkodin muille huoltajille lapsen päiväkodin nimellä.",
    whatKindOfMessagesList12: "Aluekohtaisia",
    whatKindOfMessagesList13:
      "viestejä sinulle ja päiväkodin alueen muille huoltajille alueen nimellä.",
    whatKindOfMessagesMiddleTitle2:
      "Kenelle vastausviestisi näkyvät, kun vastaat saapuneeseen viestiketjuun?",
    whatKindOfMessagesList14:
      "Mikäli näet viestiketjun osallistujalistassa päiväkodin tai ryhmän nimen, viestiketjun viestit näkyvät kyseisen yksikön henkilöstölle, joka on kyseisessä päiväkodissa tai ryhmässä. Mikäli haluat viestitellä yksittäisen varhaiskasvatuksen henkilöstön jäsenen kanssa, voit aloittaa uuden viestiketjun kyseisen henkilön kanssa.",
    whatKindOfMessagesList15: "Ryhmä- ja yksikkökohtaiset viestiketjut:",
    whatKindOfMessagesList16:
      "Hoitoryhmän tai -yksikön nimellä aloitetun viestiketjun vastaukset näkyvät yksikön henkilöstölle.",
    whatKindOfMessagesList17: "Perhekohtaiset viestiketjut:",
    whatKindOfMessagesList18:
      "Lapsesi nimellä aloitettu viestiketju on lähetetty myös muille lapseen liitetyille huoltajille. Tällöin viestiketjun osallistujissa on merkintä 'Lapsen Sukunimi, Etunimi (Huoltajat)'. Kun vastaat tähän viestiketjuun, näkyy vastauksesi viestin lähettäjälle.",
    whatKindOfMessagesList19: "Huom!",
    whatKindOfMessagesList20:
      "Jos lapseen liitetään myöhemmin uusi huoltaja (esim. sinun puolisosi), myös hän näkee kaikki lapseen liittyvät aiemmat viesitketjut vastauksineen.",

    //Avainsanat
    defaultMessagingKeywordButton: "Viestintä",
    messagingButtonKeywordButton: "Painikkeet",
    messageThreadKeywordButton: "Lähettäminen",
    whatKindOfMessagesKeywordButton: "Vastaanottajat",

    //Guide: Permission
    //Lupakyselyt
    permissionRequestAccordionTitle: "Lupakyselyt",
    permissionRequestAccordionDesc: "Lisätietoa lupakyselyistä",
    permissionRequestSubTitle: "Lupakyselyt",
    permissionRequestIntro:
      "Lupakyselyiden avulla henkilöstö voi pyytää kootusti huoltajien lupaa esimerkiksi retkille osallistumiseen tai muihin tilanteisiin.",
    permissionRequestList1:
      "Löydät lastasi koskevat lupakyselyt valitsemalla vasemmalta",
    permissionRequestList2:
      "-lupakyselyt kuvakkeen tai painamalla oikean yläkulman",
    permissionRequestList3:
      "-tapahtumaseloste painiketta ja siirtymällä 'ilmoitukset' kohtaan, jonka jälkeen saat lupakyselyt ja suostumukset auki painamalla",
    permissionRequestList4:
      "-näytä lisää painiketta kohdassa 'Luvat ja suostumukset' ja valitsemmalla joko huoltajan suostumukset tai kyselyt.",
    permissionRequestList5:
      "'Kyselyt' osiossa näet listattuna lastasi koskevat lupakyselyt.",
    permissionRequestList6: "Voit päivittää listauksen",
    permissionRequestList7: "'Päivitä'",
    permissionRequestList8: "painikkeesta.",
    permissionRequestList9: "-kuvake näkyy uusien lupakyselyiden kohdalla.",
    permissionRequestList10:
      "-Uudet ilmoitukset kuvakkeen alla näkyvät uudet lupakyselyt.",
    permissionRequestList11:
      "-Päivämäärä kuvakkeen kohdassa näkyvä päivämäärä kertoo, mihin saakka lupakysely on voimassa ja siihen voi vastata.",
    permissionRequestList12:
      "Avaa haluamasi lupakysely painamalla lupakyselyn otsikosta",
    permissionRequestList13:
      "Lupakyselyn avattuasi voit vastata siihen merkitsemällä",
    permissionRequestList14: "'Annan luvan'",
    permissionRequestList15: "kohdassa joko",
    permissionRequestList16: "'Kyllä'",
    permissionRequestList17: "tai",
    permissionRequestList18: "'Ei'.",
    permissionRequestList19: "Tehtyäsi valinnan, paina",
    permissionRequestList20: "'LÄHETÄ'",
    permissionRequestList21: "-painiketta lähettääksesi vastauksen.",
    permissionRequestList22: "Perheen huoltajat voivat",
    permissionRequestList23: "muokata vastausta",
    permissionRequestList24:
      "vielä lupakyselyn voimassaolon ajan. Viimeisen vastauspäivämäärän jälkeen vastaus lukittuu.",

    //Huoltajan suostumukset
    parentalConsentsAccordionTitle: "Huoltajan suostumukset",
    parentalConsentsAccordionDesc: "Lisätietoa huoltajan suostumuksista",
    parentalConsentsList1:
      "Joillakin kunnilla ja yksityisillä päiväkodeilla voi olla käytössä huoltajakohtaisia suostumuksia. Nämä suostumukset näkyvät DaisyFamilyssä sekä hakemuspalvelu eDaisyssä, jos se on käytössä.",
    parentalConsentsList2:
      "Löydät itseäsi koskevat suostumukset valitsemalla vasemmasta laidasta",
    parentalConsentsList3:
      "-näytä lisää painiketta kohdassa 'Luvat ja suostumukset' ja valitsemmalla 'huoltajan suostumukset'.",
    parentalConsentsList4:
      "Vastaa suostumuksiin haluamallasi tavalla painamalla joko 'Kyllä' tai 'Ei' valintaa. Voit vaihtaa suostumustasi halutessasi. Tallenna muutos painamalla 'Tallenna suostumukset' painiketta.",

    //Lasten luvat
    childPermissionsAccordionTitle: "Lasten luvat",
    childPermissionsAccordionDesc: "Lisätietoa lasten luvista",
    childPermissionsList1: "'Luvat ja suostumukset'",
    childPermissionsList2:
      "sivulla ohjelma ilmoittaa, jos perheen lasta koskeviin lupakyselyihin ei ole vastattu.",
    childPermissionsList3:
      "Lapsikohtaiset lupakysymykset löydät lapsen perustietojen osiosta 'Lapsen luvat'.",
    childPermissionsList4:
      "Lapsen perustiedot löytyvät lapsen sivun alavalikosta.",
    childPermissionsList5: "Lapsen sivuille pääset:",
    childPermissionsList6: "Jos perheessä on",
    childPermissionsList7: "yksi lapsi",
    childPermissionsList8:
      "Daisyssä, tulet lapsen sivulle heti kirjautesassi sisään DaisyFamilyyn.",
    childPermissionsList9: "Perustiedot löytyvät tämän lapsen alavalikosta.",
    childPermissionsList10: "useita lapsia",
    childPermissionsList11:
      "Daisyssä, pääset yksittäisen lapsen sivulle painamalla lapsen omasta värilaatikosta, jossa on lapsen nimi ja profiilikuva. Tämä löytyy",
    childPermissionsList12: "'Lapset'",
    childPermissionsList13: "sivulta.",
    childPermissionsList14: "Perustiedot löytyvät lapsen sivun alavalikosta",
    childPermissionsList15: "Lapsen sivulla",
    childPermissionsList16: "'Perustiedot'",
    childPermissionsList17: "löytyvät sivun alavalikosta.",
    childPermissionsList18:
      "Merkitse lapsen lupiin 'KYLLÄ tai 'EI' valinta ja tallenna muutokset. Lapsen huoltajat voivat muokata lupatietoja halutessaan.",
    childPermissionsList19:
      "Kun muokkaat lupatietoa, olethan asiasta yhteydessä myös lapsesi hoitopaikan henkilöstöön, jotta heillä on viimeisin tieto muutoksista.",

    //Avainsanat
    permissionRequestKeywordButton: "Muokkaaminen",
    parentalConsentsKeywordButton: "Huoltajakohtaiset",
    childPermissionsKeywordButton: "Lapsikohtaiset",

    //Keskusteluajat
    discussionTestTitle: "Keskusteluajat testi ohje",

    //Testi
    discussionTestList: "Testi",

    //Avainsanat:
    discussionTestKeyword: "Testi",

    //UUSI VARAUSKALENTERI
    newCalendarNewCaretime: "Uusi Hoitoaika",
    newCalendarNewAbsence: "Uusi Poissaolo",
    newCalendarDeleteTimes: "Poista varauksia",
    newCalendarDeleteTimes2: "Poista varaukset",
    newCalendarServiceNeed: "Hoidontarve",
    newCalendarPaymentCategory: "Maksuluokka",
    newCalendarFreeTime: "Ilmainen aika",
    newCalendarUsedTime: "Käytetty aika",
    newCalendarBilling: "Laskutusperuste",
    newCalendarDeleteCareTime: "Poista hoitoaika",
    newCalendarCareTime: "Hoitoaika",
    newCalendarAbsence: "Poissaolo",
    newCalendarLockedTime: "Lukitusaika",
    newCalendarCareTimes: "Hoitoajat",
    newCalendarVacationTimes: "Loma-ajat",
    newCalendarVacationTime: "Loma-aika",
    newCalendarPeriodTime: "Päivystysaika",
    newCalendarChildrenToShow: "Näytettävät lapset",
    newCalendarDefaultTime: "Oletushoitoaika",
    newCalendarLoadingCareTimes: "Haetaan hoitoaikoja",
    newCalendarSick: "Sairas",
    newCalendarAbsent: "Poissa",
    newCalendarAbsentReasonSelect: "Valitse poissaolon syy",
    newCalendarSaveAbsence: "Tallenna Poissaolo",
    newCalendarSavingAbsence: "Tallennetaan Poissaoloja",
    newCalendarSaveNewAbsence: "Ilmoita Poissaolo",
    newCalendarSavingAbsenceFailed: "Poissaolon tallennus epäonnistui",
    newCalendarAbsentSaved: "Poissaolot tallennettu",
    newCalendarSaveNewCareTime: "Ilmoita uusi hoitoaika",
    newCalendarSaveNewCareTimes: "Tallenna varaukset",
    newCalendarSavingCareTimes: "Tallennetaan varauksia",
    newCalendarSavingCareTimes2: "Tallennetaan hoitoaikoja",
    newCalendarCareTImesSaved: "Hoitoajat tallennettu",
    newCalendarSavingCareTimefailed: "Hoitoaikojen tallennus epäonnistui",
    newCalendarSelectChildren: "Valitse lapset",
    newCalendarSelectTime: "Valitse varauksen kesto",
    newCalendarSelectDates: "Valitse päivät",
    newCalendarDays: "Päivät",
    newCaledarWeeks: "Viikot",
    newCalendarRange: "Aikaväli",
    newCalendarDeleteCareTimes: "Poista varaukset",
    newCalendarDeleteCareTimes2: "Poista varauksia",
    newCalendarSavingDefault: "Tallennetaan oletushoitoaikaa",
    newCalendarExistSelected:
      "Valituilla päivillä on olemassaolevia varauksia, haluatko säilyttää vanhat varaukset?",
    newCalendarWeekEnds:
      "Yhdelle tai useammalle lapselle on mahdollista varata viikonloppuja. Haluatko sisällyttää varaukseen viikonloput?",
    newCalendarSaturdays:
      "Yhdelle tai useammalle lapselle on mahdollista tehdä varauksia lauantaille. Haluatko sisällyttää varaukseen lauantait?",
    newCalendarSundays:
      "Yhdelle tai useammalle lapselle on mahdollista tehdä varauksia sunnuntaille. Haluatko sisällyttää varaukseen sunnuntait?",
    newCalendarAbsentWeekEnds:
      "Yhdelle tai useammalle lapselle on mahdollista ilmoittaa poissaoloja myös viikonlopulle. Haluatko sisällyttää ilmoitukseen viikonloput?",
    newCalendarAbsentSaturdays:
      "Yhdelle tai useammalle lapselle on mahdollista ilmoittaa poissaoloja myös lauantaille. Haluatko sisällyttää ilmoitukseen lauantait?",
    newCalendarAbsentSundays:
      "Yhdelle tai useammalle lapselle on mahdollista ilmoittaa poissaoloja myös sunnuntaille. Haluatko sisällyttää ilmoitukseen sunnuntait?",

    newCalendarAreYouSureExit: "Haluatko varmasti poistua?",
    newCalendarEditLoss: "Kaikki tekemäsi valinnat menetetään",
    newCalendarLoadingPrev: "Ladataan edellisiä hoitoaikoja",
    newCalendarLoadingNext: "Ladataan seuraavia hoitoaikoja",
    newCalendarWeek: "Viikko",
    newCalendarClose: "Sulje",
    newCalendarCancel: "Peruuta",
    NewCalendarDelete: "Poista",
    newCalendarExit: "Poistu",
    newCalendarSave: "Tallenna",
    NewCalendarYes: "Kyllä",
    newCalendarNo: "Ei",
    newCalendarSaveSuccess: "Tallennus onnistui",
    newCalendarSaveFailed: "Tallennus epäonnistui",
    newCalendarTryAgain: "yritä uudelleen",
    newCalendarDeletingCareTimes: "Poistetaan hoitoaikoja",
    newCalendarDeletingCareTimesFailed: "Varauksien poistaminen epäonnistui",
    newCalendarDeletingCareTimesFailed2: "poistaminen epäonnistui",
    newCalendarCareTimesDeletedSuccess: "Varaukset poistettu onnistuneesti",
    newCalendarCareTimesDeletedSuccess2: "Varaukset poistettu",
    newCalendarAreYousureWantToDeleteCaretime:
      "Haluatko varmasti poistaa hoitoajan",
    newCalendatAutomaticClose: "Ikkuna suljetaan automaattisesti",
    newCalendarAfterSeconds: "sekunnin kuluttua",
    newCalendarEo: "EO",
    actionNotes: "Merkinnät",
    actionNotesNull: "Ei merkintöjä",
    newNotifications: "New notifications",

    //kalenteri kellon valinta
    clockType: "Kellon tyyppi",
    clockTypeDialog1:
      "Hoitoaikakalenterin uutena ominaisuutena voit valita kellonajan asettamiseen käytettävän kellon tyypin",
    clockTypeDialog2: "Jatkossa voit muuttaa valintaasi kalenterin asetuksista",
    clockTypeSlider: "Liukusäädin",
    clockTypeDigital: "Digitaalinen kello",
    clockTypeStart: "Alkuaika",
    clockTypeEnd: "loppuaika",
    saveChoice: "Tallenna valinta",
    new: "Uutta",
  },

  en: {
    awDevelopersInform: "Developers inform",
    awClientsInform: "Kindergartens inform",
    awNotAvailableDevTextTrans: "Translation not available in English! ",
    awNewBulletin: " active bulletin",
    awNewBulletins: " active bulletins",
    awMoveToBulletin: "Move to bulletin board",
    awNursingTimesLock: "Care times are closing",
    awShowMore: "Show more",
    awUnansConsent: " unrequited consent",
    awMoveToPerm: "Move to Permissions & Consents",
    awMove2Messaging: "Move to messaging",
    awNotReadMessages: " unread messages",
    awNewMessages: "New messages!",
    awAllMarkedAsRead: "All marked as read!",
    awBulletins: "BULLETINS",
    awAllDone: "All done, awesome!",

    broSeems: "It seems that DaisyFamily does not support your browser.",
    broForAttachments: "for downloading files",
    broYourBrowserIs: "Your browser is",
    broYourVersionIs: "and your browser's version is",
    broDaisyFamilySupports:
      "DaisyFamily supports these browser versions and newer versions than them",
    broAllSupportedVersions: "All supported browsers",
    broDaisyFamilySupports2:
      "DaisyFamily supports the following browsers and newer versions of them",
    broOtherOperatingSystems:
      "Other operating systems: Chrome or Firefox may provide the smoothest user experience for DaisyFamily.",
    broUpdateYourBrowserRegularly:
      "Update your browser regularly for improved information security.",
    broUnknown: "Unknown",

    discDiscussionBooking: "Book discussion",
    discChildNoDiscussionsToBeBooked: (childName) => {
      return `Child ${childName} doesn't have any discussions to be booked`;
    },
    discPastDiscussions: "Past discussions",
    discUpcomingDiscussions: "Upcoming discussions",
    discChildsPastDiscussions: "Childs past discussions",
    discChildsUpcomingDiscussions: "Childs upcoming discussions",
    discTimesCouldntBeLoaaded: "Childs discussion times couldn't be loaded",
    discCancelDiscussionTime: "Cancel discussion time",
    discCancelConfimation:
      "Are you sure you want to cancel this discussion booking?",
    discDiscussionTimes: "Discussion times",
    discTimeType: "Discussion time type:",
    discType: "Discussiontype",
    discTypeVASU: "VASU",
    discTypeLEOPS: "LEOPS",
    discTypeLapu: "Lapu",
    discTypeMuu: "Other",
    discSaveConfirmation: "Do you want to save the following information?",
    discSaveError: (error) => {
      return `Error ${error}: discussiontime couldn't bbe saved.`;
    },
    discBooking: "Appointment",
    discChooseTime: "Choose time",
    discChooseParticipant: "Choose participants",
    discTimesAvailable: "Appointments available",
    discTimesUnabailable: "No appointments available",
    discChildsUpcomingDiscussion: "Upcoming discussions",
    discNoActiveBookings: "No active discussion bookings",
    discReminder:
      "Remember to participate in or cancel the appointment before it's due:",
    discInformationFailed:
      "Childs discussiontime information couldn't be loaded.",
    discYes: "Yes",
    discNo: "No",
    discCouldNotBeCanceled: "The discussion time could not be canceled",
    discCancelSuccess: "Discussion time canceled succesfully",
    discRemoteMeeting: "Remote meeting",
    discCopy: "Copy",
    discCopied: "Copied",
    discCancelableUntil: (date) => {
      return `Cancelable until ${date}`;
    },
    discCancelableUntil2: "Cancelable until",
    discInformationGivenByPersonel:
      "Addittional information given by personnel",
    discInformationGivenByGuardians:
      "Addittional information given by guardians",
    discNoInformation: "No addittional information...",
    discNoTimesToBeBooked: (childName) => {
      return `Child ${childName} doesn't have any times to be booked`;
    },
    discStopEditing: "Stop editing",
    discSaveChanges: "Save changes",
    discSomethingWentWrong: "Something went wrong",
    discNewTime: "New time",
    discMakeAnAppointment: "Make an appointment",
    discEdit: "Edit",
    discSubject: "Subject",
    discAddress: "Address",
    discStaff: "Staff",
    discAlreadyBooked: "Discussiontime not open. Pick another one.",
    discMoveToBooking: "Go to discussion time booking",
    discYouHaveAppointments: "You have available discussion times!",
    discAvailableForXDays: (count) => {
      return `Appointments to be made for ${count} days`;
    },
    discEditSuccess: "Editing success!",
    discBookingSaved: "Booking saved",
    discBookingSaveError: "Booking couldn't be saved",
    discPreBookingConflict:
      "Somebody else prebooked the time. Choose another time.",
    discPreBookingError: "There was a problem with saving prebooking",
    discLeftOfPrebooking: (x, y) => {
      return `${x} minutes ${y} seconds left of booking`;
    },
    discPrebookingTimeRanOut: "Prebooking time ran out.",
    discPrebookingMoreTime: "More time?",

    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",

    selectall: "Select all",
    selectallRemove: "Unselect all",
    selectall: "Select all",
    selectallRemove: "Unselect all",

    permDialogNotSavedText:
      "Changes haven't been saved. Unsaved changes will be lost.",
    permUnansweredPerms: "unanswered permissions!",
    permPermissionQueries: "Short-time Permissions",
    permPQTitle: "Title",
    permAnswersWereSaved: "The answers were saved",
    permNoData: "No data",
    permPermSavingFailed: "Saving the answers failed",
    permOneOrMoreSavesFailed: "One or more answers were not saved",
    permBackTo: "Back to the list of short-time permissions",
    permLastDayToAnswer: "Last date to answer",
    permCannotAnswerAnymore:
      "The permission question / poll cannot be answered anymore.",
    permToPermissions: "Go to Short-time Permissions",
    permOneOrMoreChildrenNoAnswer:
      "An answer is missing for one or more children.",
    permAnswerAllChildren: "Please answer the question for all the children.",
    permRefresh: "Refresh",
    permSender: "Sender",
    permIGivePermission: "I give permission", //todo

    accAccessibilityStatement: "Accessibility statement",
    accHeader1: "Accessibility",
    accHeader2a: "Accessibility statement for the DaisyFamily.fi website",
    accPara1:
      "This statement applies to the DaisyFamily website ([commune].daisyfamily.fi). This statement has been created on 23 March 2021 and it has been updated on 23 March 2021.",
    accPara2:
      "The accessibility of this digital service has been assessed by the development team of DaisyFamily.",
    accHeader2b: "Other ways to contact us",
    accPara3:
      "If you are unable to use our website, you can contact your daycare centre or email us at",
    accPara4: "saavutettavuus@daisyvarhaiskasvatus.fi",
    accHeader2c: "How accessible is the website?",
    accPara5:
      "The online service is partially compliant with accessibility standards (WCAG 2.1 A-, and AA-level).",
    accHeader3a: "Non-accessible content",
    accPara6:
      "Users may encounter some accessibility issues on our website. The following is a list of known issues discovered by our development team or by clients. If you discover a problem that is not listed, please let us know by email at",
    accPara7: "saavutettavuus@daisyvarhaiskasvatus.fi",
    accPara8: "Contents or functionalities which are not yet fully accessible:",
    accUL1a:
      "Certain parts of the website (text & links) do not meet contrast ratio requirements (WCAG 1.4.3)",
    accUL1b: "Headers are not ranked correctly on all pages (WCAG 1.3.1)",
    accUL1c:
      "Not all visual content has a text equivalent describing what the image or graphic element represents (WCAG 1.1.1)",
    accUL1d:
      "The services cannot be used conveniently with a screen reader or a keyboard. (WCAG 4.1 and 2.1.1)",
    accHeader3b:
      "The accessibility requirements do not apply to certain types of content",
    accPara9:
      "The following is a list of content on our website which is exempt from the statutory accessibility requirements.",

    accUL2a:
      "Content that is used temporarily in a limited group for pedagogical purposes",
    accUL2b:
      "Content posted on the service by users or other third parties that it not produced, funded or controlled by the service provider itself",
    accUL2c:
      "Archived materials that have been archived before 23 September 2019",

    accHeader2d: "Give feedback on accessibility",
    accPara10:
      "If you notice an issue with accessibility on our service, tell us about it and we will do our best to fix it.",
    accPara11: "Give feedback by email: ",
    accPara12:
      "Accessibility feedback is received by our DaisyFamily development team.",

    accHeader2e: "Ongoing evaluation of accessibility",
    accPara13:
      "If you notice issues with the accessibility of this website, please let us know first. We will reply within two (2) weeks.",
    accPara14:
      "If you are not satisfied with our reply or if you do not receive a reply within two (2) weeks, you can file a report with Regional State Administrative Agency for Southern Finland (in Finnish). The Agency website contains detailed instructions for filing a report and information on how the matter will be handled.",

    accHeader3c:
      "Contact information of the Regional State Administrative Agency",
    accPara15: "Etelä-Suomen aluehallintovirasto",
    accPara16: "Saavutettavuuden valvonnan yksikkö",
    accPara17: "Telephone exchange",

    accHeader2f: "Our ongoing efforts to improve accessibility on our website",
    accPara18:
      "We will update this statement as we fix accessibility issues. Our goal is to solve accessibility issues by the end of year 2021.",
    accPara19:
      "We are committed to improving the accessibility of our online services. The steps we are taking to ensure accessibility include the following:",

    accUL3a:
      "We will take accessibility requirements into account when developing our services.",
    accUL3b:
      "We offer information on our website also in plain Finnish, plain Swedish and plain English.",
    accUL3c: "We provide accessibility training to our employees.",

    accPara20: "Read more",
    accPara21:
      "Saavutettavuusvaatimukset.fi (Accessibility statement for the e-service for customers (in Finnish))",
    accPara22: "Act on the Provision of Digital Services 306/2019 (finlex.fi)",
    accPara23: "EU’s Accessibility Directive (eur-lex.europa.eu)",

    cookieHeader1: "Cookies",
    cookieHeader2a: "Information about cookies",
    cookiePara1:
      "When visiting our website (https://kunta.daisyfamily.fi), cookies (including Local and Session storage records), small text files, are being saved onto your device by your browser. Cookies cannott damage or spread malware to your device.",
    cookiePara2:
      "Our website only uses technical cookies that are necessary to give our clients safe, fluent, and appropriate usage of the service. For example, the login process and saving language selection use cookies to enable fluent and safe usage of the website for clients.",
    cookiePara3:
      "It is possible to switch off all technical cookies from your browser’s settings. We would like to point out, however, that by doing so you cannot log in to our service. Deleting your own cookie history is possible from your browser’s settings and will not affect the usage or log in to our service.",
    cookiePara4:
      "Every browser has its own practices and settings for managing cookies. From your browser’s own website, you can familiarize yourself with your browser's cookie settings and cookie handling practices.",
    cookies: "Cookies statement",

    allowed: "Allowed",
    newNotifications: "New Notifications",
    allowEmailNotificationsExplanation:
      "You will receive email notifications for new applications, messages and comments redarding your child.",
    allowPushNotificationsExplanation:
      "You will receive notifications on your mobile for new applications, messages and comments regarding your child. Note! To receive notifications you install DaisyFamily app to you mobile device!",
    allowViewLabel: "Show contact information",
    allowViewModalTitle: "Visibility to other guardians",
    allowViewModalYesText:
      "Allow other guardians of the child to see and edit your contact information",
    allowViewModalNoText:
      "Your contact information and possibility to edit your information will be hidden from other guardians",
    bbTitle: "Bulletin board",
    bbHeaderText: "Here you can see daycare bulletin board messages.",
    bbSlideViewNoMessages: "No messages",
    bbGroup: "Group",
    bbCentreShortText: "Centre", //todo
    bbValidUntilBBCard: "Valid until {0}",
    bookingShowTimes: "Show bookings",
    bookingAddBookingTitle: "Add booking",
    bookingAddBookingInfo:
      "You are about to add booking(s) for the selected day(s).\nDo you want to make completely new booking(s) (1 or more)\nor keep existing ones and add?",
    bookingAddBookingLeftBtn: "NEW",
    bookingAddBookingRightBtn: "KEEP",
    bookingAddBookingAndKeepPrevTitle: "Information for the selected days",
    bookingCareHoursTitle: "Care times",
    bookingCareHoursTitle2: "Booking Calendar",
    bookingCareHoursDefaultTitle: "Default care time to use",
    bookingCareHoursDefaultUse: "Default care time to be used: 8-16", //tarkista (miten?)
    bookingCareHoursDefaultMissingInfo:
      "Default care time has not been set. 8 am to 4 pm \n will be automatically used as a default. You can save the default \n care time from the 'clock' button in the lower left corner.",
    bookingCareHoursDefaultSave: "Save",
    bookingCareHoursSaveInformation: "Save information?",
    bookingCareHoursMissing: "Care time bookings missing",
    bookingCareHoursMissingTimeSpan:
      "Care time bookings or absences not notified for period about to be locked:",
    bookingChoosePlacement: "Select placement",
    bookingDataSaving: "Saving data", //todo
    bookingDeleteBookingTitle: "Delete bookings",
    bookingDeleteBookingInfo:
      "You are about to delete the booking(s) for the selected day(s).\nAre you sure you want to delete your booking(s)?",
    bookingDeleteBookingLeftBtn: "CANCEL",
    bookingDeleteBookingRightBtn: "DELETE",
    bookingDetailDelBookingTitle: "Deleting entries",
    bookingDetailDelBookingInfo: "Are you sure you want to delete the entry?",
    bookingDetalDelBookingInfoMultiple:
      "Are you sure you want to delete the entry (DELETE)?\nDo you want to delete all entries for all placements (DELETE ALL)?",
    bookingDeleteBookingAll: "DELETE ALL",
    bookingsLocked: "Booking locked",
    bookingBookingsDeleted: "Booking(s) deleted",
    bookingAllCouldNOtBeDeleted: "All bookings could not be deleted",
    bookingErrorDeleteDetails: "\nErrors in deletion:\n",
    bookingErrorSaving: "\nAn error occurred while saving:\n",
    bookingErrorSavingDetailed: "\nErrors occured while saving:\n",
    bookingBookingsSaved: "Care time booking(s) saved",
    bookingAddMoreBtn: "Add a new booking",
    bookingErrorNoValidPlacement:
      "Bookings/absences are not possible \n due no valid placement(s).",
    bookingYoureEditingFollowing: "You are editing the following bookings",
    bookingErrorBadRequest: "Saving failed. Overlapping bookings!",
    bookingNextBooked: "Next booked day care time",
    bookingMake: "Book day care time",
    bookingSavingAbsenceFailedPartially:
      "All absences could not be saved, check bookings!",
    bookingSavingFailedPartially:
      "All bookings could not be saved, check bookings!",
    bookingSavingFailedPartially2:
      "All bookings can not be saved due to placement in: ",
    bookingType: "Booking type",
    bookingTypeAbsent: "Absent",
    bookingTypeHoliday: "Holiday",
    bookingTypeNT: "Care hours",
    bookingTypeSick: "Sick",
    bookingTypeSNT: "Rotation child care", // ?
    child: "Child",
    childFiles: "Files",
    ChildrenTalkMethodUsed1: "'Let´s Talk about Children'",
    ChildrenTalkMethodUsed2: "discussion held",
    ci_CentreName: "Daycare centre",
    ci_CentreAddress: "Daycare centre address",
    ci_CentrePhone: "Daycare centre phone number",
    ci_ManagerName: "Daycare director", //todo
    ci_OpenType: "Open hours type", //todo Aukiolotyyppi
    ci_OpenTimes: "Open hours", //todo
    ci_GroupName: "Group name", //todo
    ci_GroupPhone: "Group phone number", //todo
    ci_placementGoodUntil: "Placement duration active until:", //todo Sijoituksen voimassaoloaika:
    choosePicturesToDownload: "Select the images you want to download.",
    placementInformation: "Placement information", //todo Sijoitustiedot
    placementDayCareCentre: "Daycare information", //todo
    menuPlacementInformation: "Placement information", //todo
    ciAddressCity: "City",
    ciAddressPostalcode: "Postalcode",
    ciAddressStreet: "Street",
    ciAllergies: "Allergies",
    ciBackupPersons: "Backup persons",
    ciBackupPersonsNone: "No backup persons have been added",
    ciBackupPersonRemovalSuccess: "Entry successfully deleted",
    ciBackupPersonRemovalFail: "Failed to delete entry",
    ciBackupPersonRemoveConfirmation: "Delete backup person?",
    ciDeleteBackupParent: "Delete backup person",
    ciDeleteYes: "Yes",
    ciDeleteNo: "No",
    ciBasicInfo: "Basic information",
    ciContactInformation: "Contact information",
    ciInfo1:
      "The phone number must be between 7 and 12 digits long.\nAnd format can be +358 44 1234567 or 044 1234567.\nSpaces are allowed.",
    ciName: "Name",
    ciFirstName: "Firstname",
    ciLastName: "Lastname",
    ciNoNumber: "No number",
    ciShortEmailAddress: "@mail",
    ciShortPhoneNumber: "Phone number",
    ciShortPrimaryNumber: "Primary number",
    ciShortWorkNumber: "Workphone number",
    ciAlbum: "Photo albums",
    ciAlbumNote:
      "For the time being, photo albums are only found on DaisyNet. This feature will be added to the next DaisyFamily beta version",
    clChildren: "Children",
    clChildrenNotFound: "No children found",
    clChildrenLoadingData: "Loading child data",
    cookieMoreInfo: "More information",
    cookieNote: "By continuing to use DaisyFamily service, you accept cookies.",
    downloadMedia: "Download",
    emailNotifications: "Email notifications",
    oldMessagingSetting: "Show the old Messaging implementation inbox",
    oldMessages: "Old Messages",
    newMessages: "New Messages",
    emptyFolder: "The folder is empty",
    emptyGrowthFolder: "The growthfolder is empty",
    errorBackend: "Error in backend. Please try again in a few moments.",
    errorBackendContactSupport:
      "Error in backend. Please contact the communal Daisy-administrator.",
    errorBadRequest: "Invalid search criterion",
    errorCode: "Error code ",
    error: "Error",
    errorChangingFailed: "Saving to the server failed",
    errorCityName: "Please enter a valid city name",
    errorCommuneNotAvailable: "Commune name not available",
    errorCommuneNotFound: "Commune not found",
    errorDataCouldNotBeDownloaded: "Data could not be downloaded",
    errorDelBooking:
      "The booking/absence entry is within the unit's locking period and therefore cannot be deleted.",
    errorInDB: "Database error",
    errorInformationMissing: "Information missing",
    errorInLogin: "Wrong username and/or password!",
    errorLoadImage: "Failed to load the image.",
    errorLoadImageFolders: "Failed to load image folders.",
    errorLoadImages: "Failed to load images.",
    errorLockedDaysOnly:
      "You have selected locked day(s) and \ncannot select unlocked day(s) at the same time.",
    errorLoginOptionsNotFound:
      "Login settings for the service not found.\nContact your service provider.",
    errorMessageSending:
      "Unable to send the message to the server. Please copy the content of your message and try again later.",
    errorMsgProbablyNotSent:
      "Error with sending the message. The message was probably not sent. Please copy your message and try again later.",
    errorNetwork: "Network error",
    errorNotFound: "Data not found",
    errorNoPlacements: "No valid placements",
    errorNTsummary:
      "Could not load available day care time information. Try again later.",
    errorNameCantBeEmpty: "The person's name may not be blank",
    errorPostalCode: "Please enter a valid postal code",
    errorSendingFailed: "Sending failed",
    errorStreetAddress: "Please enter a valid street address",
    errorTimesInvalid: "Day care times set incorrectly",
    errorUnauthorizedNoActiveChilds:
      "Access to DaisyFamily denied, no active children.",
    errorUnknown: "Unknown error",
    errorUnLockedDaysOnly:
      "Locked days cannot be selected at the same time as unlocked days.",
    errorWithMsgSending: "Error sending message",
    errorEsuomiLoginNoChild: "No children found", //"Yhtään lasta ei löytynyt", //todo
    errorEsuomiLoginNoActive: "Access rights no longer active/valid", //"Käyttöoikeus on loppunut", //todo
    errorEsuomiLoginUserNotFound: "User was not found", //"Käyttäjää ei löytynyt", //todo
    errorEsuomiLoginUnknown: "Unexpected error upon login", //"Kirjautumisessa tapahtui odottamaton virhe", //todo
    errorEsuomiLogin: "Error upon login", //""Virhe kirjauduttaessa", todo
    errorEsuomiClientCancel: "Login canceled",
    errorEsuomiAuthFail: "Login failed with chosen identity",
    errorEmailWrongFormat: "Email address is not valid",
    fileModalHeader: "File",
    fileEmptyDesc: "No description available.",
    fileTypeWrong:
      "File type not supported or file corrupted. You might encounter problems when downloading or previewing file.",
    fileUploaded: "Uploaded",
    fileDescription: "Description",
    fileFileName: "File name",
    fileSaved: "Saved",
    fileType: "File Type",
    generalOpen: "Open",
    generalOpenText:
      "Open the file in your browser. Pop-ups must be enabled in your browser settings.",
    generalBack: "Cancel",
    generalClose: "Close",
    generalOkBtn: "OK",
    generalDataSaved: "Data saved",
    generalDownloadFileAttachment: "Download attached file",
    generalDownloadFileAttachment2: "Download file to your device",
    generalDownloadImage: "Download image",
    generalPassword: "Password",
    generalLoadingTitle: "Loading",
    generalPasswordExpiringTitle: "Your password is expiring", //"Salasana vanhenemassa",
    generalPasswordExpiringWarning:
      "Your password is expiring {0}. Do you wish to change your password now? If your password expires, you will need to contact the daycare centre", //todo
    //generalPasswordExpiringWarning: "Salasana on vanhenee {0}. Haluat vaihtaa salasanan nyt? Jos salasana vanhenee teidän täytyy olla yhteydessä päiväkotiin",
    generalSaveOrCancel: "Form changed. Save or cancel change first",
    generalSaveModifications: "Cancel changes?",
    generalWeek: "Week",
    generalName: "Name",
    generalDate: "Date",
    generalStarts: "Start",
    generalEnds: "End",
    generalNotifications: "Notifications",
    generalAreYouSure2Leave: "Are you sure you want to leave?",
    generalInfo: "Properties",
    generalLastModified: "Last modified",
    generalQuestionsForParents: "Questions for parents",
    formChange: "Form change",
    logged: "Form locked readonly state",
    loginBtn: "Log in",
    loginCapsLockAlert: "Caps Lock on!",
    loginGeneralInfo: "Please log in!",
    loginHere: "Log in here",
    loginLoadingData: "Loading data",
    loginOptionsInfo: "Select login method",
    loginProcessingInfo: "Logging...",
    loginUserName: "Username",
    loginUsernameAndPassword: "Username and password",
    loginPasswordMustBeChanged:
      "Your password has been set as needing to be changed. Press OK to go to the password change page.",
    loginPasswordChangeTitle: "Password Change",
    logoutAutoTitle: "Automatic logout",
    logoutAutoInfo:
      "No actions.\nAutomatic logout within {0} minutes {1} seconds",
    logoutAutoSessionOldInfo: "Login session expired. Click OK to login again.",
    logoutBtn: "Logout",
    logoutEsuomiSSOTitle: "eSuomi SSO logout / eSuomi SSO uloskirjautuminen", //todo
    logoutEsuomiSSOBody:
      "Logging out from eSuomi services. Click OK to continue. / Kirjaudutaan ulos esuomi palveluista. Klikkaa OK jatkaaksesi.", //todo
    logoutEsuomiBtn: "Click to logout from eSuomi",
    menuChildren: "Children",
    menuHome: "Home",
    menuLogout: "Logout",
    menuMessaging: "Messaging",
    menuNotifyAbsence: "Today's absence",
    menuNotifyAbsenceToday: "Today's absence",
    menuSettings: "Settings",
    msgWishToRemoveThread:
      "Do you wish to remove (from your inbox) the thread:",
    msgYesRemove: "Yes, remove",
    msgCancel: "Cancel",
    msgChooseChild: "Select child(ren)",
    msgChooseRecipients: "Select recipient(s)",
    msgWriteAndSend: "Write and send",
    msgGoToMessage: "Go to message",
    msgDeleteThread: "Remove message thread from inbox",
    msgThreadRemovalNotSuccessful:
      "The message thread removal from inbox did not succeed",
    msgThreadRemovalSucceeded: "The message thread was removed from inbox",
    msgRemovingThreadFromInbox: "Removing the message thread from inbox",
    msgCaretakers: "Guardians",
    msgCharacterLimitWarning:
      "Please make sure the message is not longer than 5000 characters.",
    msgCouldNotLoadRecipientsFromServer:
      "Recipient information could not be downloaded from the server.",
    msgDenyReply:
      "The sender has chosen that replying is disabled for this message thread",
    msgGroupsAndDaycares: "Groups and day care centres",
    msgInboxTitle: "Messages",
    msgIncludeStaffTitle: "Please, include at least one employee or group",
    msgIncludeStaffBody:
      "At least one employee or group is required to send the message",
    msgInfo1:
      "Only select an individual employee as the recipient if there is\na specific reason for it. Personal messages are not checked\nas often and do not reach the employees in the day care\ncentre directly.",
    msgInfo2:
      "Select a group or day care centre as the recipient when you\nwant the message to reach the day care worker(s) as quickly\nand reliably as possible. Messages from the group are\nforwarded to the phone of the day care group (recommended). ",
    msgLoadingThread: "Loading message thread",
    msgMarkAllAsRead: "Mark all messages as read",
    msgMarkAllAsReadShort: "Mark all read",
    msgMarkAllAsReadConfirmation: "Do you wish to mark every message as read?",
    msgMarkMessagesSuccess: "Messages were marked read",
    msgMarkMessagesFailed: "Marking the messages read was unsuccessful",
    msgMe: "Me",
    msgNewMsgBodyInfo: "Type a message",
    msgMessageNew: "New message",
    msgCouldNotBeLoaded: "Messages could not be loaded from the server",
    msgNewMsgTitleInfo: "Message subject",
    msgNewMsgThreadTitle: "Message thread title",
    msgAddrecipientshere: "Change recipients",
    msgAttachment1singular: "There is",
    msgAttachment2singular:
      "file attachment attached to this file, you can download it below",
    msgAttachment1: "There are",
    msgAttachment2:
      "file attachments attached to this file, you can download them below", //= Viestiin on liitetty 3 liitetiedostoa, voit ladata ne alta
    msgRecipients: "Recipients",
    msgSending: "Sending message",
    msgSentWaitingForServer: "Sending message, waiting for server response",
    msgTitleTooLong: "The message title exceeds the character limit",
    msgyouHaveRemovedThisMessage: "You have removed this message",
    msgNoContent: "The message has no content.",
    msgNoMessagesInfo: "No messages",
    msgNumber: "Total",
    msgOthers: "others",
    msgParticipants: "participants",
    msgReplyToMessageThread: "Reply to the message thread",
    newest: "Newest",
    notAllowed: "Not allowed",
    notAllowEmailNotificationsExplanation:
      "You won't receive notifications by email about new applications, messages and comments regarding your child.",
    notAllowPushNotificationsExplanation:
      "You won't receive push notifications to your mobile about new messages and comments regarding your child.",
    noteGoToBookings: "Take me to bookings",
    noFilesToShow: "No files to show",
    noReservedNursingTimes: "No reserved nursingtimes",
    oldest: "Oldest",

    passwordsMatch: "Passwords match",
    passwordTenChars: "At least 10 characters",
    passwordOneCapital: "At least 1 capital letter",
    passwordOneNumber: "At least 1 number",

    //perms and consents
    noAnswer: "Not answered",
    permsPermitsGiven: "Permits issued:", //"Annetut luvat:",
    permAnswersNotSaved: "The answers were not saved!", //Vastauksia ei tallennettu!
    permAnswerAllQuestions: "Please answer every question before saving", //"Vastaa jokaiseen kysymykseen ennen tallentamista",
    permSaveConsents: "Save consents", //"Tallenna suostumukset",
    permSaving: "Saving", // "Tallennetaan",
    permSaved: "Saved!", //"Tallennettu!",
    permParentConsentSaved: "Consents saved successfully", //"Suostumusten tallennus onnistui!",
    permChildPermPermsSaved: "Child's permissions saved", //"Lapsen luvat tallennettu",
    permSavingFailed: "Saving failed! Please try again later. Error:", //"Tallennus epäonnistui! Yritä myöhemmin uudelleen. Virhe:",
    permYes: "Yes",
    permNo: "No",
    permQuestionName: "Question", //"Kysymyksen nimi",
    permPermissionQuestionsAria: "permission-questions",
    permGuardiansConsents: "Guardian's Consents",
    permGuardianPermQPanel: "guardian-consents-panel",
    permChildrenPermissions: "Children's Permissions",
    permEachChildPage:
      "Children's permissions can be found on each child's own individual page", // Lasten luvat löytyvät kunkin lapsen omalta sivulta",
    permUnansweredPermissions: "Unanswered permissions", //"Vastaamattomia lupia",
    permNoUnansweredPermissions: "No unanswered permissions", // "Ei vastaamattomia lupia",
    permInfoNeedsUpdating: "Your information needs to be updated", //"Tietosi kaipaavat päivitystä!",
    permNeedConsent:
      "The early childhood education services need your consent(s)", //"Varhaiskasvatuspalvelut tarvitsevat suostumustanne",
    permGoToPermissions: "Take me to Consents", // "Siirry suostumuksiin",
    permSaveChildPermissions: "Save child's permissions", // "Tallenna lapsen luvat",
    permMunicipalPermissionQuestions: "Child's permissions",
    permNoQuestionsToShow: "No questions to show",
    permissionQuestionsGen: "Permissions and consents", // pitkäaikaiset, lyhytaikaiset ja huoltajan suostumukset
    permissions: "Permissions",
    pushNotifications: "Push notifications",

    pdGrowthFolderTitle: "Pedagogical documentation",
    retry: "Retry",
    taskFolder: "Taskfolder",
    settingEmptyInformation: "Clear data",
    settingEmptyInformationInfo:
      "You can completely erase application information here (municipal data, login details)",
    settingLanguagesTitle: "Languages",
    settingLoginTitle: "Logging",
    settingLoginInfo:
      "Logging in to the application always requires strong identification.\n(bank id, mobile id)",
    settingLoginSettings: "Login settings",
    settingPasswordChangeOK: "Password change succeeded",
    settingPasswordChangeFAIL: "Password change failed",
    settingPasswordChangeInvalid:
      "New password has to be at least ten characters long,\ninclude at least one number\nand at least one capital letter.",
    settingPasswordDoesntMatch: "Passwords you entered do not match!",
    settingPasswordNew: "New password",
    settingPasswordNewAgain: "New password again",
    settingNewUserNote:
      "Please set password below. After setting password you can login with username/password or by strong authentication.",

    termsHeaderMain: "Terms of Use for the DaisyFamily Online Service",
    termsHeader1: "1. General information about using the online service",
    termsHeader2: "2. Service Provider",
    termsHeader3: "3. Processing of personal data",
    termsHeader4: "4. Cookies",
    termsHeader5: "5. Use of the Service",
    termsHeader5sub1: "5.1 Rights and Restrictions on Use of the Service",
    termsHeader5sub2: "5.2 Changes to the Service",
    termsHeader5sub3: "5.3 Registration",
    termsHeader6:
      "6. Links to external sites contained in the Service and external links to the Service",
    termsHeader7: "7. Intellectual Property Rights",
    termsHeader8: "8. Limitation of Liability",
    termsHeader9: "9. Amendments and notices",
    termsHeader10: "10. Applicable legislation and settlement of disputes",

    termsPara1:
      "Welcome to the online service of WhileOnTheMove Oy (hereinafter “WOM”) (hereinafter “Service”). You are advised to read these Terms of Use (the “Terms”) carefully before using the Service.",
    termsPara2:
      "These Terms apply to all user actions on the Service, regardless of the main device on which the Service is used. The User is only allowed to use the Service in accordance with the current Terms and Conditions and applicable law and good practice. The Service may also contain sections or functions, the use of which is subject to special conditions or instructions provided separately in the Service in addition to these Terms, and their use may require registration. Some content on the Service may only be available to residents of certain geographic areas.",
    termsPara3:
      "The Terms constitute an agreement between you and WOM and define the rights and obligations of you and WOM with respect to the Service.",
    termsPara4:
      "Use of the Service is an indication that the user accepts the current Terms and Conditions and undertakes to comply with them and the terms and instructions provided separately in the Service.",
    termsPara5: "business ID:",
    termsPara6: "Phone exchange:",
    termsPara7:
      "WOM processes the personal data of the user obtained through the Service in the manner permitted and required by the personal data legislation in force at the time. The data protection statement of the register based on WOM's customer relationship and other material connection describes the processing of personal data in more detail.",
    termsPara8:
      "When visiting our website (https://kunta.daisyfamily.fi), cookies (including Local and Session storage records), small text files, are being saved onto your device by your browser. Cookies cannott damage or spread malware to your device.",
    termsPara9:
      "Our website only uses technical cookies that are necessary to give our clients safe, fluent, and appropriate usage of the service. For example, the login process and saving language selection use cookies to enable fluent and safe usage of the website for clients.",
    termsPara10:
      "It is possible to switch off all technical cookies from your browser’s settings. We would like to point out, however, that by doing so you cannot log in to our service. Deleting your own cookie history is possible from your browser’s settings and will not affect the usage or log in to our service.",
    termsPara11:
      "WOM grants you a limited, revocable, non-exclusive, and personal right to use the Service and its content, as defined in these Terms, only in accordance with these Terms and any terms and conditions that may be provided separately on the Service. The User has the right to use the Service and its content only for the User's own, non-commercial use.",
    termsPara12:
      "User may not modify or reproduce the Service or its content or any portion thereof. User also do not have the right, as such or as modified, to distribute, distribute, transmit, publicly or otherwise make the Service or its content available to the public unless expressly authorized to do so in these Terms.",
    termsPara13:
      "The User has the right to take a print or copy of the content of the Service or save a copy on their own main device for their own private use. User must retain the copyright and other proprietary notices contained in the Service on all such printouts and recordings.",
    termsPara14:
      "User may not interrupt or attempt to interrupt or otherwise interfere with or attempt to interfere with the operation of the Service or the ability of other users to use the Service in any way. Prohibited actions include, for example, the placement in the Service of material containing timed malware, viruses or other material that may affect the programmatic structure of the Service.",
    termsPara15: "The User may not use the Service",
    termsPara16: "to act as a representative of the WOM",
    termsPara17:
      "falsifying or manipulating title or identifying information in a manner intended to disguise the origin of content transmitted through the Service",
    termsPara18: "other forms of misrepresentation",
    termsPara19: "to store or collect other users' personal information",
    termsPara20: "forwarding spam or other unauthorized communications",
    termsPara21: "other acts contrary to law or good practice.",
    termsPara22:
      "The Service and its content are provided “as is”. WOM reserves the right to modify or alter the Service or its content at any time without notice or liability. WOM thus has the right to e.g. modify, delete or add portions of the Service and make free portions paid or vice versa. WOM also reserves the right at any time to suspend or terminate the provision of the Service, its content or any part thereof, or to restrict your use of the Service or any part thereof. If the user has violated these Terms, the terms or instructions provided separately on the Service, or the law or good manners, WOM has the right to block the user's access to the Service and to refuse to use the Service in the future.",
    termsPara23:
      "To obtain the license described above for certain sections of the Service, you may be required to register. The right to use sections subject to registration may be limited to natural or legal persons per section.",
    termsPara24:
      "When registering as a user of the Service, the user must provide the correct requested information about himself. Providing false information is prohibited and may result in penalties.",
    termsPara25:
      "Upon registration, the user is given a username and the user chooses a password for himself. The user is responsible for the careful storage and confidentiality of the password and is responsible for all transactions with the user ID on the Service. The user should contact WOM immediately if the user discovers or suspects that his / her IDs have been revealed or that his / her IDs have been misused. WOM has the right to freeze or close user IDs.",
    termsPara26:
      "The Service may contain links to websites owned or operated by third parties. The Linked Sites are not under the control of WOM in any way and WOM does not control or review the content of the Linked Sites. WOM is not responsible for the content, accuracy, reliability or security of the linked sites.",
    termsPara27:
      "Use of links to external sites will remove you from the Service, and you will use the linked sites at your own risk. The user is advised to read carefully the terms and conditions and instructions of the linked site before using that site.",
    termsPara28:
      "An external link to this Service should link directly to the main page of the Service, and open in a standalone browser window and not into the own frame of the linked site. The appearance, placement and other characteristics of the link must not adversely affect WOM's reputation or create a false impression that the linking entity or its services or products are related to or sponsored by WOM. The link must also not contain WOM trademarks in figurative form.",
    termsPara29:
      "The links contained in the Service to external sites and external links to the Service do not indicate any relationship between WOM and the third party.",
    termsPara30:
      "The Service and its content, such as texts, images and logos, are the property of WOM or its affiliates and are protected by copyright laws and international copyright treaties, as well as other intellectual property laws and registrations.",
    termsPara31:
      "All intellectual property rights (such as copyrights, registered and unregistered trademarks and design rights, domain names, patents, database rights, and trade secrets) related to the Service and Content and the goodwill resulting from their use belong to WOM or its affiliates. WOM does not grant you any direct or indirect rights in any intellectual property rights.",
    termsPara32:
      "WOM strives to keep the Service available to the user continuously and without interruption. However, WOM does not warrant that the Service will be available to you without interruption, timeliness or error. The Service and its content are provided to the User “as is”. WOM is not responsible for the accuracy, reliability, errors, omissions, inaccuracies or other defects in the information or content provided on the Service.",
    termsPara33:
      "WOM cannot guarantee the security of the Service, its content or the information, such as messages, attachments or hyperlinks, that are transmitted through the Service and are produced by or otherwise on users responsibility , nor is it responsible for ensuring that electronic bulletins sent through the Service do not contain viruses or other harmful elements. WOM is also not responsible for any information sent or received by the User through the Service or for any information or content that is not sent or received.",
    termsPara34:
      "The service may not be available in all countries and may only be available in certain languages. The service, its operation, or some features may depend on the compatibility of the devices used, network connections, and content formats.",
    termsPara35:
      "WOM shall not be liable for any direct, indirect, incidental or consequential damages or special damages, including damages arising out of the loss of or interruption of business, contract, revenue or data, resulting from the use or obstruction of the Service or its content, regardless of these Terms, whether the damage is based on contract, infringement of rights, negligence or any other ground, even if the WOM has been notified in advance of the possibility of such damage. In all cases, WOM's liability is always limited to liability under mandatory law.",
    termsPara36:
      "User release WOM and all of its employees, directors and agents on its behalf from liability for all suits and claims arising out of user's use of the Service.",
    termsPara37:
      "WOM reserves the right to change these Terms at its sole discretion by notifying the User by email or through the Service. Changes to the Terms will be effective upon notice. Use of the Service after the moment of notification is considered a notification that the user accepts the changes made to the Terms.",
    termsPara38:
      "WOM may include notifications in the Service. WOM may also send you notifications about the Service to the email address or telephone number provided by the user. Notifications shall be deemed to have been received (7) days after they have been sent or displayed by the WOM. Continuing to use the service means that the user is considered to have received all notifications regardless of the method of transmission.",
    termsPara39:
      "The use of the Service and these Terms are governed by Finnish law, except for its conflict of law provisions.",
    termsPara40:
      "The parties shall endeavor to resolve their differences primarily through negotiations. If no amicable settlement is reached, the disputes will be settled in the Oulu District Court in Finland. The consumer user may also take the dispute to the competent district court or consumer dispute board, depending on his domicile.",
    termsPara41: "Terms of use have been prepared and / or updated:",
    termsPara42: "Oulu, March 30, 2021",

    uctAvailableTime: "Available day care time",
    uctInfo1:
      "Here you can track how many hours your child has already used (= hours already used + hours booked) per month. \nIf the payment category is not defined and you do not see the hours available, \n this means that the placement you have selected does not have service need defined in DaisyManager.",
    uctInvoiceBasis: "Invoice data", //todo
    uctPaymentCategory: "Payment category",
    uctServiceNeedType: "Service need type",
    uctSpentTime: "Spent time",
    uctBooker: "Booker",
    uctBookings: "Reservations",
    uctBooking: "Reservation",
    uctBilling: "Billing", //TODO
    uctBillingCaps: "BILLING", //TODO
    uctDateAbbr: "Date",
    uctDuration: "Duration",
    uctHoursTotal: "Total hours",
    uctPaymentFree: "Free hours", //TODO
    uctPreSchoolDuration: "Preschool Duration",
    uctPreSchoolTeaching: "Preschool",
    uctRealizedCareTimes: "Spent time",
    womNotification: "Message from Daisy Varhaiskasvatus",
    womNotificationPara1: "Welcome to DaisyFamily!",
    womNotificationPara2: "This is version 1.2.1.0",
    womNotificationPara3:
      "In the new version of DaisyFamily user interface and background services have been optimized",
    changePasswordBy: "Reset the password by giving your e-mail address",
    childsBirthdate: "Child's birthdate", //TODO
    firstTimer: "Are you logging into Daisy for the first time?",
    firstTimerInfo1:
      "Are you logging into Daisy for the first time? Here you can order a username and a password for yourself.", //todo
    firstTimerInfo2:
      "Please enter your child's birthdate, and the e-mail address you gave in your application for childcare.", //todo
    forgotten: "Forgotten?",
    iHaveCopiedMyUserName: "I have stored my username somewhere safe",
    incorrectBirthDate: "Birthdate input not valid",
    incorrectEmailAddress: "Email input not valid",
    infoNewUser1:
      "Your username is shown only once here. Should you forget your username in the future, on that occasion please contact the childcare centre.", //todo
    infoNewUser2:
      "Your password has been sent to your e-mail address. If the message cannot be found, please check the spam folder.",
    orderUserNameAndPassword: "Order username and password", // todo
    passwordReset: "Password reset",
    passwordResetInfo1:
      "A temporary password has been sent to your e-mail address. If not found, please check your spam filter.",
    passwordResetInfo2:
      "Return to the login page and give the username and the temporary password.",
    passwordRequirements:
      "The password needs to be at least 10 characters long, and contain at least 1 capital letter and 1 number.",
    passwordSaveInfoText: "Save here",
    privacystatement: "Privacy policy",
    requestFailed: "The request failed",
    resetPassword: "Reset your password",
    msgSearchForRecipient: "Search for recipients",
    msgSelectRecipients: "Select the recipients",
    msgSend: "Send",
    msgStaffPersons: "Staff members and managers",
    loginStrongIdentification: "Strong identification",
    termsOfUse: "Terms of Use",
    themeChange: "Change theme",
    msgThreadParticipants: "Message thread participants",
    tips: "Tips",
    tipsInfo: "Show the tips in the app",
    msgTitleMaxLength: "Maximum title length reached",
    msgTitleMissing: "Please include a title for your message",
    toTheLoginPage: "To the login page",
    tryAgainQ: "Try again?",
    msgUnknownPersonOrGroup: "Unknown person or group",
    msgUnreadMessages: "Unread messages:",
    userManual: "User's Manual",
    signUp: "Sign up",
    useStrongAuthenticationInstead:
      "Your daycare centre is using strong authentication for signing in. Please sign in using strong authentication. The password can be changed in the Settings once you have signed in.", //todo
    useStrongIdentification:
      "Please use strong identification to log in for the first time",
    observations: "Write observations...",
    welcometodaisyfamily: "Welcome to DaisyFamily",
    welcometodaisyfamily2:
      "DaisyFamily - Experience the Future of Early Childhood Education",
    welcomeToUsingDF: "Welcome to using DaisyFamily!",
    msgWhomMessageConcerns: "Whom does this message concern?",
    settingYes: "Yes",
    msgYou: "You",
    msgYourMsgWasSent: "The message was sent",
    yourUserName: "Your username",
    prev: "PREVIOUS",
    next: "NEXT",
    errCouldNotLoadData: "The data could not be loaded from the server",
    waitingservertoloaddata: "Loading data from server",

    formSaved: "Form saved",
    eduPlanChildDescription: "Description of the child",
    vasu: "Learning plan",
    vasuInfo: "ECEC feature is not turned on. Contact your service provider",
    vasuLocked:
      "Child education plan form is locked in readonly state. You can see the contents of the plan but you cannot modify the form",
    Preinformation1: "Basic information",
    Preinformation2:
      "The values of our kindergarten early childhood education and the group's goals",
    eduPlanTitle1: "Preparing for the discussion",
    eduPlanTitle2: "The discussion",
    eduPlanTitle3: "Support needs",
    eduPlanTitle4: "Spring evaluation",
    eduPlanSubtitle1: "Filled in by staff",
    eduPlanSubtitle2: "Questions for parents",
    vasuInvoledPeople:
      "Early childhood educators involved in the development of the ECEC plan",
    medicalplan: "Plan for medical care",
    attachments: "Attachments",
    guardian: "Guardian",
    eceTeacher: "Early childhood education teacher",
    otherParticipants:
      "Other personnel and/or experts involved in the creation of the plan",
    creationDate: "Creation date",
    vasuValues: "Our day cares ECEC values",
    groupObjectives: "Group objectives",
    VasuChildSubTitle1: "Self-sufficiency and everyday skills",
    VasuChildSubTitle2: "Interaction and emotional skills",
    VasuChildSubTitle3: "Language",
    VasuChildSubTitle4: "Play",
    VasuChildSubTitle5: "Motor skills and interest in sport",
    VasuChildSubTitle7: "Exploration",
    VasuChildSubTitle8:
      "Evaluation of the fulfilment of the previous ECEC plan's objectives and actions, if a previous ECEC plan exists",
    VasuChildSubTitle1v2: "A rich world of languages",
    VasuChildSubTitle2v2: "Many forms of expression",
    VasuChildSubTitle3v2: "Me and our community",
    VasuChildSubTitle4v2: "I study and act in my environment",
    VasuChildSubTitle5v2: "I grow, move and develop",
    ObjectivesForPedagogicalActivitiesAndMeasuresToAchieve:
      "Objectives for the pedagogical activities and actions to achieve the planned objectives",
    VasuChildSubTitle10: "Other noteworthy things",
    ChildWellbeingAndLearningObjectives:
      "Objectives and measures for promoting wellbeing and learning for the child",
    OtherExtraInfo: "Other additional information",
    OtherImportanInfo: "Other important issues",
    ParentNotes: "Guardians' observations",
    VEONotes: "ECEC special needs teacher's notes",
    ChildNotes: "Child's opinions and wishes",
    OtherNotes: "Other notes",
    OwnNotes: "Own notes",
    IssuesRaisedAgreed: "Things discussed / agreed upon",
    Evaluation: "Evaluation",
    VasuChildSubTitle1Info:
      "Dressing, undressing, meals, sleeping, going to the bathroom, arriving and leaving daycare",
    VasuChildSubTitle2Info:
      "Interaction with other children and adults, handling boundaries and rules, waiting for turn, tolerating disappointments, expression of emotions etc.",
    VasuChildSubTitle3Info:
      "Producing and understanding speech, linguistic memory and vocabulary, rudiments of reading and writing, interest in books and fairy tales etc.",
    VasuChildSubTitle4Info:
      "Developmental phase of play, favorite themes to play, playmates, duration of play, the use of imagination, the possibilites of play and meaning/significance at home/daycare etc.",
    VasuChildSubTitle5Info: "Fine and gross motor skills, daily exercise",
    VasuChildSubTitle6Info:
      "Musical, visual, verbal and bodily expression, culture",
    VasuChildSubTitle7Info:
      "Environmental education, mathematical thinking, technology education",
    VasuChildSubTitleGeneralInfo1:
      "Characteristics of the child:\n What does the child already know, what skills is the child learning, in which areas does the child need support?",
    VasuChildSubTitleGeneralInfo2:
      "Characteristics of the child:\n The child's strenghts, freetime hobbies, areas where there is need for support",
    VasuChildSubTitle8Info:
      "Fulfilment of the objectives and observations about the previous ECEC plan",
    VasuChildSubTitle1Infov2:
      "Interaction, language comprehension, speech production, memory and vocabulary, interest in reading and writing, Language of Instruction",
    VasuChildSubTitle2Infov2:
      "Musical, pictorial, handicraft, verbal and bodily expression, play",
    VasuChildSubTitle3Infov2:
      "Family/local community, culture, attitude education, media",
    VasuChildSubTitle4Infov2:
      "Mathematical thinking, environmental and technology education",
    VasuChildSubTitle5Infov2: "Movement, dressing, eating, rest, health",
    ObjectivesForPedagogicalActivitiesInfo1:
      "The child's strengths, interests and needs, and taking them into account",
    ObjectivesForPedagogicalActivitiesInfo2:
      "Objectives for the pedagogical activities",
    ObjectivesForPedagogicalActivitiesInfo3:
      "Actions and methods to achieve objectives",
    ObjectivesForPedagogicalActivitiesInfo4:
      "Other possible needs relating to the support of the child's development and learning, and the objectives and arrangements agreed upon relating to the implementation of the support",
    VasuChildSubTitle10Info1:
      "Other noteworthy things related to supporting the child's wellbeing",
    VasuChildSubTitle10Info2:
      "Other document and plans utilized in the planning process",
    VasuChildSubTitle10Info3:
      "Monitoring and evaluating (other additional information)",
    VasuChildSubTitle10Info4: "Cooperation and responsibilities",
    VasuSpecialSupport: "Special needs support",
    VasuEnhancedSupport: "Enhanced Support",
    VasuadministrativeDecision: "Administrative decision",
    VasuNoadministrativeDecision: "No Administrative decision",
    VasuSupportTitle1: "Support forms and assessments",
    VasuSupportTitle2: "Other things to consider in child support",
    VasuNoSpecialSupport:
      "The child does not need special support, they are supported with enhanced support",
    VasuNoEnhancedSupport:
      "The child does not need enhanced support, support is provided through a general decision",
    VasuSupportSubTitle1:
      "The overall situation of the child's growth, development and well-being from the perspective of the child, guardian and early childhood education staff",
    VasuSupportSubTitle2: "Pedagogical forms of support and evaluation",
    VasuSupportSubTitle3: "Structural forms of support and assessment",
    VasuSupportSubTitle4: "Curative forms of support and assessment",
    VasuSupportSubTitle5: "Cooperation and responsibilities",
    VasuSupportSubTitle6:
      "Other possible documents and plans used in the preparation",
    VasuSupportSubTitle7: "Additional information",
    leopsrequestFailed: "Request failed",
    leopsLockingSavedOk: "Locking saved",
    leopsLockingSavedFailed: "Locking saving failed",
    leopsChildEvaluationSaved: "Child evaluation saved",
    leopsChildEvaluationSaveFailed: "Child evaluatio save failed",
    leopsAnswerSelectingBestChoice: "Answer by selecting best choice",
    leopsStillTraining: "I'm still traininig",
    leopsICanAlready: "I can already",
    ManyFormsOfExpression: "The many forms of expression",
    leopsRichWorldOfLanguage: "The rich world of language",
    leopsMeAndOurCommunity: "Me and our community",
    leopsIStudyAndWork: "I study and work in my environment",
    leopsIGrowAndDevelop: "I grow and develop",
    leopsIWantToLearnInPreschool: "I want to learn in preschool",
    StaffNotes: "ECEC personnel's observations",
    leopsLeopsTitle1_3: "Guardian section",
    leopsChildStrengthsAndIterestsParent: "My child’s strengths and interests",
    leopsObjectivesAndConsiderationsForThePreschool:
      "Objectives and other considerations for the pre-school year",
    HopesForCooperation: "Hopes for cooperation",
    leopsIHaveLearnedInPreschool: "I have learned in preschool",
    leopsTitle4_3: "Teacher's summary of the pre-school year",
    ChildStrengthsAndIterests: "The child’s strengths and interests",
    EvaluationPedagogicalActivitiesGoalsSupport:
      "Evaluation of the pedagogical activities, goals and support measures of the pre-school year",
    OtherAdditionalInfoAndCooperation:
      "Other additional information and cross - sectoral cooperation",
    GreetingsFromGuardian: "Greetings from guardian",
    LockLeops: "Lock",
    ThingsAriseFromPreviousECEC: "Issues arise from a possible earlier ECEC",
    ObjectivesForPedagogicalActivitiesAndMeasuresToAchieve:
      "Objectives for the pedagogical activities and actions to achieve the objectives",
    OtherPartnersAndDocuments: "Other partners and documents",
    StudentCareMeasures: "Student care measures",
    AgreedCooperationWithGuardians: "Agreed cooperation with guardians",
    ManyFormsOfExpressionInfo:
      "Oral and physical expression, multilingualism, participation and influence, ability to concentrate, self-regulatory skills",
    RichWorldOfLanguageInfo:
      "Perception of meanings, interest in language, reading and writing",
    MeAndOurCommunityInfo:
      "Everyday ethical choices, emotional skills, interaction skills, working in society and the surrounding community",
    IStudyAndWorkInfo:
      "Mathematical thinking, everyday mathematics and technology, the relationship with nature, research",
    IGrowAndDevelopInfo:
      "Self-care skills, healthy and sustainable lifestyles, safety skills",
    LeopsTitle1_2: "Child's autumn self-assessment and preparatory findings",
    LeopsTitle1_2_1: "Child self-assessment (autumn)",
    EvaluationPedagogicalActivities: "Evaluation of pedagogical activities",
    LockLeopsInfo:
      "The Spring Assessment Discussion and Data Transfer to School section is complete and I want to lock it in. NOTE! Once you lock a conversation, you will no longer be able to add or edit your comments.",
    LeopsTitle4_2: "Child's spring self-assessment",
    LeopsTitle4_2_1: "Child's spring self-assessment (spring)",
    PedagogicalEvaluation: "Pedagogical evaluation",
    PedagogicalStudy: "Pedagogical study",
    EnhancedSupportLearningPlan: "Enhanced support learning plan",
    HOJKS: "HOJKS",
    removeFormLocking: "Remove form locking",
    ChildStrengthsAndInterestsLearningNeeds:
      "The child's strengths, interests, learning abilities and special needs",
    Confidental:
      "Confidential.\nSection 24 (1) (30) of the Public Access to Information Act",
    DocumentsUsedInDrafting: "Documents used in the drafting",
    EnhancedSupportInitiating: "Initiating and organizing enhanced support",
    EnhancedSupportAndArrangements:
      "Enhanced support and support arrangements for the child are recorded in more detail in the learning plan",
    EnhancedSupportStartingAndOrganization:
      "The initiation and organization of enhanced support / return to general support has been addressed on the basis of the above pedagogical assessment",
    GeneralSupportAndAssessmentImpact:
      "General support and assessment of the impact of different forms of support",
    PersonsInvolvedDraftingDocument:
      "Persons involved in drafting the document and dates of consultations",
    PPH: "Family daycare",
    PreschoolPlanCreated: "Pre-school plan created",
    AssessmentChildGrowthAndLearning:
      "Assessment of a child's growth and learning",
    OverallSituationChildGrowthAndLearning:
      "The overall situation of the child's growth and learning from the perspectives of the child, the guardian and the pre-school staff",
    AssessmentEnhancedSupportAndArrangements:
      "Assessment of the need for enhanced support and support arrangements",
    EnhancedSupportStart: "Enhanced support starts",
    EnhancedSupportNotNeeded:
      "The child does not need enhanced support, supported by general support",
    EnhancedSupportLearningPlanCreated:
      "Enhanced support learning plan developed",
    LeopsInfo:
      "If the pedagogical report is prepared for the purpose of reviewing the decision on special support or if the place of teaching changes, fill in the sections below",
    SpecialSupportFirstDate: "Special support decision made for the first time",
    SpecialSupportPrevDate: "Previous revision of the special support decision",
    DecisionMaker: "Decision maker",
    PreschoolOrganizer: "Preschool organizer",
    GeneralCompulsoryEducation: "General compulsory education",
    Started: "Started",
    ExtendedCompulsoryEducation: "Extended compulsory education",
    EnhancedSupportEvaluation:
      "Enhanced support and an assessment of the impact of different forms of support",
    PreschooEducationlProviderAssessment:
      "Pre-school education provider's assessment of the need for special support",
    SpecialSupportStart: "Special support will be started",
    DateLong: "Date",
    ResponsiblePreschollTeacher: "Responsible prescholl teacher",
    ConsultationChildAndGuardian: "Consultation of the child and the guardian",
    ConsultationChildAndGuardian2:
      "Consultation of the child and the guardian or legal representative in accordance with the Administrative Code",

    HOJKS_Title1: "Personal teaching plan (HOJKS)",
    LeopsFormTitle2: "Arranging the child's pre-school education and support",
    LeopsFormTitle2_1: "Objectives of the child's pre-school education",
    LeopsFormTitle2_2: "The child's learning abilities and support needs",
    LeopsFormTitle3: "Pedagogical solutions",
    LeopsFormTitle3_1: "Reinforcing interaction and learning environment",
    LeopsFormTitle3_1_1:
      "A positive and acceptable atmosphere, a genuine presence of breeders, common goals for action",
    LeopsFormTitle3_2: "Pedagogical operating models",
    LeopsFormTitle3_2_1:
      "Small group activities, use of images, anticipation, structure",
    LeopsFormTitle3_3: "A multidisciplinary breeding community",
    LeopsFormTitle3_3_1: "Responsibilities and roles",
    LeopsHojksTitle3_4:
      "Interpretation and assistance services, other educational services and special aids in accordance with the decision on special support",
    LeopsEnhancedTitle3_4:
      "Interpretation and assistance services in accordance with the Basic Education Act, other teaching services and special aids",
    LeopsFormTitle3_5:
      "Possible participation of the child in other early childhood education",
    LeopsHojksTitle3_6:
      "Description of the arrangements and responsibilities for the transport of the child's pre-school education and the supervised activities and supervision of the child expecting transport",
    LeopsHojksTitle4: "Monitoring and evaluation of HOJKS",
    LeopsEnhancedTitle4: "Monitoring and evaluating the learning plan",
    LeopsHojksTitle4_1: "Assessment of the realization of HOJKS 'goals",
    LeopsEnhancedTitle4_1:
      "Assessment of the achievement of the objectives of the learning plan",
    LeopsHojksTitle4_2: "Assessment of the effectiveness of HOJKS measures",
    LeopsEnhancedTitle4_2:
      "Assessment of the effectiveness of the measures in the learning plan",
    LeopsHojksTitle4_3:
      "An assessment of the child's overall well-being and learning situation should be made together with the child and the guardian",
    LeopsEnhancedTitle4_3: "Next monitoring and evaluation",
    LeopsFormTitle5: "Outcome of the evaluation",
    LeopsEnhancedTitle5_2:
      "The learning plan responds to the child’s need for support and enhanced support continues",
    LeopsEnhancedTitle5_3:
      "A pedagogical report is prepared for the child for special support",
    LeopsHojksTitle5_1:
      "The child does not need special support, a decision is made to end it and an enhanced support learning plan is drawn up for the child",
    LeopsHojksTitle5_2:
      "HOJKS meets the child's need for support and special support continues",
    LeopsStudy6_2:
      "The document will be read-only mode when the guardian(s) consultation is confirmed",
    LeopsStudy2: "Kontrollera särskilt stöd / byta undervisningsanordnare",

    //GUIDE: Booking
    guide: "GUIDE",
    guideNoContent: "No content available",
    backToTop: "Scroll to top",
    keywordButtonTitle1: "Scroll to ",
    keywordButtonTitle2: " section",
    help: "Help?",
    press: "Press",
    color: "Color",
    text: "Text",
    symbol: "Symbol",
    description: "Description",
    keywords: "Keywords",
    addBookingKeywordButton: "BOOKING",
    addBookingKeywordButton2: "Booking",
    editBookingKeywordButton: "EDITING",
    absentBookingKeywordButton: "ABSENCE",
    absentBookingKeywordButton2: "Absence",
    defaultTimeBookingKeywordButton: "DEFAULT CARE TIME",
    bookingIconsKeywordButton: "INTERPRETATION",
    careTimeLeftKeywordButton: "CARE TIME",
    invoiceDataKeywordButton: "INVOICE DATA",
    guideFAQKeywordButton: "FAQ",
    addBookingAccordionTitle: "Booking a care time",
    editBookingAccordionTitle: "Editing care time",
    defaultTimeAccordionTitle: "Default care time",
    absentAccordionTitle: "Marking absence",
    bookingIconsAccordionTitle: "Calendar's symbols",
    careTimeLeftAccordionTitle: "Available care time",
    invoiceDataAccordionTitle: "Invoice data",
    accordionTitleFAQ: "FAQ",
    defaultTimeAccordionDesc: "Setting & purpose",
    bookingIconsAccordionDesc: "Symbols & colors",
    bookingAbsentAccordionDesc: "Absent or sick",
    accordionDescFAQ: "Frequently asked questions",
    addBookingTableTitle: "Care time calendar's colors",
    addBookingTableDesc1:
      "You can book care times for those days in the future that appear on white background in the care time booking calendar.",
    addBookingTableDesc2:
      "If your child is attending shift care, you can book care times also for the weekends that have been marked with the light blue colour.",
    addBookingTableDesc3:
      "It is also possible to make bookings for the general vacation period (marked with pink)...",
    addBookingTableDesc4:
      "...and for the orange special arrangements vacation period (päivystysaika in Finnish), if the date for the bookings has not been locked yet.",
    addBookingTableDesc5: "Weekend.",
    addBookingAfterTable1:
      "You can set a default care time for your care time bookings by pressing the ",
    addBookingAfterTable2:
      " -button, in the lower left corner. The app will then suggest a default care time every time you are making a booking. The default time can be set in 15-minute intervals and you can change it any time you would like.",
    addBookingAfterTable3:
      "The default care time functionality makes it easier for you to book multiple similar care times, but you cannot create actual bookings with it.",
    addBookingMiddleTitle: "Making a care time booking",
    addBookingList1:
      "Select the dates for the booking on the calendar first. You can select single days by pressing the row for the correct date, entire weeks by pressing the number of the week, or a whole month by pressing the ",
    addBookingList1_2:
      " -button in the lower right corner. Weekends need to be selected separately by pressing on the relevant rows. The selected days will become grey.",
    addBookingList2:
      "Confirm the selections by pressing the confirm button in the lower right corner.",
    addBookingList3:
      "In the “Care Times” booking view, select the children for whom the booking will be made by selecting the on the right.",
    addBookingList4:
      "Under the name of the first selected child, you can select where the booking will be made. The selection will be made according to the so called ‘placement priority’, so if you choose the 1st priority for that child, the same placement priority (1st priority) will be automatically selected also for the other selected children.",
    addBookingList5:
      "The default value for the type of booking is “Care hours”. If needed, you can change the type to “Absent” or “Sick”.",
    addBookingList6:
      "The booking will be made automatically for the days that you chose on the calendar.",
    addBookingList7:
      "Mark the desired time of day for the booking. The starting and ending times can be set at 15-minute intervals.",
    addBookingList8:
      "If you would like to make a second booking for the same days, but for different times, press the “+ Add a new booking” button, and select the options you want for the second booking.",
    addBookingList9:
      "Save the booking by pressing the confirm button at the bottom.",
    addBooking2022Promise:
      "The Care Time Calendar for DaisyFamily will be restructured in 2022 based on the feedback we have received.",
    editBookingGuideTitle: "Editing an Already Existing Care Time Booking",
    editBookingGuideIntro:
      "You can edit an already existing care time booking with the help of the guide below, or, if you wish to, you can delete the care time bookings/markings by selecting the relevant dates and pressing the ",
    editBookingGuideIntro2: " -button in the lower righthand corner.",
    editBookingMiddleTitle: "Editing the care time bookings",
    editBookingList1:
      "Select the dates you wish to edit on the calendar. You can select single days by pressing the row on the date, entire weeks by pressing the week numbers, or an entire month by pressing the ",
    editBookingList1_2:
      " button in the lower righthand corner. Weekends must be selected separately by pressing those dates. The selected dates will turn grey.",
    editBookingList2:
      "Confirm the selections by pressing the plus sign button.",
    editBookingList3:
      "Select “NEW” if you would like to create completely new care time bookings. Select “KEEP” if you would like to create new bookings in addition to the current bookings for the selected days.",
    editBookingList4:
      "In the booking view, select children for whom the booking will be made, by selecting the checkbox on the right side of the child’s name. ",
    editBookingList5:
      "Under the name of the first selected child, you can select where the booking will be made. The selection will be made according to the so called ‘placement priority’, so if you choose the 1st priority for that child, the same placement priority (1st priority) will be automatically selected also for the other selected children.",
    editBookingList6:
      "The default value for the type of booking is “Care hours”. If needed, you can change the type to “Absent” or “Sick”.",
    editBookingList7:
      "The booking will be automatically made for the days that you chose on the calendar.",
    editBookingList8:
      "Mark the desired time of day for the booking. The starting and ending times can be set at 15-minute intervals.",
    editBookingList9:
      "Save the booking/bookings by pressing the confirm button at the bottom of the page.",
    absentBookingIntroTitle: "Marking the Child as Absent or Sick",
    absentBookingIntro1:
      "Children can be marked as absent or sick both in the care time calendar and by using the quick function called “Today’s absence” through which you can notify the daycare that your child is absent or sick on that current day.",
    absentBookingIntro2:
      "You can mark a child as absent or sick for the current day or for multiple days into the future, including for dates that have been already locked.",
    absentBookingIntro3:
      "When you mark a child as absent or sick for a date that has been locked and which already has a care time booking, the care time booking will still stay and show on the “Invoice Data” view.",
    absentBookingMiddleTitle1:
      "Marking children as absent or sick in the caretime booking calendar",
    absentBookingMiddleTitle2:
      "Marking children as absent or sick using the “Today’s absence” tool",
    absentBookingListOne1:
      "Select the dates for which you wish to edit the booking, on the care time booking calendar. You can select single days by pressing the row on the date, entire weeks by pressing the week numbers, or an entire month by pressing the [koko kk] button in the lower righthand corner. Weekends must be selected separately by pressing those dates. The selected dates will turn grey.",
    absentBookingListOne1_2:
      " button in the lower righthand corner. Weekends must be selected separately by pressing those dates. The selected dates will turn grey.",
    absentBookingListOne2: "Confirm the selections by pressing the button ",
    absentBookingListOne2_2:
      " at the bottom. The button to be pressed will depend on whether the date in question is in the future or whether it is a date that has already been locked.",
    absentBookingListOne3:
      "In the care time booking view, select the children for whom the marking will be made by checking the checkbox for them on the right.",
    absentBookingListOne4:
      "Under the name of the first selected child, you can select where the marking will be made. The selection will be made according to the so called ‘placement priority’, so if you choose the 1st priority for that child, the same placement priority (1st priority) will be automatically selected also for the other selected children.",
    absentBookingListOne5: "Select “Absent” or “Sick” in the dropdown menu.",
    absentBookingListOne6:
      "Save the marking by pressing the confirm button at the bottom.",
    absentBookingListTwo1:
      "Press the Main Menu button on the upper righthand corner and select “Today’s absence”.",
    absentBookingListTwo2:
      "In the booking view, check the checkbox for the children whom the marking will concern.",
    absentBookingListTwo3:
      "Under the name of the first selected child, you can select where the marking will be made. The selection will be made according to the so called ‘placement priority’, so if you choose the 1st priority for that child, the same placement priority (1st priority) will be automatically selected also for the other selected children.",
    absentBookingListTwo4: "Select “Absent” or “Sick” on the pulldown menu.",
    absentBookingListTwo5:
      "Save the marking by pressing the confirm button at the bottom.",
    BookingSymbolsTableTitle: "Interpreting the Care Time Calendar Symbols",
    symbolBookingDesc1:
      "The small circles represent the family’s children in the care time booking calendar. A circle shown on a particular date represents a specific child for whom the booking has been made. By pressing the circles, you will be able to see the specifics regarding the bookings for the day.",
    symbolBookingDesc2:
      "Notifies about an exception. The child’s care time booking has been made for multiple time slots for the day, or the booked care day has an “Absent” marking or a “Sick” marking.",
    symbolBookingDesc3:
      "Default care time – You can set a default care time here. The app will suggest this default time every time you are making a care time booking. The default can be set at 15-minute intervals, and it can be changed any time you wish. This functionality helps you create similar kinds of caretime bookings faster, but you cannot make actual bookings just with it.",
    symbolBookingDesc4:
      "Selection/Proceeding button – When you have selected the days for which you wish to make bookings or markings in the calendar, press this button to proceed to the next step in making a booking or marking.",
    symbolBookingDesc5:
      "Select all – Using this functionality, you can select the entire month’s unlocked weekdays (excluding holiday dates) to create bookings for them.",
    symbolBookingDesc6:
      "Absence – When you have selected a locked date/dates for which you wish to create “Absent” or “Sick” marking(s), select this Absence Button to proceed.",
    symbolBookingDesc7:
      "Add booking – Add new bookings or replace the current bookings for the days you have selected",
    symbolBookingDesc8:
      "Delete – Removes bookings and markings for the selected days.",
    symbolBookingDesc9:
      "Available care time – Opens up a sidebar where you can see the available care time for spending for the month. From the sidebar you can also open a child & month specific ‘Invoice data’ view, where you will be able to see summaries of care time reservations and hours spent in care.",
    textBookingDesc1:
      "The text ”Differences” tells you that the starting and/or ending times of the bookings for the siblings differ from each other.",
    textBookingDesc2:
      "This text tells you that this date in the past has a care time booking and/or the child has been in care that day.",
    textBookingDesc3: "Official holiday dates, e.g.:",
    availableCareTimeText1:
      "You can view the available care time behind the [€] button on the care time calendar. The side menu shows the care need marked for the month, the payment category and the time used.",
    availableCareTimeText2:
      "The care time used includes the booked hours of the month as well as the hours already used that have exceeded the daily booking. By looking at this reading, you can see how much care time is still available for this month.",
    availableCareTimeText3:
      "The information on the need for care and the class of payment comes from the background information marked for the child, so if it is missing, please contact the staff or supervisor of your child's place of care.",
    invoiceDataText1:
      "You can find the invoice data for each child and month by selecting the [€] button for the desired month in the care booking calendar and the INVOICE DATA button in the side menu that opens.",
    invoiceDataText2:
      "In the billing basis, you can scroll through the three buttons at the top: reservations, spent time, and preschool.",
    invoiceDataText3_1: "Reservations: ",
    invoiceDataText3_2:
      "you can see the bookings made for the month, their duration, the booker and the effect on the monthly invoicing. Marks on a yellow background mean that the billing is based on the nursing time reservation made for the day in question.",
    invoiceDataText4_1: "Spent time: ",
    invoiceDataText4_2:
      "you can see the actual nursing time to the nearest minute, the actual day duration, and the effect on monthly invoicing. Markings on a green background mean that the billing is based on the actual nursing time for the day in question",
    invoiceDataText5_1: "Preschool: ",
    invoiceDataText5_2:
      "you can view the duration of pre-school education and its effect on monthly invoicing. Pre-school time is free and is therefore deducted from billing. Note! Not all municipalities and private kindergartens use this Daisy pre-school time. If Daisy's pre-school time is off, your child's pre-school time will not appear in this view.",
    invoiceDataText6:
      "At the bottom of your billing basis, you'll see a summary of your monthly invoicing.",
    guideDifferences: "Differences",
    guideTimeReserved: "Time reserved/Time spent in care",
    guideRedText: "Red text",
    redTextExample: "Christmas Eve",
    notificationsSettings: "Notifications",

    defaultMessagingAccordionTitle: "Message view",
    defaultMessagingAccordionDesc: "More info about messaging",
    defaultMessagingSubTitle1: "Message view",
    defaultMessagingIntro:
      "In the message view, you manage communication with the staff at your child's care facility.",
    defaultMessagingList1: "Messaging opens from",
    defaultMessagingList2:
      "-message button, which can be found on the left side of the page.",
    defaultMessagingList3: "You can also open the messaging from",
    defaultMessagingList4:
      "-event report button in the upper right corner and by going to the 'notifications' section, after which you can open the messages by pressing",
    defaultMessagingList5:
      "-show more button and selecting the message thread.",
    defaultMessagingMiddleTitle: "Number of messages",
    defaultMessagingList6: "An orange ball with a number in the ",
    defaultMessagingList7:
      "-message button indicates the number of threads in the message box with unread messages.",
    defaultMessagingList8:
      "You can also see the number of unread messages in the 'notifications' menu in the upper right corner.",
    defaultMessagingMiddleTitle2: "Read and unread messages",
    defaultMessagingList9:
      "The light blue messages in the message list have been read and the dark blue messages have not yet been opened. In the listing, the most recent messages appear at the top.",
    defaultMessagingMiddleTitle3: "View",
    defaultMessagingList10:
      "The communication is shown as message threads as usual from instant messaging applications. Other people's messages appear on the left side of the message thread, and your own messages on the right side of the thread. The included recipients are shown at the top of the message chain.",

    //Viestityksen painikkeet
    messagingButtonsAccordionTitle: "Messaging buttons",
    messagingButtonsAccordionDesc: "More info about messaging buttons",
    messagingButtonsSubTitle1: "Messaging buttons",
    messagingButtonList1: "New message",
    messagingButtonList2: " - start a new message thread.",
    messagingButtonList3: "Refresh",
    messagingButtonList4: " - updates the message view.",
    messagingButtonList5: "All read",
    messagingButtonList6: " - marks all unread messages as read.",
    messagingButtonList7: "Delete",
    messagingButtonList8: " - deletes the message.",

    //Uusi viestiketju
    messageThreadAccordionTitle: "New message thread",
    messageThreadAccordionDesc: "More info about new message thread",
    messageThreadSubTitle1: "New message thread",
    messageThreadList1: "Start",
    messageThreadList2: "a new message thread",
    messageThreadList3: "by pressing the",
    messageThreadList4: "plus button in the message view.",
    messageThreadList5: "Choose",
    messageThreadList6: "with the bullet marker,",
    messageThreadList7: "which child or children the messages applies to.",
    messageThreadList8: "Select",
    messageThreadList9: "the recipents of the message.",
    messageThreadList10: "Accept the selection with",
    messageThreadList11: "the accecpt button.",
    messageThreadList12: "Select",
    messageThreadList13: "a group,",
    messageThreadList14:
      "if you want staff to see the message as soon as possible and/or when the message does not contain sensitive information. In this case, the message will appear on the phone application of the unit's personnel.",
    messageThreadList15: "an individual employee or employees,",
    messageThreadList16:
      "if you want to send a message only to certain people. This selection is made if your message is intended to be private. The message will only be visible to the employee in question. These personal messages may be read less often than messages sent to a group or unit.",
    messageThreadList17: "Enter",
    messageThreadList18: "the subject of the message and the message.",
    messageThreadList19: "At this point, you can still remove recipients.",
    messageThreadList20: "Send the message by pressing",
    messageThreadList21: "'SEND'",
    messageThreadList22: "button.",

    //Viestinnän näkyvyys
    whatKindOfMessagesAccordionTitle: "Communication visibility",
    whatKindOfMessagesAccordionDesc: "More info about communication visibility",
    whatKindOfMessagesList1:
      "In early childhood education, you can be sent messages sent by an individual member of staff, a daycare center or a daycare group. The message chain does not show whether it is a so-called mass message (group, daycare or region-specific), because the communication is only between you and the early childhood education staff. So when you reply to a message you receive, it remains between you and the sender.",
    whatKindOfMessagesList2:
      "The exception to this are family-specific messages",
    whatKindOfMessagesList3:
      "sent to family guardians under your child's name. When you reply to family-specific messages, the answer is also visible to other guardians of your family.",
    whatKindOfMessagesMiddleTitle: "In DaisyFamily, you may receive:",
    whatKindOfMessagesList4: "Personal messages",
    whatKindOfMessagesList5: "for you, with your name.",
    whatKindOfMessagesList6: "Family-specific messages",
    whatKindOfMessagesList7:
      "for you and your child's other guardians with your child's name.",
    whatKindOfMessagesList8: "Group-specific messages",
    whatKindOfMessagesList9:
      "or you and the other guardians of your child's group under the name of the child's care group.",
    whatKindOfMessagesList10: "Detailed messages",
    whatKindOfMessagesList11:
      "for you and the other guardians of your child's daycare center with the name of the child's daycare center.",
    whatKindOfMessagesList12: "Region-specific messages",
    whatKindOfMessagesList13:
      "for you and other guardians of the kindergarten region with the region's name.",
    whatKindOfMessagesMiddleTitle2:
      "Who can see your reply messages when you reply to an incoming message thread?",
    whatKindOfMessagesList14:
      "If you see the name of a daycare center or group in the participant list of the message thread, the messages of the message thread will be visible to the personnel of that unit who are in that daycare center or group. If you want to communicate with an individual early childhood education staff member, you can start a new message thread with that person.",
    whatKindOfMessagesList15: "Group- and unit-specific message threads:",
    whatKindOfMessagesList16:
      "Responses to message threads started with the name of the daycare group or unit are visible to the unit's staff.",
    whatKindOfMessagesList17: "Family-specific message threads:",
    whatKindOfMessagesList18:
      "The message thread started with your child's name has also been sent to other guardians connected to the child. In this case, the participants in the message thread have the entry 'Child's Surname, First Name (Guardians)'. When you reply to this thread, your reply will be shown to the sender of the message.",
    whatKindOfMessagesList19: "Note!",
    whatKindOfMessagesList20:
      "If a new guardian is added to the child later (e.g. your spouse), he or she will also see all previous message threads related to the child with their replies.",

    //Avainsanat
    defaultMessagingKeywordButton: "Messaging",
    messagingButtonKeywordButton: "Buttons",
    messageThreadKeywordButton: "Send",
    whatKindOfMessagesKeywordButton: "Recipients",

    //Guide: Permission
    //Lupakyselyt
    permissionRequestAccordionTitle: "Permission queries",
    permissionRequestAccordionDesc: "More info about permission queries",
    permissionRequestSubTitle: "Permission queries",
    permissionRequestIntro:
      "With the help of permission queries, staff can collectively request permission from guardians, for example, to participate in excursions or other situations.",
    permissionRequestList1:
      "You can find permission requests for your child by selecting the",
    permissionRequestList2:
      "-permission requests icon on the left or by pressing the",
    permissionRequestList3:
      "-event report button in the upper right corner and going to 'notifications', then you can open permission requests and consents by pressing the",
    permissionRequestList4:
      "-show more button under 'Permissions and consents' and selecting either guardian consents or requests.",
    permissionRequestList5:
      "In the 'Inquiries' section, you can see a list of permission inquiries about your child.",
    permissionRequestList6: "You can update the listing by clicking the",
    permissionRequestList7: "'Update'",
    permissionRequestList8: "button.",
    permissionRequestList9: "-kuvake näkyy uusien lupakyselyiden kohdalla.",
    permissionRequestList10:
      "-New notifications under the icon show new permission requests.",
    permissionRequestList11:
      "-The date shown in the place of the icon tells you until what time the permission inquiry is valid and you can answer it.",
    permissionRequestList12:
      "Avaa haluamasi lupakysely painamalla lupakyselyn otsikosta",
    permissionRequestList13:
      "After opening the permission survey, you can answer it by marking either",
    permissionRequestList14: "",
    permissionRequestList15: "",
    permissionRequestList16: "'Yes'",
    permissionRequestList17: "or",
    permissionRequestList18: "'No'.",
    permissionRequestList19: "After making your selection, press the",
    permissionRequestList20: "'SEND'",
    permissionRequestList21: "-button to send your reply.",
    permissionRequestList22: "Family guardians can",
    permissionRequestList23: "modify the answer",
    permissionRequestList24:
      "during the validity of the permission request. After the last answer date, the answer is locked.",

    //Huoltajan suostumukset
    parentalConsentsAccordionTitle: "Guardian's consents",
    parentalConsentsAccordionDesc: "More info about guardian's consents",
    parentalConsentsList1:
      "Some municipalities and private kindergartens may have guardian-specific consents. These consents are visible in DaisyFamily and the application service eDaisy, if it is used.",
    parentalConsentsList2: "You can find your consents by selecting ",
    parentalConsentsList3:
      "-show more button under 'Permissions and consents' and selecting 'guardian consents'.",
    parentalConsentsList4:
      "Respond to the consents as you wish by pressing either 'Yes' or 'No'. You can change your consent if you want. Save the change by pressing the 'Save consents' button.",

    //Lasten luvat
    childPermissionsAccordionTitle: "Child permissions",
    childPermissionsAccordionDesc: "More info about child permissions",
    childPermissionsList1: "'Permissions and consents'",
    childPermissionsList2:
      "page informs you if the permission queries concerning the family's child have not been answered.",
    childPermissionsList3:
      "Child-specific permission questions can be found in the child's basic information section 'Child's permissions'.",
    childPermissionsList4:
      "The child's basic information can be found in the submenu of the child's page.",
    childPermissionsList5: "You can access the child's website:",
    childPermissionsList6: "If the family has",
    childPermissionsList7: "one child",
    childPermissionsList8:
      "in Daisy, you will come to the child's page as soon as you log in to DaisyFamily.",
    childPermissionsList9:
      "Basic information can be found in this child's submenu.",
    childPermissionsList10: "several children",
    childPermissionsList11:
      "in Daisy, you can access the individual child's page by clicking on the child's own color box with the child's name and profile picture. This can be found on the",
    childPermissionsList12: "'Children'",
    childPermissionsList13: "page.",
    childPermissionsList14:
      "Basic information can be found in the submenu of the child's page.",
    childPermissionsList15: "On the child's page,",
    childPermissionsList16: "'Basic information'",
    childPermissionsList17: "can be found in the sub-menu of the page.",
    childPermissionsList18:
      "Mark the child's permissions as 'YES' or 'NO' and save the changes. The child's guardians can modify the permission information if they wish.",
    childPermissionsList19:
      "When you edit the permission information, please also contact the staff at your child's care facility so that they have the latest information about the changes.",

    //Avainsanat
    permissionRequestKeywordButton: "Editing",
    parentalConsentsKeywordButton: "Guardian-specific",
    childPermissionsKeywordButton: "Child-specific",

    //Keskusteluajat
    discussionTestTitle: "Keskusteluajat testi ohje",

    //Testi
    discussionTestList: "Testi",

    //Avainsanat:
    discussionTestKeyword: "Testi",

    //UUSI VARAUSKALENTERI
    newCalendarNewCaretime: "New caretime",
    newCalendarNewAbsence: "New absence",
    newCalendarDeleteTimes: "Delete caretimes",
    newCalendarDeleteTimes2: "Delete caretimes",
    newCalendarServiceNeed: "Service need",
    newCalendarPaymentCategory: "Payment category",
    newCalendarFreeTime: "Free time",
    newCalendarUsedTime: "used time",
    newCalendarBilling: "Laskutusperuste",
    newCalendarDeleteCareTime: "Delete caretime",
    newCalendarCareTime: "Caretime",
    newCalendarAbsence: "Absence",
    newCalendarLockedTime: "locked time",
    newCalendarCareTimes: "Caretimes",
    newCalendarVacationTimes: "Vacation Periods",
    newCalendarVacationTime: "Vacation",
    newCalendarPeriodTime: "Vacation period",
    newCalendarChildrenToShow: "Children shown",
    newCalendarDefaultTime: "Default caretime",
    newCalendarLoadingCareTimes: "loading caretimes",
    newCalendarSick: "Sick",
    newCalendarAbsent: "Absent",
    newCalendarAbsentReasonSelect: "Absent reason",
    newCalendarSaveAbsence: "Save absence",
    newCalendarSavingAbsence: "Saving absences",
    newCalendarSaveNewAbsence: "Save absence",
    newCalendarSavingAbsenceFailed: "Saving absences failed",
    newCalendarAbsentSaved: "Absences saved",
    newCalendarSaveNewCareTime: "Save new caretime",
    newCalendarSaveNewCareTimes: "Save caretimes",
    newCalendarSavingCareTimes: "Saving caretimes",
    newCalendarSavingCareTimes2: "Saving caretimes",
    newCalendarCareTImesSaved: "Caretimes Saved",
    newCalendarSavingCareTimefailed: "Saving caretimes failed",
    newCalendarSelectChildren: "Select children",
    newCalendarSelectTime: "Select time",
    newCalendarSelectDates: "Select dates",
    newCalendarDays: "days",
    newCaledarWeeks: "weeks",
    newCalendarRange: "range",
    newCalendarDeleteCareTimes: "Delete caretimes",
    newCalendarDeleteCareTimes2: "Delete caretimes",
    newCalendarSavingDefault: "Saving default caretimes",
    newCalendarExistSelected:
      "Selected days have existing reservations, do you want to keep the existing reservations?",
    newCalendarWeekEnds:
      "It is possible to book weekends for one or more children. Do you want to add weekends to the booking?",
    newCalendarSaturdays:
      "It is possible to book saturdays for one or more children. Do you want to add saturdays to the booking?",
    newCalendarSundays:
      "It is possible to book sundays for one or more children. Do you want to add sundays to the booking?",

    newCalendarAbsentWeekEnds:
      "It is possible to add abcences for the weekends for one or more children. Do you want to include weekends when saving abcences?",
    newCalendarAbsentSaturdays:
      "It is possible to add abcences for saturdays for one or more children. Do you want to include saturdays when saving abcences?",
    newCalendarAbsentSundays:
      "It is possible to add abcences for sundays for one or more children. Do you want to include sundays when saving abcences?",
    newCalendarAreYouSureExit: "Are you sure you want to exit?",
    newCalendarEditLoss: "All changes will be lost",
    newCalendarLoadingPrev: "Loading previous caretimes",
    newCalendarLoadingNext: "Loading next caretimes",
    newCalendarWeek: "Week",
    newCalendarClose: "Close",
    newCalendarCancel: "Cancel",
    NewCalendarDelete: "Delete",
    newCalendarExit: "Exit",
    newCalendarSave: "Save",
    NewCalendarYes: "Yes",
    newCalendarNo: "No",
    newCalendarSaveSuccess: "Saved successfully",
    newCalendarSaveFailed: "Saving failed",
    newCalendarTryAgain: "Try again",
    newCalendarDeletingCareTimes: "Deleting caretimes",
    newCalendarDeletingCareTimesFailed: "Deleting caretimes failed",
    newCalendarDeletingCareTimesFailed2: "Deleted successfully",
    newCalendarCareTimesDeletedSuccess: "Caretimes deleted successfully",
    newCalendarCareTimesDeletedSuccess2: "Caretimes deleted",
    newCalendarAreYousureWantToDeleteCaretime:
      "Are you sure you want to delete this caretime",
    newCalendatAutomaticClose: "Window will be closed automatically after",
    newCalendarAfterSeconds: "seconds",
    newCalendarEo: "PS",
    actionNotes: "Notes",
    actionNotesNull: "No notes",

    //kalenteri kellon valinta
    clockType: "Clock Type",
    clockTypeDialog1:
      "As a new feature of the caretime calendar, you can choose the type of clock used to set the time",
    clockTypeDialog2:
      "In the future, you can change your choice from the calendar settings",
    clockTypeSlider: "slider",
    clockTypeDigital: "Digital clock",
    clockTypeStart: "start time",
    clockTypeEnd: "end time",
    saveChoice: "Save selection",
    new: "New",
  },

  sv: {
    awDevelopersInform: "Utvecklare informerar", //TODO
    awClientsInform: "Dagis informerar", //TODO
    awNotAvailableDevTextTrans: "Översättning inte tillgänglig på engelska! ",
    awNewBulletin: " aktiv bulletin", //TODO
    awNewBulletins: " aktiva bulletiner", //TODO
    awMoveToBulletin: "Flytta till anslagstavlan", //TODO
    awNursingTimesLock: "Vårdtiderna stängs", //TODO
    awShowMore: "Visa mer", //TODO
    awUnansConsent: " obesvarat samtycke", //TODO
    awMoveToPerm: "Flytta till Behörigheter & Samtycken", //TODO
    awMove2Messaging: "Flytta till meddelanden", //TODO
    awNotReadMessages: " olästa meddelanden", //TODO
    awNewMessages: "Nya meddelanden!", //TODO
    awAllMarkedAsRead: "Alla markerade som lästa!", //TODO
    awBulletins: "BULLETINER", //TODO
    awAllDone: "Allt klart, fantastiskt!", //TODO

    broSeems: "Det verkar som att DaisyFamily inte stöder din webbläsare.",
    broForAttachments: "för att ladda ner filer",
    broYourBrowserIs: "Din webbläsare är",
    broYourVersionIs: "och din webbläsares version är",
    broDaisyFamilySupports:
      "DaisyFamily stöder dessa webbläsarversioner och nyare versioner än dem",
    broAllSupportedVersions: "Alla webbläsare som stöds",
    broDaisyFamilySupports2:
      "DaisyFamily stöder följande webbläsare och nyare versioner av dem",
    broOtherOperatingSystems:
      "Andra operativsystem: Chrome eller Firefox kan ge den smidigaste användarupplevelsen för DaisyFamily.",
    broUpdateYourBrowserRegularly:
      "Uppdatera din webbläsare regelbundet för förbättrad informationssäkerhet.",
    broUnknown: "Obekant",

    discDiscussionBooking: "Boka en diskussion",
    discChildNoDiscussionsToBeBooked: (childName) => {
      return `Inga nya samtalstider kan bokas för barne ${childName}`;
    },
    discPastDiscussions: "Tidigare samtal",
    discUpcomingDiscussions: "Framtida samtal",
    discChildsPastDiscussions: "Barnets tidigare samtal",
    discChildsUpcomingDiscussions: "Barnets framtida samtaler",
    discTimesCouldntBeLoaaded: "Barnets samtalstider kunde inte laddas",
    discCancelDiscussionTime: "Avboka samaltiden",
    discCancelConfimation: "Vill ni verkligen avboka samtal?",
    discDiscussionTimes: "Samtalstider",
    discTimeType: "Samtaltidens typ:",
    discType: "Samtalstyp",
    discTypeVASU: "VASU",
    discTypeLEOPS: "LEOPS",
    discTypeLapu: "Lapu",
    discTypeMuu: "Annan",
    discSaveConfirmation: "Vill ni spara följande information?",
    discSaveError: (error) => {
      return `Fel ${error}: Samtalsreservation kunde inte sparas.`;
    },
    discBooking: "Tidsbeställning",
    discChooseTime: "Välj en tid",
    discChooseParticipant: "Välj deltagare",
    discTimesAvailable: "Tillgängliga möten",
    discTimesUnabailable: "Inga tillgängliga möten",
    discChildsUpcomingDiscussion: "Kommande samtal",
    discNoActiveBookings: "Inga aktiva samtal resevations",
    discReminder:
      "Kom ihåg att delta i den framtida diskussionen eller avbryt den innan deadline:",
    discInformationFailed: "Barnets samtalstider kunde inte laddas.",
    discYes: "Ja",
    discNo: "Nej",
    discCouldNotBeCanceled: "Samtalstiden kunde inte avbryta",
    discCancelSuccess: "Samtalstiden avbröts",
    discRemoteMeeting: "Fjärrmöte",
    discCopy: "Kopiera",
    discCopied: "Kopierade",
    discCancelableUntil: (date) => {
      return `Kan ställas in till  ${date}`;
    },
    discCancelableUntil2: "Kan ställas in till",
    discInformationGivenByPersonel: "Ytterligare information från personnell",
    discInformationGivenByGuardians:
      "Ytterligare information från vårdnadshavare",
    discNoInformation: "Inga ytterligare information...",
    discNoTimesToBeBooked: (childName) => {
      return `Barn ${childName} finns inga samtalstider`;
    },
    discStopEditing: "Sluta redigera data",
    discSaveChanges: "Spara ändringarna",
    discSomethingWentWrong: "Något gick fel",
    discNewTime: "Ny tid",
    discMakeAnAppointment: "Boka tid",
    discEdit: "Redigera",
    discSubject: "Ämne",
    discAddress: "Address",
    discStaff: "Personal",
    discAlreadyBooked:
      "Samtastid är inte tillgänglig. Boka en annan samtalstid.",
    discMoveToBooking: "Gå till bokning",
    discYouHaveAppointments: "Ni har samtalstider tillgängliga!",
    discAvailableForXDays: (count) => {
      return `Lediga samtasltider påa ${count}`;
    },
    discEditSuccess: "Redigeringen lyckades!",
    discBookingSaved: "Bokning sparad",
    discBookingSaveError: "Reservationen kunde inte sparas",
    discPreBookingConflict:
      " Någon annan lyckades boka tid. Välj en annan tid.",
    discPreBookingError: "Det uppstod ett problem med att spara förbokningen",
    discLeftOfPrebooking: (x, y) => {
      return `${x} minuter ${y} sekunder kvar bokning tid`;
    },
    discPrebookingTimeRanOut: "Förbokning tid slutade.",
    discPrebookingMoreTime: "Mer tid?",

    monday: "måndag",
    tuesday: "tisdag",
    wednesday: "onsdag",
    thursday: "torsdag",
    friday: "fredag",
    saturday: "lördag",
    sunday: "söndag",

    selectall: "Välj alla",
    selectallRemove: "Ta bort alla val",

    permDialogNotSavedText:
      "Ändringarna har inte sparats. Osparade ändringar kommer att gå förlorade.", //TODO
    permUnansweredPerms: "obesvarat tillstånd!", //TODO vastaamatonta lupaa!
    permPermissionQueries: "Tillståndsfrågor",
    permPQTitle: "Titel",
    permAnswersWereSaved: "Svaren sparades",
    permNoData: "Inga data",
    permPermSavingFailed: "Tillståndssparande misslyckades",
    permOneOrMoreSavesFailed: "En eller flera svaren misslyckades",
    permBackTo: "Tillbacka till tillståndsfrågor",
    permLastDayToAnswer: "Den sista svarsdatum",
    permCannotAnswerAnymore: "Frågor kan inte längre besvaras.",
    permToPermissions: "Gå till val här",
    permOneOrMoreChildrenNoAnswer: "Ett eller flera barn har inget svar.",
    permAnswerAllChildren: "Svar för alla barn",
    permRefresh: "Uppdatera listan", //N.B: this is also used next to refresh icon for refresh the message list
    permSender: "Avsändare",
    permIGivePermission: "Jag ger tillstånd",

    accAccessibilityStatement: "Tillgänglighetsutlåtande",
    accHeader1: "Tillgänglighet",
    accHeader2a: "Tillgänglighetsutlåtande för webbplatsen daisyfamily.fi",
    accPara1:
      "Det här tillgänglighetsutl åtandet gäller webbplatsen daisyfamily.fi (kunta.daisyfamily.fi). Utlåndet har sammanställts enligt situationen 23.3.2021 och det har senast uppdaterats 23.3.2021.",
    accPara2:
      "Webbplatsens tillgänglighet har bedömts hos DaisyFamily-produktutvecklingsteamet.",
    accHeader2b: "Alternative sätt att sköta ärenden",
    accPara3:
      "Om du inte kan sköta ditt ärende på nätet kan du kontakta din dagliga personal eller skicka ett e-postmeddelande till oss på ",
    accPara4: "saavutettavuus@daisyvarhaiskasvatus.fi",
    accHeader2c: "Hur tillgänglig är webbplatsen?",
    accPara5:
      "Tjänsten uppfyller tillgänglighetskraven delvis (WCAG-kriterierna på nivåerna 2.1, A och AA).",
    accHeader3a: "Innehåll som inte är tillgängligt",
    accPara6:
      "Användarna kan fortsättningvis stöta på vissa tillgänglighetsproblem på webbplatsen. I det följande finns en lista på de problem som vi är medvetna om. Vänlingen meddela oss om du upptäcker ett problem på webbplatsen som inte nämns i listan: ",
    accPara7: "saavutettavuus@daisyvarhaiskasvatus.fi",
    accPara8:
      "Innehåll och funktioner som än så länge inte är helt tillgängliga:",
    accUL1a:
      "Det finns vissa brister vad gäller färgkonstrater t.ext. länkstigar(WCAG 1.4.3)",
    accUL1b:
      "Rubriknivåerna löper inte i hierarkisk ordning på alla sidor (WCAG 1.3.1)",
    accUL1c:
      "Allt visuellt innehåll har inte någon textmotsvarighet som förklarar vad en bild eller ett grafiskt element föreställer (WCAG 1.1.1)",
    accUL1d:
      "Tjänsterna kan inte smidigt användas med skärmläsare eller tangentbord. (WCAG 4.1 och 2.1.1)",

    accHeader3b: "Kraven på tillgänglighet gäller inte visa slag av innehåll",
    accPara9:
      "I det följande anges en lista på sådant innehåll på våra webbsidor som inte omfattas av tillgänglighetslagtiftningen.",

    accUL2a:
      "Innehåll som används väl i en begränsad grupp under en begränsad tid",
    accUL2b:
      "Innehåll som publiceras på tjänsten av användare eller andra tredje parter som inte produceras, finansieras eller kontrolleras av tjänsteleverantören själv",
    accUL2c:
      "Tillgänglighetskraven gäller inte innehåll som arkiverats före 23.9.2019",

    accHeader2d: "Ge respons om tillgängligheten",
    accPara10:
      "Upptäckte du någon sådan brist I vår tjänst som gäller tillgängligheten? Berätta det för oss så gör vi vårt bästa för att åtgärda bristerna.",
    accPara11: "Ge respons om via e-post till",
    accPara12:
      "Tillgänglighetsrespons till DaisyFamily tas emot av den tekniska supporten.",

    accHeader2e: "Tillsyn över tillgängligheten",
    accPara13:
      "Om du upptäcker problem som gäller tillgängligheten ska du först ge oss respons på saken. Vi svarar inom två (2) veckor.",
    accPara14:
      "Om du inte är nöjd med det svar du har fått eller inte får något svar alls inom två (2) veckor kan du lämna in en anmälan om bristande tillgängenlighet till Regionförvaltningverket i Södra Finland. På regionförvaltningsverkets webbplats finns det beskrivet hur du kan lämna in en anmälan och hur ärendet behandlas.",

    accHeader3c: "Tillsynsmyndighetens kontaktuppgifter",
    accPara15: "Regionförvaltningsverket i Södra Finland",
    accPara16: "Enheten för tillgänglighetstillsyn",
    accPara17: "Telefonnummer växeln",

    accHeader2f: "Vi arbetar kontinuerligt för att förbätta tillgängligheten",
    accPara18:
      "Vi uppdaterar detta tillgänglighetsutl åtande efter hand som vi rättar till brister. Under 2021 kommer vi att arbeta för att åtgärda de identifierade bristerna.",
    accPara19:
      "Vi har förtbundit oss till att förbättra tillgängligheten i våra tjänster på nätet. Vi säkrar tillgängligheten genom bland annat följande åtgärder:",

    accUL3a:
      "Vi beaktar kraven på tillgänglighet när vi utvecklar våra tjänster",
    accUL3b:
      "På våra webbsidor erbjuder vi information även på lätt finska, lätt svenska och lätt engelska",
    accUL3c: "Vi erbjuder våra anställda utbildning i tillgänglighet",
    accPara20: "Read more",
    accPara21:
      "Saavutettavuusvaatimukset.fi (Accessibility statement for the e-service for customers (in Finnish))",
    accPara22: "Act on the Provision of Digital Services 306/2019 (finlex.fi)",
    accPara23: "EU’s Accessibility Directive (eur-lex.europa.eu)",

    cookieHeader1: "Cookies",
    cookieHeader2a: "Cookies på vår webbplats",
    cookiePara1:
      "När du besöker vår webbplats (https://kommun.daisyfamily.fi) lagrar vår webbplats cookies (inkl. Lokal lagring och sessionslagring), dvs. små textfiler, på den enhet du använder. Cookies kan inte användas för att skada din enhet eller sprida skadlig kod.",
    cookiePara2:
      "Vår webbplats använder endast nödvändiga tekniska cookies, vars syfte är att möjliggöra en säker, smidig och lämplig användning av webbplatsen. Vi använder till exempel cookies för att logga in på webbplatsen och lagra ditt språkval.",
    cookiePara3:
      "Det är möjligt att inaktivera tekniska cookies i inställningarna för webbläsaren du använder. Vi vill dock påpeka att inaktivera alla cookies hindrar dig från att logga in på vår webbplats. Att radera din cookiehistorik är möjlig via webbläsarens cookieinställningar och hindrar dig inte från att logga in på webbplatsen.",
    cookiePara4:
      "Varje webbläsare har olika sätt och inställningar för hantering av cookies. Du kan läsa webbläsarens cookieinställningar på webbläsarens egen webbplats.",
    cookies: "Cookies",

    allowed: "Tillåten",
    newNotifications: "Nya meddelanden",
    allowEmailNotificationsExplanation:
      "Ni kommer att få meddelanden via e-post när ni får nya ansökningar, meddelanden eller meddelanden om ditt barn.",
    allowPushNotificationsExplanation:
      "You will receive notifications on your mobile for new applications, messages and comments regarding your child. Note! To receive notifications you install DaisyFamily app to you mobile device!", //TODO: translate
    allowViewLabel: "Visa information till andra vårdnadshavare",
    allowViewModalTitle: "Synlighet för andra vårdnadshavare",
    allowViewModalYesText:
      "Låt andra vårdnadshavare av barnet se och ändra din kontaktinformation",
    allowViewModalNoText:
      "Din kontaktinformation kommer att döljas för andra vårdnadshavare",
    bbTitle: "Anslagstavla",
    bbHeaderText: "Här ser ni meddelanden från dagis.",
    bbSlideViewNoMessages: "Inga meddelanden",
    bbGroup: "Grupp",
    bbCentreShortText: "Dagis",
    bbValidUntilBBCard: "Giltigt till {0}",
    bookingShowTimes: "Visa tider",
    bookingAddBookingTitle: "Lägg till reservation",
    bookingAddBookingInfo:
      "Lägg till en reservation\nNi håller på att lägga till en bokning för de valda dagarna.\nVill ni göra helt nya bokningar (1 eller fler) eller behålla befintliga och lägga till?",
    bookingAddBookingLeftBtn: "NYA",
    bookingAddBookingRightBtn: "BEHÅLLA",
    bookingAddBookingAndKeepPrevTitle: "Information för de valda dagarna",
    bookingCareHoursTitle: "Vårdtider",
    bookingCareHoursTitle2: "Bokningskalender",
    bookingCareHoursDefaultTitle:
      "Standard vårdtid som skall användas som grund",
    bookingCareHoursDefaultUse: "Standard vårdtid som ska användas: 8-16", //tarkista (miten?? ruotsinnos itsessään on ok)
    bookingCareHoursDefaultMissingInfo:
      "Ingen stardardtid har ställts in, 8-16 användas automatiskt. \n Ni kan spara en standardtid via clock-knappen i hörnet nere till vänster.",
    bookingCareHoursDefaultSave: "Spara",
    bookingCareHoursSaveInformation: "spara informationen?",
    bookingCareHoursMissing: "Inte reserverad vårdtider", //todo Antti
    bookingCareHoursMissingTimeSpan:
      "Vårtidsbokningar eller frånvaron har inte meddelats för perioden som låses:",
    bookingChoosePlacement: "Välj placering",
    bookingDataSaving: "Data sparas",
    bookingDeleteBookingTitle: "Ta bort reservation",
    bookingDeleteBookingInfo:
      "Ni håller på att ta bort bokning(ar) under de valda dagarna.\nÄr ni säker på att ni vill ta bort er bokning(ar)?",
    bookingDeleteBookingLeftBtn: "ABRYT",
    bookingDeleteBookingRightBtn: "TA BORT",
    bookingDetailDelBookingTitle: "Ta bort anteckningar",
    bookingDetailDelBookingInfo:
      "Är ni säker på att ni vill ta bort anteckningen?",
    bookingDetalDelBookingInfoMultiple:
      "Vill du verkligen ta bort frånvaron (ABRYT)?\nVill du ta bort frånvaron från alla placeringar (TA BORT ALLA)?",
    bookingDeleteBookingAll: "TA BORT ALLA",
    bookingsLocked: "Bokning låst",
    bookingBookingsDeleted: "Bokningarna raderades",
    bookingAllCouldNOtBeDeleted: "Inte alla bokningar kunde raderas",
    bookingErrorDeleteDetails: "\nFel vid radering:\n",
    bookingErrorSaving: "\n Ett fel inträffade vid förvaring:\n",
    bookingErrorSavingDetailed: "\n Fel inträffade vid förvaring:\n",
    bookingBookingsSaved: "Bokningar sparade",
    bookingAddMoreBtn: "Lägg till en ny bokning",
    bookingErrorNoValidPlacement:
      "Bokningar/frånvaro har blockerats eftersom det inte finns någon giltig placeringarna.",
    bookingYoureEditingFollowing: "Du redigerar följande bokningar", // todo
    bookingErrorBadRequest: "Det gick inte att spara. Överlappande bokningar!",
    bookingNextBooked: "Nästa upptagen vårdtid",
    bookingMake: "Boka vårdtider",
    bookingSavingAbsenceFailedPartially:
      "Alla frånvaro kunde inte sparas, kolla bokningar!",
    bookingSavingFailedPartially:
      "Alla bokningar kunde inte sparas, kolla bokningar!",
    bookingSavingFailedPartially2:
      "Alla bokningar kan inte sparas på grund av placering i: ",
    bookingType: "Bokningstyp",
    bookingTypeAbsent: "Frånvarande",
    bookingTypeHoliday: "Ledig",
    bookingTypeNT: "Vårdtid",
    bookingTypeSick: "Sjuk",
    bookingTypeSNT: "Skiftesvård",
    child: "Barn",
    childFiles: "Bilagor tillhörande barnet",
    ChildrenTalkMethodUsed1: "'Föra barnen på tal'",
    ChildrenTalkMethodUsed2: "-samtal utfört",
    ci_CentreName: "Daghem",
    ci_CentreAddress: "Daghemmets adress",
    ci_CentrePhone: "Daghemmets telefonnummer",
    ci_ManagerName: "Daghemsföreståndare",
    ci_OpenType: "Typ av öppethållningstid",
    ci_OpenTimes: "Öppethållningstid / Öppet",
    ci_GroupName: "Barnsgrupps namn",
    ci_GroupPhone: "Barnsgrupps telefonnummer",
    ci_placementGoodUntil: "Placeringes giltighet",
    choosePicturesToDownload: "Välj de bilder du vill ladda ner",
    placementInformation: "Placerings information",
    placementDayCareCentre: "Daghemmets information",
    menuPlacementInformation: "Placerings information",
    ciAddressCity: "Stad",
    ciAddressPostalcode: "Postnummer",
    ciAddressStreet: "Gatan",
    ciAllergies: "Allergier",
    ciBackupPersons: "Reservpersoner",
    ciBackupPersonsNone: "Inga reservpersoner har lagts till i barnet",
    ciBackupPersonRemovalSuccess: "Borttagning lyckades",
    ciBackupPersonRemovalFail: "Borttagning misslyckades",
    ciBackupPersonRemoveConfirmation: "Ta bort reservperson?",
    ciDeleteBackupParent: "Ta bort reservperson",
    ciDeleteYes: "Ja",
    ciDeleteNo: "Nej",
    ciBasicInfo: "Grunduppgifter",
    ciContactInformation: "Kontaktuppgifter",
    ciInfo1:
      "Telefonnumret måste vara mellan 7 och 12 siffror långt.\nOch formatet kan vara +358 44 1234567 eller 044 1234567.\nMellanslagna är tillåtna.",
    ciName: "Namn",
    ciFirstName: "Förnamn",
    ciLastName: "Efternamn",
    ciNoNumber: "Ingen nummer",
    ciShortEmailAddress: "@post",
    ciShortPhoneNumber: "Tel.num.",
    ciShortPrimaryNumber: "Primär telefonnummer",
    ciShortWorkNumber: "Arbets num.",
    ciAlbum: "Fotoalbum",
    ciAlbumNote:
      "Fotoalbumerna finns nu bara på DaisyNet. Dessa skall tillsättas DaisyFamily betaversion",
    clChildren: "Barnen",
    clChildrenNotFound: "Inga barn hittades",
    clChildrenLoadingData: "Barn data nedladdning",
    cookieMoreInfo: "Mer information",
    cookieNote:
      "Genom att fortsätta använda DaisyFamily accepterar du användningen av cookies.",
    downloadMedia: "Ladda ner",
    emailNotifications: "E-postaviseringar",
    oldMessagingSetting: "Visa den gamla meddelande implementeringen",
    oldMessages: "gamla meddelanden ",
    newMessages: "nya meddelanden",
    emptyFolder: "Mappen är tom",
    emptyGrowthFolder: "Dokumentationsmappen är tom",
    errorBackend: "Fel i backend. Försök igen om några ögönblick.",
    errorBackendContactSupport:
      "Fel i backend. Kontakta kommunal Daisy-administratör.",
    errorBadRequest: "Ogiltigt sökkriterium",
    errorCode: "Felkod",
    error: "Fel",
    errorChangingFailed: "Det gick inte att spara på servern",
    errorCityName: "Ogiltigt stadsnamn",
    errorCommuneNotAvailable: "Stedsnamnet är inte tillgängligt",
    errorCommuneNotFound: "Staten finns inte",
    errorDataCouldNotBeDownloaded: "Data kunde inte laddas",
    errorDelBooking:
      "Vårdtid reservation / frånvaro anteckning ligger inom låstid, och därför inte kan tas bort.",
    errorInDB: "Databasfel",
    errorInformationMissing: "Information saknas",
    errorInLogin: "Fel användarnamn och /\n eller lösenord!",
    errorLoadImage: "Bildöverföringen misslyckades",
    errorLoadImageFolders: "Fel vid inläsning av bildmapp",
    errorLoadImages: "Fel vid inläsning av bilder",
    errorLockedDaysOnly:
      "Du har valt en eller flera låsta dagar och kan inte välja dagar som inte är låsta samtidigt.",
    errorLoginOptionsNotFound:
      "Inloggningsinställningarna för tjänsten finns inte.\nKontakta din tjänsteleverantör",
    errorMessageSending:
      "Det går inte att skicka meddelandet till servern. Kopiera innehållet i meddelandet och försök igen senare.",
    errorMsgProbablyNotSent:
      "Det var ett fel i skicka meddelandet. Det här meddelandet kommer sannolikt inte att skickas. Kopiera innehållet i meddelandet och försök igen senare.",
    errorNetwork: "Nätverksfel",
    errorNotFound: "Data inte hittades!",
    errorNoPlacements: "Inga giltiga placeringar",
    errorNTsummary:
      "Det gick inte att ladda tillgänglig information om vårdtiden. Försök igen senare.",
    errorNameCantBeEmpty: "Personens namn får inte vara tomt",
    errorPostalCode: "Ogiltigt postnummer",
    errorSendingFailed: "Sändning misslyckades",
    errorStreetAddress: "Ogiltigt adress",
    errorTimesInvalid: "Vårdtiderna är felaktiga",
    errorUnauthorizedNoActiveChilds:
      "Tillträde till DaisyFamily nekat, inga aktiva barnen.",
    errorUnknown: "Ett okänt fel",
    errorUnLockedDaysOnly:
      "Du kan inte välja låsta dagar samtidigt med olåsta dagar.",
    errorWithMsgSending: "Fel vid meddelandesändning",
    errorEsuomiLoginNoChild: "Inga barn hittades",
    errorEsuomiLoginNoActive: "Användärkonto har slutat",
    errorEsuomiLoginUserNotFound: "Användarkonto hittades inte",
    errorEsuomiLoginUnknown: "Ett ovantat fel vid inlogging",
    errorEsuomiLogin: "Fel vid inlogging",
    errorEsuomiClientCancel: "Inloggningen till tjänsten har avbrutits.",
    errorEsuomiAuthFail:
      "Identifieringen misslyckades (med valde identifieringenmetod)",
    errorEmailWrongFormat: "Sähköposti osoite väärää muotoa",
    fileModalHeader: "Fil", //TODO
    fileEmptyDesc: "Ingen beskrivning finns tillgänglig.", //TODO
    fileTypeWrong:
      "Filtypen stöds inte eller filen är skadad. Du kan stöta på problem när du laddar ner eller förhandsgranskar filen.", //TODO
    fileUploaded: "Uppladdad", //TODO
    fileDescription: "Fil Beskrivning",
    fileFileName: "Filnamn",
    fileSaved: "Sparad",
    fileType: "Filtyp",
    generalOpen: "Öppen",
    generalOpenText:
      "Öppna filen i din webbläsare. Popup-fönster måste vara aktiverat i din webbläsarinställningar.", //TODO
    generalBack: "Tillbaka",
    generalClose: "Stäng",
    generalOkBtn: "OK",
    generalDataSaved: "Data sparade",
    generalDownloadFileAttachment: "Ladda ner bilagan",
    generalDownloadFileAttachment2: "Ladda ner filen till din enhet",
    generalDownloadImage: "Ladda ner bilden",
    generalPassword: "Lösenord",
    generalLoadingTitle: "Laddar",
    generalPasswordExpiringTitle: "Lösenord löper ut",
    generalPasswordExpiringWarning:
      "Lösenordet löper ut {0}. Vill ni byta lösenordet nu? Om lösenordet är föråldrad, ni måste ta kontakt till daghemmet",
    generalSaveOrCancel: "Form har ändrats. Spara eller avbryt ändringen först",
    generalSaveModifications: "avbryt ändringen?",
    generalWeek: "Vecka",
    generalName: "Namn",
    generalDate: "Datum",
    generalStarts: "Start",
    generalEnds: "Ända",

    generalNotifications: "Meddelanden",
    generalAreYouSure2Leave: "Är du säker på att du vill lämna?", //TODO
    generalInfo: "Informationer", //TODO
    generalLastModified: "Senast redigerad",
    generalQuestionsForParents: "Frågor till föräldrar",
    formChange: "Formändring",
    logged: "Formuläret låst i läsläge",
    loginBtn: "Logga in",
    loginCapsLockAlert: "Caps Lock på!",
    loginGeneralInfo: "Vänligen logga in!",
    loginHere: "Logga in här",
    loginLoadingData: "Laddar data",
    loginOptionsInfo: "Välj inloggningsmetoden",
    loginProcessingInfo: "Logga in",
    loginUserName: "Användarnamn",
    loginUsernameAndPassword: "Användarnamn och lösenord",
    loginPasswordMustBeChanged:
      'Ni måste byta lösenord. Clicka "OK" för att förandra lösenordet.',
    loginPasswordChangeTitle: "Byt lösenord",
    logoutAutoTitle: "Automatisk utcheckning",
    logoutAutoInfo:
      "Inga funktioner.\nAutomatisk utcheckning inom {0} minuter {1} sekunder",
    logoutAutoSessionOldInfo:
      "Sessionen löpte. Klicka på OK för att logga in igen.",
    logoutBtn: "Logga ut",
    logoutEsuomiSSOTitle: "eSuomi SSO Logout", //todo
    logoutEsuomiSSOBody:
      "Logga ut från eSuomi service. Klicka OK för att försatta.", //todo pitääkö tämän olla passiivissa?
    logoutEsuomiBtn: "Logga ut från eSuomi",
    menuChildren: "Barnen",
    menuHome: "Hem",
    menuLogout: "Logga ut",
    menuMessaging: "Meddelanden",
    menuNotifyAbsence: "Dagens frånvaro", //TODO
    menuNotifyAbsenceToday: "Dagens frånvaro", //TODO
    menuSettings: "Inställningar",
    msgWishToRemoveThread: "Vill ni radera tråden från Inbox",
    msgYesRemove: "Ja, radera",
    msgCancel: "Nej, angrå",
    msgChooseChild: "Välj barnet",
    msgChooseRecipients: "Välj mottagare",
    msgWriteAndSend: "Skriva och skicka",
    msgGoToMessage: "Gå till tråd",
    msgDeleteThread: "Radera tråden från Inbox",
    msgThreadRemovalNotSuccessful: "Trådborttagning från inbox lyckades inte",
    msgThreadRemovalSucceeded: "Trådborttagning från inbox lyckades",
    msgRemovingThreadFromInbox: "Tar bort tråd från Inbox",
    msgCaretakers: "Vårdnadshavaren",
    msgCharacterLimitWarning:
      "Se till att meddelandet inte är längre än 5000 tecken.",
    msgCouldNotLoadRecipientsFromServer:
      "Mottagarinformationen kunde inte hämtas från servern.",
    msgDenyReply:
      "Avsändaren har valt att svara är inaktiverat för den här meddelandetråden",
    msgGroupsAndDaycares: "Grupper och daghemmen",
    msgInboxTitle: "Meddelanden",
    msgIncludeStaffTitle: "Inkludera minst en anställd eller grupp",
    msgIncludeStaffBody:
      "Minst en anställd eller grupp krävs för att skicka meddelandet",
    msgInfo1:
      "Välj endast en enskild anställd som mottagare om det finns\nen specifik anledning till det. Personliga meddelanden läses\nsällan och når inte direkt anställda i daghemmet.",
    msgInfo2:
      "Välj en grupp eller dagis som mottagare när du vill att\nmeddelandet ska nå barnomsorgsarbetaren så snabbt och\npålitligt som möjligt. Gruppmeddelanden kommer att\nvidarebefordras till grupps telefon (rekommenderas).",
    msgLoadingThread: "Laddar meddelandetråd",
    msgMarkAllAsRead: "Markera alla meddelanden som lästa",
    msgMarkAllAsReadShort: "Markera som lästa", //todo
    msgMarkAllAsReadConfirmation:
      "Är du säker på att du vill markera alla meddelanden som lästa?",
    msgMarkMessagesSuccess: "Meddelanden markerades som lästa",
    msgMarkMessagesFailed: "Det gick inte att markera meddelanden som lästa",
    msgMe: "Jag",
    msgNewMsgBodyInfo: "Skriv ett meddelande",
    msgMessageNew: "Nytt meddelande",
    msgCouldNotBeLoaded: "Meddelanderna kunde inte ladda från servern",
    msgNewMsgTitleInfo: "Meddelandets ämne",
    msgNewMsgThreadTitle: "Tråd rubrik",
    msgAddrecipientshere: "Ändra mottagare",
    msgAttachment1singular: "Det finns",
    msgAttachment2singular: "bifogad fil, ni kan ladda det under",
    msgAttachment1: "De finns",
    msgAttachment2: "bifogad fila, ni kan ladda dessa under",
    msgRecipients: "Mottagare",
    msgSending: "Skickar meddelande",
    msgSentWaitingForServer: "Skickar meddelande, väntar på svar från servern",
    msgTitleTooLong: "Meddelandets ämne övervakads teckenbegränsning",
    msgyouHaveRemovedThisMessage: "Ni har tagit bort detta meddelande",
    msgNoContent: "Meddelandet har inget innehåll.",
    msgNoMessagesInfo: "Inga meddelanden",
    msgNumber: "St.", // lyhyt sana/lyhenne kuvaamaan sitä, että tätä seuraava numero kertoo, kuinka monta viestiä viestiketjussa on
    msgOthers: "andra",
    msgParticipants: "deltagarna",
    msgReplyToMessageThread: "Svara på tråden",
    newest: "Nyaste",
    notAllowed: "Inte tillåtet",
    notAllowEmailNotificationsExplanation:
      "Ni kommer inte att få e-postmeddelanden om nya ansökningar, meddelanden eller meddelanden om ditt barn.",
    notAllowPushNotificationsExplanation:
      "You won't receive push notifications to your mobile about new messages and comments regarding your child.", //TODO: translate
    noteGoToBookings: "Gå till vårdtider",
    noFilesToShow: "Inga filor att visa",
    noReservedNursingTimes: "Inga reserverade vårdtider",
    oldest: "Äldsta",

    passwordsMatch: "Lösenorden matchar",
    passwordTenChars: "Minst 10 tecken",
    passwordOneCapital: "Minst 1 stor bokstav",
    passwordOneNumber: "Minst 1 nummer",

    //perms and consents
    noAnswer: "Inte besvarad", //TODO
    permsPermitsGiven: "Utfärdade tillstånd:", //"Annetut luvat:" //TODO
    permAnswersNotSaved: "Frågorna har inte sparat!",
    permAnswerAllQuestions: "Svara till varje frågan innan ni sparar",
    permSaveConsents: "Spara samtyckerna",
    permSaving: "Sparar",
    permSaved: "Sparat!",
    permParentConsentSaved: "Samtyckerna är sparat!",
    permChildPermPermsSaved: "Barns tillstånd sparat",
    permSavingFailed: "Spara misslyckades, försoka senare igen. Fel:",
    permYes: "Ja",
    permNo: "Nej",
    permQuestionName: "Namnet på frågan",
    permPermissionQuestionsAria: "frågor-om-tillstånd",
    permGuardiansConsents: "Vårdnadshavarens samtycker",
    permGuardianPermQPanel: "vårdnadshavarens-samtycker-sida",
    permChildrenPermissions: "Barns tillstånd",
    permEachChildPage: "Barns tillstånd finns på varje barns egen sida",
    permUnansweredPermissions: "Obesvarad tillstånd",
    permNoUnansweredPermissions: "Inga obesvarade tillstånd",
    permInfoNeedsUpdating: "Din information behöver uppdatering!",
    permNeedConsent: "Småbarnspedagogik tjänstenrna behöver dina samtycker",
    permGoToPermissions: "Gå till samtycker",
    permSaveChildPermissions: "Spara barnets tillstånd",
    permMunicipalPermissionQuestions: "Barns tillstånd",
    permNoQuestionsToShow: "Inga frågor att visa",
    permissionQuestionsGen: "Tillstånd och samtycker", // pitkäaikaiset, lyhytaikaiset ja huoltajan suostumukset
    permissions: "Tillstånd",

    pushNotifications: "Pushmeddelanden",
    pdGrowthFolderTitle: "Pedagogisk dokumentation",
    retry: "Försök igen",
    taskFolder: "Aktivitetsmapp",
    settingEmptyInformation: "Radera data",
    settingEmptyInformationInfo:
      "Du kan helt radera applikationinformation här (kommunala data, inloggningsuppgifter)",
    settingLanguagesTitle: "Språk",
    settingLoginTitle: "Inloggning",
    settingLoginInfo:
      "Inloggning till applikation kräver alltid stark identifiering.\n(Bank-ID, mobil-ID)",
    settingLoginSettings: "Logga in inställningar",
    settingPasswordChangeOK: "Lösenords ändring lyckades",
    settingPasswordChangeFAIL: "Lösenords ändring misslyckades",
    settingPasswordChangeInvalid:
      "Nytt lösenord måste vara minst tio tecken långt,\n och innehålla minst ett nummer\noch en stor bokstav", //todo
    settingPasswordDoesntMatch: "De lösenord du angav matchar inte!",
    settingPasswordNew: "Nytt lösenord",
    settingPasswordNewAgain: "Nytt lösenord igen",
    settingNewUserNote:
      "Var så god och ange ett lösenord för ditt anvärdarnamn. I framtiden kan du logga in med ett användarnamn / lösenord eller genom stark autentisering.",

    termsHeaderMain: "Användarvillkor för onlinetjänsten DaisyFamily",
    termsHeader1: "1. Allmänt om användning av onlinetjänsten",
    termsHeader2: "2. Tjänsteleverantör",
    termsHeader3: "3. Behandling av personuppgifter",
    termsHeader4: "4. Kakor (cookies)",
    termsHeader5: "5. Användning av Tjänsten",
    termsHeader5sub1:
      "5.1 Rättigheter och inskränkningar som rör användningen av Tjänsten",
    termsHeader5sub2: "5.2 Ändringar i Tjänsten",
    termsHeader5sub3: "5.3 Registrering",
    termsHeader6:
      "6. Tjänstens länkar till externa webbplatser och externa länkar till Tjänsten",
    termsHeader7: "7. Immaterialrättigheter",
    termsHeader8: "8. Ansvarsbegränsningar",
    termsHeader9: "9. Ändringar i Villkoren och meddelanden om dessa",
    termsHeader10: "10. Tillämplig lagstiftning och tvistlösning",

    termsPara1:
      "Välkommen till WhileOnTheMove Oy:s (i fortsättningen ”WOM”) onlinetjänst (i fortsättningen ”Tjänst”). Användaren rekommenderas att noggrant bekanta sig med dessa användarvillkor (i fortsättningen ”Villkor”) före användning av Tjänsten.",
    termsPara2:
      "Dessa Villkor kan tillämpas i alla Tjänstens funktioner oavsett vilken huvudenhet som används för Tjänsten. Användaren får endast nyttja Tjänsten enligt aktuella, gällande Villkor samt enligt tillämplig lagstiftning och god sed. Tjänsten kan i övrigt innehålla avsnitt eller funktioner där  användningen förutom dessa Villkor även förutsätter särskilda villkor och anvisningar som anges separat i Tjänsten. Användningen av dessa avsnitt eller funktioner kan kräva att man registrerar sig. Det är möjligt att en del av Tjänstens innehåll kan vara tillgängligt enbart för dem som är bosatta inom vissa geografiska områden.",
    termsPara3:
      "Villkoren utgör ett avtal mellan användaren och WOM och specificerar rättigheter och skyldigheter beträffande användare och WOM:s Tjänst.",
    termsPara4:
      "Användning av Tjänsten är ett tecken på att användaren godkänner aktuella, gällande Villkor och förbinder sig att följa dem jämte separat angivna villkor och anvisningar i Tjänsten.",
    termsPara5: "FO-nummer:",
    termsPara6: "Telefon:",
    termsPara7:
      "WOM behandlar personuppgifter som rör användaren och som erhålls via Tjänsten i enlighet med vad som tillåts och åläggs i den personuppgiftslagstiftning som gäller vid tidpunkten i fråga. Behandlingen av personuppgifter beskrivs närmare i dataskyddsbeskrivningen för  registret som är baserat på WOM:s kundförhållanden och övriga sakliga anknytningar.",
    termsPara8:
      "När du besöker vår webbplats (https://kunta.daisyfamily.fi), sparar den kakor (inkl. local och session storage), dvs. små textfiler i den enhet du använder. Kakorna kan inte användas till att skada din enhet eller till att sprida skadlig programvara (malware).",
    termsPara9:
      "Vår webbplats använder endast nödvändiga tekniska kakor vars syfte är att möjliggöra en trygg, smidig och ändamålsenlig användning av webbplatsen. Exempelvis utnyttjar vi kakor när du registrerar dig på vår webbplats och i samband med att ditt språkval ska sparas.",
    termsPara10:
      "Det är möjligt att inaktivera tekniska kakor via webbläsarinställningarna. Vi vill ändå påpeka att det i så fall inte går att registrera sig på vår webbplats. Du kan radera din kakhistorik via din webbläsares kakinställningar. Detta förhindrar inte att du registrerar dig på vår webbplats.",
    termsPara11:
      "WOM beviljar användaren en begränsad, återkallelig, icke exklusiv och personlig rätt att använda Tjänsten och dess innehåll, enbart enligt vad som specificeras i dessa Villkor och i eventuellt separat angivna villkor och anvisningar i Tjänsten. Användaren har rätt att använda Tjänsten och dess innehåll endast för eget, icke kommersiellt bruk.",
    termsPara12:
      "Användaren får inte ändra eller kopiera Tjänsten eller dess innehåll, alternativt någon del av dem. Användaren har inte heller rätt att dela, distribuera, förmedla, offentligt framföra eller på annat sätt hålla hela eller en del av Tjänsten eller innehållet tillgängliga för allmänheten, såvida inte tillstånd uttryckligen har getts för detta i dessa Villkor.",
    termsPara13:
      "Användaren har rätt att för eget, privat bruk skriva ut eller kopiera innehållet i Tjänsten och att spara en kopia på sin huvudsakliga enhet. Användaren ska spara Tjänstens meddelanden om upphovsrätt och övriga rättigheter på alla sådana utskrifter och kopior.",
    termsPara14:
      "Användaren får inte avbryta eller försöka avbryta eller på något annat sätt störa eller försöka störa Tjänstens funktioner eller andra användares möjlighet att använda Tjänsten. Det är till exempel förbjudet att ladda upp tidsinställd skadlig programvara, virus eller annat material som kan påverka Tjänstens programuppbyggnad.",
    termsPara15: "Användaren får inte använda Tjänsten",
    termsPara16: "för att utge sig vara representant för WOM,",
    termsPara17:
      "till rubrik- och identifieringsinformationsförfalskning eller hantering på ett sätt som avser att dölja ursprunget för innehåll som förmedlats via Tjänsten,",
    termsPara18: "för att på annat sätt ge  falsk information,",
    termsPara19: "för att lagra och samla in användarnas personuppgifter,",
    termsPara20:
      "till att förmedla skräppost eller annan otillåten datakommunikation,",
    termsPara21: "till aktiviteter som strider mot lag eller god sed.",
    termsPara22:
      "Tjänsten med innehåll erbjuds i befintligt skick. WOM har rätt att när som helst redigera eller ändra Tjänsten eller dess innehåll, utan förvarning eller ersättningsskyldighet. WOM har därmed rätt att bl.a. redigera, radera eller utvidga innehållet i Tjänsten samt att göra om kostnadsfria avsnitt till en avgiftsbelagd tjänst eller tvärtom. WOM har även rätt att när som helst helt avbryta eller upphöra med Tjänsten respektive dess innehåll eller tillhandahållandet av något avsnitt, alternativt begränsa användningen av Tjänsten eller dess olika delar. Om användaren har handlat i strid med dessa Villkor, Tjänstens särskilt angivna villkor eller anvisningar respektive lagar och god sed, har WOM rätt att förhindra användarens tillgång till Tjänsten och förbjuda användning av Tjänsten i fortsättningen.",
    termsPara23:
      "I vissa delar av Tjänsten kan användaren behöva registrera sig för att få behörighet att använda Tjänsten, enligt tidigare beskrivning av behörighet. Rätten att använda avsnitt som kräver registrering kan avsnittsvis avgränsas till enbart fysiska eller juridiska personer.",
    termsPara24:
      "Vid registrering för att använda Tjänsten ska användaren lämna korrekta, erforderliga uppgifter om sig själv. Det är förbjudet att ge felaktiga uppgifter och detta kan leda till lagliga åtgärder.",
    termsPara25:
      "Vid registrering erhåller användaren ett användarnamn och får själv välja ett lösenord. Användaren är ansvarig för att förvara och hemlighålla användarnamnet och lösenordet ordentligt och hr ansvar för alla aktiviteter i Tjänsten som är kopplade till användarnamnet. Användaren ska omedelbart kontakta WOM om användaren märker eller misstänker att hans/hennes användaruppgifter har blivit kända eller missbrukas. WOM har rätt att tillfälligt inaktivera eller stänga av användarens användaruppgifter.",
    termsPara26:
      "Tjänsten kan innehålla länkar till webbplatser som ägs eller upprätthålls av tredje part. Länkade webbplatser står inte på något sätt under WOM:s kontroll och WOM övervakar eller granskar inte innehållet på länkade webbplatser. WOM ansvarar inte för dataskyddet eller  innehållet på länkade webbplatser eller för att innehållet är korrekt eller tillförlitligt.",
    termsPara27:
      "Användning av länkar som leder till externa webbplatser för användaren ifrån Tjänsten och användaren använder de länkade webbplatserna på eget ansvar. Användaren rekommenderas att noggrant bekanta sig med webbplatsernas användarvillkor och anvisningar före nyttjande av webbplatserna.",
    termsPara28:
      "En extern länk till Tjänsten ska hänvisa direkt till Tjänstens huvudsida och öppnas i ett självständigt fönster, alltså inte inom ramen för  den länkade webbplatsen. Länkens utseende, placering och övriga egenskaper får inte skada WOM:s rykte eller skapa ett felaktigt intryck av att den länkade sidan eller dess tjänster respektive produkter skulle vara kopplade till WOM:s verksamhet eller vara sponsrade av WOM. Länken får inte heller innehålla WOM:s varumärken i bildformat.",
    termsPara29:
      "De externa länkarna som ingår i Tjänsten och externa länkar till Tjänsten ger ingen uppfattning om förhållandet mellan WOM och den länkande respektive länkade tredje parten.",
    termsPara30:
      "Tjänsten och dess innehåll, såsom texter, bilder och symboler, är WOM:s eller dess respektive samarbetspartners egendom och är skyddad av upphovsrättslagar och internationella upphovsrättsavtal samt annan immaterialrättslagstiftning och -registrering.",
    termsPara31:
      "Alla immaterialrättigheter (som t.ex. upphovsrätt, registrerade och oregistrerade varumärkes- och mönsterrättigheter, domännamn, patent, databasrättigheter och affärshemligheter) i Tjänsten och dess innehåll samt deras användning skapar ett goodwill-värde som tillhör WOM och dess respektive samarbetspartner. WOM ger inte användaren några direkta eller indirekta rättigheter till immaterialrättigheterna.",
    termsPara32:
      "WOM strävar efter att hålla Tjänsten tillgänglig för användaren kontinuerligt och störningsfritt. WOM kan dock inte garantera att tjänsten är tillgänglig för användaren utan avbrott, i rätt tid eller felfritt. Tjänsten med dess innehåll erbjuds till användaren i befintligt skick. WOM tar inte på sig något ansvar för det förmedlade innehållet eller för att det är korrekt eller tillförlitligt, och inte heller för fel, inexakthet eller andra felaktigheter.",
    termsPara33:
      "”WOM kan inte garantera säkerheten för tjänsten, dess innehåll eller säkerheten för meddelanden, bilagor eller länkar som Prenumerant ansvarar för som skickas genom tjänsten. Dessutom kan vi inte garantera att elektroniska meddelanden som skickas via tjänsten är fria från virus eller andra skadliga komponenter.",
    termsPara34:
      "Tjänsten är inte nödvändigtvis tillgänglig i alla länder och kan endast vara tillgänglig på vissa specifika språk. Tjänsten, dess funktion respektive särskilda egenskaper kan vara beroende av kompatibiliteten och nätverksanslutningarna för de enheter som används samt av hur innehållet är formaterat.",
    termsPara35:
      "WOM kan ej ansvara för några direkta, indirekta, omedelbara eller fördröjda skador eller för skador som grundar sig på skadeståndsskyldighet, eller för särskilda skador, inklusive skador på grund av förlust av affärsverksamhet, avtal, intäkter eller information, alternativt avbrott i affärsverksamhet som beror på användning av Tjänsten eller dess innehåll respektive användningsförhinder eller dessa Villkor, oberoende av om skadorna är baserade på överenskommelse, intrång på rättighet, oaktsamhet eller annan orsak, även om WOM i förväg har erhållit information om möjliga skaderisker. I alla eventualiteter begränsas WOM:s ansvar maximalt till det ansvar som tvingande lagstiftning fastställer.",
    termsPara36:
      "Användaren fritar WOM och dess personal, chefer och personer som agerar i WOM:s namn, från ansvar för alla rättsanspråk och yrkanden som förorsakas av användning av tjänsten.",
    termsPara37:
      "WOM förbehåller sig rätten att efter eget gottfinnande ändra dessa Villkor, genom att meddela användaren om detta med e-post eller via Tjänsten. Ändringarna i Villkoren träder i kraft när information om dem har tillhandahållits . Därpå följande användning av Tjänsten anses utgöra användarens godkännande av de ändrade Villkoren.",
    termsPara38:
      "WOM kan inkludera meddelanden i Tjänsten. WOM kan även skicka meddelanden till den e-postadress eller det telefonnummer som användaren har angett. Meddelandena anses vara mottagna sju (7) dagar efter att WOM skickat eller publicerat dem. Fortsatt användning av Tjänsten anses innebära att användaren har mottagit alla meddelanden, oavsett hur de har skickats.",
    termsPara39:
      "Finsk lagstiftning tillämpas på användningen av Tjänsten och på dessa Villkor, med undantag av dess lagvalsregler.",
    termsPara40:
      "Parterna strävar att i första hand lösa tvisten via förhandling. Om det inte går att fram till någon uppgörelse i godo ska tvisten lösas vid Uleåborgs tingsrätt i Finland. Konsumentanvändare kan även överföra tvistlösningen till tingsrätten eller konsumenttvistenämnden som fastställs i enlighet med användarens hemort.",
    termsPara41: "Användarvillkoren har utarbetats och/eller uppdaterats:",
    termsPara42: "I Uleåborg den 30 mars 2021",

    uctAvailableTime: "Tillgänglig vårdtid",
    uctInfo1:
      "Här kan du hålla reda på hur många timmar ditt barn redan har använt (= timmar redan använt + upptagen timmar) per månad. Om betalningskategorin är tom och du inte ser de tillgängliga timmarna, betyder det att den plats du valt inte kräver någon vård i DaisyManager.",
    uctInvoiceBasis: "Faktureringsunderlag",
    uctPaymentCategory: "Avgiftsklass",
    uctServiceNeedType: "Vårdbehov; Timbeserad",
    uctSpentTime: "Använd tid",
    uctBooker: "Reserverat av",
    uctBookings: "Reservationer",
    uctBooking: "Reservation",
    uctBilling: "Fakturering",
    uctBillingCaps: "FAKTURERING",
    uctDateAbbr: "Datum",
    uctDuration: "Varaktighet",
    uctHoursTotal: "Timmar totalt",
    uctPaymentFree: "Kostnadsfri",
    uctPreSchoolDuration: "Förskoleundervisninges tid",
    uctPreSchoolTeaching: "Förskoleundervisning",
    uctRealizedCareTimes: "Använd tid",
    womNotification: "Meddelande från Daisy Varhaiskasvatus",
    womNotificationPara1: "Välkommen till DaisyFamily!",
    womNotificationPara2: "Version 1.2.1.0 används.",
    womNotificationPara3:
      "I den nya versionen av DaisyFamily användargränssnitt och bakgrundstjänster har optimerats",

    changePasswordBy: "För att reset lösenord, ge din e-postadressen",
    childsBirthdate: "Barnets födelsedatum",
    firstTimer: "Loggar du in för första gången till Daisy?",
    firstTimerInfo1:
      "Om ni skall loggas in första gången till Daisy, skapa ett användarnamn för dig själv här.",
    firstTimerInfo2:
      "Ange ditt barns födelsedatum och använd e-postadressen som du angav tidigare när du ansökte vårdplats.",
    forgotten: "Glömt lösenordet?",
    iHaveCopiedMyUserName: "Jag har kopierad mitt användarnamn",
    incorrectBirthDate: "Födelsedatum är inte korrekt",
    incorrectEmailAddress: "E-postadressen är inte korrekt",
    infoNewUser1:
      "Ditt användarnamn skall visas bara en gång. Om du glömmar ditt anvandarnamn, ta kontakt till dagis",
    infoNewUser2:
      "Ditt lösenord har sändas till e-post. Kolla din spam-mapp, om ni hittas inte meddelande",
    orderUserNameAndPassword: "Beställä användarnamn och lösenord",
    passwordReset: "Förandra lösenord",
    passwordResetInfo1:
      "Tillfallig lösenord har sändas till e-post. Kolla din spam-mapp, om du hittas inte meddelande",
    passwordResetInfo2:
      "Gå tillbacka till login sida och ge anvandarnamn och tillfallig lösenord",
    passwordRequirements:
      "Lösenordet måste vara minst 10 tecken långt, och ha minst 1 stor bokstav, och minst 1 nummer.", //todo
    passwordSaveInfoText: "Spara här", //TODO
    privacystatement: "Integritetspolicy",
    requestFailed: "Begäran misslyckades",
    resetPassword: "Växla lösenord",
    msgSearchForRecipient: "Sök efter en mottagare",
    msgSelectRecipients: "Välj mottagarna",
    msgSend: "Skicka",
    msgStaffPersons: "Anställda och chefer",
    loginStrongIdentification: "Stark identifiering",
    termsOfUse: "Användarvillkor",
    themeChange: "Ändra tema",
    msgThreadParticipants: "Tråd deltagare",
    //till:                         "Till",
    tips: "Tips",
    tipsInfo: "Visa guiderna i appen",
    msgTitleMaxLength: "Maximal rubriklängd nått",
    msgTitleMissing: "Ange en rubrik för ditt meddelande",
    toTheLoginPage: "Gå till login sida",
    tryAgainQ: "Försök igen?",
    msgUnknownPersonOrGroup: "Okänd person eller grupp",
    msgUnreadMessages: "Olästa meddelanden:",
    userManual: "Bruksanvisning",
    signUp: "Bli medlem", //TODO
    useStrongAuthenticationInstead:
      "Din dagis har stark autentisering. Logga in i appen med stark autentisering och ändra ditt lösenord i DaisyFamily från inställningssidan efter inloggning",
    useStrongIdentification:
      "Använd stark autentisering för att logga in för första gången",
    observations: "Skriva observationer...",
    welcometodaisyfamily: "Välkommen till DaisyFamily",
    welcometodaisyfamily2: "Framtidens småbarnpedagogik från DaisyFamily!",
    welcomeToUsingDF: "Välkommen till DaisyFamily!",
    msgWhomMessageConcerns: "Vem berör detta meddelande?",
    settingYes: "Ja",
    msgYou: "Du",
    msgYourMsgWasSent: "Ditt meddelande har skickats",
    yourUserName: "Ditt användarnamn",
    prev: "TIDIGARE",
    next: "NÄSTÄ",
    errCouldNotLoadData: "Data kunde inte laddas ner från servern",
    waitingservertoloaddata: "Laddar data från servern",

    formSaved: "Formulär sparat",
    eduPlanChildDescription: "Beskrivning av barnet",
    vasu: "Plan för småbarnspedagogik",
    vasuInfo:
      "Plan för småbarnspedagogi -funktionen är inte påslagen. Kontakta din tjänsteleverantör",
    vasuLocked:
      "Formen för barnutbildningsplan är låst i skrivskyddat läge. Du kan se innehållet i planen men du kan inte ändra formuläret",
    Preinformation1: "Grundläggande",
    Preinformation2: "Värdena i vår dagis i barndom och gruppens mål",
    eduPlanTitle1: "Förberedning för samtal",
    eduPlanTitle2: "Samtal",
    eduPlanTitle3: "Behov för stöd",
    eduPlanTitle4: "Vårens utvecklingssamtal och överföring till skolan",
    eduPlanSubtitle1: "Personalen fyller",
    eduPlanSubtitle2: "Frågor till föräldrar",
    vasuInvoledPeople:
      "Personer som deltog i skapandet av planen för småbarnspedagogi",
    medicalplan: "Läkemedelsplan",
    attachments: "Bilagor",
    guardian: "Vårdnadshavare",
    eceTeacher: "Förskolelärare",
    otherParticipants:
      "Annan personal och/eller sakkunniga som deltagit i planeringen",
    creationDate: "Datum dokumentet skapades",
    vasuValues: "Daghemmets småbarnspedagogiska värdegrund",
    groupObjectives: "Gruppens målsättningar",
    VasuChildSubTitle1: "Självförsörjning och färdigheter i vardagen",
    VasuChildSubTitle2: "Växelverkan och känslofärdigheter",
    VasuChildSubTitle3: "Språk",
    VasuChildSubTitle4: "Lek",
    VasuChildSubTitle5: "Fysisk aktivitet",
    VasuChildSubTitle7: "Forskning",
    VasuChildSubTitle8:
      "Utvärdering av förverkligande av mål och åtgärder i barnets eventuella tidigare plan för småbarnspedagogik",
    VasuChildSubTitle1v2: "Språkens rika värld",
    VasuChildSubTitle2v2: "Mina många uttrycksformer",
    VasuChildSubTitle3v2: "Jag och våra gemenskap",
    VasuChildSubTitle4v2: "Jag utforskar min omgivning",
    VasuChildSubTitle5v2: "Jag växer, rör på mig och utvecklas",
    ObjectivesForPedagogicalActivitiesAndMeasuresToAchieve:
      "Mål för den pedagogiska verksamheten och åtgärder för att uppnå målsättningar",
    VasuChildSubTitle10: "Övrigt att beakta",
    ChildWellbeingAndLearningObjectives:
      "Mål och åtgärder för att främja barnets välbefinnande och lärande",
    OtherExtraInfo: "Övrig information",
    OtherImportanInfo: "Andra relevanta frågor",
    ParentNotes: "Vårdnadshavarnas iakttagelser",
    VEONotes: "Iakttagelser som gjorts av speciallärare inom småbarnspedagogik",
    ChildNotes: "Barnets åsikt och önskemål",
    OtherNotes: "Andras observationer",
    OwnNotes: "Mina egna observationer",
    IssuesRaisedAgreed: "Överenskommelser / behandlade frågor",
    Evaluation: "Utvärdering",
    VasuChildSubTitle1Info:
      "Att klä på sig och klä av sig, måltider, att gå på toalett, att komma och gå till och från dagvårdsplatsen",
    VasuChildSubTitle2Info:
      "Växelverkan mellan med andra barn och vuxna, inställning till gränser och regler, att vänta på sin tur, förmågan att tåla besvikelser, uttrycka känslor osv.",
    VasuChildSubTitle3Info:
      "Att förstå och producera tal, verbalt arbetsminne och ordförråd, grunderna i att läsa och skriva, intresse för böcker och sagor osv.",
    VasuChildSubTitle4Info:
      "Lekutvecklingsskedet, favoritlekar, lekkamrater, lekens längd, användning av fantasin, lekens möjligheter och betydelse hemma/på daghemmet osv.",
    VasuChildSubTitle5Info: "Grov- och finmotorik, dagliga fysiska aktiviteter",
    VasuChildSubTitle6Info:
      "Musikalisk, visuell, verbal och kroppslig uttrycksförmåga, kultur",
    VasuChildSubTitle7Info:
      "Miljöfostran, matematiskt tänkande, teknologifostran",
    VasuChildSubTitleGeneralInfo1:
      "Barnets egenskaper?\n Vad barnet redan kan göra, vad barnet försöker lära sig, med vad kan barnet behöva stöd",
    VasuChildSubTitleGeneralInfo2:
      "Barnets egenskaper?\n Barnets styrkor, hobbyn, var kan man ge stöd?",
    VasuChildSubTitle8Info:
      "Förverkligande av mål och iakttagelser från barnets tidigare plan för småbarnspedagogik",
    VasuChildSubTitle1Infov2:
      "Växelverkan: att förstå och producera tal, verbalt arbetsminne och ordförråd, grunderna i att läsa och skriva, intresse för böcker och sagor osv. Undervisningspråk",
    VasuChildSubTitle2Infov2:
      "Musikalisk, visuell, verbal och kroppslig uttrycksförmåga och lek",
    VasuChildSubTitle3Infov2:
      "Familjen/närmiljön, kultur, etiskt tänkande och undervisning / åskådning, media",
    VasuChildSubTitle4Infov2:
      "Matematiska tänkande, miljöfostran onh teknologiforstran",
    VasuChildSubTitle5Infov2:
      "Fysisk aktivitet, påklädning, måltider, hälsa och vila",
    ObjectivesForPedagogicalActivitiesInfo1:
      "Barnets styrkor, intressen och behov, samt hur dessa tas i hänsyn",
    ObjectivesForPedagogicalActivitiesInfo2: "Mål för pedagogisk verksamhet",
    ObjectivesForPedagogicalActivitiesInfo3:
      "Åtgärder och metoder för att uppnå målen",
    ObjectivesForPedagogicalActivitiesInfo4:
      "Alla andra behov relaterade till utvecklings- och inlärningsstöd, målen för genomförandet av stödet och de överenskomna arrangemangen",
    VasuChildSubTitle10Info1:
      "Övriga saker som bör beaktas med stöd av barnets välbefinnande",
    VasuChildSubTitle10Info2:
      "Eventuella övriga dokument och planer som utnyttjats i uppgörandet",
    VasuChildSubTitle10Info3:
      "Uppföljnings- och utvärderingspunkter (övriga tilläggsuppgifter)",
    VasuChildSubTitle10Info4:
      "Samarbete och ansvarooperation and responsibilities",
    VasuSpecialSupport: "Särskilt stöd",
    VasuEnhancedSupport: "Intensifierat stöd",
    VasuadministrativeDecision: "Förvaltningsbeslut",
    VasuNoadministrativeDecision: " Inte förvaltningsbeslut",
    VasuSupportTitle1: "Stödformer ocg utvärdering",
    VasuSupportTitle2: "Andra saker att tänka på i barnets stöd",
    VasuNoSpecialSupport:
      "Barn behövs inte särsklit stöd, Intensifierat stöd ges för barnet",
    VasuNoEnhancedSupport:
      "Barnet behöver inte särsklit stöd, stöd ges genom ett allmänt stöd",

    VasuSupportSubTitle1:
      "Den övergripande situationen för barnets tillväxt, utveckling och välbefinnande ur barnets, vårdnadshavarens och förskolepersonalens perspektiv",
    VasuSupportSubTitle2: "Pedagogiska stöder och utvärdering",
    VasuSupportSubTitle3:
      "strukturella arrangemang och andra arrangemang som stödjer välbefinnandet och utvärdening",
    VasuSupportSubTitle4: "Stödformer och utvärdering",
    VasuSupportSubTitle5: "Samarbete och ansvarooperation and responsibilities",
    VasuSupportSubTitle6:
      "Andra möjliga dokument och planer som används vid beredningen",
    VasuSupportSubTitle7: "Andra relevanta saker",

    leopsrequestFailed: "Begäran misslyckades",
    leopsLockingSavedOk: "Låsning sparad",
    leopsLockingSavedFailed: "Sparande av låsning misslyckad",
    leopsChildEvaluationSaved: "Kommentar sparad",
    leopsChildEvaluationSaveFailed: "Sparandet av kommentar misslyckad",
    leopsAnswerSelectingBestChoice:
      "Tryck på lämpligt alternativ för att svara",
    leopsStillTraining: "Jag övar ännu",
    leopsICanAlready: "Jag kan redan",
    ManyFormsOfExpression: "Mina många uttrycksformer",
    leopsRichWorldOfLanguage: "Språkets rika värld",
    leopsMeAndOurCommunity: "Jag och vår gemenskap",
    leopsIStudyAndWork: "Jag utforskar min omgivning",
    leopsIGrowAndDevelop: "Jag växer och utvecklas",
    leopsIWantToLearnInPreschool: "I förskolan vill jag lära mig",
    StaffNotes: "Småbarnspedagogiska personalens iakttagelser",
    leopsLeopsTitle1_3: "Vårdnadshavarens del",
    leopsChildStrengthsAndIterestsParent: "Mitt barns styrkor och intressen",
    leopsObjectivesAndConsiderationsForThePreschool:
      "Mål och andra observationer för förskoleåret",
    HopesForCooperation: "Önskemål för samarbetet",
    leopsIHaveLearnedInPreschool: "I förskolan har jag lärt mig",
    leopsTitle4_3: "Lärarens sammanfattning av förskoleåret",
    ChildStrengthsAndIterests: "Barnets intressen och styrkor",
    EvaluationPedagogicalActivitiesGoalsSupport:
      "Utvärdering av förundervisningsårets pedagogiska verksamhet, målsättningar och stödverksamhet",
    OtherAdditionalInfoAndCooperation:
      "Annan ytterligare information och sektorsövergripande samarbete",
    GreetingsFromGuardian: "Hälsningar från vårdnadshavaren",
    LockLeops: "Låsa",
    ThingsAriseFromPreviousECEC:
      "Frågor uppkomna från den tidigare läroplanen för småbarnspedagogik",
    ObjectivesForPedagogicalActivitiesAndMeasuresToAchieve:
      "Mål för pedagogisk verksamhet och åtgärder för att uppnå målen",
    OtherPartnersAndDocuments: "Övriga sammarbetspartners och bilagor",
    StudentCareMeasures: "Elevvårdsåtgärder",
    AgreedCooperationWithGuardians:
      "Överenskommet samarbete med vårdnadshavare",
    ManyFormsOfExpressionInfo:
      "Muntligt och kroppsligt uttryck, multilitteracitet, förmåga att delta och påverka, koncentrationsförmåga, självregleringskunskaper",
    RichWorldOfLanguageInfo:
      "Uppfattning om betydelser, intresse för talat språk och för att läsa och skriva",
    MeAndOurCommunityInfo:
      "Etiska val i vardagen, utveckla sina emotionella färdigheter och träna konstruktivt beteende",
    IStudyAndWorkInfo:
      "Matematiskt tänkande, vardagsmatematik och teknik, förhållandet till naturen, utforskande",
    IGrowAndDevelopInfo:
      "Egenvårdskunskaper, hälsosam och hållbar livsstil, säkerhetskunskaper",
    LeopsTitle1_2:
      "Barnets självbedömning av hösten och förberedande observationer",
    LeopsTitle1_2_1: "Barns självbedömning (höst)",
    EvaluationPedagogicalActivities: "Utvärdering av pedagogisk verksamhet",
    LockLeopsInfo:
      "Avsnittet Diskussioner och överföring till skolan om våren är klar och jag vill låsa in den. NOTERA! När du har låst en konversation kan du inte längre lägga till eller redigera dina kommentarer.",
    LeopsTitle4_2: "Barnets självbedömning av våren",
    LeopsTitle4_2_1: "Barnets vårbedömning (vår)",
    PedagogicalEvaluation: "Pedagogisk bedömning",
    PedagogicalStudy: "Pedagogisk utredning",
    EnhancedSupportLearningPlan: "Förbättrad inlärningsplan för stöd",
    HOJKS: "HOJKS",
    removeFormLocking: "Ta bort låsning av formulär",
    ChildStrengthsAndInterestsLearningNeeds:
      "Barnets intressen, styrkor och förutsättningar för lärande och specialbehov",
    Confidental: "Konfidentiell.\nOffentlighetslagen 24 § 1 mom. 30 punkten",
    DocumentsUsedInDrafting: "Använda dokument",
    EnhancedSupportInitiating:
      "Påbörjan och organisation av intensifierat stöd",
    EnhancedSupportAndArrangements:
      "Intensifierat stöd för barnet samt organisering av stöd skrivs upp mer detaljerat i läroplanen",
    EnhancedSupportStartingAndOrganization:
      "Påbörjan av intensifierat stöd samt organisering av det / återkomst till det allmänna stödet har behandlats utifrån ovanstående pedagogiska bedömningen",
    GeneralSupportAndAssessmentImpact:
      "Allmänt stöd och bedömning av effekterna av olika stödformer",
    PersonsInvolvedDraftingDocument:
      "Personer som deltagit i uppgörande av dokumentet samt konsultationsdatum",
    PPH: "Familjedagvård",
    PreschoolPlanCreated: "Läroplan för förundervisning utförd",
    AssessmentChildGrowthAndLearning:
      "Bedömning av barnets tillväxt och lärande",
    OverallSituationChildGrowthAndLearning:
      "Den övergripande situationen för barnets tillväxt och inlärning ur barnets, vårdnadshavarens och förskolepersonalens perspektiv",
    AssessmentEnhancedSupportAndArrangements:
      "Bedömning av behov av intensifierat stöd och stödarrangemang",
    EnhancedSupportStart: "Påbörjas intensifierat stöd",
    EnhancedSupportNotNeeded:
      "Barnet behöver inte intensifierat stöd, stöds genom det allmänna stödet",
    EnhancedSupportLearningPlanCreated:
      "Läroplan för intensifierat stöd skapad",
    LeopsInfo:
      "Om en pedagogisk redogörelse görs för att granska beslut om specialstöd eller om stället för organisering av undervisning förändras, fylls delarna nedan i",
    SpecialSupportFirstDate: "Första beslut om specialstöd",
    SpecialSupportPrevDate: "Sista granskning av beslut om specialstöd",
    DecisionMaker: "Beslutsfattare",
    PreschoolOrganizer: "Arrangör av förundervisning",
    GeneralCompulsoryEducation: "Allmän obligatorisk utbildning",
    Started: "Börjandes med",
    ExtendedCompulsoryEducation: "Förlängd läroplikt",
    EnhancedSupportEvaluation:
      "Intensifierat stöd och bedömning av olika stödformers inverkan",
    PreschooEducationlProviderAssessment:
      "Förskoleutbildarens bedömning av behovet av särskilt stöd",
    SpecialSupportStart: "Påbörjan av specialstöd",
    DateLong: "Datum",
    ResponsiblePreschollTeacher: "Ansvarig förskolelärare",
    ConsultationChildAndGuardian: "Hörande av barn och vårdnadshavare",
    ConsultationChildAndGuardian2:
      "Hörande av barn och vårdnadshavare eller laglig företrädare enligt förvaltningslag",

    HOJKS_Title1:
      "Elevens individuella plan för hur undervisningen ska ordnas (IP)",
    LeopsFormTitle2: "Arrangemang för barnets förskoleutbildning och stöd",
    LeopsFormTitle2_1: "Målsättningar för barnets förskoleutbildning",
    LeopsFormTitle2_2: "Barnets inlärningsförmåga och stödbehov",
    LeopsFormTitle3: "Pedagogiska lösningar",
    LeopsFormTitle3_1: "Förstärkande växelverkan och miljö för inlärning",
    LeopsFormTitle3_1_1:
      "Positiv och acceptabel atmosfär, genuin närvaro av utbildningspersonalen, gemensamma operativa mål",
    LeopsFormTitle3_2: "Pedagogiska verksamhetsmodeller",
    LeopsFormTitle3_2_1:
      "Aktiviteter i små grupper, användning av bilder, förutseende, struktur",
    LeopsFormTitle3_3: "En multidisciplinär utbildningsgrupp",
    LeopsFormTitle3_3_1: "Ansvar och roller",
    LeopsHojksTitle3_4:
      "Tolk- och assistanstjänster, annan utbildningstjänst och särskilda hjälpmedel enligt beslut om särskilt stöd",
    LeopsEnhancedTitle3_4:
      "Tolk- och assistanstjänster enligt lagen om grundläggande utbildning, övriga undervisningstjänster och särskilda hjälpmedel",
    LeopsFormTitle3_5:
      "Eventuellt deltagande av barnet i annan förskoleutbildning",
    LeopsHojksTitle3_6:
      "Beskrivning av arrangemangen och ansvaret för barnets transport till och från platsen för förskoleutbildning, samt verksamheten och övervakandet av det barn som väntar på transport",
    LeopsHojksTitle4: "Uppföljning och utvärdering av IP",
    LeopsEnhancedTitle4: "Övervaka och utvärdera lärandeplanen",
    LeopsHojksTitle4_1:
      "Bedömning av uppnåendet av målsättningarna i elevens IP",
    LeopsEnhancedTitle4_1:
      "Bedömning av uppnåendet av målsättningarna i elevens IP",
    LeopsHojksTitle4_2: "Bedömning av åtgärdernas effektivitet i elevens IP",
    LeopsEnhancedTitle4_2: "Bedömning av åtgärdernas effektivitet i elevens IP",
    LeopsHojksTitle4_3:
      "En bedömning av barnets övergripande välbefinnande och inlärningen som helhet bör göras gemensamt med barnet och vårdnadshavaren",
    LeopsEnhancedTitle4_3: "Nästa uppföljning och utvärdering",
    LeopsFormTitle5: "Resultat av utvärderingen",
    LeopsEnhancedTitle5_2:
      "Elevens IP möter barnets behov av stöd och det särskilda stödet fortsätter",
    LeopsEnhancedTitle5_3:
      "En pedagogisk redovisning görs för barnet inför särskilt stöd",
    LeopsHojksTitle5_1:
      "Barnet behöver inte särskilt stöd, beslut fattas om att avsluta det och en plan för intensifierat görs för barnet",
    LeopsHojksTitle5_2:
      "Elevens IP möter barnets behov av stöd och det särskilda stödet fortsätter",
    LeopsStudy6_2:
      "Dokumentet går in i läsläge när vårdnadshavarens (s) samråd har bekräftats",
    LeopsStudy2: "Checking special support / changing teaching provider",

    //GUIDE: Booking
    guide: "GUIDE", //TODO
    guideNoContent: "No content available", //TODO
    backToTop: "Scroll to top", //TODO
    keywordButtonTitle1: "Scroll to ", //TODO
    keywordButtonTitle2: " section", //TODO
    help: "Help?", //TODO
    press: "Press", //TODO
    color: "Color", //TODO
    text: "Text", //TODO
    symbol: "Symbol", //TODO
    description: "Description", //TODO
    keywords: "Keywords", //TODO
    addBookingKeywordButton: "BOOKING", //TODO
    addBookingKeywordButton2: "Booking", //TODO
    editBookingKeywordButton: "EDITING", //TODO
    absentBookingKeywordButton: "ABSENCE", //TODO
    absentBookingKeywordButton2: "Absence", //TODO
    defaultTimeBookingKeywordButton: "DEFAULT CARE TIME", //TODO
    bookingIconsKeywordButton: "INTERPRETATION", //TODO
    careTimeLeftKeywordButton: "CARE TIME", //TODO
    invoiceDataKeywordButton: "INVOICE DATA", //TODO
    guideFAQKeywordButton: "FAQ", //TODO
    addBookingAccordionTitle: "Booking a care time", //TODO
    editBookingAccordionTitle: "Editing care time", //TODO
    defaultTimeAccordionTitle: "Default care time", //TODO
    absentAccordionTitle: "Marking absence", //TODO
    bookingIconsAccordionTitle: "Calendar's symbols", //TODO
    careTimeLeftAccordionTitle: "Available care time", //TODO
    invoiceDataAccordionTitle: "Invoice data", //TODO
    accordionTitleFAQ: "FAQ", //TODO
    defaultTimeAccordionDesc: "Setting & purpose", //TODO
    bookingIconsAccordionDesc: "Symbols & colors", //TODO
    bookingAbsentAccordionDesc: "Absent or sick", //TODO
    accordionDescFAQ: "Frequently asked questions", //TODO
    addBookingTableTitle: "Care time calendar's colors", //TODO
    addBookingTableDesc1:
      "You can book care times for those days in the future that appear on white background in the care time booking calendar.", //TODO
    addBookingTableDesc2:
      "If your child is attending shift care, you can book care times also for the weekends that have been marked with the light blue colour.", //TODO
    addBookingTableDesc3:
      "It is also possible to make bookings for the general vacation period (marked with pink)...", //TODO
    addBookingTableDesc4:
      "...and for the orange special arrangements vacation period (päivystysaika in Finnish), if the date for the bookings has not been locked yet.", //TODO
    addBookingTableDesc5: "Weekend.", //TODO
    addBookingAfterTable1:
      "You can set a default care time for your care time bookings by pressing the ", //TODO
    addBookingAfterTable2:
      " -button, in the lower left corner. The app will then suggest a default care time every time you are making a booking. The default time can be set in 15-minute intervals and you can change it any time you would like.", //TODO
    addBookingAfterTable3:
      "The default care time functionality makes it easier for you to book multiple similar care times, but you cannot create actual bookings with it.", //TODO
    addBookingMiddleTitle: "Making a care time booking", //TODO
    addBookingList1:
      "Select the dates for the booking on the calendar first. You can select single days by pressing the row for the correct date, entire weeks by pressing the number of the week, or a whole month by pressing the ", //TODO
    addBookingList1_2:
      " -button in the lower right corner. Weekends need to be selected separately by pressing on the relevant rows. The selected days will become grey.", //TODO
    addBookingList2:
      "Confirm the selections by pressing the confirm button in the lower right corner.", //TODO
    addBookingList3:
      "In the “Care Times” booking view, select the children for whom the booking will be made by selecting the on the right.", //TODO
    addBookingList4:
      "Under the name of the first selected child, you can select where the booking will be made. The selection will be made according to the so called ‘placement priority’, so if you choose the 1st priority for that child, the same placement priority (1st priority) will be automatically selected also for the other selected children.", //TODO
    addBookingList5:
      "The default value for the type of booking is “Care hours”. If needed, you can change the type to “Absent” or “Sick”.", //TODO
    addBookingList6:
      "The booking will be made automatically for the days that you chose on the calendar.", //TODO
    addBookingList7:
      "Mark the desired time of day for the booking. The starting and ending times can be set at 15-minute intervals.", //TODO
    addBookingList8:
      "If you would like to make a second booking for the same days, but for different times, press the “+ Add a new booking” button, and select the options you want for the second booking.", //TODO
    addBookingList9:
      "Save the booking by pressing the confirm button at the bottom.", //TODO
    addBooking2022Promise:
      "The Care Time Calendar for DaisyFamily will be restructured in 2022 based on the feedback we have received.", //TODO
    editBookingGuideTitle: "Editing an Already Existing Care Time Booking", //TODO
    editBookingGuideIntro:
      "You can edit an already existing care time booking with the help of the guide below, or, if you wish to, you can delete the care time bookings/markings by selecting the relevant dates and pressing the ", //TODO
    editBookingGuideIntro2: " -button in the lower righthand corner.", //TODO
    editBookingMiddleTitle: "Editing the care time bookings", //TODO
    editBookingList1:
      "Select the dates you wish to edit on the calendar. You can select single days by pressing the row on the date, entire weeks by pressing the week numbers, or an entire month by pressing the ", //TODO
    editBookingList1_2:
      " button in the lower righthand corner. Weekends must be selected separately by pressing those dates. The selected dates will turn grey.", //TODO
    editBookingList2:
      "Confirm the selections by pressing the plus sign button.", //TODO
    editBookingList3:
      "Select “NEW” if you would like to create completely new care time bookings. Select “KEEP” if you would like to create new bookings in addition to the current bookings for the selected days.", //TODO
    editBookingList4:
      "In the booking view, select children for whom the booking will be made, by selecting the checkbox on the right side of the child’s name. ", //TODO
    editBookingList5:
      "Under the name of the first selected child, you can select where the booking will be made. The selection will be made according to the so called ‘placement priority’, so if you choose the 1st priority for that child, the same placement priority (1st priority) will be automatically selected also for the other selected children.", //TODO
    editBookingList6:
      "The default value for the type of booking is “Care hours”. If needed, you can change the type to “Absent” or “Sick”.", //TODO
    editBookingList7:
      "The booking will be automatically made for the days that you chose on the calendar.", //TODO
    editBookingList8:
      "Mark the desired time of day for the booking. The starting and ending times can be set at 15-minute intervals.", //TODO
    editBookingList9:
      "Save the booking/bookings by pressing the confirm button at the bottom of the page.", //TODO
    absentBookingIntroTitle: "Marking the Child as Absent or Sick", //TODO
    absentBookingIntro1:
      "Children can be marked as absent or sick both in the care time calendar and by using the quick function called “Today’s absence” through which you can notify the daycare that your child is absent or sick on that current day.", //TODO
    absentBookingIntro2:
      "You can mark a child as absent or sick for the current day or for multiple days into the future, including for dates that have been already locked.", //TODO
    absentBookingIntro3:
      "When you mark a child as absent or sick for a date that has been locked and which already has a care time booking, the care time booking will still stay and show on the “Invoice Data” view.", //TODO
    absentBookingMiddleTitle1:
      "Marking children as absent or sick in the caretime booking calendar", //TODO
    absentBookingMiddleTitle2:
      "Marking children as absent or sick using the “Today’s absence” tool", //TODO
    absentBookingListOne1:
      "Select the dates for which you wish to edit the booking, on the care time booking calendar. You can select single days by pressing the row on the date, entire weeks by pressing the week numbers, or an entire month by pressing the [koko kk] button in the lower righthand corner. Weekends must be selected separately by pressing those dates. The selected dates will turn grey.", //TODO
    absentBookingListOne1_2:
      " button in the lower righthand corner. Weekends must be selected separately by pressing those dates. The selected dates will turn grey.", //TODO
    absentBookingListOne2: "Confirm the selections by pressing the button ", //TODO
    absentBookingListOne2_2:
      " at the bottom. The button to be pressed will depend on whether the date in question is in the future or whether it is a date that has already been locked.", //TODO
    absentBookingListOne3:
      "In the care time booking view, select the children for whom the marking will be made by checking the checkbox for them on the right.", //TODO
    absentBookingListOne4:
      "Under the name of the first selected child, you can select where the marking will be made. The selection will be made according to the so called ‘placement priority’, so if you choose the 1st priority for that child, the same placement priority (1st priority) will be automatically selected also for the other selected children.", //TODO
    absentBookingListOne5: "Select “Absent” or “Sick” in the dropdown menu.", //TODO
    absentBookingListOne6:
      "Save the marking by pressing the confirm button at the bottom.", //TODO
    absentBookingListTwo1:
      "Press the Main Menu button on the upper righthand corner and select “Today’s absence”.", //TODO
    absentBookingListTwo2:
      "In the booking view, check the checkbox for the children whom the marking will concern.", //TODO
    absentBookingListTwo3:
      "Under the name of the first selected child, you can select where the marking will be made. The selection will be made according to the so called ‘placement priority’, so if you choose the 1st priority for that child, the same placement priority (1st priority) will be automatically selected also for the other selected children.", //TODO
    absentBookingListTwo4: "Select “Absent” or “Sick” on the pulldown menu.", //TODO
    absentBookingListTwo5:
      "Save the marking by pressing the confirm button at the bottom.", //TODO
    BookingSymbolsTableTitle: "Interpreting the Care Time Calendar Symbols", //TODO
    symbolBookingDesc1:
      "The small circles represent the family’s children in the care time booking calendar. A circle shown on a particular date represents a specific child for whom the booking has been made. By pressing the circles, you will be able to see the specifics regarding the bookings for the day.", //TODO
    symbolBookingDesc2:
      "Notifies about an exception. The child’s care time booking has been made for multiple time slots for the day, or the booked care day has an “Absent” marking or a “Sick” marking.", //TODO
    symbolBookingDesc3:
      "Default care time – You can set a default care time here. The app will suggest this default time every time you are making a care time booking. The default can be set at 15-minute intervals, and it can be changed any time you wish. This functionality helps you create similar kinds of caretime bookings faster, but you cannot make actual bookings just with it.", //TODO
    symbolBookingDesc4:
      "Selection/Proceeding button – When you have selected the days for which you wish to make bookings or markings in the calendar, press this button to proceed to the next step in making a booking or marking.", //TODO
    symbolBookingDesc5:
      "Select all – Using this functionality, you can select the entire month’s unlocked weekdays (excluding holiday dates) to create bookings for them.", //TODO
    symbolBookingDesc6:
      "Absence – When you have selected a locked date/dates for which you wish to create “Absent” or “Sick” marking(s), select this Absence Button to proceed.", //TODO
    symbolBookingDesc7:
      "Add booking – Add new bookings or replace the current bookings for the days you have selected", //TODO
    symbolBookingDesc8:
      "Delete – Removes bookings and markings for the selected days.", //TODO
    symbolBookingDesc9:
      "Available care time – Opens up a sidebar where you can see the available care time for spending for the month. From the sidebar you can also open a child & month specific ‘Invoice data’ view, where you will be able to see summaries of care time reservations and hours spent in care.", //TODO
    textBookingDesc1:
      "The text ”Differences” tells you that the starting and/or ending times of the bookings for the siblings differ from each other.", //TODO
    textBookingDesc2:
      "This text tells you that this date in the past has a care time booking and/or the child has been in care that day.", //TODO
    textBookingDesc3: "Official holiday dates, e.g.:", //TODO
    availableCareTimeText1:
      "You can view the available care time behind the [€] button on the care time calendar. The side menu shows the care need marked for the month, the payment category and the time used.", //TODO
    availableCareTimeText2:
      "The care time used includes the booked hours of the month as well as the hours already used that have exceeded the daily booking. By looking at this reading, you can see how much care time is still available for this month.", //TODO
    availableCareTimeText3:
      "The information on the need for care and the class of payment comes from the background information marked for the child, so if it is missing, please contact the staff or supervisor of your child's place of care.", //TODO
    invoiceDataText1:
      "You can find the invoice data for each child and month by selecting the [€] button for the desired month in the care booking calendar and the INVOICE DATA button in the side menu that opens.", //TODO
    invoiceDataText2:
      "In the billing basis, you can scroll through the three buttons at the top: reservations, spent time, and preschool.", //TODO
    invoiceDataText3_1: "Reservations: ", //TODO
    invoiceDataText3_2:
      "you can see the bookings made for the month, their duration, the booker and the effect on the monthly invoicing. Marks on a yellow background mean that the billing is based on the nursing time reservation made for the day in question.", //TODO
    invoiceDataText4_1: "Spent time: ", //TODO
    invoiceDataText4_2:
      "you can see the actual nursing time to the nearest minute, the actual day duration, and the effect on monthly invoicing. Markings on a green background mean that the billing is based on the actual nursing time for the day in question", //TODO
    invoiceDataText5_1: "Preschool: ", //TODO
    invoiceDataText5_2:
      "you can view the duration of pre-school education and its effect on monthly invoicing. Pre-school time is free and is therefore deducted from billing. Note! Not all municipalities and private kindergartens use this Daisy pre-school time. If Daisy's pre-school time is off, your child's pre-school time will not appear in this view.", //TODO
    invoiceDataText6:
      "At the bottom of your billing basis, you'll see a summary of your monthly invoicing.", //TODO
    guideDifferences: "Differences", //TODO
    guideTimeReserved: "Time reserved/Time spent in care", //TODO
    guideRedText: "Red text", //TODO
    redTextExample: "Christmas Eve", //TODO
    notificationsSettings: "Aviseringsinställningar", //TODO

    defaultMessagingAccordionTitle: "Viestinäkymä",
    defaultMessagingAccordionDesc: "Lisätietoa viestinnästä",
    defaultMessagingSubTitle1: "Viestitysnäkymä",
    defaultMessagingIntro:
      "Viestinäkymässä hoidat viestinnän lapsesi hoitopaikan henkilöstön kanssa.",
    defaultMessagingList1: "Viestitys avautuu",
    defaultMessagingList2:
      "-viestipainikkeesta, joka löytyy sivun vasemmasta laidasta.",
    defaultMessagingList3: "Voit myös avata viestit oikean yläkulman",
    defaultMessagingList4:
      "-tapahtumaseloste painikkeesta ja siirtymällä 'ilmoitukset' kohtaan, jonka jälkeen saat viestit auki painamalla",
    defaultMessagingList5:
      "-näytä lisää painiketta ja valitsemalla viestiketjun.",
    defaultMessagingMiddleTitle: "Viestien lukumäärä",
    defaultMessagingList6: "Oranssi pallo numeron kanssa ",
    defaultMessagingList7:
      "-viestipainikkeessa kertoo niiden viestilaatikossa olevien viestiketjujen lukumäärän, joissa on lukemattomia viestejä.",
    defaultMessagingList8:
      "Näet lukemattomien viestien lukumäärän myös oikean yläkulman 'ilmoitukset' valikosta.",
    defaultMessagingMiddleTitle2: "Lueutu ja lukemattomat viesti",
    defaultMessagingList9:
      "Viestilistauksen vaaleansiniset viestit on luettu ja tummansinisiä viestejä ei ole vielä avattu. Listauksessa tuoreimmat viestit näkyvät ylimmäisenä.",
    defaultMessagingMiddleTitle3: "Näkymä",
    defaultMessagingList10:
      "Viestintä näkyy pikaviestisovelluksista tuttuun tapaan viestiketjuina. Muiden viestit näkyvät viestiketjun vasemmassa laidassa ja omat viestisi ketjun oikeassa laidassa. Viestiketjun ylälaidassa näkyvät mukana olevat vastaanottajat.",

    //Viestityksen painikkeet
    messagingButtonsAccordionTitle: "Viestityksen painikkeet",
    messagingButtonsAccordionDesc: "Lisätietoa viestinnän painikkeista",
    messagingButtonsSubTitle1: "Viestityksen painikkeet",
    messagingButtonList1: "Uusi viesti",
    messagingButtonList2: " - aloita uusi viestiketju.",
    messagingButtonList3: "Päivitä",
    messagingButtonList4: " - päivittää viestityksen näkymän ajan tasalle.",
    messagingButtonList5: "Kaikki luetuiksi",
    messagingButtonList6: " - merkitsee kaikki lukemattomat viesti luetuiksi.",
    messagingButtonList7: "Poista",
    messagingButtonList8: " - poistaa viestin.",

    //Uusi viestiketju
    messageThreadAccordionTitle: "Uusi viestiketju",
    messageThreadAccordionDesc: "Lisätietoa uudesta viestiketjusta",
    messageThreadSubTitle1: "Uusi viestiketju",
    messageThreadList1: "Aloita",
    messageThreadList2: "uusi viestiketju",
    messageThreadList3: "painamalla viestinäkymässä",
    messageThreadList4: "painiketta.",
    messageThreadList5: "Valitse",
    messageThreadList6: "täppämerkinnällä",
    messageThreadList7: "ketä lasta tai lapsia viesti koskee.",
    messageThreadList8: "Valitse viestin",
    messageThreadList9: "vastaanottaja(t).",
    messageThreadList10: "Hyväksy valinta",
    messageThreadList11: "väkänen painikkeella.",
    messageThreadList12: "Valitse",
    messageThreadList13: "ryhmä,",
    messageThreadList14:
      "jos haluat henkilöstön näkevän viestin mahdollisimman pian ja/tai kun viesti ei sisällä arkaluonteista tietoa. Tällöin viesti tulee näkyviin yksikön henkilöstön puhelinsovellukseen.",
    messageThreadList15: "yksittäinen työntekijä tai työntekijöitä,",
    messageThreadList16:
      "jos haluat lähettää viestin vain tietyille henkilöille. Tämä valinta tehdään, jos viestisi on tarkoitus olla yksityinen. Viesti tulee näkyviin vain kyseiselle työntekijälle. Näitä henkilökohtaisia viestejä saatetaan lukea harvemmin, kuin ryhmälle tai yksikölle lähetettyjä viestejä.",
    messageThreadList17: "Kirjoita",
    messageThreadList18: "viestin aihe ja viesti.",
    messageThreadList19: "Tässä vaiheessa voit vielä poistaa vastaanottajia.",
    messageThreadList20: "Lähetä viesti painamalla",
    messageThreadList21: "'LÄHETÄ'",
    messageThreadList22: "button.",

    //Viestinnän näkyvyys
    whatKindOfMessagesAccordionTitle: "Viestinnän näkyvyys",
    whatKindOfMessagesAccordionDesc: "Lisätietoa viestinnän näkyvyydestä",
    whatKindOfMessagesList1:
      "Varhaiskasvatuksessa sinulle voidaan lähettää viestejä, joiden lähettäjänä on henkilöstön yksittäinen jäsen, päiväkoti tai päiväkodin ryhmä. Viestiketjussa ei näy, onko kyseessä niin sanottu massaviesti (ryhmä-, päiväkoti- tai aluekohtainen), koska viestintä on vain sinun ja varhaiskasvatuksen henkilöstön välistä. Kun siis vastaat saamaasi viestiin, se pysyy sinun ja lähettäjän välisenä.",
    whatKindOfMessagesList2: "Poikkeuksena tähän ovat perhekohtaiset viestit,",
    whatKindOfMessagesList3:
      "jotka on lähetetty perheen huoltajille lapsenne nimellä. Perhekohtaisiin viesteihin vastatessasi vastaus näkyy myös muille perheesi huoltajille",
    whatKindOfMessagesMiddleTitle: "DaisyFamilyssä voit saada:",
    whatKindOfMessagesList4: "Henkilökohtaisia",
    whatKindOfMessagesList5: "viestejä sinulle, sinun nimelläsi.",
    whatKindOfMessagesList6: "Perhekohtaisia",
    whatKindOfMessagesList7:
      "viestejä sinulle ja lapsesi muille huoltajille lapsenne nimellä.",
    whatKindOfMessagesList8: "Ryhmäkohtaisia",
    whatKindOfMessagesList9:
      "viestejä sinulle ja lapsesi ryhmän muille huoltajille lapsen hoitoryhmän nimellä.",
    whatKindOfMessagesList10: "Yksityiskohtaisia",
    whatKindOfMessagesList11:
      "viestejä sinulle ja lapsesi päiväkodin muille huoltajille lapsen päiväkodin nimellä.",
    whatKindOfMessagesList12: "Aluekohtaisia",
    whatKindOfMessagesList13:
      "viestejä sinulle ja päiväkodin alueen muille huoltajille alueen nimellä.",
    whatKindOfMessagesMiddleTitle2:
      "Kenelle vastausviestisi näkyvät, kun vastaat saapuneeseen viestiketjuun?",
    whatKindOfMessagesList14:
      "Mikäli näet viestiketjun osallistujalistassa päiväkodin tai ryhmän nimen, viestiketjun viestit näkyvät kyseisen yksikön henkilöstölle, joka on kyseisessä päiväkodissa tai ryhmässä. Mikäli haluat viestitellä yksittäisen varhaiskasvatuksen henkilöstön jäsenen kanssa, voit aloittaa uuden viestiketjun kyseisen henkilön kanssa.",
    whatKindOfMessagesList15: "Ryhmä- ja yksikkökohtaiset viestiketjut:",
    whatKindOfMessagesList16:
      "Hoitoryhmän tai -yksikön nimellä aloitetun viestiketjun vastaukset näkyvät yksikön henkilöstölle.",
    whatKindOfMessagesList17: "Perhekohtaiset viestiketjut:",
    whatKindOfMessagesList18:
      "Lapsesi nimellä aloitettu viestiketju on lähetetty myös muille lapseen liitetyille huoltajille. Tällöin viestiketjun osallistujissa on merkintä 'Lapsen Sukunimi, Etunimi (Huoltajat)'. Kun vastaat tähän viestiketjuun, näkyy vastauksesi viestin lähettäjälle.",
    whatKindOfMessagesList19: "Huom!",
    whatKindOfMessagesList20:
      "Jos lapseen liitetään myöhemmin uusi huoltaja (esim. sinun puolisosi), myös hän näkee kaikki lapseen liittyvät aiemmat viesitketjut vastauksineen.",

    //Avainsanat
    defaultMessagingKeywordButton: "Viestintä",
    messagingButtonKeywordButton: "Painikkeet",
    messageThreadKeywordButton: "Lähettäminen",
    whatKindOfMessagesKeywordButton: "Vastaanottajat",

    //Guide: Permission
    //Lupakyselyt
    permissionRequestAccordionTitle: "Lupakyselyt",
    permissionRequestAccordionDesc: "Lisätietoa lupakyselyistä",
    permissionRequestSubTitle: "Lupakyselyt",
    permissionRequestIntro:
      "Lupakyselyiden avulla henkilöstö voi pyytää kootusti huoltajien lupaa esimerkiksi retkille osallistumiseen tai muihin tilanteisiin.",
    permissionRequestList1:
      "Löydät lastasi koskevat lupakyselyt valitsemalla vasemmalta",
    permissionRequestList2:
      "-lupakyselyt kuvakkeen tai painamalla oikean yläkulman",
    permissionRequestList3:
      "-tapahtumaseloste painiketta ja siirtymällä 'ilmoitukset' kohtaan, jonka jälkeen saat lupakyselyt ja suostumukset auki painamalla",
    permissionRequestList4:
      "-näytä lisää painiketta kohdassa 'Luvat ja suostumukset' ja valitsemmalla joko huoltajan suostumukset tai kyselyt.",
    permissionRequestList5:
      "'Kyselyt' osiossa näet listattuna lastasi koskevat lupakyselyt.",
    permissionRequestList6: "Voit päivittää listauksen",
    permissionRequestList7: "'Päivitä'",
    permissionRequestList8: "painikkeesta.",
    permissionRequestList9: "-kuvake näkyy uusien lupakyselyiden kohdalla.",
    permissionRequestList10:
      "-Uudet ilmoitukset kuvakkeen alla näkyvät uudet lupakyselyt.",
    permissionRequestList11:
      "-Päivämäärä kuvakkeen kohdassa näkyvä päivämäärä kertoo, mihin saakka lupakysely on voimassa ja siihen voi vastata.",
    permissionRequestList12:
      "Avaa haluamasi lupakysely painamalla lupakyselyn otsikosta",
    permissionRequestList13:
      "Lupakyselyn avattuasi voit vastata siihen merkitsemällä",
    permissionRequestList14: "'Annan luvan'",
    permissionRequestList15: "kohdassa joko",
    permissionRequestList16: "'Kyllä'",
    permissionRequestList17: "tai",
    permissionRequestList18: "'Ei'.",
    permissionRequestList19: "Tehtyäsi valinnan, paina",
    permissionRequestList20: "'LÄHETÄ'",
    permissionRequestList21: "-painiketta lähettääksesi vastauksen.",
    permissionRequestList22: "Perheen huoltajat voivat",
    permissionRequestList23: "muokata vastausta",
    permissionRequestList24:
      "vielä lupakyselyn voimassaolon ajan. Viimeisen vastauspäivämäärän jälkeen vastaus lukittuu.",

    //Huoltajan suostumukset
    parentalConsentsAccordionTitle: "Huoltajan suostumukset",
    parentalConsentsAccordionDesc: "Lisätietoa huoltajan suostumuksista",
    parentalConsentsList1:
      "Joillakin kunnilla ja yksityisillä päiväkodeilla voi olla käytössä huoltajakohtaisia suostumuksia. Nämä suostumukset näkyvät DaisyFamilyssä sekä hakemuspalvelu eDaisyssä, jos se on käytössä.",
    parentalConsentsList2:
      "Löydät itseäsi koskevat suostumukset valitsemalla vasemmasta laidasta",
    parentalConsentsList3:
      "-näytä lisää painiketta kohdassa 'Luvat ja suostumukset' ja valitsemmalla 'huoltajan suostumukset'.",
    parentalConsentsList4:
      "Vastaa suostumuksiin haluamallasi tavalla painamalla joko 'Kyllä' tai 'Ei' valintaa. Voit vaihtaa suostumustasi halutessasi. Tallenna muutos painamalla 'Tallenna suostumukset' painiketta.",

    //Lasten luvat
    childPermissionsAccordionTitle: "Lasten luvat",
    childPermissionsAccordionDesc: "Lisätietoa lasten luvista",
    childPermissionsList1: "'Luvat ja suostumukset'",
    childPermissionsList2:
      "sivulla ohjelma ilmoittaa, jos perheen lasta koskeviin lupakyselyihin ei ole vastattu.",
    childPermissionsList3:
      "Lapsikohtaiset lupakysymykset löydät lapsen perustietojen osiosta 'Lapsen luvat'.",
    childPermissionsList4:
      "Lapsen perustiedot löytyvät lapsen sivun alavalikosta.",
    childPermissionsList5: "Lapsen sivuille pääset:",
    childPermissionsList6: "Jos perheessä on",
    childPermissionsList7: "yksi lapsi",
    childPermissionsList8:
      "Daisyssä, tulet lapsen sivulle heti kirjautesassi sisään DaisyFamilyyn.",
    childPermissionsList9: "Perustiedot löytyvät tämän lapsen alavalikosta.",
    childPermissionsList10: "useita lapsia",
    childPermissionsList11:
      "Daisyssä, pääset yksittäisen lapsen sivulle painamalla lapsen omasta värilaatikosta, jossa on lapsen nimi ja profiilikuva. Tämä löytyy",
    childPermissionsList12: "'Lapset'",
    childPermissionsList13: "sivulta.",
    childPermissionsList14: "Perustiedot löytyvät lapsen sivun alavalikosta",
    childPermissionsList15: "Lapsen sivulla",
    childPermissionsList16: "'Perustiedot'",
    childPermissionsList17: "löytyvät sivun alavalikosta.",
    childPermissionsList18:
      "Merkitse lapsen lupiin 'KYLLÄ tai 'EI' valinta ja tallenna muutokset. Lapsen huoltajat voivat muokata lupatietoja halutessaan.",
    childPermissionsList19:
      "Kun muokkaat lupatietoa, olethan asiasta yhteydessä myös lapsesi hoitopaikan henkilöstöön, jotta heillä on viimeisin tieto muutoksista.",

    //Avainsanat
    permissionRequestKeywordButton: "Muokkaaminen",
    parentalConsentsKeywordButton: "Huoltajakohtaiset",
    childPermissionsKeywordButton: "Lapsikohtaiset",

    //Keskusteluajat
    discussionTestTitle: "Keskusteluajat testi ohje",

    //Testi
    discussionTestList: "Testi",

    //Avainsanat:
    discussionTestKeyword: "Testi",

    //UUSI VARAUSKALENTERI
    newCalendarNewCaretime: "Ny vårdtid",
    newCalendarNewAbsence: "Ny frånvaro",
    newCalendarDeleteTimes: "Ta bort bokningarna",
    newCalendarDeleteTimes2: "Ta bort bokning",
    newCalendarServiceNeed: "Vårdbehov",
    newCalendarPaymentCategory: "Betalningsklass",
    newCalendarFreeTime: "Gratis tid",
    newCalendarUsedTime: "Använd tid",
    newCalendarBilling: "Faktureringsgrund",
    newCalendarDeleteCareTime: "Ta bort vårdtid",
    newCalendarCareTime: "Vårdtid",
    newCalendarAbsence: "Frånvaro",
    newCalendarLockedTime: "Låsningstid",
    newCalendarCareTimes: "Vårdtider",
    newCalendarVacationTimes: "Semestertider",
    newCalendarVacationTime: "Semestertid",
    newCalendarPeriodTime: "Dejoureringstid",
    newCalendarChildrenToShow: "Välj vilka barns om visas",
    newCalendarDefaultTime: "Sandard vårdtid",
    newCalendarLoadingCareTimes: "Laddar bokningar",
    newCalendarSick: "Sjuk",
    newCalendarAbsent: "Frånvarande",
    newCalendarAbsentReasonSelect: "Välj orsak till frånvaro",
    newCalendarSaveAbsence: "Spara frånvaro",
    newCalendarSavingAbsence: "Sparar frånvaro",
    newCalendarSaveNewAbsence: "Anmäl frånvaro",
    newCalendarSavingAbsenceFailed: "Misslyckades med att spara frånvaro",
    newCalendarAbsentSaved: "Frånvaron sparad",
    newCalendarSaveNewCareTime: "Meddela ny vårdtid",
    newCalendarSaveNewCareTimes: "Spara bokningar",
    newCalendarSavingCareTimes: "Sparar bokningar",
    newCalendarSavingCareTimes2: "Sparar vårdtider",
    newCalendarCareTImesSaved: "Vårdtiderna sparade",
    newCalendarSavingCareTimefailed: "Misslyckades med att spara vårdtider",
    newCalendarSelectChildren: "Välj barn",
    newCalendarSelectTime: "Välj bokningens längd",
    newCalendarSelectDates: "Välj dagar",
    newCalendarDays: "Dagar",
    newCaledarWeeks: "Veckor",
    newCalendarRange: "Tidsintervall",
    newCalendarDeleteCareTimes: "Ta bort bokningarna",
    newCalendarDeleteCareTimes2: "Ta bort bokningar",
    newCalendarSavingDefault: "Sparar standard vårdtid",
    newCalendarExistSelected:
      "Det finns tidigare bokningar för de valda tiderna, vill du spara tidigare bokningar?",
    newCalendarWeekEnds:
      "Det går att boka veckoslut för ett eller flera barn. Vill du lägga till veckoslut till bokningen?",
    newCalendarSaturdays:
      "Det går att boka lördagar för ett eller flera barn. Vill du lägga till lördagar i bokningen?",
    newCalendarSundays:
      "Det går att boka söndagar för ett eller flera barn. Vill du lägga till söndagar i bokningen?",

    newCalendarAbsentWeekEnds:
      "Det går att lägga till frånvaro för veckoslut för ett eller flera barn. Vill du lägga till veckoslut till bokningen?",
    newCalendarAbsentSaturdays:
      "Det går att lägga till frånvaro för lördagar för ett eller flera barn. Vill du lägga till lördagar i bokningen?",
    newCalendarAbsentSundays:
      "Det går att lägga till frånvaro för söndagar för ett eller flera barn. Vill du lägga till söndagar i bokningen?",
    newCalendarAreYouSureExit: "Are you sure you want to exit?",
    newCalendarAreYouSureExit: "Är du säker på att du vill avsluta?",
    newCalendarEditLoss: "Alla val du har gjort kommer att gå förlorade",
    newCalendarLoadingPrev: "Laddar tidigare vårdtider",
    newCalendarLoadingNext: "Laddar följande vårdtider",
    newCalendarWeek: "Vecka",
    newCalendarClose: "Stäng",
    newCalendarCancel: "Avbryt",
    NewCalendarDelete: "Ta bort",
    newCalendarExit: "Avsluta",
    newCalendarSave: "Spara",
    NewCalendarYes: "Ja",
    newCalendarNo: "Nej",
    newCalendarSaveSuccess: "Lyckades spara",
    newCalendarSaveFailed: "Misslyckades med att spara",
    newCalendarTryAgain: "Försök på nytt!",
    newCalendarDeletingCareTimes: "Tar bort vårdtider",
    newCalendarDeletingCareTimesFailed: "Misslyckades ta bort vårdtiderna",
    newCalendarDeletingCareTimesFailed2: "Misslyckades ta bort",
    newCalendarCareTimesDeletedSuccess: "Lyckades ta bort bokningarna",
    newCalendarCareTimesDeletedSuccess2: "Bokningarna borttagna",
    newCalendarAreYousureWantToDeleteCaretime:
      "Vill du verkligen ta bort vårdtiden",
    newCalendatAutomaticClose: "Fönstret stängs automatiskt",
    newCalendarAfterSeconds: "Om en sekund",
    newCalendarEo: "EO",
    actionNotes: "Markeringar",
    actionNotesNull: "Inga markeringar",
    newNotifications: "Nya meddelanden",

    clockType: "Klockform",
    clockTypeDialog1:
      "Som en ny funktion i vårdtidskalendern kan ni välja klockform som används för att ställa in tiden",
    clockTypeDialog2:
      "I framtiden kan du ändra ditt val från kalenderinställningarna",
    clockTypeSlider: "Reglaget",
    clockTypeDigital: "Digital klocka",
    clockTypeStart: "Digital klocka",
    clockTypeEnd: "Digital klocka",
    saveChoice: "spara val",
    new: "nyhet",
  },
};

export default new LocalizedStrings(languageStrings);

/*
fi:
    //abandonMessagesCheckHeader: "Hylkää viesti?",
    //abandonmessagecheck:    "Haluatko hylätä kirjoittamasi viestin?",
    //abandonNo:              "Peruuta",
    //abandonYes:             "Kyllä, hylkää viesti",
    //addrecipientshere:      "Lisää vastaanottajia",
    //change:                 "Muokkaa",
    //childinfo:              "Lapsen tiedot",
    //choosecentre:           "Valitse päiväkoti",
    //choosechild:            "Valitse lapsi",
    //dataloading:            "Tietojen haku",
    //dates:                  "Päivämäärä(t)",
    //daycarecentre:          "Päiväkoti",
    //dayshort:               "PVM",
    //defaulttime:            "Oletusaika",
    //error1:                 "Virhe: Viestiä ei ole ehkä lähetetty yhteys- tai palvelinvirheen vuoksi.",
    //errorCheckPhonemubers:  "Tarkista puhelinnumero(t)",
    //errorLoggingLogOut:     "Uloskirjautumista ei voitu tallentaa tietokantaan",
    //errorLostToken:         "Kirjautumistiedot kadonneet",
    //errorPhoneNumberFormat: "Puhelinnumero väärää muotoa",
    //errorPhoneNumberLength: "Puhelinnumero väärän mittainen (10-15)",
    //errorSavingEditedFailed: "Tallennettavaa tietoa ei löytynyt.\nTallennus epäonnistui",
    //errorSavingBookings: "Hoitoaikavarausten tallentaminen epäonnistui",
    //errorTypeNotAccpeted:   "Varauksen tyyppiä ei hyväksytä",
    //errorUserUnKnown:       "Käyttäjä tuntematon",
    //infoChangesToLockedPeriod: "Lukitusajalle on lisätty poissa-/sairas-merkintä. Alkuperäinen varattu hoitoaika lasketaan kyseisen kuukauden käytettyihin tunteihin.",
    //loadingpicture:         "Ladataan kuvaa",
    //loadingPossibleRecipients: "Ladataan mahdollisia vastaanottajia",
    //messagesend:            "Lähetä viesti",
    //nursingtimeused:        "Käytetty hoitoaika",
    //settingNo:              "Ei",
    //nopicture:              "Kuvaa ei saatavilla",
    //saving:                 "Tallennetaan...",
    //persons:                "Henkilöt",
    //reloadmessages:         "Päivitä viestit",
    //nursingtimeavailable:   "Käytettävissä oleva hoitoaika",
    //bookingPartialFailed:   "Kaikkia päiviä ei voida varata!",
    //selectchildfirst:       "Valitse lapsi ensin!",
    //selectall:              "Valitse kaikki",
    //selectallRemove:        "Poista valinnat",
    //startandendtimes:       "Alku- ja loppu klo. aika",
    //summary:                "Yhteenveto",
    //thisIsAMassThread:      "Alkuperäinen lähettäjä lähetti samanlaisen viestin myös muille henkilöille.",
    //thisReplyOnlyToSender:  "Tämä vastaus menee vain alkuperäiselle lähettäjälle",
    //thisReplyWillBeSentToAll: "Tämä vastaus lähetetään kaikille",
    //timeshort:              "Klo",
    //today:                  "Tänään",
    //settingsapplication:    "Sovellusasetukset",
    //bookingTypePAbsent:     "E-poissaolo", //
    //nursingtimesbookings:   "Hoitoaikavaraukset",
    //nursintimesDiffer:       "Eri merkintöjä",
    //nursingtimeunreserved:  "Hoitoaikoja varaamatta! Ota yhteyttä päiväkotiin!",
    //passwordChange:         "Salasanan vaihto",
    //personNotFound:         "Henkilöä ei löydy",
    //reminders:              "Muistutukset",
    //reservenursingtimes:    "Hoitoajat/poissaolot",
    //saveabsences:           "Poissaolo(t) talletettu",
    //savednursingtimes:      "Hoitoaikavaraus/\nvaraukset talletettu",
    //showmessagetootherparents: "Näytä viestiketju perheen muille huoltajille",
    //till:                   "Asti",

    //waitingservertosavedata:"Talletetaan tietoja palvelimelle",
    //passwordMakeChange:     "Vaihda salasana",
    //pleasecopycontent:      "Please copy and save your content into a separate file: ",
    //replyToSenderOnly:      "Vastaa vain alkuperäiselle lähettäjälle",
    //replyToAll:             "Vastaa kaikille",
    //willbelocked:           "Hoitoaikavaraukset lukittuna",
    //yourmsgwasnotsentbackup: "Viesti ei todennäköisesti mennyt perille. Kopioi viestisi talteen ja yritä myöhemmin uudelleen.",

    en:
    //abandonMessagesCheckHeader: "Abandon message?",
    //abandonmessagecheck:    "Do you wish to abandon the message you've written?",
    //abandonNo:              "Cancel",
    //abandonYes:             "Yes, abandon message",
    //addrecipientshere:      "Add recipients",
    //change:                 "Change",
    //childinfo:              "Child details",
    //choosecentre:           "Choose day care centre",
    //choosechild:            "Choose child",
    //dataloading:            "Loading data",
    //dates:                  "Date(s)",
    //daycarecentre:          "Day care centre",
    //dayshort:               "Day",
    //defaulttime:            "Default time",
    //error1:                 "Error: Message may not have been sent due to a connection or server error.",
    //errorCheckPhonemubers:  "Check phonenumber(s)",
    //errorLostToken:         "Login information lost",
    //errorLoggingLogOut:     "Logout couldn't be stored to the database",
    //errorPhoneNumberFormat: "Invalid phone number format",
    //errorPhoneNumberLength: "Invalid phone number length (10-15)",
    //errorSavingEditedFailed: "Could not find data to save. \nSaving failed",
    //errorSavingBookings: "Saving day care booking(s) failed!",
    //errorTypeNotAccpeted:   "Booking type not accepted",
    //errorUserUnKnown:       "Unrecognised user",
    //infoChangesToLockedPeriod: "Absence/sick entry has been added for a locked period. The initial booked care time will still be counted into the total hours of the month.",
    //loadingpicture:         "Loading picture",
    //loadingPossibleRecipients: "Loading possible recipients",
    //messagesend:            "Send message",
    //settingNo:              "No",
    //nopicture:              "No picture available",
    //nursingtimeavailable:   "Available care time", //still left to book?,
    //bookingPartialFailed:   "All bookings can't be made!",
    //nursingtimeused:        "Used day care time",  //Spent?
    //persons:                "Persons",
    //reloadmessages:         "Refresh messages list",
    //bookingmake:       "Make bookings",
    //selectchildfirst:       "Please select child first",
    //selectall:              "Select all",
    //selectallRemove:        "Remove all selections",
    //startandendtimes:       "Start and end times",
    //summary:                "Summary",
    // "Tämä viesti on alun perin lähetetty usealle perheelle. Tämä viestiketju on kuitenkin vain sinun perheesi ja päiväkodin välinen.",
    //thisIsAMassThread:      "The original sender sent the same message also to other people.",
    //thisReplyOnlyToSender:  "This reply will be sent only to the original sender", // TODO: tarkistettava miten oikeasti toimii: lähetetäänkö lähettäjätyöntekijälle vai ryhmälle
    //thisReplyWillBeSentToAll: "This reply will be sent to all",
    //timeshort:              "Time",
    //today:                  "Today",
    //bookingTypePAbsent:     "Absence, informed beforehand",
    //settingsapplication:    "Application settings",
    //nursingtimesbookings:   "Day care bookings",
    //nursintimesDiffer:       "Differences",
    //nursingtimeunreserved:  "Unbooked care times! Contact the day care centre!",
    //passwordChange:         "Password change",
    //personNotFound:         "Person not found",
    //reminders:              "Reminders",
    //reservenursingtimes:    "Care times/absences",
    //saveabsences:           "Absence(s) saved",
    //savednursingtimes:      "Care time booking(s) saved",
    //showmessagetootherparents: "Show the message thread to the other caretakers in the family",
    //till:                   "Till",
    //waitingservertosavedata:"Saving data to server",
    //passwordMakeChange:     "Change password",
    //pleasecopycontent:      "Please copy and save your content into a separate file: ",
    //replyToAll:             "Reply to all",
    //replyToSenderOnly:      "Reply to original sender only",
    //willbelocked:           "Care time bookings are locked until",
    //yourmsgwasnotsentbackup: "Your message may not have been sent. Please backup your text and try again later.",


sv:
    //abandonMessagesCheckHeader: "Avstå från meddelandet?",
    //abandonmessagecheck:    "Vill du överge meddelandet du har skrivit?",
    //abandonNo:              "Avbryta",
    //abandonYes:             "Ja, avstå från meddelandet",
    //addrecipientshere:      "Lägg till fler mottagare",
    //address:                "Adress",
    //change:                 "Ändra",
    //childinfo:              "Barn information",
    //choosecentre:           "Välj daghem",
    //choosechild:            "Välj barn",
    //dataloading:            "Laddar data",
    //dates:                  "Datum",
    //daycarecentre:          "Vårdplats",
    //dayshort:               "Dagen",
    //defaulttime:            "Standard tid",
    //error1:                 "Fel: Meddelandet kanske inte har skickats på grund av en anslutning eller ett serverfel.",
    //errorCheckPhonemubers:  "Säkerställ telefonnummer(na)",
    //errorLoggingLogOut:     "Logga ut kunde inte lagras i databasen",
    //errorLostToken:         "Inloggningsinformation förlorad",
    //errorPhoneNumberFormat: "Felaktig telefonnummerformat",
    //errorPhoneNumberLength: "Felaktig telefonnummer längd (10-15)",
    //errorSavingEditedFailed: "Det finns inte information som kunde sparas. \nLagring misslyckade",
    //errorSavingBookings: "Vårdtidsbokning(s) misslyckades!",
    //errorTypeNotAccpeted:   "Typ av bokning accepteras inte",
    //errorUserUnKnown:       "Okänd användare",
    //infoChangesToLockedPeriod: "En frånvarande / sjuk anteckning har registrerats på låst perioden. Den initiala bokade behandlingstiden räknas till använda timmarna.",
    //loadingpicture:         "Laddar bild",
    //loadingPossibleRecipients: "Laddar möjliga mottagare",
    //messagesend:            "Skicka meddelandet",
    //settingNo:              "Nej",
    //nopicture:              "Ingen bild tillgänglig",
    //nursingtimeavailable:   "Tillgänglig vårdtid",
    //nursingtimeused:        "Använd vårdtid", //käännös, begagnad?
    //persons:                "Personerna",
    //reloadmessages:         "Uppdatera meddelandelistan", // käännös
    //bookingPartialFailed:   "Alla bokningar kunde inte inställas!", //Aka
    //saving:                 "Sparande...",
    //selectchildfirst:       "Välj barnet först!",
    //selectall:              "Välj alla",
    //selectallRemove:        "Ta bort alla val",
    //startandendtimes:       "Start och slut gånger",
    //summary:                "Sammanfattning",
    //thisIsAMassThread:      "Den ursprungliga avsändaren skickade det samma meddelande också till andra mottagare.", //todo käännös voi olla virheellinen
    //thisIsAMassThread:     VANHA KÄÄNNÖS, ÄLÄ KÄYTÄ SUORAAN "Detta meddelande skickades ursprungligen till flera mottagare. Andra mottagare ser inte ditt svar, du kommer bara att svara på deltagarna i den här tråden.",
    //thisReplyOnlyToSender:  "This reply will be sent only to the original sender", // TODO: tarkistettava miten oikeasti toimii: lähetetäänkö lähettäjätyöntekijälle vai ryhmälle
    //thisReplyWillBeSentToAll: "This reply will be sent to all",
    //timeshort:              "Tid",
    //today:                  "Den här dagen",
    //youWereLoggedOut401:    "Användaren loggades automatiskt ut, sessionen gick ut", //AKa
    //bookingTypePAbsent:     "I förväg informerad frånvaro",  //?
    //settingsapplication:    "Applikation inställningar",
    //nursingtimesbookings:   "Vårtids bokningar",
    //nursintimesDiffer:      "Olika bokningar",
    //nursingtimeunreserved:  "Vårdtiderna inte bokade! Kontakta daghemmet!",
    //passwordChange:         "Lösenords ändring",
    //personNotFound:         "Person som inte hittades",
    //reminders:              "Påminnelsar",
    //reservenursingtimes:    "Vårdtiderna/frånvaro",
    //saveabsences:           "Frånvaro/frånvaronerna sparade",
    //savednursingtimes:      "Vårdstiden/\nvårdstiderna sparade",
    //showmessagetootherparents: "Visa tråden till de andra vårdgivarna i familjen",

    //waitingservertosavedata:"Spara data till servern",
    //passwordMakeChange:     "Ändra lösenord",
    //pleasecopycontent:      "Kopiera och spara ditt innehåll i en separat fil: ",
    //replyToAll:             "Svara alla",
    //replyToSenderOnly:      "Svara bara på den ursprungliga avsändaren",
    //willbelocked:           "Vårdtidsbokningarna är låsta till",
    //yourmsgwasnotsentbackup: "Ditt meddelande kanske inte har skickats. Säkerhetskopiera din text och försök igen senare.",


*/

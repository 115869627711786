import { types } from './actionTypes';
import Moment from 'moment';

const ChildrenReducer = (state, action) => {
  const payload = action.data

  /*console.log('CHILDREN REDUCER.'+
              //"\n action: ", action +
              //"\n action.type ", action.type+
              "\n state: ",state);*/

  switch (action.type) {
    case types.LOADING_CHILDREN:
      return {
        ...state,
        state: types.LOADING_CHILDREN
      }
    case types.LOAD_SUCCESS_CHILDREN:
      //console.log('CHILDREN REDUCER. CHILDREN LOAD OK payload: ', payload);
      //payload.Start = Moment (payload.Start); //new Date().setHours(8,0,0);//setHours(8,15,0) asettaa myös defaultti minuutit
      //payload.End   = Moment (payload.End);   //new Date().setHours(16,0,0);

      payload.ListChildren.forEach(child => {
        child.Placements.forEach(pl => {
          pl.Start = Moment(pl.Start);
          pl.End = pl.End === null ? null : Moment(pl.End);
        });
      });

      return {
        ...state,
        Code: 200,
        state: types.LOAD_SUCCESS_CHILDREN,
        ChildList: payload.ListChildren,
        ShortestLockTime: payload.ShortestLockTime
      }
    case types.LOAD_FAILED_CHILDREN:
      //console.log('CHILDREN REDUCER. CHILDREN LOAD FAILED payload: ', payload);
      return {
        ...state,
        Code: payload.Code,
        state: types.LOAD_FAILED_CHILDREN
      }
    case types.LOADING_CHILDINFO:
      return {
        ...state,
        childInfoState: types.LOADING_CHILDINFO
      }
    case types.LOAD_SUCCESS_CHILDINFO:
      return {
        ...state,
        childInfoState: types.LOAD_SUCCESS_CHILDINFO,
        ChildList: childInfoReducer(state.ChildList, payload),
      }
    case types.LOAD_FAILED_CHILDINFO:
      return {
        ...state,
        childInfoState: types.LOAD_FAILED_CHILDINFO
      }

    case types.SAVING_CHILDINFO:
      return {
        ...state,
        childInfoState: types.SAVING_CHILDINFO,
      }
    case types.SAVE_SUCCESS_CHILDINFO:
      //console.log('CHILDREN REDUCER. SAVE_SUCCESS_CHILDINFO payload: ', payload);
      return {
        ...state,
        childInfoState: types.SAVE_SUCCESS_CHILDINFO,
      }
    case types.SAVE_FAILED_CHILDINFO:
      //console.log('CHILDREN REDUCER. SAVE_FAILED_CHILDINFO payload: ', payload);
      return {
        ...state,
        childInfoState: types.SAVE_FAILED_CHILDINFO,
      }
    case types.LOAD_PERSON_SETTINGS_SUCCESS: 
      // Note: SettingsReducer also reacts this action, we update only email here
      return {
        ...state,
        ChildList: parentsSettingsReducer(state.ChildList, payload)
      }
    case types.SAVE_PERSON_SETTINGS_SUCCESS:
      return {
        ...state,
        ChildList: parentsSettingsReducer(state.ChildList, payload)
      }
    case types.UPDATING_BACKUP_PARENT:
    case types.ADDING_BACKUP_PARENT: //TODO: childinfo
      return {
        ...state,
        childInfoState: action.type,
      }
    case types.UPDATE_BACKUP_PARENT_SUCCESS:
    case types.ADD_BACKUP_PARENT_SUCCESS:
      return {
        ...state,
        childInfoState: types.SAVE_SUCCESS_CHILDINFO,
      }
    case types.UPDATE_BACKUP_PARENT_FAILED:
    case types.ADD_BACKUP_PARENT_FAILED:
      return {
        ...state,
        childInfoState: types.SAVE_FAILED_CHILDINFO,
      }

    case types.LOADING_DELETE_BACKUP_PARENT:
      return {
        ...state,
        backupParent: {
          ...state.backupParent,
          deleteStatus: action.type,
        }
      }

    case types.DELETE_BACKUP_PARENT_SUCCESS:
      return {
        ...state,
        backupParent: {
          ...state.backupParent,
          deleteStatus: types.DELETE_BACKUP_PARENT_SUCCESS,
        }
      }

    case types.DELETE_BACKUP_PARENT_FAILED:
      return {
        ...state,
        backupParent: {
          ...state.backupParent,
          deleteStatus: types.DELETE_BACKUP_PARENT_FAILED,
          deleteCode: payload.Code
        }
      }

    default: return state;
  }
}

// Updates child entry on GetChildInfo request
const childInfoReducer = (childList, payload) => {
  let updated = false;

  const newChildList = childList.reduce((newArr, element) => {
    if (element.Id == payload.PersonID) {
      element.Info = {
        Admin: payload.Admin,
        Allergy: payload.Allergy,
        ParentsInfo: payload.ParentsInfo,
        PersonId: payload.PersonID, //This is useless here but lets save it for now
        Phonenumbers: payload.Phonenumbers,
        SavedInfoType: payload.SavedInfoType,
      }

      updated = true;
    }

    newArr.push(element);
    return newArr;
  }, []);

  if (!updated) {
    newChildList.push({
      Id: payload.PersonID,
      Info: {
        Admin: payload.Admin,
        Allergy: payload.Allergy,
        ParentsInfo: payload.ParentsInfo,
        PersonId: payload.PersonID, //This is useless here but lets save it for now
        Phonenumbers: payload.Phonenumbers,
        SavedInfoType: payload.SavedInfoType,
      }
    });
  }

  return newChildList;
}

// Update child entry on Get/SavePersonSettings
const parentsSettingsReducer = (childList, payload) => {
  let data

  if (payload instanceof Array) {
    data = payload[0];
  } else {
    data = payload;
  }

  const resultList = childList.reduce((newChildArr, childElem) => {
    let updated = false;

    if (childElem.Info?.ParentsInfo) {
      const newParentsInfo = childElem.Info.ParentsInfo.reduce((newArr, element) => {
        if (element.PersonID === data.PersonId) {
          element.EmailAddress = data.HomeEmail;

          updated = true;
        }

        newArr.push(element);

        return newArr;
      }, []);

      childElem.Info.ParentsInfo = newParentsInfo;

      //Entry didn't exists, add it
      if (!updated) {
        // if not existing found create new
        childElem.Info.ParentsInfo.push({
          PersonID: data.PersonId,
          EmailAddress: data.HomeEmail,
        })
      }

    } else {
      // No existing ParentsInfo so create new array of parents
      childElem.Info = {
        ...childElem.Info,
        ParentsInfo: [
          {
            PersonID: data.PersonId,
            EmailAddress: data.HomeEmail,
          }
        ]
      }
    }

    newChildArr.push(childElem);
    return newChildArr;
  }, []);

  return resultList;
}

export default ChildrenReducer;
import React, { useEffect, useState } from "react";
//import Languages from "../../../translations"
//import makeStyles from '@mui/styles/makeStyles';

//import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import MoreIcon from "@mui/icons-material/MoreVert";
import MenuIcon from "@mui/icons-material/Menu";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import Drawer from "@mui/material/Drawer";
import AddReactionIcon from "@mui/icons-material/AddReaction";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Paper from "@mui/material/Paper";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";

import Fab from "@mui/material/Fab";
import Button from "@mui/material/Button";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";

import AddAlarmIcon from "@mui/icons-material/AddAlarm";
import EditNotificationsIcon from "@mui/icons-material/EditNotifications";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import EuroIcon from "@mui/icons-material/Euro";
import CBTBottomMoreMenu from "./CBTBottomMoreMenu";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Badge from "@mui/material/Badge";
import MailIcon from "@mui/icons-material/Mail";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import CTBHeaderNav from "../CareTimeBookingHeaderNav/CTBHeaderNav";
import Container from "@mui/material/Container";
import Slide from "@mui/material/Slide";
import PropTypes from "prop-types";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import ListItemText from "@mui/material/ListItemText";
import OutlinedInput from "@mui/material/OutlinedInput";

import Checkbox from "@mui/material/Checkbox";
import moment from "moment";

const actions = [
  {
    icon: <AddAlarmIcon />,
    name: "Add",
    TooltipClasses: { backgroundColor: "white" },
  },
  { icon: <EditNotificationsIcon />, name: "Edit" },
  { icon: <DeleteSweepIcon />, name: "Delete" },
  { icon: <PersonOffIcon />, name: "Absence" },
];

const CTBBottomNav = (
  {
    classes,
    handleInfo,
    handleSave,
    CTBList,
    nursingtimes,
    names,
    views,
    personName,
    handleNames,
    handleView,
    view,
    monthChange,
  },
  props
) => {
  const [open, setOpen] = React.useState(false);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [CTBcount, setCTBcount] = useState(CTBList);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    anchorOrigin: {
      vertical: "center",
      horizontal: "left",
    },
    getContentAnchorEl: null,
    sx: { mb: 10 },
  };
  useEffect(() => {
    setCTBcount(CTBList);
  }, [CTBList]);
  const handleDrawerOpen = () => {
    setDrawerOpen(!drawerOpen);
  };
  const SDactions = actions;

  return (
    <>
      <Backdrop open={open} />
      <div className={classes.appBarContainer}>
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.bottomToolBar}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleInfo}
            >
              <MenuIcon />
            </IconButton>

            <IconButton color="inherit" onClick={handleDrawerOpen}>
              <EventRepeatIcon />
            </IconButton>

            <IconButton color="inherit" onClick={handleDrawerOpen}>
              <EventRepeatIcon />
            </IconButton>
            <CBTBottomMoreMenu classes={classes} />
          </Toolbar>
          {drawerOpen && (
            <AppBar className={classes.navAppBar}>
              <Toolbar>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel>näkymä</InputLabel>
                    <Select
                      sx={{ color: "success.main" }}
                      value={view}
                      label="näkymä"
                      onChange={handleView}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "200px",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "200px",
                          horizontal: "left",
                        },
                      }}
                    >
                      {views.map((view2) => (
                        <MenuItem key={view2} value={view2}>
                          {view2}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>

                <IconButton onClick={() => monthChange("prev")}>
                  <ArrowBackIosIcon />
                </IconButton>
                <div className={classes.careTimeMonthTitle}>
                  <Typography
                    color="textSecondary"
                    sx={{
                      textAlign: "center",
                      fontWeight: "Medium",
                      fontSize: 13,
                    }}
                  >
                    {"Helmikuu"}
                  </Typography>

                  <Typography
                    color="textSecondary"
                    sx={{
                      textAlign: "center",
                      fontWeight: "Medium",
                      fontSize: 12,
                    }}
                  >
                    {"2022"}
                  </Typography>
                </div>

                <IconButton onClick={() => monthChange("next")}>
                  <ArrowForwardIosIcon />
                </IconButton>

                <Box sx={{ flexGrow: 1 }} />

                <Box sx={{ minWidth: 120 }}>
                  <FormControl sx={{ m: 1, width: 120 }} size="small">
                    <InputLabel>Lapset</InputLabel>
                    <Select
                      sx={{ color: "success.main" }}
                      multiple
                      value={personName}
                      onChange={handleNames}
                      input={<OutlinedInput label="Tag" />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {names.map((name) => (
                        <MenuItem key={name.FirstName} value={name.Id}>
                          <Checkbox
                            checked={personName.indexOf(name.Id) > -1}
                          />
                          <ListItemText primary={name.FirstName} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Toolbar>
            </AppBar>
          )}
        </AppBar>
      </div>
    </>
  );
};

export default CTBBottomNav;

import React from 'react';
import KeyboardCommandKeyIcon from '@mui/icons-material/KeyboardCommandKey';
import Languages  from '../../../translations';


const HelpTooltip = () => {
  return(
    <>
    <h5 style={{textAlign: "center", width: "100%", fontSize: "medium", marginTop: "5px"}}>{Languages.help}</h5>
    <hr />
    <p><KeyboardCommandKeyIcon fontSize="small"/> {Languages.press} </p>
    <div style={{width: "100%", textAlign: "center"}}>
      <p style={{backgroundColor: "white", textAlign: "center", marginLeft: "20%", marginRight: "20%", color: "#4377BA", fontSize: "medium"}}>
        <b>F1</b>
      </p>
    </div>
    </>
  )
}

export default HelpTooltip;
import React from "react";
import { history } from "../../Router";
import {useStateValue} from "../../State";
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import AttributionIcon from '@mui/icons-material/Attribution';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import Languages from "../../translations";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChildProfilePicture from "../../Components/ChildProfilePicture";



const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const PermissionNotifications = ({ onClose }) => {
  const [expanded, setExpanded] = React.useState(false);
  const { state } = useStateValue();
  const { mainstats, children } = state;

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleRedirectToPermissions = () => {
    if(history.location.pathname === '/permissions') {
      onClose()
    }
    else {
      history.push("/permissions")
    }
  }

  const handleRedirectToPermissionQueries = () => {
    if(history.location.pathname === '/permissionqueries') {
      onClose()
    }
    else {
      history.push({
        pathname: "/permissionqueries",
        })
    }
  }

  const handleRedirectToChildPermissions = (numberPerChild) => {
    state.mainstats.childToOpen = numberPerChild.ChildId
    history.push({
      pathname: "/permissions",
    })
    onClose()
  }

  const handleRedirectToAdultConsents = () => {
    state.mainstats.openAdultConsents = true
    if(history.location.pathname === '/permissions') {
      onClose()
    }
    else {
    history.push("/permissions");
    onClose()
    }
  }

  const checkName = (numberPerChild) => {
  const childName = children.ChildList.find(child => child.Id == numberPerChild.ChildId)
  return childName.FirstName
  }

  if(state.mainstats.TotalUnansweredPermissions === 0 || state.mainstats.TotalUnansweredPermissions === null) {
    return null
  }
  else {
    return(
      <>
        <Divider textAlign="left"><Chip label={Languages.permissionQuestionsGen} sx={{bgcolor: "#002E6D", textTransform: "uppercase"}} /></Divider>
          <Card sx={{ bgcolor: "#FFEBD4", border: "1px solid #EE5711", borderLeft: "7px solid #EE5711", margin: "5px" }}>
            <CardHeader
              avatar={
                <Avatar sx={{ bgcolor: "#EE5711" }} aria-label="Permissions icon">
                  <LiveHelpIcon />
                </Avatar>
              }
              action={
                <Tooltip title={Languages.awMoveToPerm} arrow>
                  <IconButton aria-label={Languages.awMoveToPerm} onClick={handleRedirectToPermissions}>
                    <FollowTheSignsIcon sx={{color: "#EE5711"}} />
                  </IconButton>
                </Tooltip>
              }
              title={<p style={{color: "black", fontSize: "large", marginBottom: "0"}}><b>{Languages.permUnansweredPermissions}!</b></p>}
              subheader={<>{state.mainstats.TotalUnansweredPermissions}&nbsp;{Languages.permUnansweredPerms}</>}
            />
            <CardActions disableSpacing>
              <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label={Languages.awShowMore}
                title={Languages.awShowMore}
              >
                <ExpandMoreIcon sx={{color: "#EE5711"}}/>
              </ExpandMore>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent>
                <List dense>
                  <div>
                    {(state.mainstats.TotalUnansweredChildPermPermissions !== null && typeof state.mainstats.ToltaUnansweredChildPermPermissions !== undefined && state.mainstats.TotalUnansweredChildPermPermissions > 0)
                    ?
                    <>
                      <Divider 
                        textAlign="left" 
                        sx={{
                          "&::before, &::after": {
                            color: "black", 
                            borderColor: "black"
                        }}}>
                          <b style={{color: "black"}}>{Languages.permMunicipalPermissionQuestions}</b>
                      </Divider> 
                      {(mainstats.UnansweredChildPermPermissions.map((numberPerChild) => {
                        if(numberPerChild.UnansweredPermPerms > 0 )
                          return(
                            <ListItem 
                              key={numberPerChild.ChildId} 
                              button 
                              divider 
                              id={numberPerChild.ChildId} 
                              sx={{borderBottom: "1px solid black"}} 
                              onClick={() => handleRedirectToChildPermissions(numberPerChild)}
                              secondaryAction={
                                <ChevronRightIcon sx={{color: "#EE5711"}} />
                              }   
                            >
                              <ListItemAvatar>
                                <ChildProfilePicture childId={numberPerChild.ChildId}/>
                              </ListItemAvatar>
                              <div style={{display: "flex"}}>
                                <ListItemText 
                                  sx={{color: "black"}} 
                                  primary={checkName(numberPerChild)}
                                  secondary={<i>{numberPerChild.UnansweredPermPerms} {Languages.permUnansweredPerms}</i>} /> 
                              </div>
                            </ListItem>              
                          )
                        else null})
                      )   } 
                    </>
                :
                null
                } 
              </div>

              {state.mainstats.UnansweredParentConsents === 0 || state.mainstats.UnansweredParentContents === null 

          ?
          null
          :
          <>
        <Divider textAlign="left" sx={{"&::before, &::after": {
          color: "black", borderColor: "black"}}}><b style={{color: "black"}}>{Languages.permGuardiansConsents}</b></Divider>
          <ListItem
          onClick={handleRedirectToAdultConsents}
          
            button 
            divider 
            sx={{borderBottom: "1px solid black"}}
            secondaryAction={
              <ChevronRightIcon sx={{color: "#EE5711"}} />}
            >
              <ListItemAvatar sx={{textAlign: "center", color: "#EE5711"}}><AttributionIcon fontSize="large"/></ListItemAvatar>
              <ListItemText 
                sx={{color: "black"}}
                primary={Languages.permNeedConsent}
                secondary={<i>{state.mainstats.UnansweredParentConsents}  {Languages.awUnansConsent}</i>}
              />
              </ListItem> 
        </>
        }
              
              {state.mainstats.UnansweredShortTermPermissions === 0 || state.mainstats.UnansweredShortTermPermissions === null 
          ?
          null
          :
          <>
              <Divider textAlign="left" sx={{"&::before, &::after": {
          color: "black", borderColor: "black"}}}><b style={{color: "black"}}>{Languages.permPermissionQueries}</b></Divider>
            <ListItem 
              onClick={handleRedirectToPermissionQueries} 
              button 
              divider 
              sx={{borderBottom: "1px solid black"}}
              secondaryAction={
                <ChevronRightIcon sx={{color: "#EE5711"}} />
              }
            >
              <ListItemAvatar sx={{textAlign: "center", color: "#EE5711"}}><QuestionAnswerIcon fontSize="large"/></ListItemAvatar>
              <ListItemText 
                sx={{color: "black"}}
                primary={Languages.permOneOrMoreChildrenNoAnswer}
                secondary={<i>{state.mainstats.UnansweredShortTermPermissions} {Languages.permUnansweredPerms}</i>}
              />
              </ListItem> 
              </>
  }
      </List>
        </CardContent>
      </Collapse>
    </Card>
    </>
    )
  }
}

export default PermissionNotifications;
import { types } from './actionTypes';

const CentreInfoReducer = (state, action) => {

switch(action.type) {

  case types.LOADING_PL_CENTRE_INFOS:
    return {
      ...state,
      placements: {
        status: types.LOADING_PL_CENTRE_INFOS,
      }
    }

  case types.PL_CENTRE_INFOS_LOAD_SUCCESS:
    return {
      ...state,
      placements: {
        status: types.PL_CENTRE_INFOS_LOAD_SUCCESS,
        Code: action.Code,
        data: action.data,
      }
    }  

  case types.PL_CENTRE_INFOS_LOAD_FAILED:
    return {
      ...state,
      placements: {
        status: types.PL_CENTRE_INFOS_LOAD_FAILED,
        Code: action.data.Code
      }
    }

    default:
  }
    
  return state;
}


export default CentreInfoReducer;

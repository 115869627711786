import React from "react";
import Typography from "@mui/material/Typography";
import Languages              from '../../../translations';

const GuardianConsentFailed = ({isShown, consentCode}) => {

    if(!isShown) {
        return(<div></div>)
    }

    return(
        <Typography 
        id="test_guardian_consents_errCouldNotLoadData" 
        data-testid="guardian_consents_errCouldNotLoadData" 
        component={"span"}>
        {Languages.errCouldNotLoadData + " (" + consentCode + ") "}
        </Typography>
    )

}

export default GuardianConsentFailed;


import React, { useState, useEffect } from "react";
import "./loginoptions.css";
import Languages from "../../../translations";
import { CircularProgress } from "@mui/material";
import Box from '@mui/material/Box';
import CookieNote from "../../../Components/CookieNote";
import ButtonMAT from '@mui/material/Button';
import { modes } from "..";


export const ESuomiButton = ({ settingsStatus, buttonStyle, onStrongLogin }) => {
  if (settingsStatus) {
    return (
      <ButtonMAT
        id="test_LoginOptions_eSuomiBtn"
        color="primary"
        style={{ textAlign: "center", color: "#ffffff", backgroundColor: "#4377BA", width: "100%", height: "70px", fontWeight: "bold" }} > <CircularProgress /></ButtonMAT>
    );
  }
  return (
    <ButtonMAT id="test_LoginOptions_eSuomiBtn" color="primary"
      style={{ textAlign: "center", color: "#ffffff", backgroundColor: "#4377BA", width: "100%", height: "70px", fontWeight: "bold" }}
      onClick={onStrongLogin}> {Languages.loginStrongIdentification} </ButtonMAT>
  );
}

export const OptionsLayout = ({ children }) => (
  <React.Fragment>
    <div className="LoginValinnat"></div>
    {children}
  </React.Fragment>
);

const LoginOptions = ({ authSettings, onStrongLogin, onPasswordLoginSelect, settingsStatus, cookiesAccepted, onCookieAccept }) => {
  const [buttonStyle, setButtonStyle] = useState({
    width: "40%",
    height: "180px",
    marginLeft: "10%"
  })

  useEffect(() => {
    if (authSettings === modes.passwordAndStrong) {
      setButtonStyle({
        width: "40%",
        height: "130px",
        marginLeft: "5%"
      });
    } else {
      setButtonStyle({
        width: "80%",
        height: "130px",
        marginLeft: "10%"
      });
    }
  }, [authSettings])

  //const handleKeyUp = (event) => {
  //if (event.key === "Enter") {
  // What should happen???
  // this.logIn();
  // }
  //};

  const show = authSettings == modes.passwordAndStrong || authSettings == modes.strongOnly

  // Don't render anything if correct mode is not set
  if (!show) {
    return (null);
  }

  if (!cookiesAccepted) {
    return (
      <OptionsLayout>
        <Box paddingBottom={5}>
          <CookieNote onCookieAccept={onCookieAccept} cookiesAccepted={cookiesAccepted} />
        </Box>
      </OptionsLayout>
    )
  }

  return (
    <OptionsLayout>
      <Box paddingBottom={5}>
        {authSettings === modes.passwordAndStrong &&
          <div>
            <ButtonMAT 
              id="test_LoginOptions_UID_PwBtn"
              onClick={onPasswordLoginSelect}
              style={{ fontWeight: "bold", textAlign: "center", color: "#ffffff", backgroundColor: "#4377BA", width: "100%", height: "70px" }}
              > 
              {Languages.loginUsernameAndPassword}
            </ButtonMAT>
            <ESuomiButton 
              settingsStatus={settingsStatus}
              buttonStyle={buttonStyle}
              onStrongLogin={onStrongLogin} 
              />
          </div>
        }
        {authSettings === modes.strongOnly &&
          <ESuomiButton settingsStatus={settingsStatus} buttonStyle={buttonStyle} onStrongLogin={onStrongLogin} />
        }
      </Box>
    </OptionsLayout>
  );
}

export default LoginOptions;
import React                  from  "react";
import Languages              from  "../../../translations";
import PedagDocFolder         from  "../PedagDocFolder";
import GrowthFolderSortingBar from  './GrowthFolderSortingBar';
import GrowthFolderEmpty      from  './GrowthFolderEmpty';
import                              "../growthfolder.css";
import Typography from '@mui/material/Typography';


const GrowthFolderSuccess = ({ growthFolder, growthFolderFunctions }) => {

  function returnGrowthFolderComponents(growthFolder, growthFolderFunctions) {
    if (typeof growthFolder          === 'undefined' || growthFolder          === null ||
        typeof growthFolderFunctions === 'undefined' || growthFolderFunctions === null) {
      return(<Typography>{Languages.errorUnknown + "(777)."}</Typography>);
    }

    else if (growthFolder.folders.listgrowthfolder.length === 0){
      return(
        <GrowthFolderEmpty />  
      );
    }  

    else {
      return (
        growthFolder.folders.listgrowthfolder.map((folder) => {
          return (
            <div className="growthfolder-success__folderblock-marginBottom"
              key = {folder.imageStoreFolder.Id}>
                <PedagDocFolder
                  Folder = {folder}
                  growthFolderFunctions = {growthFolderFunctions}
                />
            </div>
          );
        } 
      ))
    }
  }

  return (
    <React.Fragment>
      <GrowthFolderSortingBar
        growthFolderFunctions = {growthFolderFunctions}
        growthFolder = {growthFolder} 
      />
      {returnGrowthFolderComponents (growthFolder, growthFolderFunctions)}
    </React.Fragment>
  );
}

export default GrowthFolderSuccess;
import React from 'react';
import { ServerApi } from "../../internal";
import { ATTACHMENT_TYPES } from "../../Api";
import Button                          from "@mui/material/Button";
import { downloadBase64File }          from "../../utils";
import { previewBase64File }                   from "../../utils";
import Languages                       from "../../translations";
import IconButton                      from "@mui/material/IconButton";
import SaveAlt                         from "@mui/icons-material/SaveAlt";
import FileOpenIcon from '@mui/icons-material/FileOpen';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FileThreeDotsButton from './FileThreeDotsButton';
import TextButton from './TextButton';

// This renders a button for downloading a file. Change attachment type if you are using this for some other type of file
// than personAttachment

const DownloadFileButton = ({ FileName, FileMimeType, FileId, useIconButton, useThreeDots, useTextButton, text, disabled, file, isImage }) => {

  if(file === null){
    file={
      Id: FileId,
      MimeType: FileMimeType,
      Name: FileName,
      Description: ""
    }
  }
  if (useIconButton) {
    return(
      <IconButton
        data-testid={"downloadIconButton-" + FileName}
        color="primary" 
        /*style={{backgroundColor: "#000001"}}*/
        size="small"
        aria-label={"download file " + FileName} 
        component="span"
        onClick={isImage ? () => imageOnClick(FileId, FileMimeType, FileName) :
                           () => attachmentOnClick(FileId, FileMimeType, FileName)}
      >
        <FileDownloadIcon color="secondary"/>
      </IconButton>
    )
  }
  else if (useThreeDots) {
    return(
      <>
      <FileThreeDotsButton 
        file={file}
        isImage={isImage}
        imageOnClick={imageOnClick}
        attachmentOnClick={attachmentOnClick}
        previewFileOnClick={previewFileOnClick}  
      />
      </>
    )
  }
  else if (useTextButton) {
    return(
      <>
      <TextButton 
        file={file}
        isImage={isImage}
        imageOnClick={imageOnClick}
        attachmentOnClick={attachmentOnClick}
        previewFileOnClick={previewFileOnClick}
        text ={text}
        buttonDisabled = {disabled}
      />
      </>
    )
  }
  else {
    return(
      <Button 
        id="test_OpenMessage_download_file"
        style={{color: "#4377BA"}}
        aria-label="download file"
        onClick={isImage ? () => imageOnClick(FileId, FileMimeType, FileName) :
                           () => attachmentOnClick(FileId, FileMimeType, FileName)}
      >
          {Languages.generalDownloadFileAttachment + " " + FileName}
      </Button>
    )
  }
}

const imageOnClick = async (FileId, FileMimeType, FileName) => {
  const response = await new ServerApi().downloadFile(FileId, ATTACHMENT_TYPES.personAttachment);
  downloadBase64File(response.data, FileMimeType, FileName);
}

const attachmentOnClick = async (FileId, FileMimeType, FileName) => {
  const response = await new ServerApi().downloadFile(FileId, ATTACHMENT_TYPES.personAttachment);
  downloadBase64File(response.data, FileMimeType, FileName);
}

const previewFileOnClick = async (FileId, FileMimeType, FileName) => {
  const response = await new ServerApi().previewFile(FileId, ATTACHMENT_TYPES.personAttachment, FileMimeType);
  previewBase64File(response, FileMimeType, FileName);
}

export default DownloadFileButton;

import React, { useEffect, useState }                from "react";
import ContentBoxMUI          from "../../Components/ContentBoxMUI"; 
import './taskfolder.css';
import Typography from '@mui/material/Typography';
import TaskImage from './TaskImage';
import { ServerApi } from '../../internal';
import Skeleton               from '@mui/material/Skeleton';
import html2pdf     from 'html2pdf.js';
import Button       from  "@mui/material/Button";


const TaskFolder = (props) => {

  const {location} = props;
  
    const [ taskThumbnailLoaded, setTaskThumbnailLoaded ] = useState(false);

    const [ backResponse, setbackResponse ] = useState();
    useEffect(() => {
      if (!taskThumbnailLoaded) {
       fetchTaskThumbnail(location, setTaskThumbnailLoaded, setbackResponse);
      }
    }, [ taskThumbnailLoaded])

    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])

    const printToPDF = () => {
      var options = {
        pagebreak: { mode: 'avoid-all' },
        filename: `${location.state.folder.Name}.pdf`,
        margin: [10,15]
      };
      const elementToPrint = document.getElementById('pdfdiv');
      html2pdf(elementToPrint, options);
    }


  return (
    <div>
<ContentBoxMUI>
  <div id="pdfdiv" className="TaskFolderContainer"> 
            <div className="TaskFolderDescription">
            {location.state.folder.Description}
            <hr/>
            </div>
            {taskThumbnailLoaded ? 
             backResponse.map(task => {
              return task.Published ? (
                <div className="TaskFolderContainer" key={task.Id.toString()}>
                  
                  <div className = "taskTitle">
                    {task.Header !== "NULL" ? <Typography variant = 'h5'><b>{task.Header}</b></Typography> : null}
                  </div>
                  {task.Data ?
                    <div className = "taskImage">                       
                      <TaskImage image = {"data:" + task.MimeType + ";base64," + task.DataThumbnail }
                      fullimage = {"data:" + task.MimeType + ";base64," + task.Data } 
                      downloadData = {task.Data} 
                      downloadMimeType = {task.MimeType}
                      downloadFileName = {task.FileName}
                    /> 
                    </div>
                    :
                    null}
                  {task.ImgDescription !== "NULL" ? 
                    <div className = "taskDesc">
                      <div className="taskDescTextCenter">
                      <Typography>{task.ImgDescription}</Typography>
                      </div>
                    </div>
                    :
                    null}
                    <hr/>
                </div>
                  )
                :
              null})
              :
              <div>
                <div className="taskMarginBottom">
                <Skeleton variant="rectangular" height={250} ></Skeleton>
              </div>
              <div className="taskMarginBottom">
                <Skeleton variant="rectangular" height={250}></Skeleton>
              </div>
              <div className="taskMarginBottom">
                <Skeleton variant="rectangular" height={250} ></Skeleton>
              </div>
                
              </div>

}
            </div>
            <div className="taskFolderPDFDownload" data-html2canvas-ignore="true">
              <Button variant="text" style={{width:"100%"}} onClick={() => {printToPDF()}}>Tallenna PDF-tiedostona</Button>
            </div>
             
            </ContentBoxMUI>
            </div>
  );
}


const fetchTaskThumbnail = async (location, setTaskThumbnailLoaded, setbackResponse) => {
    const response = await new ServerApi().loadTaskThumbnail(location.state.childId, location.state.folder.Id);
    setbackResponse(response.data);
    setTaskThumbnailLoaded(true);
}

export default TaskFolder;
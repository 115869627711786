import React, { useState, useEffect } from "react";
import {useStateValue} from "../../State";
import Typography from "@mui/material/Typography";
import Languages              from '../../translations';
//import {isAnyPropUndefinedOrNull} from "../../utils";
import GuardianConsentSuccess from "./Components/GuardianConsentSuccess.jsx";
import GuardianConsentFailed from "./Components/GuardianConsentFailed.jsx";
import GuardianConsentLoading from "./Components/GuardianConsentLoading.jsx";

const GuardianConsents = (props) => {

  const { state, actions } = useStateValue();

  const data = state.municipalpermissionquestions.guardianConsent.data;

  const consentStatus = state.municipalpermissionquestions.guardianConsent.status;
  const consentCode = state.municipalpermissionquestions.guardianConsent.Code;

  const [ isMount, setIsMount ] = useState(true);

  useEffect(() => {
    if (isMount && props.gcPanelOpen) {
      actions.triggerLoadGuardianConsents();
      setIsMount(false);
    }

    /*return function cleanup() {
      actions.triggerCleanLoadGuardianConsents();
    }*/
  }, [isMount, setIsMount, actions, props])

  return(

    <React.Fragment>

        {consentStatus === "LOAD_GUARDIAN_CONSENTS_SUCCESS" ?
          <GuardianConsentSuccess
            guardianConsentData={data}
            isShown={consentStatus === "LOAD_GUARDIAN_CONSENTS_SUCCESS"}
            setClickAwayNoteTrigger={(set) => props.setClickAwayNoteTrigger(set)}
          /> : null}

        <GuardianConsentFailed 
          consentCode={consentCode}
          isShown={consentStatus === "LOAD_GUARDIAN_CONSENTS_FAILED"}
        />

        <GuardianConsentLoading
          isShown={consentStatus === "LOADING_GUARDIAN_CONSENTS"}
        />

    </React.Fragment>


  )
}

export default GuardianConsents;
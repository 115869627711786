import React, { useState, useEffect } from "react";
import { useStateValue } from "../../State";
import "./leops.css";

import ContentBoxMUI from "../../Components/ContentBoxMUI";
import Languages from "../../translations";
import Link from "@mui/material/Link";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import LockIcon from "@mui/icons-material/Lock";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
//import { makeStyles }   from '@mui/material/styles';
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import BlockIcon from "@mui/icons-material/Block";
import GeneralSection from "../Vasu/GeneralPart";
import ChildRow from "../Vasu/ChildRow";
import Typography from "@material-ui/core/Typography";

import { types } from "../../Reducers/actionTypes";
import { LOGOUT_REASONS } from "../../Api";
import { useAuth } from "../../Auth";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  fixedbottom: {
    float: "right !important",
    fontSize: "x-large",
    position: "fixed",
    fontWeight: "bold",
    color: "#D500F9",
    right: "0",
    bottom: "0",
    zIndex: "1040 !important",
  },
  displaynone: {
    display: "none",
  },
}));

const LeopsContainer = (props) => {
  const classes = useStyles();
  const { state, actions } = useStateValue();
  const { authActions } = useAuth();

  const GeneralPart = state.leops.GeneralPart;
  const Table3 = state.leops.Table3;

  const { history /*, location*/ } = props;

  const { children, profilePictures } = state;
  const child = children.ChildList.find(
    (c) => c.Id === props.match.params.ChildId
  );
  const picturechild = profilePictures.profilePicturesList.find(
    (p) => p.PersonID === props.match.params.ChildId
  );

  //console.log("LeopsContainer. MOUNT. Child: ", child.SSN);

  /*console.log("LeopsContainer. MOUNT. child id: ", props.match.params.ChildId,
              "\n children.state: ",children.state,
              "\n picturechild: ",picturechild,
              "\n leops id: ", child === undefined ? null : child.LeopsId);*/
  //Child data
  const [childColor, setchildColor] = useState(
    child === undefined ? null : child.BgColor
  );
  const [childpicture, setchildpicture] = useState(
    picturechild === undefined ? undefined : picturechild.ImageBase64
  );
  const [childName, setchildName] = useState(
    child === undefined ? null : child.Name
  );
  const [childFirstName, setchildFirstName] = useState(
    child === undefined ? null : child.FirstName
  );
  const [childLastName, setchildLastName] = useState(
    child === undefined ? null : child.LastName
  );
  const [LeopsId, setLeopsId] = useState(
    child === undefined ? null : child.LeopsId
  );

  const [LoadingData, setLoadingData] = useState(true);
  const [ProfilePicturesNotHandled, setProfilePicturesNotHandled] = useState(
    picturechild === undefined ? true : false
  );

  var Slides = [1, 2, 3, 4, 5];

  useEffect(() => {
    handleChildList();
    handleProfilePictures();
  });

  useEffect(() => {
    //console.log("LeopsContainer. ",state.leops.state, " LoadingData: ", LoadingData);

    switch (state.leops.state) {
      case "INITIAL_STATE":
        //console.log("LeopsContainer INITIAL_STATE. LeopsId: ", LeopsId, " child: ",child );
        if (LeopsId !== null) {
          //console.log("LeopsContainer INITIAL_STATE. Load leops");
          actions.triggerLoadLeops(LeopsId);
          setLoadingData(true);
        } else {
          history.push("/");
        }
        break;
      case types.LEOPS_HANDLED:
        //console.log("LeopsContainer LEOPS_HANDLED");
        if (LoadingData) {
          setLoadingData(false);
        }
        break;
      case types.LEOPS_LOAD_OK:
        //console.log("LeopsContainer LEOPS_LOAD_OK");
        actions.triggerLeopsHandled();
        setLoadingData(false);
        break;
      case types.LEOPS_LOAD_FAILED:
        console.log(
          "LeopsContainer LEOPS_LOAD_FAILED. Code: ",
          state.leops.Code,
          " \n Message: ",
          state.leops.Message
        );
        setLoadingData(false);
        var Msg =
          state.leops.Code === 404
            ? Languages.errorNotFound
            : state.leops.Code === 500
            ? Languages.errorBackend
            : Languages.requestFailed;

        //actions.triggerAddToSnackBarQueue({color: "#000000", message: Msg});
        //state.leops.state ="INITIAL_STATE";
        //history.goBack();

        break;
      default:
        //console.log("LeopsContainer. ",state.leops.state, " LoadingData: ", LoadingData);
        break;
    }
  }, [state.leops, LeopsId, actions, LoadingData]);

  const handleChildList = () => {
    /*console.log("LeopsContainer. handleChildList."+
                "\n children.state: ", children.state,
                "\n profilePictures.state: ",profilePictures,
                "\n state.leops.state: ", state.leops.state);*/

    switch (children.state) {
      case types.LOADING_CHILDREN:
        //console.log("LeopsContainer. LOADING_CHILDREN");
        //setLoadingData(true);
        break;
      case types.LOAD_FAILED_CHILDREN:
        //console.log("VasuContainer. handleChildList. LOAD_FAILED_CHILDREN");
        if (children.Code === 401) {
          authActions.logout(LOGOUT_REASONS.normal);
        }
        break;
      case types.LOAD_SUCCESS_CHILDREN:
        const child = children.ChildList.find(
          (c) => c.Id === props.match.params.ChildId
        );
        //console.log("LeopsContainer. Child: ", child);
        if (child != undefined) {
          //Child data
          setchildColor(child.BgColor);
          setchildName(child.Name);
          setchildFirstName(child.FirstName);
          setchildLastName(child.LastName);
          setLeopsId(child.LeopsId);

          if (profilePictures.state === "PROFILEPICTURES_INITIAL_STATE") {
            actions.triggerLoadProfilePictures();
            setProfilePicturesNotHandled(true);
          }
        }
        break;
      default:
        break;
    }
  };

  const handleProfilePictures = () => {
    /*console.log("LeopsContainer. Profilepicture state: ",profilePictures.state,
                //"\n child.ProfilePictureBase64: ", child === undefined ? undefined : child.ProfilePictureBase64,
                "\n childpicture: ", childpicture === undefined ? undefined : "OK");*/

    if (
      ProfilePicturesNotHandled /* && children.state === types.LOAD_SUCCESS_CHILDREN*/
    ) {
      switch (profilePictures.state) {
        case types.LOADING_PROFILEPICTURES:
          //console.log("LeopsContainer. LOADING_PROFILEPICTURES");
          break;
        case types.LOAD_FAILED_PROFILEPICTURES:
          setchildpicture(profilePictures.Code);
          setProfilePicturesNotHandled(false);
          break;
        case types.LOAD_SUCCESS_PROFILEPICTURES:
          var picturechild = profilePictures.profilePicturesList.find(
            (p) => p.PersonID === props.match.params.ChildId
          );
          //console.log("LeopsContainer. LOAD_SUCCESS_PROFILEPICTURES. picturechild: ", picturechild);
          if (picturechild != undefined) {
            setchildpicture(picturechild.ImageBase64);
            setProfilePicturesNotHandled(false);
          }
          break;
        default:
          break;
      }
    }
  };

  const toggleExpanded = (Form) => {
    // not need to do anything here
  };

  const handleBoxClick = (part) => {
    switch (part) {
      case 1:
        history.push("/leopsPreparingPart", {
          childpicture: childpicture,
          childFirstName: childFirstName,
          childLastName: childLastName,
          childColor: childColor,
        });
        break;
      case 2:
        history.push("/leopsDiscussionPart", {
          childpicture: childpicture,
          childFirstName: childFirstName,
          childLastName: childLastName,
          childColor: childColor,
        });
        break;
      case 3:
        history.push("/leopsSupportPart", {
          childpicture: childpicture,
          childFirstName: childFirstName,
          childLastName: childLastName,
          childColor: childColor,
          childName: childName,
          SSN: child.SSN,
        });
        break;
      case 4:
        history.push("/leopsSpringEvaluationPart", {
          childpicture: childpicture,
          childFirstName: childFirstName,
          childLastName: childLastName,
          childColor: childColor,
        });
        break;
      case 5:
        history.push("/descriptionPart", {
          childpicture: childpicture,
          childFirstName: childFirstName,
          childLastName: childLastName,
          childColor: childColor,
          vasu: false,
        });
        break;
      default:
        console.log("LeopsContainer. Avaa tuntematon taulukko: ", part);
        break;
    }
  };

  if (state.leops.state === "INITIAL_STATE") {
    //console.log("LeopsContainer INITIAL_STATE => RENDER EMPTY");
    return (
      <div className="LeopsContainer">
        <div style={{ opacity: "0.5" }}>
          <Backdrop open={true}>
            <CircularProgress />
          </Backdrop>
        </div>
      </div>
    );
  } else if (state.leops.state === types.LEOPS_LOAD_FAILED) {
    return (
      <ContentBoxMUI>
        <div>
          <Typography>
            {Languages.leopsrequestFailed + ": " + state.leops.Code}
          </Typography>
        </div>
      </ContentBoxMUI>
    );
  } else {
    return (
      <ContentBoxMUI>
        <div
          id={"test_LeopsChild_" + props.match.params.ChildId}
          className="LeopsContainer"
        >
          {LoadingData ? (
            <div style={{ opacity: "0.5" }}>
              <Backdrop open={true}>
                <CircularProgress />
              </Backdrop>
            </div>
          ) : (
            <div>
              <ChildRow
                childpicture={childpicture}
                childFirstName={childFirstName}
                childLastName={childLastName}
                childColor={childColor}
                FormId={LeopsId}
                history={history}
              ></ChildRow>

              <Box className="col-12" sx={{ mt: 3 }}>
                <GeneralSection
                  className="boxed"
                  childName={childName}
                  vasu={false}
                  callback={(status) => toggleExpanded(status)}
                ></GeneralSection>
              </Box>

              {Slides.map((item, index) => {
                if (Table3 === null && index === 2) {
                  return null;
                } else {
                  return (
                    <div>
                      <List className="col-12" sx={{ mt: -1 }}>
                        <ListItemButton
                          className="boxed"
                          sx={{
                            backgroundColor:
                              index === 0
                                ? "#a6dee0"
                                : index === 1
                                ? "#afdfc8"
                                : index === 2
                                ? "#fceebf"
                                : index === 3
                                ? "#fbdcce"
                                : "#fbcece",
                            borderRadius: 0.5,
                            mt: 1.5,
                            boxShadow: 2,
                            opacity:
                              GeneralPart.FormLocked === true ? "60%" : "",
                          }}
                          onClick={(event) => handleBoxClick(index + 1)}
                        >
                          <ListItemText
                            primaryTypographyProps={{
                              fontWeight: "Bold",
                              fontSize: 16,
                              ml: 2,
                              mb: 1,
                            }}
                            primary={
                              index === 0
                                ? "1. " + Languages.eduPlanTitle1
                                : index === 1
                                ? "2. " + Languages.eduPlanTitle2
                                : index === 2
                                ? "3. " + Languages.eduPlanTitle3
                                : index === 3
                                ? Table3 === null
                                  ? "3. " + Languages.eduPlanTitle4
                                  : "4. " + Languages.eduPlanTitle4
                                : Table3 === null
                                ? "4. " + Languages.eduPlanChildDescription
                                : "5. " + Languages.eduPlanChildDescription
                            }
                            secondary={
                              <React.Fragment>
                                <ListItemText
                                  primaryTypographyProps={{
                                    fontSize: 15,
                                    mb: 1,
                                    ml: 2,
                                  }}
                                  primary={
                                    index === 4
                                      ? Languages.eduPlanSubtitle1
                                      : index === 2
                                      ? Languages.eduPlanSubtitle1
                                      : index === 1
                                      ? Languages.eduPlanSubtitle1
                                      : Languages.eduPlanSubtitle2
                                  }
                                />
                              </React.Fragment>
                            }
                          />
                          <ListItemIcon>
                            <ArrowForwardIosIcon fontSize="small" />
                          </ListItemIcon>
                        </ListItemButton>
                      </List>
                    </div>
                  );
                }
              })}

              <div
                className={
                  GeneralPart.FormLocked === true
                    ? classes.fixedbottom
                    : classes.displaynone
                }
              >
                {Languages.logged}
                <LockIcon></LockIcon>
              </div>
            </div>
          )}
        </div>
      </ContentBoxMUI>
    );
  }
};

export default LeopsContainer;

import * as React from 'react';
import Popper from '@mui/material/Popper';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton                      from "@mui/material/IconButton";
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import InfoIcon from '@mui/icons-material/Info';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import ImageIcon from '@mui/icons-material/Image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArticleIcon from '@mui/icons-material/Article';
import SimCardAlertIcon from '@mui/icons-material/SimCardAlert';
import ErrorIcon from '@mui/icons-material/Error';
import moment from "moment";
import Languages from "../../translations";
import Typography from '@mui/material/Typography';



const useStyles = makeStyles((theme) => ({
  fileModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: "1102",
  },
  filePaper: {
    backgroundColor: theme.palette.background.paper,
    border: '5px solid #4377BA',
    borderRadius: "0px 0px 30px 0px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 2),
    zIndex: "1102",
    display: "inline",
    minWidth: "280px",
    maxWidth: "99vw",
  },
  filePaperHeaderContainer: {
    display: "inline-flex",
    width: "100%",
  },
  filePaperHeaderTextContainer: {
    width: "75%",
  },
  filePaperHeader: {
    textAlign: "start",
    font: "caption",
    fontSize: "small",
  },
  filePaperHeaderFileName: {
    textAlign: "start",
    overflow: "hidden",
    whiteSpace: "nowrap", 
    textOverflow: "ellipsis",
  },
  filePaperHeaderFileIcon: {
    width: "25%",
    textAlign: "end",
  },
  fileInfoKey: {
    marginBottom: "0px",
    display: "inline-flex"
  },
}));


const TextButton = ({ file, attachmentOnClick, imageOnClick, isImage, previewFileOnClick, text, buttonDisabled }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [showWarning, setShowWarning] = React.useState(false);
  const classes = useStyles();

  const handleClick = () => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  //console.log(file)
  const handleClickAway = () => {
    setOpen(false);
  };

  const downloadFile = () => {
    if(isImage){
      imageOnClick(file.Id, file.MimeType, file.Name);
      setOpen(false);
    }
    else {
      attachmentOnClick(file.Id, file.MimeType, file.Name);
      setOpen(false)
    }
  }

  const previewFile = () => {
    previewFileOnClick(file.Id, file.MimeType, file.Name)
    setOpen(false)
  }

  const validateMimeType = () => {
    if(file.MimeType == "application/pdf" || file.MimeType == "image/png" || file.MimeType == "image/jpeg" || file.MimeType == "application/vnd.openxmlformats-officedocument.wordprocessingml.document"){
      return null    
    }
    else {
      setShowWarning(true)
    }
  }

  const handleModalOpen = () => {
    validateMimeType()
    setOpen(false)
    setModalOpen(true)
  }

  const handleModalClose = () => {
    setModalOpen(false)
  }

  const iconManager = () => {
    if(file.MimeType == "application/pdf") {
      return(<PictureAsPdfIcon fontSize="large" color="secondary" />)
    }
    else if(file.MimeType == "application/vnd.openxmlformats-officedocument.wordprocessingml.document"){
      return(<ArticleIcon fontSize="large" color="secondary" />)
    }
    else if(file.MimeType == "image/jpeg" || file.MimeType == "image/png"){
      return(<ImageIcon fontSize="large" color="secondary" />)
    }
    else{
      return(<SimCardAlertIcon fontSize="large" sx={{color: "#e036da"}} />)
    }
  }

  const fileTypeManager = () => {
    if(file.MimeType == "application/pdf") {
      return(<p>PDF</p>)
    }
    else if(file.MimeType == "application/vnd.openxmlformats-officedocument.wordprocessingml.document"){
      return(<p>Microsoft Word</p>)
    }
    else if(file.MimeType == "image/jpeg" || file.MimeType == "image/png"){
      return(<p>{file.MimeType}</p>)
    }
    else{
      return(<p>{Languages.fileTypeWrong}</p>)
    }
  }


  return(
    <>

    <Backdrop
      sx={{ color: '#fff', zIndex: "1101" }}
      open={open}
      onClick={handleClickAway}
    />      

     
    <Popper open={open} anchorEl={anchorEl} placement="bottom-end" transition style={{zIndex: "1102"}}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
            <List>
          <ListItem disablePadding sx={{marginTop: "0px"}}>
            <ListItemButton
             onClick={downloadFile}
            >
              <ListItemIcon>
                <FileDownloadIcon sx={{color: "white"}} />
              </ListItemIcon>
              <ListItemText primary="Lataa" />
            </ListItemButton>
          </ListItem>
          <hr style={{color: "white !important", backgroundColor: "white", marginTop: "0px", marginBottom: "0px"}}/>
          <ListItem disablePadding>
            <ListItemButton onClick={previewFile}>
              <ListItemIcon>
                <FileOpenIcon sx={{color: "white"}} />
              </ListItemIcon>
              <ListItemText primary="Avaa" />
            </ListItemButton>
          </ListItem>
          <hr style={{color: "white !important", backgroundColor: "white", marginTop: "0px", marginBottom: "0px"}}/>
          <ListItem disablePadding sx={{marginBottom: "0px"}}>
            <ListItemButton onClick={handleModalOpen}>
              <ListItemIcon>
                <InfoIcon sx={{color: "white"}} />
              </ListItemIcon>
              <ListItemText primary="Tiedot" />
            </ListItemButton>
          </ListItem>
        </List>
            </Paper>
          </Fade>
        )}
      </Popper>



      <ListItemButton  disabled = {buttonDisabled} sx={{backgroundColor: "#cadafa", borderRadius: .5, boxShadow: 2, maxHeight: 6, maxWidth: 70 }} onClick={handleClick('bottom-end')}>
              <Typography color="textSecondary" sx={{textAlign:"left", fontWeight: 'medium', fontSize: 16, color: "#424242"}}>{text}</Typography>
        </ListItemButton>

      <Modal
        className={classes.fileModal}
        open={modalOpen}
        onClose={handleModalClose}
        closeAfterTransition
        //BackdropComponent={Backdrop}
        //BackdropProps={{
         // timeout: 500,
        //}}
      >
        <Fade in={modalOpen}>
          <div className={classes.filePaper}>
            <div className={classes.filePaperHeaderContainer}>
              <div className={classes.filePaperHeaderTextContainer}>
                <div className={classes.filePaperHeader}><b>{Languages.fileModalHeader}</b></div>
                <div className={classes.filePaperHeaderFileName}>{file.Name}</div>
              </div>
            <div className={classes.filePaperHeaderFileIcon}>{iconManager()}</div>

            </div>
          <hr />
            <p className={classes.fileInfoKey}><b>{Languages.fileDescription}</b></p>
            {file.Description ?
            <p>{file.Description}</p>
            :
            <p>{Languages.fileEmptyDesc}</p>
            }
            <p className={classes.fileInfoKey}><b>{Languages.fileType}</b></p>{showWarning && <ErrorIcon sx={{color: "#e036da", marginLeft: "5px"}} />}
            {fileTypeManager()}
            <p className={classes.fileInfoKey}><b>{Languages.fileUploaded}</b></p>
            <p>{moment(file.Saved).format('LLLL')}</p>
            <div style={{textAlign: "end"}}>
            <Button onClick={handleModalClose} variant="outlined" color="secondary">{Languages.generalClose}</Button>
            </div>
          </div>
        </Fade>
      </Modal>

    </>
    

  )
}

export default TextButton
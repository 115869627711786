import React        from  'react';
import Languages    from  "../../../../translations";
import ImageViewer  from  './PedagDocImageViewer';
import Button       from  "@mui/material/Button";
import GetAppIcon   from  '@mui/icons-material/GetApp';
import                    '../pedagdocimage.css';
import { useStateValue } from '../../../../State';
import { useLocation, useHistory } from 'react-router-dom';
import IconButton from '@mui/material/IconButton'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useState } from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useSwipeable } from 'react-swipeable'




const PedagDocImageSuccess = ({ growthFolder, pedagDocImageFunctions }) => {
  if (typeof growthFolder           === "undefined" || growthFolder           === null || 
      typeof pedagDocImageFunctions === "undefined" || pedagDocImageFunctions === null) {
    return(<div> {Languages.errorUnknown + "(777)."} </div> );
  }
  
  else {
    return(
      <div className="pedagdoc-imagesuccess-media-layout">
        <div className="pedagdoc-imagesuccess-media-container">
          <div className="pedagdoc-imagesuccess-media-display">
            <ImageViewer image = {growthFolder.image[0]} />
            <ImageNavigation />
          </div>
            <div className="pedagdoc-imagesuccess-download-image-button">
              <Button
                data-testid = "pedagdocimage-success__download-button" 
                variant = "text" 
                style =  {{width:"100%"}}
                startIcon = {<GetAppIcon/>}
                onClick = {() => {pedagDocImageFunctions["downloadImage"]()}} 
                block = "true"
              > {Languages.downloadMedia} </Button> 
            </div>
          </div>
      </div>
    );
  }
}
export default PedagDocImageSuccess;

const ImageNavigation = () =>{
  const {state, actions} = useStateValue()
  const {growthFolder} = state
  const location = useLocation()
  const history = useHistory()

  const pictureIds = growthFolder.images.map(x=> {return x.Id})
  const currentPictureIndex = pictureIds.indexOf(location.state.Id)
  
  console.log(growthFolder)
  
  if(location.state.Id != growthFolder.image[0].Id){
    actions.triggerLoadImage({ChildId: location.state.childId, Id: location.state.Id})
  }

  const loadPicture = (Id) =>{
    history.push("/growthfolder/media", {
      Id: Id,
      childId: location.state.childId
    })
    /* actions.triggerLoadImage({ChildId: location.state.childId, Id: Id}) */
  }

  const handlePictureChange = (direction) =>{
    //1 on oikealle ja -1 vasemmalle
    let index = pictureIds.indexOf(location.state.Id)
    if(index != -1){
      index += direction
      if(index < pictureIds.length && index >= 0){
        loadPicture(pictureIds[index])
      }
        
    }
  }
  

  const style ={
    backgroundColor:"rgba(255, 255, 255, 0.322)",
  }

  const handlers = useSwipeable({
    onSwipedRight: () => handlePictureChange(-1),
    onSwipedLeft: () => handlePictureChange(1)
  })

  if(pictureIds.includes(location.state.Id) && pictureIds.length > 1){
    return(
      <div {...handlers} className='image-nav-container'>
        <div className='image-nav-button-level'>
          {currentPictureIndex > 0 && 
          <IconButton className={'nav-button-left'} sx={style} size={"large"} onClick={()=>handlePictureChange(-1)}>
            <ArrowBackIosNewIcon fontSize={'large'}/>
          </IconButton>}
          {(currentPictureIndex + 1) != (pictureIds.length) && currentPictureIndex <= pictureIds.length &&
          <IconButton className={'nav-button-right'} sx={style} size={"large"} onClick={()=>handlePictureChange(1)}>
            <ArrowForwardIosIcon fontSize={'large'}/>
          </IconButton>
          }
        </div>
      </div>)
  }
  return null
  
}
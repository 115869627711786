import React from 'react';
import { Typography, TextField, InputAdornment, IconButton/*, Grid*/ } from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import withStyles from '@mui/styles/withStyles';


const styles = {
  // root: {
  //   background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  //   borderRadius: 3,
  //   border: 0,
    
  //   height: 48,
  //   padding: '0 30px',
  //   boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  //   color: 'black'
  // },
  //textField: {
  //  color: '#00FF00'
  //},
  // label: {
  //   textTransform: 'capitalize',
  // },
};

const InputField = React.forwardRef(({ fieldId, label, ariaLabel, value, onChange, classes }, ref) => {

  return (
    <Typography
      component={'span'}
      variant={'body2'}
      //color="textSecondary"
      >
      <TextField id={fieldId}
        //ref={this.test_username}
        autoFocus
        aria-label={ariaLabel}

        label={label}
        
        value={value}
        onChange={onChange}
        inputRef={ref}
        inputProps={{className:"usernamepasswordinput123"}}
        InputProps={{
          startAdornment: (
            <InputAdornment
              position="start">
              <IconButton disabled size="large">
                <AccountCircle></AccountCircle>
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Typography>
  );
});

export default withStyles(styles)(InputField);
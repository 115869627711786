import React from "react";
import BackToUpButton from "../GuideTools/BackToUpButton";
import Languages from "../../../translations";
import makeStyles from '@mui/styles/makeStyles';
import FeedIcon from '@mui/icons-material/Feed';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import Badge from '@mui/material/Badge';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';


const useStyles = makeStyles({
  success: {
    color: 'rgb(129, 199, 132)',
    fontSize: 'xx-large',
  }
});



const DiscussionAccordionText = () => {
  const classes = useStyles();
    return(
      <>
      <div>
      <h4>{Languages.discussionTestTitle}</h4>
          <br />
          <p className="guide-accordion-details-texts">{Languages.discussionTestList} </p>
          <br />
        <BackToUpButton />
        </div>
      </>
    )
}

export default DiscussionAccordionText
import React /*{ Component }*/ from "react";
import "./note.css";
import Card from '@mui/material/Card';
//import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
//import { Avatar } from "@mui/material";
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import AttachmentIcon from "@mui/icons-material/Attachment";
import Paper from "@mui/material/Paper";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import GroupIcon         from '@mui/icons-material/Group';
import Languages from "../../translations";
import MessageText from "../MessageText";
import DownloadIcon from '@mui/icons-material/Download';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import AttachmentIconManager from "./AttachmentIconManager";
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade'



// Tätä käytetään ilmoitustaulun viesteissä

const useStyles = makeStyles(theme => ({
  root: (props) => ({
    //backgroundColor: '#fbfbfd',
    marginTop: "5px",
    marginBottom: "5px",
    marginLeft: "2px",
    marginRight: "2px",
    backgroundColor: 'LemonChiffon',
    [theme.breakpoints.down('md')]: {
      maxHeight: props.constantHeight ? '350px' : undefined,
      minHeight: '300px',
    },
    [theme.breakpoints.up('md')]: {
      maxHeight: props.constantHeight ? '350px' : undefined,
      minHeight: '350px',
    },
    [theme.breakpoints.up('lg')]: {
      maxHeight: props.constantHeight ? '380px' : undefined,
      minHeight: '380px',
    },
    [theme.breakpoints.up('xl')]: {
      maxHeight: props.constantHeight ? '380px' : undefined,
      minHeight: '380px',
    }
  })
}));

const useStyles2 = makeStyles(theme => ({
  root: (props) => ({
    backgroundColor: '#fbfbfd',
    //backgroundColor: 'white',
    [theme.breakpoints.down('md')]: {
      maxHeight: props.constantHeight ? '350px' : undefined,
      minHeight: '300px',
    },
    [theme.breakpoints.up('md')]: {
      maxHeight: props.constantHeight ? '350px' : undefined,
      minHeight: '350px',
    },
    [theme.breakpoints.up('lg')]: {
      maxHeight: props.constantHeight ? '380px' : undefined,
      minHeight: '380px',
    },
    [theme.breakpoints.up('xl')]: {
      maxHeight: props.constantHeight ? '380px' : undefined,
      minHeight: '380px',
    }
  })
}));

const Note = ({ recipient, date, title, available, children, attachments, attachmentOnClick, previewFileOnClick, constantHeight, showAttachmentsAsIcon, source }) => {
  const styleProps = {
    constantHeight,
  }
  const classes = useStyles(styleProps);
  const classes2 = useStyles2(styleProps)

  if(source === "bulletinOnly") {
    //console.log(attachments)
  return (
    <>
      <Card className={classes.root} key={"bbNoteCard_" + title} component={Paper} elevation={3}>
        <CardContent>
          <div style={{textAlign: "center", color:"black"}}><Typography color="secondary"><FiberManualRecordIcon elevation={15}/></Typography></div>
          <Typography component="span" color="textSecondary" >
                <GroupIcon/> <b>{recipient}</b>
              </Typography>
          <i style={{color: "black", width: "100%", /*textAlign: "end",*/ display: "block"}}>{date}</i>
          <hr style={{marginTop: "0px"}}/>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
            </Grid>
            <Grid item md={12}>
              <Typography variant="h6" component="h2" color="textSecondary">
                <b>{title}</b>
              </Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <MessageText style={{whiteSpace: "pre-wrap"}} color="textSecondary">
                {children}
              </MessageText>
            </Grid>
            {attachments.length > 0 && 
            <div style={{width: "100%"}}><Typography color="textSecondary"><br /><AttachmentIcon /> <b>{Languages.attachments} ({attachments.length}):</b></Typography></div>
            }
            <Grid item md={12} xs={12}>
              {(attachments && !showAttachmentsAsIcon) && attachments.map((att, index) =>
              <React.Fragment key={"noteAttButtonContainer_" + att.FileName}>
                <hr style={{marginTop: "0px"}}/>
                <Typography color="textSecondary" style={{overflow: "hidden", textOverflow: "ellipsis", maxWidth: "300px"}}><AttachmentIconManager mimeType={att.MimeType}/>&nbsp;{att.FileName}</Typography>
                <Tooltip 
                  arrow 
                  title={<div style={{fontSize: "0.8rem"}}>{Languages.generalDownloadFileAttachment2}.<hr /><li>{att.FileName}</li></div>}
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 600 }}
                >
                <Button aria-label="download bulletinboard attachment" style={{ /*marginLeft: "0px",*/ minWidth: "50%", color: "black", marginBottom: "4px", fontSize: "x-small", /*whiteSpace: "nowrap",*/ }} key={"bbAttBtn_" + index} variant="outlined" color="secondary" onClick={() => attachmentOnClick(att)}>
                  <DownloadIcon/><b>&nbsp;{Languages.downloadMedia}</b>
                </Button>
                </Tooltip>
                <Tooltip 
                  arrow 
                  title={<div style={{fontSize: "0.8rem"}}>{Languages.generalOpenText}<hr /><li>{att.FileName}</li></div>}
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 600 }}  
                >
                <Button style={{ /*marginLeft: "0px",*/ textAlign: "center", minWidth: "50%", color: "black", marginBottom: "4px", /*justifyContent: "flex-start", */ fontSize: "x-small", /*whiteSpace: "nowrap",*/ }} key={"bbAttBtnPrev_" + index} variant="outlined" color="secondary" onClick={() => previewFileOnClick(att.Id, att.MimeType, att.FileName)}>
                <FileOpenIcon/><b>&nbsp;{Languages.generalOpen}</b>
                  </Button>
                  </Tooltip>
                </React.Fragment>
              )}
              {/*(attachments && attachments.length > 0 && showAttachmentsAsIcon) &&
              <Typography align="right" color="textSecondary">
                <AttachmentIcon data-testid="attachment-icon"/>
              </Typography>
              */}
            </Grid>
            
          </Grid>
          
        </CardContent>
        <hr style={{marginBottom: "0px"}}/>
        <Typography style={{marginTop: "0px", marginLeft:"20px"}} color="textSecondary" gutterBottom>
                <i>{available}</i>
              </Typography>
      </Card>
    </>
  
  );
} else {
        return(
                <>
      <Card className={classes2.root} component={Paper} elevation={3}>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography color="textSecondary" style={{display: "inline"}} >
              { recipient && <span><GroupIcon /></span>} <b>{recipient}</b><br />{(attachments && attachments.length > 0 && showAttachmentsAsIcon) &&
                <>
                <Typography component="span" color="secondary"><AttachmentIcon style={{textAlign: "right"}} data-testid="attachment-icon"/> <i>{Languages.attachments}: {attachments.length}</i></Typography></>
              } 
              </Typography>
              <i style={{color: "black", width: "100%", /*textAlign: "end",*/ display: "block"}}>{date}</i>
              <hr style={{marginTop: "0px"}}/>
            </Grid>
            <Grid item md={12}>
              <Typography variant="h5" component="h2" color="textSecondary">
                <b>{title}</b>
              </Typography>
            </Grid>
            <Grid item md={12}>
              <Typography component="span" style={{whiteSpace: "pre-wrap"}} color="textSecondary">
                {children}
              </Typography>
            </Grid>

            <Grid item md={12}>
              {(attachments && !showAttachmentsAsIcon) && attachments.map((att, index) =>
                <Button key={"bbAttBtn_" + index} variant="outlined" color="secondary" onClick={() => attachmentOnClick(att)}>
                  {att.FileName}
                </Button>
              )}
              {/*(attachments && attachments.length > 0 && showAttachmentsAsIcon) &&
              <Typography align="right" color="secondary">
                <AttachmentIcon data-testid="attachment-icon"/>
              </Typography>
              */}
            </Grid>
          </Grid>
        </CardContent>
        <hr />
        <Typography style={{marginLeft:"16px"}} color="textSecondary" gutterBottom>
                <i>{available}</i>
              </Typography>
      </Card>
    </>
              )
            }
};
export default Note;

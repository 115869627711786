import React, { useState, useEffect } from "react";
import "./settings.css";
import Languages from "../../translations";
import { useStateValue } from '../../State';
import ContentBoxMUI          from "../../Components/ContentBoxMUI";
import InfoModal from "../../Components/Modals/InfoModal";
import { useAuth, SessionManager } from "../../Auth";
import LoadingIndicator from "../../Components/LoadingIndicator";
import { LanguagesOption } from "./Components/SettingOption";
import { PasswordChangeOption } from "./Components/PasswordChangeOption";
import NewUserNote from "./Components/NewUserNote";
import LegalLinks from "../../Layouts/Login/Components/LegalLinks.jsx";
import Typography from "@mui/material/Typography";
import SettingsIcon                   from '@mui/icons-material/Settings';
import NotificationsSettings from "./Components/NotificationsSettings";

const styles = {
  color: "#4377BA",
  fontSize: "18pt",
  fontWeight: "bold",
  textAlign: "center",
  width: "100%",

}

const Settings = ({onNavBack, location}) => {
  const { loading: isLoading, loginSettings, saving } = useAuth();
  const { authState } = useAuth();

  const [languageSelection, setLanguageSelection] = useState(SessionManager.getUserLanguage());
  const [languagesExpanded, setLanguagesExpanded] = useState(false);
  const [passwordExpanded, setPasswordExpanded] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [showNewUserText, setShowNewUserText] = useState(false);
  const [emailServiceEnabled, setEmailServiceEnabled] = useState(false);

  const { state: locationState } = location;
  
  const { actions, state: { settings } } = useStateValue();

  //inaktivoidaan tämä, koska ei pitäisi olla / ei tarvita erillisiä keydown listenereita
  /*const keydownHandler = (event) => {
    // Ctrl + S
    if (event.code === "KeyS" && event.ctrlKey )
    {
      event.preventDefault();
      savePassword();
    }
  }*/

  //useEventListener('keydown', keydownHandler);

  // component mount
  useEffect(() => {
    if (locationState && locationState.newUserRedirect) {
      console.log("New user redirect detected");
      setPasswordExpanded(true);
      setShowNewUserText(true);
    }
  },[]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  useEffect(() => {
    if (saving.lastError) {
      setErrorText(Languages.errorBackend);
      setShowError(true);
    }
  }, [saving]);

  useEffect(() => {
    const emailServiceSetting = settings.communeSettings.find(setting => setting.Key === "mailServiceEnabled");
    setEmailServiceEnabled(emailServiceSetting);
    
    if (!settings.personSettings) {
      actions.triggerLoadPersonSettings(authState.personId)
    }
  }, [settings])
  
  const handleChangeLanguage = (event) => {
    //console.log("Settings. Handle language. event.target.value: ",event.target.value);
    Languages.setLanguage(event.target.value);
    sessionStorage.setItem('language', event.target.value);
    SessionManager.setUserLanguage(event.target.value);
    setLanguageSelection(event.target.value);
    actions.triggerLoadVacationNotificationStatus(); //the language strings come from the server and this request is rarely called 
  }

  return (
    <ContentBoxMUI onNavBack={onNavBack}>
      <Typography style={styles}><SettingsIcon /> {Languages.menuSettings}</Typography>
      <hr />
      <LoadingIndicator show={(isLoading || saving.onGoing)}/>
      <InfoModal 
        modalOpen={showError}
        headerText={Languages.error}
        allowClose={true}
        onClose={() => setShowError(false)}
        >
        <h4 style={{whiteSpace:"pre-line"}}>{errorText}</h4>
      </InfoModal>
      <NewUserNote show={showNewUserText}/>
      {loginSettings.Password && 
        <PasswordChangeOption
          expanded={passwordExpanded}
          onExpandClick={()=>setPasswordExpanded(!passwordExpanded)}
          />
      }
      <LanguagesOption
        onLanguageChange={handleChangeLanguage}
        languageSelection={languageSelection}
        expanded={languagesExpanded}
        onExpandClick={()=> setLanguagesExpanded(!languagesExpanded)}/>
      <NotificationsSettings isEmailServiceEnabled={emailServiceEnabled}/>
      <br />
      <br />
      <hr />
      <div style={{marginLeft:"10px"}}>
        {sessionStorage.getItem('WebAppVersion') !== "undefined" &&
            <Typography variant="body2" style={{textTransform: "uppercase"}} color="textSecondary">DaisyFamily. Versio: {sessionStorage.getItem('WebAppVersion')} {/* 1 = Main versio, 1.1 = New feature, 1.1.1 = Bug fixes*/}</Typography>
        }
        {/* DaisyFamilyn frontin näkyvä versionumero vaihdetaan DaisyFamily-kansion .env-tiedostosta. Localhost käynnistettävä uudelleen, jos haluaa nähdä versionumeron muutoksen heti*/}
        <Typography variant="body2" style={{textTransform: "uppercase"}} color="textSecondary">{sessionStorage.getItem('RESTAPIVersion')}</Typography>
        {/*<div>(Dev 0.9.2.056)*/} {/* 1 = Main versio, 1.1 = New feature, 1.1.1 = Bug fixes*/}{/*</div>*/}
      </div>
      <hr />
      <LegalLinks/>

    </ContentBoxMUI>
  )
}

export default Settings;

import React, { useEffect, useMemo, useState } from "react";
import Drawer from "@mui/material/Drawer";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { useStateValue } from "../../State";
import moment from "moment";
import NursingTimesTable from "./NursingTimesTable";
import NotificationsList from "./NotificationsList";
import DailyNotes from "./DailyNotes";
import translations, { languageStrings } from "../../translations";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import { render } from "enzyme";
import { set } from "lodash";
import { history } from "../../Router";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ textAlign: "center", width: "100%", display: "block" }}
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: 3,
            color: "#000000",
            padding: "1px",
            paddingTop: "5px",
            textAlign: "center",
            height: "auto",
            marginBottom: "100px",
          }}
        >
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
};

const tabProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const getNext = (nursingTimesData) => {
  const nursingTimes = {};
  const statuses = {};
  const isoFormat = "YYYY-MM-DD";
  nursingTimesData.List.WeekData?.WeekList?.forEach((week) => {
    week.DayList.forEach((day) => {
      if (day.NursingTimes.length > 0) {
        if (!nursingTimes[week.WeekNumber]) {
          nursingTimes[week.WeekNumber] = [];
        }

        if (!nursingTimes[week.WeekNumber][day.Date.format(isoFormat)]) {
          nursingTimes[week.WeekNumber][day.Date.format(isoFormat)] = [];
        }

        day.NursingTimes.forEach((time) => {
          day.Statuses.forEach((time2) => {
            if (time2.Date === time.Date && time2.PersonId === time.PersonId) {
              time["Type"] = time2.Type;
            }
          });
        });

        nursingTimes[week.WeekNumber][day.Date.format(isoFormat)].push(
          ...day.NursingTimes
        );
      }
    });
  });

  return nursingTimes;
};

const TabsView = ({ nextNursingTimes, onClose, handleReload, bulletins }) => {
  const [value, setValue] = useState(0);
  const { state } = useStateValue();
  const { mainstats } = state;
  const { settings } = state;
  const [showModal, setShowModal] = useState(true);
  const newNotificationsCount = mainstats.actionViewNotis;

  const handleChange = (event, value) => {
    setValue(value);
  };

  useEffect(() => {
    if (
      window.location.pathname === "/nursingtimes" ||
      window.location.pathname === "/caretimecalendar"
    ) {
      setShowModal(false);
    }
  });

  const GetNewCalendarEnabledSetting = () => {
    let newCalendarEnabled = settings.communeSettings.find(
      (setting) => setting.Key === "DaisyFamily/NewCaretimeCalendarEnabled"
    );
    if (
      newCalendarEnabled != null &&
      typeof newCalendarEnabled != undefined &&
      newCalendarEnabled.ValueBool === true
    ) {
      return true;
    }
    return false;
  };

  if (showModal === true && !GetNewCalendarEnabledSetting()) {
    return (
      <div>
        <Box sx={{ width: "100%", textAlign: "center", display: "revert" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              textAlign: "center",
              display: "block",
            }}
          >
            <Tabs
              sx={{
                textAlign: "center",
                display: "inline-flex",
                display: "-webkit-inline-flex",
              }}
              textColor="secondary"
              indicatorColor="secondary"
              value={value}
              onChange={handleChange}
              aria-label="tabs"
            >
              <Tab
                label={translations.bookingCareHoursTitle}
                {...tabProps(0)}
              />
              <Tab label={translations.actionNotes} {...tabProps(1)} />
              <Tab
                label={
                  <>
                    <Badge
                      badgeContent={newNotificationsCount}
                      color="error"
                      overlap="circular"
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      sx={{ marginRight: "-6px", marginLeft: "auto" }}
                    />
                    {translations.generalNotifications}
                  </>
                }
                {...tabProps(2)}
              />
            </Tabs>
          </Box>

          <TabPanel value={value} index={0}>
            <NursingTimesTable
              nursingTimes={nextNursingTimes}
              onClose={onClose}
              handleReload={handleReload}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <DailyNotes />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <NotificationsList onClose={onClose} bulletins={bulletins} />
          </TabPanel>

          {/*<TabPanel value={value} index={1}>
            {Ilmoitukset}
          </TabPanel>*/}
        </Box>
      </div>
    );
  } else {
    return (
      <div>
        <Box sx={{ width: "100%", textAlign: "center", display: "revert" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              textAlign: "center",
              display: "block",
            }}
          >
            <Tabs
              sx={{
                textAlign: "center",
                display: "inline-flex",
                display: "-webkit-inline-flex",
              }}
              textColor="secondary"
              indicatorColor="secondary"
              value={value}
              onChange={handleChange}
              aria-label="tabs"
            >
              <Tab label={translations.actionNotes} {...tabProps(0)} />
              <Tab
                label={
                  <>
                    <Badge
                      badgeContent={newNotificationsCount}
                      color="error"
                      overlap="circular"
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      sx={{ marginRight: "-6px", marginLeft: "auto" }}
                    />
                    {translations.generalNotifications}
                  </>
                }
                {...tabProps(1)}
              />
            </Tabs>
          </Box>

          <TabPanel value={value} index={0}>
            <DailyNotes />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <NotificationsList onClose={onClose} bulletins={bulletins} />
          </TabPanel>

          {/*<TabPanel value={value} index={1}>
            {Ilmoitukset}
          </TabPanel>*/}
        </Box>
      </div>
    );
  }
};

const StyledDrawer = styled(
  Drawer,
  {}
)(({ theme, anchor }) => ({
  "& .MuiBackdrop-root": {
    height: "-webkit-fill-available",
    //display: 'none'
  },
  "& .MuiDrawer-paper": drawerPaperStyles({ theme })[anchor],
}));

const drawerPaperStyles = (props) => ({
  top: {
    height: "50%",
    position: "absolute",
  },
  right: {
    position: "absolute",
    marginTop: "64px",
    background: props.theme.palette.background.default,
    [props.theme.breakpoints.down("md")]: {
      marginTop: "56px",
      width: "100%",
    },
    [props.theme.breakpoints.up("md")]: {
      width: "50%",
    },
    [props.theme.breakpoints.up("lg")]: {
      width: "30%",
    },
  },
});

const ActionView = ({ open, onClose }) => {
  const {
    actions,
    state: { nursingtimes },
  } = useStateValue();
  const [reload, setReload] = useState(false);

  const handleReload = () => {
    setReload((current) => !current);
    handleLoad();
  };

  const handleLoad = () => {
    const startDate = moment().startOf("week");
    actions.triggerLoadNursingTimes(startDate);
  };

  useEffect(() => {
    if (!nursingtimes.List.state) {
      handleLoad();
    }

    if (reload == true) {
      handleReload();
    }
  }, [nursingtimes.List.state, reload]);

  const nextNursingTimes = useMemo(
    () => getNext(nursingtimes),
    [nursingtimes.List]
  );

  return (
    <React.Fragment>
      <div style={{ height: "200%" }}>
        <StyledDrawer
          anchor="right"
          open={open}
          variant="temporary"
          onClose={onClose}
        >
          <IconButton
            variant="outlined"
            size="small"
            aria-label="close"
            title={translations.generalClose}
            sx={{
              color: "black",
              position: "absolute",
              right: "10px",
              marginTop: "5px",
              zIndex: 100,
              backgroundColor: "#dbd8d8",
            }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
          <TabsView
            nextNursingTimes={nextNursingTimes}
            onClose={onClose}
            handleReload={handleReload}
          />
        </StyledDrawer>
      </div>
    </React.Fragment>
  );
};

export { ActionView };

import React                      from    'react';
import PedagDocImageFolderFailed  from    "./Components/PedagDocImageFolderFailed";
import PedagDocImageFolderLoading from    "./Components/PedagDocImageFolderLoading";
import PedagDocImageFolderSuccess from    './Components/PedagDocImageFolderSuccess';
import Languages                  from     "../../../translations";


const PedagDocImageFolderViewManager = ({ imagefolderState, growthfolderState, imageFolderFunctions, imageFolderInformation, errorCode }) => {

  function returnCorrectImageFolderView(growthfolderstate){

    const imageFolderStateOptions = {

      "LOADING_IMAGES": function() {
        return (
          <PedagDocImageFolderLoading />
        );
      },

      "LOAD_IMAGES_SUCCESS": function() {
        return (
          <PedagDocImageFolderSuccess
            imagefolderState = {imagefolderState}
            growthfolderState = {growthfolderState}
            imageFolderFunctions = {imageFolderFunctions}
            imageFolderInformation = {imageFolderInformation} 
          />
        );
      },

      "LOAD_IMAGES_FAILED": function() {
        return (
          <PedagDocImageFolderFailed
            reloadFoldersIfFailed = {imageFolderFunctions["reloadFoldersIfFailed"]}
            errorCode = {errorCode} 
          />
        );
      },

      "default": function() {
        return (
          <div>{Languages.errorUnknown + "(777)."}</div>
        );
      }
    };

    return (imageFolderStateOptions[growthfolderstate] || imageFolderStateOptions["default"])();
  }

  return(
    <React.Fragment>
      {returnCorrectImageFolderView(growthfolderState.state)}
    </React.Fragment>
  );

}

export default PedagDocImageFolderViewManager;
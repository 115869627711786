import React, { useEffect, useRef, useCallback } from 'react';
import isNil from "lodash/fp/isNil";
import       "./taskfolder.css";
import GetAppIcon from '@mui/icons-material/GetApp';
import Fab                    from "@mui/material/Fab";
import CancelIcon from '@mui/icons-material/Cancel';
import FixedBottomBar         from "../FixedBottomBar";
import { downloadBase64File } from '../../utils';




const ImageModal = ({onCloseRequest, image, downloadData, downloadFileName, downloadMimeType}) => {
    const modal = useRef(null);

const handleKeyUp = useCallback(
    e => {
        const keys = {
            27: () => {
                e.preventDefault();
                onCloseRequest();
                window.removeEventListener("keyup", handleKeyUp, true);
            }
        };

        if (keys[e.keyCode]) {
            keys[e.keyCode]();
        }
    },
    [onCloseRequest] 
);


const handleOutsideClick = useCallback(
    e => {
        if (!isNil(modal)) {
            if (!modal.current.contains(e.target)) {
                onCloseRequest();
                document.removeEventListener("click", handleOutsideClick, true);
            }
        }
    }, [onCloseRequest]
);



useEffect(() => {
    window.addEventListener("keyup", handleKeyUp, true);

    return () => {
        window.removeEventListener("keyup", handleKeyUp, true);
    };
}, [handleKeyUp]); 



    return (
        <div className="taskfolder-modal-container04112020">
        <div className = "modalOverlay" onClick = {() => onCloseRequest()}> </div>
            <div className = "taskModal" ref = {modal}>
                <img src={image} alt = "Tehtäväkuva"/>
                </div>
            
            
            <FixedBottomBar>
            <div className="downloadTask"><Fab style={{margin:"16px"}} color="secondary" onClick = {() => {downloadBase64File(downloadData, downloadMimeType, downloadFileName)}}><GetAppIcon /></Fab></div>
            <div className="exitTask"><Fab onClick = {() => onCloseRequest()} style={{margin:"16px"}} color="secondary" ><CancelIcon /></Fab></div>
            </FixedBottomBar>

        </div>
    );
};

export default ImageModal;
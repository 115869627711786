import React, { Component } from "react";
import { /*Modal, ModalBody,*/ /*FormGroup, Label, Input*/} from "reactstrap";
import Moment from 'moment';
import Modal from "@mui/material/Modal";
import { StateContext, /*useStateValue*/ } from "../../../../State";

import "./newreservations.css";
import NursingTimePopUpAppBar from "./NursingTimePopUpAppBar";
import Languages          from "../../../../translations";
//import PlusIcon      from "../../../images/plus.PNG"; TARVITAAN MYÖHEMMIN

import ContentBox         from "../../../../Components/ContentBox";
import FixedBottomBar     from "../../../../Components/FixedBottomBar";
import SnackbarComponent  from '../../../../Components/Snackbar';
import DenseTable         from '../../../../Components/DenseTable';

import NewReservationView  from './NewReservationView';

import AddIcon            from "@mui/icons-material/Add";
import Check              from '@mui/icons-material/Check';
import Cancel             from '@mui/icons-material/Cancel';
import Button             from "@mui/material/Button";
import Dialog             from "@mui/material/Dialog";
import DialogContent      from "@mui/material/DialogContent";
import Fab                from "@mui/material/Fab"

import _cloneDeep         from "lodash/cloneDeep"; // deep clones an array so that the copy is not just shallow

class NewReservations extends Component {

  static contextType = StateContext;

  constructor(props) {
    super(props);
    this.strings = Languages;
  
    this.state = { showSaveButton:     true,
                   showSnackbar:       false,
                   ListOfReservations: [0],
                   ListOfReservationsTimes: [{Start:this.props.startTime, End:this.props.endTime, StartNotValid: false, EndNotValid: false }], //setHours(8,15,0) asettaa myös defaultti minuutit
                   ListOfChildsInReservations: [],
                   ListOfCurrentReservations: [],
                   ListOfPlacements:   [],
                   selections:         [],
                   selectionStart:     null,
                   selectionEnd:       null,
                   infoText:           "",
                   focusNotSet:        true,
                   partiallyvalid:     false,
                   selectionType:      this.props.Mode === "NEWDAILYRESERVATION" || this.props.Mode === "KEEPDAILYRESERVATION" ? "I" : "A",
                   eveningPreschool:   false,
                   nursingtimetypes:   []};
  }

  componentDidMount() {
    const { WeekData } = this.context.state.nursingtimes.List;
    const { children } = this.context.state;
    
    document.addEventListener('keydown',this.keydownHandler);

    var selections = [];
    let startday = null;
    let endday = null;
    var nursingtimetypes = [];
    let CurrentReservations = [];

    WeekData.WeekList.forEach(week => {
      var weekstring = "";
      
      var daycount = week.DayList.filter(d => d.DateSelected);
      //console.log("Days selected, week: ", week.WeekNumber +" ", selectedcount.length);

      week.DayList.filter(d => {
        return d.DateSelected;
      }).forEach(d => {
        // pvm kellonaika/poissolo teksti / sijoitus
        d.NursingTimes.forEach(nt => {
          var selectedChild = children.ChildList.find(c => c.Id === nt.PersonId);
          
          CurrentReservations.push({  PersonID:   nt.PersonId,
                                      PersonName: nt.FirstName,
                                      Date:       nt.Date, 
                                      Start:      nt.Start,
                                      End:        nt.End,
                                      Text:       nt.Name,
                                      PlacementIcon: nt.PlacementIcon,
                                      PlacementColor: selectedChild.BgColor,
                                      Placement:  nt.Group === null ? nt.Centre /*+ " " +nt.Priority*/ : /*nt.Centre + "-"+*/nt.Group/*+ " "+nt.Priority*/})
        });
        d.Statuses.forEach(st => {
          var selectedChild = children.ChildList.find(c => c.Id === st.PersonId);
          CurrentReservations.push({  PersonID:   st.PersonId,
                                      PersonName: st.FirstName,
                                      Date:       st.Date, 
                                      Start:      null,
                                      End:        null,
                                      Text:       st.Name,
                                      PlacementIcon: st.PlacementIcon,
                                      PlacementColor: selectedChild.BgColor,
                                      Placement:  st.Group === null ? st.Centre /*+ " " +st.Priority*/ : /*st.Centre+ "-"+*/st.Group/*+ " "+st.Priority*/})
        });
        
        if (daycount.length === 1)
        {
          weekstring += d.Date.format("DD.MM.YYYY");
          startday  = startday === null ? d.Date : startday;
          //console.log("Last selected day. Set startday: ", startday.format("DD.MM.") );
          endday    = d.Date;
          daycount.length = 0;
        }
        else if (daycount.length > 0)
        {
          weekstring += weekstring === "" ? d.Date.format("DD.") + " - " : "";
          startday  = startday === null ? d.Date : startday;
          //console.log("Set startday: ", startday.format("DD.MM.") );
          daycount.length--;
        }
     });
     if ( weekstring !== "")
     { 
       selections.push(weekstring);
     }
    });

    //Set dropdown list
    if (this.state.selectionType === "I" /*this.props.Mode === "NEWDAILYRESERVATION" || this.props.Mode === "KEEPDAILYRESERVATION"*/ )
    {
      nursingtimetypes = [{ text: this.strings.bookingTypeAbsent,  value: "A"},
                          { text: this.strings.bookingTypeNT,      value: "I" },
                          { text: this.strings.bookingTypeSick,    value: "S" }/*,
                          { text: this.strings.bookingTypeSNT,     value: "B" }*/];
    }
    else
    {
      nursingtimetypes = [{ text: this.strings.bookingTypeAbsent,  value: "A"},
                          { text: this.strings.bookingTypeSick,    value: "S" }];
    }

    //Käyttäjä ei voi itse valita ennaltailmoitettua poissaoloa, vaan serveri tekee sen tarvittaessa
    /*if (sessionStorage.getItem('pAbsentCode') !== "null")
    {
      nursingtimetypes.push({text: this.strings.bookingTypePAbsent, value: sessionStorage.getItem('pAbsentCode')});
    }*/
    if (sessionStorage.getItem('HolidayCode') !== "null")
    {
      nursingtimetypes.push({text: this.strings.bookingTypeHoliday, value: sessionStorage.getItem('HolidayCode')});
    }

    CurrentReservations = CurrentReservations.sort((a, b) => new Date(a.Date) - new Date(b.Date));
    //CurrentReservations = CurrentReservations.sort((a, b) => a.PersonName.localeCompare(b.PersonName));
    //CurrentReservations = CurrentReservations.sort((a, b) => new Date(a.Start) - new Date(b.Start));
    let FirstCurrentReservation = CurrentReservations.filter(res => { return res.Start !== null });
    FirstCurrentReservation = FirstCurrentReservation.sort((a, b) => new Date(a.Start) - new Date(b.Start));

    let newFirstTime = null;
    if (FirstCurrentReservation.length > 0 )
    {
      newFirstTime = [{ Start:          Moment(FirstCurrentReservation[0].Start), 
                        End:            Moment(FirstCurrentReservation[0].End),
                        StartNotValid:  false,
                        EndNotValid:    false }];
    }
    
    /*console.log("NewReservations. Mount. "+
                "\n CurrentReservations: ",CurrentReservations,
                "\n FirstCurrentReservation: ", FirstCurrentReservation);*/
    let date = null;
    let tableitem = [];
    let tabledata = [];
    CurrentReservations.forEach((cres, cresIndex) => {
      if (date === null) // first date
      {
        date = Moment(cres.Date).format("DD.MM.YYYY");
        tableitem.push({  PersonID:       cres.PersonID,
                          PlacementIcon:  cres.PlacementIcon,
                          PlacementColor: cres.PlacementColor,
                          Colum1:         cres.PersonName,
                          Colum2:         cres.Placement,
                          Colum3:         cres.Start === null ? null : Moment(cres.Start).format('HH:mm') + "-" + Moment(cres.End).format('HH:mm') 
        });
        if (CurrentReservations.length -1 === cresIndex) // last item
        {
          tabledata.push({Date: date,
                          Title: null,
                          Items: tableitem}); 
        }
      }
      else if (date === Moment(cres.Date).format("DD.MM.YYYY") ) // same date
      {
        tableitem.push({  PersonID:       cres.PersonID,
                          PlacementIcon:  cres.PlacementIcon,
                          PlacementColor: cres.PlacementColor,
                          Colum1:         cres.PersonName,
                          Colum2:         cres.Placement,
                          Colum3:         cres.Start === null ? null : Moment(cres.Start).format('HH:mm') + "-" + Moment(cres.End).format('HH:mm') 
                        });
        if (CurrentReservations.length -1 === cresIndex) // last item
        {
          tabledata.push({Date: date,
                          Title: null,
                          Items: tableitem}); 
        }
      }
      else // Date change 
      {
        tabledata.push({Date: date,
                        Title: null,
                        Items: tableitem}); 
        date = Moment(cres.Date).format("DD.MM.YYYY");
        tableitem = [];
        tableitem.push({  PersonID:       cres.PersonID,
                          PlacementIcon:  cres.PlacementIcon,
                          PlacementColor: cres.PlacementColor,
                          Colum1:         cres.PersonName,
                          Colum2:         cres.Placement,
                          Colum3:         cres.Start === null ? null : Moment(cres.Start).format('HH:mm') + "-" + Moment(cres.End).format('HH:mm') 
        });
        if (CurrentReservations.length -1 === cresIndex) // last item
        {
          tabledata.push({Date: date,
                          Title: null,
                          Items: tableitem}); 
        }
      }
    });
    
    //console.log("NewReservations. Mount. tabledata: ", tabledata );

    this.setState({ selections:                 selections,
                    selectionStart:             startday,
                    selectionEnd:               endday,
                    ListOfReservationsTimes:    newFirstTime === null ? this.state.ListOfReservationsTimes : newFirstTime,
                    FirstCurrentReservation:    FirstCurrentReservation,
                    ListOfCurrentReservations:  tabledata /*CurrentReservations*/,
                    nursingtimetypes:           nursingtimetypes });
    
  }

  componentDidUpdate(event) {
    const { ChildList } = this.context.state.children;
    
    //console.log("NewReservations. Update. state.showSnackbar: ",this.state.showSnackbar);
    
    if (this.state.focusNotSet) {
      //console.log("NewReservation. Set focus to top page");
      window.scrollTo(0, this.refs.pagetop);   // General scroll to element function
    }

    if (this.state.ListOfPlacements.length === 0) {
      //Check earliest and lastest reservation day
      //Create placements dropdown list for each child
      var pls = [];
      var q = 0;

      ChildList.forEach(child => {
        let item = [];
        let placementsNotFound = true;
        let firstPossibleNotChecked = true;
        let ddTitle = null;
        let ddTitlePlId = null;

        child.Placements.forEach(pl => {
          /*if (child.Id === "32416e7e-e081-445c-95f8-7df508e825bb")
          { console.log("Child: ", child.Name);
            console.log("Selected Startday: ", this.state.selectionStart.format("DD.MM."), " Endday: ", this.state.selectionEnd.format("DD.MM."));
            console.log("PL: ",pl.Centre, pl.Group === null ? "" : "-" + pl.Group, " Prio: ", pl.Priority, " Checked: ", pl.Checked);
            console.log("Start: ", pl.Start.format("DD.MM."), " End: ", pl.End === null ? null : pl.End.format("DD.MM."));
          }*/

          // placement valid for all days
          var centreName = "";
          var groupName = "";
          if ( (pl.Start <= this.state.selectionStart) && // placement start day valid
               (pl.End === null || ( (pl.End >= this.state.selectionEnd) && (pl.End >= this.state.selectionStart) )
               ) )
          {
            /*if (child.Id === "32416e7e-e081-445c-95f8-7df508e825bb")
            { console.log("PL valid for all days. "); }*/

            placementsNotFound = false;
            centreName = pl.Centre;
            groupName = pl.Group === null ? ""/* + pl.Priority*/ : " - " + pl.Group /*+ " " + pl.Priority*/;
            
            if (firstPossibleNotChecked)
            {  
              firstPossibleNotChecked = false;
              pl.Checked = true;
              ddTitle = centreName + groupName + " " + pl.Priority;
              ddTitlePlId = pl.Id;
             /*if (child.Id === "32416e7e-e081-445c-95f8-7df508e825bb")
              { console.log("First DD title set: ", ddTitle); }*/
  
            }
            
            item.push({ value: pl.Id, text: centreName + groupName, validpartially: false, Priority: pl.Priority, enddate: pl.End === null ? null :  pl.End.format("DD.MM.") });
          }
          //placement valid part of the days
          else if (pl.Start <=  this.state.selectionEnd &&
                  (pl.End === null || (pl.End !== null && pl.End >=  this.state.selectionStart))) 
          {
            /*if (child.Id === "32416e7e-e081-445c-95f8-7df508e825bb")
            { console.log("PL valid part of days"); }*/

            placementsNotFound = false;
            centreName = pl.Centre;
            groupName = pl.Group === null ? "" /*+ pl.Priority*/ : " - " + pl.Group /*+ " " + pl.Priority*/;
            
            if (firstPossibleNotChecked)
            {  
              firstPossibleNotChecked = false;
              pl.Checked = true;
              ddTitle = centreName + groupName + " " + pl.Priority;
              ddTitlePlId = pl.Id;
             /* if (child.Id === "32416e7e-e081-445c-95f8-7df508e825bb")
              { console.log("First DD title set: ", ddTitle); }*/
            }

            item.push({ value: pl.Id, text: centreName + groupName, validpartially: true, Priority: pl.Priority, enddate: pl.End === null ? null :  pl.End.format("DD.MM.") });
          }
          else {
            pl.Checked = false; // if not in dropdownlist, set it as unselected (maybe set selected by server)
            centreName = pl.Centre;
            groupName = pl.Group === null ? "" /*+ pl.Priority*/ : " - " + pl.Group /*+ " " + pl.Priority*/;

           /*if (child.Id === "32416e7e-e081-445c-95f8-7df508e825bb")
            { console.log("PL not valid. "); }*/
        	}
        });

        if (placementsNotFound) {
          item.push({ value: null, text: "NOT FOUND", validpartially: null, Priority: null, enddate: null });
          //console.log("NewReservation. Update. ",child.Name," no valid placement. Set unchecked");
          child.Checked = false;
        }

        q++;
        pls.push({ ID: q, Title: placementsNotFound ? this.strings.bookingChoosePlacement : ddTitle /*item[0].text*/, PlId: ddTitlePlId, ChildId: child.Id, data: item });
      });

     
      let newChildList = [];
      newChildList.push(_cloneDeep( ChildList.filter(child => { return (child.Checked)})).sort((a, b) => a.FirstName.localeCompare(b.FirstName)) );
      let newPlacementList = [];
      newPlacementList.push(pls);

      /*console.log("NewReservation. Update. "+
                  "\n Lapset joilla voimassa oleva sijoitus: ", ChildList.filter(child => { return child.Checked }));*/

      if (ChildList.filter(child => { return child.Checked }).length === 0)
      {
        this.closeComponent("CLOSE_NONE_VALID_PLACEMENTS");
      }
      this.setState({ showSaveButton: ChildList.filter(child => { return child.Checked }).length > 0 ? true : false,
                      ListOfPlacements: newPlacementList,
                      infoText: "",
                      focusNotSet: false,
                      ListOfChildsInReservations: newChildList});
    }
  }
 
  componentWillUnmount() {
    //console.log("NewReservation. Unmount. Remove keydown listener");
    document.removeEventListener('keydown',this.keydownHandler);
  }

  componentDidCatch(event) {
    console.log("NewReservation.componentDidCatch. VIRHE: ", event);
  }

  keydownHandler = (event) => {
    //console.log("NewReservation. keydownHandler. event.keyCode: ", event.keyCode, " event.ctrlKey: ",event.ctrlKey);
    if (event.keyCode === 83 && event.ctrlKey )
    {
      event.preventDefault();
      this.saveNursingTimes();
    }
  }

  handleSelection = (selection, reservationNumber) => {
    //const { children } = this.context.state;
    
    /*console.log("NewReservations. handle dropdown selection:"+
                "\n Reservation: ",reservationNumber+
                "\n selection.selection[",reservationNumber,"]: ",selection.selection[0],
                "\n this.state.ListOfChildsInReservations: ", this.state.ListOfChildsInReservations);*/

    if (selection.selection[0].component === this.strings.bookingType) 
    {
      let listsCutted = false;
      let array1 = [...this.state.ListOfReservations]; // make a separate copy of the array
      let array2 = [...this.state.ListOfPlacements]; // make a separate copy of the array
      let array3 = [...this.state.ListOfChildsInReservations]; // make a separate copy of the array
      let array4 = [...this.state.ListOfReservationsTimes]; // make a separate copy of the array

      if (selection.selection[0].id !== "I")
      {
        this.state.ListOfReservations.forEach((resCount,index) => {
          if (index > 0)
          {
            listsCutted = true;
            let cuttingIndex = array1.indexOf(index);
            //console.log("NewReservations. handle reservation type change. Remove reservation: " + index);
            array1.splice(cuttingIndex, 1);
            array2.splice(cuttingIndex, 1);
            array3.splice(cuttingIndex, 1);
            array4.splice(cuttingIndex, 1);
          }
        });
      }
      this.setState({ selectionType:              selection.selection[0].id,
                      ListOfReservations:         listsCutted ? array1 : this.state.ListOfReservations,
                      ListOfPlacements:           listsCutted ? array2 : this.state.ListOfPlacements,
                      ListOfChildsInReservations: listsCutted ? array3 : this.state.ListOfChildsInReservations,
                      ListOfReservationsTimes:    listsCutted ? array4 : this.state.ListOfReservationsTimes});
    }
    else //this.strings.bookingChoosePlacement
    {
      /*var checkedChild = children.ChildList.filter(child => {
        return child.Checked === true && child.Id === selection.selection[0].childId;
      });*/
      var checkedChild = this.state.ListOfChildsInReservations[reservationNumber].filter(child => {
        return child.Checked === true && child.Id === selection.selection[0].childId;
      });

      var selectedPlacementPriority = 0;

      //set selected placement checked for childlist placement list
      checkedChild[0].Placements.forEach(pl => {
        if (pl.Id === selection.selection[0].id) {
          selectedPlacementPriority = pl.Priority;
          pl.Checked = true;
        }
        else {
          pl.Checked = false;
        }
      });

      var pls = this.state.ListOfPlacements[reservationNumber];
      pls.forEach(pl => {
        for (var e = 0; e < pl.data.length; e++) {
          if (pl.data[e].value === selection.selection[0].id) {
            pl.Title = selection.selection[0].name
            pl.PlId = selection.selection[0].id;
            //pl.validpartially = selection.selection[reservationNumber].partiallyvalid === true ? true : false
            break;
          }
        }
      });

      this.setState({ placements: pls });

      this.checkRestPlacementSelections(selection.selection[0].childId, selectedPlacementPriority, reservationNumber);
    }
  };

  checkRestPlacementSelections = (selectedChildId, plPriority, reservationNumber) => {
    //const { children } = this.context.state;

    //Check rest of the selected childs and select same prio placement if found

    var checkedChild = this.state.ListOfChildsInReservations[reservationNumber].filter(child => {
      return child.Checked === true && child.Id !== selectedChildId;
    });
    
    var validPlacements = this.state.ListOfPlacements[reservationNumber];
    /*console.log("NewReservation. checkRestPlacementSelections. First child selected prio: ", plPriority,
                "\n checkedChild: " ,checkedChild, 
                "\n validPlacements: ",validPlacements );*/

    checkedChild.forEach(child => {
      var samePriorityPlacements = validPlacements.filter( vpl => {
        return (vpl.ChildId === child.Id)
      })[0].data.filter((data) => {
        return (data.Priority === plPriority);
      });

      if (samePriorityPlacements.length === 0) {
        let placements = validPlacements.filter(pl => {
          return (pl.ChildId === child.Id);
        });
        // jos 0-prio sijoitusta ei valittaisikaan, jos löytyy toisen prion sijoitus
        /*let NonzeroPrioPls = placements[0].data.filter(dpl => {
          return (dpl.Priority !== 0);
        });*/
        
        let selectPlacementId = //NonzeroPrioPls.length === 0 ?
                                placements[0].data[0].value /*:
                                NonzeroPrioPls[0].value*/;
        
        /*console.log("Placement prio match not found from first index. Child: ", child.Name,
                    "\n Valid placements: ",placements[0].data,
                   // "\n Non 0 prio: ",NonzeroPrioPls,
                    "\n selectPlacementId: ", selectPlacementId );*/

        child.Placements.forEach(pl => {
          if (pl.Id === selectPlacementId)
          { pl.Checked = true; }
          else
          { pl.Checked = false; }
        });

        placements[0].Title = placements[0].data.filter(dpl => {
          return (dpl.value === selectPlacementId);
        })[0].text + "-" + placements[0].data.filter(dpl => {
          return (dpl.value === selectPlacementId);
        })[0].Priority;

        //console.log("Set dropdwon title: ",placements[0].Title);
      }
      else {
        //if found, loop again and set is as selected
        //console.log("Same prio placement found.Child: ", child.Name, " placements: ", samePriorityPlacements )
        child.Placements.forEach(pl => {
          if (pl.Id === samePriorityPlacements[0].value) {
            //console.log("Select ", pl.Centre, " - ", pl.Group, " Checked");
            pl.Checked = true;
          }
          else {
            pl.Checked = false;
          }
        });
        let placements = validPlacements.filter(pl => {
          return (pl.ChildId === child.Id);
        });
       
        placements[0].Title = samePriorityPlacements[0].text + "-" + samePriorityPlacements[0].Priority;
        //console.log("Set dropdwon title: ", samePriorityPlacements[0].Title  );
      }
    });

    this.setState({ placements: validPlacements });
  };

  saveNursingTimes = () => {
    /*console.log("NewReservations. Save new nursingtimes");*/
    //Tallenna varaukset kalenterissa
    this.closeComponent("SAVENEW");
  };

  closeComponent = (status) => {
  
    if (status === "CLOSEERROR") {
      this.setState({ errorModal: false });
    }
    else {
      this.props.callback(status,
                          this.state.ListOfChildsInReservations,
                          this.state.ListOfReservationsTimes, 
                          this.state.selectionType,
                          this.state.eveningPreschool);
    }
  };

  ChildSelection = (childID, reservationNumber) => {
    //const { children } = this.context.state;
    
    /*console.log("NewReservations. ChildSelection. childID: ", childID,
                "\n Reservation: ", reservationNumber, 
                "\n ListOfChildsInReservations[",reservationNumber,"]: ", this.state.ListOfChildsInReservations[reservationNumber],
                "\n Original ChildrenList: ",children.ChildList);*/

    let SelectedChild = this.state.ListOfChildsInReservations[reservationNumber].find(child => {
      return (childID === child.Id);
    });
    SelectedChild.Checked = !SelectedChild.Checked;
    let nbrofcheckedChild = this.state.ListOfChildsInReservations[reservationNumber].filter(child => {
      return child.Checked === true;
    });

    if ( nbrofcheckedChild.length !== 0 )
    {
      this.checkRestPlacementSelections( nbrofcheckedChild[0].Id, 
                                         nbrofcheckedChild[0].Placements.filter(pl => {
                                          return (pl.Checked === true)
                                         })[0].Priority,
                                         reservationNumber);
    }

    this.setState({ showSaveButton: nbrofcheckedChild.length === 0 ? false : true });
  };

  roundMinutes = (min) => {
    if (min === "00" || (0 < min &&  min < 8) )
    { min = 0; }
    else if ( 7 < min &&  min < 23 )
    { min = 15; }
    else if ( 22 < min &&  min < 38 )
    { min = 30; }
    else if (37 < min && min < 53)
    { min = 45; }
    else if (58 < min && min < 60)
    {min = 59; }
    else
    { min = 0;
      //hour++; 
    }
    return min;
  }

  handleTimeSelection = (type, time, reservationNumber) => {
    
    // Make a shallow copy of the items
    //const newItems = [...this.state.ListOfReservationsTimes];
    const newItems = _cloneDeep(this.state.ListOfReservationsTimes);
    //reset validity flags
    newItems.forEach(item => {
      item.StartNotValid = false;
      item.EndNotValid = false;
    });
    
    if (type === "HideSave")
    {
      //console.log("NewReservation. handleTimeSelection. Hide Save button");
    }
    else
    {
      let hour = Moment(time).format('HH');  
      let min = Moment(time).format('mm');
      let RoundedMin = this.roundMinutes(min);

      if (type === "StartTime") {
        newItems[reservationNumber].Start = Moment(time).hour(hour).minute(RoundedMin); //Moment(time);
        newItems[reservationNumber].StartNotValid = false; // default that time ok
        //console.log("NewReservation. handleTimeSelection Start[",reservationNumber,"] edited: ",newItems[reservationNumber].Start.format('HH:mm'));
      }
      else // EndTime
      {
        newItems[reservationNumber].End = Moment(time).hour(hour).minute(RoundedMin); //Moment(time);
        newItems[reservationNumber].EndNotValid = false; // default that time ok
        //console.log("NewReservation. handleTimeSelection End[",reservationNumber,"] edited: ",newItems[reservationNumber].End.format('HH:mm'));
      }

      //tarkista valitun varauksen ajat keskenään ensin
      if ( Moment(newItems[reservationNumber].Start).format('HH:mm') >= Moment(newItems[reservationNumber].End).format('HH:mm') &&
           Moment(newItems[reservationNumber].End).format('HH:mm') !== "00:00" )
      {
        if (type === "StartTime") 
        {
          newItems[reservationNumber].StartNotValid = true;
          newItems[reservationNumber].EndNotValid = false;
        }
        else // EndTime
        {
          newItems[reservationNumber].EndNotValid = true;
          newItems[reservationNumber].StartNotValid = false;
        }
      }
    
      if (  newItems[reservationNumber].StartNotValid === false && 
            newItems[reservationNumber].EndNotValid   === false && 
            newItems.length > 1 )
      {
        //Jos editoitu aika on keskenään ok, 
        //Sitten tarkista koko aikataulu, ettei keskenään vääriä tai peräkkäisiä päällekkäisiä aikoja
        newItems.forEach((timeItem,index) => {
          if (index > 0 )
          {  
          /*console.log("NewReservations: Handle time selection. Check timetable:"+
                      "\n item[",index,"] Start: "+timeItem.Start.format('HH')          +":",timeItem.Start.format('mm')+
                      "\n item[",index,"] End: ", timeItem.End.format('HH')             +":"+timeItem.End.format('mm')+
                      "\n Prev Start: ",          newItems[index-1].Start.format('HH')  +":"+newItems[index-1].Start.format('mm')+
                      "\n Prev End: ",            newItems[index-1].End.format('HH')    +":"+newItems[index-1].End.format('mm')); +
                      "\n Start < Prev End: ", timeItem.Start.format('HH:mm') < newItems[index-1].End.format('HH:mm') ? true : false);
                      "\n Start >= Prev Start: ", timeItem.Start.format('HH:mm') >= newItems[index-1].Start.format('HH:mm') ? true : false );*/
          }

          //Tarkista, ettei aikapari ole väärin
          if (timeItem.Start.format('HH:mm') >= timeItem.End.format('HH:mm') && timeItem.End.format('HH:mm') !== "00:00" )
          {
            timeItem.StartNotValid = true;
            timeItem.EndNotValid   = true;
          }
        
          //tarkista peräkkäiset ajat
          if (index > 0 && 
              ( (timeItem.Start.format('HH') !== "00" && timeItem.Start.format('HH:mm') < newItems[index-1].End.format('HH:mm')) ||
              (timeItem.Start.format('HH:mm') < newItems[index-1].Start.format('HH:mm') && timeItem.End.format('HH:mm') > newItems[index-1].Start.format('HH:mm'))
              )) 
          { 
            //console.log("NewReservations: Handle time selection. Time[",reservationNumber,"] overlapping previous");
            if (type === "StartTime")
            { newItems[reservationNumber].StartNotValid = true; }
            else
            { newItems[reservationNumber].EndNotValid = true; }
          }
        });
      }
    }

    let notValidFound = newItems.find(item => {
      return (item.StartNotValid === true || item.EndNotValid === true)
    }) === undefined ? false : true ;

    /*console.log("NewReservations: Handle time selection. Check times validity:"+
                "\n NotValid Found: ",notValidFound);*/

    this.setState({ ListOfReservationsTimes:  newItems,
                    showSaveButton:           notValidFound || type === "HideSave" ? false : true,
                    showSnackbar:             notValidFound || type === "HideSave" ? true : false});
  };

  handleCalendarChange = () => {
    console.log("NewReservations. Handle Calendar click");
  }

  handleSnackbarCB = () => {
    //console.log("NewReservations. Handle Snackbar callback");
    this.setState({ showSnackbar: false });
  }

  HandleEvpr = (evpr) =>{
    if(evpr === null){
      let current = this.state.eveningPreschool;
      if(current == false){
        this.setState({eveningPreschool: true});
      }else{
        this.setState({eveningPreschool: false});
      }
    }else{
      this.setState({eveningPreschool: evpr});
    }
  }

  

  addReservationSelectionView = () => {
    const { ChildList } = this.context.state.children;

    let newCountList = Array.from(this.state.ListOfReservations);
    newCountList.push(this.state.ListOfReservations.length);

    /*console.log("NewReservations. Add new reservation."+
                "\n ListOfReservations: ", this.state.ListOfReservations+ " Length: ",this.state.ListOfReservations.length+
                "\n newCountList: ", newCountList + " Length: ",newCountList.length+
                "\n ListOfReservationsTimes: ", this.state.ListOfReservationsTimes  );*/

    //let newChildList = [];
    let newChildList = this.state.ListOfChildsInReservations;
    //newChildList.push(_cloneDeep(ChildList));
    newChildList.push(_cloneDeep( ChildList.sort((a, b) => a.FirstName.localeCompare(b.FirstName))));

    //new time list
    let newTimeList = _cloneDeep(this.state.ListOfReservationsTimes);
    let newEnd   = Moment(new Date().setHours(this.state.ListOfReservationsTimes[this.state.ListOfReservations.length-1].End.hour()+4,
                                              this.state.ListOfReservationsTimes[this.state.ListOfReservations.length-1].End.minute(),
                                              0));
    newTimeList.push({Start:          this.state.ListOfReservationsTimes[this.state.ListOfReservations.length-1].End, 
                      End:            Moment(newEnd),
                      StartNotValid:  false, 
                      EndNotValid:    false });
    /*console.log("NewReservations. Add new reservation: "+
                "\n newEnd: ", newEnd);*/
      
    let notValidTimeFound = false;
    if ( Moment(newTimeList[newTimeList.length-1].Start).format('HH:mm') >= Moment(newTimeList[newTimeList.length-1].End).format('HH:mm') &&
         Moment(newTimeList[newTimeList.length-1].End).format('HH:mm') !== "00:00" )
    {
      notValidTimeFound = true;
      newTimeList[newTimeList.length-1].StartNotValid = true;
      newTimeList[newTimeList.length-1].EndNotValid  = true;
    }

    //new valid placement list
    let newValidPlacementList = this.state.ListOfPlacements;
    newValidPlacementList.push(_cloneDeep(this.state.ListOfPlacements[0]));
    
    /*console.log("NewReservations. Add new reservation block. New lists:"+
                "\n of placements: ", newValidPlacementList,
                "\n of childs: ", newChildList,
                "\n of times: ", newTimeList,
                "\n of reservations: ", newCountList);*/

    this.setState({ ListOfPlacements:           newValidPlacementList,
                    ListOfChildsInReservations: newChildList,
                    ListOfReservationsTimes:    newTimeList,
                    ListOfReservations:         newCountList,
                    showSaveButton:             notValidTimeFound ? false : true,
                    showSnackbar:               notValidTimeFound ? true : false });
  }

  render() {

    if (this.state.nursingtimetypes.length === 0)
    {
      return null;
    }

    if (this.state.errorModal) {
      return (
        <Modal className="ModalMUIplacement" open={this.state.errorModal}>

          <div className="InfoMessage textCenter">
            <ContentBox header={this.strings.bookingCareHoursTitle} showCloseicon={true} setfunction={() => this.closeComponent("CLOSEERROR")} >
              <h4>{this.strings.errorTimesInvalid}</h4>
            </ContentBox>
          </div>

        </Modal>
      );
    }
    else {
      return ( 

        
      <Dialog  fullScreen open={true} onClose={() => this.closeComponent("CLOSED")} scroll={"paper"}>
        <DialogContent  style={{backgroundColor: "#FFFFFF",}}>
        <NursingTimePopUpAppBar close={() => this.closeComponent("CLOSED")}/>

          <div style={{backgroundColor: "white",  maxWidth: "800px",  margin: "auto"}}>
          <div style={{minHeight:"60px"}}></div>


        <div className="NewReservation textCenter" ref="pagetop">
        
          {this.state.ListOfPlacements.length > 0 ?
          <div>
            {this.props.Mode === "KEEPDAILYRESERVATION"/* || 
             this.props.Mode === "KEEPLOCKEDDAYABSENT"*/ ?
              <div>
                <DenseTable Title={Languages.bookingAddBookingAndKeepPrevTitle} Data ={this.state.ListOfCurrentReservations}></DenseTable>
                <div className="BorderBottomLightblue"></div>
              </div>
            : null}
            
            {this.state.ListOfReservations.map(number => {
            return (
            <NewReservationView key                       ={number}
                                count                     ={number}
                                mode                      ={this.state.selectionType /*this.props.Mode*/}
                                selectedDates             ={this.state.selections} 
                                ntDropDownData            ={this.state.nursingtimetypes}
                                listOfplacements          ={this.state.ListOfPlacements}
                                listOfChilds              ={this.state.ListOfChildsInReservations}
                                listOfTimes               ={this.state.ListOfReservationsTimes}
                                FirstCurrentReservation   ={this.state.FirstCurrentReservation}
                                ChildStats                ={this.context.state.nursingTimesSummary.NT.ListChildStats}
                                handleSelection           ={(selection, reservationNumber) => this.handleSelection(selection, reservationNumber)}
                                handleChildSelection      ={(childID, reservationNumber)   => this.ChildSelection(childID, reservationNumber)}
                                HandleEvpr                ={(evpr) => this.HandleEvpr(evpr)}
                                handleTimeSelection       ={(type,time, reservationNumber)  => this.handleTimeSelection(type,time, reservationNumber)}>
            </NewReservationView>
            );
            })}
          </div>
          : null}

          <SnackbarComponent verticalPosition={'bottom'} show={this.state.showSnackbar} message ="Tarkista varausajat" callback={() => this.handleSnackbarCB()} />

          {/*this.props.Mode: KEEPDAILYRESERVATION, NEWLOCKEDDAYABSENT, KEEPLOCKEDDAYABSENT */ 
           this.props.HideAddReservationButton === false &&
           this.props.Mode === "NEWDAILYRESERVATION" &&
           this.state.selectionType === "I" &&
           this.state.showSaveButton &&                 /* Times valid */
           this.state.ListOfPlacements.length < 3 ?     /* 2 reservation under making => dont show add button */
            <Button id={"test_NewBooking_AddNewReservation"}variant="outlined" color="primary" startIcon={<AddIcon />} onClick={this.addReservationSelectionView}> {this.strings.bookingAddMoreBtn} </Button>
          : null }

          <br></br><br></br>
          <FixedBottomBar>
          {this.state.showSaveButton ?
            <div className="container">
              <div className="painikkeet row">
                <Fab id="test_NewBooking_Cancel" style={{color: "white", backgroundColor: "#FF284C", margin: "auto"/*, marginRight: "10%"*/}} onClick={() => this.closeComponent("CLOSED")}> <Cancel/> </Fab>
                <Fab id="test_NewBooking_Save" style={{color: "white", backgroundColor: "#81C784", margin: "auto"}} onClick={this.saveNursingTimes}> <Check/> </Fab>
                </div>
            </div>
            :
            <div className="container">
              <div className="row">
              <Fab id="test_NewBooking_Cancel" style={{color: "white", backgroundColor: "#FF284C", margin: "auto"/*, marginRight: "10%"*/}} onClick={() => this.closeComponent("CLOSED")}> <Cancel /> </Fab>
              </div>
            </div>
          }
          </FixedBottomBar>
        </div> {/* className="CareTime"*/}
        <div style={{minHeight:"60px"}}></div>
        </div>
  
        </DialogContent>
      </Dialog>
      ); /* return child calender*/
    }
  }/* render*/
}

export default NewReservations;

/*
response: response object
okCodes: array of success codes
errorMessage: error message to throws
*/

export const processResponse = (response, okCodes, errorMessage, callback) => {
  if (response && response.status) {
    if (okCodes.indexOf(response.status) < 0) {
      if (callback) callback(response);

      throw new ApiError(response.status, errorMessage, response);
    }
  } else {
    console.error(
      "Error reading response status got response object:",
      response
    );
    throw Error("Error reading response status", response);
  }
};

export let calcValues = (num1, num2) => {
  return num1 * num2;
};

// Simulates link with embedded base64 data click. Browser shows download dialog when this function is called.
// Thanks to Richard Oliver Bray:
// https://medium.com/octopus-labs-london/downloading-a-base-64-pdf-from-an-api-request-in-javascript-6b4c603515eb
export const downloadBase64File = (base64data, mimetype, filename) => {
  // const linkSource = `data:${mimetype};base64,${base64data}`;
  if (mimetype == "application/pdf") {
    /*Old solution
  const linkSource = base64data;
  const downloadLink = document.createElement("a");
  const fileName = filename;
  console.log(linkSource);
  console.log(mimetype);
  

  downloadLink.download = fileName;
  downloadLink.href = linkSource;
  downloadLink.rel = "canonical"
  downloadLink.type = "application/pdf"*/
    //SAMS
    //downloadLink.target = "_blank";
    //downloadLink.click();
    //window.open(downloadLink);
    //window.open("https://data:application/pdf;base64, " + base64data );

    //One solution but the data didn't work
    /*
  const blobData = new Blob([base64data], {type: mimetype});
  console.log(blobData);
  const downloadURL = URL.createObjectURL(blobData);
  console.log(downloadURL);
  const downloadLink = document.createElement('a');
  downloadLink.href = downloadURL;
  console.log(downloadLink.href);
  downloadLink.setAttribute('download', filename);
  downloadLink.click();
  */

    const linkSource = base64data;
    const downloadLink = document.createElement("a");
    const fileName = filename;
    console.log(linkSource);
    console.log(mimetype);

    downloadLink.download = fileName;
    if (
      downloadLink.download === "null" ||
      typeof downloadLink.download === "undefined"
    ) {
      window.alert(
        "Selaimesi ei ole tuettujen selaimiemme listalla. Ole hyvä, ja päivitä tai vaihda selainta."
      );
    } else {
      downloadLink.href = linkSource;
      downloadLink.rel = "canonical";
      downloadLink.type = "application/pdf";
      //SAMS
      //downloadLink.target = "_blank";
      downloadLink.click();
    }
  }

  //Download for .docx word documents by Samwais
  else if (
    mimetype ==
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  ) {
    const linkSource = base64data;
    const downloadLink = document.createElement("a");
    const fileName = filename;
    console.log(linkSource);
    console.log(mimetype);

    downloadLink.download = fileName;
    downloadLink.href = linkSource;
    downloadLink.rel = "canonical";
    downloadLink.type =
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

    downloadLink.click();
  } else {
    const linkSource = `data:${mimetype};base64,${base64data}`;
    //const linkSource = base64data;
    const downloadLink = document.createElement("a");
    const fileName = filename;
    console.log(linkSource);
    console.log(mimetype);
    //console.log(mimetype);
    //console.log(mimetype);
    //console.log(filename);
    //console.log("Olen nyt utilssisa downloadbase64Filesä");

    //const header = application/{mimetype};
    downloadLink.download = fileName;
    downloadLink.href = linkSource;
    downloadLink.rel = "canonical";
    //SAMS
    //downloadLink.target = "_blank";
    downloadLink.click();
    //window.open(downloadLink);
    //window.open("https://data:application/pdf;base64, " + base64data );
  }
};

export const previewBase64File = (
  base64data,
  mimetype,
  filename,
  contentType = mimetype,
  sliceSize = 512
) => {
  console.log("bae64data: " + base64data);
  console.log("mimetype: " + mimetype);
  // console.log("bae64data: " + base64data);
  const base64WithoutPrefix = base64data.substr(
    "data:application/pdf;base64,".length
  );
  const byteCharacters = window.atob(base64WithoutPrefix);
  //const byteCharacters = Buffer.from(base64data, 'base64')
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  //return blob;
  URL.revokeObjectURL(blobUrl);
  const blobUrl = URL.createObjectURL(blob);

  //window.location = blobUrl;

  const openWindow = window.open(blobUrl, filename); //|| window.location.replace(blobUrl);
  if (openWindow === null || typeof openWindow == "undefined") {
    window.alert(
      "Hyväksy ponnahdusikkunat selaimesi asetuksissa nähdäksesi liitteen."
    );
  }
  //This will break the browser's download in preview if after creation.
  //URL.revokeObjectURL(blobUrl);
};

export class ApiError extends Error {
  constructor(code, message, response, ...params) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    }

    this.name = "ApiError";
    this.code = code;
    this.message = message;
    this.responsedata = response.data;
  }
}

export const containsMoreThanEmptySpaces = (testable) => {
  //Tests whether the string given contains something other than just empty spaces
  // null and undefined return false

  if (testable === null || typeof testable === "undefined") {
    return false;
  } else {
    var contenttobetrimmed = testable;
    //console.log("1" + contenttobetrimmed + "2");
    var trimmedContent = contenttobetrimmed.trim();
    //console.log("3" + cont2 + "4");
    var doesContainMoreThanJustEmptySpaces = false;

    if (trimmedContent.length > 0) {
      doesContainMoreThanJustEmptySpaces = true;
    }

    return doesContainMoreThanJustEmptySpaces;
  }
};

// thanks to http://www.primaryobjects.com/2012/11/19/parsing-hostname-and-domain-from-a-url-with-javascript/
export const getHostName = (url) => {
  const match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);

  if (
    match != null &&
    match.length > 2 &&
    typeof match[2] === "string" &&
    match[2].length > 0
  ) {
    return match[2];
  } else {
    return null;
  }
};

export const getDomain = (url) => {
  const hostName = getHostName(url);
  let domain = hostName;

  if (hostName != null) {
    const parts = hostName.split(".").reverse();

    if (parts != null && parts.length > 1) {
      domain = parts[1] + "." + parts[0];

      if (hostName.toLowerCase().indexOf(".co.uk") !== -1 && parts.length > 2) {
        domain = parts[2] + "." + domain;
      }
    }
  }

  return domain;
};

export const findLengthOfLongestWordInString = (string1) => {
  if (string1 !== undefined && string1 !== null) {
    let words = string1.split(" ");
    let longestWordLength = 0;

    for (let i = 0; i < words.length; i++) {
      if (longestWordLength < words[i].length) {
        longestWordLength = words[i].length;
      }
    }
    //console.log("longestWordLength: ", longestWordLength);
    return longestWordLength;
  } else {
    return 0;
  }
};

/*
url = baseurl without /-character at the end
params = array of objects [{key: paramName, value: paramValue}]
*/
export const buildEncodedUrl = (url, params, skip) => {
  let encodedUrl = url;

  if (!url) return url;

  if (encodedUrl.endsWith("/")) {
    // remove trailing /
    encodedUrl = encodedUrl.slice(0, -1);
  }
  encodedUrl += "?";

  params.forEach((param) => {
    if (param.value && param.key) {
      if (param.value !== undefined || param.value !== null) {
        if (param.key !== skip) {
          encodedUrl +=
            encodeURIComponent(param.key) +
            "=" +
            encodeURIComponent(param.value) +
            "&";
        } else {
          encodedUrl += param.key + "=" + param.value + "&";
        }
      }
    }
  });

  if (encodedUrl.endsWith("&")) {
    encodedUrl = encodedUrl.slice(0, -1);
  }

  return encodedUrl;
};

export const isAnyPropUndefinedOrNull = (props) => {
  let returnValue = false;

  if (Object.keys(props).length === 0) {
    return true;
  }

  Object.values(props).forEach((value) => {
    if (typeof value === "undefined" || value === null) {
      returnValue = true;
    }
  });

  return returnValue;
};

export const redirectBrowser = (url) => {
  window.location.assign(url);
};

export const getMyUrl = () => {
  const host = window.location.host;
  const httpScheme = window.location.protocol;
  const url = `${httpScheme}//${host}`;

  return url;
};

export const parseJwt = (token) => {
  if (!token) {
    return undefined;
  }

  const payload = atob(token.split(".")[1]);
  const header = atob(token.split(".")[0]);

  return {
    header: JSON.parse(header),
    payload: JSON.parse(payload),
  };
};

export const convertNullToString = (str) => {
  if (str === undefined || str === null) {
    return "";
  }
  return str;
};

export const parseQueryString = (locationSearch) => {
  let queryString;

  if (!locationSearch) {
    return {};
  }

  if (locationSearch.startsWith("?", 0)) {
    queryString = locationSearch.slice(1);
  } else {
    queryString = locationSearch;
  }

  if (queryString.length === 0) {
    return {};
  }

  if (queryString.indexOf("#") > -1) {
    queryString = queryString.substr(0, queryString.indexOf("#"));
  }

  let queryParams = queryString.split("&");
  let parsedQuery = {};

  queryParams.forEach((qp) => {
    let [key, val] = qp.split("=");
    parsedQuery[key] = decodeURIComponent(val);
  });

  return parsedQuery;
};

export const getLighterColor = (origColor) => {
  switch (origColor) {
    case "#86B8FF":
      return "#f0f6ff";
    case "#81C784":
      return "#f4faf5";
    case "#FFDB83":
      return "#fffbf0";
    case "#9181c5":
      return "#f6f5fa";
    default:
      return "#ffffff";
  }
};

/*95export const getLighterColor = (origColor) => {
  switch (origColor) {
    case "#86B8FF":
      return "#e5f0ff";
    case "#81C784":
      return "#edf7ee";
    case "#FFDB83":
      return "#fff8e5";
    case "#9181c5":
      return "#f0eef7";
    default:
      return "#ffffff";
  }
}*/

/*96export const getLighterColor = (origColor) => {
  switch (origColor) {
    case "#86B8FF":
      return "#ebf3ff";
    case "#81C784":
      return "#f1f9f2";
    case "#FFDB83":
      return "#fffaeb";
    case "#9181c5":
      return "#f3f1f8";
    default:
      return "#ffffff";
  }
}*/

/*
export const getLighterColor = (origColor) => {
  switch (origColor) {
    case "#86B8FF":
      return "#dbeaff";
    case "#81C784":
      return "#e6f4e7";
    case "#FFDB83":
      return "#fff5db";
    case "#9181c5":
      return "#eae7f4";
    default:
      return "#ffffff";
  }
}*/
// This just return frontend url when not running on localhost
export const getBackendPublicUrl = () => {
  let host;
  let httpScheme;
  if (window) {
    host = window.location.hostname;
    httpScheme = window.location.protocol;
  }
  console.log("window host", host);
  if (host === "localhost") {
    console.log(
      `Reading backend url from env ${process.env.REACT_APP_LOCAL_BACKEND_URL}`
    );
    host = process.env.REACT_APP_LOCAL_BACKEND_URL;
  }
  // example: http:

  const url = `${httpScheme}//${host}`;

  console.log("Backend url resolved to: ", url);

  return url;
};

export const getProfilePictureData = (state, childId) => {
  if (!state || !childId) {
    return undefined;
  }

  const {
    profilePictures: { profilePicturesList },
  } = state;
  const picEntity = profilePicturesList?.filter(
    (pp) => pp.PersonID === childId
  )[0];

  return picEntity?.ImageBase64;
};

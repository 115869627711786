import React, {useCallback}  from "react";
import {  ListGroup,  } from "reactstrap";
import moment                 from "moment";
import "../messaging.css";
import Message                from "../../../Components/Messaging/Message";
import FixedBottomBar         from "../../../Components/FixedBottomBar";
import MessagingBottomBar     from "./MessagingBottomBar";
import Languages              from "../../../translations";

const MessagingSuccess = ({refresh, showNew, openMessageThread, setAllRead, messages, setAllReadLoading}) => {

  const renderableMessages = useCallback(() => {

    if (typeof messages === "undefined" || messages === null) {
      return(<div>{Languages.errorUnknown + "(777)."}</div>);
    }
    else {

      return (
      messages.map((lmm, index) => {
          
        return (
          <Message key={lmm.ThreadId}
            tag="Message"
            counter={"test_Message_message_" + index}
            header={lmm.Title} 
            partContent={lmm.PartContent}
            datemessage={moment(lmm.NewestSaved).format("DD.MM. HH:mm")} 
            date={moment(lmm.NewestSaved).format("HH:mm, DD.MM.")}
            threadId={lmm.ThreadId}
            latestSenderName = {lmm.MessageSender.Name}
            openmessage = {(thread) => openMessageThread(thread)}
            unreadMessagesCount = {lmm.CountNotReadMessages}
            totalNumberOfMessages = {lmm.CountMessages}
            countAttachment = {lmm.CountAttachment}
          />
        );
      } 
      ))
    }

  })

  return(
      <React.Fragment>
        <ListGroup>
      
        {renderableMessages()}

        
         <MessagingBottomBar loading={false} refresh={refresh} showNew={showNew} setAllRead={setAllRead} setAllReadLoading={setAllReadLoading}/>
        

      </ListGroup>
    </React.Fragment>
  )
}

export default MessagingSuccess;
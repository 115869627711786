import React from "react";
import "../newmessage.css";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import FaceRoundedIcon from '@mui/icons-material/FaceRounded';
import Paper from "@mui/material/Paper";
import FixedBottomBar from "../../../../Components/FixedBottomBar";
import Languages from "../../../../translations";
import FixedBottomBarSelectChildrenContent from "./FixedBottomBarSelectChildrenContent";
import { getProfilePictureData, isAnyPropUndefinedOrNull } from "../../../../utils";
import Typography from "@mui/material/Typography";
import { useStateValue } from "../../../../State";
import ChildProfilePicture from '../../../../Components/ChildProfilePicture';



const SelectChildrenInQuestionView = (props) => {
  const { state } = useStateValue();

  const {childList, loadPotentialRecipientsState, 
    loadRecipientsData, closeSelectChildrenView, 
    checkedChildren, addOrRemoveChildFromCheckedChildren} = props;

  const undefinedOrNullPropExists = isAnyPropUndefinedOrNull(props);

  if (undefinedOrNullPropExists) {
      return(<div data-testid="SCIQV-undefined-or-null"></div>)
  }

  else {
    return (
      <React.Fragment>
        
        <div className="NMemptyspace"></div>
          <div style={{marginLeft: "10px", marginRight: "10px"}}>
            <div className="NMtitle"><span>{Languages.msgWhomMessageConcerns}</span></div>
            <div className="straightline"></div>
            <svg height="1" width="100%" style={{marginBottom: "10px"}}>
              <line className="line1" x1="0" y1="0" x2="100%" y2="0" />
            </svg>
          </div>
          <div>
            {childList.map((child, c_index) => {
              const profilePicBase64 = getProfilePictureData(state, child.Id);
              return (
                <div key={child.Name}>
                  <Grid container justifyContent="center" alignItems="center">

                    { !profilePicBase64 ?
                      <Avatar style={{ height: "40px", width: "40px" }}><ChildProfilePicture /></Avatar> :
                      <Avatar alt="picture" src={"data:image/jpeg;" + profilePicBase64} />
                    }
                    <p>&nbsp; &nbsp;</p>
                    <Paper style={{ borderWidth: "10px", backgroundColor: "#ffffff", borderStyle:"solid", borderColor: child.BgColor, width: "70%" }}>
                      <Typography color="textSecondary">&nbsp; &nbsp; {child.FirstName} &nbsp; &nbsp;</Typography>
                    </Paper>
                    <Checkbox
                      inputProps={checkedChildren.includes(child.Id) ? 
                        {'aria-label': "checked checkbox for " + child.Name} : 
                        {'aria-label': "unchecked checkbox for " + child.Name}}
                      data-testid="newMessageCheckedChild-checkbox"
                      color="secondary"
                      id={"test_ChildCounter_"+ c_index}
                      value={child.Id}
                      onChange={addOrRemoveChildFromCheckedChildren}
                      checked={checkedChildren.includes(child.Id) ? true : false}
                    />
                  </Grid>
                  <br />
                </div>
              );
            })
            }
        </div>

        <FixedBottomBar>
          <div className="messaging-buttons float-right">
            <FixedBottomBarSelectChildrenContent
              loadPotentialRecipientsState = {loadPotentialRecipientsState}
              loadRecipientsData = {() => loadRecipientsData()}
              closeSelectChildrenView = {() => closeSelectChildrenView()}
              isChildrenChecked = {checkedChildren.length > 0 ? true : false}
            />
          </div>
        </FixedBottomBar>
      </React.Fragment>
    );
  }
}

export default SelectChildrenInQuestionView;
import React, { Component } from "react";
import "./fixedbottombaraligned.css";

class FixedBottomBarAligned extends Component {
  render() {
    return <div id="test_FixedBottomBarAligned" className="FixedBottomBarAligned">{this.props.children}</div>;
  }
}

export default FixedBottomBarAligned;

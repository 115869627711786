import { useEffect, useState, useRef, useCallback} from 'react';
import { useEventListener } from '../Hooks';
import moment from 'moment';
import { LOGOUT_REASONS } from '../Api';
import { useAuth } from './AuthProvider';
import { SessionManager } from '.';

const TIME_FORMAT = "YYYY-MM-DDTHH:mm:ssZ"

export default class AutoLogoutHandler {
  constructor(authActions) {

    // Idle time when automatic logout occut, in seconds
    this.autoLogoutTimeoutSecs = 20 * 60;
    // How many seconds before auto logout show warning, value in seconds
    this.warningThreshold = 5 * 60;

    this.authActions = authActions;
    this.startTime = null;
    this.warningDispatched = false;
  }

  start() {
    if (!process.env.REACT_APP_AUTO_LOGOUT_ENABLED) {
      return;
    }

    if (typeof this.checkTimer === 'number') {
      this.cancel();
    }

    if (process.env.REACT_APP_AUTO_LOGOUT_ENABLED === 'false') {
      console.log("***Autologout disabled.***");
      return;
    }

    const warningTimeSecs = this.autoLogoutTimeoutSecs - this.warningThreshold;

    this.logoutTime = moment().add(this.autoLogoutTimeoutSecs, 's');
    
    // Store logout time
    SessionManager.setScheduledAutoLogout(this.logoutTime.format(TIME_FORMAT));

    // Start checking elapsed time
    this.checkTimer = setInterval(() => {
      const now = moment();
      
      this.logoutTime = moment(SessionManager.getScheduledAutoLogout(), TIME_FORMAT);
      this.warningTime = this.logoutTime.add(warningTimeSecs, 's');

      if (now.isSame(this.logoutTime) || now.isAfter(this.logoutTime)) {
        console.log("Autologout time hit. Logging out.");
        this.authActions.logout(LOGOUT_REASONS.automatic);
        this.cancel();
      }

      if (now.isSame(this.warningTime) || now.isAfter(this.warningTime)) {
        if (!this.warningDispatched) {
          console.log("Autologout warning time hit.");
          this.authActions.showPopup(true, this.logoutTime);
          this.warningDispatched = true;
        }
      }
    }, 1000);
  }

  cancel() {
    clearInterval(this.checkTimer);
    this.checkTimer = undefined;
    this.warningDispatched = false;
  }

  isRunning() {
    return this.checkTimer;
  }
}

// Not used 
const useAutoLogout = (showPopup, isAuthenticated) => {
  const [timeSetup, setTimeSetup] = useState({
    logoutTime: moment(),
    warningTime: moment()
  })
  const savedCallback = useRef();
  const intervalId = useRef();
  const { logout } = useAuth();

  const globalClickListener = useCallback(() => {
    if (isAuthenticated) {
      setup();
      // setPopupWarning(false);
      showPopup(false);
      // this.context.dispatch({
      //   type: types.RESET_AUTOLOGOUT_TIMER
      // })
    }
  }, [isAuthenticated]);

  useEventListener('click', globalClickListener);

  const setup = () => {
    // Idle time when automatic logout occut, in seconds
    const autoLogoutTimeoutSecs = 1 * 60;
    // How many seconds before auto logout show warning, value in seconds
    const warningThreshold = 30;

    const warningTimeSecs = autoLogoutTimeoutSecs - warningThreshold;

    const logoutTime = moment().add(autoLogoutTimeoutSecs, 's');
    const warningTime = moment().add(warningTimeSecs, 's');

    console.log("logout time", logoutTime);
    console.log("warning time", warningTime);
    
    const timeSetup = {
      logoutTime,
      warningTime
    };

    setTimeSetup(timeSetup);
    return timeSetup;
  }

  useEffect(() => {
    setup();
    
    savedCallback.current = () => {
      const now = moment();
      const { logoutTime, warningTime } = timeSetup;
      console.log("Tick", timeSetup);
  
      if (now.isSame(logoutTime) || now.isAfter(logoutTime)) {
        console.log("Autologout time hit. Logging out.");
        logout(LOGOUT_REASONS.automatic);

        clearInterval(intervalId.current);
        // TODO: solve
        // this.cancel(); 
      }
  
      if (now.isSame(warningTime) || now.isAfter(warningTime)) {
        // if (!this.warningDispatched) {
        console.log("Autologout warning time hit.");
        showPopup(true, logoutTime);
        // this.warningDispatched = true;
        // }
      }
    };
  }, [isAuthenticated, timeSetup]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (isAuthenticated) {
      intervalId.current = setInterval(tick, 1000);
    } else {
      clearInterval(intervalId.current)
    }
    return () => clearInterval(intervalId.current);
  }, [isAuthenticated]);

  // const stop = () => {
  //   clearInterval(intervalId);
  // }

  // return { start, stop }
}

export { useAutoLogout }
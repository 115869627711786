import React, { /*useState,*/ useEffect, useState } from "react";
import {useStateValue} from "../../../State";
import "../vasu.css";


import ContentBoxMUI        from "../../../Components/ContentBoxMUI";
import Languages            from "../../../translations";
import ChildRow             from '../ChildRow';
import DownloadFileButton from "../../../Components/DownloadFileButton/DownloadFileButton";
import Icon from '@mui/material/Icon';
import Box              from '@mui/material/Box'
import LockIcon         from '@mui/icons-material/Lock';
import List             from '@mui/material/List';
import ListItemButton   from '@mui/material/ListItemButton';
import ListItemIcon     from '@mui/material/ListItemIcon';
import ListItemText     from '@mui/material/ListItemText';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CheckBoxTwoToneIcon from '@mui/icons-material/CheckBoxTwoTone';
import Stack              from '@mui/material/Stack';
import BlockIcon from '@mui/icons-material/Block';
import Typography from '@mui/material/Typography';
import { types } from '../../../Reducers/actionTypes';
import SnackbarComponent from '../../../Components/Snackbar';
import makeStyles from '@mui/styles/makeStyles';
import Backdrop         from '@mui/material/Backdrop';
import CircularProgress from "@mui/material/CircularProgress";
import ApprovalsComponent  from '../Approvals';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';


const useStyles = makeStyles(theme => ({
  backdrop: {
    //zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: "#FBE4FF !important"
  },
}));


const VasuSupportPart = (props) => {

  const classes = useStyles();
  const { state, actions } = useStateValue();
  const VasuData = state.vasu;
  const ApprovalsData = state.approval;
  const GeneralPart = VasuData.GeneralPart;
  const SupportData = VasuData.VasuSupportExtraPart;
  const SpecialApprovals = VasuData.VasuApprovalsTable.SpecialApprovals;
  const EnhancedApprovals = VasuData.VasuApprovalsTable.EnhancedApprovals;

  const [showSnackbar,  setshowSnackbar]  = useState(false);
  const [NoteText,      setNoteText]      = useState(null);
  const [SavingData,    setSavingData]    = useState(false);
  const [expanded, setExpanded]           = useState(false);
  const { history, location } = props;
  //console.log("VasuSupportPart. MOUNT. location.state: ", location.state);
  const { childpicture, childFirstName, childLastName, childColor, value} = location.state;

  if (location.state === undefined)
  {
    //console.log("VasuSupportPart. MOUNT. location.state === undefined. GO BACK");
    history.goBack(); 
  }
  //console.log("VasuSupportPart. MOUNT. Table1: ", Table1);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  const handleString = (stringtype) => {
    var tstring = "";
    if(stringtype === "enhanced")
      if(SupportData.EnhancedSupportStart != null){
        tstring += Languages.VasuEnhancedSupport + " " + SupportData.EnhancedSupportStart + " - " +(SupportData.EnhancedSupportEnd === null ? "" : SupportData.EnhancedSupportEnd) + " " ;
        if(SupportData.EnhancedSupportDecisionDate != null) {
          tstring += Languages.VasuadministrativeDecision + " " + SupportData.EnhancedSupportDecisionDate;
        }
        else{
          tstring += Languages.VasuNoadministrativeDecision;
        }
      }
      else{
        null;
      }
    else{
      if(SupportData.SpecialSupportStart != null){
        tstring += Languages.VasuSpecialSupport + " " + SupportData.SpecialSupportStart + " - " + (SupportData.SpecialSupportEnd === null ? "" : SupportData.SpecialSupportEnd) + " " ;
        if(SupportData.SpecialSupportDecisionDate != null) {
          tstring += Languages.VasuadministrativeDecision + " " + SupportData.SpecialSupportDecisionDate;
        }
        else{
          tstring += Languages.VasuNoadministrativeDecision;
        }
      }
    }
    
    return tstring
  }



  const handleBoxClick = (part) => {
    switch (part) {
      case 1:
        history.push("/vasuEnhancedSupport", {
          childpicture: childpicture,
          childFirstName: childFirstName,
          childLastName: childLastName,
          childColor: childColor,
          vasu: GeneralPart.VasuStyle
        });
        break;
      case 2:
        history.push("/vasuSpecialSupport", {
          childpicture: childpicture,
          childFirstName: childFirstName,
          childLastName: childLastName,
          childColor: childColor,
          vasu: GeneralPart.VasuStyle
        });
        break;
    }
  };

  useEffect( () => {
    switch(ApprovalsData.state)
    {
      case undefined:
      case "INITIAL_STATE":
        break;
      case types.SAVING_APPROVAL:
        setSavingData(true);
        break;
       case types.APPROVAL_SAVED_OK:
        setSavingData(false);
        setshowSnackbar(true);
        setNoteText("Hyväksynnän tallennus ok");
        break;
      case types.APPROVAL_SAVED_FAILED:
        setSavingData(false);
        setshowSnackbar(true);
        setNoteText(Languages.requestFailed);
        break;
      default:
        break;
    }
  }, [ApprovalsData]);

  const handleSnackbarCB = () => {
    actions.triggerApprovalHandled();
    setshowSnackbar(false);
  };


  if (VasuData.state === "INITIAL_STATE" || location.state === undefined)
  {
    return (<div className="VasuSupportPart"></div>);
  }
  else
  {
    const { childpicture, childFirstName, childLastName, childColor} = location.state;
  
  return (
    <div className="VasuSupportPart">
      {SavingData ?
          <div style={{opacity: "0.5"}}>
              <Backdrop open={true} className={classes.backdrop}>
                <CircularProgress />
              </Backdrop>
          </div>
        :
      <ContentBoxMUI>
        <Box sx ={{mt: 1}}>
          <ChildRow childpicture   = {childpicture}
                    childFirstName = {childFirstName}
                    childLastName = {childLastName}
                    childColor     = {childColor}
                    FormId         = {GeneralPart.Id}
                    history        = {history}>
          </ChildRow>
        </Box>
        
        
        <div>
          <h4 className="textCenter">3. {Languages.eduPlanTitle3}</h4>

          <div style={{opacity: GeneralPart.Locked === null ? "40%" : ""}}>
            
          
          
          <Stack  spacing={1} alignItems="center" sx={{}}>
              <Stack direction="row" spacing={1} alignItems="baseline" sx = {{mt: 2}}>
                  {SupportData.EnhancedSupportStart === null ? 
                    null
                    :
                    <CheckBoxTwoToneIcon  sx={{ color: "green", fontSize: 16, fontWeight: 'medium', display: 'inline'}}/>
                  }
                <Typography color="textSecondary" sx={{textAlign:"left", fontWeight: 'medium', fontSize: 16, color: "#424242"}}>
                  {handleString("enhanced")}
                </Typography>
                {SupportData.EnhancedSupportStart !== null &&
                  <DownloadFileButton
                  FileName={GeneralPart.Attachments[GeneralPart.Attachments.map(object => object.Id).indexOf(SupportData.IdEnhancedSupportDecision)].Name}
                  FileMimeType={GeneralPart.Attachments[GeneralPart.Attachments.map(object => object.Id).indexOf(SupportData.IdEnhancedSupportDecision)].FileType}
                  FileId={GeneralPart.Attachments[GeneralPart.Attachments.map(object => object.Id).indexOf(SupportData.IdEnhancedSupportDecision)].Id}
                  file={null}
                  useIconButton={false}
                  useThreeDots={false}
                  useTextButton ={true}
                  disabled = {SupportData.IdEnhancedSupportDecision === null ? true : false}
                  text={Languages.generalOpen}
                  isImage={false}
                />
                }
              </Stack>

              <List className="col-12" sx={{ }}>
                <ListItemButton className="boxed"  disabled = {SupportData.EnhancedSupportStart !== null ?  false : true} sx={{backgroundColor: "#fceebf", borderRadius: .5, boxShadow: 2}} onClick={(event) => handleBoxClick(1)}>
                  <ListItemText 
                    primaryTypographyProps={{fontWeight: 'Bold', fontSize: 16, ml: 2}} primary={Languages.VasuEnhancedSupport}/>
                  <ListItemIcon>
                    {SupportData.EnhancedSupportStart !== null ?
                      <ArrowForwardIosIcon fontSize="small" /> :
                    < BlockIcon fontSize="small" sx={{ color: "red" }} />
                    }
                  </ListItemIcon>
                </ListItemButton>
              </List>

              {SupportData.IdEnhancedSupportDecision !==  null ? 
                <Box className="col-12">
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    style={{backgroundColor: "#fceebf"}}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography color="textSecondary" sx={{textAlign:"left", fontWeight: 'bold', fontSize: 16, color: "#424242"}}>{Languages.ConsultationChildAndGuardian2}</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{backgroundColor: "#ffffff"}}>
                    <Stack className="col-12" direction={isDesktop ? "row" : "column"}  spacing={8} alignItems="center" sx = {[isDesktop ? {mt: 2, ml:4} : {mt: 2}]}>
                      <ApprovalsComponent Approval={EnhancedApprovals.Parent1Approval} GeneralPart={GeneralPart} ParentName={GeneralPart.Parent1Name} ParentId={GeneralPart.Parent1Id} DecisionId={SupportData.IdEnhancedSupportDecision} Type={0}>  </ApprovalsComponent>
                      <ApprovalsComponent Approval={EnhancedApprovals.Parent2Approval} GeneralPart={GeneralPart} ParentName={GeneralPart.Parent2Name} ParentId={GeneralPart.Parent2Id} DecisionId={SupportData.IdEnhancedSupportDecision} Type={0}>  </ApprovalsComponent>
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              </Box>
              : null}
              

            </Stack>

          

          

          <Stack spacing={1} alignItems="center" sx={{mt: 10}}>     
            <Stack  direction="row" spacing={1} alignItems="baseline" sx = {{}} >
                  {SupportData.SpecialSupportStart === null ? 
                    null
                    :
                    <CheckBoxTwoToneIcon  sx={{ color: "green", fontSize: 16, fontWeight: 'medium'  }}/>
                  }
              <Typography color="textSecondary" sx={{textAlign:"left", fontWeight: 'medium', fontSize: 16, color: "#424242"}}>
                {handleString("special")}
              </Typography>
                {SupportData.SpecialSupportStart !== null &&
                  <DownloadFileButton
                      
                  FileName={GeneralPart.Attachments[GeneralPart.Attachments.map(object => object.Id).indexOf(SupportData.IdSpecialSupportDecision)].Name}
                  FileMimeType={GeneralPart.Attachments[GeneralPart.Attachments.map(object => object.Id).indexOf(SupportData.IdSpecialSupportDecision)].FileType}
                  FileId={GeneralPart.Attachments[GeneralPart.Attachments.map(object => object.Id).indexOf(SupportData.IdSpecialSupportDecision)].Id}
                  file={null}
                  useIconButton={false}
                  useThreeDots={false}
                  useTextButton ={true}
                  disabled = {SupportData.IdSpecialSupportDecision === null ? true : false}
                  text={Languages.generalOpen}
                  isImage={false}
                />
                }
            </Stack>

            <List className="col-12" sx={{}}>
              <ListItemButton className="boxed" disabled = {SupportData.SpecialSupportStart !== null ?  false : true} sx={{ backgroundColor: "#fceebf", borderRadius: .5, boxShadow: 2}} onClick={(event) => handleBoxClick(2)}>
                <ListItemText 
                  primaryTypographyProps={{fontWeight: 'Bold', fontSize: 16, ml: 2}} primary={Languages.VasuSpecialSupport} />
                <ListItemIcon>
                {SupportData.SpecialSupportStart !== null ?
                  <ArrowForwardIosIcon fontSize="small" /> :
                  <BlockIcon fontSize="small" sx={{ color: "red" }} />
                  }
                </ListItemIcon>
              </ListItemButton>
            </List>


            {SupportData.IdSpecialSupportDecision !==  null ? 
            <Box className="col-12">
              <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  style={{backgroundColor: "#fceebf"}}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Typography color="textSecondary" sx={{textAlign:"left", fontWeight: 'bold', fontSize: 16, color: "#424242"}}>{Languages.ConsultationChildAndGuardian2}</Typography>
                </AccordionSummary>
                <AccordionDetails style={{backgroundColor: "#ffffff"}}>
                  <Stack className="col-12" direction={isDesktop ? "row" : "column"}  spacing={8} alignItems="center" sx = {[isDesktop ? {mt: 2, ml:4}: {mt: 2}]}>
                  <ApprovalsComponent Approval={SpecialApprovals.Parent1Approval} GeneralPart={GeneralPart} ParentName={GeneralPart.Parent1Name} ParentId={GeneralPart.Parent1Id} DecisionId={SupportData.IdSpecialSupportDecision} Type={0}>  </ApprovalsComponent>
                <ApprovalsComponent Approval={SpecialApprovals.Parent2Approval} GeneralPart={GeneralPart} ParentName={GeneralPart.Parent2Name} ParentId={GeneralPart.Parent2Id} DecisionId={SupportData.IdSpecialSupportDecision} Type={0}>  </ApprovalsComponent>
                  </Stack>
                </AccordionDetails>
              </Accordion>
            </Box>
            : null}
          </Stack>

          {showSnackbar ? 
                        <SnackbarComponent verticalPosition={'bottom'} show={showSnackbar} okColor={true} message ={NoteText} callback={() => handleSnackbarCB()} />
                    :  <></> }
          </div>

          {GeneralPart.Locked === null ?
            <div className="fixed-bottom" style={{left:"auto", fontWeight: "bold", color: "#D500F9"}}>
              <div className="float-right">
                {Languages.logged}
                <LockIcon style={{color: "#D500F9"}} fontSize="large"></LockIcon>
              </div>
            </div>
          : null}
          
        </div>  
        </ContentBoxMUI>
      }
      </div>
  );
  }
}

export default VasuSupportPart;
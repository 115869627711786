import './pedagdocimagefolder.css';
import React, { Component } from 'react';
import { StateContext } from "../../../State";
import { downloadBase64File } from '../../../utils';
import { ServerApi } from '../../../internal';
import PedagDocImageFolderViewManager from './PedagDocImageFolderViewManager';
import ContentBoxMUI from "../../../Components/ContentBoxMUI";

class PedagDocImageFolder extends Component {

  static contextType = StateContext;

  constructor(props) {
    super(props);
    this.state = {
      download: false,
      selectedImages: [],
      childId: this.props.location.state.childId

    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (typeof this.props.location.state === "undefined") {
      this.props.history.push("/growthfolder");
    }
    else {
      const { folder } = this.props.location.state;
      this.context.actions.triggerLoadImages({ childId: this.props.location.state.childId, folder: folder.Id });
    }
  }

  renderImageThumbnail = (image) => {
    let tileStyle;
    this.state.selectedImages.includes(image.Id) ?
      tileStyle = "imagefolder__image-tile--selected"
      :
      tileStyle = "imagefolder__image-tile"

    return (
      <div className={tileStyle} key={image.Id} onClick={(e) => this.handleClick(image.Id)}>
        <button className="pedag-doc-img-folder-thumbnail">
          <img
            src={"data:" + image.MimeType + ";base64," + image.DataThumbnail}
            alt="ImageThumbnail"
          />
        </button>
      </div>

    );
  }

  handleClick = (Id) => {
    if (this.state.download && !this.state.selectedImages.includes(Id)) {
      this.state.selectedImages.push(Id);
    }
    else if (this.state.download && this.state.selectedImages.includes(Id)) {
      const index = this.state.selectedImages.indexOf(Id);
      if (index > -1) {
        this.state.selectedImages.splice(index, 1);
      }
    }
    else {
      this.props.history.push("/growthfolder/media", {
        Id: Id,
        childId: this.props.location.state.childId
      }
      );
    }
  }

  changeMode = () => {
    if (this.state.download === false) {
      this.setState((state) => {
        return { download: true }
      });
    } else if (this.state.download === true) {
      this.setState((state) => {
        return {
          download: false,
          selectedImages: []
        }
      });
    }
  }


  downloadFiles = async () => {
    this.setState((state) => {
      return { loading: true }
    });
    if (this.state.selectedImages === null || this.state.selectedImages.length === 0) {
      this.setState((state) => {
        return { loading: false }
      });
      this.changeMode();
    }

    //TÄNNE LISÄYS!

    else {
      const response = await new ServerApi().loadDownloadImages({ ChildId: this.props.location.state.childId, imageIds: this.state.selectedImages });
      //if(response) {
      if (!response) {
        this.setState((state) => {
          return { loading: false };
        });
        this.changeMode();
      }
      else {

        response.data.map(img => {
          downloadBase64File(img.Data, img.MimeType, img.FileName);
        });
        this.setState(() => {
          return { loading: false }
        });
        this.changeMode();
      }
    }
  }

  reloadFoldersIfFailed = () => {
    this.context.actions.triggerLoadImages({ childId: this.props.location.state.childId, folder: this.props.location.state.folder.Id });
  }

  render() {

    const { growthFolder } = this.context.state;
    const imageFolderFunctions = {
      reloadFoldersIfFailed: this.reloadFoldersIfFailed,
      renderImageThumbnail: this.renderImageThumbnail,
      changeMode: this.changeMode,
      downloadFiles: this.downloadFiles
    };

    return (
      <ContentBoxMUI>
        <PedagDocImageFolderViewManager
          imagefolderState={this.state}
          growthfolderState={growthFolder}
          imageFolderFunctions={imageFolderFunctions}
          imageFolderInformation={this.props.location.state.folder}
          errorCode={growthFolder.errorCode} />
      </ContentBoxMUI>
    );
  }
}

export default PedagDocImageFolder;
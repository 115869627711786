import React            from  'react';
import Languages        from  "../../../../translations";
import KasvunkansioIcon from  "../../../../images/growthfolder.png";
import                        '../pedagdocimagefolder.css';


const PedagDocImageFolderEmpty = () => {
  return(
      <div className = "imagefolder--empty__container">
        <div className = "imagefolder--empty-folder">
          <img src={KasvunkansioIcon} alt = {"Kasvunkansio"} height = "150"/>
          <p>{Languages.emptyFolder}</p>
        </div>
      </div>      
  );
}

export default PedagDocImageFolderEmpty;
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Slide from "@mui/material/Slide";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import Languages from "../../../translations";
import ChildProfilePicture from "../../ChildProfilePicture";
import ContentBoxMUI from "../../ContentBoxMUI";
import LightStylesDesktop from "../CareTimeBookingStyles/LightStylesDesktop";
import { useStateValue } from "./../../../State";
import CTBBottomNav from "./CareTimeBookingBottomNav/CTBBottomNav";
import CTBContentManager from "./CareTimeBookingContent/CTBContentManager";
import CTBContentManager2 from "./CareTimeBookingContent/CTBContentManager2";
import CTBHeaderNav from "./CareTimeBookingHeaderNav/CTBHeaderNav";
import CTBHeaderNav2 from "./CareTimeBookingHeaderNav/CTBHeaderNav2";
import CTBBottomNav2 from "./CareTimeBookingBottomNav/CTBBottomNav2";
import ButtonGroup from "@mui/material/ButtonGroup";
import MenuIcon from "@mui/icons-material/Menu";
import AddIcon from "@mui/icons-material/Add";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupsIcon from "@mui/icons-material/Groups";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
const CareTimeBookingSuccess = ({
  nursingtimes,
  monthChange,
  CTBSummary,
  focusDay,
  handleFocusDay,
  handleCalendarNavClick,
  LoadCareTimesCuick,
  holidays,
}) => {
  /*const [popperAnchorEl, setPopperAnchorEl] = React.useState(null);
  const [popperOpen, setPopperOpen] = React.useState(false);
  const [popperPlacement, setPopperPlacement] = React.useState();
*/
  const { state, actions } = useStateValue();
  const classes = LightStylesDesktop();
  const [showInfo, setShowInfo] = useState(false);
  const [view, setView] = React.useState("kuukausi");
  const [CTBList, setCTBList] = React.useState([]);
  const childList = state.children.ChildList;
  const [CTBSave, setCTBSave] = useState(false);
  const testlist = [];
  const [scrollWeek, setScrollWeek] = React.useState(0);
  const [scrollDay, setScrollDay] = React.useState(0);
  //Uudet varaukset
  const [newReservations, setNewReservations] = React.useState([]);
  //poistetut varaukset
  const [deletedReservations, setDeletedReservations] = React.useState([]);
  //muokatut  varaukset
  const [editedReservations, setEditedReservations] = React.useState([]);
  const [nursingTimeList, setNursingTimeList] = React.useState();
  //console.log(childList);

  for (var key in childList) {
    testlist.push(childList[key].Id);
  }

  const [personName, setPersonName] = React.useState(testlist);

  const handleFormat = (event, newPersonNames) => {
    setPersonName(newPersonNames);
  };

  const handleScrollWeek = (week) => {
    setScrollWeek(week);
  };

  const handleScrollDay = (day) => {
    setScrollDay(day);
  };

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const views = ["viikko", "kuukausi", "valinta"];

  //If deleted reservations contains the existing reservation -> delete it from the list
  //if not, then add it to the list
  const SaveDelete = (personIds, dates) => {
    actions.triggerDeleteNursingtimesMany({
      ChildIds: personIds,
      ListDatesToDelete: dates,
    });
  };

  const handleDelReservations = (existingReservation) => {
    if (
      deletedReservations.some(
        (reservation) => reservation.Id === existingReservation.Id
      )
    ) {
      var updatedReservations = deletedReservations.filter(
        (reservation) => reservation.Id !== existingReservation.Id
      );
      setDeletedReservations(updatedReservations);
    } else {
      deletedReservations.push(existingReservation);
    }
  };

  //if edited reservations contains the existing reservation -> delete it from the list
  // if not, then add it to the list
  const handleEditReservations = (existingReservation) => {
    if (
      editedReservations.some(
        (reservation) => reservation.Id === existingReservation.Id
      )
    ) {
      var updatedReservations = editedReservations.filter(
        (reservation) => reservation.Id !== existingReservation.Id
      );
      setEditedReservations(updatedReservations);
    } else {
      editedReservations.push(existingReservation);
    }
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleView = (event) => {
    setView(event.target.value);
  };

  const handleInfo = () => {
    setShowInfo(!showInfo);
  };

  const handleSave = () => {
    setCTBSave(!CTBSave);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (CTBSave) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [CTBSave]);

  const containerStyles = {
    color: "#002e6d",
  };
  const containerStyles2 = {
    color: "white",
  };

  return (
    <div>
      <ContentBoxMUI sx={{ background: "red" }} header={Languages.growthfolder}>
        <CTBHeaderNav2
          nursingtimes={nursingtimes}
          classes={classes}
          names={childList}
          views={views}
          personName={personName}
          handleNames={handleChange}
          handleView={handleView}
          view={view}
          monthChange={monthChange}
          scrollWeek={scrollWeek}
          scrollDay={scrollDay}
        />
        <br />

        <Dialog
          titleStyle={{ textAlign: "center" }}
          open={CTBSave}
          onClose={handleSave}
          scroll="paper"
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{ backgroundColor: "#FFFFFF" }}
          >
            <Typography color="textSecondary">
              {"Tallennettavat hoitoajat"}
            </Typography>
          </DialogTitle>
          <DialogContent
            ref={descriptionElementRef}
            dividers
            style={{ backgroundColor: "#FFFFFF" }}
          >
            <List disableGutters>
              {childList.map((child) => (
                <>
                  {CTBList.some((ctb) => ctb.childId === child.Id) ? (
                    <ListItem>
                      <Accordion style={{ backgroundColor: "#FFFFFF" }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <>
                            <Stack direction="row" spacing={1}>
                              <Chip
                                sx={{ color: "black" }}
                                avatar={
                                  <Avatar>
                                    <ChildProfilePicture childId={child.Id} />
                                  </Avatar>
                                }
                                label={child.FirstName.split(/\s+/)[0]}
                              ></Chip>
                              <Chip
                                sx={{ color: "black" }}
                                label={
                                  CTBList.filter(
                                    (ctb) => ctb.childId == child.Id
                                  ).length + " Varausta"
                                }
                              ></Chip>
                            </Stack>
                          </>
                        </AccordionSummary>
                        <AccordionDetails>
                          <>
                            <List>
                              {CTBList.map((ctb) => (
                                <>
                                  {ctb.childId === child.Id && (
                                    <ListItem>
                                      <Typography color="textSecondary">
                                        {ctb.centrename +
                                          " " +
                                          ctb.date.format("ddd DD.MM") +
                                          " " +
                                          ctb.start.format("HH:mm") +
                                          "-" +
                                          ctb.end.format("HH:mm")}
                                      </Typography>
                                    </ListItem>
                                  )}
                                </>
                              ))}
                            </List>
                          </>
                        </AccordionDetails>
                      </Accordion>
                    </ListItem>
                  ) : (
                    <></>
                  )}
                </>
              ))}
            </List>
          </DialogContent>
          <DialogActions
            style={{ justifyContent: "center", backgroundColor: "#FFFFFF" }}
          >
            <Button variant="contained" onClick={handleSave}>
              Tallenna
            </Button>
            <Button variant="contained" onClick={handleSave}>
              Sulje
            </Button>
          </DialogActions>
        </Dialog>
        <CTBContentManager2
          nursingtimes={nursingtimes}
          classes={classes}
          personName={personName}
          view={view}
          childList={childList}
          handleDelReservations={SaveDelete}
          handleScrollWeek={handleScrollWeek}
          handleScrollDay={handleScrollDay}
          monthChange={monthChange}
          focusDay={focusDay}
          handleFocusDay={handleFocusDay}
          holidays={holidays}
        />

        <CTBBottomNav2
          classes={classes}
          handleInfo={handleInfo}
          handleSave={handleSave}
          CTBList={CTBList.length + deletedReservations.length}
          nursingtimes={nursingtimes}
          names={childList}
          views={views}
          personName={personName}
          handleNames={handleFormat}
          handleView={handleView}
          view={view}
          monthChange={monthChange}
          handleFocusDay={handleFocusDay}
          handleCalendarNavClick={handleCalendarNavClick}
          LoadCareTimesCuick={LoadCareTimesCuick}
        />
        {/*}
        <Box
          className="testi123"
          sx={{
            backgroundColor: "white",
            width: "400px",
            height: "auto",
            position: "fixed",
            bottom: "5px",
            borderRadius: 2,
            border: "2px solid #002e6d",
            padding: "1px",
          }}
        >
          <ButtonGroup
            size="large"
            fullWidth
            sx={{
              width: "100%",
              "& .MuiButtonGroup-grouped": {
                borderColor: "#ffffff",
              },
            }}
          >
            <Button>
              <MenuIcon style={containerStyles} />
            </Button>
            <Button>
              <GroupsIcon style={containerStyles} />
            </Button>
            <Box sx={{ ml: 3, mr: 3 }}>
              <Button
                variant="contained"
                color="success"
                style={{ borderRadius: 4 }}
              >
                <AddIcon
                  sx={{ padding: 0 }}
                  fontSize="medium"
                  style={containerStyles2}
                />
              </Button>
            </Box>

            <Button></Button>
            <Button>
              <SettingsIcon style={containerStyles} />
            </Button>
          </ButtonGroup>
          </Box>*/}
      </ContentBoxMUI>
      {/*<CTBSummaryDrawer
        CTBSummary={CTBSummary}
        showInfo={showInfo}
        handleInfo={handleInfo}
        childList={childList}
                  ></CTBSummaryDrawer>*/}
    </div>
  );
};

export default CareTimeBookingSuccess;

const Puller = styled(Box)(() => ({
  width: 30,
  height: 6,
  backgroundColor: "#cccccc",
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

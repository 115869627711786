import React, { useEffect, useState } from "react";
import Languages from "../../translations";
import BookingGuideContent from "./BookingGuide/BookingGuideContent";
import DiscussionGuideContent from "./DiscussionGuide/DiscussionGuideContent";
import ScrollToTopButton from "./GuideTools/ScrollToTopButton";
import MessagingGuideContent from "./MessagingGuide/MessagingGuideContent";
import PermissionGuideContent from "./PermissionsGuide/PermissionGuideContent";
import CaretimeCalendarGuideContent from "./CaretimeCalendarGuide/CaretimeCalendarGuideContent";

const GuideModalContentManager = ({ modalType, errorCode }) => {
  function returnCorrectGuideModalContent(modalType) {
    const guideModalContentOptions = {
      BOOKING_GUIDE: function () {
        return (
          <>
            <BookingGuideContent
              modalKeywords="Ei valmis"
              modalContent="Ei valmis"
            />
          </>
        );
      },

      CARETIME_GUIDE: function () {
        return (
          <>
            <CaretimeCalendarGuideContent
              modalKeywords="Ei valmis"
              modalContent="Ei valmis"
            />
          </>
        );
      },

      MESSAGING_GUIDE: function () {
        return (
          <>
            <MessagingGuideContent
              modalKeywords="Ei valmis"
              modalContent="Ei valmis"
            />
          </>
        );
      },

      PERMISSION_GUIDE: function () {
        return (
          <>
            <PermissionGuideContent
              modalKeywords="Ei valmis"
              modalContent="Ei valmis"
            />
          </>
        );
      },

      DISCUSSION_GUIDE: function () {
        return (
          <>
            <DiscussionGuideContent
              modalKeywords="Ei valmis"
              modalContent="Ei valmis"
            />
          </>
        );
      },

      default: function () {
        return <div>{Languages.errorUnknown + "(777)."}</div>;
      },
    };

    return (
      guideModalContentOptions[modalType] || guideModalContentOptions["default"]
    )();
  }

  return (
    <React.Fragment>{returnCorrectGuideModalContent(modalType)}</React.Fragment>
  );
};

export default GuideModalContentManager;

import React from "react";
import BackToUpButton from "../GuideTools/BackToUpButton";
import AddAlarmIcon from "@mui/icons-material/AddAlarm";
import Languages from "../../../translations";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SelectAllIcon from "@mui/icons-material/SelectAll";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
  success: {
    color: "rgb(129, 199, 132)",
    fontSize: "xx-large",
  },
  body2: {
    marginLeft: 25,
    paddingBottom: 10,
  },
});

const AddBookingAccordionText = () => {
  const classes = useStyles();

  return (
    <>
      <div>
        <h4>{Languages.addBookingTableTitle}</h4>
        <div className="guide-table-container">
          <table className="booking-guide-color-schema-table">
            <thead>
              <tr>
                <th className="table-header-for-colors">{Languages.color}</th>
                <th className="table-header-for-description">
                  {Languages.description}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="white-color-cell"></td>
                <td className="booking-table-description-cell">
                  {Languages.addBookingTableDesc1}
                </td>
              </tr>
              <tr>
                <td className="lightblue-color-cell"></td>
                <td className="booking-table-description-cell">
                  {Languages.addBookingTableDesc2}
                </td>
              </tr>
              <tr>
                <td className="pink-color-cell"></td>
                <td className="booking-table-description-cell">
                  {Languages.addBookingTableDesc3}
                </td>
              </tr>
              <tr>
                <td className="orange-color-cell"></td>
                <td className="booking-table-description-cell">
                  {Languages.addBookingTableDesc4}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <br />
        <p className="guide-accordion-details-texts">
          {Languages.addBookingAfterTable1} <AddAlarmIcon />{" "}
          {Languages.addBookingAfterTable2}{" "}
          <b>{Languages.addBookingAfterTable3}</b>
        </p>
        <br />
        <br />
        <hr className="guide-accordion-details-line" />
        <h4>{Languages.addBookingMiddleTitle}</h4>
        <br />
        <div>
          <ol>
            <li className="guide-list-item-styles">
              {Languages.addBookingList1} <SelectAllIcon />{" "}
              {Languages.addBookingList1_2}
            </li>
            <br />
            <li className="guide-list-item-styles">
              {Languages.addBookingList2}{" "}
              <CheckCircleIcon className={classes.success} />
            </li>
            <br />
            <li className="guide-list-item-styles">
              {Languages.addBookingList3}{" "}
              <input
                className="PrivateSwitchBase-input-81"
                style={{ width: "20px", height: "20px" }}
                id="test_NewBooking_Child0"
                type="checkbox"
                data-indeterminate="false"
                checked={true}
                readOnly
              />
            </li>
            <br />
            <li className="guide-list-item-styles">
              {Languages.addBookingList4}
            </li>
            <br />
            <li className="guide-list-item-styles">
              {Languages.addBookingList5}
            </li>
            <br />
            <li className="guide-list-item-styles">
              {Languages.addBookingList6}
            </li>
            <br />
            <li className="guide-list-item-styles">
              {Languages.addBookingList7}
            </li>
            <br />
            <li className="guide-list-item-styles">
              {Languages.addBookingList8}
            </li>
            <br />
            <li className="guide-list-item-styles">
              {Languages.addBookingList9}{" "}
              <CheckCircleIcon className={classes.success} />
            </li>
          </ol>
        </div>
        <br />
        <p className="guide-accordion-details-texts">
          {Languages.addBooking2022Promise}
        </p>
        <br />
        <BackToUpButton />
      </div>
    </>
  );
};

export default AddBookingAccordionText;

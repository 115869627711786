import React from "react";
import Typography from "@mui/material/Typography";
import Languages from "../../translations";

function CookieInfoDetailedContent() {
  return (
    <React.Fragment>
        <hr />    
      <Typography variant="h4" component="h1" color="textSecondary"
                  display="inline" style={{wordWrap:"break-word"}}>{Languages.cookieHeader1}</Typography>
      <br/>
      <br/>
      <Typography variant="h5" component="h3" color="textSecondary" 
                  display="inline" style={{wordWrap:"break-word"}}>{Languages.cookieHeader2a}</Typography>
      <br/>
      <br />
      <Typography variant="body1" color="textSecondary">{Languages.cookiePara1}</Typography>
      <br/>
      <Typography variant="body1" color="textSecondary">{Languages.cookiePara2}</Typography>
      <br/>
      <Typography variant="body1" color="textSecondary">{Languages.cookiePara3}</Typography>
      <br/>
      <Typography variant="body1" color="textSecondary">{Languages.cookiePara4}</Typography>
      <br/>

      <Typography variant="body1" color="textSecondary">
        <ul>

          <li><a href="https://support.google.com/accounts/answer/61416" 
                  target="_blank" 
                  rel="noopener noreferrer">Google Chrome</a></li>
          
          <li><a href="https://support.apple.com/fi-fi/guide/safari/sfri11471/mac" 
                  target="_blank" 
                  rel="noopener noreferrer">Apple Safari</a></li>
          
          <li><a href="https://support.microsoft.com/fi-fi/windows/microsoft-edge-selaustiedot-ja-tietosuoja-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd" 
                  target="_blank" 
                  rel="noopener noreferrer">Microsoft Edge</a></li>
          
          <li><a href="https://support.mozilla.org/fi/kb/firefox-tyopoytaversion-tehostettu-seurannan-suoja" 
                  target="_blank" 
                  rel="noopener noreferrer">Mozilla Firefox</a></li>
          
          <li><a href="https://support.microsoft.com/fi-fi/topic/ev%C3%A4steiden-poistaminen-ja-hallinta-168dab11-0753-043d-7c16-ede5947fc64d" 
                  target="_blank" 
                  rel="noopener noreferrer">Microsoft Internet Explorer</a></li>
          
          <li><a href="https://help.opera.com/en/latest/web-preferences/" 
                  target="_blank" 
                  rel="noopener noreferrer">Opera</a></li>
          
          <li><a href="https://help.duckduckgo.com/duckduckgo-help-pages/settings/save/" 
                  target="_blank" 
                  rel="noopener noreferrer">DuckDuckGo</a></li>
        </ul>
        <hr />
      </Typography>

    </React.Fragment>
  )
}

export default CookieInfoDetailedContent;
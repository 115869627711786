import React, { useEffect, useState } from "react";
import moment from "moment";
import Slider from "@mui/material/Slider";
import { useStateValue } from "../../../../State";
const TimeSlider = ({ handleResLenght }) => {
  const { state, actions } = useStateValue();
  const defaultNursingTimes = state.nursingtimes.defaultNursingTimes;

  //Reservation start/end times stored as minutes for the slider component
  const [value, setValue] = React.useState([
    moment.duration(moment("2020-01-01 08:00").format("HH:mm")).asMinutes(),
    moment.duration(moment("2020-01-01 16:00").format("HH:mm")).asMinutes(),
  ]);

  useEffect(() => {
    if (defaultNursingTimes !== null) {
      console.log(defaultNursingTimes);
      setValue([
        moment
          .duration(moment(defaultNursingTimes.Start).format("HH:mm"))
          .asMinutes(),
        moment
          .duration(moment(defaultNursingTimes.End).format("HH:mm"))
          .asMinutes(),
      ]);
    }
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChange2 = (event, newValue) => {
    handleResLenght(newValue);
  };
  return (
    <Slider
      sx={{
        height: 8,
        borderRadius: 0,
        mt: 2,
        width: "90%",
        ml: "2%",

        "& .MuiSlider-thumb": {
          backgroundColor: "#0074C8",
          height: 24,
          width: 24,
        },

        "& .MuiSlider-track": {
          height: 8,
          color: "#0074C8",
        },
        "& .MuiSlider-rail": {
          color: "#e0e0de",
          opacity: 1,
          height: 8,
        },
        "& .MuiSlider-valueLabel": {
          fontSize: 12,
          fontWeight: "normal",
          top: 2,
          backgroundColor: "unset",
          color: "black",
          "&:before": {
            display: "none",
          },
          "& *": {
            background: "transparent",
            color: "black",
          },
        },
      }}
      value={value}
      valueLabelDisplay="on"
      valueLabelFormat={(value) =>
        moment().startOf("day").add(value, "minutes").format("HH:mm")
      }
      min={0}
      max={1440}
      step={15}
      onChange={handleChange}
      onChangeCommitted={handleChange2}
    />
  );
};

export default TimeSlider;

import "./growthfolder.css";
import React, { Component } from "react";
import Languages from "../../translations";
import { StateContext } from "../../State";
import ContentBoxMUI from "../../Components/ContentBoxMUI";
import { ServerApi } from '../../internal';
import GrowthFolderViewManager from "./GrowthFolderViewManager";

class GrowthFolder extends Component {

  static contextType = StateContext;

  constructor(props) {
    super(props);
    this.state = {
      foldersLoading: false,
      foldersLoaded: false,
      sortNew: true,
      groups: [],
      childId: null
    };
  }

  getGroups = () => {
    const child = this.context.state.children.ChildList.filter(child =>
      child.Id === this.props.location.state.Id);
    const groups = [];
    child[0].Placements.map(placement => {
      if(placement.GroupId && !groups.includes(placement.GroupId)){
        groups.push(placement.GroupId);
      }
    });
    this.setState(() => {
      return {
        groups: groups
      }
    });
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    if (typeof this.props.location.state === undefined) {
      this.props.history.push("/");
    }
    else if (this.context.state.children.ChildList.length === 0) {
      this.props.history.push("/");
    }
    else {
      this.setState(() => {
        return {
          childId: this.props.location.state.Id,
          foldersLoading: true
        }
      });

      await this.getGroups();
      this.context.actions.triggerLoadFolders({ groups: this.state.groups, childId: this.props.location.state.Id, type: "IMAGEFOLDER", sorting: "NEW" });
      this.setState({ foldersLoading: false, foldersLoaded: true });
    }
  }

  // Komponentin päivittyessä lataa kansiot uudestaan jos folderLoaded === false. Tarkistaa myös lapsen, jonka kansiot haetaan (sivupalkin kautta lasta vaihdettaessa
  // osaa ladata valitun lapsen kansiot ja päivittää näkymän.)
  componentDidUpdate() {
    if (this.props.location.state === undefined) {
      this.props.history.push("/");
      return(null)
    }
    else if (this.context.state.children.ChildList.length === 0) {
      this.props.history.push("/");
    }
    if (this.state.foldersLoaded === false) {
      if (typeof this.props.location.state === undefined) {
        this.props.history.push("/");
      }
      else if (this.state.sortNew === true && this.state.foldersLoading === false) {
        this.context.actions.triggerLoadFolders({ groups: this.state.groups, childId: this.props.location.state.Id, type: "IMAGEFOLDER", sorting: "NEW" });
        this.setState({ foldersLoaded: true });
      }
      else if (this.state.sortNew === false && this.state.foldersLoading === false) {
        this.context.actions.triggerLoadFolders({ groups: this.state.groups, childId: this.props.location.state.Id, type: "IMAGEFOLDER", sorting: "OLD" });
        this.setState({ foldersLoaded: true });
      }
    }
    else if (this.state.childId !== this.props.location.state.Id) {
      this.setState({...this.state, childId: this.props.location.state.Id });
      this.context.actions.triggerLoadFolders({ groups: this.state.groups, childId: this.props.location.state.Id, type: "IMAGEFOLDER", sorting: "NEW" });
      this.setState({ foldersLoaded: true });

    }
  }

  loadTaskThumbnails = async (folder) => {
    await new ServerApi().loadTaskThumbnail(this.props.location.state.Id, folder.Id);
  };

  openFolder = (folder) => {
    if (folder.Foldertype === "undefined" || folder.Foldertype === null) {
      return (<div>{Languages.errorUnknown + "(777)."}</div>);
    }
    else if (folder.Foldertype === "IMGF") {
      this.props.history.push("/growthfolder/imagefolder", {
        childId: this.props.location.state.Id,
        folder: folder
      })
    }
    else if (folder.Foldertype === "TF") {
      //this.loadTaskThumbnails(folder);
      this.props.history.push("/growthfolder/taskfolder", {
        childId: this.props.location.state.Id,
        folder: folder
      });
    };
  };
  setSorting = (sort) => {
    this.setState(() => {
      return {
        sortNew: sort
      };
    }
    );
    if (sort === true) {
      this.context.actions.triggerLoadFolders({ groups: this.state.groups, childId: this.props.location.state.Id, type: "IMAGEFOLDER", sorting: "NEW" });
    }
    else {
      this.context.actions.triggerLoadFolders({ groups: this.state.groups, childId: this.props.location.state.Id, type: "IMAGEFOLDER", sorting: "OLD" });
    }
  };

  tryReloadFolders = () => {
    this.context.actions.triggerLoadFolders({ groups: this.state.groups, childId: this.props.location.state.Id, type: "IMAGEFOLDER", sorting: "NEW" });
  };

  render() {
    const growthFolderFunctions = {
      loadTaskThumbnails: this.loadTaskThumbnails,
      tryReloadFolders: this.tryReloadFolders,
      setSorting: this.setSorting,
      openFolder: this.openFolder,
      getGroups: this.getGroups
    }
    return (
      <ContentBoxMUI header={Languages.growthfolder} >
        <GrowthFolderViewManager
          growthFolderFunctions={growthFolderFunctions}
          growthFolder={this.context.state.growthFolder}
          errorCode={this.context.state.growthFolder.errorCode}
        />
      </ContentBoxMUI>
    );
  }
}

export default GrowthFolder;
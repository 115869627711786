import React, { useEffect, useState } from "react";

import Drawer from "@mui/material/Drawer";

import Box from "@mui/material/Box";

import Button from "@mui/material/Button";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import moment from "moment";
import Stack from "@mui/material/Stack";

import Typography from "@mui/material/Typography";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { styled } from "@mui/material/styles";

import { useStateValue } from "../../../../State";
import { history } from "../../../../Router";
import { types } from "../../../../Reducers/actionTypes";
import TimeSlider from "../common/TimeSlider";
const CTBSettingsDrawer = ({ open, onClose }) => {
  const { state, actions } = useStateValue();
  const defaultNursingTimes = state.nursingtimes.defaultNursingTimes;
  const [value4, setValue4] = React.useState();
  const [value5, setValue5] = React.useState();

  useEffect(() => {
    if (defaultNursingTimes !== null) {
      setValue4(defaultNursingTimes.Start);
      setValue5(defaultNursingTimes.End);
    }
  }, []);

  const [ResLenght, setResLenght] = React.useState([]);

  const handleResLenght = (newLenght) => {
    //console.log(moment(newLenght[0]));
    setResLenght(newLenght);
  };

  useEffect(() => {
    if (defaultNursingTimes !== null) {
      console.log(defaultNursingTimes);
      setResLenght([
        moment
          .duration(moment(defaultNursingTimes.Start).format("HH:mm"))
          .asMinutes(),
        moment
          .duration(moment(defaultNursingTimes.End).format("HH:mm"))
          .asMinutes(),
      ]);
    }
  }, []);

  const handleDefaultStart = (newValue) => {
    setValue4(newValue);
  };

  const handleDefaultEnd = (newValue) => {
    setValue5(newValue);
  };
  const handleDefaultNursingTimes = () => {
    actions.triggerSaveDefaultNursingTimes({
      StartTime: moment().startOf("day").add(ResLenght[0], "minutes"),
      EndTime: moment().startOf("day").add(ResLenght[1], "minutes"),
    });
  };

  const handleRedirectToCaretimes = () => {
    if (history.location.pathname === "/nursingtimes") {
      onClose();
    } else {
      history.push("/nursingtimes");
    }
  };
  return (
    <>
      <Drawer
        anchor={"bottom"}
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            width: "100%",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            mb: "40px",
          },
        }}
        sx={{ zIndex: 0 }}
        transitionDuration={{ enter: 500, exit: 400 }}
      >
        <Box
          sx={{
            width: "auto",
            height: "auto",
            backgroundColor: "white",
            borderTopLeftRadius: "inherit",
            borderTopRightRadius: "inherit",
          }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          role="presentation"
        >
          <Puller onClick={onClose} />
          <Stack sx={{ mb: 2 }}>
            <Accordion
              style={{
                backgroundColor: "#FFFFFF",
                boxShadow: "none",
                border: "none",
              }}
              sx={{ width: "100%", mt: 4, mb: 1 }}
              TransitionProps={{
                timeout: 400,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  color="textSecondary"
                  fontWeight="bold"
                  fontSize="16px"
                  align="center"
                  sx={{ width: "100%" }}
                >
                  Oletushoitoaika
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={1} alignItems="center">
                  <TimeSlider handleResLenght={handleResLenght} />
                  <Button
                    variant="contained"
                    color="success"
                    sx={{ width: "65%", backgroundColor: "#00B3DF" }}
                    onClick={() => handleDefaultNursingTimes()}
                  >
                    Tallenna
                  </Button>
                </Stack>
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                backgroundColor: "#FFFFFF",
                boxShadow: "none",
                border: "none",
              }}
              sx={{ width: "100%", mb: 1 }}
              TransitionProps={{
                timeout: 400,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Stack alignItems="center"></Stack>
                <Typography
                  color="textSecondary"
                  fontWeight="bold"
                  fontSize="16px"
                  align="center"
                  sx={{ width: "100%" }}
                >
                  Vanha Varauskalenteri
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={1} alignItems="center">
                  <Typography
                    color="textSecondary"
                    fontWeight="medium"
                    fontSize="14px"
                    align="center"
                    sx={{ width: "100%" }}
                  >
                    Voit siirtyä vanhaan hoitoaikakalenteriin alla olevalla
                    painikkeella
                  </Typography>
                  <Button
                    variant="contained"
                    size="Small"
                    color="success"
                    sx={{ width: "65%", backgroundColor: "#00B3DF" }}
                    onClick={() => handleRedirectToCaretimes()}
                  >
                    Vanha Varauskalenteri
                  </Button>
                </Stack>
              </AccordionDetails>
            </Accordion>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default CTBSettingsDrawer;

const Puller = styled(Box)(() => ({
  width: 30,
  height: 6,
  backgroundColor: "#cccccc",
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

import React from "react";
import BackToUpButton from "../GuideTools/BackToUpButton";
import AddAlarmIcon from "@mui/icons-material/AddAlarm";
import Languages from "../../../translations";
import WarningIcon from "@mui/icons-material/Warning";
import EuroIcon from "@mui/icons-material/Euro";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import SelectAllIcon from "@mui/icons-material/SelectAll";

const BookingSymbolsAccordionText2 = () => {
  return (
    <>
      <div style={{ width: "100%" }}>
        <br />
        <h4>{Languages.BookingSymbolsTableTitle}</h4>
        <div className="guide-table-container">
          <table className="booking-guide-color-schema-table">
            <thead>
              <tr>
                <th className="table-header-for-colors">{Languages.color}</th>
                <th className="table-header-for-description">
                  {Languages.description}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="white-color-cell"></td>
                <td className="booking-table-description-cell">
                  {Languages.addBookingTableDesc1}
                </td>
              </tr>
              <tr>
                <td className="lightblue-color-cell"></td>
                <td className="booking-table-description-cell">
                  {Languages.addBookingTableDesc2}
                </td>
              </tr>
              <tr>
                <td className="darkblue-color-cell"></td>
                <td className="booking-table-description-cell">
                  {Languages.addBookingTableDesc5}
                </td>
              </tr>
              <tr>
                <td className="pink-color-cell"></td>
                <td className="booking-table-description-cell">
                  {Languages.addBookingTableDesc3}
                </td>
              </tr>
              <tr>
                <td className="orange-color-cell"></td>
                <td className="booking-table-description-cell">
                  {Languages.addBookingTableDesc4}
                </td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th className="table-header-for-colors">{Languages.symbol}</th>
                <th className="table-header-for-description">
                  {Languages.description}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="guide-text-cell">
                  <span className="green-dot"></span>{" "}
                  <span className="blue-dot"></span>{" "}
                  <span className="yellow-dot"></span>
                </td>
                <td className="booking-table-description-cell">
                  {Languages.symbolBookingDesc1}
                </td>
              </tr>
              <tr>
                <td className="guide-text-cell">
                  <WarningIcon color="error" fontSize="large" />
                </td>
                <td className="booking-table-description-cell">
                  {Languages.symbolBookingDesc2}
                </td>
              </tr>
              <tr>
                <td className="guide-text-cell">
                  <AddAlarmIcon
                    style={{ color: "rgb(129, 199, 132)" }}
                    fontSize="large"
                  />
                </td>
                <td className="booking-table-description-cell">
                  {Languages.symbolBookingDesc3}
                </td>
              </tr>
              <tr>
                <td className="guide-text-cell">
                  <CheckCircleIcon
                    style={{ color: "rgb(129, 199, 132)" }}
                    fontSize="large"
                  />
                </td>
                <td className="booking-table-description-cell">
                  {Languages.symbolBookingDesc4}
                </td>
              </tr>
              <tr>
                <td className="guide-text-cell">
                  <SelectAllIcon color="secondary" fontSize="large" />
                </td>
                <td className="booking-table-description-cell">
                  {Languages.symbolBookingDesc5}
                </td>
              </tr>
              <tr>
                <td className="guide-text-cell">
                  <EventBusyIcon
                    style={{ color: "rgb(129, 199, 132)" }}
                    fontSize="large"
                  />
                </td>
                <td className="booking-table-description-cell">
                  {Languages.symbolBookingDesc6}
                </td>
              </tr>
              <tr>
                <td className="guide-text-cell">
                  <AddCircleOutlineIcon
                    style={{ color: "rgb(129, 199, 132)" }}
                    fontSize="large"
                  />
                </td>
                <td className="booking-table-description-cell">
                  {Languages.symbolBookingDesc7}
                </td>
              </tr>
              <tr>
                <td className="guide-text-cell">
                  <DeleteIcon color="secondary" fontSize="large" />
                </td>
                <td className="booking-table-description-cell">
                  {Languages.symbolBookingDesc8}
                </td>
              </tr>
              <tr>
                <td className="guide-text-cell">
                  <EuroIcon color="secondary" fontSize="large" />
                </td>
                <td className="booking-table-description-cell">
                  {Languages.symbolBookingDesc9}
                </td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th className="table-header-for-colors">{Languages.text}</th>
                <th className="table-header-for-description">
                  {Languages.description}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="guide-text-cell">
                  {Languages.guideDifferences}
                </td>
                <td className="booking-table-description-cell">
                  {Languages.textBookingDesc1}
                </td>
              </tr>
              <tr>
                <td className="guide-text-cell">
                  {Languages.guideTimeReserved}
                </td>
                <td className="booking-table-description-cell">
                  {Languages.textBookingDesc2}
                </td>
              </tr>
              <tr>
                <td className="guide-text-cell">{Languages.guideRedText}</td>
                <td className="booking-table-description-cell">
                  {Languages.textBookingDesc3}{" "}
                  <p style={{ color: "red" }}>{Languages.redTextExample}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <BackToUpButton />
      </div>
    </>
  );
};

export default BookingSymbolsAccordionText2;

import React, { useState, useEffect } from "react";
import {useStateValue} from "../../State";
import ContentBox from "../../Components/ContentBox";
import Languages from "../../translations";
import { useAuth } from "../../Auth/AuthProvider";
import CircularProgress from "@mui/material/CircularProgress";
import StrongIdentificationNewUserRedirectView from "./Components/StrongIdentificationNewUserRedirectView";
import NewUserOrderUsernameInitView from "./Components/NewUserOrderUsernameInitView";
import NewUserOrderUsernameSuccessView from "./Components/NewUserOrderUsernameSuccessView";
import NewUserOrderUsernameFailedView from "./Components/NewUserOrderUsernameFailedView";

const NewUserPasswordLoginOnly = (props) => {

  const { actions } = useStateValue();
  
  const { state: { loginEmail } } = useStateValue();

  const [email, setEmail] = useState("");
  const [childBirthday, setChildBirthday] = useState("2015-07-15");
  const [emailWarning, setEmailWarning] = useState(false);
  const [birthdayWarning, setBirthdayWarning] = useState(false);


  const { loginSettings, loading } = useAuth();
  
  const [view, setView] = useState("authLoading");



  useEffect(() => {

    if (loading === false) {
      if (typeof loginSettings.StrongIdentification === "undefined" || typeof loginSettings.Password === "undefined") {

        props.history.push("/");
      }
      else if (loginSettings.StrongIdentification) {
        setView("redirectToStrongIdentification");
      }
      else {
        setView("e-mailOrderUsernameAndPassword");
      }
    }
  }, [loginSettings, loading, props.history]);


  const sendRequest = () => {
    const emailValidationOk = emailValidation(email);
    const birthdayValidationOk = birthdayValidation(childBirthday);
    
    emailValidationOk ? setEmailWarning(false) : setEmailWarning(true);
    
    birthdayValidationOk ? setBirthdayWarning(false) : setBirthdayWarning(true);

    if (emailValidationOk && birthdayValidationOk) {
      
      actions.triggerOrderUserNameAndPasswordRequest(
        {
          EmailAddress: email,
          ChildBirthday: childBirthday
        }
      );
    }
  }

  const emailValidation = (email) => {
    if (/(?!.*\.\..*)^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/.test(email.toUpperCase())) {
      return true;
    }
    else {
      return false;
    }
  } 
  
  const birthdayValidation = (childBirthday) => {
    
    const d = new Date();
    const maxYear = d.getUTCFullYear();
    console.log("maxYear", maxYear);
    if (parseInt(childBirthday.substring(0, 4)) > 1940 && childBirthday.substring(0,4) < maxYear) {
      return true;
    }
    else {
      return false;
    }
  }

  const resetThisPage = () => {
    setEmail("");
    setChildBirthday("2015-07-15");
    setEmailWarning(false);
    setBirthdayWarning(false);
    //actions.triggerForgottenPasswordPageReset();
    actions.triggerNewUserPageReset();
  }

  const resetPageAndRedirect = () => {
    setEmail("");
    setChildBirthday("2015-07-15");
    actions.triggerNewUserPageReset();
    props.history.push("/login");
  }

  const goToLogin = () => {
    props.history.push("/login");
  }

  const fns = {
    setChildBirthday: setChildBirthday, 
    setEmail: setEmail, 
    sendRequest: sendRequest, 
    resetPageAndRedirect: resetPageAndRedirect
  }

  const warnings = {
    emailWarning: emailWarning,
    birthdayWarning: birthdayWarning
  }

  if (view === "authLoading") {
    return(<div data-testid="NewUserPasswordLoginOnly-AuthLoading">{Languages.generalLoadingTitle}</div>);
  }

  if (view === "redirectToStrongIdentification") {
    return(
      <StrongIdentificationNewUserRedirectView
        goToLogin={goToLogin}
      />
    )
  }

  if (view === "e-mailOrderUsernameAndPassword") {

    return (
    
      <React.Fragment>
        <div className="Login textCenter">
        <ContentBox header={Languages.orderUserNameAndPassword}>
        
        
        {loginEmail.orderUsername.status !== "ORDER_USERNAME_SUCCESS" && 
          loginEmail.orderUsername.status !== "ORDER_USERNAME_FAILED" &&
            loginEmail.orderUsername.status !== "LOADING_ORDER_USERNAME" &&
              
              <NewUserOrderUsernameInitView
                fns={fns}
                warnings={warnings}
                email={email}
              />
        }

        { loginEmail.orderUsername.status === "LOADING_ORDER_USERNAME" &&
        
          <CircularProgress data-testid="newuser-loading-order-username"/>
        
        }

        { loginEmail.orderUsername.status === "ORDER_USERNAME_SUCCESS" &&
          
          <NewUserOrderUsernameSuccessView
            newusername={typeof loginEmail.orderUsername.newusername !== "undefined" ? loginEmail.orderUsername.newusername : "Error"}
            resetPageAndRedirect={resetPageAndRedirect}
          />
        
        }

        { loginEmail.orderUsername.status === "ORDER_USERNAME_FAILED" &&

          <NewUserOrderUsernameFailedView 
            errorType={loginEmail.orderUsername.errorType}
            resetThisPage={resetThisPage}
            resetPageAndRedirect={resetPageAndRedirect}
          />

        }

        </ContentBox>
        </div>
      </React.Fragment>
    );
  }
}

export default NewUserPasswordLoginOnly;
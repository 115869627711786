import React, { useEffect, useState } from "react";
import { useStateValue } from "../../State/";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import WarningIcon from "@mui/icons-material/Warning";
import { history } from "../../Router";
import Button from "@mui/material/Button";
import Languages from "../../translations";


const MissingVacationBookingsNote = () => {

//deprecated 30.6.2022 - Samuli K
//vacation notifications are now in actionview
//maybe child or children page might get some info if needed in the future!

/*
  const { state: { smallRequests } } = useStateValue();
  const { vacationNotificationStatus: { data } } = smallRequests;

  const answerNow = () => {
    history.push("/nursingtimes", {})
  }

  if (!data.ShowNotification || !data.NotificationTextRows) {
    return (null);
  }


//console.log(data)
  return (
    <Card style={{ backgroundColor: "#e036da" }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid container item xs={3} alignContent="center" justifyContent="center" alignItems="center">
            <WarningIcon />
          </Grid>
          <Grid item xs={9}>
            {data.NotificationTextRows?.map((row, index) => {
              return (<Typography key={index}>{row}</Typography>)
            })}
            <Button style={{ backgroundColor: "#ffca28", marginTop: "10px" }} onClick={answerNow} variant="contained">{Languages.noteGoToBookings}</Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
*/

  return null
}

export default MissingVacationBookingsNote;
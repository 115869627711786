import makeStyles from "@mui/styles/makeStyles";

const LightStyles = makeStyles((theme) => ({
  appBar: {
    top: "auto",
    bottom: 0,
    position: "fixed",
    //width: '80%',
    //alignContent: 'center',
    //textAlign: "center",
    maxHeight: "60px",
    zIndex: theme.zIndex.drawer - 1,
  },
  navAppBar: {
    top: "auto",
    bottom: 55,
    position: "fixed",
    //width: '80%',
    //alignContent: 'center',
    //textAlign: "center",
    maxHeight: "65px",
    backgroundColor: "white",
    zIndex: theme.zIndex.drawer + 1,
  },
  TopAppBar: {
    top: "50px",
    bottom: "auto",
    position: "fixed",
    //width: '80%',
    //alignContent: 'center',
    //textAlign: "center",
    height: "70px",
    zIndex: theme.zIndex.drawer - 1,
    backgroundColor: "#FFFFFF",
  },
  toolBar: {
    display: "inline",
  },
  bottomToolBar: {
    margin: "auto",
    maxWidth: 800,
    width: "100%",
  },
  appBarContainer: {
    //width: '80vh',
    //backgroundColor: 'white',
    // position: 'absolute',
    // backgroundColor: 'red',
    // top: 'auto',
    // bottom: 0,
    //marginRight: '15%',
    // marginLeft: '15%',
  },
  speedDialActions: {
    //zIndex: theme.zIndex.drawer + 5,
    //zIndex: 13001,
    //position: "absolute",
    //display: "block",
    //flexDirection: "column",
    //overflow: "visible",
    //flexGrow: 1,
    //flexDirection: "column-reverse",
    //display: "flex",
    //top: 0,
    //bottom: "500px"
    //display: "block",
    //flexDirection: "column"
    //position: "relative"
    color: "white",
    backgroundColor: "#4377BA",
    //staticTooltipLabel: {color:"white", backgroundColor: "red"},
  },
  staticTooltipLabel: {
    color: "white",
    backgroundColor: "#4377BA",
    width: "100px",
    textAlign: "center",
  },
  fabClosed: {
    backgroundColor: "green",
    color: "white",
    "&:hover": {
      bgcolor: "red",
    },
  },
  fabOpen: {
    backgroundColor: "red",
    color: "white",
    fabOpen: {
      backgroundColor: "red",
    },
  },
  saveButton: {
    bottom: -5,
    left: "50%",

    width: 100,
  },

  speedDialogFab: {
    position: "fixed",
    bottom: "30px",
    zIndex: theme.zIndex.drawer + 2,
    //position: "absolute",
    //textAlign: "center",
    //top: -30,
    //top: "-75%",
    //leftMargin: "500px",
    left: 0,
    right: 0,
    top: "auto",
    margin: "auto",
    //display: "inline-block",
    //backgroundColor: "green",
    //backgroundColor: "red",
    //margin: '0 auto',
    //flexDirection: "column"
    //display: "flex"
  },
  speedDialogEuroFab: {
    position: "fixed",
    bottom: "30px",
    zIndex: theme.zIndex.drawer + 2,
  },
  bottomNavMenu: {
    float: "right",
    margin: "0 auto",
    //bottom: "20px"
  },
  headerPaper: {
    marginTop: "20px",
    width: "100%",
    backgroundColor: "white",
    color: "blue",
    display: "flex",
  },
  headerLeftDiv: {
    width: "25%",
    textAlign: "left",
    marginTop: "auto",
    //display: 'contents',
  },
  headerCenterDiv: {
    width: "50%",
    textAlign: "center",
    display: "-webkit-inline-box",
    display: "inline",
    justifyContent: "center",
  },
  headerRightDiv: {
    width: "25%",
    textAlign: "right",
    marginTop: "auto",
  },
  grow: {
    flexGrow: 1,
  },
  careTimeMonthTitle: {
    display: "inline-block",
    textAlign: "center",
  },
  /*verticalDivider: {
    color: 'black',
    backgroundColor: 'black',
    display: 'inline',
  },*/
  datePickerActiveStyles: {
    backgroundColor: "white",
    color: "#4377BA",
  },
  datePickerInactiveStyles: {
    backgroundColor: "lightgrey",
    color: "#4377BA",
  },
  datePickerAvatar: {
    backgroundColor: "white",
    color: "#4377BA",
    border: "solid",
    borderColor: "#4377BA",
  },
  editDateRangeMenuHeader: {
    backgroundColor: "#4377BA",
    color: "white",
    textAlign: "center",
  },
  popperPaper: {
    backgroundColor: "white",
    //zIndex: theme.zIndex.backdrop + 2,
  },
  dateRangeMenuHeader: {
    padding: "10px",
    marginBottom: "2px",
  },
  backdrop: {
    //zIndex: 100,
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  careTimePopper: {
    zIndex: theme.zIndex.drawer + 2,
  },
  chipAvatarIcon: {
    backgroundColor: "white",
    color: "#4377BA",
  },
  careTimePreviewListItem: {
    backgroundColor: "white",
  },
  careTimePreviewListAvatar: {
    backgroundColor: "white",
    border: "solid 1px",
    borderColor: "#4377BA",
  },
  moreMenuList: {
    minWidth: "300px",
    color: "#4377BA",
    backgroundColor: "white",
    //position: "relative",
  },
  moreMenuListItem: {
    //color: ""
  },
  moreMenuHeader: {
    color: "white",
    backgroundColor: "#4377BA",
    paddingLeft: "5px",
  },
  moreMenuListItemText: {
    paddingLeft: "10px",
    minWidth: "40%",
    //paddingRight: "20px",
  },
  moreMenuListItemTextChosen: {
    paddingLeft: "10px",
    fontSize: "large",
    fontWeight: "600",
  },
  moreMenuSwitchPlacement: {
    //marginLeft: "50px",
    textAlign: "-webkit-right",
    justifyContent: "end",
    width: "100%",
    display: "flex",
    marginTop: "revert",
  },
  moreMenuListItemChecked: {
    width: "100%",
    textAlign: "-webkit-right",
    justifyContent: "end",
  },
}));

export default LightStyles;

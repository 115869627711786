import React, { useState, useEffect } from "react";
import {useStateValue} from "../../../../State";

import Languages        from "../../../../translations";

import Accordion        from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon   from '@mui/icons-material/ExpandMore';
import Typography       from "@mui/material/Typography";

import CommentsReadOnly from '../../../Vasu/CommentsReadOnly';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  nobottomline: {
    borderBottom: "none",
    color: "black",
    backgroundColor: "#ffffff",
    flex: "0 0 100%"
  },
}));

const SupportTable3 = (props) => {

  const classes = useStyles();

  const { Form, callback, closeView } = props;

  const { state/*, actions*/ } = useStateValue();
  const FormT3 = Form === 0 ? state.leops.Table3.Form1.Table[2] :
                 Form === 1 ? state.leops.Table3.Form2.Table[2] :
                 Form === 2 ? state.leops.Table3.Form3.Table[2] : 
                              state.leops.Table3.Form4.Table[2];

  let Row1text = "";
  FormT3.TableRow[0].RowItem.forEach((item)=> {
    Row1text += item.Editor + "\n" + item.RowText + "\n";
  });

  let Row2text = "";
  let Row3text = "";
  
  if (Form === 2 || Form === 3)
  {
    FormT3.TableRow[1].RowItem.forEach((item)=> {
      Row2text += item.Editor + "\n" + item.RowText + "\n";
    });
    FormT3.TableRow[2].RowItem.forEach((item)=> {
      Row3text += item.Editor + "\n" + item.RowText + "\n";
    });
  }

  const [expanded, setExpanded] = useState(false);

  /*console.log("SupportTable3. MOUNT",
              "\n Form: ", Form,
              "\nFormT3: ", FormT3);*/

  const toggleExpanded = () => {
    setExpanded(!expanded);
    callback("TABLE3");
  }

  useEffect(() => {
    //console.log("Table1. useEffect. Close view");
    setExpanded(false);
  },[closeView]); 

  return (
      <Accordion className="painikkeet"
                 style={{ backgroundColor: "#4377BA"/*, color: "black"*/ }}
                 //disabled = {item.Saved === "" ? true: false}
                 expanded={expanded}
                 onChange={() => toggleExpanded()}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel3a-content"
                          id="test_Leops_SupportTable3">
          <Typography className="heading"> {Form === 0 || Form === 1 ? Languages.DocumentsUsedInDrafting : 
                                            Form === 2 ? Languages.LeopsEnhancedTitle4 : Languages.LeopsHojksTitle4}</Typography>
        </AccordionSummary>
        <div className={classes.nobottomline}>

          <AccordionDetails>
            <CommentsReadOnly Title1  = {<Typography color="textSecondary" sx={{textAlign:"left", fontWeight: 'Bold', fontSize: 14, color: "#424242", pt:1, ml:1}}>{Form === 0 || Form === 1 ? Languages.DocumentsUsedInDrafting :
                                         Form === 2 ? Languages.LeopsEnhancedTitle4_1 : Languages.LeopsHojksTitle4_1}</Typography>}
                              Title2  = {null} // kommentoija h-elementtiin
                              Title3  = {null}
                              BgColor = {classes.nobottomline.backgroundColor} 
                              Data    = {{Text: Row1text}}>
            </CommentsReadOnly>
          </AccordionDetails>

        {Form === 2 || Form === 3 ?
          <div>
          <AccordionDetails>
            <CommentsReadOnly Title1  = {<Typography color="textSecondary" sx={{textAlign:"left", fontWeight: 'Bold', fontSize: 14, color: "#424242", pt:1, ml:1}}>{Form === 2 ? Languages.LeopsEnhancedTitle4_2 : Languages.LeopsHojksTitle4_2}</Typography>}
                              Title2  = {null} // kommentoija h-elementtiin
                              Title3  = {null}
                              BgColor = {classes.nobottomline.backgroundColor} 
                              Data    = {{Text: Row2text}}>
            </CommentsReadOnly>
          </AccordionDetails>

          <AccordionDetails>
            <CommentsReadOnly Title1  = {<Typography color="textSecondary" sx={{textAlign:"left", fontWeight: 'Bold', fontSize: 14, color: "#424242", pt:1, ml:1}}>{Form === 2 ? Languages.LeopsEnhancedTitle4_3 : Languages.LeopsHojksTitle4_3}</Typography>}
                              Title2  = {null} // kommentoija h-elementtiin
                              Title3  = {null}
                              BgColor = {classes.nobottomline.backgroundColor} 
                              Data    = {{Text: Row3text}}>
            </CommentsReadOnly>
          </AccordionDetails>
          </div>
        : 
        null}
      </div>
      </Accordion>
  );
}

export default SupportTable3;
// src/react-auth0-spa.js
import React, { useState, useEffect, useContext, useRef, useReducer } from "react";
import moment from "moment";
import AuthClient, { ESUOMI_AUTH_ERROR_TYPES, ESUOMI_AUTH_TYPE_VALUES } from "./AuthClient";
import { LoginSettingsReducer, AuthStateReducer } from "../Reducers/AuthReducers";
import { history } from "../Router";
import { useWindowLocation } from "../Hooks";
import { loginAction, logoutAction, resetPasswordAction, refreshSessionAction, samlLogoutAction } from "./authActions";
import InfoModal from "../Components/Modals/InfoModal";
import Languages from "../translations";
import Button from '@mui/material/Button';
import { initAuthState, initLoginSettingsState } from "../State";
import { authActionTypes } from "../Reducers/authActionTypes";
import { getBackendPublicUrl, getMyUrl, parseQueryString } from "../utils";
import { SessionManager } from ".";
import Typography from "@mui/material/Typography";

// Threshold when to show popup about expiring password
// Value in days
const PASSWORD_EXPIRING_WARNING_THRESHOLD = 14

// const DEFAULT_REDIRECT_CALLBACK = () => {
//   // window.history.replaceState({}, document.title, window.location.pathname);
// }

//TODO:
// Check that password auth is enabled in commune settings. Otherwise don't redirect to settings page
const DEFAULT_REDIRECT_CALLBACK = (authStatus, history) => {
  console.log("onRedirectCallback", authStatus);
  if (authStatus) {
    if (authStatus.authType === ESUOMI_AUTH_TYPE_VALUES.eSuomiRegister) {
      if (authStatus.authenticated) {
        console.log("onRedirectCallback() redirecting to /esuomi_register");
        history.push("/settings", {
          newUserRedirect: true
        });
      } 
    }

    if (authStatus.authType === ESUOMI_AUTH_TYPE_VALUES.eSuomiLogin) {
      if (authStatus.authenticated) {
        console.log("onRedirectCallback() redirecting to /");
        history.push("/");
      } else {
        console.log("onRedirectCallback() authentication failed");
        //history.push("/login");
      }
    }

    if (authStatus.authType === ESUOMI_AUTH_TYPE_VALUES.eSuomiLogout) {
      console.log("esuomi logout detected redirecting to /login");
      history.push("/login");
    }
  }
};

export const AuthContext = React.createContext();

export const useAuth = () => useContext(AuthContext);

export const mapErrorTypeToText = (errorType) => {
  const error = {
    [ESUOMI_AUTH_ERROR_TYPES.noActiveChildFound]: Languages.errorEsuomiLoginNoChild,
    [ESUOMI_AUTH_ERROR_TYPES.noActiveChildPlacements]: Languages.errorEsuomiLoginNoActive,
    [ESUOMI_AUTH_ERROR_TYPES.userNotFound]: Languages.errorEsuomiLoginUserNotFound,
    [ESUOMI_AUTH_ERROR_TYPES.clientCanceled]: Languages.errorEsuomiClientCancel,
    [ESUOMI_AUTH_ERROR_TYPES.idpDenied]: Languages.errorEsuomiAuthFail,
    [ESUOMI_AUTH_ERROR_TYPES.serverError]: Languages.errorEsuomiAuthFail,
    [ESUOMI_AUTH_ERROR_TYPES.unknownError]: Languages.errorEsuomiLoginUnknown,
    'default': Languages.errorEsuomiLoginUnknown,
  }
  if (error[errorType]) {
    return error[errorType];
  }
  return error['default'];
}



export const AuthProvider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [authState, dispatchAuthState] = useReducer(AuthStateReducer, {}, initAuthState);
  const [loginSettings, dispatchLoginSettings] = useReducer(LoginSettingsReducer, {}, initLoginSettingsState);
  const [authClient, setAuth] = useState(new AuthClient(initOptions));
  // State to track LOADING
  const [loading, setLoading] = useState(true);
  // State to track SAVING, like password save
  const [saving, setSaving] = useState({
    onGoing: false,
    lastError: ""
  });
  const [passwordChangeSuccess, setPasswordChangeSuccess] = useState("neutral");

  // Flag to set show esuomi error dialog 
  const [eSuomiErrorPopupOpen, setESuomiErrorPopupOpen] = useState(false);
  // Flag to set true when to show "logout from esuomi button" in esuomi error dialog
  const [eSuomiErrorLogoutRequired, setESuomiErrorLogoutRequired] = useState(false);

  const [warningPopup, setWarningPopup] = useState({
    show: false,
    timeout: undefined
  });
  const [passwordExpirePopup, setPasswordExpirePopup] = useState(false);
  const [passwordChangePopup, setPasswordChangePopup] = useState(false);

  const triggerAppReset = useRef();

  const windowLocation = useWindowLocation();

  useEffect(() => {

    const initAuth = async () => {
      const authFromHook = authClient;
      
      const loadSettings = async () => {
        const loginSettingsResponse = await authFromHook.loadLoginSettings();
        if (loginSettings.error) {
          dispatchLoginSettings({ type: authActionTypes.LOAD_LOGIN_OPTIONS_FAILED, payload: loginSettingsResponse.errorDescription });
        } else {
          dispatchLoginSettings({ type: authActionTypes.LOAD_LOGIN_OPTIONS_SUCCESS, payload: loginSettingsResponse });
        }

        // In future we can fetch another auth related settings here in parallel with loginSettings
        return [loginSettings];
      }

      // Start Loading loginSettings in parallel
      await loadSettings();

      // Handle url parameters when redirect from saml login
      const queryParams = parseQueryString(windowLocation.search);
      if (Object.keys(queryParams).includes("authType")) {
        // First parse parameters and return result object
        const authStatus = await authFromHook.handleRedirectCallback(windowLocation.search, setLoading);

        if (authStatus.status === "error") {
          console.log("esuomi login error detected", authStatus);
          if (authStatus.authType === ESUOMI_AUTH_TYPE_VALUES.eSuomiLogin) {
            dispatchAuthState({ 
              type: authActionTypes.LOGIN_USER_FAILED,
              payload: { 
                lastErrorText: mapErrorTypeToText(authStatus.errorType) 
              } 
            });
            
            // Enable possibility to logout from esuomi if auth in esuomi succeed but fail in daisy.
            if (authStatus.errorType !== ESUOMI_AUTH_ERROR_TYPES.clientCanceled &&
                authStatus.errorType !== ESUOMI_AUTH_ERROR_TYPES.idpDenied) {
              setESuomiErrorLogoutRequired(true);                  
            }
            setESuomiErrorPopupOpen(true);
            
          } else if (authStatus.authType !== ESUOMI_AUTH_TYPE_VALUES.eSuomiLogout) {
            console.log("Esuomi logout failed. ");
          }
        }
        // Call actual redirect call back which can handle needed changes based on authStatus object
        onRedirectCallback(authStatus, history);
      }

      // https://esittely.daisyfamily.fi/?f=fCQ8mNvnQO2o7cxnGeZflx:APA91bGMtW0iDq-ehxP0FcEv6HwFL2Tehtt1Tb8yOjhHAYFUwjDJlg1U-cRLCqVuGqEIesEa5dAblrkQY_diA7AOfia2Zt349WU2y1O1ZI918TXreeLTyTFvY6bX3p6Bb1GxNdMCLkbn&t=android&d=60b22a54-ab13-4c94-869e-51f3fb440537
      if (Object.keys(queryParams).includes("f") &&
        Object.keys(queryParams).includes("t") &&
        Object.keys(queryParams).includes("d")) {
          SessionManager.setFirebaseInfo(queryParams["f"], queryParams["t"], queryParams["d"]);
      }


      const isAuthenticated = authFromHook.isAuthenticated();

      if (isAuthenticated) {
        const user = authFromHook.getUser();
        const userPersonId = authFromHook.getUserPersonId();
        dispatchAuthState({ 
          type: authActionTypes.LOGIN_USER,
          payload: { 
            isAuthenticated,
            user,
            personId: userPersonId
          }
        });
      } else {
        // try to refresh session with refresh token
        console.log("AuthProvider: init login with refresh token");
        refreshSessionAction(authFromHook, setLoading, dispatchAuthState);
      }

      setLoading(false);
    };

    initAuth();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (authState.user) {
      if (authState.passwordChangeRequired) {
        setPasswordChangePopup(true);
        return;
      }

      if (authState.passwordValid) {
        const now = moment();
        const futureDate = moment().add(PASSWORD_EXPIRING_WARNING_THRESHOLD, 'days');
        if (moment(authState.passwordValid).isBetween(now, futureDate)) {
          setPasswordExpirePopup(true);
        }
        
      }
    }
  }, [authState])

  // Used to pass callback fn or data from appstate context which is below inside authProvider
  const setAppStateData = (fn) => {
    triggerAppReset.current = fn;
  }

  // action: Login
  const login = (params = {}) => loginAction(authClient, setLoading, dispatchAuthState, params);

  // action: Logout
  const logout = (reason) => {
    logoutAction(authClient, setLoading, triggerAppReset.current, dispatchAuthState, setWarningPopup, reason)
  };

  // action: samlLogin
  const samlLogin = async () => {
    const baseUrl = getBackendPublicUrl();
    const redirUrl = `${getMyUrl()}/login`;
    window.location = `${baseUrl}/api/esuomi/samlLogin?redirectUrl=${redirUrl}`;
  }

  // action: samlLogout, can be used when error happened in saml login
  const samlLogout = () => samlLogoutAction();

  // action: resetPassword
  const resetPassword = (newPassword) => resetPasswordAction(authClient, setSaving, newPassword, setPasswordChangeSuccess);

  // action: show autologout warning popup
  const showWarningPopup = (show, logoutTime) => {
    setWarningPopup({
      show,
      logoutTime,
    });
  }
  
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: authState.isAuthenticated,
        user: authState.user,
        authState,
        loading,
        saving,
        // popupOpen,
        loginSettings,
        lastLogoutReason: authState.lastLogoutReason,
        warningPopup,
        authActions: {
          samlLogin,
          login,
          logout,
          showWarningPopup,
          resetPassword,
          setAppStateData,
        },
      }}
    >
      <InfoModal modalOpen={eSuomiErrorPopupOpen} headerText={Languages.errorEsuomiLogin} onClose={() => setESuomiErrorPopupOpen(false)} actions={
        <div>
          <Button 
            onClick={() => {
              setESuomiErrorPopupOpen(false);
              history.push("/login");
            }}
            color="primary">
              {Languages.generalClose}
          </Button>
          {eSuomiErrorLogoutRequired && <Button onClick={() => {
              setESuomiErrorPopupOpen(false);
              setESuomiErrorLogoutRequired(false);
              samlLogout();
            }
            } 
            color="primary">{Languages.logoutEsuomiBtn}</Button> }
        </div>
        } 
        allowClose={true}>
        {authState.lastErrorText}
      </InfoModal>

      <InfoModal modalOpen={passwordExpirePopup} headerText={Languages.generalPasswordExpiringTitle} onClose={() => setPasswordExpirePopup(false)} actions={
        <div>
        <Button onClick={() => { 
          setPasswordExpirePopup(false);
          history.push("/settings");
          }} color="primary">{Languages.generalOkBtn}</Button>
        <Button onClick={() => setPasswordExpirePopup(false)} color="primary">{Languages.generalClose}</Button>
        </div>
        } 
        allowClose={true}>
        {Languages.formatString(Languages.generalPasswordExpiringWarning, <b>{moment(authState.passwordValid).format("D.M.YYYY")}</b>)}
      </InfoModal>

      <InfoModal modalOpen={passwordChangePopup} headerText={Languages.loginPasswordChangeTitle} actions={
        <div>
        <Button onClick={() => { 
          setPasswordChangePopup(false);
          history.push("/settings");
          }} color="primary">{Languages.generalOkBtn}</Button>
        </div>
        } 
        allowClose={false}>
        {Languages.loginPasswordMustBeChanged}
      </InfoModal>

      <InfoModal 
        modalOpen={passwordChangeSuccess === "success"} 
        headerText={Languages.loginPasswordChangeTitle} 
        allowClose={false}>
          {passwordChangeSuccess === "success" ? 
            <Typography>{Languages.settingPasswordChangeOK}</Typography> : 
            <Typography>{Languages.settingPasswordChangeFAIL}</Typography>}

          <Button onClick={() => { 
            setPasswordChangeSuccess("neutral");
            }} color="primary">{Languages.generalOkBtn}</Button>
      </InfoModal>


      {children}
    </AuthContext.Provider>
  );
};
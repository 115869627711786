import { types } from "./actionTypes";
import Moment from "moment";

const CareTimeReducer = (state, action) => {
  const payload = action.data;
  switch (action.type) {
    //INIT for laoding first caretimes when opening caretimes calendar
    case types.LOADING_CARETIMES:
      return {
        ...state,
        careTimesList: { state: types.LOADING_CARETIMES },
        testList: {
          ...state.testList,
          state: types.LOADING_CARETIMES,
        },
      };
    case types.CARETIMES_LOAD_OK:
      payload.WeekList.forEach((week) => {
        week.DayList.forEach((dayinfo) => {
          dayinfo.Date = Moment(dayinfo.Date);
        });
      });

      if (
        payload.NursingTimeDays !== null &&
        typeof payload.NursingTimeDays !== undefined
      ) {
        payload.NursingTimeDays.forEach((dayinfo) => {
          dayinfo.Date = Moment(dayinfo.Date);
        });

        return {
          ...state,
          careTimesList: { WeekData: payload, state: types.CARETIMES_LOAD_OK },
          testList: {
            ...state.testList,
            WeekData: {
              ...state.testList.WeekData,
              WeekList: [...state.testList.WeekData.WeekList, payload.WeekList],
            },
            DayList: [...payload.NursingTimeDays],
            state: types.CARETIMES_LOAD_OK,
          },
        };
      }

      return {
        ...state,
        careTimesList: { WeekData: payload, state: types.CARETIMES_LOAD_OK },
        testList: {
          ...state.testList,
          WeekData: {
            ...state.testList.WeekData,
            WeekList: [...state.testList.WeekData.WeekList, payload.WeekList],
          },
          state: types.CARETIMES_LOAD_OK,
        },
      };

    case types.CARETIMES_LOAD_FAILED:
      return {
        ...state,
        careTimesList: {
          Code: payload.Code,
          state: types.CARETIMES_LOAD_FAILED,
        },
        testList: {
          ...state.testList,
          state: types.CARETIMES_LOAD_FAILED,
        },
      };

    //WIP for loading next/previous nursing times and appending data to the end or beginning of the list
    case types.LOADING_CARETIMES_NEXT:
      console.log("CARETIMES NEXT LOAD OK");
      return {
        ...state,
        careTimesList: { state: types.LOADING_CARETIMES },
      };
    case types.CARETIMES_NEXT_LOAD_OK:
      payload.WeekList.forEach((week) => {
        week.DayList.forEach((dayinfo) => {
          dayinfo.Date = Moment(dayinfo.Date);
        });
      });
      payload.NursingTimeDays.forEach((dayinfo) => {
        dayinfo.Date = Moment(dayinfo.Date);
      });

      return {
        ...state,
        careTimesList: {
          WeekData: payload,
          state: types.CARETIMES_NEXT_LOAD_OK,
        },
        testList: {
          ...state.testList,
          WeekData: {
            ...state.testList.WeekData,
            WeekList: [...state.testList.WeekData.WeekList, payload.WeekList],
          },
          DayList: [...state.testList.DayList, ...payload.NursingTimeDays],
          state: types.CARETIMES_NEXT_LOAD_OK,
        },
      };

    case types.CARETIMES_NEXT_LOAD_FAILED:
      return {
        ...state,
        careTimesList: {
          Code: payload.Code,
          state: types.CARETIMES_NEXT_LOAD_FAILED,
        },
      };

    case types.LOADING_CARETIMES_PREV:
      return {
        ...state,
        careTimesList: { state: types.LOADING_CARETIMES },
      };
    case types.CARETIMES_PREV_LOAD_OK:
      console.log("CARETIMES PREV LOAD OK");
      payload.NursingTimeDays.forEach((dayinfo) => {
        dayinfo.Date = Moment(dayinfo.Date);
      });

      payload.WeekList.forEach((week) => {
        week.DayList.forEach((dayinfo) => {
          dayinfo.Date = Moment(dayinfo.Date);
        });
      });

      return {
        ...state,
        careTimesList: {
          WeekData: payload,
          state: types.CARETIMES_PREV_LOAD_OK,
        },
        testList: {
          ...state.testList,
          WeekData: {
            ...state.testList.WeekData,
            WeekList: [payload.WeekList, ...state.testList.WeekData.WeekList],
          },
          DayList: [...payload.NursingTimeDays, ...state.testList.DayList],
          state: types.CARETIMES_PREV_LOAD_OK,
        },
      };

      return {
        ...state,
        careTimesList: { WeekData: payload, state: types.CARETIMES_LOAD_OK },
        testList: {
          ...state.testList,
          WeekData: {
            ...state.testList.WeekData,
            WeekList: [payload.WeekList, ...state.testList.WeekData.WeekList],
          },
        },
      };

    case types.CARETIMES_PREV_LOAD_FAILED:
      return {
        ...state,
        careTimesList: {
          Code: payload.Code,
          state: types.CARETIMES_PREV_LOAD_FAILED,
        },
      };

    //WIP for quick info for top nav
    case types.LOADING_CARETIMES_QUICK:
      return {
        ...state,
        Quick: { state: types.LOADING_CARETIMES_QUICK },
      };
    case types.CARETIMES_QUICK_LOAD_OK:
      return {
        ...state,
        Quick: {
          ...state.Quick,
          state: types.CARETIMES_QUICK_LOAD_OK,
          DayList: payload.DayList,
          DayList2: payload.DayList2,
          HolidayList: payload.HolidayList,
          VacationList: payload.VacationList,
          VacationPeriodList: payload.VacationPeriodList,
        },
      };
    case types.CARETIMES_QUICK_LOAD_FAILED:
      return {
        ...state,
        Quick: {
          state: types.CARETIMES_QUICK_LOAD_FAILED,
        },
      };

    case types.LOADING_CARETIMES_QUICK_NEXT:
      return {
        ...state,
        Quick: { state: types.LOADING_CARETIMES_QUICK_NEXT },
      };
    case types.CARETIMES_QUICK_LOAD_NEXT_OK:
      console.log(payload);
      return {
        ...state,
        Quick: {
          ...state.Quick,
          state: types.CARETIMES_QUICK_LOAD_OK,
          DayList: [payload.DayList, ...state.Quick.DayList],
        },
      };
    case types.CARETIMES_QUICK_LOAD_NEXT_FAILED:
      return {
        ...state,
        Quick: {
          state: types.CARETIMES_QUICK_LOAD_NEXT_FAILED,
        },
      };

    //WIP for checking new caretimes before actually saving them
    case types.CHECKING_CARETIMES:
      return {
        ...state,
        checkList: { state: types.CHECKING_CARETIMES },
      };
    case types.CARETIMES_CHECK_OK:
      return {
        ...state,
        checkList: { checkData: payload, state: types.CARETIMES_CHECK_OK },
      };
    case types.CARETIMES_CHECK_FAILED:
      return {
        ...state,
        checkList: { Code: payload.Code, state: types.CARETIMES_CHECK_FAILED },
      };

    //WIP SAVING CARETIMES
    case types.SAVING_CARETIMES:
      return {
        ...state,
        CTSaved: { state: types.SAVING_CARETIMES },
      };
    case types.CARETIMES_SAVED_OK:
      if (payload[1].Code === -2) {
        var selectedData2 = {
          DeleteCode: payload[0] === null ? null : payload[0].Code,
          DeleteErros:
            payload[0] === null || payload[0].listErrors.length === 0
              ? null
              : payload[0].listErrors,
          DeleteGroupedErros:
            payload[0] === null || payload[0].GroupedErrors.length === 0
              ? null
              : payload[0].GroupedErrors,
          SavedCode: payload[1] === null ? null : payload[1].Code,
          SaveErros:
            payload[1] === null || payload[1].listErrors.length === 0
              ? null
              : payload[1].listErrors,
          SaveGroupedErros:
            payload[1] === null || payload[1].GroupedErrors.length === 0
              ? null
              : payload[1].GroupedErrors,
        };
        return {
          ...state,
          CTSaved: {
            state: types.CARETIMES_SAVED_FAILED,
            Code: payload[1].Code,
            Data: selectedData2,
          },
        };
      }
      if (payload[1].Code === 0) {
        payload[1].testData.NursingTimeDays.forEach((dayinfo) => {
          dayinfo.Date = Moment(dayinfo.Date);
        });

        payload[1].testData.WeekList.forEach((week) => {
          week.DayList.forEach((dayinfo) => {
            dayinfo.Date = Moment(dayinfo.Date);
          });
        });
        return {
          ...state,
          testList: {
            ...state.testList,
            WeekData: payload[1].testData,
          },
          testList: {
            ...state.testList,
            WeekData: {
              ...state.testList.WeekData,
              WeekList: [payload[1].testData.WeekList],
            },
            DayList: [...payload[1].testData.NursingTimeDays],
          },
          CTSaved: {
            state: types.CARETIMES_SAVED_OK,
            Code: 0,
            Data: [...payload[1].GroupedErrors],
          },
          Quick: {
            ...state.Quick,
            state: types.CARETIMES_QUICK_LOAD_OK,
            DayList: payload[1].testData.QuickData.DayList,
            DayList2: payload[1].testData.QuickData.DayList2,
            HolidayList: payload[1].testData.QuickData.HolidayList,
            VacationList: payload[1].testData.QuickData.VacationList,
            VacationPeriodList:
              payload[1].testData.QuickData.VacationPeriodList,
          },
        };
      }

    case types.CARETIMES_SAVED_FAILED:
      console.log(payload);
      var selectedData = {};
      selectedData2 = {
        DeleteCode: payload[0].Code === null ? null : payload[0].Code,
        DeleteErros:
          payload[0] === null || payload[0].listErrors.length === 0
            ? null
            : payload[0].listErrors,
        DeleteGroupedErros:
          payload[0] === null || payload[0].GroupedErrors.length === 0
            ? null
            : payload[0].GroupedErrors,
        SavedCode: payload[1].Code === null ? null : payload[1].Code,
        SaveErros:
          payload[1] === null || payload[1].listErrors.length === 0
            ? null
            : payload[1].listErrors,
        SaveGroupedErros:
          payload[1] === null || payload[1].GroupedErrors.length === 0
            ? null
            : payload[1].GroupedErrors,
      };
      return {
        ...state,
        CTSaved: {
          state: types.CARETIMES_SAVED_FAILED,
          Code: 666,
          Data: selectedData2,
        },
      };

    //WIP SAVING ABSENCES
    case types.CARETIMES_ABSENT_SAVED_OK:
      if (payload.responseData[1].Code === -2) {
        var selectedData2 = {
          DeleteCode:
            payload.responseData[0] === null
              ? null
              : payload.responseData[0].Code,
          DeleteErros:
            payload.responseData[0] === null ||
            payload.responseData[0].listErrors.length === 0
              ? null
              : payload.responseData[0].listErrors,
          DeleteGroupedErros:
            payload.responseData[0] === null ||
            payload.responseData[0].GroupedErrors.length === 0
              ? null
              : payload.responseData[0].GroupedErrors,
          SavedCode:
            payload.responseData[1] === null
              ? null
              : payload.responseData[1].Code,
          SaveErros:
            payload.responseData[1] === null ||
            payload.responseData[1].listErrors.length === 0
              ? null
              : payload.responseData[1].listErrors,
          SaveGroupedErros:
            payload.responseData[1] === null ||
            payload.responseData[1].GroupedErrors.length === 0
              ? null
              : payload.responseData[1].GroupedErrors,
        };
        return {
          ...state,
          Absent: {
            state: types.CARETIMES_ABSENT_SAVED_FAILED,
            Code: payload.responseData[1].Code,
            Data: selectedData2,
          },
        };
      }
      payload.responseData[1].testData.WeekList.forEach((week) => {
        week.DayList.forEach((dayinfo) => {
          dayinfo.Date = Moment(dayinfo.Date);
        });
      });

      payload.responseData[1].testData.NursingTimeDays.forEach((dayinfo) => {
        dayinfo.Date = Moment(dayinfo.Date);
      });
      return {
        ...state,
        testList: {
          ...state.testList,
          WeekData: payload.responseData[1].testData,
        },
        testList: {
          ...state.testList,
          WeekData: {
            ...state.testList.WeekData,
            WeekList: [payload.responseData[1].testData.WeekList],
          },
          DayList: [...payload.responseData[1].testData.NursingTimeDays],
        },
        Absent: { state: types.CARETIMES_ABSENT_SAVED_OK, Code: 0 },
        Quick: {
          ...state.Quick,
          state: types.CARETIMES_QUICK_LOAD_OK,
          DayList: payload.responseData[1].testData.QuickData.DayList,
          DayList2: payload.responseData[1].testData.QuickData.DayList2,
          HolidayList: payload.responseData[1].testData.QuickData.HolidayList,
          VacationList: payload.responseData[1].testData.QuickData.VacationList,
          VacationPeriodList:
            payload.responseData[1].testData.QuickData.VacationPeriodList,
        },
      };

    case types.DELETING_CARETIMES:
      return {
        ...state,
        Deleted: { state: types.DELETING_CARETIMES },
      };
    case types.CARETIMES_DELETED_OK:
      payload.testData.NursingTimeDays.forEach((dayinfo) => {
        dayinfo.Date = Moment(dayinfo.Date);
      });

      var selectedData = {
        Code: payload === "" ? null : payload.Code,
        Erros:
          payload === "" || payload.listErrors.length === 0
            ? null
            : payload.listErrors,
      };

      return {
        ...state,
        testList: {
          ...state.testList,
          DayList: [...payload.testData.NursingTimeDays],
        },
        Deleted: {
          Data: selectedData,
          state: types.CARETIMES_DELETED_OK,
        },
        Quick: {
          ...state.Quick,
          state: types.CARETIMES_QUICK_LOAD_OK,
          DayList: payload.testData.QuickData.DayList,
          DayList2: payload.testData.QuickData.DayList2,
          HolidayList: payload.testData.QuickData.HolidayList,
          VacationList: payload.testData.QuickData.VacationList,
          VacationPeriodList: payload.testData.QuickData.VacationPeriodList,
        },
      };

    case types.CARETIMES_DELETED_FAILED:
      return {
        ...state,
        Deleted: {
          Code: payload.Code,
          Message: payload.Message,
          state: types.CARETIMES_DELETED_FAILED,
        },
      };
    case types.CARETIMES_ABSENT_SAVED_FAILED:
      return {
        ...state,
        Absent: { state: types.CARETIMES_ABSENT_SAVED_FAILED },
      };
    case types.SAVING_CARETIMES_ABSENT:
      return {
        ...state,
        Absent: { state: types.SAVING_CARETIMES_ABSENT },
      };
    case types.LOADING_CLOCK_TYPE:
      return {
        ...state,
        clockType: { state: types.LOADING_CLOCK_TYPE },
      };
    case types.CLOCK_TYPE_LOAD_OK:
      return {
        ...state,
        clockType: { state: types.CLOCK_TYPE_LOAD_OK, Type: payload.Type },
      };
    case types.CLOCK_TYPE_LOAD_FAILED:
      return {
        ...state,
        clockType: { state: types.CLOCK_TYPE_LOAD_FAILED },
      };

    case types.SAVING_CLOCK_TYPE:
      return {
        ...state,
        clockType: { state: types.SAVING_CLOCK_TYPE },
      };
    case types.CLOCK_TYPE_SAVED_OK:
      return {
        ...state,
        clockType: {
          state: types.CLOCK_TYPE_SAVED_OK,
          Type: payload.Type.Type,
        },
      };
    case types.CLOCK_TYPE_SAVED_FAILED:
      return {
        ...state,
        clockType: { state: types.CLOCK_TYPE_SAVED_FAILED },
      };
    default:
      return state;
  }
};

export default CareTimeReducer;

import React      from "react";
import Typography from "@mui/material/Typography";
import Languages  from '../../translations';         
import Dialog     from "@mui/material/Dialog";
import DialogTitle  from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from '@mui/material/Button';


const GeneralPermDialog = ({isVisible, closeGeneralPermDialog, handleForceClose, changeEditStatusOff}) => {

  const forceClose = () => {
    closeGeneralPermDialog()
    changeEditStatusOff()
    handleForceClose()
  }

  if (!isVisible) {
    return(null);
  }

  return (
    <Dialog 
      PaperProps={{
        style: {
          backgroundColor: "#B30036"
        },
      }}
      onClose={closeGeneralPermDialog} 
      aria-labelledby="close-popup" 
      open={isVisible}
    >
      <DialogTitle six={{display: "inline-block"}} id="dialog-NullWarningPopUp">{Languages.generalAreYouSure2Leave}</DialogTitle>
      <DialogContent sx={{backgroundColor: "#F9E0E5"}}>
        <br />
        <Typography sx={{color: "black"}}>{Languages.permDialogNotSavedText}</Typography>
        <br/>
        <Button onClick={forceClose} sx={{backgroundColor: "#B30036",  '&:hover, &.Mui-focusVisible': {
          boxShadow: `0px 0px 0px 1px`, backgroundColor: "#8F002B", borderColor: "#8F002B"
          }}} variant="contained">{Languages.ciDeleteYes}</Button>
        <Button onClick={closeGeneralPermDialog} sx={{color: "#8F002B", borderColor: "#E18994", float: "right", '&:hover, &.Mui-focusVisible': {
          boxShadow: `0px 0px 0px 1px`, borderColor: "#8F002B"
          }}} variant="outlined">{Languages.generalBack}</Button>
      </DialogContent>
    </Dialog>
  );
}

export default GeneralPermDialog;
import React   from "react";
import "../messaging.css";
import Languages              from "../../../translations";
import FixedBottomBar         from "../../../Components/FixedBottomBar";
import Card                   from "@mui/material/Card";
import CardContent            from "@mui/material/CardContent";
import MessagingBottomBar     from "./MessagingBottomBar";

const MessagingZeroMessages = ({refresh, showNew}) => {

return(
  <React.Fragment>
    <Card style={{backgroundColor:"#4377BA"}}>             
      <CardContent>
        <div>  
          <div className="row no-gutters">
            <div className="col-2 marginx"></div>
            <div className="col-7">
              <div>{Languages.msgNoMessagesInfo}</div>
            </div>
            <div className="col-3 skeletonCenter15"></div> 
          </div>
        </div>
      </CardContent>
    </Card>

    <FixedBottomBar>
      <MessagingBottomBar loading={false} refresh={refresh} showNew={showNew}/>
    </FixedBottomBar>
  </React.Fragment>
)
}

export default MessagingZeroMessages;
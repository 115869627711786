import React from "react";
//import ContentBox from "../../../Components/ContentBox";

import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import { DialogContent, IconButton } from "@mui/material";

import withStyles from '@mui/styles/withStyles';

const styles = (theme) => ({
  root: {
    boxShadow: '0px 0px 2px 3px #0000001f',

    color: 'white',
    padding: '5px 2px 5px 10px',
    zIndex: 1, // zIndexin asetus täällä on vähän haxi mutta jostain syystä Dialogin renderöimä backdrop jää kaikkien päälle
    position: 'relative',
    fontSize: '1.2rem',
    //fontFamily: 'Boogaloo cursive',
    background: '#4377BA' //'linear-gradient(-180deg, #30cfff, #00b7f1, #00abe1)',
  },
  content: {
    backgroundColor: 'white',
    color:'black',
    zIndex: 1,
  },
  actions: {
    zIndex: 1,
    backgroundColor: 'white',
  },
  closeButton: {
    marginTop:"-2px",
    position: 'absolute',
    right: "10px",
    //top: theme.spacing(0),
    color: "#ffffff",//theme.palette.primary[500],
    zIndex: 1
  },
})

const InfoModal = ({ modalOpen=true, headerText, classes, children, onClose, actions, allowClose=false }) => {
  return (
    <Dialog open={modalOpen} id={"test_InfoModal"} maxWidth="sm" fullWidth={true}>
      <DialogTitle 
        className={classes.root} 
        style={allowClose && !headerText ? {minHeight:"44px"} : {}}>{headerText}
      { allowClose && 
        <IconButton size="small" aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>}
      </DialogTitle>
      <DialogContent className={classes.content}>
        <div style={{marginTop:"4px"}}></div>
        {children}
      </DialogContent>
      { actions && <DialogActions className={classes.actions}>
        {actions}
      </DialogActions> }
    </Dialog>
  );
};

export default withStyles(styles)(InfoModal);
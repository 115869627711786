import { types } from './actionTypes';

const MunicipalPermissionsReducer = (state, action) => {

switch(action.type) {

  case types.CLEAN_LOAD_CHILD_PERMPERMISSIONS:
    return {
      ...state,
      childPermPermissionsList: {
        status: "NEVER_LOADED",
        Code: undefined,
        data: undefined,
      }
    }

  case types.LOADING_CHILD_PERMPERMISSIONS:
    return {
      ...state,
      childPermPermissionsList: {
        status: types.LOADING_CHILD_PERMPERMISSIONS,
      }
    }

  case types.LOAD_CHILD_PERMPERMISSIONS_SUCCESS:
    return {
      ...state,
      childPermPermissionsList: {
        status: types.LOAD_CHILD_PERMPERMISSIONS_SUCCESS,
        Code: action.Code,
        data: action.data,
      }
    }  

  case types.LOAD_CHILD_PERMPERMISSIONS_FAILED:
    return {
      ...state,
      childPermPermissionsList: {
        status: types.LOAD_CHILD_PERMPERMISSIONS_FAILED,
        Code: action.data.Code
      }
    }

//
  case types.CLEAN_POST_CHILD_PERMPERMISSIONS:
    return {
      ...state,
        saveChildPermPermissions: {
          status: "NEVER_LOADED",
          Code: undefined,
        }
    }

  case types.LOADING_POST_CHILD_PERMPERMISSIONS:
    return {
      ...state,
        saveChildPermPermissions: {
          status: types.LOADING_POST_CHILD_PERMPERMISSIONS,
        }
    }

  case types.POST_CHILD_PERMPERMISSIONS_SUCCESS:
    return {
      ...state,
        saveChildPermPermissions: {
          status: types.POST_CHILD_PERMPERMISSIONS_SUCCESS,
          Code: action.Code
        }
    }

  case types.POST_CHILD_PERMPERMISSIONS_FAILED:
    return {
      ...state,
        saveChildPermPermissions: {
          status: types.POST_CHILD_PERMPERMISSIONS_FAILED,
          Code: action.data.Code,
        }
    }

//
  case types.LOADING_GUARDIAN_CONSENTS:
    return {
      ...state,
      guardianConsent: {
        status: types.LOADING_GUARDIAN_CONSENTS,
      }
    }

  case types.LOAD_GUARDIAN_CONSENTS_SUCCESS:
    return {
      ...state,
      guardianConsent: {
        status: types.LOAD_GUARDIAN_CONSENTS_SUCCESS,
        Code: action.Code,
        data: action.data
      }
    }

  case types.LOAD_GUARDIAN_CONSENTS_FAILED:
    return {
      ...state,
      guardianConsent: {
        status: types.LOAD_GUARDIAN_CONSENTS_FAILED,
        Code: action.data.Code,
        data: action.data
      }
    }

  case types.CLEAN_POST_GUARDIAN_CONSENTS:
    return {
      ...state,
      saveGuardianConsent: {
        status: "NEVER_LOADED",
        Code: undefined,
      }
    }

  case types.LOADING_POST_GUARDIAN_CONSENTS:
    return {
      ...state,
      saveGuardianConsent: {
        status: types.LOADING_POST_GUARDIAN_CONSENTS
      }
    }

  case types.POST_GUARDIAN_CONSENTS_SUCCESS:
    return {
      ...state,
      saveGuardianConsent: {
        status: types.POST_GUARDIAN_CONSENTS_SUCCESS,
        Code: action.Code,
      }
    }

  case types.POST_GUARDIAN_CONSENTS_FAILED:
    return {
      ...state,
      saveGuardianConsent: {
        status: types.POST_GUARDIAN_CONSENTS_FAILED,
        Code: action.data.Code,
      }
    }
  
  //kyselyt:
  case types.LOADING_PERMISSION_QUERIES:
    return {
      ...state,
      permissionQueryList: {
        status: types.LOADING_PERMISSION_QUERIES,
      }
    }

  case types.LOAD_PERMISSION_QUERIES_SUCCESS:
    return {
      ...state,
      permissionQueryList: {
        status: types.LOAD_PERMISSION_QUERIES_SUCCESS,
        Code: action.Code,
        data: action.data,
      }
    }

  case types.LOAD_PERMISSION_QUERIES_FAILED:
    return {
      ...state,
      permissionQueryList: {
        status: types.LOAD_PERMISSION_QUERIES_FAILED,
        Code: action.data.Code
      }
    }
  
  case types.LOADING_SINGLE_PERMISSION_QUERY:
    return {
      ...state,
      onePermissionQuery: {
        status: types.LOADING_SINGLE_PERMISSION_QUERY
      }
    }

  case types.LOAD_SINGLE_PERMISSION_QUERY_SUCCESS:
    return {
      ...state,
      onePermissionQuery: {
        status: types.LOAD_SINGLE_PERMISSION_QUERY_SUCCESS,
        Code: action.Code,
        data: action.data,
      }
    }

  case types.LOAD_SINGLE_PERMISSION_QUERY_FAILED:
    return {
      ...state,
      onePermissionQuery: {
        status: types.LOAD_SINGLE_PERMISSION_QUERY_FAILED,
        Code: action.data.Code
      }
    }

  case types.LOADING_POST_SINGLE_PERMISSION_QUERY: 
    return {
      ...state,
      savePermissionQuery: {
        status: types.LOADING_POST_SINGLE_PERMISSION_QUERY,
      }
    }

  case types.POST_SINGLE_PERMISSION_QUERY_SUCCESS:
    return {
      ...state,
      savePermissionQuery: {
        status: types.POST_SINGLE_PERMISSION_QUERY_SUCCESS,
        Code: action.Code,
        data: action.data
      }
    }

  case types.POST_SINGLE_PERMISSION_QUERY_FAILED: 
    return {
      ...state,
      savePermissionQuery: {
        status: types.POST_SINGLE_PERMISSION_QUERY_FAILED,
        Code: action.data.Code
      }
    }



    default:
  }
    
  return state;
}


export default MunicipalPermissionsReducer;

import { ApiBase } from "../internal";

export class ChildrenApi extends ApiBase {
  async loadChildren() {
    //console.log("CHILDREN API. loadChildren");
    const response = await this.httpClient.get("/persons/getchildren/",
      {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": sessionStorage.getItem('language') === "en" ? "en-US" : sessionStorage.getItem('language')
        }
      });
    //console.error("CHILDREN API. Load children response: ", response);
    // TODO: tämä pitäisi olla kyllä reducerin puolella
    /*for (var i = 0; i < response.data.ListChildren.length; i++) {
      var placements = response.data.ListChildren[i].Placements;
      for (var w = 0; w < placements.length; w++) {
        placements[w].Start = Moment(placements[w].Start);
        placements[w].End = placements[w].End === null ? null : Moment(placements[w].End);
      }
      response.data.ListChildren[i].Placements = placements;
    }*/
    return response;
  }

  async loadChildInfo(ChildId) {
    const response = await this.httpClient.get("/persons/getchildinfo/" + ChildId,
      {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": sessionStorage.getItem('language') === "en" ? "en-US" : sessionStorage.getItem('language')
        }
      });

    //console.log("CHILDREN API. Load child info response: ", response);
    return response;

  }

  async saveChildInfo(ChildInfoData) {
    const response = await this.httpClient.post("/persons/savechildinfo", ChildInfoData,
      {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": sessionStorage.getItem('language') === "en" ? "en-US" : sessionStorage.getItem('language')
        }
      });

    //console.log("CHILDREN API. Save child info response: ", response);
    return response;
  }

  async addBackupParent(childId, data) {
    const response = await this.httpClient.post(`/persons/backup-persons/${childId}`, data);
    return response;
  }

  async updateBackupParent(childId, data) {
    const response = await this.httpClient.put(`/persons/backup-persons/${childId}`, data);
    return response;
  }

  async deleteBackupParent(childId, backupParentId) {
    const response = await this.httpClient.delete(`/persons/backup-persons/${childId}/${backupParentId}`);
    return response;
  }
}
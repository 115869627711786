import React, { useState, useEffect } from "react";
import {useStateValue} from "../../../State";
import "../../Vasu/vasu.css";

import CommentsReadOnly  from '../../Vasu/CommentsReadOnly';
import ContentBoxMUI     from "../../../Components/ContentBoxMUI";
import SnackbarComponent from '../../../Components/Snackbar';

import Languages      from "../../../translations";

import SwipeableViews   from 'react-swipeable-views';

import MobileStepper    from '@mui/material/MobileStepper';
import Box              from '@mui/material/Box'
import Button           from '@mui/material/Button';
import Divider          from '@mui/material/Divider';
import Backdrop         from '@mui/material/Backdrop';
import CircularProgress from "@mui/material/CircularProgress";
import { Typography }    from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

import KeyboardArrowLeft  from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

import _cloneDeep         from "lodash/cloneDeep"; // deep clones an array so that the copy is not just shallow
import { types } from '../../../Reducers/actionTypes';

const useStyles = makeStyles(theme => ({
  backdrop: {
    //zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: "#FBE4FF !important"
  },
}));

const DiscussionSubPart = (props) => {

  const classes = useStyles();
  const { state, actions } = useStateValue();
  
  const RowItems     = state.leops.Table2.RowItems;

  //const { history, location } = props;
  const MaxSlideCount = 8;
  let slides = [];
  var Titles = [Languages.ThingsAriseFromPreviousECEC,
                Languages.ObjectivesForPedagogicalActivitiesAndMeasuresToAchieve,
                Languages.OtherPartnersAndDocuments,
                Languages.StudentCareMeasures,
                Languages.AgreedCooperationWithGuardians];
  const { SelectedSlideIndex } = props;

  const [SlideIndex,   setSlideIndex] = useState(SelectedSlideIndex);
  const [BGColor,      setBGColor]    = useState(/*SelectedSlideIndex === 0 ? */"#AFDFDB"/* : '#FBE4FF'*/);
  const [showSnackbar, setshowSnackbar] = useState(false);
  const [NoteText,     setNoteText]     = useState(null);
  const [SavingData,   setSavingData]   = useState(false);
  
  useEffect( () => {
    switch(state.leops.state)
    {
      case "INITIAL_STATE":
        console.log("DiscussionSubPart INITIAL_STATE" );
        break;
      default:
        console.log("DiscussionSubPart ",state.leops.state );
        break;
    }
  }, [state.leops]);

  const handleSnackbarCB = () => {
    //console.log("VasuPreparingSubPart. handleSnackbarCB.  Vasu.state: ", VasuData.state);
    actions.triggerVasuHandled();
    setshowSnackbar(false);
  };

  const handlePrev = () =>
  {
    var index = SlideIndex;
    if (SlideIndex > 0)
    {
      index--;
      setSlideIndex(index);
    }
  };

  const handleNext = (maxCount) =>
  {
    var index = SlideIndex;
    if (SlideIndex < (MaxSlideCount-1))
    {
      index++;
      setSlideIndex(index);
    }
  };
  
  const handleChangeIndex = (e, index) => {
    //console.log("DiscussionSubPart. Handle swipe. uusi slide: ",e," edellinen : ",index);
    setSlideIndex(e);
  };

  const SlideText = () => {
    var slides = [];
    slides = [{Text: ""}, 
              {Text: Languages.ObjectivesForPedagogicalActivitiesInfo1},
              {Text: Languages.ObjectivesForPedagogicalActivitiesInfo2},
              {Text: Languages.ObjectivesForPedagogicalActivitiesInfo3},
              {Text: Languages.ObjectivesForPedagogicalActivitiesInfo4},
              {Text: ""},
              {Text: ""},
              {Text: ""}];
    return slides;
  };
  
  if (state.leops.state === "INITIAL_STATE")
  {
    console.log("DiscussionSubPart. INITIAL_STATE RENDER EMPTY");

    return (<div className="DiscussionSubPart"></div>);
  }
  else 
  {
    if (slides.length === 0)
    {
      slides = SlideText();
    }
    return (
    <div className="DiscussionSubPart">
      <Box>
      
      {SavingData ?
        <div style={{opacity: "0.5"}}>
            <Backdrop open={true} className={classes.backdrop}>
              <CircularProgress />
            </Backdrop>
        </div>
      :
        <div>
       
        <SwipeableViews className     = {"col-12 textCenter slide pt-3 "} /*pl-2 pr-2*/
                        index         = {SlideIndex} 
                        key           = {SlideIndex}
                        onChangeIndex = {handleChangeIndex} >
          {slides.map((slide, index) => { 
            return ( 
              <div key={index}>
                <Box className="br-top col-12" sx={{ backgroundColor: BGColor}}>
                <Typography color="textSecondary" sx={{textAlign:"center", fontWeight: 'Bold', fontSize: 18, color: "#424242", pt:1.5}}>{Titles[SlideIndex === 0 ? 0 :
                                                                                                         SlideIndex === 5 ? 2 :
                                                                                                         SlideIndex === 6 ? 3 :
                                                                                                         SlideIndex === 7 ? 4 : 1]}</Typography>
                <MobileStepper style={{backgroundColor: BGColor, color: "black"}}
                               //variant="progress" <= viiva
                               variant="text"
                               steps={MaxSlideCount}
                               position="static"
                               //LinearProgressProps={{width: "100%"}}
                               activeStep={SlideIndex}
                               //className={classes.root}
                               nextButton={ <Button size="small" style={{color: "black", opacity: SlideIndex === (MaxSlideCount-1) ? "0.5" : "" }} onClick={handleNext} disabled={SlideIndex === (MaxSlideCount-1)}>{Languages.next} <KeyboardArrowRight /> </Button> }
                               backButton={ <Button size="small" style={{color: "black", opacity: SlideIndex === 0 ? "0.5" : ""}} onClick={handlePrev} disabled={SlideIndex === 0}> <KeyboardArrowLeft /> {Languages.prev}  </Button> }/>
              </Box>
              <Box className="br-btm col-12" sx={{boxShadow: 2, mb: 1, pb: 1, backgroundColor: "white"}}>
                    <Typography color="textSecondary" sx={{textAlign:"center", fontWeight: 'Medium', fontSize: 16, color: "#424242", pt:1}}>{slide.Text}</Typography>
                </Box>
              </div>
            );                  
          })}
        </SwipeableViews>

        {SlideIndex === 0 || SlideIndex === 5 || SlideIndex === 6 || SlideIndex === 7 ?
        <div>
          <Box  className="col-12">
            <CommentsReadOnly Title1  = {<Typography color="textSecondary" sx={{textAlign:"left", fontWeight: 'Bold', fontSize: 16, color: "#424242", pt:1, ml:1}}>{Languages.StaffNotes} </Typography>}// 0: henkilöstön kommentit Mahdollisesta aiemmasta vasusta nousevat asiat
                                                          // 5: Muut yhteistyötahot ja asiakirjat
                                                          // 6: Oppilashuollolliset toimenpiteet 
                                                          // 7: Sovittu yhteistyö huoltajien kanssa
                              Title2  = {null}
                              Title3  = {null}
                              BgColor = {BGColor} 
                              Data    = {RowItems[SlideIndex].RowText[0].Colum1Text === null ? {Text:"No documented ECEC personnel's observations"} : {Text: RowItems[SlideIndex].RowText[0].Colum1Text}}>
            </CommentsReadOnly>

          </Box>
        </div>
        : // SlideIndex < 5 ?
        <div>
          <Box  className="col-12" sx = {{mt: 2}} >
            <CommentsReadOnly Title1  = {<Typography color="textSecondary" sx={{textAlign:"left", fontWeight: 'Bold', fontSize: 16, color: "#424242", pt:1, ml:1}}>{Languages.IssuesRaisedAgreed} </Typography>} // Esille otetut / Sovitut asiat
                              Title2  = {null}
                              Title3  = {null}
                              BgColor = {BGColor} 
                              Data    = {RowItems[SlideIndex].RowText[0].Colum1Text === null ? {Text: "No documented thins discussed / agreed upon"} : {Text: RowItems[SlideIndex].RowText[0].Colum1Text}}>
            </CommentsReadOnly>
          </Box>
          <Box  className="col-12" sx = {{mt: 2}} >
            <CommentsReadOnly Title1  = {<Typography color="textSecondary" sx={{textAlign:"left", fontWeight: 'Bold', fontSize: 16, color: "#424242", pt:1, ml:1}}>{Languages.EvaluationPedagogicalActivities}  </Typography>}// Pedagogisen toiminnan arviointi
                              Title2  = {null}
                              Title3  = {null}
                              BgColor = {BGColor} 
                              Data    = {RowItems[SlideIndex].RowText[0].Colum2Text === null ? {Text: "No documented evaluations of pedagogial activities"} : {Text: RowItems[SlideIndex].RowText[0].Colum2Text}}>
            </CommentsReadOnly>

          </Box>
        </div>
        }

        <SnackbarComponent verticalPosition={'bottom'} show={showSnackbar} message ={NoteText} callback={() => handleSnackbarCB()} />

        </div>

      }
      </Box>
    </div>
  );
  }
}

export default DiscussionSubPart;
import React from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Languages from "../../../translations";
import FormGroup from "@mui/material/FormGroup";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  root: { 
    color: "black",
    background: "white",
  }
})

const ForgottenPasswordStartingView = ({setEmail, sendForgottenPasswordRequest, resetPageAndRedirect, email, emailWarning}) => {

  const classes = useStyles();

  return(

    <React.Fragment>
      <Typography> {Languages.changePasswordBy} </Typography>
      <br/>
      <Typography 
        color="secondary"
        component={'span'}
        variant={'body2'}>
          <FormGroup>
            <TextField
              InputProps={{className: classes.root, "data-testid": "fpsw-input-3948"}}
              id="test_forgotten_password_email"
              label={"Email"}
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormGroup>
      </Typography>            
      <Typography color="error">
        {emailWarning && <span>{Languages.incorrectEmailAddress} <br /> </span> }
      </Typography>
      <Button style={{marginTop: "20px"}}
        color="primary"
        id="test_forgotten_password_reset_password"
        variant="outlined"
        onClick={sendForgottenPasswordRequest}>{Languages.resetPassword}
      </Button>
      <br />
      <Button
          style={{marginTop: "20px", marginBottom: "20px"}}
          onClick={resetPageAndRedirect}
          color="primary"
          variant="outlined"
          id="test_toLoginPageButton"
      >{Languages.generalBack}</Button>
    </React.Fragment>

  )

}

export default ForgottenPasswordStartingView;

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import Languages from "../../../../translations";

const NewMessageStepper = ({activeStep}) => {
  return(

  <>
    <Stepper activeStep={activeStep} alternativeLabel={true} style={{backgroundColor:"#ffffff"}}>
      <Step style={{color:"#4377BA"}}>
        <StepLabel><Typography variant="body2" color={activeStep === 0 ? "secondary" : "textSecondary"}>{Languages.msgChooseChild}</Typography></StepLabel>
      </Step>
      <Step>
        <StepLabel><Typography variant="body2" color={activeStep === 1 ? "secondary" : "textSecondary"}>{Languages.msgChooseRecipients}</Typography></StepLabel>
      </Step>
      <Step>
        <StepLabel><Typography variant="body2" color={activeStep === 2 ? "secondary" : "textSecondary"}>{Languages.msgWriteAndSend}</Typography></StepLabel>
      </Step>
    </Stepper>

    <svg height="1" width="100%" style={{marginTop:"-10px", marginBottom:"10px"}}>
      <line style={{stroke:"cornflowerblue", strokeWidth:"1"}} x1="0" y1="0" x2="100%" y2="0" />
    </svg>
  </>
  )
}

export default NewMessageStepper;
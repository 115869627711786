import moment from "moment";
import { ServerApi } from "../internal";
import { SettingsApi } from "../internal";
import { 
  getMyUrl, 
  parseJwt, 
  processResponse,
  parseQueryString 
} from "../utils";
import sessionManager from "./SessionManager";

// query parameters from esuomi login redirect, use lowercase values only! because real values are always lowercased before check
export const ESUOMI_AUTH_URL_PARAMS = {
  authType: "authtype",
  username: "userid",
  status: "status",
  errorType: "errortype"
}

// values for authType query parameter
export const ESUOMI_AUTH_TYPE_VALUES = {
  eSuomiRegister: "esuomi_register",
  eSuomiLogin: "esuomi_normal",
  eSuomiLogout: "esuomi_logout"
}

// values for errorType query parameter
export const ESUOMI_AUTH_ERROR_TYPES = {
  noActiveChildFound: "noactivechildfound",
  noActiveChildPlacements: "noactivechildplacements",
  userNotFound: "usernotfound",
  authFailed: "esuomiauthfail",
  errorParsingResponse: "invalidSaml",
  // User canceled authentication
  clientCanceled: "authreqfail",
  // Tunnistusväline hylkäsi
  idpDenied: "authdenied",
  serverError: "InternalServerError",
  unknownError: "unknown"
}

export default class AuthClient {
  constructor(dispatch) {
    this.dispatch = dispatch;
    this.serverApi = new ServerApi();
  }

  createError(error, desc) {
    return {
      error: error,
      errorDescription: desc
    }
  }

  createAuthErrorResult(error) {
    return {
      error: error,
      httpCode: error.response ? error.response.status : undefined,
      errorCode: error.response ? error.response.data.ErrorCode : undefined,
      authenticated: false
    };
  }

  isAuthenticated() {
    return sessionManager.loggedInStatus()
  }

  getUser() {
    return sessionManager.getUser();
  }

  getUserPersonId() {
    return sessionManager.getPersonId();
  }

  async tokenAuthenticate(token) {
    if (token) {
      try {
        const response = await this.serverApi.tokenLogin(token);

        processResponse(response, [200]);

        const responseData = response.data;

        sessionManager.setSession(responseData);
        // TODO: save accesstoken

        //this.sessionManager.setTokenCookie(responseData.Token);

        return true;
      } catch (error) {
        console.log("tokenAuthenticate error", error);
      }
    }
    return false;
  }
  
  // payload ErrorCode field values:
  // 1 = Invalid user/pass
  // 2 = User rights ended
  async authenticate({ username, password }) {
    try {
      const response = await this.serverApi.login(username, password, sessionManager.getFirebaseInfo());

      processResponse(response, [200]);

      const responseData = response.data;

      sessionManager.setSession(responseData);
      sessionManager.setAccessToken(responseData.Token);

      const jwt = parseJwt(sessionManager.getAccessToken());
      console.log("access token will expire: " + moment.unix(jwt.payload.exp).format());
      //this.sessionManager.setTokenCookie(responseData.Token);
      return {
        httpCode: response.status,
        errorCode: responseData.ErrorCode,
        authenticated: true,
        serverResponse: responseData
      };

    } catch (error) {
      sessionManager.resetSession();
      console.log("Error in login", error);
      return this.createAuthErrorResult(error);
    }
  }

  async logout(reason, dispatch) {
    try {
      // Should this ever fail?
      const response = await this.serverApi.logout(reason);
      sessionManager.resetSession();

      if (response.data.ssoLogout === true) {
        return {
          ssoLogoutRequired: true,
          ssoSettings: {
            ...response.data.logoutDetails
          }
        }

      }

      return {
        ssoLogoutRequired: false,
        ssoSettings: null
      }
      // Send request to reset App state to initial
      // dispatch({
      //   type: types.APP_RESET
      // });

    } catch (error) {
      sessionManager.resetSession();
      return {
        ssoLogoutRequired: false,
        ssoSettings: null
      }
      // // Send request to reset App state to initial
      // this.dispatch({
      //   type: types.APP_RESET
      // });
    }

  }

  async refreshSession() {
    try {
      //const currentToken = sessionManager.getAccessToken();
      const response = await this.serverApi.refreshToken(sessionManager.getFirebaseInfo());
      processResponse(response, [200], "Error getting refrehing session");

      sessionManager.setSession(response.data);

      return {
        httpCode: response.status,
        errorCode: response.data.ErrorCode,
        user: sessionManager.getUser(),
        authenticated: true,
        serverResponse: response.data
      };

    } catch (error) {

      return this.createAuthErrorResult(error);
    }
  }

  async loadEsuomiSettings() {
    try {
      const redirectUrl = getMyUrl() + "/login";
      const response = await this.serverApi.loadEsuomiRedirectSettings(redirectUrl);
      processResponse(response, [200], "Error loading eSuomi settings");
      return response.data;
    } catch (error) {
      return this.createError(error, "Error loading esuomi settings");
    }
  }

  async loadEsuomiRegisterSettings() {
    try {
      const currentUrl = getMyUrl();
      const response = await this.serverApi.loadEsuomiRegisterSettings(`${currentUrl}/register`);
      processResponse(response, [200], "Error loading eSuomi register settings");
      return response.data;
    } catch (error) {
      return this.createError(error, "Error loading esuomi register settings");
    }
  }

  async loadEsuomiLogoutParameters(relayState) {
    try {
      const response = await this.serverApi.loadEsuomiLogoutSettings(relayState);
      processResponse(response, [200], "");
      return response.data;
    } catch (error) {
      console.error(error);
      return this.createError(error, "Error loading esuomi logout parameters");
    }
  }

  async loadLoginSettings() {
    try {
      const response = await this.serverApi.loadLoginOptions();
      processResponse(response, [200], "Error loading login options");
      return response.data;
    } catch (error) {
      console.error(error);
      return this.createError(error, "Error loading login settings");
    }
  }

  createToAuthStateFromQueryParams = (params, authState) => {
    const getParamName = (name) => {
      const paramNames = {
        [ESUOMI_AUTH_URL_PARAMS.authType]: "authType",
        [ESUOMI_AUTH_URL_PARAMS.errorType]: "errorType",
        [ESUOMI_AUTH_URL_PARAMS.username]: "userId",
        [ESUOMI_AUTH_URL_PARAMS.status]: "status",
        'default': undefined
      }

      if (paramNames[name]) {
        return paramNames[name];
      } else {
        return paramNames['default'];
      }
    }

    const newState = { ...authState }
    
    Object.keys(params).forEach(key => {
      const keyname = key.toLowerCase();
      
      const paramName = getParamName(keyname);
      
      
      if (paramName) {
        newState[paramName] = params[key];
      } else {
        console.log("Unknown param name", keyname);
      }
    });

    // Add url missing errorType parameter add it with 'unknown' value
    if (newState.status === "error" && !newState.errorType) {
      newState['errorType'] = "unknown";
    }

    return newState;
  }

  mapErrorType = (name) => {
    const errorTypes = {
      [ESUOMI_AUTH_ERROR_TYPES.noActiveChildPlacements]: ESUOMI_AUTH_ERROR_TYPES.noActiveChildPlacements,
      [ESUOMI_AUTH_ERROR_TYPES.noActiveChildFound]: ESUOMI_AUTH_ERROR_TYPES.noActiveChildFound,
      [ESUOMI_AUTH_ERROR_TYPES.userNotFound]: ESUOMI_AUTH_ERROR_TYPES.userNotFound,
      [ESUOMI_AUTH_ERROR_TYPES.clientCanceled]: ESUOMI_AUTH_ERROR_TYPES.clientCanceled,
      [ESUOMI_AUTH_ERROR_TYPES.idpDenied]: ESUOMI_AUTH_ERROR_TYPES.idpDenied,
      [ESUOMI_AUTH_ERROR_TYPES.serverError]: ESUOMI_AUTH_ERROR_TYPES.serverError,
      [ESUOMI_AUTH_ERROR_TYPES.unknownError]: ESUOMI_AUTH_ERROR_TYPES.unknownError,
      'default': ESUOMI_AUTH_ERROR_TYPES.unknownError
    }

    if (errorTypes[name]) {
      return errorTypes[name];
    } else {
      return errorTypes['default'];
    }
  }


  async handleRedirectCallback(locationSearch, setLoading) {
    //const queryStringFragments = url.split('?').slice(1);

    if (locationSearch.length === 0) {
      throw new Error('There are no query params available for parsing.');
    }

    const params = parseQueryString(locationSearch);
    const token = parseCookieValue("token");

    const authState = this.createToAuthStateFromQueryParams(params, { authenticated: false });
    console.log("authState", authState);
    
    if (authState.status !== "error") {
      if (authState.authType === ESUOMI_AUTH_TYPE_VALUES.eSuomiLogin) {
        setLoading(true);
        authState.authenticated = await this.tokenAuthenticate(token);
        setLoading(false);
      }

      if (authState.authType === ESUOMI_AUTH_TYPE_VALUES.eSuomiRegister) {
        setLoading(true);
        authState.authenticated = await this.tokenAuthenticate(token);
        setLoading(false);
      }
    } else {
      authState.errorType = this.mapErrorType(authState.errorType);
    }

    return authState;
  }

  async setPassword(newPassword) {
    try {
      const response = await new SettingsApi().savePassword(newPassword);
      processResponse(response, [200], "Error saving password");
      return response.data;
    } catch (error) {
      return this.createError(error, error.message);
    }
    return false;
  }
}

// Parses specified cookie value
const parseCookieValue = (keyName) => {
  // If running inside node (jest) we don't have document
  if (!document) return;

  let cookie = document.cookie;

  const cookieFragments = cookie.split(';');

  let result;

  cookieFragments.forEach((frag) => {
    const [key, value] = frag.split("=");

    if (key === keyName) {
      result = value.trim();
    }
  })
  return result;
}
import React, {useState} from "react";
import GuideAccordion from "../GuideTools/GuideAccordion";
import KeywordButton from "../GuideTools/KeywordButton";
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import Divider from '@mui/material/Divider'
import Languages from "../../../translations";


const PermissionGuideContent = () => {
  const [expanded, setExpanded] = useState('')
  
  
  const handlePanelExpand = (panelId) => (event, expanded) => {
    setExpanded(expanded ? panelId : false)
    //window.scrollTo({top: 100, behavior: 'smooth'});
     
    //isExpanded.scrollIntoView()
    setTimeout(window.scrollTo(0, panelId.offsetTop-100), 600) //moves the newly opened panel up offeset by 
 
  };

  const expandAcco = (panelId) => () =>{
      setExpanded(panelId)
  }



    return(
      <>
        <div className="guide-modal-keywords-container">
          <h6 className="guide-modal-keyword-header"><VpnKeyIcon color="primary"/>  {Languages.keywords}: </h6>
          <KeywordButton handleClick={expandAcco("id-guide-permission-request")} buttonText={Languages.permissionRequestKeywordButton} panelId="id-guide-permission-request" buttonVariant="outlined"/>
          <KeywordButton handleClick={expandAcco("id-guide-parental-consensts")} buttonText={Languages.parentalConsentsKeywordButton} panelId="id-guide-parental-consensts" buttonVariant="outlined" />
          <KeywordButton handleClick={expandAcco("id-guide-child-permissions")} buttonText={Languages.childPermissionsKeywordButton} panelId="id-guide-child-permissions" buttonVariant="outlined" />      
          <Divider variant="middle"/>
          <br />
        </div>
        <div className="booking-guide-content-conteiner">
          <GuideAccordion accordionTitle={Languages.permissionRequestAccordionTitle} accordionTitleDescription={Languages.permissionRequestAccordionDesc}  panelId="id-guide-permission-request" handlePanelExpand={handlePanelExpand} isExpanded={expanded} accordionDetails="PERMISSION_REQUEST_ACCORDION_TEXT"/>
          <GuideAccordion accordionTitle={Languages.parentalConsentsAccordionTitle} accordionTitleDescription={Languages.parentalConsentsAccordionDesc} panelId="id-guide-parental-consensts" handlePanelExpand={handlePanelExpand} isExpanded={expanded} accordionDetails="PARENTAL_CONSENTS_ACCORDION_TEXT"/>      
          <GuideAccordion accordionTitle={Languages.childPermissionsAccordionTitle} accordionTitleDescription={Languages.childPermissionsAccordionDesc} panelId="id-guide-child-permissions" handlePanelExpand={handlePanelExpand} isExpanded={expanded} accordionDetails="CHILD_PERMISSIONS_ACCORDION_TEXT"/>          
        </div>
      </>
    )
}

export default PermissionGuideContent
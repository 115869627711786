import React, {useState, useEffect,useRef} from 'react';
import {useStateValue} from "../../State/";
import ContentBoxMUI from '../../Components/ContentBoxMUI';
import { history } from "../../Router";
import {useLocation} from "react-router-dom";
import {types} from "../../Reducers/actionTypes";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import BlueDivider from '../../Components/BlueDivider';
import Grid from "@mui/material/Grid";
import DiscussionCard from '../../Components/Discussion/DiscussionCard';
import DiscussionSpeedDial from '../../Components/Discussion/DiscussionSpeedDial';
import DiscussionCardPagination from '../../Components/Discussion/DiscussionCardPagination';
import BookableButtonItem from '../../Components/Discussion/BookableButtonItem';
import Box from '@mui/material/Box';
import ChildProfilePicture from '../../Components/ChildProfilePicture';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import CardMedia from '@mui/material/CardMedia';
import {  CardActionArea, CardActions } from '@mui/material';
import DaisyLogo from '../../images/Family-21.png';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import EventNoteIcon from '@mui/icons-material/EventNote';
import CircularProgress from '@mui/material/CircularProgress';
import Languages from "../../translations";

const ChildsDiscussions = () => {
  const location = useLocation();

  
  const {state, actions} = useStateValue();
  const {children, discussion} = state;

  const [childId, setChildId] = useState(null);
  const [selectedSection, setSelectedSection ] = React.useState("")


  const handleNewBookingClick = () => {
    setSelectedSection("bookNew")
    setTimeout(scroll, 600)
  }

  const handleShowBookingsClick = () => {
    setSelectedSection("showBookings")
    setTimeout(scroll, 600)
  }

  const handlePastBookingsClick = () => {
    setSelectedSection("showHistory")
    setTimeout(scroll, 600)
  }

  const scroll = () => {
    const section = document.querySelector("#childsDiscussionScrollToSection");
    if(section){
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    }
  };

  const status = state.discussion.oneChildDiscussions.status;
  
  const data = state.discussion.oneChildDiscussions.data;

  useEffect(() => {
    if (!location?.state?.childId) {
      history.push("/discussionBooking");
    }
    else {
      setChildId(location.state.childId);
    }
  }, [location]);
  

  useEffect(() => {
    if (!location?.state?.childId) {
      history.push("/discussionBooking");
    }
    else {
      actions.triggerLoadChildDiscussions(location.state.childId)
    }
  }, []);

  useEffect(()=>{
    if(discussion.editDiscussionBooking.status == types.EDIT_DISCUSSION_BOOKING_SUCCESS){
      actions.triggerLoadChildDiscussions(location.state.childId)
    }
  },[discussion.editDiscussionBooking.status])

  useEffect(()=>{
    if(discussion.deleteDiscussionBooking.status == types.DELETE_DISCUSSION_BOOKING_SUCCESS){
      actions.triggerLoadChildDiscussions(location.state.childId)
    }
  },[discussion.deleteDiscussionBooking.status])

  const bookDiscussion = (type) => {
    history.push({pathname:"/bookDiscussion", 
    state:
     {
      child: data.ChildId,
      type: type.toString(),
      from: "ChildsDiscussions"
    }});
  }

  const getChildName = (childId) => {
    const child = children.ChildList.find(x => x.Id == childId);
    if (typeof child === "undefined") {
      return("");
    }
    return child.FirstName;
  }
  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };



  if (status === types.LOADING_ONE_CHILD_DISCUSSIONS) {
    return(
      <ContentBoxMUI header={"Ajat"}>
        <div style={{textAlign: "center", justifyContent: "center", width: "100%", padding: "100px"}}>
        <CircularProgress />
        <Typography>{Languages.generalLoadingTitle}</Typography>  {/*Ladataan keskusteluaikoja*/}
        </div>
      </ContentBoxMUI>
    )  
  }

  if (status === types.LOAD_ONE_CHILD_DISCUSSIONS_SUCCESS) {
    return(
      <ContentBoxMUI header={"Ajat"}>
      {/*<DiscussionSpeedDial/>*/}
      <Box sx={{ display: "inline-flex", verticalAlign: "middle", padding: "5px" }}>
            <ChildProfilePicture childId={data.ChildId} />&nbsp;&nbsp;
            <Typography variant="h4" sx={{verticalAlign: "middle", color:"#002E6D"}}>{getChildName(data.ChildId)}</Typography>
            </Box>
            <BlueDivider/>
      <Box sx={{ flexGrow: 1, }}>
      <Grid container spacing={2} sx={{justifyContent: "center"}}>
      <Grid item xs={12} md={4}>
        <Card sx={{textAlign: "center"}}>
          <CardActionArea onClick={handleNewBookingClick}>
        <CardMedia
          sx={{backgroundColor: "#D7E3ED", padding: "50px"}}
          ><MoreTimeIcon fontSize="large" sx={{verticalAlign:"middle", color:"#002E6D"}}/></CardMedia>
          <CardContent sx={{backgroundColor: "#002E6D"}}>{Languages.discDiscussionBooking}</CardContent>
          </CardActionArea>
          </Card>        
      </Grid>
      <Grid item xs={12} md={4}>
        <Card sx={{textAlign: "center"}}>
        <CardActionArea onClick={handleShowBookingsClick}>
        <CardMedia
          sx={{backgroundColor: "#D7E3ED", padding: "50px"}}
          ><EventNoteIcon fontSize="large" sx={{verticalAlign:"middle", color:"#002E6D"}}/></CardMedia>
          <CardContent sx={{backgroundColor: "#002E6D"}}>{Languages.discUpcomingDiscussions} ({data.ComingDiscussions.length})</CardContent>
          </CardActionArea>
          </Card>        
      </Grid>
      <Grid item xs={12} md={4}>
        <Card sx={{textAlign: "center"}}>
        <CardActionArea onClick={handlePastBookingsClick}>
        <CardMedia
          sx={{backgroundColor: "#D7E3ED", padding: "50px"}}
          ><HistoryToggleOffIcon fontSize="large" sx={{verticalAlign:"middle", color:"#002E6D"}}/></CardMedia>
          <CardContent sx={{backgroundColor: "#002E6D"}}>{Languages.discPastDiscussions} ({data.PastDiscussions.length})</CardContent>
          </CardActionArea>
          </Card>        
      </Grid>
      </Grid>
      </Box>
      <BlueDivider/>
      <div id="childsDiscussionScrollToSection"></div>
      {selectedSection === "" &&
      <CardMedia 
          component="img"
          image={DaisyLogo}
          alt="DaisyFamily logo"
        />
      }
      {selectedSection === "bookNew" &&
      <Box sx={{ flexGrow: 1 }}>
        {data.BookableTypes.length > 0 ?
        <> 
        <Typography variant="h6" component="h2" sx={{padding: "5px", color:"#002E6D"}}><MoreTimeIcon fontSize="large" sx={{verticalAlign:"middle", color:"#002E6D"}}/>&nbsp;&nbsp;{Languages.discDiscussionBooking}</Typography><br />
        <Grid container spacing={2}>
          {data.BookableTypes.map((type) => {
            return(<Grid item xs={6} md={3} key={type}>
              <BookableButtonItem bookableType={type} bookDiscussion={()=> bookDiscussion(type)}/>
        </Grid>
        )
      })}
      </Grid>
      </>
      : <Typography variant="h6" component="h2">{Languages.discChildNoDiscussionsToBeBooked(getChildName(data.ChildId))}</Typography>

    }
      </Box>
      }
      {selectedSection === "showBookings" &&
      <>
      {data.ComingDiscussions.length > 0 ? 
        <><Typography variant="h6" component="h2" sx={{padding: "5px", color:"#002E6D"}}><EventNoteIcon fontSize="large" sx={{verticalAlign:"middle", color:"#002E6D"}}/>&nbsp;&nbsp;{Languages.discChildsUpcomingDiscussions}</Typography><br />
        </>
      : <>
        <br />
        <br />
        <br />
        <Typography align="center" variant="h6" component="h2">{Languages.discNoActiveBookings}</Typography>< br />
        <CardMedia 
            component="img"
            image={DaisyLogo}
            alt="DaisyFamily logo"
          />
        </>
      }
      {data.ComingDiscussions.length > 0 && data.ComingDiscussions.map((card, index) => {
        return(
            <div key={"futureDisc" + card.DiscussionStart + card.DiscussionEnd + index}>
            <DiscussionCard
              discussionId={card.DiscussionId}
              provideKey={card.DiscussionId}
              type={card.DiscussionType} 
              dateData={card}
              cancelDate={card.CancelableUntil}
              parents={card.Parents} 
              staff={card.Employees}
              remote={card.IsRemoteMeeting}
              address={card.DiscussionAddress}
              url={card.MeetingUrl}
              employeeInfoText={card.ExtraInfoShared}
              parentInfoText={card.ExtraInfoParent}
              data={card}
              />
            <br/>
            </div>
          )
      })}
      </>
      }
      {selectedSection === "showHistory" &&
      <>
      {data.PastDiscussions.length > 0 && <><Typography variant="h6" component="h2" sx={{padding: "5px", color:"#002E6D"}}><HistoryToggleOffIcon fontSize="large" sx={{verticalAlign:"middle", color:"#002E6D"}}/>&nbsp;&nbsp;{Languages.discChildsPastDiscussions}</Typography><br /></>}
      {data.PastDiscussions.length > 0 && <DiscussionCardPagination discussions={data.PastDiscussions}/>}
      </>
      }

      </ContentBoxMUI>
    )
  }

  return(
    <ContentBoxMUI header={"Ajat"}>
      <Typography>{Languages.discTimesCouldntBeLoaaded}</Typography>
    </ContentBoxMUI>
  )  

}

export default ChildsDiscussions;
import React from "react";
import Typography from "@mui/material/Typography";
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CircularProgress from '@mui/material/CircularProgress';

import Languages from "../../../../translations";
import { isPostalCode, isProperStreetAddressOrCity } from "./";

const ParentsInfoEditable = ({ 
  isEditingAddressAllowed,
  isEmailServiceEnabled,
  onShowAllowViewHelpModal,
  onShowEmailHelpModal,
  formFieldChange,
  parents,
  formStatus,
  initialParentDataStatus }) => {
  if (!parents) {
    return (
      <CircularProgress />
    );
  }

  return (
    <Typography component={'span'}>
      {parents.map((parent, parentIndex) => {
        const parentData = initialParentDataStatus.find(status => status.personId === parent.personId);
        //const emptynameFound = formStatus.find(status => status.field === "BUParentName" && status.personId === parent.personId && status.valid === false);
        const invalidEmailFound = formStatus.find(status => status.field === "ParentEmail" && status.personId === parent.personId && status.valid === false);
        const invalidPhoneNumberFound = formStatus.find(status => status.field === "ParentPhoneNumber" && status.personId === parent.personId && status.valid === false);
        const invalidWorkPhoneNumberFound = formStatus.find(status => status.field === "ParentWorkNumber" && status.personId === parent.personId && status.valid === false);

        return (
          <div key={parent.personId}>
            <Divider></Divider>
            <br></br>

            {(invalidPhoneNumberFound || invalidWorkPhoneNumberFound) || (!parentData.workNumberIsValid || !parentData.phoneNumberIsValid) && parentIndex === 0 ?
              <div>
                <TextField error={true}
                  variant="standard"
                  name={"ParentPhoneNumberErrorInfoText"}
                  key={"ParentPhoneNumberErrorInfoText"}
                  id="outlined-read-only-input"
                  InputProps={{ readOnly: true, disableunderline: "true", className: "marginTop"/*, attrid: pn.ID*/ }}
                  type="text"
                  helperText={Languages.ciInfo1} />
                <br></br><br></br>
              </div>
              : null
            }

            <div className="row">
              <div className="col-4"> <p>{Languages.ciName}</p> </div>
              <div className="col-8"> {parent.Name === null ? "" : <p>{parent.Name}</p>} </div>


              <div className="col-4">
                 <p>{Languages.ciShortPhoneNumber}</p>
              </div>

              <div className="col-8">
                <div key={"PHNRO0-home1"}>
                  <TextField error={invalidPhoneNumberFound || !parentData.phoneNumberIsValid ? true : false}
                    variant="standard"
                    name="ParentPhoneNumber"
                    id={"test_ParentHomeNo" + parentIndex}
                    type="text"
                    placeholder={Languages.ciShortPhoneNumber}
                    value={parent.ParentPhoneNumber.Number ? parent.ParentPhoneNumber.Number : ""}
                    onChange={(event) => 
                      formFieldChange(event, {personId: parent.personId, numberId: parent.ParentPhoneNumber.ID, numberType: parent.ParentPhoneNumber.Type})
                    }
                    helperText={invalidPhoneNumberFound ? Languages.error : ""}
                    autoFocus={invalidPhoneNumberFound ? true : false} />
                </div>
              </div>

              <div className="col-4">
              <p>{Languages.ciShortWorkNumber}</p>
              </div>

              <div className="col-8">
                    <div>
                      <TextField error={invalidWorkPhoneNumberFound || !parentData.workNumberIsValid ? true : false}
                        variant="standard"
                        name="ParentWorkNumber"
                        id={"test_ParentWorkNo" + parentIndex}
                        type="text"
                        placeholder={Languages.ciShortPhoneNumber}
                        value={parent.ParentWorkNumber.Number ? parent.ParentWorkNumber.Number : ""}
                        onChange={(event) => 
                          formFieldChange(event, { personId: parent.personId, numberId: parent.ParentWorkNumber.ID, numberType: parent.ParentWorkNumber.Type })
                        }
                        helperText={invalidWorkPhoneNumberFound ? Languages.error : ""}
                        autoFocus={invalidWorkPhoneNumberFound ? true : false} />
                    </div>
              </div>

              <div className="col-4">
                <p>{Languages.ciShortEmailAddress}</p>
              </div>

              <div className="col-8">
                <TextField error={invalidEmailFound || !parentData.emailIsValid ? true : false}
                  variant="standard"
                  key={"ParentEmail"}
                  name="ParentEmail"
                  id={"test_ParentEmail" + parentIndex} //"standard-basic"
                  type="text"
                  placeholder={Languages.ciShortEmailAddress}
                  value={parent.ParentEmail === null ? "" : parent.ParentEmail}
                  onChange={(event) => 
                    formFieldChange(event, { personId: parent.personId })
                  }
                  helperText={invalidEmailFound ? Languages.errorEmailWrongFormat : ""}
                />
              </div>

              <div className="col-4">
                <p>{Languages.ciAddressStreet}</p>
                <p>{Languages.ciAddressPostalcode}</p>
                <p>{Languages.ciAddressCity}</p>
              </div>

              <div className="col-8">
                {isEditingAddressAllowed &&
                  <>
                    <TextField
                      variant="standard"
                      key={"ParentStreetAddress"}
                      name={"ParentStreetAddress"}
                      type="text"
                      placeholder={""}
                      value={parent.ParentStreetAddress}
                      onChange={(event) => 
                        formFieldChange(event, { personId: parent.personId })
                      }
                      error={!isProperStreetAddressOrCity(parent.ParentStreetAddress) || !parentData.streetIsValid}
                      helperText={isProperStreetAddressOrCity(parent.ParentStreetAddress) ? '' : Languages.errorStreetAddress}
                    />
                    <br />
                    <TextField
                      variant="standard"
                      key={"ParentPostalCode"}
                      name={"ParentPostalCode"}
                      type="text"
                      placeholder={""}
                      value={parent.ParentPostalCode}
                      onChange={(event) => 
                        formFieldChange(event, { personId: parent.personId })
                      }
                      error={!isPostalCode(parent.ParentPostalCode) || !parentData.postalCodeIsValid}
                      helperText={isPostalCode(parent.ParentPostalCode) ? '' : Languages.errorPostalCode}
                    />
                    <br />
                    <TextField
                      variant="standard"
                      key={"ParentCity"}
                      name={"ParentCity"}
                      type="text"
                      placeholder={""}
                      value={parent.ParentCity}
                      onChange={(event) => 
                        formFieldChange(event, { personId: parent.personId })
                      }
                      error={!isProperStreetAddressOrCity(parent.ParentCity) || !parentData.cityIsValid}
                      helperText={isProperStreetAddressOrCity(parent.ParentCity) ? '' : Languages.errorCityName}
                    />

                  </>
                }
                {!isEditingAddressAllowed &&
                  <div>
                    <p>{parent.ParentStreetAddress !== null ? parent.ParentStreetAddress : ""}</p>
                    <p>{parent.ParentPostalCode !== null ? parent.ParentPostalCode : ""}</p>
                    <p>{parent.ParentCity !== null ? parent.ParentCity : ""}</p>
                  </div>
                }
              </div>

              <div className="col-4">
                <p>{Languages.allowViewLabel}</p>
              </div>

              <div className="col-8">
                <div>
                  <FormControlLabel
                    control={<Switch
                      key="ParentAllowView"
                      name="ParentAllowView"
                      style={parent.ParentAllowView === true ? { color: "#5EFF33" } : { color: "red" }}
                      checked={parent.ParentAllowView}
                      onChange={(event) => 
                        formFieldChange(event, { personId: parent.personId })
                      }
                    />}
                    label={parent.ParentAllowView ? Languages.allowed : Languages.notAllowed}
                    labelPlacement="end"
                  />
                  <IconButton color="secondary" onClick={onShowAllowViewHelpModal} size="large">
                    <HelpOutlineIcon />
                  </IconButton>
                </div>
              </div>
            </div>

          </div>
        );
      })}
    </Typography>
  );
}

export default ParentsInfoEditable;
import React, { useEffect, useState } from 'react';
import Snackbar/*, { SnackbarOrigin }*/ from "@mui/material/Snackbar";

const SnackbarComponent = ({ verticalPosition, show, okColor, message, callback }) => {
  
  const [open,       setOpenState] = useState(show);
  const [vertical,   setVerticalState] = useState(verticalPosition);
  const [horizontal, setHorizontalState] = useState('center');
  const [useOkColor, setOkColorState]  = useState(okColor);

  useEffect(() => {

    if (open !== show)
    {
      setOpenState(show);
      setOkColorState(show ? okColor : useOkColor);
    }
    if(open === false && show === false && useOkColor )
    {
      setTimeout(() => {
        setOkColorState(false);
      }, 2000);
    }
   }, [show, open, okColor, useOkColor]); 

   useEffect(() => {
    //console.log("Snackbar. TIMER useEffect. open: ", open)
    if (open === true) {
      //setOpenState(true);
      setTimeout(() => {
        callback();
        setOpenState(false);
      }, 6000);
    }
  }, [open, callback]);
  
  return (
    
      <Snackbar
        id="test_Snackbar"
        anchorOrigin={{ vertical, horizontal }}
        key={`${vertical},${horizontal}`}
        open={open}
        
        onClose={callback}
        message= {message}
        ContentProps={{
          style: { whiteSpace: "pre-line", textAlign:"center", color: 'white', backgroundColor: useOkColor ? "green" : '#d500f9' },
        }}
      />
    
  );
  }
export default SnackbarComponent;

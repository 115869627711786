import React   from "react";
import {  ListGroup,  } from "reactstrap";
import "../messaging.css";
import FixedBottomBar         from "../../../Components/FixedBottomBar";
import Skeleton               from '@mui/material/Skeleton';
import Card                   from "@mui/material/Card";
import CardContent            from "@mui/material/CardContent";
import MessagingBottomBar     from "./MessagingBottomBar";

const MessagingLoading = ({refresh, showNew}) => {

return (
  <React.Fragment>
    <ListGroup>

      <div>
          <Card style={{backgroundColor:"#4377BA"}}>             
            <CardContent>
              <div>  
                <div className="row no-gutters">
                  <div className="col-2 marginx">
                    <Skeleton variant="circular" width={40} height={40}/>
                  </div>
                  <div className="col-7">
                    <div><Skeleton width={100}/>
                      <Skeleton width={220}/>
                    </div>
                  </div>
                  <div className="col-3 skeletonCenter15">
                    <Skeleton width={100}/>
                  </div> 
                </div>
              </div>
            </CardContent>
          </Card>
          <div className="pad3"></div>
          <Card style={{backgroundColor:"#4377BA"}}>             
            <CardContent>
              <div>  
                <div className="row no-gutters">
                  <div className="col-2 marginx">
                    <Skeleton data-testid="MessagingLoading-Skeleton-keq9apef" variant="circular" width={40} height={40}/>
                  </div>
                  <div className="col-7">
                    <div><Skeleton width={100}/>
                      <Skeleton width={220}/>
                    </div>
                  </div>
                  <div className="col-3 skeletonCenter15">
                    <Skeleton width={100}/>
                  </div> 
                </div>
              </div>
            </CardContent>
          </Card>
          <div className="pad3"></div>
          <Card style={{backgroundColor:"#4377BA"}}>             
            <CardContent>
              <div>  
                <div className="row no-gutters">
                  <div className="col-2 marginx">
                    <Skeleton variant="circular" width={40} height={40}/>
                  </div>
                  <div className="col-7">
                    <div><Skeleton width={100}/>
                      <Skeleton width={220}/>
                    </div>
                  </div>
                  <div className="col-3 skeletonCenter15">
                    <div style={{display:"inline-block"}}><Skeleton width={55} /></div> <div style={{display:"inline-block"}}><Skeleton width={55} display="inline-block"/></div>
                  </div> 
                </div>
              </div>
            </CardContent>
          </Card>

      </div>

    </ListGroup>
      <MessagingBottomBar loading={true} refresh={() => refresh()} showNew={() => showNew()}/>
  </React.Fragment>
);
}

export default MessagingLoading;
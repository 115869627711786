import React                  from "react";
import "../invoiceBasis.css";
import Grid                   from "@mui/material/Grid";
import Button                 from "@mui/material/Button";
import Languages              from "../../../translations";


const InvoiceSummaryButtons = ({changeViewTo, activeButton}) => {

  if (typeof changeViewTo === "undefined" || changeViewTo === null ||
    typeof activeButton === "undefined" || activeButton === null
  ) {
    return(<div data-testid="invoiceSummaryButtonsError"></div>)
  }
  else {

    return(
      <div style={{maxWidth: "360px", margin: "auto"}}>
        <div style={{marginLeft: "10px"}}>
        <br />
        <Grid>
          <Grid container>
            <Grid item></Grid>
            <Grid item>
              <Button 
                variant="contained" 
                size={activeButton.bookings ? "large" : "small"} 
                style={{color: "#FFFFFF", backgroundColor: "#ffc000", marginRight: "5px", marginBottom: "5px"}} 
                onClick={changeViewTo.bookingsView}>
                  {Languages.uctBookings}
              </Button>
              <Button 
                variant="contained" 
                size={activeButton.toteuma ? "large" : "small"} 
                style={{color: "#FFFFFF", backgroundColor: "#6BAF6E", marginRight: "5px", marginBottom: "5px"}} 
                onClick={changeViewTo.toteumaView}>
                  {Languages.uctRealizedCareTimes}
              </Button>
              <Button 
                variant="contained" 
                size={activeButton.esiopetus ? "large" : "small"} 
                style={{color: "#FFFFFF", backgroundColor: "#FF4D88", marginRight: "5px", marginBottom: "5px"}} 
                onClick={changeViewTo.esiopetusView}>
                  {Languages.uctPreSchoolTeaching}
              </Button>
            </Grid>
            <Grid item></Grid>
          </Grid>
        </Grid>
        <br />
        </div>
      </div>
    )}

}

export default InvoiceSummaryButtons;
import React, { useState, useEffect } from "react";
import {useStateValue} from "../../State";
import "./vasu.css";

import Languages      from "../../translations";

import TextField      from '@mui/material/TextField';
import { Typography }    from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Stack              from '@mui/material/Stack';



const Approvals = (props) => {

  const { Approval, GeneralPart, ParentName, ParentId, DecisionId, Type } = props; 
  const { state, actions } = useStateValue();

  
  const [checked, setChecked] = useState(false);
  const [Comments,setComments] = useState(Approval === null ? "" : Approval.Info + "\n\n" + Approval.Saved);;

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };


  const ChangeNotes = (event) => {

    setComments(event.target.value);
  };

  const handleApprovalSave = () => {
    
    let ApprovalData = [];

    ApprovalData = {  
      PersonId:     ParentId, 
      Type:   Type,
      DocumentId:   DecisionId, 
      Info: Comments
      };
    

    console.log(ApprovalData);
    actions.triggerSaveApproval(ApprovalData);
  };

  useEffect( () => {
    
  },[]);


  
  const checkDisabled = () => {
    if(Approval !== null || ParentId !== GeneralPart.ParentId){
        return true;
      }
      else{
        return false;
      }
  };
  

  return (
    <div className="Approval">
        <Stack className="col-12" alignItems="center">
            <Typography color="textSecondary" sx={{textAlign:"left", fontWeight: 'medium', fontSize: 16, color: "#424242"}}>{ParentId === GeneralPart.Parent1Id ? Languages.guardian + " 1." : Languages.guardian + " 2."} </Typography>
            <Stack className='col-12' direction = "row" spacing ={2} alignItems="center">
                <Checkbox
                    defaultChecked={Approval === null ? false : true}
                    disabled = {checkDisabled()}
                    onChange={handleChange}
                    sx={{
                      color: "red",
                      '&.Mui-checked': {
                        color: "green",
                      },
                    }}                
                />
                <Typography color="textSecondary" sx={{textAlign:"left", fontWeight: 'medium', fontSize: 16, color: "#424242"}}>{ParentName}</Typography>
            </Stack>
            
            <Stack className="col-12" spacing={2}>
                <TextField
                name={"Notes"}
                id={"Notes"}
                sx = {{borderRadius: 2, mt: 1, "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "black",
                },}}
                inputProps={{maxLength: 500, style: { color: 'black',  maxWidth: "100%"}}}
                InputLabelProps={{shrink: false}}
                label={null}
                multiline
                rows = "3"
                type="text"
                value={Comments}
                disabled={!checked}
                onChange={ChangeNotes}
                
                />
                {Approval === null && checked ? 
                
                <Button  disabled = {!checked} onClick={() => {handleApprovalSave();}} variant="contained">Tallenna</Button>
                : <></>}
                
            </Stack>
            
        </Stack>
      
    </div>
  );
}

export default Approvals;

import Languages from '../../../../translations';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import InfoModal from '../../../../Components/Modals/InfoModal';

const ContentRootDiv = styled('div')(() => ({
  textAlign: 'center'
}))

const HelpTitle = styled('h4')(() => ({
  whiteSpace: 'pre-line'
}))

export const EmailNotificationsHelpModal = ({ open, onClose }) => {
  if (open) {
    return (
      <InfoModal
        modalOpen={open}
        allowClose={true}
        onClose={onClose}>
        <ContentRootDiv>
          <HelpTitle>{Languages.emailNotifications}</HelpTitle>
          <Divider />
          <Typography align="left" variant="h6">{Languages.allowed}:</Typography>
          <Typography align="left">{Languages.allowEmailNotificationsExplanation}</Typography>
          <hr />
          <Typography align="left" variant="h6">{Languages.notAllowed}:</Typography>
          <Typography align="left">{Languages.notAllowEmailNotificationsExplanation}</Typography>
        </ContentRootDiv>
      </InfoModal>
    )
  }
  else {
    return null;
  }
}

export const PushNotificationsHelpModal = ({ open, onClose }) => {
  if (open) {
    return (
      <InfoModal
        modalOpen={open}
        allowClose={true}
        onClose={onClose}>
        <ContentRootDiv>
          <HelpTitle>{Languages.pushNotifications}</HelpTitle>
          <Divider />
          <Typography align="left" variant="h6">{Languages.allowed}:</Typography>
          <Typography align="left">{Languages.allowPushNotificationsExplanation}</Typography>
          <hr />
          <Typography align="left" variant="h6">{Languages.notAllowed}:</Typography>
          <Typography align="left">{Languages.notAllowPushNotificationsExplanation}</Typography>
        </ContentRootDiv>
      </InfoModal>
    )
  }
  else {
    return null;
  }
}

import React, { useState } from "react";
import TextField from '@mui/material/TextField';
import Languages from "../../../../translations";
import Grid from '@mui/material/Grid';
import { convertNullToString } from "../../../../utils";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import IconButton from "@mui/material/IconButton";
import InfoModal from "../../../../Components/Modals/InfoModal";
import Button from "@mui/material/Button";

const BackupParentInfoEditField = ({ parent, BUparentIndex, formStatus, formFieldChange, deleteBackupParent, deleteIsLoading }) => {
  const invalidFirstNameFound = formStatus.find(status => status.field === "FirstName" && status.personId === parent.personId && status.valid === false);
  const invalidLastNameFound = formStatus.find(status => status.field === "LastName" && status.personId === parent.personId && status.valid === false);
  const invalidPhoneNumberFound = formStatus.find(status => status.field === "BUParentPhoneNumber" && status.personId === parent.personId && status.valid === false);
  const invalidWorkPhoneNumberFound = formStatus.find(status => status.field === "BUParentWorkNumber" && status.personId === parent.personId && status.valid === false);

  const [showConfirmation, setShowConfirmation] = useState(false);


  const showConfirmationPopup = () => {
    setShowConfirmation(true);
  }

  const confirmationYes = () => {
    setShowConfirmation(false);
    deleteBackupParent(parent.personId);
  }

  const confirmationNo = () => {
    setShowConfirmation(false);
  }

  return (
  <>
    <InfoModal 
      modalOpen={showConfirmation}
      onClose={() => setShowConfirmation(false)}
      headerText={Languages.ciBackupPersonRemoveConfirmation}
      allowClose={true}
    >
      <Button variant="contained" color="secondary" onClick={confirmationYes}>{Languages.ciDeleteYes}</Button>
      <Button style={{marginLeft: "10px"}} variant="outlined" color="secondary" onClick={confirmationNo}>{Languages.ciDeleteNo}</Button>  
    </InfoModal>  
    

    <Grid container>
      <Grid item xs={4}> <p>{Languages.ciFirstName}</p></Grid> 
      <Grid item xs={6}>
        <TextField error={invalidFirstNameFound ? true : false}
          variant="standard"
          name="BUParentFirstName"
          id={"test_BUParentFirstName" + BUparentIndex} //"standard-basic"
          type="text"
          placeholder={Languages.ciFirstName}
          value={convertNullToString(parent.FirstName)}
          onChange={(event) =>
            formFieldChange(event, { personId: parent.personId, entityId: parent.entityId })
          }
          helperText={invalidFirstNameFound ? Languages.errorNameCantBeEmpty : ""}
        />
      </Grid>
      <Grid item xs={2}>
        {deleteIsLoading ? null : 
        <IconButton
              data-testid={"deleteBackupPerson" + parent.FirstName + parent.LastName}
              color="secondary" 
              /*style={{backgroundColor: "#0095d8"}}*/
              size="small"
              aria-label={Languages.ciDeleteBackupParent + " " + parent.FirstName + " " + parent.LastName} 
              component="span"
              onClick={showConfirmationPopup}
              disabled={!deleteIsLoading ? false : true}
              >
          <DeleteRoundedIcon/></IconButton>}
      </Grid>
      
      <Grid item xs={4}><p>{Languages.ciLastName}</p></Grid>
      <Grid item xs={6}>
        <TextField 
          variant="standard"
          error={invalidLastNameFound ? true : false}
          name="BUParentLastName"
          id={"test_BUParentLastName" + BUparentIndex} //"standard-basic"
          type="text"
          placeholder={Languages.ciLastName}
          value={convertNullToString(parent.LastName)}
          onChange={(event) =>
            formFieldChange(event, { personId: parent.personId, entityId: parent.entityId })
          }
          helperText={invalidLastNameFound ? Languages.errorNameCantBeEmpty : ""}
        />
      </Grid>
      <Grid item xs={2}></Grid>

      <Grid item xs={4}>
        <p>{Languages.ciShortPhoneNumber}</p>
      </Grid>
      <Grid item xs={6}>
        <div>
          <TextField 
            variant="standard"
            error={invalidPhoneNumberFound ? true : false}
            name="BUParentPhoneNumber"
            id={"test_BUParentHomeNo" + BUparentIndex + "_0"}
            type="text"
            placeholder={Languages.ciShortPhoneNumber}
            value={convertNullToString(parent.BUParentPhoneNumber?.Number)}
            onChange={(event) =>
              formFieldChange(event, { personId: parent.personId, numberId: parent.BUParentPhoneNumber?.ID, entityId: parent.entityId, type: 0 })
            }
            helperText={invalidPhoneNumberFound ? Languages.error : ""}
            />
        </div>
      </Grid>
      <Grid item xs={2}></Grid>

      <Grid item xs={4}>
        <p>{Languages.ciShortWorkNumber}</p>
      </Grid>

      <Grid item xs={6}>
        <TextField 
          variant="standard"
          error={invalidWorkPhoneNumberFound ? true : false}
          name="BUParentWorkNumber"
          id={"test_BUParentWorkNo" + BUparentIndex + "_0"}
          type="text"
          placeholder={Languages.ciShortPhoneNumber}
          value={convertNullToString(parent.BUParentWorkNumber?.Number)}
          onChange={(event) =>
            formFieldChange(event, { personId: parent.personId, numberId: parent.BUParentWorkNumber?.ID, entityId: parent.entityId, type: 1 })
          }
          helperText={invalidWorkPhoneNumberFound ? Languages.error : ""}
          />
      </Grid>
      <Grid item xs={2}></Grid>
    </Grid>
  </>
  );
}

export default BackupParentInfoEditField;
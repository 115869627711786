import React, { useEffect/*, useState*/ } from 'react';
import makeStyles from '@mui/styles/makeStyles';
//import { useStateValue } from '../../../../State';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

//import Languages          from "../../../../translations";

const DenseTable = ({Title, Data}) => {

  //const { state, actions } = useStateValue();
  //const { ChildList } = state.children;
  //const { WeekList }  = state.nursingtimes.List.WeekData;

  /*console.log("DenseTable. Mount. Title: " + Title+
              "\n Data: ", Data);*/
  
  useEffect(() => {
    //console.log("useEffect. ListOfCurrentReservationsView: ");
   }); 

  const useStyles = makeStyles({
    table: {
      minWidth: 100,
      backgroundColor: 'white'
    },
  });

  const classes = useStyles();
  return (
    <div className="textCenter" id={"test_DenseTable"}>
      
      <h5 id={"test_DenseTable_Title"} style={{color: "black", fontWeight:"bold", textAlign: "centre", whiteSpace:"pre-line"}}>{Title}</h5>

      <TableContainer component={Paper} >

        <Table className={classes.table} size="small" aria-label="a dense table">

        {Data.map((row,index) => (
          <React.Fragment key={index}>
          {row.Date === null ? null : 
          <TableHead>
            <TableRow key={index}>
              <TableCell /*className="col-4"*/ component="th" scope="row" style={{color: "black"}}>
                <div id={"test_DenseTable_RowDate"} style={{fontWeight:"bold"}}>{row.Date}</div>
                <div id={"test_DenseTable_RowTitle"}>{row.Title === null ? "" : " " +row.Title}</div>
              </TableCell>
              
              {row.Items[0].Colum2 === null ? null :
              <TableCell component="th" scope="row"></TableCell>
              }
              {row.Items[0].Colum3 === null ? null :
              <TableCell component="th" scope="row"></TableCell>
              }
            </TableRow>
          </TableHead>
          }

          <TableBody >
            {row.Items.map((item,itemIndex) => (
              <TableRow key={item.PersonID !== undefined ? item.PersonID+"_"+itemIndex : itemIndex}>
              
                {item.PlacementColor === undefined || item.PlacementIcon === undefined ? null :
                <TableCell id={"test_DenseTable_Colum0"} align="left" style={{color: "black", width: "1%"}}>
                  <div style={{color: "transparent", textShadow:"0 0 0 "+item.PlacementColor , fontWeight:"bold"}}>{String.fromCharCode(item.PlacementIcon)}</div>
                  </TableCell>
                }

                <TableCell id={"test_DenseTable_Colum1"} align="left" style={{color: "black"}}>{item.Colum1}</TableCell>
                
                {item.Colum2 === null ? null :
                <TableCell id={"test_DenseTable_Colum2"} align="left" style={{color: "black"}}>{item.Colum2}</TableCell>
                }

                {item.Colum3 === null ? null :
                <TableCell id={"test_DenseTable_Colum3"} align="left" style={{color: "black"}}>{item.Colum3}</TableCell>
                }
              </TableRow>
            ))}
          </TableBody>
          </React.Fragment>
          ))}
        </Table>
      </TableContainer>
  </div>
);
}

export default DenseTable;


import React from "react";
import AttachmentIcon from "@mui/icons-material/Attachment";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArticleIcon from '@mui/icons-material/Article';
import ImageIcon from '@mui/icons-material/Image';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';



const AttachmentIconManager = ({ mimeType }) => {


    function returnIcon(mimeType) {

      const returnIconOptions = {
      
      "application/pdf": function() {
        return(<PictureAsPdfIcon data-testid="attachment-icon" />);
      },

      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": function() {
        return(<ArticleIcon data-testid="attachment-icon" />);
      },

      "image/jpeg": function() {
        return(<ImageIcon /*attachmentData={attachmentData} showFullImage={showFullImage}*/ data-testid="attachment-icon" />);
      },

      "image/png": function () {
        return(<ImageIcon /*attachmentData={attachmentData} showFullImage={showFullImage}*/ data-testid="attachment-icon" />)
      },

      "default": function() {
        return(<ReportGmailerrorredIcon data-testid="attachment-icon" />);
    },
  };
    return (returnIconOptions[mimeType] || returnIconOptions["default"])();

  }

  return(
    <>
      {returnIcon(mimeType)}
    </>
  );
}

export default AttachmentIconManager
import { types } from './actionTypes';

const ProfilePictureReducer= (state, action) => {
  const payload = action.data
  
  /*console.log('PROFILEPICTURE REDUCER.'+
              "\n action: ", action,
              "\n action.type: ", action.type);*/

  switch (action.type) {
    case types.LOADING_PROFILEPICTURES:
      return {
        ...state,
        state: types.LOADING_PROFILEPICTURES
      }
    case types.LOAD_SUCCESS_PROFILEPICTURES:
      //console.log('PROFILEPICTURE REDUCER. PICTURE LOAD OK payload: ', payload);
      return {
        ...state,
        Code: 200,
        state: types.LOAD_SUCCESS_PROFILEPICTURES,
        profilePicturesList: payload
      }
    case types.LOAD_FAILED_PROFILEPICTURES:
      //console.log('PROFILEPICTURE  REDUCER. PICTURE LOAD FAILED payload: ', payload);
      return {
        ...state,
        Code: payload.Code,
        state: types.LOAD_FAILED_PROFILEPICTURES
      }

      default: return state;
  }
}

export default ProfilePictureReducer;
import React, { useState, useEffect } from "react";
//import {useStateValue} from "../../State";
import PermissionQueries from "./PermissionQueries"; 
import ContentBoxMUI     from "../../Components/ContentBoxMUI";


const PermissionQueryLayout = () => {

    return (
      <React.Fragment>
        
        <ContentBoxMUI topPositionFix="58px">
      
          <div style={{marginTop: "10px"}}></div>
          <PermissionQueries/>
        
        </ContentBoxMUI>
        
      </React.Fragment>
    );
  
  }
  
  export default PermissionQueryLayout;
import React, { Component } from "react";
import "./contentbox.css";
import Closeicon from "../../images/closeicon.svg";
//import Arrowleft from "../../images/arrowleft.svg";
//import Arrowright from "../../images/arrowright.svg";

//react-linkify
// import { Linkify } from 'react-linkify';
//var Linkify = require('react-linkify');

class ContentBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    //console.log("ContentBox/componentDidMount. this.props.headerClick: ", this.props.headerClick);
    //console.log("ContentBox. Mount. window.pageYOffset: ", window.pageYOffset);

    //console.log("showArrowBack, showCloseicon", this.props.showArrowBack, this.props.showCloseicon, this.props);
    /*var shoArrowBack = this.props.showArrowBack;
    var shoCloseicon = this.props.shoCloseicon;
    var funct = this.props.setfunction;

    document.addEventListener('keyup', function (event) {
      var key = event.key || event.keyCode;
      if (key === 'Escape' || key === 'Esc' || key === 27) {

        if (shoArrowBack !== undefined || shoCloseicon !== undefined) {
          console.log("suljetaan ikkuna, koska painettiin ESCiä");
          funct();
        }
      }
    });
*/
  }
    
  componentDidUpdate() {
    /*console.log("ContentBox/componentDidUpdate.\n"+
                "state.headerbgcolor: ", this.state.headerbgcolor,"\n"+
                "props.headerbgcolor: ", this.props.headerbgcolor, "\n"+
                "props.children: ",  this.props.children);*/
  }
 
  render() {
    if (this.props.isOpen === false )
    { 
      return (<div></div>);
    }
    else
    {
      return (
        <div className="ContentBox">
          {this.props.hideHeader ? null :
          <div className="headerRow textCenter vertical-middle" style={{backgroundColor: this.props.headerbgcolor === undefined ? '#00b7f1' :this.props.headerbgcolor}}>
            
            {this.props.button ? <div className="float-left">{this.props.button}</div> : ""}
            <div style={{marginBottom: "8px"}} className="text" >{this.props.header}</div>

            {this.props.showCloseicon ? (
              <div id="test_ContentBox_CloseIcon" className="closeicon" onClick={this.props.setfunction}> 
                {" "}
                {this.props.showCloseicon ? ( <img src={Closeicon} id="test_ContentBox_CloseIcon2" alt="Closeicon" /> ) : ( "" )}
              </div>
            ) : ( "" )}
          </div>
          }

          {this.props.hidecontentRow ? null :
          <div className="contentRow">{this.props.children}</div>
          }
        </div>
      );
    }
  }
}

export default ContentBox;

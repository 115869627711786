import React        from  "react";
import Languages    from  "../../../translations";
import ButtonGroup  from  '@mui/material/ButtonGroup';
import Button       from  "@mui/material/Button";
import                    "../growthfolder.css";


const GrowthFolderSortingBar = ( { growthFolder, growthFolderFunctions } ) => {

  if (typeof growthFolder          === 'undefined' || growthFolder          === null ||
        typeof growthFolderFunctions === 'undefined' || growthFolderFunctions === null) {
      return(null);
    }

  else if (growthFolder.folders.listgrowthfolder.length === 0) {
    return (
      <div className= "growthfolder-sorting-buttons-area">
        <ButtonGroup variant="text" color="secondary" aria-label="text primary button group">
          <Button disabled ={true} color="secondary">{Languages.newest}</Button>
          <Button disabled={true} color="secondary">{Languages.oldest}</Button>
        </ButtonGroup>
      </div>
    )
  }

  else {
    return (
      <div className= "growthfolder-sorting-buttons-area">
        <ButtonGroup variant="outlined" color="secondary" aria-label="text primary button group">
          <Button color="primary" onClick={() => growthFolderFunctions['setSorting'](true)}>{Languages.newest}</Button>
          <Button color="primary" onClick={() => growthFolderFunctions['setSorting'](false)}>{Languages.oldest}</Button>
        </ButtonGroup>
      </div>
    )
  }
}

export default GrowthFolderSortingBar;
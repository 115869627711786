import React, { useEffect, useState } from "react";
import "./childprofilepicture.css";
import Avatar from "@mui/material/Avatar";
import FaceRoundedIcon from "@mui/icons-material/FaceRounded";
import { useStateValue } from "../../State";

/*
props.childId
This component takes a child's personId as props and returns a placeholder picture or the child's picture in a round Avatar
*/

// Todo: redo NewMessage profile pictures too

const ChildProfilePicture = (props) => {
  const { state, actions } = useStateValue();
  const { profilePictures } = state;
  const [pictureContent, setPictureContent] = useState(null);

  useEffect(() => {
    if (
      profilePictures.state !== "LOAD_SUCCESS_PROFILEPICTURES" &&
      profilePictures.state !== "LOADING_PROFILEPICTURES"
    ) {
      actions.triggerLoadProfilePictures();
    }
  }, []);

  useEffect(() => {
    if (profilePictures.state === "LOAD_SUCCESS_PROFILEPICTURES") {
      const pictureContent = profilePictures.profilePicturesList.reduce(
        (accumulator, currentValue) => {
          if (currentValue.PersonID === props.childId) {
            accumulator = currentValue; //.ImageBase64;
          }
          return accumulator;
        }
      );
      setPictureContent(pictureContent);
    }
  }, [state, profilePictures, props, pictureContent]);

  return typeof pictureContent === "undefined" ||
    pictureContent === null ||
    pictureContent.ImageBase64 === null ? (
    <Avatar style={{ height: "40px", width: "40px" /*margin: "auto"*/ }}>
      <FaceRoundedIcon
        sx={{ bgcolor: props.color != null ? props.color : null }}
      />
    </Avatar>
  ) : (
    <Avatar
      /*style={{margin: "auto"}}*/ alt="picture"
      src={"data:image/jpeg;" + pictureContent.ImageBase64}
    />
  );

  /* 

    
    
    profilePictures.state === "LOAD_SUCCESS_PROFILEPICTURES" ?
      profilePictures.profilePicturesList.map(picture => {
        return (

          picture.PersonID === props.childId ? <Avatar style={{ height: "40px", width: "40px", margin: "auto" }}><FaceRoundedIcon /></Avatar> : null

        )
      }

      )
    :
    <Avatar style={{ height: "40px", width: "40px", margin: "auto" }}><FaceRoundedIcon /></Avatar>

*/

  /*
    {children.ChildList.reduce((accumulator, currentValue) => {
      if (childData.childPersonId === currentValue.Id) {
        accumulator = currentValue.BgColor;
      }
      return accumulator;
    })}*/

  /*children.ChildList.map(child => {
                      
      if (child.Id === props.childId) {
        return (
          <div key={child.Id}>
            
            {child.ProfilePictureBase64 === undefined || child.ProfilePictureBase64 === null || child.ProfilePictureBase64 === "" ?
            <Avatar style={{ height: "40px", width: "40px", margin: "auto" }}><FaceRoundedIcon /></Avatar> :
            <Avatar style={{margin: "auto"}} alt="picture" src={"data:image/jpeg;" + child.ProfilePictureBase64} />}
          </div>
        )}
      else {
        return null;
      }
    })*/
};

export default ChildProfilePicture;

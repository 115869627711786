import React, { useEffect, useState } from "react";

import { useStateValue } from "../../../../State";

import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import Languages from "../../../../translations";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ChildProfilePicture from "../../../ChildProfilePicture";
import CloseIcon from "@mui/icons-material/Close";

import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

import { styled } from "@mui/material/styles";

import MenuItem from "@mui/material/MenuItem";

import Select from "@mui/material/Select";

import Badge from "@mui/material/Badge";
import { types } from "../../../../Reducers/actionTypes";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import moment from "moment";
const ReservationChildSelection = ({
  childList,
  handleChildToggle,
  handlePlacementSelect,
  selectedChildren,
  selectedPlacements,
}) => {
  return (
    <>
      {childList.map((child) => (
        <>
          <Button
            variant={
              selectedChildren.includes(child.Id) ? "contained" : "outlined"
            }
            value={child.Id}
            sx={{
              width: "80%",
              ml: "10%",
            }}
            onClick={() => handleChildToggle(child.Id)}
          >
            <Box sx={{ width: "100%" }}>
              <Stack direction="row" spacing={2} alignItems="center">
                <Box sx={{ width: "20%" }}>
                  <Avatar sx={{ width: 36, height: 36, mr: 5 }}>
                    <ChildProfilePicture childId={child.Id} />
                  </Avatar>
                </Box>
                <Box sx={{ width: "60%" }}>
                  <Typography
                    color="textSecondary"
                    fontSize="small"
                    textAlign="center"
                  >
                    {child.Name}
                  </Typography>
                </Box>
                <Box sx={{ width: "20%" }}>
                  {selectedChildren.indexOf(child.Id) > -1 && (
                    <CheckIcon color="success" />
                  )}
                  {selectedChildren.indexOf(child.Id) == -1 && (
                    <ClearIcon color="error" />
                  )}
                </Box>
              </Stack>
            </Box>
          </Button>
          {selectedChildren.includes(child.Id) && (
            <ChildCentreRow
              person={child}
              handlePlacementSelect={handlePlacementSelect}
              selectedChildren={selectedChildren}
              selectedPlacements={selectedPlacements}
            ></ChildCentreRow>
          )}
        </>
      ))}
    </>
  );
};

export default ReservationChildSelection;

const ChildCentreRow = ({
  person,
  handlePlacementSelect,
  selectedChildren,
  selectedPlacements,
}) => {
  //WIP Centre Selection functionality
  const [age, setAge] = React.useState(person.Placements[0].Id);

  return (
    <>
      <Box
        sx={{
          width: "80%",
          ml: "10%",
          border: 1,
          borderColor: "#e0e0de",
          mb: 1,
        }}
      >
        <Select
          size="small"
          value={selectedPlacements[person.Id] || person.Placements[0].Id}
          onChange={(event) =>
            handlePlacementSelect(person.Id, event.target.value)
          }
          sx={{
            color: "black",

            width: "96%",
            ml: "2%",
            mb: 1,
            fontSize: "13px",
          }}
        >
          {person.Placements.map((placement) => (
            <MenuItem key={placement.Centre} value={placement.Id}>
              <List sx={{ padding: 0 }}>
                <ListItemText>
                  <Typography textAlign="center">
                    {placement.Priority +
                      ". " +
                      placement.Centre +
                      " - " +
                      placement.Group}
                  </Typography>
                </ListItemText>
                {placement.Priority === 2 && (
                  <ListItemText>
                    <Typography textAlign="center">
                      {Languages.invoiceDataText5_1}
                    </Typography>
                  </ListItemText>
                )}
                {placement.End === null ? (
                  <ListItemText>
                    <Typography fontSize="small" textAlign="center">
                      {moment(placement.Start).format("DD.MM.YYYY") + " - "}
                    </Typography>
                  </ListItemText>
                ) : (
                  <ListItemText>
                    <Typography fontSize="small" textAlign="center">
                      {moment(placement.Start).format("DD.MM.YYYY") +
                        " - " +
                        moment(placement.End).format("DD.MM.YYYY")}
                    </Typography>
                  </ListItemText>
                )}
              </List>
            </MenuItem>
          ))}
        </Select>
      </Box>
    </>
  );
};

/* 
 Idea for this is taken from 
 https://medium.com/simply/state-management-with-react-hooks-and-context-api-at-10-lines-of-code-baf6be8302c
*/
import React, { createContext, useContext, useReducer } from "react";
import { applyMiddleware } from "../Reducers/middleware";
import { useActions } from "../Reducers/actionCreators";
import { AuthStatus } from "./stateStatuses";
import { useAuth } from "../Auth/AuthProvider";

export { initAuthState, initLoginSettingsState } from "./authState";
export const StateContext = createContext();
export { AuthStatus };

export const StateProvider = ({ reducer, initialState, children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const authContext = useAuth();
  const enhancedDispatch = applyMiddleware({
    dispatch,
    logout: authContext.authActions.logout,
  });
  const actions = useActions(state, enhancedDispatch);

  authContext.authActions.setAppStateData(actions.triggerAppReset);

  return (
    <StateContext.Provider
      value={{ state, dispatch: enhancedDispatch, actions, authContext }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateValue = () => useContext(StateContext);

export const getInitialState = () => {
  return {
    ...initialState,
  };
};

export const initialState = {
  bulletinBoards: {
    status: "NEVER_LOADED",
    notes: [],
  },
  dFSettings: {
    // NOTE! dFSettings are not wiped/reset on Logout! Only after refresh
    code: 200,
    state: "NEVER_LOADED",
    updateMainStatsTimerSecs: 301,
    updateThreadTimerSecs: 120,
  },
  discussion: {
    discussionTimes: {
      Code: undefined,
      status: "NEVER_LOADED",
      data: undefined,
    },
    discussionSlots: {
      Code: undefined,
      status: "NEVER_LOADED",
      data: undefined,
    },
    saveDiscussionBooking: {
      Code: undefined,
      status: "NEVER_LOADED",
    },
    deleteDiscussionBooking: {
      Code: undefined,
      status: "NEVER_LOADED",
    },
    editDiscussionBooking: {
      Code: undefined,
      status: "NEVER_LOADED",
    },
    oneChildDiscussions: {
      Code: undefined,
      status: "NEVER_LOADED",
      data: undefined,
    },
    parentsForEditing: {
      Code: undefined,
      status: "NEVER_LOADED",
      data: undefined,
    },
    discNotiStats: {
      Code: undefined,
      status: "NEVER_LOADED",
      data: undefined,
    },
    discTimeSelectionStats: {
      Code: undefined,
      status: "NEVER_LOADED",
      data: [],
    },
    discSaveTimeSelection: {
      status: "NEVER_LOADED",
    },
    discDeleteTimeSelection: {
      status: "NEVER_LOADED",
    },
  },
  taskFolder: {
    state: "NEVER_LOADED",
    Code: undefined, // 500, 502, 400, 200, (401),
    taskfolder: undefined,
  },
  loadTasks: {
    loadTaskThumbnails: {
      state: "NEVER_LOADED",
      Code: undefined,
      savedTaskThumbnail: {},
    },
    loadFullTaskImages: {
      state: "NEVER_LOADED",
      Code: undefined,
      savedFullTaskImage: {},
    },
  },
  growthFolder: {
    state: "NEVER_LOADED",
    errorCode: undefined, // 500, 502, 400, 200, (401),
    loadOrder: undefined,
    folders: undefined,
    images: undefined,
    image: undefined,
  },
  messaging: {
    loadMessages: {
      messages: [],
      state: "NEVER_LOADED",
      Code: undefined,
    },
    doNotLoadInboxAfterOpenThread: false,
    deleteThread: {
      deletedThreadId: undefined,
      status: "NOT_LOADED",
      Code: undefined,
    },
    setThisThreadRead: {
      state: "NEVER_LOADED",
      Code: undefined,
    },
    currentThreadId: {
      id: undefined,
    },
    loadThread: {
      state: "NEVER_LOADED",
      Code: undefined,
      threadId: undefined,
      threadContent: undefined,
      allThreadParticipantsMinusUser: undefined,
      truncatedParticipantsList: undefined,
      numberOfNamesNotShown: undefined,
      DenyReply: undefined,
      MassThread: undefined,

      OneMessageRequester: undefined,
      threadFetched: undefined,
      lastTriedFetchTime: undefined,
    },
    openMessageScrolling: {
      requester: undefined,
    },
    latestApiRequestState: {
      state: "NEVER_LOADED",
    },
    requester: {
      requester: "NEVER_USED",
    },
    replyToThread: {
      state: "NEVER_LOADED",
      Code: undefined,
      ReplyToThreadLoadNewThread: undefined,
    },
    loadPotentialRecipients: {
      state: "NEVER_LOADED",
      Code: undefined,
      potentialRecipients: [],
    },
    postNewMessage: {
      state: "NEVER_LOADED",
      Code: undefined,
    },
    forSnackBar: {
      state: "INIT_VALUE",
    },
    setAllMessagesRead: {
      status: "NEVER_LOADED",
      Code: undefined,
    },
    ////New message load on login stuff
    messageData: {
      messages: [],
      threads: [],
      currentThreadId: undefined,
      notReadThreads: undefined,
      messageThreadAnswered: false,
      lastLoaded: undefined,
      state: "NEVER_LOADED",
      Code: undefined,
    },
  },
  images: {
    // profiles:
    // messageAttachments:
  },
  nursingtimes: {
    List: {
      WeekData: [],
      state: undefined,
      Code: undefined,
    },
    Saved: {
      Data: [],
      state: undefined,
      Code: undefined,
    },
    defaultNursingTimes: [],
    nextNursingTimes: {
      TimesList: [],
      state: undefined,
    },
    Deleted: {
      state: undefined,
      Data: [],
    },
  },
  //CARETIMES WIP
  caretimes: {
    careTimesList: {
      WeekData: [],
      state: undefined,
      Code: undefined,
    },
    testList: {
      WeekData: {
        WeekList: [],
        DaysToBeSelected: 0,
        LockedDaysToBeSelected: 0,
        ShortestLockTime: " ",
      },
      DayList: [],
      state: undefined,
      Code: undefined,
    },
    Quick: {
      state: undefined,
      DayList: [],
      DayList2: [],
      HolidayList: [],
      VacationList: [],
      VacationPeriodList: [],
      Code: undefined,
    },
    checkList: {
      checkData: [],
      state: undefined,
      Code: undefined,
    },
    CTSaved: {
      Data: [],
      state: undefined,
      Code: undefined,
    },
    defaultNursingTimes: [],
    clockType: {
      state: undefined,
      Type: "slider",
    },

    nextNursingTimes: {
      TimesList: [],
      state: undefined,
    },
    Deleted: {
      state: undefined,
      Data: [],
    },
    Absent: {
      state: undefined,
      Data: [],
      Code: undefined,
    },
  },
  nursingTimesSummary: {
    NT: {
      status: "NEVER_LOADED",
      Code: 200,
      overbookingWarning: false,
      ListChildStats: [],
    },
    InvoiceBasisData: {
      status: "NEVER_LOADED",
      Code: 200,
      data: undefined,
    },
  },
  snackbar: {
    queue: [], // [{message: "Näytä tämä lyhyt viesti", color: "#FFFFFF"}, {message: "Näytä tämä lyhyt viesti2", color: "#000000"}]
    postNewMessage: {
      status: "reset",
      message: "",
    },
  },
  files: {
    loadPreviewPicture: {
      status: "NEVER_LOADED",
      Code: undefined,
      savedPreviewPictures: {}, //{id: data}
    },
    loadFullImage: {
      status: "NEVER_LOADED",
      Code: undefined,
      savedFullImages: {}, //{id: data}
    },
    fileDownloadStats: {
      status: "NEVER_DOWNLOADED",
      response: undefined,
      fileId: undefined,
      downloads: [],
    },
  },
  header: {
    title: "",
    state: "title_does_not_exist",
    leftArrow: undefined,
    closeIcon: undefined,
    function: undefined,
    arrowClicked: undefined,
    invoiceViewOpen: false,
  },
  profilePictures: {
    state: "PROFILEPICTURES_INITIAL_STATE",
    Code: 666,
    profilePicturesList: [],
  },
  children: {
    state: undefined,
    Code: undefined,
    ChildList: [],
    ShortestLockTime: undefined,
    backupParent: {
      deleteStatus: "INITIAL_STATE",
      deleteCode: undefined,
    },
  },
  approval: {
    state: "INITIAL_STATE",
  },
  dailynote: {
    state: "INITIAL_STATE",
    Code: undefined,
    WeekList: [],
  },
  vasu: {
    state: "INITIAL_STATE",
    Code: undefined,
    GeneralPart: [],
    Table1: [],
    Table2: [],
    Table3: [],
    VasuSupportExtraPart: [],
    VasuApprovalsTable: [],
  },
  leops: {
    state: "INITIAL_STATE",
    GeneralPart: [],
    Table1: [],
    Table2: [],
    Table3: [],
    Table4: [],
    Table5: [],
  },
  settings: {
    state: undefined,
    Code: undefined,
    communeSettings: [],
  },
  mainstats: {
    Code: undefined,
    state: "NOT_LOADED",
    ThreadCode: undefined,
    ThreadStatus: "NEVER_LOADED",
    // mainNavStatus: {
    //   open: undefined,
    // },
    NursingtimeCode: undefined,
    NursingtimeStatus: "NEVER_LOADED",
    PermissionsCode: undefined,
    PermissionsStatus: "NEVER_LOADED",
    lastSuccessTime: 0,
    lastErrorTime: 0,
    lastSuccessTimeThread: 0,
    lastSuccessTimeNursingtime: 0,
    lastSuccessTimePermissions: 0,
    stats: undefined,
    //notReadMessages: ei käytössä tällä hetkellä, mutta backendissa koodia
    notReadThreads: undefined,
    nursingTimesStatusAllDone: undefined,
    listChildNursingTimeStatus: undefined,
    TotalUnansweredPermissions: undefined,
    TotalUnansweredChildPermPermissions: undefined,
    UnansweredParentConsents: undefined,
    UnansweredChildPermPermissions: undefined,
    UnansweredShortTermPermissions: undefined,
    childToOpen: undefined,
    openAdultConsents: undefined,
    actionViewNotis: undefined,
  },
  loginEmail: {
    passwordReset: {
      status: "NEVER_LOADED",
      Code: undefined,
      errorType: undefined,
    },
    orderUsername: {
      status: "NEVER_LOADED",
      Code: undefined,
      errorType: undefined,
      newusername: undefined,
    },
  },
  notifications: {
    status: "",
    notificationType: "",
    extra: "",
  },
  centreinfos: {
    placements: {
      status: "NEVER_LOADED",
      Code: undefined,
      data: [],
    },
  },
  childFiles: {
    filesList: {
      status: "CHILD_FILES_NEVER_LOADED",
      Code: undefined,
      filesList: [],
    },
  },
  smallRequests: {
    vacationNotificationStatus: {
      data: {
        ShowNotification: false,
        NotificationTextRows: undefined,
      },
      status: "NEVER_LOADED",
    },
  },
  municipalpermissionquestions: {
    //lasten pitkäaikaiset luvat
    childPermPermissionsList: {
      status: "NEVER_LOADED",
      Code: undefined,
      data: [],
    },
    saveChildPermPermissions: {
      status: "NEVER_LOADED",
      Code: undefined,
    },
    //huoltajien suostumukset
    guardianConsent: {
      status: "NEVER_LOADED",
      Code: undefined,
      data: [],
    },
    saveGuardianConsent: {
      status: "NEVER_LOADED",
      Code: undefined,
    },
    // permissionQuery =  kyselyt = lyhytaikaiset lapsen luvat
    permissionQueryList: {
      status: "NEVER_LOADED",
      Code: undefined,
      data: [],
    },
    savePermissionQuery: {
      status: "NEVER_LOADED",
      Code: undefined,
    },
    onePermissionQuery: {
      status: "NEVER_LOADED",
      Code: undefined,
      data: undefined,
    },
  },
};

// Usage in class componenet
// static contextType = StateContext;
// trigger: this.context.actions.triggerLoadMessages('test-data');

import React/*, { useState, useEffect }*/ from "react";
//import {useStateValue}    from "../../State";
import "./vasu.css";

import Avatar             from "@mui/material/Avatar";
import FaceRoundedIcon    from '@mui/icons-material/FaceRounded';
import Grid               from "@mui/material/Grid";
import Paper              from "@mui/material/Paper";
import { Typography }     from "@mui/material";
import Box                from '@mui/material/Box'
import Stack              from '@mui/material/Stack';

import KeyboardArrowLeft  from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';


const ChildRow = (props) => {

  //const { state/*, actions*/ } = useStateValue();
  //const { children } = state;
  const { FormId, childpicture, childFirstName, childLastName, childColor/*, history*/ } = props;
 
  return (
    <div id = {"test_Form_"+FormId} className="FormChildRow">
          <React.Fragment>
            <Stack direction="row"  spacing={2} alignItems="center">
              <Typography style={{/* paddingTop: "8px", paddingBottom: "8px"*/ }}><KeyboardArrowLeft style={{opacity: "0"}} /*onClick={() => handleChildSelection("PREVCHILD")}*/></KeyboardArrowLeft> </Typography>
              <Box>
                {childpicture === undefined || childpicture === null || childpicture === "" ?
                  <Avatar data-testid={"ut_vasu_no_childpicture"} style={{ height: "60px", width: "60px" }} sx ={{boxShadow: 2}}><FaceRoundedIcon /></Avatar> :
                  <Avatar data-testid={"ut_vasu_childpicture"} alt="picture" src={"data:image/jpeg;" + childpicture} style={{ height: "60px", width: "60px" }} sx ={{boxShadow: 2}} />}
              </Box>  
              <Typography color="textSecondary" style={{textAlign:"left", fontWeight: 'Bold', fontSize: 18, color: "#424242"}} data-testid={"ut_vasu_childname"} >{childFirstName + " " + childLastName}</Typography>
            </Stack>
            
          </React.Fragment>

          <React.Fragment>
            <div style={{ marginBottom: "10px"}}></div>
          </React.Fragment>
      </div>
  );
}

export default ChildRow;
import React, { useEffect, useState } from "react";
import Languages from "../../../../translations";
import Drawer from "@mui/material/Drawer";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import moment from "moment";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { TimeClock } from "@mui/x-date-pickers/TimeClock";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { styled } from "@mui/material/styles";

import { useStateValue } from "../../../../State";
import { history } from "../../../../Router";
import { types } from "../../../../Reducers/actionTypes";
import CalendarTimeSlider from "../common/CalendarTimeSlider";
import { DigitalClock } from "@mui/x-date-pickers/DigitalClock";
import { MultiSectionDigitalClock } from "@mui/x-date-pickers/MultiSectionDigitalClock";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { StaticTimePicker } from "@mui/x-date-pickers/StaticTimePicker";
import { renderMultiSectionDigitalClockTimeView } from "@mui/x-date-pickers/timeViewRenderers";
import CircularProgress from "@mui/material/CircularProgress";
import Avatar from "@mui/material/Avatar";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ChildProfilePicture from "../../../ChildProfilePicture";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import Slider from "@mui/material/Slider";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import CardHeader from "@mui/material/CardHeader";
const CareTimeCalendarSettingsDrawer = ({
  open,
  onClose,
  personName,
  handleNames,
  names,
}) => {
  const { state, actions } = useStateValue();
  const defaultNursingTimes = state.nursingtimes.defaultNursingTimes;
  const clockType = state.caretimes.clockType.Type;
  const [ResLenght, setResLenght] = React.useState([]);
  const [saved, setSaved] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [saved2, setSaved2] = React.useState(false);
  const [saving2, setSaving2] = React.useState(false);
  const [value, setValue] = React.useState(state.caretimes.clockType.Type);
  const handleResLenght = (newLenght) => {
    //console.log(moment(newLenght[0]));
    setResLenght(newLenght);
  };

  useEffect(() => {
    switch (defaultNursingTimes.state) {
      case types.SAVING_SUCCESS_DEFAULT_NURSINGTIMES:
        setSaving(true);
        setSaved(true);
        break;
      case types.SAVING_DEFAULT_NURSINGTIMES:
        setSaving(true);
        setSaved(false);

        break;
      case types.SAVING_FAILED_DEFAULT_NURSINGTIMES:
        setSaving(false);
        setSaved(true);
        break;
      default:
        break;
    }
  }, [defaultNursingTimes.state]);

  useEffect(() => {
    switch (state.caretimes.clockType.state) {
      case types.CLOCK_TYPE_SAVED_OK:
        setSaving2(true);
        setSaved2(true);
        break;
      case types.SAVING_CLOCK_TYPE:
        setSaving2(true);
        setSaved2(false);

        break;
      case types.CLOCK_TYPE_SAVED_FAILED:
        setSaving2(false);
        setSaved2(true);
        break;
      default:
        break;
    }
  }, [state.caretimes.clockType.state]);

  useEffect(() => {
    setSaved(false);
    setSaved2(false);
    setValue(state.caretimes.clockType.Type);
  }, [open]);

  const [ResLenght2, setResLenght2] = React.useState([]);

  const handleResLenght2 = (newLenght) => {
    //console.log(moment(newLenght[0]));
    setResLenght2(newLenght);
  };

  const handleDefaultNursingTimes = () => {
    actions.triggerSaveDefaultNursingTimes({
      StartTime: moment().startOf("day").add(ResLenght[0], "minutes"),
      EndTime: moment().startOf("day").add(ResLenght[1], "minutes"),
    });
  };

  const handleCLockType = () => {
    actions.triggerSaveClockType({
      Type: value,
    });
  };

  const handleRedirectToCaretimes = () => {
    if (history.location.pathname === "/nursingtimes") {
      onClose();
    } else {
      history.push("/nursingtimes");
    }
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Drawer
      anchor={"bottom"}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          margin: "auto",
          maxWidth: 800,
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          mb: "40px",
        },
      }}
      sx={{
        zIndex: 0,
      }}
      transitionDuration={{ enter: 500, exit: 400 }}
    >
      <Box
        sx={{
          width: "auto",
          height: "auto",

          backgroundColor: "white",
          borderTopLeftRadius: "inherit",
          borderTopRightRadius: "inherit",
        }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        role="presentation"
      >
        <Puller onClick={onClose} />
        <Box
          sx={{
            maxHeight: 680,
            overflow: "auto",
            width: "100%",
            "&::-webkit-scrollbar": { display: "none" },
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          <Stack sx={{ mb: 2 }}>
            <Accordion
              style={{
                backgroundColor: "#FFFFFF",
                boxShadow: "none",
              }}
              sx={{ width: "100%", mt: 4, mb: 1 }}
              TransitionProps={{
                timeout: 400,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  color="textSecondary"
                  fontWeight="bold"
                  fontSize="16px"
                  align="center"
                  sx={{ width: "100%" }}
                >
                  {Languages.newCalendarChildrenToShow}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ToggleButtonGroup
                  sx={{ width: "100%", mt: 5, mb: 2 }}
                  orientation="vertical"
                  value={personName}
                  onChange={handleNames}
                >
                  {names.map((name) => (
                    <ToggleButton
                      key={name.Id}
                      value={name.Id}
                      sx={{
                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "100%" }}>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <Box sx={{ width: "20%" }}>
                            <Avatar sx={{ width: 36, height: 36, mr: 5 }}>
                              <ChildProfilePicture childId={name.Id} />
                            </Avatar>
                          </Box>
                          <Box sx={{ width: "60%" }}>
                            <Typography
                              color="textSecondary"
                              fontSize="small"
                              textAlign="center"
                            >
                              {name.Name}
                            </Typography>
                          </Box>
                          <Box sx={{ width: "20%" }}>
                            {personName.indexOf(name.Id) > -1 && (
                              <CheckIcon color="success" />
                            )}
                            {personName.indexOf(name.Id) == -1 && (
                              <ClearIcon color="error" />
                            )}
                          </Box>
                        </Stack>
                      </Box>
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                backgroundColor: "#FFFFFF",
                boxShadow: "none",
              }}
              sx={{
                width: "100%",
                mb: 1,
                borderTop: "1px solid rgba(0, 0, 0, .125)",
              }}
              TransitionProps={{
                timeout: 400,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  color="textSecondary"
                  fontWeight="bold"
                  fontSize="16px"
                  align="center"
                  sx={{ width: "100%" }}
                >
                  {Languages.newCalendarDefaultTime}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {defaultNursingTimes.state !==
                  types.SAVING_DEFAULT_NURSINGTIMES && (
                  <Stack spacing={1} alignItems="center">
                    <CalendarTimeSlider handleResLenght={handleResLenght} />
                    <Button
                      variant="contained"
                      color="success"
                      sx={{ width: "65%", backgroundColor: "#00B3DF" }}
                      onClick={() => handleDefaultNursingTimes()}
                    >
                      {Languages.newCalendarSave}
                    </Button>
                    {defaultNursingTimes.state ===
                      types.SAVING_SUCCESS_DEFAULT_NURSINGTIMES &&
                      saved && (
                        <Typography color="textSecondary" fontSize="medium">
                          {Languages.newCalendarSaveSuccess}
                        </Typography>
                      )}
                    {defaultNursingTimes.state ===
                      types.SAVING_FAILED_DEFAULT_NURSINGTIMES &&
                      saved && (
                        <Typography color="textSecondary" fontSize="medium">
                          {Languages.newCalendarSaveFailed +
                            ", " +
                            Languages.newCalendarTryAgain}
                        </Typography>
                      )}
                  </Stack>
                )}

                {defaultNursingTimes.state ===
                  types.SAVING_DEFAULT_NURSINGTIMES && (
                  <Stack spacing={1} alignItems="center">
                    <CircularProgress></CircularProgress>
                    <Typography color="textSecondary" fontSize="medium">
                      {Languages.newCalendarSavingDefault}
                    </Typography>
                  </Stack>
                )}
              </AccordionDetails>
            </Accordion>

            <Accordion
              style={{
                backgroundColor: "#FFFFFF",
                boxShadow: "none",
              }}
              sx={{
                width: "100%",
                mb: 1,
                borderTop: "1px solid rgba(0, 0, 0, .125)",
              }}
              TransitionProps={{
                timeout: 400,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  color="textSecondary"
                  fontWeight="bold"
                  fontSize="16px"
                  align="center"
                  sx={{ width: "100%" }}
                >
                  {Languages.clockType}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {state.caretimes.clockType.state !==
                  types.SAVING_CLOCK_TYPE && (
                  <Stack spacing={1} alignItems="center">
                    <Stack spacing={1} alignItems="center">
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={value}
                          onChange={handleChange}
                        >
                          <Card
                            variant="outlined"
                            sx={{ backgroundColor: "white" }}
                          >
                            <Stack spacing={1} alignItems="center">
                              <FormControlLabel
                                value="slider"
                                control={<Radio />}
                                label={Languages.clockTypeSlider}
                                sx={{ color: "black" }}
                              />
                              <Clocktype type={"slider"}></Clocktype>
                            </Stack>
                          </Card>

                          <Card
                            variant="outlined"
                            sx={{ backgroundColor: "white", mt: 1 }}
                          >
                            <Stack
                              spacing={1}
                              alignItems="center"
                              sx={{ padding: 1, paddingBottom: 0 }}
                            >
                              <FormControlLabel
                                value="dropdown"
                                control={<Radio />}
                                label={Languages.clockTypeDigital}
                                sx={{ color: "black" }}
                              />
                              <Clocktype type={"dropdown"}></Clocktype>
                            </Stack>
                          </Card>
                        </RadioGroup>
                      </FormControl>
                      <Button
                        variant="contained"
                        color="success"
                        sx={{ width: "65%", backgroundColor: "#00B3DF" }}
                        onClick={() => handleCLockType()}
                      >
                        {Languages.newCalendarSave}
                      </Button>
                    </Stack>
                    {state.caretimes.clockType.state ===
                      types.CLOCK_TYPE_SAVED_OK &&
                      saved2 && (
                        <Typography color="textSecondary" fontSize="medium">
                          {"Tallennus onnistui!"}
                        </Typography>
                      )}
                    {state.caretimes.clockType.state ===
                      types.CLOCK_TYPE_SAVED_FAILED &&
                      saved2 && (
                        <Typography color="textSecondary" fontSize="medium">
                          {Languages.newCalendarSaveFailed +
                            ", " +
                            Languages.newCalendarTryAgain}
                        </Typography>
                      )}
                  </Stack>
                )}

                {state.caretimes.clockType.state ===
                  types.SAVING_CLOCK_TYPE && (
                  <Stack spacing={1} alignItems="center">
                    <CircularProgress></CircularProgress>
                    <Typography color="textSecondary" fontSize="medium">
                      {Languages.newCalendarSavingDefault}
                    </Typography>
                  </Stack>
                )}
              </AccordionDetails>
            </Accordion>
          </Stack>
        </Box>
      </Box>
    </Drawer>
  );
};

export default CareTimeCalendarSettingsDrawer;

const Puller = styled(Box)(() => ({
  width: 30,
  height: 6,
  backgroundColor: "#cccccc",
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

const Clocktype = ({ type }) => {
  const { state, actions } = useStateValue();
  const defaultNursingTimes = state.nursingtimes.defaultNursingTimes;

  const [allowedMinutes, setAllowedMinutes] = useState([0, 15, 30, 45, 59]);

  //Reservation start/end times stored as minutes for the slider component
  const [value, setValue] = React.useState([
    moment
      .duration(moment(defaultNursingTimes.Start).format("HH:mm"))
      .asMinutes(),
    moment
      .duration(moment(defaultNursingTimes.End).format("HH:mm"))
      .asMinutes(),
  ]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChange2 = (event, newValue) => {};

  const [endHours, setEndHours] = useState(0);

  const handleEndHours = (value) => {
    setEndHours(value.hours());
    if (value.hours() == 23) {
      setAllowedMinutes([0, 15, 30, 45, 59]);
    } else {
      setAllowedMinutes([0, 15, 30, 45]);
    }
  };

  const formatTime = (time) => {
    if (time && time.hours() === 23 && time.minutes() === 59) {
      return "24:00";
    }

    return time ? time.format("HH:mm") : "";
  };
  const [selectedTime, setSelectedTime] = React.useState(null);

  const handleTimeChange = (time) => {
    setSelectedTime(time);
  };

  const renderCustomTime = () => {
    return <ListItem button>{"24:00"}</ListItem>;
  };

  const handleChange3 = (event) => {
    setStartHours(event.target.value);
  };
  const handleChange4 = (event) => {
    setStartMinutes(event.target.value);
  };
  const handleChange5 = (event) => {
    setEndHours2(event.target.value);
    if (event.target.value == 24) {
      setEndMinutes(0);
    }
  };
  const handleChange6 = (event) => {
    setEndMinutes(event.target.value);
  };

  const [startHours, setStartHours] = React.useState(
    moment(defaultNursingTimes.Start).hours()
  );
  const [startMinutes, setStartMinutes] = React.useState(
    moment(defaultNursingTimes.Start).minute()
  );
  const [endHours2, setEndHours2] = React.useState(
    moment(defaultNursingTimes.End).hours()
  );
  const [endMinutes, setEndMinutes] = React.useState(
    moment(defaultNursingTimes.End).minute()
  );

  const [value2, setValue2] = React.useState(moment("2022-04-17T15:30"));
  if (type === "slider") {
    return (
      <Slider
        sx={{
          height: 8,
          borderRadius: 0,
          mt: 2,
          width: "90%",
          ml: "2%",

          "& .MuiSlider-thumb": {
            backgroundColor: "#0074C8",
            height: 24,
            width: 24,
          },

          "& .MuiSlider-track": {
            height: 8,
            color: "#0074C8",
          },
          "& .MuiSlider-rail": {
            color: "#e0e0de",
            opacity: 1,
            height: 8,
          },
          "& .MuiSlider-valueLabel": {
            fontSize: 12,
            fontWeight: "normal",
            top: 2,
            backgroundColor: "unset",
            color: "black",
            "&:before": {
              display: "none",
            },
            "& *": {
              background: "transparent",
              color: "black",
            },
          },
        }}
        value={value}
        valueLabelDisplay="on"
        valueLabelFormat={(value) =>
          moment().startOf("day").add(value, "minutes").format("HH:mm") ==
            "00:00" && value == 1440
            ? "24:00"
            : moment().startOf("day").add(value, "minutes").format("HH:mm")
        }
        min={0}
        max={1440}
        step={15}
        onChange={handleChange}
        onChangeCommitted={handleChange2}
      />
    );
  }
  if (type === "clock") {
    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DemoContainer
          components={["DigitalClock", "MultiSectionDigitalClock"]}
        >
          <DemoItem label="Digital clock">
            <DigitalClock
              skipDisabled
              timeStep={15}
              sx={{ color: "black", border: "1px black" }}
            />
          </DemoItem>
          <DemoItem label="Multi section digital clock">
            <MultiSectionDigitalClock
              skipDisabled
              ampm={false}
              sx={{ color: "black", backgroundColor: "red" }}
            />
          </DemoItem>
        </DemoContainer>
      </LocalizationProvider>
    );
  }
  if (type === "dropdown") {
    return (
      <Box>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="center"
          sx={{ paddingBottom: 1 }}
        >
          <Card
            variant="outlined"
            sx={{
              bgcolor: "white",
              mt: 0.5,
              ml: 1,
            }}
          >
            <CardHeader
              title={
                <Typography
                  color="textSecondary"
                  fontWeight="bold"
                  textAlign="center"
                >
                  {Languages.clockTypeStart}
                </Typography>
              }
              sx={{ paddingBottom: 0, paddingTop: 2 }}
            />
            <CardContent>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent="center"
              >
                <FormControl sx={{}}>
                  <Select
                    sx={{
                      color: "black",
                      fontSize: 18,
                      "& .MuiSelect-select": {
                        paddingRight: "16px!important",
                        paddingLeft: "16px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#0074C8",
                      },
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={startHours}
                    onChange={handleChange3}
                    inputProps={{
                      IconComponent: () => null,
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                      style: {
                        maxHeight: 240,
                      },
                      getContentAnchorEl: null,
                      MenuListProps: {
                        sx: {
                          backgroundColor: "white",
                          color: "black",
                          "&& .Mui-selected": {
                            color: "white",
                            backgroundColor: "#0074C8",
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value={0}>00</MenuItem>
                    <MenuItem value={1}>01</MenuItem>
                    <MenuItem value={2}>02</MenuItem>
                    <MenuItem value={3}>03</MenuItem>
                    <MenuItem value={4}>04</MenuItem>
                    <MenuItem value={5}>05</MenuItem>
                    <MenuItem value={6}>06</MenuItem>
                    <MenuItem value={7}>07</MenuItem>
                    <MenuItem value={8}>08</MenuItem>
                    <MenuItem value={9}>09</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={11}>11</MenuItem>
                    <MenuItem value={12}>12</MenuItem>
                    <MenuItem value={13}>13</MenuItem>
                    <MenuItem value={14}>14</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={16}>16</MenuItem>
                    <MenuItem value={17}>17</MenuItem>
                    <MenuItem value={18}>18</MenuItem>
                    <MenuItem value={19}>19</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={21}>21</MenuItem>
                    <MenuItem value={22}>22</MenuItem>
                    <MenuItem value={23}>23</MenuItem>
                  </Select>
                </FormControl>
                <Typography
                  color="textSecondary"
                  fontSize="large"
                  fontWeight="bold"
                >
                  {":"}
                </Typography>
                <FormControl sx={{}}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={startMinutes}
                    onChange={handleChange4}
                    inputProps={{ IconComponent: () => null }}
                    sx={{
                      color: "black",
                      fontSize: 18,
                      "& .MuiSelect-select": {
                        paddingRight: "16px!important",
                        paddingLeft: "16px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#0074C8",
                      },
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                      style: {
                        maxHeight: 240,
                      },
                      getContentAnchorEl: null,
                      MenuListProps: {
                        sx: {
                          backgroundColor: "white",
                          color: "black",
                          "&& .Mui-selected": {
                            color: "white",
                            backgroundColor: "#0074C8",
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value={0}>00</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                    <MenuItem value={45}>45</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
            </CardContent>
          </Card>

          <Typography
            sx={{ paddingTop: 5 }}
            color="textSecondary"
            fontSize="large"
            fontWeight="bold"
          >
            {"-"}
          </Typography>
          <Card
            variant="outlined"
            sx={{
              bgcolor: "white",
              mt: 0.5,
              ml: 1,
            }}
          >
            <CardHeader
              title={
                <Typography
                  color="textSecondary"
                  fontWeight="bold"
                  textAlign="center"
                >
                  {Languages.clockTypeEnd}
                </Typography>
              }
              sx={{ paddingBottom: 0, paddingTop: 2 }}
            />
            <CardContent>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent="center"
              >
                <FormControl sx={{}}>
                  <Select
                    sx={{
                      color: "black",
                      fontSize: 18,
                      "& .MuiSelect-select": {
                        paddingRight: "16px!important",
                        paddingLeft: "16px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#0074C8",
                      },
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={endHours2}
                    onChange={handleChange5}
                    inputProps={{
                      IconComponent: () => null,
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                      style: {
                        maxHeight: 240,
                      },
                      getContentAnchorEl: null,
                      MenuListProps: {
                        sx: {
                          backgroundColor: "white",
                          color: "black",
                          "&& .Mui-selected": {
                            color: "white",
                            backgroundColor: "#0074C8",
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value={0}>00</MenuItem>
                    <MenuItem value={1}>01</MenuItem>
                    <MenuItem value={2}>02</MenuItem>
                    <MenuItem value={3}>03</MenuItem>
                    <MenuItem value={4}>04</MenuItem>
                    <MenuItem value={5}>05</MenuItem>
                    <MenuItem value={6}>06</MenuItem>
                    <MenuItem value={7}>07</MenuItem>
                    <MenuItem value={8}>08</MenuItem>
                    <MenuItem value={9}>09</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={11}>11</MenuItem>
                    <MenuItem value={12}>12</MenuItem>
                    <MenuItem value={13}>13</MenuItem>
                    <MenuItem value={14}>14</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={16}>16</MenuItem>
                    <MenuItem value={17}>17</MenuItem>
                    <MenuItem value={18}>18</MenuItem>
                    <MenuItem value={19}>19</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={21}>21</MenuItem>
                    <MenuItem value={22}>22</MenuItem>
                    <MenuItem value={23}>23</MenuItem>
                    <MenuItem value={24}>24</MenuItem>
                  </Select>
                </FormControl>
                <Typography
                  color="textSecondary"
                  fontSize="large"
                  fontWeight="bold"
                >
                  {":"}
                </Typography>
                <FormControl sx={{}}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={endMinutes}
                    onChange={handleChange6}
                    inputProps={{ IconComponent: () => null }}
                    disabled={endHours2 == 24 ? true : false}
                    sx={{
                      color: "black",
                      fontSize: 18,
                      "& .MuiSelect-select": {
                        paddingRight: "16px!important",
                        paddingLeft: "16px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#0074C8",
                      },
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                      style: {
                        maxHeight: 240,
                      },
                      getContentAnchorEl: null,
                      MenuListProps: {
                        sx: {
                          backgroundColor: "white",
                          color: "black",
                          "&& .Mui-selected": {
                            color: "white",
                            backgroundColor: "#0074C8",
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value={0}>00</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                    <MenuItem value={45}>45</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
            </CardContent>
          </Card>
        </Stack>
      </Box>
    );
  }
};

import React                  from "react";
import "../invoiceBasis.css";
import Languages              from "../../../translations";
import TableBody              from "@mui/material/TableBody";
import TableCell              from "@mui/material/TableCell";
import TableHead              from "@mui/material/TableHead";
import TableRow               from "@mui/material/TableRow";
import moment                 from "moment";


const checkWhetherZero = (hoursAndMinutes) => {
  if (hoursAndMinutes === "0 t 00 min" || hoursAndMinutes === "0 h 00 min") {
    return(true);
  }
  else {
    return(false);
  }
}

const ToteumaView = ({nursingDays}) => {

  const toteumaColor = "#6BAF6E";
  const basicCell = {backgroundColor: "#FFFFFF", color: "#000000", wordWrap: "break-word"};
  const basicCellStrong = {backgroundColor: "#FFFFFF", color: "#000000", wordWrap: "break-word", fontWeight: "bold"};
  const toteumaHighlight= {backgroundColor: toteumaColor, fontWeight: "bold"}
  const bookingsHighlight = {backgroundColor: "#ffc000", fontWeight: "bold"}

  return(

    <React.Fragment>
      <TableHead id="test_Invoice_SpentTimeView">
        <TableRow>
          <TableCell padding="none" align="center" style={basicCellStrong}>{Languages.uctDateAbbr}</TableCell> 
          <TableCell padding="none" align="center" style={toteumaHighlight}>{Languages.uctRealizedCareTimes}</TableCell>
          <TableCell padding="none" align="center" style={toteumaHighlight}>{Languages.uctDuration}</TableCell>
          <TableCell padding="none" align="center" style={basicCellStrong}>{Languages.uctBilling}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {nursingDays.map((row, index) => {
          return(
            <TableRow hover key={"row2" + index}>
              <TableCell padding="none" align="center" style={basicCell}>{moment(row.Date).format("DD.MM.")}</TableCell>
              <TableCell padding="none" align="center" style={basicCell}>
                <div>
                  {
                    typeof row.StatusPairTimes === "undefined" || row.StatusPairTimes.length === 0 ?
                      <div>-</div>
                    :
                    row.StatusPairTimes.map((pair, index) => {
                      return(
                        <div key={"pair" + index} style={{marginTop: "1px", marginBottom: "1px"}}>{pair}</div>
                      )
                    })
                  }
                  {
                    row.SingleStatusesTexts.map((statusText, index) => {
                      return(
                        <div key={"statusText09363" + index}>
                          {statusText}
                        </div>
                      )
                    }  
                    )
                  }
                </div>
              </TableCell>
              <TableCell 
                padding="none" align="center" data-testid={"cell-toteuma123" + (index + 1)}
                style={row.RealizationLonger ? toteumaHighlight : basicCell}
              >
                {checkWhetherZero(row.StatusHours) ? "-" : row.StatusHours}
              </TableCell>
              <TableCell padding="none" align="center" data-testid={"cell-toteuma456" + (index + 1)} style={
                row.ReservationLonger ? bookingsHighlight : 
                row.RealizationLonger ? toteumaHighlight :
                basicCell}>{row.PaymentHours}</TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </React.Fragment>

  )

}

export default ToteumaView;
import { types } from './actionTypes';

const HeaderReducer = (state, action) => {
  const payload = action.payload

  switch (action.type) {

    case types.UPDATE_INVOICE_BASIS_VIEW_STATE:
      return {
        ...state,
        invoiceViewOpen: payload
      }

    case types.UPDATE_HEADER_TITLE:
      return {
        ...state,
        state: 'title_exists',
        title: payload
      }
    case types.UPDATE_HEADER_ARROW:
      return {
        ...state,
        leftArrow: payload.leftArrow,
        closeIcon: payload.closeIcon,
        function: payload.function,
      }
    case types.HEADER_ARROW_CLICKED:
      return {
        ...state,
        arrowClicked: true,
      }

    case types.HEADER_ARROW_PROCESSED:
      return {
        ...state,
        arrowClicked: false,
      }
    default: return state;
  }
}

export default HeaderReducer;

import React, { useState, useEffect } from "react";
import {useStateValue} from "../../../../State";

import Moment           from "moment";

import Languages        from "../../../../translations";

import Accordion        from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon   from '@mui/icons-material/ExpandMore';
import Typography       from "@mui/material/Typography";

import Table            from '@mui/material/Table';
import TableBody        from "@mui/material/TableBody";
import TableCell        from "@mui/material/TableCell";
import TableHead        from "@mui/material/TableHead";
import TableRow         from "@mui/material/TableRow";

import CommentsReadOnly from '../../../Vasu/CommentsReadOnly';

import makeStyles from '@mui/styles/makeStyles';


const useStyles = makeStyles(theme => ({
  nobottomline: {
    borderBottom: "none",
    color: "black",
    backgroundColor: "#ffffff"
  },
}));

const EnhancedSupportInitiating = (props) => {

  const classes = useStyles();

  const { Form, callback, closeView, Name, SSN } = props;

  const { state/*, actions*/ } = useStateValue();
  const Table3   = state.leops.Table3;
  const SubModel = Form !== undefined ? Form === 0 ? Table3.EvaluationModel : Table3.StudyModel : null;
  const FormT4 = Form === 0 ? state.leops.Table3.Form1.Table[3] : state.leops.Table3.Form2.Table[3];

  const [expanded, setExpanded] = useState(false);

  /*console.log("EnhancedSupportInitiating. MOUNT",
              "\n Form: ", Form,
              "\nFormT4: ", FormT4);*/

  const toggleExpanded = () => {
    setExpanded(!expanded);
    callback("ESI");
  }

  useEffect(() => {
    //console.log("Table1. useEffect. Close view");
    setExpanded(false);
  },[closeView]); 

  return (
      <Accordion className="painikkeet"
                 style={{ backgroundColor: "#4377BA"/*, color: "black"*/ }}
                 //disabled = {item.Saved === "" ? true: false}
                 expanded={expanded}
                 onChange={() => toggleExpanded()}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel3a-content"
                          id="test_Leops_SupportTableESI">
          <Typography className="heading"> {Languages.EnhancedSupportInitiating}</Typography>
        </AccordionSummary>
        <div className={classes.nobottomline}>
        <AccordionDetails>
            <Table>  
              <TableHead>
                <TableRow >
                  <TableCell className={classes.nobottomline} padding="none" align="left" >{Languages.child}</TableCell> 
                  <TableCell className={classes.nobottomline} align="left" style={{paddingLeft: "20px"}} >{Languages.childsBirthdate}</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow >
                  <TableCell className={classes.nobottomline} padding="none" align="left"> {Name}</TableCell> 
                  <TableCell className={classes.nobottomline} align="left" style={{paddingLeft: "20px"}} >{SSN}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            </AccordionDetails>

            <AccordionDetails>
            {Form === 0 ?
            <div className={classes.nobottomline}>
              <br></br>
              <input style={{width: '20px', height: '20px'}} id="test_ESI_EVALUATION_1" type="checkbox" data-indeterminate="false" checked={SubModel.EnhancedSupport} readOnly />{"  " +Languages.EnhancedSupportStart} {Moment(SubModel.EnhancedSupportStartDate).format("DD.MM.YYYY")}
              <br></br>
              <br></br>
              <input style={{width: '20px', height: '20px'}}id="test_ESI_EVALUATION_2" type="checkbox" data-indeterminate="false" checked={SubModel.GeneralSupport} readOnly />{" " +Languages.EnhancedSupportNotNeeded}
              <br></br>
              <br></br>
              {Languages.EnhancedSupportAndArrangements}
              <br></br>
              <br></br>
              <CommentsReadOnly Title1  = {<Typography color="textSecondary" sx={{textAlign:"left", fontWeight: 'Bold', fontSize: 14, color: "#424242", pt:1, ml:1}}>{Languages.PersonsInvolvedDraftingDocument}</Typography>}
                                Title2  = {null} // kommentoja h-elementtiin
                                Title3  = {null}
                                BgColor = {classes.nobottomline.backgroundColor} 
                                Data    = {{Text: FormT4.TableRow[0].RowItem[0].Editor + "\n" + FormT4.TableRow[0].RowItem[0].RowText}}>
              </CommentsReadOnly>
              <br></br>
              {Languages.EnhancedSupportStartingAndOrganization + ": "} 
              {Moment(SubModel.EnhancedSupportAgreedDate).format("DD.MM.YYYY")}
            </div>
            :
            <div className={classes.nobottomline}>
              <br></br>
              <input style={{width: '20px', height: '20px'}}id="test_ESI_STUDY_1" type="checkbox" data-indeterminate="false" checked={SubModel.SpecialSupport} readOnly />{"  "+Languages.SpecialSupportStart} {Moment(SubModel.SpecialSupportStartDate).format("DD.MM.YYYY")}
              <br></br>
              <br></br>
            </div>
            } 
           </AccordionDetails>

           <AccordionDetails>
            {Form === 1 ?
              <Table>  
                <TableHead>
                  <TableRow >
                    <TableCell className={classes.nobottomline} padding="none" align="left" >{Languages.DateLong}</TableCell> 
                    <TableCell className={classes.nobottomline} align="left" style={{paddingLeft: "20px"}} >{Languages.ResponsiblePreschollTeacher}</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow >
                    <TableCell className={classes.nobottomline} padding="none" align="left" > {Moment(SubModel.Saved).format("DD.MM.YYYY")}</TableCell> 
                    <TableCell className={classes.nobottomline} align="left" style={{paddingLeft: "20px"}}>{SubModel.ResponsiblePreschollTeacher}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            : null }
        </AccordionDetails>
        </div>
      </Accordion>
  );
}

export default EnhancedSupportInitiating;
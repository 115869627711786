export const types = {
  ASYNC_LOAD_DEFAULT_NURSINGTIMES: "START_LOAD_DEFAULT_NURSINGTIMES",
  LOADING_DEFAULT_NURSINGTIMES: "LOADING_DEFAULT_NURSINGTIMES",
  LOAD_SUCCESS_DEFAULT_NURSINGTIMES: "LOAD_SUCCESS_DEFAULT_NURSINGTIMES",
  LOAD_FAILED_DEFAULT_NURSINGTIMES: "LOAD_FAILED_DEFAULT_NURSINGTIMES",
  DEFAULT_NURSINGTIMES_HANDLED: "DEFAULT_NURSINGTIMES_HANDLED",

  ASYNC_SAVE_DEFAULT_NURSINGTIMES: "START_SAVE_DEFAULT_NURSINGTIMES",
  SAVING_DEFAULT_NURSINGTIMES: "SAVING_DEFAULT_NURSINGTIMES",
  SAVING_SUCCESS_DEFAULT_NURSINGTIMES: "SAVING_SUCCESS_DEFAULT_NURSINGTIMES",
  SAVING_FAILED_DEFAULT_NURSINGTIMES: "SAVING_FAILED_DEFAULT_NURSINGTIMES",

  ASYNC_SAVE_CLOCK_TYPE: "ASYNC_SAVE_CLOCK_TYPE",
  SAVING_CLOCK_TYPE: "SAVING_CLOCK_TYPE",
  CLOCK_TYPE_SAVED_OK: "CLOCK_TYPE_SAVED_OK",
  CLOCK_TYPE_SAVED_FAILED: "CLOCK_TYPE_SAVED_FAILED",

  ASYNC_LOAD_CLOCK_TYPE: "ASYNC_LOAD_CLOCK_TYPE",
  LOADING_CLOCK_TYPE: "LOADING_CLOCK_TYPE",
  CLOCK_TYPE_LOAD_OK: "CLOCK_TYPE_LOAD_OK",
  CLOCK_TYPE_LOAD_FAILED: "CLOCK_TYPE_LOAD_FAILED",

  ASYNC_READ_NEXTNURSINGTIMES: "ASYNC_READ_NEXTNURSINGTIMES",
  READING_NEXTNURSINGTIMES: "READING_NEXTNURSINGTIMES",
  NEXTNURSINGTIMES_READ: "NEXTNURSINGTIMES_READ",
  NEXTNURSINGTIMES_FAILED: "NEXTNURSINGTIMES_FAILED",
  NEXTNURSINGTIMES_HANDLED: "NEXTNURSINGTIMES_HANDLED",

  ASYNC_READ_NURSINGTIMES: "ASYNC_READ_NURSINGTIMES",
  READING_NURSINGTIMES: "READING_NURSINGTIMES",
  NURSINGTIMES_READ: "NURSINGTIMES_READ",
  NURSINGTIMES_FAILED: "NURSINGTIMES_FAILED",
  NURSINGTIMES_READ_HANDLED: "NURSINGTIMES_READ_HANDLED",
  NURSINGTIMES_FAILED_HANDLED: "NURSINGTIMES_FAILED_HANDLED",

  ASYNC_SAVE_NURSINGTIMES: "ASYNC_SAVE_NURSINGTIMES",
  SAVING_NURSINGTIMES: "SAVING_NURSINGTIMES",
  NURSINGTIMES_SAVED_OK: "NURSINGTIMES_SAVED_OK",
  NURSINGTIMES_SAVED_FAILED: "NURSINGTIMES_SAVED_FAILED",
  NURSINGTIMES_SAVED_OK_HANDLED: "NURSINGTIMES_SAVED_OK_HANDLED",
  NURSINGTIMES_SAVED_FAILED_HANDLED: "NURSINGTIMES_SAVED_FAILED_HANDLED",

  ASYNC_DELETE_MANY_NURSINGTIMES: "ASYNC_DELETE_MANY_NURSINGTIMES",
  ASYNC_DELETE_NURSINGTIMES: "ASYNC_DELETE_NURSINGTIMES",
  DELETING_NURSINGTIMES: "DELETING_NURSINGTIMES",
  NURSINGTIMES_DELETED_OK: "NURSINGTIMES_DELETED_OK",
  NURSINGTIMES_DELETED_FAILED: "NURSINGTIMES_DELETED_FAILED",
  NURSINGTIMES_DELETED_HANDLED: "NURSINGTIMES_DELETED_HANDLED",

  //WIP CARETIMES
  //###############################################

  //LOAD
  ASYNC_LOAD_CARETIMES: "ASYNC_LOAD_CARETIMES",
  LOADING_CARETIMES: "LOADING_CARETIMES",
  CARETIMES_LOAD_OK: "CARETIMES_LOAD_OK",
  CARETIMES_LOAD_FAILED: "CARETIMES_LOAD_FAILED",
  //LOAD NEXT
  ASYNC_LOAD_CARETIMES_NEXT: "ASYNC_LOAD_CARETIMES_NEXT",
  LOADING_CARETIMES_NEXT: "LOADING_CARETIMES_NEXT",
  CARETIMES_NEXT_LOAD_OK: "CARETIMES_NEXT_LOAD_OK",
  CARETIMES_NEXT_LOAD_FAILED: "CARETIMES_NEXT_LOAD_FAILED",
  //LOAD PREV
  ASYNC_LOAD_CARETIMES_PREV: "ASYNC_LOAD_CARETIMES_PREV",
  LOADING_CARETIMES_PREV: "LOADING_CARETIMES_PREV",
  CARETIMES_PREV_LOAD_OK: "CARETIMES_PREV_LOAD_OK",
  CARETIMES_PREV_LOAD_FAILED: "CARETIMES_PREV_LOAD_FAILED",

  //QUICK VIEW
  ASYNC_LOAD_CARETIMES_QUICK: "ASYNC_LOAD_CARETIMES_QUICK",
  LOADING_CARETIMES_QUICK: "LOADING_CARETIMES_QUICK",
  CARETIMES_QUICK_LOAD_OK: "CARETIMES_QUICK_LOAD_OK",
  CARETIMES_QUICK_LOAD_FAILED: "CARETIMES_QUICK_LOAD_FAILED",
  // QUICK VIEW LOAD NEXT
  ASYNC_LOAD_CARETIMES_QUICK_NEXT: "ASYNC_LOAD_CARETIMES_QUICK_NEXT",
  LOADING_CARETIMES_QUICK_NEXT: "LOADING_CARETIMES_QUICK_NEXT",
  CARETIMES_QUICK_LOAD_NEXT_OK: "CARETIMES_QUICK_LOAD_NEXT_OK",
  CARETIMES_QUICK_LOAD_NEXT_FAILED: "CARETIMES_QUICK_LOAD_NEXT_FAILED",
  //QUICK VIEW LOAD PREVIOUS
  ASYNC_LOAD_CARETIMES_QUICK_PREV: "ASYNC_LOAD_CARETIMES_QUICK_PREV",
  LOADING_CARETIMES_QUICK_PREV: "LOADING_CARETIMES_QUICK_PREV",
  CARETIMES_QUICK_LOAD_PREV_OK: "CARETIMES_QUICK_LOAD_PREV_OK",
  CARETIMES_QUICK_LOAD_PREV_FAILED: "CARETIMES_QUICK_LOAD_PREV_FAILED",

  //PRECHECK
  ASYNC_CHECK_CARETIMES: "ASYNC_CHECK_CARETIMES",
  CHECKING_CARETIMES: "CHECKING_CARETIMES",
  CARETIMES_CHECK_OK: "CARETIMES_CHECK_OK",
  CARETIMES_CHECK_FAILED: "CARETIMES_CHECK_FAILED",

  // SAVE CARETIMES
  ASYNC_SAVE_CARETIMES: "ASYNC_SAVE_CARETIMES",
  SAVING_CARETIMES: "SAVING_CARETIMES",
  CARETIMES_SAVED_OK: "CARETIMES_SAVED_OK",
  CARETIMES_SAVED_FAILED: "CARETIMES_SAVED_FAILED",
  // SAVE ABSENT
  ASYNC_SAVE_ABSENT_CARETIMES: "ASYNC_SAVE_ABSENT_CARETIMES",
  SAVING_CARETIMES_ABSENT: "SAVING_CARETIMES_ABSENT",
  CARETIMES_ABSENT_SAVED_OK: "CARETIMES_ABSENT_SAVED_OK",
  CARETIMES_ABSENT_SAVED_FAILED: "CARETIMES_ABSENT_SAVED_FAILED",

  //DELETE
  ASYNC_DELETE_CARETIMES: "ASYNC_DELETE_CARETIMES",
  DELETING_CARETIMES: "DELETING_CARETIMES",
  CARETIMES_DELETED_OK: "CARETIMES_DELETED_OK",
  CARETIMES_DELETED_FAILED: "CARETIMES_DELETED_FAILED",

  //###############################################
  //VASU
  ASYNC_LOAD_VASU: "ASYNC_LOAD_VASU",
  LOADING_VASU: "LOADING_VASU",
  VASU_LOAD_OK: "VASU_LOAD_OK",
  VASU_LOAD_FAILED: "VASU_LOAD_FAILED",
  ASYNC_SAVE_VASU: "ASYNC_SAVE_VASU",
  SAVING_VASU: "SAVING_VASU",
  VASU_SAVED_OK: "VASU_SAVED_OK",
  VASU_SAVED_FAILED: "VASU_SAVED_FAILED",
  VASU_HANDLED: "VASU_HANDLED",
  // DAILYNOTES
  ASYNC_LOAD_DAILY_NOTES: "ASYNC_DAILY_NOTES",
  LOADING_DAILY_NOTES: "LOADING_DAILY_NOTES",
  DAILY_NOTES_LOAD_OK: "DAILY_NOTES_LOAD_OK",
  DAILY_NOTES_LOAD_FAILED: "DAILY_NOTES_LOAD_FAILED",
  //APPROVALS
  ASYNC_SAVE_APPROVAL: "ASYNC_SAVE_APPROVAL",
  SAVING_APPROVAL: "SAVING_APPROVAL",
  APPROVAL_SAVED_OK: "APPROVAL_SAVED_OK",
  APPROVAL_SAVED_FAILED: "APPROVAL_SAVED_FAILED",
  APPROVAL_HANDLED: "APPROVAL_HANDLED",
  //LEOPS
  ASYNC_LOAD_LEOPS: "ASYNC_LOAD_LEOPS",
  LOADING_LEOPS: "LOADING_LEOPS",
  LEOPS_LOAD_OK: "LEOPS_LOAD_OK",
  LEOPS_LOAD_FAILED: "LEOPS_LOAD_FAILED",

  ASYNC_SAVE_LOCKING_LEOPS: "ASYNC_SAVE_LOCKING_LEOPS",
  ASYNC_SAVE_CHILD_EVALUATIONS_LEOPS: "ASYNC_SAVE_CHILD_EVALUATIONS_LEOPS",
  ASYNC_SAVE_PARENT_COMMENTS_LEOPS: "ASYNC_SAVE_PARENT_COMMENTS_LEOPS",
  SAVING_LEOPS: "SAVING_LEOPS",
  LEOPS_SAVED_OK: "LEOPS_SAVED_OK",
  LEOPS_SAVED_FAILED: "LEOPS_SAVED_FAILED",
  LEOPS_HANDLED: "LEOPS_HANDLED",

  ASYNC_SAVE_STUDYFORM: "ASYNC_SAVE_STUDYFORM",
  SAVING_STUDYFORM: "SAVING_STUDYFORM",
  STUDYFORM_SAVED_OK: "STUDYFORM_SAVED_OK",
  STUDYFORM_SAVED_FAILED: "STUDYFORM_SAVED_FAILED",

  ASYNC_LOAD_NT_SUMMARY: "ASYNC_LOAD_NT_SUMMARY",
  LOADING_NT_SUMMARY: "LOADING_NT_SUMMARY",
  NT_SUMMARY_LOAD_SUCCESS: "NT_SUMMARY_LOAD_SUCCESS",
  NT_SUMMARY_LOAD_FAILED: "NT_SUMMARY_LOAD_FAILED",

  ASYNC_LOAD_INVOICE_SUMMARY_TABLE: "ASYNC_LOAD_INVOICE_SUMMARY_TABLE",
  LOADING_INVOICE_SUMMARY_TABLE: "LOADING_INVOICE_SUMMARY_TABLE",
  INVOICE_SUMMARY_TABLE_LOAD_SUCCESS: "INVOICE_SUMMARY_TABLE_LOAD_SUCCESS",
  INVOICE_SUMMARY_TABLE_LOAD_FAILED: "INVOICE_SUMMARY_TABLE_LOAD_FAILED",

  ASYNC_LOAD_PL_CENTRE_INFOS: "ASYNC_LOAD_PL_CENTRE_INFOS",
  LOADING_PL_CENTRE_INFOS: "LOADING_PL_CENTRE_INFOS",
  PL_CENTRE_INFOS_LOAD_SUCCESS: "PL_CENTRE_INFOS_LOAD_SUCCESS",
  PL_CENTRE_INFOS_LOAD_FAILED: "PL_CENTRE_INFOS_LOAD_FAILED",

  ASYNC_LOAD_CHILD_FILES: "ASYNC_LOAD_CHILD_FILES",
  LOADING_CHILD_FILES: "LOADING_CHILD_FILES",
  CHILD_FILES_LOAD_SUCCESS: "CHILD_FILES_LOAD_SUCCESS",
  CHILD_FILES_LOAD_FAILED: "CHILD_FILES_LOAD_FAILED",

  // General App State Updates without API calls

  UPDATE_INVOICE_BASIS_VIEW_STATE: "UPDATE_INVOICE_BASIS_VIEW_STATE",

  UPDATE_HEADER_TITLE: "UPDATE_HEADER_TITLE",
  UPDATE_HEADER_ARROW: "UPDATE_HEADER_ARROW",
  REMOVE_HEADER_ARROW: "REMOVE_HEADER_ARROW",
  HEADER_ARROW_CLICKED: "HEADER_ARROW_CLICKED",
  HEADER_ARROW_PROCESSED: "HEADER_ARROW_PROCESSED",

  UPDATE_MESSAGE_THREAD_ID: "UPDATE_MESSAGE_THREAD_ID",
  // Start of App Api calls (?)
  ASYNC_LOAD_CHILDREN: "START_LOAD_CHILDREN",
  LOADING_CHILDREN: "LOADING_CHILDREN",
  LOAD_SUCCESS_CHILDREN: "LOAD_SUCCESS_CHILDREN",
  LOAD_FAILED_CHILDREN: "LOAD_FAILED_CHILDREN",

  ASYNC_LOAD_CHILDINFO: "ASYNC_LOAD_CHILDINFO",
  LOADING_CHILDINFO: "LOADING_CHILDINFO",
  LOAD_SUCCESS_CHILDINFO: "LOAD_SUCCESS_CHILDINFO",
  LOAD_FAILED_CHILDINFO: "LOAD_FAILED_CHILDINFO",
  LOAD_HANDLED_CHILDINFO: "LOAD_HANDLED_CHILDINFO",

  ASYNC_SAVE_CHILDINFO: "ASYNC_SAVE_CHILDINFO",
  SAVING_CHILDINFO: "SAVING_CHILDINFO",
  SAVE_SUCCESS_CHILDINFO: "SAVE_SUCCESS_CHILDINFO",
  SAVE_FAILED_CHILDINFO: "SAVE_FAILED_CHILDINFO",

  SETTINGS_HANDLED: "SETTINGS_HANDLED",

  ASYNC_SAVE_PASSWORD: "ASYNC_SAVE_PASSWORD",
  SAVING_PASSWORD: "SAVING_PASSWORD",
  SAVE_SUCCESS_PASSWORD: "SAVE_SUCCESS_PASSWORD",
  SAVE_FAILED_PASSWORD: "SAVE_FAILED_PASSWORD",
  SAVE_SUCCESS_HANDLED_PASSWORD: "SAVE_SUCCESS_HANDLED_PASSWORD",
  SAVE_FAILED_HANDLED_PASSWORD: "SAVE_FAILED_HANDLED_PASSWORD",

  ASYNC_LOAD_MAINSTATS: "START_LOAD_MAINSTATS",
  LOADING_MAINSTATS: "LOADING_MAINSTATS",
  LOAD_SUCCESS_MAINSTATS: "LOAD_SUCCESS_MAINSTATS",
  LOAD_FAILED_MAINSTATS: "LOAD_FAILED_MAINSTATS",
  LOADED_MAINSTATS: "LOADED_MAINSTATS",

  ASYNC_LOAD_THREAD_MAINSTATS: "ASYNC_LOAD_THREAD_MAINSTATS",
  LOADING_THREAD_MAINSTATS: "LOADING_THREAD_MAINSTATS",
  LOAD_THREAD_MAINSTATS_SUCCESS: "LOAD_THREAD_MAINSTATS_SUCCESS",
  LOAD_THREAD_MAINSTATS_FAILED: "LOAD_THREAD_MAINSTATS_FAILED",

  ASYNC_LOAD_NURSINGTIME_MAINSTATS: "ASYNC_LOAD_NURSINGTIME_MAINSTATS",
  LOADING_NURSINGTIME_MAINSTATS: "LOADING_NURSINGTIME_MAINSTATS",
  LOAD_NURSINGTIME_MAINSTATS_SUCCESS: "LOAD_NURSINGTIME_MAINSTATS_SUCCESS",
  LOAD_NURSINGTIME_MAINSTATS_FAILED: "LOAD_NURSINGTIME_MAINSTATS_FAILED",

  ASYNC_LOAD_PERMISSIONS_MAINSTATS: "ASYNC_LOAD_PERMISSIONS_MAINSTATS",
  LOADING_PERMISSIONS_MAINSTATS: "LOADING_PERMISSIONS_MAINSTATS",
  LOAD_PERMISSIONS_MAINSTATS_SUCCESS: "LOAD_PERMISSIONS_MAINSTATS_SUCCESS",
  LOAD_PERMISSIONS_MAINSTATS_FAILED: "LOAD_PERMISSIONS_MAINSTATS_FAILED",

  ASYNC_LOAD_DF_SETTINGS: "START_LOAD_DF_SETTINGS",
  LOADING_DF_SETTINGS: "LOADING_DF_SETTINGS",
  LOAD_SUCCESS_DF_SETTINGS: "LOAD_SUCCESS_DF_SETTINGS",
  LOAD_FAILED_DF_SETTINGS: "LOAD_FAILED_DF_SETTINGS",
  LOADED_DF_SETTINGS: "LOADED_DF_SETTINGS",

  ASYNC_LOAD_PROFILEPICTURES: "START_LOAD_PROFILEPICTURES",
  LOADING_PROFILEPICTURES: "LOADING_PROFILEPICTURES",
  LOAD_SUCCESS_PROFILEPICTURES: "LOAD_SUCCESS_PROFILEPICTURES",
  LOAD_FAILED_PROFILEPICTURES: "LOAD_FAILED_PROFILEPICTURES",

  // NEW MESSAGE LOAD
  ASYNC_LOAD_MESSAGE_DATA: "ASYNC_LOAD_MESSAGE_DATA",
  LOADING_MESSAGE_DATA: "LOADING_MESSAGE_DATA",
  MESSAGE_DATA_LOAD_OK: "MESSAGE_DATA_LOAD_OK",
  MESSAGE_DATA_LOAD_FAILED: "MESSAGE_DATA_LOAD_FAILED",
  ////////////////////////////////////////////////////////////////

  // Messaging
  ASYNC_LOAD_MESSAGES: "START_LOAD_MESSAGES",
  LOADING_MESSAGES: "LOADING_MESSAGES",
  LOAD_MESSAGES_SUCCESS: "LOAD_MESSAGES_SUCCESS",
  LOAD_MESSAGES_FAILED: "LOAD_MESSAGES_FAILED",

  UPDATE_DO_NOT_LOAD_INBOX_VARIABLE: "UPDATE_DO_NOT_LOAD_INBOX_VARIABLE",
  MARK_THREAD_READ_LOCALLY: "MARK_THREAD_READ_LOCALLY",

  ASYNC_DELETE_MESSAGE_THREAD: "ASYNC_DELETE_MESSAGE_THREAD",
  DELETE_MESSAGE_THREAD_LOADING: "DELETE_MESSAGE_THREAD_LOADING",
  DELETE_MESSAGE_THREAD_SUCCESS: "DELETE_MESSAGE_THREAD_SUCCESS",
  DELETE_MESSAGE_THREAD_FAILED: "DELETE_MESSAGE_THREAD_FAILED",

  ASYNC_LOAD_MESSAGE_THREAD: "ASYNC_LOAD_MESSAGE_THREAD",
  LOADING_MESSAGE_THREAD: "LOADING_MESSAGE_THREAD",
  LOAD_MESSAGE_THREAD_SUCCESS: "LOAD_MESSAGE_THREAD_SUCCESS",
  LOAD_MESSAGE_THREAD_FAILED: "LOAD_MESSAGE_THREAD_FAILED",

  ASYNC_SET_THREAD_READ: "START_SET_THREAD_READ",
  WAITING_SET_THREAD_READ: "WAITING_SET_THREAD_READ",
  SET_THREAD_READ_SUCCESS: "SET_THREAD_READ_SUCCESS",
  SET_THREAD_READ_FAILED: "SET_THREAD_READ_FAILED",

  ASYNC_SET_ALL_MESSAGES_READ: "ASYNC_SET_ALL_MESSAGES_READ",
  LOADING_SET_ALL_MESSAGES_READ: "LOADING_SET_ALL_MESSAGES_READ",
  SET_ALL_MESSAGES_READ_SUCCESS: "SET_ALL_MESSAGES_READ_SUCCESS",
  SET_ALL_MESSAGES_READ_FAILED: "SET_ALL_MESSAGES_READ_FAILED",

  ASYNC_REPLYTO_MASS_THREAD: "ASYNC_REPLYTO_MASS_THREAD",
  WAITING_REPLYTO_MASS_THREAD: "WAITING_REPLYTO_MASS_THREAD",
  REPLYTO_MASS_THREAD_SUCCESS: "REPLYTO_MASS_THREAD_SUCCESS",
  REPLYTO_MASS_THREAD_FAILED: "REPLYTO_MASS_THREAD_FAILED",

  ASYNC_REPLYTO_NONMASS_THREAD: "ASYNC_REPLYTO_NONMASS_THREAD",
  WAITING_REPLYTO_NONMASS_THREAD: "WAITING_REPLYTO_NONMASS_THREAD",
  REPLYTO_NONMASS_THREAD_SUCCESS: "REPLYTO_NONMASS_THREAD_SUCCESS",
  REPLYTO_NONMASS_THREAD_FAILED: "REPLYTO_NONMASS_THREAD_FAILED",

  ASYNC_LOAD_POTENTIAL_RECIPIENTS: "ASYNC_LOAD_POTENTIAL_RECIPIENTS",
  WAITING_LOAD_POTENTIAL_RECIPIENTS: "WAITING_LOAD_POTENTIAL_RECIPIENTS",
  LOAD_POTENTIAL_RECIPIENTS_SUCCESS: "LOAD_POTENTIAL_RECIPIENTS_SUCCESS",
  LOAD_POTENTIAL_RECIPIENTS_FAILED: "LOAD_POTENTIAL_RECIPIENTS_FAILED",

  ASYNC_POST_NEW_MESSAGE: "ASYNC_POST_NEW_MESSAGE",
  WAITING_POST_NEW_MESSAGE: "WAITING_POST_NEW_MESSAGE",
  POST_NEW_MESSAGE_SUCCESS: "POST_NEW_MESSAGE_SUCCESS",
  POST_NEW_MESSAGE_FAILED: "POST_NEW_MESSAGE_FAILED",
  RESET_POST_NEW_MESSAGE: "RESET_POST_NEW_MESSAGE",

  UPDATE_OM_SCROLL_ASSISTANT: "UPDATE_OM_SCROLL_ASSISTANT",

  CLEAR_LOAD_THREAD_DATA: "CLEAR_LOAD_THREAD_DATA",

  ASYNC_LOAD_PREVIEW_PICTURE: "ASYNC_LOAD_PREVIEW_PICTURE",
  LOADING_PREVIEW_PICTURE: "LOADING_PREVIEW_PICTURE",
  LOAD_PREVIEW_PICTURE_SUCCESS: "LOAD_PREVIEW_PICTURE_SUCCESS",
  LOAD_PREVIEW_PICTURE_FAILED: "LOAD_PREVIEW_PICTURE_FAILED",

  ASYNC_LOAD_FULL_IMAGE: "ASYNC_LOAD_FULL_IMAGE",
  LOADING_FULL_IMAGE: "LOADING_FULL_IMAGE",
  LOAD_FULL_IMAGE_SUCCESS: "LOAD_FULL_IMAGE_SUCCESS",
  LOAD_FULL_IMAGE_FAILED: "LOAD_FULL_IMAGE_FAILED",

  // Bulletinboard
  ASYNC_LOAD_BULLETINBOARD: "START_LOAD_BULLETINGBOARDS",
  LOADING_BULLETINBOARDS: "LOADING_BULLETINBOARDS",
  LOAD_BULLETINBOARDS_SUCCESS: "LOAD_BULLETINBOARDS_SUCCESS",
  LOAD_BULLETINBOARDS_FAILED: "LOAD_BULLETINBOARDS_FAILED",

  // Password recovery
  ASYNC_PASSWORD_RESET: "ASYNC_PASSWORD_RESET",
  LOADING_PASSWORD_RESET: "LOADING_PASSWORD_RESET",
  PASSWORD_RESET_SUCCESS: "PASSWORD_RESET_SUCCESS",
  PASSWORD_RESET_FAILED: "PASSWORD_RESET_FAILED",
  PASSWORD_RESET_NEUTRAL: "PASSWORD_RESET_NEUTRAL",
  // Order username and password
  ASYNC_ORDER_USERNAME: "ASYNC_ORDER_USERNAME",
  LOADING_ORDER_USERNAME: "LOADING_ORDER_USERNAME",
  ORDER_USERNAME_SUCCESS: "ORDER_USERNAME_SUCCESS",
  ORDER_USERNAME_FAILED: "ORDER_USERNAME_FAILED",
  ORDER_USERNAME_RESET: "ORDER_USERNAME_RESET",

  // Snackbar
  OPEN_POST_NEW_MESSAGE_SNACKBAR: "OPEN_POST_NEW_MESSAGE_SNACKBAR",
  CLOSE_POST_NEW_MESSAGE_SNACKBAR: "CLOSE_POST_NEW_MESSAGE_SNACKBAR",
  // 401 logout notification
  //LOGOUT_401_NOTIFICATION:                    'LOGOUT_401_NOTIFICATION'

  SHOW_NOTIFICATION: "SHOW_NOTIFICATION",
  RESET_AUTOLOGOUT_TIMER: "RESET_AUTOLOGOUT_TIMER",
  // GrowthFolder
  ASYNC_LOAD_FOLDERS: "ASYNC_LOAD_FOLDERS",
  LOADING_FOLDERS: "LOADING_FOLDERS",
  LOAD_FOLDERS_SUCCESS: "LOAD_FOLDERS_SUCCESS",
  LOAD_FOLDERS_FAILED: "LOAD_FOLDERS_FAILED",
  LOAD_FOLDERS_LOADED: "LOAD_FOLDERS_LOADED",

  ASYNC_LOAD_IMAGES: "ASYNC_LOAD_IMAGES",
  LOADING_IMAGES: "LOADING_IMAGES",
  LOAD_IMAGES_SUCCESS: "LOAD_IMAGES_SUCCESS",
  LOAD_IMAGES_FAILED: "LOAD_IMAGES_FAILED",
  LOAD_IMAGES_LOADED: "LOAD_IMAGES_LOADED",

  ASYNC_LOAD_IMAGE: "ASYNC_LOAD_IMAGE",
  LOADING_IMAGE: "LOADING IMAGE",
  LOAD_IMAGE_SUCCESS: "LOAD_IMAGE_SUCCESS",
  LOAD_IMAGE_FAILED: "LOAD_IMAGE_FAILED",
  LOAD_IMAGE_LOADED: "LOAD_IMAGE_LOADED",
  APP_RESET: "APP_RESET",

  //TaskFolder
  ASYNC_LOAD_TASK_THUMBNAIL: "ASYNC_LOAD_TASK_THUMBNAIL",
  LOADING_TASK_THUMBNAIL: "LOADING_TASK_THUMBNAIL",
  LOAD_TASK_THUMBNAIL_SUCCESS: "LOAD_TASK_THUMBNAIL_SUCCESS",
  LOAD_TASK_THUMBNAIL_FAILED: "LOAD_TASK_THUMBNAIL_FAILED",

  //Municipal permission questions

  ASYNC_LOAD_CHILD_PERMPERMISSIONS: "ASYNC_LOAD_CHILD_PERMPERMISSIONS",
  LOADING_CHILD_PERMPERMISSIONS: "LOADING_CHILD_PERMPERMISSIONS",
  LOAD_CHILD_PERMPERMISSIONS_SUCCESS: "LOAD_CHILD_PERMPERMISSIONS_SUCCESS",
  LOAD_CHILD_PERMPERMISSIONS_FAILED: "LOAD_CHILD_PERMPERMISSIONS_FAILED",
  CLEAN_LOAD_CHILD_PERMPERMISSIONS: "CLEAN_LOAD_CHILD_PERMPERMISSIONS",

  ASYNC_POST_CHILD_PERMPERMISSIONS: "ASYNC_POST_CHILD_PERMPERMISSIONS",
  LOADING_POST_CHILD_PERMPERMISSIONS: "LOADING_POST_CHILD_PERMPERMISSIONS",
  POST_CHILD_PERMPERMISSIONS_SUCCESS: "POST_CHILD_PERMPERMISSIONS_SUCCESS",
  POST_CHILD_PERMPERMISSIONS_FAILED: "POST_CHILD_PERMPERMISSIONS_FAILED",
  CLEAN_POST_CHILD_PERMPERMISSIONS: "CLEAN_POST_CHILD_PERMPERMISSIONS",

  ASYNC_LOAD_GUARDIAN_CONSENTS: "ASYNC_LOAD_GUARDIAN_CONSENTS",
  LOADING_GUARDIAN_CONSENTS: "LOADING_GUARDIAN_CONSENTS",
  LOAD_GUARDIAN_CONSENTS_SUCCESS: "LOAD_GUARDIAN_CONSENTS_SUCCESS",
  LOAD_GUARDIAN_CONSENTS_FAILED: "LOAD_GUARDIAN_CONSENTS_FAILED",
  CLEAN_LOAD_GUARDIAN_CONSENTS: "CLEAN_LOAD_GUARDIAN_CONSENTS",

  ASYNC_POST_GUARDIAN_CONSENTS: "ASYNC_POST_GUARDIAN_CONSENTS",
  LOADING_POST_GUARDIAN_CONSENTS: "LOADING_POST_GUARDIAN_CONSENTS",
  POST_GUARDIAN_CONSENTS_SUCCESS: "POST_GUARDIAN_CONSENTS_SUCCESS",
  POST_GUARDIAN_CONSENTS_FAILED: "POST_GUARDIAN_CONSENTS_FAILED",
  CLEAN_POST_GUARDIAN_CONSENTS: "CLEAN_POST_GUARDIAN_CONSENTS",

  //Kyselyt
  ASYNC_LOAD_PERMISSION_QUERIES: "ASYNC_LOAD_PERMISSION_QUERIES",
  LOADING_PERMISSION_QUERIES: "LOADING_PERMISSION_QUERIES",
  LOAD_PERMISSION_QUERIES_SUCCESS: "LOAD_PERMISSION_QUERIES_SUCCESS",
  LOAD_PERMISSION_QUERIES_FAILED: "LOAD_PERMISSION_QUERIES_FAILED",

  ASYNC_LOAD_SINGLE_PERMISSION_QUERY: "ASYNC_LOAD_SINGLE_PERMISSION_QUERY",
  LOADING_SINGLE_PERMISSION_QUERY: "LOADING_SINGLE_PERMISSION_QUERY",
  LOAD_SINGLE_PERMISSION_QUERY_SUCCESS: "LOAD_SINGLE_PERMISSION_QUERY_SUCCESS",
  LOAD_SINGLE_PERMISSION_QUERY_FAILED: "LOAD_SINGLE_PERMISSION_QUERY_FAILED",

  ASYNC_POST_SINGLE_PERMISSION_QUERY: "ASYNC_POST_SINGLE_PERMISSION_QUERY",
  LOADING_POST_SINGLE_PERMISSION_QUERY: "LOADING_POST_SINGLE_PERMISSION_QUERY",
  POST_SINGLE_PERMISSION_QUERY_SUCCESS: "POST_SINGLE_PERMISSION_QUERY_SUCCESS",
  POST_SINGLE_PERMISSION_QUERY_FAILED: "POST_SINGLE_PERMISSION_QUERY_FAILED",

  //CommuneSettings
  ASYNC_LOADCOMMUNESETTINGS: "ASYNC_LOADCOMMUNESETTINGS",
  LOADING_COMMUNESETTINGS: "LOADING_COMMUNESETTINGS",
  LOAD_COMMUNESETTINGS_SUCCESS: "LOAD_COMMUNESETTINGS_SUCCESS",
  LOAD_COMMUNESETTINGS_FAILED: "LOAD_COMMUNESETTINGS_FAILED",

  //SnackbarQueue
  REDUCE_SNACKBAR_QUEUE: "REDUCE_SNACKBAR_QUEUE",
  ADDTO_SNACKBAR_QUEUE: "ADDTO_SNACKBAR_QUEUE",

  //Backup Parent adding/modifying
  ASYNC_ADD_BACKUP_PARENT: "ASYNC_ADD_BACKUP_PARENT",
  ASYNC_UPDATE_BACKUP_PARENT: "ASYNC_UPDATE_BACKUP_PARENT",
  UPDATING_BACKUP_PARENT: "UPDATING_BACKUP_PARENT",
  UPDATE_BACKUP_PARENT_SUCCESS: "UPDATE_BACKUP_PARENT_SUCCESS",
  UPDATE_BACKUP_PARENT_FAILED: "UPDATE_BACKUP_PARENT_FAILED",
  ADDING_BACKUP_PARENT: "ADDING_BACKUP_PARENT",
  ADD_BACKUP_PARENT_SUCCESS: "ADD_BACKUP_PARENT_SUCCESS",
  ADD_BACKUP_PARENT_FAILED: "ADD_BACKUP_PARENT_FAILED",

  ASYNC_DELETE_BACKUP_PARENT: "ASYNC_DELETE_BACKUP_PARENT",
  LOADING_DELETE_BACKUP_PARENT: "LOADING_DELETE_BACKUP_PARENT",
  DELETE_BACKUP_PARENT_SUCCESS: "DELETE_BACKUP_PARENT_SUCCESS",
  DELETE_BACKUP_PARENT_FAILED: "DELETE_BACKUP_PARENT_FAILED",

  //ASYNC_LOAD_FULL_IMAGE: 'ASYNC_LOAD_FULL_IMAGE',
  //LOADING_FULL_IMAGE: 'LOADING_FULL_IMAGE',
  //LOAD_FULL_IMAGE_SUCCESS: 'LOAD_FULL_IMAGE_SUCCESS',
  //LOAD_FULL_IMAGE_FAILED: 'LOAD_FULL_IMAGE_FAILED',

  // TaskFolder
  /*ASYNC_LOAD_TASKFOLDER:                       'ASYNC_LOAD_TASKFOLDER',
  LOADING_TASKFOLDER:                          'LOADING_TASKFOLDER',
  LOAD_TASKFOLDER_SUCCESS:                     'LOAD_TASKFOLDER_SUCCESS',
  LOAD_TASKFOLDER_FAILED:                      'LOAD_TASKFOLDER_FAILED',
  LOAD_TASKFOLDER_LOADED:                      'LOAD_TASKFOLDER_LOADED',*/

  ASYNC_LOAD_VACATION_STATUS: "ASYNC_LOAD_VACATION_STATUS",
  LOADING_VACATION_STATUS: "LOADING_VACATION_STATUS",
  LOAD_VACATION_STATUS_SUCCESS: "LOAD_VACATION_STATUS_SUCCESS",
  LOAD_VACATION_STATUS_FAILED: "LOAD_VACATION_STATUS_FAILED",

  ASYNC_LOAD_DISCUSSION_TIMES: "ASYNC_LOAD_DISCUSSION_TIMES",
  LOADING_DISCUSSION_TIMES: "LOADING_DISCUSSION_TIMES",
  LOAD_DISCUSSION_TIMES_SUCCESS: "LOAD_DISCUSSION_TIMES_SUCCESS",
  LOAD_DISCUSSION_TIMES_FAILED: "LOAD_DISCUSSION_TIMES_FAILED",

  ASYNC_LOAD_AVAILABLE_DISC_SLOTS: "ASYNC_LOAD_AVAILABLE_DISC_SLOTS",
  LOADING_AVAILABLE_DISC_SLOTS: "LOADING_AVAILABLE_DISC_SLOTS",
  LOAD_AVAILABLE_DISC_SLOTS_SUCCESS: "LOAD_AVAILABLE_DISC_SLOTS_SUCCESS",
  LOAD_AVAILABLE_DISC_SLOTS_FAILED: "LOAD_AVAILABLE_DISC_SLOTS_FAILED",

  ASYNC_SAVE_DISCUSSION_BOOKING: "ASYNC_SAVE_DISCUSSION_BOOKING",
  SAVING_DISCUSSION_BOOKING: "SAVING_DISCUSSION_BOOKING",
  SAVE_DISCUSSION_BOOKING_SUCCESS: "SAVE_DISCUSSION_BOOKING_SUCCESS",
  SAVE_DISCUSSION_BOOKING_FAILED: "SAVE_DISCUSSION_BOOKING_FAILED",

  ASYNC_DELETE_DISCUSSION_BOOKING: "ASYNC_DELETE_DISCUSSION_BOOKING",
  DELETING_DISCUSSION_BOOKING: "DELETING_DISCUSSION_BOOKING",
  DELETE_DISCUSSION_BOOKING_SUCCESS: "DELETE_DISCUSSION_BOOKING_SUCCESS",
  DELETE_DISCUSSION_BOOKING_FAILED: "DELETE_DISCUSSION_BOOKING_FAILED",

  ASYNC_EDIT_DISCUSSION_BOOKING: "ASYNC_EDIT_DISCUSSION_BOOKING",
  EDITING_DISCUSSION_BOOKING: "EDITING_DISCUSSION_BOOKING",
  EDIT_DISCUSSION_BOOKING_SUCCESS: "EDIT_DISCUSSION_BOOKING_SUCCESS",
  EDIT_DISCUSSION_BOOKING_FAILED: "EDIT_DISCUSSION_BOOKING_FAILED",

  ASYNC_LOAD_ONE_CHILD_DISCUSSIONS: "ASYNC_LOAD_ONE_CHILD_DISCUSSIONS",
  LOADING_ONE_CHILD_DISCUSSIONS: "LOADING_ONE_CHILD_DISCUSSIONS",
  LOAD_ONE_CHILD_DISCUSSIONS_SUCCESS: "LOAD_ONE_CHILD_DISCUSSIONS_SUCCESS",
  LOAD_ONE_CHILD_DISCUSSIONS_FAILED: "LOAD_ONE_CHILD_DISCUSSIONS_FAILED",

  ASYNC_GET_PARENTS_FOR_DISCUSSION_EDITING:
    "ASYNC_GET_PARENTS_FOR_DISCUSSION_EDITING",
  LOADING_PARENTS_FOR_DISCUSSION_EDITING:
    "LOADING_PARENTS_FOR_DISCUSSION_EDITING",
  GET_PARENTS_FOR_DISCUSSION_EDITING_SUCCESS:
    "GET_PARENTS_FOR_DISCUSSION_EDITING_SUCCESS",
  GET_PARENTS_FOR_DISCUSSION_EDITING_FAILED:
    "GET_PARENTS_FOR_DISCUSSION_EDITING_FAILED",

  ASYNC_GET_DISCUSSIONNOTIFICATION_STATS:
    "ASYNC_GET_DISCUSSIONNOTIFICATION_STATS",
  LOADING_DISCUSSIONNOTIFICATION_STATS: "LOADING_DISCUSSIONNOTIFICATION_STATS",
  GET_DISCUSSIONNOTIFICATION_STATS_SUCCESS:
    "GET_DISCUSSIONNOTIFICATION_STATS_SUCCESS",
  GET_DISCUSSIONNOTIFICATION_STATS_FAILED:
    "GET_DISCUSSIONNOTIFICATION_STATS_FAILED",

  ASYNC_GET_DISCUSSION_TIMESELECTIONSTATUS:
    "ASYNC_GET_DISCUSSION_TIMESELECTIONSTATUS",
  LOADING_DISCUSSION_TIMESELECTIONSTATUS:
    "LOADING_DISCUSSION_TIMESELECTIONSTATUS",
  GET_DISCUSSION_TIMESELECTIONSTATUS_SUCCESS:
    "GET_DISCUSSION_TIMESELECTIONSTATUS_SUCCESS",
  GET_DISCUSSION_TIMESELECTIONSTATUS_FAILED:
    "GET_DISCUSSION_TIMESELECTIONSTATUS_FAILED",

  ASYNC_SAVE_DISCUSSION_TIMESELECTIONSTATUS:
    "ASYNC_SAVE_DISCUSSION_TIMESELECTIONSTATUS",
  SAVING_DISCUSSION_TIMESELECTIONSTATUS:
    "SAVING_DISCUSSION_TIMESELECTIONSTATUS",
  SAVE_DISCUSSION_TIMESELECTIONSTATUS_SUCCESS:
    "SAVE_DISCUSSION_TIMESELECTIONSTATUS_SUCCESS",
  SAVE_DISCUSSION_TIMESELECTIONSTATUS_FAILED:
    "SAVE_DISCUSSION_TIMESELECTIONSTATUS_FAILED",
  SAVE_DISCUSSION_TIMESELECTIONSTATUS_CONFLICT:
    "SAVE_DISCUSSION_TIMESELECTIONSTATUS_CONFLICT",

  ASYNC_DELETE_DISCUSSION_TIMESELECTIONSTATUSES:
    "ASYNC_DELETE_DISCUSSION_TIMESELECTIONSTATUSES",
  DELETING_DISCUSSION_TIMESELECTIONSTATUSES:
    "DELETING_DISCUSSION_TIMESELECTIONSTATUSES",
  DELETE_DISCUSSION_TIMESELECTIONSTATUSES_SUCCESS:
    "DELETE_DISCUSSION_TIMESELECTIONSTATUSES_SUCCESS",
  DELETE_DISCUSSION_TIMESELECTIONSTATUSES_FAILED:
    "DELETE_DISCUSSION_TIMESELECTIONSTATUSES_FAILED",

  SET_MAINNAV_OPEN: "SET_MAINNAV_OPEN",

  SAVING_PERSON_SETTINGS: "SAVING_PERSON_SETTINGS",
  ASYNC_SAVE_PERSON_SETTINGS: "ASYNC_SAVE_PERSON_SETTINGS",
  SAVE_PERSON_SETTINGS_SUCCESS: "SAVE_PERSON_SETTINGS_SUCCESS",
  SAVE_PERSON_SETTINGS_FAILED: "SAVE_PERSON_SETTINGS_FAILED",

  ASYNC_LOAD_PERSON_SETTINGS: "ASYNC_LOAD_PERSON_SETTINGS",
  LOADING_PERSON_SETTINGS: "LOADING_PERSON_SETTINGS",
  LOAD_PERSON_SETTINGS_SUCCESS: "LOAD_PERSON_SETTINGS_SUCCESS",
  LOAD_PERSON_SETTINGS_FAILED: "LOAD_PERSON_SETTINGS_FAILED",

  ASYNC_DOWNLOAD_FILE: "ASYNC_DOWNLOAD_FILE",
  DOWNLOADING_FILE: "DOWNLOADING_FILE",
  DOWNLOAD_FILE_SUCCESS: "DOWNLOAD_FILE_SUCCESS",
  DOWNLOAD_FILE_FAILED: "DOWNLOAD_FILE_FAILED",
};

// Define actions here or under specific reducer
export const fetching = () => ({ type: "FETCHING" });
export const success = (response) => ({ type: "SUCCESS", response });

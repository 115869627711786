import React, { useState } from "react";
import GuideAccordion from "../GuideTools/GuideAccordion";
import KeywordButton from "../GuideTools/KeywordButton";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import Divider from "@mui/material/Divider";
import Languages from "../../../translations";

const CaretimeCalendarGuideContent = () => {
  const [expanded, setExpanded] = useState("");

  const handlePanelExpand = (panelId) => (event, expanded) => {
    setExpanded(expanded ? panelId : false);
    //window.scrollTo({top: 100, behavior: 'smooth'});

    //isExpanded.scrollIntoView()
    setTimeout(window.scrollTo(0, panelId.offsetTop - 100), 600); //moves the newly opened panel up offeset by
  };

  const expandAcco = (panelId) => () => {
    setExpanded(panelId);
  };

  return (
    <>
      <div className="guide-modal-keywords-container">
        <h6 className="guide-modal-keyword-header">
          <VpnKeyIcon color="primary" /> {Languages.keywords}:{" "}
        </h6>
        <KeywordButton
          handleClick={expandAcco("id-guide-add-booking")}
          buttonText={Languages.addBookingKeywordButton}
          panelId="id-guide-add-booking"
          buttonVariant="outlined"
        />
        <KeywordButton
          handleClick={expandAcco("id-guide-edit-booking")}
          buttonText={Languages.editBookingKeywordButton}
          panelId="id-guide-edit-booking"
          buttonVariant="outlined"
        />
        <KeywordButton
          handleClick={expandAcco("id-guide-absent-booking")}
          buttonText={Languages.absentBookingKeywordButton}
          panelId="id-guide-absent-booking"
          buttonVariant="outlined"
        />
        <KeywordButton
          handleClick={expandAcco("id-guide-default-booking-time")}
          buttonText={Languages.defaultTimeBookingKeywordButton}
          panelId="id-guide-default-booking-time"
          buttonVariant="outlined"
        />
        <KeywordButton
          handleClick={expandAcco("id-guide-booking-icons")}
          buttonText={Languages.bookingIconsKeywordButton}
          panelId="id-guide-booking-icons"
          buttonVariant="outlined"
        />
        <KeywordButton
          handleClick={expandAcco("id-guide-booking-care-time-left")}
          buttonText={Languages.careTimeLeftKeywordButton}
          panelId="id-guide-booking-care-time-left"
          buttonVariant="outlined"
        />
        <KeywordButton
          handleClick={expandAcco("id-guide-booking-invoice-data")}
          buttonText={Languages.invoiceDataKeywordButton}
          panelId="id-guide-booking-invoice-data"
          buttonVariant="outlined"
        />
        <KeywordButton
          handleClick={expandAcco("id-guide-booking-faq")}
          buttonText={Languages.guideFAQKeywordButton}
          panelId="id-guide-booking-faq"
          buttonVariant="outlined"
        />
        <Divider variant="middle" />
        <br />
      </div>
      <div className="booking-guide-content-conteiner">
        <GuideAccordion
          accordionTitle={Languages.addBookingAccordionTitle}
          panelId="id-guide-add-booking"
          handlePanelExpand={handlePanelExpand}
          isExpanded={expanded}
          accordionDetails="ADD_BOOKING_ACCORDION_TEXT2"
        />
        <GuideAccordion
          accordionTitle={Languages.editBookingAccordionTitle}
          panelId="id-guide-edit-booking"
          handlePanelExpand={handlePanelExpand}
          isExpanded={expanded}
          accordionDetails="EDIT_BOOKING_ACCORDION_TEXT2"
        />
        <GuideAccordion
          accordionTitle={Languages.absentAccordionTitle}
          accordionTitleDescription={Languages.bookingAbsentAccordionDesc}
          handlePanelExpand={handlePanelExpand}
          panelId="id-guide-absent-booking"
          isExpanded={expanded}
          accordionDetails="ABSENT_BOOKING_ACCORDION_TEXT2"
        />
        <GuideAccordion
          accordionTitle={"Asetukset"}
          panelId="id-guide-default-booking-time"
          handlePanelExpand={handlePanelExpand}
          isExpanded={expanded}
          accordionDetails="DEFAULT_BOOKING_TIME_ACCORDION_TEXT2"
        />

        <GuideAccordion
          accordionTitle={Languages.careTimeLeftAccordionTitle}
          handlePanelExpand={handlePanelExpand}
          panelId="id-guide-booking-care-time-left"
          isExpanded={expanded}
          accordionDetails="AVAILABLE_CARE_TIME_ACCORDION_TEXT2"
        />
        <GuideAccordion
          accordionTitle={Languages.invoiceDataAccordionTitle}
          handlePanelExpand={handlePanelExpand}
          panelId="id-guide-booking-invoice-data"
          isExpanded={expanded}
          accordionDetails="INVOICE_DATA_ACCORDION_TEXT2"
        />
        <GuideAccordion
          accordionTitle={Languages.accordionTitleFAQ}
          accordionTitleDescription={Languages.accordionDescFAQ}
          handlePanelExpand={handlePanelExpand}
          panelId="id-guide-booking-faq"
          isExpanded={expanded}
          accordionDetails="FAQ_TEXT"
        />
      </div>
    </>
  );
};

export default CaretimeCalendarGuideContent;

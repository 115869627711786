import React, {useState} from "react";
import { useAuth } from '../../../Auth/AuthProvider';
import { Typography } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import Languages from "../../../translations";
import Box from '@mui/material/Box';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import AccessibilityContent from "../../../Components/AccessibilityContent/AccessibilityContent";
import CookieInfoDetailedContent from "../../../Components/CookieNote/CookieInfoDetailedContent";
import TermsOfUseContent from "../../../Components/TermsOfUseContent/TermsOfUseContent";


const styles = {
  root: {
    marginTop: '20px',
  }
}

const AccessibilityReport = ({changeAccessibilityReport, isOpen}) => {
  return(
    <Dialog aria-label={"modal-" + Languages.accAccessibilityStatement} fullScreen open={isOpen} onClose={changeAccessibilityReport} scroll={"paper"}>
      <DialogContent style={{backgroundColor: "#FFFFFF"}}>
        <Button style={{color: "#4377BA"}} aria-label={Languages.generalClose + "-modal-" + Languages.accAccessibilityStatement} onClick={changeAccessibilityReport}>{Languages.generalClose}</Button>
        <AccessibilityContent/>
        <Button aria-label={Languages.generalClose + "-modal-" + Languages.accAccessibilityStatement} style={{color: "#4377BA"}} onClick={changeAccessibilityReport}>{Languages.generalClose}</Button>
      </DialogContent>
    </Dialog>
  )
}

const CookieDialog = ({setCookieDetails, isOpen}) => {
  return(
    <Dialog aria-label={"modal-" + Languages.cookies} fullScreen open={isOpen} onClose={setCookieDetails} scroll={"paper"}>
      <DialogContent style={{backgroundColor: "#FFFFFF"}}>
        <Button aria-label={Languages.generalClose + "-modal-" + Languages.cookies} style={{color: "#4377BA"}} onClick={setCookieDetails}>{Languages.generalClose}</Button>
        <CookieInfoDetailedContent/>
        <Button aria-label={Languages.generalClose + "-modal-" + Languages.cookies} style={{color: "#4377BA"}} onClick={setCookieDetails}>{Languages.generalClose}</Button>
      </DialogContent>
    </Dialog>
  )
}

const TermsOfUseDialog = ({toggleTermsOfUse, isOpen}) => {
  return(
    <Dialog aria-label={"modal-" + Languages.termsOfUse} fullScreen open={isOpen} onClose={toggleTermsOfUse} scroll={"paper"}>
      <DialogContent style={{backgroundColor: "#FFFFFF"}}>
        <Button aria-label={Languages.generalClose + "-modal-" + Languages.termsOfUse} style={{color: "#4377BA"}} onClick={toggleTermsOfUse}>{Languages.generalClose}</Button>
        <TermsOfUseContent/>
        <Button aria-label={Languages.generalClose + "-modal-" + Languages.termsOfUse} style={{color: "#4377BA"}} onClick={toggleTermsOfUse}>{Languages.generalClose}</Button>
      </DialogContent>
    </Dialog>
  )
}

const LegalLinks = () => {
  const { loginSettings } = useAuth();
  const registryDescriptionLink = loginSettings ? loginSettings.RegistryDescriptions : null;
  const usageManualLink = loginSettings ? loginSettings.ManualInstructions : null;

  const [accessibilityReportOn, setAccessibilityReportOn] = useState(false);
  const [cookieDetailsOn, setCookieDetailsOn] = useState(false);
  const [termsOfUseOn, setTermsOfUseOn] = useState(false);

  const changeAccessibilityReport = () => {
    setAccessibilityReportOn(!accessibilityReportOn);
  }

  const setCookieDetails = () => {
    setCookieDetailsOn(!cookieDetailsOn);
  }

  const toggleTermsOfUse = () => {
    setTermsOfUseOn(!termsOfUseOn);
  }

  return (
    <React.Fragment>
      <div>
        <br/>
        {usageManualLink &&
          <Button aria-label={Languages.userManual} id="test_Login_UsersManualLnk" style={{color: "#045980"}} size="small" variant="text" color="primary" href={usageManualLink} target="_blank" rel="noopener noreferrer">{Languages.userManual}</Button>
        }
        {registryDescriptionLink &&
          <Button aria-label={Languages.privacystatement} id="test_Login_PrivacyStatementLnk" style={{color: "#045980"}} size="small" variant="text" color="primary" href={registryDescriptionLink} target="_blank" rel="noopener noreferrer">{Languages.privacystatement}</Button>
        }
        <Button 
          aria-label={Languages.cookies} 
          id="test_LegalLinks_cookies" 
          style={{color: "#045980"}} 
          size="small" 
          variant="text" 
          color="primary" 
          onClick={setCookieDetails}>{Languages.cookies}</Button>
        {/*<Button aria-label={Languages.termsOfUse} id="test_Login_TermsOfUseLnk" style={{color: "#045980"}} size="small" variant="text" color="primary" href={"https://ohjeet.daisymanager.fi/DaisyFamily/Daisy_Family_Käyttöehdot.pdf"} target="_blank" rel="noopener noreferrer">{Languages.termsOfUse}</Button>*/}
        <Button aria-label={Languages.accAccessibilityStatement} id="test_Login_AccessibilityStatement" style={{color: "#045980"}} size="small" variant="text" color="primary" onClick={changeAccessibilityReport}>{Languages.accAccessibilityStatement}</Button>
        <Button
          aria-label={Languages.termsOfUse}
          id="test_Login_TermsOfUseLnk"
          style={{color: "#045980"}} 
          size="small" 
          variant="text" 
          color="primary" 
          onClick={toggleTermsOfUse}>{Languages.termsOfUse}
        </Button>
      </div>
      
      <AccessibilityReport changeAccessibilityReport={changeAccessibilityReport} isOpen={accessibilityReportOn}/>
      <CookieDialog setCookieDetails={setCookieDetails} isOpen={cookieDetailsOn}/>
      <TermsOfUseDialog toggleTermsOfUse={toggleTermsOfUse} isOpen={termsOfUseOn}/>

    </React.Fragment>
  );
}

export default withStyles(styles)(LegalLinks);
import React from 'react';
import CircularProgress       from "@mui/material/CircularProgress";
import Backdrop               from '@mui/material/Backdrop';

const LoadingIndicator = ({ show }) => {
  if (show) {
    return (
      <div className="BackDrop">
        <Backdrop open={show}>
          <CircularProgress />
        </Backdrop>
      </div>)
  }
  return (null);
}

export default LoadingIndicator;
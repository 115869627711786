import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useState, useEffect } from "react";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import moment from "moment";
import Chip from "@mui/material/Chip";
import ChildProfilePicture from "../../../ChildProfilePicture";

import CloseIcon from "@mui/icons-material/Close";

import Avatar from "@mui/material/Avatar";

import Stack from "@mui/material/Stack";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import Languages from "../../../../translations";

import { useStateValue } from "../../../../State";
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import SickIcon from "@mui/icons-material/Sick";
import Slider from "@mui/material/Slider";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CheckIcon from "@mui/icons-material/Check";

import NightShelterIcon from "@mui/icons-material/NightShelter";
import SchoolIcon from "@mui/icons-material/School";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { types } from "../../../../Reducers/actionTypes";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";
const CTBDayDialog3 = ({
  day,
  modalOpen,
  handleClick,
  names,
  handleFocusDay,
  showWeekEnds,
  index,
  handleDialogIndex,
  handleDialogIndexChange,
  maxIndex,
}) => {
  const { state, actions } = useStateValue();
  const [personAbsent, setPersonAbsent] = React.useState([]);
  const careTimes = state.caretimes.testList;
  const [savingAbsent, setSavingAbsent] = React.useState(false);
  const [absentSaved, setAbsentSaved] = useState(false);
  const [absenting, setAbsenting] = useState(false);
  const [testToggle, setTestToggle] = React.useState(false);
  useEffect(() => {
    if (modalOpen === false) {
      setPersonAbsent([]);
    }
    handleDayChange();
  }, [modalOpen, index]);

  const handleDayChange = () => {
    setAbsentSaved(false);
    setSavingAbsent(false);
    setAbsenting(false);
    setDeleteData({});
    setPersonAbsent([]);
    setDeleting(false);
    setDeleted(false);
    setSavingDelete(false);
    setTestToggle(!testToggle);
  };

  useEffect(() => {
    switch (state.caretimes.Absent.state) {
      case types.CARETIMES_ABSENT_SAVED_OK:
        setSavingAbsent(false);
        setAbsentSaved(true);
        setTestToggle(!testToggle);

        break;
      case types.SAVING_CARETIMES_ABSENT:
        setAbsentSaved(false);
        setSavingAbsent(true);

        break;
      case types.CARETIMES_ABSENT_SAVED_FAILED:
        setSavingAbsent(false);
        setAbsentSaved(true);
        setTestToggle(!testToggle);
        break;
      default:
        break;
    }
  }, [state.caretimes.Absent.state]);

  const handlePersonAbsent = (data) => {
    //console.log(data);
    if (
      personAbsent.some((absentData) => absentData.PersonId === data.PersonId)
    ) {
      var UpdatedpersonAbsent = personAbsent.filter(
        (absentData) => absentData.PersonId !== data.PersonId
      );
      setPersonAbsent(UpdatedpersonAbsent);
    } else {
      personAbsent.push(data);
    }
  };

  const updateAbsentReason = (data) => {
    const newState = personAbsent.map((obj) => {
      if (obj.PersonId === data.PersonId) {
        return { ...obj, AbsentReason: data.AbsentReason };
      }
      return obj;
    });

    setPersonAbsent(newState);
  };

  const handleAbsenting = () => {
    setAbsenting(!absenting);
  };

  const saveAbsents = () => {
    //console.log("testtestets");
    handleFocusDay(day.Date);
    actions.triggerSaveCareTimesAbsent({
      PersonList: personAbsent,
      Dates: [day.Date],
      DeleteOld: false,
      EveningPreschool: false,
      Holidays: [],
    });
    setAbsenting(true);
  };

  const handleNavclick = (index) => {
    handleDayChange();
    handleDialogIndexChange(index);
  };

  const [deleting, setDeleting] = React.useState(false);

  const [savingDelete, setSavingDelete] = React.useState(false);
  const [deleted, setDeleted] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState({});
  const handleDeletePopUp = (deletedata) => {
    setDeleteData(deletedata);
    setDeleted(false);
    setSavingDelete(false);
    setDeleting(!deleting);
  };

  const handleDeleteClose = () => {
    setDeleted(false);
    setSavingDelete(false);
    handleDeletePopUp();
  };

  useEffect(() => {
    switch (state.caretimes.Deleted.state) {
      case types.CARETIMES_DELETED_OK:
        //console.log("deleted ok");
        setDeleteData({});
        setSavingDelete(false);
        setDeleted(true);
        break;
      case types.DELETING_CARETIMES:
        //console.log("deleting");
        setSavingDelete(true);
        break;
      case types.CARETIMES_DELETED_FAILED:
        //console.log("deleted faield");
        setSavingDelete(false);
        setDeleteData({});
        setDeleted(true);
        break;
      default:
        break;
    }
  }, [state.caretimes.Deleted.state]);

  const deleteCareTime = () => {
    actions.triggerDeleteCareTimes(deleteData);
  };
  return (
    <>
      <Dialog
        disablePadding
        disableGutters
        fullWidth
        onClose={handleClick}
        open={modalOpen}
        sx={{
          "& .MuiDialog-container .MuiDialog-paper": {
            margin: 0,
            borderRadius: 2,
            width: "96%",
            height: "100%",
          },
          width: "100%",
        }}
      >
        <DialogTitle textAlign="center" style={{ backgroundColor: "#FFFFFF" }}>
          <>
            {state.caretimes.Absent.state !== types.SAVING_CARETIMES_ABSENT && (
              <Stack direction="row" alignItems="center" sx={{ ml: "30%" }}>
                <IconButton
                  aria-label="close"
                  onClick={() => handleNavclick(-1)}
                  sx={{
                    color: "success",
                  }}
                >
                  <NavigateBeforeIcon />
                </IconButton>
                <Typography color="textSecondary" fontWeight="bold">
                  {moment(day.Date).format("ddd DD.MM.")}
                </Typography>
                <IconButton
                  aria-label="close"
                  onClick={() => handleNavclick(1)}
                  sx={{
                    color: "success",
                  }}
                >
                  <NavigateNextIcon />
                </IconButton>
              </Stack>
            )}

            {state.caretimes.Absent.state === types.SAVING_CARETIMES_ABSENT && (
              <Skeleton sx={{ bgcolor: "lightgrey", ml: "30%" }}>
                <Stack direction="row" alignItems="center" sx={{ ml: "30%" }}>
                  <IconButton
                    aria-label="close"
                    onClick={() => handleNavclick(-1)}
                    sx={{
                      color: "success",
                    }}
                  >
                    <NavigateBeforeIcon />
                  </IconButton>
                  <Typography color="textSecondary" fontWeight="bold">
                    {moment(day.Date).format("ddd DD.MM")}
                  </Typography>
                  <IconButton
                    aria-label="close"
                    onClick={() => handleNavclick(1)}
                    sx={{
                      color: "success",
                    }}
                  >
                    <NavigateNextIcon />
                  </IconButton>
                </Stack>
              </Skeleton>
            )}
          </>

          <IconButton
            aria-label="close"
            onClick={handleClick}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "success",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          dividers
          disableGutters
          disablePadding
          sx={{ padding: 0 }}
          style={{ backgroundColor: "#FFFFFF" }}
        >
          <>
            {state.caretimes.Absent.state !== types.SAVING_CARETIMES_ABSENT &&
              state.caretimes.Deleted.state !== types.DELETING_CARETIMES && (
                <>
                  {day.CareTimeTest.map((child) => (
                    <>
                      <CTBdayDialogChildRow
                        key={child.ChildId}
                        child={child}
                        isPast={day.NotSelectable}
                        isLocked={day.IsLockedDate}
                        date={day.Date}
                        handleFocusDay={handleFocusDay}
                        handlePersonAbsent={handlePersonAbsent}
                        updateAbsentReason={updateAbsentReason}
                        statuses={day.Statuses.filter(
                          (status) => status.PersonId === child.ChildId
                        )}
                        handleDeletePopUp={handleDeletePopUp}
                        deleting={deleting}
                        absenting2={testToggle}
                      ></CTBdayDialogChildRow>
                    </>
                  ))}
                </>
              )}
            {state.caretimes.Absent.state === types.SAVING_CARETIMES_ABSENT ||
              (state.caretimes.Deleted.state === types.DELETING_CARETIMES && (
                <>
                  {names.map((child) => (
                    <>
                      <CTBdaySkeletonRow></CTBdaySkeletonRow>
                    </>
                  ))}
                </>
              ))}
          </>
        </DialogContent>
        <DialogActions
          sx={{
            width: "100%",
            height: "55px",
            margin: 0,
            backgroundColor: "success.main",
          }}
          style={{ justifyContent: "center", padding: 0 }}
        >
          <Button
            sx={{
              width: "100%",
              height: "60px",
              margin: 0,
              backgroundColor: "success.main",
              "&:disabled": { backgroundColor: "#8c8c8b" },
            }}
            variant="contained"
            onClick={saveAbsents}
            disabled={personAbsent.length < 1}
          >
            Tallenna
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        disablePadding
        disableGutters
        fullWidth
        open={absenting}
        sx={{
          "& .MuiDialog-container .MuiDialog-paper": {
            margin: 0,
            borderRadius: 2,
          },
          width: "100%",
        }}
      >
        <DialogTitle textAlign="center" style={{ backgroundColor: "#FFFFFF" }}>
          <Typography color="textSecondary" fontWeight="bold">
            {Languages.newCalendarSaveAbsence}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleAbsenting}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "success",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          dividers
          disableGutters
          disablePadding
          sx={{ padding: 0 }}
          style={{ backgroundColor: "#FFFFFF" }}
        >
          <>
            {savingAbsent && !absentSaved && (
              <Stack spacing={1} alignItems="center">
                <CircularProgress></CircularProgress>
                <Typography color="textSecondary" fontSize="medium">
                  {Languages.newCalendarSavingAbsence}
                </Typography>
              </Stack>
            )}

            {!savingAbsent &&
              absentSaved &&
              state.caretimes.Absent.state ===
                types.CARETIMES_ABSENT_SAVED_FAILED && (
                <>
                  <Stack spacing={1} alignItems="center">
                    <Typography color="textSecondary" fontSize="medium">
                      {Languages.newCalendarSavingAbsenceFailed}
                    </Typography>

                    <Button
                      variant="contained"
                      sx={{ backgroundColor: "#0074C8" }}
                      onClick={handleAbsenting}
                    >
                      {Languages.newCalendarClose}
                    </Button>
                  </Stack>
                </>
              )}

            {!savingAbsent &&
              absentSaved &&
              state.caretimes.Absent.state ===
                types.CARETIMES_ABSENT_SAVED_OK && (
                <>
                  <Stack spacing={1} alignItems="center">
                    <Typography color="textSecondary" fontSize="medium">
                      {Languages.newCalendarAbsentSaved}
                    </Typography>
                    <AutoClose handleClick={handleAbsenting}></AutoClose>
                    <Button
                      variant="contained"
                      sx={{ backgroundColor: "#0074C8" }}
                      onClick={handleAbsenting}
                    >
                      {Languages.newCalendarClose}
                    </Button>
                  </Stack>
                </>
              )}
          </>
        </DialogContent>
      </Dialog>
      <Dialog
        disablePadding
        disableGutters
        fullWidth
        open={deleting}
        sx={{
          "& .MuiDialog-container .MuiDialog-paper": {
            margin: 0,
            borderRadius: 2,
          },
          width: "100%",
        }}
      >
        <DialogTitle textAlign="center" style={{ backgroundColor: "#FFFFFF" }}>
          <Typography color="textSecondary" fontWeight="bold">
            {Languages.newCalendarDeleteCareTime}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleDeletePopUp}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "success",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          dividers
          disableGutters
          disablePadding
          sx={{ padding: 0 }}
          style={{ backgroundColor: "#FFFFFF" }}
        >
          <>
            {!savingDelete && !deleted && (
              <Typography
                color="textSecondary"
                textAlign="center"
                sx={{ padding: 2 }}
              >
                {Languages.newCalendarAreYousureWantToDeleteCaretime}
              </Typography>
            )}

            {savingDelete && !deleted && (
              <Stack spacing={1} alignItems="center">
                <CircularProgress></CircularProgress>
                <Typography color="textSecondary" fontSize="medium">
                  {Languages.newCalendarDeletingCareTimes}
                </Typography>
              </Stack>
            )}

            {!savingDelete &&
              deleted &&
              state.caretimes.Deleted.state ===
                types.CARETIMES_DELETED_FAILED && (
                <>
                  <Stack spacing={1} alignItems="center">
                    <Typography color="textSecondary" fontSize="medium">
                      {Languages.newCalendarDeletingCareTimesFailed}
                    </Typography>

                    <Button
                      variant="contained"
                      sx={{ backgroundColor: "#0074C8" }}
                      onClick={handleDeleteClose}
                    >
                      {Languages.newCalendarClose}
                    </Button>
                  </Stack>
                </>
              )}

            {!savingDelete &&
              deleted &&
              state.caretimes.Deleted.state === types.CARETIMES_DELETED_OK && (
                <>
                  <Stack spacing={1} alignItems="center">
                    <Typography color="textSecondary" fontSize="medium">
                      {Languages.newCalendarCareTimesDeletedSuccess}
                    </Typography>
                    <AutoClose handleClick={handleDeleteClose}></AutoClose>
                    <Button
                      variant="contained"
                      sx={{ backgroundColor: "#0074C8" }}
                      onClick={handleDeleteClose}
                    >
                      Sulje
                    </Button>
                  </Stack>
                </>
              )}
          </>
        </DialogContent>
        {!savingDelete && !deleted && (
          <DialogActions
            sx={{
              width: "100%",
              margin: 0,
              backgroundColor: "white",
              padding: 1,
            }}
            style={{ justifyContent: "center" }}
          >
            <Button
              sx={{
                margin: 0,
                backgroundColor: "success.main",
                "&:disabled": { backgroundColor: "#8c8c8b" },
              }}
              variant="contained"
              onClick={handleDeletePopUp}
            >
              {Languages.newCalendarCancel}
            </Button>
            <Button
              sx={{
                margin: 0,
                backgroundColor: "success.main",
                "&:disabled": { backgroundColor: "#8c8c8b" },
              }}
              variant="contained"
              onClick={deleteCareTime}
            >
              {Languages.NewCalendarDelete}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

export default CTBDayDialog3;

const CTBdayDialogChildRow = ({
  child,
  isPast,
  isLocked,
  date,
  handleFocusDay,
  handlePersonAbsent,
  updateAbsentReason,
  statuses,
  absenting2,
  deleting,
  handleDeletePopUp,
}) => {
  const { state, actions } = useStateValue();
  const [absent, setAbsent] = React.useState(false);
  const [absentReason, setAbsentReason] = React.useState("Poissa");
  const [absenting, setAbsenting] = React.useState(false);

  const handleReason = (event, newReason) => {
    if (newReason !== null) {
      updateAbsentReason({
        PersonId: child.ChildId,
        AbsentReason: newReason,
        allowWeekends: true,
        allowSaturday: true,
        allowSunday: true,
      });
      setAbsentReason(newReason);
    }
  };
  const handleAbsent = () => {
    handlePersonAbsent({
      PersonId: child.ChildId,
      AbsentReason: absentReason,
      allowWeekends: true,
      allowSaturday: true,
      allowSunday: true,
    });
    setAbsent(!absent);
  };
  return (
    <>
      <Card
        variant="outlined"
        elevation={0}
        sx={{
          bgcolor: "white",
          mt: 1,
          width: "96%",
          ml: "2%",
        }}
      >
        <CardHeader
          sx={{ padding: 0 }}
          avatar={
            <Avatar sx={{ width: 36, height: 36, ml: 1 }}>
              <ChildProfilePicture childId={child.ChildId} />
            </Avatar>
          }
          title={
            <>
              {" "}
              <Stack direction="row" spacing={2} sx={{ ml: 0.5 }}>
                <Typography color="textSecondary" fontWeight="bold">
                  {child.Name.split(" ")[0]}
                </Typography>
                {statuses.some((s) => s.Type === "S" || s.Type === "A") && (
                  <Chip
                    size={"small"}
                    icon={
                      statuses.filter(
                        (s) => s.Type === "S" || s.Type === "A"
                      )[0].Type === "S" ? (
                        <SickIcon />
                      ) : (
                        <NightShelterIcon />
                      )
                    }
                    color={"warning"}
                    label={
                      statuses.filter(
                        (s) => s.Type === "S" || s.Type === "A"
                      )[0].Name
                    }
                  ></Chip>
                )}
                {statuses.some((s) => s.Type === "41") && (
                  <Chip
                    size={"small"}
                    sx={{ ml: 1, backgroundColor: "#00b3df" }}
                    label={statuses.filter((s) => s.Type === "41")[0].Name}
                  ></Chip>
                )}
              </Stack>
            </>
          }
          action={
            <>
              {isPast === false && isLocked === false && (
                <>
                  <IconButton
                    disableRipple={true}
                    size="small"
                    sx={{
                      backgroundColor: "warning.main",
                      color: "white",
                      width: 24,
                      height: 24,
                    }}
                    onClick={() =>
                      handleDeletePopUp({
                        ChildIds: [child.ChildId],
                        ListDatesToDelete: [moment(date).toISOString(true)],
                        FocusDate: moment(date).format("DD.MM.YYYY"),
                      })
                    }
                  >
                    <DeleteForeverIcon
                      sx={{
                        width: 18,
                        height: 18,
                      }}
                    ></DeleteForeverIcon>
                  </IconButton>
                </>
              )}
              {isPast == false && (
                <>
                  <FormControlLabel
                    sx={{
                      mt: 1,
                      mr: 2,
                      "& .MuiFormControlLabel": {
                        styleOverrides: {
                          root: {
                            width: "fit-content",
                          },
                        },
                      },
                    }}
                    control={
                      <Switch checked={absent} onChange={handleAbsent} />
                    }
                    labelPlacement="start"
                    label={
                      <Typography color="textSecondary" fontWeight="medium">
                        {"poissa"}
                      </Typography>
                    }
                  />
                </>
              )}
            </>
          }
        />
        <CardContent sx={{ padding: 0 }}>
          {absent && (
            <>
              <Card
                variant="outlined"
                sx={{
                  bgcolor: "white",
                  mt: 1,
                  width: "96%",
                  ml: "2%",
                  mb: 1,
                }}
              >
                <CardHeader
                  sx={{ padding: 1 }}
                  title={
                    <Typography
                      color="textSecondary"
                      textAlign="center"
                      fontWeight="medium"
                    >
                      {Languages.newCalendarAbsentReasonSelect}
                    </Typography>
                  }
                />
                <CardContent sx={{ padding: 0 }}>
                  <ToggleButtonGroup
                    sx={{ width: "100%" }}
                    value={absentReason}
                    exclusive
                    onChange={handleReason}
                  >
                    <ToggleButton
                      style={{ justifyContent: "center" }}
                      sx={{ padding: 1, width: "30%", ml: "20%" }}
                      value="Poissa"
                    >
                      <Box sx={{ width: "100%" }}>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <Box sx={{ width: "50%" }}>
                            <Typography
                              color="textSecondary"
                              fontSize="small"
                              textAlign="center"
                            >
                              {Languages.newCalendarAbsent}
                            </Typography>
                          </Box>
                          <Box sx={{ width: "30%" }}>
                            {absentReason === "Poissa" && (
                              <CheckIcon color="success" />
                            )}
                          </Box>
                        </Stack>
                      </Box>
                    </ToggleButton>
                    <ToggleButton
                      sx={{
                        padding: 1,
                        width: "30%",
                      }}
                      value="Sairas"
                    >
                      <Box sx={{ width: "100%" }}>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <Box sx={{ width: "50%" }}>
                            <Typography
                              color="textSecondary"
                              fontSize="small"
                              textAlign="center"
                            >
                              {Languages.newCalendarSick}
                            </Typography>
                          </Box>
                          <Box sx={{ width: "30%" }}>
                            {absentReason === "Sairas" && (
                              <CheckIcon color="success" />
                            )}
                          </Box>
                        </Stack>
                      </Box>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </CardContent>
                <CardActions style={{ justifyContent: "center" }}></CardActions>
              </Card>
            </>
          )}
          {child.Centres.some((centre) => centre.NursingTimes.length > 0) ===
            true && (
            <>
              {child.Centres.map((ct) => (
                <>
                  {ct.NursingTimes.length > 0 && (
                    <>
                      <CTBdayDialogCTitem
                        ct={ct}
                        isPast={isPast}
                        isLocked={isLocked}
                      ></CTBdayDialogCTitem>
                    </>
                  )}
                </>
              ))}
            </>
          )}
        </CardContent>
        <CardActions style={{ justifyContent: "center" }}></CardActions>
      </Card>
    </>
  );
};

const CTBdaySkeletonRow = ({}) => {
  return (
    <>
      <Card
        variant="outlined"
        elevation={0}
        sx={{
          bgcolor: "white",
          mt: 1,
          width: "96%",
          ml: "2%",
        }}
      >
        <CardHeader
          sx={{ padding: 0 }}
          avatar={
            <Skeleton
              variant="circular"
              sx={{ bgcolor: "lightgrey", ml: 1, width: 36, height: 36 }}
            >
              <Avatar />
            </Skeleton>
          }
          title={
            <Typography color="textSecondary" fontWeight="bold">
              {<Skeleton width="30%" sx={{ bgcolor: "lightgrey" }} />}
            </Typography>
          }
          action={
            <>
              <Skeleton sx={{ bgcolor: "lightgrey", mr: 2 }}>
                <IconButton
                  disableRipple={true}
                  size="small"
                  sx={{
                    backgroundColor: "warning.main",
                    color: "white",
                    width: 24,
                    height: 24,
                  }}
                >
                  <DeleteForeverIcon
                    sx={{
                      width: 18,
                      height: 18,
                    }}
                  ></DeleteForeverIcon>
                </IconButton>

                <FormControlLabel
                  sx={{
                    mt: 1,
                    mr: 2,
                    "& .MuiFormControlLabel": {
                      styleOverrides: {
                        root: {
                          width: "fit-content",
                        },
                      },
                    },
                  }}
                  control={<Switch />}
                  labelPlacement="start"
                  label={
                    <Typography color="textSecondary" fontWeight="medium">
                      {Languages.newCalendarAbsent}
                    </Typography>
                  }
                />
              </Skeleton>
            </>
          }
        />
        <CardContent sx={{ padding: 0 }}>
          <Skeleton sx={{ bgcolor: "lightgrey", ml: 1 }}>
            <Box sx={{ width: 345, height: 150 }}></Box>
          </Skeleton>
        </CardContent>
        <CardActions style={{ justifyContent: "center" }}></CardActions>
      </Card>
    </>
  );
};

const AutoClose = (props) => {
  const [progress, setProgress] = React.useState(3);
  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress <= 0) {
          props.handleClick();
        }
        return oldProgress - 1;
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box sx={{ width: "80%" }}>
      <Typography color="textSecondary" fontSize="medium">
        {Languages.newCalendatAutomaticClose +
          " " +
          progress +
          " " +
          Languages.newCalendarAfterSeconds}
      </Typography>
    </Box>
  );
};

const CTBdayDialogCTitem = ({ ct, isPast, isLocked }) => {
  const [sameGroup, setSameGroup] = useState(
    ct.NursingTimes.every(
      (time) =>
        time.CentreId === ct.NursingTimes[0].CentreId &&
        time.Group === ct.NursingTimes[0].Group
    )
  );

  const [Eo, setEo] = useState(
    ct.NursingTimes.some((time) => time.Priority === "2")
  );

  return (
    <>
      <Card elevation={0} sx={{ maxWidth: 345, backgroundColor: "white" }}>
        <CardHeader
          sx={{ padding: 0 }}
          title={
            <>
              {sameGroup && (
                <>
                  <Chip
                    icon={<NightShelterIcon />}
                    size="small"
                    sx={{
                      padding: 0,
                      ml: 1,
                      backgroundColor: "#00B3DF",
                      "& .MuiChip-icon": {
                        color: "white",
                      },
                    }}
                    label={
                      <Typography
                        color="white"
                        fontWeight="medium"
                        fontSize="small"
                      >
                        {ct.CentreName}
                      </Typography>
                    }
                  ></Chip>
                  <Chip
                    icon={<NightShelterIcon />}
                    size="small"
                    sx={{
                      padding: 0,
                      ml: 1,
                      backgroundColor: "#00B3DF",
                      "& .MuiChip-icon": {
                        color: "white",
                      },
                    }}
                    label={
                      <Typography
                        color="white"
                        fontWeight="medium"
                        fontSize="small"
                      >
                        {ct.NursingTimes[0].Group}
                      </Typography>
                    }
                  ></Chip>
                  {Eo && (
                    <Chip
                      icon={<SchoolIcon />}
                      size="small"
                      sx={{
                        padding: 0,
                        ml: 1,
                        backgroundColor: "success.main",
                        "& .MuiChip-icon": {
                          color: "white",
                        },
                      }}
                      label={
                        <Typography
                          color="white"
                          fontWeight="medium"
                          fontSize="small"
                        >
                          {"Eo"}
                        </Typography>
                      }
                    ></Chip>
                  )}
                </>
              )}
            </>
          }
          action={<></>}
        />

        <CardContent
          sx={{
            padding: 0,
            "&:last-child": {
              paddingBottom: 1,
            },
          }}
        >
          {ct.NursingTimes.sort(
            (a, b) => moment(a.Start) - moment(b.Start)
          ).map((nt) => (
            <>
              {ct.NursingTimes.length > 0 && (
                <>
                  <ExistingReservation
                    nt={nt}
                    sameGroup={sameGroup}
                  ></ExistingReservation>
                </>
              )}
            </>
          ))}
        </CardContent>
      </Card>
    </>
  );
};

const ExistingReservation = (props) => {
  const [startTimes, setStartTimes] = useState([]);
  const [endTimes, setEndTimes] = useState([]);
  const [value, setValue] = React.useState([]);
  const [shortRes, setShortRes] = useState(false);
  const [extraShortRes, setExtraShortRes] = React.useState(false);
  const [marks, setMarks] = React.useState([]);

  const [gapInRes, setGapInRes] = React.useState(false);
  const [gapStart, setGapStart] = React.useState();
  const [gapEnd, setGapEnd] = React.useState();
  const [gapStart2, setGapStart2] = React.useState();
  const [gapEnd2, setGapEnd2] = React.useState();

  useEffect(() => {
    const startAsMinutes = moment
      .duration(moment(props.nt.Start).format("HH:mm"))
      .asMinutes();
    const endAsMinutes = moment
      .duration(moment(props.nt.End).format("HH:mm"))
      .asMinutes();

    setValue([startAsMinutes, endAsMinutes]);
    setMarks([
      {
        value: startAsMinutes,
        label: moment()
          .utc()
          .startOf("day")
          .add(startAsMinutes, "minutes")
          .format("HH:mm"),
      },

      {
        value: endAsMinutes >= 1439 ? 1440 : endAsMinutes,
        label:
          endAsMinutes >= 1439
            ? "24:00"
            : moment()
                .utc()
                .startOf("day")
                .add(endAsMinutes, "minutes")
                .format("HH:mm"),
      },
    ]);

    setShortRes(endAsMinutes - startAsMinutes < 300 ? true : false);
    setExtraShortRes(endAsMinutes - startAsMinutes < 100 ? true : false);
  }, [props.Centres]);

  // 270 480 600 750 Välin/eo palkin laskenta: Loppuaika * 60 - alkuaika * 60 = varauksen kesto.
  // kesto - Välin alkuaika * 60 / kesto // kesto - välin loppuaika * 60 / kesto > prosenteista gradientti.

  return (
    <Box sx={{ mt: props.sameGroup ? 0 : 1 }}>
      {!props.sameGroup && (
        <>
          <Chip
            icon={<NightShelterIcon />}
            size="small"
            sx={{
              padding: 0,
              ml: 1,
              backgroundColor: "#00B3DF",
              "& .MuiChip-icon": {
                color: "white",
              },
            }}
            label={
              <Typography color="white" fontWeight="medium" fontSize="small">
                {props.nt.Centre}
              </Typography>
            }
          ></Chip>
          <Chip
            icon={<NightShelterIcon />}
            size="small"
            sx={{
              padding: 0,
              ml: 1,
              backgroundColor: "#00B3DF",
              "& .MuiChip-icon": {
                color: "white",
              },
            }}
            label={
              <Typography color="white" fontWeight="medium" fontSize="small">
                {props.nt.Group}
              </Typography>
            }
          ></Chip>
          {props.nt.Priority === "2" && (
            <Chip
              icon={<SchoolIcon />}
              size="small"
              sx={{
                padding: 0,
                ml: 1,
                backgroundColor: "success.main",
                "& .MuiChip-icon": {
                  color: "white",
                },
              }}
              label={
                <Typography color="white" fontWeight="medium" fontSize="small">
                  {Languages.newCalendarEo}
                </Typography>
              }
            ></Chip>
          )}
        </>
      )}
      {gapInRes ? (
        <Slider
          sx={{
            height: 8,
            borderRadius: 0,
            width: "96%",
            ml: "2%",
            mb: 0,

            "& .MuiSlider-thumb": {
              backgroundColor: "transparent",
              height: 0,
              width: 0,
            },

            "& .MuiSlider-track": {
              height: 8,
              border: 0,
              background:
                "linear-gradient(90deg, rgba(0,179,223,1) 0%, rgba(0,179,223,1)" +
                ((gapStart - value[0]) / (value[1] - value[0])) * 100 +
                "%, rgba(224,224,222,1)" +
                ((gapStart - value[0]) / (value[1] - value[0])) * 100 +
                "%, rgba(224,224,222,1)" +
                ((gapEnd - value[0]) / (value[1] - value[0])) * 100 +
                "%, rgba(0,179,223,1)" +
                ((gapEnd - value[0]) / (value[1] - value[0])) * 100 +
                "%)",
            },
            "& .MuiSlider-rail": {
              color: "#e0e0de",
              opacity: 1,
              height: 8,
            },
            "& .MuiSlider-valueLabel": {
              fontSize: 12,
              fontWeight: "normal",
              top: 28,
              display: "none",
              backgroundColor: "unset",
              color: "black",
              "&:before": {
                display: "none",
              },

              "& *": {
                background: "transparent",
                color: "black",
              },
            },
            "& .MuiSlider-markLabel": {
              color: "black",
              fontSize: 12,
              fontWeight: "normal",
              top: 30,
            },
            "& .MuiSlider-mark": {
              display: "none",
            },
            '& .MuiSlider-markLabel[data-index="0"]': {
              marginLeft: extraShortRes ? "-15px" : shortRes ? "-8px" : "0px",
            },
            '& .MuiSlider-markLabel[data-index="1"]': {
              marginLeft: extraShortRes ? "12px" : shortRes ? "6px" : "0px",
            },
          }}
          value={value}
          marks={marks}
          min={0}
          max={1440}
          disabled
        />
      ) : (
        <Slider
          sx={{
            height: 8,
            borderRadius: 0,
            width: "96%",
            ml: "2%",
            mb: 0,

            "& .MuiSlider-thumb": {
              backgroundColor: "transparent",
              height: 0,
              width: 0,
            },

            "& .MuiSlider-track": {
              height: 8,
              border: 0,
              color: props.nt.Priority === "2" ? "success.main" : "#00b3df",
            },
            "& .MuiSlider-rail": {
              color: "#e0e0de",
              opacity: 1,
              height: 8,
            },
            "& .MuiSlider-valueLabel": {
              fontSize: 12,
              fontWeight: "normal",
              top: 28,
              display: "none",
              backgroundColor: "unset",
              color: "black",
              "&:before": {
                display: "none",
              },

              "& *": {
                background: "transparent",
                color: "black",
              },
            },
            "& .MuiSlider-markLabel": {
              color: "black",
              fontSize: 12,
              fontWeight: "normal",
              top: 30,
            },
            "& .MuiSlider-mark": {
              display: "none",
            },
            '& .MuiSlider-markLabel[data-index="0"]': {
              marginLeft: extraShortRes ? "-15px" : shortRes ? "-8px" : "0px",
            },
            '& .MuiSlider-markLabel[data-index="1"]': {
              marginLeft: extraShortRes ? "12px" : shortRes ? "6px" : "0px",
            },
          }}
          value={value}
          marks={marks}
          min={0}
          max={1440}
          disabled
        />
      )}
    </Box>
  );
};

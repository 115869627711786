import React from "react";
import BackToUpButton from "../GuideTools/BackToUpButton";
import Languages from "../../../translations";


const InvoiceDataAccordionText = () => {
    return(
      <div>
      <br />
      <p className="guide-accordion-details-texts">{Languages.invoiceDataText1}</p>
      <br />
      <br />
      <p className="guide-accordion-details-texts">{Languages.invoiceDataText2}</p>
      <br />
      <br />
      <p className="guide-accordion-details-texts"><b>{Languages.invoiceDataText3_1}</b>{Languages.invoiceDataText3_2}</p>
      <br />
      <br />
      <p className="guide-accordion-details-texts"><b>{Languages.invoiceDataText4_1}</b>{Languages.invoiceDataText4_2}</p>
      <br />
      <br />
      <p className="guide-accordion-details-texts"><b>{Languages.invoiceDataText5_1}</b>{Languages.invoiceDataText5_2}</p>
      <br />
      <br />
      <p className="guide-accordion-details-texts">{Languages.invoiceDataText6}</p>
      <br />
      <br />
      <BackToUpButton />
      </div>
    )
}

export default InvoiceDataAccordionText
import ChildBasicInfo from "./ChildBasicInfo";
// Example data: [{ Number: "034 432 432" }]
export const validatePhoneNumber = (phoneNumber, allowEmpty) => {
  const checkPhoneNumberLenght = (nbr) => {

    let remText = nbr.replace("+", "");
    // use the \s quantifier to remove all white space
    let remText2 = remText.replace(/\s/g, "");
    // get the length of the string after removal
    let length2 = remText2.length;

    return length2;
  }
  //Lankanumero
  //09 123 456 tai 09123456
  //const regex1 = /^\(?([0-9]{2})\)?[ ]?([0-9]{3})[ ]?([0-9]{3})$/;

  //Mobiilinumerot
  //+1/2/3 012 123/4 1/2/3/4 1/2/3
  //const regex1 = /^((\+\d{1,3}[ ]\d{2})|([0]{1}(\d{2})))([ ])(\d{2,3})([ ]?)(\d{2,3})([ ]?)(\d{2,3})([ ]?)(\d{1,3})([ ]?)(\d{0,2})([ ]?)(\d{0,2})*?$/;
  const regex1 = /^((\+\d{1,3}[ ]?[1-9]{2})|([0]{1}([0-9]{2})))([ ]?)(\d{1})([ ]?)(\d{1})([ ]?)(\d{1})([ ]?)(\d{1})([ ]?)(\d{1})?([ ]?)(\d{1})?([ ]?)(\d{1})?([ ]?)(\d{1})?([ ]?)(\d{1})?([ ]?)(\d{1})?$/;

  //useampi hyväksytty välimerkki
  //const regex = /^\(?([0-9]{3})\)?[- ]?([0-9]{3-10})[- ]?([0-9]{3})[- ]?([0-9]{3})$/;

  let lenght = 0;
  //let FailedNumbersIds = [];
  let failedIndex = 0;
 
  if (allowEmpty) {
   if (!phoneNumber) {
      return {
        valid: true,
        number: phoneNumber
      }
    }
  }

  if (!phoneNumber) {
    return {
      valid: false,
      number: phoneNumber,
      reason: "Lenght"
    }
  }

  lenght = checkPhoneNumberLenght(phoneNumber);

  if (lenght < 7 || lenght > 12) {
    failedIndex++;
    return {
      number: phoneNumber,
      valid: false,
      reason: "Lenght"
    };
    
  }
  
  if (!regex1.test(phoneNumber)) {
    failedIndex++;
    return {
      number: phoneNumber,
      valid: false,
      reason: "Format"
    };
  }

  return {
    number: phoneNumber,
    valid: true,
  };
}

export const isPostalCode = (string) => {
  const regexp = /^[0-9]{5}$/;
  if (regexp.test(string)) {
    return true;
  }
  else {
    return false;
  }
}

export const isProperStreetAddressOrCity = (string) => {
  const regexp = /^[-A-ZÄÖÜßa-zäöü]{1,30}\s?[A-ZÄÖÜßa-zäöü0-9\s]{1,20}?$/;
  if (regexp.test(string)) {
    return true;
  }
  else {
    return false;
  }
}

export const isValidName = (string) => {
  if (!string) {
    return false;
  }
  if (string.length < 2) {
    return false;
  }
  return true;
}

export const isValidEmail = (emailAddress) => {
  const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (emailAddress.trim() !== "" && !regexEmail.test(emailAddress)) {
    return false;
  }

  return true;
}

export { ChildBasicInfo as default }
import React from "react"
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AccordionDetailsManager from "../AccordionDetailsManager";
import PropTypes from 'prop-types'


const GuideAccordion = ({accordionTitle, accordionTitleDescription, handlePanelExpand, isExpanded, panelId, accordionDetails}) => {


    return(
        <>
          <Accordion color="secondary" expanded={isExpanded === panelId} onChange={handlePanelExpand(panelId)} id={panelId} TransitionProps={{ unmountOnExit: true }}>
            <div className="df-guide-modal-accordion-summary" id={accordionTitle}>
              <AccordionSummary id={panelId}
                expandIcon={isExpanded===panelId ? <HighlightOffIcon style={{fill: "red"}}/> : <ControlPointIcon style={{fill: "green"}}/>}
              >
                <p className="df-guide-accordion-title-keyword">{accordionTitle}</p>
                <p className="df-guide-accordion-title-description">{accordionTitleDescription}</p>
              </AccordionSummary>
          </div>
          <div className="df-guide-modal-accordion-details" id="panel-details">
            <AccordionDetails>
              <AccordionDetailsManager accordionDetails={accordionDetails}/>
            </AccordionDetails>
          </div>
        </Accordion>
      </>
    )
}

GuideAccordion.propTypes = {
  handlePanelExpand: PropTypes.func.isRequired,
  //isExpanded: PropTypes.string.isRequired,
  panelId: PropTypes.string.isRequired,
  accordionDetails: PropTypes.string.isRequired,
  accordionTitle: PropTypes.string.isRequired,
  accordionTitleDescription: PropTypes.string,
}

export default GuideAccordion
import React          from  'react';
import Languages      from  "../../../../translations";
import AutorenewIcon  from  '@mui/icons-material/Autorenew';
import Card           from  "@mui/material/Card";
import Button         from  "@mui/material/Button";
import                      '../pedagdocimagefolder.css';


const PedagDocImageFolderFailed = ({ reloadFoldersIfFailed, errorCode }) => {
  return (
      <React.Fragment>
        <Card data-testid={"pedagdocimagefolder-error-test"} style={{backgroundColor:"#4377BA"}}>
          <p className = "imagefolder__error-message">{Languages.errorLoadImages}</p>
          <p className = "imagefolder__error-message">
            {typeof errorCode === "undefined" ? Languages.errorUnknown : 
              errorCode === 400 ? Languages.errorCode + " 400 " :
              errorCode === 500 ? Languages.errorCode + " 500: " + Languages.errorInDB :
              errorCode === 502 ? " 502: " + Languages.errorNetwork : 
              errorCode === 777 ? Languages.errorUnknown : Languages.errorUnknown
            }
          </p>
        </Card>
        <div className= "imagefolder__error-message__button"> 
          <Button
            title = "reloadButton" 
            data-testid = "pedagdocimagefolder__error-message-react-button"
            startIcon = {<AutorenewIcon />}
            style = {{backgroundColor:"#4377BA", align:"center"}} 
            onClick = {reloadFoldersIfFailed}>
              {Languages.retry}
          </Button>
        </div>
      </React.Fragment>
  );
}

export default PedagDocImageFolderFailed;
import React, { Component } from "react";
import { StateContext/*, useStateValue*/ }  from "../../State";
import { types }                        from '../../Reducers/actionTypes';


import {Modal, ModalBody } from "reactstrap";
import Moment from 'moment';

import "./notifyabsent.css";
import Languages from "../../translations";

import ContentBox      from "../../Components/ContentBox";
import DenseTable      from '../../Components/DenseTable';
import NewReservations from "../NursingtimeCalendar/Components/NewReservations";

import CircularProgress from "@mui/material/CircularProgress";
import ContentBoxMUI from "../../Components/ContentBoxMUI";
import Backdrop from '@mui/material/Backdrop';

class NotifyAbsent extends Component {

  static contextType = StateContext;

  constructor(props) {
    super(props);
    this.state = {
      infoModal: false,
      errorModal: false,
      noValidPLS: false,
      infoText: "",
      showNewReservation: false,
      DenseTableTitleSaveError: null,
      DenseTableDataSaveErrors: null,
    };
    this.strings = Languages;
  }

  componentDidMount() {
    /*console.log("NotifyAbsent. Mount\n"+
    "this.props.location: ", this.props.location,"\n"+
    "this.props.location.state: ", this.props.location.state);*/

    //console.log("NotifyAbsent. Mount. Initialize calendar to this month. Set today selected");
    this.context.actions.triggerLoadNursingTimes(Moment().startOf("month"));
    this.setState({ infoModal: true });
  }
  
  componentDidUpdate() {
    /*console.log("NotifyAbsent. Update: \n"+
                //"infoModal: ", this.state.infoModal,"\n"+
                //"errorModal: ", this.state.errorModal);*/

    this.handleChildNursingTimes();
    this.handleSavingAbsenses();
  }

  handleChildNursingTimes = () => {
    const { children } = this.context.state;
    const { List } = this.context.state.nursingtimes;
    const { WeekData } = this.context.state.nursingtimes.List;
          
    /*console.log("Absent. handleChildNursingTimes: "+
                //"\n this.state.initDate", this.state.initDate,
                "\n List.state: ", List.state,
                "\n List.Code: ", List.Code,
                "\n List.WeekData: ", List.WeekData);*/
    switch (List.state)
    {
      case types.READING_NURSINGTIMES:
        //console.log("Calendar. READING_NURSINGTIMES");
        break;
      case types.NURSINGTIMES_READ:
        this.context.actions.triggerNursingTimesReadHandled();
        //console.log("Calendar. handleChildNursingTimes. daysToBeSelected: ", List.WeekData.DaysToBeSelected);
        children.ChildList.forEach(c => { c.Checked = true; });
        WeekData.WeekList.forEach(week => {
          var day = week.DayList.find(d => d.Date.format('DD.MM.YYYY') === Moment(new Date()).format('DD.MM.YYYY') );
          if (day !== undefined )
          {
            day.DateSelected = true;
          }
        });
        this.setState({ infoModal: false });
        break;
      case types.NURSINGTIMES_FAILED:
        this.context.actions.triggerNursingTimesFailedHandled();
        this.setState({ infoModal:  false,
                        errorModal: true,
                        infoText:   List.Code === 404 ? this.strings.bookingCareHoursTitle + "\n" + this.strings.errorNotFound  : 
                                    List.Code === 500 ? this.strings.bookingCareHoursTitle + "\n" + this.strings.errorInDB :
                                    List.Code === 502 ? this.strings.bookingCareHoursTitle + "\n" + this.strings.errorNetwork : 
                                    /* List.Code === 666*/ this.strings.bookingCareHoursTitle + "\n" + this.strings.errorUnknown });
          break;
      default:
        //console.log("Calendar. handleChildNursingTimes. List.state: ", List.state);
        break;
    }
  };

  handleSavingAbsenses = () => {
    const { children } = this.context.state;
    const { Saved } = this.context.state.nursingtimes;
    
    /*console.log("Absent. handleSavingAbsenses"+
                "\n Saved.state: ",Saved.state+
                "\n Saved: ", Saved);*/

    switch (Saved.state)
    {
      case types.SAVING_NURSINGTIMES:
        //console.log("Absent. SAVING_NURSINGTIMES");
        break;
      case types.NURSINGTIMES_SAVED_OK:
        //console.log("Absent. ABSENT SAVED OK.");
        this.context.actions.triggerSaveOkNursingTimesHandled();
        //Re-select all children
        children.ChildList.forEach(c => { c.Checked = true; });
        let date = null;
        //let DenseTableDataDeleteErros = [];
        //let DenseTableDataItemDeleteErros = [];
        let DenseTableDataSaveErros = [];
        let DenseTableDataItemSaveErros = [];

        if ( Saved.Data === null || (Saved.Data.SaveErros === null || Saved.Data.SaveErros.length === 0) )
        {
          //console.log("Absent. ABSENT SAVED OK. Re-read nursingtimes and go to Calendar");
          this.context.actions.triggerLoadNursingTimes(this.state.initDate);
          this.props.history.push("/nursingtimes",{readNursingTimes: true});
        }
        else
        {
          //console.log("Absent. ABSENT SAVED PARTIALLY OK. Saved.Data: ", Saved.Data);
          // Päämenusta poissaoloa ilmoitettaessa deletonti virhelistaa EI näytetä
          if (Saved.Data.SaveErros !== null && Saved.Data.SaveErros.length > 0)
          {
            //console.log("Calendar. NURSINGTIMES SAVED PARTIALLY OK. Saved errors: ", Saved.Data.SaveErros);
            //subjective child
            if (Saved.Data.SavedCode === -4)
            {
              DenseTableDataItemSaveErros.push({	//PersonID: null,
                                                  Colum1: Saved.Data.SaveErros[0].ChildName,
                                                  Colum2: Saved.Data.SaveErros[0].ErrorString,
                                                  Colum3: null });
              DenseTableDataSaveErros.push({ Date:  null, //subjetive 20h error. No date info
                                             Title: null,
                                             Items: DenseTableDataItemSaveErros});
            }
            else // Saved.Data.SavedCode === -2
            {            
              // tarvitaanko???
              //Saved.Data.SaveErros = Saved.Data.SaveErros.sort((a, b) => new Date(a.ErrorDate) - new Date(b.ErrorDate));
              //New DenseTable
              date = null;
              Saved.Data.SaveErros.forEach((serror, serrorIndex) => {
                if (date === null) // first date
                {
                  date = Moment(serror.ErrorDate).format("DD.MM.YYYY");
                  DenseTableDataItemSaveErros.push({ // PersonID:       cres.PersonID,
                                                     // PlacementIcon:  cres.PlacementIcon,
                                                     // PlacementColor: cres.PlacementColor,
                                                     Colum1: serror.ChildName,
                                                     Colum2: serror.ErrorString,
                                                     Colum3: null });
                  if (Saved.Data.SaveErros.length -1 === serrorIndex) // first and last item
                  {
                    DenseTableDataSaveErros.push({ Date: date,
                                                   Title: null,
                                                   Items: DenseTableDataItemSaveErros}); 
                  }                  
                }
                else if (date === Moment(serror.ErrorDate).format("DD.MM.YYYY") ) // same date
                {
                  DenseTableDataItemSaveErros.push({ Colum1: serror.ChildName,
                                                     Colum2: serror.ErrorString,
                                                     Colum3: null });
          
                  if (Saved.Data.SaveErros.length -1 === serrorIndex) // last item
                  {
                    DenseTableDataSaveErros.push({ Date: date,
                                                   Title: null,
                                                   Items: DenseTableDataItemSaveErros}); 
                  }
                }
                else // Date change 
                {
                  DenseTableDataSaveErros.push({ Date: date,
                                                 Title: null,
                                                 Items: DenseTableDataItemSaveErros}); 
                  date = Moment(serror.ErrorDate).format("DD.MM.YYYY");

                  DenseTableDataItemSaveErros = [];
                  DenseTableDataItemSaveErros.push({ Colum1: serror.ChildName,
                                                     Colum2: serror.ErrorString,
                                                     Colum3: null });
                              
                  if (Saved.Data.SaveErros.length -1 === serrorIndex) // last item
                  {
                    DenseTableDataSaveErros.push({ Date: date,
                                                   Title: null,
                                                   Items: DenseTableDataItemSaveErros}); 
                  }
                }
              });
            }
          }
        }
          //console.log("Absent. ABSENT SAVED OK. Re-read nursingtimes");
        this.setState({ infoModal:  Saved.Data === null || (Saved.Data.SaveErros === null || Saved.Data.SaveErros.length === 0) ? true : false,
                        errorModal: Saved.Data === null ? false :
                                    Saved.Data.SaveErros !== null && Saved.Data.SaveErros.length > 0 ? true : false,
                        DenseTableTitleSaveError: Saved.Data === null || Saved.Data.SaveErros === null || Saved.Data.SaveErros.length === 0 ? null :
                                    Saved.Data.SavedCode === -4 ? Languages.bookingSavingFailedPartially + Languages.bookingErrorSaving :
                                    Languages.bookingSavingFailedPartially + Languages.bookingErrorSavingDetailed ,
                        DenseTableDataSaveErrors: DenseTableDataSaveErros });
        break;
      case types.NURSINGTIMES_SAVED_FAILED:
        //console.log("Absent. ABSENT SAVED FAILED. Saved ", Saved);

        this.context.actions.triggerSaveFailedNursingTimesHandled();
        this.setState({ infoModal:  false,
                        errorModal: true,
                        infoText:   Saved.Code === 400 ? Saved.Message === null ? this.strings.bookingErrorBadRequest : Saved.Message :
                                    Saved.Code === 404 ? this.strings.errorNotFound  : 
                                    Saved.Code === 500 ? this.strings.errorInDB :
                                    Saved.Code === 502 ? this.strings.errorNetwork : 
                                    /* Saved.Code === 666*/ this.strings.errorUnknown });
        break;
      default:
        break;
    }
  };

  handleNewReservation= (status, ListOfChilds, ListOfReservationsTimes, type ) => { 
    //const { children } = this.context.state;
    const { WeekData } = this.context.state.nursingtimes.List;

    //console.log("NotifyAbsent. handle new reservation. status: ", status, " Type: ", type);
    let reservationsChilds = [];

    if(status === "SAVENEW")
    {
      let selectedChildren = [];
      ListOfChilds.forEach(childList => {
        selectedChildren = childList.filter(c => { return (c.Checked === true) } )
        .map((ci,ciIndex) => {
          
          let pl = ciIndex === 0 ? ci.Placements.find(pl => pl.Checked === true) : null;
          return ({ Id:     ci.Id, 
                    Name:   ci.FirstName, 
                    PlId:   pl === null ? null : pl.Id, 
                    PlName: pl === null ? null : pl.Group === null ? pl.Centre : pl.Centre +"-"+ pl.Group});
        });
        reservationsChilds.push(selectedChildren);
      });

      var selectedDates = [];
      WeekData.WeekList.forEach(week => { week.DayList.filter(d => { if (d.DateSelected === true) { selectedDates.push({Date: d.Date}); } }); });
      let Types = [];
      Types.push(type);
      let DeletedOld = true;
      
      
      this.context.actions.triggerSaveNursingTimes({reservationsChilds,selectedDates,ListOfReservationsTimes, Types ,DeletedOld});
        
      this.setState({ showNewReservation: false,
                      infoModal: true });
    }
    else if(status === "CLOSE_NONE_VALID_PLACEMENTS")
    {
      this.setState({ infoModal:  false,
                      noValidPLS: true,
                      errorModal: true,
                      infoText:   Languages.bookingErrorNoValidPlacement});
    }
    else // CLOSE
    {
      this.props.history.goBack();
    }
  };

  closeError = () => {
    const { List } = this.context.state.nursingtimes;
    //const { Saved } = this.context.state.nursingtimes;

    /*console.log("NotifyAbsent. Close Error note"+
                "\n Saved.state: ", Saved.state,
                "\n List.state: ", List.state);*/

    if (this.state.noValidPLS || List.state === types.NURSINGTIMES_FAILED_HANDLED)
    {
      this.props.history.goBack();
    }
    this.setState({ errorModal: false });

  };  

  render() {
    const { children } = this.context.state;
    
    /*console.log("NotifyAbsent. RENDER "+
                "\n childrenList: ", children.ChildList,
                 "\n children.state: ",children.state);*/

    if (children.ChildList.length === 0)
    {
      return (
        <div className="BackDrop">
          <Backdrop open={true} > <CircularProgress /> </Backdrop>
        </div>
      );
    }
    else if(this.state.errorModal)
    {
      return (
        <Modal isOpen={this.state.errorModal}>
          <ModalBody>
            <div className="InfoMessage textCenter">
              <ContentBox header={this.strings.bookingDataSaving} showCloseicon={true} setfunction={this.closeError} >
              {this.state.DenseTableTitleSaveError !== null ?
               <DenseTable Title={this.state.DenseTableTitleSaveError} Data ={this.state.DenseTableDataSaveErrors}></DenseTable>
              : <h4 style={{whiteSpace:"pre-line"}}>{this.state.infoText}</h4> }
              </ContentBox>
            </div>
          </ModalBody> 
        </Modal>
      );
    }
    else
    {
      const { WeekData } = this.context.state.nursingtimes.List;
      //console.log("Absent. RENDER. WeekData : ", WeekData);
      
      return (
        <div className="NotifyAbsent">
          {this.state.infoModal || WeekData === undefined || WeekData.length === 0 ?
            <div className="BackDrop">
              <Backdrop /*className={classes.backdrop}*/ open={true} /*onClick={handleClose}*/>
                <CircularProgress />
              </Backdrop>
            </div>
          :
            <ContentBoxMUI>
              <NewReservations Mode={"ABSENT"} callback={(status, ListOfChilds, ListOfReservationsTimes, type) => this.handleNewReservation(status, ListOfChilds, ListOfReservationsTimes, type )} />
            </ContentBoxMUI>
          }
        </div>
      );
    }
  }
}

export default NotifyAbsent;
import React, { useEffect /*, { useState, useEffect }*/ } from "react";
import "../mainnav.css";
import { Link } from "react-router-dom";
import Lapset from "../../../images/lapset.svg";
//import Typography from '@mui/material/Typography';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
//import Button          from "@mui/material/Button";
import Badge from "@mui/material/Badge";
import withStyles from "@mui/styles/withStyles";
import Warning from "@mui/icons-material/WarningRounded";
import Languages from "../../../translations";
import ChatBubble from "@mui/icons-material/ChatBubbleRounded";
import CalendarToday from "@material-ui/icons/CalendarToday";
import EventNoteRounded from "@mui/icons-material/EventNoteRounded";
import EventBusyRounded from "@mui/icons-material/EventBusyRounded";
import SettingsRounded from "@mui/icons-material/SettingsRounded";
import BackspaceRounded from "@mui/icons-material/BackspaceRounded";
import ChildCareRounded from "@mui/icons-material/ChildCareRounded";
import KasvunkansioIcon from "../../../images/growthfolder.png";
import LiveHelp from "@mui/icons-material/LiveHelp";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import SessionManager from "../../../Auth/SessionManager";
import Divider from "@mui/material/Divider";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import useMediaQuery from "@mui/material/useMediaQuery";
import InterpreterMode from "@mui/icons-material/InterpreterMode";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const MainMenuLinks = ({
  childList,
  userName,
  communeName,
  mainstats,
  messageData,
  pathName,
  logout,
  openGrowthFolder,
  getGrowthfolderEnabledSetting,
  drawerOpen,
  changeGrowthFolderMenuOpen,
  growthFolderMenuOpen,
  anchorEl,
  setAnchorEl,
  subMenuOpen,
  discussionsEnabled,
  newCalendarEnabled,
  oldCalendarEnabled,
  optimizedMessaging,
  bothcalendarSettingsNUll,
}) => {
  const StyledBadge = withStyles((theme) => ({
    badge: {
      right: -20,
      top: 15,
      padding: "0 4px",
      width: 25,
      height: 25,
      borderRadius: 50,
    },
  }))(Badge);

  const showNewBooking = true;
  const isDesktop = useMediaQuery("(min-width:1200px)", { noSsr: true });

  //const [open, setOpen] = React.useState(false);

  /*const handleGrowthClick = () => {
    setOpen(!open)
  };
  */

  //console.log(childList, userName, communeName, mainstats, pathName, logout);

  if (
    typeof childList === "undefined" ||
    childList === null ||
    typeof userName === "undefined" ||
    typeof communeName === "undefined" ||
    communeName === null ||
    typeof mainstats === "undefined" ||
    mainstats === null ||
    typeof pathName === "undefined" ||
    pathName === null ||
    typeof logout === "undefined" ||
    logout === null
  ) {
    return <div data-testid="main-menu-links-error-jrs0983s"></div>;
  } else {
    return (
      <List key="mainMenuList" sx={{ height: "100vh", overflowX: "hidden" }}>
        {drawerOpen && (
          <ListItem>
            <ListItemText>
              <p
                style={!isDesktop ? { fontSize: "70%" } : {}}
                id="test_Main_UsersRealName"
              >
                {" "}
                {SessionManager.getUsersName()}
              </p>
              <p
                style={
                  !isDesktop
                    ? { marginTop: "-10px", fontSize: "70%" }
                    : { marginTop: "-10px" }
                }
                id="test_Main_UserName"
              >
                {" "}
                {userName}
              </p>
              <p
                style={
                  !isDesktop
                    ? {
                        marginTop: "-10px",
                        marginBottom: "-10px",
                        fontSize: "70%",
                      }
                    : { marginTop: "-10px" }
                }
                id="test_Main_CommuneName"
              >
                {communeName}
              </p>
            </ListItemText>
          </ListItem>
        )}
        {childList.length === 1 ? (
          <Link
            to={{
              pathname: "/childInfo",
              state: {
                childId: childList[0].Id,
                childName: childList[0].Name,
                childColor: childList[0].BgColor,
                VasuId: childList[0].VasuId,
                LeopsId: childList[0].LeopsId,
                showBackArrow: false,
                growthFolderEnabled: "false",
              },
            }}
            replace={pathName === "/childInfo" ? true : false}
            title={childList[0].FirstName}
          >
            <ListItem
              data-testid="main-menu-links-je93nls8"
              id="test_Menu_OpenChildView"
              style={{ color: "white" }}
            >
              <ListItemIcon>
                <img src={Lapset} alt={"Lapset Children"} height="28"></img>
              </ListItemIcon>
              <ListItemText primary={childList[0].FirstName} />
            </ListItem>
          </Link>
        ) : (
          <Link
            to="/childList"
            replace={pathName === "/childList" ? true : false}
            title={Languages.menuChildren}
          >
            <ListItem id="test_Menu_OpenChild_list" style={{ color: "white" }}>
              <ListItemIcon>
                <img src={Lapset} alt={"Lapset Children"} height="28"></img>
              </ListItemIcon>
              <ListItemText primary={Languages.menuChildren} />
            </ListItem>
          </Link>
        )}

        {newCalendarEnabled() && (
          <>
            <Link
              to="/caretimecalendar"
              replace={pathName === "/caretimecalendar" ? true : false}
              title={Languages.bookingMake}
            >
              <ListItem
                id="test_Menu_OpenAbsMent"
                onClick={() => false}
                style={{ color: "white" }}
              >
                <ListItemIcon style={{ color: "white" }}>
                  <CalendarToday
                    alt={"Uusi hoitoaikavaraus New care time booking"}
                    height="28"
                  />
                </ListItemIcon>
                <ListItemText primary={Languages.bookingMake} />
              </ListItem>
            </Link>
          </>
        )}

        {(oldCalendarEnabled() || bothcalendarSettingsNUll()) && (
          <>
            <Link
              /*to="/nursingtimes"*/ to={{
                pathname: "/nursingtimes",
                state: { readNursingTimes: true },
              }}
              replace={pathName === "/nursingtimes" ? true : false}
              title={Languages.bookingMake}
            >
              <ListItem
                id="test_Menu_OpenCalendar"
                onClick={() => false}
                style={{ color: "white" }}
              >
                <ListItemIcon style={{ color: "white" }}>
                  <EventNoteRounded
                    alt={"Varaus Caretimes Calendar"}
                    height="28"
                  ></EventNoteRounded>
                </ListItemIcon>

                <ListItemText primary={Languages.bookingMake} />
                {mainstats.nursingTimesStatusAllDone === false ? (
                  <Warning
                    titleAccess="warning varoitus"
                    data-testid="caretimes-warning-picture-3hs9dn3b"
                    color="error"
                  />
                ) : (
                  <div data-testid="caretimes-warning-picture-notpresent"></div>
                )}
              </ListItem>
            </Link>

            <Link
              to="/notifyabsent"
              replace={pathName === "/notifyabsent" ? true : false}
              title={Languages.menuNotifyAbsence}
            >
              <ListItem
                id="test_Menu_OpenAbsMent"
                onClick={() => false}
                style={{ color: "white" }}
              >
                <ListItemIcon style={{ color: "white" }}>
                  <EventBusyRounded
                    alt={"Poissaolo Notify about Absence"}
                    height="28"
                  />
                </ListItemIcon>
                <ListItemText primary={Languages.menuNotifyAbsence} />
              </ListItem>
            </Link>
          </>
        )}
        {optimizedMessaging() ? (
          <>
            <Link
              to="/messaging2"
              replace={pathName === "/messaging2" ? true : false}
              title={Languages.menuMessaging}
            >
              <ListItem
                data-testid="messaging-link-0934ks03"
                id="test_Menu_OpenMessaging"
                style={{ color: "white" }}
              >
                <ListItemIcon style={{ color: "white" }}>
                  <Badge
                    badgeContent={messageData.notReadThreads}
                    color="error"
                  >
                    <ChatBubble alt={"Viestitys Messaging"} height="28" />
                  </Badge>
                </ListItemIcon>
                <ListItemText primary={Languages.menuMessaging} />
              </ListItem>
            </Link>
          </>
        ) : (
          <>
            <Link
              to="/messaging"
              replace={pathName === "/messaging" ? true : false}
              title={Languages.menuMessaging}
            >
              <ListItem
                data-testid="messaging-link-0934ks03"
                id="test_Menu_OpenMessaging"
                style={{ color: "white" }}
              >
                <ListItemIcon style={{ color: "white" }}>
                  <Badge badgeContent={mainstats.notReadThreads} color="error">
                    <ChatBubble alt={"Viestitys Messaging"} height="28" />
                  </Badge>
                </ListItemIcon>
                <ListItemText primary={Languages.menuMessaging} />
              </ListItem>
            </Link>
          </>
        )}

        <Link
          to="/permissions"
          replace={pathName === "/permissions" ? true : false}
          title={Languages.permissionQuestionsGen}
        >
          <ListItem style={{ color: "white" }}>
            <ListItemIcon style={{ color: "white" }}>
              <Badge
                badgeContent={
                  !mainstats.TotalUnansweredPermissions
                    ? null
                    : mainstats.TotalUnansweredPermissions
                }
                color="error"
              >
                <LiveHelp alt={"Luvat Permissions"} height="28" />
              </Badge>
            </ListItemIcon>
            <ListItemText primary={Languages.permissionQuestionsGen} />
          </ListItem>
        </Link>
        {discussionsEnabled() && (
          <Link
            to="/discussionBooking"
            replace={pathName === "/discussionBooking" ? true : false}
          >
            <ListItem
              data-testid="discussionBooking-link-0934lj87"
              /*button*/ id="test_Menu_OpenDiscussionBooking"
              style={{ color: "white" }}
            >
              <ListItemIcon style={{ color: "white" }}>
                <Badge badgeContent={0} color="error">
                  <InterpreterMode
                    alt={
                      "Keskusteluajanvaraus / Discussion Booking / Boka diskussion"
                    }
                    height="28"
                  />
                </Badge>
              </ListItemIcon>
              <ListItemText primary={Languages.discDiscussionBooking} />
              {mainstats.AvailableDiscussionBookableDatesCount > 0 && (
                <Warning
                  titleAccess="warning varoitus"
                  style={{ color: "#e036da" }}
                />
              )}
            </ListItem>
          </Link>
        )}

        <Link
          to="/placementinformation"
          replace={pathName === "/placementinformation" ? true : false}
          title={Languages.placementDayCareCentre}
        >
          <ListItem
            id="test_Menu_PlacementInformation"
            style={{ color: "white" }}
          >
            <ListItemIcon style={{ color: "white" }}>
              <HomeWorkIcon alt={"Sijoitukset Placements"} height="28 " />
            </ListItemIcon>
            <ListItemText primary={Languages.placementDayCareCentre} />
          </ListItem>
        </Link>

        {/*KasvunKansion MainNavin toteutus */}
        {getGrowthfolderEnabledSetting() ? (
          <div>
            <ListItem
              button
              id="OpenGrowthfolder"
              style={{ color: "white" }}
              onClick={(e) => {
                changeGrowthFolderMenuOpen(e);
              }}
              title={Languages.pdGrowthFolderTitle}
            >
              <ListItemIcon>
                <img
                  src={KasvunkansioIcon}
                  alt={"KasvunKansio"}
                  height="28"
                ></img>
              </ListItemIcon>
              <ListItemText
                primary={Languages.pdGrowthFolderTitle}
                primaryTypographyProps={{ style: { whiteSpace: "normal" } }}
              />
              {growthFolderMenuOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse in={growthFolderMenuOpen} timeout="auto" unmountOnExit>
              <List disablePadding>
                {childList.map((child) => {
                  return (
                    <ListItem
                      key={child.Id}
                      button
                      className="growthMenuItem"
                      onClick={() => openGrowthFolder(child.Id)}
                    >
                      <ListItemText primary={child.Name} />
                    </ListItem>
                  );
                })}
              </List>
            </Collapse>

            <Menu
              open={subMenuOpen}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {childList.map((child) => {
                return (
                  <MenuItem
                    key={child.Id}
                    onClick={() => openGrowthFolder(child.Id)}
                  >
                    {child.Name}
                  </MenuItem>
                );
              })}
            </Menu>
          </div>
        ) : null}

        <Link
          /*to="/settings">*/ to={{
            pathname: "/settings",
            state: { resetmenu: true },
          }}
          replace={pathName === "/settings" ? true : false}
          title={Languages.menuSettings}
        >
          <ListItem
            id="test_Menu_OpenSettings"
            style={{ color: "white", marginBottom: "3px" }}
          >
            <ListItemIcon style={{ color: "white" }}>
              <SettingsRounded alt={"Asetukset"} height="28 " />
            </ListItemIcon>
            <ListItemText primary={Languages.menuSettings} />
          </ListItem>
        </Link>
        <Divider />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            marginTop: "10px",
          }}
        >
          <ListItem
            button
            id="test_Menu_Logout"
            style={{ color: "#FFF", font: "bold" }}
            onClick={logout}
            title={Languages.menuLogout}
          >
            <ListItemIcon style={{ color: "white" }}>
              <BackspaceRounded alt={"LogOut"} height="28" />
            </ListItemIcon>
            <ListItemText primary={Languages.menuLogout} />
          </ListItem>
        </div>
      </List>
    );
  }
};

export default MainMenuLinks;

import React      from "react";
import Typography from "@mui/material/Typography";
import Languages  from '../../../translations';         
import Dialog     from "@mui/material/Dialog";
import DialogTitle  from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon  from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import ErrorIcon from '@mui/icons-material/Error';

const AnswerEveryQuestionPopUp = ({isShown, closeNullWarningPopUp}) => {

  if (!isShown) {
    return(null);
  }

  return (
    <Dialog 
      PaperProps={{
        style: {
          backgroundColor: "#F9E0E5",
          border: "solid 1px #E18994",
          borderLeft: "solid 6px #B30036",
          color: "black",
        },
      }}
      onClose={closeNullWarningPopUp} 
      aria-labelledby="close-popup" 
      open={isShown}
    >
      {/*<DialogTitle id="dialog-NullWarningPopUp"><ErrorIcon sx={{color: "#B30036"}} /> <b>{Languages.permAnswersNotSaved}</b> 
      <IconButton sx={{float: "right", display: "inline-flex"}} aria-label="close" onClick={closeNullWarningPopUp} size="large"><CloseIcon/></IconButton>
      <br />
      {Languages.permAnswerAllQuestions}
      </DialogTitle>*/}
      <DialogContent sx={{display: "block"}}>
        <Typography sx={{display: "inline", fontSize: "1.30rem"}}variant="subtitle1" >{Languages.permAnswersNotSaved}<IconButton sx={{float: "right"}} aria-label="close" onClick={closeNullWarningPopUp} size="large"><CloseIcon/></IconButton> </Typography>
        <br/>
        <Typography sx={{display: "inline"}}>{Languages.permAnswerAllQuestions}</Typography>
      </DialogContent>
    </Dialog>
  );
}

export default AnswerEveryQuestionPopUp;
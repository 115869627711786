import React, { useState, useEffect } from "react";
import {useStateValue} from "../../../State";
import Typography from "@mui/material/Typography";
import Languages              from '../../../translations';
//import {isAnyPropUndefinedOrNull} from "../../utils";
import Radio               from "@mui/material/Radio";
//import Button                 from "@mui/material/Button";
import TableContainer         from "@mui/material/TableContainer";
import Paper                  from "@mui/material/Paper";
import Table                  from "@mui/material/Table";
import TableBody              from "@mui/material/TableBody";
import TableFooter            from "@mui/material/TableFooter";
import TableRow               from "@mui/material/TableRow";
import TableCell              from "@mui/material/TableCell";
import FormControl            from "@mui/material/FormControl";
import FormLabel              from "@mui/material/FormLabel";
import RadioGroup             from "@mui/material/RadioGroup";
import FormControlLabel       from "@mui/material/FormControlLabel";
import AnswerEveryQuestionPopUp from "./AnswerEveryQuestionPopUp";
//import ClickAwayListener      from "@mui/material/ClickAwayListener";
//import ClickAwayNotePopUp     from "./ClickAwayNotePopUp";
import _cloneDeep             from "lodash/cloneDeep";
import SaveConsentsButton     from "./SaveConsentsButton";
import CircularProgress       from "@mui/material/CircularProgress";
//import Button                 from "@mui/material/Button";

const GuardianConsents = ({guardianConsentData, isShown}) => {

  const { state, actions } = useStateValue();

  const postGuardianConsentStatus = state.municipalpermissionquestions.saveGuardianConsent.status;

  const [copyOfData, setCopyOfData] = useState(_cloneDeep(guardianConsentData.MunicipalPermissionQuestions));

  const [currentlyChecked, setCurrentlyChecked] = useState(copyOfData);
  const [somethingChanged, setSomethingChanged] = useState(false);
  const [sentData, setSentData] = useState();
  const [prevStatus, setPrevStatus] = useState("NEVER_LOADED");

  const [nullWarning, setNullWarning] = useState(false);

  useEffect(() => {
    setCopyOfData(_cloneDeep(guardianConsentData.MunicipalPermissionQuestions));
  }, [guardianConsentData.MunicipalPermissionQuestions]);

  useEffect(() => {
    if(prevStatus === "LOADING_POST_GUARDIAN_CONSENTS" && postGuardianConsentStatus === "POST_GUARDIAN_CONSENTS_SUCCESS") {
      setCopyOfData(_cloneDeep(sentData));
    }
    if(prevStatus === "LOADING_POST_GUARDIAN_CONSENTS" && postGuardianConsentStatus === "POST_GUARDIAN_CONSENTS_FAILED") {
      setSomethingChanged(true);
    }
    setPrevStatus(postGuardianConsentStatus);
  }, [postGuardianConsentStatus, prevStatus, setCopyOfData, sentData])

  useEffect(() => {
    if(prevStatus === "LOADING_POST_GUARDIAN_CONSENTS" && postGuardianConsentStatus === "POST_GUARDIAN_CONSENTS_SUCCESS") {
      actions.triggerLoadPermissionsMainStats();
      actions.triggerAddToSnackBarQueue({color: "#000000", message: Languages.permParentConsentSaved});
    }
    if(prevStatus === "LOADING_POST_GUARDIAN_CONSENTS" && postGuardianConsentStatus === "POST_GUARDIAN_CONSENTS_FAILED") {
      actions.triggerLoadPermissionsMainStats();
    }
    setPrevStatus(postGuardianConsentStatus);
  }, [postGuardianConsentStatus, prevStatus, actions])

  


  useEffect(() => {
    return function cleanUp() {
      actions.triggerCleanPostGuardianConsents();
    };
  }, []);

  const processCheckedThings = (isChecked, e) => {
    //console.log("Event", e.target.checked);
    //console.log("Value", e.target.value);
    let updatedArray = currentlyChecked;
    updatedArray.forEach((perm) => {
      if (perm.QuestionId === e.target.value) {
        perm.AnswerStatus = isChecked;
      }
    });
    setCurrentlyChecked(updatedArray);
    console.log("currentlyChecked", currentlyChecked);

    let isDifferent = false;

    updatedArray.forEach((updated) => {
      for(let i = 0 ; i < copyOfData.length; i++){
        if (updated.QuestionId === copyOfData[i].QuestionId) {
          if (updated.AnswerStatus !== copyOfData[i].AnswerStatus){
            isDifferent = true;
          }
        }
      }
    })

    if(isDifferent){
      setSomethingChanged(true);      
    }
    else{
      setSomethingChanged(false);
    }
  }

  const checkBoxOnChangeYes = (e) => {
    processCheckedThings(true, e);
  }

  const checkBoxOnChangeNo = (e) => {
    processCheckedThings(false, e);
  }

  const saveChangesToServer = () => {
    const isValid = checkValidity(currentlyChecked);

    if (isValid) {
      //console.log("currentlyChecked before trigger", currentlyChecked);
      actions.triggerPostGuardianConsents(currentlyChecked);
      setNullWarning(false);
      setSomethingChanged(false);
      setSentData(_cloneDeep(currentlyChecked));
    }
    else {
      setNullWarning(true);
    }
  }

  const checkValidity = (listToSend) => {
    let isValid = true; 
    
    listToSend.forEach((item) => {
      if(item.AnswerStatus == null) {
        isValid = false;
      }
    })
    return(isValid);
  }

  const closeNullWarningPopUp = () => {
    setNullWarning(false);
  }

/*
  const addOneMore = () => {
    actions.triggerAddToSnackBarQueue({color: "#cdcdcd", message: "Success button 1"});
  }

  const addAnother = () => {
    actions.triggerAddToSnackBarQueue({color: "#000000", message: "Success button 2"});
  }
  
  const addSuccessThree = () => {
    actions.triggerAddToSnackBarQueue({ message: "Success button 3"});
  }

          <Button onClick={addOneMore}>One</Button>
          <Button onClick={addAnother}>Two</Button>
          <Button onClick={addSuccessThree}>Three</Button>
*/

  if(!isShown) {
    return(<div></div>)
  }
  
  return(
    <div>

     {/* <SaveConsentsButton
        saveChanges={saveChangesToServer}
        showButton={somethingChanged}
  />*/}

      {guardianConsentData && guardianConsentData.MunicipalAnnouncementText ?
        <>
          <Typography>{guardianConsentData.MunicipalAnnouncementText}</Typography><br/>
        </>
        : <div></div>
      }

      {postGuardianConsentStatus === "LOADING_POST_GUARDIAN_CONSENTS" ? 
        <div style={{margin: "auto"}}> <CircularProgress/> {Languages.permSaving} </div> : null
      }

      {postGuardianConsentStatus === "POST_GUARDIAN_CONSENTS_FAILED" ? 
        <div style={{margin: "auto", backgroundColor: "#e036da"}}> <Typography>{Languages.permSavingFailed + " "} {state.municipalpermissionquestions.saveGuardianConsent.Code}</Typography></div> : null
      }

      {currentlyChecked && currentlyChecked.length > 0 ?

      <>

          <AnswerEveryQuestionPopUp 
            isShown={nullWarning}
            closeNullWarningPopUp={closeNullWarningPopUp}
          />
          <SaveConsentsButton
          saveChanges={saveChangesToServer}
          showButton={somethingChanged}
          />
          <br />
          <br />
          <TableContainer color="secondary" style={{marginLeft: "-2px"}} /*component={Paper}*/>
            <Table size="small" aria-label="permissions questions">
              <TableBody>
                {currentlyChecked.map((perms, index) => {               
                  return(
                      <TableRow key={perms.QuestionId} style={{backgroundColor: "#ffffff"}}>                      
                          
                          <TableCell component="th" style={{maxWidth: "10px"}}> <div style={perms.AnswerStatus === null ? {color: "#e036da", borderLeft: "6px solid #EE5711", height: "40px", } : {color: "#0095d8", borderLeft: "6px solid white", height: "40px"}}></div></TableCell>
                          <TableCell component="th" scope="row">
                          {/* Ripple was disabled because 1.3.2021 ripple causes buggy flickering in Chrome, changed back in Sept 2021*/}
                          <FormControl component="fieldset" style={{color: "#000000"}}>
                          <FormLabel component="legend" style={{color: "#000000"}}>{perms.QuestionName}</FormLabel>
                              <RadioGroup row aria-label={perms.QuestionName} name={perms.QuestionName}>
                                <FormControlLabel 
                                  value={perms.QuestionId} 
                                  checked={perms.AnswerStatus === true ? true : false} 
                                  control={<Radio disableRipple={false}/>} 
                                  label={Languages.permYes}
                                  onChange={checkBoxOnChangeYes}/>
                                <FormControlLabel 
                                  value={perms.QuestionId} 
                                  checked={perms.AnswerStatus === false ? true : false}
                                  control={<Radio disableRipple={false}/>} 
                                  label={Languages.permNo}
                                  onChange={checkBoxOnChangeNo}/>
                              </RadioGroup>
                          </FormControl>
                        </TableCell>
                      </TableRow>   
                  )
                })}               
              </TableBody>
            </Table>
          </TableContainer>
          <br /> 
          <SaveConsentsButton
          saveChanges={saveChangesToServer}
          showButton={somethingChanged}
          />
     
          {/*<div>
            <Button disabled={!somethingChanged} variant="contained" color="primary" onClick={saveChangesToServer}>Tallenna muutokset</Button>
          </div>*/}
        </>
        :
        <Typography>{Languages.permNoQuestionsToShow}</Typography>
      
      }
    </div>
  )
}

export default GuardianConsents;
import React                  from "react";
import Typography             from "@mui/material/Typography";
import Languages              from '../../../translations';
import Grid                   from "@mui/material/Grid";
import RoomIcon               from "@mui/icons-material/Room";
import {containsMoreThanEmptySpaces} from "../../../utils";
import Call                   from "@mui/icons-material/Call";
import {isAnyPropUndefinedOrNull} from "../../../utils";
import HomeWorkIcon         from '@mui/icons-material/HomeWork';
import GroupIcon         from '@mui/icons-material/Group';
import Paper from "@mui/material/Paper";



const PlacementInfoLoadSuccessView = (props) => {

  const {placementData, oneChild} = props;
  
  if (isAnyPropUndefinedOrNull(props)){
    return(<div data-testid="PlacementInfoLoadSuccessViewUndefinedOrNull"></div>)
  }

  return (
    <React.Fragment>
      <Grid container direction="row" justifyContent="center" spacing={1} component={Paper}>
      {placementData ? placementData.map((centre, index) => {
        return (
          
        <Grid width="100%" key={"centre" + index} item xs={12} style={{wordWrap: "breakWord", backgroundColor: "white", color: "black"}}>
          
            <Typography id={"test_placem_centreName" + index} variant="h6" component="h3" color="secondary">{/*Languages.ci_CentreName*/}<HomeWorkIcon /> <b>{centre.CentreName}</b></Typography>

            {centre.OpenTime && centre.OpenTime.OpenTimes ?
              
              <Typography id={"test_placem_centreOpenTimes" + index}><b>{Languages.ci_OpenTimes}:</b> {centre.OpenTime.OpenTimes}</Typography>   
              :
              <div data-testid={"placementInfo-OpenTimes-notfound" + index}></div>
            }
            {centre.OpenTime && centre.OpenTime.CentreOpenType ?
              
              <Typography id={"test_placem_centreOpenTime" + index}><b>{Languages.ci_OpenType}:</b> {centre.OpenTime.CentreOpenType}</Typography> : 
              <div data-testid={"placementInfo-OpenTimes2-notfound" + index}></div> 
            }
            <Typography id={"test_placem_centreAddress" + index}><b>{Languages.ci_CentreAddress}:</b> {centre.CentreAddress} {containsMoreThanEmptySpaces(centre.CentreAddress) && <a target="_blank" rel="noreferrer noopener" href={"https://maps.google.com?q=" + centre.CentreAddress }><RoomIcon/></a>}</Typography> 
              
            <Typography id={"test_placem_centrePhone" + index}><b>{Languages.ci_CentrePhone}:</b> {centre.CentrePhone} {centre.CentrePhone !== null && <a href={"tel:" + centre.CentrePhone}><Call/></a>}</Typography>
            <Typography id={"test_placem_managerName" + index}><b>{Languages.ci_ManagerName}:</b> {centre.ManagerName}</Typography>
            <br />
            <div style={{marginLeft: "15px"}}>
              {centre.GroupInfoList ? centre.GroupInfoList.map((group, index) => {
                return (
                  <React.Fragment key={"group" + index}>
                    <Typography variant="h8" component="h5" id={"test_placem_groupName" + index} color="secondary">{/*Languages.ci_GroupName*/}<GroupIcon/> <b>{group.GroupName}</b></Typography>
                    
                    {group.GroupOpenTimeList ? group.GroupOpenTimeList.map((listItem, index) => {
                      return (
                          <Typography id={"test_placem_listItem" + index} key={"listItem_" + index}><b>{listItem}</b></Typography>
                      )  
                      })
                    : null
                    }
                    <Typography id={"test_placem_groupPhone" + index}><b>{group.GroupPhone !== null && Languages.ci_GroupPhone + ":"}</b> {group.GroupPhone} {group.GroupPhone !== null && <a href={"tel:" + group.GroupPhone}><Call/></a>}</Typography>
                    

                    {group.ChildPlInfoList ? group.ChildPlInfoList.map((individualChild, index) => {
                        return(
                          <React.Fragment key={individualChild + index}>
                            <div style={{marginLeft: "15px"}}>
                              {oneChild === false && <Typography variant="body2" id={"test_placem_indChildName" + index}>{individualChild.ChildName}</Typography>}
                              <Typography variant="body2"><i>{Languages.ci_placementGoodUntil}</i></Typography>
                              <Typography id={"test_placem_placementStartEnd" + index} variant="body2"><i>{individualChild.PlacementStart} -{individualChild.PlacementEnd !== null ? <span>{" " + individualChild.PlacementEnd} </span>: ""}</i></Typography>
                            </div>
                          </React.Fragment>
                        )
                      })
                    : null}
                    <br/>

                  </React.Fragment>
                )
              })
              : null                   
              }
            </div>
            <hr />
        </Grid>
          
        )
      }) : null}
      </Grid>
    </React.Fragment>
  );

}

export default PlacementInfoLoadSuccessView;
import React, { useState, useEffect } from "react";
import {useStateValue} from "../../../../State";

import Moment           from "moment";

import Languages        from "../../../../translations";

import Accordion        from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon   from '@mui/icons-material/ExpandMore';
import Typography       from "@mui/material/Typography";

import Table            from '@mui/material/Table';
import TableBody        from "@mui/material/TableBody";
import TableCell        from "@mui/material/TableCell";
import TableHead        from "@mui/material/TableHead";
import TableRow         from "@mui/material/TableRow";

import LockIcon         from '@mui/icons-material/Lock';

import makeStyles from '@mui/styles/makeStyles';


const useStyles = makeStyles(theme => ({
  nobottomline: {
    borderBottom: "none",
    color: "black",
    backgroundColor: "#ffffff",
    flex: "0 0 100%"
  },
  fixedbottom: {
    float: "right !important",
    fontSize: "x-large",
    position: "fixed",
    fontWeight: "bold", 
    color: "#D500F9",
    right: "0",
    bottom: "0",
    zIndex: "1040 !important"
  },
  displaynone:{
    display: "none"
  }
}));

const ConsultationChildAndGuardian = (props) => {

  const classes = useStyles();

  const { Form, callback, Lockingcallback, closeView } = props;

  const { state/*, actions*/ } = useStateValue();
  
  const MainGeneralPart = state.leops.GeneralPart;

  const Table3   = state.leops.Table3;
  const SubModel = Form !== undefined ? Form === 0 ? Table3.EvaluationModel : Table3.StudyModel : null;
  const Table3GeneralPart = Table3 === null ? null : Table3.GeneralPart;
  const GeneralPart = Table3GeneralPart === null ? null : Table3GeneralPart[Form];

  const [expanded, setExpanded] = useState(false);
  const [FormLocked,     setFormLocked] = useState(SubModel.Locked);
  const [Parent1Checked, setParent1Checked] = useState(SubModel.HearedParent1);
  const [Parent2Checked, setParent2Checked] = useState(SubModel.HearedParent2);
  const [SendReq,        setSendReq]        = useState(false);

  //console.log("ConsultationChildAndGuardian. MOUNT. \n FormLocked: ", FormLocked, "\n SubModel: ", SubModel);

  useEffect(() => {
    /*console.log("ConsultationChildAndGuardian. useEffect. ",
                "\n Parent1Checked: ", Parent1Checked,
                "\n Parent2Checked: ", Parent2Checked, 
                "\n GeneralPart.Parent2Name: ",GeneralPart.Parent2Name,
                "\n FormLocked: ", FormLocked,
                "\n SendReq: ", SendReq);*/

    if (SendReq)
    {
      //setFormLocked(Parent1Checked && ( Parent2Checked || GeneralPart.Parent2Name === null) ? true : false);

      //kumpi ja mihin suuntaan ruksi muutettu, montako parenttia => serveri päättelee meneekö lukkoon
      Lockingcallback(SendReq, SubModel.HearedParent1Editable ? Parent1Checked : Parent2Checked, GeneralPart.Parent2Name === null ? 1 : 2);
      setSendReq(false);
    }

  },[SubModel, Parent1Checked, Parent2Checked, SendReq]); 

  useEffect(() => {
    //console.log("Table1. useEffect. Close view");
    setExpanded(false);
  },[closeView]); 

  const toggleExpanded = () => {
    setExpanded(!expanded);
    callback("CCG");
  }

  const toogleCheckBox = (CheckBox) => {
    //console.log("ConsultationChildAndGuardian. toogleCheckBox: ",CheckBox);

    if (CheckBox === "1")
    {
      setParent1Checked(!Parent1Checked);
    }
    else
    {
      setParent2Checked(!Parent2Checked);
    }
    setSendReq(CheckBox);
  }

  return (
      <Accordion className="painikkeet"
                 style={{ backgroundColor: "#4377BA"/*, color: "black"*/ }}
                 //disabled = {item.Saved === "" ? true: false}
                 expanded={expanded}
                 onChange={() => toggleExpanded()}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel3a-content"
                          id="test_Leops_SupportTableCCG">
          <Typography className="heading"> {Languages.ConsultationChildAndGuardian}
            {FormLocked ?
              <LockIcon style={{color: "#D500F9"}} fontSize="large"></LockIcon>
            : null}
          </Typography>
        </AccordionSummary>
        <div className={classes.nobottomline}>
          <AccordionDetails>
            <h5>{Languages.ConsultationChildAndGuardian2}</h5>
          </AccordionDetails>
          <AccordionDetails>
            <h6>{(Languages.LeopsStudy6_2)}</h6>
          </AccordionDetails>

          <AccordionDetails>
            <Table>  
              <TableHead>
                <TableRow >
                  <TableCell className={classes.nobottomline} padding="none" align="left" >{Languages.guardian + " 1"}</TableCell> 
                  <TableCell className={classes.nobottomline} align="left" style={{paddingLeft: "20px"}} >{Languages.guardian + " 2"}</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow >
                  <TableCell className={classes.nobottomline} padding="none" align="left">
                    <input style={{width: '20px', height: '20px'}} id="test_CCG_PARENT_1" type="checkbox" data-indeterminate="false" checked={Parent1Checked} disabled={!SubModel.HearedParent1Editable || MainGeneralPart.FormLocked} onChange={() => toogleCheckBox("1")} />{"  " + GeneralPart.Parent1Name}
                  </TableCell> 
                  <TableCell className={classes.nobottomline} align="left" style={{paddingLeft: "20px"}}>
                    <input style={{width: '20px', height: '20px'}} id="test_CCG_PARENT_1" type="checkbox" data-indeterminate="false" checked={Parent2Checked} disabled={!SubModel.HearedParent2Editable || MainGeneralPart.FormLocked} onChange={() => toogleCheckBox("2")} />{"  " + (GeneralPart.Parent2Name === null ? "" : GeneralPart.Parent2Name)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
        </AccordionDetails>
        </div>

      </Accordion>
  );
}

export default ConsultationChildAndGuardian;
import { ApiBase } from "../internal";
import Moment from "moment";
import SessionManager from "../Auth/SessionManager";

export class CareTimeApi extends ApiBase {
  constructor() {
    super();
  }

  //Initial caretimes read at first time opening caretimebooking -- Loads longer period of caretimes to offset lazyloading when scrolling
  async readNursingTimes2(dates) {
    const userLang = SessionManager.getUserLanguage();
    let data = { requestedMonth: null, start: null, end: null };

    if (dates.date2 != null) {
      data = {
        requestedMonth: Moment(dates.date).startOf("month").format("MM"),
        start: Moment(dates.date).format("YYYY-MM-DD"),
        end: Moment(dates.date2).format("YYYY-MM-DD"),
      };
    } else {
      data = {
        requestedMonth: Moment(dates.date).startOf("month").format("MM"),
        start: Moment(dates.date)
          .startOf("month")
          .startOf("isoWeek")
          .format("YYYY-MM-DD"),
        end: Moment(dates.date)
          .endOf("month")
          .endOf("isoWeek")
          .add(1, "weeks")
          .format("YYYY-MM-DD"),
      };
    }

    try {
      const response = await this.httpClient.get(
        "/nursingtimes/getnursingtimes2/" +
          data.requestedMonth +
          "/" +
          data.start +
          "/" +
          data.end,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": userLang === "en" ? "en-US" : userLang,
          },
        }
      );
      return response;
    } catch (error) {
      const responseData = {
        status:
          error.response === undefined
            ? 502
            : error.response.status === undefined
            ? 502
            : error.response.status,
      };
      return responseData;
    }
  }

  // Reads next caretimes from the server when scrolled down
  async readNursingTimesNext(dates) {
    const userLang = SessionManager.getUserLanguage();

    let data = { requestedMonth: null, start: null, end: null };

    if (dates.date2 != null) {
      data = {
        requestedMonth: Moment(dates.date).startOf("month").format("MM"),
        start: Moment(dates.date).format("YYYY-MM-DD"),
        end: Moment(dates.date2).format("YYYY-MM-DD"),
      };
    } else {
      data = {
        requestedMonth: Moment(dates.date).startOf("month").format("MM"),
        start: Moment(dates.date)
          .startOf("month")
          .startOf("isoWeek")
          .format("YYYY-MM-DD"),
        end: Moment(dates.date)
          .endOf("month")
          .endOf("isoWeek")
          .add(1, "weeks")
          .format("YYYY-MM-DD"),
      };
    }

    try {
      const response = await this.httpClient.get(
        "/nursingtimes/getnursingtimes2/" +
          data.requestedMonth +
          "/" +
          data.start +
          "/" +
          data.end,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": userLang === "en" ? "en-US" : userLang,
          },
        }
      );
      return response;
    } catch (error) {
      const responseData = {
        status:
          error.response === undefined
            ? 502
            : error.response.status === undefined
            ? 502
            : error.response.status,
      };
      return responseData;
    }
  }

  // Reads previous caretimes from the server when scrolled up
  async readNursingTimesPrev(dates) {
    const userLang = SessionManager.getUserLanguage();

    let data = { requestedMonth: null, start: null, end: null };

    if (dates.date2 != null) {
      data = {
        requestedMonth: Moment(dates.date).startOf("month").format("MM"),
        start: Moment(dates.date).format("YYYY-MM-DD"),
        end: Moment(dates.date2).format("YYYY-MM-DD"),
      };
    } else {
      data = {
        requestedMonth: Moment(dates.date).startOf("month").format("MM"),
        start: Moment(dates.date)
          .startOf("month")
          .startOf("isoWeek")
          .format("YYYY-MM-DD"),
        end: Moment(dates.date)
          .endOf("month")
          .endOf("isoWeek")
          .add(1, "weeks")
          .format("YYYY-MM-DD"),
      };
    }

    try {
      const response = await this.httpClient.get(
        "/nursingtimes/getnursingtimes2/" +
          data.requestedMonth +
          "/" +
          data.start +
          "/" +
          data.end,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": userLang === "en" ? "en-US" : userLang,
          },
        }
      );
      return response;
    } catch (error) {
      const responseData = {
        status:
          error.response === undefined
            ? 502
            : error.response.status === undefined
            ? 502
            : error.response.status,
      };
      return responseData;
    }
  }

  //Reads the Quick info if caretimes or statuses exist on each day to display them in calendar nav drawer // still WIP
  async readNursingTimesQuick(dates) {
    const userLang = SessionManager.getUserLanguage();
    const data = {
      start: dates.startDate,
      end: dates.endDate,
    };
    try {
      const response = await this.httpClient.get(
        "/nursingtimes/getnursingtimesquick/" + data.start + "/" + data.end,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": userLang === "en" ? "en-US" : userLang,
          },
        }
      );
      return response;
    } catch (error) {
      const responseData = {
        status:
          error.response === undefined
            ? 502
            : error.response.status === undefined
            ? 502
            : error.response.status,
      };
      return responseData;
    }
  }

  async readNursingTimesQuickNext(dates) {
    const userLang = SessionManager.getUserLanguage();
    const data = {
      start: dates.startDate,
      end: dates.endDate,
    };
    try {
      const response = await this.httpClient.get(
        "/nursingtimes/getnursingtimesquick/" + data.start + "/" + data.end,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": userLang === "en" ? "en-US" : userLang,
          },
        }
      );
      return response;
    } catch (error) {
      const responseData = {
        status:
          error.response === undefined
            ? 502
            : error.response.status === undefined
            ? 502
            : error.response.status,
      };
      return responseData;
    }
  }

  //Pre-saving check for the caretimes to see if the saving is allowed
  async checkCareTimes(Data) {
    const userLang = SessionManager.getUserLanguage();
    let NewReservations = [];
    let NewReservationInfo = [];
    let RequestData = {};

    Data.selectedDates.forEach((date) => {
      Data.ListOfReservationsTimes.forEach((time, timeIndex) => {
        Data.reservationsChilds
          .find((child, cListIndex) => {
            return cListIndex === timeIndex;
          })
          .forEach((c, cIndex) => {
            NewReservationInfo = {
              PersonId: c.Id,
              PlacementId: c.PlId,
              Start: Moment(time.Start).format("HH:mm"),
              End:
                Moment(time.End).format("HH:mm") === "00:00"
                  ? "23:59:59"
                  : Moment(time.End).format("HH:mm"),
              Date: Moment(date.Date).format("DD.MM.YYYY"),
            };
            NewReservations.push(NewReservationInfo);
          });
      });
    });

    RequestData = {
      MultiReservations: NewReservations,
      Types: Data.Types,
      DeleteOld: Data.DeletedOld,
      EveningPreSchool: Data.eveningPreSchool,
    };

    const response = await this.httpClient.post(
      "/nursingtimes/checkexistingnursingtimes/",
      RequestData,
      {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": userLang === "en" ? "en-US" : userLang,
        },
      }
    );

    return response;
  }

  //Actually save the caretimes if pre-check approves
  async saveCareTimes(Data) {
    const userLang = SessionManager.getUserLanguage();
    let NewReservations = [];
    let NewReservationInfo = [];
    // NOTE: start and End values are set to 01.01.2022 to ensure correct DST time. If using Moment(Date) or Moment() start and endtimes could shift 1 hour if Date or Moment() i.e. current date is DST clock moving date.
    // so the date should be hard-coded to something else. The date part is formatted but this ensures the time is correct
    //console.log(Data);

    //console.log(Moment());
    Data.Dates.forEach((date) => {
      Data.PersonList.forEach((person) => {
        const NewReservationInfo = {
          PersonId: person.PersonId,
          PlacementId: person.PlacementId,
          Start: Moment()
            .startOf("day")
            .add(Data.StartTime, "minutes")
            .format("HH:mm"),
          End:
            Moment()
              .startOf("day")
              .add(Data.EndTime, "minutes")
              .format("HH:mm") === "00:00"
              ? Moment()
                  .set({ hour: 23, minute: 59, second: 59 })
                  .format("HH:mm")
              : Moment()
                  .startOf("day")
                  .add(Data.EndTime, "minutes")
                  .format("HH:mm"),
          Date: Moment(date).format("DD.MM.YYYY"),
          Type: person.Type,
        };

        // Check if the day is not Saturday (6) or Sunday (7)
        if (
          Moment(date).isoWeekday() !== 6 &&
          Moment(date).isoWeekday() !== 7 &&
          !Data.Holidays.some(
            (e) =>
              Moment(e.Date).format("MM.DD.YYYY") ==
              Moment(date).format("MM.DD.YYYY")
          )
        ) {
          NewReservations.push(NewReservationInfo);
        } else {
          // Check if it's a weekend day and the person is allowed
          if (
            Moment(date).isoWeekday() === 6 &&
            person.allowSaturday === true &&
            !Data.Holidays.some(
              (e) =>
                Moment(e.Date).format("MM.DD.YYYY") ==
                Moment(date).format("MM.DD.YYYY")
            )
          ) {
            NewReservations.push(NewReservationInfo);
          }
          if (
            (Moment(date).isoWeekday() === 7 ||
              Data.Holidays.some(
                (e) =>
                  Moment(e.Date).format("MM.DD.YYYY") ==
                  Moment(date).format("MM.DD.YYYY")
              )) &&
            person.allowSunday === true
          ) {
            NewReservations.push(NewReservationInfo);
          }
        }
      });
    });

    let RequestData = {};

    RequestData = {
      MultiReservations: NewReservations,
      DeleteOld: Data.DeleteOld,
      EveningPreSchool: Data.EveningPreSchool,
      FocusDate: Moment.min(Data.Dates).format("DD.MM.YYYY"),
    };
    const response = await this.httpClient.post(
      "/nursingtimes/savenursingtimes2/",
      RequestData,
      {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": userLang === "en" ? "en-US" : userLang,
        },
      }
    );
    return response;
  }

  //Own function for saving absences and sick days
  async saveCareTimesAbsent(Data) {
    const userLang = SessionManager.getUserLanguage();
    let NewReservations = [];
    let NewReservationInfo = [];
    console.log("DATA IS HERE", Data);
    Data.Dates.forEach((date) => {
      Data.PersonList.forEach((person) => {
        NewReservationInfo = {
          PersonId: person.PersonId,
          PlacementId: null,
          Start: Moment(date).startOf("day").add(8, "hours").format("HH:mm"),
          End: Moment(date).startOf("day").add(16, "hours").format("HH:mm"),
          Date: Moment(date).format("DD.MM.YYYY"),
          Type: person.AbsentReason === "Poissa" ? "A" : "S",
        };
        //NewReservations.push(NewReservationInfo);
        // Check if the day is not Saturday (6) or Sunday (7)
        if (
          Moment(date).isoWeekday() !== 6 &&
          Moment(date).isoWeekday() !== 7 &&
          !Data.Holidays.some(
            (e) =>
              Moment(e.Date).format("MM.DD.YYYY") ==
              Moment(date).format("MM.DD.YYYY")
          )
        ) {
          NewReservations.push(NewReservationInfo);
        } else {
          // Check if it's a weekend day and the person is allowed
          if (
            Moment(date).isoWeekday() === 6 &&
            person.allowSaturday === true &&
            !Data.Holidays.some(
              (e) =>
                Moment(e.Date).format("MM.DD.YYYY") ==
                Moment(date).format("MM.DD.YYYY")
            )
          ) {
            NewReservations.push(NewReservationInfo);
          }
          if (
            (Moment(date).isoWeekday() === 7 ||
              Data.Holidays.some(
                (e) =>
                  Moment(e.Date).format("MM.DD.YYYY") ==
                  Moment(date).format("MM.DD.YYYY")
              )) &&
            person.allowSunday === true
          ) {
            NewReservations.push(NewReservationInfo);
          }
        }
      });
    });

    let RequestData = {};

    RequestData = {
      MultiReservations: NewReservations,
      DeleteOld: Data.DeleteOld,
      EveningPreSchool: Data.EveningPreSchool,
      FocusDate: Moment.min(Data.Dates).format("DD.MM.YYYY"),
    };
    const response = await this.httpClient.post(
      "/nursingtimes/savenursingtimes2/",
      RequestData,
      {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": userLang === "en" ? "en-US" : userLang,
        },
      }
    );
    return response;
  }

  async deleteCaretimes(Data) {
    try {
      const response = await this.httpClient.delete(
        "/nursingtimes/deletemany2/",
        Data
      );

      //console.log("NURSINGTIMES API. Response: ", response);
      return response;
    } catch (error) {
      console.error(
        "CARETIMEs API CATCH ERROR. Delete nursingtimes. error.response: ",
        error.response
      );
      let responseData = {
        status: error.response.status,
        message: error.response.data.Message,
      };
      //console.error("NURSINGTIMES API CATCH ERROR. Delete nursingtimes. response: ", responseData );

      return responseData;
    }
  }

  async saveClockType(clocktypedata) {
    //= async () => {
    /*console.log("NURSINGTIMES API. saveDefaultNursingtimes: "+
                "\n StartTime: ", Moment(DefaultTimesData.StartTime).format('HH:mm'),
                "\n EndTime: ",   Moment(DefaultTimesData.EndTime).format('HH:mm'));*/
    const userLang = SessionManager.getUserLanguage();

    const DefaultTimes = {
      Type: clocktypedata.Type,
    };

    try {
      const response = await this.httpClient.post(
        "/nursingtimes/saveclocktype/",
        DefaultTimes,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": userLang === "en" ? "en-US" : userLang,
          },
        }
      );
      //console.log("NURSINGTIMES API. Save new default nursingtimes. OK: ", response);
      const responseData = {
        status: 200,
        Type: clocktypedata,
      };
      return responseData;
    } catch (error) {
      /*console.log("NURSINGTIMES API. Save new default nursingtimes. ERROR: ", error, "\n"+
      " response.status: ", error.response === undefined ? 502 : error.response.status === undefined ? 502 : error.response.status);*/
      const responseData = {
        status:
          error.response === undefined || error.response.status === undefined
            ? 502
            : error.response.status,
      };
      return responseData;
    }
  }

  async loadClockType() {
    const userLang = SessionManager.getUserLanguage();

    try {
      const response = await this.httpClient.get(
        "/nursingtimes/loadclocktype/",
        {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": userLang === "en" ? "en-US" : userLang,
          },
        }
      );
      //console.log("NURSINGTIMES API. Response: ", response);
      const responseData = { status: 200, data: response.data };
      return responseData;
    } catch (error) {
      console.log(
        "NURSINGTIMES API. load clock type ERROR: ",
        error,
        "\n" + "response.status: ",
        error.response === undefined
          ? 502
          : error.response.status === undefined
          ? 502
          : error.response.status
      );
      // 502 Bad Gateway
      const responseData = {
        status:
          error.response === undefined || error.response.status === undefined
            ? 502
            : error.response.status,
      };
      return responseData;
    }
  }
}

import {types} from "./actionTypes";

const ChildFilesReducer = (state, action) => {

  switch(action.type) {

    case types.LOADING_CHILD_FILES:
      return {
        ...state,
          filesList: {
            status: "LOADING_CHILD_FILES",
            filesList: [],
            Code: undefined
          }
      }
    
    case types.CHILD_FILES_LOAD_SUCCESS:
      return {
        ...state,
          filesList: {
            status: "CHILD_FILES_LOAD_SUCCESS",
            filesList: action.data,
            Code: action.code
          }
      }

    case types.CHILD_FILES_LOAD_FAILED:
      return {
        ...state,
          filesList: {
            status: "CHILD_FILES_LOAD_FAILED",
            filesList: [],
            Code: action.code
          }
      }

    default:
      return state;
  }

}

export default ChildFilesReducer;
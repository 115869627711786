import React, {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import {detect} from "detect-browser";
import * as semver from "semver";
import useMediaQuery from '@mui/material/useMediaQuery';
import InfoModal from "../Modals/InfoModal";
import Button from "@mui/material/Button";
import Languages from "../../translations";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

const UpdateBrowserReminder = () => {

  const browser = detect();
  const isDesktop = useMediaQuery('(min-width:720px)');

  const [browserName, setBrowserName] = useState("");
  const [browserVersion, setBrowserVersion] = useState("");
  const [browserWarning, setBrowserWarning] = useState(false);
  const [supportedBrowsersOpen, setSupportedBrowsersOpen] = useState(false);
  const [osType, setOsType] = useState("Unknown");

  useEffect(() => {
    if (browser) {
      
      switch (browser.os) {
        case "iOS":
          setOsType("apple");
          break;
        case "Mac OS":
          setOsType("apple");
          break;
        case "Android OS":
          setOsType("android");
          break;
        case "Windows 10":
          setOsType("Win");
          break;
        case "Chrome OS":
          setOsType("chromeOS");
          break;
        case "Windows 7":
          setOsType("Win");
          break;
        case "Windows 8":
          setOsType("Win");
          break;
        case "Windows 8.1":
          setOsType("Win");
          break;
        case "Windows 11": //this is not supported by the library yet, but in case it will be...
          setOsType("Win");
          break;
        case "Sun OS":
          setOsType("Unknown");
          break;
        case "Linux":
          setOsType("linux");
          break;
        case "Windows 3.11":
          setOsType("Win");
          break;
        case "Windows 95":
          setOsType("Win");
          break;
        case "Windows 98":
          setOsType("Win");
          break;
        case "Windows 2000":
          setOsType("Win");
          break;
        case "Windows XP":
          setOsType("Win");
          break;
        case "Windows Vista":
          setOsType("Win");
          break;
        case "Windows ME":
          setOsType("Win");
          break;
        case "Windows CE":
          setOsType("Win");
          break;
        case "Windows Mobile":
          setOsType("Unknown");
          break;
        case "BlackBerry OS":
          setOsType("Unknown");
          break;
        default:
          setOsType("Unknown");
        }
    }
  }, []);


  const scrollTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  const showWarning = () => {
    setBrowserWarning(true);
    scrollTop();
  }

  useEffect(() => {

    if (browser) {
      //console.log(browser.name + " " + browser.version);
      setBrowserWarning(false);
      setBrowserName(browser.name);
      setBrowserVersion(browser.version);
      console.log("OS: ", browser.os);

      switch (browser.name) {
        case "chrome":
          setBrowserName("Chrome");
          if(browser.OS == "Android OS") {
            if(semver.satisfies(browser.version, ">=97.0.0")) {
              console.log("Satisfies Chrome browser requirement for Android OS");
              setBrowserWarning(false);
            }
            else {
              console.log("Does not satisfy Chrome browser requirement for Android OS");
              showWarning();
            }
          }
          else {
            if(semver.satisfies(browser.version, ">=90.0.0")) {
              console.log("Satisfies Chrome browser requirement for non-Android OS");
              setBrowserWarning(false);
            }
            else {
              console.log("Does not satisfy Chrome browser requirement for non-Android OS");
              showWarning();
            }
          }
          break;

        case "chromium-webview":
          setBrowserName("Chrome-webview");
          if(semver.satisfies(browser.version, ">=90.0.0")) {
            console.log("Satisfies chromium-webview browser requirement");
            setBrowserWarning(false);
          }
          else {
            console.log("Does not satisfy chromium-webview browser requirement");
            showWarning();
          }
          break;

        case "firefox":
          setBrowserName("Firefox");
          if(browser.OS == "Android OS") {
            if(semver.satisfies(browser.version, ">=95.0.0")) {
              console.log("Satisfies Firefox Android browser requirement");
              setBrowserWarning(false);
            }
            else {
              console.log("Does not satisfy Firefox Android browser requirement");
              showWarning();
            } 
          }
          else {
            if(semver.satisfies(browser.version, ">=78.0.0")) {
              console.log("Satisfies Firefox browser requirement");
              setBrowserWarning(false);
            }
            else {
              console.log("Does not satisfy Firefox browser requirement");
              showWarning();
            } 
          }
          break;

        case "edge":
          setBrowserName("Edge");
          if(semver.satisfies(browser.version, ">=91.0.0")) {
            console.log("Satisfies Edge browser requirement");
            setBrowserWarning(false);
          }
          else {
            console.log("Does not satisfy Edge browser requirement");
            showWarning();
          }
          break;

        case "edge-chromium":
          setBrowserName("Edge");
          if(semver.satisfies(browser.version, ">=91.0.0")) { //91.0.0
            console.log("Satisfies Edge browser requirement");
            setBrowserWarning(false);
          }
          else {
            console.log("Does not satisfy Edge browser requirement");
            showWarning();
          }
          break;

        case "edge-ios":
          setBrowserName("Edge iOS");
            console.log("Edge iOS does not satisfy browser requirement");
            showWarning();
          break;  

        case "ios":
          setBrowserName("iOS");
          if(semver.satisfies(browser.version, ">=12.5.0")) {
            console.log("Satisfies ios browser requirement");
            setBrowserWarning(false);
          }
          else {
            console.log("Does not satisfy ios browser requirement");
            showWarning();
          }
          break;  

        case "ios-webview":
          setBrowserName("iOS-webview");
          if(semver.satisfies(browser.version, ">=12.5.0")) {
            console.log("Satisfies ios browser requirement");
            setBrowserWarning(false);
          }
          else {
            console.log("Does not satisfy ios browser requirement");
            showWarning();
          }
          break;  

        case "safari":
          setBrowserName("Safari");
          if(semver.satisfies(browser.version, ">=14.0.0")) {
            console.log("Satisfies ios browser requirement");
            setBrowserWarning(false);
          }
          else {
            console.log("Does not satisfy ios browser requirement");
            showWarning();
          }
          break;  

        case "samsung":
          setBrowserName("Samsung");  
          console.log("Samsung browser does not satisfy requirement");
          showWarning();
          break;

        case "android":
          setBrowserName("Android");
          console.log("Android does not satisfy browser requirement");
          showWarning();
          break;

        case "chrios":
            setBrowserName("Apple Chrome (CriOS)");
            console.log("Does not satisfy Apple browser requirement");
            showWarning();
          break;

        default: 
          console.log("Selaimen versionumeroa ei pystytty vertaamaan");
          console.log("browser.name ja version: " + browser.name + " " + browser.version);
          showWarning();
      }

    }
    else {
      setBrowserName(Languages.broUnknown);
      setBrowserVersion(Languages.broUnknown);
      showWarning();
    }


  }, [])


  //const browserRequirements = "Chrome 90, Edge 91, Safari (macOs) 14, Safari (iOS) 12.5, Firefox 78"
  // MUI5 requirements: Chrome 90, Edge 91, Safari (macOs) 14, Safari (iOS) 12.5, Firefox 78, Android 91, Samsung 13.
  // Download Attribute: Edge 97, Firefox 20, Chrome 14, Safari 10.1-15.1, Opera 15-81, Safari on iOS 13-15.1, Android Browser 4.44-4.4.4, Opera Mobile 64,
  // Chrome for Android 97, Firefox for Android 95, UC Browser for Android 12.12, Samsung Internet 4-14.0


  const android = "Chrome 97, Edge 91, Firefox 78";
  const apple = "Safari 14 (macOS), Safari 12.5 (iOS)";
  
  const appleNoti2 = Languages.broForAttachments;
  const forDownloading = " (" + appleNoti2 +")";
  const iOsText1 = "Safari 15.1";
  const iOsText2 = "Safari 12.5";
  const macOsText1 = "Safari 15.1";
  const macOsText2 = "Safari 14";
  const windows = "Chrome 90, Edge 91, Firefox 78";
  const linux = "Chrome 90, Firefox 78";
  const chromeos = "Chrome 97";


  const BrowserRequirements = () => {
    if (osType == "Win") {
      return(windows);
    }
    if (osType == "apple") {
      return("");
    }
    if (osType == "android") {
      return(android);
    }
    if (osType == "linux") {
      return(linux);
    }
    /*if (osType == "sunOS") {
      return("")
    }*/
    if (osType == "chromeOS") {
      return("Chrome 90");
    }
    /*if (osType == "windowsmobile") {
      return("Chrome 90")
    }*/
    if (osType == "Unknown") {
      return("");
    }

    return("");
  }

  const Texts = () => {
    if (isDesktop) {
      return(
        <>
          <Typography color="textPrimary">{Languages.broSeems}</Typography>
          <Typography color="textPrimary">{Languages.broYourBrowserIs} <b>{browserName}</b> {Languages.broYourVersionIs}: <b>{browserVersion}</b>.
          </Typography> <br/>
          {osType !== ("Unknown") ? <Typography color="textPrimary">{Languages.broDaisyFamilySupports}:</Typography> : <p></p>}
          {osType !== ("Unknown") ? <Typography color="textPrimary"><BrowserRequirements/></Typography> : <p></p>}
          <Button aria-label={Languages.broAllSupportedVersions} variant="outlined" style={{color: "white"}} size="small" onClick={() => setSupportedBrowsersOpen(true)}>{Languages.broAllSupportedVersions}</Button>
        </>
      )
    }
    return(
      <>
        <Typography color="textPrimary">{Languages.broSeems}</Typography> 
        <Typography color="textPrimary">{Languages.broYourBrowserIs} <b>{browserName}</b> {Languages.broYourVersionIs}: <b>{browserVersion}</b>.</Typography>
        <Button aria-label={Languages.broAllSupportedVersions} sx={{marginTop:"4px"}}color="secondary" variant="contained" size="small" onClick={() => setSupportedBrowsersOpen(true)}>{Languages.broAllSupportedVersions}</Button>
      </>
    )
  }


  if (browserWarning) {
    return(
      <>
        <div style={{margin: "auto", backgroundColor:"#181818", borderLeftStyle: "solid", borderLeftColor:"#ff8080", borderWidth: "8px"}}>
          
          <div style={{minHeight:"10px"}}></div>
            <div style={{margin: "auto"}}>
              <div style={{marginLeft:"10px", marginRight:"10px"}}>
                <Texts/>
              </div>
            </div>
            <div style={{minHeight:"10px"}}></div>
          
        </div>
        
        <InfoModal 
          modalOpen={supportedBrowsersOpen} 
          headerText={Languages.broAllSupportedVersions} 
          onClose={() => setSupportedBrowsersOpen(false)}
          allowClose={true}
        >
          <Typography color="textSecondary" style={{marginBottom: "10px", marginTop:"10px"}}>{Languages.broDaisyFamilySupports2}:</Typography>

          <Typography color="textSecondary" style={{marginBottom: "10px"}}><b>Apple iOs:</b></Typography>
          <ul>
            <li>
              <Typography color="textSecondary" style={{marginBottom: "10px", marginLeft:"5px"}}>{iOsText1}<br/>{forDownloading}</Typography>
            </li>
            <li>
              <Typography color="textSecondary" style={{marginBottom: "10px", marginLeft:"5px"}}>{iOsText2}</Typography>
            </li>
          </ul>
          <Typography color="textSecondary" style={{marginBottom: "10px"}}><b>Apple MacOs:</b></Typography>
          <ul>
            <li>
              <Typography color="textSecondary" style={{marginBottom: "10px", marginLeft:"5px"}}>{macOsText1}<br/>{forDownloading}</Typography>
            </li>
            <li>
              <Typography color="textSecondary" style={{marginBottom: "10px", marginLeft:"5px"}}>{macOsText2}</Typography>
            </li>
          </ul>
          <Typography color="textSecondary" style={{marginBottom: "10px"}}><b>Android:</b></Typography>
          <ul>
            <li>
              <Typography color="textSecondary" style={{marginBottom: "10px", marginLeft:"5px"}}>{android}</Typography>
            </li>
          </ul>
          <Typography color="textSecondary" style={{marginBottom: "10px"}}><b>Windows:</b></Typography>
          <ul>
            <li>
              <Typography color="textSecondary" style={{marginBottom: "10px"}}>{windows}</Typography>
            </li>
          </ul>
          <Typography color="textSecondary" style={{marginBottom: "10px"}}><b>Chrome OS:</b></Typography>
          <ul>
            <li>
              <Typography color="textSecondary" style={{marginBottom: "10px"}}>{chromeos}</Typography>
            </li>
          </ul>
          <Typography color="textSecondary" style={{marginBottom: "10px"}}><b>Linux:</b></Typography>
          <ul>
            <li>
              <Typography color="textSecondary" style={{marginBottom: "10px"}}>{linux}</Typography>
            </li>
          </ul>
          <Typography color="textSecondary" style={{marginBottom: "10px"}}>{Languages.broOtherOperatingSystems}</Typography>
          <Typography color="textSecondary" style={{marginBottom: "10px"}}><b>{Languages.broUpdateYourBrowserRegularly}</b></Typography>
        </InfoModal>
      </>
    );
  }

  return(null);

}

export default UpdateBrowserReminder;

import React from 'react';
import Typography from "@mui/material/Typography";
import Languages from "../../../translations";
import {Link} from "react-router-dom";
import Button                 from '@mui/material/Button';

const NewUserLinkPasswordLoginOnly = () => {
  return(
      <>
        <Typography color="textSecondary">
          {Languages.firstTimer}
        </Typography>
        <Button variant="outlined" color="secondary">
        <Link to="/newuser" style={{color: "#4377BA"}}>
          {Languages.signUp}  
        </Link>
        </Button>
  </>
  );
}

export default NewUserLinkPasswordLoginOnly;

import PropTypes from 'prop-types'
import { Typography } from '@mui/material'

const convertUrlsToLinks = (string) => {
  const urlRegex = /([a-z][a-z0-9+\-.]*:\/\/[a-z0-9\-._~%!$&'()*+,;=]+@?[a-z0-9\-._~%]+|\[[a-z0-9\-._~%!$&'()*+,;=:]+\])([\/\?äÄöÖåÅa-z0-9\.\=\#\%\+\;\'\!\-]*)/ig
  const result = string?.replace(urlRegex, (match, p1, p2) => {
    // lowercase only host part of url
    const tmp = `${p1.toLowerCase()}${p2}`;
    return `<a href="${tmp}" target="_new_daisy">${tmp}</a>`
  })

  return result
}

const MessageText = ({children, component, ...props}) => {
  const convertedText = convertUrlsToLinks(children)

  return (
    <Typography component={component} {...props}
      dangerouslySetInnerHTML={{ __html: convertedText }}
    >
    </Typography>  
  )
}

MessageText.defaultProps = {
  component: 'span'
}

MessageText.propTypes = {
  children: PropTypes.string,
  component: PropTypes.string //Component used to render typography
}

export { MessageText }
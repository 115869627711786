import React from "react";
import BackToUpButton from "../GuideTools/BackToUpButton";
import Languages from "../../../translations";
import makeStyles from '@mui/styles/makeStyles';
import CircleIcon from '@mui/icons-material/Circle';
import AddIcon from '@mui/icons-material/Add';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckIcon from '@mui/icons-material/Check';

const useStyles = makeStyles({
  success: {
    color: 'rgb(129, 199, 132)',
    fontSize: 'xx-large',
  }
});



const MessageThreadAccordionText = () => {
  const classes = useStyles();
    return(
      <>
      <div>
      <h4>{Languages.messageThreadSubTitle1}</h4>
          <br/>
          <div>
          <ol>
          <li className="guide-list-item-styles">{Languages.messageThreadList1} <b>{Languages.messageThreadList2}</b> {Languages.messageThreadList3}<CircleIcon sx ={{color: "rgb(129, 199, 132)", fontSize: "3em", position: 'relative', left: '13px'}}/> <AddIcon style={{position: 'relative', right: '30px'}}/>{Languages.messageThreadList4}</li>
          <br />
          <li className="guide-list-item-styles">{Languages.messageThreadList5} <CheckBoxOutlineBlankIcon/>/<CheckBoxIcon sx={{color: "#4377BA"}}/> {Languages.messageThreadList6} <b>{Languages.messageThreadList7}</b></li>
          <br />
          <li className="guide-list-item-styles">{Languages.messageThreadList8} <b>{Languages.messageThreadList9}</b> {Languages.messageThreadList10}<CircleIcon sx ={{color: "rgb(129, 199, 132)", fontSize: "3em", position: 'relative', left: '13px'}}/><CheckIcon style={{position: 'relative', right: '26px'}}/>{Languages.messageThreadList11} {Languages.messageThreadList12} <b>{Languages.messageThreadList13}</b> {Languages.messageThreadList14}</li>
          <br />
          <p className="guide-list-item-styles">{Languages.messageThreadList12} <b>{Languages.messageThreadList15}</b> {Languages.messageThreadList16}</p>
          <br />
          <li className="guide-list-item-styles">{Languages.messageThreadList17} <b>{Languages.messageThreadList18}</b> {Languages.messageThreadList19}</li>
          <br />
          <li className="guide-list-item-styles">{Languages.messageThreadList20} <b>{Languages.messageThreadList21}</b> {Languages.messageThreadList22}</li>
          </ol>
          </div>
          <br />
        <BackToUpButton />
        </div>
      </>
    )
}

export default MessageThreadAccordionText
import React, { useState, useEffect } from "react";
import {useStateValue} from "../../State";
import Typography from "@mui/material/Typography";
import Accordion         from '@mui/material/Accordion';
import AccordionSummary  from '@mui/material/AccordionSummary';
import AccordionDetails  from '@mui/material/AccordionDetails';
import ExpandMoreIcon         from '@mui/icons-material/ExpandMore';
import AssignmentTurnedInIcon         from '@mui/icons-material/AssignmentTurnedIn';
import Languages              from '../../translations';
import ChildPermPermissionSuccess from "./Components/ChildPermPermissionSuccess";
import {isAnyPropUndefinedOrNull} from "../../utils";
import Badge                  from "@mui/material/Badge";
import withStyles from '@mui/styles/withStyles';
import CircularProgress from '@mui/material/CircularProgress';


const styles = {
  color: "#4377BA",
  fontSize: "14pt",
  fontWeight: "bold",
}

const MunicipalPermissionQs = (props) => {

  const StyledBadge = withStyles(theme => ({
    badge: {
      right: -20,
      top: 10,
      padding: '0 4px',
      width: 25,
      height: 25,
      borderRadius: 50,
    },
  }))(Badge);

  const {childId, childColor = "#4377BA"} = props;

  const { state, actions } = useStateValue();
  const {mainstats} = state; 

  const data = state.municipalpermissionquestions.childPermPermissionsList.data;

  const [mpqPanelOpen, setMpqPanelOpen] = useState(false);

  const changeOpennessState = () => {
    setMpqPanelOpen(!mpqPanelOpen);
  }

  const [ isMount, setIsMount ] = useState(true);
  const [ unansweredNumber, setUnansweredNumber] = useState(null);

  useEffect(() => {
    if (isMount && mpqPanelOpen) {
      actions.triggerLoadMunicipalPermissionQuestions(childId);
      setIsMount(false);
    }
  }, [isMount, setIsMount, actions, props, mpqPanelOpen])

  useEffect(() => {
    if (!mainstats || !mainstats.UnansweredChildPermPermissions || 
      typeof mainstats.UnansweredChildPermPermissions === "undefined" ||
      typeof mainstats.UnansweredChildPermPermissions[0].UnansweredPermPerms === "undefined" || 
      !mainstats.UnansweredChildPermPermissions[0].UnansweredPermPerms)
      {
        setUnansweredNumber(null);
      }
    else {
      const index = mainstats.UnansweredChildPermPermissions.findIndex(x => x.ChildId === childId);
      if (!index) {
        setUnansweredNumber(null);
      }
      else {
        const unansweredperms = mainstats.UnansweredChildPermPermissions[index].UnansweredPermPerms;

        if ( unansweredperms === 0) {
          setUnansweredNumber(null);
        } 
        else {
          setUnansweredNumber(unansweredperms)
        }
      }
    }
  }, [mainstats]);


  if (isAnyPropUndefinedOrNull(props)) {
    return(<div data-testid="municipalPermQuestionsPropsUndefinedOrNull"></div>)
  }

  return (

    <React.Fragment>
    
    <div style={{marginTop: "10px"}}></div>

      <Accordion id="test_ChildInfo_MunicipalPermQuestions"
        style={{ border: "5px", borderLeft: "12px", borderStyle:"solid", borderBottomLeftRadius: "15px", borderBottomRightRadius: "15px", borderColor: childColor, backgroundColor: "#ffffff", color: "#181818"}}
        expanded={mpqPanelOpen}
        onChange={changeOpennessState}
      >

        <AccordionSummary 
          expandIcon={<ExpandMoreIcon />} 
          aria-controls="municipal-permission-questions-panel1a-content"
          id="test_municipal-permissions"
          data-testid={"municipal-permissions-extendable-button" + mpqPanelOpen}>
            <StyledBadge 
              data-testid="unansweredChildPermPermissions-title" 
              badgeContent={unansweredNumber}
              color="error"
            >
              <Typography style={styles}><AssignmentTurnedInIcon /> <b style={{marginLeft: "10px", display: "inline"}}>{Languages.permMunicipalPermissionQuestions}</b></Typography>
            </StyledBadge>
        </AccordionSummary>

        <AccordionDetails>

          {state.municipalpermissionquestions.childPermPermissionsList.status === "LOAD_CHILD_PERMPERMISSIONS_SUCCESS" &&

            <ChildPermPermissionSuccess
              muniPermData={data}
              childId={childId}
              isShown={true}
            />

          }

          {state.municipalpermissionquestions.childPermPermissionsList.status === "LOAD_CHILD_PERMPERMISSIONS_FAILED" &&

            <Typography 
              id="test_municipal_permissions_errCouldNotLoadData" 
              data-testid="municipal_permissions_errCouldNotLoadData" 
              component={"span"}>{Languages.errCouldNotLoadData}
            </Typography>

          }

          {state.municipalpermissionquestions.childPermPermissionsList.status === "LOADING_CHILD_PERMPERMISSIONS" &&

            <>
      <CircularProgress />
    <br />
            <Typography 
              id="test_municipal_permissions_waitingservertoloaddata" 
              component={"span"}>{Languages.waitingservertoloaddata}
            </Typography>
            </>
          }

        </AccordionDetails>

      </Accordion>


        
    </React.Fragment>
  ) 
        

}

export default MunicipalPermissionQs;
import React from "react";
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Typography from "@mui/material/Typography";
import Languages from "../../../../translations";
import BackupParentInfoReadOnlyField from "./BackupParentInfoReadOnlyField";
import BackupParentInfoEditField from "./BackupParentInfoEditField";
import Box from "@mui/material/Box"
import ErrorIcon from '@mui/icons-material/Error';

const BackupParentInfoReadOnly = ({ backupPersons, formStatus, onFormFieldChange }) => {

  return (
    <Typography component={'span'}>
      {backupPersons.map((parent, BUparentIndex) => {

        const invalidPhoneNumberFound = formStatus.find(status => status.field === "BUParentPhoneNumber" && status.personId === parent.personId && status.valid === false);
        const invalidWorkPhoneNumberFound = formStatus.find(status => status.field === "BUParentWorkNumber" && status.personId === parent.personId && status.valid === false);

        return (
          <div key={"bu_parent"+BUparentIndex}>
            <Divider></Divider>
            <br/>

            {invalidPhoneNumberFound && BUparentIndex === 0 ?
              <div style={{display: "flex"}}>
               {/* <TextField 
                  error={true}
                  name="BUParentPhoneNumberErrorInfoText"
                  id="outlined-read-only-input"
                  InputProps={{ readOnly: true, disableunderline: "true", className: "marginTop" }}
                  type="text"
                  helperText={Languages.ciInfo1} />*/}
                  <Box 
                  sx={{
                    backgroundColor: "#F9E0E5",
                    border: "1px solid #E18994",
                    borderLeft: "5px solid #B30036",
                    padding: "10px",
                    display: "flex"

                  }} 
                    id="outlined-read-only-input"
                  
                  ><div style={{height: "100%", textAlign: "center", padding: "10px", display: "flex", alignItems: "center"}}><ErrorIcon sx={{color:"#B30036"}}/></div>
                  {Languages.ciInfo1}</Box>
                <br/><br /></div>
              : null
            }
            <br />
            {parent.newField ? 
              <BackupParentInfoEditField
                formStatus={formStatus}
                BUparentIndex={BUparentIndex}
                parent={parent}
                formFieldChange={onFormFieldChange}
              />
              :
              <BackupParentInfoReadOnlyField parent={parent} />
            }
            
          </div>
        );
      })}
    </Typography>

  )
}

export default BackupParentInfoReadOnly;
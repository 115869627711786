import React from "react";
import BackToUpButton from "../GuideTools/BackToUpButton";
import AddAlarmIcon from "@mui/icons-material/AddAlarm";
import Languages from "../../../translations";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SelectAllIcon from "@mui/icons-material/SelectAll";
import makeStyles from "@mui/styles/makeStyles";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";

const useStyles = makeStyles({
  success: {
    color: "rgb(129, 199, 132)",
    fontSize: "xx-large",
  },
  body2: {
    marginLeft: 25,
    paddingBottom: 10,
  },
});

const AddBookingAccordionText2 = () => {
  const classes = useStyles();

  return (
    <>
      <div>
        <h4>{Languages.addBookingTableTitle}</h4>
        <div className="guide-table-container">
          <table className="booking-guide-color-schema-table">
            <thead>
              <tr>
                <th className="table-header-for-colors">{Languages.color}</th>
                <th className="table-header-for-description">
                  {Languages.description}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="white-color-cell"></td>
                <td className="booking-table-description-cell">
                  {
                    "Hoitovarauksia voit tehdä tulevaisuuden päiville, jotka näkyvät varauskalenterissa valkoisella pohjalla"
                  }
                </td>
              </tr>
              <tr>
                <td className="locked-color-cell "></td>
                <td className="booking-table-description-cell">
                  {
                    "Lukittu päivä: hoitovaraukset ovat lukittuneet, jolloin päivälle voi ilmoittaa vain poissaolon."
                  }
                </td>
              </tr>
              <tr>
                <td className="vacation-color-cell"></td>
                <td className="booking-table-description-cell">
                  {
                    "Varausten tekeminen on mahdollista kalenterissa myös vaaleanpunaiselle yleiselle loma-ajalle..."
                  }
                </td>
              </tr>
              <tr>
                <td className="Period-color-cell"></td>
                <td className="booking-table-description-cell">
                  {
                    "… ja oranssille päivystysajalle, jos varausaika ei ole vielä lukkiutunut."
                  }
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <br />
        <p className="guide-accordion-details-texts">
          {
            "Hoitovarauksia voit tehdä tulevaisuuden päiville, jotka eivät ole vielä lukittuneet. Mikäli lapsesi on vuorohoidossa, voi varauksia tehdä myös viikonlopuille."
          }
        </p>
        <br />
        <br />
        <hr className="guide-accordion-details-line" />
        <h4>{Languages.addBookingMiddleTitle}</h4>
        <br />
        <div>
          <ol>
            <li className="guide-list-item-styles">
              {
                "Valitse varauskalenterin alareunasta vihreä varausten muokkauspainike. "
              }
              <EditCalendarIcon />
            </li>
            <br />
            <li className="guide-list-item-styles">
              {"Valitse ”Uusi hoitoaika” tehdäksesi varauksen."}
            </li>
            <br />
            <li className="guide-list-item-styles">
              {
                " Valitse kenelle lapselle/lapsille varaukset tehdään (oletuksena valittu kaikki lapset)."
              }
            </li>
            <br />
            <li className="guide-list-item-styles">
              {
                "Valitse lapsikohtaisesti, minne varaus tehdään. Eri ryhmiin/yksiköihin tehtävät varaukset tehdään erikseen."
              }
            </li>
            <br />
            <li className="guide-list-item-styles">
              {
                "Valitse varauksen kesto. Kellonajat voit merkitä 15 minuutin välein."
              }
            </li>
            <br />
            <li className="guide-list-item-styles">
              {
                "Valitse tehdäänkö varaukset yksittäisille päiville, viikolle vai valitulle aikavälille."
              }
            </li>
            <br />
            <li className="guide-list-item-styles">
              {
                "Valitse päivät, joille varaus tehdään. Valitut päivät näkyvät sinisellä värillä ympyröitynä."
              }
            </li>
            <br />
            <li className="guide-list-item-styles">
              {"Valitse ”Tallenna varaukset”."}
            </li>
            <br />
          </ol>
        </div>
        <br />

        <br />
        <BackToUpButton />
      </div>
    </>
  );
};

export default AddBookingAccordionText2;

import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Badge                  from "@mui/material/Badge";
import withStyles from '@mui/styles/withStyles';
import ChildPermPermissionSuccess2 from "../../MunicipalPermissionQuestions/Components/ChildPermPermissionSuccess2";
import Languages from "../../../translations";
import { isAnyPropUndefinedOrNull } from "../../../utils";
import { useStateValue } from "../../../State";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';


const MunicialPermissionsSingleList = (props) => {

  const {childId, childColor = "#4377BA"} = props;

  const { state, actions } = useStateValue();
  const {mainstats} = state; 

  const data = state.municipalpermissionquestions.childPermPermissionsList.data;

  const [mpqPanelOpen, setMpqPanelOpen] = useState(true);

  const [ isMount, setIsMount ] = useState(true);
  const [ unansweredNumber, setUnansweredNumber] = useState(null);

  useEffect(() => {
    if (isMount && mpqPanelOpen) {
      actions.triggerLoadMunicipalPermissionQuestions(childId);
      setIsMount(false);
    }
  }, [isMount, setIsMount, actions, props, mpqPanelOpen])

  useEffect(() => {
    if (!mainstats || !mainstats.UnansweredChildPermPermissions || 
      typeof mainstats.UnansweredChildPermPermissions === "undefined" ||
      typeof mainstats.UnansweredChildPermPermissions[0].UnansweredPermPerms === "undefined" || 
      !mainstats.UnansweredChildPermPermissions[0].UnansweredPermPerms)
      {
        setUnansweredNumber(null);
      }
    else {
      const index = mainstats.UnansweredChildPermPermissions.findIndex(x => x.ChildId === childId);
      if (!index) {
        setUnansweredNumber(null);
      }
      else {
        const unansweredperms = mainstats.UnansweredChildPermPermissions[index].UnansweredPermPerms;

        if ( unansweredperms === 0) {
          setUnansweredNumber(null);
        } 
        else {
          setUnansweredNumber(unansweredperms)
        }
      }
    }
  }, [mainstats]);


  if (isAnyPropUndefinedOrNull(props)) {
    return(<div data-testid="municipalPermQuestionsPropsUndefinedOrNull"></div>)
  }

  return (
    <React.Fragment>  
    <div style={{marginTop: "10px", marginLeft: "50px"}}></div>    
        <div style={{marginTop: "10px", marginLeft: "10px"}}>
          {state.municipalpermissionquestions.childPermPermissionsList.status === "LOAD_CHILD_PERMPERMISSIONS_SUCCESS" &&
            <ChildPermPermissionSuccess2
              muniPermData={data}
              childId={childId}
              isShown={true}
              changeEditStatusOn={props.changeEditStatusOn}
              changeEditStatusOff={props.changeEditStatusOff}
            />
          }
          {state.municipalpermissionquestions.childPermPermissionsList.status === "LOAD_CHILD_PERMPERMISSIONS_FAILED" &&
            <Typography 
              id="test_municipal_permissions_errCouldNotLoadData" 
              data-testid="municipal_permissions_errCouldNotLoadData" 
              component={"span"}>{Languages.errCouldNotLoadData}
            </Typography>
          }
          {state.municipalpermissionquestions.childPermPermissionsList.status === "LOADING_CHILD_PERMPERMISSIONS" &&            
            <Box sx={{ display: 'flex', width: "100%", textAlign: "center"}}>
              <CircularProgress sx={{margin: "auto"}}/>
            </Box>        
          }
      </div>
    </React.Fragment>
  ) 
}

export default MunicialPermissionsSingleList;
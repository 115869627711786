import React, { useState, useEffect } from "react";
import {useStateValue} from "../../../State";
import "../leops.css";

import Moment           from "moment";

import ContentBoxMUI    from "../../../Components/ContentBoxMUI";
import ChildRow         from '../../Vasu/ChildRow';
import GeneralSection   from '../../Vasu/GeneralPart';
import Languages        from "../../../translations";

import Accordion        from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon   from '@mui/icons-material/ExpandMore';
import Typography       from "@mui/material/Typography";

import LockIcon         from '@mui/icons-material/Lock';

import SubTable1 from "./Tables/Table1";
import SubTable2 from "./Tables/Table2";
import SubTable3 from "./Tables/Table3";
import OutcomeOfTheEvaluation       from "./Tables/OutcomeOfTheEvaluation";
import EnhancedSupportInitiating    from "./Tables/EnhancedSupportInitiating";
import ConsultationChildAndGuardian from "./Tables/ConsultationChildAndGuardian";
import CheckingSpecialSupport       from "./Tables/CheckingSpecialSupport";

import Backdrop         from '@mui/material/Backdrop';
import CircularProgress from "@mui/material/CircularProgress";

import { types } from "../../../Reducers/actionTypes";

const LeopsSupportPart = (props) => {

  const { state, actions } = useStateValue();
  const GeneralPart = state.leops.GeneralPart;
  const Table3      = state.leops.Table3;
  const Items       = state.leops.Table3.Items;
  const SubModel    = Table3.StudyModel;

  //state.leops.state === "INITIAL_STATE"
  const [expandedPanel,   setExpandedPanel]   = useState(-1);
  const [StudyFormLocked, setStudyFormLocked] = useState(SubModel === undefined ? false : SubModel.Locked);
  const [SavingData,      setSavingData]      = useState(false);

  const [CloseGeneralPart, setGeneralPartClosed] = useState(false);
  const [CloseTable1,      setTable1Closed]      = useState(false);
  const [CloseTable2,      setTable2Closed]      = useState(false);
  const [CloseTable3,      setTable3Closed]      = useState(false);
  const [CloseOOTE,        setOOTEClosed]        = useState(false);
  const [CloseESI,         setESIClosed]         = useState(false);
  const [CloseCCG,         setCCGClosed]         = useState(false);
  const [CloseCSS,         setCSSClosed]         = useState(false);

  //console.log("LeopsSupportPart. MOUNT StudyFormLocked: ", StudyFormLocked);

  const { history, location } = props;

  if (location.state === undefined || Items === undefined)
  { history.goBack(); }

  useEffect( () => {
    
    switch(state.leops.state)
    {
      case types.LEOPS_HANDLED:
        if (SavingData)
        {
          setSavingData(false);
        }
        break;
      case types.STUDYFORM_SAVED_OK:
        //console.log("LeopsSupportPart SAVE_OK. state.leops: ", state.leops);
        actions.triggerAddToSnackBarQueue({color: "#000000", message: Languages.formSaved});
        actions.triggerLeopsHandled();
        setSavingData(false);
        setStudyFormLocked(SubModel.Locked);
        break;
      case types.STUDYFORM_SAVED_FAILED:
        //console.log("LeopsSupportPart SAVE_FAILED. state.leops: ", state.leops);
        setSavingData(false);
        var Msg = state.leops.Code === 404 ? Languages.errorNotFound :
                  state.leops.Code === 500 ? Languages.errorBackend : Languages.requestFailed;
        actions.triggerAddToSnackBarQueue({color: "#d500f9", message: Msg});
        actions.triggerLeopsHandled();
        break;
      default:
        //console.log("LeopsSupportPart ",state.leops.state);
        break;
      }
  }, [state]);

  const LockingChanged = (Parent, Status, ParentCount) => {
    //console.log("LeopsSupportPart. LockingChanged. Parent: ",Parent, " Status: ", Status, " ParentCount: ", ParentCount);
    let LockingData = {LeopsId:     GeneralPart.Id,
                       FormId:      SubModel.Id,
                       Parent:      Parent,
                       Status:      Status,
                       ParentCount: ParentCount};
    actions.triggerSaveStudyForm(LockingData);
    setSavingData(true);
  }

  const toggleExpanded = (Form) => {
    //console.log("LeopsSupportPart. toggleExpanded: ", Form);
    switch (Form)
    {
      case 0:
      case 1:
      case 2:
      case 3:
        setExpandedPanel(expandedPanel === Form ? -1 : Form);
        setGeneralPartClosed(!CloseGeneralPart);
        setTable1Closed(!CloseTable1);
        setTable2Closed(!CloseTable2);
        setTable3Closed(!CloseTable3);
        setOOTEClosed(!CloseOOTE);
        setESIClosed(!CloseESI);
        setCCGClosed(!CloseCCG);
        setCSSClosed(!CloseCSS);
        break;
      case "GENERALPART":
        setTable1Closed(!CloseTable1);
        setTable2Closed(!CloseTable2);
        setTable3Closed(!CloseTable3);
        setOOTEClosed(!CloseOOTE);
        setESIClosed(!CloseESI);
        setCCGClosed(!CloseCCG);
        setCSSClosed(!CloseCSS);
        break;
      case "TABLE1":
        setGeneralPartClosed(!CloseGeneralPart);
        setTable2Closed(!CloseTable2);
        setTable3Closed(!CloseTable3);
        setOOTEClosed(!CloseOOTE);
        setESIClosed(!CloseESI);
        setCCGClosed(!CloseCCG);
        setCSSClosed(!CloseCSS);
        break;
      case "TABLE2":
        setGeneralPartClosed(!CloseGeneralPart);
        setTable1Closed(!CloseTable1);
        setTable3Closed(!CloseTable3);
        setOOTEClosed(!CloseOOTE);
        setESIClosed(!CloseESI);
        setCCGClosed(!CloseCCG);
        setCSSClosed(!CloseCSS);
        break;
      case "TABLE3":
        setGeneralPartClosed(!CloseGeneralPart);
        setTable1Closed(!CloseTable1);
        setTable2Closed(!CloseTable2);
        setOOTEClosed(!CloseOOTE);
        setESIClosed(!CloseESI);
        setCCGClosed(!CloseCCG);
        setCSSClosed(!CloseCSS);
        break;
      case "OOTE":
        setGeneralPartClosed(!CloseGeneralPart);
        setTable1Closed(!CloseTable1);
        setTable2Closed(!CloseTable2);
        setTable3Closed(!CloseTable3);
        setESIClosed(!CloseESI);
        setCCGClosed(!CloseCCG);
        setCSSClosed(!CloseCSS);
        break;
      case "ESI":
        setGeneralPartClosed(!CloseGeneralPart);
        setTable1Closed(!CloseTable1);
        setTable2Closed(!CloseTable2);
        setTable3Closed(!CloseTable3);
        setOOTEClosed(!CloseOOTE);
        setCCGClosed(!CloseCCG);
        setCSSClosed(!CloseCSS);
        break;
      case "CCG":
        setGeneralPartClosed(!CloseGeneralPart);
        setTable1Closed(!CloseTable1);
        setTable2Closed(!CloseTable2);
        setTable3Closed(!CloseTable3);
        setOOTEClosed(!CloseOOTE);
        setESIClosed(!CloseESI);
        setCSSClosed(!CloseCSS);
        break;
      case "CSS":
        setGeneralPartClosed(!CloseGeneralPart);
        setTable1Closed(!CloseTable1);
        setTable2Closed(!CloseTable2);
        setTable3Closed(!CloseTable3);
        setOOTEClosed(!CloseOOTE);
        setESIClosed(!CloseESI);
        setCCGClosed(!CloseCCG);
        break;
      default:
        setExpandedPanel(-1);
        setGeneralPartClosed(!CloseGeneralPart);
        setTable1Closed(!CloseTable1);
        setTable2Closed(!CloseTable2);
        setTable3Closed(!CloseTable3);
        setOOTEClosed(!CloseOOTE);
        setESIClosed(!CloseESI);
        setCCGClosed(!CloseCCG);
        setCSSClosed(!CloseCSS);
        break;
    }
  }
  
  if (state.leops.state === "INITIAL_STATE" || location.state === undefined)
  {
    return (
      <div className="LeopsSupportPart">
      </div>
    );
  }
  else
  {
    const { childpicture, childFirstName, childLastName, childColor, childName, SSN } = location.state;

    var Slides = [{Id: Items[0].Id, Title: Languages.PedagogicalEvaluation,        Saved: Items[0].Id === null ? "" : Languages.fileSaved + " " + Moment(Items[0].Saved).format("DD.MM.YYYY")},
                  {Id: Items[1].Id, Title: Languages.PedagogicalStudy,             Saved: Items[1].Id === null ? "" : Languages.fileSaved + " " + Moment(Items[1].Saved).format("DD.MM.YYYY")},
                  {Id: Items[2].Id, Title: Languages.EnhancedSupportLearningPlan,  Saved: Items[2].Id === null ? "" : Languages.fileSaved + " " + Moment(Items[2].Saved).format("DD.MM.YYYY")},
                  {Id: Items[3].Id, Title: Languages.HOJKS,                        Saved: Items[3].Id === null ? "" : Languages.fileSaved + " " + Moment(Items[3].Saved).format("DD.MM.YYYY")}];

    return (
      <div className="LeopsSupportPart">
         <ContentBoxMUI>

          <ChildRow childpicture   = {childpicture}
                    childFirstName = {childFirstName}
                    childLastName  = {childLastName}
                    childColor     = {childColor}
                    FormId         = {GeneralPart.Id}
                    history        = {history}>
          </ChildRow>

          <h5 className="textCenter">3. {Languages.eduPlanTitle3}</h5>

          {SavingData ?
            <div style={{opacity: "0.5"}}>
              <Backdrop open={true}> <CircularProgress/> </Backdrop>
            </div>
          :
          <div style={{ opacity: GeneralPart.FormLocked === true ? "60%" : ""}} >

          {Slides.map((item,index)=> {
            return(
              <Accordion key={"SupportForm_"+index}
                         className="painikkeet"
                         style={{ backgroundColor: "#ECEAE5", color: "black" }} // harmaa tausta, musta teksti tai sininen tausta #4377BA
                         disabled = {item.Saved === "" ? true: false}
                         expanded={ expandedPanel === index}
                         onChange={() => toggleExpanded(index)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="test_Leops_SupportForm0_Btn">
                  <Typography className="heading"> 
                    {item.Title}
                    {index === 1 && StudyFormLocked ?
                      <LockIcon style={{color: "#D500F9"}} fontSize="large"></LockIcon>
                    : null}
                    <br></br>
                    {item.Saved}
                  </Typography>
                </AccordionSummary>
                  <AccordionDetails style={{display: "block"}}>
                    <GeneralSection Form={index} callback ={(status) => toggleExpanded (status)} closeView={CloseGeneralPart} childName = {childName} vasu={false} SSN={SSN}></GeneralSection>
                    
                    {index === 1 ?
                    <CheckingSpecialSupport Form={index} callback ={(status) => toggleExpanded (status)} closeView={CloseCSS}></CheckingSpecialSupport>
                    : null} 
                     
                    <SubTable1      Form={index} callback ={(status) => toggleExpanded (status)} closeView={CloseTable1}></SubTable1>
                    <SubTable2      Form={index} callback ={(status) => toggleExpanded (status)} closeView={CloseTable2}></SubTable2>
                    <SubTable3      Form={index} callback ={(status) => toggleExpanded (status)} closeView={CloseTable3}></SubTable3>
                    
                    {index === 0 ?
                    <EnhancedSupportInitiating Form={index} callback ={(status) => toggleExpanded (status)} closeView={CloseESI} Name={childName} SSN={SSN}></EnhancedSupportInitiating>
                    : null} 

                    {index === 1 ?
                    <div>
                    <EnhancedSupportInitiating    Form={index} callback ={(status) => toggleExpanded (status)} closeView={CloseESI} Name={childName} SSN={SSN}></EnhancedSupportInitiating>
                    <ConsultationChildAndGuardian Form={index} callback ={(status) => toggleExpanded (status)} Lockingcallback ={(Parent,Status, ParentCount) => LockingChanged (Parent, Status, ParentCount)} closeView={CloseCCG} ></ConsultationChildAndGuardian>
                    </div>
                    : null} 

                    {index === 2 || index === 3 ?
                    <OutcomeOfTheEvaluation Form={index} callback ={(status) => toggleExpanded (status)} closeView={CloseOOTE} ></OutcomeOfTheEvaluation>
                    : null}
                    
                  </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
        }

        </ContentBoxMUI>
    </div>
    );
  }
}

export default LeopsSupportPart;
import React from "react";
import ContentBoxMUI     from "../../Components/ContentBoxMUI";
import PlacementInfo  from "../../Layouts/ChildInfo/Components/PlacementInfo.jsx";


const PlacementInfoLayout = () => {

  return (
    <React.Fragment>
      
      <ContentBoxMUI topPositionFix="58px">
    
        <div style={{marginTop: "10px"}}></div>
        <PlacementInfo oneChild={false} panelOpennessState={true}/>
      
      </ContentBoxMUI>
      
    </React.Fragment>
  );

}

export default PlacementInfoLayout;
import React, { useEffect, useState } from "react";

import { useStateValue } from "../../../../State";
import Languages from "../../../../translations";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";

import IconButton from "@mui/material/IconButton";
import moment from "moment";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ChildProfilePicture from "../../../ChildProfilePicture";
import CloseIcon from "@mui/icons-material/Close";

import CheckIcon from "@mui/icons-material/Check";

import Divider from "@mui/material/Divider";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import DialogActions from "@mui/material/DialogActions";

import Chip from "@mui/material/Chip";
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import { types } from "../../../../Reducers/actionTypes";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import CalendarDateSelectTabs from "../common/CalendarDateSelectTabs";
import CalendarTimeSlider from "../common/CalendarTimeSlider";
import CalendarChildSelection from "../common/CalendarChildSelection";
import CalendarCentreSelection from "../common/CalendarCentreSelection";
import ReservationChildSelection from "./ReservationChildSelection";
import Checkbox from "@mui/material/Checkbox";

const CareTimeCalendarNewReservationDialog = ({
  handleClick,
  newResDialogOpen,
  names,
  personName,
  handleFocusDay,
  LoadCareTimesCuick,
}) => {
  const { state, actions } = useStateValue();
  const saved = state.caretimes.CTSaved;
  const [saving, setSaving] = React.useState(false);
  const [saved2, setSaved2] = React.useState(false);
  const [holidays, setHolidays] = React.useState([]);

  const [resCount, setResCount] = React.useState(0);
  useEffect(() => {
    switch (state.caretimes.Quick.state) {
      case undefined:
        break;
      case types.CARETIMES_QUICK_LOAD_OK:
        setExtRes(state.caretimes.Quick.DayList);
        setHolidays(state.caretimes.Quick.HolidayList);
        break;
      case types.CARETIMES_QUICK_LOAD_FAILED:
        break;
      default:
        break;
    }
  }, [state.caretimes.Quick.state]);

  useEffect(() => {
    switch (state.caretimes.CTSaved.state) {
      case types.CARETIMES_SAVED_OK:
        setSaving(false);
        setSaved2(true);

        //state.caretimes.CTSaved.state = undefined;
        break;
      case types.SAVING_CARETIMES:
        setSaving(true);
        break;
      case types.CARETIMES_SAVED_FAILED:
        setSaving(false);
        //state.caretimes.CTSaved.state = undefined;
        //handleClick();
        setSaved2(true);
        break;
      default:
        break;
    }
  }, [state.caretimes.CTSaved.state]);

  useEffect(() => {
    //console.log("auki");

    setExtRes(state.caretimes.Quick.DayList);
    setListOfChildren([]);
    setDeleteOld("");
    setIncludeWeekEnd("");
    setEveningPreschoolChecked(false);
    setHighlightedDays([]);
    setClosePopUp(false);
    setResChildren([]);
    setExistSelected(false);
    setSaving(false);
    setSaved2(false);
    setStartDay(null);
    setEndDay(null);
    setStartDate(null);
    setMode(0);
    setSelectedChildren(names.map((child) => child.Id));
    const initialSelectedPlacements = {};
    names.forEach((child) => {
      initialSelectedPlacements[child.Id] = child.Placements[0].Id;
    });
    setSelectedPlacements(initialSelectedPlacements);
    setResLenght([
      moment
        .duration(moment(defaultNursingTimes.Start).format("HH:mm"))
        .asMinutes(),
      moment
        .duration(moment(defaultNursingTimes.End).format("HH:mm"))
        .asMinutes(),
    ]);
  }, [newResDialogOpen]);

  //Reservation start/end times stored as minutes for the slider component
  const defaultNursingTimes = state.nursingtimes.defaultNursingTimes;
  const [value, setValue] = React.useState([
    moment
      .duration(moment(defaultNursingTimes.Start).format("HH:mm"))
      .asMinutes(),
    moment
      .duration(moment(defaultNursingTimes.End).format("HH:mm"))
      .asMinutes(),
  ]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [existSelected, setExistSelected] = React.useState(false);
  //console.log(existSelected);
  const handleExistSelected = () => {
    setExistSelected(!existSelected);
  };
  //Start and end times stored as minutes
  const [ResLenght, setResLenght] = React.useState([
    moment
      .duration(moment(defaultNursingTimes.Start).format("HH:mm"))
      .asMinutes(),
    moment
      .duration(moment(defaultNursingTimes.End).format("HH:mm"))
      .asMinutes(),
  ]);

  const handleResLenght = (newLenght) => {
    //console.log(moment(newLenght[0]));
    //console.log(newLenght);
    setResLenght(newLenght);
  };

  const timeWarning = () => {
    return (
      moment(ResLenght[0]).isAfter(moment(ResLenght[1])) ||
      moment(ResLenght[0]).isSame(moment(ResLenght[1]))
    );
  };
  const [resChildren, setResChildren] = React.useState([]);

  const handleResChildren = (child, centre) => {
    const existingObjectIndex = resChildren.findIndex(
      (obj) => obj.childId === child
    );

    if (existingObjectIndex === -1) {
      // If object with the same ID doesn't exist, add it to the list
      setResChildren((prevResChildren) => [
        ...prevResChildren,
        {
          childId: child,
          resId: centre.Id,
          Type: centre.Type,
          OpenType: centre.OpenType,
        },
      ]);
    } else {
      // If object with the same ID exists, remove it from the list
      setResChildren((prevResChildren) =>
        prevResChildren.filter((_, index) => index !== existingObjectIndex)
      );
    }
  };
  //console.log(moment());
  const handleWeekEnd = (allowed) => {
    setAllowWeekends(allowed);
  };

  //Handle selected Children
  const [listOfChildren2, setListOfChildren2] = React.useState(
    names.map((child) => child.Id)
  );

  const [listOfChildren, setListOfChildren] = React.useState(
    names.map((child) => child.Id)
  );
  const handleChildren = (event, newPersonNames) => {
    setListOfChildren2(newPersonNames);
  };

  //Handle DeleteOld if existing res days selected
  const [DeleteOld, setDeleteOld] = React.useState("");

  const handleDeleteOld = (event, newReason) => {
    if (newReason !== null) {
      setDeleteOld(newReason);
    }
  };

  const [extRes, setExtRes] = React.useState([]);

  //Handle single selected days
  const [highlightedDays, setHighlightedDays] = React.useState([]);

  const handleHighLightedDays = (newDay) => {
    if (highlightedDays.some((day) => day.isSame(newDay, "day"))) {
      var updated = highlightedDays.filter(
        (day) => day.isSame(newDay, "day") == false
      );
      setHighlightedDays(updated);
    } else {
      highlightedDays.push(moment(newDay));
    }
    //console.log(highlightedDays);
    //console.log(extRes);
  };

  //Handle Week Selection
  const [startDate, setStartDate] = React.useState(null);

  const handleWeek = (date) => {
    setStartDate(date);
  };

  //Handle date Range

  const [startDay, setStartDay] = React.useState(null);
  const [endDay, setEndDay] = React.useState(null);

  const handleDayRange = (newDay) => {
    if (startDay == null) {
      setStartDay(newDay);
    } else {
      if (endDay == null) {
        if (newDay.isSameOrBefore(startDay)) {
          setStartDay(newDay);
        } else {
          setEndDay(newDay);
        }
      } else {
        setStartDay(newDay);
        setEndDay(null);
      }
    }
  };

  const checkExistingRes = () => {
    let ListDates = [];

    switch (mode) {
      case 0:
        ListDates = highlightedDays;
        break;
      case 1:
        for (
          var m = moment(startDate).startOf("week");
          m.isSameOrBefore(moment(startDate).endOf("week"));
          m.add(1, "days")
        ) {
          if (moment(m).isoWeekday() !== 6 && moment(m).isoWeekday() !== 7) {
            ListDates.push(moment(m));
          }
          if (
            (moment(m).isoWeekday() === 6 && allowSaturday) ||
            (moment(m).isoWeekday() === 7 && allowSunday)
          ) {
            ListDates.push(moment(m));
          }
        }
        break;
      case 2:
        for (
          var m = moment(startDay);
          m.isSameOrBefore(moment(endDay));
          m.add(1, "days")
        ) {
          if (moment(m).isoWeekday() !== 6 && moment(m).isoWeekday() !== 7) {
            ListDates.push(moment(m));
          }
          if (
            (moment(m).isoWeekday() === 6 && allowSaturday) ||
            (moment(m).isoWeekday() === 7 && allowSunday)
          ) {
            ListDates.push(moment(m));
          }
        }
        break;
      default:
        //console.log("mitä vittua");
        break;
    }
    return ListDates.some(
      (r) => extRes.indexOf(moment(r).format("YYYY-MM-DDTHH:mm:ss")) >= 0
    );
  };
  //Handle mode days/weeks/range for saving the dates
  const [mode, setMode] = React.useState(0);

  const handleMode = (value) => {
    //Clear all selections when changing mode // WIP -- Add confirmation if dates selected
    setHighlightedDays([]);
    setStartDay(null);
    setEndDay(null);
    setStartDate(null);
    setMode(value);
    setDeleteOld("");
    setExistSelected(false);
    setEveningPreschoolChecked(false);
  };

  //handle closing if user has made any actions popup

  const [closePopUp, setClosePopUp] = React.useState(false);
  const handleCloseClick = () => {
    if (
      listOfChildren.length > 0 ||
      DeleteOld !== "" ||
      highlightedDays.length > 0 ||
      startDate !== null ||
      startDay !== null ||
      endDay !== null
    ) {
      setClosePopUp(true);
    } else {
      handleClick();
    }
  };

  const handleClosePopUp = () => {
    setClosePopUp(!closePopUp);
  };

  const saveAbsent = () => {
    let ListDates = [];

    switch (mode) {
      case 0:
        ListDates = highlightedDays;
        break;
      case 1:
        for (
          var m = moment(startDate).startOf("week");
          m.isSameOrBefore(moment(startDate).endOf("week"));
          m.add(1, "days")
        ) {
          ListDates.push(moment(m));
        }
        break;
      case 2:
        for (
          var m = moment(startDay);
          m.isSameOrBefore(moment(endDay));
          m.add(1, "days")
        ) {
          ListDates.push(moment(m));
        }
        break;
      default:
        //console.log("mitä vittua");
        break;
    }

    setResCount(ListDates.length * listOfChildren.length);
    handleFocusDay(moment.min(ListDates));
    var personAbsent = [];

    //console.log(names);
    //console.log(selectedChildren);
    //console.log(selectedPlacements);
    selectedChildren.forEach((child) => {
      var testchild = names.filter((n) => n.Id === child)[0];
      var testPlacement = testchild.Placements.filter(
        (p) => p.Id === selectedPlacements[child]
      )[0];

      //console.log("testi", testPlacement);
      //console.log("testi", testPlacement.Type);
      //console.log("testi", testPlacement.OpenType);
      personAbsent.push({
        PersonId: child,
        Type: "I",
        PlacementId: selectedPlacements[child],
        allowWeekends:
          includeWeekEnd === "Ei"
            ? false
            : selectedPlacements[child] &&
              names
                .find((c) => c.Id === child)
                .Placements.find(
                  (placement) =>
                    placement.Id === selectedPlacements[child] &&
                    (placement.Type === "ROUND_THE_CLOCK" ||
                      placement.Type === "EXTENDED") &&
                    ((placement.OpenType === "MONSUN" &&
                      placement.OpenTimes === null) ||
                      (placement.OpenType === "MONSUN" &&
                        placement.OpenTimes !== null &&
                        ((placement.OpenTimes.toLowerCase().includes("ma") &&
                          placement.OpenTimes.toLowerCase().includes("su")) ||
                          (placement.OpenTimes.toLowerCase().includes("la") &&
                            placement.OpenTimes.toLowerCase().includes("su")))))
                ) !== undefined,

        allowSaturday:
          includeWeekEnd === "Ei"
            ? false
            : selectedPlacements[child] &&
              names
                .find((c) => c.Id === child)
                .Placements.find(
                  (placement) =>
                    placement.Id === selectedPlacements[child] &&
                    (placement.Type === "ROUND_THE_CLOCK" ||
                      placement.Type === "EXTENDED") &&
                    ((placement.OpenType === "MONSUN" &&
                      placement.OpenTimes === null) ||
                      (placement.OpenType === "MONSUN" &&
                        placement.OpenTimes !== null &&
                        (placement.OpenTimes.toLowerCase().includes("ma-su") ||
                          placement.OpenTimes.toLowerCase().includes(
                            "mon-sun"
                          ) ||
                          placement.OpenTimes.toLowerCase().includes("må-sö") ||
                          placement.OpenTimes.toLowerCase().includes("la") ||
                          placement.OpenTimes.toLowerCase().includes("sat") ||
                          placement.OpenTimes.toLowerCase().includes("lö"))))
                ) !== undefined,
        allowSunday:
          includeWeekEnd === "Ei"
            ? false
            : selectedPlacements[child] &&
              names
                .find((c) => c.Id === child)
                .Placements.find(
                  (placement) =>
                    placement.Id === selectedPlacements[child] &&
                    (placement.Type === "ROUND_THE_CLOCK" ||
                      placement.Type === "EXTENDED") &&
                    ((placement.OpenType === "MONSUN" &&
                      placement.OpenTimes === null) ||
                      (placement.OpenType === "MONSUN" &&
                        placement.OpenTimes !== null &&
                        (placement.OpenTimes.toLowerCase().includes("sun") ||
                          placement.OpenTimes.toLowerCase().includes("su") ||
                          placement.OpenTimes.toLowerCase().includes("sö"))))
                ) !== undefined,
      });
    });
    //console.log("personAbsent", personAbsent);

    actions.triggerSaveCareTimes({
      PersonList: personAbsent,
      Dates: ListDates,
      StartTime: ResLenght[0],
      EndTime: ResLenght[1],
      DeleteOld: DeleteOld !== "Ei" ? false : true,
      EveningPreschool: eveningPreschoolChecked,
      Holidays: holidays,
    });
  };

  //////////////////////////////////////////////////////////
  const [selectedChildren, setSelectedChildren] = useState([]);
  const [selectedPlacements, setSelectedPlacements] = useState({});
  const [allowWeekends, setAllowWeekends] = useState(false);
  const [allowSaturday, setAllowSaturday] = useState(false);
  const [allowSunday, setAllowSunday] = useState(false);

  useEffect(() => {
    setSelectedChildren(names.map((child) => child.Id));

    const initialSelectedPlacements = {};
    names.forEach((child) => {
      initialSelectedPlacements[child.Id] = child.Placements[0].Id;
    });
    setSelectedPlacements(initialSelectedPlacements);

    setAllowWeekends(
      names.some(
        (child) =>
          (child.Placements[0].Type === "ROUND_THE_CLOCK" ||
            child.Placements[0].Type === "EXTENDED") &&
          ((child.Placements[0].OpenType === "MONSUN" &&
            child.Placements[0].OpenTimes === null) ||
            (child.Placements[0].OpenType === "MONSUN" &&
              child.Placements[0].OpenTimes !== null &&
              ((child.Placements[0].OpenTimes.toLowerCase().includes("ma") &&
                child.Placements[0].OpenTimes.toLowerCase().includes("su")) ||
                child.Placements[0].OpenTimes.toLowerCase().includes("la") ||
                child.Placements[0].OpenTimes.toLowerCase().includes("su") ||
                (child.Placements[0].OpenTimes.toLowerCase().includes("la") &&
                  child.Placements[0].OpenTimes.toLowerCase().includes("su")))))
      )
    );

    setAllowSaturday(
      names.some(
        (child) =>
          (child.Placements[0].Type === "ROUND_THE_CLOCK" ||
            child.Placements[0].Type === "EXTENDED") &&
          ((child.Placements[0].OpenType === "MONSUN" &&
            child.Placements[0].OpenTimes === null) ||
            (child.Placements[0].OpenType === "MONSUN" &&
              child.Placements[0].OpenTimes !== null &&
              (child.Placements[0].OpenTimes.toLowerCase().includes("ma-su") ||
                child.Placements[0].OpenTimes.toLowerCase().includes(
                  "mon-sun"
                ) ||
                child.Placements[0].OpenTimes.toLowerCase().includes("må-sö") ||
                child.Placements[0].OpenTimes.toLowerCase().includes("la") ||
                child.Placements[0].OpenTimes.toLowerCase().includes("sat") ||
                child.Placements[0].OpenTimes.toLowerCase().includes("lö"))))
      )
    );

    setAllowSunday(
      names.some(
        (child) =>
          (child.Placements[0].Type === "ROUND_THE_CLOCK" ||
            child.Placements[0].Type === "EXTENDED") &&
          ((child.Placements[0].OpenType === "MONSUN" &&
            child.Placements[0].OpenTimes === null) ||
            (child.Placements[0].OpenType === "MONSUN" &&
              child.Placements[0].OpenTimes !== null &&
              (child.Placements[0].OpenTimes.toLowerCase().includes("sun") ||
                child.Placements[0].OpenTimes.toLowerCase().includes("su") ||
                child.Placements[0].OpenTimes.toLowerCase().includes("sö"))))
      )
    );
  }, []);

  const [render, setRender] = useState(false);

  const handleChildToggle = (childId) => {
    setSelectedChildren((prevSelected) => {
      const updatedSelected = prevSelected.includes(childId)
        ? prevSelected.filter((id) => id !== childId)
        : [...prevSelected, childId];

      setSelectedPlacements((prevPlacements) => {
        const updatedPlacements = { ...prevPlacements };
        if (!updatedSelected.includes(childId)) {
          delete updatedPlacements[childId];
        } else if (!(childId in updatedPlacements)) {
          updatedPlacements[childId] = names.find(
            (dataChild) => dataChild.Id === childId
          ).Placements[0].Id;
        }

        const newAllowWeekends = updatedSelected.some(
          (id) =>
            (names.find((dataChild) => dataChild.Id === id).Placements[0].Id ===
              updatedPlacements[id] &&
              (names.find((dataChild) => dataChild.Id === id).Placements[0]
                .Type === "ROUND_THE_CLOCK" ||
                names.find((dataChild) => dataChild.Id === id).Placements[0]
                  .Type === "EXTENDED") &&
              names.find((dataChild) => dataChild.Id === id).Placements[0]
                .OpenType === "MONSUN" &&
              names.find((dataChild) => dataChild.Id === id).Placements[0]
                .OpenTimes === null) ||
            (names.find((dataChild) => dataChild.Id === id).Placements[0]
              .OpenType === "MONSUN" &&
              names.find((dataChild) => dataChild.Id === id).Placements[0]
                .OpenTimes !== null &&
              ((names
                .find((dataChild) => dataChild.Id === id)
                .Placements[0].OpenTimes.toLowerCase()
                .includes("ma") &&
                names
                  .find((dataChild) => dataChild.Id === id)
                  .Placements[0].OpenTimes.toLowerCase()
                  .includes("su")) ||
                names
                  .find((dataChild) => dataChild.Id === id)
                  .Placements[0].OpenTimes.toLowerCase()
                  .includes("la") ||
                names
                  .find((dataChild) => dataChild.Id === id)
                  .Placements[0].OpenTimes.toLowerCase()
                  .includes("su") ||
                (names
                  .find((dataChild) => dataChild.Id === id)
                  .Placements[0].OpenTimes.toLowerCase()
                  .includes("la") &&
                  names
                    .find((dataChild) => dataChild.Id === id)
                    .Placements[0].OpenTimes.toLowerCase()
                    .includes("su"))))
        );

        const newAllowSaturday = updatedSelected.some(
          (id) =>
            (names.find((dataChild) => dataChild.Id === id).Placements[0].Id ===
              updatedPlacements[id] &&
              (names.find((dataChild) => dataChild.Id === id).Placements[0]
                .Type === "ROUND_THE_CLOCK" ||
                names.find((dataChild) => dataChild.Id === id).Placements[0]
                  .Type === "EXTENDED") &&
              names.find((dataChild) => dataChild.Id === id).Placements[0]
                .OpenType === "MONSUN" &&
              names.find((dataChild) => dataChild.Id === id).Placements[0]
                .OpenTimes === null) ||
            (names.find((dataChild) => dataChild.Id === id).Placements[0]
              .OpenType === "MONSUN" &&
              names.find((dataChild) => dataChild.Id === id).Placements[0]
                .OpenTimes !== null &&
              (names
                .find((dataChild) => dataChild.Id === id)
                .Placements[0].OpenTimes.toLowerCase()
                .includes("ma-su") ||
                names
                  .find((dataChild) => dataChild.Id === id)
                  .Placements[0].OpenTimes.toLowerCase()
                  .includes("mon-sun") ||
                names
                  .find((dataChild) => dataChild.Id === id)
                  .Placements[0].OpenTimes.toLowerCase()
                  .includes("må-sö") ||
                names
                  .find((dataChild) => dataChild.Id === id)
                  .Placements[0].OpenTimes.toLowerCase()
                  .includes("la") ||
                names
                  .find((dataChild) => dataChild.Id === id)
                  .Placements[0].OpenTimes.toLowerCase()
                  .includes("sat") ||
                names
                  .find((dataChild) => dataChild.Id === id)
                  .Placements[0].OpenTimes.toLowerCase()
                  .includes("lö")))
        );

        const newAllowSunday = updatedSelected.some(
          (id) =>
            (names.find((dataChild) => dataChild.Id === id).Placements[0].Id ===
              updatedPlacements[id] &&
              (names.find((dataChild) => dataChild.Id === id).Placements[0]
                .Type === "ROUND_THE_CLOCK" ||
                names.find((dataChild) => dataChild.Id === id).Placements[0]
                  .Type === "EXTENDED") &&
              names.find((dataChild) => dataChild.Id === id).Placements[0]
                .OpenType === "MONSUN" &&
              names.find((dataChild) => dataChild.Id === id).Placements[0]
                .OpenTimes === null) ||
            (names.find((dataChild) => dataChild.Id === id).Placements[0]
              .OpenType === "MONSUN" &&
              names.find((dataChild) => dataChild.Id === id).Placements[0]
                .OpenTimes !== null &&
              (names
                .find((dataChild) => dataChild.Id === id)
                .Placements[0].OpenTimes.toLowerCase()
                .includes("sun") ||
                names
                  .find((dataChild) => dataChild.Id === id)
                  .Placements[0].OpenTimes.toLowerCase()
                  .includes("su") ||
                names
                  .find((dataChild) => dataChild.Id === id)
                  .Placements[0].OpenTimes.toLowerCase()
                  .includes("sö")))
        );

        setAllowSaturday(newAllowSaturday);
        setAllowSunday(newAllowSunday);
        setAllowWeekends(newAllowWeekends);

        return updatedPlacements;
      });

      return updatedSelected;
    });
    setRender(!render);
  };

  const handlePlacementSelect = (childId, placementId) => {
    //console.log(childId, placementId);
    //console.log(
    //  names
    //    .find((dataChild) => dataChild.Id === childId)
    //    .Placements.find((p) => p.Id === placementId).Id
    //);

    //console.log(selectedChildren);
    //console.log(selectedPlacements);
    setSelectedPlacements((prevSelected) => {
      const updatedPlacements = {
        ...prevSelected,
        [childId]: placementId,
      };
      //console.log(updatedPlacements);

      const hasWeekend = Object.keys(updatedPlacements).some((childId) => {
        // console.log("childId", childId);
        const selectedPlacementId = updatedPlacements[childId];
        //console.log("selectedPlacementId", selectedPlacementId);
        const child = names.find((childData) => childData.Id === childId);
        //console.log("child", child);
        return (
          child &&
          child.Placements.some(
            (placement) =>
              placement.Id === selectedPlacementId &&
              (placement.Type === "ROUND_THE_CLOCK" ||
                placement.Type === "EXTENDED") &&
              ((placement.OpenType === "MONSUN" &&
                placement.OpenTimes === null) ||
                (placement.OpenType === "MONSUN" &&
                  placement.OpenTimes !== null &&
                  ((placement.OpenTimes.toLowerCase().includes("ma") &&
                    placement.OpenTimes.toLowerCase().includes("su")) ||
                    placement.OpenTimes.toLowerCase().includes("la") ||
                    placement.OpenTimes.toLowerCase().includes("su") ||
                    (placement.OpenTimes.toLowerCase().includes("la") &&
                      placement.OpenTimes.toLowerCase().includes("su")))))
          )
        );
      });

      const hasSaturday = Object.keys(updatedPlacements).some((childId) => {
        //console.log("childId", childId);
        const selectedPlacementId = updatedPlacements[childId];
        //console.log("selectedPlacementId", selectedPlacementId);
        const child = names.find((childData) => childData.Id === childId);
        //console.log("child", child);
        return (
          child &&
          child.Placements.some(
            (placement) =>
              placement.Id === selectedPlacementId &&
              (placement.Type === "ROUND_THE_CLOCK" ||
                placement.Type === "EXTENDED") &&
              ((placement.OpenType === "MONSUN" &&
                placement.OpenTimes === null) ||
                (placement.OpenType === "MONSUN" &&
                  placement.OpenTimes !== null &&
                  (placement.OpenTimes.toLowerCase().includes("ma-su") ||
                    placement.OpenTimes.toLowerCase().includes("la"))))
          )
        );
      });

      const hasSunday = Object.keys(updatedPlacements).some((childId) => {
        //console.log("childId", childId);
        const selectedPlacementId = updatedPlacements[childId];
        //console.log("selectedPlacementId", selectedPlacementId);
        const child = names.find((childData) => childData.Id === childId);
        //console.log("child", child);
        return (
          child &&
          child.Placements.some(
            (placement) =>
              placement.Id === selectedPlacementId &&
              (placement.Type === "ROUND_THE_CLOCK" ||
                placement.Type === "EXTENDED") &&
              ((placement.OpenType === "MONSUN" &&
                placement.OpenTimes === null) ||
                (placement.OpenType === "MONSUN" &&
                  placement.OpenTimes !== null &&
                  placement.OpenTimes.toLowerCase().includes("su")))
          )
        );
      });

      setAllowSaturday(hasSaturday);
      setAllowSunday(hasSunday);
      setAllowWeekends(hasWeekend);

      return updatedPlacements;
    });
    setRender(!render);
  };

  //console.log(DeleteOld);

  //Handle DeleteOld if existing res days selected
  const [includeWeekEnd, setIncludeWeekEnd] = React.useState("");

  const handleWeekEndInclude = (event, newReason) => {
    if (newReason !== null) {
      setIncludeWeekEnd(newReason);
    }
  };

  /////////////////////////////////////////////////////////
  //eveningpreschool

  const [eveningPreschoolChecked, setEveningPreschoolChecked] =
    React.useState(false);

  const checkEveningPreschool = () => {
    let hasTrueEvpr = names.some(
      (dict) => selectedChildren.includes(dict.Id) && !dict.EveningPreschool
    );
    return hasTrueEvpr;
  };
  const handleEveningPreSchoolChecked = (event) => {
    setEveningPreschoolChecked(event.target.checked);
  };

  const CheckStartTime = () => {
    if (ResLenght[0] >= 660) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Dialog
      disablePadding
      disableGutters
      fullWidth
      open={newResDialogOpen}
      sx={{
        "& .MuiDialog-container .MuiDialog-paper": {
          margin: 0,
          borderRadius: 2,
          width: "96%",
        },
        width: "100%",
      }}
    >
      <DialogTitle textAlign="center" style={{ backgroundColor: "#FFFFFF" }}>
        {!saved2 && !saving && (
          <Typography color="textSecondary" fontWeight="bold">
            {Languages.newCalendarSaveNewCareTime}
          </Typography>
        )}

        {saving && (
          <Typography color="textSecondary" fontWeight="bold">
            {Languages.newCalendarSavingCareTimes2}
          </Typography>
        )}

        {saved2 &&
          state.caretimes.CTSaved.state === types.CARETIMES_SAVED_OK && (
            <Typography color="textSecondary" fontWeight="bold">
              {Languages.newCalendarCareTImesSaved}
            </Typography>
          )}

        {saved2 &&
          state.caretimes.CTSaved.state === types.CARETIMES_SAVED_FAILED && (
            <Typography color="textSecondary" fontWeight="bold">
              {Languages.newCalendarSaveFailed}
            </Typography>
          )}

        {!saving && (
          <IconButton
            aria-label="close"
            onClick={saved2 ? handleClick : handleCloseClick}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "success",
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent
        dividers
        disableGutters
        disablePadding
        sx={{ padding: 0 }}
        style={{ backgroundColor: "#FFFFFF" }}
      >
        {saving === true || saved2 === true ? (
          <>
            <Stack spacing={2} alignItems="center" sx={{ mt: 2, mb: 2 }}>
              <ProgressBar
                saving={saving}
                saved={saved2}
                count={resCount}
                handleClick={handleClick}
              ></ProgressBar>
            </Stack>
          </>
        ) : (
          <Box sx={{ mb: 2 }}>
            <div>
              <>
                <Divider
                  sx={{
                    m: 2,
                    "&::before, &::after": {
                      borderColor: "#0074C8",
                    },
                  }}
                  textAlign="center"
                >
                  <Chip
                    label={"1. " + Languages.newCalendarSelectChildren}
                    sx={{ backgroundColor: "#0074C8" }}
                  />
                </Divider>
                <ReservationChildSelection
                  childList={names}
                  handleWeekEnd={handleWeekEnd}
                  handleChildToggle={handleChildToggle}
                  selectedChildren={selectedChildren}
                  selectedPlacements={selectedPlacements}
                  handlePlacementSelect={handlePlacementSelect}
                ></ReservationChildSelection>
                {/*<CalendarChildSelection
                  names={names}
                  listOfChildren={listOfChildren2}
                  handleFormat={handleChildren}
                ></CalendarChildSelection>*/}
              </>
              {/*
              <Divider
                sx={{
                  m: 2,
                  "&::before, &::after": {
                    borderColor: "#0074C8",
                  },
                }}
                textAlign="center"
              >
                <Chip
                  label={"2. Valitse Yksiköt"}
                  sx={{ backgroundColor: "#0074C8" }}
                />
              </Divider>
              {listOfChildren2.length < 1 ? (
                <Typography
                  sx={{ mt: 1 }}
                  color="warning.main"
                  fontSize="medium"
                  textAlign="center"
                >
                  {"Valitse ensin vähintään yksi lapsi"}
                </Typography>
              ) : (
                <CalendarCentreSelection
                  listOfChildren={listOfChildren2}
                  names={names}
                  handleResChildren={handleResChildren}
                  displayName={true}
                ></CalendarCentreSelection>
              )}*/}

              <Divider
                sx={{
                  m: 2,
                  "&::before, &::after": {
                    borderColor: "#0074C8",
                  },
                }}
                textAlign="center"
              >
                <Chip
                  label={"2. " + Languages.newCalendarSelectTime}
                  sx={{ backgroundColor: "#0074C8" }}
                />
              </Divider>
              <>
                <CalendarTimeSlider handleResLenght={handleResLenght} />
                {timeWarning() && (
                  <Typography color="warning.main" textAlign="center">
                    {"Varauksen alkuajan on oltava pienempi kuin loppuajan"}
                  </Typography>
                )}
                {CheckStartTime() && checkEveningPreschool() && (
                  <Stack direction="row" alignItems="center" sx={{ ml: "30%" }}>
                    <Typography color="textSecondary" textAlign="center">
                      {"Iltaesikoulu"}
                    </Typography>
                    <Checkbox
                      checked={eveningPreschoolChecked}
                      onChange={handleEveningPreSchoolChecked}
                    />
                  </Stack>
                )}
              </>
              <>
                <Divider
                  sx={{
                    m: 2,
                    "&::before, &::after": {
                      borderColor: "#0074C8",
                    },
                  }}
                  textAlign="center"
                >
                  <Chip
                    label={"3. " + Languages.newCalendarSelectDates}
                    sx={{ backgroundColor: "#0074C8" }}
                  />
                </Divider>
                {(allowSaturday ||
                  allowSunday ||
                  (allowSaturday && allowSunday)) && (
                  <>
                    <Typography color="textSecondary" textAlign="center">
                      {allowSaturday && allowSunday
                        ? Languages.newCalendarWeekEnds
                        : allowSaturday
                        ? Languages.newCalendarSaturdays
                        : allowSunday
                        ? Languages.newCalendarSundays
                        : "test"}
                    </Typography>
                    <ToggleButtonGroup
                      sx={{ width: "100%", mt: 0.5 }}
                      value={includeWeekEnd}
                      exclusive
                      onChange={handleWeekEndInclude}
                    >
                      <ToggleButton
                        value={"Kyllä"}
                        sx={{
                          width: "40%",
                          ml: "10%",
                        }}
                      >
                        <Box sx={{ width: "100%" }}>
                          <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                          >
                            <Box sx={{ width: "20%" }}></Box>
                            <Box sx={{ width: "60%" }}>
                              <Typography
                                color="textSecondary"
                                fontSize="medium"
                                textAlign="center"
                              >
                                {Languages.NewCalendarYes}
                              </Typography>
                            </Box>
                            <Box sx={{ width: "20%" }}>
                              {includeWeekEnd === "Kyllä" && (
                                <CheckIcon color="success" />
                              )}
                            </Box>
                          </Stack>
                        </Box>
                      </ToggleButton>
                      <ToggleButton
                        value={"Ei"}
                        sx={{
                          width: "40%",
                        }}
                      >
                        <Box sx={{ width: "100%" }}>
                          <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                          >
                            <Box sx={{ width: "20%" }}></Box>
                            <Box sx={{ width: "60%" }}>
                              <Typography
                                color="textSecondary"
                                fontSize="medium"
                                textAlign="center"
                              >
                                {Languages.newCalendarNo}
                              </Typography>
                            </Box>
                            <Box sx={{ width: "20%" }}>
                              {includeWeekEnd === "Ei" && (
                                <CheckIcon color="success" />
                              )}
                            </Box>
                          </Stack>
                        </Box>
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </>
                )}

                <CalendarDateSelectTabs
                  handleHighLightedDays={handleHighLightedDays}
                  highlightedDays={highlightedDays}
                  handleMode={handleMode}
                  handleDayRange={handleDayRange}
                  startDay={startDay}
                  endDay={endDay}
                  startDate={startDate}
                  handleWeek={handleWeek}
                  shortestLockTime={moment(state.children.ShortestLockTime)}
                  LoadCareTimesCuick={LoadCareTimesCuick}
                  allowWeekEnds={includeWeekEnd === "Kyllä" ? true : false}
                  allowSaturday={
                    includeWeekEnd === "Kyllä" && allowSaturday ? true : false
                  }
                  allowSunday={
                    includeWeekEnd === "Kyllä" && allowSunday ? true : false
                  }
                ></CalendarDateSelectTabs>

                {checkExistingRes() === true && (
                  <Box alignItems="center" justify="center" sx={{ mb: 2 }}>
                    <Typography color="textSecondary" textAlign="center">
                      {Languages.newCalendarExistSelected}
                    </Typography>
                    <ToggleButtonGroup
                      sx={{ width: "100%", mt: 0.5 }}
                      value={DeleteOld}
                      exclusive
                      onChange={handleDeleteOld}
                    >
                      <ToggleButton
                        value={"Kyllä"}
                        sx={{
                          width: "40%",
                          ml: "10%",
                        }}
                      >
                        <Box sx={{ width: "100%" }}>
                          <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                          >
                            <Box sx={{ width: "20%" }}></Box>
                            <Box sx={{ width: "60%" }}>
                              <Typography
                                color="textSecondary"
                                fontSize="medium"
                                textAlign="center"
                              >
                                {Languages.NewCalendarYes}
                              </Typography>
                            </Box>
                            <Box sx={{ width: "20%" }}>
                              {DeleteOld === "Kyllä" && (
                                <CheckIcon color="success" />
                              )}
                            </Box>
                          </Stack>
                        </Box>
                      </ToggleButton>
                      <ToggleButton
                        value={"Ei"}
                        sx={{
                          width: "40%",
                        }}
                      >
                        <Box sx={{ width: "100%" }}>
                          <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                          >
                            <Box sx={{ width: "20%" }}></Box>
                            <Box sx={{ width: "60%" }}>
                              <Typography
                                color="textSecondary"
                                fontSize="medium"
                                textAlign="center"
                              >
                                {Languages.newCalendarNo}
                              </Typography>
                            </Box>
                            <Box sx={{ width: "20%" }}>
                              {DeleteOld === "Ei" && (
                                <CheckIcon color="success" />
                              )}
                            </Box>
                          </Stack>
                        </Box>
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                )}
              </>
            </div>
          </Box>
        )}
      </DialogContent>
      {!saving && !saved2 && (
        <DialogActions
          sx={{
            width: "100%",
            height: "50px",
            margin: 0,
            backgroundColor:
              listOfChildren2.length < 1 ? "#8c8c8b" : "success.main",
          }}
          style={{ justifyContent: "center", padding: 0 }}
        >
          <Button
            sx={{
              width: "100%",
              height: "50px",
              margin: 0,
              backgroundColor: "success.main",
              "&:disabled": { backgroundColor: "#8c8c8b" },
            }}
            variant="contained"
            onClick={saveAbsent}
            disabled={
              selectedChildren.length < 1 ||
              timeWarning() ||
              (checkExistingRes() === true && DeleteOld === "") ||
              (allowWeekends === true && includeWeekEnd === "") ||
              (mode === 0 && highlightedDays.length < 1) ||
              (mode === 1 && startDate === null) ||
              (mode === 2 && (startDay === null || endDay === null))
                ? true
                : false
            }
          >
            {Languages.newCalendarSaveNewCareTimes}
          </Button>
        </DialogActions>
      )}

      <Dialog onClose={handleClosePopUp} open={closePopUp}>
        <DialogTitle textAlign="center" style={{ backgroundColor: "#FFFFFF" }}>
          <Typography color="textSecondary" fontWeight="bold">
            {Languages.newCalendarAreYouSureExit}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClosePopUp}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "success",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ backgroundColor: "#FFFFFF" }}>
          <Typography sx={{ mt: 2 }} color="textSecondary" fontWeight="bold">
            {Languages.newCalendarEditLoss}
          </Typography>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: "center",

            backgroundColor: "#FFFFFF",
          }}
        >
          <Button
            variant="contained"
            sx={{ backgroundColor: "#0074C8" }}
            onClick={handleClosePopUp}
          >
            {Languages.newCalendarCancel}
          </Button>
          <Button
            variant="contained"
            sx={{ backgroundColor: "#0074C8" }}
            onClick={handleClick}
          >
            {Languages.newCalendarExit}
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default CareTimeCalendarNewReservationDialog;

const ProgressBar = (props) => {
  const { state, actions } = useStateValue();
  const saved = state.caretimes.CTSaved;

  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress >= 90) {
          return 90;
        }
        const diff = props.count / 100;
        return Math.min(oldProgress + diff, 100);
      });
    }, 100);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const [closeProgress, setCloseProgress] = React.useState(0);
  React.useEffect(() => {
    const timer = setInterval(() => {
      setCloseProgress((oldProgress) => {
        if (
          oldProgress >= 5 &&
          state.caretimes.CTSaved.state === types.CARETIMES_SAVED_OK
        ) {
          props.handleClick;
        }
        return oldProgress + 1;
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [saved]);

  return (
    <Box sx={{ width: "80%" }}>
      {props.saving && !props.saved && (
        <>
          {/*<LinearProgress
            sx={{
              "& .MuiLinearProgress-colorPrimary": {
                backgroundColor: "#0074C8",
              },
              "& .MuiLinearProgress-barColorPrimary": {
                backgroundColor: "#0074C8",
              },
              "& .MuiLinearProgress-dashedColorPrimary": {
                backgroundImage:
                  "radial-gradient(#0074C8 0%, #0074C8 16%, transparent 42%)",
              },
            }}
            dashedColorPrimary="red"
            dashedColorSecondary="green"
            variant="buffer"
            value={progress}
            valueBuffer={progress}
          />
          
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
            sx={{ ml: `${progress - 10}%` }}
          >
            {Math.round((progress / 100) * props.count) + " / " + props.count}
          </Typography>
          
          */}
          <Stack spacing={1} alignItems="center">
            <CircularProgress></CircularProgress>
            <Typography color="textSecondary" fontSize="medium">
              {Languages.newCalendarSavingCareTimes2}
            </Typography>
          </Stack>
        </>
      )}

      {!props.saving && props.saved && (
        <>
          {state.caretimes.CTSaved.state === types.CARETIMES_SAVED_FAILED && (
            <>
              <Stack spacing={1} alignItems="center">
                <Typography color="textSecondary" fontSize="medium">
                  {Languages.newCalendarSavingCareTimefailed}
                </Typography>
                {state.caretimes.CTSaved.Data.SaveGroupedErros.map((error) => (
                  <Card
                    variant="outlined"
                    sx={{
                      bgcolor: "white",
                      mt: 1,
                      width: "96%",
                      ml: "2%",
                      mb: 1,
                    }}
                  >
                    <CardHeader
                      sx={{ padding: 1 }}
                      title={
                        <Typography
                          color="textSecondary"
                          textAlign="center"
                          fontWeight="medium"
                        >
                          {[
                            moment(error?.ErrorDate).format("DD.MM.YYYY"),
                            ...(error?.ChildNames || []),
                          ]
                            .filter(
                              (value) => value !== null && value !== undefined
                            )
                            .join(" ")}
                        </Typography>
                      }
                    />
                    <CardContent sx={{ padding: 0, pb: 0 }}>
                      <Typography
                        color="textSecondary"
                        textAlign="center"
                        fontSize="medium"
                      >
                        {[error?.ErrorString]
                          .filter(
                            (value) => value !== null && value !== undefined
                          )
                          .join(" ")}
                      </Typography>
                    </CardContent>
                  </Card>
                ))}
              </Stack>
            </>
          )}

          {state.caretimes.CTSaved.state === types.CARETIMES_SAVED_OK && (
            <>
              <Stack spacing={1} alignItems="center">
                <Typography color="textSecondary" fontSize="medium">
                  {Languages.newCalendarCareTImesSaved}
                </Typography>
                <AutoClose handleClick={props.handleClick}></AutoClose>
                <Button
                  variant="contained"
                  sx={{ backgroundColor: "#0074C8" }}
                  onClick={props.handleClick}
                >
                  {Languages.newCalendarClose}
                </Button>
              </Stack>
            </>
          )}
        </>
      )}
    </Box>
  );
};

const AutoClose = (props) => {
  const [progress, setProgress] = React.useState(5);
  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress <= 0) {
          props.handleClick();
        }
        return oldProgress - 1;
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box sx={{ width: "80%" }}>
      <Typography color="textSecondary" fontSize="medium">
        {Languages.newCalendatAutomaticClose +
          " " +
          progress +
          " " +
          Languages.newCalendarAfterSeconds}
      </Typography>
    </Box>
  );
};

import React, { useEffect, useState } from "react";

import { useStateValue } from "../../../../State";

import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { types } from "../../../../Reducers/actionTypes";
import Box from "@mui/material/Box";

import moment from "moment";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Stack from "@mui/material/Stack";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Badge from "@mui/material/Badge";
import Typography from "@mui/material/Typography";

const CalendarTabs = ({
  handleHighLightedDays,
  highlightedDays,

  handleMode,
  handleDayRange,
  startDay,
  endDay,
  startDate,
  handleWeek,
  shortestLockTime = null,
  LoadCareTimesCuick,
}) => {
  //TabHandler for changing the calendar selection mode days/weeks/range

  const [alignment, setAlignment] = React.useState(0);

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
      setTab(newAlignment);
      handleMode(newAlignment);
    }
    console.log(newAlignment);
  };
  const { state, actions } = useStateValue();
  const [tab, setTab] = React.useState(0);

  const handleTab = (event, newValue) => {
    handleMode(newValue);
    setTab(newValue);
  };
  const [holidays, setHolidays] = React.useState([]);
  const [extRes, setExtRes] = React.useState([]);
  useEffect(() => {
    switch (state.caretimes.Quick.state) {
      case undefined:
        break;
      case types.CARETIMES_QUICK_LOAD_OK:
        setExtRes(state.caretimes.Quick.DayList);
        setHolidays(state.caretimes.Quick.HolidayList);
        break;
      case types.CARETIMES_QUICK_LOAD_FAILED:
        break;
      default:
        break;
    }
  }, [state.caretimes.Quick.state]);

  const HandleCalendarChange = (newValue) => {
    if (tab === 0) {
      handleHighLightedDays(newValue);
    }
    if (tab === 1) {
      handleWeek(newValue);
    }
    if (tab === 2) {
      handleDayRange(newValue);
    }
  };
  return (
    <>
      <LocalizationProvider
        dateAdapter={AdapterMoment}
        localeText={{
          calendarWeekNumberHeaderText: "#",
          calendarWeekNumberText: (weekNumber) => `${weekNumber}.`,
        }}
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          <Stack>
            <ToggleButtonGroup
              value={alignment}
              exclusive
              onChange={handleAlignment}
              aria-label="text alignment"
            >
              <ToggleButton value={0} aria-label="left aligned">
                <Typography
                  sx={{
                    textDecoration: alignment === 0 ? "underline" : "none",
                  }}
                  variant="body1"
                  color={alignment === 0 ? "primary" : "black"}
                >
                  Päivät
                </Typography>
              </ToggleButton>

              <ToggleButton value={1} aria-label="centered">
                <Typography
                  sx={{
                    textDecoration: alignment === 1 ? "underline" : "none",
                  }}
                  variant="body1"
                  color={alignment === 1 ? "primary" : "black"}
                >
                  Viikot
                </Typography>
              </ToggleButton>
              <ToggleButton value={2} aria-label="right aligned">
                <Typography
                  sx={{
                    textDecoration: alignment === 2 ? "underline" : "none",
                  }}
                  variant="body1"
                  color={alignment === 2 ? "primary" : "black"}
                >
                  Aikaväli
                </Typography>
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Box>
        <Box>
          <Tabs
            sx={{ width: "80%", ml: "10%" }}
            value={tab}
            onChange={handleTab}
            aria-label="basic tabs example"
          >
            <Tab label="Päivät" {...a11yProps(0)} />
            <Tab label="Viikot" {...a11yProps(1)} />
            <Tab label="Aikaväli" {...a11yProps(2)} />
          </Tabs>
          <DateCalendar
            sx={{
              "& .MuiPickersCalendarHeader-root": {
                color: "black",
              },
              "& .MuiDayCalendar-weekDayLabel": {
                margin: 0,
              },
              "& .MuiPickersCalendarHeader-switchViewIcon": {
                display: "none",
              },
              "& .MuiPickersSlideTransition-root": {
                height: "auto",
              },
              "& .MuiPickersArrowSwitcher-button": {
                color: "black",
              },
            }}
            value={startDate}
            onChange={(newValue) => HandleCalendarChange(newValue)}
            slots={{
              day: tab === 2 ? Day3 : tab === 1 ? Day : ServerDay,
            }}
            shouldDisableDate={(date) => {
              if (
                date.isoWeekday() === 6 ||
                date.isoWeekday() === 7 ||
                (shortestLockTime != null && date.isBefore(shortestLockTime))
              ) {
                return true;
              }
              return false;
            }}
            disableHighlightToday
            defaultCalendarMonth={shortestLockTime}
            slotProps={{
              day:
                tab === 2
                  ? {
                      selectedStartDay: startDay,
                      selectedEndDay: endDay,
                      minDate: moment(),
                      extRes,
                    }
                  : tab === 1
                  ? {
                      selectedDay: startDate,
                      minDate: moment(),
                      extRes,
                    }
                  : {
                      highlightedDays,
                      extRes,
                    },
            }}
            minDate={moment()}
            maxDate={moment().add(18, "months").endOf("month")}
          />
        </Box>
        <TabPanel value={tab} index={0}>
          <DateCalendar
            sx={{
              "& .MuiPickersCalendarHeader-root": {
                color: "black",
              },
              "& .MuiDayCalendar-weekDayLabel": {
                margin: 0,
              },
              "& .MuiPickersCalendarHeader-switchViewIcon": {
                display: "none",
              },
              "& .MuiPickersSlideTransition-root": {
                height: "auto",
              },
              "& .MuiPickersArrowSwitcher-button": {
                color: "black",
              },
            }}
            minDate={moment()}
            onChange={(newValue) => HandleCalendarChange(newValue)}
            slots={{
              day: ServerDay,
            }}
            shouldDisableDate={(date) => {
              if (
                date.isoWeekday() === 6 ||
                date.isoWeekday() === 7 ||
                (shortestLockTime != null && date.isBefore(shortestLockTime)) ||
                holidays.some(
                  (e) =>
                    moment(e.Date).format("MM.DD.YYYY") ==
                    moment(date).format("MM.DD.YYYY")
                )
              ) {
                return true;
              }
              return false;
            }}
            defaultCalendarMonth={shortestLockTime}
            slotProps={{
              day: {
                highlightedDays,
                extRes,
              },
            }}
            disableHighlightToday
            onMonthChange={console.log("test")}
            maxDate={moment().add(18, "months").endOf("month")}
          />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <DateCalendar
            sx={{
              "& .MuiPickersCalendarHeader-root": {
                color: "black",
              },
              "& .MuiDayCalendar-weekDayLabel": {
                margin: 0,
              },
              "& .MuiPickersCalendarHeader-switchViewIcon": {
                display: "none",
              },
              "& .MuiPickersSlideTransition-root": {
                height: "auto",
              },
              "& .MuiPickersArrowSwitcher-button": {
                color: "black",
              },
            }}
            value={startDate}
            onChange={(newValue) => HandleCalendarChange(newValue)}
            slots={{ day: Day }}
            shouldDisableDate={(date) => {
              if (
                date.isoWeekday() === 6 ||
                date.isoWeekday() === 7 ||
                (shortestLockTime != null && date.isBefore(shortestLockTime))
              ) {
                return true;
              }
              return false;
            }}
            defaultCalendarMonth={shortestLockTime}
            slotProps={{
              day: {
                selectedDay: startDate,
                minDate: moment(),
                extRes,
              },
            }}
            disableHighlightToday
            minDate={moment()}
            maxDate={moment().add(18, "months").endOf("month")}
          />
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <DateCalendar
            sx={{
              "& .MuiPickersCalendarHeader-root": {
                color: "black",
              },
              "& .MuiDayCalendar-weekDayLabel": {
                margin: 0,
              },
              "& .MuiPickersCalendarHeader-switchViewIcon": {
                display: "none",
              },
              "& .MuiPickersSlideTransition-root": {
                height: "auto",
              },
              "& .MuiPickersArrowSwitcher-button": {
                color: "black",
              },
            }}
            value={startDate}
            onChange={(newValue) => HandleCalendarChange(newValue)}
            slots={{ day: Day3 }}
            shouldDisableDate={(date) => {
              if (
                date.isoWeekday() === 6 ||
                date.isoWeekday() === 7 ||
                (shortestLockTime != null && date.isBefore(shortestLockTime))
              ) {
                return true;
              }
              return false;
            }}
            disableHighlightToday
            defaultCalendarMonth={shortestLockTime}
            slotProps={{
              day: {
                selectedStartDay: startDay,
                selectedEndDay: endDay,
                minDate: moment(),
                extRes,
              },
            }}
            minDate={moment()}
            maxDate={moment().add(18, "months").endOf("month")}
          />
        </TabPanel>
      </LocalizationProvider>
    </>
  );
};

export default CalendarTabs;

// SINGLE/MULTIPLE DAY SELECTION CUSTOM RENDERER
function ServerDay(props) {
  const { highlightedDays, extRes, day, outsideCurrentMonth, ...other } = props;

  const isSelected =
    !props.outsideCurrentMonth &&
    highlightedDays.some(
      (e) => moment(e).format("MM.DD.YYYY") == moment(day).format("MM.DD.YYYY")
    );

  const existingRes =
    !props.outsideCurrentMonth &&
    extRes.some(
      (e) => moment(e).format("MM.DD.YYYY") == moment(day).format("MM.DD.YYYY")
    );

  if (existingRes === true) {
    return (
      <Badge
        badgeContent={" "}
        variant="dot"
        overlap="circular"
        color="success"
      >
        <PickersDay
          sx={{
            backgroundColor: isSelected ? "#0074C8" : "white",
            color: isSelected ? -"white" : "black",
          }}
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
          disableMargin
          disableRipple
        />
      </Badge>
    );
  }
  return (
    <PickersDay
      sx={{
        backgroundColor: isSelected ? "#0074C8" : "white",
        color: isSelected ? -"white" : "black",
      }}
      {...other}
      disableMargin
      outsideCurrentMonth={outsideCurrentMonth}
      day={day}
      disableRipple
    />
  );
}

//WEEK SELECTION CUSTOM RENDERER
const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== "dayIsBetween" && prop !== "isFirstDay" && prop !== "isLastDay",
})(({ theme, dayIsBetween, isFirstDay, isLastDay, outsideCurrentMonth }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: "#0074C8",
    color: "white",
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  }),
  ...(isLastDay && {
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  }),
  ...(!dayIsBetween && {
    borderRadius: 0,
    backgroundColor: "white",
    color: "black",
  }),
}));

function Day(props) {
  const { day, extRes, selectedDay, minDate, outsideCurrentMonth, ...other } =
    props;

  const existingRes = extRes.some(
    (e) => moment(e).format("MM.DD.YYYY") == moment(day).format("MM.DD.YYYY")
  );

  if (selectedDay == null) {
    if (existingRes === true && !outsideCurrentMonth) {
      return (
        <Badge
          badgeContent={" "}
          variant="dot"
          overlap="circular"
          color="success"
        >
          <PickersDay
            disableMargin
            disableRipple
            outsideCurrentMonth={outsideCurrentMonth}
            day={day}
            {...other}
            sx={{ backgroundColor: "white", color: "black" }}
          />
        </Badge>
      );
    }
    return (
      <PickersDay
        disableMargin
        disableRipple
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
        {...other}
        sx={{ backgroundColor: "white", color: "black" }}
      />
    );
  }

  const start = moment(selectedDay).startOf("week").isBefore(minDate)
    ? moment(minDate)
    : moment(selectedDay).startOf("week");
  const end = moment(selectedDay).endOf("week");

  const dayIsBetween = moment(day).isBetween(start, end, null, "[]");
  const isFirstDay = moment(day).isSame(start, "day");
  const isLastDay = moment(day).isSame(end, "day");

  if (existingRes === true) {
    return (
      <Badge
        badgeContent={" "}
        variant="dot"
        overlap="circular"
        color="success"
      >
        <CustomPickersDay
          {...other}
          day={day}
          disableMargin
          dayIsBetween={dayIsBetween}
          isFirstDay={isFirstDay}
          isLastDay={isLastDay}
          disableRipple
        />
      </Badge>
    );
  }
  return (
    <CustomPickersDay
      {...other}
      day={day}
      disableMargin
      dayIsBetween={dayIsBetween}
      isFirstDay={isFirstDay}
      disableRipple
      isLastDay={isLastDay}
    />
  );
}

//RANGE SELECTION CUSTOM RENDERER
const CustomPickersDay2 = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== "dayIsBetween" &&
    prop !== "isFirstDay" &&
    prop !== "isLastDay" &&
    prop !== "isWeekEnd",
})(({ theme, dayIsBetween, isFirstDay, isLastDay, isWeekEnd }) => ({
  ...(dayIsBetween &&
    isWeekEnd === false && {
      borderRadius: 0,
      backgroundColor: "#0074C8",
      color: "white",
    }),
  ...(dayIsBetween &&
    isWeekEnd === true && {
      borderRadius: 0,
      backgroundColor: "white",
      color: "black",
    }),
  ...(isFirstDay && {
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  }),
  ...(isLastDay && {
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  }),
  ...(!dayIsBetween && {
    borderRadius: 0,
    backgroundColor: "white",
    color: "black",
  }),
}));

function Day3(props) {
  const {
    day,
    selectedStartDay,
    selectedEndDay,
    minDate,
    extRes,
    outsideCurrentMonth,
    ...other
  } = props;
  const existingRes = extRes.some(
    (e) => moment(e).format("MM.DD.YYYY") == moment(day).format("MM.DD.YYYY")
  );

  if (selectedStartDay == null || selectedEndDay == null) {
    if (existingRes === true && !outsideCurrentMonth) {
      return (
        <Badge
          badgeContent={" "}
          variant="dot"
          overlap="circular"
          color="success"
        >
          <PickersDay
            disableMargin
            outsideCurrentMonth={outsideCurrentMonth}
            disableRipple
            day={day}
            {...other}
            sx={{ backgroundColor: "white", color: "black" }}
          />
        </Badge>
      );
    }
    return (
      <PickersDay
        disableMargin
        disableRipple
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
        {...other}
        sx={{ backgroundColor: "white", color: "black" }}
      />
    );
  }

  const start = moment(selectedStartDay).isSame(minDate, "day")
    ? moment(minDate)
    : moment(selectedStartDay);
  const end = moment(selectedEndDay);

  const dayIsBetween = moment(day).isBetween(start, end, null, "[]");
  const isFirstDay = moment(day).isSame(start, "day");
  const isLastDay = moment(day).isSame(end, "day");
  if (existingRes === true) {
    return (
      <Badge
        badgeContent={" "}
        variant="dot"
        overlap="circular"
        color="success"
      >
        <CustomPickersDay2
          {...other}
          day={day}
          disableMargin
          disableRipple
          dayIsBetween={dayIsBetween}
          isFirstDay={isFirstDay}
          isLastDay={isLastDay}
          isWeekEnd={
            (moment(day).isoWeekday() === 6 ||
              moment(day).isoWeekday() === 7) === true
              ? true
              : false
          }
        />
      </Badge>
    );
  }
  return (
    <CustomPickersDay2
      {...other}
      day={day}
      disableMargin
      disableRipple
      dayIsBetween={dayIsBetween}
      isFirstDay={isFirstDay}
      isLastDay={isLastDay}
      isWeekEnd={
        (moment(day).isoWeekday() === 6 || moment(day).isoWeekday() === 7) ===
        true
          ? true
          : false
      }
    />
  );
}

const Puller = styled(Box)(() => ({
  width: 30,
  height: 6,
  backgroundColor: "#cccccc",
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ padding: 0 }}>{children}</Box>}
    </div>
  );
}

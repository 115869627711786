import React, { useState } from "react";
import ImageModal from "./ImageModal";

const TaskImage = ({ image, fullimage, imageDownload, downloadMimeType, downloadFileName, downloadData }) => {
    const [taskModalOn, setShowModal] = useState(false);

    const toggleModalOn = () => setShowModal(!taskModalOn);

    const toggleModalOff = () => setShowModal(false);


    return (
        <div className="wrapper">
            <img
            src={image}
            alt = "Tehtäväkuva"
            onClick = {toggleModalOn}
        />

            {taskModalOn ?  
                <ImageModal
                image={fullimage} imageDownload = {imageDownload}  
                downloadData = {downloadData} 
                      downloadMimeType = {downloadMimeType}
                      downloadFileName = {downloadFileName}
                onCloseRequest = {toggleModalOff}>
                </ImageModal>
                :
                null
            }
        </div>
    );
};

export default TaskImage;
import React from "react";
import BackToUpButton from "../GuideTools/BackToUpButton";
import Languages from "../../../translations";
import makeStyles from '@mui/styles/makeStyles';
import FeedIcon from '@mui/icons-material/Feed';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import Badge from '@mui/material/Badge';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const useStyles = makeStyles({
  success: {
    color: 'rgb(129, 199, 132)',
    fontSize: 'xx-large',
  }
});



const DefaultMessagingAccordionText = () => {
  const classes = useStyles();
    return(
      <>
      <div>
      <h4>{Languages.defaultMessagingSubTitle1}</h4>
          <br />
          <p className="guide-accordion-details-texts">{Languages.defaultMessagingIntro} </p>
          <br />
          <br />
          <p className="guide-accordion-details-texts">{Languages.defaultMessagingList1} <ChatBubbleIcon sx={{color: "#808080"}}/> {Languages.defaultMessagingList2} </p>
          <br />
          <p className="guide-accordion-details-texts">{Languages.defaultMessagingList3} <FeedIcon sx={{color: "#FF6D29"}}/> {Languages.defaultMessagingList4} <ExpandMoreIcon sx={{color: "#FF6D29"}}/> {Languages.defaultMessagingList5} </p>
          <br />
         <hr className="guide-accordion-details-line"/>
          <h4>{Languages.defaultMessagingMiddleTitle}</h4>
          <br/>
          <div>
          <p className="guide-accordion-details-texts">{Languages.defaultMessagingList6} <Badge badgeContent={3} color="error"><ChatBubbleIcon color="action"/></Badge> {Languages.defaultMessagingList7} {Languages.defaultMessagingList8}</p>
        <hr className="guide-accordion-details-line"/>
          <h4>{Languages.defaultMessagingMiddleTitle2}</h4>
          <br />
          <p className="guide-accordion-details-texts">{Languages.defaultMessagingList9}</p>
        <hr className="guide-accordion-details-line"/>  
          <h4>{Languages.defaultMessagingMiddleTitle3}</h4>
          <br />
          <p className="guide-accordion-details-texts">{Languages.defaultMessagingList10}</p>
          </div>
        <BackToUpButton />
        </div>
      </>
    )
}

export default DefaultMessagingAccordionText
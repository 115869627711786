import React from 'react';
import FaceRoundedIcon from '@mui/icons-material/FaceRounded';
import CardMedia from '@mui/material/CardMedia';
import Languages from "../../translations";
import makeStyles from '@mui/styles/makeStyles';


const useStyles = makeStyles(theme => ({
  profilePicture: {
    //minHeight: '160px',
    //height: 0,
    //paddingTop: "10px",
    //marginTop: "30",
    height: '100%',
    width: '100%',
    minHeight: "160px", 
  },
  pictureNotFound: {
    [theme.breakpoints.down('md')]: {
      marginRight: 'auto',
      marginTop: '10%',
      marginLeft: '35%',
      marginBottom: 'auto',
      padding: '0px'
    },
    [theme.breakpoints.up('md')]: {
      marginRight: 'auto',
      marginTop: '10%',
      marginLeft: '35%',
      marginBottom: 'auto',
      padding: '0px'
    },
    [theme.breakpoints.up('lg')]: {
      marginRight: 'auto',
      marginTop: '10%',
      marginLeft: '27%',
      marginBottom: 'auto',
      padding: '0px'
    },
    [theme.breakpoints.up('xl')]: {
      marginRight: 'auto',
      marginTop: '10%',
      marginLeft: '27%',
      marginBottom: 'auto',
      padding: '0px'
    }    
  },
  pictureNotFoundIcon: {
    [theme.breakpoints.down('md')]: {
      height: '50%',
      width: '50%',
    },
    [theme.breakpoints.up('md')]: {
      height: '40%',
      width: '40%',
    },
    [theme.breakpoints.up('lg')]: {
      height: '60%',
      width: '60%',
    },
    [theme.breakpoints.up('xl')]: {
      height: '60%',
      width: '60%',
    }      
  }
}));

const mapHttpCode = (code) => ({
  400: Languages.errorBadRequest,
  404: Languages.errorNotFound,
  500: Languages.errorInDB,
  502: Languages.errorNetwork,
  666: Languages.errorUnknown,
})[code];

const ProfilePicture = ({ profilepicture, onechilditem = false }) => {
  const classes = useStyles();

  if (!profilepicture) {
    return (
      <div className={classes.pictureNotFound} data-testid="profile-pic-not-found">
        {onechilditem ? <React.Fragment><div style={{height: "1rem"}}></div> <FaceRoundedIcon style={{color: "#E0E0E0"}} className={classes.pictureNotFoundIcon}></FaceRoundedIcon></React.Fragment>
        : <FaceRoundedIcon style={{color: "#E0E0E0"}} className={classes.pictureNotFoundIcon}></FaceRoundedIcon>
        }
      </div>
    );
  }

  if (typeof profilepicture === 'number') {
    return (
      <div className={classes.pictureNotFound} data-testid="profile-pic-not-found">{mapHttpCode(profilepicture)}</div>
    )
  }
  // background-image: url("data:image/jpeg;base64,...")
  return (
    <CardMedia className={classes.profilePicture}
      image={"data:image/jpeg;" + profilepicture}
      title="Profiilikuva"
      role="img" />
  )
}

/*const onechildprofilepicture = {

  media: {
    height: "100%",
    width: "100%"
  }
  ,
  media2: {
    height: 0,
    paddingTop: "56.25%",
    marginTop: "30",
    maxHeight: 20
  }
}*/


const ProfilePicture2 = ({ profilepicture }) => {
  const classes = useStyles();
  
  return (
    <div className={classes.profilePicture}>
      {profilepicture === null ?
        <div className={classes.profilePicture}>
          <FaceRoundedIcon className={classes.profilePictureNotFound}></FaceRoundedIcon>
        </div>
        : profilepicture === 400 ? <div className="OneChildProfilePicture"> {Languages.errorBadRequest} </div>
          : profilepicture === 404 ? <div className="OneChildProfilePicture"> {Languages.errorNotFound}</div>
            : profilepicture === 500 ? <div className="OneChildProfilePicture"> {Languages.errorInDB}</div>
              : profilepicture === 502 ? <div className="OneChildProfilePicture"> {Languages.errorNetwork}</div>
                : profilepicture === 666 ? <div className="OneChildProfilePicture"> {Languages.errorUnknown}</div>
                  :
                  <CardMedia height="100%"
                    className={classes.profilePicture}
                    image={"data:image/jpeg;" + profilepicture}
                    title="Profiilikuva" />
      }
    </div>
  );
}

export { ProfilePicture2 };
export default ProfilePicture;
import axios from "axios";
import Queue from 'smart-request-balancer';
import SessionManager from "../Auth/SessionManager";
import { history } from "../Router";
import moment from "moment";

const refreshTokenUrl = "/auth/refresh-token";

// Urlit joihin tehtyä requestia ei retrytä jos tulee 401
const bypassRetryUrls = [`${refreshTokenUrl}`, "/auth/login"]

// Docs for request queue:
// https://www.npmjs.com/package/smart-request-balancer

const getRestUrl = () => {
  // should contain port. example: localhost:3000
  let host;
  let httpScheme;
  if (window) {
    host = window.location.host
    httpScheme = window.location.protocol;
  } else {
    httpScheme = "http"
    host = "localhost:3000"
  }
  // example: http:

  const url = `${httpScheme}//${host}/api`;

  return url;
}

export class HttpClientWrapper {
  constructor(queue, client) {
    this.queue = queue
    this.client = client
  }

  async refreshToken() {

    const headers = {
      "content-type": "application/json; charset=utf-8",
    };

    let axiosConfig = {
      headers: headers,
      withCredentials: true,
    }

    const now = moment();
    const tokenExpireTime = SessionManager.accessTokenExpireTime()

    console.log("Queuing refresh-token request")
    // Don't fetch new token if we have already fresh (token which is valid more than 3 minutes)
    if (moment.duration(tokenExpireTime.diff(now)).as('seconds') > 180) {
      console.log("Token is already refreshed");
      return;
    }
    
    const start = moment();
    const response = await this.queue.request(async (retry) => {
      try {
        console.log("Sending Queued refresh-token request");
        const response = await this.client.post(refreshTokenUrl, null, axiosConfig);;
        return response;
      } catch (error) {
        // const originalRequest = error.config;
        if (SessionManager.loggedInStatus() === true) {
          // Logout user because token refresh failed
          console.log("refresh-token request fail and user logged in. Logging out");
          SessionManager.resetSession();
          history.push("/login");
        }
        // Note: its possible to retry refresh-token request here if we get some temporary http error

        throw error;
      }
    }, refreshTokenUrl, 'refreshToken');

    const finish = moment();
    console.log("Refresh token request took: ", moment.duration(finish.diff(start)).as('seconds'));
    const accessToken = response.data.Token;

    SessionManager.setAccessToken(accessToken);
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
    console.log("token refreshed");

    return;
  }

  async request(axiosConfig) {
    return await this.queue.request(async (retry) => {
      try {
        const response = await this.client(axiosConfig);
        return response;
      } catch (error) {
        const originalRequest = error.config;
        if (error.response.status === 401) {
          if (!bypassRetryUrls.includes(originalRequest.url)) {
            if (SessionManager.loggedInStatus()) {
              this.refreshToken();
              return retry(5); // retry last request after 10 seconds
            }
          }
        }

        // Note: refresh-token error handling is done in HttpClientWrapper.refreshToken()

        throw error;
      }

    }, axiosConfig.url, 'common');

  }

  async post(url, data, axiosConfig) {
    return await this.request({
      url: url,
      method: 'post',
      data: data,
      ...axiosConfig
    })
  }
  async get(url, axiosConfig) {
    return await this.request({
      url: url,
      method: 'get',
      ...axiosConfig
    })
  }
  async put(url, data, axiosConfig) {
    return await this.request({
      url: url,
      method: 'put',
      data: data,
      ...axiosConfig
    })
  }

  async delete(url, data, axiosConfig) {
    return await this.request({
      url: url,
      method: 'delete',
      data: data,
      ...axiosConfig
    })
  }
}

const createQueue = () => {
  return new Queue({
    rules: {                     // Describing our rules by rule name
      common: {                  // Common rule. Will be used if you won't provide rule argument
        rate: 30,                // Allow to send 30 messages
        limit: 1,                // per 1 second
        priority: 20
      },
      refreshToken: {
        rate: 1,
        limit: 10,
        priority: 1 // Rule priority. The lower priority is, the higher chance that this rule will execute faster 
      }
    },
  })
}
// Base class for apis
export class ApiBase {
  static httpClientInstance
  static REFRESH_PENDING = false;
  static axiosClient;
  static requestQueue;

  constructor(authzType) {
    this.configureInterceptor = this.configureInterceptor.bind(this);
    this.refreshToken = this.refreshToken.bind(this);


    //console.log("Resolved restUrl: ", restBaseUrl);
    if (!ApiBase.httpClientInstance) {
      console.log("creating new Axios instance..");
      ApiBase.requestQueue = createQueue();
      // ApiBase.httpClientInstance = new HttpClientWrapper();
      ApiBase.axiosClient = axios.create({
        baseURL: getRestUrl(),
        withCredentials: true,
        headers: {
          "content-type": "application/json; charset=utf-8"
        }
      });
      ApiBase.httpClientInstance = new HttpClientWrapper(ApiBase.requestQueue, ApiBase.axiosClient);
    }
    this.httpClient = ApiBase.httpClientInstance;

    this.authzType = "Bearer";
    if (authzType) {
      this.authzType = authzType
    }

    this.configureInterceptor();
  }

  configureInterceptor() {
    // Request interceptor for API calls. Automatically add authorization header to each call
    ApiBase.axiosClient.interceptors.request.use(
      (config) => {
        const accessToken = SessionManager.getAccessToken();
        config.headers = {
          'Authorization': `Bearer ${accessToken}`,
          'content-type': "application/json; charset=utf-8",
          'Accept': 'application/json',
          'Accept-Language': SessionManager.getUserLanguage() === "en" ? "en-US" : SessionManager.getUserLanguage(),
        } //DO NOT REMOVE Accept-Language or at least MunicipalPermissions will break

        // console.log("REQUEST interceptor => ",config.url);

        return config;
      },
      error => {
        Promise.reject(error)
      }
    );

    // Note: this is handled in HttpClientWrapper
    // Response interceptor for API calls
    // ApiBase.axiosClient.interceptors.response.use(
    //   (response) => {
    //     return response
    //   }, async function (error) {
    //     const originalRequest = error.config;

    //     // return other that 401 errors to caller so it can handle them
    //     if (error.response.status !== 401) {
    //       return Promise.reject(error);
    //     }

    //     // when request is to login url return errors to caller
    //     if (originalRequest.url == loginUrl) {
    //       return Promise.reject(error);
    //     }

    //     if (error.response.status === 401 && originalRequest.url !== refreshTokenUrl) {
    //       if (ApiBase.REFRESH_PENDING === false) {
    //         console.log(`RESPONSE interceptor got 401, trying to refresh access token... original url: ${originalRequest.url}`);
    //         ApiBase.REFRESH_PENDING = true;
    //         const accessTokenResponse = await this.refreshToken();
    //         const accessToken = accessTokenResponse.data.Token;
    //         axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
    //         console.log("Token refreshed");
    //         ApiBase.REFRESH_PENDING = false;
    //         return this.httpClient(originalRequest);
    //       } else {
    //         //console.log(`RESPONSE interceptor got 401, there is already refresh-token request pending, not creating new. original url: ${originalRequest.url}`);
    //       }

    //       // Re-request last request
    //       if (SessionManager.loggedInStatus() === true) {
    //         return this.httpClient(originalRequest);
    //       }
    //     }

    //     // Refresh-token request gave error, we can logout
    //     if (SessionManager.loggedInStatus() === true) {
    //       // Logout user because token refresh failed
    //       console.log("RESPONSE interceptor logging out and resetting session");
    //       SessionManager.resetSession();
    //       history.push("/login");
    //     }

    //     return Promise.reject(error);
    //   }.bind(this)
    // );
  }

  async refreshToken(firebaseInfo) {
    let axiosConfig = {
      withCredentials: true,
    }
    let data = undefined
    if (firebaseInfo) {
      data = {
        FirebaseToken: firebaseInfo.firebaseToken,
        DeviceType: firebaseInfo.deviceType,
        DeviceId: firebaseInfo.deviceId,
      }
    } else {
      data = {
        //FirebaseToken: '',
        //DeviceType: '',
        //DeviceId: ''
      }
    }

    const response = await this.httpClient.post(refreshTokenUrl, data, axiosConfig);

    SessionManager.setAccessToken(response.data.Token);
    return response;
  }

  getBearerToken() {
    return SessionManager.getAccessToken();
  }

  createAuthorizationHeaderValue(token) {
    if (!token) {
      console.log("ApiBase no access token");
      return undefined;
    }

    let tokenVal = token;
    if (tokenVal.startsWith('Bearer', 0)) {
      tokenVal = tokenVal.slice(6);
    }
    return `${this.authzType} ${tokenVal}`;
  }

  getAuthorizationHeaderValue() {
    const accessToken = this.getBearerToken();
    return `${this.authzType} ${accessToken}`;
  }

  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    // return sessionStorage.getItem('clienttoken');
  }

  invalidateCookie() {
    //document.cookie = "token=; domain=.daisynet.fi; expires = Thu, 01 Jan 1970 00:00:00 GMT";
  }

}
import React                  from "react";
import "../invoiceBasis.css";
import Languages              from "../../../translations";
import Typography             from "@mui/material/Typography";
import TableContainer         from "@mui/material/TableContainer";
import Table                  from "@mui/material/Table";
import TableHead              from "@mui/material/TableHead";
import TableRow               from "@mui/material/TableRow";
import TableCell              from "@mui/material/TableCell";
import TableBody              from "@mui/material/TableBody";
import Paper                  from "@mui/material/Paper";

const InvoiceDataBottomBarContent = ({totalHours}) => {


  const checkWhetherZero = (hoursAndMinutes) => {
    if (hoursAndMinutes === "0 t 00 min" || hoursAndMinutes === "0 h 00 min") {
      return(true);
    }
    else {
      return(false);
    }
  }

  return(
    <React.Fragment>

      <Typography 
        color="textSecondary">{Languages.uctHoursTotal}
      </Typography>
      <div style={{width: "94%", maxWidth:"800px", marginBottom:"-2px"}}>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="total hours">

            <TableHead>

              <TableRow>
                <TableCell padding="none" align="center" variant="head" style={{backgroundColor: "#ffc000"}}>
                  {Languages.uctBookings}
                </TableCell>
                <TableCell padding="none" align="center" variant="head" style={{backgroundColor: "#6BAF6E"}}>
                  {Languages.uctRealizedCareTimes}
                </TableCell>
                <TableCell padding="none" align="center" variant="head" style={{backgroundColor: "#ff4d88"}}>
                  {Languages.uctPreSchoolTeaching}
                </TableCell>
                {checkWhetherZero(totalHours.InvoiceFreeHours) ? null : <TableCell padding="none" align="center" variant="head">
                  {Languages.uctPaymentFree}
                </TableCell>}
                <TableCell padding="none" align="center" variant="head" style={{backgroundColor: "#5A5A5A"}}>
                  {Languages.uctBillingCaps}
                </TableCell>
              </TableRow>

            </TableHead>

            <TableBody>
              <TableRow>
                <TableCell padding="none" align="center" style={{backgroundColor: "#ffc000"}}>{totalHours.ReservationHours}</TableCell>
                <TableCell padding="none" align="center" style={{backgroundColor: "#6BAF6E"}}>{totalHours.StatusHours}</TableCell>
                <TableCell padding="none" align="center" style={{backgroundColor: "#FF4D88"}}>{totalHours.PreSchoolHours}</TableCell>
                {checkWhetherZero(totalHours.InvoiceFreeHours) ? null : <TableCell padding="none" align="center">{totalHours.InvoiceFreeHours}</TableCell>}
                <TableCell padding="none" align="center" data-testid={"invoice-bottom-bar-totalHours"} style={{backgroundColor: "#5A5A5A"}}>{totalHours.PaymentHours}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </React.Fragment>
  )
}

export default InvoiceDataBottomBarContent;
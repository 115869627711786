import React, { useEffect, useState } from "react";
import "../newmessage.css";
import Languages from "../../../../translations";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import Typography from "@mui/material/Typography";
import { ThemeProvider, StyledEngineProvider, createTheme, adaptV4Theme } from "@mui/material/styles";
import { isAnyPropUndefinedOrNull } from "../../../../utils";

const MessageTitleField = (props) => {

  const {saveTempTitleContent, tempSavedTitleContent, maxLengthForTitle, setMessageTitle, messageTitle} = props;

  const undefinedOrNullPropExists = isAnyPropUndefinedOrNull(props);

  const theme2 = createTheme(adaptV4Theme({ 
    palette: {
      text: {
        // textPrimary: "#BBBB99",
      }
    }
  }))

  const [titleIsTooLong, setTitleIsTooLong] = useState(false);
  const [mount, setMount] = useState(true);

  useEffect(() => {
    if (mount) {
      if (typeof setMessageTitle !== "undefined") {
        setMessageTitle(tempSavedTitleContent);
      }
      setMount(false);
    }
  }, [mount, setMessageTitle, tempSavedTitleContent]);

  const handleMessageTitleChange = (e) => {
    
    if (e.target.value.length <= maxLengthForTitle) {
      setMessageTitle(e.target.value);
      setTitleIsTooLong(false);
      saveTempTitleContent(e.target.value);
    }
    else {
      setMessageTitle(e.target.value.substring(0,maxLengthForTitle));
      setTitleIsTooLong(true);
      saveTempTitleContent(e.target.value.substring(0,maxLengthForTitle));
    }

  }

  if (undefinedOrNullPropExists) {
    return(
      <div data-testid="MessageTitleField-undefined-or-null"></div>
    )
  }
  else{

    return (
      <div style={{marginLeft:"10px", marginRight: "10px"}}>
        <FormGroup>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme2}>
              <Typography
                component={'span'} 
                variant={'body2'}
                color="textSecondary">
                <TextField
                  required
                  id="test_NewMsg_MsgTitle_Txt"
                  label={Languages.msgNewMsgTitleInfo}
                  className=""
                  multiline
                  maxRows="5"
                  margin="normal"
                  value={messageTitle}
                  onChange={handleMessageTitleChange}
                  fullWidth
                  autoComplete="off"
                />
              </Typography>
            </ThemeProvider>
          </StyledEngineProvider>
        </FormGroup>
      
        {titleIsTooLong && <p>{Languages.msgTitleMaxLength}</p>}
      </div>
    );
  }
}

export default MessageTitleField;
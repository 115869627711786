import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ContentBox from "../../../Components/ContentBox";
import Languages from "../../../translations";

const StrongIdentificationRedirectView = ({goToLogin}) => {


  return(

    <React.Fragment>
      <div className="Login textCenter">
        <ContentBox header={Languages.passwordReset}>        
          <Typography>{Languages.useStrongAuthenticationInstead}
          </Typography>
          <br />
          <Button
            style={{marginTop: "20px", marginBottom: "20px"}}
            onClick={goToLogin}
            color="primary"
            variant="outlined"
            id="test_toLoginPageButton"
          >{"OK"}</Button>  
        </ContentBox>
      </div>
    </React.Fragment>

  )

}

export default StrongIdentificationRedirectView;
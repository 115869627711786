import React, { useState, useReducer, useEffect } from "react";
import { useStateValue } from "../../../../State";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Divider from '@mui/material/Divider';
import AccordionActions from '@mui/material/AccordionActions';
import IconButton from '@mui/material/IconButton';
import Edit from '@mui/icons-material/EditRounded';
import Check from '@mui/icons-material/Check';
import Undo from '@mui/icons-material/Undo';
import { Add } from '@mui/icons-material';
import SupervisedUserCircleIcon         from '@mui/icons-material/SupervisedUserCircle';
import WarningIcon         from '@mui/icons-material/Warning';
import Languages from "../../../../translations";
import BackupParentInfoReadOnlyView from "./BackupParentInfoReadOnlyView";
import BackupParentInfoEditView from "./BackupParentInfoEditView";
import { isValidName, validatePhoneNumber } from "./";
import { convertNullToString } from "../../../../utils";
import { types } from "../../../../Reducers/actionTypes";
import ErrorIcon from '@mui/icons-material/Error';



const styles = {
  color: "#4377BA",
  fontSize: "14pt",
  fontWeight: "bold",
  error: {
  display: "flex",
  color: "4377BA"
  }
}

/*
api endpoints
update
PUT /api/backup-persons/(childPersonId)
add new
POST /api/backup-persons/(childPersonId)
Body:
{
  Id: string,
  FirstName: string,
  LastName: string,
  HomePhone: {
    ID: string,
    Number: string,
    Type: int
  },
  WorkPhone: {
    ID: string,
    Number: string,
    Type: int
  },
  Priority?: int
}
*/

export const formDataReducer = (state, action) => {
  if (action.type === "update") {
    const field = action.data.fieldName;
    const personId = action.data.personId;
    const entityId = action.data.entityId;
    const newState = [...state];

    if (!entityId && !personId) {
      throw new Error("formDataReducer Error: at least fieldId must be defined. Reducer Action =>", action.type);
    }

    let entityIndex;

    if (personId) {
      entityIndex = newState.findIndex(s => s.personId === personId);
    } else {
      entityIndex = newState.findIndex(s => s.entityId === entityId);
    }

    if (entityIndex === -1) {
      newState.push({
      })
      entityIndex = newState.lastIndex;
    }

    newState[entityIndex] = {
      ...newState[entityIndex],
      personId: personId,
      entityId: entityId,
      [field]: action.data.fieldValue
    }

    return newState;

  } else if (action.type === "backend_update") {
    const newState = [];
    const parents = action.data.filter(parent => { return parent.Type === "BP"; })
    parents.forEach((parentInfo, index) => {

      let parentObj = {
        entityId: `bu_${index}`,
        personId: parentInfo.PersonID,
        FirstName: parentInfo.FirstName,
        LastName: parentInfo.LastName,
        ParentType: parentInfo.Type,
      };

      parentInfo.Phonenumbers.forEach(ph => {
        if (ph.Type === 1) {
          parentObj["BUParentWorkNumber"] = {
            ID: ph.ID,
            Number: convertNullToString(ph.Number),
            Type: ph.Type,
          }
        }
        if (ph.Type === 0) {
          parentObj["BUParentPhoneNumber"] = {
            ID: ph.ID,
            Number: convertNullToString(ph.Number),
            Type: ph.Type,
          }
        }
      })

      newState.push(parentObj);
    });

    return newState;
  } else if (action.type === "add_new") {
    const newState = [...state];

    let parentObj = {
      personId: undefined,
      entityId: `bu_${newState.length}`,
      newField: true,
      FirstName: undefined,
      LastName: undefined,
      ParentType: "BP",
      BUParentPhoneNumber: {
        Type: 0,
        Number: undefined,
      },
      BUParentWorkNumber: {
        Type: 1,
        Number: undefined,
      }
    }

    newState.push(parentObj);

    return newState;
  }

  return state;
}

const handleFormFieldChange = (event, dispatch, ChildInfo, extra) => {
  const personId = extra.personId;
  const entityId = extra.entityId;

  let eventValue = event.target.value;

  if (event.target.type === "checkbox") {
    eventValue = event.target.checked;
  }

  switch (event.target.name) {
    case "BUParentFirstName":
      dispatch({
        type: "update", data: {
          personId: personId,
          entityId: entityId,
          fieldName: "FirstName",
          fieldValue: eventValue,
        }
      });
      break;

    case "BUParentLastName":
      dispatch({
        type: "update", data: {
          personId: personId,
          entityId: entityId,
          fieldName: "LastName",
          fieldValue: eventValue,
        }
      });

      break;
    case "BUParentPhoneNumber":
    case "BUParentWorkNumber":
      let parent = ChildInfo.Info.ParentsInfo.find(pi => pi.PersonID === personId);
      let parentNumberObj;

      if (!parent) {
        // New parent without backend data
        parentNumberObj = {
          Type: extra.type,
          Number: eventValue
        }
      } else {
        parentNumberObj = parent.Phonenumbers.find(pin => pin.ID === extra.numberId && pin.Type === extra.type);
      }

      const parentNumber = { ...parentNumberObj }

      parentNumber.Number = eventValue;

      dispatch({
        type: "update", data: {
          personId: personId,
          entityId, entityId,
          fieldName: event.target.name,
          fieldValue: parentNumber,
        }
      });
      break;
    default:
      break;
  }

};
/*
Body:
{
  Id: string,
  FirstName: string,
  LastName: string,
  HomePhone: {
    ID: string,
    Number: string,
    Type: int
  },
  WorkPhone: {
    ID: string,
    Number: string,
    Type: int
  },
  Priority?: int
}
*/

const isNullOrEmpty = (str) => {
  if (str === null || str === undefined || str.length === 0) {
    return true;
  }
  return false;
}
const buildBackupParentRequest = (formData) => {
  let parentInfo = {
    Id: formData.personId,
    FirstName: formData.FirstName,
    LastName: formData.LastName,
  }

  // Empty phone number is allowed. Backend should remove it if it's empty
  if (formData.BUParentPhoneNumber?.Number?.length > 4) {
    parentInfo.HomePhone =
    {
      Type: 0,
      Id: formData.BUParentPhoneNumber.ID,
      Number: formData.BUParentPhoneNumber.Number
    }
  }

  if (formData.BUParentWorkNumber?.Number?.length > 4) {
    parentInfo.WorkPhone = {
      Type: 1,
      Id: formData.BUParentWorkNumber.ID,
      Number: formData.BUParentWorkNumber.Number
    }
  }

  return parentInfo;
}

const validateForm = (formData) => {
  const checkStatus = [];

  const validate = (field, value) => {
    const validators = {
      FirstName: (value) => {
        return { valid: isValidName(value) };
      },
      LastName: (value) => {
        return { valid: isValidName(value) };
      },
      BUParentPhoneNumber: (value) => {
        const result = validatePhoneNumber(value.Number, false);

        return result;
      },
      BUParentWorkNumber: (value) => {
        const result = validatePhoneNumber(value.Number, true);

        return result;
      },
      default: () => ({ valid: true })
    }

    return (validators[field] || validators['default'])(value);
  }

  formData.forEach(entity => {
    Object.keys(entity).forEach(key => {
      const value = entity[key];
      const result = validate(key, value);

      if (!result.valid) {
        checkStatus.push({
          personId: entity.personId,
          field: key,
          ...result,
        })
      }
    })
  })
  return checkStatus;
}


const BackupParentInfo = ({ childId, childColor }) => {
  const { state: { children }, actions } = useStateValue();
  const [expanded, setExpanded] = useState(false);
  const [editing, setEditing] = useState(false);
  const [addingNew, setAddingNew] = useState(false);
  const [formFieldsData, dispatchFormFieldsUpdate] = useReducer(formDataReducer, []);
  const [formValidStatus, setFormValidStatus] = useState([])
  const { ChildList } = children;
  const [successNotHandledYet, setSuccessNotHandledYet] = useState(false);

  /*useEffect(() => {
    console.log("successNotHandledYet", successNotHandledYet);
  }, [successNotHandledYet]);*/

  useEffect(() => {
    if (!editing || !addingNew) {
      const currentChild = ChildList.find(c => c.Id == childId);

      if (currentChild.Info) {
        dispatchFormFieldsUpdate({ type: "backend_update", data: currentChild.Info?.ParentsInfo });
      }
    }
  }, [ChildList])

  useEffect(() => {
    const formStatus = validateForm(formFieldsData);
    setFormValidStatus(formStatus);
  }, [formFieldsData])

  useEffect(() => {
    //console.log("USEEFFECT deletestatus", children.backupParent.deleteStatus)

    switch(children.backupParent.deleteStatus)
      {
        case "INITIAL_STATE":
          break;
        case types.LOADING_DELETE_BACKUP_PARENT:
          break;
        case types.DELETE_BACKUP_PARENT_SUCCESS:
          actions.triggerAddToSnackBarQueue({color: "#000000", message: Languages.ciBackupPersonRemovalSuccess});
          if (successNotHandledYet) {
            setSuccessNotHandledYet(false);
            actions.triggerLoadChildinfo(childId);
          }
          break;
        case types.DELETE_BACKUP_PARENT_FAILED:
          //console.log("CUckOO")
          if (successNotHandledYet) {
            actions.triggerAddToSnackBarQueue({color: "#000000", message: Languages.ciBackupPersonRemovalSuccess + children.backupParent.deleteCode + ")"});
            setSuccessNotHandledYet(false);
          }
          break;
        default:
          break;
        }
  }, [children.backupParent.deleteStatus]);


  const toggleExpanded = () => {
    setExpanded(!expanded);
  }

  const onEdit = () => {
    setEditing(true);
  }

  const onUndo = () => {
    setEditing(false);
    setAddingNew(false);
    actions.triggerLoadChildinfo(childId);
  }

  const onSave = () => {
    const formStatus = validateForm(formFieldsData);
    setFormValidStatus(formStatus);
    if (formStatus.length !== 0) {
      console.log("Validation errors in form data. Not saving.");
      return;
    }
    formFieldsData.forEach((data) => {
      const parentData = buildBackupParentRequest(data);
      actions.triggerUpdateBackupParent(childId, parentData);
    })

    setEditing(false);
  }

  const onSaveNew = () => {
    const formStatus = validateForm(formFieldsData);
    setFormValidStatus(formStatus);
    if (formStatus.length !== 0) {
      console.log("Validation errors in form data. Not saving.");
      return;
    }

    const newData = formFieldsData.filter(data => data.newField === true);
    newData.forEach((data) => {
      const parentData = buildBackupParentRequest(data);
      actions.triggerAddBackupParent(childId, parentData);
    })
    setAddingNew(false);
  }

  const onAdd = () => {
    dispatchFormFieldsUpdate({
      type: "add_new",
    });

    setAddingNew(true);
  }

  const onFormFieldChange = (event, extra) => {
    const currentChild = ChildList.find(c => c.Id == childId);
    handleFormFieldChange(event, dispatchFormFieldsUpdate, currentChild, extra);
  }

  
const deleteBackupParent = (backupPersonId) => {
  console.log("Deleting backup parent " + backupPersonId);
  if (!successNotHandledYet) {
    actions.triggerDeleteBackupParent(childId, backupPersonId);
    setSuccessNotHandledYet(true);
  }
}


  if (formFieldsData.length === 0) {
    return (
      <Accordion className="painikkeet"
        style={{ border: "5px", borderLeft: "12px", borderStyle:"solid", borderColor: "#EE5711", backgroundColor: "#FFEBD4", color: "#181818", verticalAlign: "middle" }}
        expanded={expanded}
        onChange={toggleExpanded}>
        <AccordionSummary aria-controls="panel4a-content" expandIcon={<ExpandMoreIcon />}>
          <Typography className="heading" style={styles.error}><SupervisedUserCircleIcon color="secondary"/> <b style={{marginLeft: "10px", display: "inline",  /*textDecoration: "underline", textDecorationColor: "#e036da", textDecorationThickness: "5px"*/}}>{Languages.ciBackupPersonsNone} &nbsp;&nbsp;<ErrorIcon color="error" /></b></Typography>
        </AccordionSummary>
        <AccordionActions className="painikkeet">
          <IconButton id="test_BUParentsInfo_AddBtn" onClick={onAdd} size="large"><Add /></IconButton>
        </AccordionActions>
      </Accordion>
    );
  }

  return (
    <Accordion className="painikkeet"
      style={{ border: "5px", borderLeft: "12px", borderStyle: "solid", borderColor: childColor, backgroundColor: "#ffffff", color: "#181818" }}
      expanded={expanded}
      onChange={toggleExpanded}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}
        aria-controls="panel4a-content"
        id="test_BUParentsInfo_Btn" /*id="panel4a-header" */>
        <Typography className="heading" style={styles}><SupervisedUserCircleIcon /> <b style={{marginLeft: "10px", display: "inline"}}>{Languages.ciBackupPersons}</b></Typography>
      </AccordionSummary>
      <div className="row">
        <AccordionDetails className="ml-3">
          {!editing &&
            <BackupParentInfoReadOnlyView
            
              backupPersons={formFieldsData}
              formStatus={formValidStatus}
              onFormFieldChange={onFormFieldChange}
            />
          }
          {editing &&
          
            <BackupParentInfoEditView
              backupPersons={formFieldsData}
              formFieldChange={onFormFieldChange}
              formStatus={formValidStatus} 
              deleteBackupParent={(backupParentId) => deleteBackupParent(backupParentId)}
              deleteIsLoading={successNotHandledYet}
              />
          }
        </AccordionDetails>
      </div>

      <Divider />

      {(!editing && !addingNew) &&
        <AccordionActions className="painikkeet">
          <IconButton id="test_BUParentsInfo_EditBtn" onClick={onEdit} size="large"> <Edit /> </IconButton>
          <IconButton id="test_BUParentsInfo_AddBtn" onClick={onAdd} size="large"> <Add /> </IconButton>
        </AccordionActions>
      }
      {editing &&
        <AccordionActions className="painikkeet">
          <IconButton id="test_BUParentsInfo_UndoBtn" onClick={onUndo} size="large"> <Undo /> </IconButton>
          {formValidStatus.length === 0 &&
            <IconButton id="test_BUParentsInfo_SaveBtn" onClick={onSave} size="large"> <Check /> </IconButton>
          }
        </AccordionActions>
      }
      {addingNew &&
        <AccordionActions className="painikkeet">
          <IconButton id="test_BUParentsInfo_UndoBtn" onClick={onUndo} size="large"> <Undo /> </IconButton>
          {formValidStatus.length === 0 &&
            <IconButton id="test_BUParentsInfo_SaveBtn" onClick={onSaveNew} size="large"> <Check /> </IconButton>
          }
        </AccordionActions>
      }
    </Accordion>
  );
}

export default BackupParentInfo;
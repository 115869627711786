import { ApiBase } from "../internal";

export class ApprovalApi extends ApiBase {
  /*constructor() {
    super();
  }*/


  async saveApproval(ApprovalData) {
    const response = await this.httpClient.post("/vasu/saveapproval/", ApprovalData,
      {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": sessionStorage.getItem('language') === "en" ? "en-US" : sessionStorage.getItem('language')
        }
      });
    return response;
  }
}
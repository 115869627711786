import React, { useEffect, useState } from "react";
import moment from "moment";
import Slider from "@mui/material/Slider";
import { useStateValue } from "../../../../State";
import Typography from "@mui/material/Typography";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DigitalClock } from "@mui/x-date-pickers/DigitalClock";
import { MultiSectionDigitalClock } from "@mui/x-date-pickers/MultiSectionDigitalClock";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Languages from "../../../../translations";
const CalendarTimeSlider = ({ handleResLenght }) => {
  const { state, actions } = useStateValue();
  const defaultNursingTimes = state.nursingtimes.defaultNursingTimes;
  const clockType = state.caretimes.clockType.Type;
  const [allowedMinutes, setAllowedMinutes] = useState([0, 15, 30, 45]);
  const [type, setType] = useState(clockType);
  //Reservation start/end times stored as minutes for the slider component
  const [value, setValue] = React.useState([
    moment
      .duration(moment(defaultNursingTimes.Start).format("HH:mm"))
      .asMinutes(),
    moment
      .duration(moment(defaultNursingTimes.End).format("HH:mm"))
      .asMinutes(),
  ]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChange2 = (event, newValue) => {
    handleResLenght(newValue);
  };

  const [endHours, setEndHours] = useState(0);

  const handleEndHours = (value) => {
    setEndHours(value.hours());
    if (value.hours() == 23) {
      setAllowedMinutes([0, 15, 30, 45, 59]);
    } else {
      setAllowedMinutes([0, 15, 30, 45]);
    }
  };

  const handleChange3 = (event) => {
    setStartHours(event.target.value);
    handleResLenght([
      event.target.value * 60 + startMinutes,
      endHours2 * 60 + endMinutes,
    ]);
  };
  const handleChange4 = (event) => {
    setStartMinutes(event.target.value);
    handleResLenght([
      startHours * 60 + event.target.value,
      endHours2 * 60 + endMinutes,
    ]);
  };
  const handleChange5 = (event) => {
    setEndHours2(event.target.value);
    if (event.target.value == 24) {
      setEndMinutes(0);
      handleResLenght([startHours * 60 + startMinutes, 1440]);
    } else {
      handleResLenght([
        startHours * 60 + startMinutes,
        event.target.value * 60 + endMinutes,
      ]);
    }
  };
  const handleChange6 = (event) => {
    setEndMinutes(event.target.value);
    handleResLenght([
      startHours * 60 + startMinutes,
      endHours2 * 60 + event.target.value,
    ]);
  };

  //console.log(state.caretimes.clockType);
  const [startHours, setStartHours] = React.useState(
    moment(defaultNursingTimes.Start).hours()
  );
  const [startMinutes, setStartMinutes] = React.useState(
    moment(defaultNursingTimes.Start).minute()
  );
  const [endHours2, setEndHours2] = React.useState(
    moment(defaultNursingTimes.End).hours()
  );
  const [endMinutes, setEndMinutes] = React.useState(
    moment(defaultNursingTimes.End).minute()
  );
  if (type === "slider") {
    return (
      <Slider
        sx={{
          height: 8,
          borderRadius: 0,
          mt: 2,
          width: "90%",
          ml: "2%",

          "& .MuiSlider-thumb": {
            backgroundColor: "#0074C8",
            height: 24,
            width: 24,
          },

          "& .MuiSlider-track": {
            height: 8,
            color: "#0074C8",
          },
          "& .MuiSlider-rail": {
            color: "#e0e0de",
            opacity: 1,
            height: 8,
          },
          "& .MuiSlider-valueLabel": {
            fontSize: 12,
            fontWeight: "normal",
            top: 2,
            backgroundColor: "unset",
            color: "black",
            "&:before": {
              display: "none",
            },
            "& *": {
              background: "transparent",
              color: "black",
            },
          },
        }}
        value={value}
        valueLabelDisplay="on"
        valueLabelFormat={(value) =>
          moment().startOf("day").add(value, "minutes").format("HH:mm") ==
            "00:00" && value == 1440
            ? "24:00"
            : moment().startOf("day").add(value, "minutes").format("HH:mm")
        }
        min={0}
        max={1440}
        step={15}
        onChange={handleChange}
        onChangeCommitted={handleChange2}
      />
    );
  }
  if (type === "kello") {
    return (
      <Typography color="textSecondary" fontWeight="bold" sx={{}}>
        {"Kello"}
      </Typography>
    );
  }
  if (type === "dropdown") {
    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Box>
          {/*<Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="center"
          >
            <DesktopTimePicker
              sx={{ width: 180, "& input": { color: "black" } }}
              label="Alku"
              views={["hours", "minutes"]}
              defaultValue={moment()}
              minutesStep={15}
              skipDisabled={true}
              slotProps={{
                popper: {
                  sx: {
                    "& .MuiList-root": {
                      backgroundColor: "white",
                    },
                    "& .MuiMenuItem-root": {
                      "&.Mui-selected": {
                        backgroundColor: "#0074C8",
                        color: "white",
                      },
                      color: "black",
                    },
                    "& .MuiDialogActions-root": {
                      backgroundColor: "#0074C8",
                      height: 45,
                    },
                  },
                },
              }}
            />
            <DesktopTimePicker
              sx={{ width: 180, "& input": { color: "black" } }}
              label="Loppu"
              views={["hours", "minutes"]}
              defaultValue={moment()}
              timeSteps={{ hours: 1, minutes: 1 }}
              shouldDisableTime={(tvalue, view) => {
                return (
                  view == "minutes" &&
                  !allowedMinutes.includes(tvalue.minutes())
                );
              }}
              onViewChange={(newValue) => {
                console.log(newValue);
              }}
              onSelectedSectionsChange={(newValue, FieldSelectedSections) => {
                console.log(newValue, FieldSelectedSections);
              }}
              onChange={(TValue, FieldChangeHandlerContext) => {
                handleEndHours(TValue);
              }}
              skipDisabled
              slotProps={{
                popper: {
                  sx: {
                    "& .MuiList-root": {
                      backgroundColor: "white",
                    },
                    "& .MuiMenuItem-root": {
                      "&.Mui-selected": {
                        backgroundColor: "#0074C8",
                        color: "white",
                      },
                      color: "black",
                    },
                    "& .MuiDialogActions-root": {
                      backgroundColor: "#0074C8",
                      height: 45,
                    },
                  },
                },
              }}
            />
            </Stack>*/}

          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="center"
            sx={{ paddingBottom: 3 }}
          >
            <Card
              variant="outlined"
              sx={{
                bgcolor: "white",
                mt: 0.5,
                ml: 1,
              }}
            >
              <CardHeader
                title={
                  <Typography
                    color="textSecondary"
                    fontWeight="bold"
                    textAlign="center"
                  >
                    {Languages.clockTypeStart}
                  </Typography>
                }
                sx={{ paddingBottom: 0, paddingTop: 2 }}
              />
              <CardContent>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  justifyContent="center"
                >
                  <FormControl sx={{}}>
                    <Select
                      sx={{
                        color: "black",
                        fontSize: 18,
                        "& .MuiSelect-select": {
                          paddingRight: "16px!important",
                          paddingLeft: "16px",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#0074C8",
                        },
                      }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={startHours}
                      onChange={handleChange3}
                      inputProps={{
                        IconComponent: () => null,
                      }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "center",
                        },
                        style: {
                          maxHeight: 240,
                        },
                        getContentAnchorEl: null,
                        MenuListProps: {
                          sx: {
                            backgroundColor: "white",
                            color: "black",
                            "&& .Mui-selected": {
                              color: "white",
                              backgroundColor: "#0074C8",
                            },
                          },
                        },
                      }}
                    >
                      <MenuItem value={0}>00</MenuItem>
                      <MenuItem value={1}>01</MenuItem>
                      <MenuItem value={2}>02</MenuItem>
                      <MenuItem value={3}>03</MenuItem>
                      <MenuItem value={4}>04</MenuItem>
                      <MenuItem value={5}>05</MenuItem>
                      <MenuItem value={6}>06</MenuItem>
                      <MenuItem value={7}>07</MenuItem>
                      <MenuItem value={8}>08</MenuItem>
                      <MenuItem value={9}>09</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={11}>11</MenuItem>
                      <MenuItem value={12}>12</MenuItem>
                      <MenuItem value={13}>13</MenuItem>
                      <MenuItem value={14}>14</MenuItem>
                      <MenuItem value={15}>15</MenuItem>
                      <MenuItem value={16}>16</MenuItem>
                      <MenuItem value={17}>17</MenuItem>
                      <MenuItem value={18}>18</MenuItem>
                      <MenuItem value={19}>19</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={21}>21</MenuItem>
                      <MenuItem value={22}>22</MenuItem>
                      <MenuItem value={23}>23</MenuItem>
                    </Select>
                  </FormControl>
                  <Typography
                    color="textSecondary"
                    fontSize="large"
                    fontWeight="bold"
                  >
                    {":"}
                  </Typography>
                  <FormControl sx={{}}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={startMinutes}
                      onChange={handleChange4}
                      inputProps={{ IconComponent: () => null }}
                      sx={{
                        color: "black",
                        fontSize: 18,
                        "& .MuiSelect-select": {
                          paddingRight: "16px!important",
                          paddingLeft: "16px",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#0074C8",
                        },
                      }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "center",
                        },
                        style: {
                          maxHeight: 240,
                        },
                        getContentAnchorEl: null,
                        MenuListProps: {
                          sx: {
                            backgroundColor: "white",
                            color: "black",
                            "&& .Mui-selected": {
                              color: "white",
                              backgroundColor: "#0074C8",
                            },
                          },
                        },
                      }}
                    >
                      <MenuItem value={0}>00</MenuItem>
                      <MenuItem value={15}>15</MenuItem>
                      <MenuItem value={30}>30</MenuItem>
                      <MenuItem value={45}>45</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
              </CardContent>
            </Card>

            <Typography
              sx={{ paddingTop: 5 }}
              color="textSecondary"
              fontSize="large"
              fontWeight="bold"
            >
              {"-"}
            </Typography>
            <Card
              variant="outlined"
              sx={{
                bgcolor: "white",
                mt: 0.5,
                ml: 1,
              }}
            >
              <CardHeader
                title={
                  <Typography
                    color="textSecondary"
                    fontWeight="bold"
                    textAlign="center"
                  >
                    {Languages.clockTypeEnd}
                  </Typography>
                }
                sx={{ paddingBottom: 0, paddingTop: 2 }}
              />
              <CardContent>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  justifyContent="center"
                >
                  <FormControl sx={{}}>
                    <Select
                      sx={{
                        color: "black",
                        fontSize: 18,
                        "& .MuiSelect-select": {
                          paddingRight: "16px!important",
                          paddingLeft: "16px",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#0074C8",
                        },
                      }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={endHours2}
                      onChange={handleChange5}
                      inputProps={{
                        IconComponent: () => null,
                      }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "center",
                        },
                        style: {
                          maxHeight: 240,
                        },
                        getContentAnchorEl: null,
                        MenuListProps: {
                          sx: {
                            backgroundColor: "white",
                            color: "black",
                            "&& .Mui-selected": {
                              color: "white",
                              backgroundColor: "#0074C8",
                            },
                          },
                        },
                      }}
                    >
                      <MenuItem value={0}>00</MenuItem>
                      <MenuItem value={1}>01</MenuItem>
                      <MenuItem value={2}>02</MenuItem>
                      <MenuItem value={3}>03</MenuItem>
                      <MenuItem value={4}>04</MenuItem>
                      <MenuItem value={5}>05</MenuItem>
                      <MenuItem value={6}>06</MenuItem>
                      <MenuItem value={7}>07</MenuItem>
                      <MenuItem value={8}>08</MenuItem>
                      <MenuItem value={9}>09</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={11}>11</MenuItem>
                      <MenuItem value={12}>12</MenuItem>
                      <MenuItem value={13}>13</MenuItem>
                      <MenuItem value={14}>14</MenuItem>
                      <MenuItem value={15}>15</MenuItem>
                      <MenuItem value={16}>16</MenuItem>
                      <MenuItem value={17}>17</MenuItem>
                      <MenuItem value={18}>18</MenuItem>
                      <MenuItem value={19}>19</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={21}>21</MenuItem>
                      <MenuItem value={22}>22</MenuItem>
                      <MenuItem value={23}>23</MenuItem>
                      <MenuItem value={24}>24</MenuItem>
                    </Select>
                  </FormControl>
                  <Typography
                    color="textSecondary"
                    fontSize="large"
                    fontWeight="bold"
                  >
                    {":"}
                  </Typography>
                  <FormControl sx={{}}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={endMinutes}
                      onChange={handleChange6}
                      inputProps={{ IconComponent: () => null }}
                      disabled={endHours2 == 24 ? true : false}
                      sx={{
                        color: "black",
                        fontSize: 18,
                        "& .MuiSelect-select": {
                          paddingRight: "16px!important",
                          paddingLeft: "16px",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#0074C8",
                        },
                      }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "center",
                        },
                        style: {
                          maxHeight: 240,
                        },
                        getContentAnchorEl: null,
                        MenuListProps: {
                          sx: {
                            backgroundColor: "white",
                            color: "black",
                            "&& .Mui-selected": {
                              color: "white",
                              backgroundColor: "#0074C8",
                            },
                          },
                        },
                      }}
                    >
                      <MenuItem value={0}>00</MenuItem>
                      <MenuItem value={15}>15</MenuItem>
                      <MenuItem value={30}>30</MenuItem>
                      <MenuItem value={45}>45</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
              </CardContent>
            </Card>
          </Stack>
        </Box>
      </LocalizationProvider>
    );
  }
};

export default CalendarTimeSlider;

import React            from  'react';
import Fab              from  "@mui/material/Fab"; 
import GetAppIcon       from  '@mui/icons-material/GetApp';
import CancelIcon       from  '@mui/icons-material/Cancel';
import CircularProgress from  '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Languages                          from  "../../../../translations";



const PedagDocImageFolderFixedBottomBar = ({ imagefolderState, imageFolderFunctions }) => {

  return(
    <>
      <div className="FixedBottomBarPedag">
        {imagefolderState.download ? 
          <>
            <Typography style={{backgroundColor: "white", display: "table"}}>{Languages.choosePicturesToDownload}</Typography>
            <>
              <Fab id="download" style={{margin:"16px"}} color="secondary" aria-label="Downloading" className="fab" onClick={imageFolderFunctions["downloadFiles"]}>
                { imagefolderState.loading ?
                  <CircularProgress />
                :
                  <GetAppIcon />
                }
              </Fab>
            </>
            <Fab id="cancel" title="cancel" style={{margin:"16px"}} color="secondary" aria-label="Cancel" className="fab" onClick={imageFolderFunctions["changeMode"]}>
              <CancelIcon />
            </Fab>
          </> 
          :
          <>
            <Fab id="download" title="download" style={{margin:"16px"}} color="secondary" aria-label="Download images" className="fab" onClick={imageFolderFunctions["changeMode"]}>
              <GetAppIcon />
            </Fab>
          </>}
      </div>
    </>
  );
}

export default PedagDocImageFolderFixedBottomBar;
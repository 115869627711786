import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
} from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import moment from "moment";
import SwipeableViews from "react-swipeable-views";
import virtualize from "react-swipeable-views-utils/lib/virtualize";
import Typography from "@mui/material/Typography";
import { useStateValue } from "../../../../State";
import PropTypes from "prop-types";
import Badge from "@mui/material/Badge";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";
import { styled } from "@mui/material/styles";
import { types } from "../../../../Reducers/actionTypes";
import { Virtuoso } from "react-virtuoso";

function ServerDay(props) {
  const {
    highlightedDays,
    highlightedDays2,
    day,
    outsideCurrentMonth,
    ...other
  } = props;

  const isSelected =
    !props.outsideCurrentMonth &&
    highlightedDays.some(
      (e) => moment(e).format("MM.DD.YYYY") == moment(day).format("MM.DD.YYYY")
    );

  const isSelected2 =
    !props.outsideCurrentMonth &&
    highlightedDays2.some(
      (e) => moment(e).format("MM.DD.YYYY") == moment(day).format("MM.DD.YYYY")
    );

  if (isSelected2) {
    return (
      <Badge
        badgeContent={" "}
        variant="dot"
        overlap="circular"
        color="warning"
      >
        <PickersDay
          sx={{
            backgroundColor: isSelected ? "success.light" : "white",
            color: isSelected ? -"white" : "black",
          }}
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
        />
      </Badge>
    );
  }
  return (
    <PickersDay
      sx={{
        backgroundColor: isSelected ? "success.light" : "white",
        color: isSelected ? -"white" : "black",
      }}
      {...other}
      outsideCurrentMonth={outsideCurrentMonth}
      day={day}
    />
  );
}

const CTBCalendarDrawer = ({
  open,
  onClose,
  handleClickScroll,
  handleCalendarNavClick,
}) => {
  const { state, actions } = useStateValue();
  const [isLoading, setIsLoading] = React.useState(true);
  const [highlightedDays, setHighlightedDays] = React.useState([]);
  const [highlightedDays2, setHighlightedDays2] = React.useState([]);
  useEffect(() => {
    switch (state.caretimes.Quick.state) {
      case undefined:
        break;
      case types.CARETIMES_QUICK_LOAD_OK:
        setHighlightedDays(state.caretimes.Quick.DayList);
        setHighlightedDays2(state.caretimes.Quick.DayList2);
        setIsLoading(false);
        break;
      case types.CARETIMES_QUICK_LOAD_FAILED:
        break;
      default:
        break;
    }
    if (!open) {
      console.log("kiinni meni");
    }
  }, [state.caretimes.Quick.state, open]);

  const getUser = (index) => {
    const generated = [];
    if (!generated[index]) {
      generated[index] = index;
    }

    return generated[index];
  };

  const GenerateUsers = (length, startIndex = 0) => {
    return Array.from({ length }).map((_, i) => getUser(i + startIndex));
  };

  const GenerateUsersInit = (length, startIndex = 0) => {
    return Array.from({ length }).map((_, i) =>
      getUser(i + startIndex - length / 2)
    );
  };

  const START_INDEX = 1000;
  const INITIAL_ITEM_COUNT = 20;

  const [firstItemIndex, setFirstItemIndex] = useState(START_INDEX);
  const [firstItemIndex2, setFirstItemIndex2] = useState(START_INDEX);
  const [users, setUsers] = useState(() =>
    GenerateUsersInit(INITIAL_ITEM_COUNT, START_INDEX)
  );

  const prependItems = useCallback(() => {
    console.log("preped");
    if (open === true) {
      const usersToPrepend = 20;
      const nextFirstItemIndex = firstItemIndex - usersToPrepend;

      setFirstItemIndex(() => nextFirstItemIndex);
      setUsers(() => [
        ...GenerateUsers(usersToPrepend, nextFirstItemIndex),
        ...users,
      ]);
      return false;
    }
  }, [firstItemIndex, users, setUsers, open]);

  const append = useCallback(() => {
    console.log("append");
    if (open === true) {
      const usersToPrepend = 20;
      const nextFirstItemIndex = firstItemIndex2 + usersToPrepend;

      setUsers([
        ...users,
        ...GenerateUsers(usersToPrepend, firstItemIndex2 + 20),
      ]);
      setFirstItemIndex2(nextFirstItemIndex);
      return false;
    }
  }, [firstItemIndex2, users, setUsers, open]);

  return (
    <>
      <Drawer
        anchor={"top"}
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            height: "auto",
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
            backgroundColor: "white",
          },
        }}
        sx={{ zIndex: 0 }}
        transitionDuration={{ enter: 600, exit: 400 }}
      >
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Box sx={{ mt: 15 }}>
            <Virtuoso
              style={{ height: 600 }}
              firstItemIndex={firstItemIndex}
              initialTopMostItemIndex={INITIAL_ITEM_COUNT - 10}
              data={users}
              increaseViewportBy={200}
              overscan={200}
              startReached={open ? prependItems : null}
              endReached={append}
              itemContent={(index, user) => {
                return (
                  <CalendarElement
                    highlightedDays={highlightedDays.filter(
                      (day) =>
                        moment(day).month() ===
                        moment()
                          .add(user - 1000, "month")
                          .month()
                    )}
                    highlightedDays2={highlightedDays2.filter(
                      (day) =>
                        moment(day).month() ===
                        moment()
                          .add(user - 1000, "month")
                          .month()
                    )}
                    user={user}
                    index={index}
                    handleClickScroll={handleClickScroll}
                  ></CalendarElement>
                );
              }}
            />
          </Box>
        </LocalizationProvider>
      </Drawer>
    </>
  );
};

export default CTBCalendarDrawer;

const CalendarElement = ({
  highlightedDays,
  highlightedDays2,
  user,
  index,
  handleClickScroll,
}) => {
  return (
    <DateCalendar
      sx={{
        "& .MuiPickersCalendarHeader-root": {
          color: "black",
          marginLeft: 10,
          marginTop: 0,
        },
        "& .MuiPickersCalendarHeader-switchViewIcon": {
          display: "none",
        },
        "& .MuiPickersSlideTransition-root": {
          height: "auto",
        },
        "& .MuiPickersArrowSwitcher-button": {
          display: "none",
        },

        "& .MuiDayCalendar-weekNumberLabel": {
          color: "white",
        },
        "& .MuiDayCalendar-weekNumber: aria-label#Week 35": {
          color: "red",
        },
        "& .MuiDayCalendarSkeleton-daySkeleton": {
          backgroundColor: "#ECF3FB",
        },
      }}
      renderLoading={() => <DayCalendarSkeleton />}
      displayWeekNumber
      defaultCalendarMonth={moment().add(user - 1000, "month")}
      onChange={(newValue) => handleClickScroll(newValue)}
      slots={{
        day: ServerDay,
      }}
      slotProps={{
        day: {
          highlightedDays,
          highlightedDays2,
        },
      }}
    />
  );
};

import { ApiBase } from "../internal";

export class LeopsApi extends ApiBase {
  /*constructor() {
    super();
  }*/

  async loadLeops(LeopsID) {
      const response = await this.httpClient.get("/leops/getleops/" + LeopsID,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": sessionStorage.getItem('language') === "en" ? "en-US" : sessionStorage.getItem('language')
          }
        });
      // console.log("VASU API. Load Vasu response: ", response);
      return response;
  }
 
  async saveLockingLeops(Data) {
    //console.log("LEOPSAPI. Save locking. Status: ", Data);
    const response = await this.httpClient.post("/leops/savelocking/", Data,
      {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": sessionStorage.getItem('language') === "en" ? "en-US" : sessionStorage.getItem('language')
        }
      });
    return response;
  }

  async saveStudyFormLeops (Data) {
    //console.log("LEOPSAPI. Save locking. Status: ", Data);
    const response = await this.httpClient.post("/leops/savestudyform/", Data,
      {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": sessionStorage.getItem('language') === "en" ? "en-US" : sessionStorage.getItem('language')
        }
      });
    return response;
  }

  async saveChildEvaluationsLeops(Data) {
    //console.log("LEOPSAPI. Save Child Evaluations. Data: ", Data);
    
    let NewAssesmentData   = [];
    let RequestData = {};
    
    Data.Data.forEach(item => { 
      //console.log("LEOPSAPI. Data item: ",item);
      let AssesmentData = {LeopsId:   Data.LeopsId,
                           Row:       item.Row,
                           SubRow:    item.SubRow,
                           Learning:  item.Learning,
                           Knowing:   item.Knowing }
      NewAssesmentData.push(AssesmentData);
    });
    RequestData = {"Evaluations":NewAssesmentData, "IsSpringEval": Data.IsSpringEval};

    //console.log("LEOPSAPI. RequestData: ", RequestData);

    const response = await this.httpClient.post("/leops/saveevaluations/", RequestData,
      {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": sessionStorage.getItem('language') === "en" ? "en-US" : sessionStorage.getItem('language')
        }
      });
    return response;
  }

  async saveParentCommentsLeops (Data) {
    //console.log("LEOPSAPI. saveParentCommentsLeops. Data: ", Data);

    const response = await this.httpClient.post("/leops/savecomment/", Data,
      {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": sessionStorage.getItem('language') === "en" ? "en-US" : sessionStorage.getItem('language')
        }
      });
    return response;
  }
}

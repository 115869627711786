import React from "react";
import { useStateValue } from "../../State";
import { history } from "../../Router";
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';
import moment from "moment"
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import GroupsIcon from '@mui/icons-material/Groups';
import Languages from "../../translations";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));


const BulletinboardNotifications = ({ onClose }) => {
  const [expanded, setExpanded] = React.useState(false);
  const { state } = useStateValue();
  const { bulletinBoards } = state;

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleRedirectToBulletinboard = () => {
    if(history.location.pathname === '/bulletinboard') {
      onClose()
    }
    else {
      history.push("/bulletinboard")
    }
  }

if(bulletinBoards !== null && typeof bulletinBoards !== undefined && bulletinBoards.notes.length > 0) {
  return(
    <>
      <Divider textAlign="left"><Chip label={Languages.bbTitle} sx={{bgcolor: "#002E6D", textTransform: "uppercase"}} /></Divider>
      <Card sx={{ bgcolor: "#FFEBD4", border: "1px solid #EE5711", borderLeft: "7px solid #EE5711", margin: "5px" }}>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: "#EE5711" }} aria-label="Bulletinboard icon">
              <StickyNote2Icon />
            </Avatar>
          }
          action={
            <Tooltip title={Languages.awMoveToBulletin} arrow>
              <IconButton aria-label={Languages.awMoveToBulletin} onClick={handleRedirectToBulletinboard}>
                <FollowTheSignsIcon sx={{color: "#EE5711" }}/>
              </IconButton>
            </Tooltip>
          }
          title={<p style={{color: "black", fontSize: "large", marginBottom: "0", textTransform: "capitalize"}}><b>{bulletinBoards.notes.length === 1 ? Languages.awNewBulletin : Languages.awNewBulletins}!</b></p>}
          subheader={<>{bulletinBoards.notes.length}  {bulletinBoards.notes.length === 1 ? Languages.awNewBulletin : Languages.awNewBulletins}</>}
        />
        <CardActions disableSpacing>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label={Languages.awShowMore}
            title={Languages.awShowMore}
          >
            <ExpandMoreIcon sx={{color: "#EE5711" }} />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>  
            <List aria-labelledby="New content on bulletinboard list" >
              { bulletinBoards.notes.map((note, index) => {
                return(
                  <ListItem 
                    key={bulletinBoards.notes.Title + index} 
                    button 
                    divider 
                    sx={{borderBottom: "1px solid black"}} 
                    onClick={handleRedirectToBulletinboard}
                    secondaryAction={
                      <ChevronRightIcon sx={{color: "#EE5711"}}/>
                    }
                  >
                    <ListItemAvatar >
                      <Avatar sx={{bgcolor: "#EE5711"}}>
                        <StickyNote2Icon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      sx={{color: "black"}}
                      primary={note.Title}
                      secondary={
                        <>
                          <GroupsIcon  fontSize="medium" sx={{color: "#EE5711", }}/>&nbsp;{note.RecipientName}&nbsp;&nbsp;&nbsp;{moment(note.Date).format('DD.MM')}  - {moment(note.Available).format('DD.MM.YY')}
                        </>
                      }
                    />
                  </ListItem>
                )
              })}
            </List>
          </CardContent>
        </Collapse>
      </Card>
    </>
  )}
else return null  
}


export default BulletinboardNotifications
import React from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Languages from "../../../translations";
import FormGroup  from "@mui/material/FormGroup";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  root: { 
    color: "black",
    background: "white",
  }
})

const NewUserOrderUsernameInitView = ({fns, warnings, email}) => {

  const classes = useStyles();

  return(
    <React.Fragment>
      <Typography style={{marginTop: "20px"}}> {Languages.firstTimerInfo1} </Typography>
      <Typography style={{marginTop: "20px"}}> {Languages.firstTimerInfo2} </Typography>
      <br/>
        <Typography 
          color="secondary"
          component={'span'}
          variant={'body2'}>
          <FormGroup>
            <TextField
              InputProps={{className: classes.root, "data-testid": "nuou-bdate-input-2093" }}
              id="test_forgotten_password_childbirthdate"
              label={Languages.childsBirthdate}
              type="date"
              defaultValue="2015-07-15"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => fns.setChildBirthday(e.target.value)}
            />
            <br /> <br />
            <TextField styles={{marginTop: "10px"}}
              InputProps={{className: classes.root, "data-testid": "nuou-email-input-0937"}}
              id="test_forgotten_password_email"
              label={"Email"}
              variant="outlined"
              value={email}
              onChange={(e) => fns.setEmail(e.target.value)}
            />
          </FormGroup>
        </Typography>            
        <Typography color="error">
          {warnings.emailWarning && <span>{Languages.incorrectEmailAddress} <br /> </span> }
        
          {warnings.birthdayWarning && <span>{Languages.incorrectBirthDate}</span>}
        </Typography>
      <Button style={{marginTop: "20px"}}
        color="secondary"
        id="test_forgotten_password_reset_password"
        variant="contained"
        onClick={fns.sendRequest}>{Languages.orderUserNameAndPassword}
      </Button>
      <br />
      <Button
        style={{marginTop: "20px", marginBottom: "20px"}}
        onClick={fns.resetPageAndRedirect}
        color="secondary"
        variant="outlined"
        id="test_toLoginPageButton"
      >{Languages.toTheLoginPage}</Button>
    </React.Fragment>
  )
}

export default NewUserOrderUsernameInitView;
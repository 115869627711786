import {React, useState} from "react"
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { NextTime } from "../../Layouts/DiscussionBooking/DiscussionBookingLayout";
import Typography from '@mui/material/Typography';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import PersonIcon from '@mui/icons-material/Person';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import DeleteDiscussionBooking from "../../Layouts/DiscussionBooking/DeleteDiscussionBooking";
import "./discussionCard.css"
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AddLinkIcon from '@mui/icons-material/AddLink';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Languages from  '../../translations'
import ModifyDiscussionBookingButton from "./ModifyDiscussionBooking";
import Moment from "moment";




const DiscussionCard = ({old=false,preview=false,discussionId,type,dateData,cancelDate,parents,staff,remote,address,url,employeeInfoText,parentInfoText,provideKey,data }) =>{
    const [borderColor, setColor] = useState("#002E6D")
    const [anchorEl, setAnchorEl] = useState(null);
    const [successCopy, setSuccessCopy] = useState(false)

  const openURLPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };


  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePopoverClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccessCopy(false);
  };

  const handleCopyURLClick = () => {
    navigator.clipboard.writeText(url);
    setSuccessCopy(true)
    handleClose();

  }

  const open = Boolean(anchorEl);
  const id = open ? 'Meeting url popover' : undefined;

    const cardStyle={
        card:{
            padding: "0"
            
        },
        cardContent:{
            backgroundColor: borderColor,
            color: "black",
            position: "relative",
        },
        dividerItem:{
            height:"5px",
            width:"100%",
            backgroundColor: borderColor
        }
        ,
        topPaper:{
            padding: "0",
            position: "absolute",
            top: "0",
            left: "0",
            backgroundColor: borderColor,
            height:"20px",
            width:"100%",
            marginBottom: "10px",
            borderRadius: "0",
        },
        grid:{
            backgroundColor:"#F2F3F5"
        },
        gridItem:{
            display: "inline-block",
        },
        accordion:{
            color: "black"
        },
        moreInfo:{
            
        },
        cancelDate:{
            color: 'white'
        }
    }

    const action = (
        <>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handlePopoverClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
      );
    return(
        <>
        <Snackbar
            open={successCopy}
            autoHideDuration={2500}
            onClose={handlePopoverClose}
            message="Linkki kopioitu leikepöydälle"
            action={action}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            key={"snackbar"+provideKey}
      />
    <Card sx={cardStyle.card} key={provideKey}> 
        <CardContent sx={cardStyle.cardContent} elevation={2}>
                <Grid container spacing={"5px"} sx={cardStyle.grid}>
                    <Grid item xs={12} className="topInfoBar">
                        <Paper sx={[cardStyle.gridItem, {textAlign: "center"}]} elevation={0}>
                            <DiscType type={type} setColor={setColor} color={borderColor}/>
                        </Paper>
                        <Paper sx={[cardStyle.gridItem,{textAlign: "center"}]} elevation={0}>
                            <Typography variant="h6">
                                <AccessTimeIcon sx={{color: "#002E6D"}} />&nbsp;
                                <NextTime data={dateData}/>
                            </Typography>
                        </Paper>
                        <Paper sx={[cardStyle.gridItem,{textAlign: "center"}]} elevation={0}>
                                { url != null?
                                    <>  
                                        <Button onClick={openURLPopover}variant="contained" sx={{ width: "100%",height:"100%", color:"#002E6D", backgroundColor: "#D7E3ED", border: "1px solid #002E6D"}}>
                                            <AddLinkIcon />&nbsp;&nbsp;{Languages.discRemoteMeeting}
                                        </Button>
                                        <Popover
                                        
                                            id={id}
                                            open={open}
                                            anchorEl={anchorEl}
                                            onClose={handleClose}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                            >
                                            <Box sx={{backgroundColor: "#D7E3ED", color: "#002E6D", textAlign: "left"}}>
                                                <Button target="_blank" href={url} variant="text" sx={{backgroundColor: "#D7E3ED", color: "#002E6D", }}><OpenInNewIcon />&nbsp;&nbsp;{Languages.generalOpen}</Button>
                                                <br />
                                                <Button onClick={handleCopyURLClick} variant="text" sx={{backgroundColor: "#D7E3ED", color: "#002E6D", }}><ContentCopyIcon />&nbsp;&nbsp;{Languages.discCopy}</Button>
                                            </Box>
                                        </Popover>
                                    </>
                                : 
                                <><LocationOnIcon sx={{color: "#002E6D"}}/> {address}</>}
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sx={cardStyle.dividerItem} />
                    { !remote && url && <>
                        
                        <Grid item xs={12} sx={{textAlign:"center"}}><LocationOnIcon sx={{color: "#002E6D"}}/> {address}</Grid>
                        <Grid item xs={12} sx={cardStyle.dividerItem} />
                    </>}
                    <Grid item xs={12} sx={[ /* {textAlign: "center"} */]} >
                        <Participants backgroundColor={borderColor} employeeList={staff} parentList={parents}/>
                    </Grid> 
                    <Grid item xs={12} sx={[cardStyle.moreInfo ]} className="infoParent">      
                        <InformationBox employee  infoText={employeeInfoText} color={borderColor}/>
                        <InformationBox  infoText={parentInfoText} color={borderColor}/>
                    </Grid>
                </Grid>
                {Date.parse(cancelDate) > Date.now() && !old && !preview && <Paper sx={{ display:"flex",justifyContent:"center", backgroundColor:"transparent", }} elevation={0}>

                    <DeleteDiscussionBooking discussionId={discussionId}/>
                    <ModifyDiscussionBookingButton data={data}/>
                </Paper>
                }
                {!old && <Typography sx={cardStyle.cancelDate} variant="p" >{Languages.discCancelableUntil(Moment(cancelDate).format('LLLL'))}</Typography>}
        </CardContent>
    </Card>

    </>
    )
}


const Participants = ({employeeList, parentList, backgroundColor})=> {
    const participant = []
    for(const employee of employeeList){
        participant.push({name:employee.StaffName, employee: true})
    }
    for(const parent of parentList){
        participant.push({name:parent.ParentName, employee: false})
    }
    const style = {
        paper:{
            display: "flex",
            backgroundColor: "transparent",
            justifyContent: "space-evenly",
            margin: "5px",
        },
        flexItem:{
            width: "150px",
            padding: "5px",
            textAlign: "center",
            wordBreak: "break-word",
            backgroundColor: backgroundColor,
        },
        text:{

        }
    }
    return(
    <Paper elevation={0} sx={style.paper} className="participantParent">
        <Grid container spacing={2}>
        {participant.map((member,index)=>{
            return <Grid key={index + member.name} item xs={6} md={3}><Paper elevation={1} sx={style.flexItem} className="participantChild">
                <Typography sx={style.text}>{member.employee ? <PersonIcon/>:<FamilyRestroomIcon/>} {member.name} </Typography>
            </Paper>
            </Grid>
            
        })}
        </Grid>
    </Paper>)
}

const DiscType = ({type,setColor,color}) => {
    if (type == 0) {
        return <DiscBox color={color} discstring={Languages.discTypeVASU}/>
    }
    if (type == 1) {
        return <DiscBox color={color} discstring={Languages.discTypeLapu}/>
    }
    if (type == 2) {
        return <DiscBox color={color} discstring={Languages.discTypeMuu}/>
    }
    if (type == 3) {
        return <DiscBox color={color} discstring={Languages.discTypeLEOPS}/>
    }
    return ""
}

const DiscBox = ({color, discstring}) => {
    const style = {
        dot:{
            height: "15px",
            width: "15px",
            backgroundColor:color,
            borderRadius: "50%",
            display: "inline-block",
        },
        text:{
        }
    }
    return (<Typography variant="h6" sx={{}}>
                {" "}<b>{Languages.discSubject}: </b> {discstring}
            </Typography>)
}


const InformationBox = ({employee = false, color, infoText}) =>{

    const infoBoxStyle = {
        parentPaper:{
            width:"50%",
            margin: "2px"
        },
        topPaper:{
            borderColor:color,
            backgroundColor:color,
            borderRadius:"5px 5px 0px 0px",
        },
        topText:{
            color: "white",
            padding: "5px", 
            display: "flex",
        },
        bottomPaper:{
            backgroundColor:"#D7E3ED",
            color:"black", 
            padding: "3px",
            borderRadius:"0px 0px 5px 5px",
            wordBreak: "break-word",
            flex: '1'
        },
        bottomText:{
            
        },
        svg:{
        }
    }
    return(<>
    <Paper className="infoPaper">
        <Paper sx={infoBoxStyle.topPaper}>
            <Typography sx={infoBoxStyle.topText}>
                {employee ? <><PersonIcon sx={infoBoxStyle.svg}/>&nbsp;&nbsp;{Languages.discInformationGivenByPersonel} </> : <><FamilyRestroomIcon sx={infoBoxStyle.svg}/>&nbsp;&nbsp;{Languages.discInformationGivenByGuardians} </>}
            </Typography>
        </Paper>
        <Paper sx={infoBoxStyle.bottomPaper}>
            <Typography variant="p">{infoText && infoText !== "" ? infoText : <>{Languages.discNoInformation}</>}</Typography>
        </Paper>
    </Paper>
    </>)
}

export default DiscussionCard
import React, {useState, useEffect}   from 'react';
import Grid                from "@mui/material/Grid";
import Paper               from "@mui/material/Paper";
import Typography          from "@mui/material/Typography";
import ChildProfilePicture from "../ChildProfilePicture"; 
import { useStateValue }   from "../../State";


const ChildNamePicture = ({childId, childName, BgColor}) => {

  const { state } = useStateValue();

  const { children } = state;

  const [childColor, setChildColor] = useState();


  useEffect(() => {

    if (typeof BgColor === "undefined") {
      
      setChildColor(
            children.ChildList.reduce((accumulator, currentValue) => {
              if (childId === currentValue.Id) {
                accumulator = currentValue.BgColor;
              }
              return accumulator;
            })
      )
    }
    else {
      setChildColor(
        BgColor
      )
    }
  }, [BgColor, childId, children.ChildList]);
  

  return(

                <Grid container spacing={2} alignItems="center" id="test_childNamePicture">
                  <Grid item xs={3}>
                    <ChildProfilePicture childId={childId} />
                  </Grid>

                  <Grid item xs={8}>
                    <Paper style={{ backgroundColor: childColor /*props.BgColor*/}}>
                      <Typography align="center" style={{ paddingTop: "8px", paddingBottom: "8px"}}>&nbsp;{childName}</Typography>
                    </Paper>
                  </Grid>
                </Grid>
  )
}

export default ChildNamePicture;
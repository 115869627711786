import React, { useState, useEffect } from "react";
//import Languages from "../../../translations"
//import makeStyles from '@mui/styles/makeStyles';
import { useStateValue } from "../../../../State";
import Avatar from "@mui/material/Avatar";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FaceIcon from "@mui/icons-material/Face";
import DoneIcon from "@mui/icons-material/Done";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import EventNoteIcon from "@mui/icons-material/EventNote";
import DateRangeIcon from "@mui/icons-material/DateRange";

import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import moment from "moment";
import { types } from "../../../../Reducers/actionTypes";

import Checkbox from "@mui/material/Checkbox";

import AppBar from "@mui/material/AppBar";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";

import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";

import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";

import Slide from "@mui/material/Slide";
import PropTypes from "prop-types";

const HideOnScroll = (props) => {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

import AdbIcon from "@mui/icons-material/Adb";

const CTBHeaderNav2 = (
  {
    classes,
    nursingtimes,
    names,
    views,
    personName,
    handleNames,
    handleView,
    view,
    monthChange,
    scrollWeek,
    scrollDay,
  },
  props
) => {
  const [popperAnchorEl, setPopperAnchorEl] = React.useState(null);
  const [popperOpen, setPopperOpen] = React.useState(false);
  const [popperPlacement, setPopperPlacement] = React.useState();
  const { actions, state } = useStateValue();
  const pages = ["Products", "Pricing", "Blog"];
  const settings = ["Profile", "Account", "Dashboard", "Logout"];
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  var test = "x";

  var test2 = "xxx";

  const handleDelete = () => {
    console.log("vaihda lapsien näkymää");
  };

  const handleClick = (newPopperPlacement) => (event) => {
    setPopperAnchorEl(event.currentTarget);
    setPopperOpen((prev) => popperPlacement !== newPopperPlacement || !prev);
    setPopperPlacement(newPopperPlacement);
  };

  const [age, setAge] = React.useState("");

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleClickScroll = (date) => {
    const element = document.getElementById(date.format("MM.DD.YYYY"));
    if (element) {
      const y = element.getBoundingClientRect().top + window.pageYOffset - 120;
      window.scrollTo({ top: y });
    }
  };

  return (
    <>
      <AppBar
        style={{ background: "transparent", boxShadow: "none" }}
        className={classes.TopAppBar}
      >
        <Toolbar
          sx={{
            margin: "auto",
            maxWidth: 800,
            width: "100%",
            backgroundColor: "white",
            boxShadow: 5,
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
          }}
        >
          <Stack alignItems="center" sx={{ mt: 1, ml: 2, mb: 0.5 }}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              role="presentation"
            >
              <Stack alignItems="center" spacing={0}>
                <Typography fontSize="small">{"vko"}</Typography>
                <Avatar sx={{ color: "black", backgroundColor: "white" }}>
                  {moment(scrollDay).isoWeek()}
                </Avatar>
              </Stack>
              <Stack alignItems="center" spacing={0}>
                <Typography color="textSecondary" fontSize="small">
                  {moment(scrollDay).startOf("week").format("ddd")}
                </Typography>
                <Avatar
                  sx={{
                    color:
                      moment(scrollDay).startOf("week").format("DD.MM.YYYY") ===
                      moment(scrollDay).format("DD.MM.YYYY")
                        ? "white"
                        : moment(scrollDay)
                            .startOf("week")
                            .isSame(new Date(), "day") === true
                        ? "success.main"
                        : "black",

                    backgroundColor:
                      moment(scrollDay).startOf("week").format("DD.MM.YYYY") ===
                      moment(scrollDay).format("DD.MM.YYYY")
                        ? "success.light"
                        : "white",
                  }}
                  onClick={() =>
                    handleClickScroll(moment(scrollDay).startOf("week"))
                  }
                >
                  {moment(scrollDay).startOf("week").format("DD")}
                </Avatar>
              </Stack>
              <Stack alignItems="center">
                <Typography color="textSecondary" fontSize="small">
                  {moment(scrollDay)
                    .startOf("week")
                    .add(1, "days")
                    .format("ddd")}
                </Typography>
                <Avatar
                  sx={{
                    color:
                      moment(scrollDay)
                        .startOf("week")
                        .add(1, "days")
                        .format("DD.MM.YYYY") ===
                      moment(scrollDay).format("DD.MM.YYYY")
                        ? "white"
                        : moment(scrollDay)
                            .startOf("week")
                            .add(1, "days")
                            .isSame(new Date(), "day") === true
                        ? "success.main"
                        : "black",

                    backgroundColor:
                      moment(scrollDay)
                        .startOf("week")
                        .add(1, "days")
                        .format("DD.MM.YYYY") ===
                      moment(scrollDay).format("DD.MM.YYYY")
                        ? "success.light"
                        : "white",
                  }}
                  onClick={() =>
                    handleClickScroll(
                      moment(scrollDay).startOf("week").add(1, "days")
                    )
                  }
                >
                  {moment(scrollDay)
                    .startOf("week")
                    .add(1, "days")
                    .format("DD")}
                </Avatar>
              </Stack>

              <Stack alignItems="center">
                <Typography color="textSecondary" fontSize="small">
                  {moment(scrollDay)
                    .startOf("week")
                    .add(2, "days")
                    .format("ddd")}
                </Typography>
                <Avatar
                  sx={{
                    color:
                      moment(scrollDay)
                        .startOf("week")
                        .add(2, "days")
                        .format("DD.MM.YYYY") ===
                      moment(scrollDay).format("DD.MM.YYYY")
                        ? "white"
                        : moment(scrollDay)
                            .startOf("week")
                            .add(2, "days")
                            .isSame(new Date(), "day") === true
                        ? "success.main"
                        : "black",

                    backgroundColor:
                      moment(scrollDay)
                        .startOf("week")
                        .add(2, "days")
                        .format("DD.MM.YYYY") ===
                      moment(scrollDay).format("DD.MM.YYYY")
                        ? "success.light"
                        : "white",
                  }}
                  onClick={() =>
                    handleClickScroll(
                      moment(scrollDay).startOf("week").add(2, "days")
                    )
                  }
                >
                  {moment(scrollDay)
                    .startOf("week")
                    .add(2, "days")
                    .format("DD")}
                </Avatar>
              </Stack>
              <Stack alignItems="center">
                <Typography color="textSecondary" fontSize="small">
                  {moment(scrollDay)
                    .startOf("week")
                    .add(3, "days")
                    .format("ddd")}
                </Typography>
                <Avatar
                  sx={{
                    color:
                      moment(scrollDay)
                        .startOf("week")
                        .add(3, "days")
                        .format("DD.MM.YYYY") ===
                      moment(scrollDay).format("DD.MM.YYYY")
                        ? "white"
                        : moment(scrollDay)
                            .startOf("week")
                            .add(3, "days")
                            .isSame(new Date(), "day") === true
                        ? "success.main"
                        : "black",
                    backgroundColor:
                      moment(scrollDay)
                        .startOf("week")
                        .add(3, "days")
                        .format("DD.MM.YYYY") ===
                      moment(scrollDay).format("DD.MM.YYYY")
                        ? "success.light"
                        : "white",
                  }}
                  onClick={() =>
                    handleClickScroll(
                      moment(scrollDay).startOf("week").add(3, "days")
                    )
                  }
                >
                  {moment(scrollDay)
                    .startOf("week")
                    .add(3, "days")
                    .format("DD")}
                </Avatar>
              </Stack>
              <Stack alignItems="center">
                <Typography color="textSecondary" fontSize="small">
                  {moment(scrollDay)
                    .startOf("week")
                    .add(4, "days")
                    .format("ddd")}
                </Typography>
                <Avatar
                  sx={{
                    color:
                      moment(scrollDay)
                        .startOf("week")
                        .add(4, "days")
                        .format("DD.MM.YYYY") ===
                      moment(scrollDay).format("DD.MM.YYYY")
                        ? "white"
                        : moment(scrollDay)
                            .startOf("week")
                            .add(4, "days")
                            .isSame(new Date(), "day") === true
                        ? "success.main"
                        : "black",
                    backgroundColor:
                      moment(scrollDay)
                        .startOf("week")
                        .add(4, "days")
                        .format("DD.MM.YYYY") ===
                      moment(scrollDay).format("DD.MM.YYYY")
                        ? "success.light"
                        : "white",
                  }}
                  onClick={() =>
                    handleClickScroll(
                      moment(scrollDay).startOf("week").add(4, "days")
                    )
                  }
                >
                  {moment(scrollDay)
                    .startOf("week")
                    .add(4, "days")
                    .format("DD")}
                </Avatar>
              </Stack>
              <Stack alignItems="center">
                <Typography color="textSecondary" fontSize="small">
                  {moment(scrollDay)
                    .startOf("week")
                    .add(5, "days")
                    .format("ddd")}
                </Typography>
                <Avatar
                  sx={{
                    color:
                      moment(scrollDay)
                        .startOf("week")
                        .add(5, "days")
                        .format("DD.MM.YYYY") ===
                      moment(scrollDay).format("DD.MM.YYYY")
                        ? "white"
                        : moment(scrollDay)
                            .startOf("week")
                            .add(5, "days")
                            .isSame(new Date(), "day") === true
                        ? "success.main"
                        : "black",
                    backgroundColor:
                      moment(scrollDay)
                        .startOf("week")
                        .add(5, "days")
                        .format("DD.MM.YYYY") ===
                      moment(scrollDay).format("DD.MM.YYYY")
                        ? "success.light"
                        : "white",
                  }}
                  onClick={() =>
                    handleClickScroll(
                      moment(scrollDay).startOf("week").add(5, "days")
                    )
                  }
                >
                  {moment(scrollDay)
                    .startOf("week")
                    .add(5, "days")
                    .format("DD")}
                </Avatar>
              </Stack>
              <Stack alignItems="center">
                <Typography color="textSecondary" fontSize="small">
                  {moment(scrollDay)
                    .startOf("week")
                    .add(6, "days")
                    .format("ddd")}
                </Typography>
                <Avatar
                  sx={{
                    color:
                      moment(scrollDay)
                        .startOf("week")
                        .add(6, "days")
                        .format("DD.MM.YYYY") ===
                      moment(scrollDay).format("DD.MM.YYYY")
                        ? "white"
                        : moment(scrollDay)
                            .startOf("week")
                            .add(6, "days")
                            .isSame(new Date(), "day") === true
                        ? "success.main"
                        : "black",
                    backgroundColor:
                      moment(scrollDay)
                        .startOf("week")
                        .add(6, "days")
                        .format("DD.MM.YYYY") ===
                      moment(scrollDay).format("DD.MM.YYYY")
                        ? "success.light"
                        : "white",
                  }}
                  onClick={() =>
                    handleClickScroll(
                      moment(scrollDay).startOf("week").add(6, "days")
                    )
                  }
                >
                  {moment(scrollDay)
                    .startOf("week")
                    .add(6, "days")
                    .format("DD")}
                </Avatar>
              </Stack>
            </Box>
            {/*<Stack
              sx={{ left: 0, right: 0, margin: "0 auto" }}
              direction="row"
              spacing={2}
            >
              <IconButton onClick={() => monthChange("prev")}>
                <ArrowBackIosIcon />
              </IconButton>
              <Stack alignItems="center">
                <Typography color="textSecondary">{test}</Typography>
                <Typography color="textSecondary">{test2}</Typography>
              </Stack>
              <IconButton onClick={() => monthChange("next")}>
                <ArrowForwardIosIcon />
              </IconButton>
                  </Stack>*/}
          </Stack>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default CTBHeaderNav2;

import React from "react";
import BackToUpButton from "../GuideTools/BackToUpButton";
import Languages from "../../../translations";
import makeStyles from '@mui/styles/makeStyles';
import FeedIcon from '@mui/icons-material/Feed';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import Badge from '@mui/material/Badge';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';


const useStyles = makeStyles({
  success: {
    color: 'rgb(129, 199, 132)',
    fontSize: 'xx-large',
  }
});



const ParentalConsentsAccordionText = () => {
  const classes = useStyles();
    return(
      <>
      <div>
      <h4>{Languages.parentalConsentsAccordionTitle}</h4>
          <br />
          <p className="guide-accordion-details-texts">{Languages.parentalConsentsList1} </p>
          <br />
          <br />
          <p className="guide-accordion-details-texts">{Languages.parentalConsentsList2} <Badge badgeContent={3} color="error"><LiveHelpIcon color="action" /></Badge> {Languages.permissionRequestList2} <FeedIcon sx={{color: "#FF6D29"}}/> {Languages.permissionRequestList3} <ExpandMoreIcon sx={{color: "#FF6D29"}}/> {Languages.parentalConsentsList3}</p>
          <br />
          <br />
          <p className="guide-accordion-details-texts">{Languages.parentalConsentsList4}</p>
          <br />
          <br />
        <BackToUpButton />
        </div>
      </>
    )
}

export default ParentalConsentsAccordionText
import React, { useEffect, useReducer, useState } from "react";
import Languages from "../../../../translations";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from "@mui/material/Typography";
import TextField from '@mui/material/TextField';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import Divider from '@mui/material/Divider';
import AccordionActions from '@mui/material/AccordionActions';
import IconButton from '@mui/material/IconButton';
import Edit from '@mui/icons-material/EditRounded';
import Check from '@mui/icons-material/Check';
import Undo from '@mui/icons-material/Undo';
import withStyles from '@mui/styles/withStyles';

import { useStateValue } from "../../../../State";
import { SessionManager } from "../../../../Auth";
import { validatePhoneNumber } from "./";

const styles = {
  root: { background: "white" },
  input: {
    color: "black"
  },
  accordionHeading: {
    color: "#4377BA",
    fontSize: "13pt",
    fontWeight: "bold",
    display: "flex"
  }
}

const EditField = ({ phoneNumberData, inError, index, onChange }) => {
  return (
    <div className="row">
      <div className="col-4"><p>{Languages.ciShortPhoneNumber}</p></div>
      <div className="col-8">
        <TextField error={inError}
          variant="standard"
          name="ChildPrimaryNumber"
          id={"test_ChildPrimaryNo" + index}
          inputProps={{ attrid: phoneNumberData.ID }}
          type="text"
          placeholder={Languages.ciShortPhoneNumber}
          value={phoneNumberData.Number === null ? "" : phoneNumberData.Number}
          helperText={!inError ? "" : Languages.error}
          onChange={onChange}
          autoFocus={index === 0 ? true : false} />
      </div>
    </div>
  );
}

const ReadOnlyField = ({ number }) => {
  return (
    <div className="row">
      <div className="col-4"><p>{Languages.ciShortPhoneNumber}</p></div>
      <div className="col-8"><p>{!number ? Languages.ciNoNumber : number}</p></div>
    </div>
  )
}

const phoneNumberReducer = (state, action) => {
  /*
  Phonenumbers:[
    ID:
    Number:
    Type:
  ]
  */
  if (action.type === "update_all") {
    return [
      ...action.data,
    ]
  }
  if (action.type === "update") {
    const newState = [
      ...state,
    ]
    newState.forEach(data => {
      if (data.ID === action.data.ID) {
        data.Number = action.data.Number
      }
    })
    return newState;
  }
  return state;
}

const PriorityPhoneNumbers = ({ childId, childColor = "#4377BA", classes }) => {
  const { state: { children }, actions } = useStateValue();
  const { ChildList } = children;

  const [expanded, setExpanded] = useState(false);
  const [editing, setEditing] = useState(false);
  const [phoneNumbers, dispatchPhoneNumbers] = useReducer(phoneNumberReducer, [{}]);
  const [phoneNumberErrors, setPhoneNumberErrors] = useState([]);


  useEffect(() => {
    if (!editing) {
      const currentChild = ChildList.find(c => c.Id == childId)
      if (currentChild.Info) {
        dispatchPhoneNumbers({
          type: "update_all",
          data: currentChild.Info.Phonenumbers
        });
      }
    }
  }, [ChildList])

  useEffect(() => {
    if (editing) {
      const validationResult = validator(phoneNumbers);
      setPhoneNumberErrors(validationResult);
    }
  }, [phoneNumbers])

  const validator = (numbers) => {
    const validationResult = [];
    numbers.forEach(ph => {
      const result = validatePhoneNumber(ph.Number);
      if (!result.valid) {
        validationResult.push(result);
      }
    })
    return validationResult;
  }

  const toggleExpanded = () => {
    setExpanded(!expanded);
  }

  const onUndo = () => {
    //this.EditInfo("ChildPrimaryNumber")
  }

  const onSave = () => {
    const vStatus = validator(phoneNumbers);

    if (vStatus.length === 0) {
      // Save
      const childInfoData = {
        PersonID: childId,
        Admin: SessionManager.getUser(),
        SavedInfoType: "ChildPrimaryNumber",
        Phonenumbers: phoneNumbers,
      };

      actions.triggerSaveChildinfo(childInfoData);
      setEditing(false);
    } else {
      setPhoneNumberErrors(vStatus);
    }
  }

  return <>
    <Accordion /*defaultExpanded*/
      className="painikkeet"
      //style={styles.accordion}
      //style={styles.accordion}}
      style={{ border: "5px", borderLeft: "12px", borderStyle: "solid", /*borderTopLeftRadius: "15px",*/ borderTopRightRadius: "15px", borderColor: childColor, backgroundColor: "#ffffff", color: "#181818" }}
      expanded={expanded}
      onChange={toggleExpanded}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="test_EditableAcc_Btn" /*id="panel1a-header" */>
        <Typography className="heading" style={styles.accordionHeading}>
          <ContactPhoneIcon />
          <b style={{ marginLeft: "12px", display: "inline" }}>{Languages.ciShortPrimaryNumber}</b>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography component={'span'}>
          <TextField error={phoneNumberErrors.length > 0 ? true : false}
            variant="standard"
            name={"ChildInfoPhoneNumberErrorInfoText"}
            key={"ChildInfoPhoneNumberErrorInfoText"}
            id="outlined-read-only-input"
            InputProps={{ readOnly: true, disableUnderline: true /*, attrid: pn.ID*/ }}
            type="text"
            helperText={phoneNumberErrors.length > 0 ? Languages.ciInfo1 : ""}
            className={classes.input}
            inputProps={{ style: { color: 'black' } }}
          >
            <Typography style={{ color: "#000000" }} />
          </TextField>
          {phoneNumberErrors.length > 0 ? <div><br></br><br></br></div> : null}

          {phoneNumbers.map((pn, index) => {
            const validationErrors = phoneNumberErrors.length > 0;

            return (
              <div key={"pr_phone_" + index}>
                {editing &&
                  <EditField
                    inError={validationErrors}
                    phoneNumberData={pn}
                    index={index}
                    onChange={(event) =>
                      dispatchPhoneNumbers({
                        type: "update",
                        data: { ID: pn.ID, Number: event.target.value }
                      })
                    }
                  />}
                {!editing && <ReadOnlyField number={pn.Number} />}
              </div>
            );
          })}
        </Typography>
      </AccordionDetails>
      <Divider />

      {!editing &&
        <AccordionActions className="painikkeet">
          <IconButton id="test_ChildInfo_EditBtn" onClick={() => setEditing(true)} size="large"> <Edit /> </IconButton>
        </AccordionActions>
      }
      {editing &&
        <AccordionActions className="painikkeet">
          <IconButton id="test_ChildInfo_UndoBtn" onClick={onUndo} size="large"> <Undo /> </IconButton>
          {phoneNumberErrors.length === 0 ?
            <IconButton id="test_ChildInfo_SaveBtn" onClick={onSave} size="large"> <Check /> </IconButton>
            : null}
        </AccordionActions>
      }
    </Accordion>
  </>;
}

export default withStyles(styles)(PriorityPhoneNumbers);
import React, {useState} from 'react';
import './cookienote.css';
import Card                   from '@mui/material/Card';
import CardContent            from '@mui/material/CardContent';
import Typography             from "@mui/material/Typography";
import Button                 from '@mui/material/Button';
import Languages              from "../../translations";
import CookieInfoDetailedContent from "./CookieInfoDetailedContent";
import Dialog                 from "@mui/material/Dialog";
import DialogContent          from "@mui/material/DialogContent";

// "#d500f9"

const CookieNote = ({onCookieAccept, cookiesAccepted}) => {

  const onOk = () => {
    onCookieAccept();
  }

  const [moreInfoShown, setMoreInfoShown] = useState(false);

  const changeMoreCookieInfo = () => {
    setMoreInfoShown(!moreInfoShown);
  }

  const CookieMoreInfoDialog = () => {
    
    if (moreInfoShown === false) {
      return(null);
    }

    return(
      <Dialog aria-label={"modal-cookies"} fullScreen open={true} onClose={changeMoreCookieInfo} scroll={"paper"}>
        <DialogContent style={{backgroundColor: "#FFFFFF"}}>
          <Button aria-label={Languages.generalClose} color="secondary" style={{color: "#4377BA"}} onClick={changeMoreCookieInfo}>{Languages.generalClose}</Button>
          <CookieInfoDetailedContent/>
          <Button aria-label={Languages.generalClose} color="secondary" style={{color: "#4377BA"}} onClick={changeMoreCookieInfo}>{Languages.generalClose}</Button>
        </DialogContent>
      </Dialog>
    )
  }



return (
  !cookiesAccepted &&
  <div id={"test_CookieNote"} className="CookieNote" style={{margin:"auto"}}>
  
    <CookieMoreInfoDialog/>
    
    <br/>
    <Card raised style={{backgroundColor:"#FFFFFF", textAlign:"center",  margin:"auto", maxWidth:"340px"}}>
        <CardContent style={{textAlign:"center"}}>
          <Typography color="textSecondary" paragraph={true}> {Languages.cookieNote} </Typography>
            {/*<Box m={1}>
              <a style={{ align:"center", color:"#750189",  }} 
                  id="test_More_Cookie_Info_Lnk" 
                  href={"https://ohjeet.daisymanager.fi/Cookies/Daisy_Family_Cookies_FI.pdf"} 
                  target="_blank" 
                  rel="noopener noreferrer">
                    {Languages.cookieMoreInfoHereLnk}
              </a>
            </Box>*/}
          <Button
            style={{backgroundColor:"#0365CE"}}
            id="test_CookieNote_OkBtn"
            onClick={onOk}
            color="inherit"
            variant="contained"
            margin="auto">OK
          </Button>&nbsp;&nbsp;
          <Button
            style={{backgroundColor:"#0365CE"}}
            id="test_CookieNote_MoreInfoBtn"
            onClick={changeMoreCookieInfo}
            color="primary"
            variant="contained"
            margin="auto">{Languages.cookieMoreInfo}
          </Button>
        </CardContent>
    </Card>
  </div>
);

}

export default CookieNote;
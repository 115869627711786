import React from "react";
import "../newmessage.css";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import InfoTip from "../../../../Components/InfoTip";

const RecipientSelection = ({title, infotext, potentialRecipients, handleCheckboxes, currentlyCheckedList, automationTestId}) => {

  if ( potentialRecipients === null || typeof potentialRecipients === "undefined") { return(<div></div>)}

  return(

    <React.Fragment>
      <br />
      <div className="NMtitle">
        <span>{title} &nbsp;
          <InfoTip
            infotext={infotext}
            color="primary"
          />
        </span>
      </div>
      <div className="straightline"></div>
      <svg height="1" width="100%">
        <line className="line1" x1="0" y1="0" x2="100%" y2="0" />
      </svg>
      <p className="smallertext1"></p>
          

      <FormGroup row>
      {
        potentialRecipients.map((groupCenterOrPerson, index) => {
          return (
            <FormControlLabel
              key={automationTestId + groupCenterOrPerson.Id}
              control={
                <Checkbox
                  color="secondary"
                  id={automationTestId + index}
                  value={groupCenterOrPerson.Id}
                  onChange={handleCheckboxes}
                  checked={currentlyCheckedList.includes(groupCenterOrPerson.Id) ? true : false}
                />}
              label={groupCenterOrPerson.Name}
            />
          );
        })
      }
      <br />
      </FormGroup>
    </React.Fragment>
  )
}

export default RecipientSelection;
import React, { useState, useEffect } from "react";
import InfoModal from "../Modals/InfoModal"
import { useStateValue, AuthStatus } from "../../State";
import Languages from "../../translations";

export const ERROR_LEVEL = {
  FATAL: "fatal",
  WARNING: "warning"
}

export const mapStatusToText = (status) => {
  const texts = {
    [AuthStatus.LOGIN_OPTIONS_LOAD_FAILED]: () => Languages.errorBackendContactSupport,
    [AuthStatus.ESUOMI_SETTINGS_LOAD_FAILED]: () => Languages.errorBackend,
    "default": () => Languages.errorBackend,
  }

  return (texts[status] || texts['default'])();
};

export const travelAppState = (state) => {
  const errors = [];

  for (const stateName of Object.keys(state)) {
    const currentState = state[stateName];
    if (currentState) {
      if (currentState.hasOwnProperty('status')) {
        if (currentState.status.indexOf("FAILED") > -1) {
          errors.push({
            state: stateName,
            errorLevel: ERROR_LEVEL.FATAL,
            errorText: mapStatusToText(currentState.status),
            errorLogText: "Generic error"
          });
        }
      }
    }
  }

  return errors;
};

export const ErrorHandling = ({ children, fnStateTraveler }) => {
  const { state } = useStateValue();

  const [showError, setShowError] = useState(false);
  const [errors, setErrors] = useState([]);
  const [allowClose, setAllowClose] = useState(true);

  useEffect(() => {
    const errorsFound = fnStateTraveler(state);
    if (errorsFound.length > 0) {
      for (const errorDef of errorsFound) {
        if (errorDef.errorLevel === ERROR_LEVEL.FATAL) {
          setAllowClose(false);
          console.error(`${errorDef.state} error detected. ${errorDef.errorLogText}`);
        }
        if (errorDef.errorLevel === ERROR_LEVEL.WARNING) {
          console.warn(`${errorDef.state} error detected. ${errorDef.errorLogText}`);
        }
      }
      setErrors(errorsFound);
      setShowError(true);
    } else {
      setShowError(false);
    }
  }, [state])

  return (
    <React.Fragment>
      {showError &&
        <InfoModal allowClose={allowClose} headerText={Languages.error} modalOpen={true} onClose={()=> setShowError(false)}>
          <div key={"errorList-key-1"}>{errors[0].errorText}</div>
        </InfoModal>}
      {children}
    </React.Fragment>
  )
};
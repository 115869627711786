import { types } from './actionTypes';

const DiscussionReducer = (state, action) => {
  const payload = action.data;
  
  //console.log("DiscussionReducer, payload", payload);
  switch (action.type) {
    case types.LOADING_DISCUSSION_TIMES:
      return {
        ...state,
          discussionTimes: {
            status: types.LOADING_DISCUSSION_TIMES
          }
      }

    case types.LOAD_DISCUSSION_TIMES_SUCCESS:
      console.log("Success", payload);
      return {
        ...state,
          discussionTimes: {
            code: payload.status,
            status: types.LOAD_DISCUSSION_TIMES_SUCCESS,
            data: payload
          }  
      }

    case types.LOAD_DISCUSSION_TIMES_FAILED:
      return {
        ...state,
          discussionTimes: {
            code: payload.Code,
            status: types.LOAD_DISCUSSION_TIMES_FAILED
          }
      }

    case types.LOADING_AVAILABLE_DISC_SLOTS: 
      return {
        ...state,
          discussionSlots: {
            status: types.LOADING_AVAILABLE_DISC_SLOTS
          }
      }

    case types.LOAD_AVAILABLE_DISC_SLOTS_SUCCESS:
      console.log("Success", payload);
      return {
        ...state,
          discussionSlots: {
            code: payload.status,
            status: types.LOAD_AVAILABLE_DISC_SLOTS_SUCCESS,
            data: payload
          }  
      }

    case types.LOAD_AVAILABLE_DISC_SLOTS_FAILED:
      return {
        ...state,
          discussionSlots: {
            code: payload.Code,
            status: types.LOAD_AVAILABLE_DISC_SLOTS_FAILED
          }
      }

    case types.SAVING_DISCUSSION_BOOKING: 
      return {
        ...state,
          saveDiscussionBooking: {
            status: types.SAVING_DISCUSSION_BOOKING
          }
      }

    case types.SAVE_DISCUSSION_BOOKING_SUCCESS: 
      return {
        ...state,
          saveDiscussionBooking: {
            status: types.SAVE_DISCUSSION_BOOKING_SUCCESS
          }
      }

    case types.SAVE_DISCUSSION_BOOKING_FAILED:  
      return {
          ...state,
            saveDiscussionBooking: {
              status: types.SAVE_DISCUSSION_BOOKING_FAILED,
              code: action.data.Code,
              errorText: action.data.responsedata
            }
      }
      
    case types.DELETING_DISCUSSION_BOOKING:
      return {
        ...state,
          deleteDiscussionBooking: {
            status: types.DELETING_DISCUSSION_BOOKING
          }
      }

    case types.DELETE_DISCUSSION_BOOKING_SUCCESS:
      return {
        ...state,
          deleteDiscussionBooking: {
            status: types.DELETE_DISCUSSION_BOOKING_SUCCESS
          }
      }

    case types.DELETE_DISCUSSION_BOOKING_FAILED:
      return {
        ...state,
          deleteDiscussionBooking: {
            status: types.DELETE_DISCUSSION_BOOKING_FAILED,
            code: action.data.Code,
            errorText: action.data.responsedata
          }
      }

    case types.EDITING_DISCUSSION_BOOKING:
      return {
        ...state,
          editDiscussionBooking: {
            status: types.EDITING_DISCUSSION_BOOKING
          }
      }

    case types.EDIT_DISCUSSION_BOOKING_SUCCESS:
      return {
        ...state,
          editDiscussionBooking: {
            status: types.EDIT_DISCUSSION_BOOKING_SUCCESS
          }
      }

    case types.EDIT_DISCUSSION_BOOKING_FAILED:
      return {
        ...state,
          editDiscussionBooking: {
            status: types.EDIT_DISCUSSION_BOOKING_FAILED,
            code: action.data.Code,
            errorText: action.data.responsedata
          }
      }

    case types.LOADING_ONE_CHILD_DISCUSSIONS:
      return {
        ...state,
          oneChildDiscussions: {
            status: types.LOADING_ONE_CHILD_DISCUSSIONS
          }
      }

    case types.LOAD_ONE_CHILD_DISCUSSIONS_SUCCESS:
      console.log("Success", payload);
      return {
        ...state,
          oneChildDiscussions: {
            code: payload.status,
            status: types.LOAD_ONE_CHILD_DISCUSSIONS_SUCCESS,
            data: payload
          }  
      }

    case types.LOAD_ONE_CHILD_DISCUSSIONS_FAILED:
      return {
        ...state,
          oneChildDiscussions: {
            code: payload.Code,
            status: types.LOAD_ONE_CHILD_DISCUSSIONS_FAILED
          }
      }
    
    case types.LOADING_PARENTS_FOR_DISCUSSION_EDITING:
      return {
          ...state,
          parentsForEditing: {
            status: types.LOADING_PARENTS_FOR_DISCUSSION_EDITING
          }
      }
    case types.GET_PARENTS_FOR_DISCUSSION_EDITING_SUCCESS:
      return {
        ...state,
        parentsForEditing: {
          status: types.GET_PARENTS_FOR_DISCUSSION_EDITING_SUCCESS,
          data: payload
        }
      }
    case types.GET_PARENTS_FOR_DISCUSSION_EDITING_FAILED:
      return {
        ...state,
        parentsForEditing: {
          Code: payload.Code,
          status: types.GET_PARENTS_FOR_DISCUSSION_EDITING_FAILED,
        }
      }
    
    case types.LOADING_DISCUSSIONNOTIFICATION_STATS:
      return {
        ...state,
        discNotiStats:{
          status: types.LOADING_DISCUSSIONNOTIFICATION_STATS,
        }
      }
    
    case types.GET_DISCUSSIONNOTIFICATION_STATS_SUCCESS:
      return {
        ...state,
        discNotiStats:{
          status: types.GET_DISCUSSIONNOTIFICATION_STATS_SUCCESS,
          data: payload
        }
      }
    
    case types.GET_PARENTS_FOR_DISCUSSION_EDITING_FAILED:
      return {
        ...state,
        discNotiStats:{
          Code: payload.Code,
          status: types.GET_PARENTS_FOR_DISCUSSION_EDITING_FAILED,
        }
      }
    
    case types.LOADING_DISCUSSION_TIMESELECTIONSTATUS:
      return {
        ...state,
        discTimeSelectionStats: {
          status: types.LOADING_DISCUSSION_TIMESELECTIONSTATUS,
          data: []
        }
      }
    
    case types.GET_DISCUSSION_TIMESELECTIONSTATUS_FAILED:
      return {
        ...state,
        discTimeSelectionStats: {
          status: types.GET_DISCUSSION_TIMESELECTIONSTATUS_FAILED,
          Code: payload.Code,
          data: []
        }
      }
    
    case types.GET_DISCUSSION_TIMESELECTIONSTATUS_SUCCESS:
      return {
        ...state,
        discTimeSelectionStats: {
          status: types.GET_DISCUSSION_TIMESELECTIONSTATUS_SUCCESS,
          data: payload
        }
      }
    
    case types.SAVING_DISCUSSION_TIMESELECTIONSTATUS: 
      return {
        ...state,
        discSaveTimeSelection: {
          status: types.SAVING_DISCUSSION_TIMESELECTIONSTATUS,
        }
      }
    
    case types.SAVE_DISCUSSION_TIMESELECTIONSTATUS_SUCCESS:
      return {
        ...state,
        discSaveTimeSelection: {
          status: types.SAVE_DISCUSSION_TIMESELECTIONSTATUS_SUCCESS,
          data: payload
        }
      }
    case  types.SAVE_DISCUSSION_TIMESELECTIONSTATUS_CONFLICT:
      return {
        ...state,
        discSaveTimeSelection: {
          status: types.SAVE_DISCUSSION_TIMESELECTIONSTATUS_CONFLICT,
        }
      }
    case types.SAVE_DISCUSSION_TIMESELECTIONSTATUS_FAILED:
      return {
        ...state,
        discSaveTimeSelection: {
          status: types.SAVE_DISCUSSION_TIMESELECTIONSTATUS_FAILED,
        }
      }
    case types.DELETING_DISCUSSION_TIMESELECTIONSTATUSES:
      return {
        ...state,
        discDeleteTimeSelection: {
          status: types.DELETING_DISCUSSION_TIMESELECTIONSTATUSES,
        }
      }
    
    case types.DELETE_DISCUSSION_TIMESELECTIONSTATUSES_FAILED:
      return {
        ...state,
        discDeleteTimeSelection: {
          status: types.DELETE_DISCUSSION_TIMESELECTIONSTATUSES_FAILED,
        }
      }
    
    case types.DELETE_DISCUSSION_TIMESELECTIONSTATUSES_SUCCESS:
      return {
        ...state,
        discTimeSelectionStats: {
          Code: undefined,
          status: 'NEVER_LOADED',
          data: []
        },
        discSaveTimeSelection: {
          status: 'NEVER_LOADED',
        },
        discDeleteTimeSelection: {
          status: types.DELETE_DISCUSSION_TIMESELECTIONSTATUSES_SUCCESS,
        }
      }
      
    default: return state;
  }
}

export default DiscussionReducer;
import React            from  'react';
import CircularProgress from  "@mui/material/CircularProgress";
import                        '../pedagdocimage.css';


const PedagDocImageLoading = (  ) => {
  return(
    <div title = "pedagdoc-image__loading" className = "pedagdoc-imageloading-loader-container">
      <div className = "pedagdoc-imageloading-circular-animation-position">   
        <CircularProgress />
      </div>
    </div>
  );
}

export default PedagDocImageLoading;
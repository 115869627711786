import React from 'react';
import { useAuth } from '../Auth';
import { useStateValue } from './';
/*
This HOC can be used with class compenents when refactoring to function component is not possible and access to both context needed
*/
const withAllContexts = (Component) => (props) => {
  const authContext = useAuth();
  const stateContext = useStateValue();
  return (
    <Component authContextValue={authContext} appContextValue={stateContext} {...props}/>
  )
}

export default withAllContexts;
import { authActionTypes as types } from './authActionTypes';
import { AuthStatus } from '../State';

const loginActions = {
  [types.LOAD_LOGIN_OPTIONS_SUCCESS]: (state, payload) => {
    return {
      ...state,
      status: AuthStatus.LOGIN_OPTIONS_LOADED,
      ...payload
    }
  },
  [types.LOAD_LOGIN_OPTIONS_FAILED]: (state, payload) => {
    return {
      ...state,
      status: AuthStatus.LOGIN_OPTIONS_LOAD_FAILED,
      ...payload
    }
  }
}

const authActions = {
  [types.LOGIN_USER]: (state, payload) => {
    return {
      ...state,
      isAuthenticated: true,
      user: payload.user,
      personId: payload.personId,
      lastHttpCode: payload.httpCode,
      // Translations formatString will throw if it's input is undefined. That's why "empty" value must be string
      passwordValid: payload.serverResponse?.PasswordValidTo ? payload.serverResponse.PasswordValidTo : "",
      passwordChangeRequired: payload.serverResponse?.ChangePasswordAtlogin
    }
  },
  [types.LOGIN_USER_FAILED]: (state, payload) => {
    return {
      ...state,
      isAuthenticated: false,
      user: undefined,
      lastHttpCode: payload.httpCode,
      lastErrorCode: payload.errorCode,
      lastErrorText: payload.lastErrorText,
    }
  },
  [types.LOGOUT_USER]: (state, payload) => {
    return {
      ...state,
      isAuthenticated: false,
      user: undefined,
      lastLogoutReason: payload.lastLogoutReason,
      accessToken: undefined
    }
  },
  [types.GET_ESUOMI_AUTH_REQUEST_FAILED]: (state, payload) => {
    return {
      ...state,
      isAuthenticated: false,
      user: undefined,
      lastErrorText: payload.lastErrorText
    }
  },
}

const LoginSettingsReducer = (state, action) => {
  return {
    default: state,
    ...loginActions
  }[action.type || 'default'](state, action.payload);
}

const AuthStateReducer = (state, action) => {
  console.log("AuthState reducer =>", action);
  return {
    default: state,
    ...authActions
  }[action.type || 'default'](state, action.payload);
}



export { LoginSettingsReducer, AuthStateReducer };

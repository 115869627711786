import React from "react";
import FlagFin from "../../../images/flagsFIN.png";
import FlagEng from "../../../images/flagsEN.png";
import FlagSv from "../../../images/flagsSV.png";
import makeStyles from '@mui/styles/makeStyles';
import ButtonBase from '@mui/material/ButtonBase';
//import FinnishWork from "../../../images/key-flag.png";

//"https://ohjeet.daisymanager.fi/Tietosuojaseloste/tietosuojaseloste_pohja.pdf" //tietosuojaseloste/rekisteriselostelinkki ladataan kunta-asetuksista
// käyttöohjelinkki kunta-asetuksista
// käyttöehdot-linkki on hardcodattu

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
  },
  image: {
    position: 'relative',
    display: "inline-block",
    height: 36,
    width: 36,
    margin: "5px",
  },
  focusVisible: {},
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'contain',
    backgroundPosition: 'center 40%',
    backgroundRepeat: 'no-repeat',
  },
}));


const LangButton = ({ img, onClick, idForLang }) => {
  const classes = useStyles();
  return (
    <ButtonBase
      focusRipple
      className={classes.image}
      focusVisibleClassName={classes.focusVisible}
      onClick={onClick}
      id = {idForLang}
    >
      <span
        className={classes.imageSrc}
        style={{
          backgroundImage: `url(${img})`,
        }}
      />
    </ButtonBase>
  );
}
const LanguageBar = ({ onSetLanguage }) => {
  const classes = useStyles();
  return (
      <div className={classes.root}> 
        <div>
          <LangButton idForLang="test_Login_EnglishImg" aria-label="english" onClick={() => onSetLanguage("en")} img={FlagEng}><img className="LoginFlag" src={FlagEng} alt="Eng" /></LangButton>
          <LangButton idForLang="test_Login_SwedishImg" aria-label="swedish" onClick={() => onSetLanguage("sv")} img={FlagSv}><img className="LoginFlag" src={FlagSv} alt="Sv" /></LangButton>
          <LangButton idForLang="test_Login_FinnishImg" aria-label="finnish" onClick={() => onSetLanguage("fi")} img={FlagFin}><img className="LoginFlag" src={FlagFin} alt="Fin" /></LangButton> 
        </div>
      </div>
  );
}

export default LanguageBar;
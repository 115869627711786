import { types } from './actionTypes';
import moment from 'moment';

const BulletinBoardReducer = (state, action, childrenState) => {
  const payload = action.data;

  switch(action.type) {
    case types.LOAD_BULLETINBOARDS_SUCCESS:
      return {
        ...state,
        status: "LOADED",
        notes: parseResponse(payload)
      }
    case types.LOADING_BULLETINBOARDS:
      return {
        ...state,
        status: types.LOADING_BULLETINBOARDS,
        notes: []
      }
    case types.LOAD_BULLETINBOARDS_FAILED:
      return {
        ...state,
        status: "FAILED",
        notes: []
      }
  }
  return state;
}

const parseResponse = (data) => {
  const result = [];

  if (data) {
    data.forEach(item => {
      const note = {
        RecipientName: item.Recipient.Name,
        RecipientType: item.Recipient.Type,
        RecipientId: item.Recipient.Id,
        Title: item.Title,
        Body: item.Content,
        Date: moment(item.Date),
        Available: moment(item.End),
        Attachments: item.ListAttachments
      }

      result.push(note);
      
    });
  }
  result.sort((a,b) => b.Date.valueOf() - a.Date.valueOf());

  return result;
}
export default BulletinBoardReducer;
import React, { useState } from "react";
import "../newmessage.css";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import GroupTwoTone from "@mui/icons-material/GroupTwoTone";
import FaceTwoTone from "@mui/icons-material/FaceTwoTone";
import Typography from "@mui/material/Typography";
import { ThemeProvider, StyledEngineProvider, createTheme, adaptV4Theme } from "@mui/material/styles";
import Languages from "../../../../translations";
import _cloneDeep from "lodash/cloneDeep";


const SearchSuggestions = ({allRecipients, currentlyCheckedList, updateCheckedList}) => {

  const textfieldTheme = createTheme(adaptV4Theme({
    palette: {
      text: {
      }
    }
  }));
  const [searchString, setSearchString] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestionsDiv, setShowSuggestionsDiv] = useState([false]);

  const recipientsSearch = (event) => {
    
    setSearchString(event.target.value); // show the search string value in the field

    const suggestionsList = createSuggestionsListFromUserInput(event);

    setSuggestions(suggestionsList);
    setShowSuggestionsDiv(true);
  }
  
  const createSuggestionsListFromUserInput = (userString) => {
    
    let allPotentialRecipients = _cloneDeep(allRecipients);

    // go through the list of potential recipients and 
    // add on the suggestions list any that match with the search string from the user

    let suggestionsList = [];

    for (let i = 0; i < allPotentialRecipients.length; i++) {

      const PossibleName = allPotentialRecipients[i].Name;
      const PossibleId = allPotentialRecipients[i].Id;
      const PossibleType = allPotentialRecipients[i].Type;

      // UC = Upper Case
      const UCPossibleName = PossibleName.toUpperCase();

      let UCsuggestionsList = [];

      for (let j = 0; j < suggestionsList.length; j++) {
        UCsuggestionsList.push(suggestionsList[j].Name.toUpperCase());
      }

      const notEmptyAndNotYetOnList = userString.target.value !== "" && 
        UCPossibleName.includes(userString.target.value.toUpperCase()) === true && 
        UCsuggestionsList.includes(userString.target.value.toUpperCase()) === false;

      if (notEmptyAndNotYetOnList) {
        suggestionsList.push({ Id: PossibleId, Name: PossibleName, Type: PossibleType });
      }

    }

    return(suggestionsList);

  }

  const addSelectionToCheckedList = (selectedsuggestionId) => {

    let copyOfCurrentlyCheckedList = _cloneDeep(currentlyCheckedList);
    console.log("currentlyCheckedList", currentlyCheckedList);
    
    const isOnCheckedListAlready = checkWhetherSelectionIsOnList(copyOfCurrentlyCheckedList, selectedsuggestionId);

    if (isOnCheckedListAlready === false) {
      copyOfCurrentlyCheckedList.push(selectedsuggestionId); // if the clicked Id is not on the list of selected recipients, add it there
      updateCheckedList(copyOfCurrentlyCheckedList);
    }

    clearSuggestionsAndSearchbox();
  }

  const checkWhetherSelectionIsOnList = (currentCheckedList, idToBeAdded) =>{
    let isOnCheckedList = false;

    for (let m = 0; m < currentCheckedList.length; m++) {
      if (currentCheckedList[m] === idToBeAdded) {
        isOnCheckedList = true;
      }
    }
    return(isOnCheckedList);
  }

  const clearSuggestionsAndSearchbox = () => {
    setSearchString("");
    setSuggestions([]);
  }

return (
  <FormGroup>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={textfieldTheme}>
          <TextField
            id="recipients-search-input"
            label={Languages.msgSearchForRecipient}
            type="search"
            className=""
            value={searchString}
            onChange={recipientsSearch}
            margin="normal"
            autoComplete="off"
            fullWidth
          />
          <Typography
            component={'span'} 
            variant={'body2'}
            color="textSecondary">
            {showSuggestionsDiv
              &&
              <div className={suggestions.length === 0 ? "emptyprettydiv" : "prettydiv"}>

                {suggestions.map((suggest, index) => {
                  return (
                    <div key={suggest.Id + index} onClick={() => addSelectionToCheckedList(suggest.Id)} value={suggest.Name}>
                    { suggest.Type === "group" || suggest.Type === "centre" ? <GroupTwoTone /> : <FaceTwoTone /> }
                      &nbsp; {suggest.Name} &nbsp;
                    </div>
                  )
                })}
              </div>
            }
          </Typography>
        </ThemeProvider>
      </StyledEngineProvider>
  </FormGroup>
);
}

export default SearchSuggestions;
import React from 'react';
import Fab                    from "@mui/material/Fab";
import SaveIcon               from "@mui/icons-material/Save";
import Languages              from "../../../translations";
import { green } from '@mui/material/colors';
import Zoom from '@mui/material/Zoom';


const fabGreenStyle = {
  //bottom: 16,
  //right: 16,
  //zIndex: "20000",
  //position: "fixed",
  color: 'common.white',
  bgcolor: green[500],
  '&:hover': {
    bgcolor: green[600],
  },
};

const SaveConsentsButton = ({saveChanges, showButton}) => {
    //if(showButton) {
      return(
        <>
        <Zoom
          timeout={400}
          in={true}
        >
        <Fab 
          //sx={fabGreenStyle}
          variant="extended"
          disabled={!showButton}
          //size="medium"
          color="secondary"
          onClick={saveChanges}
          aria-label="save-parent-consents"
        > <SaveIcon/> Tallenna huoltajan suostumukset</Fab>
        </Zoom>
        </>
        
      )
    //}
   // return(null)
}

export default SaveConsentsButton;

/*
div style={{zIndex: "20", position: "fixed", marginTop: "-40px", right: "0"}}>
          <Fab
            variant="extended"
            size="small"
            color="primary"
            aria-label="save-parent-consents"
            onClick={saveChanges}
          >
            <SaveIcon/> {Languages.permSaveConsents}
          </Fab>
        </div>
*/
import React, { useState, useEffect } from "react";
import {useStateValue} from "../../../State";
import "../vasu.css";

import Moment from 'moment';

import CommentsEditing  from '../CommentsEditing';
import CommentsReadOnly from '../CommentsReadOnly';

import SnackbarComponent from '../../../Components/Snackbar';
import SlideConfirmationNote  from "../../../Components/SlideConfirmationNote";

import Languages      from "../../../translations";

import SwipeableViews   from 'react-swipeable-views';
import { Typography }    from "@mui/material";
import MobileStepper    from '@mui/material/MobileStepper';
import Box              from '@mui/material/Box'
import Button           from '@mui/material/Button';
import Divider          from '@mui/material/Divider';
import Backdrop         from '@mui/material/Backdrop';
import CircularProgress from "@mui/material/CircularProgress";
import makeStyles from '@mui/styles/makeStyles';

import KeyboardArrowLeft  from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LockIcon           from '@mui/icons-material/Lock';

import _cloneDeep         from "lodash/cloneDeep"; // deep clones an array so that the copy is not just shallow
import { types } from '../../../Reducers/actionTypes';

const useStyles = makeStyles(theme => ({
  backdrop: {
    //zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: "#FBE4FF !important"
  },
}));

const VasuPreparingSubPart = (props) => {

  const classes = useStyles();
  const { state, actions } = useStateValue();
  const VasuData = state.vasu;
  const Table1 = state.vasu.Table1;
  const GeneralPart = state.vasu.GeneralPart;
  const MaxSlideCount = 7;
  const MaxSlideCountNew = 5;
  /*console.log("VasuPreparingSubPart. Mount."+
              /*"\n props: ", props,
              "\n Table1: ",Table1);*/
  let slides = [];

  const { SelectedSlideIndex } = props;
  const { value } = props;

  const [ChildNotes,  setChildNotes]  = useState(_cloneDeep(Table1.Data.ChildNotes));
  const [ParentNotes, setParentNotes] = useState(_cloneDeep(Table1.Data.ParentNotes));
   
  const [SlideIndex,    setSlideIndex]    = useState(SelectedSlideIndex);
  const [Title,         setTitleText]     =  useState(null);
  const [showSnackbar,  setshowSnackbar]  = useState(false);
  const [NoteText,      setNoteText]      = useState(null);
  const [SavingData,    setSavingData]    = useState(false);
  const [FormChanged,   setFormChanged]   = useState(false);
  const [showSlideNote, setshowSlideNote] = useState(false);
  const [EditingTextOpen, setEditingTextOpen] = useState(null); // häkki, kunnes useamman tekstikentän yhtäaikainen editointi ja tallennus speksattu
  
  var SubTitles = [ Languages.VasuChildSubTitle1,
                    Languages.VasuChildSubTitle2,
                    Languages.VasuChildSubTitle3,
                    Languages.VasuChildSubTitle4,
                    Languages.VasuChildSubTitle5,
                    Languages.ManyFormsOfExpression,
                    Languages.VasuChildSubTitle7];

  var SubtitlesNew = [ Languages.VasuChildSubTitle1v2,
                       Languages.VasuChildSubTitle2v2,
                       Languages.VasuChildSubTitle3v2,
                       Languages.VasuChildSubTitle4v2,
                       Languages.VasuChildSubTitle5v2];


  useEffect( () => {
    //console.log("VasuPreparingSubPart. useEffect. SlideIndex: "+SlideIndex+"\n Table1: ", Table1);
    setTitleText(TitleText(SlideIndex, Table1));
  },[SlideIndex, Table1]);
  
  useEffect( () => {
    switch(VasuData.state)
    {
      case undefined:
      case "INITIAL_STATE":
        //console.log("VasuPreparingSubPart. useEffect. INITIAL_STATE. TEE EHKA JOTAIN");
        break;
      case types.SAVING_VASU:
        //console.log("VasuPreparingSubPart. useEffect. SAVING_VASU");
        setSavingData(true);
        break;
       case types.VASU_SAVED_OK:
        /*console.log("VasuPreparingSubPart. useEffect. VASU_SAVED_OK. Päivitä notekset");/*+
                    "\n Table1.Data.ChildNotes: ", Table1.Data.ChildNotes+
                    "\n Table1.Data.ParentNotes: ", Table1.Data.ParentNotes);*/
        setChildNotes(_cloneDeep(Table1.Data.ChildNotes));
        setParentNotes(_cloneDeep(Table1.Data.ParentNotes));
        setSavingData(false);
        setshowSnackbar(true);
        setNoteText("Vasun tallennus ok");
        break;
      case types.VASU_SAVED_FAILED:
        setSavingData(false);
        setshowSnackbar(true);
        setNoteText(VasuData.Code === 404 ? Languages.errorNotFound :
                    VasuData.Code === 500 ? Languages.errorBackend :
                                            Languages.requestFailed);
        break;
      default:
        break;
    }
  }, [VasuData, Table1.Data.ChildNotes, Table1.Data.ParentNotes]);
  
  const handleSnackbarCB = () => {
    //console.log("VasuPreparingSubPart. handleSnackbarCB.  Vasu.state: ", VasuData.state);
    actions.triggerVasuHandled();
    setshowSnackbar(false);
  };

  const handlePrev = () =>
  {
    var index = SlideIndex;
    if (FormChanged)
    {
      setshowSlideNote(true);
    }
    else if (SlideIndex > 0)
    {
      index--;
      setSlideIndex(index);
    }
  };
  
  const handleNext = (maxCount) =>
  {
    var index = SlideIndex;
    if (FormChanged)
    {
      setshowSlideNote(true);
    }
    else if (SlideIndex < (value === "new" ? MaxSlideCountNew -1 : MaxSlideCount -1))
    {
      index++;
      setSlideIndex(index);
    }
  };

  const handleChangeIndex = (e, index) => {
    //console.log("VasuPreparingSubPart. Handle swipe. Nykyinen Slide: ",SlideIndex,"\n Uusi slide: ",e,"\n Edellinen : ",index);
    if (FormChanged)
    {
      setSlideIndex(index);
      setshowSlideNote(true);
    }
    else
    {
      setSlideIndex(e);
    }
  };

  const handleSlideConfirmationNoteCB = (status) =>
  {
    //console.log("PreparingSubPart. Handle slide confnote callback. status: ", status);
    setshowSlideNote(false);
  };

  const handleCommentsEditingCB = (type, mode, newData) => {
    /*console.log("VasuPreparingSubPart. Comments callback:"+
                "\n SlideIndex: "+SlideIndex+
                "\n type: "+ type +
                "\n mode: "+ mode +
                "\n newData: ", newData);*/

    let NewVasuData = [];

    if ( mode === "Save" )
    {
      /*if (type === "ChildNotes")
      { ChildNotes[SlideIndex] = {Text: newData, Saved: Moment(new Date())}; }
      else
      { ParentNotes[SlideIndex] = {Text: newData, Saved: Moment(new Date())}; }*/
      //setChildNotes(ChildNotes);
      //setParentNotes(ParentNotes);
      NewVasuData = { VasuID:     GeneralPart.Id,
                      ChildNotes: type === "ChildNotes" ? true : false,
                      Index:      SlideIndex,
                      NewText:    newData};
      setFormChanged(false);
      setEditingTextOpen(null);
      actions.triggerSaveVasu(NewVasuData);
    }
  };

  const handleFormChange = (status, component) =>
  {
    //console.log("Vasu PreparingSubPart. Handle change. Status: ", status, " component: ", component);

    if (EditingTextOpen === null && status === "EDITED")
    {
      setFormChanged(true);
      setEditingTextOpen(component);
    }
    else if (EditingTextOpen !== null && EditingTextOpen !== component && status === "EDITED")
    {
      setshowSlideNote(true);
    }
    else if (EditingTextOpen === component && status === "EDIT CANCELED")
    {
      setFormChanged(false);
      setEditingTextOpen(null);
    }
  };

  const SlideText = () => {
    var slides = [];
    var slidesNew = [];

    slides = [{Text: Languages.VasuChildSubTitle1Info + "\n" + Languages.VasuChildSubTitleGeneralInfo1}, 
              {Text: Languages.VasuChildSubTitle2Info + "\n" + Languages.VasuChildSubTitleGeneralInfo1},
              {Text: Languages.VasuChildSubTitle3Info + "\n" + Languages.VasuChildSubTitleGeneralInfo1},
              {Text: Languages.VasuChildSubTitle4Info + "\n" + Languages.VasuChildSubTitleGeneralInfo1},
              {Text: Languages.VasuChildSubTitle5Info + "\n" + Languages.VasuChildSubTitleGeneralInfo2},
              {Text: Languages.VasuChildSubTitle6Info + "\n" + Languages.VasuChildSubTitleGeneralInfo2},
              {Text: Languages.VasuChildSubTitle7Info + "\n" + Languages.VasuChildSubTitleGeneralInfo2}];

    slidesNew = [{Text: Languages.VasuChildSubTitle1Infov2}, 
                 {Text: Languages.VasuChildSubTitle2Infov2},
                 {Text: Languages.VasuChildSubTitle3Infov2},
                 {Text: Languages.VasuChildSubTitle4Infov2},
                 {Text: Languages.VasuChildSubTitle5Infov2}];

    if ( value === "old"){
      return slides;
    }
    else{
      return slidesNew;
    }
    
  };
  
  const TitleText = (Index, value, Table1) => {
    let Title = SubTitles[Index];
    //console.log("VasuPreparingSubPart. Return TitleText: ", Title);
    return Title;
  };

  



  if (VasuData.state === "INITIAL_STATE")
  {
    return (<div className="VasuPreparingSubPart"></div>);
  }
  else 
  {
    if (slides.length === 0)
    {
      //console.log("VasuPreparingSubPart. RENDER. Create slides");
      slides = SlideText();
    }
    return (
    <div className="VasuPreparingSubPart">
      <Box>
      
      {SavingData ?
        <div style={{opacity: "0.5"}}>
            <Backdrop open={true} className={classes.backdrop}>
              <CircularProgress />
            </Backdrop>
        </div>
      :
        <div>
        <div>
        
          <SwipeableViews className     = {"col-12 textCenter slide pt-3" /*pl-2 pr-2*/}
                          index         = {SlideIndex} 
                          key           = {SlideIndex}
                          onChangeIndex = {handleChangeIndex}
                          disabled      = {FormChanged ? true : false} >
            {slides.map((slide, index) => { 
              return ( 
                <div key={index}>
                  <Box  className="br-top col-12" sx={{ backgroundColor: "#a6dee0"}}>
                    <Typography color="textSecondary" sx={{textAlign:"center", fontWeight: 'Bold', fontSize: 18, color: "#424242", pt:1}}>{value === "new" ? SubtitlesNew[SlideIndex] : SubTitles[SlideIndex]}</Typography>
                    <MobileStepper style={{backgroundColor: "#a6dee0", color: "black", padding: 0}}
                                  //variant="progress" <= viiva
                                  variant="text"
                                  steps={value === "new" ? MaxSlideCountNew : MaxSlideCount}
                                  position="static"
                                  //LinearProgressProps={{width: "100%"}}
                                  activeStep={SlideIndex}
                                  //className={classes.root}
                                  nextButton={ <Button size="small" style={{color: "black", opacity: SlideIndex === (value === "new" ? MaxSlideCountNew -1 : MaxSlideCount -1) ? "0.5" : "" }} onClick={handleNext} disabled={SlideIndex === (value === "new" ? MaxSlideCountNew -1 : MaxSlideCount -1)}>{Languages.next} <KeyboardArrowRight /> </Button> }
                                  backButton={ <Button size="small" style={{color: "black", opacity: SlideIndex === 0 ? "0.5" : ""}} onClick={handlePrev} disabled={SlideIndex === 0}> <KeyboardArrowLeft /> {Languages.prev}  </Button> }/>
                  </Box>
                  
                  <Box className="br-btm col-12" sx={{boxShadow: 2, mb: 3, pb: 1, backgroundColor: "white"}}>
                    <Typography color="textSecondary" sx={{textAlign:"center", fontWeight: 'Medium', fontSize: 16, color: "#424242", pt:1}}>{slide.Text}</Typography>
                  </Box>
                  
                </div>
              );                  
            })}
          </SwipeableViews>
        
          {showSlideNote ?
              <SlideConfirmationNote openNote     ={showSlideNote}
                                    title        ={Languages.formChange}
                                    text         ={Languages.generalSaveOrCancel}
                                    leftButton   ={Languages.generalOkBtn}
                                    rightButton  ={null}
                                    middleButton ={null}
                                    callback     ={(status) => handleSlideConfirmationNoteCB(status)}></SlideConfirmationNote>
          : null }

          

          <Box className="col-12" >
          
            <CommentsReadOnly Title1  = {<Typography color="textSecondary" sx={{textAlign:"left", fontWeight: 'Bold', fontSize: 16, color: "#424242", pt:1, ml:1}}>{Languages.ChildNotes}</Typography>} 
                              Title2  = {null}
                              Title3  = {null}
                              BgColor = {"#a6dee0"} 
                              Data    = {{Text: Table1.Data.ChildNotesOther[SlideIndex].Text, /*Saved: Moment(Table1.Data.ChildNotesOther[SlideIndex].Saved).format('DD.MM.YYYY')*/}}>
            </CommentsReadOnly>
            {GeneralPart.Locked === null ?
            <CommentsReadOnly Title1  = {null} 
                              Title2  = {null}
                              Title3  = {null}
                              BgColor = {"#a6dee0"} 
                              Data    = {{Text: ChildNotes[SlideIndex].Text, /* Saved: Moment(ChildNotes[SlideIndex].Saved).format('DD.MM.YYYY')*/}}>
            </CommentsReadOnly>
            :
            <CommentsEditing key        = {"ChildNotes"+SlideIndex}
                            Type       = {"ChildNotes"}
                            PrevType     = {EditingTextOpen} // hakki
                            BgColor    = {"#a6dee0"} 
                            Data       = {{Text: ChildNotes[SlideIndex].Text, 
                                            Saved: Moment(ChildNotes[SlideIndex].Saved).format('DD.MM.YYYY') }}
                            callback     = {(status, type, newData) => handleCommentsEditingCB(status, type, newData)}
                            Title1       = {null}
                            callbackEdit = {(status, component) => handleFormChange(status, component)}>
            </CommentsEditing>
            }
          </Box>
          
          
          <Box className="col-12" sx = {{mt: 2}}>
            <CommentsReadOnly Title1  = {<Typography color="textSecondary" sx={{textAlign:"left", fontWeight: 'Bold', fontSize: 16, color: "#424242", pt:1, ml:1}}>{Languages.ParentNotes}</Typography>}  
                              Title2  = {null}
                              Title3  = {null}
                              BgColor = {"#a6dee0"} 
                              Data    = {{Text: Table1.Data.ParentNotesOther[SlideIndex].Text, /*Saved: Moment(Table1.Data.ParentNotesOther[SlideIndex].Saved).format('DD.MM.YYYY')*/}}>
            </CommentsReadOnly>

            {GeneralPart.Locked === null ?
            <CommentsReadOnly Title1  = {null} 
                              Title2  = {null}
                              Title3  = {null}
                              BgColor = {"#a6dee0"} 
                              Data    = {{Text: ParentNotes[SlideIndex].Text, /* Saved: Moment(ParentNotes[SlideIndex].Saved).format('DD.MM.YYYY')*/}}>
            </CommentsReadOnly>
            :
            <CommentsEditing key        = {"ParentNotes"+SlideIndex}
                            Type       = {"ParentNotes"}
                            PrevType     = {EditingTextOpen} // hakki
                            BgColor    = {"#a6dee0"} 
                            Data       = {{Text: ParentNotes[SlideIndex].Text, 
                                            Saved: Moment(ParentNotes[SlideIndex].Saved).format('DD.MM.YYYY')}}
                            callback     = {(type, mode, newData) => handleCommentsEditingCB(type, mode, newData)}
                            Title1       = {null}
                            callbackEdit = {(status, component) => handleFormChange(status,component)}>
            </CommentsEditing>
            }
          </Box>
          <Box  className="col-12" sx = {{mt: 2}} >
              <CommentsReadOnly Title1  = {<Typography color="textSecondary" sx={{textAlign:"left", fontWeight: 'Bold', fontSize: 16, color: "#424242", pt:1, ml:1}}>{Languages.StaffNotes} </Typography>} 
                                Title2  = {null}
                                Title3  = {null}
                                BgColor = {"#a6dee0"} 
                                Data    = {Table1.Data.StaffNotes[SlideIndex].Text === null ? {Text: "ei kirjattuja varhaiskasvatushenkilöstön havaintoja", /* Saved: Moment(Table1.Data.StaffNotes[SlideIndex].Saved).format('DD.MM.YYYY')*/} 
                                : {Text: Table1.Data.StaffNotes[SlideIndex].Text, /* Saved: Moment(Table1.Data.StaffNotes[SlideIndex].Saved).format('DD.MM.YYYY')*/}}>
              </CommentsReadOnly>    
          </Box>
          <Box  className="col-12" sx = {{mt: 2}}>
              <CommentsReadOnly Title1  = {<Typography color="textSecondary" sx={{textAlign:"left", fontWeight: 'Bold', fontSize: 16, color: "#424242", pt:1, ml:1}}>{Languages.VEONotes} </Typography>}
                                Title2  = {null}
                                Title3  = {null}
                                BgColor = {"#a6dee0"} 
                                Data    = {Table1.Data.VEONotes[SlideIndex].Text === null ? {Text: "ei kirjattuja varhaiskasvatushenkilöstön havaintoja", /* Saved: Moment(Table1.Data.VEONotes[SlideIndex].Saved).format('DD.MM.YYYY')*/} 
                                : {Text: Table1.Data.VEONotes[SlideIndex].Text, /* Saved: Moment(Table1.Data.VEONotes[SlideIndex].Saved).format('DD.MM.YYYY')*/}}>
              </CommentsReadOnly>
          </Box>

          </div>
          {showSnackbar ? 
              <SnackbarComponent verticalPosition={'bottom'} show={showSnackbar} message ={NoteText} callback={() => handleSnackbarCB()} />
          :  null }
                  
          {GeneralPart.Locked === null ?
            <div className="fixed-bottom" style={{left:"auto", fontWeight: "bold", color: "#D500F9"}}>
              <div className="float-right">
                {Languages.logged}
                <LockIcon style={{color: "#D500F9"}} fontSize="large"></LockIcon>
              </div>
            </div>
          : null}
          
        </div>

      }
      </Box>
    </div>
  );
  }
}

export default VasuPreparingSubPart;
//export default withStyles(styles)(VasuPreparingSubPart);
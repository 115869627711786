import React, { useState, useEffect } from "react";
import { useStateValue } from "../../../State";
import "../vasu.css";
import ApprovalsComponent from "../Approvals";
import Box from "@mui/material/Box";
import ContentBoxMUI from "../../../Components/ContentBoxMUI";
import BlockIcon from "@mui/icons-material/Block";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Stack from "@mui/material/Stack";
import Languages from "../../../translations";
import ChildRow from "../ChildRow";
import VasuDiscussionSubPart from "./VasuDiscussionSubPart";
import DownloadFileButton from "../../../Components/DownloadFileButton/DownloadFileButton";
import Icon from "@mui/material/Icon";
import CheckBoxTwoToneIcon from "@mui/icons-material/CheckBoxTwoTone";
import Typography from "@mui/material/Typography";
import { types } from "../../../Reducers/actionTypes";
import SnackbarComponent from "../../../Components/Snackbar";
import makeStyles from "@mui/styles/makeStyles";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    //zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "#FBE4FF !important",
  },
}));

const VasuDiscussionPart = (props) => {
  const classes = useStyles();
  const { state, actions } = useStateValue();
  const VasuData = state.vasu;
  const ApprovalsData = state.approval;
  const GeneralPart = VasuData.GeneralPart;
  const SupportData = VasuData.VasuSupportExtraPart;
  const DiscussionData = VasuData.Table2;
  const Approvals = VasuData.VasuApprovalsTable.DiscussionApprovals;
  const { history, location } = props;
  //console.log("VasuDiscussionPart. MOUNT. location.state: ", location.state);

  if (location.state === undefined) {
    history.goBack();
  }

  const [ShowSlideIndex, setShowSlideIndex] = useState(-1);
  const [showSnackbar, setshowSnackbar] = useState(false);
  const [NoteText, setNoteText] = useState(null);
  const [SavingData, setSavingData] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleBackClick = () => {
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = () => {
      window.history.pushState(null, "", window.location.href);
      setShowSlideIndex(-1);
      window.onpopstate = undefined;
      window.history.back();
    };
  };

  const handleBoxClick = (index) => {
    setShowSlideIndex(index);
    handleBackClick();
  };

  useEffect(() => {
    switch (ApprovalsData.state) {
      case undefined:
      case "INITIAL_STATE":
        break;
      case types.SAVING_APPROVAL:
        setSavingData(true);
        break;
      case types.APPROVAL_SAVED_OK:
        setSavingData(false);
        setshowSnackbar(true);
        setNoteText("Hyväksynnän tallennus ok");
        break;
      case types.APPROVAL_SAVED_FAILED:
        setSavingData(false);
        setshowSnackbar(true);
        setNoteText(Languages.requestFailed);
        break;
      default:
        break;
    }
  }, [ApprovalsData]);

  const handleSnackbarCB = () => {
    actions.triggerApprovalHandled();
    setshowSnackbar(false);
  };

  if (VasuData.state === "INITIAL_STATE" || location.state === undefined) {
    return <div className="VasuPreparingPart"></div>;
  } else {
    const { childpicture, childFirstName, childLastName, childColor, value } =
      location.state;

    return (
      <div className="VasuPreparingPart">
        {SavingData ? (
          <div style={{ opacity: "0.5" }}>
            <Backdrop open={true} className={classes.backdrop}>
              <CircularProgress />
            </Backdrop>
          </div>
        ) : (
          <ContentBoxMUI>
            <Box sx={{ mt: 1 }}>
              <ChildRow
                childpicture={childpicture}
                childFirstName={childFirstName}
                childLastName={childLastName}
                childColor={childColor}
                FormId={GeneralPart.Id}
                history={history}
                value={value}
              ></ChildRow>
            </Box>

            {ShowSlideIndex === -1 ? (
              <div>
                <h4 className="textCenter">
                  {GeneralPart.HideTable1 === true
                    ? "1. " + Languages.eduPlanTitle2
                    : "2. " + Languages.eduPlanTitle2}
                </h4>

                <List className="col-12" sx={{ mt: -1 }}>
                  <ListItemButton
                    className="boxed"
                    sx={{
                      backgroundColor: "#afdfc8",
                      borderRadius: 0.5,
                      mt: 1.5,
                      boxShadow: 2,
                    }}
                    onClick={(event) => handleBoxClick(0)}
                  >
                    <ListItemText
                      primaryTypographyProps={{
                        fontWeight: "Bold",
                        fontSize: 16,
                        ml: 2,
                      }}
                      primary={Languages.VasuChildSubTitle8}
                    />
                    <ListItemIcon>
                      <ArrowForwardIosIcon fontWeight="Bold" fontSize="small" />
                    </ListItemIcon>
                  </ListItemButton>
                  <ListItemButton
                    className="boxed"
                    sx={{
                      backgroundColor: "#afdfc8",
                      borderRadius: 0.5,
                      mt: 1.5,
                      boxShadow: 2,
                    }}
                    onClick={(event) => handleBoxClick(1)}
                  >
                    <ListItemText
                      primaryTypographyProps={{
                        fontWeight: "Bold",
                        fontSize: 16,
                        ml: 2,
                      }}
                      primary={
                        Languages.ObjectivesForPedagogicalActivitiesAndMeasuresToAchieve
                      }
                    />
                    <ListItemIcon>
                      <ArrowForwardIosIcon fontWeight="Bold" fontSize="small" />
                    </ListItemIcon>
                  </ListItemButton>
                  <ListItemButton
                    className="boxed"
                    sx={{
                      backgroundColor: "#afdfc8",
                      borderRadius: 0.5,
                      mt: 1.5,
                      boxShadow: 2,
                    }}
                    onClick={(event) => handleBoxClick(5)}
                  >
                    <ListItemText
                      primaryTypographyProps={{
                        fontWeight: "Bold",
                        fontSize: 16,
                        ml: 2,
                      }}
                      primary={Languages.VasuChildSubTitle10}
                    />
                    <ListItemIcon>
                      <ArrowForwardIosIcon fontSize="small" />
                    </ListItemIcon>
                  </ListItemButton>
                  {/*{DiscussionData.VasuDiscussionGuideText !== null &&
                    DiscussionData.VasuDiscussionGuideText.length > 0 && (
                      <>
                        <Box
                          sx={{
                            backgroundColor: "#afdfc8",
                            borderRadius: 0.5,
                            mt: 5,
                            boxShadow: 2,
                            padding: "8px 15px",
                          }}
                        >
                          <Typography
                            color="textSecondary"
                            sx={{
                              textAlign: "center",
                              fontWeight: "bold",
                              fontSize: 16,
                              color: "#424242",
                            }}
                          >
                            {DiscussionData.VasuDiscussionGuideText}
                          </Typography>
                        </Box>
                      </>
                          )}*/}
                </List>

                {SupportData.VasuDiscussionDecisionDate !== null ? (
                  <div>
                    <Stack spacing={2} alignItems="center" sx={{}}>
                      <Stack
                        direction="row"
                        spacing={1}
                        alignItems="baseline"
                        sx={{ mt: 2 }}
                      >
                        <Icon>
                          {SupportData.IdDiscussionDecision === null ? (
                            <BlockIcon
                              fontSize="small"
                              sx={{
                                color: "red",
                                fontSize: 15,
                                fontWeight: "medium",
                              }}
                            />
                          ) : (
                            <CheckBoxTwoToneIcon
                              sx={{
                                color: "green",
                                fontSize: 16,
                                fontWeight: "medium",
                              }}
                            />
                          )}
                        </Icon>
                        <Typography
                          color="textSecondary"
                          sx={{
                            textAlign: "left",
                            fontWeight: "medium",
                            fontSize: 16,
                            color: "#424242",
                          }}
                        >
                          {Languages.vasu +
                            " " +
                            Languages.eduPlanTitle2 +
                            " " +
                            Languages.VasuadministrativeDecision +
                            " " +
                            SupportData.VasuDiscussionDecisionDate}
                        </Typography>
                        {SupportData.VasuDiscussionDecisionDate !== null && (
                          <DownloadFileButton
                            FileName={
                              GeneralPart.Attachments[
                                GeneralPart.Attachments.map(
                                  (object) => object.Id
                                ).indexOf(SupportData.IdDiscussionDecision)
                              ].Name
                            }
                            FileMimeType={
                              GeneralPart.Attachments[
                                GeneralPart.Attachments.map(
                                  (object) => object.Id
                                ).indexOf(SupportData.IdDiscussionDecision)
                              ].FileType
                            }
                            FileId={
                              GeneralPart.Attachments[
                                GeneralPart.Attachments.map(
                                  (object) => object.Id
                                ).indexOf(SupportData.IdDiscussionDecision)
                              ].Id
                            }
                            file={null}
                            useIconButton={false}
                            useThreeDots={false}
                            useTextButton={true}
                            disabled={
                              SupportData.VasuDiscussionDecisionDate === null
                                ? true
                                : false
                            }
                            text={Languages.generalOpen}
                            isImage={false}
                          />
                        )}
                      </Stack>
                      <Box className="col-12">
                        <Accordion
                          expanded={expanded === "panel1"}
                          onChange={handleChange("panel1")}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            style={{ backgroundColor: "#afdfc8" }}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <Typography
                              color="textSecondary"
                              sx={{
                                textAlign: "left",
                                fontWeight: "bold",
                                fontSize: 16,
                                color: "#424242",
                              }}
                            >
                              {Languages.ConsultationChildAndGuardian2}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails
                            style={{ backgroundColor: "#ffffff" }}
                          >
                            <Stack
                              className="col-12"
                              direction="row"
                              spacing={8}
                              alignItems="center"
                              sx={{ mt: 2, ml: 4 }}
                            >
                              <ApprovalsComponent
                                Approval={Approvals.Parent1Approval}
                                GeneralPart={GeneralPart}
                                ParentName={GeneralPart.Parent1Name}
                                ParentId={GeneralPart.Parent1Id}
                                DecisionId={SupportData.IdDiscussionDecision}
                                Type={0}
                              >
                                {" "}
                              </ApprovalsComponent>
                              <ApprovalsComponent
                                Approval={Approvals.Parent2Approval}
                                GeneralPart={GeneralPart}
                                ParentName={GeneralPart.Parent2Name}
                                ParentId={GeneralPart.Parent2Id}
                                DecisionId={SupportData.IdDiscussionDecision}
                                Type={0}
                              >
                                {" "}
                              </ApprovalsComponent>
                            </Stack>
                          </AccordionDetails>
                        </Accordion>
                      </Box>
                    </Stack>

                    {showSnackbar ? (
                      <SnackbarComponent
                        verticalPosition={"bottom"}
                        show={showSnackbar}
                        okColor={true}
                        message={NoteText}
                        callback={() => handleSnackbarCB()}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <VasuDiscussionSubPart
                SelectedSlideIndex={ShowSlideIndex}
                value={value}
              >
                {" "}
              </VasuDiscussionSubPart>
            )}
          </ContentBoxMUI>
        )}
      </div>
    );
  }
};

export default VasuDiscussionPart;

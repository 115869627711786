import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";

import Typography from "@mui/material/Typography";

import ChildProfilePicture from "../../../ChildProfilePicture";

import MenuItem from "@mui/material/MenuItem";

import Select from "@mui/material/Select";

const CentreSelection = ({
  listOfChildren,
  names,
  handleResChildren,
  displayName,
  handleChange2,
  age,
}) => {
  return (
    <>
      {listOfChildren.map((child) => (
        <>
          {names.map((person) => (
            <>
              {person.Id === child && (
                <ChildCentreRow
                  person={person}
                  child={child}
                  handleResChildren={handleResChildren}
                  displayName={displayName}
                ></ChildCentreRow>
              )}
            </>
          ))}
        </>
      ))}
    </>
  );
};

export default CentreSelection;

const ChildCentreRow = ({ person, child, handleResChildren, displayName }) => {
  //WIP Centre Selection functionality
  const [age, setAge] = React.useState(person.Placements[0].Id);
  useEffect(() => {
    handleResChildren(child, person.Placements[0].Id);
  }, []);

  const handleChange2 = (event) => {
    setAge(event.target.value);
    handleResChildren(child, event.target.value);
  };

  if (!displayName) {
    return (
      <Select
        size="small"
        value={age}
        sx={{
          color: "black",

          width: "96%",
          ml: "2%",
          mb: 1,
          fontSize: "13px",
        }}
        onChange={handleChange2}
      >
        {person.Placements.map((placement) => (
          <MenuItem key={placement.Id} value={placement.Id}>
            {placement.Priority +
              ". " +
              placement.Centre +
              " - " +
              placement.Group}
          </MenuItem>
        ))}
      </Select>
    );
  }
  return (
    <>
      <Box
        sx={{
          width: "80%",
          ml: "10%",
          border: 1,
          borderColor: "#e0e0de",
        }}
      >
        <Stack direction="row" alignItems="center" sx={{ mt: 1, mb: 2 }}>
          <Box sx={{ width: "20%" }}>
            <Avatar sx={{ width: 36, height: 36, ml: "10%" }}>
              <ChildProfilePicture childId={child} />
            </Avatar>
          </Box>
          <Box sx={{ width: "60%" }}>
            <Typography
              sx={{ mt: 1 }}
              color="textSecondary"
              fontSize="13px"
              textAlign="center"
            >
              {person.LastName.toUpperCase() +
                ", " +
                person.FirstName.toUpperCase()}
            </Typography>
          </Box>
          <Box sx={{ width: "20%" }}></Box>
        </Stack>

        <Select
          size="small"
          value={age}
          sx={{
            color: "black",

            width: "96%",
            ml: "2%",
            mb: 1,
            fontSize: "13px",
          }}
          onChange={handleChange2}
        >
          {person.Placements.map((placement) => (
            <MenuItem key={placement.Centre} value={placement.Id}>
              {placement.Priority +
                ". " +
                placement.Centre +
                " - " +
                placement.Group}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </>
  );
};

import { AuthStatus } from './';

export const initAuthState = () => {
  return {
    isAuthenticated: false,
    user: undefined,
    lastLogoutReason: "NOT_SET",
    lastErrorText: undefined,
    passwordValid: "",
    passwordChangeRequired: false
  }
}

export const initLoginSettingsState = () => {
  return {
    status: AuthStatus.NOT_LOADED
  }
}
import React, { useState, useEffect, useCallback } from "react";
import { useStateValue } from "../../State";
import "./vasu.css";

import ContentBoxMUI from "../../Components/ContentBoxMUI";
import Languages from "../../translations";
import InfoModal from "../../Components/Modals/InfoModal";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import LockIcon from "@mui/icons-material/Lock";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import BlockIcon from "@mui/icons-material/Block";
import Typography from "@mui/material/Typography";

//import { makeStyles }   from '@mui/material/styles';

import GeneralSection from "./GeneralPart";
import ChildRow from "./ChildRow";

import SnackbarComponent from "../../Components/Snackbar";

import { types } from "../../Reducers/actionTypes";
import { LOGOUT_REASONS } from "../../Api";
import { useAuth } from "../../Auth";
import { getProfilePictureData } from "../../utils";

const VasuContainer = (props) => {
  const { state, actions } = useStateValue();
  const { authActions } = useAuth();
  const [ShowNote, setShowNote] = useState(true);
  const VasuData = state.vasu;
  const GeneralPart = state.vasu.GeneralPart;
  const SupportData = state.vasu.VasuSupportExtraPart;
  //console.log("VasuContainer. MOUNT \n GeneralPart: ", GeneralPart);

  const { history /*, location*/ } = props;

  const { children } = state;
  const { profilePictures } = state;
  const child = children.ChildList.find(
    (c) => c.Id === props.match.params.ChildId
  );
  const profilePicBase64 = getProfilePictureData(state, child?.Id);

  //Child data
  const [childColor, setchildColor] = useState(
    child === undefined ? null : child.BgColor
  );
  const [childpicture, setchildpicture] = useState(profilePicBase64);
  const [childName, setchildName] = useState(
    child === undefined ? null : child.Name
  );
  const [childFirstName, setchildFirstName] = useState(
    child === undefined ? null : child.FirstName
  );
  const [childLastName, setchildLastName] = useState(
    child === undefined ? null : child.LastName
  );
  const [VasuId, setVasuId] = useState(
    child === undefined ? null : child.VasuId
  );

  const [LoadingData, setLoadingData] = useState(false);
  const [showSnackbar, setshowSnackbar] = useState(false);
  const [NoteText, setNoteText] = useState(null);
  const [ProfilePicturesNotHandled, setProfilePicturesNotHandled] =
    useState(false);

  useEffect(() => {
    if (GeneralPart.Locked === null) {
      if (sessionStorage.getItem("prev") === "childinfo") {
        sessionStorage.setItem(
          "showNote" + childFirstName.slice(-3) + childFirstName.slice(3),
          JSON.stringify(true)
        );
      }
      const data = sessionStorage.getItem(
        "showNote" +
          (childFirstName !== null ? childFirstName.slice(-3) : "") +
          (childFirstName !== null ? childFirstName.slice(3) : "")
      );
      if (data !== null) setShowNote(JSON.parse(data));
      sessionStorage.removeItem("prev");
    }
  }, []);

  useEffect(() => {
    handleChildList();
    handleProfilePictures();
  });

  useEffect(() => {
    switch (VasuData.state) {
      case undefined:
      case "INITIAL_STATE":
        if (VasuId !== null && LoadingData === false) {
          //console.log("VasuContainer. INITIAL_STATE. Load vasu");
          actions.triggerLoadVasu(VasuId);
          setLoadingData(true);
        }
        break;
      case types.VASU_LOAD_OK:
        //console.log("\nVasuContainer. useEffect. VASU_LOAD_OK");
        setLoadingData(false);
        break;
      case types.VASU_LOAD_FAILED:
        setLoadingData(false);
        setshowSnackbar(true);
        setNoteText(
          VasuData.Code === 404
            ? Languages.errorNotFound
            : VasuData.Code === 500
            ? Languages.errorBackend
            : Languages.requestFailed
        );
        break;
      default:
        break;
    }
  }, [VasuData, VasuId, LoadingData]);

  const handleChildList = () => {
    /*console.log("VasuContainer. handleChildList."+
                "\n children.state: ", children.state,
                "\n profilePictures.state: ",profilePictures,
                "\n VasuData.state: ", VasuData.state);*/

    switch (children.state) {
      case types.LOADING_CHILDREN:
        //console.log("VasuContainer. handleChildList. LOADING_CHILDREN");
        //setLoadingData(true);
        break;
      case types.LOAD_FAILED_CHILDREN:
        //console.log("VasuContainer. handleChildList. LOAD_FAILED_CHILDREN");
        if (children.Code === 401) {
          authActions.logout(LOGOUT_REASONS.normal);
        }
        break;
      case types.LOAD_SUCCESS_CHILDREN:
        /*console.log("VasuContainer. handleChildList. LOAD_SUCCESS_CHILDREN"+
                            "\n profilePictures.state: ",profilePictures);*/
        const child = children.ChildList.find(
          (c) => c.Id === props.match.params.ChildId
        );
        setchildColor(child.BgColor);
        setchildName(child.Name);
        setchildFirstName(child.FirstName);
        setchildLastName(child.LastName);
        setVasuId(child.VasuId);

        if (profilePictures.state === "PROFILEPICTURES_INITIAL_STATE") {
          actions.triggerLoadProfilePictures();
          setProfilePicturesNotHandled(true);
        }
        if (VasuData.state === "INITIAL_STATE" && LoadingData === false) {
          //console.log("VasuContainer. LOAD_SUCCESS_CHILDREN ja INITIAL_STATE. Load vasu");
          actions.triggerLoadVasu(child.VasuId);
          setLoadingData(true);
        }
        break;
      default:
        break;
    }
  };

  const handleProfilePictures = () => {
    if (
      ProfilePicturesNotHandled /* && children.state === types.LOAD_SUCCESS_CHILDREN*/
    ) {
      switch (profilePictures.state) {
        case types.LOADING_PROFILEPICTURES:
          //console.log("VasuContainer. LOADING_PROFILEPICTURES");
          break;
        case types.LOAD_FAILED_PROFILEPICTURES:
          setchildpicture(profilePictures.Code);
          setProfilePicturesNotHandled(false);
          break;
        case types.LOAD_SUCCESS_PROFILEPICTURES:
          var picturechild = profilePictures.profilePicturesList.find(
            (p) => p.PersonID === props.match.params.ChildId
          );
          //console.log("VasuContainer. LOAD_SUCCESS_PROFILEPICTURES. picturechild: ", picturechild);
          setchildpicture(picturechild.ImageBase64);
          setProfilePicturesNotHandled(false);
          break;
        default:
          break;
      }
    }
  };

  const closeNote = useCallback(() => {
    setShowNote(false);
    if (childFirstName !== null && GeneralPart.Locked === null) {
      sessionStorage.setItem(
        "showNote" + childFirstName.slice(-3) + childFirstName.slice(3),
        JSON.stringify(false)
      );
    }
  }, []);

  const handleSnackbarCB = () => {
    VasuData.state = "INITIAL_STATE"; // vasun lataus epäonnistunut, alustetaan vasun tila
    //console.log("VasuContainer. handleSnackbarCB.  Vasu.state: ", VasuData.state);
    //setshowSnackbar(false);
    history.goBack();
  };

  const toggleExpanded = (Form) => {
    // not need to do anything here
  };

  const handleBoxClick = (part) => {
    switch (part) {
      case 1:
        history.push("/vasuPreparingPart", {
          childpicture: childpicture,
          childFirstName: childFirstName,
          childLastName: childLastName,
          childColor: childColor,
          value: GeneralPart.VasuStyle,
        });
        break;
      case 2:
        history.push("/vasuDiscussionPart", {
          childpicture: childpicture,
          childFirstName: childFirstName,
          childLastName: childLastName,
          childColor: childColor,
          value: GeneralPart.VasuStyle,
        });
        break;
      case 3:
        history.push("/vasuSupportPart", {
          childpicture: childpicture,
          childFirstName: childFirstName,
          childLastName: childLastName,
          childColor: childColor,
          vasu: GeneralPart.VasuStyle,
        });
        break;
      case 4:
      default:
        history.push("/descriptionPart", {
          childpicture: childpicture,
          childFirstName: childFirstName,
          childLastName: childLastName,
          childColor: childColor,
          vasu: true,
        });
        break;
    }
  };

  if (VasuData.state === "INITIAL_STATE") {
    return <div className="VasuContainer"></div>;
  } else if (showSnackbar) {
    return (
      <div className="VasuContainer">
        <ContentBoxMUI style={{ backgroundColor: "antiquewhite" }}>
          <ChildRow
            childpicture={childpicture}
            childFirstName={childFirstName}
            childLastName={childLastName}
            childColor={childColor}
            FormId={VasuId}
            history={history}
          ></ChildRow>
          <SnackbarComponent
            verticalPosition={"bottom"}
            show={showSnackbar}
            message={NoteText}
            callback={() => handleSnackbarCB()}
          />
        </ContentBoxMUI>
      </div>
    );
  } else {
    return (
      <ContentBoxMUI>
        <div id={"test_VasuChild_loaded"} className="VasuContainer">
          {LoadingData ? (
            <div style={{ opacity: "0.5" }}>
              <Backdrop open={true}>
                <CircularProgress />
              </Backdrop>
            </div>
          ) : (
            <div>
              <Box sx={{ mt: 1 }}>
                <ChildRow
                  childpicture={childpicture}
                  childFirstName={childFirstName}
                  childLastName={childLastName}
                  childColor={childColor}
                  FormId={VasuId}
                  history={history}
                ></ChildRow>
              </Box>

              <Box className="col-12" sx={{ mt: 3 }}>
                <GeneralSection
                  className="boxed"
                  childName={childName}
                  vasu={true}
                  callback={(status) => toggleExpanded(status)}
                ></GeneralSection>
              </Box>

              {/*{GeneralPart.HideTable1 === true ? null :

                <Link
                  className="boxlink col-12"
                  component="button"
                  onClick={() => handleBoxClick(1)}
                  underline="hover">
                  <Box bgcolor="#FBE4FF"
                    className="boxed text-center p-5"
                    style={{ opacity: GeneralPart.Locked === null ? "40%" : "" }}>
                    <div style={{ fontSize: "large" }}>1. {Languages.eduPlanTitle1}</div>
                  </Box>
                </Link>
              }

              <Link
                className="boxlink col-12"
                component="button"
                onClick={() => handleBoxClick(2)}
                underline="hover">
                <Box bgcolor="#E9FFEA"
                  className="boxed text-center p-5"
                  style={{ opacity: GeneralPart.FormLocked === true ? "60%" : "" }}>
                  <div style={{ fontSize: "large" }}>{GeneralPart.HideTable1 === true ? "1. " + Languages.eduPlanTitle2 : "2. " + Languages.eduPlanTitle2}</div>
                </Box>
              </Link>

              <Link
                className="boxlink col-12"
                component="button"
                onClick={() => handleBoxClick(3)}
                underline="hover">
                <Box bgcolor="#FFF5D8"
                  className="boxed text-center p-5"
                  style={{ opacity: GeneralPart.FormLocked === true ? "60%" : "" }}>
                  <div style={{ fontSize: "large" }}>{GeneralPart.HideTable1 === true ? "2. " + Languages.eduPlanChildDescription : "3. " + Languages.eduPlanChildDescription}</div>
                </Box>
              </Link>*/}

              <List className="col-12" sx={{ mt: -1 }}>
                {GeneralPart.HideTable1 === true ? null : (
                  <ListItemButton
                    className="boxed"
                    sx={{
                      backgroundColor: "#a6dee0",
                      borderRadius: 0.5,
                      mt: 1.5,
                      boxShadow: 2,
                    }}
                    onClick={(event) => handleBoxClick(1)}
                  >
                    <ListItemText
                      primaryTypographyProps={{
                        fontWeight: "Bold",
                        fontSize: 16,
                        ml: 2,
                        mb: 1,
                      }}
                      primary={"1. " + Languages.eduPlanTitle1}
                      secondary={
                        <React.Fragment>
                          <ListItemText
                            primaryTypographyProps={{
                              fontSize: 15,
                              mb: 1,
                              ml: 2,
                            }}
                            primary={Languages.eduPlanSubtitle2}
                            secondaryTypographyProps={{
                              fontSize: 14,
                              fontStyle: "italic",
                              ml: 2,
                            }}
                            secondary={
                              Languages.generalLastModified +
                              ": " +
                              GeneralPart.PreparingModificationDate
                            }
                          />
                        </React.Fragment>
                      }
                    />
                    <ListItemIcon>
                      <ArrowForwardIosIcon fontWeight="Bold" fontSize="small" />
                    </ListItemIcon>
                  </ListItemButton>
                )}
                <ListItemButton
                  className="boxed"
                  sx={{
                    backgroundColor: "#afdfc8",
                    borderRadius: 0.5,
                    mt: 1.5,
                    boxShadow: 2,
                  }}
                  onClick={(event) => handleBoxClick(2)}
                >
                  <ListItemText
                    primaryTypographyProps={{
                      fontWeight: "Bold",
                      fontSize: 16,
                      ml: 2,
                      mb: 1,
                    }}
                    primary={
                      (GeneralPart.HideTable1 === true ? "1. " : "2. ") +
                      Languages.eduPlanTitle2
                    }
                    secondary={
                      <React.Fragment>
                        <ListItemText
                          primaryTypographyProps={{
                            fontSize: 15,
                            mb: 1,
                            ml: 2,
                          }}
                          primary={Languages.eduPlanSubtitle1}
                          secondaryTypographyProps={{
                            fontSize: 14,
                            fontStyle: "italic",
                            ml: 2,
                          }}
                          secondary={
                            Languages.generalLastModified +
                            ": " +
                            GeneralPart.DiscussionModificationDate
                          }
                        />
                      </React.Fragment>
                    }
                  />
                  <ListItemIcon>
                    <ArrowForwardIosIcon fontWeight="Bold" fontSize="small" />
                  </ListItemIcon>
                </ListItemButton>
                {GeneralPart.VasuStyle == "new" && (
                  <ListItemButton
                    className="boxed"
                    disabled={
                      GeneralPart.SupportDisabled === "yes" ? true : false
                    }
                    sx={{
                      backgroundColor: "#fceebf",
                      borderRadius: 0.5,
                      mt: 1.5,
                      boxShadow: 2,
                    }}
                    onClick={(event) => handleBoxClick(3)}
                  >
                    <ListItemText
                      primaryTypographyProps={{
                        fontWeight: "Bold",
                        fontSize: 16,
                        ml: 2,
                        mb: 1,
                      }}
                      primary={
                        (GeneralPart.HideTable1 === true ? "2. " : "3. ") +
                        Languages.eduPlanTitle3
                      }
                      secondary={
                        <React.Fragment>
                          <ListItemText
                            primaryTypographyProps={{
                              fontSize: 15,
                              mb: 1,
                              ml: 2,
                            }}
                            primary={
                              GeneralPart.SupportDisabled === "no"
                                ? Languages.eduPlanSubtitle1
                                : Languages.VasuNoEnhancedSupport
                            }
                            secondaryTypographyProps={{
                              fontSize: 14,
                              fontStyle: "italic",
                              ml: 2,
                            }}
                            secondary={
                              GeneralPart.SupportDisabled === "no"
                                ? Languages.generalLastModified +
                                  ": " +
                                  GeneralPart.PreparingModificationDate
                                : " "
                            }
                          />
                        </React.Fragment>
                      }
                    />
                    <ListItemIcon>
                      {GeneralPart.SupportDisabled === "no" ? (
                        <ArrowForwardIosIcon fontSize="small" />
                      ) : (
                        <BlockIcon fontSize="small" sx={{ color: "red" }} />
                      )}
                    </ListItemIcon>
                  </ListItemButton>
                )}
                <ListItemButton
                  className="boxed"
                  sx={{
                    backgroundColor: "#fbcece",
                    borderRadius: 0.5,
                    mt: 1.5,
                    boxShadow: 2,
                  }}
                  onClick={(event) => handleBoxClick(4)}
                >
                  <ListItemText
                    primaryTypographyProps={{
                      fontWeight: "Bold",
                      fontSize: 16,
                      ml: 2,
                      mb: 1,
                    }}
                    primary={
                      (GeneralPart.VasuStyle === "new"
                        ? GeneralPart.HideTable1 === true
                          ? "3. "
                          : "4. "
                        : "2. ") + Languages.eduPlanChildDescription
                    }
                    secondary={
                      <React.Fragment>
                        <ListItemText
                          primaryTypographyProps={{
                            fontSize: 15,
                            mb: 1,
                            ml: 2,
                          }}
                          primary={Languages.eduPlanSubtitle1}
                          secondaryTypographyProps={{
                            fontSize: 14,
                            fontStyle: "italic",
                            ml: 2,
                          }}
                          secondary={
                            Languages.generalLastModified +
                            ": " +
                            GeneralPart.DescriptionModificationDate
                          }
                        />
                      </React.Fragment>
                    }
                  />
                  <ListItemIcon>
                    <ArrowForwardIosIcon fontSize="small" />
                  </ListItemIcon>
                </ListItemButton>
              </List>

              {GeneralPart.Locked === null ? (
                <div>
                  <InfoModal
                    modalOpen={ShowNote}
                    headerText={Languages.logged}
                    onClose={closeNote}
                    allowClose={true}
                  >
                    <Typography>{Languages.vasuLocked}</Typography>
                  </InfoModal>
                  <div
                    className="fixed-bottom"
                    style={{
                      left: "auto",
                      fontWeight: "bold",
                      color: "#D500F9",
                    }}
                  >
                    <div className="float-right">
                      {Languages.logged}
                      <LockIcon
                        style={{ color: "#D500F9" }}
                        fontSize="large"
                      ></LockIcon>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          )}
        </div>
      </ContentBoxMUI>
    );
  }
};

export default VasuContainer;

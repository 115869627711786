import moment from "moment";
import { getDomain, parseJwt } from "../utils";
import Languages from "../translations";
import { SESSION_KEYS, LOCAL_STORAGE_KEYS } from "../Contants";


class SessionManager {
  constructor() {
    // properties to cache in-memory
    this.accessToken = undefined;
    this.personId = undefined;
    this.userId = undefined;
    this.usersName = undefined;
    this.firebaseInfo = undefined;
  }

  setSession(responseData) {

    const resolveCommune = (commune) => {
      if (responseData.CommuneName === null) {
        return Languages.errorCommuneNotFound;
      }
      if (responseData.CommuneName === "NOTAVAILABLE") {
        return Languages.errorCommuneNotAvailable;
      }
      return commune
    }

    this.usersName = responseData.UserName;

    sessionStorage.setItem(SESSION_KEYS.communeName, resolveCommune(responseData.CommuneName));
    sessionStorage.setItem(SESSION_KEYS.pAbsentCode, responseData.CommunePAbsentCode);
    sessionStorage.setItem(SESSION_KEYS.holidayCode, responseData.CommuneHolidayCode);
    sessionStorage.setItem(SESSION_KEYS.companyCode, responseData.CommuneCompDayCode);
    sessionStorage.setItem(SESSION_KEYS.restApiVersion, responseData.ServerVersion);
  }

  resetSession() {
    sessionStorage.setItem(SESSION_KEYS.communeName, "");
    sessionStorage.setItem(SESSION_KEYS.pAbsentCode, "");
    this.setAccessToken(undefined);
    this.userId = undefined;
    this.personId = undefined;
    this.usersName = undefined;
    this.firebaseInfo = undefined;
  }

  setAccessToken(token) {
    try {
      this.accessToken = token;
      if (token) {
        const jwt = parseJwt(token);
        console.log("Access token updated. Expire time: " + moment.unix(jwt?.payload.exp).format());  
      }
    } catch (error) {
      console.error(`Error occured when setting token ${token}`, error);
    }

  }

  getAccessToken() {
    return this.accessToken;
  }

  accessTokenExpireTime() {
    if (this.accessToken) {
      const parsedJwt = parseJwt(this.accessToken);

      return moment.unix(parsedJwt.payload.exp)
    }

    return undefined
  }

  getCommune() {
    return sessionStorage.getItem('communeName')
  }

  getCookie(cname) {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
  }

  loggedInStatus() {
    return this.userId != undefined;
  }

  // On first call parse userId from jwt token and cache it to memory
  // next calls returns userId from memory
  getUser() {
    if (!this.userId) {
      if (this.accessToken) {
        const jwtData = parseJwt(this.accessToken);
        this.userId = jwtData.payload.unique_name;

        return jwtData.payload.unique_name;
      }
      return undefined;
    }

    return this.userId;
  }

  getUsersName() {
    return this.usersName;
  }

  getPersonId() {
    if (!this.personId) {
      if (this.accessToken) {
        const jwtData = parseJwt(this.accessToken);
        this.personId = jwtData.payload.nameid;

        return jwtData.payload.nameid;
      }
      return undefined;
    }

    return this.personId;
  }

  getUserLanguage() {
    const browserLang = window.navigator.language || window.navigator.userLanguage;
    let lang = localStorage.getItem(LOCAL_STORAGE_KEYS.language);
    if (!lang || lang.length === 0) {
      localStorage.setItem(LOCAL_STORAGE_KEYS.language, browserLang);
      return browserLang;
    }

    return lang;
  }

  setUserLanguage(lang) {
    localStorage.setItem(LOCAL_STORAGE_KEYS.language, lang);
  }

  setEsuomiSessionId(relayState) {
    if (!relayState) {
      sessionStorage.removeItem(SESSION_KEYS.esuomiRelayState);
    } else {    
      sessionStorage.setItem(SESSION_KEYS.esuomiRelayState, relayState);
    }
  }

  getEsuomiSessionId() {
    return sessionStorage.getItem(SESSION_KEYS.esuomiRelayState);
  }

  setFirebaseInfo(firebaseToken, deviceType, deviceId) {
    this.firebaseInfo = {
      firebaseToken,
      deviceType,
      deviceId
    }
  }

  getFirebaseInfo() {
    return this.firebaseInfo;
  }

  /*
  Params:
  time: moment object holding calculated logout time
  */
  setScheduledAutoLogout(time) {
    localStorage.setItem(LOCAL_STORAGE_KEYS.autoLogoutTime, time);
  }

  getScheduledAutoLogout() {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.autoLogoutTime);
  }
}





const sessionManagerInstance = new SessionManager();

export default sessionManagerInstance;
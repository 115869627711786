import React from "react";
import BackToUpButton from "../GuideTools/BackToUpButton";
import DeleteIcon from '@mui/icons-material/Delete'
import Languages from "../../../translations";
import makeStyles from '@mui/styles/makeStyles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import SelectAllIcon from '@mui/icons-material/SelectAll'
import ControlPointIcon from '@mui/icons-material/ControlPoint'

const useStyles = makeStyles({
  success: {
    color: 'rgb(129, 199, 132)',
    fontSize: 'xx-large',
  }
});



const WhatKindOfMessagesAccordionText = () => {
  const classes = useStyles();
    return(
      <>
      <div>
          <h4>{Languages.whatKindOfMessagesAccordionTitle}</h4>
          <br/>
          <div>
          <p className="guide-accordion-details-texts">{Languages.whatKindOfMessagesList1} <b>{Languages.whatKindOfMessagesList2}</b> {Languages.whatKindOfMessagesList3}</p>
          <br />
          <br />
          <hr className="guide-accordion-details-line"/>
          <h4>{Languages.whatKindOfMessagesMiddleTitle}</h4>
          <br />
          <ol>
          <li className="guide-list-item-styles"><b>{Languages.whatKindOfMessagesList4}</b> {Languages.whatKindOfMessagesList5}</li>
          <br />
          <li className="guide-list-item-styles"><b>{Languages.whatKindOfMessagesList6}</b> {Languages.whatKindOfMessagesList7}</li>
          <br />
          <li className="guide-list-item-styles"><b>{Languages.whatKindOfMessagesList8}</b> {Languages.whatKindOfMessagesList9}</li>
          <br />
          <li className="guide-list-item-styles"><b>{Languages.whatKindOfMessagesList10}</b> {Languages.whatKindOfMessagesList11}</li>
          <br />
          <li className="guide-list-item-styles"><b>{Languages.whatKindOfMessagesList12}</b> {Languages.whatKindOfMessagesList13}</li>
          </ol>
          <hr className="guide-accordion-details-line"/>
          <h4>{Languages.whatKindOfMessagesMiddleTitle2}</h4>
          <br />
          <p className="guide-accordion-details-texts">{Languages.whatKindOfMessagesList14}</p>
          <br />
          <br />
          <p className="guide-accordion-details-texts"><b>{Languages.whatKindOfMessagesList15}</b> {Languages.whatKindOfMessagesList16}</p>
          <br />
          <br />
          <p className="guide-accordion-details-texts"><b>{Languages.whatKindOfMessagesList17}</b> {Languages.whatKindOfMessagesList18}</p>
          <br />
          <br />
          <p className="guide-accordion-details-texts"><b>{Languages.whatKindOfMessagesList19}</b> {Languages.whatKindOfMessagesList20}</p>
          </div>
        <br />
        <BackToUpButton />
        </div>
      </>
    )
}

export default WhatKindOfMessagesAccordionText
import React                  from 'react';
import Grid                   from "@mui/material/Grid";
import "../usedcaretimesinfodrawer.css";
import Typography             from "@mui/material/Typography"; 
import Card                   from "@mui/material/Card";
import CardContent            from "@mui/material/CardContent";
import EuroSymbolIcon         from "@mui/icons-material/EuroSymbol";
import Languages              from "../../../translations"; 
import Moment                 from "moment";


const SummaryTopBanner = ({overBookingWarning, closeDrawer, timespan}) => {

    return(

        <div display={{style: {minHeight:"25px"}}}>
          <Card
            onClick={closeDrawer} 
            style={ overBookingWarning ? {backgroundColor: "#C700EB"} : {backgroundColor: "#4377BA"}}>
            <CardContent>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={10}>
                  <Typography id="test_summaryTopBanner_availableTime" style={{marginTop: "5px"}}>
                    {Languages.uctAvailableTime + " "} 
                  </Typography>
                  <Typography>
                    ( {Moment(timespan.start).format("DD.MM.YYYY")} - {Moment(timespan.end).format("DD.MM.YYYY")} )
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <EuroSymbolIcon/>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>

    )

}

export default SummaryTopBanner;
import React, { useEffect, useState } from "react";
import { useStateValue } from "../../../../State";
import MenuIcon from "@mui/icons-material/Menu";
import AddIcon from "@mui/icons-material/Add";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupsIcon from "@mui/icons-material/Groups";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import LightStyles from "../../CareTimeCalendarStyles/LightStyles";
import CareTimeCalendarSummaryDrawer from "./CareTimeCalendarSummaryDrawer";
import CareTimeCalendarChildSelectionDrawer from "./CareTimeCalendarChildSelectionDrawer";
import TopCalendarNavigationDrawer from "./TopCalendarNavigationDrawer";
import CareTimeCalendarReservationDrawer from "./CareTimeCalendarReservationDrawer";
import CareTimeCalendarNewReservationDialog from "./CareTimeCalendarNewReservationDialog";
import CareTimeCalendarNewAbsentDialog from "./CareTimeCalendarNewAbsentDialog";
import CareTimeCalendarDeleteDialog from "./CareTimeCalendarDeleteDialog";
import CareTimeCalendarSettingsDrawer from "./CareTimeCalendarSettingsDrawer";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
const BottomNavigation = (
  {
    names,
    personName,
    handleNames,
    handleFocusDay,
    handleCalendarNavClick,
    LoadCareTimesCuick,
  },
  props
) => {
  const { state, actions } = useStateValue();

  const classes = LightStyles();
  const [drawerOpen3, setDrawerOpen3] = React.useState(false);
  const [drawerOpen4, setDrawerOpen4] = React.useState(false);

  const [drawerState, setDrawerState] = React.useState({
    child: false,
    setting: false,
    calendar: false,
    reservation: false,
    billing: false,
  });

  const toggleDrawer = (anchor) => (event) => {
    if (drawerState[anchor] === true) {
      setDrawerState({ ...state, [anchor]: false });
    } else {
      setDrawerState({ ...state, [anchor]: true });
    }
  };

  const [dialogState, setDialogState] = React.useState({
    reservation: false,
    absent: false,
  });

  const toggleDialog = (anchor) => (event) => {
    if (drawerState[anchor] === true) {
      setDrawerState({ ...state, [anchor]: false });
    } else {
      setDrawerState({ ...state, [anchor]: true });
    }
  };

  const [newResDialogOpen, setNewResDialogOpen] = React.useState(false);
  const closeNewResDialog = () => {
    setNewResDialogOpen(false);
  };
  const HandleNewRes = () => {
    setNewResDialogOpen(true);
    setDrawerOpen4(!drawerOpen4);
  };

  const [newAbsentDialogOpen, setNewAbsentDialogOpen] = React.useState(false);
  const closeNewAbsDialog = () => {
    setNewAbsentDialogOpen(false);
  };
  const handleNewAbs = () => {
    setNewAbsentDialogOpen(true);
    setDrawerOpen4(!drawerOpen4);
  };

  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };
  const handleDelete = () => {
    setDeleteDialogOpen(true);
    setDrawerOpen4(!drawerOpen4);
  };

  const handleClickScroll = (date) => {
    setDrawerState({ calendar: false });
    const element = document.getElementById(date.format("MM.DD.YYYY"));
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      const y = element.getBoundingClientRect().top + window.pageYOffset - 120;

      window.scrollTo({ top: y });
    } else {
      handleCalendarNavClick(date);
    }
  };

  return (
    <div>
      <AppBar
        className={classes.appBar}
        position="fixed"
        style={{ background: "transparent", boxShadow: "none" }}
        sx={{
          top: "auto",
          bottom: 0,
          height: "50px",
        }}
      >
        <Toolbar
          sx={{
            margin: "auto",
            maxWidth: 800,
            width: "100%",
            backgroundColor: "white",
            boxShadow: 5,
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          }}
        >
          <ButtonGroup
            size="large"
            fullWidth
            sx={{
              mb: 0.5,
              "& .MuiButtonGroup-grouped": {
                borderColor: "#ffffff",
              },
              "& .MuiButtonGroup-root": {
                borderColor: "#ffffff",
              },
              "& .MuiButtonGroup-outlined": {
                borderColor: "#ffffff",
              },
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "white",
                boxShadow: 0,
                ":hover": {
                  backgroundColor: "white",
                  boxShadow: 0,
                },
              }}
              onClick={toggleDrawer("setting")}
            >
              <SettingsIcon style={{ color: "#002e6d" }} />
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "white",
                boxShadow: 0,
                ":hover": {
                  backgroundColor: "white",
                  boxShadow: 0,
                },
              }}
              onClick={toggleDrawer("calendar")}
            >
              <CalendarMonthIcon style={{ color: "#002e6d" }} />
            </Button>

            <Box sx={{ ml: 3, mr: 3 }}>
              <Button
                variant="contained"
                color="success"
                style={{ borderRadius: 4 }}
                onClick={toggleDrawer("reservation")}
              >
                <EditCalendarIcon
                  sx={{ padding: 0 }}
                  fontSize="medium"
                  color="white"
                />
              </Button>
            </Box>

            <Button
              onClick={handleDelete}
              variant="contained"
              sx={{
                "&:disabled": {
                  backgroundColor: "transparent",
                },
              }}
              disabled
            >
              <DeleteForeverIcon sx={{ color: "white" }} />
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "white",
                boxShadow: 0,
                ":hover": {
                  backgroundColor: "white",
                  boxShadow: 0,
                },
              }}
              onClick={toggleDrawer("billing")}
            >
              <SummarizeOutlinedIcon style={{ color: "#002e6d" }} />
            </Button>
          </ButtonGroup>
          <CareTimeCalendarSettingsDrawer
            open={drawerState["setting"]}
            onClose={toggleDrawer("setting")}
            names={names}
            personName={personName}
            handleNames={handleNames}
          ></CareTimeCalendarSettingsDrawer>
        </Toolbar>
      </AppBar>

      <CareTimeCalendarSummaryDrawer
        CTBdrawerOpen={drawerState["billing"]}
        CTBhandleDrawerOpen={toggleDrawer("billing")}
        childList={names}
      ></CareTimeCalendarSummaryDrawer>
      {/*<CareTimeCalendarChildSelectionDrawer
        open={drawerState["child"]}
        onClose={toggleDrawer("child")}
        
          ></CareTimeCalendarChildSelectionDrawer>*/}

      <CareTimeCalendarReservationDrawer
        open={drawerState["reservation"]}
        onClose={toggleDrawer("reservation")}
        HandleNewRes={HandleNewRes}
        handleNewAbs={handleNewAbs}
        handleDelete={handleDelete}
      ></CareTimeCalendarReservationDrawer>

      <TopCalendarNavigationDrawer
        open={drawerState["calendar"]}
        onClose={toggleDrawer("calendar")}
        handleClickScroll={handleClickScroll}
        handleCalendarNavClick={handleCalendarNavClick}
        childList={names}
      ></TopCalendarNavigationDrawer>

      <CareTimeCalendarNewReservationDialog
        handleClick={closeNewResDialog}
        personName={personName}
        newResDialogOpen={newResDialogOpen}
        names={names}
        handleFocusDay={handleFocusDay}
        LoadCareTimesCuick={LoadCareTimesCuick}
      />

      <CareTimeCalendarNewAbsentDialog
        handleClick={closeNewAbsDialog}
        personName={personName}
        newResDialogOpen={newAbsentDialogOpen}
        handleFocusDay={handleFocusDay}
        names={names}
      />

      <CareTimeCalendarDeleteDialog
        handleClick={closeDeleteDialog}
        personName={personName}
        newResDialogOpen={deleteDialogOpen}
        names={names}
        handleFocusDay={handleFocusDay}
        LoadCareTimesCuick={LoadCareTimesCuick}
      />
    </div>
  );
};

export default BottomNavigation;

import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import ContentBoxMUI from "../../ContentBoxMUI";
import ContentManager from "./CareTimeCalendarContent/ContentManager";
import BottomNavigation from "./CareTimeCalendarNav/BottomNavigation";
import { useStateValue } from "../../../State";
import moment from "moment";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import PersonIcon from "@mui/icons-material/Person";
import AddIcon from "@mui/icons-material/Add";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import SettingsIcon from "@mui/icons-material/Settings";
import { types } from "../../../Reducers/actionTypes";
import CalendarTimeSlider from "./common/CalendarTimeSlider";
import { DigitalClock } from "@mui/x-date-pickers/DigitalClock";
import { MultiSectionDigitalClock } from "@mui/x-date-pickers/MultiSectionDigitalClock";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { StaticTimePicker } from "@mui/x-date-pickers/StaticTimePicker";
import { renderMultiSectionDigitalClockTimeView } from "@mui/x-date-pickers/timeViewRenderers";
import CircularProgress from "@mui/material/CircularProgress";
import Avatar from "@mui/material/Avatar";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import CardHeader from "@mui/material/CardHeader";
import Languages from "../../../translations";
import Box from "@mui/material/Box";
const CareTimeCalendarSuccess = ({
  CTBDays,
  focusdate,
  handleCalendarNavClick,
  getNextMonth,
  getPrevMonth,
  handleFocusDay,
}) => {
  const { state, actions } = useStateValue();
  // children state array
  const childList = state.children.ChildList;
  // selected children swown in calendar -- default is all children selected
  const [selectedChildren, setSelectedChildren] = useState(
    childList.map((child) => child.Id)
  );
  const handleSelectedChildren = (event, newPersonNames) => {
    setSelectedChildren(newPersonNames);
  };

  //scroll to the init date on load
  const handleClickScroll = (date) => {
    const element = document.getElementById(date.format("MM.DD.YYYY"));
    if (element) {
      const y = element.getBoundingClientRect().top + window.scrollY - 120;
      window.scrollTo({ top: y });
    }
  };

  useEffect(() => {
    //console.log("open changed", open);
  }, [open]);
  useEffect(() => {
    //handleClickScroll(initDate);
  }, []);

  const [open, setOpen] = React.useState(true);

  const handleClose = (value) => {
    setOpen(false);
    localStorage.setItem("dialog", JSON.stringify(true));
  };

  const [dialogClose, setDialogClosed] = React.useState(false);
  const [newDialog, setNewDialog] = React.useState(true);
  useEffect(() => {
    const dc = JSON.parse(localStorage.getItem("dialog"));
    //console.log(dc);
    if (dc == true) {
      setDialogClosed(true);
    }
  }, []);

  return (
    <ContentBoxMUI>
      <ContentManager
        CTBDays={CTBDays}
        childList={childList}
        selectedChildren={selectedChildren}
        focusdate={focusdate}
        getNextMonth={getNextMonth}
        getPrevMonth={getPrevMonth}
        handleFocusDay={handleFocusDay}
      />
      <BottomNavigation
        names={childList}
        personName={selectedChildren}
        handleNames={handleSelectedChildren}
        handleCalendarNavClick={handleCalendarNavClick}
        handleFocusDay={handleFocusDay}
      />
      {newDialog && !dialogClose && (
        <NewDialog open={open} handleClose={handleClose}></NewDialog>
      )}
    </ContentBoxMUI>
  );
};

export default CareTimeCalendarSuccess;

const NewDialog = ({ open, handleClose }) => {
  const { state, actions } = useStateValue();
  const clockType = state.caretimes.clockType.Type;
  const [ResLenght, setResLenght] = React.useState([]);
  const [saved, setSaved] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [saved2, setSaved2] = React.useState(false);
  const [saving2, setSaving2] = React.useState(false);
  //console.log(clockType);
  const [value, setValue] = React.useState("slider");

  useEffect(() => {
    switch (state.caretimes.clockType.state) {
      case types.CLOCK_TYPE_LOAD_OK:
        setValue(state.caretimes.clockType.Type);
      case types.CLOCK_TYPE_SAVED_OK:
        setSaving2(true);
        setSaved2(true);
        break;
      case types.SAVING_CLOCK_TYPE:
        setSaving2(true);
        setSaved2(false);

        break;
      case types.CLOCK_TYPE_SAVED_FAILED:
        setSaving2(false);
        setSaved2(true);
        break;
      default:
        break;
    }
  }, [state.caretimes.clockType.state]);

  const [ResLenght2, setResLenght2] = React.useState([]);

  const handleResLenght2 = (newLenght) => {
    //console.log(moment(newLenght[0]));
    setResLenght2(newLenght);
  };

  const handleCLockType = () => {
    actions.triggerSaveClockType({
      Type: value,
    });
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <Dialog
      disablePadding
      disableGutters
      fullWidth
      sx={{
        "& .MuiDialog-container .MuiDialog-paper": {
          margin: 0,
          borderRadius: 2,
          width: "96%",
        },
        width: "100%",
      }}
      open={open}
    >
      <DialogTitle style={{ backgroundColor: "#FFFFFF" }}>
        <Typography
          color="textSecondary"
          fontSize="26px"
          fontWeight="bold"
          textAlign="center"
        >
          {Languages.new}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ backgroundColor: "#FFFFFF" }}>
        <Typography color="textSecondary" fontWeight="bold" textAlign="center">
          {Languages.clockTypeDialog1}
        </Typography>
        {state.caretimes.clockType.state !== types.LOADING_CLOCK_TYPE && (
          <Box sx={{ mt: 3, mb: 3 }}>
            {state.caretimes.clockType.state !== types.SAVING_CLOCK_TYPE && (
              <Stack spacing={1} alignItems="center">
                <Stack spacing={1} alignItems="center">
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={value}
                      onChange={handleChange}
                    >
                      <Card
                        variant="outlined"
                        sx={{ backgroundColor: "white" }}
                      >
                        <Stack spacing={1} alignItems="center">
                          <FormControlLabel
                            value="slider"
                            control={<Radio />}
                            label={Languages.clockTypeSlider}
                            sx={{ color: "black" }}
                          />
                          <Clocktype type={"slider"}></Clocktype>
                        </Stack>
                      </Card>

                      <Card
                        variant="outlined"
                        sx={{ backgroundColor: "white", mt: 1 }}
                      >
                        <Stack
                          spacing={1}
                          alignItems="center"
                          sx={{ padding: 1 }}
                        >
                          <FormControlLabel
                            value="dropdown"
                            control={<Radio />}
                            label={Languages.clockTypeDigital}
                            sx={{ color: "black" }}
                          />
                          <Clocktype type={"dropdown"}></Clocktype>
                        </Stack>
                      </Card>
                    </RadioGroup>
                  </FormControl>
                  <Button
                    variant="contained"
                    color="success"
                    sx={{ width: "65%", backgroundColor: "#00B3DF" }}
                    onClick={() => handleCLockType()}
                  >
                    {Languages.saveChoice}
                  </Button>
                </Stack>
                {state.caretimes.clockType.state ===
                  types.CLOCK_TYPE_SAVED_OK &&
                  saved2 && (
                    <Typography color="textSecondary" fontSize="medium">
                      {Languages.newCalendarSaveSuccess}
                    </Typography>
                  )}
                {state.caretimes.clockType.state ===
                  types.CLOCK_TYPE_SAVED_FAILED &&
                  saved2 && (
                    <Typography color="textSecondary" fontSize="medium">
                      {Languages.newCalendarSaveFailed +
                        ", " +
                        Languages.newCalendarTryAgain}
                    </Typography>
                  )}
              </Stack>
            )}

            {state.caretimes.clockType.state === types.SAVING_CLOCK_TYPE && (
              <Stack spacing={1} alignItems="center">
                <CircularProgress></CircularProgress>
                <Typography color="textSecondary" fontSize="medium">
                  {Languages.newCalendarSavingDefault}
                </Typography>
              </Stack>
            )}
          </Box>
        )}
        <Typography color="textSecondary" fontWeight="bold" textAlign="center">
          {Languages.clockTypeDialog2}
          <SettingsIcon style={{ color: "#002e6d" }} />
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          width: "100%",
          margin: 0,
          backgroundColor: "#FFFFFF",
        }}
        style={{ justifyContent: "center" }}
      >
        <Button
          sx={{ backgroundColor: "#00B3DF" }}
          variant="contained"
          onClick={handleClose}
        >
          {Languages.generalClose}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
const Clocktype = ({ type }) => {
  const { state, actions } = useStateValue();
  const defaultNursingTimes = state.nursingtimes.defaultNursingTimes;

  const [allowedMinutes, setAllowedMinutes] = useState([0, 15, 30, 45, 59]);

  //Reservation start/end times stored as minutes for the slider component
  const [value, setValue] = React.useState([480, 960]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChange2 = (event, newValue) => {};

  const [endHours, setEndHours] = useState(0);

  const handleEndHours = (value) => {
    setEndHours(value.hours());
    if (value.hours() == 23) {
      setAllowedMinutes([0, 15, 30, 45, 59]);
    } else {
      setAllowedMinutes([0, 15, 30, 45]);
    }
  };

  const formatTime = (time) => {
    if (time && time.hours() === 23 && time.minutes() === 59) {
      return "24:00";
    }

    return time ? time.format("HH:mm") : "";
  };
  const [selectedTime, setSelectedTime] = React.useState(null);

  const handleTimeChange = (time) => {
    setSelectedTime(time);
  };

  const renderCustomTime = () => {
    return <ListItem button>{"24:00"}</ListItem>;
  };

  const handleChange3 = (event) => {
    setStartHours(event.target.value);
  };
  const handleChange4 = (event) => {
    setStartMinutes(event.target.value);
  };
  const handleChange5 = (event) => {
    setEndHours2(event.target.value);
    if (event.target.value == 24) {
      setEndMinutes(0);
    }
  };
  const handleChange6 = (event) => {
    setEndMinutes(event.target.value);
  };

  const [startHours, setStartHours] = React.useState(8);
  const [startMinutes, setStartMinutes] = React.useState(0);
  const [endHours2, setEndHours2] = React.useState(16);
  const [endMinutes, setEndMinutes] = React.useState(0);

  const [value2, setValue2] = React.useState(moment("2022-04-17T15:30"));
  if (type === "slider") {
    return (
      <Slider
        sx={{
          height: 8,
          borderRadius: 0,
          mt: 2,
          width: "90%",
          ml: "2%",

          "& .MuiSlider-thumb": {
            backgroundColor: "#0074C8",
            height: 24,
            width: 24,
          },

          "& .MuiSlider-track": {
            height: 8,
            color: "#0074C8",
          },
          "& .MuiSlider-rail": {
            color: "#e0e0de",
            opacity: 1,
            height: 8,
          },
          "& .MuiSlider-valueLabel": {
            fontSize: 12,
            fontWeight: "normal",
            top: 2,
            backgroundColor: "unset",
            color: "black",
            "&:before": {
              display: "none",
            },
            "& *": {
              background: "transparent",
              color: "black",
            },
          },
        }}
        value={value}
        valueLabelDisplay="on"
        valueLabelFormat={(value) =>
          moment().startOf("day").add(value, "minutes").format("HH:mm") ==
            "00:00" && value == 1440
            ? "24:00"
            : moment().startOf("day").add(value, "minutes").format("HH:mm")
        }
        min={0}
        max={1440}
        step={15}
        onChange={handleChange}
        onChangeCommitted={handleChange2}
      />
    );
  }
  if (type === "clock") {
    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DemoContainer
          components={["DigitalClock", "MultiSectionDigitalClock"]}
        >
          <DemoItem label="Digital clock">
            <DigitalClock
              skipDisabled
              timeStep={15}
              sx={{ color: "black", border: "1px black" }}
            />
          </DemoItem>
          <DemoItem label="Multi section digital clock">
            <MultiSectionDigitalClock
              skipDisabled
              ampm={false}
              sx={{ color: "black", backgroundColor: "red" }}
            />
          </DemoItem>
        </DemoContainer>
      </LocalizationProvider>
    );
  }
  if (type === "dropdown") {
    return (
      <Box>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="center"
          sx={{ paddingBottom: 3 }}
        >
          <Card
            variant="outlined"
            sx={{
              bgcolor: "white",
              mt: 0.5,
              ml: 1,
            }}
          >
            <CardHeader
              title={
                <Typography
                  color="textSecondary"
                  fontWeight="bold"
                  textAlign="center"
                >
                  {Languages.clockTypeStart}
                </Typography>
              }
              sx={{ paddingBottom: 0, paddingTop: 2 }}
            />
            <CardContent>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent="center"
              >
                <FormControl sx={{}}>
                  <Select
                    sx={{
                      color: "black",
                      fontSize: 18,
                      "& .MuiSelect-select": {
                        paddingRight: "16px!important",
                        paddingLeft: "16px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#0074C8",
                      },
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={startHours}
                    onChange={handleChange3}
                    inputProps={{
                      IconComponent: () => null,
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                      style: {
                        maxHeight: 240,
                      },
                      getContentAnchorEl: null,
                      MenuListProps: {
                        sx: {
                          backgroundColor: "white",
                          color: "black",
                          "&& .Mui-selected": {
                            color: "white",
                            backgroundColor: "#0074C8",
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value={0}>00</MenuItem>
                    <MenuItem value={1}>01</MenuItem>
                    <MenuItem value={2}>02</MenuItem>
                    <MenuItem value={3}>03</MenuItem>
                    <MenuItem value={4}>04</MenuItem>
                    <MenuItem value={5}>05</MenuItem>
                    <MenuItem value={6}>06</MenuItem>
                    <MenuItem value={7}>07</MenuItem>
                    <MenuItem value={8}>08</MenuItem>
                    <MenuItem value={9}>09</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={11}>11</MenuItem>
                    <MenuItem value={12}>12</MenuItem>
                    <MenuItem value={13}>13</MenuItem>
                    <MenuItem value={14}>14</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={16}>16</MenuItem>
                    <MenuItem value={17}>17</MenuItem>
                    <MenuItem value={18}>18</MenuItem>
                    <MenuItem value={19}>19</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={21}>21</MenuItem>
                    <MenuItem value={22}>22</MenuItem>
                    <MenuItem value={23}>23</MenuItem>
                  </Select>
                </FormControl>
                <Typography
                  color="textSecondary"
                  fontSize="large"
                  fontWeight="bold"
                >
                  {":"}
                </Typography>
                <FormControl sx={{}}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={startMinutes}
                    onChange={handleChange4}
                    inputProps={{ IconComponent: () => null }}
                    sx={{
                      color: "black",
                      fontSize: 18,
                      "& .MuiSelect-select": {
                        paddingRight: "16px!important",
                        paddingLeft: "16px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#0074C8",
                      },
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                      style: {
                        maxHeight: 240,
                      },
                      getContentAnchorEl: null,
                      MenuListProps: {
                        sx: {
                          backgroundColor: "white",
                          color: "black",
                          "&& .Mui-selected": {
                            color: "white",
                            backgroundColor: "#0074C8",
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value={0}>00</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                    <MenuItem value={45}>45</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
            </CardContent>
          </Card>

          <Typography
            sx={{ paddingTop: 5 }}
            color="textSecondary"
            fontSize="large"
            fontWeight="bold"
          >
            {"-"}
          </Typography>
          <Card
            variant="outlined"
            sx={{
              bgcolor: "white",
              mt: 0.5,
              ml: 1,
            }}
          >
            <CardHeader
              title={
                <Typography
                  color="textSecondary"
                  fontWeight="bold"
                  textAlign="center"
                >
                  {Languages.clockTypeEnd}
                </Typography>
              }
              sx={{ paddingBottom: 0, paddingTop: 2 }}
            />
            <CardContent>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent="center"
              >
                <FormControl sx={{}}>
                  <Select
                    sx={{
                      color: "black",
                      fontSize: 18,
                      "& .MuiSelect-select": {
                        paddingRight: "16px!important",
                        paddingLeft: "16px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#0074C8",
                      },
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={endHours2}
                    onChange={handleChange5}
                    inputProps={{
                      IconComponent: () => null,
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                      style: {
                        maxHeight: 240,
                      },
                      getContentAnchorEl: null,
                      MenuListProps: {
                        sx: {
                          backgroundColor: "white",
                          color: "black",
                          "&& .Mui-selected": {
                            color: "white",
                            backgroundColor: "#0074C8",
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value={0}>00</MenuItem>
                    <MenuItem value={1}>01</MenuItem>
                    <MenuItem value={2}>02</MenuItem>
                    <MenuItem value={3}>03</MenuItem>
                    <MenuItem value={4}>04</MenuItem>
                    <MenuItem value={5}>05</MenuItem>
                    <MenuItem value={6}>06</MenuItem>
                    <MenuItem value={7}>07</MenuItem>
                    <MenuItem value={8}>08</MenuItem>
                    <MenuItem value={9}>09</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={11}>11</MenuItem>
                    <MenuItem value={12}>12</MenuItem>
                    <MenuItem value={13}>13</MenuItem>
                    <MenuItem value={14}>14</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={16}>16</MenuItem>
                    <MenuItem value={17}>17</MenuItem>
                    <MenuItem value={18}>18</MenuItem>
                    <MenuItem value={19}>19</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={21}>21</MenuItem>
                    <MenuItem value={22}>22</MenuItem>
                    <MenuItem value={23}>23</MenuItem>
                    <MenuItem value={24}>24</MenuItem>
                  </Select>
                </FormControl>
                <Typography
                  color="textSecondary"
                  fontSize="large"
                  fontWeight="bold"
                >
                  {":"}
                </Typography>
                <FormControl sx={{}}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={endMinutes}
                    onChange={handleChange6}
                    inputProps={{ IconComponent: () => null }}
                    disabled={endHours2 == 24 ? true : false}
                    sx={{
                      color: "black",
                      fontSize: 18,
                      "& .MuiSelect-select": {
                        paddingRight: "16px!important",
                        paddingLeft: "16px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#0074C8",
                      },
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                      style: {
                        maxHeight: 240,
                      },
                      getContentAnchorEl: null,
                      MenuListProps: {
                        sx: {
                          backgroundColor: "white",
                          color: "black",
                          "&& .Mui-selected": {
                            color: "white",
                            backgroundColor: "#0074C8",
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value={0}>00</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                    <MenuItem value={45}>45</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
            </CardContent>
          </Card>
        </Stack>
      </Box>
    );
  }
};

import React, { useState, useEffect } from "react";
import {useStateValue} from "../../../State";
import "../vasu.css";

import Moment from 'moment';

import CommentsReadOnly from '../CommentsReadOnly';

import ContentBoxMUI  from "../../../Components/ContentBoxMUI";
import Languages      from "../../../translations";

import SwipeableViews from 'react-swipeable-views';

import MobileStepper  from '@mui/material/MobileStepper';
import Box              from '@mui/material/Box'
import Button         from '@mui/material/Button';
import Divider        from '@mui/material/Divider';
import { Typography }    from "@mui/material";
import KeyboardArrowLeft  from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

const VasuDiscussionSubPart = (props) => {

  const { state/*, actions*/ } = useStateValue();
  const VasuData = state.vasu;
  const Table2      = state.vasu.Table2;
  
  const { SelectedSlideIndex } = props;
  const { value } = props;
  //const [VasuChildNotes,  setVasuChildNotes]  = useState(_cloneDeep(Table1.Data.VasuChildNotes));
  const [SlideIndex, setSlideIndex]       = useState(SelectedSlideIndex);
  const [MaxSlideCount, setMaxSlideCount] = useState((value === "new" ? 9 : 8));
  const [Title, setTitleText]             = useState(null);
  
  useEffect( () => {
    //console.log("VasuPreparingSubPart. useEffect. SlideIndex: "+SlideIndex+"\n Table2: ", Table2);
    setTitleText(TitleText(SlideIndex, Table2));
  },[SlideIndex, Table2]);

  const handleChangeIndex = (e, index) => {
    //console.log("VasuDiscussionSubPart. Handle swipe. uusi slide: ",e," edellinen : ",index);
    setSlideIndex(e);
  };
  
  const handlePrev = () =>
  {
    var index = SlideIndex;
    if (SlideIndex > 0)
    {
      index--;
      setSlideIndex(index);
    }
  };

  const handleNext = (maxCount) =>
  {
    var index = SlideIndex;
    if (SlideIndex < (value === "new" ? 8 : 7))
    {
      index++;
      setSlideIndex(index);
    }
  };

  const SlideText = () => {
    let slides = [{Text: Languages.VasuChildSubTitle8Info}, 
                  {Text: Languages.ObjectivesForPedagogicalActivitiesInfo1},
                  {Text: Languages.ObjectivesForPedagogicalActivitiesInfo2},
                  {Text: Languages.ObjectivesForPedagogicalActivitiesInfo3},
                  {Text: Languages.ObjectivesForPedagogicalActivitiesInfo4},
                  {Text: Languages.VasuChildSubTitle10Info1},
                  {Text: Languages.VasuChildSubTitle10Info2},
                  {Text: Languages.VasuChildSubTitle10Info3}];

    let slidesNew = [{Text: Languages.VasuChildSubTitle8Info}, 
                  {Text: Languages.ObjectivesForPedagogicalActivitiesInfo1},
                  {Text: Languages.ObjectivesForPedagogicalActivitiesInfo2},
                  {Text: Languages.ObjectivesForPedagogicalActivitiesInfo3},
                  {Text: Languages.ObjectivesForPedagogicalActivitiesInfo4},
                  {Text: Languages.VasuChildSubTitle10Info4},
                  {Text: Languages.VasuChildSubTitle10Info1},
                  {Text: Languages.VasuChildSubTitle10Info2},
                  {Text: Languages.VasuChildSubTitle10Info3}];
    if ( value === "old"){
      return slides;
    }
    else{
      return slidesNew;
    }
  };
  
  const TitleText = (Index, Table2) => {
    //console.log("VasuDiscussionSubPart. TitleText. Index: ", Index);
    let Title = Index === 0 ? Languages.VasuChildSubTitle8 :
                Index === 1 || Index === 2 || Index === 3 || Index === 4 ? Languages.ObjectivesForPedagogicalActivitiesAndMeasuresToAchieve : Languages.VasuChildSubTitle10;
    return Title;
  };

  if (VasuData.state === "INITIAL_STATE" )
  {
    return (
      <div className="VasuDiscussionSubPart"></div>
    );
  }
  else 
  {
    let slides = SlideText();
    return (
      <div className="VasuDiscussionSubPart">
        <Box>
          <SwipeableViews className     = {"col-12 textCenter slide pt-3"}
                          index         = {SlideIndex} 
                          key           = {SlideIndex}
                          onChangeIndex = {handleChangeIndex} >
            {slides.map((slide, index) => { 
              return ( 
                <div key={index}>
                  
                    <Box  className="br-top col-12" sx={{ backgroundColor: "#afdfc8"}}>
                      <Typography color="textSecondary" sx={{textAlign:"center", fontWeight: 'Bold', fontSize: 18, color: "#424242", pt: 1.5}}>{Title}</Typography>
                      <MobileStepper style={{backgroundColor: "#afdfc8", color: "black", padding: 0}}
                                    //variant="progress" <= viiva
                                    variant="text"
                                    steps={value === "new" ? 9 : 8}
                                    position="static"
                                    //LinearProgressProps={{width: "100%"}}
                                    activeStep={SlideIndex}
                                    //className={classes.root}
                                    nextButton={ <Button size="small" style={{color: "black", opacity: SlideIndex === (value === "new" ? 8 : 7) ? "0.5" : "" }} onClick={handleNext} disabled={SlideIndex === (value === "new" ? 8 : 7)}>{Languages.next} <KeyboardArrowRight /> </Button> }
                                    backButton={ <Button size="small" style={{color: "black", opacity: SlideIndex === 0 ? "0.5" : ""}} onClick={handlePrev} disabled={SlideIndex === 0}> <KeyboardArrowLeft /> {Languages.prev}  </Button> }/>
                    </Box>
                    
                    <Box className="br-btm col-12" sx={{boxShadow: 2, mb: 3, pb: 1, backgroundColor: "white"}}>
                      <Typography color="textSecondary" sx={{textAlign:"center", fontWeight: 'Medium', fontSize: 16, color: "#424242", pt:1}}>{slide.Text}</Typography>
                    </Box>
                  
                  </div>
              );                  
            })}
          </SwipeableViews>
            
          {SlideIndex > 4 ? null :
            <Box  className="col-12" >
              <CommentsReadOnly Title1  = {<Typography color="textSecondary" sx={{textAlign:"left", fontWeight: 'Bold', fontSize: 16, color: "#424242", pt:1, ml:1}}>{Languages.StaffNotes} </Typography>}
                                Title2  = {SlideIndex === 1 || SlideIndex === 2 || SlideIndex === 3 || SlideIndex === 4 ? <Typography color="textSecondary" sx={{textAlign:"left", fontWeight: 'medium', fontSize: 14, color: "#424242", ml:1}}>{Languages.IssuesRaisedAgreed} </Typography> : null}
                                Title3  = {null}
                                BgColor = {'#afdfc8'} 
                                Data    = {{Text: Table2.Data[SlideIndex].StaffNotes.Text, /* Saved: Moment(Table2.Data[SlideIndex].StaffNotes.Saved).format('DD.MM.YYYY')*/}}>
              </CommentsReadOnly>
            </Box>
            
          }
          
          
          {SlideIndex === 0 ? null :
          <div>

          {Table2.Data[SlideIndex].Evaluations.map((evaluation, eindex) => { 
            return ( 
              <div key={eindex}>
                <Box  className="col-12" >
                  <CommentsReadOnly Title1  = {SlideIndex >= 5 && eindex === 0 ? <Typography color="textSecondary" sx={{textAlign:"left", fontWeight: 'Bold', fontSize: 16, color: "#424242", pt:1, ml:1}}>{Languages.StaffNotes} </Typography> : null} 
                                    Title2  = {null}
                                    Title3  = {(SlideIndex < 5 && eindex === 0 ) || (SlideIndex >= 5 && eindex === 1) ? <Typography color="textSecondary" sx={{textAlign:"left", fontWeight: 'Bold', fontSize: 16, color: "#424242", pt:1, ml:1}}>{Languages.Evaluation} </Typography> : null}
                                    BgColor = {'#afdfc8'} 
                                    Data    = {{Text: evaluation.Text, /* Saved: Moment(evaluation.Saved).format('DD.MM.YYYY')*/}}>
                  </CommentsReadOnly>
                </Box>
              </div>
            );                  
          })}
          </div>
          }

        </Box>
      </div>
    );
  }
}

export default VasuDiscussionSubPart;
import { types } from './actionTypes';

const FileReducer = (state, action) => {
  //const payload = action.data;

  switch(action.type) {

    case types.LOADING_PREVIEW_PICTURE:
      return {
        ...state,
                loadPreviewPicture: {
                  ...state.loadPreviewPicture,
                  status: "LOADING_PREVIEW_PICTURE",
                }
      }

    case types.LOAD_PREVIEW_PICTURE_SUCCESS:

      return {
        ...state,
                  loadPreviewPicture: {
                    ...state.loadPreviewPicture,
                    status: "LOAD_PREVIEW_PICTURE_SUCCESS",
                    code: action.code,
                    savedPreviewPictures: parseResponse(action.imageId, action.data, state.loadPreviewPicture.savedPreviewPictures, true),
                  }
      }
    case types.LOAD_PREVIEW_PICTURE_FAILED:
      
      return {
        ...state,
                loadPreviewPicture: {
                  ...state.loadPreviewPicture,
                  status: "LOAD_PREVIEW_PICTURE_FAILED",
                  code: action.data.Code,
                }
      }

    case types.LOADING_FULL_IMAGE:
      return {
        ...state,
            loadFullImage: {
              ...state.loadFullImage,
              status: "LOADING_FULL_IMAGE",
            }
      }

    case types.LOAD_FULL_IMAGE_SUCCESS:
      return {
        ...state,
              loadFullImage: {
                ...state.loadFullImage,
                status: "LOAD_FULL_IMAGE_SUCCESS",
                code: action.code,
                savedFullImages: parseResponse2(action.imageId, action.data, state.loadFullImage.savedFullImages)
              }
      }

    case types.LOAD_FULL_IMAGE_FAILED:
      return {
        ...state,
              loadFullImage: {
                ...state.loadFullImage,
                status: "LOAD_FULL_IMAGE_FAILED",
                code: action.code,
              }
      }
    
    case types.DOWNLOADING_FILE:
      return {
        ...state,
          fileDownloadStats:{
            status: types.DOWNLOADING_FILE,
            response: undefined,
            fileId: action.fileId,
            downloads: action.downloads,
          }
      }
    
    case types.DOWNLOAD_FILE_SUCCESS:
      return {
        ...state,
          fileDownloadStats:{
            status: types.DOWNLOAD_FILE_SUCCESS,
            response: action.response,
            fileId: action.fileId,
            downloads: action.downloads,
          }
      }

    case types.DOWNLOAD_FILE_FAILED:
      return {
        ...state,
          fileDownloadStats:{
            status: types.DOWNLOAD_FILE_FAILED,
            response: undefined,
            fileId: action.fileId,
            downloads: action.downloads,
          }
      }
    
    default:
  }
    
  return state;
}

const parseResponse2 = (imageId, imageData, savedFullImages) => {

  const alreadyExists = savedFullImages.hasOwnProperty(imageId);

  if (alreadyExists) {
    return(savedFullImages);
  }
  else {
    savedFullImages[imageId] = imageData;
   
    return(savedFullImages);
  }

}



const parseResponse = (imageId, imageData, savedPictures, isPreviewObject) => {
  
  if (isPreviewObject === true) {

    const alreadyExists = savedPictures.hasOwnProperty(imageId);

    if (alreadyExists) {
      return(savedPictures);
    }
    else {
      savedPictures[imageId] = imageData;
      return(savedPictures);
    }

  }
}

export default FileReducer;

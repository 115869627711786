import React from "react";
import Languages from "../../translations";
import AddBookingAccordionText from "./BookingGuide/AddBookingAccordionText";
import EditBookingAccordionText from "./BookingGuide/EditBookingAccordionText";
import DefaultTimeAccordionText from "./BookingGuide/DefaultTimeAccordionText";
import AbsentBookingAccordionText from "./BookingGuide/AbsentBookingAccordionText";
import BookingSymbolsAccordionText from "./BookingGuide/BookingSymbolsAccordionText";
import AvailableCareTimeAccordionText from "./BookingGuide/AvailableCareTimeAccordionText";
import InvoiceDataAccordionText from "./BookingGuide/InvoiceDataAccordionText";
import DefaultMessagingAccordionText from "./MessagingGuide/DefaultMessagingAccordionText";
import MessageThreadAccordionText from "./MessagingGuide/MessageThreadAccordionText";
import MessagingButtonsAccordionText from "./MessagingGuide/MessagingButtonsAccordionText";
import WhatKindOfMessagesAccordionText from "./MessagingGuide/WhatKindOfMessagesAccordionText";
import PermissionRequestAccordionText from "./PermissionsGuide/PermissionRequestAccordionText";
import ParentalConsentsAccordionText from "./PermissionsGuide/ParentalConsentsAccordionText";
import ChildPermissionsAccordionText from "./PermissionsGuide/ChildPermissionsAccordionText";
import DiscussionAccordionText from "./DiscussionGuide/DiscussionAccordionText";
import AddBookingAccordionText2 from "./CaretimeCalendarGuide/AddBookingAccordionText2";
import EditBookingAccordionText2 from "./CaretimeCalendarGuide/EditBookingAccordionText2";
import DefaultTimeAccordionText2 from "./CaretimeCalendarGuide/DefaultTimeAccordionText2";
import AbsentBookingAccordionText2 from "./CaretimeCalendarGuide/AbsentBookingAccordionText2";
import BookingSymbolsAccordionText2 from "./CaretimeCalendarGuide/BookingSymbolsAccordionText2";
import AvailableCareTimeAccordionText2 from "./CaretimeCalendarGuide/AvailableCareTimeAccordionText2";
import InvoiceDataAccordionText2 from "./CaretimeCalendarGuide/InvoiceDataAccordionText2";
import FAQAccordionText from "./CaretimeCalendarGuide/FAQAccordionText";
const AccordionDetailsManager = ({ accordionDetails }) => {
  function returnCorrectAccordionDetails(accordionDetails) {
    const accordionDetailsOptions = {
      //BOOKING
      ADD_BOOKING_ACCORDION_TEXT: function () {
        return <AddBookingAccordionText />;
      },

      EDIT_BOOKING_ACCORDION_TEXT: function () {
        return <EditBookingAccordionText />;
      },

      DEFAULT_BOOKING_TIME_ACCORDION_TEXT: function () {
        return <DefaultTimeAccordionText />;
      },

      ABSENT_BOOKING_ACCORDION_TEXT: function () {
        return <AbsentBookingAccordionText />;
      },

      BOOKING_SYMBOLS_ACCORDION_TEXT: function () {
        return <BookingSymbolsAccordionText />;
      },

      AVAILABLE_CARE_TIME_ACCORDION_TEXT: function () {
        return <AvailableCareTimeAccordionText />;
      },

      INVOICE_DATA_ACCORDION_TEXT: function () {
        return <InvoiceDataAccordionText />;
      },
      //NEW BOOKING
      ADD_BOOKING_ACCORDION_TEXT2: function () {
        return <AddBookingAccordionText2 />;
      },

      EDIT_BOOKING_ACCORDION_TEXT2: function () {
        return <EditBookingAccordionText2 />;
      },

      DEFAULT_BOOKING_TIME_ACCORDION_TEXT2: function () {
        return <DefaultTimeAccordionText2 />;
      },

      ABSENT_BOOKING_ACCORDION_TEXT2: function () {
        return <AbsentBookingAccordionText2 />;
      },

      BOOKING_SYMBOLS_ACCORDION_TEXT2: function () {
        return <BookingSymbolsAccordionText2 />;
      },

      AVAILABLE_CARE_TIME_ACCORDION_TEXT2: function () {
        return <AvailableCareTimeAccordionText2 />;
      },

      INVOICE_DATA_ACCORDION_TEXT2: function () {
        return <InvoiceDataAccordionText2 />;
      },
      FAQ_TEXT: function () {
        return <FAQAccordionText />;
      },

      //MESSAGING
      DEFAULT_MESSAGING_ACCORDION_TEXT: function () {
        return <DefaultMessagingAccordionText />;
      },

      MESSAGE_THREAD_ACCORDION_TEXT: function () {
        return <MessageThreadAccordionText />;
      },

      MESSAGING_BUTTONS_ACCORDION_TEXT: function () {
        return <MessagingButtonsAccordionText />;
      },

      WHAT_KIND_OF_MESSAGES_ACCORDION_TEXT: function () {
        return <WhatKindOfMessagesAccordionText />;
      },

      //PERMISSIONS
      PERMISSION_REQUEST_ACCORDION_TEXT: function () {
        return <PermissionRequestAccordionText />;
      },

      PARENTAL_CONSENTS_ACCORDION_TEXT: function () {
        return <ParentalConsentsAccordionText />;
      },

      CHILD_PERMISSIONS_ACCORDION_TEXT: function () {
        return <ChildPermissionsAccordionText />;
      },

      //DISCUSSIONS - NOT READY
      DISCUSSION_ACCORDION_TEXT: function () {
        return <DiscussionAccordionText />;
      },

      default: function () {
        return <div>{Languages.guideNoContent}</div>;
      },
    };

    return (
      accordionDetailsOptions[accordionDetails] ||
      accordionDetailsOptions["default"]
    )();
  }

  return (
    <React.Fragment>
      {returnCorrectAccordionDetails(accordionDetails)}
    </React.Fragment>
  );
};

export default AccordionDetailsManager;

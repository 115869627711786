import React, { Component, useRef } from "react";
import { Modal, ModalBody,/*,*/ /*FormGroup, Label, Input*/ } from "reactstrap";

import Moment from 'moment';
import { StateContext /*, useStateValue*/ } from "../../State";
import { types } from '../../Reducers/actionTypes';

import { SessionManager } from "../../Auth";

import "./nursingtimecalendar.css";
import Languages from "../../translations";

import ContentBoxMUI from "../../Components/ContentBoxMUI";
import ContentBox from "../../Components/ContentBox";
import FixedBottomBar from "../../Components/FixedBottomBar";
import CalendarNav from "../../Components/CalendarNav";
import UsedCareTimesInfoDrawer from "../../Components/UsedCareTimesInfoDrawer";
import SlideConfirmationNote from "../../Components/SlideConfirmationNote";
import SnackbarComponent from '../../Components/Snackbar';
import DenseTable from '../../Components/DenseTable';

import DailyReservations from "./Components/DailyReservations";
import NewReservations from "./Components/NewReservations";
import DefaultNursingTimes from "./Components/DefaultNursingTimes";
//import ReservationContainer from "./Components/NewReservations/ReservationContainer";

import CircularProgress from "@mui/material/CircularProgress";
import SelectAll from '@mui/icons-material/SelectAll';
import AddAlarm from '@mui/icons-material/AddAlarm';
import Fab from "@mui/material/Fab";
import Check from '@mui/icons-material/Check';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import EventBusyRounded from '@mui/icons-material/EventBusyRounded';
import EuroSymbolIcon from "@mui/icons-material/EuroSymbol";
import Typography from "@mui/material/Typography";
import Backdrop from '@mui/material/Backdrop';
//import EuroWarningIcon from "../../images/eurowarning.svg";
import Warning from "@mui/icons-material/Warning";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ChildProfilePicture from '../../Components/ChildProfilePicture';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';


class NursingtimeCalendar extends Component {

  static contextType = StateContext;

  constructor(props) {
    super(props);
    this.strings = Languages;

    this.state = {
      newReservationModalOpen: false,
      DailyReservationsTargetPlace: null,
      openDefaultNursingTimes: false,
      showSlideNote: false,
      showSnackbar: false,
      SnackbarOkColor: false,
      SlideNoteTitle: null,
      NoteText: null,
      SlideNoteLeftButton: null,
      SlideNoteRightButton: null,
      selectedweek: null,
      selecteddate: null,

      selectedDays: 0,
      emptyDaysSelected: 0,
      lockeddaySelected: 0,

      WeekendsHolidaysSelected: 0,
      lockedWeekendsHolidaysSelected: 0,

      daysToBeSelected: 0,
      lockeddaysToBeSelected: 0,

      WeekendsHolidaysTobeSelected: 0,
      lockedWeekendsHolidaysTobeSelected: 0,

      infoModal: false,
      errorModal: false,

      DenseTableTitleDeleteErros: null,
      DenseTableDataDeleteErros: null,
      DenseTableTitleSaveError: null,
      DenseTableDataSaveErrors: null,
      selectedReservationsType: null,

      selectedElement: "Calendar_Loaded",

      Mode: "NEWDAILYRESERVATION",
      HideAddReservationButton: true,
      showUsedCareTimesInfoDrawer: false,
      showFixedBottombar: true,
      StartTime: Moment(new Date().setHours(8, 0, 0)),
      EndTime: Moment(new Date().setHours(16, 0, 0)),
      initDate: Moment().startOf("month"),
      prevMonthText: Moment().startOf("month").subtract(1, "months"),
      nextMonthText: Moment().startOf("month").add(1, "months"),
      timespanForInvoiceBasis: null,
      dateForLoadSummaryRefreshWhenError: null
    };
  }

  componentDidMount() {
    //const { nursingtimes } = this.context.state;
    /*console.log("NursingtimeCalendar. Mount. Read nursingtimes and default times: "+ 
                "\n location.state.readNursingTimes: ", this.props.location.state.readNursingTimes);
                /*"\n context.state: ", 
                "\n defaultNursingTimes: ", nursingtimes.defaultNursingTimes.length === 0 ? "Length = 0" :nursingtimes.defaultNursingTimes,
                "\n defaultNursingTimes.state: ", nursingtimes.defaultNursingTimes.state);*/

    if (this.props.location.state !== undefined) {
      this.props.location.state.readNursingTimes = false;
    }
    //console.log("Calendar. Mount. Load nursingtimes");
    this.context.actions.triggerLoadProfilePictures();
    this.context.actions.triggerLoadNursingTimes(this.state.initDate);
    this.context.actions.triggerLoadDefaultNursingTimes();
    //this.loadSummary();
    this.setState({ infoModal: true });
  }

  componentDidUpdate(prevProps, prevState) {
    //const { children } = this.context.state;

    if (this.props.location.state !== undefined && (this.props.location.state.readNursingTimes === true || this.context.state.nursingtimes.List === undefined)) {
      //console.log("Calendar. Update. Read nursingtimes. Mode:", this.state.Mode);
      console.log("testi123")
      //Drawer avattu ja valittu kalenteri uudestaan => refreshaa näyttö
      this.props.location.state.readNursingTimes = false;
      this.context.actions.triggerLoadNursingTimes(this.state.initDate);
      this.context.actions.triggerLoadDefaultNursingTimes();
      //this.loadSummary();
      this.setState({
        newReservationModalOpen: false,
        Mode: "NEWDAILYRESERVATION",
        DailyReservationsTargetPlace: null,
        openDefaultNursingTimes: false,
        infoModal: true
      });
    }

    this.handleDefaultNursingTimes();
    this.handleChildNursingTimes();
    this.handleSavingNursingtime();
    this.handleDeleteNursingtime();
  }

  componentDidCatch(event) {
    console.log("Calendar.componentDidCatch. VIRHE: ", event);
  }

  loadSummary = (date) => {
    console.log("loadSummary 1triggered, date", date);
    let d = date === undefined ? new Date() : new Date(date);

    //last day of the current month is the day before the first day of the next month

    //let lastDayOfCurrentMonth = new Date((d.getUTCFullYear()), (d.getUTCMonth() + 1), 0).getDate(); 
    let lastDayOfCurrentMonth = new Date(Moment(d).format("YYYY"), Moment(d).format("MM"), 0).getDate();

    /*console.log("Calendar. LoadSummary",
                "\n date: ", Moment(d).format("D"),".",Moment(d).format("M"),".",Moment(d).format("YYYY"),
                "\n lastDayOfCurrentMonth:  ",lastDayOfCurrentMonth );*/

    let startMonthZero = "";
    if ((Moment(d).format("M")/* d.getUTCMonth() + 1*/) < 10) {
      startMonthZero = "0";
    }
    let endMonthZero = "";
    if ((Moment(d).format("M") /*d.getUTCMonth() + 1*/) < 10) {
      endMonthZero = "0";
    }

    const requestedTimespan = {
      Start: (Moment(d).format("YYYY") /*d.getUTCFullYear()*/ + "-" + startMonthZero + (Moment(d).format("M")/*d.getUTCMonth() + 1*/) + "-01T00:00:00"), // first day of the current month
      End: (Moment(d).format("YYYY") /*d.getUTCFullYear()*/ + "-" + endMonthZero + (Moment(d).format("M") /*d.getUTCMonth() + 1*/) + "-" + lastDayOfCurrentMonth + "T23:59:59") // last day of the current month
    }

    const timespanForInvoiceBasis = {
      start: (Moment(d).format("YYYY") /*d.getUTCFullYear()*/ + "-" + startMonthZero + (Moment(d).format("M")/*d.getUTCMonth() + 1*/) + "-01"), // first day of the current month
      end: (Moment(d).format("YYYY") /*d.getUTCFullYear()*/ + "-" + endMonthZero + (Moment(d).format("M") /*d.getUTCMonth() + 1*/) + "-" + lastDayOfCurrentMonth) // last day of the current month
    }

    this.context.actions.triggerLoadNursingTimesSummary(requestedTimespan);

    this.setState({
      timespanForInvoiceBasis: timespanForInvoiceBasis,
      dateForLoadSummaryRefreshWhenError: d
    });
  }

  doNothing = () => {
    console.log("NursingtimeCalendar. doNothing");
  }

  handleNotSelectable = () => {
    //console.log("Calendar. handleNotSelectable. Clear calendar");
    this.setState({ DailyReservationsTargetPlace: null });
  };

  handleLockedDay = (weeknum, selectedday, e) => {
    const { WeekData } = this.context.state.nursingtimes.List;
    let week = WeekData.WeekList.find(w => w.WeekNumber === weeknum);
    let day = week.DayList.find(d => d.Date === selectedday.Date);
    //const element = e.currentTarget;
    let refKey = selectedday.Date.format("DDMMYYYY");

    var reservationCount = day.NursingTimes.length + day.Statuses.length;
    //console.log("Calendar. handleLockedDay. Selected day: ",day);

    if (this.state.showSnackbar) {
      //console.log("NursingtimeCalendar. handleLockedDay. Snackbar open. Do nothing");
    }
    else if (day.DateSelected) {
      day.DateSelected = false;
      //console.log("Calendar. handle locked day. Unselect day: ",Moment(day.Date).format('DD.MM.YYYY'));
      this.setState({
        DailyReservationsTargetPlace: null,
        lockeddaySelected: this.state.selectedDays > 0 ? 0 :
          day.WeekHoliday !== null || day.IsWeekend === true ? this.state.lockeddaySelected : this.state.lockeddaySelected - 1,
        lockedWeekendsHolidaysSelected: day.WeekHoliday !== null || day.IsWeekend === true ? this.state.lockedWeekendsHolidaysSelected - 1 : this.state.lockedWeekendsHolidaysSelected,
        emptyDaysSelected: reservationCount === 0 && this.state.emptyDaysSelected > 0 ? this.state.emptyDaysSelected - 1 : this.state.emptyDaysSelected,
        showSnackbar: this.state.selectedDays > 0 ? true : false,
        NoteText: this.state.selectedDays > 0 ? this.strings.errorUnLockedDaysOnly : null,
        selectedElement: refKey
      });
    }
    else {
      //console.log("Calendar. handle locked day. Day: ",Moment(day.Date).format('DD.MM.YYYY'), " ",this.state.selectedDays > 0 ? "ei voi valita, normipäiviä valittu" : "valittu" );
      day.DateSelected = this.state.selectedDays > 0 ? false : true;

      this.setState({
        DailyReservationsTargetPlace: null,
        lockeddaySelected: this.state.selectedDays > 0 ? 0 :
          day.WeekHoliday !== null || day.IsWeekend === true ? this.state.lockeddaySelected : this.state.lockeddaySelected + 1,
        lockedWeekendsHolidaysSelected: day.DateSelected && (day.WeekHoliday !== null || day.IsWeekend === true) ? this.state.lockedWeekendsHolidaysSelected + 1 : this.state.lockedWeekendsHolidaysSelected,
        emptyDaysSelected: this.state.selectedDays > 0 ? this.state.emptyDaysSelected :
          reservationCount === 0 ? this.state.emptyDaysSelected + 1 : this.state.emptyDaysSelected,
        showSnackbar: this.state.selectedDays > 0 ? true : false,
        NoteText: this.state.selectedDays > 0 ? this.strings.errorUnLockedDaysOnly : null,
        selectedElement: refKey
      });
    }
  };

  selectAll = (status) => {
    const { WeekList } = this.context.state.nursingtimes.List.WeekData;
    let count = 0;
    let reservationCount = 0;
    //console.log("Calendar. selectAll. Status: ",status+ "\n WeekData: ", WeekList);

    if (this.state.DailyReservationsTargetPlace) {
      //do nothing
    }
    else if (status === "SELECT") {
      let lockedDaySelected = this.state.lockedWeekendsHolidaysSelected > 0 ||
        this.state.lockeddaySelected > 0 ||
        this.state.daysToBeSelected === 0 ? true : false;
      WeekList.forEach(w => {
        w.DayList.filter(d => {
          return (d.NotSelectable === false &&
            d.DateSelected === false &&
            d.WeekHoliday === null &&
            d.IsWeekend === false)
        }).forEach(d => {

          //console.log("Calendar. SELECT ALL. Day: ", d);
          //valitse lukkopäivät, jos kaikki valittavissa olevat ovat lukkopäiviä, tai yksi lukkopäivä valittu
          if (d.IsLockedDate === lockedDaySelected ? true : false) {
            d.DateSelected = true;
            count++;
            reservationCount = d.NursingTimes.length === 0 && d.Statuses.length === 0 ? reservationCount : reservationCount + 1;
          }
        });
      });
      this.setState({
        selectedDays: lockedDaySelected ? 0 : this.state.selectedDays + count,
        lockeddaySelected: lockedDaySelected ? this.state.lockeddaySelected + count : 0,
        emptyDaysSelected: this.state.emptyDaysSelected + (count - reservationCount),
        DailyReservationsTargetPlace: null
      });
    }
    else {
      WeekList.forEach(w => {
        w.DayList.forEach(d => {
          if (d.DateSelected === true) {
            d.DateSelected = false;
          }
        });
      });
      //console.log("Calendar. selectAll. Status: ",status + "\n count: ", count, "\n selectedDays: ",this.state.selectedDays);
      this.setState({
        selectedDays: 0,
        lockeddaySelected: 0,
        emptyDaysSelected: 0,
        WeekendsHolidaysSelected: 0,
        lockedWeekendsHolidaysSelected: 0,
        Mode: "NEWDAILYRESERVATION",
        DailyReservationsTargetPlace: null
      });
    }
  };

  handleRowClick = (weeknum, selectedday, e) => {
    const { WeekData } = this.context.state.nursingtimes.List;
    var week = WeekData.WeekList.find(w => w.WeekNumber === weeknum);
    var day = week.DayList.find(d => d.Date === selectedday.Date);
    //const element = e.currentTarget;

    //console.log("NursingtimeCalendar. handleRowClick. Selected day: ",Moment(day.Date).format('DD.MM.YYYY'));
    let reservationCount = day.NursingTimes.length + day.Statuses.length;

    if (this.state.showSnackbar) {
      //console.log("NursingtimeCalendar. handleRowClick. Snackbar open. Do nothing");
    }
    else if (day.DateSelected) {
      day.DateSelected = false;
      //console.log("Calendar. handleRowClick. Unselect day: ",Moment(day.Date).format('DD.MM.YYYY'));
      this.setState({
        DailyReservationsTargetPlace: null,
        selectedDays: this.state.lockeddaySelected > 0 ? 0 :
          day.WeekHoliday !== null || day.IsWeekend === true ? this.state.selectedDays : this.state.selectedDays - 1,
        WeekendsHolidaysSelected: day.WeekHoliday !== null || day.IsWeekend === true ? this.state.WeekendsHolidaysSelected - 1 : this.state.WeekendsHolidaysSelected,
        emptyDaysSelected: reservationCount === 0 && this.state.emptyDaysSelected > 0 ? this.state.emptyDaysSelected - 1 : this.state.emptyDaysSelected,
        showSnackbar: this.state.lockeddaySelected > 0 ? true : false,
        NoteText: this.state.lockeddaySelected > 0 ? this.strings.errorLockedDaysOnly : null,
        selectedElement: Moment(day.Date).format('DDMM')
      });
    }
    else {
      //console.log("Calendar. handleRowClick. Day: ",Moment(day.Date).format('DD.MM.'), " " ,this.state.lockeddaySelected > 0 ? "Ei voi valita, kun lukituspäiviä valittu" : "Valittu");
      //console.log("Calendar. handleRowClick. Day: ",Moment(day.Date).format('DD.MM.'), " Week holiday: " ,day.WeekHoliday, " weekend day: ", day.IsWeekend);

      day.DateSelected = this.state.lockeddaySelected > 0 ? false : true;
      this.setState({
        DailyReservationsTargetPlace: null,
        selectedDays: this.state.lockeddaySelected > 0 ? 0 :
          day.WeekHoliday !== null || day.IsWeekend === true ? this.state.selectedDays : this.state.selectedDays + 1,
        WeekendsHolidaysSelected: day.DateSelected && (day.WeekHoliday !== null || day.IsWeekend === true) ? this.state.WeekendsHolidaysSelected + 1 : this.state.WeekendsHolidaysSelected,
        emptyDaysSelected: this.state.lockeddaySelected > 0 ? this.state.emptyDaysSelected :
          reservationCount === 0 ? this.state.emptyDaysSelected + 1 : this.state.emptyDaysSelected,
        showSnackbar: this.state.lockeddaySelected > 0 ? true : false,
        NoteText: this.state.lockeddaySelected > 0 ? this.strings.errorLockedDaysOnly : null,
        selectedElement: Moment(day.Date).format('DDMM')
      });
    }
  };

  showDailyReservations = (date, e, weeknum) => {
    const element = e.currentTarget;

    //const { WeekData } = this.context.state.nursingtimes.List;
    //var week = WeekData.WeekList.find(w => w.WeekNumber === weeknum);
    //var day = week.DayList.find(d => d.Date === date);
    //day.DateSelected = true;

    /*console.log("Calendar. Näytä päivän varaukset"+
                "\n Selected week: ", week,
                "\n Selected day: ", day,
                "\n All selected weekdays: ", week.DayList.filter(d => d.DateSelected === true) );*/

    // ensin state nulliksi, sitten uusi element arvo perään
    this.setState({ DailyReservationsTargetPlace: null },
      () => {
        this.setState({
          DailyReservationsTargetPlace: element,
          selectedweek: weeknum,
          selecteddate: date
        });
      });
  };

  makeReservation = () => {
    //const { ChildList } = this.context.state.children;
    //const { WeekData } = this.context.state.nursingtimes.List;

    /*console.log("Calendar. Make reservation."+
                "\n selectedDays: ", this.state.selectedDays,
                "\n WeekendsHolidaysSelected: " + this.state.WeekendsHolidaysSelected+
                "\n lockeddaySelected: " , this.state.lockeddaySelected,
                "\n lockedWeekendsHolidaysSelected: " +this.state.lockedWeekendsHolidaysSelected);*/

    if (this.state.DailyReservationsTargetPlace) {
      //do nothing
    }
    else {
      this.setState({
        newReservationModalOpen: true,
        HideAddReservationButton: this.state.lockeddaySelected > 0 || this.state.lockedWeekendsHolidaysSelected > 0 ? true :
          this.state.selectedDays > 0 || this.state.WeekendsHolidaysSelected > 0 &&
            this.state.selectedDays + this.state.WeekendsHolidaysSelected === this.state.emptyDaysSelected ? false : true,
        Mode: this.state.selectedDays > 0 || this.state.WeekendsHolidaysSelected > 0 ? "NEWDAILYRESERVATION" : "NEWLOCKEDDAYABSENT",
        DailyReservationsTargetPlace: null,
        showFixedBottombar: false
      });
    }
  };

  handleChildNursingTimes = () => {
    const { WeekData } = this.context.state.nursingtimes.List;
    const { List } = this.context.state.nursingtimes;
    
    /*console.log("Calendar. handleChildNursingTimes: "+
                "\n List.state: ", List.state,
                "\n List.Code: ", List.Code+
                "\n List.WeekData: ", List.WeekData);*/

    switch (List.state) {
      case types.READING_NURSINGTIMES:
        //console.log("Calendar. READING_NURSINGTIMES");
        break;
      case types.NURSINGTIMES_READ:
        this.context.actions.triggerNursingTimesReadHandled();
        this.loadSummary(this.state.initDate);
        //console.log("Calendar. NURSINGTIMES_READ. WeekData: ", List.WeekData);
        //Unselect all days
        WeekData.WeekList.forEach(week => { week.DayList.forEach(d => { d.DateSelected = false; }); });
        let count = 0;
        WeekData.WeekList.forEach(week => {
          week.DayList.forEach(d => {
            if (d.NotSelectable === false && d.IsLockedDate === false && (d.WeekHoliday !== null || d.IsWeekend === true)) { count++; }
          });
        });
        let weekendsAndmiddleweekHolidaysTobeSelected = count;
        count = 0;
        WeekData.WeekList.forEach(week => {
          week.DayList.forEach(d => {
            if (d.NotSelectable === false && d.IsLockedDate === true && (d.WeekHoliday !== null || d.IsWeekend === true)) { count++; }
          });
        });
        let lockedWeekendsAndmiddleweekHolidaysTobeSelected = count;
        this.setState({
          showFixedBottombar: true,
          infoModal: false,
          selectedDays: 0,
          lockeddaySelected: 0,
          WeekendsHolidaysSelected: 0,
          WeekendsHolidaysTobeSelected: weekendsAndmiddleweekHolidaysTobeSelected,
          lockedWeekendsHolidaysTobeSelected: lockedWeekendsAndmiddleweekHolidaysTobeSelected,
          daysToBeSelected: List.WeekData.DaysToBeSelected - weekendsAndmiddleweekHolidaysTobeSelected,
          lockeddaysToBeSelected: List.WeekData.LockedDaysToBeSelected - lockedWeekendsAndmiddleweekHolidaysTobeSelected
        });
        break;
      case types.NURSINGTIMES_FAILED:
        this.context.actions.triggerNursingTimesFailedHandled();
        //console.log("Calendar. NURSINGTIMES_FAILED");

        this.setState({
          infoModal: false,
          errorModal: true,
          NoteText: List.Code === 400 ? this.strings.bookingCareHoursTitle + "\n" + this.strings.errorBadRequest :
            List.Code === 404 ? this.strings.bookingCareHoursTitle + "\n" + this.strings.errorNotFound :
              List.Code === 500 ? this.strings.bookingCareHoursTitle + "\n" + this.strings.errorInDB :
                List.Code === 502 ? this.strings.bookingCareHoursTitle + "\n" + this.strings.errorNetwork :
                                    /*List.Code === 666 */ this.strings.bookingCareHoursTitle + "\n" + this.strings.errorUnknown
        });
        break;
      default:
        //console.log("Calendar. NURSINGTIMES DEFAULT: ", List.state, "\n WeekData: ",List.WeekData);
        break;
    }
  };

  handleDeleteNursingtime = () => {
    const { Deleted } = this.context.state.nursingtimes;

    /*console.log("Calendar. Handle delete: "+
                "\n State: ",Deleted === undefined ? undefined : Deleted.state+
                "\n Data: ", Deleted === undefined ? undefined : Deleted.Data);*/

    switch (Deleted.state) {
      case types.DELETING_NURSINGTIMES:
        //console.log("Calendar. DELETING_NURSINGTIMES");
        break;
      case types.NURSINGTIMES_DELETED_OK:
        //console.log("Calendar. NURSINGTIMES_DELETED_OK. Deleted.Data: ", Deleted.Data);
        this.context.actions.triggerDeleteNursingtimesHandled();
        this.context.actions.triggerLoadNursingTimes(this.state.initDate);
        // Reload summary
        this.loadSummary(this.state.initDate);
        if (!this.context.state.smallRequests.vacationNotificationStatus.data.ShowNotification) {
          this.context.actions.triggerLoadVacationNotificationStatus();
        }
        this.setState({
          infoModal: true,
          showSnackbar: true,
          SnackbarOkColor: true,
          NoteText: Deleted.Data.Code === null ? Languages.bookingBookingsDeleted : /*Deleted.Data.Code === -2*/ Languages.errorDelBooking
        });
        break;
      case types.NURSINGTIMES_DELETED_FAILED:
        //console.log("Calendar. NURSINGTIMES_DELETED_FAILED. Deleted: ", Deleted);
        this.context.actions.triggerDeleteNursingtimesHandled();
        this.setState({
          infoModal: false,
          showSnackbar: true,
          showFixedBottombar: true,
          NoteText: Deleted.Code === 400 ? Deleted.Message === undefined ? this.strings.errorBadRequest : Deleted.Message :
            Deleted.Code === 404 ? this.strings.errorNotFound :
              Deleted.Code === 409 ? this.strings.errorDelBooking : //"Varaus lukitusajalla" :
                Deleted.Code === 500 ? this.strings.errorInDB :
                  Deleted.Code === 502 ? this.strings.errorNetwork :
                                    /* Saved.Code === 666*/ this.strings.errorUnknown
        });
        break;
      default:
        //console.log("Calendar. NURSINGTIMES DELETE DEFAULT state: ", Deleted.state);
        break;
    }
  };

  handleSavingNursingtime = () => {
    const { children } = this.context.state;
    const { Saved } = this.context.state.nursingtimes;
    const { WeekData } = this.context.state.nursingtimes.List;
    const { defaultNursingTimes } = this.context.state.nursingtimes;

    
    /*console.log("Calendar. Handle saving nursingtimes: "+
                "\n Saved.state: "+Saved.state+
                "\n Saved", Saved);*/

    switch (Saved.state) {
      case types.SAVING_NURSINGTIMES:
        //console.log("Calendar. SAVING_NURSINGTIMES");
        break;
      case types.NURSINGTIMES_SAVED_OK:
        this.context.actions.triggerSaveOkNursingTimesHandled();
        //console.log("Calendar. NURSINGTIMES_SAVED_OK. Saved: ", Saved);
        // Reload summary
        this.loadSummary(this.state.initDate);
        //load vacation notification status
        let showNotification = this.context.state.smallRequests.vacationNotificationStatus.data.ShowNotification
        if (typeof showNotification === "undefined" || showNotification) {
          this.context.actions.triggerLoadVacationNotificationStatus();
        }
        //Re-select all children
        children.ChildList.forEach(c => { c.Checked = true; });
        //Removed selection
        WeekData?.WeekList.forEach(week => { week.DayList.forEach(d => { d.DateSelected = false; }); });

        let DenseTableDataDeleteErros = [];
        let DenseTableDataItemDeleteErros = [];
        let DenseTableDataSaveErros = [];
        let DenseTableDataItemSaveErros = [];

        //näytä virhenote vain, jos tehty varaus ja tallennus/poistovirheitä tai poissaololle pelkästään tallennusvirhe
        if (Saved.Data === null ||
          // poissaololle ei virheitä tai ainoastaan poistovirheitä -> ei näytetä virhenotea
          (this.state.selectedReservationsType !== "I" && (Saved.Data.SaveErros === null || Saved.Data.SaveErros.length === 0))) {
          //console.log("Calendar. NURSINGTIMES SAVED OK. Load NextNursingTimes");
          Saved.Data = null;
          this.context.actions.triggerLoadNursingTimes(this.state.initDate);
          this.context.actions.triggerLoadNextNursingTimes();
        }
        else {
          //console.log("Calendar. NURSINGTIMES SAVED PARTIALLY OK. Saved.Data: ", Saved.Data);//+
          //          "\n selectedReservationsType: ", this.state.selectedReservationsType);

          if (Saved.Data.DeleteErros !== null && Saved.Data.DeleteErros.length > 0 && this.state.selectedReservationsType === "I") {
            // New Grouped errors
            Saved.Data.DeleteGroupedErros.forEach(err => {
              DenseTableDataItemDeleteErros = [];
              let NameString = "";
              err.ChildNames.forEach((child, cindex) => {
                NameString += cindex === err.ChildNames.length - 1 ? child : child + " , ";
              });
              DenseTableDataItemDeleteErros.push({
                Colum1: NameString,
                Colum2: null,
                Colum3: null
              });

              DenseTableDataDeleteErros.push({
                Date: Moment(err.ErrorDate).format("DD.MM.YYYY"),
                Title: err.ErrorString,
                Items: DenseTableDataItemDeleteErros
              })
            });
          }

          if (Saved.Data.SaveErros !== null && Saved.Data.SaveErros.length > 0) {
            //console.log("Calendar. NURSINGTIMES SAVED PARTIALLY OK. Saved errors: ", Saved.Data);
            //console.log("Calendar. NURSINGTIMES SAVED PARTIALLY OK. Saved Grouped errors: ", Saved.Data.SaveGroupedErros);
            //subjective child
            if (Saved.Data.SavedCode === -4) {
              /*DenseTableDataItemSaveErros.push({	//PersonID: null,
                                                  Colum1: Saved.Data.SaveErros[0].ChildName,
                                                  Colum2: Saved.Data.SaveErros[0].ErrorString,
                                                  Colum3: null });
              DenseTableDataSaveErros.push({ Date:  null, //subjetive 20h error. No date info
                                             Title: null,
                                             Items: DenseTableDataItemSaveErros});*/
              // New Grouped errors
              Saved.Data.SaveGroupedErros.forEach(err => {

                DenseTableDataItemSaveErros = [];
                let NameString = "";
                err.ChildNames.forEach((child, cindex) => {
                  NameString += cindex === err.ChildNames.length - 1 ? child : child + " , ";
                });
                DenseTableDataItemSaveErros.push({
                  Colum1: NameString,
                  Colum2: err.ErrorString,
                  Colum3: null
                });

                DenseTableDataSaveErros.push({
                  Date: null,
                  Title: null,
                  Items: DenseTableDataItemSaveErros
                })
              });
            }
            else // Saved.Data.SavedCode === -2
            {
              // New Grouped errors
              Saved.Data.SaveGroupedErros.forEach(err => {

                DenseTableDataItemSaveErros = [];
                let NameString = "";
                err.ChildNames.forEach((child, cindex) => {
                  NameString += cindex === err.ChildNames.length - 1 ? child : child + " , ";
                });
                DenseTableDataItemSaveErros.push({
                  Colum1: NameString,
                  Colum2: null,
                  Colum3: null
                });

                DenseTableDataSaveErros.push({
                  Date: Moment(err.ErrorDate).format("DD.MM.YYYY"),
                  Title: err.ErrorString,
                  Items: DenseTableDataItemSaveErros
                })
              });
            }
          }
        }

        /*console.log("Calendar. NURSINGTIMES SAVED PARTIALLY OK."+
                    "\n DenseTableDataDeleteErros: ", DenseTableDataDeleteErros+
                    "\n DenseTableDataSaveErros: ",   DenseTableDataSaveErros);*/

        this.setState({
          showFixedBottombar: true,
          infoModal: Saved.Data === null ? true : false,

          showSnackbar: Saved.Data === null ? true : false,
          SnackbarOkColor: true,
          NoteText: Languages.bookingBookingsSaved,

          newReservationModalOpen: false,
          StartTime: defaultNursingTimes.Start,
          EndTime: defaultNursingTimes.End,
          Mode: "NEWDAILYRESERVATION",

          errorModal: Saved.Data === null ? false : true,

          DenseTableTitleDeleteErros: Saved.Data === null || Saved.Data.DeleteErros === null || Saved.Data.DeleteErros.length === 0 || this.state.selectedReservationsType !== "I" ?
            null : Languages.bookingAllCouldNOtBeDeleted + Languages.bookingErrorDeleteDetails,
          DenseTableDataDeleteErros: DenseTableDataDeleteErros,

          DenseTableTitleSaveError: Saved.Data === null || Saved.Data.SaveErros === null || Saved.Data.SaveErros.length === 0 ?
            null :
            Saved.Data.DeleteErros === null || Saved.Data.DeleteErros.length === 0 ?
              Saved.Data.SavedCode === -4 ? Languages.bookingSavingFailedPartially + Languages.bookingErrorSaving : Languages.bookingSavingFailedPartially + Languages.bookingErrorSavingDetailed :
              Saved.Data.SavedCode === -4 ? Languages.bookingErrorSaving : Languages.bookingErrorSavingDetailed,
          DenseTableDataSaveErrors: DenseTableDataSaveErros
        });
        break;
      case types.NURSINGTIMES_SAVED_FAILED:
        this.context.actions.triggerSaveFailedNursingTimesHandled();
        WeekData.WeekList.forEach(week => { week.DayList.forEach(d => { d.DateSelected = false; }); });
        children.ChildList.forEach(c => { c.Checked = true; });

        console.log("Calendar. NURSINGTIMES_SAVED_FAILED. Saved: ", Saved);
        this.setState({
          showFixedBottombar: true,
          infoModal: false,
          showSnackbar: true,
          NoteText: Saved.Code === 400 ? Saved.Message === null ? this.strings.bookingErrorBadRequest : Saved.Message :
            Saved.Code === 404 ? this.strings.errorNotFound :
              Saved.Code === 500 ? this.strings.errorInDB :
                Saved.Code === 502 ? this.strings.errorNetwork :
                                    /* Saved.Code === 666*/ this.strings.errorUnknown
        });
        break;
      default:
        //console.log("Calendar. NURSINGTIMES SAVED DEFAULT: ", Saved.state);
        break;
    }
  };

  handleDefaultNursingTimes = () => {
    const { defaultNursingTimes } = this.context.state.nursingtimes;
    const { List } = this.context.state.nursingtimes;

    /*console.log("Calendar. handleDefaultNursingTimes: "+
                "\n defaultNursingTimes: ", defaultNursingTimes.length === 0 ? "Length = 0" :defaultNursingTimes,
                "\n defaultNursingTimes.state: ", defaultNursingTimes.state);*/

    switch (defaultNursingTimes.state) {
      case types.LOADING_DEFAULT_NURSINGTIMES:
      case types.SAVING_DEFAULT_NURSINGTIMES:
        //console.log("Calendar. LOADING_DEFAULT_NURSINGTIMES");
        break;
      case types.LOAD_FAILED_DEFAULT_NURSINGTIMES:
        this.context.actions.triggerDefaultNursingTimesHandled();
        //console.log("Calendar. LOAD_FAILED_DEFAULT_NURSINGTIMES");
        this.setState({
          showSnackbar: true,
          StartTime: Moment(new Date().setHours(8, 0, 0)),
          EndTime: Moment(new Date().setHours(16, 0, 0)),
          NoteText: defaultNursingTimes.Code === 404 ? this.strings.bookingCareHoursDefaultMissingInfo :
            defaultNursingTimes.Code === 500 ? this.strings.bookingCareHoursDefaultTitle + "\n" + this.strings.errorInDB + "\n" + this.strings.bookingCareHoursDefaultUse :
              defaultNursingTimes.Code === 502 ? this.strings.bookingCareHoursDefaultTitle + "\n" + this.strings.errorNetwork + "\n" + this.strings.bookingCareHoursDefaultUse :
                this.strings.bookingCareHoursDefaultTitle + "\n" + this.strings.errorUnknown + "\n" + this.strings.bookingCareHoursDefaultUse
        });
        break;
      case types.LOAD_SUCCESS_DEFAULT_NURSINGTIMES:
        this.context.actions.triggerDefaultNursingTimesHandled();
        this.setState({
          StartTime: defaultNursingTimes.Start,
          EndTime: defaultNursingTimes.End,
          infoModal: List.state === types.NURSINGTIMES_READ_HANDLED || List.state === types.NURSINGTIMES_FAILED_HANDLED ? false : true
        });
        break;
      case types.SAVING_FAILED_DEFAULT_NURSINGTIMES:
        this.context.actions.triggerDefaultNursingTimesHandled();
        this.setState({
          showSnackbar: true,
          NoteText: defaultNursingTimes.Code === 404 ? this.strings.bookingCareHoursDefaultTitle + "\n" + this.strings.errorNotFound :
            defaultNursingTimes.Code === 500 ? this.strings.bookingCareHoursDefaultTitle + "\n" + this.strings.errorInDB :
              defaultNursingTimes.Code === 502 ? this.strings.bookingCareHoursDefaultTitle + "\n" + this.strings.errorNetwork :
                this.strings.errorUnknown
        });
        break;
      case types.SAVING_SUCCESS_DEFAULT_NURSINGTIMES:
        this.context.actions.triggerDefaultNursingTimesHandled();
        //console.log("Default Nursingtimes. SAVING_SUCCESS_DEFAULT_NURSINGTIMES");
        this.setState({
          StartTime: defaultNursingTimes.Start,
          EndTime: defaultNursingTimes.End,
          showSnackbar: true,
          SnackbarOkColor: true,
          NoteText: this.strings.generalDataSaved
        });
        break;
      default:
        break;
    }
  };

  handleDefaultNursingTimesCB = (status, data) => {
    //const { defaultNursingTimes } = this.context.state.nursingtimes;

    /*console.log("Calendar. handleDefaultNursingTimesCB. status: ",status, " data: ", data+
                "\n defaultNursingTimes.state: ", defaultNursingTimes.state);*/

    if (status === "CLOSECOMPONENT") {
      // this.context.actions.triggerLoadDefaultNursingTimes();
    }
    else if (status === "SAVINGDEFAULTTIMES") {
      this.context.actions.triggerSaveDefaultNursingTimes(data);
    }

    this.setState({ openDefaultNursingTimes: false });
  };

  openDefaultNursingTimes = () => {
    this.setState({
      openDefaultNursingTimes: this.state.DailyReservationsTargetPlace ? false : true,
      DailyReservationsTargetPlace: this.state.DailyReservationsTargetPlace ? this.state.DailyReservationsTargetPlace : null
    });
  };

  openUsedCareTimesInfoDrawer = () => {
    this.setState({
      showUsedCareTimesInfoDrawer: this.state.DailyReservationsTargetPlace ? false : true
    })
  }

  closeUsedCareTimesInfoDrawer = () => {
    this.setState({
      showUsedCareTimesInfoDrawer: false
    })
  }

  onMonthChange = (status) => {
    const { List } = this.context.state.nursingtimes;

    /*console.log("NursingtimeCalendar. Month change. Status: ", status,
                "\n List.state: ", List.state);
                "\n this.state.initDate: ", this.state.initDate.format("DD.MM.YYYY"));*/

    if (List.state === types.READING_NURSINGTIMES || this.state.DailyReservationsTargetPlace) {
      //console.log("NursingtimeCalendar. Month change. Reading ongoing or dailyreservations open. Do nothing");
    }
    else if (status === "PREVIOUS") {
      //this.state.initDate.subtract(1, "months") already sets new state value, so no need to subtract/add again in setstate
      this.context.actions.triggerLoadNursingTimes(this.state.initDate.subtract(1, "months"));
      //this.loadSummary(this.state.initDate);

      this.setState({
        DailyReservationsTargetPlace: null,
        infoModal: true,
        Mode: "NEWDAILYRESERVATION",
        showFixedBottombar: false,
        selectedDays: 0,
        lockeddaySelected: 0,
        emptyDaysSelected: 0,
        selectedElement: "Calendar_Loaded",
        //initDate:      Moment(this.state.initDate.subtract(1, "months")),
        prevMonthText: Moment(this.state.prevMonthText.subtract(1, "months")),
        nextMonthText: Moment(this.state.nextMonthText.subtract(1, "months"))
      });
    }
    else {
      this.context.actions.triggerLoadNursingTimes(this.state.initDate.add(1, "months"));
      //this.loadSummary(this.state.initDate);

      this.setState({
        DailyReservationsTargetPlace: null,
        Mode: "NEWDAILYRESERVATION",
        infoModal: true,
        showFixedBottombar: false,
        selectedDays: 0,
        lockeddaySelected: 0,
        emptyDaysSelected: 0,
        selectedElement: "Calendar_Loaded",
        //initDate:      Moment(this.state.initDate.add(1, "months")),
        prevMonthText: Moment(this.state.prevMonthText.add(1, "months")),
        nextMonthText: Moment(this.state.nextMonthText.add(1, "months"))
      });
    }
  };

  selectToday = () => {
    this.context.actions.triggerLoadNursingTimes(Moment().startOf("month"));
    this.setState({
      initDate: Moment().startOf("month"),
      DailyReservationsTargetPlace: null,
      selectedDays: 0,
      infoModal: true
    });
  };

  selectWeek = (weeknum) => {
    const { WeekData } = this.context.state.nursingtimes.List;
    let week = WeekData.WeekList.find(w => w.WeekNumber === weeknum);
    let days = week.DayList;

    let overallselectedableDays = days.filter((d, index) => {
      return (d.NotSelectable === false /*&& index < 5*/)
    });
    let lockeddayselectable = overallselectedableDays.filter(d => { return d.IsLockedDate === true });
    let alllockeddays = overallselectedableDays.length === lockeddayselectable.length ? true : false;

    let selectedableDays = this.state.lockeddaySelected > 0 || (alllockeddays && this.state.selectedDays === 0) ? lockeddayselectable :
      overallselectedableDays.filter(d => { return d.IsLockedDate === false });
    selectedableDays = selectedableDays.filter(d => { return d.WeekHoliday === null && d.IsWeekend === false });

    let selectedDays = selectedableDays.filter(d => {
      return (d.DateSelected === true)
    });
    let unselectedDays = selectedableDays.filter(d => {
      return (d.DateSelected === false)
    });

    /*console.log("Calendar. select week: "+weeknum+
                "\n kaikkiaan valittavissa: " ,overallselectedableDays.length,
                "\n valittavissa: ",selectedableDays.length," valittu: ", selectedDays.length," ei valittu: ",unselectedDays.length );*/

    let daySelected = 0;
    let lockdaySelected = 0;
    let emptyCount = 0;

    if (selectedableDays.length === 0) {
      //console.log("Calendar. Nothing to select from the week. Show error note, if tried to select wrong day");
      this.setState({
        showSnackbar: overallselectedableDays.length === 0 ? false : true,
        NoteText: this.state.lockeddaySelected > 0 ? this.strings.errorLockedDaysOnly :
          this.state.selectedDays > 0 ? this.strings.errorUnLockedDaysOnly : "Arkipyhät/vko-lopun päivät valittava yksitellen"
      });
    }
    else if (selectedableDays.length > selectedDays.length) {
      //console.log("Calendar. selectWeek. Select rest of the days");
      unselectedDays.forEach(d => {
        d.DateSelected = true;
        daySelected = d.IsLockedDate ? 0 : daySelected + 1;
        lockdaySelected = d.IsLockedDate ? lockdaySelected + 1 : 0;
        emptyCount = d.NursingTimes.length === 0 && d.Statuses.length === 0 ? emptyCount + 1 : emptyCount;
      });
      this.setState({
        selectedDays: this.state.selectedDays + daySelected,
        lockeddaySelected: this.state.lockeddaySelected + lockdaySelected,
        emptyDaysSelected: this.state.emptyDaysSelected + emptyCount
      });
    }
    else //if (selectedableDays.length === selectedDays.length )
    {
      //console.log("Calendar. selectWeek. Unselect all");
      overallselectedableDays.filter(d => { return d.DateSelected === true }).forEach(d => {
        d.DateSelected = false;
        daySelected = d.IsLockedDate ? 0 : daySelected - 1;
        lockdaySelected = d.IsLockedDate ? lockdaySelected - 1 : 0;
        emptyCount = d.NursingTimes.length === 0 && d.Statuses.length === 0 ? emptyCount + 1 : emptyCount;
      });
      this.setState({
        selectedDays: this.state.selectedDays + daySelected,
        lockeddaySelected: this.state.lockeddaySelected + lockdaySelected,
        emptyDaysSelected: this.state.emptyDaysSelected > 0 ? this.state.emptyDaysSelected - emptyCount : 0
      });
    }
  };

  closeErrorModal = (status) => {
    const { List } = this.context.state.nursingtimes;
    const { Saved } = this.context.state.nursingtimes;

    /*console.log("Calendar. Close error note: "+
                "\n reading state: ", List.state,
                "\n saving state: ", Saved.state );*/

    if (List.state === types.NURSINGTIMES_FAILED_HANDLED) {
      //console.log("Calendar. Close note. NURSINGTIMES_FAILED_HANDLED. Go main page");
      this.props.history.push("/");
    }
    else if (Saved.state === types.NURSINGTIMES_SAVED_OK_HANDLED) {
      //console.log("Calendar. Close note. SAVED_OK_HANDLED. Read nursingtimes");
      this.context.actions.triggerLoadNursingTimes(this.state.initDate);
      this.context.actions.triggerLoadNextNursingTimes();

      this.setState({ infoModal: true, errorModal: false, DenseTableTitleDeleteErros: null, DenseTableTitleSaveError: null });
    }
    else {
      //console.log("Calendar. Close note. Default");
      this.setState({ errorModal: false, showFixedBottombar: true, DenseTableTitleDeleteErros: null, DenseTableTitleSaveError: null });
    }
  };

  deleteReservations = (status) => {
    let reserveddays = [];
    let selectedChilds = [];
    const { WeekData } = this.context.state.nursingtimes.List;
    const { children } = this.context.state;
    WeekData.WeekList.forEach(week => {
      week.DayList.forEach(day => {
        if (day.DateSelected && (day.NursingTimes.length > 0 || day.Statuses.length > 0)) {
          reserveddays.push(Moment(day.Date).toISOString(true));
        }
      });
    });

    children.ChildList.forEach(child => {
      if (child.Checked === true) {
        selectedChilds.push(child.Id);
      }
    });

    let DeleteData = {
      ChildIds: selectedChilds,
      ListDatesToDelete: reserveddays
    };
    this.context.actions.triggerDeleteNursingtimesMany(DeleteData);

    this.setState({ infoModal: true, showFixedBottombar: false });
  };

  confirmAction = (status) => {

    /*console.log("Calendar. confirmAction: status: ", status, " Mode: ", this.state.Mode+
                "\n selectedDays: ", this.state.selectedDays, " lockeddaySelected: ", this.state.lockeddaySelected);*/

    this.setState({
      Mode: status === "DELETE" ? "DELETERESERVATIONS" :
        this.state.selectedDays > 0 ? "NEWDAILYRESERVATION" : "NEWLOCKEDDAYABSENT",
      showSlideNote: true,
      SlideNoteTitle: status === "DELETE" ? Languages.bookingDeleteBookingTitle : Languages.bookingAddBookingTitle,
      NoteText: status === "DELETE" ? Languages.bookingDeleteBookingInfo :
        Languages.bookingAddBookingInfo,
      SlideNoteLeftButton: status === "DELETE" ? Languages.bookingDeleteBookingLeftBtn : Languages.bookingAddBookingLeftBtn,
      SlideNoteRightButton: status === "DELETE" ? Languages.bookingDeleteBookingRightBtn : Languages.bookingAddBookingRightBtn
    });
  };

  handleSlideConfirmationNoteCB = (status) => {

    /*console.log("Calendar. handleSlideConfirmationNoteCB."+
                "\n status: ", status,
                "\n Mode: ", this.state.Mode+ 
                "\n selectedDays: ", this.state.selectedDays, 
                "\n WeekendsHolidaysSelected: ", this.state.WeekendsHolidaysSelected,
                "\n emptyDaysSelected: ", this.state.emptyDaysSelected,
                "\n lockeddaySelected: ", this.state.lockeddaySelected );*/

    if (status === "CLOSE") { this.setState({ showSlideNote: false, Mode: "NEWDAILYRESERVATION", HideAddReservationButton: false }); }
    else if (status === "LEFT") {
      if (this.state.Mode === "DELETERESERVATIONS") //BACK
      { this.setState({ showSlideNote: false, Mode: "NEWDAILYRESERVATION" }); }
      else //NEW RESERVATION
      {
        this.makeReservation();
        this.setState({
          showSlideNote: false,
          Mode: this.state.lockeddaySelected > 0 ? "NEWLOCKEDDAYABSENT" : "NEWDAILYRESERVATION"
        });
      }
    } // "RIGHT"
    else {
      if (this.state.Mode === "DELETERESERVATIONS") // DELETE
      {
        this.deleteReservations();
        this.setState({ showSlideNote: false });
      }
      else // KEEP
      {
        this.makeReservation();
        this.setState({
          showSlideNote: false,
          Mode: this.state.lockeddaySelected > 0 ? "KEEPLOCKEDDAYABSENT" : "KEEPDAILYRESERVATION"
        });
      }
    }
  };

  handleNewReservation = (status, ListOfChildsInReservations, ListOfReservationsTimes, type, eveningPreSchool) => {
    const { children } = this.context.state;
    const { WeekData } = this.context.state.nursingtimes.List;
    //const { defaultNursingTimes } = this.context.state.nursingtimes;

    /*console.log("Calendar. handle new reservation callback: status: ",status, " Mode: ", this.state.Mode+
                "\n ListOfChildsInReservations: ", ListOfChildsInReservations,
                "\n ListOfReservationsTimes:", ListOfReservationsTimes,
                "\n type: ", type );*/

    let reservationsChilds = [];

    if (status === "SAVENEW") {
      let selectedChildren = [];
      ListOfChildsInReservations.forEach(childList => {
        selectedChildren = childList.filter(c => { return (c.Checked === true) })
          .map((ci, ciIndex) => {

            //let pl = ciIndex === 0 ? ci.Placements.find(pl => pl.Checked === true) : null;
            let pl = ci.Placements.find(pl => pl.Checked === true);
            return ({
              Id: ci.Id,
              Name: ci.FirstName,
              PLPrio: pl === null || pl === undefined ? null : pl.Priority,
              PlId: pl === null || pl === undefined ? null : pl.Id,
              PlName: pl === null || pl === undefined ? null : pl.Group === null ? pl.Centre : pl.Centre + "-" + pl.Group
            });
          });
        reservationsChilds.push(selectedChildren);
      });

      var selectedDates = [];
      // array-callback-return
      WeekData.WeekList.forEach(week => { week.DayList.filter(d => { if (d.DateSelected === true) { selectedDates.push({ Date: d.Date }); } }); });

      /*console.log("SAVE. Mode: ",this.state.Mode,
                  "\nselectedDays: ", this.state.selectedDays, " WeekendsHolidayselected: ", this.state.WeekendsHolidaysSelected, " emptyDaysSelected: ", this.state.emptyDaysSelected+
                  "\nlockeddaySelected: ", this.state.lockeddaySelected+ " lockedWeekendsHolidayselected: " +this.state.lockedWeekendsHolidaysSelected);*/

      let DeletedOld = this.state.Mode === "KEEPDAILYRESERVATION" || this.state.Mode === "KEEPLOCKEDDAYABSENT" ? false :
        this.state.Mode === "NEWDAILYRESERVATION" && ((this.state.selectedDays + this.state.WeekendsHolidaysSelected) === this.state.emptyDaysSelected) ? false :
          this.state.Mode === "NEWLOCKEDDAYABSENT" && ((this.state.lockeddaySelected + this.state.WeekendsHolidaysSelected) === this.state.emptyDaysSelected) ? false : true;

      /*console.log("Calendar. Handle new reservation. Child & placement data: ",reservationsChilds+
                  "\n this.state.Mode: ", this.state.Mode+
                  "\n DeletedOld: " + DeletedOld);*/
      //console.log({ reservationsChilds, selectedDates, ListOfReservationsTimes, type, DeletedOld });
      let Types = [];
      Types.push(type);
      this.context.actions.triggerSaveNursingTimes({ reservationsChilds, selectedDates, ListOfReservationsTimes, Types, DeletedOld, eveningPreSchool});
      this.setState({
        selectedDays: 0,
        lockeddaySelected: 0,
        emptyDaysSelected: 0,
        WeekendsHolidaysSelected: 0,
        lockedWeekendsHolidaysSelected: 0,
        newReservationModalOpen: false,
        infoModal: true,
        selectedReservationsType: type,
        showFixedBottombar: false
      });
      
    }
    else // CLOSE
    {
      WeekData.WeekList.forEach(week => { week.DayList.forEach(d => { d.DateSelected = false; }); });
      children.ChildList.forEach(c => { c.Checked = true; });

      //remove all selections
      this.setState({
        Mode: "NEWDAILYRESERVATION",
        selectedDays: 0,
        lockeddaySelected: 0,
        emptyDaysSelected: 0,
        WeekendsHolidaysSelected: 0,
        lockedWeekendsHolidaysSelected: 0,
        newReservationModalOpen: false,
        showFixedBottombar: true,
        selectedReservationsType: null,
        SnackbarOkColor: status === "CLOSE_NONE_VALID_PLACEMENTS" ? false : true,
        showSnackbar: status === "CLOSE_NONE_VALID_PLACEMENTS" ? true : false,
        NoteText: status === "CLOSE_NONE_VALID_PLACEMENTS" ? Languages.bookingErrorNoValidPlacement : null
      });
    }
  };

  closeNursingtimeCalendar = (status) => {
    //console.log("NursingtimeCalendar. Close calendar. status: ", status);
    this.props.callback(status, null, null, null);
  };

  handleCBDailyReservations = (status, CBData) => {
    const { ChildList } = this.context.state.children;
    const { WeekData } = this.context.state.nursingtimes.List;
    var week = WeekData.WeekList.find(w => w.WeekNumber === this.state.selectedweek);
    var day = week.DayList.find(d => d.Date === this.state.selecteddate);

    //console.log("Calendar. handleCBDailyReservations. status: ", status, " Selected day: ", day, " CB Data: ", CBData);

    if (status === "CLOSEDAILYRESERVATION") {
      //(console.log("Calendar. handleCBDailyReservations. Selected day: ", day);// Select childs: ", childIds);
    }
    else if (status === "NEWDAILYRESERVATION") {
      day.DateSelected = true;
      ChildList.forEach(c => {
        c.Checked = false;
        CBData.forEach(cid => {
          if (c.Id === cid) { c.Checked = true; }
        });
      });
      //console.log("Calendar. handleCBDailyReservations. Make reservations for children, who dont have any. ChildList: ", ChildList);
    }
    else if (status === "EDIT") {
      day.DateSelected = true;
      ChildList.forEach(c => {
        c.Checked = false;
        if (c.Id === CBData.PersonId) { c.Checked = true; }
      });
      //console.log("Calendar. handleCBDailyReservations. Edit reservations. ChildList: ", ChildList);
    }
    else if (status === "DELETERESERVATION") {
      // console.log("Calendar. Delete reservation: ",IDs );
      
      this.context.actions.triggerDeleteNursingtimes(CBData);
    }
    this.setState({
      Mode: status === "CLOSEDAILYRESERVATION" || status === "DELETERESERVATION" ? null : status,
      DailyReservationsTargetPlace: null,
      newReservationModalOpen: status === "NEWDAILYRESERVATION" ? true : false,
      infoModal: status === "DELETERESERVATION" ? true : false
    });
  };

  handleSnackbarCB = () => {
    this.focusRef = this.state.selectedElement;

    /*console.log("Calendar. Handle Snackbar callback."+
                "\n this.state.selectedElement: ",this.state.selectedElement,
                "\n  this[divs]: ", this[`divs${this.state.selectedElement}`]);*/

    /*if (this[`divs${this.state.selectedElement}`] !== null && this[`divs${this.state.selectedElement}`] !== undefined)
    { 
      console.log("Calendar. Handle Snackbar callback. Scroll into view: "+this.state.selectedElement);
      this[`divs${this.state.selectedElement}`].scrollIntoView();
      this.setState({ showSnackbar: false,selectedElement: null  }); 
    } // .focus();
    else*/
    {
      this.setState({ showSnackbar: false, SnackbarOkColor: false });
    }
  };

  CheckDayInfo = (dayinfo) => {
    let retval = "NO";

    dayinfo.NursingTimes.forEach(nt => {
      if (retval === "NO") {
        let personId = nt.PersonId;
        let count = dayinfo.NursingTimes.filter(nt => {
          return nt.PersonId === personId;
        }).length;
        //console.log("Date: ",dayinfo.Date.format("DD.MM."), " PersonId: ",personId, " Varauksia: "+ count);
        if (count > 1) retval = "YES";
      }
    });
    dayinfo.Statuses.forEach(st => {
      if (retval === "NO") {
        let personId = st.PersonId;
        let count = dayinfo.Statuses.filter(st => {
          return st.PersonId === personId;
        }).length;
        //console.log("Date: ",dayinfo.Date.format("DD.MM."), " PersonId: ",personId, " Varauksia: "+ count);
        if (count > 1) retval = "YES";
      }
    });
    return retval;
  }

  render() {
    const { children } = this.context.state;
    const { List } = this.context.state.nursingtimes;
    const { nursingTimesSummary } = this.context.state;
    const userLang = SessionManager.getUserLanguage();

    //console.log("NursingtimeCalendar. RENDER Manager lang:  " , userLang, " vs storege lang: ",sessionStorage.getItem('language') );

    /*console.log("NursingtimeCalendar. RENDER "+
                // "\n this.props.location.state: ", this.props.location.state+
                //"\n children: ", children.ChildList,
                // "\n children.state: ",children.state,
                "\n List.WeekData: ", List.WeekData +
                "\n List.state: ", List.state);*/

    /*if ( (this.props.location.state !== undefined && this.props.location.state.readNursingTimes) ||
         (List.state !== types.NURSINGTIMES_READ && List.state !== types.NURSINGTIMES_READ_HANDLED && List.state !== types.NURSINGTIMES_FAILED_HANDLED) ||
         children.state !== types.LOAD_SUCCESS_CHILDREN )*/
    if (List.state === undefined || List.WeekData === undefined) {
      return (
        <ContentBoxMUI hideMUIHeader={null}
          header={this.strings.bookingCareHoursTitle}
          showArrowBack={true}>
          <div className="BackDrop" style={{ opacity: "0.5" }}>
            <Backdrop open={true} > <CircularProgress /> </Backdrop>
          </div>
        </ContentBoxMUI>
      );
    }
    else if (children.ChildList.length === 0) {
      return (
        <div className="BackDrop">
          <Backdrop open={true} > <CircularProgress /> </Backdrop>
        </div>
      );
    }
    else if (this.state.errorModal) {
      return (
        <Modal isOpen={this.state.errorModal}>
          <ModalBody>
            <div className="InfoMessage textCenter">
              <ContentBox header={this.strings.bookingCareHoursTitle}
                showCloseicon={true}
                setfunction={() => this.closeErrorModal("CLOSECOMPONENT")} >

                {this.state.DenseTableTitleSaveError !== null ?
                  <DenseTable Title={this.state.DenseTableTitleSaveError} Data={this.state.DenseTableDataSaveErrors}></DenseTable>
                  : null}

                {this.state.DenseTableTitleDeleteErros !== null ?
                  <DenseTable Title={this.state.DenseTableTitleDeleteErros} Data={this.state.DenseTableDataDeleteErros}></DenseTable>
                  : null}

                {this.state.DenseTableTitleSaveError === null && this.state.DenseTableTitleDeleteErros === null ?
                  <h4 style={{ whiteSpace: "pre-line" }}>{this.state.NoteText}</h4>
                  : null}
              </ContentBox>
            </div>
          </ModalBody>
        </Modal>
      );
    }
    /*else if (this.state.Mode !== null)
    {
      return (
        <ContentBoxMUI>
          <ReservationContainer Mode={this.state.Mode}
                                startTime = {this.context.state.nursingtimes.defaultNursingTimes.Start} 
                                endTime   = {this.context.state.nursingtimes.defaultNursingTimes.End} 
                                callback  ={(status, type, start, end) => this.handleNewReservation(status, type, start, end)} />
        </ContentBoxMUI>
      );
    }*/
    else {
      return (
        <div className="CareTime">
          {this.state.DailyReservationsTargetPlace ?
            <DailyReservations
              target={this.state.DailyReservationsTargetPlace} // "test_Main_RightArrow" 
              week={this.state.selectedweek}
              date={this.state.selecteddate}
              callback={(status, Data) => this.handleCBDailyReservations(status, Data)}
            />
            : null}

          {this.state.newReservationModalOpen ?
            <NewReservations Mode={this.state.Mode}
              HideAddReservationButton={this.state.HideAddReservationButton}
              startTime={this.state.StartTime}
              endTime={this.state.EndTime}
              callback={(status, ListOfChildsInReservations, ListOfReservationsTimes, type, eveningPreSchool) => this.handleNewReservation(status, ListOfChildsInReservations, ListOfReservationsTimes, type, eveningPreSchool)} />
            : null}

          <div style={{ zIndex: "20", position: "fixed", right: "0", opacity: this.state.DailyReservationsTargetPlace ? "0.5" : null }}>
            {this.context.state.nursingTimesSummary.NT.status === 'LOADING_NT_SUMMARY' ||
              this.context.state.nursingtimes.List.state === 'READING_NURSINGTIMES' /*"LOADING_NT_SUMMARY"*/ ?
              <Fab
                id="test_NursingTimeCalendar_UsedCareHoursBtn"
                style={{ marginTop: "150px" }}
                aria-label="UsedCareHours"
                disabled={true}
              >
                <CircularProgress />
              </Fab>
              :

              <Fab
                id="test_NursingTimeCalendar_UsedCareHoursBtn"
                style={{
                  marginTop: "150px",
                  backgroundColor: (nursingTimesSummary.NT.overbookingWarning || (this.context.state.nursingTimesSummary.NT.status !== "NT_SUMMARY_LOAD_SUCCESS" && this.context.state.nursingTimesSummary.NT.status !== "LOADING_NT_SUMMARY") ? "#d500f9" : null)
                }}
                color="secondary"
                aria-label="UsedCareHours"
                onClick={() => this.openUsedCareTimesInfoDrawer(true)}
              >
                <EuroSymbolIcon />
                {nursingTimesSummary.NT.overbookingWarning || (this.context.state.nursingTimesSummary.NT.status !== "NT_SUMMARY_LOAD_SUCCESS" && this.context.state.nursingTimesSummary.NT.status !== "LOADING_NT_SUMMARY") ?
                  <Typography>!</Typography>
                  : null}
              </Fab>
            }

            <UsedCareTimesInfoDrawer
              isOpen={this.state.showUsedCareTimesInfoDrawer}
              closeDrawer={() => this.closeUsedCareTimesInfoDrawer()}
              timespan={this.state.timespanForInvoiceBasis}
              onFailedLoadRetry={(date1) => this.loadSummary(date1)}
              refreshDate={this.state.dateForLoadSummaryRefreshWhenError} />
          </div>

          <ContentBoxMUI
          >
            <CalendarNav positionFix={56}
              nextText={this.state.nextMonthText.locale(userLang === "en" ? "en-gb" : userLang).format("MMMM")}
              prevText={this.state.prevMonthText.locale(userLang === "en" ? "en-gb" : userLang).format("MMMM")}
              onNextClick={() => this.onMonthChange("NEXT")}
              onPrevClick={() => this.onMonthChange("PREVIOUS")}
              centerText={this.state.initDate.locale(userLang === "en" ? "en-gb" : userLang).format("MMMM YYYY")}
            /*helperText={"LOCKED: Selectable: "+this.state.lockeddaysToBeSelected+"+"+this.state.lockedWeekendsHolidaysTobeSelected+" Selected: "+this.state.lockeddaySelected+"+"+this.state.lockedWeekendsHolidaysSelected+
                       "\nNORMAL: Selectable "+this.state.daysToBeSelected + "+" +this.state.WeekendsHolidaysTobeSelected+ " Selected: "+this.state.selectedDays+"+"+this.state.WeekendsHolidaysSelected+
                       "\nEMPTY Selected: "+this.state.emptyDaysSelected}*/
            />
            <hr />
            <div className="container-fluid week-container" style={{ opacity: this.state.infoModal ? "0.5" : null }}>
              {this.state.infoModal ?
                <div className="BackDrop">
                  <Backdrop /*className={classes.backdrop}*/ open={true} /*onClick={handleClose}*/>
                    <CircularProgress />
                  </Backdrop>
                </div>
                :
                List.WeekData.WeekList.map(week => {
                  return (
                    <div ref={div => { this[`divs${"Calendar_Loaded"}`] = div; }} id="test_Calendar_Loaded" key={week.WeekNumber} className="row no-gutters week-row">
                      <div className="col-1">
                        <span className="week-number" id={"test_NursCalendar_week_" + week.WeekNumber} onClick={() => this.selectWeek(week.WeekNumber)} >
                          <IconButton size="small" color="primary" >
                            {week.WeekNumber}
                          </IconButton>
                        </span>
                      </div>
                      <div className="col-11 weekDays">

                        {week.DayList.map(dayinfo => { //date=>dayinfo:ksi
                          //if (dayinfo.InfoToBeShown !== null ) console.log("Dayinfo: ", dayinfo);
                          //näytä vain yksi sijoitusikoni/lapsi, jos ko lapsella on useampi varaus samalle päivälle
                          var handledChildIds = [];

                          let className = "row no-gutters date-row levea";
                          className += dayinfo.Date.isSame(this.state.initDate, "month") ? "" : " diff-month";

                          let test_showDailyView = this.CheckDayInfo(dayinfo);

                          return (
                            <div ref={div => { this[`divs${dayinfo.Date.format("DDMM")}`] = div; }} attr_week={week.WeekNumber} id={"test_NursCalendar_Day_Selection_" + dayinfo.Date.format("DD.MM")} key={dayinfo.Date.format("DD.MM.YYYY")} className={className} style={{ backgroundColor: dayinfo.DateSelected ? "#c0c0c0" : dayinfo.ColorOfDay }}>

                              <div className="col-3" onClick={e => {
                                dayinfo.NotSelectable /*|| dayinfo.WeekHoliday !== null*/ ? this.handleNotSelectable() :
                                dayinfo.IsLockedDate ? this.handleLockedDay(week.WeekNumber, dayinfo, e) :
                                  this.handleRowClick(week.WeekNumber, dayinfo, e)
                              }} >
                                <span className="weekday-name"> {dayinfo.Date.locale(userLang === "en" ? "en-gb" : userLang).format("ddd")} </span> {" "}
                                <IconButton style={{ fontSize: "revert", fontWeight: "bold" }} size="large">
                                  {dayinfo.Date.format("DD.MM.")}
                                </IconButton>
                              </div>

                              <div className="col-5" id={"test_NursCalendar_Day_Text_" + dayinfo.Date.format("DD.MM")}
                                style={{ color: dayinfo.WeekHoliday === null ? "" : "red" }}
                                onClick={e => {
                                  dayinfo.NotSelectable ? this.handleNotSelectable() :
                                  dayinfo.IsLockedDate ? this.handleLockedDay(week.WeekNumber, dayinfo, e) :
                                    this.handleRowClick(week.WeekNumber, dayinfo, e)
                                }}>
                                {dayinfo.InfoToBeShown !== null ? dayinfo.InfoToBeShown :
                                  dayinfo.WeekHoliday === null ? "" : dayinfo.WeekHoliday}
                                {dayinfo.ShowEuroPersonIds === null ? null : <Warning style={{ marginLeft: "2px" }} color="error" alt="PV" width="20px" />}
                              </div>

                              {dayinfo.NursingTimes.length === 0 && dayinfo.Statuses.length === 0 ?
                                <div className="col-4"
                                  onClick={e => {
                                    dayinfo.NotSelectable ? this.handleNotSelectable() :
                                    dayinfo.IsLockedDate ? this.handleLockedDay(week.WeekNumber, dayinfo, e) :
                                      this.handleRowClick(week.WeekNumber, dayinfo, e)
                                  }}
                                  attr_open_dailyview={"NO"}
                                  attr_reservation_count={0}
                                  id={"test_NursCalendar_Day_View_" + dayinfo.Date.format("DD.MM")}>
                                </div>
                                : dayinfo.NursingTimes.length > 0 || dayinfo.Statuses.length > 0 ?

                                  <div className="col-4"
                                    onClick={e => this.showDailyReservations(dayinfo.Date, e, week.WeekNumber)}
                                    attr_open_dailyview={test_showDailyView}
                                    attr_reservation_count={dayinfo.NursingTimes.length + dayinfo.Statuses.length}
                                    id={"test_NursCalendar_Day_View_" + dayinfo.Date.format("DD.MM")} 
                                    style={{
                                      display: 'flex',
                                      alignItems: 'left',
                                      justifyContent: 'left',
                                    }}>
                                  <AvatarGroup >
                                      {dayinfo.NursingTimes.length > 0 ? dayinfo.NursingTimes.map(nt => {
                                        var child = handledChildIds.find(c => c.Id === nt.PersonId);
                                        if (child === undefined) {
                                          handledChildIds.push({ Id: nt.PersonId });
                                          var selectedChild = children.ChildList.find(c => c.Id === nt.PersonId);
                                          return (
                                            <Avatar sx={{ width: 24, height: 24, bgcolor: selectedChild.BgColor}}>
                                            
                                            <ChildProfilePicture childId={nt.PersonId} color={selectedChild.BgColor} />
                                            </Avatar>
                                          );
                                        }
                                        return (null);
                                      }) : null}

                                      {dayinfo.Statuses.length > 0 ? dayinfo.Statuses.map(st => {
                                        //console.log("Check Statuses. Index: ",index," Status Date: ",Moment(st.Date).format("DD.MM.")," Handled childs: ",handledChildIds);
                                        var child = handledChildIds.find(c => c.Id === st.PersonId);
                                        //console.log("Check Statuses. Search child id :",st.PersonId, " from Handled list. Result: ", child );
                                        if (child === undefined) {
                                          handledChildIds.push({ Id: st.PersonId });
                                          var selectedChild = children.ChildList.find(c => c.Id === st.PersonId);
                                          return (
                                            <Avatar sx={{ width: 24, height: 24, bgcolor: selectedChild.BgColor}}>
                                            
                                            <ChildProfilePicture childId={st.PersonId} color={selectedChild.BgColor} />
                                            </Avatar>
                                          );
                                        }
                                        return (null);
                                      }) : null}
                                    
                                  </AvatarGroup>
                                  </div>
                                  : null}
                            </div>
                          ); /* return */
                        })}
                      </div>
                    </div>
                  ); /* return*/
                })}
            </div> {/* className="container-fluid week-container"*/}


            {this.state.showFixedBottombar ?
              <FixedBottomBar>
                <div className="float-left" /*style={{opacity: this.state.DailyReservationsTargetPlace ? "0.5" : null}}*/>
                  <Fab id="test_NursingTimeCalendar_DefaultNursingTimesBtn" style={{ margin: "10px" }} color="secondary" aria-label="DefaultNursingTime" className="fab" onClick={this.openDefaultNursingTimes}>
                    {this.context.state.nursingtimes.defaultNursingTimes.state === "LOADING_DEFAULT_NURSINGTIMES" ||
                      this.context.state.nursingtimes.defaultNursingTimes.state === "SAVING_DEFAULT_NURSINGTIMES" ? <CircularProgress /> : <AddAlarm></AddAlarm>}
                  </Fab>
                </div>

                <div className="float-right" style={{ margin: "10px" }} /*style={{opacity: this.state.DailyReservationsTargetPlace ? "0.5" : null}}*/>
                  <div className="container">
                    <div className="row ">

                      <div>
                        <span>&nbsp;&nbsp;</span>
                        {this.state.daysToBeSelected === 0 && this.state.lockeddaysToBeSelected === 0 ? null :                  /*Ei voida valita yhtään mitään*/
                          (this.state.selectedDays > 0 && this.state.daysToBeSelected === this.state.selectedDays) ||  /*Kaikki jommista kummista päivistä valittu */
                            (this.state.lockeddaySelected > 0 && this.state.lockeddaysToBeSelected === this.state.lockeddaySelected) ?
                            <IconButton size="small" aria-label={Languages.selectallRemove} onClick={() => this.selectAll("UNSELECT")}>
                              <SelectAll id="test_NursingTimeCalendar_UnSelectAllBtn" style={{/*backgroundColor*/ color: "#FF284C" }} align="left"
                           /*color="#FF284C"*/ className="fab">
                              </SelectAll>
                            </IconButton>
                            :
                            <IconButton id="test_NursingTimeCalendar_SelectAllBtn" size="small" aria-label={Languages.selectall} onClick={() => this.selectAll("SELECT")}>
                              <SelectAll align="left" color="secondary" className="fab"></SelectAll>
                            </IconButton>
                        }
                      </div>

                      {(this.state.selectedDays + this.state.lockeddaySelected + this.state.WeekendsHolidaysSelected) > this.state.emptyDaysSelected ?
                        <IconButton aria-label={Languages.bookingDeleteBookingTitle} size="small" id="test_NursingTimeCalendar_DeleteReservationBtn" onClick={() => this.confirmAction("DELETE")}>
                          <DeleteRoundedIcon
                            color="secondary"
                            style={{/*"white",*/ float: "right", margin: "15px" }}>
                          </DeleteRoundedIcon>
                        </IconButton>
                        : null}

                      {this.state.lockeddaySelected > 0 || this.state.lockedWeekendsHolidaysSelected > 0 ?
                        <Fab id="test_NursingTimeCalendar_MakeAbsentBtn" style={{ backgroundColor: "#81C784" }} align="left" color="primary" aria-label="Poissaolo / Absence / Frånvaro" className="fab" onClick={this.makeReservation}>
                          <EventBusyRounded height="28" />
                        </Fab>
                        :
                        (this.state.selectedDays > 0 || this.state.WeekendsHolidaysSelected > 0) &&
                          (this.state.emptyDaysSelected === this.state.selectedDays + this.state.WeekendsHolidaysSelected) ?
                          <Fab id="test_NursingTimeCalendar_MakeReservationBtn" style={{ backgroundColor: "#81C784" }} align="left" color="primary" aria-label="Tee varaus / Make Booking" className="fab" onClick={this.makeReservation}>
                            <Check></Check>
                          </Fab>
                          : this.state.selectedDays > 0 || this.state.WeekendsHolidaysSelected > 0 ?
                            <Fab id="test_NursingTimeCalendar_SelectReservationBtn" color="primary" style={{/*color:"white"*/backgroundColor: "#81C784" }} align="left" aria-label="Varaus / Reservation" className="fab" onClick={() => this.confirmAction("RESERVATIONS")}>
                              <AddRoundedIcon ></AddRoundedIcon>
                            </Fab>
                            : null}
                    </div>
                  </div>
                </div>
              </FixedBottomBar>

              : null}

            <SnackbarComponent verticalPosition={'bottom'} show={this.state.showSnackbar} okColor={this.state.SnackbarOkColor} message={this.state.NoteText} callback={() => this.handleSnackbarCB()} />

            {this.state.showSlideNote ?
              <SlideConfirmationNote openNote={this.state.showSlideNote}
                title={this.state.SlideNoteTitle}
                text={this.state.NoteText}
                leftButton={this.state.SlideNoteLeftButton}
                rightButton={this.state.SlideNoteRightButton}
                callback={(status) => this.handleSlideConfirmationNoteCB(status)}></SlideConfirmationNote>
              : null}

            {this.state.openDefaultNursingTimes ? <DefaultNursingTimes sTime={this.context.state.nursingtimes.defaultNursingTimes.Start}
              eTime={this.context.state.nursingtimes.defaultNursingTimes.End}
              callback={(status, data) => this.handleDefaultNursingTimesCB(status, data)} /> : null}
          </ContentBoxMUI>
        </div> /* className="CareTime"*/
      ); /* return child calender*/
    }
  }/* render*/
}

export default NursingtimeCalendar;


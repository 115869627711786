const createMockResponse = ({ data, status, statusText = "" }) => ({
  data: data,
  status: status,
  statusText: statusText,
});

export const createLoginOptionsResponse = ({
  password = true,
  strong = true,
  emailChildBirth = false,
}) => {
  return createMockResponse({
    data: {
      Password: password,
      StrongIdentification: strong,
      EmailChildBirth: emailChildBirth,
      ManualInstructions:
        "https://ohjeet.daisymanager.fi/DaisyFamily/daisyfamily_kayttohje.pdf",
      RegistryDescriptions: "Content/files/Daisy_rekisteriseloste.pdf",
    },
    status: 200,
  });
};

// Creates same error type as axios does, eases testing
export const createAxiosError = (
  message,
  description,
  config,
  code,
  request,
  response
) => {
  return {
    // Standard
    message: message,
    name: "NetworkError",
    // Microsoft
    description: description,
    number: "-1", //????
    // Mozilla
    fileName: "filename",
    lineNumber: "1",
    columnNumber: "1",
    stack: "",
    // Axios
    config: config,
    code: code,
    request: request,
    response: response,
    isAxiosError: true,
  };
};

export default createMockResponse;

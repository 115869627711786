import React, { useEffect, useState } from "react";
import { useStateValue } from "../../State";
import Languages from "../../translations";
import CareTimeBookingSuccess from "./CareTimeBookingSuccess/CareTimeBookingSuccess";
import CareTimeBookingLoading from "./CareTimeBookingLoading/CareTimeBookingLoading";
import CareTimeBookingFailed from "./CareTimeBookingFailed/CareTimeBookingFailed";
import moment from "moment";
import { types } from "../../Reducers/actionTypes";
import { Date } from "core-js";

const CareTimeBooking = () => {
  const { actions, state } = useStateValue();

  const careTimes = state.caretimes.careTimesList;
  const careTimes2 = state.caretimes.testList;
  const careTimes3 = state.caretimes.CTSaved;
  const absent = state.caretimes.Absent;
  const CTBSummary = state.nursingTimesSummary.NT;
  const nursingtimes = state.nursingtimes;
  const quick = state.caretimes.Quick;
  const { Deleted } = state.nursingtimes;
  const defaultNursingTimes = state.nursingtimes.defaultNursingTimes;
  const { profilePictures } = state;
  const [bookingMainStatus, setBookingMainStatus] = useState("INITIAL STATE");
  const [LoadingData, setLoadingData] = useState(false);
  const [startDate, setStartDate] = useState(
    moment().startOf("month").add(1, "weeks")
  );
  const [focusDay, setFocusDay] = useState(moment());
  const [nursingTimeList, setNursingTimeList] = React.useState();

  const handleFocusDay = (day) => {
    setFocusDay(day);
    setStartDate(day);
  };

  const handleCalendarNavClick = (day) => {
    handleFocusDay(day);
    careTimes.state = undefined;
  };
  useEffect(() => {
    console.log("triggered");
    switch (careTimes.state) {
      case undefined:
        if (bookingMainStatus !== "CARE_TIME_BOOKING_LOADING") {
          actions.triggerLoadCareTimes({ date: startDate, date2: null });
          setBookingMainStatus("CARE_TIME_BOOKING_LOADING");
        }
        break;
      case types.CARETIMES_LOAD_OK:
        const temp = [];
        Object.values(careTimes2.WeekData.WeekList).forEach((weekArray) => {
          console.log("Test", weekArray);
          Object.values(weekArray).forEach((week) => {
            console.log("Test2", week);
            if (
              !temp.some((tempweek) => tempweek.WeekNumber === week.WeekNumber)
            ) {
              temp.push(week);
            }
          });
        });
        console.log("TEMP1", temp);
        console.log("testeffect");
        setNursingTimeList(temp);

        setBookingMainStatus("CARE_TIME_BOOKING_SUCCESS");
        console.log(careTimes);
        break;

      case types.CARETIMES_LOAD_FAILED:
        setBookingMainStatus("CARE_TIME_BOOKING_FAILED");
        break;
      default:
        break;
    }
  }, [careTimes.state]);

  useEffect(() => {
    console.log("triggered222");
    switch (careTimes3.state) {
      case undefined:
        if (bookingMainStatus !== "CARE_TIME_BOOKING_LOADING") {
          setBookingMainStatus("CARE_TIME_BOOKING_LOADING");
        }
        break;
      case types.SAVING_CARETIMES:
        setBookingMainStatus("CARE_TIME_BOOKING_LOADING");
        break;
      case types.CARETIMES_SAVED_OK:
        const temp = [];
        console.log("trigger333");
        console.log(Object.values(careTimes2.WeekData.WeekList));
        Object.values(careTimes2.WeekData.WeekList).forEach((weekArray) => {
          console.log("Test", weekArray);
          Object.values(weekArray).forEach((week) => {
            console.log("Test2", week);
            if (
              !temp.some((tempweek) => tempweek.WeekNumber === week.WeekNumber)
            ) {
              temp.push(week);
            }
          });
        });

        console.log("testeffect");
        console.log("TEMP2", temp);
        setNursingTimeList(temp);

        setBookingMainStatus("CARE_TIME_BOOKING_SUCCESS");
        break;
      case types.CARETIMES_SAVED_FAILED:
        setBookingMainStatus("CARE_TIME_BOOKING_SUCCESS");
        break;
      default:
        break;
    }
  }, [careTimes3.state]);

  useEffect(() => {
    console.log("triggered222");
    switch (absent.state) {
      case undefined:
        if (bookingMainStatus !== "CARE_TIME_BOOKING_LOADING") {
          setBookingMainStatus("CARE_TIME_BOOKING_LOADING");
        }
        break;
      case types.SAVING_CARETIMES_ABSENT:
        setBookingMainStatus("CARE_TIME_BOOKING_LOADING");
        break;
      case types.CARETIMES_ABSENT_SAVED_OK:
        const temp = [];
        console.log("trigger333");
        console.log(Object.values(careTimes2.WeekData.WeekList));
        Object.values(careTimes2.WeekData.WeekList).forEach((weekArray) => {
          console.log("Test", weekArray);
          Object.values(weekArray).forEach((week) => {
            console.log("Test2", week);
            if (
              !temp.some((tempweek) => tempweek.WeekNumber === week.WeekNumber)
            ) {
              temp.push(week);
            }
          });
        });

        console.log("testeffect");
        console.log("TEMP2", temp);
        setNursingTimeList(temp);

        setBookingMainStatus("CARE_TIME_BOOKING_SUCCESS");
        break;
      case types.CARETIMES_ABSENT_SAVED_FAILED:
        setBookingMainStatus("CARE_TIME_BOOKING_SUCCESS");
        break;
      default:
        break;
    }
  }, [absent.state]);

  useEffect(() => {
    if (
      profilePictures.state !== "LOAD_SUCCESS_PROFILEPICTURES" &&
      profilePictures.state !== "LOADING_PROFILEPICTURES"
    ) {
      actions.triggerLoadProfilePictures();
    }
  }, []);

  const [quickDate, setQuickDate] = useState(moment().startOf("month"));
  const [quickDateStart, setQuickDateStart] = useState(
    moment().add(-3, "Months").startOf("month")
  );
  const [quickDateEnd, setQuickDateEnd] = useState(
    moment().add(18, "Months").startOf("month")
  );

  const [quickMonth, setQuickMonth] = useState(null);

  const HandleQuickMonth = (month) => {
    let test = moment(quickDateEnd).add(-1, "months").startOf("month");
    console.log("nyk", month.format("yyyy-MM-DD"));
    console.log(quick);
    console.log("ed", quickDateEnd.format("yyyy-MM-DD"));
    if (month.format("yyyy-MM-DD") === test.format("yyyy-MM-DD")) {
      console.log("lörslärä");
      setQuickDateEnd(quickDateEnd.add(1, "months"));
      let test2 = moment(quickDateEnd).add(1, "months");
      actions.triggerLoadCareTimesQuickNext({
        startDate: test2.format("yyyy-MM-DD"),
        endDate: test2.endOf("month").format("yyyy-MM-DD"),
      });
    }
  };

  const [holidays, setHolidays] = useState([]);
  useEffect(() => {
    switch (quick.state) {
      case undefined:
        actions.triggerLoadCareTimesQuick({
          startDate: quickDateStart.format("yyyy-MM-DD"),
          endDate: quickDateEnd.endOf("month").format("yyyy-MM-DD"),
        });
        break;
      case types.LOADING_CARETIMES_QUICK:
        break;
      case types.CARETIMES_QUICK_LOAD_OK:
        setHolidays(quick.HolidayList);
        break;
      case types.CARETIMES_QUICK_LOAD_FAILED:
        break;
      default:
        break;
    }
  }, [quick.state]);

  const LoadCareTimesCuick = (date) => {
    console.log(date);
    actions.triggerLoadCareTimesQuick(date);
  };

  useEffect(() => {
    actions.triggerLoadDefaultNursingTimes();
  }, []);

  useEffect(() => {
    switch (defaultNursingTimes.state) {
      case types.SAVING_SUCCESS_DEFAULT_NURSINGTIMES:
        actions.triggerAddToSnackBarQueue({
          message: "Oletushoitoajan tallennus onnistui",
          severity: "success",
        });
        break;
      case types.SAVING_FAILED_DEFAULT_NURSINGTIMES:
        actions.triggerAddToSnackBarQueue({
          message: "Oletushoitoajan tallennus epäonnistui",
          severity: "Error",
        });
        break;
      default:
        break;
    }
  }, [defaultNursingTimes.state]);

  useEffect(() => {
    switch (Deleted.state) {
      case types.NURSINGTIMES_DELETED_OK:
        actions.triggerAddToSnackBarQueue({
          message: "Hoitoajan poisto onnistui",
          severity: "success",
        });
        break;
      case types.NURSINGTIMES_DELETED_FAILED:
        actions.triggerAddToSnackBarQueue({
          message: "Hoitoajan poisto epäonnistui",
          severity: "Error",
        });
        break;
      default:
        break;
    }
  }, [Deleted.state]);

  const monthChange = (dir) => {
    if (dir === "prev") {
      setStartDate(startDate.subtract(1, "months"));
      actions.triggerLoadCareTimesPrev(startDate);
    }

    if (dir === "next") {
      setStartDate(startDate.add(1, "months"));
      actions.triggerLoadCareTimesNext(startDate);
    }

    //state.caretimes.careTimesList.state = undefined;
  };

  let mainViewStatus = bookingMainStatus;

  if (mainViewStatus === "CARE_TIME_BOOKING_SUCCESS") {
    return (
      <>
        <CareTimeBookingSuccess
          nursingtimes={nursingTimeList}
          monthChange={monthChange}
          CTBSummary={CTBSummary}
          focusDay={focusDay}
          handleFocusDay={handleFocusDay}
          handleCalendarNavClick={handleCalendarNavClick}
          LoadCareTimesCuick={HandleQuickMonth}
          holidays={holidays}
        />
      </>
    );
  } else if (mainViewStatus === "CARE_TIME_BOOKING_LOADING") {
    return (
      <>
        <CareTimeBookingLoading />
      </>
    );
  } else if (mainViewStatus === "CARE_TIME_BOOKING_FAILED") {
    return (
      <>
        <CareTimeBookingFailed errorCode="404" />
      </>
    );
  } else {
    return (
      <>
        <CareTimeBookingFailed errorCode="404" />
      </>
    );
  }
};
export default CareTimeBooking;

import React from 'react';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MunicialPermissionsSingleList from '../../GeneralPermissionsLayout/Components/MunicipalPermissionsSingleList';
import IconButton from '@mui/material/IconButton';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import CloseIcon from '@mui/icons-material/Close';
import ChildProfilePicture from '../../../Components/ChildProfilePicture';
import Typography from "@mui/material/Typography";
import Languages from '../../../translations';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ReportIcon from '@mui/icons-material/Report';



const SingleChildPermissionList = ( {handleClose, mainstats, child, changeEditStatusOn, changeEditStatusOff} ) => {

  const scrollPermissionTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }


  return(
    <>
      <div style={{display: "inline-block", width: "100%"}}>
      <div style={{float: "left", padding: "5px"}}>
      <ChildProfilePicture childId={child.Id} /><h5>{child.FirstName}</h5>
      </div>
      
    <IconButton onClick={handleClose} aria-label="delete" size="medium" sx={{float: "right",  color: "#4377BA",}}>
    <CloseIcon fontSize="medium" />
    </IconButton>
    </div>
    <br/>
    {mainstats.UnansweredChildPermPermissions.map((numberPerChild) => {
            return(
              <div key={numberPerChild.ChildId}>
                {numberPerChild.ChildId === child.Id && (numberPerChild.UnansweredPermPerms === 0 
                  ?
                
                  <div style={{borderLeft: "solid 6px #006633", backgroundColor: "#DCEEDF", display: "-webkit-box", padding: "10px"}}>
                    <DoneAllIcon sx={{color: "#006633"}}fontSize="medium"/>&nbsp;&nbsp;<Typography>{Languages.permNoUnansweredPermissions}</Typography> 
                  </div>
                  
                  : 

                  <div style={{borderLeft: "solid 6px #EE5711", backgroundColor: "#FFEBD4", display: "-webkit-box", padding: "10px"}}>
                    <ReportIcon sx={{color: "#EE5711"}}fontSize="medium"/>&nbsp;&nbsp;<Typography>{numberPerChild.UnansweredPermPerms + ' ' + Languages.permUnansweredPerms }</Typography> 
                  </div>
                )}
              </div>
            )
          })}
    <hr />
    <MunicialPermissionsSingleList childId={child.Id} childColor="#4377BA" changeEditStatusOn={changeEditStatusOn} changeEditStatusOff={changeEditStatusOff}/>
    <br />
    <hr />
    <div style={{display: "inline-block", width: "100%"}}>
    <Button onClick={handleClose} variant="outlined" sx={{float: "left", padding: "10px 15px", marginTop: "5px", color: "#4377BA"}}><ArrowBackIcon />{Languages.generalBack}</Button>
    <IconButton onClick={scrollPermissionTop} aria-label="Back to top" size="large" sx={{float: "right",  color: "#4377BA",}}>
        <ArrowCircleUpIcon fontSize="large" />
    </IconButton>
    </div>
    </>
  )
}


export default SingleChildPermissionList;
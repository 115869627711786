import React from "react";
import BackToUpButton from "../GuideTools/BackToUpButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Languages from "../../../translations";
import makeStyles from "@mui/styles/makeStyles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SelectAllIcon from "@mui/icons-material/SelectAll";
import ControlPointIcon from "@mui/icons-material/ControlPoint";

const useStyles = makeStyles({
  success: {
    color: "rgb(129, 199, 132)",
    fontSize: "xx-large",
  },
});

const EditBookingAccordionText2 = () => {
  const classes = useStyles();
  return (
    <>
      <div>
        <h4>{Languages.editBookingGuideTitle}</h4>
        <br />
        <p className="guide-accordion-details-texts">
          {"Voit muokata jo tehtyjä varauksia alla olevalla ohjeella. "}
        </p>
        <br />
        <br />
        <hr className="guide-accordion-details-line" />
        <h4>{"Hoitovarausten muokkaaminen"}</h4>
        <br />
        <div>
          <ol>
            <li className="guide-list-item-styles">
              {
                "Hoitovarauksen muokkaaminen alkaa samoin kuin uuden varauksen tekeminen. Valitse vihreä muokkauspainike."
              }
            </li>
            <br />
            <li className="guide-list-item-styles">
              {"Valitse ”Uusi hoitoaika”."}
            </li>
            <br />
            <li className="guide-list-item-styles">
              {"Valitse kenelle ja minne varaukset tehdään. "}
            </li>
            <br />
            <li className="guide-list-item-styles">
              {
                "Valitse varauksen kesto. Kellonajat voit merkitä 15 minuutin välein."
              }
            </li>
            <br />
            <li className="guide-list-item-styles">
              {
                ". Valitse, haluatko säilyttää vanhat varaukset. Mikäli valitset KYLLÄ: vanha varaus säilyy ja uusi varaus tallentuu sen lisäksi.EI: vanha varaus poistuu ja korvautuu uudella varauksella."
              }
            </li>
            <br />
            <li className="guide-list-item-styles">
              {
                "Valitse kalenterista päivä(t) varaukselle ja tallenna muutokset."
              }
            </li>
          </ol>
        </div>
        <br />
        <BackToUpButton />
      </div>
    </>
  );
};

export default EditBookingAccordionText2;

import React            from  'react';
import Languages        from  "../../../translations";
import KasvunkansioIcon from  "../../../images/growthfolder.png";
import                        '../growthfolder.css';

const GrowthFolderEmpty = () => {
  return(
    <React.Fragment>
        <div className = "growthfolder-empty-container">
          <div className = "growthfolder-empty-growthfolder-img-position">
            <img src={KasvunkansioIcon} alt = {"Kasvunkansio"} height = "150"/>
            <p>{Languages.emptyGrowthFolder}</p>
          </div>
        </div> 
    </React.Fragment>
  );
}

export default GrowthFolderEmpty;
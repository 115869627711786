import React, { useState, useEffect } from "react";
import {useStateValue} from "../../../State";
import "../vasu.css";

import Link             from '@mui/material/Link'
import Box              from '@mui/material/Box'
import ContentBoxMUI         from "../../../Components/ContentBoxMUI";
import IconButton            from "@mui/material/IconButton";
import List             from '@mui/material/List';
import ListItemButton   from '@mui/material/ListItemButton';
import ListItemIcon     from '@mui/material/ListItemIcon';
import ListItemText     from '@mui/material/ListItemText';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import Languages      from "../../../translations";
import ChildRow              from '../ChildRow';
import VasuEnhancedSupportSubPart from "./VasuEnhancedSupportSubPart";

const VasuEnhancedSupport = (props) => {

  const { state/*, actions*/ } = useStateValue();
  const VasuData = state.vasu;
  const GeneralPart = state.vasu.GeneralPart;

  const { history, location } = props;
  //console.log("VasuDiscussionPart. MOUNT. location.state: ", location.state);

  if (location.state === undefined)
  { history.goBack(); }

  const [ShowSlideIndex, setShowSlideIndex] = useState(-1);
  
  useEffect( () => {
    //console.log("VasuDiscussionPart. useEffect");
  });

  const handleBackClick = () => {
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = () => {
      window.history.pushState(null, "", window.location.href);
      setShowSlideIndex(-1);
      window.onpopstate = undefined;
      window.history.back();
    };
  };

  const handleBoxClick = (index) => {
    setShowSlideIndex(index);
    handleBackClick();
  };

  if (VasuData.state === "INITIAL_STATE" || location.state === undefined)
  {
    return (
      <div className="VasuEnhancedSupport"></div>
    );
  }
  else
  {
    const { childpicture, childFirstName, childLastName, childColor } = location.state;

    return (
      <div className="VasuEnhancedSupport">
        <ContentBoxMUI>

        <Box sx ={{mt: 1}}>
          <ChildRow childpicture   = {childpicture}
                    childFirstName = {childFirstName}
                    childLastName = {childLastName}
                    childColor     = {childColor}
                    FormId         = {GeneralPart.Id}
                    history        = {history}>
          </ChildRow>
        </Box>
          
          {ShowSlideIndex === -1 ?
          <div>
            <h4 className="textCenter">{ "3.1. " + Languages.VasuEnhancedSupport}</h4>
            
            <List className="col-12" sx={{ mt: -1}}>
              <ListItemButton className="boxed" sx={{backgroundColor: "#fceebf", borderRadius: .5, mt: 1.5, boxShadow: 2}} onClick={(event) => handleBoxClick(0)}>
                <ListItemText 
                  primaryTypographyProps={{fontWeight: 'Bold', fontSize: 16, ml: 2}} primary={Languages.VasuEnhancedSupport}/>
                <ListItemIcon><ArrowForwardIosIcon fontWeight='Bold' fontSize="small"/></ListItemIcon>
              </ListItemButton>
              <ListItemButton className="boxed" sx={{ backgroundColor: "#fceebf", borderRadius: .5, mt: 1.5, boxShadow: 2}} onClick={(event) => handleBoxClick(1)}>
                <ListItemText 
                  primaryTypographyProps={{fontWeight: 'Bold', fontSize: 16, ml: 2}} primary={Languages.VasuSupportTitle1} />
                <ListItemIcon><ArrowForwardIosIcon fontWeight='Bold' fontSize="small"/></ListItemIcon>
              </ListItemButton>
              <ListItemButton className="boxed" sx={{ backgroundColor: "#fceebf", borderRadius: .5, mt: 1.5, boxShadow: 2}} onClick={(event) => handleBoxClick(5)}>
                <ListItemText 
                  primaryTypographyProps={{fontWeight: 'Bold', fontSize: 16, ml: 2}} primary={Languages.VasuSupportTitle2}/>
                <ListItemIcon><ArrowForwardIosIcon fontSize="small" /></ListItemIcon>
              </ListItemButton>
            </List>
          </div>
          :
            <VasuEnhancedSupportSubPart SelectedSlideIndex={ShowSlideIndex} > </VasuEnhancedSupportSubPart>
          }
        </ContentBoxMUI>
      </div>
    );
  }
}

export default VasuEnhancedSupport;
// https://tylermcginnis.com/react-router-protected-routes-authentication/
import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom"
import { LOGOUT_REASONS } from "../../Api";
import { useAuth } from "../../Auth/AuthProvider";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, lastLogoutReason } = useAuth();
  const location = useLocation();

  return (
  <Route {...rest} render={(props) => {
    if (isAuthenticated) {
      return (<Component {...props} />);
    } else {
      console.log(`PrivateRoute unautcheticated. isAuth: ${isAuthenticated}, lastLogoutreason: ${lastLogoutReason}, location.pathname: ${location.pathname}`);
      if (lastLogoutReason === LOGOUT_REASONS.automatic) {
        return (<Redirect to="/logout"/>);
      } else if (lastLogoutReason === LOGOUT_REASONS.normal) {
        return (<Redirect to="/login"/>);
      }
      return (<Redirect to={{
        pathname: "/login",
        search: location.search,
        state: { referrer: location.pathname }
      }}/>);
    }
  }} />
)};

export default PrivateRoute;
import React, { useEffect/*, useState*/ } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

export default function SlideConfirmationNote({openNote, title, text, leftButton, rightButton, middleButton, callback}) {

  const [open, setOpen] = React.useState(false);
  const [openNotHandled, setOpenNotHandled] = React.useState(true);

  const handleClose = () => {
    //console.log("SlideConfirmationNote. handle close");
    setOpen(false);
    callback("CLOSE");
  };

  const handleLeftButton = (status) => {
    //console.log("SlideConfirmationNote. handleLeftButton: ", status);
    setOpen(false);
    callback("LEFT");
  };

  const handleRightButton = (status) => {
    //console.log("SlideConfirmationNote. handleRightButtonn: ", status);
    setOpen(false);
    callback("RIGHT");
  };

  const handleMiddleButton = (status) => {
    //console.log("SlideConfirmationNote. handleRightButtonn: ", status);
    setOpen(false);
    callback("MIDDLE");
  };
  
  useEffect(() => {
    //console.log("SlideConfirmationNote. useEffect. open: ", open, " openNote: ", openNote, " openNotHandled: ", openNotHandled);
    if (open !== openNote && openNotHandled)
    {
      setOpen(openNote);
      setOpenNotHandled(false);
    }
   },[open,openNote,openNotHandled]); 

  return (
    <div>
    <Slide direction="up" in={open} /*mountOnEnter unmountOnExit*/>
    <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          style: {
            backgroundColor: 'white'
          },
        }}
      >
        <DialogTitle id="alert-dialog-slide-title" style={{color: "black"}}>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" style={{whiteSpace:"pre-line"}}>{text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {leftButton === undefined || leftButton === null ? null :
          <Button onClick={() => handleLeftButton(leftButton)} id="test_SlideNoteConfirmation_Left" variant="outlined" color="secondary" >
            {leftButton}
          </Button>
          }
          
          {middleButton === undefined || middleButton === null ? null :
          <Button onClick={() => handleMiddleButton(middleButton)} id="test_SlideNoteConfirmation_Middle" variant="outlined" color="secondary" >
            {middleButton}
          </Button>
          }

          {rightButton === undefined || rightButton === null ? null :
          <Button onClick={() => handleRightButton(rightButton)} id="test_SlideNoteConfirmation_Right" variant="outlined" color="secondary" >
            {rightButton}
          </Button>
          }
        </DialogActions>
      </Dialog>
      </Slide>
      </div>
  );
}

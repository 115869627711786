// export { default as ApiBase } from './ApiBase';
// export { default as ServerApi }       from './ServerApi';
// export { default as MessagesApi }     from './MessagesApi';
// export { default as NursingTimesApi } from './NursingTimesApi';
// export { default as ChildrenApi }     from './ChildrenApi';
// export { default as MainStatsApi }    from './MainStatsApi';
// export { default as ImageApi }        from './ImageApi';
// export { default as SettingsApi }     from './SettingsApi';
// export { default as VasuApi }         from './VasuApi';
// export { default as LeopsApi }        from './LeopsApi';

export { default as createMockResponse } from './MockResponse';
export { createLoginOptionsResponse, createAxiosError } from './MockResponse';

const ATTACHMENT_TYPES = {
  attachment: "ATTACHMENT",
  thumbnail: "ATTACHMENT_THUMBNAIL",
  fullImage: "ATTACHMENT_FULLIMAGE",
  personAttachment: "PERSON_ATTACHMENT"
}

const LOGOUT_REASONS = {
  automatic: "AUTOMATIC",
  normal: "NORMAL"
}

export { LOGOUT_REASONS }
export { ATTACHMENT_TYPES }
import React, { useState, useEffect } from "react";
import { useStateValue } from "../../State";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

// Listener handles requested messages as a queue.
// Request in your own component with, for example:
// actions.triggerAddToSnackBarQueue({color: "#000000", message: "Viestini"});
// https://material-ui.com/components/snackbars/

const SnackbarQueueHandler = () => {
  const { state, actions } = useStateValue();

  const [SnackbarOpen, setSnackbarOpen] = useState(false);

  const snackBarQueue = state.snackbar.queue;

  const [isProcessing, setIsProcessing] = useState(false);

  const triggerProcessing = () => {
    if (snackBarQueue.length > 0 && !SnackbarOpen) {
      setSnackbarOpen(true);
      setTimeout(() => {
        actions.triggerReduceSnackBarQueue();
        setSnackbarOpen(false);
        if (snackBarQueue.length > 0) {
          triggerProcessing();
        } else {
          setIsProcessing(false);
        }
      }, 6000 /*snackBarQueue[0].showSeconds * 1000*/);
    }
  };

  useEffect(() => {
    //if (isAuthenticated) {}
    if (SnackbarOpen === false && snackBarQueue.length > 0 && !isProcessing) {
      setIsProcessing(true);
      triggerProcessing();
    }
  }, [snackBarQueue, SnackbarOpen, isProcessing]);

  const ReturnCorrectArray = () => {
    return (
      <MuiAlert severity={snackBarQueue[0]?.severity} variant="filled">
        {snackBarQueue[0]?.message}
      </MuiAlert>
    );
  };

  //console.log(snackBarQueue[0]?.severity)
  //console.log('Moi')
  if (snackBarQueue[0]?.message) {
    return (
      <>
        <Snackbar
          id="test_QueueSnackBar"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={snackBarQueue.length > 0 && SnackbarOpen}
          //open="true"
          //message={snackBarQueue[0]?.message}
          //ContentProps={{style: {backgroundColor: !snackBarQueue[0]?.color ? "#000000" : snackBarQueue[0].color}}}
        >
          {ReturnCorrectArray()}
        </Snackbar>
      </>
    );
  }
  return null;
};

export default SnackbarQueueHandler;

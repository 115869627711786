import React              from "react";
import AppBar             from "@mui/material/AppBar";
import ToolBar            from "@mui/material/Toolbar";
import Typography         from "@mui/material/Typography";
import Grid               from "@mui/material/Grid";
import CancelIcon         from "@mui/icons-material/Cancel";
import IconButton         from '@mui/material/IconButton';


const PopUpAppBar = ({headerTitle, closeFunction}) => {


  return (
    <AppBar position="fixed" color="primary">
        <ToolBar>

          <div style={{ width: "100%" }}>
           
                <Grid container spacing={2}>
                  
                  <Grid item xs={1}>
                  <IconButton 
                    id="test_closePopUpAppBar_btn"
                    style={{display: "inline-block"}} 
                    size="medium" 
                    aria-label="Back to previous" 
                    onClick={() => closeFunction()}>
                    <Typography color="textPrimary" ><CancelIcon /></Typography>
                  </IconButton>
                  </Grid>
                  
                  <Grid item xs={9}>
                  {typeof headerTitle !== "undefined" && (headerTitle.length < 17) &&
                          
                          <div style={{ display: "inline-block", fontSize: "0.8rem", wordWrap: "break-word" }}>
                            <Typography variant="h5" style={{marginTop: "8px"}}>&nbsp; &nbsp; {headerTitle}</Typography>
                          </div>}
                  { typeof headerTitle !== "undefined" && (headerTitle.length > 17) && (headerTitle.length < 30) &&
                      
                          <div style={{display: "block", marginLeft:"12px", paddingTop:"12px", wordWrap: "break-word"}}>
                            <Typography noWrap={false}>{headerTitle.length > 50 ? headerTitle.substring(0, 51) + "..." : headerTitle} </Typography>
                          </div>}
                  { typeof headerTitle !== "undefined" && (headerTitle.length > 30) &&
                          <div style={{display: "inline-block", marginLeft:"14px", wordWrap: "break-word"}}>
                            <Typography noWrap={false}>{headerTitle.length > 50 ? headerTitle.substring(0, 51) + "..." : headerTitle} </Typography>
                          </div>}   
                  </Grid>
                  
                  <Grid item xs={2}>
                    <Grid container justifyContent="center" alignItems="center" spacing={2}>
                      <Grid item xs={6}></Grid>
                    </Grid>
                  </Grid>    
                </Grid>
          </div>

        </ToolBar>
      </AppBar>
  );
}

export default PopUpAppBar;
import React from "react";
import BackToUpButton from "../GuideTools/BackToUpButton";
import DeleteIcon from '@mui/icons-material/Delete'
import Languages from "../../../translations";
import makeStyles from '@mui/styles/makeStyles';
import CircleIcon from '@mui/icons-material/Circle';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';

const useStyles = makeStyles({
  success: {
    color: 'rgb(129, 199, 132)',
    fontSize: 'xx-large',
  }
});



const MessagingButtonsAccordionText = () => {
  const classes = useStyles();
    return(
      <>
      <div>
      <h4>{Languages.messagingButtonsSubTitle1}</h4>
          <br />
          <p className="guide-accordion-details-texts"><CircleIcon sx ={{color: "rgb(129, 199, 132)", fontSize: "3em"}}/> <AddIcon style={{position: 'relative', right: '43px'}} /><b>{Languages.messagingButtonList1}</b>{Languages.messagingButtonList2}</p>
          <br />
          <br />
          <p className="guide-accordion-details-texts"><RefreshIcon sx ={{mr: 3.5, fontSize: "3em"}}/><b>{Languages.messagingButtonList3}</b>{Languages.messagingButtonList4}</p>
          <br />
          <br />
          <p className="guide-accordion-details-texts"><PlaylistAddCheckIcon sx ={{mr:3.5, fontSize: "3em"}} /><b>{Languages.messagingButtonList5}</b>{Languages.messagingButtonList6}</p>
          <br />
          <br />
          <p className="guide-accordion-details-texts"><DeleteIcon sx ={{mr:3.5, fontSize: "3em"}}/><b>{Languages.messagingButtonList7}</b>{Languages.messagingButtonList8}</p>
          <br />
        <BackToUpButton />
        </div>
      </>
    )
}

export default MessagingButtonsAccordionText
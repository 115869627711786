import React                from  'react';
import Card                 from  "@mui/material/Card";
import CardContent          from  "@mui/material/CardContent";
import CardMedia            from  '@mui/material/CardMedia';
import                            "./pedagdocfolder.css";
import CardActionArea       from  '@mui/material/CardActionArea';
import Typography           from  '@mui/material/Typography';
import PhotoLibraryIcon     from  '@mui/icons-material/PhotoLibrary';
import AssignmentIcon       from  '@mui/icons-material/Assignment';
import Moment               from  "moment";
import GroupIcon            from  '@mui/icons-material/Group';
import PhotoIcon            from  '@mui/icons-material/Photo';
import Languages            from  "../../../translations";
import { styled } from '@mui/material/styles';

// Image contaier which keeps picture aspectration
const KeepAspectRatioMedia = styled(CardMedia)(({theme}) => ({
  backgroundSize: 'contain',
  objectFit: 'contain',
}))

const PedagDocFolder = ({Folder, growthFolderFunctions}) => {

  if (Folder === "undefined" || Folder === null) {
    return(
      <div>
        {Languages.errorUnknown + "(777)."}
      </div>
    );
  }
  else if (Folder.imageStoreFolder.Foldertype === "IMGF") {
    return (
      <div className="Folder" onClick={() => growthFolderFunctions["openFolder"](Folder.imageStoreFolder)}>
        <Card className="paddings">
          <CardActionArea>
            {Folder.MimeType !== "txt" && Folder.MimeType !== null && Folder.Base64Image !== null ? 
              <KeepAspectRatioMedia 
                component ="img"
                height="160"
                image={"data:"+Folder.MimeType +";base64,"+Folder.Base64Image}/>
            :
            <div data-testid="imagePlaceholder" className="pedagdocfolder-default-img" >
              <PhotoIcon style={{ fontSize: 160}}/>
            </div>
          }      
            <CardContent>
              <div className="pedagdocfolder-folder-row">
                {!Folder.imageStoreFolder.GroupId ?
                <div className="pedagdocfolder-folder-type-block"> 
                  <PhotoLibraryIcon />
                </div>
                : 
                <div className="pedagdocfolder-folder-type-block">
                  <PhotoLibraryIcon />
                  <GroupIcon />
                </div>
                }
                <div className="pedagdocfolder-folder-name-block">
                  <Typography display="inline" variant="h6" color="textPrimary" component="h2">
                    {Folder.imageStoreFolder.Name}
                  </Typography>
                </div>
                <div className="pedagdocfolder-folder-time-block">
                  <Typography display="inline" align="justify">
                    {Moment(Folder.imageStoreFolder.Saved).format("DD.MM.YYYY")}
                  </Typography>
                </div>
              </div>
            </CardContent>
          </CardActionArea>
        </Card>
      </div>
    );
  }

  else {
    return(
      <div className="Folder" onClick={() => growthFolderFunctions["openFolder"](Folder.imageStoreFolder)}>
        <Card className="paddings">
          <CardActionArea>
            {Folder.MimeType !== "txt" && Folder.MimeType !== null && Folder.Base64Image !== null ? 
              <CardMedia 
                component ="img"
                height="160"
                image={"data:" + Folder.MimeType +";base64," + Folder.Base64Image}/>
            :
              <div className="pedagdocfolder-default-img" >
                <PhotoIcon style={{ fontSize: 160}}/>
              </div>
            }             
                <CardContent>
                  <div className="pedagdocfolder-folder-row">
                    <div className="pedagdocfolder-folder-type-block">
                      <AssignmentIcon />
                    </div>
                    <div className="pedagdocfolder-folder-name-block">
                      <Typography display="inline" variant="h6" color="textPrimary" component="h2">
                        {Folder.imageStoreFolder.Name}
                      </Typography>
                    </div>
                    <div className="pedagdocfolder-folder-time-block">
                      <Typography display="inline" align="justify">
                        {Moment(Folder.imageStoreFolder.Saved).format("DD.MM.YYYY")}
                      </Typography>
                    </div>
                  </div>
                </CardContent>
            </CardActionArea>
          </Card>
        </div>
    );
  }    
}

export default PedagDocFolder;
import React from "react";
import Button from "@mui/material/Button";
import InfoModal from "../InfoModal";

const MessagingPopUpModal = (props) => {
  
  return(

    <InfoModal
      modalOpen={props.isOpen}
      headerText={props.header}
    >
      <div className="infomodaltxt1">
        {props.infoMsgText} 
        <br/></div>
      <div className="infomodalbuttondiv1">
        {typeof props.button !== "undefined" ?
        <Button className="abandonbutton" variant="contained" color="secondary" onClick={props.button}>{props.buttonText}</Button>   
        :
        null  
        }
        {typeof props.button2 !== "undefined" ? 
        <div>&nbsp;
          <Button className="abandonbutton" variant="contained" color="primary" onClick={props.button2}>{props.buttonText2}</Button>
        </div>
        : null}
      </div>
    </InfoModal>


  )

}

export default MessagingPopUpModal;
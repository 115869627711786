import React from "react";
import BackToUpButton from "../GuideTools/BackToUpButton";
import AddAlarmIcon from "@mui/icons-material/AddAlarm";
import Languages from "../../../translations";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SelectAllIcon from "@mui/icons-material/SelectAll";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
const AvailableCareTimeAccordionText2 = () => {
  return (
    <div>
      <br />
      <p className="guide-accordion-details-texts">
        {
          "Voit tarkastella käytettävissä olevaa hoitoaikaa hoitoaikakalenterin "
        }
        <SummarizeOutlinedIcon />
        {
          " painikkeen takaa. SivuValikossa näkyy kuukaudelle merkitty hoidontarve, maksuluokka sekä käytetty aika. "
        }
      </p>
      <br />
      <br />
      <p className="guide-accordion-details-texts">
        {
          "Käytettyyn hoitoaikaan sisältyy kuukauden varatut tunnit sekä jo käytetyt tunnit, jotka ovat ylittäneet päiväkohtaisen varauksen. Tätä lukemaa tarkastelemalla näet, paljonko hoitoaikaa on tälle kuukaudelle vielä käytettävissä."
        }
      </p>
      <br />
      <br />
      <p className="guide-accordion-details-texts">
        {
          "Hoidontarpeen ja maksuluokan tiedot tulevat lapselle merkityistä taustatiedoista, joten jos ne puuttuvat, otathan yhteyttä lapsesi hoitopaikan henkilöstöön tai esihenkilöön. "
        }
      </p>
      <br />
      <br />
      <BackToUpButton />
    </div>
  );
};
2;
export default AvailableCareTimeAccordionText2;

import translations from '../translations';
import { types } from './actionTypes';

const LoginEmailReducer = (state, action) => {
  const payload = action.data;

  switch (action.type) {

    case types.PASSWORD_RESET_NEUTRAL:
      //console.log("LoginEmailReducer: received PASSWORD_RESET_NEUTRAL. Resetting status to NEVER_LOADED, code undefined and errorType undefined");
      return {
        ...state,
        passwordReset: {
          status: "NEVER_LOADED",
          Code: undefined,
          errorType: undefined,
        }
      }


    case types.LOADING_PASSWORD_RESET:
      return {
        ...state,
        passwordReset: {
          ...state.passwordReset,
          status: types.LOADING_PASSWORD_RESET,
          Code: undefined,
          errorType: undefined
        }
      }

    case types.PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        passwordReset: {
          ...state.passwordReset,
          status: types.PASSWORD_RESET_SUCCESS,
          Code: payload.Code,
          errorType: undefined,
        }
      }

    case types.PASSWORD_RESET_FAILED:
      console.log("reducer data", action.data);
      return {
        ...state,
        passwordReset: {
          ...state.passwordReset,
          status: types.PASSWORD_RESET_FAILED,
          Code: action.data.Code !== undefined ? action.data.Code : 777,
          errorType: action.data.errorType !== undefined ? action.data.errorType : { LocalizedErrorDetails: translations.errorUnknown },
        }
      }

    case types.LOADING_ORDER_USERNAME:
      return {
        ...state,
        orderUsername: {
          ...state.orderUsername,
          status: types.LOADING_ORDER_USERNAME,
          Code: undefined,
          errorType: undefined,
          newusername: undefined,
        }
      }

    case types.ORDER_USERNAME_SUCCESS:
      return {
        ...state,
        orderUsername: {
          ...state.orderUsername,
          status: types.ORDER_USERNAME_SUCCESS,
          Code: payload.Code,
          errorType: undefined,
          newusername: action.data,
        }
      }

    case types.ORDER_USERNAME_FAILED:
      return {
        ...state,
        orderUsername: {
          ...state.orderUsername,
          status: types.ORDER_USERNAME_FAILED,
          Code: action.data.Code !== undefined ? action.data.Code : 777,
          errorType: action.data.errorType !== undefined ? action.data.errorType : { LocalizedErrorDetails: translations.errorUnknown },
          newusername: undefined,
        }
      }

    case types.ORDER_USERNAME_RESET:
      return {
        ...state,
        orderUsername: {
          status: "NEVER_LOADED",
          Code: undefined,
          errorType: undefined,
          newusername: undefined,
        }
      }

    default:
      return state
  }

  //return state;
}

export default LoginEmailReducer;
import React, { useEffect, useMemo, useState } from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Stack              from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import ChildProfilePicture from '../ChildProfilePicture';
import Avatar from '@mui/material/Avatar';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CardHeader from '@mui/material/CardHeader';
import Collapse from '@mui/material/Collapse';



  
const DailyNote = ({Note, showAll}) => {

    const [expanded, setExpanded]   = useState(false);

    const handleExpand = () => {
        setExpanded(!expanded);
    }


        
    //regex magic and some other bs
    const tempReg = Note.Note.match(/.{1,77}(?:\s|$)/g);
    const NoteCollapsed = tempReg[0];
    const NoteExpanded = tempReg.slice(1).join(' ');
    /*if(showAll === false && Note.isValid === false){
        return(
            <></>
        )
    }*/
    return(
        <Card variant="outlined" sx={{ bgcolor: 'white', width: "100%"  }}>
        <CardActionArea disabled={Note.Note.length < 77 ? true : false} onClick={handleExpand}>
          <CardHeader
            alingItems= "left"
            avatar={<Stack direction="row" spacing={1}>
                        <Chip sx={{color: "black"}} avatar={<Avatar><ChildProfilePicture childId={Note.PersonId} /></Avatar>} label={Note.Name.split(',')[1] + " " + Note.Name.split(',')[0] }></Chip>
                        {/*<Chip variant={Note.isValid ? "filled" : "outlined"} color={Note.isValid ? "success" : "warning"} label={"Voimassa: " +  Note.ValidUntil}></Chip>*/}
                    </Stack> 
                    }  
          /> 
          <CardContent>
          <Typography textAlign="left" color="text.secondary">
            {NoteCollapsed}
            {<Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Typography textAlign="left" color="text.secondary">{NoteExpanded}</Typography>
                </Collapse>}
          </Typography>
          {Note.Note.length < 77 ? null :
            <Typography textAlign="center" color="text.secondary">
                <KeyboardArrowDownIcon sx= {{transform: !expanded ? 'rotate(0deg)' : 'rotate(180deg)', transition: 'transform 0.4s ease', marginLeft: 'auto'}}></KeyboardArrowDownIcon>
            </Typography>
            
            }
          </CardContent>
        </CardActionArea>
      </Card>
      ) 
  
}

export default DailyNote;



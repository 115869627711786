import React, {useCallback}   from 'react';
import IconButton             from "@mui/material/IconButton";
import Grid                   from "@mui/material/Grid";
import ChildNamePicture       from "../../ChildNamePicture";
import Divider                from "@mui/material/Divider";
import "../usedcaretimesinfodrawer.css";
import SummaryTopBanner       from "./SummaryTopBanner";
import Typography             from "@mui/material/Typography"; 
import Warning                from "@mui/icons-material/Warning";
import Languages              from "../../../translations";
import Popover                from "@mui/material/Popover";
import InfoIcon               from '@mui/icons-material/Info';
import Button                 from "@mui/material/Button";
import CancelIcon             from "@mui/icons-material/Cancel";


const NTSummaryLoadSuccess = (props) => {
  const {closeDrawer, overBookingWarning, childListStats, openInvoiceBasisView, childList, timespan} = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const processBgColor = useCallback((childList, childData) => {
      return (
        childList.reduce((accumulator, currentValue) => {
        if (childData.childPersonId === currentValue.Id) {
          accumulator = currentValue.BgColor;
        }
        return accumulator;
      })
    )
  }, [childList, childListStats]);

  return (
    <React.Fragment>
      <SummaryTopBanner 
        closeDrawer={closeDrawer}
        overBookingWarning={overBookingWarning}
        timespan={timespan}
      />

      {childListStats.map((childData, index) => {
          return (
            <div className="hoitoaikalaskuri" key={childData.childPersonId}>
              <ChildNamePicture 
                childId={childData.childPersonId}
                childName={childData.childName}         
                BgColor={() => processBgColor(childList, childData)} 
              />
            <div className="hoitoaikalaskuri2" data-testid={"ntsummary-kn3pspw0"+index}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={5}>
                  <Typography color="textSecondary">{Languages.uctServiceNeedType + ": "}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary">{childData.serviceneedType }</Typography>
                </Grid>
              </Grid>
            
              <Grid container wrap="nowrap" spacing={1} alignItems="center">
                <Grid item xs={5} zeroMinWidth>
                  <Typography style={{overflowWrap: "break-word"}} color="textSecondary">{Languages.uctPaymentCategory + ": "}</Typography>
                </Grid>
                <Grid  item xs={6}>
                  <Typography color="textSecondary">{childData.paymentcategory}</Typography>
                </Grid>
              </Grid>

              <Grid container wrap="nowrap" spacing={1} alignItems="center">
                <Grid item xs={5} zeroMinWidth>
                  <Typography style={{overflowWrap: "break-word"}} color="textSecondary">{"Ilmainen aika: "}</Typography>
                </Grid>
                <Grid  item xs={6}>
                  <Typography color="textSecondary">{childData.freeHours}</Typography>
                </Grid>
              </Grid>
              
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={5}>
                  <Typography color="textSecondary">{Languages.uctSpentTime + ": "}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={9}>
                      <Typography color="textSecondary">{childData.spentHoursOrDays}&nbsp;/&nbsp;{childData.maxSpendAvailable}&nbsp;&nbsp;{childData.specificChildOverBookingWarning === true ? <Warning data-testid="alt-029hdghe" color="error" alt="warning hours overbooked or overspent" width="28px" /> : <span data-testid="alt-false-093jsndj"></span>}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                    <div>

                      <IconButton
                        aria-describedby={id}
                        variant="contained"
                        color="secondary"
                        onClick={handleClick}
                        size="large">
                        <InfoIcon />
                      </IconButton>
                      <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                      >
                        <Typography>{Languages.uctInfo1}</Typography>
                      </Popover>
                    </div>

                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              

                    
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <Button 
                    color="secondary" 
                    variant="outlined"
                    id="test_NTSummary_openInvoiceDataTable_btn" 
                    onClick={() => openInvoiceBasisView(childData.childPersonId, childData.childName)}>
                      {Languages.uctInvoiceBasis}
                  </Button>
                </Grid>
              </Grid>
              
              </div>

              <Divider style={{marginTop: "15px"}} variant="middle" />

            </div>
          );
        })
      }

      <IconButton id="test_closeNTSummary_btn" style={{display: "inline-block"}} size="medium" aria-label="Back to previous" onClick={closeDrawer}>
        <Typography color="primary" ><CancelIcon data-testid="closeBtn-bdkwhjde"/></Typography>
      </IconButton>
    </React.Fragment>
  );
}

export default NTSummaryLoadSuccess;
import React, { useState, useCallback } from 'react';
import { StateContext, useStateValue } from "../../../../State";

import Languages from "../../../../translations";
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Warning from "@mui/icons-material/Warning";
import ProfilePicture from "../../../../Components/ProfilePicture";
import InfoModal from '../../../../Components/Modals/InfoModal';
import { history } from "../../../../Router";
import ButtonBase from "@mui/material/ButtonBase";
import {getLighterColor} from "../../../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    
    /*borderColor: 'red',
    borderWidth: '10px',
    borderStyle: "solid"*/

    // [theme.breakpoints.up('sm')]: {
    //   height: '150%'
    // },
  },
  detailsRoot: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    cursor: 'pointer',
  },
  details: {
    flexGrow: 1
  },
  profile: {
    flexGrow: 1,
    padding: 0,
    minWidth: 151,
  },
  content: {
    flex: '1 0 auto',
    position: 'relative'
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row-reverse',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  profileContent: {
    padding: '0px',
    paddingBottom: '0px',
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: '50%',
  }
}));

const useProfileStyles = makeStyles((theme) => ({
  root: {
    '&:last-child': {
      padding: '0 0 0 0',
    },
  }
}));

const ChildsName = ({childName}) => {
  const firstName =  childName.slice(childName.lastIndexOf(',')+1);
  const lastName = childName.slice(0, childName.indexOf(','));
  //console.log("lastName firstName", lastName, firstName);
  //<Typography style={{marginBottom: "0.5rem", whiteSpace: "pre-wrap"}} component="h2" color="textSecondary" variant="h6"> <span>{lastName + "\n"}</span> <span style={{fontWeight: "bold"}}>{firstName}</span> </Typography>
  return(
    <div>
      <Typography style={{whiteSpace: "pre-wrap"}} component="h2" color="textSecondary" variant="body1"> {lastName} </Typography>
      <Typography style={{marginBottom: "0.5rem", whiteSpace: "pre-wrap", fontWeight: "bold"}} component="h2" color="textSecondary" variant="h6"> {firstName} </Typography>
    </div>
  )
}

const ChildListItem = ({ onItemClick, counter, headerbgcolor, profilepicture, childName, notReserved, nextNursingTimeTime, nextNursingTimePL}) => {
  const classes = useStyles();
  const profileClasses = useProfileStyles();
  const [ShowNote, setShowNote] = useState(false);
  const [NoteTitle, setNoteTitle] = useState(null);
  const [NoteText, setNoteText] = useState(null);
  const { state } = useStateValue();

  const closeNote = useCallback(() => {
    //console.log("ChildListItem. closeVasuModal ");
    setShowNote(false);
  }, []);

  const  doNothing = (status) => {
    //console.log("SlideConfirmationNote. handleRightButtonn: ", status);
  };



  return (
    <React.Fragment>
      <InfoModal modalOpen={ShowNote} headerText={NoteTitle} onClose={closeNote} allowClose={true}>
        <Typography>{NoteText}</Typography>
      </InfoModal>
      <Card className={classes.root} id={counter} style={{ borderColor: headerbgcolor, borderWidth: '10px', borderStyle: "solid", backgroundColor: /*headerbgcolor === "#81D4FA" ? "#4377BA" :*/ getLighterColor(headerbgcolor) }} >
        <div className={classes.detailsRoot} >
        <CardContent className={classes.profileContent} classes={profileClasses} onClick={onItemClick}>
            <ProfilePicture profilepicture={profilepicture} onechilditem={true}/>
          </CardContent>
          <div className={classes.details}>
            <CardContent>
            <div style={{textAlign: "center"}}>
              <ButtonBase aria-label={"open " + childName + " page"} focusRipple id={"button" + counter} onClick={onItemClick}>
                <ChildsName childName={childName}/>
              </ButtonBase>
            </div>
              {notReserved ?
                <Typography style={{marginBottom: "0.15rem"}} variant="body2" color="textSecondary" onClick={onItemClick}>{Languages.bookingCareHoursMissing + " "} 
                  <Warning color="error" />
                </Typography>
                :
                nextNursingTimeTime ?
                  <div onClick={onItemClick}>
                    <Typography variant="body2" color="textSecondary" style={{marginBottom: "0.15rem", marginLeft: "11px"}}> {Languages.bookingNextBooked}:</Typography>
                    <div style={{borderWidth: "3px", borderStyle: "solid", borderColor: headerbgcolor, borderRadius: "5px", whiteSpace: "pre-wrap"}}>
                      <Typography style={{textAlign: "center"}} variant="body1" color="textSecondary">{nextNursingTimeTime.split(" ").join("\n")}</Typography>
                      <Typography style={{textAlign: "center"}} color="textSecondary" variant="body2">{nextNursingTimePL} </Typography>
                    </div>
                    
                    
                  </div>
                  : null}
                  
            </CardContent>
          </div>

        </div>
      </Card>
    </React.Fragment>
  );
}

export default ChildListItem;
import React, { useEffect, useState } from "react";
import { Modal, ModalBody, /*FormGroup, Label, */Input, Button } from "reactstrap";
import Moment from 'moment';

import "./defaultnursingtimes.css";
import Languages         from "../../../../translations";
import ClockIcon         from "../../../../images/times.svg";
import ContentBox        from "../../../../Components/ContentBox";
import SnackbarComponent from '../../../../Components/Snackbar';

const DefaultNursingTimes = ({ sTime, eTime, callback}) => {

  const [keyPress,      setKeyPress]      = useState(null);
  const [startTime,     setStartTime]     = useState(sTime);
  const [endTime,       setEndTime]       = useState(eTime);
  const [prevStartTime, setPrevStartTime] = useState(sTime);
  const [prevEndTime,   setPrevEndTime]   = useState(eTime);
  const [prevTimeType,  setPrevTimeType]  = useState("StartTime");
  const [HourChange,    setHourChange]    = useState(true);
  const [TimeChanged,   setTimeChanged]   = useState("NONE");
  const [firstChange,   setFirstChange]   = useState( true );
  const [showSnackbar,  setshowSnackbar]  = useState(false); 
  const [startTimeError, setstartTimeError] = useState(false); 
  const [endTimeError,   setendTimeError]   = useState(false); 

  //firstMin:       true,
  
  useEffect(() => {
    /*console.log("useEffect. TimeChanged: ",TimeChanged);
    console.log("startTime: ",Moment(startTime).format('HH:mm'), "endTime: ",Moment(endTime).format('HH:mm'));
    console.log("HH:mm: startTime >= endTime: ", Moment(startTime).format('HH:mm') >= Moment(endTime).format('HH:mm') ? true : false);*/

    if (TimeChanged !== "NONE")
    { 
      if (Moment(startTime).format('HH:mm') >= Moment(endTime).format('HH:mm'))
      {
        if (TimeChanged === "StartTime")
        { setstartTimeError(true);
          setendTimeError(false); 
        }
        else
        { setstartTimeError(false);
          setendTimeError(true); 
        }
      }
      else
      { setstartTimeError(false);
        setendTimeError(false); 
      }      
      setTimeChanged("NONE");
    }
   },[TimeChanged,startTime,endTime ]);

   useEffect(() => {
    //console.log("useEffect. startTime set: ",Moment(startTime).format('HH:mm'), "endTime set: ",Moment(endTime).format('HH:mm'));
    //setKeyPress(null);
   },[startTime, endTime]); 

   useEffect(() => {
    //console.log("useEffect. startTimeError: ",startTimeError, " - endTimeError: ",endTimeError);
   },[startTimeError, endTimeError]); 
   

  const saveNursingTimes = () => {
    var data = {StartTime: Moment(startTime), EndTime: Moment(endTime)};
    callback("SAVINGDEFAULTTIMES", data);
  };

  const handleSnackbarCB = () => {
    setshowSnackbar(false);
  };
  const closeModal = (status) => {
    /*console.log("DefaultNursingTimes. Close info note. Status: ",status+
                "\n defaultNursingTimes.state: ", defaultNursingTimes.state);*/
    if (status === "CLOSECOMPONENT" )
    {
      callback("CLOSECOMPONENT", null);
    }
  };

  const handleKeyDown = (event, type) => {
    //console.log("DefaultNursingtime. handleKeyDown. event.key: ",event.key);
    switch(event.key)
    {
      case 'ArrowUp':
      case "ArrowDown":
        setKeyPress(event.key);
        setFirstChange(true);
        break;
      case "0":
      case "1":
      case "2":
      case "3":
      case "4":
      case "5":
      case "6":
      case "7":
      case "8":
      case "9":
        setKeyPress("Numkey"); 
        break;
      case "Tab":
      case "ArrowLeft":
      case "ArrowRight":  
        if (prevTimeType === "StartTime")
        {
          //console.log("DefaultNursingtime. handleKeyDown. "+event.key+". Round Startime minutes"); 
          //console.log("Start time: ", Moment(startTime).format('HH:mm') );
          
          let min = roundMinutes(Moment(startTime).format('mm'));
          setStartTime(Moment(startTime).minute(min));
          setPrevStartTime(Moment(startTime).minute(min));
        } 
        else
        {
          //console.log("DefaultNursingtime. handleKeyDown. "+event.key+". Round Endtime minutes");
          //console.log("End time: ", Moment(endTime).format('HH:mm') );
          let min = roundMinutes(Moment(endTime).format('mm'));
          setEndTime(Moment(endTime).minute(min));
          setPrevEndTime(Moment(endTime).minute(min));
        }     
        setFirstChange(true);
        setTimeChanged(prevTimeType);
        break;
      default:
        setKeyPress(null); 
        break;
    }
  }

  const changeTime = (event, type) => {
    var time = event.target.value.split(":");
    var hour = time[0];
    var minutes = time[1];

    /*console.log("DefaultNursingtimes. ChangeTime."+
                "\n Prev Type: "+ prevTimeType+ " Selected Type: "+ type+
                "\n firstChange: "+ firstChange+" HourChange: "+HourChange+
                "\n keypress: ", keyPress);*/

    let prevHour = null;
    let prevMin = null;
    if (type === "StartTime")
    {
      prevHour = Moment(prevStartTime).format('HH');
      prevMin = Moment(prevStartTime).format('mm');

      let min = roundMinutes(Moment(prevEndTime).format('mm'));
      setEndTime(Moment(prevEndTime).minute(min));
      setPrevEndTime(Moment(prevEndTime).minute(min));
    }
    else
    {
      prevHour = Moment(prevEndTime).format('HH');
      prevMin = Moment(prevEndTime).format('mm');

      let min = roundMinutes(Moment(prevStartTime).format('mm'));
      setStartTime(Moment(prevStartTime).minute(min));
      setPrevStartTime(Moment(prevStartTime).minute(min));
    }

    /*console.log("Prev Time ",prevHour,":",prevMin, " => New Time ", hour,":",minutes);
    console.log(prevHour !== hour ? "Tunti muutettu" : "Minuutti muutettu");
    console.log("Edellinen muutos oli tunti: ",HourChange);*/

    if ( keyPress === 'ArrowUp' || keyPress === "ArrowDown" || keyPress === null || // arrow or dropdown always first
         type !== prevTimeType || firstChange ||
        (prevHour !== hour && HourChange === false ) ||
        (prevMin  !== minutes && HourChange === true) ) 
    {
      console.log(type !== prevTimeType                       ? "EKA NUMERO, vaihdettu start<->end " :
                  prevHour !== hour && HourChange === false   ? "EKA NUMERO, vaihdettu minuuteista tunteihin" :
                  prevMin  !== minutes && HourChange === true ? "EKA NUMERO, vaihdettu tunneista minuutteihin" :
                  keyPress === null                           ? "EKA NUMERO, Dropdown" : "EKA NUMERO, näppäin: " +keyPress);
      if ( (prevHour !== hour && HourChange === false) ||
           (keyPress === 'ArrowUp' || keyPress === "ArrowDown" || keyPress === null) )
      {
        minutes = roundMinutes(minutes);
        //console.log("Pyöristetty minuutti", minutes);
      }
      
      setFirstChange(keyPress === 'ArrowUp' || keyPress === "ArrowDown" || keyPress === null ? true : false);
      setPrevTimeType(type);
      setHourChange( prevHour === hour ? false : true );
      setKeyPress(null); // resetoi numeron syöttö lähde

      if (type === "StartTime") 
      { 
        setStartTime(Moment(startTime).hour(hour).minute(minutes));
        setPrevStartTime(Moment(startTime).hour(hour).minute(minutes));
      }
      else // EndTime
      { setEndTime(Moment(endTime).hour(hour).minute(minutes));
        setPrevEndTime(Moment(endTime).hour(hour).minute(minutes));
      }
      setTimeChanged(type);
    }
    else 
    {
      console.log("TOINEN NUMERO");

      //console.log( type === "StartTime" ? "Pyöristä Start minuutit" : "Pyöristä End minuutit" );
      minutes = roundMinutes(minutes);
      //console.log("Pyöristetty minuutti", minutes);
     
      setFirstChange(true);
      setKeyPress(null); 

      //Fail case, never happen??
      if (time.length !== 2) 
      { callback("ERROR",Moment(startTime).hour(hour).minute(minutes)); }
      else if (type === "StartTime") 
      {  
        setStartTime( Moment(startTime).hour(hour).minute(minutes));
        setPrevStartTime(Moment(startTime).hour(hour).minute(minutes));
      }
      else // EndTime
      {  
        setEndTime( Moment(endTime).hour(hour).minute(minutes)); 
        setPrevEndTime(Moment(endTime).hour(hour).minute(minutes));
      }
      setTimeChanged(type);
    } // else if
  }; // const

  const roundMinutes = (min) => {
    if (min === "00" || (0 < min &&  min < 8) )
    { min = 0; }
    else if ( 7 < min &&  min < 23 )
    { min = 15; }
    else if ( 22 < min &&  min < 38 )
    { min = 30; }
    else if (37 < min && min < 53)
    { min = 45; }
    else
    { min = 0;
      //hour++; 
    }
    return min;
  }

      return (
        <div className="DefaultTImes">
          <Modal isOpen={true}>
            <ModalBody style={{marginTop:"50%"}} >
              <ContentBox header={Languages.bookingCareHoursDefaultTitle} showCloseicon={true} setfunction={() => closeModal("CLOSECOMPONENT")} >
                <div className="textCenter">
                  <div className="container">
                    <div className="row" /*style={{ padding: 5 }}*/ >
                      <div className="col-2">
                        <img id="test_DefaultNursingTimes_Icon" src={ClockIcon} alt="TIME" width="30px" />
                      </div>
                      <div className="Alkukello col-5">
                        {startTimeError ?
                          <Input id="test_DefaultNursingTimes_StartTime" 
                                 style={{color: "red"}}
                                 type="time" 
                                 step="900"
                                 
                                 value={Moment(startTime).format('HH:mm')} 
                                 onKeyDown={(e) => { handleKeyDown(e, "StartTime") }}
                                 onChange={(e) => { changeTime(e, "StartTime") }} 
                                 required
                                 invalid/>                         
                        :
                          <Input id="test_DefaultNursingTimes_StartTime" 
                                 type="time" 
                                 step="900" 
                                 value={Moment(startTime).format('HH:mm')} 
                                 onKeyDown={(e) => { handleKeyDown(e, "StartTime") }}
                                 onChange = {(e) => { changeTime(e, "StartTime")}}
                                 required
                                 valid/> 
                        }
                      </div>
                      <div className="Loppukello col-5"> 
                        {endTimeError ?
                          <Input id="test_DefaultNursingTimes_EndTime" 
                                 type="time" 
                                 style={{color: "red"}}
                                 step="900" 
                                 value={Moment(endTime).format('HH:mm')} 
                                 onKeyDown={(e) => { handleKeyDown(e, "EndTime") }}
                                 onChange={(e) => { changeTime(e, "EndTime") }} 
                                 required
                                 invalid/>
                        :
                          <Input id="test_DefaultNursingTimes_EndTime" 
                                 type="time" 
                                 step="900" 
                                 value={Moment(endTime).format('HH:mm')} 
                                 onKeyDown={(e) => { handleKeyDown(e, "EndTime") }}
                                 onChange={(e) => { changeTime(e, "EndTime") }} 
                                 required
                                 valid/>
                        } 
                      </div>
                    </div>
                  </div>

                  <div className="container">
                    <div className="row">
                      <Button id="test_DefaultNursingTimes_Cancel" 
                              className="pinkButton border-0" 
                              onClick={() => closeModal("CLOSECOMPONENT")}  
                              style={{width: "45%"}}> 
                              {Languages.generalBack} 
                      </Button>
                      {startTime === null || endTime === null || startTimeError || endTimeError  ? null :
                        <Button id="test_DefaultNursingTimes_Save" 
                                className="styledButton border-0" 
                                onClick={saveNursingTimes}                    
                                style={{width: "45%"}}> 
                                {Languages.bookingCareHoursDefaultSave}
                        </Button>
                      }
                    </div>
                  </div>
                </div>
              </ContentBox>
            </ModalBody>
          </Modal>

          <SnackbarComponent verticalPosition={'bottom'} show={showSnackbar} message ="Tarkista varausajat" callback={() => handleSnackbarCB()} />

        </div> /* className="CareTime"*/
      ); /* return child calender*/
    }

export default DefaultNursingTimes;
import React, { useEffect, useState } from "react";

import { useStateValue } from "../../State";
import { types } from "../../Reducers/actionTypes";

import DailyNote from "./DailyNote";
import Languages from "../../translations";

import moment from "moment";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import IconButton from "@mui/material/IconButton";
import translations from "../../translations";

const DailyNotes = () => {
  const { actions, state } = useStateValue();

  const NotesData = state.dailynote;

  const [page, setPage] = useState(0);
  const [LoadingData, setLoadingData] = useState(false);
  const [pageSet, setPageSet] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const theme = useTheme();
  const isSmallOrLess = useMediaQuery(theme.breakpoints.up("lg"));
  const [init, setInit] = useState(false);
  const [dir, setDir] = useState("left");
  const [month, setMonth] = useState(moment().month() + 1);
  const [year, setYear] = useState(moment().year());

  useEffect(() => {
    switch (NotesData.state) {
      case undefined:
      case "INITIAL_STATE":
        if (LoadingData === false) {
          actions.triggerLoadDailyNotes(
            "year=" + year.toString() + "&" + "month=" + month.toString()
          );
          setLoadingData(true);
        }
        break;
      case types.DAILY_NOTES_LOAD_OK:
        setPageSet(false);
        setLoadingData(false);
        if (init === false) {
          setInit(true);
          handlePage();
          console.log("initti");
        } else {
          if (dir === "left") {
            const weeks = Object.keys(NotesData.WeekList).length;
            setPage(weeks - 1);
            console.log("leftii meni");
          } else {
            setPage(0);
            console.log("rightihan se oli meni");
          }
        }
        break;
      case types.DAILY_NOTES_LOAD_FAILED:
        setLoadingData(false);
        break;
      default:
        break;
    }
  }, [NotesData.state]);

  const handleChange = () => {
    setShowAll(!showAll);
  };

  const handlePage = () => {
    NotesData.WeekList.forEach((element, index) => {
      if (element.WeekNumber == moment().week()) {
        setPage(index);
        setPageSet(true);
        console.log("testitesti");
      }
    });
    setInit(true);
  };

  const onWeekChange = (dir) => {
    setDir(dir);
    const weeks = Object.keys(NotesData.WeekList).length;
    if (dir === "left") {
      const pg = page - 1;
      if (pg < 0) {
        if (month === 1) {
          setMonth(12);
          setYear(year - 1);
        } else {
          setMonth(month - 1);
        }
        NotesData.state = "INITIAL_STATE";
      } else {
        setPage(pg);
      }
    } else if (dir === "right") {
      const pg = page + 1;
      if (pg > weeks - 1) {
        if (month === 12) {
          setMonth(1);
          setYear(year + 1);
        } else {
          setMonth(month + 1);
        }
        NotesData.state = "INITIAL_STATE";
      } else {
        setPage(pg);
      }
    }
  };

  const handleDayName = (date) => {
    var daynumber = moment(date).isoWeekday();
    switch (daynumber) {
      case 1:
        return Languages.monday;
      case 2:
        return Languages.tuesday;
      case 3:
        return Languages.wednesday;
      case 4:
        return Languages.thursday;
      case 5:
        return Languages.friday;
      case 6:
        return Languages.saturday;
      case 7:
        return Languages.sunday;
    }
  };

  const getErrorString = () => {
    var statusCode = NotesData.Code;
    switch (statusCode) {
      case 404:
        return Languages.errorNotFound;
      case 500:
        return Languages.errorInDB;
      case 502:
        return Languages.errorNetwork;
      default:
        return Languages.errorUnknown;
    }
  };

  //Return DailyNote cards if loading ok
  if (NotesData.state === types.DAILY_NOTES_LOAD_OK && LoadingData === false) {
    return (
      <div className="col-12">
        <Stack alignItems="center" spacing={2}>
          <Stack alignItems="center" spacing={1} sx={{ mb: 2 }}>
            <Stack
              direction="row"
              alignItems="center"
              spacing={3}
              sx={{ my: 2 }}
            >
              {isSmallOrLess ? (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ ml: 1 }}
                  onClick={() => onWeekChange("left")}
                >
                  {Languages.prev}
                </Button>
              ) : (
                <IconButton
                  disableRipple={true}
                  variant="contained"
                  size="small"
                  sx={{ backgroundColor: "primary.main", color: "white" }}
                  onClick={() => onWeekChange("left")}
                >
                  <ArrowLeftIcon />{" "}
                </IconButton>
              )}

              <Chip
                color="primary"
                label={
                  <Typography
                    className="col-3"
                    textAlign="center"
                    color="text.primary"
                  >
                    {Languages.generalWeek +
                      " " +
                      NotesData.WeekList[page].WeekNumber}
                  </Typography>
                }
              ></Chip>
              {isSmallOrLess ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => onWeekChange("right")}
                >
                  {Languages.next}
                </Button>
              ) : (
                <IconButton
                  disableRipple={true}
                  variant="contained"
                  size="small"
                  sx={{ backgroundColor: "primary.main", color: "white" }}
                  onClick={() => onWeekChange("right")}
                >
                  <ArrowRightIcon />{" "}
                </IconButton>
              )}
            </Stack>
            {/*<Box  display="flex" alignItems="center" justifyContent="center" sx={{backgroundColor: 'primary.main', color: "white", borderRadius: 5, px: 2}} >
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography>Näytä voimassaolevat</Typography>
                                    <Switch showAll={showAll} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} color="default"/>
                                    <Typography>Näytä Kaikki</Typography>
                                </Stack>
                            </Box>*/}
          </Stack>

          {NotesData.WeekList[page].DayList.map((day) => (
            <>
              <Divider variant="inset" textAlign="left">
                <Chip
                  label={
                    handleDayName(day.Date).substring(0, 2) +
                    " " +
                    moment(day.Date).format("DD.MM.yyyy")
                  }
                  color="primary"
                />
              </Divider>
              {day.Notes.length <
              1 /*|| (day.Notes.some((x) => x.isValid === true) === false && showAll === false)*/ ? (
                <Typography textAlign="left" color="text.secondary">
                  {translations.actionNotesNull}
                </Typography>
              ) : (
                <>
                  {day.Notes.map((Note) => (
                    <DailyNote Note={Note} showAll={showAll} />
                  ))}
                </>
              )}
            </>
          ))}
        </Stack>
      </div>
    );
  }

  //Return Error Card if loading fails
  if (NotesData.state === types.DAILY_NOTES_LOAD_FAILED) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ mt: 2 }}
      >
        <Card variant="outlined" sx={{ bgcolor: "white" }}>
          <CardContent>
            <Stack alignItems="center" spacing={1}>
              <Typography textAlign="left" color="text.secondary">
                {getErrorString()}
              </Typography>
              <Typography textAlign="left" color="text.secondary">
                {"Merkintöjen lataus epäonnistui"}
              </Typography>
            </Stack>
          </CardContent>
        </Card>
      </Box>
    );
  }

  //Show loading circle while loading dailyNotes
  return (
    <div>
      <CircularProgress />
    </div>
  );
};

export default DailyNotes;

import React from "react";
import "../newmessage.css";
import { Label } from "reactstrap";
import Languages from "../../../../translations";
import AddIcon from "@mui/icons-material/Add";
import GroupTwoTone from "@mui/icons-material/GroupTwoTone";
import FaceTwoTone from "@mui/icons-material/FaceTwoTone";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import IconButton from '@mui/material/IconButton';
import {isAnyPropUndefinedOrNull} from "../../../../utils";

const RecipientPills = (props) => {

  const {currentlyCheckedList, allPotentialRecipients, openSelectRecipientsView, pillClicked} = props;

  const undefinedOrNullPropExists = isAnyPropUndefinedOrNull(props);

  if (undefinedOrNullPropExists) {
    return(
      <div data-testid="RecipientPills-undefined-or-null"></div>
    )
  }
  else {
    return(
      <React.Fragment>
      <div style={{marginLeft: "6px"}}>
      <Grid container spacing={3}>

        <Grid item xs={10}>

          <Label for="recipients" className="font47">
            <IconButton 
              size="small" 
              style={{marginTop:"-4px", marginRight:"10px", backgroundColor:"#4377BA"}} 
              id="test_NewMsg_More_Recipients_Btn" 
              color="primary" 
              aria-label={Languages.msgAddrecipientshere} 
              onClick={openSelectRecipientsView}> <AddIcon /> 
            </IconButton>{Languages.msgRecipients +":"}<br/>
          <React.Fragment>
              {currentlyCheckedList.map((checkedItem, index) => {
                return ( 

                  allPotentialRecipients.map((jr, index) => {

                    if (checkedItem === jr.Id) {
                      return (
                        <div className="chippadding" style={{marginTop:"4px", marginLeft:"30px"}}
                          key={"listing123" + jr.Id + index}>
                          <Chip display="inline" avatar={
                            <Avatar>

                              {jr.Type === "group" || jr.Type === "centre" ? <GroupTwoTone /> : <FaceTwoTone />}

                            </Avatar>
                          } id={jr.Id} color={jr.Type === "parent_person" ? "secondary" : "secondary"} onDelete={() => pillClicked(jr.Id)} label={jr.Name} />
                        </div>)
                    }
                    else {
                      return (<div key={"listing124" + jr.Id + index}></div>);
                    }
                  })

                )
              })}
            </React.Fragment>

          </Label>
        </Grid>
        <Grid item xs={2}>
          <React.Fragment>
                        <br />
          </React.Fragment>

        </Grid>
        <br />

      </Grid>
      </div>
      <br />
    </React.Fragment>
    )
  }
}

export default RecipientPills;

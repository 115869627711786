import { useActions } from "./actionCreators";
import { types } from "./actionTypes";

const MainStatsReducer = (state, action) => {
  const payload = action.data;
  switch (action.type) {
    case types.LOADING_MAINSTATS:
      //console.log("MainStatsReducer, loading all mainstats");
      return {
        ...state,
        state: types.LOADING_MAINSTATS,
      };
    case types.LOAD_SUCCESS_MAINSTATS:
      return {
        ...state,
        Code: 200,
        state: types.LOAD_SUCCESS_MAINSTATS,
        lastSuccessTime: action.lastSuccessTime,
        //notReadMessages: payload.NotReadMessages, ei käytössä tällä hetkellä, mutta backendissa koodia
        notReadThreads: payload.NotReadThreads,
        nursingTimesStatusAllDone: payload.NursingTimesStatus.AllDone,
        listChildNursingTimeStatus:
          payload.NursingTimesStatus.listChildNursingTimeStatus,
        TotalUnansweredPermissions: payload.TotalUnansweredPermissions,
        TotalUnansweredChildPermPermissions:
          payload.TotalUnansweredChildPermPermissions,
        UnansweredParentConsents: payload.UnansweredParentConsents,
        UnansweredChildPermPermissions: payload.UnansweredChildPermPermissions,
        UnansweredShortTermPermissions: payload.UnansweredShortTermPermissions,
        lastSuccessTimeThread: action.lastSuccessTimeThread,
        lastSuccessTimeNursingtime: action.lastSuccessTimeNursingtime,
        lastSuccessTimePermissions: action.lastSuccessTimePermissions,
        AvailableDiscussionBookableDatesCount:
          payload.AvailableDiscussionBookableDatesCount,
        // TODO: pitäisikö olla myös lastErrorTimeja?
      };
    case types.LOAD_FAILED_MAINSTATS:
      return {
        ...state,
        Code: payload.Code,
        state: types.LOAD_FAILED_MAINSTATS,
        lastErrorTime: action.lastErrorTime,
      };

    //THREAD
    case types.LOADING_THREAD_MAINSTATS:
      //console.log("MainStatsReducer, loading thread mainstats");
      return {
        ...state,
        ThreadCode: 200,
        ThreadStatus: types.LOADING_THREAD_MAINSTATS,
      };

    case types.LOAD_THREAD_MAINSTATS_SUCCESS:
      return {
        ...state,
        ThreadCode: action.Code,
        ThreadStatus: types.LOAD_THREAD_MAINSTATS_SUCCESS,
        notReadThreads: payload.NotReadThreads,
        lastSuccessTimeThread: action.lastSuccessTimeThread,
      };

    case types.LOAD_THREAD_MAINSTATS_FAILED:
      return {
        ...state,
        ThreadCode: action.Code,
        ThreadStatus: types.LOAD_THREAD_MAINSTATS_FAILED,
      };

    // NURSINGTIME
    case types.LOADING_NURSINGTIME_MAINSTATS:
      //console.log("MainStatsReducer, loading nursingtime mainstats");
      return {
        ...state,
        NursingtimeCode: 200,
        NursingtimeStatus: types.LOADING_NURSINGTIME_MAINSTATS,
      };

    case types.LOAD_NURSINGTIME_MAINSTATS_SUCCESS:
      return {
        ...state,
        NursingtimeCode: action.Code,
        NursingtimeStatus: types.LOAD_NURSINGTIME_MAINSTATS_SUCCESS,
        nursingTimesStatusAllDone: payload.NursingTimesStatus.AllDone,
        listChildNursingTimeStatus:
          payload.NursingTimesStatus.listChildNursingTimeStatus,
        lastSuccessTimeNursingtime: action.lastSuccessTimeNursingtime,
      };

    case types.LOAD_NURSINGTIME_MAINSTATS_FAILED:
      return {
        ...state,
        NursingtimeCode: action.Code,
        NursingtimeStatus: types.LOAD_NURSINGTIME_MAINSTATS_FAILED,
      };

    // PERMISSIONS
    case types.LOADING_PERMISSIONS_MAINSTATS:
      //console.log("MainStatsReducer, loading permissions mainstats");
      return {
        ...state,
        PermissionsCode: 200,
        PermissionsStatus: types.LOADING_PERMISSIONS_MAINSTATS,
      };

    case types.LOAD_PERMISSIONS_MAINSTATS_SUCCESS:
      return {
        ...state,
        PermissionsCode: action.Code,
        PermissionsStatus: types.LOAD_PERMISSIONS_MAINSTATS_SUCCESS,
        lastSuccessTimePermissions: action.lastSuccessTimePermissions,
        TotalUnansweredPermissions: payload.TotalUnansweredPermissions,
        TotalUnansweredChildPermPermissions:
          payload.TotalUnansweredChildPermPermissions,
        UnansweredParentConsents: payload.UnansweredParentConsents,
        UnansweredChildPermPermissions: payload.UnansweredChildPermPermissions,
        UnansweredShortTermPermissions: payload.UnansweredShortTermPermissions,
      };

    case types.LOAD_PERMISSIONS_MAINSTATS_FAILED:
      return {
        ...state,
        PermissionsCode: action.Code,
        PermissionsStatus: types.LOAD_PERMISSIONS_MAINSTATS_FAILED,
      };

    //case types.LOADING_MESSAGE_MAINSTATS
    //console.log("MainStatsReducer, loading permissions mainstats");

    // case types.SET_MAINNAV_OPEN:
    //   return {
    //     ...state,
    //     mainNavStatus: {
    //       open: action.data
    //     }
    //   }

    default:
      return state;
  }
};

export default MainStatsReducer;

/* TODO: (backend tukee jo tätä)
    [{"PersonID":"c2bb0ea1-c5fa-...", "Data":"messagenursingtime"}]
    getmainstatseihin tuo data uutena, jos sitä ei aseteta niin antaa kaikki kuten ennenkin
    jos haluaa tarkemmin määrittää mitä haluaa niin kun string sisältää sanan "message", tulee viestien lukumäärä, 
    "thread" tulee thredien lukumäärä, "nursingtimes" tulee hoitoaikavarausten status
    eli jos haluaa kaikki niin data muotoon :"messagethreadnursingtime"
    järjestyksellä ei ole merkitystä, ei myöskään case sensitive 
    */

import React from 'react';
import PedagDocImageFailed  from  "./Components/PedagDocImageFailed";
import PedagDocImageLoading from "./Components/PedagDocImageLoading";
import PedagDocImageSuccess from './Components/PedagDocImageSuccess';
import Languages              from "../../../translations";


const PedagDocImageViewManager = ({ growthFolder, pedagDocImageFunctions, errorCode }) => {
  const returnCorrectImageView = (growthfolderState)=>{

    const imageStateOptions = {
      
      "LOADING_IMAGE": ()=> {
        return (
          <PedagDocImageLoading />
        );
      },

      "LOAD_IMAGE_SUCCESS": ()=> {
        return (
          <PedagDocImageSuccess 
            growthFolder = {growthFolder}
            pedagDocImageFunctions = {pedagDocImageFunctions} 
          />
        );
      },

      "LOAD_IMAGE_FAILED": ()=> {
        return (
          <PedagDocImageFailed
            pedagDocImageFunctions = {pedagDocImageFunctions} 
            errorCode = {errorCode}
          />
        );
      },

      "default": ()=> {
        return (
          <div>{Languages.errorUnknown + "(777)."}</div>
        );
      }
    };

    return (imageStateOptions[growthfolderState] || imageStateOptions["default"])();
  }

  return(
    <>
      {returnCorrectImageView(growthFolder.state)}
    </>
  );

}

export default PedagDocImageViewManager;
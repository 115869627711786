import { types } from './actionTypes';

const NotificationReducer = (state, action) => {
  const payload = action.data;

  switch (action.type) {
    case types.SHOW_NOTIFICATION:
      return {
        ...state,
        status: "SHOW_NOTIFICATION",
        ...payload
      }
    case types.RESET_AUTOLOGOUT_TIMER:
      return {
        ...state,
        status: "RESET_TIMER",
        ...payload
      }
  }
  return state;
}

export default NotificationReducer;
import React from "react";
import Languages from "../../../translations";
import { Typography, Box, Button } from "@mui/material";
//import { Link } from "react-router-dom";
import { modes } from "..";

const EsuomiFirstTime = ({ authOptions, onLogin }) => {
  return (
    authOptions === modes.passwordAndStrong &&
    <Box mt={1}>
      <Typography align="center" color="textSecondary">{Languages.firstTimer}</Typography>
      <div className="row justify-content-center">
        <Button id="test_firstTimerLink" variant="outlined" color="secondary" onClick={onLogin}>{Languages.loginHere}</Button>
      </div>
    </Box>
  )
};

export default EsuomiFirstTime;
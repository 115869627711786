import React, { Component, useEffect, useState } from "react";
//import SwipeableViews       from 'react-swipeable-views';
//import { autoPlay }         from 'react-swipeable-views-utils';
import { StateContext /*useStateValue*/ } from "../../State";
import { types } from "../../Reducers/actionTypes";

import {
  Modal,
  ModalBody /*FormGroup, Label, Input, Button*/,
} from "reactstrap";

import "./childlist.css";
import Languages from "../../translations";
import { MAINSTAT_CONSTS } from "../../Contants";
import ChildListItem from "./Components/ChildListItem";
import ContentBox from "../../Components/ContentBox";
import ContentBoxMUI from "../../Components/ContentBoxMUI";
import GrowthFolder from "../../Layouts/GrowthFolder";
import BulletinBoard from "../../Components/BulletinBoard";
import FloatingNav from "../../Components/FloatingNav";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Grid } from "@mui/material";
import { LOGOUT_REASONS } from "../../Api";
import PermPermissionNote from "../PermPermissionPopUp/PermPermissionNote";
import MissingVacationBookingsNote from "../../Components/MissingVacationBookingsNote/MissingVacationBookingsNote";
import useMediaQuery from "@mui/material/useMediaQuery";
import BottomNavigation from "../../Components/BottomNavigation";
import { getProfilePictureData } from "../../utils";

class ChildList extends Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);
    this.state = {
      infoModalOpen: false,
      errorModalOpen: false,
      errorText: null,
      selectedChildId: null,
      selectedChildName: null,
      selectedChildFirstName: null,
      selectedChildImage: null,
      selectedChildColor: null,
      selectedChildNextNursingTime: null,
      selectedChildNextNursingTimePlacement: null,
      profilePicturesHandled: false,
    };
    this.strings = Languages;
  }

  componentDidMount() {
    /*console.log("ChildList. Mount. State: ",this.state);*/
    //this.context.actions.triggerLoadMainStats();
    //!
    //ChildList mountataan aina kun bäkitettään takas -> lapsilista -> nextnurstingtime
    //console.log("ChildList. Mount. Read childlist");

    const { children, messaging } = this.context.state;
    if (children.state != types.LOADING_CHILDREN)
      this.context.actions.triggerLoadChildren();

    this.context.actions.triggerLoadProfilePictures();
    this.context.actions.triggerLoadNextNursingTimes();
    this.setState({ infoModalOpen: true });
  }

  componentDidUpdate() {
    const { children } = this.context.state;

    /*console.log("ChildList. Update"+
                //"window.pageYOffset: ", window.pageYOffset);
                "State:\n"+"",this.state);*/
    this.handleChildren();
    this.handleProfilePictures();
    this.handleNextNursingTimes();
    //this.handleMainStats();

    if (
      children.state === types.LOAD_SUCCESS_CHILDREN &&
      this.state.profilePicturesHandled === true &&
      children.ChildList.length === 1
    ) {
      //console.log("ChildList. Update. All data read and just one child");
      this.props.history.push("/childInfo", {
        childId: children.ChildList[0].Id,
        childName: children.ChildList[0].Name,
        childFirstName: children.ChildList[0].FirstName,
        childColor: children.ChildList[0].BgColor,
        showBackArrow: false,
        VasuId: children.ChildList[0].VasuId,
        LeopsId: children.ChildList[0].LeopsId,
      });
    }
  }

  componentWillUnmount() {
    //console.log("ChildList. Unmount");
  }

  //handleMainStats = () => {
  //const { /*nursingTimesStatusAllDone,*/ listChildNursingTimeStatus } = this.context.state.mainstats;

  /*console.log("ChildList. handleMainStats: "+
              //"\n mainstats: ", mainstats+
              "\n listChildNursingTimeStatus: ", listChildNursingTimeStatus);*/
  //};

  handleChildren = () => {
    const { children, nursingtimes } = this.context.state;

    /*console.log("ChildList. handleChildren: "+
                "\n children.state: ", children.state,
                "\n children: ", children);*/

    switch (children.state) {
      case types.LOADING_CHILDREN:
        //console.log("ChildList. LOADING_CHILDREN. Profilepicture handled/state: ",this.state.profilePicturesHandled,"/", profilePictures.state);
        break;
      case types.LOAD_FAILED_CHILDREN:
        //console.log("ChildList. LOAD_FAILED_CHILDREN");
        this.context.actions.triggerLoadChildrenHandled();
        this.setState({
          infoModalOpen: false,
          errorModalOpen: true,
          errorText:
            children.Code === 400
              ? this.strings.errorInformationMissing
              : children.Code === 404
              ? this.strings.clChildrenNotFound
              : children.Code === 500
              ? this.strings.errorInDB
              : children.Code === 502
              ? this.strings.errorNetwork
              : this.strings.errorUnknown,
        });
        break;
      case types.LOAD_SUCCESS_CHILDREN:
        if (
          this.context.state.profilePictures.state !==
            "LOAD_SUCCESS_PROFILEPICTURES" &&
          this.context.state.profilePictures.state !== "LOADING_PROFILEPICTURES"
        ) {
          //this.context.actions.triggerLoadProfilePictures();
        }
        // console.log(nursingtimes.nextNursingTimes.state);
        // if (nursingtimes.nextNursingTimes.state != types.NEXTNURSINGTIMES_READ
        //   || nursingtimes.nextNursingTimes.state != types.READING_NEXTNURSINGTIMES) {
        //     this.context.actions.triggerLoadNextNursingTimes();
        //   }

        //this.conditionallyCallLoadMainStats();

        // this.setState({
        //   infoModal: false,
        //   errorModalOpen: false,
        //   profilePicturesHandled: false
        // });
        break;
      default:
        break;
    }
  };

  conditionallyCallLoadMainStats = () => {
    const d1 = new Date();
    const timeNow = d1.getTime();

    const timeSinceLastSuccess =
      timeNow - this.context.state.mainstats.lastSuccessTime;
    // do not call too often if something else has already called this
    const minimumTimeInSecs = MAINSTAT_CONSTS.childListMinimumTimeSecs;
    if (
      timeSinceLastSuccess > minimumTimeInSecs * 1000 &&
      this.context.state.mainstats.state !== types.LOADING_MAINSTATS
    ) {
      //console.log("Triggering load ALL mainstats from ChildList, time since all were last loaded in seconds: ", timeSinceLastSuccess / 1000);
      this.context.actions.triggerLoadMainStats();
    } else {
      //console.log("*NOT* triggering load ALL mainstats from ChildList, needs to be more than " + minimumTimeInSecs + "secs since last, time since all were last loaded in seconds: ", timeSinceLastSuccess / 1000 +
      //" Download all mainstats status is " + this.context.state.mainstats.state);
    }
  };

  handleProfilePictures = () => {
    const { profilePictures } = this.context.state;
    const { children } = this.context.state;

    /*console.log("ChildList. handleProfilePictures: "+
                "\n profilePictures.state: ", profilePictures.state,
                "\n profilePictures: ", profilePictures);*/

    if (
      this.state.profilePicturesHandled === false &&
      children.state === types.LOAD_SUCCESS_CHILDREN
    ) {
      switch (profilePictures.state) {
        case types.LOADING_PROFILEPICTURES:
          //console.log("ChildList. LOADING_PROFILEPICTURES");
          break;
        case types.LOAD_FAILED_PROFILEPICTURES:
          this.setState({ profilePicturesHandled: true });
          break;
        case types.LOAD_SUCCESS_PROFILEPICTURES:
          this.setState({ profilePicturesHandled: true });
          break;
        default:
          break;
      }
    }
  };

  handleNextNursingTimes = () => {
    const { nextNursingTimes } = this.context.state.nursingtimes;
    const { children } = this.context.state;

    /*console.log("ChildList. handleNextNursingtimes. "+
                "\n nextNursingTimes.state: ", nextNursingTimes.state +
                "\n nextnursingtimes: ", nextNursingTimes);*/

    switch (nextNursingTimes.state) {
      case types.READING_NEXTNURSINGTIMES:
        //("ChildList. READING NEXT NURSINGTIMES");
        break;
      case types.NEXTNURSINGTIMES_HANDLED:
        /*console.log("ChildList. NEXT NURSINGTIMES HANDLED"+
                    "\n nextnursingtimes: ", nextNursingTimes);*/
        break;
      case types.NEXTNURSINGTIMES_READ:
        //console.log("ChildList. NEXT NURSINGTIMES READ. Update info");
        nextNursingTimes.TimesList.forEach((ntChild) => {
          children.ChildList.forEach((listChild) => {
            if (ntChild.PersonId === listChild.Id) {
              listChild.NextNursingTime.PersonId = ntChild.PersonId;
              listChild.NextNursingTime.Time = ntChild.Time;
              listChild.NextNursingTime.Placement = ntChild.Placement;
            }
          });
        });
        //this.context.actions.triggerNextNursingTimesHandled();
        break;
      case types.NEXTNURSINGTIMES_FAILED:
        children.ChildList.forEach((listChild) => {
          listChild.NextNursingTime.PersonId = listChild.Id;
          listChild.NextNursingTime.Time =
            nextNursingTimes.Code === 404
              ? this.strings.errorNotFound
              : nextNursingTimes.Code === 500
              ? this.strings.errorInDB
              : nextNursingTimes.Code === 502
              ? this.strings.errorNetwork
              : /* nextNursingTimes.Code === 666*/ this.strings.errorUnknown;
          listChild.NextNursingTime.Placement = null;
          //console.log("ChildInfo. Päivitetään lapsilistalle: ", listChild.Name," seuraava hoitoaika: ",listChild.NextNursingTime.Time);
        });
        //this.context.actions.triggerNextNursingTimesHandled();
        break;
      default:
        //console.log("ChildList. Handling next nursingtimes. DEFAULT: ", nextNursingTimes.state);
        break;
    }
  };

  handleCB = (status, Id) => {
    const { children } = this.context.state;

    //console.log("ChildList. handleCB. status: ", status, " Id: ", Id);

    var selectedChild = children.ChildList.filter((child) => {
      return child.Id === Id;
    });

    if (status === "OPENGF") {
      this.props.history.push("/growthfolder", {
        Id: Id,
      });
    } else if (status === "OPENCHILDINFO") {
      //console.log("ChildList. handleCB. Changing route to OPENCHILDINFO with child: ", selectedChild[0]);

      this.props.history.push("/childInfo", {
        childId: selectedChild[0].Id,
        childName: selectedChild[0].Name,
        childFirstName: selectedChild[0].FirstName,
        childColor: selectedChild[0].BgColor,
        showBackArrow: children.ChildList.length === 1 ? false : true,
        VasuId: selectedChild[0].VasuId,
        LeopsId: selectedChild[0].LeopsId,
      });
    } else {
      //console.log("ChildList. handleCB. Other than changing route to child info");

      this.setState({
        selectedChildId: selectedChild[0].Id,
        selectedChildName: selectedChild[0].Name,
        selectedChildFirstName: selectedChild[0].FirstName,
        selectedChildImage: selectedChild[0].ProfilePictureBase64,
        selectedChildColor: selectedChild[0].Color,
        selectedChildNextNursingTime: selectedChild[0].NextNursingTime.Time,
        selectedChildNextNursingTimePlacement:
          selectedChild[0].NextNursingTime.Placement,
        openGrowthfolder: status === "OPENGROWTHFOLDER" ? true : false,
      });
    }
  };

  openBulletinBoard = () => {
    console.log("ChildList. openBulletionBoard");
    this.props.history.push("/bulletinboard", {
      childId: this.state.selectedChildId,
    });
  };

  closeErrorModal = () => {
    //console.log("ChildList. Close error modal");
    this.context.authContext.authActions.logout(LOGOUT_REASONS.normal);
    this.setState({ errorModalOpen: false });
  };

  render() {
    const {
      children,
      messaging,
      mainstats,
      profilePictures: { profilePicturesList },
    } = this.context.state;

    if (this.state.openGrowthfolder === true) {
      return <GrowthFolder />;
    }

    if (this.state.errorModalOpen) {
      return (
        <Modal isOpen={this.state.errorModalOpen}>
          <ModalBody>
            <div className="InfoMessage textCenter">
              <ContentBox
                header={this.strings.clChildrenLoadingData}
                showCloseicon={true}
                setfunction={this.closeErrorModal}
              >
                <h4 style={{ whiteSpace: "pre-line" }}>
                  {this.state.errorText}
                </h4>
              </ContentBox>
            </div>
          </ModalBody>
        </Modal>
      );
    } else if (children.ChildList.length > 1) {
      return (
        <ContentBoxMUI>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12}>
              <MissingVacationBookingsNote />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <PermPermissionNote />
            </Grid>
            {children.ChildList.map((child, index) => {
              const { listChildNursingTimeStatus } =
                this.context.state.mainstats;

              const profilePictureBase64 = getProfilePictureData(
                this.context.state,
                child.Id
              );

              var nursingtimeNotReservedFound = false;
              if (listChildNursingTimeStatus !== undefined) {
                for (var i = 0; i < listChildNursingTimeStatus.length; i++) {
                  if (
                    listChildNursingTimeStatus[i].ChildPersonId === child.Id
                  ) {
                    nursingtimeNotReservedFound = true;
                    break;
                  }
                }
              }
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  key={"grid-item-" + child.Id}
                >
                  <ChildListItem
                    key={child.Id}
                    counter={"test_Childlist_item_" + index}
                    profilepicture={profilePictureBase64}
                    notReserved={nursingtimeNotReservedFound}
                    childId={child.Id}
                    childName={child.Name}
                    childFirstName={child.FirstName}
                    history={this.props.history}
                    headerbgcolor={child.BgColor}
                    nextNursingTimeTime={child.NextNursingTime.Time}
                    nextNursingTimePL={child.NextNursingTime.Placement}
                    VasuId={child.VasuId}
                    LeopsId={child.LeopsId}
                    onItemClick={() => this.handleCB("OPENCHILDINFO", child.Id)}
                    openGrowthFolder={() => {
                      this.handleCB("OPENGF", child.Id);
                    }}
                  ></ChildListItem>
                </Grid>
              );
            })}
            <Grid item xs={12} sm={12} md={12} lg={12} key={"grid-item-bb"}>
              <BulletinBoard
                slideViewOnClick={this.openBulletinBoard}
                mode="slide"
              />
            </Grid>
          </Grid>

          {window.innerWidth <= 1200 ? (
            //<FloatingNav notReadThreads={mainstats.notReadThreads} />
            <BottomNavigation
              notReadThreads={messaging.messageData.notReadThreads}
            />
          ) : null}
        </ContentBoxMUI>
      ); /* return end*/
    } else if (this.state.infoModalOpen) {
      //TODO: We get here also when only one children. This else-if hell should be refactored
      return (
        <div className="BackDrop">
          <Backdrop
            /*className={classes.backdrop}*/ open={
              true
            } /*onClick={handleClose}*/
          >
            <CircularProgress />
          </Backdrop>
        </div>
      );
    } // never here, just in case
    else {
      return <div>{this.strings.errorInDB}</div>;
    }
  }
}

export default ChildList;

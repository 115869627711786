import React from 'react';
import Languages from "../../../translations";
import { useAuth } from '../../../Auth';
import Typography from "@mui/material/Typography";

const LoginStatus = ({showErrorText}) => {

    const { loading, authState, user } = useAuth();

  if (loading) {
    return (<Typography id="test_Login_Waiting" color="textSecondary">{Languages.loginProcessingInfo}</Typography>);
  }

  if (user || !showErrorText) {
    return (null);
  }

  if (authState.lastHttpCode !== 200) {
    if (authState.lastErrorCode === 2) {
      return (<Typography id="test_Login_Error401_NoKids" style={{color: "#C82FC3"}} color="error">{Languages.errorUnauthorizedNoActiveChilds}</Typography>);
    }
    if (authState.lastErrorCode === 1) {
      return (<Typography id="test_Login_Error404_LoginError" color="error" fontSize="x-large">{Languages.errorInLogin}</Typography>);
    }
    if (authState.lastHttpCode === 502) {
      return (<Typography id="test_Login_Error502_NetworkError" style={{color: "#C82FC3"}} color="error">{Languages.errorNetwork}</Typography>);
    }
    if (authState.lastHttpCode === 500) {
      return (<Typography id="test_Login_Error500_DBError" style={{color: "#C82FC3"}} color="error">{Languages.errorInDB}</Typography>);
    }
  }

  return (
    <div></div>
  );
}

export default LoginStatus;
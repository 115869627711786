import React, { Component } from "react";
import "./fixedbottombar.css";

class FixedBottomBar extends Component {
  render() {
    return <div id="test_FixedBottomBar" className="FixedBottomBar">{this.props.children}</div>;
  }
}

export default FixedBottomBar;

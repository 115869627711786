import React from "react";
import BackToUpButton from "../GuideTools/BackToUpButton";
import Languages from "../../../translations";
import makeStyles from '@mui/styles/makeStyles';
import FeedIcon from '@mui/icons-material/Feed';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import Badge from '@mui/material/Badge';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';


const useStyles = makeStyles({
  success: {
    color: 'rgb(129, 199, 132)',
    fontSize: 'xx-large',
  }
});



const ChildPermissionsAccordionText = () => {
  const classes = useStyles();
    return(
      <>
      <div>
      <h4>{Languages.childPermissionsAccordionTitle}</h4>
          <br />
          <p className="guide-accordion-details-texts"><b>{Languages.childPermissionsList1}</b> {Languages.childPermissionsList2} <b>{Languages.childPermissionsList3}</b></p>
          <br />
          <hr className="guide-accordion-details-line"/>
          <br />
          <p className="guide-accordion-details-texts">{Languages.childPermissionsList4} <b>{Languages.childPermissionsList5}</b></p>
          <br />
          <br />
          <ol>
          <li className="guide-list-item-styles">{Languages.childPermissionsList6} <b>{Languages.childPermissionsList7}</b> {Languages.childPermissionsList8} <b>{Languages.childPermissionsList9}</b></li>  
          <br />
          <li className="guide-list-item-styles">{Languages.childPermissionsList6} <b>{Languages.childPermissionsList10}</b> {Languages.childPermissionsList11} <b>{Languages.childPermissionsList12}</b> {Languages.childPermissionsList13} <b>{Languages.childPermissionsList14}</b></li>
          <br />
          </ol>
          <p className="guide-accordion-details-texts">{Languages.childPermissionsList15} <b>{Languages.childPermissionsList16}</b> {Languages.childPermissionsList17}</p>
          <br />
          <br />
          <p className="guide-accordion-details-texts">{Languages.childPermissionsList18} <b>{Languages.childPermissionsList19}</b></p>
          <br />
          <br />
        <BackToUpButton />
        </div>
      </>
    )
}

export default ChildPermissionsAccordionText
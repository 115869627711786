import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
} from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import moment from "moment";
import SwipeableViews from "react-swipeable-views";
import virtualize from "react-swipeable-views-utils/lib/virtualize";
import Typography from "@mui/material/Typography";
import { useStateValue } from "../../../../State";
import PropTypes from "prop-types";
import Badge from "@mui/material/Badge";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";
import { styled } from "@mui/material/styles";
import { success, types } from "../../../../Reducers/actionTypes";
import { Virtuoso } from "react-virtuoso";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import useMediaQuery from "@mui/material/useMediaQuery";
import Languages from "../../../../translations";
function ServerDay(props) {
  const {
    highlightedDays,
    highlightedDays2,
    highlightedDays3,
    highlightedDays4,
    shortestLockTime,
    day,
    outsideCurrentMonth,
    ...other
  } = props;

  //reservation on day
  const isSelected =
    !props.outsideCurrentMonth &&
    highlightedDays.some(
      (e) => moment(e).format("MM.DD.YYYY") == moment(day).format("MM.DD.YYYY")
    );
  //status on day
  const isSelected2 =
    !props.outsideCurrentMonth &&
    highlightedDays2.some(
      (e) => moment(e).format("MM.DD.YYYY") == moment(day).format("MM.DD.YYYY")
    );

  //vacation day
  const isSelected3 =
    !props.outsideCurrentMonth &&
    highlightedDays3.some(
      (e) => moment(e).format("MM.DD.YYYY") == moment(day).format("MM.DD.YYYY")
    );

  //vacation with daycare period
  const isSelected4 =
    !props.outsideCurrentMonth &&
    highlightedDays4.some(
      (e) => moment(e).format("MM.DD.YYYY") == moment(day).format("MM.DD.YYYY")
    );

  if (isSelected2) {
    return (
      <Badge
        badgeContent={" "}
        variant="dot"
        overlap="circular"
        color="warning"
      >
        <PickersDay
          sx={{
            backgroundColor: moment(day).isBefore(shortestLockTime)
              ? "#dadbe0"
              : isSelected
              ? "success.light"
              : "white",
            color: moment(day).isBefore(shortestLockTime)
              ? "black"
              : isSelected
              ? "white"
              : "black",
            filter: moment(day).isBefore(shortestLockTime) && "grayscale(30%)",
          }}
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
        />
      </Badge>
    );
  }
  return (
    <PickersDay
      sx={{
        backgroundColor: moment(day).isBefore(shortestLockTime)
          ? "#dadbe0"
          : isSelected
          ? "success.light"
          : "white",
        color: moment(day).isBefore(shortestLockTime)
          ? "black"
          : isSelected
          ? "white"
          : "black",
        filter: moment(day).isBefore(shortestLockTime) && "grayscale(30%)",
      }}
      {...other}
      outsideCurrentMonth={outsideCurrentMonth}
      day={day}
    />
  );
}

function ServerDay2(props) {
  const {
    highlightedDays,
    highlightedDays2,
    highlightedDays3,
    highlightedDays4,
    shortestLockTime,
    day,
    outsideCurrentMonth,
    ...other
  } = props;

  //vacation day
  const isSelected3 =
    !props.outsideCurrentMonth &&
    highlightedDays3.some(
      (e) => moment(e).format("MM.DD.YYYY") == moment(day).format("MM.DD.YYYY")
    );

  //vacation with daycare period
  const isSelected4 =
    !props.outsideCurrentMonth &&
    highlightedDays4.some(
      (e) => moment(e).format("MM.DD.YYYY") == moment(day).format("MM.DD.YYYY")
    );

  return (
    <PickersDay
      sx={{
        backgroundColor: isSelected4
          ? "warning.light"
          : isSelected3
          ? "#e57373"
          : moment(day).isBefore(shortestLockTime)
          ? "#dadbe0"
          : "white",
        color: moment(day).isBefore(shortestLockTime)
          ? "black"
          : isSelected4 || isSelected3
          ? "white"
          : "black",
        filter: moment(day).isBefore(shortestLockTime) && "grayscale(30%)",
      }}
      {...other}
      outsideCurrentMonth={outsideCurrentMonth}
      day={day}
    />
  );
}

const TopCalendarNavigationDrawer = ({
  open,
  onClose,
  handleClickScroll,
  handleCalendarNavClick,
  childList,
}) => {
  const { state, actions } = useStateValue();
  const [isLoading, setIsLoading] = React.useState(true);
  const [highlightedDays, setHighlightedDays] = React.useState([]);
  const [highlightedDays2, setHighlightedDays2] = React.useState([]);
  const [highlightedDays3, setHighlightedDays3] = React.useState([]);
  const [highlightedDays4, setHighlightedDays4] = React.useState([]);

  const isDesktop = useMediaQuery("(min-width:1200px)", { noSsr: true });
  const isWideDesktop = useMediaQuery("(min-width:1600px)", { noSsr: true });
  const [showWeekEnds, setShowWeekEnds] = useState(
    childList.some((child) =>
      child.Placements.some(
        (placement) =>
          (placement.Type === "ROUND_THE_CLOCK" ||
            placement.Type === "EXTENDED") &&
          placement.OpenType === "MONSUN"
      )
    )
  );
  useEffect(() => {
    switch (state.caretimes.Quick.state) {
      case undefined:
        break;
      case types.CARETIMES_QUICK_LOAD_OK:
        setHighlightedDays(state.caretimes.Quick.DayList);
        setHighlightedDays2(state.caretimes.Quick.DayList2);
        setHighlightedDays3(state.caretimes.Quick.VacationList);
        setHighlightedDays4(state.caretimes.Quick.VacationPeriodList);
        setIsLoading(false);
        break;
      case types.CARETIMES_QUICK_LOAD_FAILED:
        break;
      default:
        break;
    }
    if (!open) {
      //console.log("kiinni meni");
    }
  }, [state.caretimes.Quick.state, open]);

  const getUser = (index) => {
    const generated = [];
    if (!generated[index]) {
      generated[index] = index;
    }

    return generated[index];
  };

  const GenerateUsers = (length, startIndex = 0) => {
    return Array.from({ length }).map((_, i) => getUser(i + startIndex));
  };

  const [first, setFirst] = useState(true);
  const GenerateUsers2 = (length, startIndex = 0) => {
    if (first) {
      return Array.from({ length }).map((_, i) => getUser(i + startIndex - 10));
      setFirst(false);
    }
    return Array.from({ length }).map((_, i) => getUser(i + startIndex));
  };

  const GenerateUsersInit = (length, startIndex = 0) => {
    return Array.from({ length }).map((_, i) =>
      getUser(i + startIndex - length / 2)
    );
  };

  const START_INDEX = 1000;
  const INITIAL_ITEM_COUNT = 20;

  const [firstItemIndex, setFirstItemIndex] = useState(START_INDEX);
  const [firstItemIndex2, setFirstItemIndex2] = useState(START_INDEX);
  const [users, setUsers] = useState(() =>
    GenerateUsersInit(INITIAL_ITEM_COUNT, START_INDEX)
  );

  const prependItems = useCallback(() => {
    //console.log("preped");
    if (open === true) {
      const usersToPrepend = 20;
      const nextFirstItemIndex = firstItemIndex - usersToPrepend;

      setFirstItemIndex(() => nextFirstItemIndex);
      setUsers(() => [
        ...GenerateUsers(usersToPrepend, nextFirstItemIndex),
        ...users,
      ]);
      return false;
    }
  }, [firstItemIndex, users, setUsers, open]);

  const append = useCallback(() => {
    //console.log("append");
    if (open === true) {
      const usersToPrepend = 20;
      const nextFirstItemIndex = firstItemIndex2 + usersToPrepend;

      setUsers([
        ...users,
        ...GenerateUsers2(usersToPrepend, firstItemIndex2 + 20),
      ]);
      setFirstItemIndex2(nextFirstItemIndex);
      return false;
    }
  }, [firstItemIndex2, users, setUsers, open]);

  const [checked, setChecked] = React.useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <>
      <Drawer
        anchor={"top"}
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            margin: "auto",
            maxWidth: 800,
            width: "100%",
            height: "85%",
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
            backgroundColor: "white",
            mt: "64px",
            "&::-webkit-scrollbar": { display: "none" },
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          },
        }}
        sx={{ zIndex: "1250 !important" }}
        transitionDuration={{ enter: 600, exit: 400 }}
      >
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Box
            sx={{
              "&::-webkit-scrollbar": { display: "none" },
              msOverflowStyle: "none",
              scrollbarWidth: "none",
            }}
          >
            <Stack alignItems="center">
              <Stack
                direction="row"
                spacing={0.5}
                sx={{ mt: 1, mb: 1, ml: 2 }}
                alignItems="center"
              >
                {!checked ? (
                  <>
                    {" "}
                    <Avatar
                      sx={{
                        backgroundColor: "success.light",
                        width: 32,
                        height: 32,
                        "& .MuiSvgIcon-root": {
                          display: "none",
                        },
                      }}
                    />
                    <Typography color="textSecondary">
                      {Languages.newCalendarCareTime}
                    </Typography>
                    <Badge
                      badgeContent={" "}
                      variant="dot"
                      overlap="circular"
                      color="warning"
                    >
                      <Avatar
                        sx={{
                          backgroundColor: "success.light",
                          width: 32,
                          height: 32,
                          "& .MuiSvgIcon-root": {
                            display: "none",
                          },
                        }}
                      />
                    </Badge>
                    <Typography color="textSecondary">
                      {Languages.newCalendarAbsence}
                    </Typography>
                    <Avatar
                      sx={{
                        backgroundColor: "#c4c1c0",
                        width: 32,
                        height: 32,
                        "& .MuiSvgIcon-root": {
                          display: "none",
                        },
                      }}
                    />
                    <Typography color="textSecondary">
                      {Languages.newCalendarLockedTime}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Avatar
                      sx={{
                        backgroundColor: "#e57373",
                        width: 32,
                        height: 32,
                        "& .MuiSvgIcon-root": {
                          display: "none",
                        },
                      }}
                    />
                    <Typography color="textSecondary">
                      {Languages.newCalendarVacationTime}
                    </Typography>

                    <Avatar
                      sx={{
                        backgroundColor: "warning.light",
                        width: 32,
                        height: 32,
                        "& .MuiSvgIcon-root": {
                          display: "none",
                        },
                      }}
                    />
                    <Typography color="textSecondary">
                      {Languages.newCalendarPeriodTime}
                    </Typography>
                    <Avatar
                      sx={{
                        backgroundColor: "#c4c1c0",
                        width: 32,
                        height: 32,
                        "& .MuiSvgIcon-root": {
                          display: "none",
                        },
                      }}
                    />
                    <Typography color="textSecondary">
                      {Languages.newCalendarLockedTime}
                    </Typography>
                  </>
                )}
              </Stack>

              <Stack direction="row" spacing={0.5} alignItems="center">
                <Typography color="textSecondary">
                  {Languages.newCalendarCareTimes}
                </Typography>
                <PinkSwitch
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                  color="secondary"
                />
                <Typography color="textSecondary">
                  {Languages.newCalendarVacationTimes}
                </Typography>
              </Stack>
            </Stack>

            <Divider />
            <Virtuoso
              style={{ height: isDesktop ? 1000 : 600 }}
              firstItemIndex={firstItemIndex}
              initialTopMostItemIndex={INITIAL_ITEM_COUNT - 10}
              data={users}
              increaseViewportBy={200}
              overscan={200}
              startReached={open ? prependItems : null}
              endReached={append}
              itemContent={(index, user) => {
                return (
                  <CalendarElement
                    highlightedDays={highlightedDays.filter(
                      (day) =>
                        moment(day).month() ===
                        moment()
                          .add(user - 1000, "month")
                          .month()
                    )}
                    highlightedDays2={highlightedDays2.filter(
                      (day) =>
                        moment(day).month() ===
                        moment()
                          .add(user - 1000, "month")
                          .month()
                    )}
                    highlightedDays3={highlightedDays3.filter(
                      (day) =>
                        moment(day).month() ===
                        moment()
                          .add(user - 1000, "month")
                          .month()
                    )}
                    highlightedDays4={highlightedDays4.filter(
                      (day) =>
                        moment(day).month() ===
                        moment()
                          .add(user - 1000, "month")
                          .month()
                    )}
                    checked={checked}
                    user={user}
                    index={index}
                    showWeekEnds={showWeekEnds}
                    handleClickScroll={handleClickScroll}
                    shortestLockTime={moment(state.children.ShortestLockTime)}
                  ></CalendarElement>
                );
              }}
            />
          </Box>
        </LocalizationProvider>
      </Drawer>
    </>
  );
};

export default TopCalendarNavigationDrawer;

const PinkSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase": {
    color: "#66bb6a",
  },
  "& .MuiSwitch-switchBase + .MuiSwitch-track": {
    backgroundColor: "#66bb6a",
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#ffa726",
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#ffa726 !important",
  },
}));

const CalendarElement = ({
  highlightedDays,
  highlightedDays2,
  highlightedDays3,
  highlightedDays4,
  user,
  index,
  showWeekEnds,
  handleClickScroll,
  checked,
  shortestLockTime = null,
}) => {
  return (
    <DateCalendar
      sx={{
        "& .MuiPickersCalendarHeader-root": {
          color: "black",
          marginLeft: 10,
          marginTop: 0,
          PointerEvent: "none",
        },
        "& .MuiPickersCalendarHeader-switchViewIcon": {
          display: "none",
        },
        "& .MuiPickersSlideTransition-root": {
          height: "auto",
        },
        "& .MuiPickersArrowSwitcher-button": {
          display: "none",
        },

        "& .MuiDayCalendar-weekNumberLabel": {
          color: "white",
        },
        "& .MuiDayCalendar-weekNumber: aria-label#Week 35": {
          color: "red",
        },
        "& .MuiDayCalendarSkeleton-daySkeleton": {
          backgroundColor: "#ECF3FB",
        },
      }}
      renderLoading={() => <DayCalendarSkeleton />}
      displayWeekNumber
      defaultCalendarMonth={moment().add(user - 1000, "month")}
      onChange={(newValue) => handleClickScroll(newValue)}
      slots={{
        day: !checked ? ServerDay : ServerDay2,
      }}
      views={["day"]}
      shouldDisableDate={(date) => {
        if (
          (date.isoWeekday() === 6 && showWeekEnds === false) ||
          (date.isoWeekday() === 7 && showWeekEnds === false)
        ) {
          return true;
        }
        return false;
      }}
      slotProps={{
        day: {
          highlightedDays,
          highlightedDays2,
          highlightedDays3,
          highlightedDays4,
          shortestLockTime,
        },
      }}
    />
  );
};

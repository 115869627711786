import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useState, useEffect } from "react";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import IconButton from "@mui/material/IconButton";
import moment from "moment";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import ChildProfilePicture from "../../../ChildProfilePicture";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import CloseIcon from "@mui/icons-material/Close";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import EditIcon from "@mui/icons-material/Edit";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardActionArea } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useStateValue } from "../../../../State";
import CardHeader from "@mui/material/CardHeader";

import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import SickIcon from "@mui/icons-material/Sick";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Slider from "@mui/material/Slider";
import CancelIcon from "@mui/icons-material/Cancel";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CheckIcon from "@mui/icons-material/Check";
import CentreSelection from "../common/CentreSelection";
import TimeSlider from "../common/TimeSlider";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: 300,
  }),
}));

const CTBdayDialog = ({
  day,
  modalOpen,
  handleClick,
  names,
  handleFocusDay,
}) => {
  const { state, actions } = useStateValue();
  const [personAbsent, setPersonAbsent] = React.useState([]);

  useEffect(() => {
    if (modalOpen === false) {
      setPersonAbsent([]);
    }
  }, [modalOpen]);

  const handlePersonAbsent = (data) => {
    if (
      personAbsent.some((absentData) => absentData.PersonId === data.PersonId)
    ) {
      var UpdatedpersonAbsent = personAbsent.filter(
        (absentData) => absentData.PersonId !== data.PersonId
      );
      setPersonAbsent(UpdatedpersonAbsent);
    } else {
      personAbsent.push(data);
    }
  };

  const updateAbsentReason = (data) => {
    const newState = personAbsent.map((obj) => {
      if (obj.PersonId === data.PersonId) {
        return { ...obj, AbsentReason: data.AbsentReason };
      }
      return obj;
    });

    setPersonAbsent(newState);
  };

  const saveAbsent = () => {
    handleFocusDay(day.Date);
    actions.triggerSaveCareTimesAbsent({
      PersonList: personAbsent,
      Dates: [day.Date],
      DeleteOld: false,
      EveningPreschool: false,
    });
  };
  const deleteCareTime = (PersonId) => {
    actions.triggerDeleteNursingtimesMany({
      ChildIds: [PersonId],
      ListDatesToDelete: [day.Date],
    });
  };

  return (
    <Dialog
      disablePadding
      disableGutters
      fullWidth
      onClose={handleClick}
      open={modalOpen}
      sx={{
        "& .MuiDialog-container .MuiDialog-paper": {
          margin: 0,
          borderRadius: 2,
          width: "90%",
        },
        width: "100%",
      }}
    >
      <DialogTitle textAlign="center" style={{ backgroundColor: "#FFFFFF" }}>
        <Typography color="textSecondary" fontWeight="bold">
          {moment(day.Date).format("ddd DD.MM")}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClick}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "success",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        dividers
        disableGutters
        disablePadding
        sx={{ padding: 0 }}
        style={{ backgroundColor: "#FFFFFF" }}
      >
        <>
          {day.CareTimeTest.map((nt) => (
            <>
              <CTBdayDialogChildRow
                nt={nt}
                isPast={day.NotSelectable}
                isLocked={day.IsLockedDate}
                deleteCareTime={deleteCareTime}
                handlePersonAbsent={handlePersonAbsent}
                updateAbsentReason={updateAbsentReason}
                handleFocusDay={handleFocusDay}
                names={names}
              ></CTBdayDialogChildRow>
            </>
          ))}
        </>
      </DialogContent>
      <DialogActions
        sx={{
          width: "100%",
          margin: 0,
          backgroundColor: personAbsent.length < 1 ? "#8c8c8b" : "success.main",
        }}
        style={{ justifyContent: "center", padding: 0 }}
      >
        {day.NotSelectable == false && (
          <Button
            sx={{
              width: "100%",
              height: "45px",
              margin: 0,
              backgroundColor: "success.main",
              "&:disabled": { backgroundColor: "#8c8c8b" },
            }}
            variant="contained"
            onClick={saveAbsent}
            disabled={personAbsent.length < 1 ? true : false}
          >
            Tallenna
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CTBdayDialog;

const CTBdayDialogChildRow = ({
  nt,
  isPast,
  isLocked,
  deleteCareTime,
  handlePersonAbsent,
  updateAbsentReason,
  names,
  handleFocusDay,
}) => {
  const [absent, setAbsent] = React.useState(false);
  const [absentReason, setAbsentReason] = React.useState("Poissa");
  const [newRes, setNewRes] = React.useState([]);
  const handleReason = (event, newReason) => {
    if (newReason !== null) {
      updateAbsentReason({ PersonId: nt.ChildId, AbsentReason: newReason });
      setAbsentReason(newReason);
    }
  };
  const handleAbsent = () => {
    handlePersonAbsent({ PersonId: nt.ChildId, AbsentReason: absentReason });
    setAbsent(!absent);
  };

  const handleNewRes = () => {
    newRes.push({ Test: "test", Test2: "test2" });
  };
  const [resChildren, setResChildren] = React.useState([]);
  const handleResChildren = (child, centre) => {
    if (resChildren.some((resChild) => resChild.childId === child)) {
      var updatedresChildren = resChildren.filter(
        (resChild) => resChild.childId !== child
      );
      updatedresChildren.push({ childId: child, resId: centre });
      setResChildren(updatedresChildren);
    } else {
      resChildren.push({ childId: child, resId: centre });
    }

    //console.log(resChildren);
  };

  const [ResLenght, setResLenght] = React.useState([
    moment.duration(moment("2020-01-01 08:00").format("HH:mm")).asMinutes(),
    moment.duration(moment("2020-01-01 16:00").format("HH:mm")).asMinutes(),
  ]);

  const handleResLenght = (newLenght) => {
    //console.log(moment(newLenght[0]));
    setResLenght(newLenght);
  };
  return (
    <Card
      variant="outlined"
      elevation={0}
      sx={{
        bgcolor: "white",
        mt: 1,
        width: "96%",
        ml: "2%",
      }}
    >
      <CardHeader
        sx={{ padding: 0 }}
        avatar={
          <Avatar sx={{ width: 36, height: 36, ml: 1 }}>
            <ChildProfilePicture childId={nt.ChildId} />
          </Avatar>
        }
        title={
          <Typography color="textSecondary" fontWeight="bold">
            {nt.Name.split(" ")[0]}
          </Typography>
        }
        action={
          <>
            {isPast == false && (
              <FormControlLabel
                sx={{
                  mt: 1,
                  mr: 2,
                  "& .MuiFormControlLabel": {
                    styleOverrides: {
                      root: {
                        width: "fit-content",
                      },
                    },
                  },
                }}
                control={<Switch checked={absent} onChange={handleAbsent} />}
                labelPlacement="start"
                label={
                  <Typography color="textSecondary" fontWeight="medium">
                    {"poissa"}
                  </Typography>
                }
              />
            )}
          </>
        }
      />
      <CardContent sx={{ padding: 0 }}>
        {nt.Centres.some((centre) => centre.NursingTimes.length > 0) ===
          true && (
          <>
            {nt.Centres.map((ct) => (
              <>
                {ct.NursingTimes.length > 0 && (
                  <>
                    <CTBdayDialogCTitem
                      ct={ct}
                      isPast={isPast}
                      isLocked={isLocked}
                      deleteCareTime={deleteCareTime}
                    ></CTBdayDialogCTitem>
                  </>
                )}
              </>
            ))}
          </>
        )}
        {newRes.length > 0 && (
          <>
            {newRes.map((res) => (
              <>
                <Typography
                  color="textSecondary"
                  fontSize="medium"
                  textAlign="center"
                >
                  {"Uusi varaus"}
                </Typography>
                <TimeSlider handleResLenght={handleResLenght}></TimeSlider>
                <CentreSelection
                  listOfChildren={[nt.ChildId]}
                  names={names}
                  handleResChildren={handleResChildren}
                  displayName={false}
                ></CentreSelection>
              </>
            ))}
          </>
        )}
      </CardContent>
      <CardActions style={{ justifyContent: "center" }}>
        {absent === true ? (
          <>
            <ToggleButtonGroup
              sx={{ width: "100%" }}
              value={absentReason}
              exclusive
              onChange={handleReason}
            >
              <ToggleButton
                style={{ justifyContent: "center" }}
                sx={{ padding: 1, width: "30%", ml: "20%" }}
                value="Poissa"
              >
                <Box sx={{ width: "100%" }}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Box sx={{ width: "50%" }}>
                      <Typography
                        color="textSecondary"
                        fontSize="small"
                        textAlign="center"
                      >
                        {"Poissa"}
                      </Typography>
                    </Box>
                    <Box sx={{ width: "30%" }}>
                      {absentReason === "Poissa" && (
                        <CheckIcon color="success" />
                      )}
                    </Box>
                  </Stack>
                </Box>
              </ToggleButton>
              <ToggleButton
                sx={{
                  padding: 1,
                  width: "30%",
                }}
                value="Sairas"
              >
                <Box sx={{ width: "100%" }}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Box sx={{ width: "50%" }}>
                      <Typography
                        color="textSecondary"
                        fontSize="small"
                        textAlign="center"
                      >
                        {"Sairas"}
                      </Typography>
                    </Box>
                    <Box sx={{ width: "30%" }}>
                      {absentReason === "Sairas" && (
                        <CheckIcon color="success" />
                      )}
                    </Box>
                  </Stack>
                </Box>
              </ToggleButton>
            </ToggleButtonGroup>
          </>
        ) : (
          <>
            {isPast === false && isLocked === false && (
              <Button
                size="small"
                variant="contained"
                sx={{ backgroundColor: "#00B3DF" }}
                onClick={handleNewRes}
              >
                Lisää varaus
              </Button>
            )}
          </>
        )}
      </CardActions>
    </Card>
  );
};

const CTBdayDialogCTitem = ({ ct, isPast, isLocked, deleteCareTime }) => {
  const [expanded, setExpanded] = React.useState(false);
  const [editing, setEditing] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleEditing = () => {
    setEditing(!editing);
  };

  const handleDeletePopUp = () => {
    setDeleting(!deleting);
  };

  const handleDelete = () => {
    deleteCareTime(ct.NursingTimes[0].PersonId);
    handleDeletePopUp();
  };
  return (
    <>
      <Dialog onClose={handleDeletePopUp} open={deleting}>
        <DialogTitle textAlign="center" style={{ backgroundColor: "#FFFFFF" }}>
          <IconButton
            aria-label="close"
            onClick={handleDeletePopUp}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "success",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ backgroundColor: "#FFFFFF" }}>
          <Typography sx={{ mt: 2 }} color="textSecondary" fontWeight="bold">
            {"Haluatko varmasti poistaa hoitoajan?"}
          </Typography>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: "center",

            backgroundColor: "#FFFFFF",
          }}
        >
          <Button
            variant="contained"
            sx={{ backgroundColor: "#0074C8" }}
            onClick={handleDeletePopUp}
          >
            Ei
          </Button>
          <Button
            variant="contained"
            sx={{ backgroundColor: "#0074C8" }}
            onClick={handleDelete}
          >
            Kyllä
          </Button>
        </DialogActions>
      </Dialog>
      <Card elevation={0} sx={{ maxWidth: 345, backgroundColor: "white" }}>
        <CardHeader
          sx={{ padding: 0 }}
          title={
            <>
              <Chip
                size="small"
                sx={{
                  color: "black",
                  padding: 0,
                  ml: 1,
                }}
                label={
                  <Typography
                    color="textSecondary"
                    fontWeight="medium"
                    fontSize="small"
                  >
                    {ct.CentreName}
                  </Typography>
                }
              ></Chip>
              {ct.NursingTimes.length === 1 && (
                <Chip
                  size="small"
                  sx={{
                    color: "black",
                    padding: 0,
                    ml: 1,
                  }}
                  label={
                    <Typography
                      color="textSecondary"
                      fontWeight="medium"
                      fontSize="small"
                    >
                      {ct.NursingTimes[0].Group}
                    </Typography>
                  }
                ></Chip>
              )}

              {ct.NursingTimes.length > 1 && (
                <IconButton
                  onClick={() => handleExpandClick()}
                  disableRipple={true}
                  size="small"
                  sx={{
                    backgroundColor: "#00B3DF",
                    color: "white",
                    width: 24,
                    height: 24,
                    ml: 1,
                  }}
                >
                  <ExpandMoreIcon />
                </IconButton>
              )}
            </>
          }
          action={
            <>
              {isPast === false && isLocked === false && (
                <>
                  <IconButton
                    disableRipple={true}
                    size="small"
                    sx={{
                      backgroundColor: "#00B3DF",
                      color: "white",
                      width: 24,
                      height: 24,
                      mr: 0.5,
                      mt: 1,
                    }}
                    onClick={() => handleEditing()}
                  >
                    {editing ? (
                      <CancelIcon
                        sx={{
                          width: 18,
                          height: 18,
                        }}
                      ></CancelIcon>
                    ) : (
                      <EditIcon
                        sx={{
                          width: 18,
                          height: 18,
                        }}
                      ></EditIcon>
                    )}
                  </IconButton>

                  <IconButton
                    disableRipple={true}
                    size="small"
                    sx={{
                      backgroundColor: "warning.main",
                      color: "white",
                      width: 24,
                      height: 24,
                      mr: 2,
                      mt: 1,
                    }}
                    onClick={() => handleDeletePopUp()}
                  >
                    <DeleteForeverIcon
                      sx={{
                        width: 18,
                        height: 18,
                      }}
                    ></DeleteForeverIcon>
                  </IconButton>
                </>
              )}
            </>
          }
        />

        <CardContent
          sx={{
            padding: 0,
            "&:last-child": {
              paddingBottom: 1,
            },
          }}
        >
          <ExistingReservation ct={ct} editing={editing}></ExistingReservation>
        </CardContent>

        <Collapse in={expanded} timeout={500} unmountOnExit>
          <CardContent
            sx={{
              padding: 0,
            }}
          >
            <Box sx={{ width: "96%", ml: "2%", mt: 1 }}>
              {ct.NursingTimes.map((time, index) => (
                <Stack direction="row" spacing={3}>
                  <Stack>
                    {index === 0 && (
                      <Typography color="textSecondary" fontSize="small">
                        {"Ryhmä"}
                      </Typography>
                    )}
                    <Typography color="textSecondary" fontSize="medium">
                      {time.Group}
                    </Typography>
                  </Stack>
                  <Stack>
                    {index === 0 && (
                      <Typography color="textSecondary" fontSize="small">
                        {"Alku"}
                      </Typography>
                    )}
                    <Typography color="textSecondary" fontSize="medium">
                      {moment(time.Start).format("HH:mm")}
                    </Typography>
                  </Stack>
                  <Stack>
                    {index === 0 && (
                      <Typography color="textSecondary" fontSize="small">
                        {"Loppu"}
                      </Typography>
                    )}
                    <Typography color="textSecondary" fontSize="medium">
                      {moment(time.End).format("HH:mm")}
                    </Typography>
                  </Stack>

                  <Stack>
                    {index === 0 && (
                      <Typography color="textSecondary" fontSize="small">
                        {"Esikoulu"}
                      </Typography>
                    )}
                    <Typography color="textSecondary" fontSize="medium">
                      {time.Priority == 2 ? "Kyllä" : "Ei"}
                    </Typography>
                  </Stack>
                </Stack>
              ))}
            </Box>
          </CardContent>
        </Collapse>
      </Card>
    </>
  );
};

const ExistingReservation = (props) => {
  const [startTimes, setStartTimes] = useState([]);
  const [endTimes, setEndTimes] = useState([]);
  const [value, setValue] = React.useState([]);

  useEffect(() => {
    props.ct.NursingTimes.forEach((time, index) => {
      startTimes.push(time.Start);
      endTimes.push(time.End);
    });

    const sortedStarts = startTimes.sort((a, b) => moment(a) - moment(b));
    const sortedEnds = endTimes.sort((a, b) => moment(a) - moment(b));

    const startAsMinutes = moment
      .duration(moment(sortedStarts[0]).format("HH:mm"))
      .asMinutes();
    const endAsMinutes = moment
      .duration(moment(sortedEnds[sortedEnds.length - 1]).format("HH:mm"))
      .asMinutes();

    setValue([startAsMinutes, endAsMinutes]);
  }, [props.Centres]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Slider
        sx={{
          height: 8,
          borderRadius: 0,
          width: "96%",
          ml: "2%",

          "& .MuiSlider-thumb": {
            backgroundColor: props.editing ? "#86B8FF" : "transparent",
            height: props.editing ? 24 : 0,
            width: props.editing ? 24 : 0,
          },

          "& .MuiSlider-track": {
            height: 8,
            color: "#0074C8",
          },
          "& .MuiSlider-rail": {
            color: "#e0e0de",
            opacity: 1,
            height: 8,
          },
          "& .MuiSlider-valueLabel": {
            fontSize: 12,
            fontWeight: "normal",
            top: props.editing ? 52 : 28,
            backgroundColor: "unset",
            color: "black",
            "&:before": {
              display: "none",
            },
            "& *": {
              background: "transparent",
              color: "black",
            },
          },
        }}
        value={value}
        valueLabelDisplay="on"
        valueLabelFormat={(value) =>
          moment().startOf("day").add(value, "minutes").format("HH:mm")
        }
        min={0}
        max={1440}
        step={15}
        onChange={handleChange}
        disabled={props.editing ? false : true}
      />
    </>
  );
};

import React, {useEffect, useState} from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoModal from "../../Components/Modals/InfoModal";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useStateValue } from "../../State";
import { types } from "../../Reducers/actionTypes";
import Languages from "../../translations";

const DeleteDiscussionBooking = ({discussionId}) => {
  
  const {state, actions} = useStateValue();
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [bookingIdToDelete, setBookingIdToDelete] = useState("");
  const [deleting, setDeleting] = useState(false);

  const deleteStatus = state.discussion.deleteDiscussionBooking.status;

  useEffect(() => {
  }, [])

  useEffect(() => {
    if (deleting && deleteStatus === types.DELETE_DISCUSSION_BOOKING_SUCCESS) {
      actions.triggerAddToSnackBarQueue({message: Languages.discCancelSuccess, color: "#000000"});
      setDeleting(false);
    }
    if (deleting && deleteStatus === types.DELETE_DISCUSSION_BOOKING_FAILED) {
      actions.triggerAddToSnackBarQueue({message: Languages.discCouldNotBeCanceled, color: "#000000"});
      setDeleting(false);
    }
    
  }, [deleteStatus, deleting]);

  const onDeleteClick = (discussionId) => {
    setDeleteConfirmation(true);
    setBookingIdToDelete(discussionId);
  }

  const deleteBooking = () => {
    actions.triggerDeleteDiscussionBooking(bookingIdToDelete);
    setDeleting(true);
    setBookingIdToDelete("");
    setDeleteConfirmation(false);
  }

  const cancelDeleteBooking = () => {
    setBookingIdToDelete("");
    setDeleteConfirmation(false);
  }

  const buttonStyle = {
    button:{
      borderColor:"#8f002b",
      borderWidth:"1px",
      borderStyle:"solid",
      backgroundColor:"#f9e0e5",
      color:"#8f002b",

      "&:hover":{
        backgroundColor:"#b30036",
        color:"#f9e0e5"
      }
    },
    svg:{

    }
  }

  return(
    <>
      <Button 
        sx={buttonStyle.button}
        startIcon={<DeleteIcon />}
        disabled={deleteStatus === types.DELETING_DISCUSSION_BOOKING}
        size="small" 
        aria-label={Languages.discCancelDiscussionTime} 
        onClick={e => {e.stopPropagation(); onDeleteClick(discussionId)}} >{Languages.generalBack}</Button>
      <DeleteConfirmationPopUp isOpen={deleteConfirmation} onDelete={deleteBooking} onCancelDelete={cancelDeleteBooking}/>
    </>
  )
}

const DeleteConfirmationPopUp = ({isOpen, onDelete, onCancelDelete}) => {
  return(
    <InfoModal modalOpen={isOpen} headerText={Languages.discCancelDiscussionTime} onClose={onCancelDelete} allowClose={true}>
      <br />
      <Typography>{Languages.discCancelConfimation}</Typography>
      <br />
      <Button sx={{marginTop: "3px"}} variant="contained" color="secondary" onClick={onDelete}>{Languages.discYes}</Button>
      <Button sx={{marginLeft: "10px", marginTop: "3px"}} variant="outlined" color="secondary" onClick={onCancelDelete}>{Languages.discNo}</Button>
    </InfoModal>
  )
}

export default DeleteDiscussionBooking;
import { types } from './actionTypes';

const GrowthFolderReducer = (state, action) => {
  const payload = action.data;

  switch(action.type) {
    case types.LOADING_FOLDERS:

      return {
        ...state,
        state: "LOADING_FOLDERS",
      }

    case types.LOAD_FOLDERS_SUCCESS:

      return {
        ...state,
        state: "LOAD_FOLDERS_SUCCESS",
        folders: payload
      }
        
    case types.LOAD_FOLDERS_FAILED:
      return {
        ...state,
        state: "LOAD_FOLDERS_FAILED",
        errorCode: payload.Code !== undefined ? payload.Code : 777,
      }

      case types.LOADING_IMAGES:

        return {
          ...state,
          state: "LOADING_IMAGES",
        }

    case types.LOAD_IMAGES_SUCCESS:

      return {
        ...state,
        state: "LOAD_IMAGES_SUCCESS",
        images: payload
      }

    case types.LOAD_IMAGES_FAILED:
      return {
        ...state,
        state: "LOAD_IMAGES_FAILED",
        errorCode: payload.Code !== undefined ? payload.Code : 777,
      }

      case types.LOADING_IMAGE:
        return {
          ...state,
          state: "LOADING_IMAGE",
        }

      case types.LOAD_IMAGE_SUCCESS:
  
        return {
          ...state,
          state: "LOAD_IMAGE_SUCCESS",
          image: payload
        }
  
      case types.LOAD_IMAGE_FAILED:
        return {
          ...state,
          state: "LOAD_IMAGE_FAILED",
          errorCode: payload.Code !== undefined ? payload.Code : 777,
        }

        default: return state;

  }
}

export default GrowthFolderReducer;
import React, { useEffect, useState } from "react";
import "./message.css";
import {useStateValue} from "../../../State";
// import ChildProfilePicture from "../../ChildProfilePicture";
import Card                 from "@mui/material/Card";
import CardContent          from "@mui/material/CardContent";
import Typography           from "@mui/material/Typography";
import DeleteIcon           from "@mui/icons-material/Delete";
import Badge                from "@mui/material/Badge";
import withStyles from '@mui/styles/withStyles';
import AttachmentIcon       from '@mui/icons-material/Attachment';
import { findLengthOfLongestWordInString } from "../../../utils";
import Languages            from "../../../translations";
import ButtonBase           from "@mui/material/ButtonBase";
import IconButton           from "@mui/material/IconButton";
import Grid                 from "@mui/material/Grid";
import Button               from "@mui/material/Button";

const StyledBadge = withStyles(theme => ({
  badge: {
    width:22,
    height: 22,
    borderRadius: 50,
    right: -15,
    top: 12,
    padding: '0 5px',
  },
}))(Badge);

const DeleteConfirmation = ({show, text1, onClickYes, onClickNo}) => {
  
  if(show){
    return(
      <React.Fragment>
        <Typography color="textPrimary">{text1}?</Typography>
        <Button onClick={onClickYes}>{Languages.msgYesRemove}</Button>
        <Button onClick={onClickNo}>{Languages.msgCancel}</Button> 
      </React.Fragment>
    )
  }
}

const Message = (props) => {

  const {actions, state} = useStateValue();

  const {messaging} = state;
  const {deleteThread} = messaging;

  const { header, partContent } = props;

  const [headerLength, setHeaderLength] = useState(0);
  const [partContentLength, setPartContentLength] = useState(0);
  const [mount, setMount] = useState(true);
  const [deleteConfirmationPopup, setDeleteConfirmationPopup] = useState(false);
  const [deleteThreadLoading, setDeleteThreadLoading] = useState(false);
  const [threadDeletedSuccesfully, setThreadDeletedSuccessfully] = useState(false);
  const [threadDeletionFailed, setThreadDeletionFailed] = useState(false);

  useEffect( () => {
    if (mount === true) {
      setHeaderLength(findLengthOfLongestWordInString(header));
      setPartContentLength(findLengthOfLongestWordInString(partContent));
      setMount(false);
    }
    }, [header, partContent, mount]
  );

  useEffect( () => {
    //console.log("deleteThread.deletedThreadId", deleteThread.deletedThreadId);
    //console.log("props", props.threadId);
    
    if (deleteThreadLoading) {
    
      const thisThreadDeletionSuccess = deleteThread.status === "DELETE_MESSAGE_THREAD_SUCCESS" && deleteThread.deletedThreadId === props.threadId;
      const thisThreadDeletionFailed = deleteThread.status === "DELETE_MESSAGE_THREAD_FAILED" && deleteThread.deletedThreadId === props.threadId;

      if (thisThreadDeletionSuccess) {
        setDeleteThreadLoading(false);
        setThreadDeletedSuccessfully(true);
        actions.triggerLoadThreadMainStats();
      }

      if (thisThreadDeletionFailed) {
        setDeleteThreadLoading(false);
        setThreadDeletionFailed(true);
      }
    }
  }, [deleteThread, props.threadId]);

  const onDeleteClick = () => {
    setDeleteConfirmationPopup(true);
  }

  const onCancelDelete = () => {
    setDeleteConfirmationPopup(false);
  }

  const onConfirmDelete = (id) => {
    setDeleteConfirmationPopup(false);
    actions.triggerDeleteThread({threadId: id});
    setDeleteThreadLoading(true);
    setThreadDeletionFailed(false);
    setThreadDeletedSuccessfully(false);
  }


  if (deleteThreadLoading) {
    return(
      <Card className="Message2" data-testid={"Message-Cardstyle-0923laml"} style={{backgroundColor:"#4377BA", maxWidth: "100%"}}>             
      <CardContent>
        <Typography color="textPrimary">{Languages.msgRemovingThreadFromInbox}</Typography>
      </CardContent>
    </Card>
    )
  }

  if (threadDeletedSuccesfully) {
    return (  
      <Card className="Message2" data-testid={"Message-Cardstyle-0923laml"} style={{backgroundColor:"#4377BA", maxWidth: "100%"}}>             
        <CardContent>
          <Typography color="textPrimary">{Languages.msgThreadRemovalSucceeded}</Typography>
        </CardContent>
      </Card>
    )
  }

  if (threadDeletionFailed) {
    return (
      <Card className="Message2" data-testid={"Message-Cardstyle-0923laml"} style={{backgroundColor:"#4377BA", maxWidth: "100%"}}>             
        <CardContent>
          <Typography color="textPrimary">{Languages.msgThreadRemovalNotSuccessful}</Typography>
        </CardContent>
      </Card>
    )
  }

  if (deleteConfirmationPopup) {
    return (
      <React.Fragment>   
          <Card 
            className="Message2" 
            data-testid={"Message-Cardstyle-0923laml"} 
            style={props.unreadMessagesCount === 0 ? {backgroundColor:"#4377BA", maxWidth: "100%"} : {maxWidth: "100%"}}
          >             
            <CardContent>
              <DeleteConfirmation 
                text1={Languages.msgWishToRemoveThread + " " + props.header} 
                onClickYes={()=>onConfirmDelete(props.threadId)}
                onClickNo={onCancelDelete}
                show={deleteConfirmationPopup}
              />
            </CardContent>
        </Card>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>   
      <Card 
        className="Message2" 
        data-testid={"Message-Cardstyle-0923laml"} 
        style={props.unreadMessagesCount === 0 ? 
          {backgroundColor: "#D7E3ED" /*"#4377ba"*/, borderColor: "#99ABC5"/*"#4377ba"*/,borderWidth: '2px', borderStyle: "solid", borderRadius: "5px", maxWidth: "100%"} : {backgroundColor:"#355E94", maxWidth: "100%", borderColor: "#355E94", borderWidth: '2px', borderStyle: "solid", borderRadius: "5px"} 
          }
      >             
        <CardContent>
          
        <Grid container spacing={1}>
                  
          <Grid item xs={8} /*onClick={() => props.openmessage(props.threadId)}*/>
            
            <ButtonBase aria-label={Languages.msgGoToMessage + " " + props.header} id={props.counter} onClick={() => props.openmessage(props.threadId)} focusRipple={true} style={{width:"100%", display: "block"}}>
         
              <div className={ props.unreadMessagesCount === 0 ? "boldIfUnread" : "boldIfUnread"}> 
                <div data-testid={"Message-Header-lw0nepbn"} className={(headerLength > 20 || partContentLength > 20 ) ? "msgheader textWrap" : "msgheader textWrap2"}> 
                  { props.unreadMessagesCount === 0 ? 
                      <Typography  
                          noWrap={false}
                          display="block" 
                          variant="body2"  
                          style={{color: /*"#ffffff"*/ "#002E6D"}}
                          component="h2">{props.header}
                      </Typography>
                    :
                      <StyledBadge  
                        anchorOrigin={{vertical: "top", horizontal: "right"}} 
                        badgeContent={props.unreadMessagesCount}  
                        color="error"
                        margintop="12px">
                        <Typography style={{marginRight:"6px", color: "#ffffff"}}
                          noWrap={false}
                          display="block" 
                          variant="body2"   
                          component="h2"><b>{props.header}</b> 
                        </Typography>
                      </StyledBadge>
                  }
                </div>
                
                <div style={{marginLeft:"14px"}} className="text-left textWrap underSpace">
                  <span className="boldIfUnread">
                    <Typography 
                      variant="body2" 
                      color="textSecondary" 
                      component="p" 
                      style={ props.unreadMessagesCount === 0 ? {color: "#002E6D"} : {color: "#ffffff"}}> 
                        {props.latestSenderName}: <br/> {props.partContent}
                    </Typography>
                  </span>
                </div>
              </div>
            
            </ButtonBase>
            
          </Grid>
          
            
         
          <Grid item xs={3}>
            <div style={{textAlign: "right"}}>
            <Typography 
              style={props.unreadMessagesCount === 0 ? {color: "#002E6D", fontVariant:"small-caps"} : {color: "#ffffff", fontVariant:"small-caps"}}
              //color="textPrimary" 
              variant="body2"
              onClick={() => props.openmessage(props.threadId)}>
                {props.date} <br/> {Languages.msgNumber}: {props.totalNumberOfMessages}
            </Typography>
            </div>
            <div style={props.unreadMessagesCount === 0 ? {color: "#002E6D"} : {color: "#ffffff"}} className="text-right">
              {props.countAttachment > 0 ? 
                <AttachmentIcon 
                  size="small"
                  style={props.unreadMessagesCount === 0 ? {color: "#002E6D"} : {color: "#ffffff"}} 
                  data-testid={"Message-AttachmentIcon-jrenblsd"}/> 
              : null} 
              {props.countAttachment > 0 ? 
                <Typography component="span" variant="body2" /*color="textPrimary"*/ style={props.unreadMessagesCount === 0 ? {color: "#002E6D"} : {color: "#ffffff"}}>&nbsp;{props.countAttachment}</Typography> 
              : null} 
            </div>
          </Grid>
          <Grid item xs={1}>
            <div style={{textAlign: "right"}}>
            <IconButton 
              size="small" 
              aria-label={Languages.msgDeleteThread} 
              onClick={onDeleteClick}>
                <DeleteIcon 
                  style={props.unreadMessagesCount === 0 ? {color: "#002E6D"} : {color: "#ffffff"}} 
                  data-testid="deleteTrashCanButton"/>
            </IconButton>
            </div>
          </Grid>

          {/*<Grid container item xs={1}>
            <div className="trashCanCenter"><IconButton onClick={onDeleteClick}><DeleteIcon /></IconButton></div>
                </Grid>*/}
        </Grid>
      
      </CardContent>
      </Card>
    </React.Fragment>
  );
}
export default Message;
import React, { useState } from 'react';
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';

import InfoModal from '../Modals/InfoModal';
import { useInterval } from '../../Hooks';
import Languages from '../../translations';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  body1: {
    backgroundColor: 'white',
    padding: theme.spacing(1),
  },
}));

const WarningDialog = ({showWarning, logoutTime, onOK}) => {
  const [count, setCount] = useState(logoutTime.diff(moment(), 'seconds'));
  const classes = useStyles()

  useInterval(1*1000, () => {
    let newCount = logoutTime.diff(moment(), 'seconds');
    if (newCount <= 0) {
      newCount = 0;
    }
    setCount(newCount);
  })

  return (
    <InfoModal modalOpen={showWarning} headerText={Languages.logoutAutoTitle} allowClose={true}
      actions={
        <Button
          color="secondary"
          variant="contained"
          size="medium"
          onClick={onOK}
          disabled={false}
          onClose={onOK}
        >
          OK
              </Button>
      }
    >
      <Typography variant="body1" classes={classes}>
      {Languages.formatString(Languages.logoutAutoInfo, Math.floor(count/60), count % 60)}
      </Typography>
    </InfoModal>
);
}

export default WarningDialog;
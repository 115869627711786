import React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Languages from "../../../translations";
import Box from "@mui/material/Box";

const DeletedBox = ({isShown}) => {

    if(isShown){
      return(
        <Grid container spacing={3}>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <Card style={{backgroundColor:"#4377BA"}} raised={false} color="primary" width="75%">
              <CardContent width="75%">
                <div className="messagetext SMBtextWrap messagesender">
            
                  <Box fontStyle="italic">
                    <Typography color="textPrimary" variant="body2">{Languages.msgyouHaveRemovedThisMessage}</Typography> 
                  </Box>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      )
    }
  
    return(null);

}

export default DeletedBox;
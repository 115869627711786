import React, { useState, useEffect, useRef } from "react";
//import { Redirect } from "react-router-dom";
// import {Redirect} from "react-router-dom";
import "../login.css";
import Languages from "../../../translations";
import ButtonMUI from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import PasswordInputField from "./PasswordInputField";
import LoginStatus from "./LoginStatus";
import InputField from "./InputField";
//import { useEventListener } from "../../../Hooks";
import Box from '@mui/material/Box';
import FormGroup from "@mui/material/FormGroup";
import CookieNote from "../../../Components/CookieNote";
import CircularProgress from "@mui/material/CircularProgress";
import { useAuth } from "../../../Auth";
import {Link} from "react-router-dom";

// TODO: throws when enter is hitted. refs are not set!

const LoginForm = ({ /*history,*/ onLogin, /*onForgetLogin, onFirstTimeLogin,*/ cookiesAccepted, onCookieAccept }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showButton, setShowButton] = useState(true);
  const [showErrorText, setShowErrorText] = useState(false);
  const { authState, loading } = useAuth();
  const [isCapsLockOn, setIsCapsLockOn] = useState(false);
  // Save refs of username/password input to enable focus handling
  const usernameFieldRef = useRef();
  const passwordFieldRef = useRef();


  // Do not activate this. Keyboard user needs to be able to activate link with Enter key
  /*useEventListener('keydown', ({ keyCode }) => {
    if (keyCode === 13) {
      doLogin();
    }
  }, window);*/

  const checkCapsLock = (e) => {
    if (e.getModifierState('CapsLock')) {
      setIsCapsLockOn(true);
    } else {
      setIsCapsLockOn(false);
    }
  }

  useEffect(() => {
    if (authState.lastHttpCode != 200) {
      setShowButton(true);
      setShowErrorText(true)
    } else {
      setShowErrorText(false)
    }
    if (loading) {
      setShowButton(false);
      setShowErrorText(false);
    } else {
      setShowButton(true);
    }

  }, [authState, loading])

  const doLogin = (e) => {
    e.preventDefault();
    if (username.length > 1 && password.length > 1) {
      onLogin(username, password);
      return;
    }
    setShowErrorText(false);
    if (username.length < 1) {
      usernameFieldRef.current.focus();
      return;
    }

    if (password.length < 1) {
      passwordFieldRef.current.focus();
      return;
    }
  }

  if (!cookiesAccepted) {
    return (
      <React.Fragment>
        <CookieNote onCookieAccept={onCookieAccept} cookiesAccepted={cookiesAccepted} />
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
        <React.Fragment>
          
          <form onSubmit={doLogin}>
          {/*<div style={{backgroundColor: "#4377BA"}}>*/}
          <FormGroup>
            <InputField
              fieldId="test_Login_username"
              label={Languages.loginUserName}
              ariaLabel="Username input field"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              ref={usernameFieldRef}
            />
            <PasswordInputField
              fieldId="test_Login_password"
              label={Languages.generalPassword}
              ariaLabel="Password input field"
              onKeyDown={checkCapsLock}
              helperText={isCapsLockOn && (
              <Typography color='red'>{Languages.loginCapsLockAlert}</Typography>
              )}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              ref={passwordFieldRef}
            />
          </FormGroup>
          {/*</div>*/}

          <LoginStatus showErrorText={showErrorText} />
          <br />

          <div className="row justify-content-center">
            {showButton &&
              <ButtonMUI style={{ backgroundColor: "#4377BA", color: "#ffffff", width: "70%", fontWeight: "bold" }}
                id="test_Login_LoginBtn" aria-label={Languages.loginBtn}
                //onClick={doLogin} 
                type="submit">{Languages.loginBtn}</ButtonMUI>
            }
            {loading && <CircularProgress />}
          </div>
          
          <Link to="/forgottenpassword" style={{fontSize: "1.25rem", color: "rgb(67, 119, 186)"}}>{Languages.forgotten}</Link>
          
          </form>
        </React.Fragment>
      <Box />
    </React.Fragment>
  );
}

export default LoginForm;

import React, { useEffect, useState }   from "react";
import "./fullimagemodal.css";
import { useStateValue }        from "../../../State";
//import Languages              from "../../../translations";
import Dialog                 from "@mui/material/Dialog";
import DialogContent          from "@mui/material/DialogContent";
import AppBar                 from "@mui/material/AppBar";
import Toolbar                from "@mui/material/Toolbar";
import IconButton             from "@mui/material/IconButton";
import CloseIcon              from "@mui/icons-material/Close";
import Typography             from "@mui/material/Typography";
import { ATTACHMENT_TYPES } from "../../../Api";
import CircularProgress from "@mui/material/CircularProgress";


/*
imagebase64withType: base 64 form image "image/jpeg;base64,öieojrekrneksnrkesrnselknresjbr..."
closefunction: function() to call when the dialog is to be closed
*/

const FullImageModal = ({ closefunction, imagename, imageId, imageMimeType}) => {

  const { state, actions } = useStateValue();

  const { loadFullImage } = state.files;

  const [ imgLoaded, setImgLoaded ] = useState(false);
  //const [ picture, setPicture ] = useState(undefined);

  useEffect(() => {
    //console.log(loadFullImage.savedFullImages, imageMimeType);
    //console.log("data:" + imageMimeType + ";base64," + loadFullImage.savedFullImages[imageId])
    
    if (!imgLoaded) {
      fetchFullImage(state, actions, imageId);
      setImgLoaded(true);
    }
  }, [state, actions, imageId, imgLoaded])


    return (
      <Dialog fullScreen open={true} onClose={closefunction} scroll={"paper"}>
        
          <React.Fragment>
            <AppBar>
              <Toolbar>
                <IconButton
                  autoFocus
                  edge="start"
                  color="inherit"
                  onClick={closefunction}
                  aria-label="Close full image view"
                  size="large">
                  <CloseIcon />
                </IconButton>
                {imagename !== null && imagename !== undefined ? 
                  <Typography variant="h6">{imagename}</Typography> 
                : null }  
              </Toolbar>
            </AppBar>   
            <DialogContent>       
            {Object.keys(loadFullImage.savedFullImages).includes(imageId) ?
              <div>
                <div className="fullimagemodal">
                  <img className="fullimagemodalimg" 
                        alt="full version" 
                        src={"data:" + imageMimeType + ";base64," + loadFullImage.savedFullImages[imageId]}
                  />
                </div>
              </div>
            :
            <div style={{marginTop: "50%", textAlign: "center"}}>
            <CircularProgress/>
            </div>
          }
            </DialogContent>
          </React.Fragment>
      </Dialog>
    );
}

const fetchFullImage = async (state, actions, imageId) => {

  const { loadFullImage } = state.files;

  if (Object.keys(loadFullImage.savedFullImages).includes(imageId) !== true) {
    actions.triggerLoadFullImage({imageId: imageId, type: ATTACHMENT_TYPES.fullImage});
  }

}


export default FullImageModal;

import React, { useState } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Typography, TextField, InputAdornment, IconButton/*, Grid*/ } from "@mui/material";

const PasswordInputField = React.forwardRef(({ fieldId, value, onChange, label, ariaLabel, onKeyDown, helperText}, ref) => {
  const [showPassword, setShowPassword] = useState(false);
 
  return (
    <>
    <div style={{minHeight:"20 px"}}>&nbsp;</div>
    <Typography
      component={'span'}
      variant={'body2'}
      color="textSecondary">


      <TextField id={fieldId}
        // inputRef={this.password}
        aria-label={ariaLabel}
        label={label}
        //color="textSecondary"
        type={showPassword ? 'text' : 'password'}
        value={value}
        helperText={helperText}
        onKeyDown={onKeyDown}
        onChange={onChange}
        inputRef={ref}
        inputProps={{className:"usernamepasswordinput123"}}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start"
            >
              <IconButton
                aria-label="Toggle password visibility"
                onClick={(e) => setShowPassword(!showPassword) }
                size="large">
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    </Typography>
    </>
  );});

export default PasswordInputField;
import { types } from './actionTypes';

const VasuReducer = (state, action) => {
  const payload = action.data
  
  /*console.log('VASU REDUCER.'+
              "\n action: ", action +
              "\n action.type ", action.type+
              "\n state: ",state);*/

  switch (action.type) {
    case types.LOADING_VASU:
      return {
        ...state,
        state: types.LOADING_VASU
      }
    case types.VASU_LOAD_OK:
      //console.log('VASU REDUCER. VASU_LOAD_OK payload: ', payload);
      //payload.Start = Moment (payload.Start); //new Date().setHours(8,0,0);//setHours(8,15,0) asettaa myös defaultti minuutit
      //payload.End   = Moment (payload.End);   //new Date().setHours(16,0,0);

      return {
        ...state,
        Code: 200,
        state: types.VASU_LOAD_OK,
        GeneralPart: payload.GeneralPart,
        Table1: payload.Table1,
        Table2: payload.Table2,
        Table3: payload.Table3,
        Table4: payload.Table4,
        Table5: payload.Table5,
        VasuSupportExtraPart: payload.VasuSupportExtraPart,
        VasuApprovalsTable: payload.VasuApprovalsTable,


      }
    case types.SAVING_VASU:
      return {
        ...state,
        state: types.SAVING_VASU
      }
    case types.VASU_LOAD_FAILED:
      console.log('VASU. VASU_LOAD_FAILED payload: ', payload);
      return {
        ...state,
        Code: payload.Code,
        state: types.VASU_LOAD_FAILED
      }
    case types.VASU_SAVED_FAILED:
      console.log('VASU REDUCER. VASU_SAVED_FAILED payload: ', payload);
      return {
        ...state,
        Code: payload.Code,
        state: types.VASU_SAVED_FAILED
      }
    case types.VASU_SAVED_OK:
      //console.log('VASU REDUCER. VASU_SAVED_OK payload: ', payload);
      state.Table1.Data.ChildNotes[payload.SubItem-1].Text = payload.ChildNotes;
      state.Table1.Data.ChildNotes[payload.SubItem-1].Saved = payload.Saved;
      state.Table1.Data.ParentNotes[payload.SubItem-1].Text = payload.ParentNotes;
      state.Table1.Data.ParentNotes[payload.SubItem-1].Saved = payload.Saved;
      
      return {
        ...state,
        Code: 200,
        state: types.VASU_SAVED_OK,
        Table1: state.Table1
      }
    case types.VASU_HANDLED:
      //console.log("VASU REDUCER. VASU_HANDLED");
      return {
        ...state,
        state: types.VASU_HANDLED,
        }
    case types.APPROVAL_SAVED_OK:
      console.log("VASU REDUCER. APPROVAL_SAVED_OK. Payload", payload);

      if(payload.DocumentId === state.VasuSupportExtraPart.IdDiscussionDecision){
        console.log("Discussion decision")
        if(payload.PersonId === state.GeneralPart.Parent1Id){
          console.log("Parent1");
          state.VasuApprovalsTable.DiscussionApprovals.Parent1Approval = {PersonId: payload.PersonId, DocumentId: payload.DocumentId, Info: payload.Info, Saved: payload.Saved};
        }
        if(payload.PersonId === state.GeneralPart.Parent2Id){
          console.log("Parent2");
          state.VasuApprovalsTable.DiscussionApprovals.Parent2Approval = {PersonId: payload.PersonId, DocumentId: payload.DocumentId, Info: payload.Info, Saved: payload.Saved};
        }
      }

      if(payload.DocumentId === state.VasuSupportExtraPart.IdEnhancedSupportDecision){
        console.log("Discussion decision")
        if(payload.PersonId === state.GeneralPart.Parent1Id){
          console.log("Parent1");
          state.VasuApprovalsTable.EnhancedApprovals.Parent1Approval = {PersonId: payload.PersonId, DocumentId: payload.DocumentId, Info: payload.Info, Saved: payload.Saved};
        }
        if(payload.PersonId === state.GeneralPart.Parent2Id){
          console.log("Parent2");
          state.VasuApprovalsTable.EnhancedApprovals.Parent2Approval = {PersonId: payload.PersonId, DocumentId: payload.DocumentId, Info: payload.Info, Saved: payload.Saved};
        }
      }

      if(payload.DocumentId === state.VasuSupportExtraPart.IdSpecialSupportDecision){
        console.log("Discussion decision")
        if(payload.PersonId === state.GeneralPart.Parent1Id){
          console.log("Parent1");
          state.VasuApprovalsTable.SpecialApprovals.Parent1Approval = {PersonId: payload.PersonId, DocumentId: payload.DocumentId, Info: payload.Info, Saved: payload.Saved};
        }
        if(payload.PersonId === state.GeneralPart.Parent2Id){
          console.log("Parent2");
          state.VasuApprovalsTable.SpecialApprovals.Parent2Approval = {PersonId: payload.PersonId, DocumentId: payload.DocumentId, Info: payload.Info, Saved: payload.Saved};
        }
      }
      console.log(state.VasuApprovalsTable);
      return {
        ...state,
        VasuApprovalsTable: state.VasuApprovalsTable
      }
      
    default: return state;
  }
}

export default VasuReducer;
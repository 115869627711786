import React from "react";
import "../newmessage.css";
import Fab from "@mui/material/Fab";
import CheckIcon from "@mui/icons-material/Check";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import RefreshIcon from '@mui/icons-material/Refresh';
import Languages from "../../../../translations";
import { isAnyPropUndefinedOrNull } from "../../../../utils";

const FixedBottomBarSelectChildrenContent = (props) => {
  
  const {loadPotentialRecipientsState, loadRecipientsData, closeSelectChildrenView, isChildrenChecked} = props;

  const undefinedOrNullPropExists = isAnyPropUndefinedOrNull(props);
  //console.log("propsUndefinedOrNull", undefinedOrNullPropExists);

  if (undefinedOrNullPropExists === false) {

    if (loadPotentialRecipientsState === "NEVER_LOADED" || 
    loadPotentialRecipientsState === "WAITING_LOAD_POTENTIAL_RECIPIENTS") {
      return (
        <Fab id="test_NewMsg_Kids_OkBtn" className="fabcheck" 
            style={{margin:"10px", color: "#000000"}} color="primary" aria-label="Add"> 
          <CircularProgress/>
        </Fab>) 
    }
    else if (loadPotentialRecipientsState === "LOAD_POTENTIAL_RECIPIENTS_SUCCESS") {
      return (
        isChildrenChecked ?  
        <Fab
          id="test_NewMsg_Kids_OkBtn" className="fabcheck" 
          style={{margin:"10px", color: "#000000"}} color="primary" 
          aria-label="Add" onClick={() => closeSelectChildrenView()}><CheckIcon />
        </Fab>
        :        
        <Fab
          id="test_NewMsg_Kids_OkBtn" className="fabcheck" 
          style={{margin:"10px"}} 
          disabled 
          aria-label="Add"><CheckIcon />
        </Fab>
        )
    }
    else {
      return (        
        <React.Fragment>
          <Typography>{Languages.msgCouldNotLoadRecipientsFromServer} {Languages.tryAgainQ}</Typography>
          <Fab 
              id="test_NewMsg_Kids_OkBtn" className="fabcheck" 
              style={{margin:"10px", color: "#000000"}} color="primary" 
              aria-label="Add" onClick={() => loadRecipientsData()}><RefreshIcon/>
          </Fab>
        </React.Fragment>)
    }

  }
  else{
    return(<div data-testid="FBBSelectChildrenContent-undefined-or-null"></div>)
  }
}


export default FixedBottomBarSelectChildrenContent;
import React, { useEffect, useState } from "react";

import { useStateValue } from "../../../../State";

import Drawer from "@mui/material/Drawer";

import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import moment from "moment";
import ChildProfilePicture from "../../../ChildProfilePicture";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import "./CTBBottomNav2.css";

import InvoiceDialog from "../CareTimeBookingContent/InvoiceDialog";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const CTBSummaryDrawer = (
  { CTBdrawerOpen, CTBhandleDrawerOpen, childList },
  props
) => {
  const { actions, state } = useStateValue();
  const [summaryTableOpen, setSummaryTableOpen] = React.useState(false);

  const [curMonth, setCurMonth] = React.useState(moment());

  const requestedTimespan = {
    Start: curMonth.startOf("month").format("YYYY-MM-DDThh:mm:ss"),
    End: curMonth.endOf("month").format("YYYY-MM-DDThh:mm:ss"),
  };

  const handleMonthChange = (dir) => {
    if (dir === "right") {
      curMonth.add(1, "months");
    } else {
      curMonth.add(-1, "months");
    }
    actions.triggerLoadNursingTimesSummary({
      Start: curMonth.startOf("month").format("YYYY-MM-DDThh:mm:ss"),
      End: curMonth.endOf("month").format("YYYY-MM-DDThh:mm:ss"),
    });
  };
  const [selectedChildId, setSelectedChildId] = React.useState("");
  useEffect(() => {
    if (CTBdrawerOpen) {
      actions.triggerLoadNursingTimesSummary({
        Start: curMonth.startOf("month").format("YYYY-MM-DDThh:mm:ss"),
        End: curMonth.endOf("month").format("YYYY-MM-DDThh:mm:ss"),
      });
    }
  }, [CTBdrawerOpen]);
  //console.log(props.childList);
  const CTBSummary = state.nursingTimesSummary.NT;

  const openSummaryTable = (Id) => {
    setSelectedChildId(Id);
    setSummaryTableOpen(true);
  };

  const closeSummaryTable = () => {
    setSummaryTableOpen(false);
  };

  return (
    <Drawer
      anchor={"bottom"}
      open={CTBdrawerOpen}
      onClose={CTBhandleDrawerOpen}
      PaperProps={{
        sx: {
          width: "100%",
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          mb: "40px",
          color: "white",
          backgroundColor: "white",
        },
      }}
      sx={{ zIndex: 0 }}
      transitionDuration={{ enter: 600, exit: 400 }}
    >
      {(state.nursingTimesSummary.NT.status === "LOADING_NT_SUMMARY" ||
        state.nursingTimesSummary.NT.status === "NEVER_LOADED") && (
        <>
          <Box sx={{ mt: 5, mb: 6 }}>
            <Puller onClick={CTBhandleDrawerOpen} />
            <Stack spacing={1} alignItems="center">
              <Typography color="textSecondary" fontSize="medium">
                {"Ladataan laskutusperustetta"}
              </Typography>
              <CircularProgress />
            </Stack>
          </Box>
        </>
      )}

      {state.nursingTimesSummary.NT.status === "NT_SUMMARY_LOAD_SUCCESS" && (
        <Box
          sx={{
            width: "auto",
            height: "auto",
            maxHeight: "500px",
            backgroundColor: "white",
            borderTopLeftRadius: "inherit",
            borderTopRightRadius: "inherit",
          }}
          role="presentation"
        >
          <Puller />
          <>
            <Box sx={{ mt: 3, ml: "30%" }}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <IconButton onClick={() => handleMonthChange("left")}>
                  <ChevronLeftIcon />
                </IconButton>
                <Typography color="textSecondary" fontSize="medium">
                  {moment(requestedTimespan.Start).format("MMMM")}
                </Typography>
                <IconButton onClick={() => handleMonthChange("right")}>
                  <ChevronRightIcon />
                </IconButton>
              </Stack>
            </Box>
          </>
          <List sx={{ ml: 0.5, mt: 2 }}>
            {CTBSummary.ListChildStats.map((stat, index) => (
              <>
                <Stack direction="row" spacing={1.5}>
                  <Box sx={{ mt: 2, ml: 0.5 }}>
                    <Avatar>
                      <ChildProfilePicture childId={stat.childPersonId} />
                    </Avatar>
                    <Typography color="textSecondary" fontSize="small">
                      {
                        childList
                          .filter((child) => child.Id === stat.childPersonId)[0]
                          .FirstName.split(/\s+/)[0]
                      }
                    </Typography>
                  </Box>
                  <Box>
                    <List>
                      <ListItemText>
                        <Typography color="textSecondary">
                          {"Hoidontarve: " + stat.serviceneedType}
                        </Typography>
                      </ListItemText>
                      <ListItemText>
                        <Typography color="textSecondary">
                          {"Maksuluokka: " + stat.paymentcategory}
                        </Typography>
                      </ListItemText>
                      <ListItemText>
                        <Typography color="textSecondary">
                          {"Ilmainen aika: " + stat.freeHours}
                        </Typography>
                      </ListItemText>
                      <ListItemText>
                        <Typography color="textSecondary">
                          {"Käytetty aika: " +
                            stat.spentHoursOrDays +
                            " / " +
                            stat.maxSpendAvailable}
                        </Typography>
                      </ListItemText>
                      <Button
                        variant="contained"
                        onClick={() => openSummaryTable(stat.childPersonId)}
                        sx={{ backgroundColor: "#00B3DF" }}
                      >
                        Laskutusperuste
                      </Button>
                    </List>
                  </Box>
                </Stack>
                {index != CTBSummary.ListChildStats.length - 1 && <Divider />}
              </>
            ))}
          </List>
        </Box>
      )}
      {summaryTableOpen && (
        <InvoiceDialog
          timespan={{
            start: moment(requestedTimespan.Start).format("YYYY-MM-DD"),
            end: moment(requestedTimespan.End).format("YYYY-MM-DD"),
          }}
          childId={selectedChildId}
          close={closeSummaryTable}
        ></InvoiceDialog>
      )}
    </Drawer>
  );
};

export default CTBSummaryDrawer;

const Puller = styled(Box)(() => ({
  width: 30,
  height: 6,
  backgroundColor: "#cccccc",
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

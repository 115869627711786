import React from 'react';
import            '../pedagdocimage.css';

const ImageViewer = ({ image }) => {
  if (!image) {
    return (null);
  }

  else {
    return(
      <figure>
        <img src = { "data:" + image.MimeType + ";base64," + image.Data } alt = "PedagDocFullImage"></img>
        <figcaption>
          {image.ImgDescription}
        </figcaption>
      </figure>
    );
  }
}

export default ImageViewer;
import React, { useEffect } from "react";
import { useStateValue } from "../../State";
import { SessionManager } from "../../Auth";
import Languages from "../../translations";
import moment from "moment";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DaisyLogo from "../../images/Family-21.png";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const DevelopmentNotification = () => {
  const [expanded, setExpanded] = React.useState(false);
  const { state } = useStateValue();
  const { settings } = state;
  const [developmentNotificationTexts, setDevelopmentTexts] = React.useState(
    []
  );
  const [language, setLanguage] = React.useState("en");
  const [validNotification, setValidNotification] = React.useState(false);
  const [startTime, setStartTime] = React.useState("Invalid start time");
  const [endTime, setEndTime] = React.useState("Invalid end time");

  const userLang = SessionManager.getUserLanguage();

  useEffect(() => {
    validityChecker();
    getDevelopmentNotificationTexts();
    setLanguage(userLang);
  }, []);

  const validityChecker = () => {
    let startTimeSetting = settings.communeSettings.find(
      (setting) =>
        setting.Key === "DaisyFamily/DevelopmentNotification/StartTime"
    );
    let endTimeSetting = settings.communeSettings.find(
      (setting) => setting.Key === "DaisyFamily/DevelopmentNotification/EndTime"
    );

    if (
      startTimeSetting !== null &&
      typeof startTimeSetting !== undefined &&
      endTimeSetting !== null &&
      typeof endTimeSetting !== undefined
    ) {
      let formatStartTime = moment(
        startTimeSetting.ValueString,
        "YYYY-MM-DD"
      ).toDate();
      setStartTime(formatStartTime);
      let formatEndTime = moment(
        endTimeSetting.ValueString,
        "YYYY-MM-DD"
      ).toDate();
      setEndTime(formatEndTime);
      const dateToday = moment().toDate();
      const valid = moment(dateToday).isBetween(formatStartTime, formatEndTime);
      setValidNotification(valid);
    } else {
      setValidNotification(false);
    }
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const getDevelopmentNotificationTexts = () => {
    const copyDevelopmentNotificationTexts = [...developmentNotificationTexts];
    let developmentTextFI = settings.communeSettings.find(
      (setting) => setting.Key === "DaisyFamily/DevelopmentNotificationTextFI"
    );
    if (developmentTextFI != null && typeof developmentTextFI != undefined) {
      copyDevelopmentNotificationTexts[0] = developmentTextFI.ValueString;
    } else {
      copyDevelopmentNotificationTexts[0] =
        Languages.awNotAvailableDevTextTrans;
    }
    let developmentTextEN = settings.communeSettings.find(
      (setting) => setting.Key === "DaisyFamily/DevelopmentNotificationTextEN"
    );
    if (developmentTextEN != null && typeof developmentTextEN != undefined) {
      copyDevelopmentNotificationTexts[1] = developmentTextEN.ValueString;
    } else {
      copyDevelopmentNotificationTexts[1] =
        Languages.awNotAvailableDevTextTrans + developmentTextFI.ValueString;
    }
    let developmentTextSV = settings.communeSettings.find(
      (setting) => setting.Key === "DaisyFamily/DevelopmentNotificationTextSV"
    );
    if (developmentTextSV != null && typeof developmentTextSV != undefined) {
      copyDevelopmentNotificationTexts[2] = developmentTextSV.ValueString;
    } else {
      copyDevelopmentNotificationTexts[2] =
        Languages.awNotAvailableDevTextTrans + developmentTextFI.ValueString;
    }
    setDevelopmentTexts(copyDevelopmentNotificationTexts);
  };

  if (validNotification === true) {
    return (
      <Card
        sx={{
          bgcolor: "#FFEBD4",
          border: "1px solid #EE5711",
          borderLeft: "7px solid #EE5711",
          margin: "5px",
        }}
      >
        <CardHeader
          avatar={
            <Avatar
              sx={{ bgcolor: "#002E6D" }}
              aria-label="DaisyFamily logo"
              src={DaisyLogo}
            />
          }
          title={
            <p style={{ color: "black", fontSize: "large", marginBottom: "0" }}>
              <b>{Languages.awDevelopersInform}</b>
            </p>
          }
          subheader={moment(startTime).format("LL")}
        />
        <CardActions disableSpacing>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label={Languages.awShowMore}
            title={Languages.awShowMore}
          >
            <ExpandMoreIcon sx={{ color: "#EE5711" }} />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography paragraph sx={{ color: "black" }}>
              {language.slice(0, 2) === "fi" && developmentNotificationTexts[0]}
              {language.slice(0, 2) === "en" && developmentNotificationTexts[1]}
              {language.slice(0, 2) === "sv" && developmentNotificationTexts[2]}
            </Typography>
            <Typography variant="caption" sx={{ color: "black" }}>
              {Languages.formatString(
                Languages.bbValidUntilBBCard,
                moment(endTime).format("LL")
              )}
            </Typography>
          </CardContent>
        </Collapse>
      </Card>
    );
  } else return null;
};

export default DevelopmentNotification;

import { ApiBase } from "../internal";

export class ImageApi extends ApiBase {
  constructor() {
    super();
  }

  async loadProfilePictures () {
    const response = await this.httpClient.get("/image/getprofilepictures/");
    
    return response;
  }
}

import React from "react";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import DevelopmentNotification from "./DevelopmentNotification";
import DaycareNotification from "./DaycareNotification";
import MessageNotifications from "./MessageNotifications";
import ReservationNotifications from "./ReservationNotifications";
import PermissionNotifications from "./PermissionNotifications";
import BulletinboardNotifications from "./BulletinboardNotifications";
import Languages from "../../translations";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import DaisyLogo from "../../images/Family-21.png";
import ErrorIcon from "@mui/icons-material/Error";
import { useStateValue } from "../../State";
import AvailableDiscussionNotifications from "./AvailableDiscussionNotifications";

const NotificationsList = ({ onClose, bulletins }) => {
  const { state } = useStateValue();
  const { mainstats } = state;
  console.log(mainstats);
  if (mainstats.actionViewNotis > 0) {
    return (
      <>
        {bulletins === true && (
          <Divider textAlign="left">
            <Chip label={Languages.awBulletins} sx={{ bgcolor: "#002E6D" }} />
          </Divider>
        )}
        <DevelopmentNotification />
        <DaycareNotification />
        <BulletinboardNotifications onClose={onClose} />
        <ReservationNotifications onClose={onClose} />
        <MessageNotifications onClose={onClose} />
        <PermissionNotifications onClose={onClose} />
        <AvailableDiscussionNotifications />
      </>
    );
  } else if (mainstats.actionViewNotis === 0) {
    return (
      <>
        <DevelopmentNotification />
        <Typography
          sx={{
            backgroundColor: "#DCEEDF",
            color: "black",
            display: "inline-block",
            padding: "15px",
            border: "solid 1px #8EC5A9",
            borderLeft: "solid 3px #006633",
          }}
        >
          <DoneAllIcon sx={{ color: "#006633" }} />
          &nbsp;&nbsp;{Languages.awAllDone}
        </Typography>
        <CardMedia component="img" image={DaisyLogo} alt="DaisyFamily logo" />
      </>
    );
  } else
    return (
      <>
        <Typography
          sx={{
            backgroundColor: "#F9E0E5",
            color: "black",
            display: "inline-block",
            padding: "15px",
            border: "solid 1px #E18994",
            borderLeft: "solid 3px #B30036",
          }}
        >
          <ErrorIcon sx={{ color: "#B30036" }} />
          &nbsp;&nbsp;{Languages.errorBackend}
        </Typography>
        <CardMedia component="img" image={DaisyLogo} alt="DaisyFamily logo" />
      </>
    );
};

export default NotificationsList;

import React, { useState } from "react";
import "./newmessage.css";
import MessageTitleField from "./Components/MessageTitleField";
import RecipientPills from "./Components/RecipientPills";
import ContentBoxMUI from "../../../Components/ContentBoxMUI";
import SendNewMessage from "../../../Components/Messaging/SendNewMessage";
import Languages from "../../../translations";
import { isAnyPropUndefinedOrNull } from "../../../utils";


const NewMessageWritingView = (props) => {

    const {currentlyCheckedList, currentlyCheckedListWithObjects, allPotentialRecipients, pillClicked,
      openSelectRecipientsView, closeNewMessageAndOpenInbox, tempSavedMessageContent, saveTempMessageContent,
      tempSavedTitleContent, saveTempTitleContent, postNewMessageState, snackBarState, sendNewMessageToServer} = props;

    const undefinedOrNullPropExists = isAnyPropUndefinedOrNull(props);

    const [messageTitle, setMessageTitle] = useState("");
    
    const maxLengthForTitle = 100;
  
      if (undefinedOrNullPropExists) {
        return(<div data-testid="NewMessageWritingView-undefined-or-null"></div>)
      }

      else {
        return (
          <>
            <RecipientPills 
              currentlyCheckedList={currentlyCheckedList}
              allPotentialRecipients={allPotentialRecipients}
              openSelectRecipientsView={openSelectRecipientsView}
              pillClicked={(id) => pillClicked(id)}
            />

            <MessageTitleField 
              saveTempTitleContent={(content) => saveTempTitleContent(content)}
              tempSavedTitleContent={tempSavedTitleContent}
              maxLengthForTitle={maxLengthForTitle}
              setMessageTitle={(title) => setMessageTitle(title)}
              messageTitle={messageTitle}
            />

            <SendNewMessage
              sendTitle={messageTitle}
              sendList1={currentlyCheckedList}
              sendList1withObjects={currentlyCheckedListWithObjects}
              closeNewMessageAndOpenInbox={closeNewMessageAndOpenInbox}
              tempSavedMessageContent={tempSavedMessageContent}
              saveMessageContentToHigherComponentState={(msgContentForTempSaving) => saveTempMessageContent(msgContentForTempSaving)}
              maxLengthForTitle={maxLengthForTitle}
              postNewMessageState={postNewMessageState}
              snackBarState={snackBarState}
              sendNewMessageToServer={(messagedata) => sendNewMessageToServer(messagedata)}
            />
          </>
      )
    }
}

export default NewMessageWritingView;
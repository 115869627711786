import React, { useState, useEffect }    from 'react';
import { useStateValue }      from "../../State";
import Drawer                 from "@mui/material/Drawer";
import "./usedcaretimesinfodrawer.css";
import NTSummaryLoadSuccessView from "./Components/NTSummaryLoadSuccessView";
import NTSummaryLoadFailed    from "./Components/NTSummaryLoadFailed";
import CircularProgress       from "@mui/material/CircularProgress";
import makeStyles from '@mui/styles/makeStyles';
import InvoiceBasis           from "../../Layouts/InvoiceBasis/InvoiceBasis";
import {isAnyPropUndefinedOrNull} from "../../utils";

const UsedCareTimesInfoDrawer = (props) => {
  
  const {isOpen, timespan, closeDrawer, refreshDate, onFailedLoadRetry} = props;

  const { state, actions } = useStateValue();
 
  const { nursingTimesSummary } = state;

  const { children } = state;

  const [triedOnce, setTriedOnce] = useState(false);

  const [invoiceChildId, setInvoiceChildId] = useState();
  const [invoiceChildName, setInvoiceChildName] = useState();

  const useStyles = makeStyles((theme) => ({
    typography: {
      padding: theme.spacing(2),
    },
    paper: {
        backgroundColor: "#FFFFFF",
    }
  }));
  
  const classes = useStyles();


    useEffect(() => {

    if (state.profilePictures.state === undefined && triedOnce === false) {
      actions.triggerLoadProfilePictures();
      setTriedOnce(true);
    }

  }, [children, actions, state, triedOnce]);

  const openInvoiceBasisView = (childId, childName) => {
    setInvoiceChildId(childId);
    setInvoiceChildName(childName);
    actions.triggerUpdateInvoiceBasisViewState(true);
  }

  if (isAnyPropUndefinedOrNull(props)) {
    return(<div data-testid="ntsummary-undefnull-9083ns78"></div>);
  }

  return(
    <React.Fragment>

    <Drawer 
      classes={{paper: classes.paper}} 
      anchor="right" 
      open={isOpen} 
      onClose={closeDrawer}
    >
      
     

      {(state.nursingTimesSummary.NT.status === "LOADING_NT_SUMMARY" || state.nursingTimesSummary.NT.status === "NEVER_LOADED") &&
        
        <CircularProgress/>
      
      }

      {state.nursingTimesSummary.NT.status === "NT_SUMMARY_LOAD_FAILED" &&

        <NTSummaryLoadFailed 
          failCode={state.nursingTimesSummary.NT.Code}
          refreshDate={refreshDate}
          onFailedLoadRetry={(refreshDate) => onFailedLoadRetry(refreshDate)}
          closeDrawer={closeDrawer}
        />

      }

      {state.nursingTimesSummary.NT.status === "NT_SUMMARY_LOAD_SUCCESS" &&

        <NTSummaryLoadSuccessView 
          timespan={timespan}
          closeDrawer={closeDrawer}
          overBookingWarning={nursingTimesSummary.NT.overbookingWarning}
          childListStats={nursingTimesSummary.NT.ListChildStats}
          openInvoiceBasisView={(childIdAndName) => openInvoiceBasisView(childIdAndName)}
          childList={children.ChildList}
        />
       
      }
    
      { state.header.invoiceViewOpen &&

        <InvoiceBasis 
          childId={invoiceChildId}
          childName={invoiceChildName}
          timespan={timespan}
        />
      
      }

    </Drawer>
  
  </React.Fragment>
  )
}

export default UsedCareTimesInfoDrawer;
import React, { useState, useEffect, useRef } from "react";
import "./mainnav.css";
import { withRouter } from "react-router-dom";
import MainMenuLinks from "./Components/MainMenuLinks";
import LinkToGuide from "./Components/LinkToGuide";
//import Logo from "../../images/DaisyFamily45px.png";
import HamburgerIcon from "@mui/icons-material/Menu";
//import HamburgerIcon from "../../images/more_vert.svg";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import ToolBar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
//import Warning from "@mui/icons-material/Warning";
import IconButton from "@mui/material/IconButton";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Button from "@mui/material/Button";
import Languages from "../../translations";
import { useStateValue } from "../../State";
import Help from "@mui/icons-material/HelpOutline";
//import KasvunkansioIcon       from '../../images/growthfolder.png';
import { LOGOUT_REASONS } from "../../Api";
import { useAuth } from "../../Auth/AuthProvider";
import SessionManager from "../../Auth/SessionManager";

import PropTypes from "prop-types";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Slide from "@mui/material/Slide";
import GuideModalsManager from "../GuideModals/GuideModalsManager";
import useMediaQuery from "@mui/material/useMediaQuery";
import makeStyles from "@mui/styles/makeStyles";
import { styled, useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import DaisyLogo from "../../images/DaisyFamily45px.png";
import Badge from "@mui/material/Badge";
import RouteNavigation from "./Components/RouteNavigation";
import moment from "moment";
import FeedIcon from "@mui/icons-material/Feed";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import ErrorIcon from "@mui/icons-material/Error";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import { types } from "../../Reducers/actionTypes";

const drawerWidth = 280;

const openedDrawerStyle = (theme) => ({
  width: drawerWidth,

  overflow: "hidden",
  zIndex: 1999999,
});

const closedDrawerStyle = (theme) => ({
  overflow: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  zIndex: 1,
});

const StyledDrawer = styled(Drawer)(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  overflow: "hidden",
  zIndex: 9999,
  ...theme.mixins.toolbar,
  ...(open && {
    ...openedDrawerStyle(theme),
    "& .MuiDrawer-paper": openedDrawerStyle(theme),
  }),
  ...(!open && {
    ...closedDrawerStyle(theme),
    "& .MuiDrawer-paper": closedDrawerStyle(theme),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  overflow: "hidden",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const IconStyles = { color: "#ffffff" };

const NotificationToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#FFEBD4",
    borderBottom: "1px solid #EE5711",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFEBD4",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 320,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    verticalAlign: "middle",
    padding: "5px",
    border: "1px solid #EE5711",
  },
}));

const ActionViewButton = ({ onClick, notiCount }) => {
  const [userSetClosed, setUserSetClosed] = React.useState(false);

  const handleNotificationPopoverClose = () => {
    setUserSetClosed(true);

    sessionStorage.setItem("checkNoti", notiCount);
  };

  if (!userSetClosed) {
    window.addEventListener("scroll", function (event) {
      setUserSetClosed(true);
    });
  }

  if (
    notiCount > sessionStorage.getItem("checkNoti") &&
    userSetClosed === false
  )
    return (
      <>
        <NotificationToolTip
          open={!userSetClosed}
          arrow
          onClose={() => handleNotificationPopoverClose()}
          disableHoverListener
          disableFocusListener
          disableTouchListener
          title={
            <Box
              sx={{
                backgroundColor: "#FFEBD4",
                color: "black",
                display: "flex",
                minWidth: "100px",
                verticalAlign: "middle",
              }}
            >
              <ErrorIcon sx={{ color: "#EE5711", margin: "5px" }} />
              &nbsp;
              <Typography sx={{ verticalAlign: "middle", padding: "5px" }}>
                {Languages.newNotifications} ({notiCount})
              </Typography>
              &nbsp;&nbsp;&nbsp;
              <IconButton
                sx={{ verticalAlign: "middle", top: 0, padding: "5px" }}
                onClick={handleNotificationPopoverClose}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          }
        >
          <IconButton
            onClick={() => {
              onClick();
              handleNotificationPopoverClose();
            }}
          >
            <FeedIcon sx={{ color: "#FF6D29" }} />
          </IconButton>
        </NotificationToolTip>
      </>
    );
  else
    return (
      <IconButton onClick={onClick}>
        <FeedIcon sx={{ color: notiCount > 0 ? "#FF6D29" : "white" }} />
      </IconButton>
    );
};

const MainNav = (props) => {
  const mainNavRef = useRef(null);
  const { state, actions } = useStateValue();
  const { children, mainstats, settings, messaging } = state;
  const { history, location, onBackClick, onActionViewButtonClick } = props;
  const messageData = messaging.messageData;
  const theme = useTheme();

  const {
    authActions: { logout },
    user,
  } = useAuth();
  const isDesktop = useMediaQuery("(min-width:1200px)", { noSsr: true });
  const isWideDesktop = useMediaQuery("(min-width:1600px)", { noSsr: true });

  const [growthFolderMenuOpen, setGrowthFolderMenuOpen] = useState(false);
  const [growthFolderSubMenuOpen, setGrowthFolderSubMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpenState, setDrawerOpenState] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState(false);

  useEffect(() => {
    if (isWideDesktop) {
      openMainNavDrawer();
    } else if (!isDesktop) {
      setDrawerOpenState(false);
    }
  }, [isWideDesktop, isDesktop]);

  useEffect(() => {
    if (!anchorEl) {
      setSubMenuOpen(false);
    } else {
      setSubMenuOpen(true);
    }
  }, [anchorEl]);

  const changeDrawerState = () => {
    setDrawerOpenState(!drawerOpenState);
  };

  const openMainNavDrawer = () => {
    setDrawerOpenState(true);
  };

  const changeGrowthFolderMenuOpen = (event) => {
    if (drawerOpenState) {
      setGrowthFolderMenuOpen(!growthFolderMenuOpen);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  useEffect(() => {
    if (!drawerOpenState) {
      setGrowthFolderMenuOpen(false);
    }
  }, [drawerOpenState]);

  const getGrowthfolderEnabledSetting = () => {
    var growthFolderEnabled = settings.communeSettings.find(
      (setting) => setting.Key === "DaisyFamily/ShowGrowthfolder"
    );
    if (
      growthFolderEnabled != null &&
      typeof growthFolderEnabled != undefined &&
      growthFolderEnabled.ValueBool === true
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getDiscussionsEnabledSetting = () => {
    let discussionsEnabled = settings.communeSettings.find(
      (setting) => setting.Key === "DaisyFamily/DiscussionTimesEnabled"
    );
    if (
      discussionsEnabled != null &&
      typeof discussionsEnabled != undefined &&
      discussionsEnabled.ValueBool === true
    ) {
      return true;
    }
    return false;
  };

  const getName = () => {
    if (!location.state) return "...";

    const { childId } = location.state;
    if (childId && children.state === "LOAD_SUCCESS_CHILDREN") {
      const child = children.ChildList.find((c) => c.Id === childId);
      return child ? child.FirstName : "...";
    }
    return "...";
  };

  const GetNewCalendarEnabledSetting = () => {
    let newCalendarEnabled = settings.communeSettings.find(
      (setting) => setting.Key === "DaisyFamily/NewCaretimeCalendarEnabled"
    );
    if (
      newCalendarEnabled != null &&
      typeof newCalendarEnabled != undefined &&
      newCalendarEnabled.ValueBool === true
    ) {
      return true;
    }
    return false;
  };

  const GetOldCalendarEnabledSetting = () => {
    let oldCalendarEnabled = settings.communeSettings.find(
      (setting) => setting.Key === "DaisyFamily/OldCaretimeCalendarEnabled"
    );
    if (
      oldCalendarEnabled != null &&
      typeof oldCalendarEnabled != undefined &&
      oldCalendarEnabled.ValueBool === true
    ) {
      return true;
    }
    return false;
  };

  const GetUseOptimizedMessagingSetting = () => {
    let optimizedMessaging = settings.communeSettings.find(
      (setting) => setting.Key === "Daisyfamily/UseOptimizedMessaging"
    );
    if (
      optimizedMessaging != null &&
      typeof optimizedMessaging != undefined &&
      optimizedMessaging.ValueBool === true
    ) {
      return true;
    }
    return false;
  };

  const GetBothCalendarSettingsNull = () => {
    let oldCalendarEnabled = settings.communeSettings.find(
      (setting) => setting.Key === "DaisyFamily/OldCaretimeCalendarEnabled"
    );
    let newCalendarEnabled = settings.communeSettings.find(
      (setting) => setting.Key === "DaisyFamily/NewCaretimeCalendarEnabled"
    );
    if (oldCalendarEnabled != null && newCalendarEnabled != null) {
      return false;
    }
    return true;
  };
  const mapLocation = (pathname) =>
    ({
      "/caretimebooking": Languages.bookingCareHoursTitle,
      "/caretimecalendar": Languages.bookingCareHoursTitle2,
      "/placementinformation": Languages.placementInformation,
      "/permissionqueries": Languages.permPermissionQueries,
      "/growthfolder": Languages.pdGrowthFolderTitle,
      "/nursingtimes": Languages.bookingCareHoursTitle,
      "/childList": Languages.menuChildren,
      "/notifyabsent": Languages.menuNotifyAbsence,
      "/messaging/newmessage": Languages.msgMessageNew,
      "/messaging": Languages.menuMessaging,
      "/growthfolder/media":
        state.growthFolder.image && state.growthFolder.image[0].Header
          ? state.growthFolder.image[0].Header
          : state.growthFolder.image &&
            state.growthFolder.image[0].Header == null
          ? state.growthFolder.image[0].FileName
          : "...",
      "/growthfolder/imagefolder":
        location.state && location.state.folder
          ? location.state.folder.Name
          : "...",
      "/growthfolder/taskfolder":
        location.state && location.state.folder
          ? location.state.folder.Name
          : "..." /*Languages.taskfolder,*/,
      "/permissions": Languages.permissionQuestionsGen,
      "/childInfo": getName(),
      "/settings": Languages.settings,
      "/bulletinboard": Languages.bulletinboard,
      "/settings": Languages.settings,
      "/bulletinboard": Languages.bulletinboard,
      "/settings": Languages.menuSettings,
      "/leops/": "Leops",
      "/leopsPreparingPart": Languages.eduPlanTitle1,
      "/leopsDiscussionPart": Languages.eduPlanTitle2,
      "/leopsSupportPart": Languages.eduPlanTitle3,
      "/leopsSpringEvaluationPart": Languages.eduPlanTitle4,
      "/descriptionPart": Languages.eduPlanChildDescription,
      "/vasu/": Languages.vasu,
      "/vasuPreparingPart": Languages.eduPlanTitle1,
      "/vasuDiscussionPart": Languages.eduPlanTitle2,
      "/DescriptionPart": Languages.eduPlanChildDescription,
      "/vasuSupportPart": Languages.eduPlanTitle2,
      "/bulletinboard": Languages.bbTitle,
      "/messaging/openmessage":
        location.state && location.state.messageThread
          ? location.state.messageThread.Title
          : state.header.title
          ? state.header.title
          : "...",
      "/discussionBooking": Languages.discDiscussionBooking,
      "/": Languages.menuChildren,
    }[pathname]);

  const backButtonClick = () => {
    if (onBackClick) {
      console.log("Calling override back button handler");
      onBackClick();
      return;
    }
    history.goBack();
  };

  const headerTitle = mapLocation(
    location.pathname.substring(0, 6) === "/vasu/"
      ? "/vasu/"
      : location.pathname.substring(0, 7) === "/leops/"
      ? "/leops/"
      : location.pathname
  );
  const showMessagingGuide =
    mapLocation(location.pathname) === Languages.menuMessaging ? true : false;
  const showPermissionGuide =
    mapLocation(location.pathname) === Languages.permissionQuestionsGen
      ? true
      : false;
  const showDiscussionGuide =
    mapLocation(location.pathname) === Languages.discDiscussionBooking
      ? true
      : false;
  const showCareTimeBookingGuide =
    mapLocation(location.pathname) === Languages.bookingCareHoursTitle
      ? true
      : false;

  const showCareTimeBookingGuideNew =
    mapLocation(location.pathname) === Languages.bookingCareHoursTitle2
      ? true
      : false;

  const showChildListgGuide =
    mapLocation(location.pathname) === Languages.menuChildren ? true : false;
  const showChildInfoGuide =
    mapLocation(location.pathname) === getName() ? true : false;

  //const showLeftArrow = history.length > 0;
  //console.log("MainNav. headerTitle: ",headerTitle, " ja pituus: ",headerTitle.length);//, " location.pathname: ",location.pathname );

  const openGrowthFolder = (Id) => {
    setAnchorEl(null);
    props.history.push("/growthfolder", {
      Id: Id,
    });
  };

  return (
    <React.Fragment key="deskTopFragment">
      {isDesktop && (
        <>
          <AppBar
            position="fixed"
            sx={{ backgroundColor: "#4377BA", zIndex: theme.zIndex.drawer - 1 }}
          >
            <ToolBar>
              <StyledDrawer
                variant="permanent"
                anchor="left"
                open={drawerOpenState}
                onClose={changeDrawerState}
                sx={{ display: "contents" }}
              >
                <DrawerHeader>
                  {drawerOpenState && (
                    <img
                      style={{ height: "30px", width: "auto" }}
                      src={DaisyLogo}
                    />
                  )}

                  <IconButton
                    id="menuButtonDesktop"
                    aria-label="Menu"
                    onClick={changeDrawerState}
                    size="large"
                  >
                    {drawerOpenState ? (
                      <ArrowBack sx={IconStyles} />
                    ) : (
                      <HamburgerIcon sx={IconStyles} />
                    )}
                  </IconButton>
                </DrawerHeader>

                <MainMenuLinks
                  anchorEl={anchorEl}
                  setAnchorEl={setAnchorEl}
                  subMenuOpen={subMenuOpen}
                  growthFolderMenuOpen={growthFolderMenuOpen}
                  changeGrowthFolderMenuOpen={changeGrowthFolderMenuOpen}
                  drawerOpen={drawerOpenState}
                  getGrowthfolderEnabledSetting={getGrowthfolderEnabledSetting}
                  openGrowthFolder={openGrowthFolder}
                  discussionsEnabled={getDiscussionsEnabledSetting}
                  newCalendarEnabled={GetNewCalendarEnabledSetting}
                  oldCalendarEnabled={GetOldCalendarEnabledSetting}
                  optimizedMessaging={GetUseOptimizedMessagingSetting}
                  bothcalendarSettingsNUll={GetBothCalendarSettingsNull}
                  childList={children.ChildList}
                  userName={user}
                  communeName={SessionManager.getCommune()}
                  mainstats={mainstats}
                  messageData={messageData}
                  pathName={location.pathname}
                  logout={() => logout(LOGOUT_REASONS.normal)}
                />
              </StyledDrawer>

              <div style={{ width: "100%", margin: "auto" }}>
                <Grid key="keeyy" container spacing={2}>
                  <Grid item xs={2}></Grid>

                  <Grid item xs={8} className="routeNavDiv">
                    <RouteNavigation
                      children={children}
                      location={location}
                      mainstats={mainstats}
                      history={history}
                      onBackClick={onBackClick}
                      getName={getName}
                      openGrowthFolder={openGrowthFolder}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={2}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    {
                      showMessagingGuide && (
                        <GuideModalsManager
                          modalType="MESSAGING_GUIDE"
                          modalTitle="Viestinnän ohje"
                        />
                      )
                      /*<LinkToGuide
                        linkAddress={"https://ohjeet.daisymanager.fi/DaisyFamily/DaisyFamilyViestitys.pdf"}
                        idForLink="test_DF_Messaging_Guide_Lnk"
                      />*/
                    }

                    {
                      showCareTimeBookingGuide && (
                        <GuideModalsManager
                          modalType="BOOKING_GUIDE"
                          modalTitle="Uusi Hoitoajan varauskalanteri"
                        />
                      )

                      /*{                      <LinkToGuide
                        linkAddress={"https://ohjeet.daisymanager.fi/DaisyFamily/DaisyFamily%20Hoitoaikavarauskalenteri.pdf"}
                        idForLink="test_DF_Booking_Care_Hours_Guide_Lnk"
                      />
                    }
                  */
                    }

                    {
                      showCareTimeBookingGuideNew && (
                        <GuideModalsManager
                          modalType="CARETIME_GUIDE"
                          modalTitle="Uusi Hoitoajan varauskalanteri"
                        />
                      )

                      /*{                      <LinkToGuide
                        linkAddress={"https://ohjeet.daisymanager.fi/DaisyFamily/DaisyFamily%20Hoitoaikavarauskalenteri.pdf"}
                        idForLink="test_DF_Booking_Care_Hours_Guide_Lnk"
                      />
                    }
                  */
                    }

                    {showPermissionGuide && (
                      <GuideModalsManager
                        modalType="PERMISSION_GUIDE"
                        modalTitle="Lupa ohjeet"
                      />
                    )}

                    {/*showDiscussionGuide &&
                    
                      <GuideModalsManager modalType="DISCUSSION_GUIDE" modalTitle="Keskusteluajat testi ohje"/>
                    
                  */}

                    {false && showChildListgGuide && (
                      <LinkToGuide
                        linkAddress={
                          "https://ohjeet.daisymanager.fi/DaisyFamily/DaisyFamily%20Lapsilista.pdf"
                        }
                        idForLink="test_DF_ChildList_Lnk"
                      />
                    )}

                    {false && showChildInfoGuide && (
                      <LinkToGuide
                        linkAddress={
                          "https://ohjeet.daisymanager.fi/DaisyFamily/DaisyFamily%20Lapsentiedot.pdf"
                        }
                        idForLink="test_DF_ChildInfo_Lnk"
                      />
                    )}

                    <NotificationChecker onClick={onActionViewButtonClick} />

                    {/* <ActionViewButton onClick={onActionViewButtonClick} />*/}
                  </Grid>
                </Grid>
              </div>
            </ToolBar>
          </AppBar>
        </>
      )}
      {/* <HideOnScroll {...props}> */}
      {!isDesktop && (
        <AppBar
          position="fixed"
          style={{
            backgroundColor:
              window.location.pathname === "/caretimebooking" ||
              window.location.pathname === "/caretimecalendar"
                ? "#002e6d"
                : "#4377BA",
            zIndex: theme.zIndex.drawer + 2,
          }}
        >
          <ToolBar style={{ paddingRight: "0px", paddingLeft: "0px" }}>
            <Drawer
              anchor="left"
              open={drawerOpenState}
              onClose={changeDrawerState}
            >
              <DrawerHeader>
                {drawerOpenState && <img src={DaisyLogo} />}

                <IconButton onClick={changeDrawerState} size="large">
                  {drawerOpenState ? (
                    <ArrowBack sx={IconStyles} />
                  ) : (
                    <HamburgerIcon sx={IconStyles} />
                  )}
                </IconButton>
              </DrawerHeader>
              <MainMenuLinks
                subMenuOpen={subMenuOpen}
                growthFolderMenuOpen={growthFolderMenuOpen}
                changeGrowthFolderMenuOpen={changeGrowthFolderMenuOpen}
                drawerOpen={drawerOpenState}
                openMainNavDrawer={openMainNavDrawer}
                getGrowthfolderEnabledSetting={getGrowthfolderEnabledSetting}
                openGrowthFolder={openGrowthFolder}
                discussionsEnabled={getDiscussionsEnabledSetting}
                newCalendarEnabled={GetNewCalendarEnabledSetting}
                oldCalendarEnabled={GetOldCalendarEnabledSetting}
                optimizedMessaging={GetUseOptimizedMessagingSetting}
                childList={children.ChildList}
                userName={user}
                bothcalendarSettingsNUll={GetBothCalendarSettingsNull}
                communeName={SessionManager.getCommune()}
                mainstats={mainstats}
                messageData={messageData}
                pathName={location.pathname}
                logout={() => logout(LOGOUT_REASONS.normal)}
              />
            </Drawer>

            <div style={{ width: "100%", margin: "auto" }}>
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    paddingLeft: "-16px",
                  }}
                >
                  <IconButton
                    id="test_Menu_Main"
                    aria-label="Päävalikko Main Menu Huvudmeny"
                    onClick={changeDrawerState}
                    size="large"
                  >
                    <Badge
                      badgeContent={
                        mainstats.TotalUnansweredPermissions != null &&
                        state.messaging.messageData.notReadThreads != null
                          ? state.messaging.messageData.notReadThreads +
                            mainstats.TotalUnansweredPermissions
                          : state.messaging.messageData.notReadThreads
                      }
                      color="error"
                    >
                      <HamburgerIcon style={{ color: "#ffffff" }} />
                    </Badge>
                  </IconButton>
                </Grid>

                {/*history.length < 2 ? null : 
                    <Grid item xs={2} sx={{display: "flex", alignItems: 'center', justifyContent: 'flex-start', paddingLeft: "-16px" }}>
                      <IconButton style={{display: "inline-block"}} size="medium" aria-label="Back to previous" onClick={backButtonClick}>
                        <Typography color="textPrimary" ><ArrowBack /></Typography>
                      </IconButton>
                    </Grid>
                  */}

                <Grid
                  item
                  /*xs={history.length < 2 ? 7 : 6}*/ xs={7}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  {history.length < 2 ? null : (
                    <IconButton
                      sx={{ display: "inline-block", marginRight: "-15px" }}
                      size="medium"
                      aria-label="Back to previous"
                      onClick={backButtonClick}
                    >
                      <Typography color="textPrimary">
                        <ArrowBack />
                      </Typography>
                    </IconButton>
                  )}
                  {typeof headerTitle !== "undefined" &&
                    headerTitle.length < 17 && (
                      <div
                        style={{
                          display: "inline-block",
                          fontSize: "0.8rem",
                          wordWrap: "break-word",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "7px",
                        }}
                      >
                        <Typography id="test_Main_Title" component="h6">
                          &nbsp; &nbsp; {headerTitle}
                        </Typography>
                      </div>
                    )}

                  {typeof headerTitle !== "undefined" &&
                    headerTitle.length >= 17 &&
                    headerTitle.length < 30 && (
                      <div
                        style={{
                          display: "inline-block",
                          wordWrap: "break-word",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "7px",
                        }}
                      >
                        <Typography
                          id="test_Main_Title"
                          component="h6"
                          noWrap={false}
                        >
                          {headerTitle.length > 50
                            ? headerTitle.substring(0, 51) + "..."
                            : headerTitle}{" "}
                        </Typography>
                      </div>
                    )}

                  {typeof headerTitle !== "undefined" &&
                    headerTitle.length > 30 && (
                      <div
                        style={{
                          display: "inline-block",
                          wordWrap: "break-word",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "7px",
                        }}
                      >
                        <Typography
                          id="test_Main_Title"
                          component="h6"
                          variant="subtitle2"
                          noWrap={false}
                        >
                          {headerTitle.length > 50
                            ? headerTitle.substring(0, 51) + "..."
                            : headerTitle}{" "}
                        </Typography>
                      </div>
                    )}
                </Grid>

                <Grid
                  item
                  xs={3}
                  sx={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    textAlign: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  {showMessagingGuide && (
                    <GuideModalsManager
                      modalType="MESSAGING_GUIDE"
                      modalTitle="Viestinnän ohje"
                    />
                  )}

                  {showCareTimeBookingGuide && (
                    <GuideModalsManager modalType="BOOKING_GUIDE" />
                  )}

                  {showCareTimeBookingGuideNew && (
                    <GuideModalsManager modalType="CARETIME_GUIDE" />
                  )}

                  {showPermissionGuide && (
                    <GuideModalsManager
                      modalType="PERMISSION_GUIDE"
                      modaltTitle="Lupa ohjeet"
                    />
                  )}

                  {/*showDiscussionGuide &&
                    <GuideModalsManager modalType="DISCUSSION_GUIDE" modalTitle="Keskusteluajat testi ohje" />

                */}

                  {false && showChildListgGuide && (
                    <LinkToGuide
                      linkAddress={
                        "https://ohjeet.daisymanager.fi/DaisyFamily/DaisyFamily%20Lapsilista.pdf"
                      }
                      idForLink="test_DF_ChildList_Lnk"
                    />
                  )}

                  {false && showChildInfoGuide && (
                    <LinkToGuide
                      linkAddress={
                        "https://ohjeet.daisymanager.fi/DaisyFamily/DaisyFamily%20Lapsentiedot.pdf"
                      }
                      idForLink="test_DF_ChildInfo_Lnk"
                    />
                  )}

                  <NotificationChecker onClick={onActionViewButtonClick} />
                  {/*<ActionViewButton onClick={onActionViewButtonClick} />*/}
                </Grid>
              </Grid>
            </div>
          </ToolBar>
        </AppBar>
      )}
    </React.Fragment>
  );
};

export default withRouter(MainNav);

export const NotificationChecker = ({ onClick }) => {
  const { actions, state } = useStateValue();
  const { mainstats, bulletinBoards, settings, smallRequests } = state;
  const [notiCount, setNotiCount] = React.useState(0);
  const [bulletins, setBulletins] = React.useState("false");

  useEffect(() => {
    notiCounter();
  }, [
    mainstats.state,
    bulletinBoards,
    smallRequests.vacationNotificationStatus.status,
    settings.state,
    notiCount,
  ]);

  function notiCounter() {
    if (
      mainstats.state !== "LOAD_SUCCESS_MAINSTATS" &&
      mainstats.state !== types.LOADING_MAINSTATS
    ) {
      actions.triggerLoadMainStats();
    }
    if (
      bulletinBoards.status !== "LOADED" &&
      bulletinBoards.status !== types.LOADING_BULLETINBOARDS
    ) {
      actions.triggerLoadBulletinBoards();
    }
    if (
      mainstats.state === "LOAD_SUCCESS_MAINSTATS" &&
      bulletinBoards.status === "LOADED" &&
      bulletinBoards !== null &&
      typeof bulletinBoards !== undefined &&
      smallRequests.vacationNotificationStatus.status ===
        "LOAD_VACATION_STATUS_SUCCESS" &&
      settings.communeSettings.length >= 0
    ) {
      let newPermissions = 0;
      let newMessages = 0;
      let newCareTimes = 0;
      let newVacationCareTimes = 0;
      let clientNotification = 0;
      let developmentNotification = 0;
      let newBulletins = 0;
      let totalCount = 0;

      newPermissions = mainstats.TotalUnansweredPermissions;
      newMessages = state.messaging.messageData.notReadThreads;

      if (
        mainstats.nursingTimesStatusAllDone !== null &&
        typeof mainstats.nursingTimesStatusAllDone !== undefined &&
        mainstats.nursingTimesStatusAllDone === false
      ) {
        newCareTimes = mainstats.listChildNursingTimeStatus.length;
      }

      if (
        bulletinBoards.notes !== null &&
        typeof bulletinBoards.notes !== undefined &&
        bulletinBoards.notes.length > 0
      ) {
        newBulletins = bulletinBoards.notes.length;
      }

      if (
        smallRequests.vacationNotificationStatus.ShowNotification !== null &&
        typeof smallRequests.vacationNotificationStatus.ShowNotification !==
          undefined &&
        smallRequests.vacationNotificationStatus.ShowNotification === true
      ) {
        newVacationCareTimes = 1;
      }

      let startTimeClientSetting = settings.communeSettings.find(
        (setting) => setting.Key === "DaisyFamily/ClientNotification/StartTime"
      );
      let endTimeClientSetting = settings.communeSettings.find(
        (setting) => setting.Key === "DaisyFamily/ClientNotification/EndTime"
      );
      let startTimeDevelopmentSetting = settings.communeSettings.find(
        (setting) => setting.Key === "DaisyFamily/ClientNotification/StartTime"
      );
      let endTimeDevelopmentSetting = settings.communeSettings.find(
        (setting) => setting.Key === "DaisyFamily/ClientNotification/EndTime"
      );

      const dateToday = moment().toDate();

      if (
        startTimeClientSetting !== null &&
        typeof startTimeClientSetting !== undefined &&
        endTimeClientSetting !== null &&
        typeof endTimeClientSetting !== undefined
      ) {
        let formatClientStartTime = moment(
          startTimeClientSetting.ValueString,
          "YYYY-MM-DD"
        ).toDate();
        let formatClientEndTime = moment(
          endTimeClientSetting.ValueString,
          "YYYY-MM-DD"
        ).toDate();
        const validClientNotification = moment(dateToday).isBetween(
          formatClientStartTime,
          formatClientEndTime
        );
        if (validClientNotification) {
          clientNotification = 1;
        }
      }

      if (
        startTimeDevelopmentSetting !== null &&
        typeof startTimeDevelopmentSetting !== undefined &&
        endTimeDevelopmentSetting !== null &&
        typeof endTimeDevelopmentSetting !== undefined
      ) {
        let formatDevelopmentStartTime = moment(
          startTimeDevelopmentSetting.ValueString,
          "YYYY-MM-DD"
        ).toDate();
        let formatDevelopmentEndTime = moment(
          endTimeDevelopmentSetting.ValueString,
          "YYYY-MM-DD"
        ).toDate();
        const validDevelopmentNotification = moment(dateToday).isBetween(
          formatDevelopmentStartTime,
          formatDevelopmentEndTime
        );
        if (validDevelopmentNotification) {
          developmentNotification = 1;
        }
      }
      // bulletinboard removed from notifications
      //totalCount = newMessages + newPermissions + newCareTimes + ***newBulletins***  + clientNotification + developmentNotification + newVacationCareTimes
      totalCount =
        newMessages +
        newPermissions +
        newCareTimes +
        clientNotification +
        developmentNotification +
        newVacationCareTimes;
      setNotiCount(totalCount);
      mainstats.actionViewNotis = totalCount;

      if (developmentNotification + clientNotification > 0) setBulletins(true);
    }
  }

  if (
    notiCount >= 0 &&
    notiCount !== null &&
    typeof notiCount !== undefined &&
    mainstats.state === "LOAD_SUCCESS_MAINSTATS" &&
    bulletinBoards.status === "LOADED"
  ) {
    return (
      <ActionViewButton
        onClick={onClick}
        notiCount={notiCount}
        bulletins={bulletins}
        mainstats={mainstats}
      />
    );
  } else return <CircularProgress size={30} />;
};

import { ApiBase } from "../internal";

export class DailyNotesApi extends ApiBase {
  /*constructor() {
    super();
  }*/

  async LoadDailyNotes(yearmonth) {

  
    try {
      const response = await this.httpClient.get("/HandlerNotes/gethandlernotes?" + yearmonth,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": sessionStorage.getItem('language') === "en" ? "en-US" : sessionStorage.getItem('language')
          }
        });

      return response;
    }
    catch (error) {
      console.error("DAILY NOTES API. Load DAILY NOTES ERROR: ", error, "\n" +
        "response.status: ", error.response === undefined ? 502 : error.response.status === undefined ? 502 : error.response.status);
      var responseData = { status: error.response === undefined || error.response.status === undefined ? 502 : error.response.status };
      return responseData;
    }
  }


}
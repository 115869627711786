import React, { Component } from "react";
import SwipeableViews       from 'react-swipeable-views';
//import { autoPlay }       from 'react-swipeable-views-utils';

import "./dailyreservations.css";

import SlideConfirmationNote  from "../../../../Components/SlideConfirmationNote";

import Moment           from 'moment';
import { StateContext/*, useStateValue*/ } from "../../../../State";
import Languages        from "../../../../translations";
import Warning          from "@mui/icons-material/Warning";
import DeleteIcon       from "@mui/icons-material/Delete";
import MobileStepper    from '@mui/material/MobileStepper';
import Button           from '@mui/material/Button';
import IconButton         from '@mui/material/IconButton';
import KeyboardArrowLeft  from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Dialog           from '@mui/material/Dialog';
import DialogContent    from '@mui/material/DialogContent';
import DialogTitle      from '@mui/material/DialogTitle';
import Typography         from "@mui/material/Typography";
import CancelIcon         from "@mui/icons-material/Cancel";
import ChildProfilePicture from "../../../../Components/ChildProfilePicture";
import Avatar from "@mui/material/Avatar";

//const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

class DailyReservations extends Component {
  
  static contextType = StateContext;
  
  constructor(props) {
    super(props);
    this.state = { isOpen: false,
                   Mark: [],
                   ChildIds: [],
                   otherValidChilds: [],
                   showSlideNote: false,
                   SlideNoteTitle: null,
                   NoteText: null,
                   SlideNoteLeftButton: null,
                   SlideNoteRightButton: null,
                   SlideNoteMiddleButton: null,
                   Mode: null,
                   dailyMarkings: [],
                   dailyMarkingsCount: []};
    this.strings = Languages;
    this.handleChangeIndex = this.handleChangeIndex.bind(this);
  }

  componentDidMount() {
    const { children } = this.context.state;
    const { WeekData } = this.context.state.nursingtimes.List;
    var week = WeekData.WeekList.find(w => w.WeekNumber === this.props.week);
    var day = week.DayList.find(d => d.Date === this.props.date);

    console.log("DailyReservations. Mount. "+
                "\n Day data: ", day,
                "\n day.NursingTimes: ", day.NursingTimes,
                "\n day.Statuses: ", day.Statuses,
                "\n children: ", children);

    var ChildIds = [];
    day.NursingTimes.forEach(nt => {
      var child = ChildIds.find(c => c.Id === nt.PersonId);
      if (child === undefined)
      {
        ChildIds.push({Id: nt.PersonId, Name: nt.FirstName, Color: nt.BgColor});
      }
    });
    day.Statuses.forEach(st => {
      var child = ChildIds.find(c => c.Id === st.PersonId);
      if (child === undefined)
      {
        ChildIds.push({Id: st.PersonId, Name: st.FirstName, Color: st.BgColor});
      }
    });

    var  MarkingsList = [];
    var  MarkingsCount = [];
    // eslint-disable-next-line
    ChildIds.forEach(child => {

      let count = 0;
      let personId = null;

      day.Statuses.filter(s => {
        return s.PersonId === child.Id
      }).forEach((s, sindex) => {
        MarkingsList.push({ Id: s.Id,
                            Type: "STATUS",
                            PersonId: s.PersonId,
                            Name: s.FirstName,
                            Title: s.Type === "I" ? s.Name + ": " + Moment(s.Start).format("HH:mm") :
                                   s.Type === "O" ? s.Name + ": " + Moment(s.End).format("HH:mm") :
                                   s.Name,
                            Placement: s.Centre + (s.Group === null ? "" : " - " + s.Group),
                            Warning: false,
                            //Lukituspäivältä saakin poistaa, jos muut ehdot sallii poistamisen
                            Selectable: day.NotSelectable ? false : true});
        count++;
        personId = s.PersonId;
      });

      day.NursingTimes.filter(c => {
        return c.PersonId === child.Id
      }).forEach(n => {
        MarkingsList.push({ Id: n.Id, 
                            Type: "NT",
                            PersonId: n.PersonId,
                            Name: n.FirstName,
                            Title: Moment(n.Start).format("HH:mm") + "-" + Moment(n.End).format("HH:mm"),
                            Placement: n.Centre + (n.Group === null ? "" : " - " + n.Group),
                            Warning: false, 
                            //Lukituspäivältä saakin poistaa, jos muut ehdot sallii poistamisen
                            Selectable: day.NotSelectable ? false : true });
        count++;
        personId = n.PersonId;
      });

      MarkingsCount.push({ PersonId: personId,
                           Count: count,
                           Index: 0});
    });

    
    var ChildsNotFound = day.NotSelectable ? [] : children.ChildList.filter(ch => 
    {
      var notfound = false;
      var child = ChildIds.find(c => c.Id === ch.Id);
      //console.log("DailyReservations. Mount. Search child ", ch.Id, " from nt/st list: ", child === undefined ? "Not found" : "founded");
      if (child === undefined)
      {
        notfound = true;
      }
      if (notfound) return (ch);
      else          return null;
    });
    /*console.log("DailyReservations. Mount."+
                "\n ChildIds: ", ChildIds,
                "\n Other childs: ",ChildsNotFound  );*/

    var otherChilds = [];
    ChildsNotFound.forEach(child => {
      //console.log("Child without reservations: ", child.Name);
        
      var validPlacements = child.Placements.filter(pl => 
        {
          return (pl.Start <= this.props.date && 
                   (pl.End === null || pl.End >= this.props.date ));
        }
      );
      if(validPlacements.length > 0 )
      {
        //console.log("Valid placements found: ",validPlacements);
        //console.log("child.Checked: ",child.Checked);
        //set checked again be default, if unchecked earlier
        child.Checked = true;
        otherChilds.push(child);
      }
    });

    if (day.ShowEuroPersonIds !== null )
    {
      day.ShowEuroPersonIds.forEach(e => {
        MarkingsList.forEach( m => {
          if (m.PersonId === e)
          { m.Warning = true; }
        }); 
      });
    }
    //window.scrollTo(0, this.refs.pagetop);

    this.setState({ ChildIds: ChildIds.sort((a, b) => a.Name.localeCompare(b.Name)),
                    otherValidChilds: day.IsLockedDate ? [] : otherChilds,
                    dailyMarkings: MarkingsList,
                    DailyMarkingsCount: MarkingsCount,
                    isOpen: true
    });
  }
  
  componentDidUpdate(event) {
    //console.log("DailyReservations. Update");
  }

  componentWillUnmount() {
    //console.log("DailyReservations. Unmount.");
  }

  close = () => {
    //const { WeekData } = this.context.state.nursingtimes.List;
    //var week = WeekData.WeekList.find(w => w.WeekNumber === this.props.week);
    //var day = week.DayList.find(d => d.Date === this.props.date);

    //console.log("DailyReservations. Close. day data:", day);
    this.props.callback("CLOSEDAILYRESERVATION");

    this.setState({ isOpen: false });
  };

  makeReservations = () => {
    let childIds = [];
    this.state.otherValidChilds.forEach( c => {
      childIds.push(c.Id);
    }); 
    //console.log("DailyReservations. makeReservations. Other valid childs: ",childIds);
    
    this.props.callback("NEWDAILYRESERVATION", childIds);
    this.setState({ isOpen: false });
  };

  confirmMode = (Id, PersonId, Type, Mode) => {
    
    let personstatuses = this.state.dailyMarkings.filter(m => m.Type === "STATUS" && m.PersonId === PersonId);
    /*console.log("DailyReservations. ConfirmMode"+
                "\n Type:"+Type+
                "\n Mode: "+ Mode+
                "\n daily Statuses: "+ personstatuses.length );*/

    let Data = {Mode: Mode, Id: Id, PersonId: PersonId, Type: Type, DeleteAbsentFromAllPlacements: false};
    
    this.setState({ Mode:                 Mode,
                    showSlideNote:        true,
                    SlideNoteTitle:       Mode==="EDIT" ? "Merkintojen editointi" : Languages.bookingDetailDelBookingTitle,
                    NoteText:             Mode==="EDIT" ? "Editoidaan merkintää"  : 
                                          Type === "NT" || personstatuses.length < 2 ? 
                                          Languages.bookingDetailDelBookingInfo : 
                                          Languages.bookingDetalDelBookingInfoMultiple,
                    SlideNoteLeftButton:  Languages.bookingDeleteBookingLeftBtn, // "PERUUTA"
                    SlideNoteRightButton: Mode==="EDIT" ? "MUUTA" : Languages.bookingDeleteBookingRightBtn, // "POISTA"
                    SlideNoteMiddleButton: Mode === "EDIT" || Type === "NT" || personstatuses.length < 2 ? null : Languages.bookingDeleteBookingAll, // SlideNoteMiddleButton
                    Mark:                 Data });
  }
  
  handleSlideConfirmationNoteCB = (status) => {
   // console.log("DaisyReservations. handleSlideConfirmationNoteCB. status: ", status);
    if (status ==="LEFT" || status === "CLOSE" )
    {
      this.setState({ showSlideNote: false });  
    }
    else
    {
      let Data = this.state.Mark;
      Data.DeleteAbsentFromAllPlacements = status === "MIDDLE" ? true : false;
      this.props.callback(this.state.Mode === "EDIT" ? "EDIT" : "DELETERESERVATION", Data);
    }
  };
  
  handleChangeIndex = (e, index, ChildId) => {
    //const marks = this.state.dailyMarkings.filter(m => m.PersonId === ChildId);
    this.state.DailyMarkingsCount.filter(m => m.PersonId === ChildId)[0].Index = index;

    /*console.log("DailyReservations. HandleChangeIndex."+
                "\n uusi slide: ",e,
                "\n edellinen : ",index,
                "\n oldslideindex: ",old,
                "\n 'SlideIndex': ", this.state.DailyMarkingsCount.filter(m => m.PersonId === ChildId)[0].Index ,
                "\n Merkintöjä: ", marks.length);*/

    if (e > index){
      //console.log("DailyReservations. HandleChangeIndex. FW");
      let count = this.state.DailyMarkingsCount.filter(m => m.PersonId === ChildId);
      count[0].Index = count[0].Index === count[0].Count - 1 ? count[0].Index : count[0].Index +1;
    }
    else
    {
      //console.log("DailyReservations. HandleChangeIndex. BW");
      this.state.DailyMarkingsCount.filter(m => m.PersonId === ChildId)[0].Index = 
      this.state.DailyMarkingsCount.filter(m => m.PersonId === ChildId)[0].Index === 0 ?
      0 :
      this.state.DailyMarkingsCount.filter(m => m.PersonId === ChildId)[0].Index-1;
    }
    this.setState({ DailyMarkingsCount: this.state.DailyMarkingsCount });
          
    // e == current slide 0->n
    // index == previous slide e-1
  };

  handlePrev = (ChildId) =>
  {
    //const marks = this.state.dailyMarkings.filter(m => m.PersonId === ChildId);

    this.state.DailyMarkingsCount.filter(m => m.PersonId === ChildId)[0].Index = 
      this.state.DailyMarkingsCount.filter(m => m.PersonId === ChildId)[0].Index === 0 ?
      0 :
      this.state.DailyMarkingsCount.filter(m => m.PersonId === ChildId)[0].Index-1;

      /*console.log("DailyReservations. handlePrev."+
                  "\n 'SlideIndex': ", this.state.DailyMarkingsCount.filter(m => m.PersonId === ChildId)[0].Index);/*,
                  "\n Child: " + marks[0].Name + 
                  "\n Merkintöjä: ", this.state.DailyMarkingsCount.filter(m => m.PersonId === ChildId));*/
  
      this.setState({ DailyMarkingsCount: this.state.DailyMarkingsCount });
  }

  handleNext = (ChildId) =>
  {
    //const marks = this.state.dailyMarkings.filter(m => m.PersonId === ChildId);
    let count = this.state.DailyMarkingsCount.filter(m => m.PersonId === ChildId);
    count[0].Index = count[0].Index === count[0].Count - 1 ? count[0].Index : count[0].Index +1;
    
    /*console.log("DailyReservations. handleNext."+
    "\n 'SlideIndex': ", this.state.DailyMarkingsCount.filter(m => m.PersonId === ChildId)[0].Index);*/

    this.setState({ DailyMarkingsCount: this.state.DailyMarkingsCount });
  }

  render() {

    if (this.state.ChildIds.length === 0)
    {
      //console.log("DailyReservations. WAIT TILL CHILDS counted");
      return null;
    }
    else
    {
    return (
      <div /*className="DailyReservations"*/ ref="pagetop">

        <Dialog open={this.state.isOpen}
                onClose={this.close}
                scroll={"paper"} >
          <DialogContent>
            <DialogTitle>
              <div>
                <div id={"test_DailyView_Date"}>
                  {this.props.date.format( "DD.MM." ) + " " + this.props.date.format( " dddd" )}
                </div>
                <div  className="DailyReservations-head-close closeicon" id="test_DailyView_Close">
                    <IconButton id="test_DailyRes_CloseIcon" style={{display: "inline-block"}} size="small" 
                                aria-label="Close dailyview" onClick={this.close} >
                      <CancelIcon />
                    </IconButton>
                </div>
              </div>
            </DialogTitle>
          <div>
            {this.state.ChildIds.map((Child, index) => {
              var marks = this.state.dailyMarkings.filter(mark => { return mark.PersonId === Child.Id; });
              
              let steps = this.state.DailyMarkingsCount.filter(m => m.PersonId === Child.Id)[0].Count;
              let slideindex = this.state.DailyMarkingsCount.filter(m => m.PersonId === Child.Id)[0].Index;
              //console.log("RENDER. slideindex: ", slideindex);
              return (
                <div  id={"test_DailyView"+index} 
                      attr_markings={steps}
                      key = {Child.Id} 
                      className="row" 
                      style={{minHeight:"40px", margin:"0.2rem 0"}}> 
                  <div className="col-3" style={{backgroundColor: Child.Color}}><Typography color="textSecondary">{Child.Name}</Typography><ChildProfilePicture childId={Child.Id} color={Child.Color}/></div>
                  <SwipeableViews className={/*marks[0].Warning ?*/ "col-8 textCenter slide" /*: "col-9 textCenter slide"*/}
                                  style={{backgroundColor: Child.Color}}
                                  index={slideindex}
                                  key= {Child.Id}
                                  onChangeIndex={(e,index) => this.handleChangeIndex(e, index,Child.Id)}
                                  enableMouseEvents >
                    {marks.map((mark,mindex) => { 
                      return ( 
                        <div key={mark.Id} style={{backgroundColor: Child.Color}}>
                          <div id={"test_DailyView_MarkTime"+mindex} /*onClick={() => mark.Selectable ? this.confirmMode(mark.Id, mark.PersonId, mark.Type, "EDIT") : null}*/
                               style={{overflow: "hidden"}}>
                            <Typography color="textSecondary">{mark.Title}<br></br>{mark.Placement}</Typography>
                          </div>
                          {mark.Selectable && slideindex===mindex ?
                            <IconButton id="test_DailyRes_CloseIcon" style={{display: "inline-block", float: "right", marginTop: "-10%"}} size="small" aria-label="Close dailyview" onClick={() => this.confirmMode(mark.Id, mark.PersonId, mark.Type, "DELETERESERVATION")} >
                              <DeleteIcon id="test_DailyView_Delete" style={{color:"white"}} alt="Trashcan" />
                            </IconButton>
                          : null } 
                        </div>
                      );                  
                    })}
                  </SwipeableViews>
                  {marks[0].Warning ? 
                      <div className="col-1 warning" style={{backgroundColor: Child.Color, paddingTop:"15px"}}>
                        <Warning color="error" style={{float:"right", marginRight:"50%"}} alt="PV" width="20px" />
                      </div> :
                      <div className="col-1 warning" style={{backgroundColor: Child.Color, paddingTop:"15px"}}>
                      </div>
                    }
                  <MobileStepper className={"col-12"}
                  style={{backgroundColor: Child.Color}}
                                          variant="progress"
                                          steps={steps === 1 ? steps : steps + 1}
                                          position="static"
                                          //LinearProgressProps={{width: "100%"}}
                                          activeStep={slideindex + 1}
                                          //className={classes.root}
                                          nextButton={ <Button id={"test_DailyView_NextBtn"+index} size="small" onClick={() => this.handleNext(Child.Id)} style={{color: slideindex === steps-1 ? Child.Color : "black"}} /*disabled={slideindex === steps-1}*/>         <KeyboardArrowRight/> </Button> }
                                          backButton={ <Button id={"test_DailyView_BackBtn"+index} size="small" onClick={() => this.handlePrev(Child.Id)} style={{color: slideindex === 0 || steps === 1 ? Child.Color : "black"}} /*disabled={slideindex === 0 || steps === 1}*/> <KeyboardArrowLeft/>  </Button> }/>

                </div>
              );
            })} {/* looppaa lapset*/}

            {this.state.otherValidChilds.map(child => { 
              return (
                <div key = {child.Id} className="row" style={{ minHeight:"40px", margin:"0.2rem 0"}} onClick={this.makeReservations} > 
                  <div className={"col-3"} style={{backgroundColor: child.BgColor}}>{child.FirstName}</div>
                  <div className={"col-9 textCenter"} style={{backgroundColor: child.BgColor}}>
                    <IconButton size="small">{this.strings.bookingMake}</IconButton>
                  </div>
                </div>
              );
            })}
          </div>

          {this.state.showSlideNote ?
            <SlideConfirmationNote openNote     ={this.state.showSlideNote}
                                   title        ={this.state.SlideNoteTitle}
                                   text         ={this.state.NoteText}
                                   leftButton   ={this.state.SlideNoteLeftButton}
                                   rightButton  ={this.state.SlideNoteRightButton}
                                   middleButton ={this.state.SlideNoteMiddleButton}
                                   callback     ={(status) => this.handleSlideConfirmationNoteCB(status)}></SlideConfirmationNote>
          : null }

        </DialogContent>
        </Dialog>
      </div>
    );}
  }
}

export default DailyReservations;

import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Router from "./Router";
import { PrivateRoute } from "./Router";

import {
  /*isMobile, isBrowser,*/ deviceDetect /*, BrowserView, MobileView*/,
} from "react-device-detect";

import "./App.css";
import Testi from "./Layouts/Testi";
//import Esuomi from "./Components/Esuomi";
//turhaa teksti
// Pages
import Login from "./Layouts/Login";
import ChildInfo from "./Layouts/ChildInfo";
import ChildList from "./Layouts/ChildList";
import LeopsContainer from "./Layouts/Leops/LeopsContainer.jsx";
import LeopsSpringEvaluationPart from "./Layouts/Leops/SpringEvaluation/SpringEvaluationPart.jsx";
import LeopsPreparingPart from "./Layouts/Leops/Preparing/PreparingPart.jsx";
import LeopsDiscussionPart from "./Layouts/Leops/Discussion/DiscussionPart.jsx";
import LeopsSupportPart from "./Layouts/Leops/Supportneeds/SupportNeedsPart.jsx";
import VasuContainer from "./Layouts/Vasu/VasuContainer.jsx";
import VasuPreparingPart from "./Layouts/Vasu/Preparing/VasuPreparingPart.jsx";
import VasuSupportPart from "./Layouts/Vasu/Support/VasuSupportPart.jsx";
import VasuEnhancedSupport from "./Layouts/Vasu/Support/VasuEnhancedSupport";
import VasuSpecialSupport from "./Layouts/Vasu/Support/VasuSpecialSupport";
import VasuDiscussionPart from "./Layouts/Vasu/Discussion/VasuDiscussionPart.jsx";
import DescriptionPart from "./Layouts/Vasu/Description/DescriptionPart.jsx";
import Messaging from "./Layouts/Messaging";
import Messaging2 from "./Layouts/Messaging2";
import NotifyAbsent from "./Layouts/NotifyAbsent";
import NursingTimeCalendar from "./Layouts/NursingtimeCalendar";
import Settings from "./Layouts/Settings";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { ServerApi } from "./Api";
import BackgroundScheduler from "./Components/BackgroundScheduler";
import BulletinBoardLayout from "./Layouts/BulletinBoardLayout";
import LogoutLayout from "./Layouts/LogoutLayout";
import NewMessageLayout from "./Layouts/NewMessageLayout";
import OpenMessageLayout from "./Layouts/OpenMessageLayout";
import OpenMessageLayout2 from "./Layouts/OpenMessageLayout2";
import SnackbarListener from "./Components/SnackbarListener";
import ForgottenPassword from "./Layouts/ForgottenPassword/ForgottenPassword.jsx";
import NewUserPasswordLoginOnly from "./Layouts/NewUser/NewUserPasswordLoginOnly.jsx";
import TaskFolder from "./Components/TaskFolder";
import GrowthFolder from "./Layouts/GrowthFolder";
import PedagDocImage from "./Layouts/GrowthFolder/PedagDocImage";
import PedagDocImageFolder from "./Layouts/GrowthFolder/PedagDocImageFolder";
import GeneralPermissionsLayout from "./Layouts/GeneralPermissionsLayout/GeneralPerm.jsx";
import SnackbarQueueHandler from "./Components/SnackbarQueue/SnackbarQueueHandler";
import PermissionQueryLayout from "./Layouts/PermissionQueries/PermissionQueryLayout";
import CareTimeBooking from "./Components/CareTimeBooking/CareTimeBooking.jsx";
import CareTimeCalendar from "./Components/CareTimeCalendar/CareTimeCalendar";
import DiscussionBookingLayout from "./Layouts/DiscussionBooking/DiscussionBookingLayout.jsx";
import DiscussionBookingSelectionLayout from "./Layouts/DiscussionBooking/DiscussionBookingSelectionLayout";
import ChildsDiscussions from "./Layouts/DiscussionBooking/ChildsDiscussions";
import LogoutWarningDialog from "./Components/LogoutWarningDialog";
import PlacementInformation from "./Layouts/PlacementInformation/PlacementInfoLayout.jsx";
import { theme, orangetheme, sporttheme, greytheme } from "./Themes";
import { AutoLogoutHandler } from "./Auth";
import withAllContexts from "./State/withAllContexts";
import { SESSION_KEYS } from "./Contants";
// TODO: Add history listener to track navigation inside app and use that to handle when to show Back arrow on MainNavi
// https://github.com/ReactTraining/history/blob/master/docs/GettingStarted.md

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      MuiTheme: "theme",
    };

    sessionStorage.setItem("WebAppVersion", process.env.REACT_APP_VERSION);

    // if the version when the cookienote was last shown and the cookieNote was set to "off" is not found, set the cookieNote to be shown
    // the cookieNoteVersion value in localStorage is the version of the app when the cookieNote was clicked closed last time

    if (localStorage.getItem("cookieNoteVersion") === null) {
      localStorage.setItem("cookieNote", "on");
    } else {
      if (
        localStorage.getItem("cookieNoteVersion") !==
        sessionStorage.getItem("WebAppVersion")
      ) {
        localStorage.setItem("cookieNote", "on");
      }
    }

    if (localStorage.getItem("womDialogVersion") === null) {
      localStorage.setItem("womDialogIsOpen", "on");
    } else {
      if (
        localStorage.getItem("womDialogVersion") !==
        sessionStorage.getItem("WebAppVersion")
      ) {
        localStorage.setItem("womDialogIsOpen", "on");
      }
    }
    const {
      authActions: { showWarningPopup, logout },
    } = this.props.authContextValue;
    this.autoLogout = new AutoLogoutHandler({
      logout: logout,
      showPopup: showWarningPopup,
    });
  }

  componentDidMount() {
    const locationExcluded = (currentPath) => {
      let excluded = false;
      const paths = ["/login", "/logout"];

      paths.forEach((path) => {
        if (currentPath.startsWith(path)) {
          excluded = true;
        }
      });

      return excluded;
    };

    const device = deviceDetect();
    sessionStorage.setItem("device", device.model);

    window.addEventListener("beforeunload", function (event) {
      // Stora location except it's excluded
      if (!locationExcluded(window.location.pathname)) {
        window.sessionStorage.setItem(
          SESSION_KEYS.lastLocation,
          window.location.pathname
        );
      }
      delete event["returnValue"];
    });

    console.log(`This is ${process.env.NODE_ENV} build`);
  }

  componentDidUpdate() {
    if (this.state.MuiTheme !== sessionStorage.getItem("theme")) {
      this.setState({ MuiTheme: sessionStorage.getItem("theme") });
    }
  }

  changeMuiTheme = (requestedTheme) => {
    console.log("changeMuiTheme. req teema:", requestedTheme.teema);
    sessionStorage.setItem("theme", requestedTheme.teema);
    this.setState({ MuiTheme: requestedTheme.teema });
  };

  render() {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider
          theme={
            this.state.MuiTheme === "theme"
              ? theme
              : this.state.MuiTheme === "greytheme"
              ? greytheme
              : this.state.MuiTheme === "orangetheme"
              ? orangetheme
              : this.state.MuiTheme === "sporttheme"
              ? sporttheme
              : theme
          }
        >
          <Router>
            <BackgroundScheduler autoLogout={this.autoLogout} />

            <div className="App">
              <SnackbarListener />
              <SnackbarQueueHandler />
              <LogoutWarningDialog />
              {/*<Header callbackFromHeader={(teema) => this.changeMuiTheme({teema})}/>*/}
              <Switch>
                <Route exact path="/login" component={Login} />
                <Route
                  exact
                  path="/forgottenpassword"
                  component={ForgottenPassword}
                />
                <Route path="/logout" component={LogoutLayout} />
                <Route
                  exact
                  path="/newuser"
                  component={NewUserPasswordLoginOnly}
                />
                <PrivateRoute
                  exact
                  path="/nursingtimes"
                  component={NursingTimeCalendar}
                />
                <PrivateRoute
                  exact
                  path="/caretimebooking"
                  component={CareTimeBooking}
                />
                <PrivateRoute
                  exact
                  path="/caretimecalendar"
                  component={CareTimeCalendar}
                />
                <PrivateRoute
                  exact
                  path="/notifyabsent"
                  component={NotifyAbsent}
                />
                <PrivateRoute
                  exact
                  path="/messaging/openmessage"
                  component={OpenMessageLayout}
                />
                <PrivateRoute
                  exact
                  path="/messaging/openmessage/:ThreadId"
                  component={OpenMessageLayout2}
                />
                <PrivateRoute
                  exact
                  path="/messaging/newmessage"
                  component={NewMessageLayout}
                />
                <PrivateRoute exact path="/messaging" component={Messaging} />
                <PrivateRoute exact path="/messaging2" component={Messaging2} />
                <PrivateRoute exact path="/childList" component={ChildList} />
                <PrivateRoute exact path="/childInfo" component={ChildInfo} />
                <PrivateRoute
                  exact
                  path="/leops/:ChildId"
                  component={LeopsContainer}
                />
                <PrivateRoute
                  exact
                  path="/leopsSupportPart"
                  component={LeopsSupportPart}
                />
                <PrivateRoute
                  exact
                  path="/leopsPreparingPart"
                  component={LeopsPreparingPart}
                />
                <PrivateRoute
                  exact
                  path="/leopsDiscussionPart"
                  component={LeopsDiscussionPart}
                />
                <PrivateRoute
                  exact
                  path="/leopsSpringEvaluationPart"
                  component={LeopsSpringEvaluationPart}
                />
                <PrivateRoute
                  exact
                  path="/vasu/:ChildId"
                  component={VasuContainer}
                />
                <PrivateRoute
                  exact
                  path="/vasuPreparingPart"
                  component={VasuPreparingPart}
                />
                <PrivateRoute
                  exact
                  path="/vasuDiscussionPart"
                  component={VasuDiscussionPart}
                />
                <PrivateRoute
                  exact
                  path="/descriptionPart"
                  component={DescriptionPart}
                />
                <PrivateRoute
                  exact
                  path="/vasuSupportPart"
                  component={VasuSupportPart}
                />
                <PrivateRoute
                  exact
                  path="/vasuEnhancedSupport"
                  component={VasuEnhancedSupport}
                />
                <PrivateRoute
                  exact
                  path="/vasuSpecialSupport"
                  component={VasuSpecialSupport}
                />
                <PrivateRoute exact path="/settings" component={Settings} />
                <PrivateRoute
                  exact
                  path="/bulletinboard"
                  component={BulletinBoardLayout}
                />
                <PrivateRoute
                  exact
                  path="/placementinformation"
                  component={PlacementInformation}
                />
                <PrivateRoute exact path="/testi" component={Testi} />
                <PrivateRoute
                  exact
                  path="/growthfolder/imagefolder"
                  component={PedagDocImageFolder}
                />
                <PrivateRoute
                  exact
                  path="/growthfolder/taskfolder"
                  component={TaskFolder}
                />
                <PrivateRoute
                  exact
                  path="/growthfolder/media"
                  component={PedagDocImage}
                />
                <PrivateRoute
                  exact
                  path="/growthfolder"
                  component={GrowthFolder}
                />
                <PrivateRoute
                  exact
                  path="/permissions"
                  component={GeneralPermissionsLayout}
                />
                <PrivateRoute
                  exact
                  path="/permissionqueries"
                  component={PermissionQueryLayout}
                />
                <PrivateRoute
                  exact
                  path="/discussionBooking"
                  component={DiscussionBookingLayout}
                />
                <PrivateRoute
                  exact
                  path="/bookDiscussion"
                  component={DiscussionBookingSelectionLayout}
                />
                <PrivateRoute
                  exact
                  path="/discussions"
                  component={ChildsDiscussions}
                />
                <PrivateRoute path="/" component={ChildList} />
              </Switch>
            </div>
          </Router>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

export default withAllContexts(App);

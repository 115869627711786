import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Languages from "../../../translations";

const NewUserOrderUsernameFailedView = ({errorType, resetThisPage, resetPageAndRedirect}) => {

  return(
    <React.Fragment>
      <Typography>{Languages.requestFailed + ". " + errorType.LocalizedErrorDetails}</Typography>

      {
        <React.Fragment>
          <Button
            style={{marginTop:"10px", marginLeft: "5px"}}
            color="primary"
            variant="outlined"
            onClick={resetThisPage}
          >{Languages.tryAgainQ}</Button>
          <Button
            style={{marginTop:"10px", marginLeft: "5px"}}
            onClick={resetPageAndRedirect}
            color="primary"
            variant="outlined"
            id="test_toLoginPageButton"
          >{Languages.toTheLoginPage}</Button>
        </React.Fragment>
      }

    </React.Fragment>
  )
}

export default NewUserOrderUsernameFailedView;
import React, { useState } from "react";
import ContentBoxMUI from "../../ContentBoxMUI";
import makeStyles from "@mui/styles/makeStyles";
import Languages from "../../../translations";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

import ChildCareIcon from "@mui/icons-material/ChildCare";
import ReplayIcon from "@mui/icons-material/Replay";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import DaisyLogo from "../../../images/Family-21.png";

//const useLightStylesDesktop = makeStyles((theme) => ({
const loadingStylesLight = makeStyles((theme) => ({
  cardActions: {
    backgroundColor: "white",
    width: "100%",
    color: "#4377BA",
    textAlign: "center",
    //display: "grid",
  },
  cardContent: {
    backgroundColor: "white",
    width: "100%",
    color: "#4377BA",
    textAlign: "center",
  },
  button: {
    color: "#4377BA",
    textAlign: "center",
  },
  cardMedia: {
    backgroundColor: "#86B8FF",
    textAlign: "center",
    display: "-webkit-inline-block",
    display: "inline-block",
    maxWidth: "50%",
    borderRadius: "50px",
  },
  backdrop: {
    //zIndex: 100,
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    overflow: "hidden",
    cursor: "wait",
    cursor: "progress",
    touchAction: "none",
  },
}));

const CareTimeCalendarLoading = () => {
  const classes = loadingStylesLight();

  return (
    <>
      <ContentBoxMUI style={{ scroll: "no", overflow: "hidden" }}>
        <Backdrop
          //color='#fff'
          className={classes.backdrop}
          open={true}
        >
          <Card elevation={12} height="100%">
            <CardContent className={classes.cardContent}>
              <CardMedia
                className={classes.cardMedia}
                component="img"
                image={DaisyLogo}
                alt="DaisyFamily logo"
              />
              <br />
              <br />
              <Divider />
              <br />
              <CircularProgress />

              <Typography textAlign="center" variant="h5">
                {Languages.newCalendarLoadingCareTimes}
              </Typography>
              <ChildCareIcon fontSize="large" />
              <br />
              <br />
              <Divider />
            </CardContent>
          </Card>
        </Backdrop>
      </ContentBoxMUI>
    </>
  );
};

export default CareTimeCalendarLoading;

import React, { useState, useEffect } from "react";
import {useStateValue} from "../../../../State";

import Moment           from "moment";

import Languages        from "../../../../translations";

import Accordion        from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon   from '@mui/icons-material/ExpandMore';
import Typography       from "@mui/material/Typography";

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  nobottomline: {
    borderBottom: "none",
    color: "black",
    backgroundColor: "#ffffff",
    flex: "0 0 100%"
  },
}));


const OutcomeOfTheEvaluation = (props) => {

  const classes = useStyles();

  const { Form, callback, closeView } = props;

  const { state/*, actions*/ } = useStateValue();
  const Table3   = state.leops.Table3;
  const SubModel = Form !== undefined ? Form === 2 ?  Table3.EnhancedSupportModel : Table3.HojksModel : null;

  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
    callback("OOTE");
  }

  //console.log("SupportTable1. MOUNT. \nTable3: ",Table3,"\n Items: ", Items);
  //console.log("SupportTable1. MOUNT",
  //            "\nForm1T1: ", Form1T1);

  useEffect(() => {
    //console.log("Table1. useEffect. Close view");
    setExpanded(false);
  },[closeView]); 

  return (
      <Accordion className="painikkeet"
                 style={{ backgroundColor: "#4377BA"/*, color: "black"*/ }}
                 //disabled = {item.Saved === "" ? true: false}
                 expanded={expanded}
                 onChange={() => toggleExpanded()}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel3a-content"
                          id="test_Leops_SupportTableOOE">
          <Typography className="heading"> {Languages.LeopsFormTitle5}</Typography>
        </AccordionSummary>
        <div className={classes.nobottomline}>
          <AccordionDetails>
            
          {Form === 2 ?
            <div className={classes.nobottomline}>
            <input style={{width: '20px', height: '20px'}}id="test_OOTE_ENHANCED_1" type="checkbox" data-indeterminate="false" checked={SubModel.Selection1} readOnly />{"  " + Languages.EnhancedSupportNotNeeded}
            <br></br>
            <input style={{width: '20px', height: '20px'}}id="test_OOTE_ENHANCED_2" type="checkbox" data-indeterminate="false" checked={SubModel.Selection2} readOnly />{"  " +Languages.LeopsEnhancedTitle5_2}
            <br></br>
            <input style={{width: '20px', height: '20px'}}id="test_OOTE_ENHANCED_3" type="checkbox" data-indeterminate="false" checked={SubModel.Selection3} readOnly />{"  " +Languages.LeopsEnhancedTitle5_3}
            </div>
          :
            <div className={classes.nobottomline}>
            <input style={{width: '20px', height: '20px'}}id="test_OOTE_HOJKS_1" type="checkbox" data-indeterminate="false" checked={SubModel.Selection1} readOnly />{" " +Languages.LeopsHojksTitle5_1}
            <br></br>
            <input style={{width: '20px', height: '20px'}}id="test_OOTE_HOJKS_2" type="checkbox" data-indeterminate="false" checked={SubModel.Selection2} readOnly />{" " +Languages.LeopsHojksTitle5_2}
            </div>
          }
          </AccordionDetails>
          </div>
      </Accordion>
  );
}

export default OutcomeOfTheEvaluation;
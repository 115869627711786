import React from "react";
import Languages from "../../../../translations";

const BackupParentInfoReadOnlyField = ({ parent }) => {
  return (
    <div className="row">
      <div className="col-4"><p>{Languages.ciName}</p></div>
      <div className="col-8"> <p>{parent.LastName + ", " +parent.FirstName} </p> </div>

      <div className="col-4">
        {parent.BUParentPhoneNumber?.Number && <p>{Languages.ciShortPhoneNumber}</p>}
      </div>

      <div className="col-8">
        {parent.BUParentPhoneNumber?.Number && <div><p>{parent.BUParentPhoneNumber.Number}</p></div>}
      </div>

      <div className="col-4">
        {parent.BUParentWorkNumber && <p>{Languages.ciShortWorkNumber}</p>}
      </div>

      <div className="col-8">
        {parent.BUParentWorkNumber && <div><p>{parent.BUParentWorkNumber.Number}</p></div>}
      </div>
    </div>
  )
}

export default BackupParentInfoReadOnlyField;
import React, { useState }  from 'react';
//import { useStateValue }                        from '../../../State';
import { ATTACHMENT_TYPES }          from "../../../Api";
import { ServerApi } from "../../../internal";
import { downloadBase64File }                   from "../../../utils";
import Languages                               from "../../../translations";
import { previewBase64File }                   from "../../../utils";

import FileOpenIcon from '@mui/icons-material/FileOpen';
import DownloadIcon from '@mui/icons-material/Download';
import ListItemButton from '@mui/material/ListItemButton';

import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade'
import { useStateValue } from '../../../State';


// This renders a button for downloading a file


const MessagingFileDownload = ({ FileName, FileMimeType, FileId, compactMode = false }) => {
/* const {state, actions} = useStateValue()
const {files} = state */
console.log("Kuka hitto sä oot")
/* console.log(state)
console.log(files) */

console.log(FileName)

return(
  <>
  <Tooltip 
          arrow 
          title={<div style={{fontSize: "0.8rem"}}>{Languages.generalDownloadFileAttachment2}.<hr /><li>{FileName}</li></div>}
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
        >
  <ListItemButton 
        aria-label={Languages.generalDownloadFileAttachment + " " + FileName} 
        id="test_OpenMessage_download_file" 
        download={FileName}
        onClick={() => attachmentOnClick(FileId, FileMimeType, FileName)} 
        divider="true" 
        sx={{flexDirection: "column", color: "white", fontSize: 12}}
        ><DownloadIcon />{Languages.downloadMedia}
  </ListItemButton>
  </Tooltip>
  <Tooltip 
          arrow 
          title={<div style={{fontSize: "0.8rem"}}>{Languages.generalOpenText}<hr /><li>{FileName}</li></div>}
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}  
        >
  <ListItemButton 
    divider="true" 
    sx={{flexDirection: "column", color: "white", fontSize: 12}}
    color="secondary"
    download={FileName}
    onClick={() => previewFileOnClick(FileId, FileMimeType, FileName)}
    ><FileOpenIcon />{Languages.generalOpen}</ListItemButton>
    </Tooltip>
  </>
)


 
}

const previewFileOnClick = async (FileId, FileMimeType, FileName) => {
  const response = await new ServerApi().previewFile(FileId, ATTACHMENT_TYPES.attachment, FileMimeType);
  previewBase64File(response, FileMimeType, FileName);
}

const attachmentOnClick = async (FileId, FileMimeType, FileName) => {
  const response = await new ServerApi().downloadFile(FileId, ATTACHMENT_TYPES.attachment, FileMimeType);
  downloadBase64File(response.data, FileMimeType, FileName);
}

export default MessagingFileDownload;


//Outdated
/*
 return(
    <>
    <h5 style={{color: "red", width: "100%", display: "inline"}}><FilePresentIcon /> {FileName}</h5>
      <Button 
        aria-label={Languages.generalDownloadFileAttachment + " " + FileName}
        color="secondary"
        variant="outlined"
        style={compactMode ? {color: "#D6E4FF"} : {}}
        id="test_OpenMessage_download_file" 
        download={FileName}
        onClick={() => attachmentOnClick(FileId, FileMimeType, FileName)}>
          <DownloadIcon /*{Languages.generalDownloadFileAttachment + " " + FileName}*/ /*/> Lataa 
          </Button>
          <Button 
                  color="secondary"
                  variant="outlined"
                  style={compactMode ? {color: "#D6E4FF"} : {}}
                  download={FileName}
                  onClick={() => previewFileOnClick(FileId, FileMimeType, FileName)}
          >
          <FileOpenIcon /> Avaa
          </Button>
          </>
      )
*/
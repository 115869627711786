import React, { useState, useEffect } from "react";
import {useStateValue} from "../../../State";
import Typography from "@mui/material/Typography";
import Languages              from '../../../translations';
import TableContainer         from "@mui/material/TableContainer";
import Paper                  from "@mui/material/Paper";
import Table                  from "@mui/material/Table";
import TableHead              from "@mui/material/TableHead";
import TableBody              from "@mui/material/TableBody";
import TableRow               from "@mui/material/TableRow";
import TableFooter            from "@mui/material/TableFooter";
import TableCell              from "@mui/material/TableCell";
import ButtonBase             from "@mui/material/ButtonBase";
import _cloneDeep             from "lodash/cloneDeep";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import Badge from "@mui/material/Badge";
import LockClockIcon from '@mui/icons-material/LockClock';
import moment from "moment";


const PermissionQuerySuccess = ({permQueryData, changeListView, isShown}) => {

  const { state, actions } = useStateValue();

  console.log(permQueryData)

  const permQueryList = state.municipalpermissionquestions.permissionQueryList.data;

  //const [copyOfData, setCopyOfData] = useState(_cloneDeep(muniPermData.MunicipalPermissionQuestions));
  // värit montako kyllä montako ei, otsikko, lähettäjä, voimassa

  const openSinglePermissionQuery = (queryid) => {
    actions.triggerLoadSinglePermissionQuery(queryid);
    //console.log(queryid)
    changeListView();
  }


  return(
    <React.Fragment>  
      <TableContainer component={Paper} style={{background: "#4377BA", overflowX: "unset"}}>
        <Table /*size="small"*/ aria-label={Languages.permPermissionQueries}>
          <TableHead>
            <TableRow sx={{backgroundColor: "#4377BA",}}>
              <TableCell align="center"><Typography><NewReleasesIcon/></Typography></TableCell>
              <TableCell sx={{fontSize: "1.25rem", fontWeight: "600"}}>{Languages.permPQTitle}</TableCell>
              <TableCell sx={{fontSize: "1.25rem", fontWeight: "600"}} align="right"><LockClockIcon /> {/*Languages.uctDateAbbr*/}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {permQueryList.QueryPerms.map((query) => {
              return(
                <TableRow  
                  focusRipple 
                  selected={true} 
                  hover={true} 
                  onClick={() => openSinglePermissionQuery(query.Id)} 
                  style={query.Read ? {backgroundColor: "#D7E3ED", borderBottom: "solid 2px white", margin: "50px", cursor: "pointer", '&:hover': {background: "black"}} : {backgroundColor:  "#4377BA", borderBottom: "solid 2px white", margin: "50px", cursor: "pointer",}}
                  key={query.Id}>
                  <TableCell  align="center">{query.UnansweredNumber === 0 ? null : <Badge badgeContent={query.UnansweredNumber} color="error" />}
                    {/*<ButtonBase 
                      focusRipple 
                      onClick={() => openSinglePermissionQuery(query.Id)}>
                        
                    </ButtonBase>*/}
                  </TableCell>
                  
                  <TableCell sx={query.Read ? {color: "#002E6D", fontWeight: "100"} : {color: "#ffffff", fontWeight: "bold"}} align="left">
                    <ButtonBase 
                    sx={query.Read ? {color: "#002E6D", fontWeight: "100"} : {color: "#ffffff", fontWeight: "bold"}}
                      focusRipple 
                      //onClick={() => openSinglePermissionQuery(query.Id)}
                  >
                        {query.ShortText}
                    </ButtonBase>
                  </TableCell>
                  <TableCell sx={query.Read ? {color: "#002E6D", fontWeight: "100"} : {color: "#ffffff", fontWeight: "bold"}} align="right">{moment(query.Date).format('L')}</TableCell>
                </TableRow>
                )
            })}
          </TableBody>
          <TableFooter>
            <TableRow></TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </React.Fragment>
  )
  
}

export default PermissionQuerySuccess;
import React, { useEffect, useState } from "react";

import { useStateValue } from "../../../../State";

import { PickersDay } from "@mui/x-date-pickers/PickersDay";

import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ChildProfilePicture from "../../../ChildProfilePicture";
import CloseIcon from "@mui/icons-material/Close";

import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

import { styled } from "@mui/material/styles";

import MenuItem from "@mui/material/MenuItem";

import Select from "@mui/material/Select";

import Badge from "@mui/material/Badge";
import { types } from "../../../../Reducers/actionTypes";
import TimeSlider from "../common/TimeSlider";
import CalendarTabs from "../common/CalendarTabs";

const ChildSelection = ({ names, listOfChildren, handleFormat }) => {
  //List of selected children for the resercation

  return (
    <ToggleButtonGroup
      sx={{ width: "100%", mt: 0.5 }}
      orientation="vertical"
      value={listOfChildren}
      onChange={handleFormat}
    >
      {names.map((name) => (
        <ToggleButton
          value={name.Id}
          sx={{
            width: "80%",
            ml: "10%",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Box sx={{ width: "20%" }}>
                <Avatar sx={{ width: 36, height: 36, mr: 5 }}>
                  <ChildProfilePicture childId={name.Id} />
                </Avatar>
              </Box>
              <Box sx={{ width: "60%" }}>
                <Typography
                  color="textSecondary"
                  fontSize="small"
                  textAlign="center"
                >
                  {name.Name}
                </Typography>
              </Box>
              <Box sx={{ width: "20%" }}>
                {listOfChildren.indexOf(name.Id) > -1 && (
                  <CheckIcon color="success" />
                )}
                {listOfChildren.indexOf(name.Id) == -1 && (
                  <ClearIcon color="error" />
                )}
              </Box>
            </Stack>
          </Box>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default ChildSelection;

import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Languages from "../../../translations";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";


const NewUserOrderUsernameSuccessView = ({newusername, resetPageAndRedirect}) => {

  return(
    <React.Fragment>
      <Typography>{Languages.welcomeToUsingDF}</Typography>
      <Typography style={{marginTop:"10px"}} color="error">
        {Languages.infoNewUser1}
      </Typography>

      <Card style={{marginTop:"10px"}}>
        <CardContent>
          <Typography>{Languages.yourUserName}</Typography>
          <Typography variant="h5" component="h2">{newusername}</Typography>
        </CardContent>
      </Card>

      <Typography style={{marginTop:"10px"}}> {Languages.infoNewUser2} </Typography>

      <Button 
        style={{marginTop:"10px"}}
        onClick={resetPageAndRedirect}
        color="secondary"
        variant="outlined"
        id="test_toLoginPageButton"
      >{Languages.iHaveCopiedMyUserName}
      </Button>
    </React.Fragment>
  )
}

export default NewUserOrderUsernameSuccessView;
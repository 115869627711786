import React, { useEffect, useState } from "react";
import { useStateValue } from "../../../../State";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";

import InvoiceDataSuccessView from "../../../../Layouts/InvoiceBasis/InvoiceDataSuccessView";
import InvoiceSummaryTableLoading from "../../../../Layouts/InvoiceBasis/Components/InvoiceSummaryTableLoading";
import makeStyles from "@mui/styles/makeStyles";
import Card from "@mui/material/Card";
import Languages from "../../../../translations";
import PopUpAppBar from "../../../PopUpAppBar/PopUpAppBar";

const InvoiceDialog = ({ timespan, childId, close }) => {
  const { state, actions } = useStateValue();

  const [isMount, setIsMount] = useState(true);

  const [summaryDetails, setSummaryDetails] = useState(null);

  useEffect(() => {
    if (isMount === true) {
      actions.triggerLoadInvoiceSummaryTable({
        childId: childId,
        timespan: timespan,
      });
      setIsMount(false);
    }
  }, [isMount, setIsMount, childId, timespan, actions]);

  const { nursingTimesSummary } = state;

  useEffect(() => {
    if (
      nursingTimesSummary.InvoiceBasisData.status ===
      "INVOICE_SUMMARY_TABLE_LOAD_SUCCESS"
    ) {
      setSummaryDetails(nursingTimesSummary.InvoiceBasisData.data);
    } else {
      setSummaryDetails(null);
    }
  }, [
    nursingTimesSummary.InvoiceBasisData.status,
    nursingTimesSummary.InvoiceBasisData.data,
  ]);

  const useStyles = makeStyles((theme) => ({
    typography: {
      padding: theme.spacing(2),
    },
    paper: {
      backgroundColor: "#FFFFFF",
    },
  }));

  const classes = useStyles();

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      fullScreen
      open={true}
      onClose={close}
      scroll={"paper"}
    >
      <DialogContent>
        <PopUpAppBar
          headerTitle={Languages.uctInvoiceBasis}
          closeFunction={close}
        />
        <div
          data-testid="InvoiceBasis-9370nd7e"
          style={{ minHeight: "60px" }}
        ></div>

        {nursingTimesSummary.InvoiceBasisData.status ===
          "INVOICE_SUMMARY_TABLE_LOAD_SUCCESS" && (
          <InvoiceDataSuccessView
            childId={childId}
            summaryData={summaryDetails}
          />
        )}
        {nursingTimesSummary.InvoiceBasisData.status ===
          "INVOICE_SUMMARY_TABLE_LOAD_FAILED" && (
          <Card>
            <Typography>{Languages.errorDataCouldNotBeDownloaded}</Typography>
          </Card>
        )}
        {nursingTimesSummary.InvoiceBasisData.status ===
          "LOADING_INVOICE_SUMMARY_TABLE" && <InvoiceSummaryTableLoading />}
      </DialogContent>
    </Dialog>
  );
};

export default InvoiceDialog;

import React, { useState, useEffect } from "react";
import {useStateValue} from "../../../../State";

import Moment           from "moment";

import Languages        from "../../../../translations";

import Accordion        from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon   from '@mui/icons-material/ExpandMore';
import Typography       from "@mui/material/Typography";

import Table            from '@mui/material/Table';
import TableBody        from "@mui/material/TableBody";
import TableCell        from "@mui/material/TableCell";
import TableHead        from "@mui/material/TableHead";
import TableRow         from "@mui/material/TableRow";

import makeStyles from '@mui/styles/makeStyles';


const useStyles = makeStyles(theme => ({
  nobottomline: {
    borderBottom: "none",
    color: "black",
    backgroundColor: "#ffffff",
    flex: "0 0 100%"
  },
}));

const CheckingSpecialSupport = (props) => {

  const classes = useStyles();

  const { Form, callback, closeView } = props;

  const { state/*, actions*/ } = useStateValue();
  const Table3   = state.leops.Table3;
  const SubModel = Table3.StudyModel;

  const [expanded, setExpanded] = useState(false);

  //console.log("CheckingSpecialSupport. MOUNT. SubModel: ", SubModel);

  const toggleExpanded = () => {
    setExpanded(!expanded);
    callback("CSS");
  }

  useEffect(() => {
    //console.log("Table1. useEffect. Close view");
    setExpanded(false);
  },[closeView]); 

  return (
      <Accordion className="painikkeet"
                 style={{ backgroundColor: "#4377BA"/*, color: "black"*/ }}
                 //disabled = {item.Saved === "" ? true: false}
                 expanded={expanded}
                 onChange={() => toggleExpanded()}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel3a-content"
                          id="test_Leops_SupportTableCSS">
          <Typography className="heading"> {Languages.LeopsStudy2}</Typography>
        </AccordionSummary>
        <div className={classes.nobottomline}>
        <AccordionDetails>
            <h6>{Languages.LeopsInfo}</h6>
        </AccordionDetails>

        <AccordionDetails>
           <Table style={{align:"left"}}>  
              <TableHead>
                <TableRow>
                  <TableCell className={classes.nobottomline} padding="none" >{Languages.SpecialSupportFirstDate}</TableCell> 
                  <TableCell className={classes.nobottomline} style={{paddingLeft: "20px"}} >{Languages.SpecialSupportPrevDate}</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow >
                  <TableCell className={classes.nobottomline} padding="none" align="center"> {Moment(SubModel.FirstDate).format("DD.MM.YYYY")} </TableCell> 
                  <TableCell className={classes.nobottomline} align="center" style={{paddingLeft: "20px"}}> {Moment(SubModel.PrevCheckDate).format("DD.MM.YYYY")} </TableCell>
                </TableRow>
              </TableBody>

              <TableHead>
                <TableRow>
                  <TableCell className={classes.nobottomline} padding="none">{Languages.DecisionMaker}</TableCell> 
                  <TableCell className={classes.nobottomline} style={{paddingLeft: "20px"}} >{Languages.DecisionMaker}</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow >
                  <TableCell className={classes.nobottomline} padding="none" align="center"> {SubModel.DecisionMaker1} </TableCell> 
                  <TableCell className={classes.nobottomline} align="center" style={{paddingLeft: "20px"}}> {SubModel.DecisionMaker2} </TableCell>
                </TableRow>
              </TableBody>

              <TableHead>
                <TableRow>
                  <TableCell className={classes.nobottomline} padding="none">{Languages.PreschoolOrganizer}</TableCell> 
                  <TableCell className={classes.nobottomline} style={{paddingLeft: "20px"}} >{Languages.PreschoolOrganizer}</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow >
                  <TableCell className={classes.nobottomline} padding="none" align="center"> {SubModel.PreschoolOrganizer1} </TableCell> 
                  <TableCell className={classes.nobottomline} align="center" style={{paddingLeft: "20px"}}> {SubModel.PreschoolOrganizer2} </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            </AccordionDetails>

            <AccordionDetails>
              <input style={{width: '20px', height: '20px'}} id="test_CSS_CHECK1" type="checkbox" data-indeterminate="false" checked={SubModel.GeneralCompulsoryEducation} readOnly />  {"  " + Languages.GeneralCompulsoryEducation + ", " + Languages.Started.toLowerCase() + " " + Moment(SubModel.GeneralCompulsoryEducationStartDate).format("DD.MM.YYYY") }
            </AccordionDetails>
            <AccordionDetails>
              <input style={{width: '20px', height: '20px'}} id="test_CSS_CHECK2" type="checkbox" data-indeterminate="false" checked={SubModel.ExtendedCompulsoryEducation} readOnly />  {Languages.ExtendedCompulsoryEducation + ", " + Languages.Started.toLowerCase() + " " + Moment(SubModel.ExtendedCompulsoryEducationStartDate).format("DD.MM.YYYY") }
          </AccordionDetails>
        </div>
      </Accordion>
  );
}

export default CheckingSpecialSupport;
import {React, useState} from "react"
import DiscussionCard from "./DiscussionCard"
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Pagination from '@mui/material/Pagination'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import './discussionCard.css'

const theme = createTheme({
    palette:{
        pagination:{
            main: '#D7E3ED',
        }
    }
})

const DiscussionCardPagination = ({discussions}) => {
    const [page, setPage] = useState(1)
    const handleChange = (event, value) => {
        setPage(value)
    }
    const showOnOnePage = 3
    const pageCount = Math.ceil(discussions.length / showOnOnePage)
    return(
        <Box >
            <Stack spacing={0} sx={{display:"flex",justifyContent:"center"}} className="pagination" >
                <ThemeProvider theme={theme}>
                    <Pagination sx={{alignSelf:"center"}} count={pageCount} page={page} onChange={handleChange} color="pagination" />
                </ThemeProvider>
                <ShowPage discussions={discussions} page={page} showOnOnePage={showOnOnePage}/>
                <ThemeProvider theme={theme}>
                    <Pagination sx={{alignSelf:"center"}} count={pageCount} page={page} onChange={handleChange} color="pagination" />
                </ThemeProvider>
            </Stack>
        </Box>
    )
}

export default DiscussionCardPagination

const ShowPage = ({discussions,page, showOnOnePage}) =>{
    let array = []
    for(let i = (page-1)*showOnOnePage; i < page*showOnOnePage; i++){
        if(i < discussions.length){
            array.push(discussions[i])
        }
    }
    return(
        array.map((card)=>{
            return <DiscussionCard
            provideKey={card.DiscussionId}
            discussionId={card.DiscussionId}
            type={card.DiscussionType} 
            dateData={card}
            cancelDate={card.CancelableUntil}
            parents={card.Parents} 
            staff={card.Employees}
            remote={card.IsRemoteMeeting}
            address={card.DiscussionAddress}
            url={card.MeetingUrl}
            employeeInfoText={card.ExtraInfoShared}
            parentInfoText={card.ExtraInfoParent}
            old
            />
        })
    )
}
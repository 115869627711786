import { types } from './actionTypes';

const DFSettingsReducer = (state, action) => {
  const payload = action.data;
  switch (action.type) {
    case types.LOADING_DF_SETTINGS:
      //console.log("DFSettingsReducer loading");
      return {
        ...state,
        state: types.LOADING_DF_SETTINGS
      }
    case types.LOAD_SUCCESS_DF_SETTINGS:
      //console.log('DFSettings reducer got action', action);
      //console.log("DFSettings reducer got payload: ", payload);
      //Be careful with the depth here - for example, here we do not need to say,
      // updage dFSettings { code: payload.status, state: etc}
      if (!payload) {
        return {
          ...state
        }
      }
      return {
        ...state,
          code: payload.status,
          state: types.LOAD_SUCCESS_DF_SETTINGS,
          updateMainStatsTimerSecs: payload.data.UpdateMainStatsTimerSecs,
          updateThreadTimerSecs: payload.data.UpdateThreadTimerSecs,
      }
    case types.LOAD_FAILED_DF_SETTINGS:
      //console.log("DFSettingsReducer. LOAD FAILED payload: ", payload);
      return ({
        ...state,
        
          code: (typeof payload.Code === "undefined" ? 777 : payload.Code),
          state: types.LOAD_FAILED_DF_SETTINGS,
        });

    default: return state;
  }
}

export default DFSettingsReducer;
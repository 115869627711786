import React, { useState, useEffect } from "react";
import {useStateValue} from "../../../State";
import "../vasu.css";

import Box              from '@mui/material/Box'
import Link             from '@mui/material/Link'
import ContentBoxMUI          from "../../../Components/ContentBoxMUI";
import List             from '@mui/material/List';
import ListItemButton   from '@mui/material/ListItemButton';
import ListItemIcon     from '@mui/material/ListItemIcon';
import ListItemText     from '@mui/material/ListItemText';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import ChildRow               from '../ChildRow';
import DescriptionSubPart from "./DescriptionSubPart";
import Languages        from "../../../translations";
import IconButton             from "@mui/material/IconButton";


const DescriptionPart = (props) => {

  const { history, location } = props;
  const { state/*, actions*/ } = useStateValue();

  if (location.state === undefined)
  { history.goBack(); }

  const [ShowSlideIndex, setShowSlideIndex] = useState(-1);


  const handleBackClick = () => {
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = () => {
      window.history.pushState(null, "", window.location.href);
      setShowSlideIndex(-1);
      window.onpopstate = undefined;
      window.history.back();
    };
  };

  const handleBoxClick = (index) => {
    setShowSlideIndex(index);
    handleBackClick();
  };

  if (location.state === undefined)
  {
    return (
      <div className="DescriptionPart"></div>
    );
  }
  else
  {
    const { childpicture, childFirstName, childLastName, childColor, vasu } = location.state;
  
    const GeneralPart = vasu ? state.vasu.GeneralPart : state.leops.GeneralPart;
  
  return (
    <div className="DescriptionPart">
      <div className="maxwidth">
       <ContentBoxMUI>

       <Box sx ={{mt: 1}}>
          <ChildRow childpicture   = {childpicture}
                    childFirstName = {childFirstName}
                    childLastName = {childLastName}
                    childColor     = {childColor}
                    FormId         = {GeneralPart.Id}
                    history        = {history}>
          </ChildRow>
        </Box>

        {ShowSlideIndex === -1 ?
        <div>
          {vasu ? <h5 className="textCenter">{GeneralPart.HideTable1 === true ? "2. " + Languages.eduPlanChildDescription : "3. " + Languages.eduPlanChildDescription}</h5>
          :       <h5 className="textCenter">{/*"4. "*/"5. " + Languages.eduPlanChildDescription}</h5> }

            <List className="col-12" sx={{ mt: -1}}>
              <ListItemButton className="boxed" sx={{backgroundColor: "#fbcece", borderRadius: .5, mt: 1.5, boxShadow: 2}} onClick={(event) => handleBoxClick(0)}>
                <ListItemText 
                  primaryTypographyProps={{fontWeight: 'Bold', fontSize: 16, ml: 2}} primary={Languages.ChildStrengthsAndIterests}/>
                <ListItemIcon><ArrowForwardIosIcon fontWeight='Bold' fontSize="small"/></ListItemIcon>
              </ListItemButton>
              <ListItemButton className="boxed" sx={{ backgroundColor: "#fbcece", borderRadius: .5, mt: 1.5, boxShadow: 2}} onClick={(event) => handleBoxClick(1)}>
                <ListItemText 
                  primaryTypographyProps={{fontWeight: 'Bold', fontSize: 16, ml: 2}} primary={Languages.ChildWellbeingAndLearningObjectives} />
                <ListItemIcon><ArrowForwardIosIcon fontWeight='Bold' fontSize="small"/></ListItemIcon>
              </ListItemButton>
              <ListItemButton className="boxed" sx={{ backgroundColor: "#fbcece", borderRadius: .5, mt: 1.5, boxShadow: 2}} onClick={(event) => handleBoxClick(2)}>
                <ListItemText 
                  primaryTypographyProps={{fontWeight: 'Bold', fontSize: 16, ml: 2}} primary={Languages.OtherExtraInfo+" /\n"+Languages.OtherImportanInfo}/>
                <ListItemIcon><ArrowForwardIosIcon fontSize="small" /></ListItemIcon>
              </ListItemButton>
            </List>
        </div>
        :
          <DescriptionSubPart SelectedSlideIndex  = {ShowSlideIndex} vasu={vasu}> </DescriptionSubPart>
        }
      </ContentBoxMUI>
    </div>
  </div>
  );
  }
}

export default DescriptionPart;
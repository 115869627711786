import React, { useState, useEffect/*, useRef*/ } from "react";
import Box from '@mui/material/Box';
import CookieNote from "../../Components/CookieNote";
import { useLocation } from "react-router-dom";
import LoginForm from "./Components/LoginForm";
import { useAuth } from "../../Auth/AuthProvider";
import LoginOptions from "./Components/LoginOptions";
import InfoModal from "../../Components/Modals/InfoModal";
import Languages from "../../translations";
import { ErrorHandling/*, mapStatusToText, ERROR_LEVEL*/ } from "../../Components/ErrorHandling";
import WomNotification from "../../Components/WomNotification/WomNotification.jsx";
import ContentBox from "../../Components/ContentBox";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardContent, Typography/*, Box*/ } from "@mui/material";
import DaisyFamily20201 from '../../images/Family-21.png';
import LegalLinks from "./Components/LegalLinks";
import LanguageBar from "./Components/LanguageBar"
import EsuomiFirstTime from "./Components/EsuomiFirstTime";
import NewUserLinkPasswordLoginOnly from "../NewUser/Components/NewUserLinkPasswordLoginOnly";
import { SESSION_KEYS } from "../../Contants";
import { SessionManager } from "../../Auth";
import VersionNumber from "./Components/VersionNumber";
import UpdateBrowserReminder from "../../Components/UpdateBrowserReminder/UpdateBrowserReminder.jsx";

const stylee = {

  media: {
    width: "50%",
    height: "50%",
    maxHeight: "150px",
    margin: "auto",
    backgroundColor: "#4377BA",
    /*borderColor: "#4377BA", 
    borderStyle: "solid"*/
  }
  ,
  media2: {
    height: 0,
    paddingTop: "56.25%",
    marginTop: "30",
    maxHeight: 20
  }
}

//const forgetLogin = () => {

// this.setState({ EsuomiParams: response.data })
// var esuomiForm = document.getElementById('esuomiSSOForm')
// esuomiForm.submit();
// console.log(this.state.EsuomiParams)
//};

//const firstTimeLogin = () => {
//Lähetä axios ja hae esuomiparametrit, ohjaudu sitten käyttäen näitä parametreja
//console.log("firstTimeLogin");
// var token = sessionStorage.getItem('clienttoken');
// let axiosConfig = { headers: { "Authorization": token } };

// axios.get("/Esuomi/", axiosConfig)
//   .then(response => {
//     this.setState({ EsuomiParams: response.data })
//     var esuomiForm = document.getElementById('esuomiSSOForm')
//     esuomiForm.submit();
//     console.log(this.state.EsuomiParams)
//   })
//   .catch(error => {
//     console.log("getEsuomiParams get getEsuomiParams ERROR: ", error, "\n" +
//       " response.status: ", error.response === undefined ? 502 : error.response.status === undefined ? 502 : error.response.status);
//     this.Response = error.response === undefined ? 502 : error.response.status === undefined ? 502 : error.response.status;
//   });
//};

export const modes = {
  password: "password",
  strongOnly: "strongOnly",
  passwordAndStrong: "passwordAndStrong",
  notSet: "notSet",
}

// TODO: move to hooks
const useForceUpdate = () => {
  const [, setIt] = useState(false);
  return () => setIt(it => !it);
};

const travelState = (state) => {
  const errors = [];
  return errors;
}

const setCookieNoteOff = () => {
  localStorage.setItem('cookieNote', "off");
  localStorage.setItem("cookieNoteVersion", sessionStorage.getItem("WebAppVersion"));
  //console.log("setCookieNoteOff, cookieNote is now: ", localStorage.getItem("cookieNote"));
  //console.log("setCookieNoteOff, cookieNoteVersion is now: ", localStorage.getItem("cookieNoteVersion"));
}

const LoadingModal = () => (
  <InfoModal headerText={Languages.generalLoadingTitle} modalOpen={true}>
    {Languages.loginLoadingData}
  </InfoModal>
);

export const resolveAuthOptions = (loginSettings) => {
  if (loginSettings.Password && loginSettings.StrongIdentification) {
    return modes.passwordAndStrong;
  }
  else if (!loginSettings.Password && loginSettings.StrongIdentification) {
    return modes.strongOnly;
  }
  else if (!loginSettings.Password && !loginSettings.StrongIdentification) {
    // Show error, no login configured
    return modes.notSet;
  }

  return modes.password;
}

const getLastLocation = () => {
  const persistedLocation = sessionStorage.getItem(SESSION_KEYS.lastLocation);
  if (persistedLocation) {
    return persistedLocation;
  }

  return "/"
}

const LoginWrapper = ({onSetLanguage, children, showUpdateBrowserReminder}) => (
  <ErrorHandling fnStateTraveler={travelState}>
    <div className="Login textCenter" style={{ margin: "auto" }}>
      <ContentBox hideHeader={true} header={Languages.welcometodaisyfamily}>
        <div>
        {showUpdateBrowserReminder ? <UpdateBrowserReminder/> : null}
        <Card 
          className="Tulevaisuuden varhaiskasvatus"
          style={{ borderColor: "#4377BA", borderWidth: '10px', borderStyle: "solid", backgroundColor: "#ffffff"}}
        >
          <div style={{minHeight:"15px", backgroundColor: "#4377BA"}}></div>
          {/*<Typography style={{marginBottom: "15px"}} variant="h3" color="textSecondary">DaisyFamily</Typography>*/}
          <div style={{backgroundColor: "#4377BA"}}>
          <CardMedia className="DaisyFamilyLogo"
            component="img"
            style={stylee.media}
            image={DaisyFamily20201}
            title="Logo" />
          </div>
          <div style={{minHeight:"20px", backgroundColor: "#4377BA"}}></div>
          <Card  
          style={{ backgroundColor: "#ffffff"}}>
          <CardContent>
            <Typography style={{ textAlign: "center" }} color="textSecondary"> {Languages.welcometodaisyfamily2} </Typography>
            <Typography color="textSecondary" className="Login-header textCenter" id="test_LoginGeneralInfo_Txt">{Languages.loginGeneralInfo}</Typography>
            <WomNotification />
            <div style={{minHeight:"10px"}}></div>
            {children}
          </CardContent>
        </Card>
        </Card>
        <LegalLinks />
        <LanguageBar onSetLanguage={onSetLanguage} />
        <VersionNumber />
        </div>
      </ContentBox>
    </div>
  </ErrorHandling>
    
);

const Login = ({ history }) => {
  const { authActions: { login, samlLogin, samlNewUserLogin }, isAuthenticated, loginSettings, loading: authLoading } = useAuth();

  // Store user selected authentication. So we don't reset always to authentication selection view
  const [authSelection, setAuthSelection] = useState();
  const [cookieAccepted, setCookieAccepted] = useState(false);

  const forceUpdate = useForceUpdate();
  const [authSetting, setAuthSetting] = useState(modes.notSet);
  // const location = useLocation();

  useEffect(() => {
    if (!authLoading) {
      const authOpt = resolveAuthOptions(loginSettings);
      console.log(loginSettings);
      if (!authSelection) {
        // Don't update user selection on backend updates
        setAuthSelection(authOpt);
      }

      setAuthSetting(authOpt);
    }
  }, [authLoading, loginSettings]);

  useEffect(() => {
    if (localStorage.getItem('cookieNote') === "on") {
      setCookieAccepted(false);
    } else {
      setCookieAccepted(true);
    }
  }, [])

  useEffect(() => {
    if (isAuthenticated) {
      const path = getLastLocation();
      console.log("Successfully logged in redirecting to ", path);
      history.replace(path);
    }
  }, [isAuthenticated])

  const setLanguage = (lang) => {
    Languages.setLanguage(lang);
    SessionManager.setUserLanguage(lang);
    forceUpdate();
  };

  const doPasswordLogin = (username, password) => {
    login({ username, password });
  };

  const onCookieAccepted = () => {
    setCookieNoteOff();
    setCookieAccepted(true);
  }

  if (authLoading && authSetting === modes.notSet) {
    return (
      <LoginWrapper>
        <LoadingModal />
      </LoginWrapper>
    );
  }

  if (!cookieAccepted) {
    return (
      <LoginWrapper onSetLanguage={setLanguage}>
        <Box paddingBottom={5}>
          <CookieNote onCookieAccept={onCookieAccepted} cookiesAccepted={cookieAccepted} />
        </Box>
      </LoginWrapper>
    )
  }

  if (!authSelection || authSelection === modes.passwordAndStrong || authSetting === modes.strongOnly) {
    return (
      <LoginWrapper onSetLanguage={setLanguage} showUpdateBrowserReminder={true}>
        <LoginOptions
          onStrongLogin={samlLogin}
          onPasswordLoginSelect={() => setAuthSelection(modes.password)}
          authSettings={authSetting}
          onSetLanguage={setLanguage}
          settingsStatus={authLoading}
          cookiesAccepted={cookieAccepted}
          onCookieAccept={onCookieAccepted}
        />
        <EsuomiFirstTime authOptions={authSetting} onLogin={samlLogin} />
      </LoginWrapper>
    );
  }

  // Settings loaded but settings is not set. Error is occured. Show error dialog
  if (authSetting === modes.notSet) {
    <LoginWrapper onSetLanguage={setLanguage} showUpdateBrowserReminder={true}>
      <InfoModal headerText={Languages.error} allowClose={false}>
        {Languages.errorLoginOptionsNotFound}
      </InfoModal>
    </LoginWrapper>
  }

  if (authSelection === modes.password) {
    return (
      <LoginWrapper onSetLanguage={setLanguage} showUpdateBrowserReminder={true}>
        <LoginForm
          history={history}
          onLogin={doPasswordLogin}
          onSetLanguage={setLanguage}
          cookiesAccepted={cookieAccepted}
          onCookieAccept={onCookieAccepted}
        />
        {loginSettings.StrongIdentification == false && 
        <div data-testid="NewUserLink-shown-j39sn3lp"><NewUserLinkPasswordLoginOnly /></div>
        }
        {loginSettings.StrongIdentification == true &&
          <EsuomiFirstTime authOptions={authSetting} onLogin={samlLogin} />
        }
      </LoginWrapper>
    )
  }

  console.log("Something wrong... We should not hit here");

  return null;

}

export default Login;
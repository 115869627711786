import React from "react";
import "./sendnewmessage.css";
import Languages            from "../../../translations";
import TextField            from "@mui/material/TextField";
import Typography           from "@mui/material/Typography";
import { ThemeProvider, StyledEngineProvider, createTheme, adaptV4Theme } from "@mui/material/styles";

const MessageBodyField = ({handleMsgBodyChange, tempSavedMessageContent, 
   numberOfCharacters, sendTitleLength, titleMissing}) => {

    const theme2 = createTheme(adaptV4Theme({
        palette: {
          text: {
           // textPrimary: "#BBBB99",
          }
        }
      }))

    if (
      typeof handleMsgBodyChange !== "undefined" && handleMsgBodyChange !== null &&
      typeof tempSavedMessageContent !== "undefined" && tempSavedMessageContent !== null &&
      typeof numberOfCharacters !== "undefined" && numberOfCharacters !== null &&
      typeof sendTitleLength !== "undefined" && sendTitleLength !== null &&
      typeof titleMissing !== "undefined" && titleMissing !== null
    ) {

      return (
        <div style={{marginLeft:"10px", marginRight:"10px"}}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme2}>
              <Typography
              component={'span'} 
              variant={'body2'}
              color="textSecondary">
              <TextField
                required
                id="test_NewMsg_MsgBody_Txt"
                label={Languages.msgNewMsgBodyInfo}
                multiline
                maxRows="7"
                className=""
                margin="normal"
                onChange={handleMsgBodyChange}
                type="textarea"
                fullWidth
                defaultValue={tempSavedMessageContent}
                alt="message input field"
              />
              </Typography>
            </ThemeProvider>
          </StyledEngineProvider>
  
          <p className={numberOfCharacters > 5000 ? "countertextred2" : "countertext2"}>{numberOfCharacters} / 5000</p> 
                  
          <p>{sendTitleLength === 0 && titleMissing === true ? Languages.msgTitleMissing : ""}</p>
        </div>
      );
    }
    else {
      return(
        <div data-testid="MessageBodyField-undefined-or-null"></div>
      );
    }
}

export default MessageBodyField;
import { types } from './actionTypes';

const ApprovalReducer = (state, action) => {
  const payload = action.data
  


  switch (action.type) {
    
    case types.SAVING_APPROVAL:
      console.log('APPROVAL REDUCER. SAVING APPROVAL');
      return {
        ...state,
        state: types.SAVING_APPROVAL
      }
    
    case types.APPROVAL_SAVED_FAILED:
      console.log('APPROVAL REDUCER. APPROVAL_SAVED_FAILED payload: ', payload);
      return {
        ...state,
        Code: payload.Code,
        state: types.APPROVAL_SAVED_FAILED
      }
    case types.APPROVAL_SAVED_OK:
      
      console.log('APPROVAL REDUCER. APPROVAL_SAVED_OK payload: ', payload);
      return {
        ...state,
        Code: 200,
        state: types.APPROVAL_SAVED_OK,
        
      }
    case types.APPROVAL_HANDLED:
        return {
            ...state,
            state: types.APPROVAL_HANDLED,
            }
    default: return state;
  }
}

export default ApprovalReducer;
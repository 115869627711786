import React from "react";
import "./sendnewmessage.css";
import Languages            from "../../../translations";
import Button               from "@mui/material/Button";
import Grid                 from "@mui/material/Grid";


const MsgSendButton = ({numberOfCharacters, sendTitleLength, postNewMessageState, sendMessagePreCheck}) => {

  if (
    typeof numberOfCharacters !== "undefined" && numberOfCharacters !== null &&
    typeof sendTitleLength !== "undefined" && sendTitleLength !== null &&
    typeof postNewMessageState !== "undefined" && postNewMessageState !== null &&
    typeof sendMessagePreCheck !== "undefined" && sendMessagePreCheck !== null 
  ) {
    
    return(
      <div className="newmessagesenddiv">           
         <Grid container spacing={8}>
           <Grid item xs={4}>
           </Grid>
           <Grid className="sngridright" item xs={8}>
               <Button 
                 id="test_NewMsg_Send_Btn"
                 variant="contained" 
                 disabled={postNewMessageState === "WAITING_POST_NEW_MESSAGE" ||
                               numberOfCharacters > 5000 || 
                                 numberOfCharacters === 0 ||
                                   sendTitleLength === 0 || 
                                     sendTitleLength > 200} 
                 color="secondary" 
                 alt="send message" 
                 onClick={sendMessagePreCheck}>{Languages.msgSend}
               </Button>
             </Grid>
         </Grid>
      </div>
    );
  }

  else {
    return(
      <div data-testid="MsgSendButton-undefined-or-null"></div>
    );
  }

}

export default MsgSendButton;
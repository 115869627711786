import React, { Component } from "react";
import "./answermessage.css";
import Languages from "../../../translations";
import TextField from "@mui/material/TextField";
import Fab from "@mui/material/Fab";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
  adaptV4Theme,
} from "@mui/material/styles"; //EMMITHEME
import { StateContext } from "../../../State";
import { containsMoreThanEmptySpaces } from "../../../utils";

const theme2 = createTheme(
  adaptV4Theme({
    // EMMITHEME
    palette: {
      common: {
        black: "#000",
        white: "#fff",
        blue: "#4377BA",
        green: "#81C784", // only for contrast with black
        lightblue: "#86B8FF",
        greenwithblackorwhite: "#508053", // enough contrast with black or white both
      },
      primary: {
        light: "rgba(225, 245, 254, 1)",
        main: "#86B8FF", //"#6E7599", //"#0D1B2E",//'#000000', //"#000001", //"#6E7599",
        dark: "rgba(2, 136, 209, 1)",
        contrastText: "#ffffff",
      },
      secondary: {
        light: "#ff4081",
        main: "#4377BA", // "#4377BA",//'#4377BA',
        dark: "#315687", //'#86B8FF',
        contrastText: "#fff",
      },
      text: {
        textPrimary: "#000000",
      },
    },
  })
);

class AnswerMessage extends Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);
    this.state = {
      content: "",
      messagetobesent: "",
      sentButtonPressed: false,
      numberOfCharacters: 0,
      backupContent: "", // pitäisikö tämä laittaa yleiseen stateen?,
      keepBackup: false,
      characterLimitWarning: "",
    };
    this.strings = Languages;
  }

  componentDidMount() {}

  componentDidUpdate() {
    const { latestApiRequestState } = this.context.state.messaging;

    if (this.state.keepBackup === true) {
      if (
        latestApiRequestState.state === "REPLYTO_NONMASS_THREAD_SUCCESS" ||
        latestApiRequestState.state === "REPLYTO_MASS_THREAD_SUCCESS"
      ) {
        this.setState({ keepBackup: false, content: "" });
      }
    }

    /*
  if (this.props.MsgError !== "") {
    const cnt = this.state.backupcontent;
    this.setState({content: cnt});
    this.props.clearMsgError();
  }*/
  }

  handleChange = (event) => {
    if (this.state.numberOfCharacters < 5001) {
      this.setState({
        content: event.target.value,
        numberOfCharacters: event.target.value.length,
        characterLimitWarning: "",
        backupContent: "",
      });
    } else {
      this.setState({
        content: event.target.value,
        numberOfCharacters: event.target.value.length,
        backupContent: "",
      });
    }
  };

  sendMessage = () => {
    const { loadThread } = this.context.state.messaging;

    // Has an employee flagged the original message as not allowing replies
    if (loadThread.DenyReply === false) {
      // let's not send an empty message with no content
      const containsMoreThanJustEmptySpaces = containsMoreThanEmptySpaces(
        this.state.content
      );
      // this.state.content value is false if it is an empty string (length is zero)
      if (
        this.state.numberOfCharacters < 5001 &&
        this.state.content &&
        containsMoreThanJustEmptySpaces
      ) {
        if (loadThread.MassThread) {
          const datatobesent = {
            Content: this.state.content,
            ThreadId: null,
            SplitThreadId: this.props.threadId,
          };

          this.context.actions.triggerReplyTo_MASS_thread(datatobesent);

          this.setState({
            numberOfCharacters: 0,
            keepBackup: true,
          });
        } else {
          const datatobesent = {
            Content: this.state.content,
            ThreadId: this.props.threadId,
          };

          this.context.actions.triggerReplyTo_NONMASS_thread(datatobesent);
          //this.context.actions.triggerUpdateMessageThreadId(this.props.threadId);

          this.setState({
            numberOfCharacters: 0,
            keepBackup: true,
          });
        }

        this.context.actions.triggerUpdateDoNotLoadInboxVariable(false);
      } else {
        this.setState({
          characterLimitWarning: this.strings.msgCharacterLimitWarning,
        });
      }
    } else {
      this.setState({ characterLimitWarning: this.strings.msgDenyReply });
    }
  };

  getErrorString = () => {
    const { loadThread } = this.context.state.messaging;
    console.log("getErrorString");

    if (loadThread.Code === 502) {
      return Languages.errorNetwork;
    }

    if (loadThread.Code === 500) {
      return Languages.errorInDB;
    }

    if (loadThread.Code === 404) {
      return Languages.errorNotFound;
    }

    return Languages.errorUnknown;
  };

  render() {
    const { latestApiRequestState, loadThread } = this.context.state.messaging;

    return (
      <div>
        <div className="answerbox2">
          <div>
            <Grid container>
              <Grid item xs={1}></Grid>
              <Grid item xs={9}>
                <StyledEngineProvider injectFirst>
                  <ThemeProvider theme={theme2}>
                    {" "}
                    {/* EMMITHEME */}
                    <Typography component={"span"} variant={"body2"}>
                      <TextField
                        color="secondary"
                        variant="outlined"
                        size="small"
                        autoFocus={false}
                        aria-label="Text field for message thread reply"
                        id="test_ReplyMsg_MsgBody_Txt"
                        value={
                          typeof this.state.content !== "undefined"
                            ? this.state.content
                            : ""
                        }
                        label={
                          loadThread.DenyReply
                            ? this.strings.msgDenyReply
                            : this.strings.msgReplyToMessageThread
                        }
                        multiline
                        rows="2"
                        disabled={loadThread.DenyReply ? true : false}
                        margin="normal"
                        onChange={this.handleChange}
                        helperText={
                          this.state.numberOfCharacters > 5000 ? (
                            <span className="countertextred1">
                              {this.state.numberOfCharacters +
                                " / 5000 " +
                                (this.props.massThreadFlag ? "." : "") +
                                "!!!"}
                            </span>
                          ) : (
                            this.state.numberOfCharacters +
                            " / 5000" +
                            (this.props.massThreadFlag ? "." : "")
                          )
                        }
                        fullWidth
                        alt="message input field"
                      />
                    </Typography>
                  </ThemeProvider>
                </StyledEngineProvider>
              </Grid>
              <Grid item xs={2}>
                <div className="replyFab" display="inline-block">
                  {loadThread.state === "LOAD_MESSAGE_THREAD_SUCCESS" &&
                  latestApiRequestState.state !==
                    "WAITING_REPLYTO_MASS_THREAD" &&
                  latestApiRequestState.state !==
                    "WAITING_REPLYTO_NONMASS_THREAD" ? (
                    <Fab
                      id="test_ReplyMsg_MsgBody_Btn"
                      style={{ backgroundColor: "#81C784", color: "#000000" }}
                      size="medium"
                      disabled={
                        loadThread.DenyReply ||
                        this.state.numberOfCharacters > 5000
                          ? true
                          : false
                      }
                      onClick={() => this.sendMessage()}
                      aria-label="Reply to thread"
                    >
                      <ChevronRight />
                    </Fab>
                  ) : null}
                  {loadThread.state === "LOAD_MESSAGE_THREAD_FAILED" ? (
                    <p>{loadThread.Code + " " + this.getErrorString()}</p>
                  ) : null}
                  {latestApiRequestState.state ===
                    "WAITING_REPLYTO_MASS_THREAD" ||
                  latestApiRequestState.state ===
                    "WAITING_REPLYTO_NONMASS_THREAD" ? (
                    <CircularProgress color="primary" />
                  ) : null}
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

export default AnswerMessage;

import React, { useState, useEffect } from "react";
import {useStateValue} from "../../../State";
import "../vasu.css";

import Moment from 'moment';

import CommentsReadOnly from '../CommentsReadOnly';

import ContentBoxMUI  from "../../../Components/ContentBoxMUI";
import Languages      from "../../../translations";

import SwipeableViews     from 'react-swipeable-views';
import Divider        from '@mui/material/Divider';
import { Typography }    from "@mui/material";
import MobileStepper  from '@mui/material/MobileStepper';
import Button         from '@mui/material/Button';
import Box              from '@mui/material/Box'
import KeyboardArrowLeft  from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
// import Link             from '@mui/material/Link'

const DescriptionSubPart = (props) => {

  const { SelectedSlideIndex, vasu } = props;

  const { state/*, actions*/ } = useStateValue();
  const Data        = vasu ? state.vasu : state.leops;
  const TableData   = vasu ? state.vasu.Table3.Data : state.leops.Table5.Data;
  
  const [SlideIndex, setSlideIndex]       = useState(SelectedSlideIndex);
  const [MaxSlideCount, setMaxSlideCount] = useState(3);
  const [Title, setTitleText]             = useState(null);
  
  var Titles = [Languages.ChildStrengthsAndIterests,
                Languages.ChildWellbeingAndLearningObjectives,
                Languages.OtherExtraInfo + "/\n" + Languages.OtherImportanInfo]; 
  //console.log("DescriptionSubPart. MOUNT TableData: ", TableData);

  useEffect( () => {
    //console.log("VasuPreparingSubPart. useEffect. SlideIndex: "+SlideIndex+"\n Table1: ", Table1);
    setTitleText(TitleText(SlideIndex));
  },[SlideIndex]);
  
  const handleChangeIndex = (e, index) => {
    //console.log("VasuDescriptionSubPart. Handle swipe. uusi slide: ",e," edellinen : ",index);
    setSlideIndex(e);
  };
  
  const handlePrev = () =>
  {
    var index = SlideIndex;
    if (SlideIndex > 0)
    {
      index--;
      setSlideIndex(index);
    }
  };

  const handleNext = (maxCount) =>
  {
    var index = SlideIndex;
    if (SlideIndex < (MaxSlideCount-1))
    {
      index++;
      setSlideIndex(index);
    }
  };

  const SlideText = () => {
    let slides = [{Text: ""}, 
                  {Text: ""},
                  {Text: ""}];
    return slides;
  };
  
  const TitleText = (Index) => {
    let Title = Titles[Index];
    return Title;
  };

  if (Data.state === "INITIAL_STATE")
  {
    return (
      <div className="DescriptionSubPart"></div>
    );
  }
  else 
  {
    // tässä määritetään sliden väliotsikko teksti, jos tarpeen
    let slides = SlideText();
    return (
      <div className="DescriptionSubPart">
       <Box>

        <SwipeableViews className     = {"col-12 textCenter slide pt-3" }
                        index         = {SlideIndex} 
                        key           = {SlideIndex}
                        onChangeIndex = {handleChangeIndex} >
          {slides.map((slide, index) => { 
            return ( 
              <div key={index}>
                <Box  className="br-top col-12" sx={{ backgroundColor: "#fbcece"}}>
                  <Typography color="textSecondary" sx={{textAlign:"center", fontWeight: 'Bold', fontSize: 18, color: "#424242", pt:1.5}}>{Title}</Typography>
                  <MobileStepper style={{backgroundColor: '#fbcece', color: "black"}}
                                //variant="progress" <= viiva
                                variant="text"
                                steps={MaxSlideCount}
                                position="static"
                                //LinearProgressProps={{width: "100%"}}
                                activeStep={SlideIndex}
                                //className={classes.root}
                                nextButton={ <Button size="small" style={{color: "black", opacity: SlideIndex === (MaxSlideCount-1) ? "0.5" : "" }} onClick={handleNext} disabled={SlideIndex === (MaxSlideCount-1)}>{Languages.next} <KeyboardArrowRight /> </Button> }
                                backButton={ <Button size="small" style={{color: "black", opacity: SlideIndex === 0 ? "0.5" : ""}} onClick={handlePrev} disabled={SlideIndex === 0}> <KeyboardArrowLeft /> {Languages.prev}  </Button> }/>
                </Box>
                <Box className="br-btm col-12" sx={{boxShadow: 2, mb: 3, pb: 1, backgroundColor: "white"}}>
                    <Typography color="textSecondary" sx={{textAlign:"center", fontWeight: 'Medium', fontSize: 16, color: "#424242", pt:1}}>{slide.Text}</Typography>
                </Box>
              </div>
            );                  
          })}
        </SwipeableViews>

        {TableData[SlideIndex].StaffNotes.map((note, nindex) => {
          return (
            <div key={nindex}>
              <Box  className="col-12">
                <CommentsReadOnly Title1  = {nindex === 0 ? <Typography color="textSecondary" sx={{textAlign:"left", fontWeight: 'Bold', fontSize: 16, color: "#424242", pt:1, ml:1}}>{Languages.StaffNotes} </Typography> : null} 
                                  Title2  = {null}
                                  Title3  = {null}
                                  BgColor = {'#fbcece'} 
                                  Data    = {{Text: note.Text, /* Saved: Moment(note.Saved).format('DD.MM.YYYY')*/}}>
                </CommentsReadOnly>
              </Box>
            </div>
          );
        })}
      </Box>
    </div>
  );
  }
}

export default DescriptionSubPart;
import React from 'react';
import { withRouter } from 'react-router-dom';
import ChatBubble       from '@mui/icons-material/ChatBubbleRounded';
import EventNote        from '@mui/icons-material/EventNoteRounded';
import Badge            from "@mui/material/Badge";
import Fab              from '@mui/material/Fab';
import { useStateValue } from '../../State';
import LiveHelp         from "@mui/icons-material/LiveHelp";

const FloatingNav = ({notReadThreads, history}) => {
  const { state } = useStateValue();
  const { listChildNursingTimeStatus } = state.mainstats;
  const { mainstats } = state;
  return (
    <div className="fixed-bottom" style={{left:"auto"}}>
      <div id="test_FloatNav" className="float-right">
      <Fab id="test_Childlist_PermissionsBtn" color="secondary" aria-label="Permissions" className="fab" style={{ marginTop: "16px", marginBottom: "16px" }} onClick={() => history.push("/permissions")}>
          <Badge badgeContent={!mainstats.TotalUnansweredPermissions ? null : mainstats.TotalUnansweredPermissions} color="error">
            <LiveHelp />
          </Badge>
        </Fab>

        <Fab id="test_Childlist_MessagingBtn" color="secondary" aria-label="Messaging" className="fab" style={{ margin: "16px" }} onClick={() => history.push("/messaging")}>
          <Badge badgeContent={notReadThreads} color="error">
            <ChatBubble />
          </Badge>
        </Fab>
        <Fab id="test_Childlist_CalendarBtn" style={{backgroundColor: listChildNursingTimeStatus !== undefined && listChildNursingTimeStatus.length > 0 ? "#D500F9" : ""}} color="secondary" aria-label="Reservations" className="fab" onClick={() => history.push("/nursingtimes",{readNursingTimes: true})}>
          <EventNote></EventNote>
        </Fab>
      </div>
    </div>
  );
}

export default withRouter(FloatingNav);
import React from "react";
import BackToUpButton from "../GuideTools/BackToUpButton";
import AddAlarmIcon from "@mui/icons-material/AddAlarm";
import Languages from "../../../translations";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import Box from "@mui/material/Box";
const DefaultTimeAccordionText2 = () => {
  return (
    <div>
      <br />
      <p className="guide-accordion-details-texts">
        <SettingsIcon />
        {
          " Hoitoaikakalenterin asetuksissa voit määritellä kalenterissa näytettävät lapset, oletushoitoajan sekä kellonajan merkitsemistavan."
        }
        <br />
        <br />
        {
          "”Näytettävät lapset” valikossa merkitse lapset, jotka näytetään kalenterissa ja varauksia tehdessä. Kaikki perheen lapset ovat oletuksena valittuna ja valinta palautuu tähän oletusvalintaan poistuttuasi hoitoaikakalenterista."
        }
        <br />
        <br />
        {
          "”Oletushoitoaika” valikossa voit asettaa oletushoitoajan, jota ohjelma tarjoaa tehdessäsi varauksia. Asetus helpottaa varausten tekemistä, mikäli käytätte usein samaa hoitoaikaa."
        }
      </p>

      <br />
      <br />
      <BackToUpButton />

      <h4>{"Kalenterin kuvakkeet"}</h4>
      <div className="guide-table-container">
        <table className="booking-guide-color-schema-table">
          <thead>
            <tr>
              <th className="table-header-for-colors">{Languages.color}</th>
              <th className="table-header-for-description">
                {Languages.description}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="white-color-cell"></td>
              <td className="booking-table-description-cell">
                {
                  "Hoitovarauksia voit tehdä tulevaisuuden päiville, jotka näkyvät varauskalenterissa valkoisella pohjalla"
                }
              </td>
            </tr>
            <tr>
              <td className="locked-color-cell"></td>
              <td className="booking-table-description-cell">
                {
                  "Lukittu päivä: hoitovaraukset ovat lukittuneet, jolloin päivälle voi ilmoittaa vain poissaolon."
                }
              </td>
            </tr>
            <tr>
              <td className="vacation-color-cell"></td>
              <td className="booking-table-description-cell">
                {
                  "Varausten tekeminen on mahdollista kalenterissa myös vaaleanpunaiselle yleiselle loma-ajalle..."
                }
              </td>
            </tr>
            <tr>
              <td className="Period-color-cell"></td>
              <td className="booking-table-description-cell">
                {
                  "… ja oranssille päivystysajalle, jos varausaika ei ole vielä lukkiutunut."
                }
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <h4>{"Kalenterin Symbolit"}</h4>
      <div className="guide-table-container">
        <table className="booking-guide-color-schema-table">
          <thead>
            <tr>
              <th className="table-header-for-colors">{"Symboli"}</th>
              <th className="table-header-for-description">
                {Languages.description}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="booking-table-description-cell">
                {<SettingsIcon />}
              </td>
              <td className="booking-table-description-cell">
                {
                  "Asetukset. Voit määritellä kalenterissa näytettävät lapset, oletushoitoajan sekä kellonajan merkitsemistavan."
                }
              </td>
            </tr>
            <tr>
              <td className="booking-table-description-cell">
                {<CalendarMonthIcon />}
              </td>
              <td className="booking-table-description-cell">
                {
                  "Kalenteri-painikkeesta näet varausten ja poissaolojen tilanteen kuukausitasolla. Voit myös siirtyä näkymässä haluamasi päivän kohdalle painamalla päivämäärästä."
                }
              </td>
            </tr>
            <tr>
              <td className="booking-table-description-cell">
                {<EditCalendarIcon />}
              </td>
              <td className="booking-table-description-cell">
                {
                  "Muokkauspainikkeesta voit lisätä uudet varaukset ja poissaolot sekä  tehdä niiden muokkaukset ja poistot."
                }
              </td>
            </tr>
            <tr>
              <td className="booking-table-description-cell">
                {<SummarizeOutlinedIcon />}
              </td>
              <td className="booking-table-description-cell">
                {
                  "Käytettävissä oleva hoitoaika. Näet lapsen hoidontarpeen, maksuluokan sekä käytetyn hoitoajan (varaukset + varauksen ylittänyt hoitoaika). Voit myös tarkastella kuukausikohtaista laskutusperustetta."
                }
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DefaultTimeAccordionText2;

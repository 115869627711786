import React, {useEffect, useState} from "react"
import { useStateValue } from "../../State"
import Box from '@mui/material/Box'
import SpeedDial from '@mui/material/SpeedDial'
import SpeedDialIcon from '@mui/material/SpeedDialIcon'
import SpeedDialAction from '@mui/material/SpeedDialAction'
import Backdrop from '@mui/material/Backdrop'
import Avatar from '@mui/material/Avatar'
import ChildProfilePicture from "../ChildProfilePicture"
import {types} from "../../Reducers/actionTypes"
import { history } from "../../Router"
import LockIcon from '@mui/icons-material/Lock'
import './discussionCard.css'
import Languages from "../../translations"

const DiscussionSpeedDial = ({}) =>{
  const {state, actions} = useStateValue()
  const [open, setOpen] = useState(false)
  const {children} = state;
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const data = state.discussion.discussionTimes.data
  const status =state.discussion.discussionTimes.status

  const getChildName = (childId) => {
    const child = children.ChildList.find(x => x.Id == childId);
    if (typeof child === "undefined") {
      return("");
    }
    return child.FirstName;
  }

  const bookDiscussion = (childId) => {
    history.push({pathname:"/bookDiscussion", 
    state:
     {child: childId}});
  }

  if (status === types.LOAD_DISCUSSION_TIMES_SUCCESS){
    return(
    <Box sx={{ height: 320, flexGrow: 1,position:"fixed",zIndex:10, right: 0, bottom: 0}}>
        <Backdrop open={open} sx={{zIndex: 1000}}/>
        <SpeedDial
            ariaLabel="SpeedDial basic example"
            sx={{ position: 'absolute', bottom: 16, right: 16, }}
            icon={<SpeedDialIcon />}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
        >
          {data.ChildDiscussions.map((child, index)=>{
            if(child.BookableTimesAvailable){
              return <SpeedDialAction
              key={child.ChildId}
              icon={<ChildProfilePicture childId={child.ChildId}/>}
              tooltipTitle={getChildName(child.ChildId)}
              onClick={()=>bookDiscussion(child.ChildId)}
            /> 
          }
            return <SpeedDialAction
              key={child.ChildId}
              icon={<LockedAvatar><ChildProfilePicture childId={child.ChildId}/></LockedAvatar>}
              tooltipTitle={Languages.discNoTimesToBeBooked(getChildName(child.ChildId))}
            />
          })}
          
      </SpeedDial>
    </Box>
    )
  }
  return null
}
export default DiscussionSpeedDial

const LockedAvatar = ({children})=>{
  const lock = {position:'absolute'}
  return (
    <Avatar className="avatarContainer">
      {children}
      <LockIcon className="lockIcon" />
    </Avatar>
    )
}
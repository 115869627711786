import React from "react";
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ArticleIcon from '@mui/icons-material/Article';
import MessagingFileDownload from "../MessagingFileDownload";



const WordListItem = ( { attachmentData } ) => {
  return(
    <>
      <ListItemIcon><ArticleIcon sx={{ fontSize: 30, color: "white" }}/></ListItemIcon> 
      <ListItemText sx={{width: "50%", overflow: "hidden", color: "white"}}>{attachmentData.FileName}</ListItemText>
      <MessagingFileDownload 
          compactMode={true}
          FileName={attachmentData.FileName}
          FileMimeType={attachmentData.MimeType}
          FileId={attachmentData.Id}
      />
    </>
  )
}


export default WordListItem
import React, { useEffect } from "react";
import { useStateValue } from "../../State";
import { history } from "../../Router";
import Tooltip from "@mui/material/Tooltip";
import { types } from "../../Reducers/actionTypes";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FollowTheSignsIcon from "@mui/icons-material/FollowTheSigns";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import GradingIcon from "@mui/icons-material/Grading";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AddCommentIcon from "@mui/icons-material/AddComment";
import moment from "moment";
import "./actionview.css";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ReplayCircleFilledIcon from "@mui/icons-material/ReplayCircleFilled";
import Languages from "../../translations";

export const MessageNotificationContent = ({
  messages,
  errorCode,
  messagesStatus,
  openMessageThread,
}) => {
  switch (messagesStatus) {
    case "LOAD_MESSAGES_FAILED":
      <p>{errorCode}</p>;
    case "NEVER_LOADED":
      <CircularProgress sx={{ color: "#EE5711" }} />;
    case "LOADING_MESSAGES":
      <CircularProgress sx={{ color: "#EE5711" }} />;

    case "LOAD_MESSAGES_SUCCESS":
      if (typeof messages === "undefined" || messages === null) {
        return <CircularProgress sx={{ color: "#EE5711" }} />;
      } else {
        if (messages.length === 0) {
          return <p>{Languages.msgNoMessagesInfo}!</p>;
        } else {
          return messages.map((newMessage) => {
            if (newMessage.CountNotReadMessages > 0) {
              return (
                <ListItem
                  sx={{ borderBottom: "1px solid grey" }}
                  onClick={() => openMessageThread(newMessage.ThreadId)}
                  button
                  key={newMessage.ThreadId}
                  secondaryAction={
                    <ChevronRightIcon sx={{ color: "#EE5711" }} />
                  }
                >
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: "#EE5711" }}>
                      <FiberNewIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <b style={{ color: "black" }}>{newMessage.Title}</b>
                    }
                    secondary={
                      <i>
                        {newMessage.MessageSender.Name} &nbsp; - &nbsp;{" "}
                        {moment(newMessage.NewestSaved).format("llll")}
                      </i>
                    }
                  />
                </ListItem>
              );
            }
          });
        }
      }
    default:
      return <p>{Languages.msgCouldNotBeLoaded}</p>;
  }
};

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const MessageNotifications = ({ onClose }) => {
  const [expanded, setExpanded] = React.useState(false);
  const { state, actions } = useStateValue();
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    React.useState(false);
  const [allMessagesDeleted, setAllMessagesDeleted] = React.useState(false);
  const [markingAllRead, setMarkingAllRead] = React.useState(false);
  const [markingAllError, setMarkingAllError] = React.useState(false);
  const [dismish, setDismish] = React.useState(false);

  const { messaging } = state;

  useEffect(() => {
    if (markingAllRead) {
      if (
        state.messaging.setAllMessagesRead.status ===
        types.SET_ALL_MESSAGES_READ_SUCCESS
      ) {
        setMarkingAllRead(false);
        setAllMessagesDeleted(true);
        setMarkingAllError(false);
      }
      if (
        state.messaging.setAllMessagesRead.status ===
        types.SET_ALL_MESSAGES_READ_FAILED
      ) {
        setMarkingAllRead(false);
        setMarkingAllError(true);
      }
    }
  }, [
    actions,
    state.messaging.setAllMessagesRead.status,
    state.messaging.setAllMessagesRead.Code,
    markingAllRead,
    dismish,
  ]);

  const handleExpandClick = () => {
    if (expanded === false) {
      setExpanded(true);
      actions.triggerLoadMessages();
    }
    if (expanded === true) {
      setExpanded(false);
    }
  };

  const sendNewMessage = () => {
    if (history.location.pathname === "/messaging/newmessage") {
      onClose();
    } else {
      history.push("/messaging/newmessage");
    }
  };

  const handleRedirectToMessaging = () => {
    if (history.location.pathname === "/messaging") {
      onClose();
    } else {
      history.push("/messaging");
    }
  };

  const redirectToMessageThread = () => {
    history.push("/messaging/openmessage");
  };

  const openMessageThread = (props) => {
    if (history.location.pathname === "/messaging/openmessage") {
      actions.triggerUpdateMessageThreadId(props);
      window.history.back();
      redirectTimeOut = setTimeout(redirectToMessageThread, 1000);
    } else {
      actions.triggerUpdateMessageThreadId(props);
      history.push("/messaging/openmessage");
    }
  };

  const toggleDeleteConfirmation = () => {
    setShowDeleteConfirmation(!showDeleteConfirmation);
  };

  const clickAllMessagesRead = () => {
    actions.triggerSetAllMessagesRead();
    setExpanded(true);
    setMarkingAllRead(true);
    setShowDeleteConfirmation(!showDeleteConfirmation);
  };

  const retryMarkAllRead = () => {
    actions.triggerSetAllMessagesRead();
    setMarkingAllError(false);
    setMarkingAllRead(true);
  };

  const closeSuccessCard = () => {
    setDismish(true);
  };

  if (markingAllError) {
    return (
      <>
        <Divider textAlign="left">
          <Chip
            label={Languages.menuMessaging}
            sx={{ bgcolor: "#002E6D", textTransform: "uppercase" }}
          />
        </Divider>
        <Card
          sx={{
            bgcolor: "#F9E0E5",
            border: "1px solid #E18994",
            borderLeft: "7px solid #B30036",
            margin: "5px",
          }}
        >
          <CardHeader
            avatar={
              <Avatar
                sx={{ bgcolor: "#B30036" }}
                aria-label="Messaging Icon error"
              >
                <ErrorOutlineIcon />
              </Avatar>
            }
            action={
              <Tooltip title={Languages.awMove2Messaging} arrow>
                <IconButton
                  aria-label={Languages.awMove2Messaging}
                  onClick={handleRedirectToMessaging}
                >
                  <FollowTheSignsIcon sx={{ color: "#B30036" }} />
                </IconButton>
              </Tooltip>
            }
            title={
              <p
                style={{ color: "black", fontSize: "large", marginBottom: "0" }}
              >
                <b>{Languages.error}!</b>
              </p>
            }
            subheader={
              <>
                {Languages.errorChangingFailed}.<br />
                {Languages.errorCode}: {state.messaging.setAllMessagesRead.Code}{" "}
              </>
            }
          />
          <CardActions disableSpacing>
            <Tooltip title={Languages.retry} arrow>
              <IconButton
                aria-label={Languages.retry}
                onClick={retryMarkAllRead}
              >
                <ReplayCircleFilledIcon sx={{ color: "#B30036" }} />
              </IconButton>
            </Tooltip>
          </CardActions>
        </Card>
      </>
    );
  } else if (
    !allMessagesDeleted &&
    state.messaging.messageData.notReadThreads > 0
  ) {
    return (
      <>
        <Divider textAlign="left">
          <Chip
            label={Languages.menuMessaging}
            sx={{ bgcolor: "#002E6D", textTransform: "uppercase" }}
          />
        </Divider>
        <Card
          sx={{
            bgcolor: "#FFEBD4",
            border: "1px solid #EE5711",
            borderLeft: "7px solid #EE5711",
            margin: "5px",
          }}
        >
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: "#EE5711" }} aria-label="messaging icon">
                <ChatBubbleIcon />
              </Avatar>
            }
            action={
              <Tooltip title={Languages.awMove2Messaging} arrow>
                <IconButton
                  aria-label={Languages.awMove2Messaging}
                  onClick={handleRedirectToMessaging}
                >
                  <FollowTheSignsIcon sx={{ color: "#EE5711" }} />
                </IconButton>
              </Tooltip>
            }
            title={
              <p
                style={{ color: "black", fontSize: "large", marginBottom: "0" }}
              >
                <b>{Languages.awNewMessages}</b>
              </p>
            }
            subheader={
              <>
                {state.messaging.messageData.notReadThreads}&nbsp;
                {Languages.awNotReadMessages}
              </>
            }
          />
          <CardActions disableSpacing>
            {!showDeleteConfirmation ? (
              <>
                <Tooltip title={Languages.msgSend} arrow>
                  <IconButton
                    aria-label={Languages.msgSend}
                    onClick={sendNewMessage}
                  >
                    <AddCommentIcon sx={{ color: "#EE5711" }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title={Languages.msgMarkAllAsRead} arrow>
                  <IconButton
                    aria-label={Languages.msgMarkAllAsRead}
                    onClick={toggleDeleteConfirmation}
                  >
                    <GradingIcon sx={{ color: "#EE5711" }} />
                  </IconButton>
                </Tooltip>

                <ExpandMore
                  expand={expanded}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label={Languages.awShowMore}
                  title={Languages.awShowMore}
                >
                  <ExpandMoreIcon sx={{ color: "#EE5711" }} />
                </ExpandMore>
              </>
            ) : (
              <>
                <b style={{ color: "black" }}>
                  {Languages.msgMarkAllAsReadConfirmation}
                </b>
                &nbsp;
                <Button
                  size="small"
                  variant="outlined"
                  sx={{
                    color: "white",
                    bgcolor: "#006633",
                    borderColor: "#00572B",
                    "&:hover": {
                      backgroundColor: "#DCEEDF",
                      color: "black",
                      borderColor: "#00572B",
                    },
                  }}
                  onClick={clickAllMessagesRead}
                >
                  {Languages.ciDeleteYes}
                </Button>
                &nbsp;
                <Button
                  size="small"
                  variant="outlined"
                  sx={{
                    color: "#8F002B",
                    bgcolor: "#F9E0E5",
                    borderColor: "#8F002B",
                    "&:hover": {
                      backgroundColor: "#F9E0E5",
                      color: "#8F002B",
                      borderColor: "#F9E0E5",
                    },
                  }}
                  onClick={toggleDeleteConfirmation}
                >
                  {Languages.ciDeleteNo}
                </Button>
              </>
            )}
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              {markingAllRead === false ? (
                <List dense>
                  <MessageNotificationContent
                    messages={messaging.loadMessages.messages}
                    messagesStatus={messaging.loadMessages.state}
                    errorCode={messaging.loadMessages.Code}
                    openMessageThread={openMessageThread}
                  />
                </List>
              ) : (
                <div className=".sweeping-delete-loader-container">
                  <div className="sweeping-delete-loader1">
                    <MarkEmailReadIcon
                      fontSize="large"
                      sx={{ color: "#006633" }}
                    />
                  </div>
                  <div className="sweeping-delete-loader2">
                    <MarkEmailReadIcon
                      fontSize="large"
                      sx={{ color: "#006633" }}
                    />
                  </div>
                  <div className="sweeping-delete-loader3">
                    <MarkEmailReadIcon
                      fontSize="large"
                      sx={{ color: "#006633" }}
                    />
                  </div>
                  <Typography sx={{ color: "black" }}>
                    {Languages.permSaving}...
                  </Typography>
                </div>
              )}
            </CardContent>
          </Collapse>
        </Card>
      </>
    );
  } else if (allMessagesDeleted) {
    if (!dismish)
      return (
        <>
          <Divider textAlign="left">
            <Chip
              label={Languages.menuMessaging}
              sx={{ bgcolor: "#002E6D", textTransform: "uppercase" }}
            />
          </Divider>
          <Card
            sx={{
              bgcolor: "#DCEEDF",
              border: "1px solid #8EC5A9",
              borderLeft: "7px solid #006633",
              margin: "5px",
            }}
          >
            <CardHeader
              avatar={
                <Avatar
                  sx={{ bgcolor: "#006633" }}
                  aria-label="All marked as read icon"
                >
                  <MarkEmailReadIcon />
                </Avatar>
              }
              action={
                <Tooltip title={Languages.generalClose} arrow>
                  <IconButton
                    aria-label={Languages.generalClose}
                    onClick={closeSuccessCard}
                  >
                    <CloseIcon sx={{ color: "#006633" }} />
                  </IconButton>
                </Tooltip>
              }
              title={
                <p
                  style={{
                    color: "black",
                    fontSize: "large",
                    marginBottom: "0",
                  }}
                >
                  <b>{Languages.awAllMarkedAsRead}</b>
                </p>
              }
              subheader={Languages.msgNoMessagesInfo}
            />
            <CardActions disableSpacing>
              <Tooltip title={Languages.awMove2Messaging} arrow>
                <Button
                  size="small"
                  sx={{ color: "black" }}
                  aria-label={Languages.awMove2Messaging}
                  onClick={handleRedirectToMessaging}
                >
                  <ForwardToInboxIcon
                    fontSize="small"
                    sx={{ color: "#006633" }}
                  />
                  &nbsp;{Languages.awMove2Messaging}
                </Button>
              </Tooltip>
            </CardActions>
          </Card>
        </>
      );
    else return null;
  } else if (
    state.messaging.messageData.notReadThreads === 0 &&
    !allMessagesDeleted
  ) {
    return null;
  } else if (dismish === true) {
    return null;
  } else return null;
};

export default MessageNotifications;

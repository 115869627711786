import React from "react";
import BackToUpButton from "../GuideTools/BackToUpButton";
import Languages from "../../../translations";
import makeStyles from '@mui/styles/makeStyles';
import FeedIcon from '@mui/icons-material/Feed';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import Badge from '@mui/material/Badge';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import LockClockIcon from '@mui/icons-material/LockClock';


const useStyles = makeStyles({
  success: {
    color: 'rgb(129, 199, 132)',
    fontSize: 'xx-large',
  }
});



const PermissionRequestAccordionText = () => {
  const classes = useStyles();
    return(
      <>
      <div>
      <h4>{Languages.permissionRequestSubTitle}</h4>
          <br />
          <p className="guide-accordion-details-texts">{Languages.permissionRequestIntro} </p>
          <br />
          <br />
          <p className="guide-accordion-details-texts">{Languages.permissionRequestList1} <Badge badgeContent={3} color="error"><LiveHelpIcon color="action" /></Badge> {Languages.permissionRequestList2} <FeedIcon sx={{color: "#FF6D29"}}/> {Languages.permissionRequestList3} <ExpandMoreIcon sx={{color: "#FF6D29"}}/> {Languages.permissionRequestList4}</p>
          <br />
          <hr className="guide-accordion-details-line"/> 
          <br />
          <p className="guide-accordion-details-texts">{Languages.permissionRequestList5} {Languages.permissionRequestList6} <b>{Languages.permissionRequestList7}</b> {Languages.permissionRequestList8}</p>
          <br />
          <br />
          <p className="guide-accordion-details-texts"><NewReleasesIcon color="action" /> {Languages.permissionRequestList10}</p>
          <br />
          <br />
          <p className="guide-accordion-details-texts"><LockClockIcon color="action"/> {Languages.permissionRequestList11}</p>
          <br />
          <br />
          <p className="guide-accordion-details-texts">{Languages.permissionRequestList13} <b>{Languages.permissionRequestList14}</b> {Languages.permissionRequestList15} <b>{Languages.permissionRequestList16}</b> {Languages.permissionRequestList17} <b>{Languages.permissionRequestList18}</b></p>
          <p className="guide-accordion-details-texts"> {Languages.permissionRequestList19} <b>{Languages.permissionRequestList20}</b> {Languages.permissionRequestList21}</p>
          <br />
          <br />
          <p className="guide-accordion-details-texts">{Languages.permissionRequestList22} <b>{Languages.permissionRequestList23}</b> {Languages.permissionRequestList24}</p>
          <br />
          <br />
        <BackToUpButton />
        </div>
      </>
    )
}

export default PermissionRequestAccordionText
import React      from "react";
import Typography from "@mui/material/Typography";
import Languages  from '../../../translations';         
import Dialog     from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon  from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

const AnswerEveryQuestionPopUp = ({isShown, closeNullWarningPopUp}) => {

  if (!isShown) {
    return(null);
  }

  return (
    <Dialog 
      PaperProps={{
        style: {
          backgroundColor: "#F9E0E5",
          border: "solid 1px #E18994",
          borderLeft: "solid 6px #B30036",
          color: "black",
        },
      }}
      onClose={closeNullWarningPopUp} 
      aria-labelledby="close-popup" 
      open={isShown}
    >
      <DialogContent sx={{display: "block"}}>
        <Typography sx={{display: "inline", fontSize: "1.30rem"}}variant="subtitle1" >{Languages.permAnswersNotSaved}<IconButton sx={{float: "right"}} aria-label="close" onClick={closeNullWarningPopUp} size="large"><CloseIcon/></IconButton> </Typography>
        <br/>
        <Typography sx={{display: "inline"}}>{Languages.permAnswerAllQuestions}</Typography>
      </DialogContent>
    </Dialog>
  );
}

export default AnswerEveryQuestionPopUp;
import { types } from "./actionTypes";
import _cloneDeep from "lodash/cloneDeep";
import SessionManager from "../Auth/SessionManager.js";
import moment from "moment";
const MessagesReducer = (state, action) => {
  const payload = action.data;
  const payload2 = action.payload;
  switch (action.type) {
    case types.SET_ALL_MESSAGES_READ_SUCCESS:
      return {
        ...state,
        setAllMessagesRead: {
          status: types.SET_ALL_MESSAGES_READ_SUCCESS,
          Code: 200,
        },

        messageData: {
          ...state.messageData,
          messages: state.messageData.messages.map((message) => ({
            ...message,
            CountNotReadMessages: 0,
          })),
          notReadThreads: 0,
        },
      };

    case types.SET_ALL_MESSAGES_READ_FAILED:
      return {
        ...state,
        setAllMessagesRead: {
          status: types.SET_ALL_MESSAGES_READ_FAILED,
          Code: action.data.Code,
        },
      };

    case types.LOADING_SET_ALL_MESSAGES_READ:
      return {
        ...state,
        setAllMessagesRead: {
          status: types.LOADING_SET_ALL_MESSAGES_READ,
          Code: undefined,
        },
      };

    case types.UPDATE_DO_NOT_LOAD_INBOX_VARIABLE:
      //console.log("UPDATE DO NOT LOAD ", action);
      return {
        ...state,
        doNotLoadInboxAfterOpenThread: action.payload,
      };

    case types.MARK_THREAD_READ_LOCALLY:
      return {
        ...state,
        loadMessages: {
          state: "LOAD_MESSAGES_SUCCESS",
          messages: action.payload,
          Code: 200,
        },
      };

    case types.DELETE_MESSAGE_THREAD_LOADING:
      return {
        ...state,
        deleteThread: {
          status: types.DELETE_MESSAGE_THREAD_LOADING,
          Code: undefined,
        },
      };

    case types.DELETE_MESSAGE_THREAD_SUCCESS:
      return {
        ...state,
        deleteThread: {
          deletedThreadId: action.data,
          status: types.DELETE_MESSAGE_THREAD_SUCCESS,
          Code: action.Code,
        },
      };

    case types.DELETE_MESSAGE_THREAD_FAILED:
      console.log("delete_message_Thread_failed", action);
      return {
        ...state,
        deleteThread: {
          deletedThreadId:
            action.data.responsedata != null ? action.data.responsedata : "1",
          status: types.DELETE_MESSAGE_THREAD_FAILED,
          Code: action.Code,
        },
      };

    case types.UPDATE_MESSAGE_THREAD_ID:
      return {
        ...state,

        currentThreadId: {
          id: action.payload,
        },
      };

    case types.UPDATE_OM_SCROLL_ASSISTANT:
      return {
        ...state,

        openMessageScrolling: {
          requester: action.payload.requester,
        },
      };

    case types.LOADING_MESSAGES:
      return {
        ...state,

        loadMessages: {
          state: "LOADING_MESSAGES",
        },
      };
    case types.LOAD_MESSAGES_SUCCESS:
      console.log("testi: ", payload);
      return {
        ...state,

        loadMessages: {
          state: "LOAD_MESSAGES_SUCCESS",
          messages: payload,
          Code: 200,
        },
      };
    case types.LOAD_MESSAGES_FAILED:
      return {
        ...state,

        loadMessages: {
          state: "LOAD_MESSAGES_FAILED",
          Code: action.data.Code !== undefined ? action.data.Code : 777,
        },
      };

    case types.LOADING_MESSAGE_THREAD:
      return {
        ...state,

        loadThread: { ...state.loadThread, state: "LOADING_MESSAGE_THREAD" },
        latestApiRequestState: {
          state: "LOADING_MESSAGE_THREAD",
        },
        currentThreadId: {
          id: action.payload,
        },
      };

    case types.LOAD_MESSAGE_THREAD_SUCCESS:
      const processedData = processThread(payload);
      const newThreads = [...state.messageData.threads, payload];
      return {
        ...state,
        currentThreadId: {
          id: processedData.threadId,
        },
        loadThread: {
          state: types.LOAD_MESSAGE_THREAD_SUCCESS,
          Code: action.Code,
          threadId: processedData.threadId,
          threadContent: processedData.threadContent,
          allThreadParticipantsMinusUser:
            processedData.allThreadParticipantsMinusUser,
          truncatedParticipantsList: processedData.truncatedParticipantsList,
          numberOfNamesNotShown: processedData.numberOfNamesNotShown,
          DenyReply: payload.DenyReply,
          MassThread: payload.MassThread,
        },
        latestApiRequestState: {
          state: "LOAD_MESSAGE_THREAD_SUCCESS",
        },
        messageData: {
          ...state.messageData,
          threads: newThreads,
        },
      };

    case types.LOAD_MESSAGE_THREAD_FAILED:
      return {
        ...state,

        loadThread: {
          ...state.loadThread,
          state: types.LOAD_MESSAGE_THREAD_FAILED,
          Code: action.data.Code !== undefined ? action.data.Code : 777,
        },
        latestApiRequestState: {
          state: "LOAD_MESSAGE_THREAD_FAILED",
        },
      };

    case types.WAITING_REPLYTO_MASS_THREAD:
      return {
        ...state,

        replyToThread: {
          state: types.WAITING_REPLYTO_MASS_THREAD,
        },
        latestApiRequestState: {
          state: "WAITING_REPLYTO_MASS_THREAD",
        },
      };

    case types.REPLYTO_MASS_THREAD_SUCCESS:
      const processedData2 = processThread(payload);

      const updatedThreads = state.messageData.threads.filter(
        (thread) =>
          thread !== null &&
          thread.listMessageModels[0].ThreadId !== payload2.SplitThreadId
      );
      const finalThreads = [...updatedThreads, payload];
      return {
        ...state,

        currentThreadId: {
          id: processedData2.threadId,
        },
        replyToThread: {
          state: types.REPLYTO_MASS_THREAD_SUCCESS,
          Code: 200,
          ReplyToThreadLoadNewThread: action.ReplyToThreadLoadNewThread,
        },
        loadThread: {
          state: types.LOAD_MESSAGE_THREAD_SUCCESS,
          Code: action.Code,
          threadId: processedData2.threadId,
          threadContent: processedData2.threadContent,
          allThreadParticipantsMinusUser:
            processedData2.allThreadParticipantsMinusUser,
          truncatedParticipantsList: processedData2.truncatedParticipantsList,
          numberOfNamesNotShown: processedData2.numberOfNamesNotShown,
          DenyReply: payload.DenyReply,
          MassThread: payload.MassThread,
        },
        latestApiRequestState: {
          state: "REPLYTO_MASS_THREAD_SUCCESS",
        },
        messageData: {
          ...state.messageData,
          messageThreadAnswered: true,
          threads: finalThreads,
        },
      };

    case types.REPLYTO_MASS_THREAD_FAILED:
      return {
        ...state,

        replyToThread: {
          state: types.REPLYTO_MASS_THREAD_FAILED,
          Code: action.Code !== undefined ? action.Code : 500,
          //ReplyToThreadLoadNewThread: action.ReplyToThreadLoadNewThread,
        },
        latestApiRequestState: {
          state: "REPLYTO_MASS_THREAD_FAILED",
        },
      };

    case types.WAITING_REPLYTO_NONMASS_THREAD:
      return {
        ...state,

        replyToThread: {
          state: types.WAITING_REPLYTO_NONMASS_THREAD,
        },
        latestApiRequestState: {
          state: "WAITING_REPLYTO_NONMASS_THREAD",
        },
      };

    case types.REPLYTO_NONMASS_THREAD_SUCCESS:
      return {
        ...state,

        replyToThread: {
          state: types.REPLYTO_NONMASS_THREAD_SUCCESS,
          Code: 200,
          ReplyToThreadLoadNewThread: action.ReplyToThreadLoadNewThread,
        },
        latestApiRequestState: {
          state: "REPLYTO_NONMASS_THREAD_SUCCESS",
        },
        messageData: {
          ...state.messageData,
          threads: state.messageData.threads.map((thread) =>
            thread !== null &&
            thread.listMessageModels[0].ThreadId ===
              action.data.listMessageModels[0].ThreadId
              ? action.data
              : thread
          ),
          messageThreadAnswered: true,
        },
      };

    case types.REPLYTO_NONMASS_THREAD_FAILED:
      return {
        ...state,

        replyToThread: {
          state: types.REPLYTO_NONMASS_THREAD_FAILED,
          Code: action.Code !== undefined ? action.Code : 500,
          //ReplyToThreadLoadNewThread: action.ReplyToThreadLoadNewThread,
        },
        latestApiRequestState: {
          state: "REPLYTO_NONMASS_THREAD_FAILED",
        },
      };

    case types.WAITING_SET_THREAD_READ:
      return {
        ...state,
        setThisThreadRead: {
          state: "WAITING_SET_THREAD_READ",
        },
      };

    case types.SET_THREAD_READ_SUCCESS:
      const updatedMessages = state.messageData.messages.map((message) => {
        if (message.ThreadId === payload2) {
          return {
            ...message,
            CountNotReadMessages: 0,
          };
        }
        return message;
      });
      return {
        ...state,
        setThisThreadRead: {
          state: types.SET_THREAD_READ_SUCCESS,
          Code: 200,
        },
        messageData: {
          ...state.messageData,
          messages: updatedMessages,
          notReadThreads: state.messageData.notReadThreads - 1,
        },
      };
    case types.SET_THREAD_READ_FAILED:
      return {
        ...state,
        setThisThreadRead: {
          state: types.SET_THREAD_READ_FAILED,
          Code: action.Code !== undefined ? action.Code : 500,
        },
      };
    case types.LOAD_POTENTIAL_RECIPIENTS_SUCCESS:
      return {
        ...state,
        loadPotentialRecipients: {
          state: types.LOAD_POTENTIAL_RECIPIENTS_SUCCESS,
          Code: 200,
          potentialRecipients: payload,
        },
      };
    case types.LOAD_POTENTIAL_RECIPIENTS_FAILED:
      return {
        ...state,
        loadPotentialRecipients: {
          state: types.LOAD_POTENTIAL_RECIPIENTS_FAILED,
          Code: action.Code !== undefined ? action.Code : 500,
        },
      };
    case types.WAITING_LOAD_POTENTIAL_RECIPIENTS:
      return {
        ...state,
        loadPotentialRecipients: {
          state: types.WAITING_LOAD_POTENTIAL_RECIPIENTS,
        },
      };

    case types.WAITING_POST_NEW_MESSAGE:
      return {
        ...state,
        postNewMessage: {
          state: types.WAITING_POST_NEW_MESSAGE,
        },
        forSnackBar: {
          state: "WAITING_POST_NEW_MESSAGE",
        },
      };
    case types.POST_NEW_MESSAGE_SUCCESS:
      return {
        ...state,
        postNewMessage: {
          state: types.POST_NEW_MESSAGE_SUCCESS,
          Code: 200,
        },
        forSnackBar: {
          state: "POST_NEW_MESSAGE_SUCCESS",
        },
        messageData: {
          ...state.messageData,
          messageThreadAnswered: true,
        },
      };
    case types.POST_NEW_MESSAGE_FAILED:
      return {
        ...state,
        postNewMessage: {
          state: types.POST_NEW_MESSAGE_FAILED,
          Code: action.Code,
        },
        forSnackBar: {
          state: "POST_NEW_MESSAGE_FAILED",
        },
      };
    case types.RESET_POST_NEW_MESSAGE:
      return {
        ...state,
        postNewMessage: {
          state: "NEVER_LOADED",
          Code: undefined,
        },
        forSnackBar: {
          state: "RESET",
        },
      };

    case types.CLEAR_LOAD_THREAD_DATA:
      return {
        ...state,
        loadThread: {
          state: "NEVER_LOADED",
        },
      };

    case types.LOADING_MESSAGE_DATA:
      return {
        ...state,

        messageData: {
          state: "LOADING_MESSAGES",
        },
      };
    case types.MESSAGE_DATA_LOAD_OK:
      return {
        ...state,

        messageData: {
          state: "MESSAGE_DATA_LOAD_OK",
          messages: payload.data.ListMessage.listMessage,
          threads: payload.data.MessageThreads,
          notReadThreads: payload.data.NotReadThreads,
          lastLoaded: moment(),
          Code: 200,
          messageThreadAnswered: false,
        },
      };
    case types.MESSAGE_DATA_LOAD_FAILED:
      console.log(action);
      return {
        ...state,

        messageData: {
          state: "MESSAGE_DATA_LOAD_FAILED",
          Code: action.data.Code !== undefined ? action.data.Code : 777,
        },
      };

    default:
      return state;
  }
};

const markThreadReadInArray = (threadId) => {
  let oldMessageArray = _cloneDeep();
};

const processThread = (payload) => {
  // NOTE! This function is used both for processing the new incoming thread from loadOneMessage
  // AND for processing the thread data coming in when the parent has replied to a thread and receives
  // a response from the server right then in ReplyToThread

  let participantsPlusSender = payload.listThreadParticipants; //_cloneDeep(payload.listThreadParticipants);

  // remove the user from the list of thread participants
  let participantsMinusSender = removeUserFromParticipantList(
    participantsPlusSender
  );

  // create the button content for x number of thread participants to be shown on the button. "Me" is added in the component
  const maxNumberOfParticipantsOntheButton = 3;
  const partialParticipantListForButton = makePreviewListWithMaxNnames(
    participantsMinusSender,
    maxNumberOfParticipantsOntheButton
  );

  const processedData = {
    threadId: payload.listMessageModels[0].ThreadId,
    threadContent: payload.listMessageModels,
    allThreadParticipantsMinusUser: participantsMinusSender,
    truncatedParticipantsList: partialParticipantListForButton.shortListOfNames,
    numberOfNamesNotShown:
      partialParticipantListForButton.numberOfNamesNotShown,
  };
  return processedData;
};

const removeUserFromParticipantList = (messageTPfullObject) => {
  //remove the parent's own object from the list because the name ("Me") is added later in OpenMessage
  //this was refactored on 22.1.2020, OKD
  //console.log("personId", SessionManager.getPersonId());
  let participantListWithoutUser = [];
  for (let k = 0; k < messageTPfullObject.length; k++) {
    if (SessionManager.getPersonId() !== messageTPfullObject[k].Id) {
      participantListWithoutUser.push(messageTPfullObject[k]);
    }
  }
  return participantListWithoutUser;
};

const makePreviewListWithMaxNnames = (
  participantsMinusSender,
  desiredNumberOfNames
) => {
  //OKD 22.1.2020
  let shortListOfNames = [];
  let andThisManyOthers = "";

  let list = _cloneDeep(participantsMinusSender);

  if (list.length > desiredNumberOfNames) {
    for (let n = 0; n < desiredNumberOfNames; n++) {
      shortListOfNames.push(list[n]);
    }
    andThisManyOthers = list.length - desiredNumberOfNames;
  } else {
    shortListOfNames = list;
    andThisManyOthers = 0;
  }

  const data = {
    shortListOfNames: shortListOfNames,
    numberOfNamesNotShown: andThisManyOthers,
  };
  return data;
};

export default MessagesReducer;

import React, {useState, useEffect} from 'react';
import Languages from '../../../translations';
import Typography from "@mui/material/Typography";
import { List, ListItem } from '@mui/material';
import TextField from '@mui/material/TextField';
import Check from '@mui/icons-material/Check';
import IconButton from '@mui/material/IconButton';
import AccordionActions from '@mui/material/AccordionActions';
import Alert from '@mui/material/Alert';
import makeStyles from '@mui/styles/makeStyles';
import { green } from '@mui/material/colors';
import { SettingOptionPanel } from "./SettingOption";
import CheckCircle from "@mui/icons-material/CheckCircle";
import Error from "@mui/icons-material/Error";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { InputAdornment } from "@mui/material";

import { useAuth } from "../../../Auth";

import { useStateValue } from '../../../State';


export const PasswordChangeOption = ({ expanded, onExpandClick }) => {

  
  const {actions} = useStateValue();
  const { authActions: { resetPassword } } = useAuth();


  const [underTenWarning, setUnderTenWarning] = useState(true);
  const [containsNumberWarning, setContainsNumberWarning] = useState(true);
  const [containsCAPSWarning, setContainsCAPSWarning] = useState(true);
  const [passwordsMatchWarning, setPasswordsMatchWarning] = useState(true);

  //const [validationErrors, setValidationErrors] = useState([]);  
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordAgain, setNewPasswordAgain] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordAgain, setShowPasswordAgain] = useState(false);

  const [allowSave, setAllowSave] = useState(false);
  useEffect(() => {
    if (newPassword !== "") {
      if (containsNumberWarning === false && containsCAPSWarning === false && underTenWarning === false && passwordsMatchWarning == false)
      setAllowSave(true);
      else {
        setAllowSave(false);
      }
    }
    else {
      setAllowSave(false);
    }

  }, [containsNumberWarning, containsCAPSWarning, underTenWarning, passwordsMatchWarning, newPassword, newPasswordAgain])
  

//const [openInfoNote, setOpenInfoNote] = useState(false);
//const [infoText, setInfoText] = useState();

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
    margin: theme.spacing(1)
  },
}));


const ErrorBar = ({ errors, onClose }) => {
  return (
    <div>
    {errors.map((error, index) => 
      <Alert onClose={onClose} severity="error" key={"error_valid_"+index}>
        {error}
      </Alert>
    )}
    </div>
  );
}

const errorOnClose = () => {

}
/*
const PasswordActions = ({show = false}) => {
  const classes = useStyles();
  if (show) {
    return (
      <>
        <p className="mt-4">{Languages.passwordSaveInfoText}!</p>
        <IconButton
          aria-label={Languages.passwordSaveInfoText}
          className={classes.root}
          id="test_LoginSettings_ChangePasswordBtn"
          onClick={() => savePassword()}
          size="large"> 
          <Check id="test_LoginSettings_ChangePasswordBtn2"/> 
        </IconButton>
      </>
    );
  }
};*/

const savePassword = () => {
  const validation = validatePassword(newPassword);
    
  if (validation.result === true && newPassword === newPasswordAgain) {
    resetPassword(newPassword)
    .then(() => {
      //setPasswordExpanded(false);
      //setLanguagesExpanded(false);
      setNewPassword("");
      setNewPasswordAgain("");
      setAllowSave(false);
      setUnderTenWarning(true);
      setContainsNumberWarning(true);
      setContainsCAPSWarning(true);
      setPasswordsMatchWarning(true);
      //Ei voida näyttää notifikaatiota, koska ei tiedetä onnistuiko vai epäonnistuiko kutsu.
      //actions.triggerAddToSnackBarQueue({message: "Salasana vaihdettiin", color: "#000000"})
    });
  }
  else
  {
    //ei näytetä mitään, tallennusnappula jo näytetään vain silloin, kun checkit menevät läpi
    //setInfoText(validation.errors[0]);
    //setOpenInfoNote(true);
  }
};


const onPasswordFieldChange = (field, value) => {
  if (field === "newPassword") {
    setNewPassword(value);
    checkValidity(field, value);
  }
  if (field === "newPasswordAgain") {
    setNewPasswordAgain(value);
    checkValidity(field, value);
  }
}


const checkValidity = (field, value) => {
  if (field === "newPassword") {
  const validationResult = validatePassword(value);

    if (validationResult.errors.includes("underTen")) {
      setUnderTenWarning(true);
    }
    else {
      setUnderTenWarning(false);
    }
    if (validationResult.errors.includes("0-9")) {
      setContainsNumberWarning(true);
    }
    else {
      setContainsNumberWarning(false);
    }
    if (validationResult.errors.includes("CAPS")) {
      setContainsCAPSWarning(true);
    }
    else {
      setContainsCAPSWarning(false);
    }
  }
  if (newPassword === value && newPassword !== "" && value !== "") {
    setPasswordsMatchWarning(false);
  }
  else {
    setPasswordsMatchWarning(true);
  }

}

const validatePassword = (password) => {
  const validation = {
    result: true,
    errors: []
  }

  if (password.length < 10) {
    validation.result = false;
    validation.errors.push("underTen")
  }

  if (/[0-9]/.test(password) === false) {
    validation.result = false;
    validation.errors.push("0-9")
  }

  if (/(?=.*[A-ZÅÄÖ])/.test(password) === false) {
    validation.result = false;
    validation.errors.push("CAPS")
  }
  // Note: Add more validation here if needed
  return validation;
}


  return (
    <React.Fragment>
      <SettingOptionPanel
        showActions={allowSave}
        headerText={Languages.generalPassword}
        expanded={expanded}
        onExpandClick={onExpandClick}
        idForButton={"test_Settings_Password"}
        ariaControls={"panel1a-content"}
        settingsIcon="password"
        >

        <List>
          <ListItem>
            <Typography>{Languages.passwordRequirements}</Typography>
          </ListItem>
          {/*<ListItem>
            <ErrorBar errors={validationErrors} onClose={errorOnClose} />
          </ListItem>*/}

          <ListItem>
          <Card style={{backgroundColor: "#E0E0E0"}}>
            <CardContent>
              <Typography color="textSecondary">{underTenWarning ? <Error style={{color: "#ED0000", marginTop: "-4px"}}/> : <CheckCircle style={{color: "#009933", marginTop: "-4px"}}/>} {Languages.passwordTenChars}<br/>
              {containsNumberWarning ? <Error style={{color: "#ED0000", marginTop: "-4px"}}/> : <CheckCircle style={{color: "#009933", marginTop: "-4px"}}/>} {Languages.passwordOneNumber}<br/>
              {containsCAPSWarning ? <Error style={{color: "#ED0000", marginTop: "-4px"}}/> : <CheckCircle style={{color: "#009933", marginTop: "-4px"}}/>} {Languages.passwordOneCapital}<br/>
              {passwordsMatchWarning ? <Error style={{color: "#ED0000", marginTop: "-4px"}}/> : <CheckCircle style={{color: "#009933", marginTop: "-4px"}}/>} {Languages.passwordsMatch}
              </Typography>
            </CardContent>
          </Card>
          </ListItem>
          <ListItem>
            <TextField required
              variant="standard"
              name={"Password"}
              id="test_LoginSettings_NewPassword1"
              label={Languages.settingPasswordNew}
              placeholder={Languages.settingPasswordNew}
              value={newPassword}
              onChange={(event) => {
                onPasswordFieldChange("newPassword", event.target.value);
              }}
              type={showPassword ? 'text' : 'password'}
              InputProps={{ 
                endAdornment: (
                <InputAdornment position="start"
                >
                  <IconButton
                    aria-label="Näytä salasana / Visa lösenordet / Toggle password visibility"
                    onClick={(e) => setShowPassword(!showPassword) }
                    size="large">
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )}}
              margin="normal"
            />
          </ListItem>
          <ListItem>
            <TextField required
              variant="standard"
              name={"PasswordAgain"}
              id="test_LoginSettings_NewPassword2"
              label={Languages.settingPasswordNewAgain}
              placeholder={Languages.settingPasswordNewAgain}
              value={newPasswordAgain}
              onChange={(event) => {
                onPasswordFieldChange("newPasswordAgain", event.target.value);
              }}
              type={showPasswordAgain ? 'text' : 'password'}
              InputProps={{ 
                endAdornment: (
                <InputAdornment position="start"
                >
                  <IconButton
                    aria-label="Toggle password again visibility"
                    onClick={(e) => setShowPasswordAgain(!showPasswordAgain) }
                    size="large">
                    {showPasswordAgain ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )}}
              margin="normal"
            />
          </ListItem>
        </List>
        <>
          {allowSave && <span className="mt-4">{Languages.passwordSaveInfoText}!&nbsp;&nbsp;</span>}
          <IconButton
            sx={{backgroundColor: green[500]}}
            disabled={!allowSave}
            aria-label={Languages.passwordSaveInfoText}
            id="test_LoginSettings_ChangePasswordBtn"
            onClick={() => savePassword()}
            size="large"> 
            <Check id="test_LoginSettings_ChangePasswordBtn2"/> 
          </IconButton>
        </>
      </SettingOptionPanel>
      
    </React.Fragment>
  );
}

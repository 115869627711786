import { ApiBase } from "../internal";
import { createMockResponse } from "./";

export class SettingsApi extends ApiBase {
  constructor() {
    super();
  }

  async savePassword(Password) {
    let data = { password: Password }
    const response = await this.httpClient.post("/persons/changepassword/", data,
      {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": sessionStorage.getItem('language') === "en" ? "en-US" : sessionStorage.getItem('language')
        }
      });

    //console.log("SETTINGS API. Save password response: ", response);
    // return createMockResponse({ status: 500, statusText: "Erroria pukkaa"});
    return response;

  }

  async loadCommuneSettings(payload)
  {
    const data = {
      settingsArray: payload
    }

    const response = await this.httpClient.post("/settings/getCommuneSettings/", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  }

}

import React, { /*useState,*/ useEffect, useState } from "react";
import {useStateValue} from "../../../State";
import "../../Vasu/vasu.css";

import ContentBoxMUI    from "../../../Components/ContentBoxMUI";
import Languages        from "../../../translations";
import ChildRow         from '../../Vasu/ChildRow';
import SpringEvaluationSubPart from "./SpringEvaluationSubPart";

import Box              from '@mui/material/Box'
import Link             from '@mui/material/Link'
import LockIcon         from '@mui/icons-material/Lock';
import makeStyles from '@mui/styles/makeStyles';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  fixedbottom: {
    float: "right !important",
    fontSize: "x-large",
    position: "fixed",
    fontWeight: "bold", 
    color: "#D500F9",
    right: "0",
    bottom: "0",
    zIndex: "1040 !important"
  },
  displaynone:{
    display: "none"
  }
}));

const SpringEvaluation = (props) => {

  const classes = useStyles();
  const { state/*, actions*/ } = useStateValue();

  const { history, location } = props;
 
  if (location.state === undefined || state.leops === undefined || state.leops.GeneralPart.length === 0)
  {
    //F5/refesh
    //console.log("SpringEvaluationPart. MOUNT. State undefined GO BACK");
    history.goBack(); 
  }
  const GeneralPart = state.leops.GeneralPart;
  //console.log("SpringEvaluationPart. MOUNT. GeneralPart: ", GeneralPart);

  const Data        = state.leops.state === "INITIAL_STATE" ? "" : state.leops.Table4.Data.AssesmentRows;

  const [ShowSlideIndex, setShowSlideIndex] = useState(-1);

  var Titles = [Languages.ManyFormsOfExpression,
                Languages.leopsRichWorldOfLanguage,
                Languages.leopsMeAndOurCommunity,
                Languages.leopsIStudyAndWork,
                Languages.leopsIGrowAndDevelop,
                Languages.leopsIHaveLearnedInPreschool,
                Languages.leopsTitle4_3,
                Languages.ChildStrengthsAndIterests,
                Languages.EvaluationPedagogicalActivitiesGoalsSupport,
                Languages.OtherAdditionalInfoAndCooperation,
                Languages.GreetingsFromGuardian,
                Languages.LockLeops];

  /*useEffect( () => {
    switch(state.leops.state)
    {
      case "INITIAL_STATE":
        //console.log("SpringEvaluationPart INITIAL_STATE" );
        break;
      default:
        //console.log("SpringEvaluationPart ",state.leops.state );
        break;
    }
  }, [state.leops]);^*/
  
  useEffect( () => {
    //console.log("SpringEvaluationPart. Näytä slide: ",ShowSlideIndex );
  }, [ShowSlideIndex]);
  
  if (state.leops.state === "INITIAL_STATE")
  {
    //console.log("SpringEvaluationPart. INITIAL_STATE => RENDER EMPTY");

    return (<div className="SpringEvaluationPart"></div>);
  }
  else
  {
    //console.log(location.state)
    const { childpicture, childFirstName, childLastName, childColor} = location.state;
  
  return (
    <div className="SpringEvaluationPart">
      <ContentBoxMUI>

        <ChildRow childpicture   = {childpicture}
                  childFirstName = {childFirstName}
                  childLastName  = {childLastName}
                  childColor     = {childColor}
                  FormId         = {GeneralPart.Id}
                  history        = {history}>
        </ChildRow>

        {ShowSlideIndex === -1 ?
        <div>
          <h5 className="textCenter">{/*4.*/} 4. {Languages.eduPlanTitle4}</h5>
          <h6 className="textCenter">{Languages.LeopsTitle4_2}</h6>

          {Data.map((item,index)=> {
            return (
              <>              
              <List className="col-12" sx={{ mt: -1}}>
              <ListItemButton className="boxed" sx={{backgroundColor: "#fbdcce", borderRadius: .5, mt: 1.5, boxShadow: 2}} onClick={(event) => setShowSlideIndex(item.AssessmentItem[0].Row-1)}>
                <ListItemText 
                  primaryTypographyProps={{fontWeight: 'Bold', fontSize: 16, ml: 2}} primary={Titles[item.AssessmentItem[0].Row-1]}/>
                <ListItemIcon><ArrowForwardIosIcon fontWeight='Bold' fontSize="small"/></ListItemIcon>
              </ListItemButton>
              </List>
            </>
            );
          })}

          {Titles.map((item,index)=> {
            if (index === 6)
            {
              return(
                <h5 className="textCenter mt-4" key={"SpringEvaluationPart_" +index} >{Titles[index]}</h5>
                );
            }
            else if (index > 4)
            {
            return (
              <div key={"SpringEvaluationSlide_" +index} style={{opacity: GeneralPart.FormLocked === true && index == 10 ? "60%" : ""}}>
              {index === 11 ?
                <h5 className="textCenter mt-4" key={"SpringEvaluationPartH_" +index} >{item}</h5>
              : null}
              <List className="col-12" sx={{ mt: -1}}>
              <ListItemButton className="boxed" sx={{backgroundColor: "#fbdcce", borderRadius: .5, mt: 1.5, boxShadow: 2}} onClick={(event) => setShowSlideIndex(index === 11 ? 10 : index < 7 ? index : index-1)}>
                <ListItemText 
                  primaryTypographyProps={{fontWeight: 'Bold', fontSize: 16, ml: 2}} primary={item}/>
                <ListItemIcon><ArrowForwardIosIcon fontWeight='Bold' fontSize="small"/></ListItemIcon>
              </ListItemButton>
              </List>
              </div>
            );
            }
          })}
 
          <div className={GeneralPart.FormLocked === true ? classes.fixedbottom : classes.displaynone} >
            {Languages.logged}
            <LockIcon style={{color: "#D500F9"}} fontSize="large"></LockIcon>
          </div>
          
        </div>
        : 
          <SpringEvaluationSubPart SelectedSlideIndex = {ShowSlideIndex} > </SpringEvaluationSubPart>
        }
     
        </ContentBoxMUI>
      </div>
  );
  }
}

export default SpringEvaluation;
import { type } from 'jquery';
import { types } from './actionTypes';

const DailyNotesReducer = (state, action) => {
  const payload = action.data
  
  switch (action.type) {
    case types.LOADING_DAILY_NOTES:
      return {
        ...state,
        state: types.LOADING_DAILY_NOTES
      }
    case types.DAILY_NOTES_LOAD_OK:
      return {
        ...state,
        Code: 200,
        state: types.DAILY_NOTES_LOAD_OK,
        WeekList: payload,
        
      }
    case types.DAILY_NOTES_LOAD_FAILED:
        return {
            ...state,
            Code: payload.Code,
            state: types.DAILY_NOTES_LOAD_FAILED
          }
    default: return state;
  }
}

export default DailyNotesReducer;
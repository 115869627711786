import { types } from './actionTypes';

const SmallRequestReducer = (state, action) => {

switch(action.type) {

  case types.LOADING_VACATION_STATUS:
    return {
      ...state,
      vacationNotificationStatus: {
        status: types.LOADING_VACATION_STATUS,
      }
    }

  case types.LOAD_VACATION_STATUS_SUCCESS:
    return {
      ...state,
      vacationNotificationStatus: {
        status: types.LOAD_VACATION_STATUS_SUCCESS,
        Code: action.Code,
        data: action.data
      }
    }

  case types.LOAD_VACATION_STATUS_FAILED:
    return {
      ...state,
      vacationNotificationStatus: {
          status: types.LOAD_VACATION_STATUS_FAILED,
          Code: action.data.Code,
          data: {
            ShowNotification: undefined,
            NotificationTextRows: undefined
          }
        }
    }

    default:
  }
    
  return state;
}


export default SmallRequestReducer;

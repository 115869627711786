import React, { useState, useEffect, useRef, useCallback } from "react";
import { useStateValue } from "../../State";
import { useParams } from "react-router-dom";

import "./openmessage.css";
import ContentBoxMUI from "../../Components/ContentBoxMUI/index.js";
import SendersMessageBox from "../../Components/Messaging/SendersMessageBox/index.js";
import OwnMessageBox from "../../Components/Messaging/OwnMessageBox/index.js";
import AnswerMessage from "../../Components/Messaging/AnswerMessage/index.js";
import Languages from "../../translations.js";
import Divider from "@mui/material/Divider";
import { Typography, Dialog } from "@mui/material";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import CircularProgress from "@mui/material/CircularProgress";
import FullImageModal from "../../Components/Messaging/FullImageModal/index.js";
import { StateContext } from "../../State/index.js";
import MessagingBar from "../../Components/Messaging/MessagingBar/index.js";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
//import Modal                  from "@mui/material/Modal";
//import ContentBox             from "../../Components/ContentBox";
import MessagingPopUpModal from "../../Components/Modals/MessagingPopUpModal/index.js";
import SessionManager from "../../Auth/SessionManager.js";
import DeletedBox from "./Components/DeletedBox.jsx";
import _cloneDeep from "lodash/cloneDeep";

import AppBar from "@mui/material/AppBar";
import ToolBar from "@mui/material/Toolbar";
import GroupTwoTone from "@mui/icons-material/GroupTwoTone";
import FaceTwoTone from "@mui/icons-material/FaceTwoTone";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import SupervisedUserCircleRoundedIcon from "@mui/icons-material/SupervisedUserCircleRounded";
import TextField from "@mui/material/TextField";
import Fab from "@mui/material/Fab";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Grid from "@mui/material/Grid";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
  adaptV4Theme,
} from "@mui/material/styles"; //EMMITHEME
import { types } from "../../Reducers/actionTypes.js";

const index2 = (props) => {
  const { state, actions } = useStateValue();
  const messaging = state.messaging.messageData;
  const latestApiRequestState = state.messaging.latestApiRequestState;
  const replyToThread = state.messaging.replyToThread;
  const loadThread = state.messaging.loadThread;
  const currentThreadId = state.messaging.currentThreadId;
  const { ThreadId } = useParams(); // Move it outside the component
  //const [ThreadId, setThreadId] = useState(useParams());
  const [loadedThread, setLoadedThread] = useState();
  const [openMessageState, setOpenMessageState] = useState("NEVER_LOADED");
  const [replyToMessageFailedModal, setReplyToMessageFailedModal] =
    useState(false);

  const closeReplyToMessageFailedModal = () => {
    setReplyToMessageFailedModal(false);
  };

  useEffect(() => {
    switch (messaging.state) {
      case undefined:
        break;
      case "NEVER_LOADED":
        break;
      case types.LOADING_MESSAGE_DATA:
        setOpenMessageState(types.LOADING_MESSAGE_DATA);
        break;
      case types.MESSAGE_DATA_LOAD_OK:
        if (currentThreadId.id && currentThreadId.id !== ThreadId) {
          let thread = messaging.threads.filter(
            (t) =>
              t !== null &&
              t.listMessageModels[0].ThreadId === currentThreadId.id
          )[0];
          if (thread) {
            setLoadedThread(thread);
            setOpenMessageState(types.MESSAGE_DATA_LOAD_OK);
          } else {
            actions.triggerLoadThreadFromServer({
              threadId: currentThreadId.id,
            });
          }
        } else {
          let thread = messaging.threads.filter(
            (t) => t !== null && t.listMessageModels[0].ThreadId === ThreadId
          )[0];
          if (thread) {
            setLoadedThread(thread);
            setOpenMessageState(types.MESSAGE_DATA_LOAD_OK);
          } else {
            actions.triggerLoadThreadFromServer({ threadId: ThreadId });
          }
        }
        break;
      case types.MESSAGE_DATA_LOAD_FAILED:
        setOpenMessageState(types.MESSAGE_DATA_LOAD_FAILED);
        break;
      default:
        break;
    }
  }, [messaging]);

  useEffect(() => {
    switch (loadThread.state) {
      case undefined:
        break;
      case "NEVER_LOADED":
        break;

      case types.LOAD_MESSAGE_THREAD_SUCCESS:
        if (
          currentThreadId.id !== "undefined" &&
          currentThreadId.id !== ThreadId
        ) {
          let thread = messaging.threads.filter(
            (t) =>
              t !== null &&
              t.listMessageModels[0].ThreadId === currentThreadId.id
          )[0];
          if (thread) {
            setLoadedThread(thread);
            setOpenMessageState(types.MESSAGE_DATA_LOAD_OK);
            window.scrollTo(0, 0);
          }
        } else {
          let thread = messaging.threads.filter(
            (t) => t !== null && t.listMessageModels[0].ThreadId === ThreadId
          )[0];
          if (thread) {
            setLoadedThread(thread);
            setOpenMessageState(types.MESSAGE_DATA_LOAD_OK);
            window.scrollTo(0, 0);
          }
        }
        break;

      default:
        break;
    }
  }, [loadThread]);

  useEffect(() => {
    switch (replyToThread.state) {
      case undefined:
        break;
      case "NEVER_LOADED":
        break;
      case "REPLYTO_MASS_THREAD_FAILED":
        setReplyToMessageFailedModal(true);
        break;
      case "REPLYTO_NONMASS_THREAD_FAILED":
        setReplyToMessageFailedModal(true);
        break;
      default:
        break;
    }
  }, [replyToThread]);

  useEffect(() => {
    if (currentThreadId.id && currentThreadId.id !== ThreadId) {
      props.history.replace("/messaging/openmessage/" + currentThreadId.id);
    }
  }, [currentThreadId]);

  useEffect(() => {
    setReplyToMessageFailedModal(false);
    if (currentThreadId.id === ThreadId) {
      let message = messaging.messages.filter(
        (m) => m !== null && m.ThreadId === ThreadId
      )[0];
      if (message) {
        if (message.CountNotReadMessages !== 0) {
          actions.triggerSetThisThreadRead(ThreadId);
        }
      }
    }
  }, []);

  return (
    <React.Fragment>
      <ContentBoxMUI>
        <div>
          {openMessageState === types.MESSAGE_DATA_LOAD_OK && (
            <>
              <MessagingTopBar
                Thread={loadedThread}
                showParticipants={true}
                showLoadingIcon={false}
                onButtonClick={console.log("test")}
              />
              {/* LOADED */}
              {loadedThread.listMessageModels.length > 0 &&
                loadedThread.listMessageModels.map((msg, index) => {
                  return (
                    <div key={msg.Id + msg.ThreadId + index}>
                      {msg.OldestNotRead && (
                        <div>
                          {index !== 0 && (
                            <React.Fragment>
                              <Divider />
                              <Typography
                                color="textSecondary"
                                display="block"
                                variant="caption"
                              >
                                {Languages.msgUnreadMessages} <br />
                              </Typography>
                            </React.Fragment>
                          )}
                        </div>
                      )}

                      <DeletedBox isShown={msg.Content === null} />

                      {msg.MessageSender.Id === SessionManager.getPersonId() &&
                        msg.Content !== null && (
                          <OwnMessageBox
                            //key={msg.Id + "key"}
                            ownmessagesender={
                              /*msg.MessageSender.DisplayName !== null ? msg.MessageSender.DisplayName :*/ msg
                                .MessageSender.Name
                            }
                            ownmessagetext={msg.Content}
                            ownmessagedate={msg.Saved}
                            compact={true}
                          />
                        )}

                      {msg.MessageSender.Id !== SessionManager.getPersonId() &&
                        msg.Content !== null && (
                          <SendersMessageBox
                            //key={msg.Id + "key2"}
                            attachmentList={msg.ListAttachments}
                            compact={true}
                            messagesender={
                              /*msg.MessageSender.DisplayName !== null ? msg.MessageSender.DisplayName :*/ msg
                                .MessageSender.Name
                            }
                            messagetext={msg.Content}
                            messagedate={msg.Saved}
                            showThreadSentToMasses={
                              loadedThread.MassThread === true &&
                              msg.Id === loadedThread.listMessageModels[0].Id
                                ? true
                                : false
                            }
                            numberOfAttachments={
                              msg.ListAttachments !== undefined &&
                              msg.ListAttachments !== null
                                ? msg.ListAttachments.length
                                : null
                            }
                          />
                        )}
                    </div>
                  );
                })}

              <AnswerMessageThread Thread={loadedThread} ThreadId={ThreadId} />
            </>
          )}

          {(latestApiRequestState.state === "LOADING_MESSAGE_THREAD" ||
            latestApiRequestState.state === "WAITING_REPLYTO_MASS_THREAD" ||
            latestApiRequestState.state === "WAITING_REPLYTO_NONMASS_THREAD") &&
          openMessageState !== types.MESSAGE_DATA_LOAD_OK ? (
            <div style={{ marginTop: "50%", textAlign: "center" }}>
              <p>{Languages.msgLoadingThread}</p>
              <CircularProgress color="secondary" />
            </div>
          ) : null}
          {(latestApiRequestState.state === "REPLYTO_MASS_THREAD_FAILED" ||
            latestApiRequestState.state ===
              "REPLYTO_NONMASS_THREAD_FAILED") && (
            <MessagingPopUpModal
              isOpen={replyToMessageFailedModal}
              header={Languages.error}
              infoMsgText={Languages.errorMsgProbablyNotSent}
              button={() => closeReplyToMessageFailedModal()}
              buttonText={"OK"}
            />
          )}
          {loadThread.state === "LOAD_MESSAGE_THREAD_FAILED" ||
          (openMessageState === types.MESSAGE_DATA_LOAD_OK &&
            loadedThread.listMessageModels.length === 0) ? (
            <Card style={{ backgroundColor: "#4377BA" }}>
              <CardContent>
                <p>{Languages.msgCouldNotBeLoaded}</p>
                <p>
                  {loadThread.Code === 400
                    ? Languages.errorCode + ": 400 "
                    : loadThread.Code === 404
                    ? Languages.errorCode + ": 404 " + Languages.errorNotFound
                    : loadThread.Code === 500
                    ? Languages.errorCode + ": 500 " + Languages.errorInDB
                    : loadThread.Code === 502
                    ? Languages.errorCode + ": 502 " + Languages.errorNetwork
                    : Languages.errorCode +
                      ": " +
                      loadThread.Code +
                      Languages.errorUnknown}
                </p>
              </CardContent>
            </Card>
          ) : null}
        </div>
      </ContentBoxMUI>
    </React.Fragment>
  );
};

export default index2;

const MessagingTopBar = ({
  Thread,
  onButtonClick,
  showParticipants,
  showLoadingIcon,
  classes,
}) => {
  return (
    <div>
      <AppBar
        className="propsheader3"
        position="static"
        color="primary"
        style={{
          //marginTop: topMargin,
          backgroundColor: "#86B8FF",
          fontSize: "large",
          height: "auto",
          width: "inherit",
          marginBottom: "10px",
        }}
      >
        <ToolBar color="primary">
          {showParticipants === true ? (
            <div
              style={{ margin: "auto", maxWidth: "800px", cursor: "pointer" }}
            >
              <Button
                style={{ height: "auto", cursor: "pointer" }}
                color="secondary"
                onClick={onButtonClick}
              >
                <div
                  style={{
                    display: "block",
                    height: "auto",
                    marginBottom: "-3px",
                    cursor: "pointer",
                  }}
                >
                  {Thread.listThreadParticipants !== undefined
                    ? Thread.listThreadParticipants.map(
                        (participant, index) => {
                          return (
                            <>
                              {participant.Id !==
                              SessionManager.getPersonId() ? (
                                <Chip
                                  style={{
                                    marginBottom: "3px",
                                    marginRight: "2px",
                                    cursor: "pointer",
                                  }}
                                  size="small"
                                  display="inline"
                                  avatar={
                                    <Avatar>
                                      {participant.Type === "groupparent" ? (
                                        <GroupTwoTone />
                                      ) : participant.Type ===
                                        "centreparent" ? (
                                        <GroupTwoTone />
                                      ) : participant.Type === "person" ? (
                                        <FaceTwoTone />
                                      ) : participant.Type ===
                                        "parent_person" ? (
                                        <FaceTwoTone />
                                      ) : participant.Type ===
                                        "groupemployee" ? (
                                        <SupervisedUserCircleRoundedIcon />
                                      ) : participant.Type ===
                                        "centreemployee" ? (
                                        <SupervisedUserCircleRoundedIcon />
                                      ) : participant.Type ===
                                        "child_person" ? (
                                        <GroupTwoTone />
                                      ) : (
                                        <GroupTwoTone />
                                      )}
                                    </Avatar>
                                  }
                                  id={participant.Id + "r"}
                                  color={
                                    participant.Type === "parent_person"
                                      ? "secondary"
                                      : "secondary"
                                  }
                                  key={participant.Id + index}
                                  label={
                                    typeof participant.Name === "undefined" ||
                                    participant.Name === null
                                      ? Languages.msgUnknownPersonOrGroup
                                      : participant.Type === "child_person"
                                      ? participant.Name +
                                        " (" +
                                        Languages.msgCaretakers +
                                        ")"
                                      : participant.Name
                                  }
                                />
                              ) : null}
                            </>
                          );
                        }
                      )
                    : null}
                  <Chip
                    style={{
                      marginBottom: "3px",
                      marginRight: "2px",
                      cursor: "pointer",
                    }}
                    size="small"
                    display="inline"
                    avatar={
                      <Avatar>
                        <FaceTwoTone />
                      </Avatar>
                    }
                    id="mer"
                    color="secondary"
                    label={Languages.msgMe}
                  />
                </div>
              </Button>
            </div>
          ) : null}

          {showLoadingIcon === true ? (
            <>
              <div style={{ textAlign: "center", width: "100%" }}>
                <CircularProgress
                  color="secondary"
                  style={{ textAlign: "center" }}
                />
              </div>
            </>
          ) : null}
        </ToolBar>
      </AppBar>
      {/* </HideOnScroll> */}
    </div>
  );
};

const theme2 = createTheme(
  adaptV4Theme({
    // EMMITHEME
    palette: {
      common: {
        black: "#000",
        white: "#fff",
        blue: "#4377BA",
        green: "#81C784", // only for contrast with black
        lightblue: "#86B8FF",
        greenwithblackorwhite: "#508053", // enough contrast with black or white both
      },
      primary: {
        light: "rgba(225, 245, 254, 1)",
        main: "#86B8FF", //"#6E7599", //"#0D1B2E",//'#000000', //"#000001", //"#6E7599",
        dark: "rgba(2, 136, 209, 1)",
        contrastText: "#ffffff",
      },
      secondary: {
        light: "#ff4081",
        main: "#4377BA", // "#4377BA",//'#4377BA',
        dark: "#315687", //'#86B8FF',
        contrastText: "#fff",
      },
      text: {
        textPrimary: "#000000",
      },
    },
  })
);

export const AnswerMessageThread = ({ Thread, ThreadId }) => {
  const { state, actions } = useStateValue();
  const { replyToThread } = state.messaging;
  const [content, setContent] = useState("");
  const [sentButtonPressed, setSentButtonPressed] = useState(false);
  const [numberOfCharacters, setNumberOfCharacters] = useState(0);
  const [backupContent, setBackupContent] = useState("");
  const [keepBackup, setKeepBackup] = useState(false);
  const [characterLimitWarning, setCharacterLimitWarning] = useState("");

  const handleChange = (event) => {
    if (numberOfCharacters < 5001) {
      setContent(event.target.value);
      setNumberOfCharacters(event.target.value.length);
      setCharacterLimitWarning("");
      setBackupContent("");
    } else {
      setContent(event.target.value);
      setNumberOfCharacters(event.target.value.length);
      setBackupContent("");
    }
  };

  const sendMessage = () => {
    if (Thread.DenyReply === false) {
      if (Thread.MassThread) {
        const datatobesent = {
          Content: content,
          ThreadId: null,
          SplitThreadId: ThreadId,
        };
        actions.triggerReplyTo_MASS_thread(datatobesent);
      } else {
        const datatobesent = {
          Content: content,
          ThreadId: ThreadId,
        };

        actions.triggerReplyTo_NONMASS_thread(datatobesent);
      }
    }
  };

  useEffect(() => {
    switch (replyToThread.state) {
      case undefined:
        break;
      case types.REPLYTO_NONMASS_THREAD_SUCCESS:
        setContent("");
        setNumberOfCharacters(0);
        break;
      case types.REPLYTO_MASS_THREAD_SUCCESS:
        setContent("");
        setNumberOfCharacters(0);
        break;
      default:
        break;
    }
  }, [replyToThread]);
  return (
    <div>
      <div className="answerbox2">
        <div>
          <Grid container>
            <Grid item xs={1}></Grid>
            <Grid item xs={9}>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme2}>
                  {" "}
                  {/* EMMITHEME */}
                  <Typography component={"span"} variant={"body2"}>
                    <TextField
                      color="secondary"
                      variant="outlined"
                      size="small"
                      autoFocus={false}
                      aria-label="Text field for message thread reply"
                      id="test_ReplyMsg_MsgBody_Txt"
                      value={typeof content !== "undefined" ? content : ""}
                      label={
                        Thread.DenyReply
                          ? Languages.msgDenyReply
                          : Languages.msgReplyToMessageThread
                      }
                      multiline
                      rows="2"
                      disabled={Thread.DenyReply ? true : false}
                      margin="normal"
                      onChange={handleChange}
                      helperText={
                        numberOfCharacters > 5000 ? (
                          <span className="countertextred1">
                            {numberOfCharacters + " / 5000 !!!"}
                          </span>
                        ) : (
                          <span>{numberOfCharacters + " / 5000"}</span>
                        )
                      }
                      fullWidth
                      alt="message input field"
                    />
                  </Typography>
                </ThemeProvider>
              </StyledEngineProvider>
            </Grid>
            <Grid item xs={2}>
              <div className="replyFab" display="inline-block">
                {replyToThread.state !== "WAITING_REPLYTO_MASS_THREAD" &&
                replyToThread.state !== "WAITING_REPLYTO_NONMASS_THREAD" ? (
                  <Fab
                    id="test_ReplyMsg_MsgBody_Btn"
                    color="success"
                    size="medium"
                    disabled={
                      Thread.DenyReply ||
                      numberOfCharacters > 5000 ||
                      numberOfCharacters < 1
                        ? true
                        : false
                    }
                    onClick={sendMessage}
                    aria-label="Reply to thread"
                  >
                    <ChevronRight />
                  </Fab>
                ) : null}
                {replyToThread.state === "WAITING_REPLYTO_MASS_THREAD" ||
                replyToThread.state === "WAITING_REPLYTO_NONMASS_THREAD" ? (
                  <CircularProgress color="primary" />
                ) : null}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

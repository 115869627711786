import React from "react";
import Typography from "@mui/material/Typography";
import Languages from "../../translations";

function TermsOfUseContent() {
  return (
    <div>
    <React.Fragment>
    <hr />
    <br/>
    <Typography
      variant="h4" 
      component="h1" 
      color="textSecondary"
      display="inline" 
      style={{wordWrap:"break-word"}}
    >
      {Languages.termsHeaderMain}    
    </Typography>
    <br/>
    <br/>
    <Typography 
      variant="h5" 
      component="h2" 
      color="textSecondary" 
      display="inline" 
      style={{wordWrap:"break-word"}}
    >
      {Languages.termsHeader1}
    </Typography>
    <br />
    <br />
    
    <Typography variant="body1" color="textSecondary">
      {Languages.termsPara1}
    </Typography>
    <br />
    <Typography variant="body1" color="textSecondary">
      {Languages.termsPara2}
    </Typography>
    <br />
    <Typography variant="body1" color="textSecondary">
      {Languages.termsPara3}
    </Typography>
    <br />
    <Typography variant="body1" color="textSecondary">
      {Languages.termsPara4}
    </Typography>
    <br />


    <Typography 
      variant="h5" 
      component="h2" 
      color="textSecondary" 
      display="inline" 
      style={{wordWrap:"break-word"}}
    >  
      {Languages.termsHeader2}
    </Typography>
    <br/>
    <br/>

    <Typography variant="body1" color="textSecondary">WhileOnTheMove Oy ({Languages.termsPara5} 2351407-1)</Typography>
    <Typography variant="body1" color="textSecondary">Elektroniikkatie 2 B</Typography>
    <Typography variant="body1" color="textSecondary">90590 Oulu</Typography>
    <Typography variant="body1" color="textSecondary">{Languages.termsPara6} 040 843 3948</Typography>
    <Typography variant="body1" color="textSecondary"><a href="https://www.daisyvarhaiskasvatus.fi" target="_blank" rel="noopener noreferrer">www.daisyvarhaiskasvatus.fi</a></Typography>
    <br />

    <Typography 
      variant="h5" 
      component="h2" 
      color="textSecondary" 
      display="inline" 
      style={{wordWrap:"break-word"}}
    >  
      {Languages.termsHeader3}
    </Typography>
    <br />
    <br />

    <Typography variant="body1" color="textSecondary">
      {Languages.termsPara7}
    </Typography>

    <br />

    <Typography 
      variant="h5" 
      component="h2" 
      color="textSecondary" 
      display="inline" 
      style={{wordWrap:"break-word"}}
    >  
      {Languages.termsHeader4}
    </Typography>
    <br />
    <br />

    <Typography variant="body1" color="textSecondary">
      {Languages.termsPara8}
    </Typography>
    <br />
    <Typography variant="body1" color="textSecondary">
      {Languages.termsPara9}
    </Typography>
    <br />
    <Typography variant="body1" color="textSecondary">
      {Languages.termsPara10}
    </Typography>
    <br />

    <Typography 
      variant="h5" 
      component="h2" 
      color="textSecondary" 
      display="inline" 
      style={{wordWrap:"break-word"}}
    >
      {Languages.termsHeader5}
    </Typography>
    <br />
    <br />

    <Typography 
      variant="h6" 
      component="h2" 
      color="textSecondary" 
      display="inline" 
      style={{wordWrap:"break-word"}}
    >
      {Languages.termsHeader5sub1}
    </Typography>
    <br />
    <br />
    <Typography variant="body1" color="textSecondary">
      {Languages.termsPara11}
    </Typography>
    <br />
    <Typography variant="body1" color="textSecondary">
      {Languages.termsPara12}
    </Typography>
    <br />
    <Typography variant="body1" color="textSecondary">
      {Languages.termsPara13}
    </Typography>
    <br />
    <Typography variant="body1" color="textSecondary">
      {Languages.termsPara14}
    </Typography>
    <br />
    <Typography variant="body1" color="textSecondary">
      {Languages.termsPara15}
    </Typography>
    
      <ol type="a">
      <Typography variant="body1" color="textSecondary">
        <li>{Languages.termsPara16}</li>
        <li>{Languages.termsPara17}</li>
        <li>{Languages.termsPara18}</li>
        <li>{Languages.termsPara19}</li>
        <li>{Languages.termsPara20}</li>
        <li>{Languages.termsPara21}</li>
        </Typography>
      </ol>
      
    <br />

    <Typography 
      variant="h6" 
      component="h2" 
      color="textSecondary" 
      display="inline" 
      style={{wordWrap:"break-word"}}
    >
      {Languages.termsHeader5sub2}
    </Typography>
    <br />
    <br />

    <Typography variant="body1" color="textSecondary">
      {Languages.termsPara22}
    </Typography>
    <br />

    <Typography 
      variant="h6" 
      component="h2" 
      color="textSecondary" 
      display="inline" 
      style={{wordWrap:"break-word"}}
    >
      {Languages.termsHeader5sub3}
    </Typography>
    <br />
    <br />

    <Typography variant="body1" color="textSecondary">
      {Languages.termsPara23}
    </Typography>
    <br />
    <Typography variant="body1" color="textSecondary">
      {Languages.termsPara24}
    </Typography>
    <br />
    <Typography variant="body1" color="textSecondary">
      {Languages.termsPara25}
    </Typography>
    <br />

    <Typography 
      variant="h5" 
      component="h2" 
      color="textSecondary" 
      display="inline" 
      style={{wordWrap:"break-word"}}
    >
      {Languages.termsHeader6}
    </Typography>
    <br />
    <br />

    <Typography variant="body1" color="textSecondary">
      {Languages.termsPara26}
    </Typography>
    <br />
    <Typography variant="body1" color="textSecondary">
      {Languages.termsPara27}
    </Typography>
    <br />
    <Typography variant="body1" color="textSecondary">    
      {Languages.termsPara28}
    </Typography>
    <br />
    <Typography variant="body1" color="textSecondary">
      {Languages.termsPara29}
    </Typography>
    <br />

    <Typography 
      variant="h5" 
      component="h2" 
      color="textSecondary" 
      display="inline" 
      style={{wordWrap:"break-word"}}
    >
      {Languages.termsHeader7}
    </Typography>
    <br />
    <br />

    <Typography variant="body1" color="textSecondary">
      {Languages.termsPara30}
    </Typography>
    <br />
    <Typography variant="body1" color="textSecondary">
      {Languages.termsPara31}
    </Typography>
    <br />

    <Typography 
      variant="h5" 
      component="h2" 
      color="textSecondary" 
      display="inline" 
      style={{wordWrap:"break-word"}}
    >
      {Languages.termsHeader8}
    </Typography>
    <br />
    <br />

    <Typography variant="body1" color="textSecondary">
      {Languages.termsPara32}
    </Typography>
    <br />
    <Typography variant="body1" color="textSecondary">
      {Languages.termsPara33}
    </Typography>
    <br />
    <Typography variant="body1" color="textSecondary">
      {Languages.termsPara34}
    </Typography>
    <br />
    <Typography variant="body1" color="textSecondary">
      {Languages.termsPara35}
    </Typography>
    <br />
    <Typography variant="body1" color="textSecondary">
      {Languages.termsPara36}
    </Typography>
    <br />

    <Typography 
      variant="h5" 
      component="h2" 
      color="textSecondary" 
      display="inline" 
      style={{wordWrap:"break-word"}}
    >
      {Languages.termsHeader9}
    </Typography>
    <br />
    <br />

    <Typography variant="body1" color="textSecondary">
      {Languages.termsPara37}
    </Typography>
    <br />
    <Typography variant="body1" color="textSecondary">
      {Languages.termsPara38}
    </Typography>
    <br />

    <Typography 
      variant="h5" 
      component="h2" 
      color="textSecondary" 
      display="inline" 
      style={{wordWrap:"break-word"}}
    >
      {Languages.termsHeader10}
    </Typography>
    <br />
    <br />

    <Typography variant="body1" color="textSecondary">
      {Languages.termsPara39}
    </Typography>
    <br />
    <Typography variant="body1" color="textSecondary">
      {Languages.termsPara40}
    </Typography>
    <br />
    <Typography variant="body1" color="textSecondary">
      {Languages.termsPara41}
    </Typography>
    <Typography variant="body1" color="textSecondary">
      {Languages.termsPara42}
    </Typography>
    <br />

    <hr />
    </React.Fragment>
    </div>

  ); 
}




export default TermsOfUseContent;

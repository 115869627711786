import React, { Component } from "react";
import "./newmessage.css";
import SelectChildrenInQuestionView from "./Components/SelectChildrenInQuestionView";
import SelectRecipientsViewContainer from "./SelectRecipientsViewContainer";
import NewMessageWritingView from "./NewMessageWritingView";
import ContentBoxMUI from "../../../Components/ContentBoxMUI";
import Languages from "../../../translations";
import _cloneDeep from "lodash/cloneDeep";
import MessagingPopUpModal from "../../../Components/Modals/MessagingPopUpModal";
import { isAnyPropUndefinedOrNull } from "../../../utils";
import NewMessageStepper from "./Components/NewMessageStepper";


class NewMessage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      undefinedOrNullPropExists: isAnyPropUndefinedOrNull(props),

      writingViewOpen: false,
      selectRecipientsView: false,
      selectChildrenView: true,

      currentlyCheckedList1: [], // this is the selected recipients list
      currentlyCheckedWithObjects: [], // this is created based on currentlyCheckedList1 when the user clicks on the button
      // that takes the user to the writing new message view. it is also updated
      // when/if the user deletes a pill in that view. for other things on the 
      // select recipients page, only currentlyCheckedList1 is used.

      tempSavedMessageContent: "",
      tempSavedTitleContent: "",

      checkedChildren: [], // list of children the parent has selected as message topics

      RPersons: [], // recipients, employees
      RParentPersons: [], // recipients, msgCaretakers
      RGroup: [],
      RAll: [],

      includeEmployeesNotification: false, // show notification if no employees are selected

    };

  }

  componentDidMount() {
    
    if (typeof this.props.children !== "undefined" && this.props.children !== null) {this.initializeCheckedChildrenList();}

  }

  initializeCheckedChildrenList = () => {
    // makes an array that has the Id:s of the user's children:
    
    let checkedChildrenVar = [];
    let parentsChildren = _cloneDeep(this.props.children.ChildList);
    for (let i = 0; i < parentsChildren.length; i++) {
      checkedChildrenVar.push(parentsChildren[i].Id);
    }
    this.setState({ checkedChildren: checkedChildrenVar });
  }

  closeNewMessageAndOpenInbox = () => {
   
    this.setState({
      writingViewOpen: false
    });

    this.props.returnToInbox();
  }

  openSelectRecipientsView = () => {
    this.setState({
      writingViewOpen: false,
      selectRecipientsView: true
    })
  }

  closeSelectRecipientsViewAndOpenWritingView = () => {

    // making a list of selected recipients that also has Type information
    let currentlyCheckedWithObjects = [];
    let tempRAll = _cloneDeep(this.state.RAll);

    for (let i = 0; i < this.state.currentlyCheckedList1.length; i++) {
      for (let j = 0; j < tempRAll.length; j++) {
        if (this.state.currentlyCheckedList1[i] === tempRAll[j].Id) {
          currentlyCheckedWithObjects.push(tempRAll[j]);
        }
      }
    }

    // checking whether the parent has selected employees, groups or centres
    let includesEmployees = false;

    for (let k = 0; k < currentlyCheckedWithObjects.length; k++) {
      if (currentlyCheckedWithObjects[k].Type === "person" || currentlyCheckedWithObjects[k].Type === "group"
        || currentlyCheckedWithObjects[k].Type === "centre") {
        includesEmployees = true;
      }
    }

    // notification will be shown if no employees have been selected
    if (includesEmployees === true) {
      this.setState({
        writingViewOpen: true,
        selectRecipientsView: false,
        currentlyCheckedWithObjects: currentlyCheckedWithObjects
      });
    }

    else {
      this.setState({
        includeEmployeesNotification: true,
        currentlyCheckedWithObjects: currentlyCheckedWithObjects
      })
    }

  }

  addOrRemoveChildFromCheckedChildren = (event) => {
    // Handles the checkboxes where the children are selected

    if (event.target.checked === true) {
      var tempList = _cloneDeep(this.state.checkedChildren);
      tempList.push(event.target.value);
      this.setState({
        checkedChildren: tempList
      });
    }

    else {
      var tempList2 = _cloneDeep(this.state.checkedChildren);
      for (var i = 0; i < tempList2.length; i++) {
        if (tempList2[i] === event.target.value) {
          tempList2.splice(i, 1);
        }
      }
      this.setState({
        checkedChildren: tempList2
      });
    }

  }


  pillClickedRemoveRecipientFromCheckedList = (id) => {
    // When a "pill"/Chip has been clicked and the corresponding recipient needs to be removed from 
    // the recipient list and UI
    
    var ky = _cloneDeep(this.state.currentlyCheckedList1);
    var kz = _cloneDeep(this.state.currentlyCheckedWithObjects);
    for (var i = 0; i < ky.length; i++) {
      if (ky[i] === id) {
        ky.splice(i, 1);
        kz.splice(i, 1);
      }
    }
    this.setState({
      currentlyCheckedList1: ky,
      currentlyCheckedWithObjects: kz
    })

  }

  closeSelectChildrenView = () => {
    // user must select at least one child in order to proceed
    if (this.state.checkedChildren.length > 0) {
      this.setState({
        selectChildrenView: false,
        selectRecipientsView: true,
      })

      this.updateRecipientsListByChildren();
    }
    else {
      // do nothing
    }
  }

  updateRecipientsListByChildren = () => {
    let recipientsFromServer = _cloneDeep(this.props.potentialRecipientsProp);
    let checkedChildren = _cloneDeep(this.state.checkedChildren);
    
    const listsByType = this.updateRecipientsListByChildrenPureFunction(recipientsFromServer, checkedChildren); 
      
    this.setState(listsByType);

  }

  updateRecipientsListByChildrenPureFunction = (recipientsFromServer, checkedChildren) => {
    
    let recipientsListBasedOnChildrenSelected = this.createRecipientsListBasedOnChildrenSelected(checkedChildren, recipientsFromServer);

    // compare the list of children that the user has selected to the list of possible recipients from the server, create a new list where the recipients are listed
    let listOfRecipientObjectsWithoutDuplicates = this.removeDuplicatesFromRecipientsList(recipientsListBasedOnChildrenSelected);

    const listsByTypeForState = this.createListsOfRecipientsBasedOnType(listOfRecipientObjectsWithoutDuplicates, recipientsFromServer);

    return(listsByTypeForState);

  }

  createRecipientsListBasedOnChildrenSelected = (checkedChildren, tempRecipientsFromServer) => {
    
    let tempRecipientsListByUser = [];

    for (let i = 0; i < checkedChildren.length; i++) {
      for (let j = 0; j < tempRecipientsFromServer.length; j++) {
        if (tempRecipientsFromServer[j].PersonId === checkedChildren[i]) {
          for (let k = 0; k < tempRecipientsFromServer[j].Recipients.length; k++) {
            tempRecipientsListByUser.push(tempRecipientsFromServer[j].Recipients[k]);
          }
        }
      }
    }

    return(tempRecipientsListByUser);

  }

  removeDuplicatesFromRecipientsList = (recipientsListWithPossibleDuplicates) => {

    //Todo? Needlessly complex? Should this be refactored? 20.2.2020
    let templist3 = [];
    for (let o = 0; o < recipientsListWithPossibleDuplicates.length; o++) {
      templist3.push(recipientsListWithPossibleDuplicates[o].Id);
    }

    let idsWithoutDuplicates = [...new Set(templist3)];


    let objArrayWithoutDuplicates = []; //make the array contain objects again instead of just Ids
    let simplecheckarray = []; // this array is created so that .includes() can be used in the next phase

    for (let q = 0; q < idsWithoutDuplicates.length; q++) {
      for (let p = 0; p < recipientsListWithPossibleDuplicates.length; p++) {
        if (idsWithoutDuplicates[q] === recipientsListWithPossibleDuplicates[p].Id) {
          if (simplecheckarray.includes(idsWithoutDuplicates[q]) !== true) {
            objArrayWithoutDuplicates.push(recipientsListWithPossibleDuplicates[p]);
            simplecheckarray.push(idsWithoutDuplicates[q]);
          }
        }
      }
    }

    return(objArrayWithoutDuplicates);
  }

  createListsOfRecipientsBasedOnType = (listOfRecipientObjectsWithoutDuplicates) => {

    //make three lists by person, parent_person and group for render() to use
    var temp = _cloneDeep(listOfRecipientObjectsWithoutDuplicates);
    var tempPersons = [];
    var tempParentPersons = [];
    var tempGroup = [];

    for (var m = 0; m < temp.length; m++) {

      if (temp[m].Type === "person") {
        tempPersons.push(temp[m]);
      }

      else if (temp[m].Type === "parent_person") {
        tempParentPersons.push(temp[m]);
      }

      else { // if Type is group, centre, or something else
        tempGroup.push(temp[m]);
      }
    }

    return({
      RPersons: tempPersons,
      RParentPersons: tempParentPersons,
      RGroup: tempGroup,
      RAll: temp
    })

  }


  closeIncludeEmployeesNotification = () => {
    this.setState({
      includeEmployeesNotification: false
    })
  }

  updateCheckedList = (changedList) => {
    this.setState({currentlyCheckedList1: changedList});
  }

  saveTempMessageContent = (contentToBeSaved) => {
    this.setState({tempSavedMessageContent: contentToBeSaved});
  }

  saveTempTitleContent = (titleToBeSaved) => {
    this.setState({tempSavedTitleContent: titleToBeSaved});
  }

  render() {

    if(this.state.undefinedOrNullPropExists) 
      {
        return(
          <div data-testid="NewMessage-undefined-or-null"></div>
        );
      }

    else {

      return (

        <div className="newmessage">

          <MessagingPopUpModal 
            isOpen={this.state.includeEmployeesNotification}
            header={Languages.msgIncludeStaffTitle}
            infoMsgText={Languages.msgIncludeStaffBody}
            button={this.closeIncludeEmployeesNotification}
            buttonText={"OK"}
          />

          {this.state.selectChildrenView &&

            <ContentBoxMUI 
              hideHeader={true}
              header={Languages.msgWhomMessageConcerns}
            >
              <>
                <NewMessageStepper activeStep={0}/>
                <SelectChildrenInQuestionView 
                  childList={this.props.childList}
                  loadPotentialRecipientsState={this.props.loadPotentialRecipientsState}
                  loadRecipientsData={this.props.loadRecipientsData}
                  closeSelectChildrenView={this.closeSelectChildrenView}
                  addOrRemoveChildFromCheckedChildren={this.addOrRemoveChildFromCheckedChildren}
                  checkedChildren={this.state.checkedChildren}/>
              </>
            </ContentBoxMUI>
          }

          {this.state.selectRecipientsView &&
            <>
              <ContentBoxMUI
                style={{minWidth:"100%"}}
                header={Languages.msgSelectRecipients}
              >
                <NewMessageStepper activeStep={1}/>
                <SelectRecipientsViewContainer 
                  allRecipients={this.state.RAll}
                  currentlyCheckedList={this.state.currentlyCheckedList1}
                  updateCheckedList={this.updateCheckedList}
                  RGroup={this.state.RGroup}
                  RPersons={this.state.RPersons}
                  closeSelectRecipientsView={this.closeSelectRecipientsViewAndOpenWritingView}
                />
              </ContentBoxMUI>
            </>
          }

          {this.state.writingViewOpen &&
            <>  
              <ContentBoxMUI
                header={Languages.msgMessageNew}
              >
                <NewMessageStepper activeStep={2}/>
                <NewMessageWritingView 
                  currentlyCheckedList={this.state.currentlyCheckedList1}
                  allPotentialRecipients={this.state.RAll}
                  pillClicked={(id) => this.pillClickedRemoveRecipientFromCheckedList(id)}
                  openSelectRecipientsView = {() => this.openSelectRecipientsView()}
                  currentlyCheckedListWithObjects={this.state.currentlyCheckedWithObjects}
                  closeNewMessageAndOpenInbox={() => this.closeNewMessageAndOpenInbox()}
                  tempSavedMessageContent={this.state.tempSavedMessageContent}
                  saveTempMessageContent={(tempContent) => this.saveTempMessageContent(tempContent)}
                  tempSavedTitleContent={this.state.tempSavedTitleContent}
                  saveTempTitleContent={(tempTitle) => this.saveTempTitleContent(tempTitle)}
                  postNewMessageState={this.props.postNewMessageState}
                  snackBarState={this.props.snackBarState}
                  sendNewMessageToServer={(data) => this.props.sendNewMessageToServer(data)}
                />
              </ContentBoxMUI>
            </>
          }
        </div>

      );

    }
  }
}

export default NewMessage;

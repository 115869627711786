import React, { /*useState,*/ useEffect, useState } from "react";
import {useStateValue} from "../../../State";
import "../leops.css";


import ContentBoxMUI    from "../../../Components/ContentBoxMUI";
import Languages        from "../../../translations";
import ChildRow         from '../../Vasu/ChildRow';
import PreparingSubPart from "./PreparingSubPart";

import Box              from '@mui/material/Box'
import Link             from '@mui/material/Link'
import LockIcon         from '@mui/icons-material/Lock';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Typography from "@material-ui/core/Typography";

import makeStyles from '@mui/styles/makeStyles';

  const useStyles = makeStyles(theme => ({
    fixedbottom: {
      float: "right !important",
      fontSize: "x-large",
      position: "fixed",
      fontWeight: "bold", 
      color: "#D500F9",
      right: "0",
      bottom: "0",
      zIndex: "1040 !important"
    },
    displaynone:{
      display: "none"
    }
  }));

  const PreparingPart = (props) => {

  const classes = useStyles();
  const { state/*, actions*/ } = useStateValue();
  
  const GeneralPart = state.leops.GeneralPart;
  const Data        = state.leops.state === "INITIAL_STATE" ? "" : state.leops.Table1.Data.AssesmentRows;

  const { history, location } = props;
  
  const [ShowSlideIndex, setShowSlideIndex] = useState(-1);

  var Titles = [Languages.ManyFormsOfExpression,
                Languages.leopsRichWorldOfLanguage,
                Languages.leopsMeAndOurCommunity,
                Languages.leopsIStudyAndWork,
                Languages.leopsIGrowAndDevelop,
                Languages.leopsIWantToLearnInPreschool,
                Languages.StaffNotes,
                Languages.leopsLeopsTitle1_3,
                Languages.leopsChildStrengthsAndIterestsParent,
                Languages.leopsObjectivesAndConsiderationsForThePreschool,
                Languages.HopesForCooperation];
                
  if (location.state === undefined)
  {
    //F5/refesh
    //console.log("PreparingPart. MOUNT. location.state === undefined. GO BACK");
    history.goBack(); 
  }

  if (state.leops.state === "INITIAL_STATE" || location.state === undefined)
  {
    //console.log("PreparingPart. INITIAL_STATE RENDER EMPTY");

    return (<div className="PreparingPart"></div>);
  }
  else
  {
    const { childpicture, childFirstName, childLastName, childColor} = location.state;
  
  return (
    <div className="PreparingPart">
      <ContentBoxMUI>

        <ChildRow childpicture   = {childpicture}
                  childFirstName = {childFirstName}
                  childLastName  = {childLastName}
                  childColor     = {childColor}
                  FormId         = {GeneralPart.Id}
                  history        = {history}>
        </ChildRow>

        {ShowSlideIndex === -1 ?
        <div style={{opacity: GeneralPart.FormLocked === true ? "60%" : ""}}>
          <h5 className="textCenter mt-3">1. {Languages.eduPlanTitle1}</h5>
          <h6 className="textCenter">{Languages.LeopsTitle1_2}</h6>
         
          {Data.map((item,index)=> {
            return (
              <>
              <List className="col-12" sx={{ mt: -1}}>
              <ListItemButton className="boxed" sx={{backgroundColor: "#a6dee0", borderRadius: .5, mt: 1.5, boxShadow: 2}} onClick={(event) => setShowSlideIndex(item.AssessmentItem[0].Row-1)}>
                <ListItemText 
                  primaryTypographyProps={{fontWeight: 'Bold', fontSize: 16, ml: 2}} primary={Titles[item.AssessmentItem[0].Row-1]}/>
                <ListItemIcon><ArrowForwardIosIcon fontWeight='Bold' fontSize="small"/></ListItemIcon>
              </ListItemButton>
              </List>
              </>
            );
          })}

          {Titles.map((item,index)=> {
            if (index > 4 && index < 8)
            {
            return (
              <>
                <List className="col-12" sx={{ mt: -1}}>
                <ListItemButton className="boxed" sx={{backgroundColor: "#a6dee0", borderRadius: .5, mt: 1.5, boxShadow: 2}} onClick={(event) => setShowSlideIndex(index)}>
                  <ListItemText 
                    primaryTypographyProps={{fontWeight: 'Bold', fontSize: 16, ml: 2}} primary={item}/>
                  <ListItemIcon><ArrowForwardIosIcon fontWeight='Bold' fontSize="small"/></ListItemIcon>
                </ListItemButton>
                </List>
              </>
            );
            }
          })}
      
         {GeneralPart.FormLocked === true ?
            <div className={classes.fixedbottom} >
              {Languages.logged}
              <LockIcon></LockIcon>
            </div>
          : null}
        
        </div>
        : 
          <PreparingSubPart SelectedSlideIndex = {ShowSlideIndex} > </PreparingSubPart>
        }
     
        </ContentBoxMUI>
      </div>
  );
  }
}

export default PreparingPart;
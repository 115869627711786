import React from "react";
import "./ownmessage.css";
import moment from "moment";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
//import { sizing }           from '@mui/system';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled } from '@mui/material/styles';
import MessageText from "../../MessageText"
// TODO: the same file handling as SendersMessageBox

const CardRoot = styled(Card)(() => ({
  borderColor: "#538156", 
  borderWidth: '2px', 
  borderStyle: "solid", 
  backgroundColor: "#2E807A",
  '& a': {
    color: "#bfbdbd"
  },
  '& a:hover': {
    color: "#afd5ff"
  },
}))

const OwnMessage = ({ ownmessagesender, ownmessagetext, ownmessagedate, compact }) => {
  if (compact) {
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={2}></Grid>
          <Grid item xs={9}>

            <CardRoot width="75%">
              <div style={{ marginLeft: "4px", marginRight: "4px", marginTop: "1px", marginBottom: "1px" }}>
                {/*<Typography style={{color: "#4377ba", marginBottom:"1px"}} component="span" variant="body2">{"Tosipitkänpitkä NimiNiminenMinnaJeeJee"}</Typography>*/}
                <div style={{ marginLeft: "10px" }}>
                  <MessageText
                  color="textPrimary"
                  variant="body2"
                  component="span"
                  style={{ whiteSpace: "pre-wrap" }}>
                    {ownmessagetext}
                  </MessageText>
                  {ownmessagetext.length <= 15 ?
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      style={{ float: "right", marginTop: "10px", marginRight: "3px", fontSize: "0.875rem" }}
                    >{moment(ownmessagedate).format("DD.MM. HH:mm")}
                    </Typography>
                    : null}
                </div>
                {ownmessagetext.length > 15 ?
                  <div style={{ float: "right" }}>
                    <Typography component="span" color="textPrimary" variant="body2">{moment(ownmessagedate).format("DD.MM. HH:mm")}&nbsp;</Typography>
                  </div>
                  : null
                }
              </div>

            </CardRoot>
          </Grid>
          <Grid item xs={1}><div style={{ marginLeft: "-16px", marginTop: "3px", height: "50%", display: "inline-block", width: "0", height: "0", borderTop: "8px solid transparent", borderBottom: "8px solid transparent", borderLeft: "8px solid #2E807A" }}></div></Grid>
        </Grid>
        <div style={{ minHeight: "5px" }}></div>
      </div>
    );
  }
  else {
    return (
      <div >
        <Grid container spacing={3}>
          <Grid item xs={2}></Grid>
          <Grid item xs={10}>
            <Card width="75%" style={{ borderColor: "#4377ba", borderWidth: '5px', borderStyle: "solid", backgroundColor: "#ffffff" }}>
              <CardContent width="75%">
                <div className="ownmessagetext OMBtextWrap ownmessagesender">
                  <Typography style={{ color: "#4377ba" }} variant="body2">{ownmessagesender}</Typography>
                  <Typography color="textSecondary" style={{ whiteSpace: "pre-wrap" }}>{ownmessagetext}</Typography>
                  <span className="ownsentdate OMBtextWrap ownmessagesender">
                    <Typography style={{ color: "#4377ba" }} variant="body2">{moment(ownmessagedate).format("DD.MM. HH:mm")}</Typography>
                  </span>
                </div>
              </CardContent>
            </Card>
            <div className="ownsendertriangle2" />
          </Grid>

          <br /><br />
        </Grid>
      </div>
    );
  }

}

export default OwnMessage;

import React, { useState, useEffect } from "react";
import {useStateValue} from "../../../State";
import Typography from "@mui/material/Typography";
import Accordion         from '@mui/material/Accordion';
import AccordionSummary  from '@mui/material/AccordionSummary';
import AccordionDetails  from '@mui/material/AccordionDetails';
import ExpandMoreIcon         from '@mui/icons-material/ExpandMore';
import HomeWorkIcon         from '@mui/icons-material/HomeWork';
import Languages              from '../../../translations';
import PlacementInfoLoadSuccessView from "./PlacementInfoLoadSuccessView";
import {isAnyPropUndefinedOrNull} from "../../../utils";

const styles = {
  color: "#4377BA",
  fontSize: "14pt",
  fontWeight: "bold",
}

const PlacementInfo = (props) => {

const {oneChild, panelOpennessState, childId, childColor = "#4377BA"} = props;

const { state, actions } = useStateValue();

const data = state.centreinfos.placements.data;

const [daycarePanelOpen, setDaycarePanelOpen] = useState((typeof panelOpennessState === "undefined" ? false : panelOpennessState));

const changeDaycareOpennessState = () => {
  setDaycarePanelOpen(!daycarePanelOpen);
}

const [ isMount, setIsMount ] = useState(true);

useEffect(() => {
  if (isMount === true && daycarePanelOpen) {
    actions.triggerLoadPlacementAndCentreInfo(oneChild === true ? childId : null);
    setIsMount(false);
  }
}, [isMount, setIsMount, actions, props, daycarePanelOpen])

if (isAnyPropUndefinedOrNull(props)) {
  return(<div data-testid="PlacementInfoPropsUndefinedOrNull"></div>)
}

return (

  <React.Fragment>
  
  {/*oneChild ? <Typography variant="h6" component="h2"> {Languages.placementInfo}</Typography> : null*/}

    <Accordion id="test_ChildInfo_PlacementInfo"
      style={{ border: "5px", borderLeft: "12px", borderStyle:"solid", borderColor: childColor, backgroundColor: "#ffffff", color: "#181818" }}
      expanded={daycarePanelOpen}
      onChange={() => changeDaycareOpennessState()}
    >

      <AccordionSummary 
        expandIcon={<ExpandMoreIcon />} 
        aria-controls="placements-panel1a-content"
        id="test_ChildPlacements1"
        data-testid={"placement-info-extendable-button" + daycarePanelOpen}>
          <Typography sx={styles}><HomeWorkIcon /> <b style={{marginLeft: "10px", display: "inline"}}>{Languages.placementDayCareCentre}</b></Typography>
      </AccordionSummary>

      <AccordionDetails>

        {state.centreinfos.placements.status === "PL_CENTRE_INFOS_LOAD_SUCCESS" &&

          <PlacementInfoLoadSuccessView
            placementData={data}
            oneChild={oneChild}
          />

        }

        {state.centreinfos.placements.status === "PL_CENTRE_INFOS_LOAD_FAILED" &&

          <Typography id="test_placem_errCouldNotLoadData" data-testid="placem_errCouldNotLoadData" component={"span"}>{Languages.errCouldNotLoadData}</Typography>

        }

        {state.centreinfos.placements.status === "LOADING_PL_CENTRE_INFOS" &&

          <Typography id="test_placem_waitingservertoloaddata" component={"span"}>{Languages.waitingservertoloaddata}</Typography>

        }

      </AccordionDetails>

    </Accordion>


      
  </React.Fragment>
)

}

export default PlacementInfo;
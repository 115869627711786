import React, { useState, useEffect } from "react";
import {useStateValue} from "../../../../State";

import Languages        from "../../../../translations";

import Accordion        from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon   from '@mui/icons-material/ExpandMore';
import Typography       from "@mui/material/Typography";

import CommentsReadOnly from '../../../Vasu/CommentsReadOnly';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  nobottomline: {
    borderBottom: "none",
    color: "black",
    backgroundColor: "#ffffff",
    flex: "0 0 100%"
  },
}));


const SupportTable2 = (props) => {

  const classes = useStyles();

  const { Form, callback, closeView } = props;

  const { state/*, actions*/ } = useStateValue();
  const FormT2 = Form === 0 ? state.leops.Table3.Form1.Table[1] :
                 Form === 1 ? state.leops.Table3.Form2.Table[1]:
                 Form === 2 ? state.leops.Table3.Form3.Table[1] : 
                              state.leops.Table3.Form4.Table[1];

  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
    callback("TABLE2");
  }

  /*console.log("SupportTable2. MOUNT",
              //"\n state.leops.Table3: ",state.leops.Table3,  
              "\n Form: ", Form,
              "\nFormT2: ", FormT2);*/

  useEffect(() => {
    //console.log("Table1. useEffect. Close view");
    setExpanded(false);
  },[closeView]); 

  return (
      <Accordion className="painikkeet"
                 style={{ backgroundColor: "#4377BA"/*, color: "black"*/ }}
                 //disabled = {item.Saved === "" ? true: false}
                 expanded={expanded}
                 onChange={() => toggleExpanded()}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel3a-content"
                          id="test_Leops_SupportTable2">
          <Typography className="heading"> {Form === 0 ? Languages.AssessmentEnhancedSupportAndArrangements :
                                            Form === 1 ? Languages.PreschooEducationlProviderAssessment :
                                                         Languages.LeopsFormTitle3}</Typography>
        </AccordionSummary>
        <div className={classes.nobottomline}>

            <AccordionDetails>
              <CommentsReadOnly Title1  = {<Typography color="textSecondary" sx={{textAlign:"left", fontWeight: 'Bold', fontSize: 14, color: "#424242", pt:1, ml:1}}>{Form === 0 ? Languages.AssessmentEnhancedSupportAndArrangements :
                                           Form === 1 ? Languages.PreschooEducationlProviderAssessment :
                                           Languages.LeopsFormTitle3_1 + "\n(" + Languages.LeopsFormTitle3_1_1.toLowerCase()+")" }</Typography>}
                                Title2  = {null} // kommentoija h-elementtiin
                                Title3  = {null}
                                BgColor = {classes.nobottomline.backgroundColor} 
                                Data    = {{Text: FormT2.TableRow[0].RowItem[0].Editor + "\n" + FormT2.TableRow[0].RowItem[0].RowText}}>
              </CommentsReadOnly>
            </AccordionDetails>

          {Form === 2 || Form === 3 ?
          <div>
            <AccordionDetails>
              <CommentsReadOnly Title1  = {<Typography color="textSecondary" sx={{textAlign:"left", fontWeight: 'Bold', fontSize: 14, color: "#424242", pt:1, ml:1}}>{Languages.LeopsFormTitle3_2 + "\n(" + Languages.LeopsFormTitle3_2_1.toLowerCase()+")"}</Typography>}
                                Title2  = {null}
                                Title3  = {null}
                                BgColor = {classes.nobottomline.backgroundColor} 
                                Data    = {{Text: FormT2.TableRow[1].RowItem[0].Editor + "\n" + FormT2.TableRow[1].RowItem[0].RowText}}>
              </CommentsReadOnly>
            </AccordionDetails>

            <AccordionDetails>
              <CommentsReadOnly Title1  = {<Typography color="textSecondary" sx={{textAlign:"left", fontWeight: 'Bold', fontSize: 14, color: "#424242", pt:1, ml:1}}>{Languages.LeopsFormTitle3_3 + "\n(" + Languages.LeopsFormTitle3_3_1.toLowerCase()+")"}</Typography>}
                                Title2  = {null}
                                Title3  = {null}
                                BgColor = {classes.nobottomline.backgroundColor} 
                                Data    = {{Text: FormT2.TableRow[2].RowItem[0].Editor + "\n" + FormT2.TableRow[2].RowItem[0].RowText}}>
              </CommentsReadOnly>
            </AccordionDetails>

            <AccordionDetails>
              <CommentsReadOnly Title1  = {<Typography color="textSecondary" sx={{textAlign:"left", fontWeight: 'Bold', fontSize: 14, color: "#424242", pt:1, ml:1}}>{Languages.StudentCareMeasures}</Typography>}
                                Title2  = {null}
                                Title3  = {null}
                                BgColor = {classes.nobottomline.backgroundColor} 
                                Data    = {{Text: FormT2.TableRow[3].RowItem[0].Editor + "\n" + FormT2.TableRow[3].RowItem[0].RowText}}>
              </CommentsReadOnly>
            </AccordionDetails>

            <AccordionDetails>
              <CommentsReadOnly Title1  = {<Typography color="textSecondary" sx={{textAlign:"left", fontWeight: 'Bold', fontSize: 14, color: "#424242", pt:1, ml:1}}>{Form === 2 ? Languages.LeopsEnhancedTitle3_4 : Languages.LeopsHojksTitle3_4}</Typography>}
                                Title2  = {null}
                                Title3  = {null}
                                BgColor = {classes.nobottomline.backgroundColor} 
                                Data    = {{Text: FormT2.TableRow[4].RowItem[0].Editor + "\n" + FormT2.TableRow[4].RowItem[0].RowText}}>
              </CommentsReadOnly>
            </AccordionDetails>

            <AccordionDetails>
              <CommentsReadOnly Title1  = {<Typography color="textSecondary" sx={{textAlign:"left", fontWeight: 'Bold', fontSize: 14, color: "#424242", pt:1, ml:1}}>{Languages.LeopsFormTitle3_5}</Typography>}
                                Title2  = {null}
                                Title3  = {null}
                                BgColor = {classes.nobottomline.backgroundColor} 
                                Data    = {{Text: FormT2.TableRow[5].RowItem[0].Editor + "\n" + FormT2.TableRow[5].RowItem[0].RowText}}>
              </CommentsReadOnly>
            </AccordionDetails>

          <AccordionDetails>
            <CommentsReadOnly Title1  = {<Typography color="textSecondary" sx={{textAlign:"left", fontWeight: 'Bold', fontSize: 14, color: "#424242", pt:1, ml:1}}>{Form === 2 ? Languages.DocumentsUsedInDrafting : Languages.LeopsHojksTitle3_6}</Typography>}
                              Title2  = {null}
                              Title3  = {null}
                              BgColor = {classes.nobottomline.backgroundColor} 
                              Data    = {{Text: FormT2.TableRow[6].RowItem[0].Editor + "\n" + FormT2.TableRow[6].RowItem[0].RowText}}>
            </CommentsReadOnly>
          </AccordionDetails>
          </div>
          :
          null}

          {Form === 3 ?
          <AccordionDetails>
            <CommentsReadOnly Title1  = {<Typography color="textSecondary" sx={{textAlign:"left", fontWeight: 'Bold', fontSize: 14, color: "#424242", pt:1, ml:1}}>{Languages.DocumentsUsedInDrafting}</Typography>}
                              Title2  = {null}
                              Title3  = {null}
                              BgColor = {classes.nobottomline.backgroundColor} 
                              Data    = {{Text: FormT2.TableRow[7].RowItem[0].Editor + "\n" + FormT2.TableRow[7].RowItem[0].RowText}}>
            </CommentsReadOnly>
          </AccordionDetails>
          : 
          null}
      </div>
      </Accordion>
  );
}

export default SupportTable2;
import React from "react";
import "../newmessage.css";
import Languages from "../../../../translations";
import _cloneDeep from "lodash/cloneDeep"; // deep clones an array so that the copy is not just shallow
import RecipientSelection from "./RecipientSelection";


const SelectRecipientsView = ({RGroup, RPersons, currentlyCheckedList, updateCheckedList}) => {

  const handleCheckboxes = (event) => {
    
    if (event.target.checked === true) {
      let ko = _cloneDeep(currentlyCheckedList);
      ko.push(event.target.value);
      updateCheckedList(ko);
    }
    else {
      let ky = _cloneDeep(currentlyCheckedList);
      for (let i = 0; i < ky.length; i++) {
        if (ky[i] === event.target.value) {
          ky.splice(i, 1);
        }
      }
      updateCheckedList(ky);
    }
  }


  return (

    <React.Fragment>

      <RecipientSelection 
        title={Languages.msgGroupsAndDaycares}
        infotext={Languages.msgInfo2}
        potentialRecipients={RGroup}
        handleCheckboxes={handleCheckboxes}
        currentlyCheckedList={currentlyCheckedList}
        automationTestId={"test_Group"}
      />

      <RecipientSelection
        title={Languages.msgStaffPersons}
        infotext={Languages.msgInfo1}
        potentialRecipients={RPersons}
        handleCheckboxes={handleCheckboxes}
        currentlyCheckedList={currentlyCheckedList}
        automationTestId={"test_Empl"}
      />

    </React.Fragment>
)
}

export default SelectRecipientsView;
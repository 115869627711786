import React                  from "react";
import Languages              from "../../../translations";
import "../invoiceBasis.css";
import "./BookingsDataView.css";
import TableBody              from "@mui/material/TableBody";
import TableCell              from "@mui/material/TableCell";
import TableHead              from "@mui/material/TableHead";
import TableRow               from "@mui/material/TableRow";
import moment                 from "moment";

const BookingsDataView = ({nursingDays}) => {


  const checkWhetherZero = (hoursAndMinutes) => {
    if (hoursAndMinutes === "0 t 00 min" || hoursAndMinutes === "0 h 00 min") {
      return(true);
    }
    else {
      return(false);
    }
  }

  const bookingsColor = "#ffc000";
  const basicCell = {backgroundColor: "#FFFFFF", color: "#000000", wordWrap: "break-word"};
  const basicCellStrong = {backgroundColor: "#FFFFFF", color: "#000000", wordWrap: "break-word", fontWeight: "bold"};
  const bookingsHighlight = {backgroundColor: bookingsColor, fontWeight: "bold"};
  const toteumaHighlight = {backgroundColor: "#6BAF6E", fontWeight: "bold"};

  return(

    <React.Fragment>
    <TableHead id="test_Invoice_BookingsView">
      <TableRow>
        <TableCell padding="none" align="center" variant="head" style={basicCellStrong}>{Languages.uctDateAbbr}</TableCell>
        <TableCell padding="none" align="center" variant="head" style={bookingsHighlight}>{Languages.uctBooking}</TableCell>
        <TableCell padding="none" align="center" variant="head" style={bookingsHighlight}>{Languages.uctDuration}</TableCell>
        <TableCell padding="none" align="center" variant="head" style={bookingsHighlight}>{Languages.uctBooker}</TableCell>
        <TableCell padding="none" align="center" variant="head" style={basicCellStrong}>{Languages.uctBilling}</TableCell>
      </TableRow>
    </TableHead>
    
    
    <TableBody>
      {nursingDays.map((row, index) => {
      
      return(
      <TableRow hover key={"row" + index}>
        <TableCell padding="none" align="center" style={basicCell}>{moment(row.Date).format("DD.MM.")}</TableCell>
        <TableCell padding="none" align="center" style={basicCell}>
          <div>
            { 
              typeof row.NursingTimesTimes === "undefined" || row.NursingTimesTimes.length === 0 ? 
                <div>-</div> 
              :
              row.NursingTimesTimes.map((time, index) => {
                return(
                  <div style={{display: "block"}} key={time + index}>{time}</div>
                )
              })
            }
            {
              row.SingleStatusesTexts.map((statusText, index) => {
                return(
                  <div key={"statusText10872" + index}>
                    {statusText}
                  </div>
                )
              }  
              )
            }
          </div>
        </TableCell>
        <TableCell padding="none" align="center" data-testid={"cell-k3mf90al" + (index + 1)}
          style={row.ReservationLonger ? bookingsHighlight : basicCell }>
            {checkWhetherZero(row.ReservationHours) ? "-" : row.ReservationHours}
        </TableCell>
        <TableCell padding="none" align="center" 
          style={/*{wordWrap: "break-word"}*/ basicCell}>
            {row.ReserverName !== null ? row.ReserverName : ""}
        </TableCell>
        <TableCell padding="none" align="center" data-testid={"cell-ej3kssjf" + (index + 1)}
          style={row.ReservationLonger ? bookingsHighlight : 
            row.RealizationLonger ? toteumaHighlight : basicCell}>
              {row.PaymentHours}
        </TableCell>

      </TableRow>
      )
      })
      }
    </TableBody>
  </React.Fragment>

  )

}

export default BookingsDataView;
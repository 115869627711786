import React, { useEffect, useState }   from "react";
import "./invoiceBasis.css";
import ChildNamePicture       from "../../Components/ChildNamePicture";
import InvoiceSummaryButtons  from "./Components/InvoiceSummaryButtons";
import ToteumaView            from "./Components/ToteumaView";
import BookingsDataView       from "./Components/BookingsDataView";
import InvoicePreSchoolView   from "./Components/InvoicePreSchoolView";
import Table                  from "@mui/material/Table";
import TableContainer         from "@mui/material/TableContainer";
import Paper                  from "@mui/material/Paper";
import FixedBottomBarAligned  from "../../Components/FixedBottomBarAligned";
import InvoiceDataBottomBarContent from "./Components/InvoiceDataBottomBarContent";
import EmptyDivThatTakesUpSpace from "../../Components/EmptyDivThatTakesUpSpace";


const InvoiceDataSuccessView = ({childId, summaryData}) => {

  const [ bookingsShown, setBookingsShown ] = useState(true);
  const [ toteumaShown, setToteumaShown ] = useState(false);
  const [ esiopetusShown, setEsiopetusShown ] = useState(false);
  const [ totalHours, setTotalHours ] = useState(null);

  useEffect(() => {
    //console.log("summaryData: ", summaryData);
    if (typeof summaryData !== "undefined" && summaryData !== null) {
      setTotalHours({
        PaymentHours: summaryData.TotalPaymentHours,
        PreSchoolHours: summaryData.TotalPreSchoolHours,
        StatusHours: summaryData.TotalStatusHours,
        ReservationHours: summaryData.TotalReservationHours,
        InvoiceFreeHours: summaryData.TotalInvoiceFreeHours
      });
    }
  }, [summaryData])

  const showBookingsView = () => {
    setBookingsShown(true);
    setToteumaShown(false);
    setEsiopetusShown(false);
  }

  const showToteumaView = () => {
    setBookingsShown(false);
    setToteumaShown(true);
    setEsiopetusShown(false);
  }

  const showEsiopetusView = () => {
    setBookingsShown(false);
    setToteumaShown(false);
    setEsiopetusShown(true);
  }

  const activeButton = {
    bookings: bookingsShown,
    toteuma: toteumaShown,
    esiopetus: esiopetusShown
  }

  const changeViewTo = {
    bookingsView: showBookingsView,
    toteumaView: showToteumaView,
    esiopetusView: showEsiopetusView
  }

  if (typeof summaryData === "undefined" || summaryData === null ||
      typeof childId === "undefined" || childId === null) {
    return(<div data-testid="InvoiceData-0h2ls7ns"></div>);
  }
  else {
    return (
      
      <div style={{maxWidth: "800px", margin: "auto"}}> 
        
        <React.Fragment>
          <ChildNamePicture childId={childId} childName={summaryData.PersonName}/>

          <InvoiceSummaryButtons 
            changeViewTo={changeViewTo}
            activeButton={activeButton}
          />

          <TableContainer component={Paper}>
            <Table size="small" border={1} bordercolor="#000001" aria-label="invoice basis data table" id="test_invoice_data_table">

                { bookingsShown &&
                  <BookingsDataView nursingDays={summaryData.NursingDays}/>
                }
                { toteumaShown &&
                  <ToteumaView nursingDays={summaryData.NursingDays}/>
                }
                { esiopetusShown &&
                  <InvoicePreSchoolView nursingDays={summaryData.NursingDays}/>
                }

            </Table>
          </TableContainer>

          <EmptyDivThatTakesUpSpace height="100px"/>

          <FixedBottomBarAligned>
            {totalHours !== null &&
              <InvoiceDataBottomBarContent
                totalHours={totalHours}
              />
            }
          </FixedBottomBarAligned>

        </React.Fragment>
      </div>
    );
  }
}


export default InvoiceDataSuccessView;
import React, { useState, useEffect } from "react";
import {useStateValue} from "../../State";
import Typography from "@mui/material/Typography";
import Languages              from '../../translations';
import Badge                  from "@mui/material/Badge";
import withStyles from '@mui/styles/withStyles';
import {types}                from "../../Reducers/actionTypes";
import PermissionQuerySuccess from "./Components/PermissionQuerySuccess";
import SinglePermQueryView from "./Components/SinglePermQueryView";
import Button                 from "@mui/material/Button";
import CircularProgress from '@mui/material/CircularProgress';
import UpdateIcon from '@mui/icons-material/Update';



// PermissionQueries = kyselyt = lapsen lyhytaikaiset luvat
const PermissionQueries = (props) => {
  const StyledBadge = withStyles(theme => ({
    badge: {
      right: -20,
      top: 10,
      padding: '0 4px',
      width: 25,
      height: 25,
      borderRadius: 50,
    },
  }))(Badge);


  const { state, actions } = useStateValue();
  //const {mainstats} = state; 

  const data = state.municipalpermissionquestions.permissionQueryList.data;

  const singlePermData = state.municipalpermissionquestions.onePermissionQuery.data;

  const listStatus = state.municipalpermissionquestions.permissionQueryList.status;

  const [queryPanelOpen, setQueryPanelOpen] = useState(true);

  const [listView, setListView] = useState(true);
/*
  const changeOpennessState = () => {
    setQueryPanelOpen(!queryPanelOpen);
  }*/

  const [ isMount, setIsMount ] = useState(true);
  //const [ unansweredNumber, setUnansweredNumber] = useState(null);

  useEffect(() => {
    if (isMount && queryPanelOpen) {
      actions.triggerLoadPermissionQueryList();
      setIsMount(false);
    }
  }, [isMount, setIsMount, actions, props, queryPanelOpen])

  const changeListView = () => {
    if (!listView) {
      reloadList();
    }
    setListView(!listView);
    
  }

  const reloadList = () => {
    actions.triggerLoadPermissionQueryList();
  }

  if (listView) { return (

    <React.Fragment>
    
    <div style={{marginTop: "10px"}}></div>

      <Button disabled={ listStatus === types.LOADING_PERMISSION_QUERIES} color="secondary" variant="contained" onClick={reloadList}><UpdateIcon />&nbsp;{Languages.permRefresh}</Button>
      <div style={{height: "10px"}}></div>

          {listStatus === types.LOAD_PERMISSION_QUERIES_SUCCESS &&

            <PermissionQuerySuccess
              permQueryData={data}
              changeListView={changeListView}
            />

          }

          {listStatus === types.LOAD_PERMISSION_QUERIES_FAILED &&

            <Typography 
              id="test_permission_queries_errCouldNotLoadData" 
              data-testid="permission_queries_errCouldNotLoadData" 
              component={"span"}>{Languages.errCouldNotLoadData}
            </Typography>

          }

          {listStatus === types.LOADING_PERMISSION_QUERIES &&
            <div style={{width: '100%', textAlign: "center"}}>
            <CircularProgress />
            <br />
            <Typography 
              id="test_permission_queries_waitingservertoloaddata" 
              component={"span"}>{Languages.waitingservertoloaddata}
            </Typography>
            </div>
          }



        
    </React.Fragment>
  ) 
}

else {
  return(
    <SinglePermQueryView 
      data={singlePermData}
      closeSinglePermView={changeListView}/>
  )
}

}

export default PermissionQueries;
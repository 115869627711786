import React, { useEffect, useState } from "react";

import { useStateValue } from "../../../../State";

import { PickersDay } from "@mui/x-date-pickers/PickersDay";

import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";

import IconButton from "@mui/material/IconButton";
import moment from "moment";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ChildProfilePicture from "../../../ChildProfilePicture";
import CloseIcon from "@mui/icons-material/Close";

import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

import { styled } from "@mui/material/styles";

import Divider from "@mui/material/Divider";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import DialogActions from "@mui/material/DialogActions";

import Slider from "@mui/material/Slider";

import "./CTBBottomNav2.css";

import Chip from "@mui/material/Chip";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";

import MenuItem from "@mui/material/MenuItem";

import Select from "@mui/material/Select";

import Badge from "@mui/material/Badge";
import { types } from "../../../../Reducers/actionTypes";
import TimeSlider from "../common/TimeSlider";
import CalendarTabs from "../common/CalendarTabs";
import ChildSelection from "../common/ChildSelection";
import CentreSelection from "../common/CentreSelection";
import CircularProgress from "@mui/material/CircularProgress";
const CTBNewResDialog = ({
  handleClick,
  newResDialogOpen,
  names,
  personName,
  handleFocusDay,
  LoadCareTimesCuick,
}) => {
  const { state, actions } = useStateValue();
  const saved = state.caretimes.CTSaved;
  const [saving, setSaving] = React.useState(false);
  const [holidays, setHolidays] = React.useState([]);

  useEffect(() => {
    switch (state.caretimes.Quick.state) {
      case undefined:
        break;
      case types.CARETIMES_QUICK_LOAD_OK:
        setExtRes(state.caretimes.Quick.DayList);
        setHolidays(state.caretimes.Quick.HolidayList);
        break;
      case types.CARETIMES_QUICK_LOAD_FAILED:
        break;
      default:
        break;
    }
  }, [state.caretimes.Quick.state]);

  useEffect(() => {
    console.log("testi testi 1");
    switch (state.caretimes.CTSaved.state) {
      case types.CARETIMES_SAVED_OK:
        console.log("testi testi 2");
        actions.triggerAddToSnackBarQueue({
          message: "Hoitoajan tallennus onnistui",
          severity: "success",
        });
        setSaving(false);
        //state.caretimes.CTSaved.state = undefined;
        handleClick();
        break;
      case types.SAVING_CARETIMES:
        console.log("tallentaa");
        setSaving(true);
        break;
      case types.CARETIMES_SAVED_FAILED:
        console.log("testi 5555");
        actions.triggerAddToSnackBarQueue({
          message: "Hoitoajan tallennus epäonnistui",
          severity: "error",
        });
        setSaving(false);
        //state.caretimes.CTSaved.state = undefined;
        handleClick();
        break;
      default:
        break;
    }
  }, [state.caretimes.CTSaved.state]);

  useEffect(() => {
    setListOfChildren([]);
    setDeleteOld("");
    setHighlightedDays([]);
    setClosePopUp(false);
    setResChildren([]);
    setExistSelected(false);
  }, [newResDialogOpen]);

  //Reservation start/end times stored as minutes for the slider component
  const [value, setValue] = React.useState([
    moment.duration(moment("2020-01-01 08:00").format("HH:mm")).asMinutes(),
    moment.duration(moment("2020-01-01 16:00").format("HH:mm")).asMinutes(),
  ]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [existSelected, setExistSelected] = React.useState(false);

  const handleExistSelected = () => {
    setExistSelected(!existSelected);
  };
  //Start and end times stored as minutes
  const [ResLenght, setResLenght] = React.useState([
    moment.duration(moment("2020-01-01 08:00").format("HH:mm")).asMinutes(),
    moment.duration(moment("2020-01-01 16:00").format("HH:mm")).asMinutes(),
  ]);

  const handleResLenght = (newLenght) => {
    console.log(newLenght);
    //console.log(moment(newLenght[0]));
    setResLenght(newLenght);
  };

  const [resChildren, setResChildren] = React.useState([]);

  const handleResChildren = (child, centre) => {
    if (resChildren.some((resChild) => resChild.childId === child)) {
      var updatedresChildren = resChildren.filter(
        (resChild) => resChild.childId !== child
      );
      updatedresChildren.push({ childId: child, resId: centre });
      setResChildren(updatedresChildren);
    } else {
      resChildren.push({ childId: child, resId: centre });
    }

    //console.log(resChildren);
  };

  //Handle selected Children
  const [listOfChildren, setListOfChildren] = React.useState([]);

  const handleChildren = (event, newPersonNames) => {
    setListOfChildren(newPersonNames);
    //console.log(newPersonNames);
  };

  //Handle DeleteOld if existing res days selected
  const [DeleteOld, setDeleteOld] = React.useState("");

  const handleDeleteOld = (event, newReason) => {
    if (newReason !== null) {
      setDeleteOld(newReason);
    }
  };

  const [extRes, setExtRes] = React.useState([]);

  //Handle single selected days
  const [highlightedDays, setHighlightedDays] = React.useState([]);

  const handleHighLightedDays = (newDay) => {
    if (highlightedDays.some((day) => day.isSame(newDay, "day"))) {
      var updated = highlightedDays.filter(
        (day) => day.isSame(newDay, "day") == false
      );
      setHighlightedDays(updated);
    } else {
      highlightedDays.push(moment(newDay));
    }
    //console.log(highlightedDays);
    //console.log(extRes);
    if (
      highlightedDays.some(
        (r) => extRes.indexOf(moment(r).format("YYYY-MM-DDTHH:mm:ss")) >= 0
      )
    ) {
      setExistSelected(true);
    } else {
      setExistSelected(false);
    }
  };

  //Handle Week Selection
  const [startDate, setStartDate] = React.useState(null);

  const handleWeek = (date) => {
    setStartDate(date);
    let ListDates = [];
    for (
      var m = moment(startDate).startOf("week");
      m.isSameOrBefore(moment(startDate).endOf("week"));
      m.add(1, "days")
    ) {
      ListDates.push(moment(m));
    }
    if (
      ListDates.some(
        (r) => extRes.indexOf(moment(r).format("YYYY-MM-DDTHH:mm:ss")) >= 0
      )
    ) {
      console.log("löytypi");
      setExistSelected(true);
    } else {
      setExistSelected(false);
    }
  };

  //Handle date Range

  const [startDay, setStartDay] = React.useState(null);
  const [endDay, setEndDay] = React.useState(null);

  const handleDayRange = (newDay) => {
    if (startDay == null) {
      setStartDay(newDay);
    } else {
      if (endDay == null) {
        if (newDay.isSameOrBefore(startDay)) {
          setStartDay(newDay);
        } else {
          setEndDay(newDay);
          let ListDates = [];
          for (
            var m = moment(startDay);
            m.isSameOrBefore(moment(newDay));
            m.add(1, "days")
          ) {
            ListDates.push(moment(m));
          }
          if (
            ListDates.some(
              (r) =>
                extRes.indexOf(moment(r).format("YYYY-MM-DDTHH:mm:ss")) >= 0
            )
          ) {
            console.log("löytypi");
            setExistSelected(true);
          } else {
            setExistSelected(false);
          }
        }
      } else {
        setStartDay(newDay);
        setEndDay(null);
      }
    }
  };

  //Handle mode days/weeks/range for saving the dates
  const [mode, setMode] = React.useState(0);

  const handleMode = (value) => {
    //Clear all selections when changing mode // WIP -- Add confirmation if dates selected
    setHighlightedDays([]);
    setStartDay(null);
    setEndDay(null);
    setStartDate(null);
    setMode(value);
    setExistSelected(false);
  };

  //handle closing if user has made any actions popup

  const [closePopUp, setClosePopUp] = React.useState(false);
  const handleCloseClick = () => {
    if (
      listOfChildren.length > 0 ||
      DeleteOld !== "" ||
      highlightedDays.length > 0 ||
      startDate !== null ||
      startDay !== null ||
      endDay !== null
    ) {
      setClosePopUp(true);
    } else {
      handleClick();
    }
  };

  const handleClosePopUp = () => {
    setClosePopUp(!closePopUp);
  };

  const saveAbsent = () => {
    let ListDates = [];

    switch (mode) {
      case 0:
        console.log("päivät");
        ListDates = highlightedDays;
        break;
      case 1:
        console.log("viikot");
        for (
          var m = moment(startDate).startOf("week");
          m.isSameOrBefore(moment(startDate).endOf("week"));
          m.add(1, "days")
        ) {
          ListDates.push(moment(m));
        }
        break;
      case 2:
        console.log("väli");
        for (
          var m = moment(startDay);
          m.isSameOrBefore(moment(endDay));
          m.add(1, "days")
        ) {
          if (moment(m).isoWeekday() !== 6 && moment(m).isoWeekday() !== 7) {
            ListDates.push(moment(m));
          }
        }
        break;
      default:
        console.log("mitä vittua");
        break;
    }

    handleFocusDay(moment.min(ListDates));
    var personAbsent = [];

    resChildren.forEach((child) => {
      personAbsent.push({
        PersonId: child.childId,
        Type: "I",
        PlacementId: child.resId,
      });
    });
    console.log("Smallest date: ", moment.min(ListDates));
    console.log({
      PersonList: personAbsent,
      Dates: ListDates,
      StartTime: moment()
        .startOf("day")
        .add(ResLenght[0], "minutes")
        .format("HH:mm"),
      EndTime: moment()
        .startOf("day")
        .add(ResLenght[1], "minutes")
        .format("HH:mm"),
      DeleteOld: false,
      EveningPreschool: false,
    });

    actions.triggerSaveCareTimes({
      PersonList: personAbsent,
      Dates: ListDates,
      StartTime: ResLenght[0],
      EndTime: ResLenght[1],
      DeleteOld: DeleteOld === "Ei" ? true : false,
      EveningPreschool: false,
    });
  };

  return (
    <Dialog
      disablePadding
      disableGutters
      fullWidth
      onClose={handleClick}
      open={newResDialogOpen}
      sx={{
        "& .MuiDialog-container .MuiDialog-paper": {
          margin: 0,
          borderRadius: 2,
          width: "96%",
        },
        width: "100%",
      }}
    >
      <DialogTitle textAlign="center" style={{ backgroundColor: "#FFFFFF" }}>
        <Typography color="textSecondary" fontWeight="bold">
          {"Ilmoita hoitoaika"}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleCloseClick}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "success",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        dividers
        disableGutters
        disablePadding
        sx={{ padding: 0 }}
        style={{ backgroundColor: "#FFFFFF" }}
      >
        {saving === true ? (
          <>
            <Stack spacing={2} alignItems="center" sx={{ mt: 2, mb: 2 }}>
              <CircularProgress />
              <Typography color="textSecondary" fontSize="medium">
                {"Tallennetaan hoitoaikoja"}
              </Typography>
            </Stack>
          </>
        ) : (
          <Box sx={{ mb: 2 }}>
            <div>
              <>
                <Divider
                  sx={{
                    m: 2,
                    "&::before, &::after": {
                      borderColor: "#0074C8",
                    },
                  }}
                  textAlign="center"
                >
                  <Chip
                    label={"1. Valitse Lapset"}
                    sx={{ backgroundColor: "#0074C8" }}
                  />
                </Divider>
                <ChildSelection
                  names={names}
                  listOfChildren={listOfChildren}
                  handleFormat={handleChildren}
                ></ChildSelection>
              </>
              <Divider
                sx={{
                  m: 2,
                  "&::before, &::after": {
                    borderColor: "#0074C8",
                  },
                }}
                textAlign="center"
              >
                <Chip
                  label={"2. Valitse Yksiköt"}
                  sx={{ backgroundColor: "#0074C8" }}
                />
              </Divider>
              {listOfChildren.length < 1 ? (
                <Typography
                  sx={{ mt: 1 }}
                  color="warning.main"
                  fontSize="medium"
                  textAlign="center"
                >
                  {"Valitse ensin vähintään yksi lapsi"}
                </Typography>
              ) : (
                <CentreSelection
                  listOfChildren={listOfChildren}
                  names={names}
                  handleResChildren={handleResChildren}
                  displayName={true}
                ></CentreSelection>
              )}
              <Divider
                sx={{
                  m: 2,
                  "&::before, &::after": {
                    borderColor: "#0074C8",
                  },
                }}
                textAlign="center"
              >
                <Chip
                  label={"2. Valitse varauksen kesto"}
                  sx={{ backgroundColor: "#0074C8" }}
                />
              </Divider>
              <>
                <TimeSlider handleResLenght={handleResLenght} />
              </>
              <>
                <Divider
                  sx={{
                    m: 2,
                    "&::before, &::after": {
                      borderColor: "#0074C8",
                    },
                  }}
                  textAlign="center"
                >
                  <Chip
                    label={"3. Valitse Päivät"}
                    sx={{ backgroundColor: "#0074C8" }}
                  />
                </Divider>
                <CalendarTabs
                  handleHighLightedDays={handleHighLightedDays}
                  highlightedDays={highlightedDays}
                  handleMode={handleMode}
                  handleDayRange={handleDayRange}
                  startDay={startDay}
                  endDay={endDay}
                  startDate={startDate}
                  handleWeek={handleWeek}
                  shortestLockTime={moment(state.children.ShortestLockTime)}
                  LoadCareTimesCuick={LoadCareTimesCuick}
                ></CalendarTabs>
              </>
            </div>

            {existSelected && (
              <Box alignItems="center" justify="center">
                <Divider
                  sx={{
                    m: 2,
                    "&::before, &::after": {
                      borderColor: "#0074C8",
                    },
                  }}
                  textAlign="center"
                >
                  <Chip
                    label={"4. Säilytetäänkö olemassaolevat varaukset?"}
                    sx={{ backgroundColor: "#0074C8" }}
                  />
                </Divider>
                <Typography color="textSecondary" textAlign="center">
                  {
                    "Valituilla päivillä on olemassaolevia varauksia, haluatko säilyttää vanhat varaukset?"
                  }
                </Typography>
                <ToggleButtonGroup
                  sx={{ width: "100%", mt: 0.5 }}
                  value={DeleteOld}
                  exclusive
                  onChange={handleDeleteOld}
                >
                  <ToggleButton
                    value={"Kyllä"}
                    sx={{
                      width: "40%",
                      ml: "10%",
                    }}
                  >
                    <Box sx={{ width: "100%" }}>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Box sx={{ width: "20%" }}></Box>
                        <Box sx={{ width: "60%" }}>
                          <Typography
                            color="textSecondary"
                            fontSize="medium"
                            textAlign="center"
                          >
                            {"Kyllä"}
                          </Typography>
                        </Box>
                        <Box sx={{ width: "20%" }}>
                          {DeleteOld === "Kyllä" && (
                            <CheckIcon color="success" />
                          )}
                        </Box>
                      </Stack>
                    </Box>
                  </ToggleButton>
                  <ToggleButton
                    value={"Ei"}
                    sx={{
                      width: "40%",
                    }}
                  >
                    <Box sx={{ width: "100%" }}>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Box sx={{ width: "20%" }}></Box>
                        <Box sx={{ width: "60%" }}>
                          <Typography
                            color="textSecondary"
                            fontSize="medium"
                            textAlign="center"
                          >
                            {"Ei"}
                          </Typography>
                        </Box>
                        <Box sx={{ width: "20%" }}>
                          {DeleteOld === "Ei" && <CheckIcon color="success" />}
                        </Box>
                      </Stack>
                    </Box>
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
            )}
          </Box>
        )}
      </DialogContent>
      {!saving && (
        <DialogActions
          sx={{
            width: "100%",
            margin: 0,
            backgroundColor:
              listOfChildren.length < 1 ? "#8c8c8b" : "success.main",
          }}
          style={{ justifyContent: "center", padding: 0 }}
        >
          <Button
            sx={{
              width: "100%",
              height: "45px",
              margin: 0,
              backgroundColor: "success.main",
              "&:disabled": { backgroundColor: "#8c8c8b" },
            }}
            variant="contained"
            onClick={saveAbsent}
            disabled={
              listOfChildren.length < 1 ||
              resChildren.length < 1 ||
              (existSelected === true && DeleteOld === "") ||
              (mode === 0 && highlightedDays.length < 1) ||
              (mode === 1 && startDate === null) ||
              (mode === 2 && (startDay === null || endDay === null))
                ? true
                : false
            }
          >
            Tallenna varaukset
          </Button>
        </DialogActions>
      )}

      <Dialog onClose={handleClosePopUp} open={closePopUp}>
        <DialogTitle textAlign="center" style={{ backgroundColor: "#FFFFFF" }}>
          <Typography color="textSecondary" fontWeight="bold">
            {"Haluatko varmasti poistua? "}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClosePopUp}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "success",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ backgroundColor: "#FFFFFF" }}>
          <Typography sx={{ mt: 2 }} color="textSecondary" fontWeight="bold">
            {"Kaikki tekemäsi valinnat menetään"}
          </Typography>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: "center",

            backgroundColor: "#FFFFFF",
          }}
        >
          <Button
            variant="contained"
            sx={{ backgroundColor: "#0074C8" }}
            onClick={handleClosePopUp}
          >
            Peruuta
          </Button>
          <Button
            variant="contained"
            sx={{ backgroundColor: "#0074C8" }}
            onClick={handleClick}
          >
            Poistu
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default CTBNewResDialog;

import React, {useEffect, useState} from "react";
import {useStateValue} from "../../State";
import ContentBoxMUI from "../../Components/ContentBoxMUI";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Languages from "../../translations";
import {types} from "../../Reducers/actionTypes";
import "./discussion.css";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import _cloneDeep from "lodash/cloneDeep";
import TextField from "@mui/material/TextField";
import BlueDivider from "../../Components/BlueDivider";
import InfoModal from "../../Components/Modals/InfoModal";
import { history } from "../../Router";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useLocation } from "react-router";
import DiscussionCard from "../../Components/Discussion/DiscussionCard";
import Paper from '@mui/material/Paper'
import {SelectTime,MoreInfoModal} from "../../Components/Discussion/SelectTimeAndMoreInfoModal";
import DiscussionSpeedDial from "../../Components/Discussion/DiscussionSpeedDial";
import Card from '@mui/material/Card'
import CircularProgress from '@mui/material/CircularProgress';


const TempBookingLayout = () => {
    const {state, actions} = useStateValue();
    const {children, discussion} = state;

    const [showSavingErrorMsg, setShowSavingErrorMsg] = useState(false)
    const [confirmSaveOpen, setConfirmSaveOpen] = useState(false)

    const [activeChildId, setActiveChildId] = useState("")
    const [checkedParents, setCheckedParents] = useState([])
    const [checkedParentsNames, setCheckedParentsNames] = useState([])
    const [additionalInfoValue, setAdditionalInfoValue] = useState("")
    const [discType,setDiscType] = useState("")
    const [discTypeArray,setDiscTypeArray] = useState([])
    const [activeDiscStats, setActiveDiscStats] = useState()

    const [showMoreInfoModal, setShowMoreInfoModal] = useState(false)
    const [infoModalSlot, setInfoModalSlot] = useState(null)

    const location = useLocation();

    const [saving, setSaving] = useState(false)

    const [selectedTimeSlotRadioButton, setSelectedTimeSlotRadioButton] = useState("")
    

    const status = discussion.discussionSlots.status;
    const data = discussion.discussionSlots.data;
    const savingStatus = discussion.saveDiscussionBooking.status;
    
   

    useEffect(async () => {
        if ( typeof children === "undefined") {
          await actions.triggerLoadChildren()
        }
        await actions.triggerLoadAvailableDiscSlots()
        
        try{
            setActiveChildId(location.state.child)
            if(location.state.from=="ChildsDiscussions"){
                setDiscType(location.state.type)
            }
        }
        catch{
            console.log("Error")
        }
        
      }, [])
    

    useEffect(()=>{
        actions.triggerDeleteDiscussionTimeSelectionStatuses()  
        return()=>{
            if(discussion.discDeleteTimeSelection != types.DELETING_DISCUSSION_TIMESELECTIONSTATUSES){
                actions.triggerDeleteDiscussionTimeSelectionStatuses()
            }
        }
    },[])
    useEffect(()=>{
        if(status== types.LOAD_AVAILABLE_DISC_SLOTS_SUCCESS){
            actions.triggerLoadMainStats()
        }
    },[status])
    useEffect(()=>{
        //päivitä
    },[additionalInfoValue])
      
      

    useEffect(() => {
    const dataAvailable = activeChildId !== undefined && status === types.LOAD_AVAILABLE_DISC_SLOTS_SUCCESS
    if(dataAvailable) {
        setCheckedParents([])
        setAdditionalInfoValue("")
        changeAvailableDiscussionTypes()
        setActiveDiscStats()
        
    }
    }, [activeChildId])

    

    useEffect(() => {
        if (status === types.LOAD_AVAILABLE_DISC_SLOTS_SUCCESS && activeChildId === undefined) {
          setActiveChildId(state.discussion.discussionSlots.data.ChildrensBookableTimeSlots[0].ChildId);
        }
      }, [status])

    useEffect(() => {
    if (saving && savingStatus === types.SAVE_DISCUSSION_BOOKING_SUCCESS) {
        actions.triggerAddToSnackBarQueue({color: "#000000", message: Languages.discBookingSaved});
        setSaving(false);
        history.push("/discussionBooking");
    }
    if (saving && savingStatus === types.SAVE_DISCUSSION_BOOKING_FAILED) {
        actions.triggerAddToSnackBarQueue({color: "#000000", message: Languages.discBookingSavedError});
        setSaving(false);
        setShowSavingErrorMsg(true);
    }
    }, [savingStatus, saving])
    
    
    const onErrorModalClose = () => {
        setShowSavingErrorMsg(false);
        history.push("/discussionBooking");
    }

    const openInfoModal = (e, slot) => {
        setShowMoreInfoModal(true);
        setInfoModalSlot(slot);
    }
    
    const closeInfoModal = () => {
        setShowMoreInfoModal(false);
        setInfoModalSlot(null);
    }

    const changeAvailableDiscussionTypes = () => {
    let typesArray = [];
    data.ChildrensBookableTimeSlots.forEach((child) => {
        if (child.ChildId === activeChildId) {
        child.DiscussionsByDate.forEach((slots2) => {
            slots2.Discussions.forEach((slots) => {
            if (!typesArray.includes(slots.DiscussionType)) {
                typesArray.push(slots.DiscussionType);
            }
            })
        
        })
        }      
    })
    setDiscTypeArray(typesArray);
    setDiscType(typesArray[0]);

    }

    const getChildName = (childId) => {
        const child = children.ChildList.find(x => x.Id == childId);
        if (typeof child === "undefined") {
          return("");
        }
        return child.FirstName;
      }
    
    const discTypeReset = () => {
        if(discTypeArray && discTypeArray.length == 1) {
          setDiscType(discTypeArray[0])
        }
        else {
          setDiscType("");
        }
    }
    //Infomodal
    const handleSelectTimeRadioButtons = (e) => {
        setSelectedTimeSlotRadioButton(e.target.value);
    }

    
    //Textfield
    const handleAdditionalInfoChange = (e) => {
        setAdditionalInfoValue(e.target.value);
    }

    const getHelperText = (length) => {
        if (length > 1000) {
          return length + "/1000 Over character limit";
        } 
        return length + "/1000";
    }

    const copyUrlToClipboard = (dataToCopy) => {
        navigator.clipboard.writeText(dataToCopy);
    }

    //Savebutton
    const onSaveButtonClick = () => {
        if (additionalInfoValue.length > 1000 || 
            !discType || checkedParents.length === 0 || 
            selectedTimeSlotRadioButton === "") {
              //doNothing
            }
        else {
          let sendableParents = _cloneDeep(checkedParents);
          let parentsList = [];
          sendableParents.forEach((parent) => {
            parentsList.push(
              {
                ParentName: null,
                ParentId: parent
              }
            )
          }        
          )
    
          const data = {
            ChildId: activeChildId,
            DiscussionId: selectedTimeSlotRadioButton,
            Parents: parentsList,
            ExtraInfoParent: additionalInfoValue
          }
          actions.triggerSaveDiscussionBooking(data);
          setSaving(true);
        }
    }

    const getParentsName = ()=> {
        let parentsNames = []
        let result = []
        for(let i = 0; i < data.ChildrensBookableTimeSlots.length; i++){
          if(data.ChildrensBookableTimeSlots[i].ChildId==activeChildId)
            parentsNames = data.ChildrensBookableTimeSlots[i].Parents
        }
        if(parentsNames.length > 0){
          for(let y = 0; y < checkedParents.length; y++){
            for(let x = 0; x < parentsNames.length; x++){
              if(checkedParents[y]===parentsNames[x].ParentId){
                result.push(parentsNames[x].ParentName)
              }
            }
        }}
        setCheckedParentsNames(result)
    }
    
    //CancelButton
    const onCancelButtonClick = () => {
        history.push("/discussionBooking");
    }

    if (status === types.LOADING_AVAILABLE_DISC_SLOTS) {
        return (
          <ContentBoxMUI header={Languages.discNewTime}>
            <div style={{textAlign: "center", justifyContent: "center", width: "100%", padding: "100px"}}>
                <CircularProgress />
            </div>
          </ContentBoxMUI>
        )
    }
    if (status === types.LOAD_AVAILABLE_DISC_SLOTS_SUCCESS) {
        //const discussions = discTypeArray.length > 0 && !(activeChildId == "")
        const noDiscussionsForSelectedChild = discTypeArray && discTypeArray.length < 1 && activeChildId && activeChildId.length !== 0;
        const oneTypeAvailableForSelection = discTypeArray && discTypeArray.length == 1;
        const multipleTypesAvailableForSelection = discTypeArray && discTypeArray.length > 1;
        

        return(
            <ContentBoxMUI header={Languages.discNewTime} className="discussionBookingParent">
                <DiscussionSpeedDial/>
                <SavingErrorMsgPopUp isOpen={showSavingErrorMsg} onErrorModalClose={onErrorModalClose}/>
                <Typography variant="h5">{Languages.discBooking}</Typography>
                <BlueDivider />
                {activeDiscStats && confirmSaveOpen && <ConfirmationDialog 
                        discType={discType} 
                        confirmSaveOpen={confirmSaveOpen}
                        setConfirmSaveOpen={setConfirmSaveOpen}
                        activeDiscStats={activeDiscStats}
                        additionalInfoValue={additionalInfoValue}
                        onSaveButtonClick={onSaveButtonClick}
                        checkedParentsNames={checkedParentsNames}
                    />}
                    <SelectChild childrensBookableTimeSlots={data.ChildrensBookableTimeSlots} activeChildId={activeChildId} setActiveChildId={setActiveChildId} discTypeReset={discTypeReset}/>

                {noDiscussionsForSelectedChild && <NoDiscussions activeChildName={data.ChildrensBookableTimeSlots.find(x=> x.ChildId == activeChildId).ChildName}/>}
                {oneTypeAvailableForSelection && <OneTypeOfDiscussions discTypeArray={discTypeArray}/>}
                {multipleTypesAvailableForSelection && <SelectType discType={discType} setDiscType={setDiscType} discTypeArray={discTypeArray} />}
                {discType && (oneTypeAvailableForSelection || multipleTypesAvailableForSelection) && <>
                    <Typography><span style={{fontWeight:"900"}}>* </span>{Languages.discChooseParticipant} </Typography>
                    <SelectParents childrensBookableTimeSlots={data.ChildrensBookableTimeSlots} activeChildId={activeChildId} checkedParents={checkedParents} setCheckedParents={setCheckedParents} />
                    <Typography><span style={{fontWeight:"900"}}>* </span>{Languages.discChooseTime} </Typography>
                    <SelectTime childrensBookableTimeSlots={data.ChildrensBookableTimeSlots} 
                        discType={discType} activeChildId={activeChildId}   
                        selectedTimeSlotRadioButton={selectedTimeSlotRadioButton}
                        setSelectedTimeSlotRadioButton={setSelectedTimeSlotRadioButton}
                        activeDiscStats={activeDiscStats}
                        setActiveDiscStats={setActiveDiscStats}
                        setConfirmSaveOpen={setConfirmSaveOpen}
                    />
                    {activeDiscStats && <>                 
                        <BlueDivider />
                        <MoreInfoModal 
                        slot={activeDiscStats}  
                        copyUrlToClipboard={copyUrlToClipboard} 
                        />
                        <BlueDivider/>
                        <TextField
                            error={additionalInfoValue.length > 1000 ? true : false}
                            helperText={getHelperText(additionalInfoValue.length)}
                            inputProps={{className:"usernamepasswordinput123"}}
                            variant="outlined"
                            id="outlined-multiline-flexible"
                            label={Languages.cookieMoreInfo}
                            multiline
                            maxRows={4}
                            value={additionalInfoValue}
                            onChange={handleAdditionalInfoChange}
                            fullWidth
                        />
                        <SaveAndCancelButtons 
                            additionalInfoValue={additionalInfoValue}
                            discType={discType}
                            checkedParents={checkedParents}
                            selectedTimeSlotRadioButton={selectedTimeSlotRadioButton}
                            setConfirmSaveOpen={setConfirmSaveOpen}
                            getParentsName={getParentsName}
                            onCancelButtonClick={onCancelButtonClick}
                        />
                    </>}
                    <br/>
                </>
                }
            </ContentBoxMUI>
        )
    }
    return(<ContentBoxMUI>
    </ContentBoxMUI>)
}

export default TempBookingLayout






//Tiny components
const NoDiscussions = ({activeChildName}) => {
    return<Typography sx={{marginTop: "14px"}}>{Languages.discNoTimesToBeBooked(activeChildName)}</Typography>
}
const OneTypeOfDiscussions = ({discTypeArray})=>{
    return(<>
    <Typography sx={{marginTop: "14px"}}>{Languages.discTimeType}</Typography> 
    <Typography color="secondary" sx={{fontWeight: "bold", marginLeft:"14px", marginTop: "4px"}}>
        <ReturnType type={discTypeArray[0]}/>
    </Typography>
    <BlueDivider />
  </>)
}
const SelectChild = ({childrensBookableTimeSlots,activeChildId,setActiveChildId,discTypeReset})=>{
    const childClick = (childId) =>{
        setActiveChildId(childId)
        discTypeReset()
    }
    return(<>
    <FormControl sx={{minWidth:"150px", maxWidth:"300px"}}>
        <InputLabel id="demo-simple-select-label2"><span style={{fontWeight:"900"}}>* </span>{Languages.child}</InputLabel>
        <Select color="primary" sx={{color:"black"}} value={activeChildId} displayEmpty>
            {typeof childrensBookableTimeSlots != "undefined" ? 
            childrensBookableTimeSlots.map((slot) => {
                return(<MenuItem value={slot.ChildId} key={slot.ChildId} onClick={()=>childClick(slot.ChildId)}>{slot.ChildName}</MenuItem>)
            }) : null}
        </Select>
    </FormControl>
    <BlueDivider/>
    </>)
}

const SelectParents = ({childrensBookableTimeSlots,activeChildId,checkedParents,setCheckedParents}) =>{
    const [disabled, setDisabled] = useState(false)

    useEffect(()=>{
        setDisabled(false)
    },[activeChildId])
    
    const handleParentCheckBox = (e) => {
        if (e.target.checked) {
            const newArray = checkedParents.concat([e.target.id]);
            setCheckedParents(newArray);
        }
        else {
            let array = _cloneDeep(checkedParents);
            const index = array.indexOf(e.target.id);
            if (index > -1) {
            array.splice(index, 1);
            }
            setCheckedParents(array);
        }
    }
    let correctChildsDiscussion
    for(const child of childrensBookableTimeSlots){
        if(child.ChildId == activeChildId){
            correctChildsDiscussion = child
        }
    }

    if(correctChildsDiscussion == null)
        return null

    if(correctChildsDiscussion.Parents.length==1 && !disabled){
        setDisabled(true)
        handleParentCheckBox({target:{id:correctChildsDiscussion.Parents[0].ParentId,checked:true}})
    }
    
    
    

    return(<>
            <FormGroup> 
                    {correctChildsDiscussion.Parents.map((ch) => {
                        return(<FormControlLabel key={ch.ParentId} control={
                            <Checkbox 
                            checked={checkedParents.includes(ch.ParentId)} 
                            onChange={handleParentCheckBox} 
                            id={ch.ParentId}
                            disabled={disabled}
                            />
                            } 
                            label={ch.ParentName}/>)
                    })}
            </FormGroup>
            <BlueDivider/>
        </>)
}

const SelectType = ({discType,setDiscType,discTypeArray})=>{
    const handleTypeChange = (e) => {
        setDiscType(e.target.value);
    }

    return(<>
    <FormControl color="primary" sx={{minWidth:"150px", maxWidth:"300px", marginTop: "2px"}}>
        <InputLabel id="disctype-simple-select-label"><span style={{fontWeight:"900"}}>* </span>{Languages.discType}</InputLabel>
        <Select
            sx={{color:"black"}}
            color="primary"
            labelId="keskustelutyyppi"
            id="keskustelutyyppi-select"
            value={discType}
            label={Languages.discType}
            onChange={handleTypeChange}
        >
            {discTypeArray.map((type) => {
            return(
                <MenuItem key={type} value={type}><ReturnType type={type}/></MenuItem>
            )
            })}
        </Select>
    </FormControl>
    <BlueDivider/>
    </>)
}
const SaveAndCancelButtons = ({additionalInfoValue,discType,checkedParents,selectedTimeSlotRadioButton,setConfirmSaveOpen,getParentsName,onCancelButtonClick}) => {
    return(
    <Paper className="saveAndCancelButtonParent" elevation={0}>
        <Button 
            variant="contained" 
            color="secondary" 
            disabled={
            additionalInfoValue.length > 1000 || 
            !discType || checkedParents.length === 0 || 
            selectedTimeSlotRadioButton === ""}
            onClick={()=>{
            setConfirmSaveOpen(true)
            getParentsName()
            }}
        >
            {Languages.bookingCareHoursDefaultSave}
        </Button>
        <Button  
            variant="outlined" 
            color="secondary"
            onClick={onCancelButtonClick}
            >{Languages.generalBack}
        </Button>
      </Paper>)
}

const ConfirmationDialog= ({discType,confirmSaveOpen,setConfirmSaveOpen,activeDiscStats, checkedParentsNames,additionalInfoValue,onSaveButtonClick}) => {
    return(
    <Dialog open={confirmSaveOpen} maxWidth="md" fullWidth>
        <DialogTitle id="alert-dialog-title">
        {Languages.discSaveConfirmation}
        </DialogTitle>
        <DialogContent>
            <DiscussionCard
                preview
                discussionId={activeDiscStats.DiscussionId}
                type={discType} 
                dateData={activeDiscStats}
                cancelDate={activeDiscStats.CancelableUntil}
                parents={formatParentData(checkedParentsNames)} 
                staff={activeDiscStats.Employees}
                remote={activeDiscStats.IsRemoteMeeting}
                address={activeDiscStats.DiscussionAddress}
                url={activeDiscStats.MeetingUrl}
                employeeInfoText={activeDiscStats.ExtraInfoShared}
                parentInfoText={additionalInfoValue}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={()=>setConfirmSaveOpen(false)}>
                {Languages.ciDeleteNo}
            </Button>
            <Button onClick={onSaveButtonClick}  autoFocus>
                {Languages.ciDeleteYes}
            </Button>
        </DialogActions>
    </Dialog>)
}

const SavingErrorMsgPopUp = ({isOpen, onErrorModalClose}) => {
    const {state} = useStateValue();
    const savingErrorText = state.discussion.saveDiscussionBooking.errorText;
    const savingErrorCode = state.discussion.saveDiscussionBooking.code;
    
    return(
      <InfoModal modalOpen={isOpen} headerText={"Virhe"} onClose={onErrorModalClose} allowClose={true}>
        <Typography>{Languages.discSaveError(savingErrorCode)}</Typography>
        <Typography>{savingErrorText && savingErrorText === "AlreadyBookingInSlot" && Languages.discAlreadyBooked}</Typography>
        <Button sx={{marginTop: "3px"}} variant="outlined" color="secondary" onClick={onErrorModalClose}>OK</Button>
      </InfoModal>
    )
  
}

const ShowDetails = ({activeDiscStats})=>{
    return(
    <>
        <Card>
            <Typography>{activeDiscStats.DiscussionAddress}</Typography>
            <Typography></Typography>

        </Card>
        <BlueDivider />
    </>
    )
}

//Useful functions

const ReturnType= ({type})=>{
    if(type==0)
        return  <span>{Languages.discTypeVASU}</span>
    if(type==1)
        return  <span>{Languages.discTypeLapu}&trade;</span>
    if(type==2)
        return  <span>{Languages.discTypeMuu}</span>
    if(type==3)
        return  <span>{Languages.discTypeLEOPS}</span>
    return ""
}

const formatParentData = (parentData)=>{
    let parentList = []
    for(const parent of parentData){
      parentList.push({ParentName: parent})
    }
    return parentList
}
import React, { useEffect, useState } from "react";

import { useStateValue } from "../../../../State";

import { PickersDay } from "@mui/x-date-pickers/PickersDay";

import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";

import Stack from "@mui/material/Stack";

import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";

import IconButton from "@mui/material/IconButton";
import moment from "moment";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ChildProfilePicture from "../../../ChildProfilePicture";
import CloseIcon from "@mui/icons-material/Close";

import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

import { styled } from "@mui/material/styles";

import Divider from "@mui/material/Divider";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import DialogActions from "@mui/material/DialogActions";

import "./CTBBottomNav2.css";
import Chip from "@mui/material/Chip";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";

import Badge from "@mui/material/Badge";
import ChildSelection from "../common/ChildSelection";
import CalendarTabs from "../common/CalendarTabs";
import { types } from "../../../../Reducers/actionTypes";

const CTBNewAbsentDialog = ({
  handleClick,
  newResDialogOpen,
  names,
  personName,
  open,
  handleFocusDay,
}) => {
  const { state, actions } = useStateValue();
  const absent = state.caretimes.Absent;

  useEffect(() => {
    switch (absent.state) {
      case types.CARETIMES_ABSENT_SAVED_OK:
        if (absent.Code === -2) {
          actions.triggerAddToSnackBarQueue({
            message: "Poissaolon tallennus epäonnistui",
            severity: "Error",
          });
          handleClick();
          break;
        }
        actions.triggerAddToSnackBarQueue({
          message: "Poissaolon tallennus onnistui",
          severity: "success",
        });
        handleClick();

        break;
      case types.CARETIMES_ABSENT_SAVED_FAILED:
        actions.triggerAddToSnackBarQueue({
          message: "Poissaolon tallennus epäonnistui",
          severity: "success",
        });
        handleClick();

        break;
      default:
        break;
    }
  }, [absent.state]);

  useEffect(() => {
    setListOfChildren([]);
    setAbsentReason("");
    setHighlightedDays([]);
    setClosePopUp(false);
  }, [newResDialogOpen]);

  //handle closing if user has made any actions popup

  const [closePopUp, setClosePopUp] = React.useState(false);
  const handleCloseClick = () => {
    if (
      listOfChildren.length > 0 ||
      absentReason !== "" ||
      highlightedDays.length > 0 ||
      startDate !== null ||
      startDay !== null ||
      endDay !== null
    ) {
      setClosePopUp(true);
    } else {
      handleClick();
    }
  };

  const handleClosePopUp = () => {
    setClosePopUp(!closePopUp);
  };

  //Handle selected Children
  const [listOfChildren, setListOfChildren] = React.useState([]);

  const handleChildren = (event, newPersonNames) => {
    setListOfChildren(newPersonNames);
    console.log(newPersonNames);
  };

  //Handle absent reason sick/absent
  const [absentReason, setAbsentReason] = React.useState("");

  const handleReason = (event, newReason) => {
    if (newReason !== null) {
      setAbsentReason(newReason);
    }
  };

  const [extRes, setExtRes] = React.useState([]);

  //Handle single selected days
  const [highlightedDays, setHighlightedDays] = React.useState([]);

  const handleHighLightedDays = (newDay) => {
    if (highlightedDays.some((day) => day.isSame(newDay, "day"))) {
      var updated = highlightedDays.filter(
        (day) => day.isSame(newDay, "day") == false
      );
      setHighlightedDays(updated);
    } else {
      highlightedDays.push(moment(newDay));
    }
  };

  //Handle Week Selection
  const [startDate, setStartDate] = React.useState(null);

  const handleWeek = (date) => {
    setStartDate(date);
  };
  //Handle date Range

  const [startDay, setStartDay] = React.useState(null);
  const [endDay, setEndDay] = React.useState(null);

  const handleDayRange = (newDay) => {
    if (startDay == null) {
      setStartDay(newDay);
    } else {
      if (endDay == null) {
        setEndDay(newDay);
      } else {
        setStartDay(newDay);
        setEndDay(null);
      }
    }
  };

  //Handle mode days/weeks/range for saving the dates
  const [mode, setMode] = React.useState(0);

  const handleMode = (value) => {
    //Clear all selections when changing mode // WIP -- Add confirmation if dates selected
    setHighlightedDays([]);
    setStartDay(null);
    setEndDay(null);
    setStartDate(null);
    setMode(value);
  };

  const saveAbsent = () => {
    let ListDates = [];

    switch (mode) {
      case 0:
        console.log("päivät");
        ListDates = highlightedDays;
        break;
      case 1:
        console.log("viikot");
        for (
          var m = moment(startDate).startOf("week");
          m.isSameOrBefore(moment(startDate).endOf("week"));
          m.add(1, "days")
        ) {
          ListDates.push(moment(m));
        }
        break;
      case 2:
        console.log("väli");
        for (
          var m = moment(startDay);
          m.isSameOrBefore(moment(endDay));
          m.add(1, "days")
        ) {
          ListDates.push(moment(m));
        }
        break;
      default:
        console.log("mitä vittua");
        break;
    }

    handleFocusDay(moment.min(ListDates));
    var personAbsent = [];

    listOfChildren.forEach((child) => {
      personAbsent.push({ PersonId: child, AbsentReason: absentReason });
    });

    console.log({
      PersonList: personAbsent,
      Dates: ListDates,
      DeleteOld: false,
      EveningPreschool: false,
    });
    actions.triggerSaveCareTimesAbsent({
      PersonList: personAbsent,
      Dates: ListDates,
      DeleteOld: false,
      EveningPreschool: false,
    });
  };

  return (
    <Dialog
      disablePadding
      disableGutters
      fullWidth
      onClose={handleClick}
      open={newResDialogOpen}
      sx={{
        "& .MuiDialog-container .MuiDialog-paper": {
          margin: 0,
          borderRadius: 2,
          width: "96%",
        },
        width: "100%",
        borderRadius: 2,
      }}
    >
      <DialogTitle textAlign="center" style={{ backgroundColor: "#FFFFFF" }}>
        <Typography color="textSecondary" fontWeight="bold">
          {"Ilmoita poissaolo"}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleCloseClick}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "success",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        dividers
        disableGutters
        disablePadding
        sx={{ padding: 0 }}
        style={{ backgroundColor: "#FFFFFF" }}
      >
        <Box sx={{ mb: 2, padding: 0 }}>
          <div>
            <Divider
              sx={{
                m: 2,
                "&::before, &::after": {
                  borderColor: "#0074C8",
                },
              }}
              textAlign="center"
            >
              <Chip
                label={"1. Valitse Lapset"}
                sx={{ backgroundColor: "#0074C8" }}
              />
            </Divider>
            <>
              <ChildSelection
                names={names}
                listOfChildren={listOfChildren}
                handleFormat={handleChildren}
              ></ChildSelection>
            </>

            <Divider
              sx={{
                m: 2,
                "&::before, &::after": {
                  borderColor: "#0074C8",
                },
              }}
              textAlign="center"
            >
              <Chip
                label={"2. Valitse Poissaolon syy"}
                sx={{ backgroundColor: "#0074C8" }}
              />
            </Divider>
            <ToggleButtonGroup
              sx={{ width: "80%", ml: "10%" }}
              value={absentReason}
              exclusive
              onChange={handleReason}
              orientation="vertical"
            >
              <ToggleButton value="Poissa">
                <Box sx={{ width: "100%" }}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Box sx={{ width: "20%" }}></Box>
                    <Box sx={{ width: "60%" }}>
                      <Typography
                        color="textSecondary"
                        fontSize="medium"
                        textAlign="center"
                      >
                        {"Poissa"}
                      </Typography>
                    </Box>
                    <Box sx={{ width: "20%" }}>
                      {absentReason === "Poissa" && (
                        <CheckIcon color="success" />
                      )}
                    </Box>
                  </Stack>
                </Box>
              </ToggleButton>
              <ToggleButton value="Sairas">
                <Box sx={{ width: "100%" }}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Box sx={{ width: "20%" }}></Box>
                    <Box sx={{ width: "60%" }}>
                      <Typography
                        color="textSecondary"
                        fontSize="medium"
                        textAlign="center"
                      >
                        {"Sairas"}
                      </Typography>
                    </Box>
                    <Box sx={{ width: "20%" }}>
                      {absentReason === "Sairas" && (
                        <CheckIcon color="success" />
                      )}
                    </Box>
                  </Stack>
                </Box>
              </ToggleButton>
            </ToggleButtonGroup>
            {absentReason === "" && (
              <Typography
                sx={{ mt: 1 }}
                color="warning.main"
                fontSize="medium"
                textAlign="center"
              >
                {"Valitse Poissaolon syy"}
              </Typography>
            )}

            <Divider
              sx={{
                m: 2,
                "&::before, &::after": {
                  borderColor: "#0074C8",
                },
              }}
              textAlign="center"
            >
              <Chip
                label={"3. Valitse päivät"}
                sx={{ backgroundColor: "#0074C8" }}
              />
            </Divider>
            <CalendarTabs
              handleHighLightedDays={handleHighLightedDays}
              highlightedDays={highlightedDays}
              extRes={extRes}
              handleMode={handleMode}
              handleDayRange={handleDayRange}
              startDay={startDay}
              endDay={endDay}
              startDate={startDate}
              handleWeek={handleWeek}
            ></CalendarTabs>
          </div>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          width: "100%",
          margin: 0,
          backgroundColor:
            listOfChildren.length < 1 ? "#8c8c8b" : "success.main",
        }}
        style={{ justifyContent: "center", padding: 0 }}
      >
        <Button
          sx={{
            width: "100%",
            height: "45px",
            margin: 0,
            backgroundColor: "success.main",
            "&:disabled": { backgroundColor: "#8c8c8b" },
          }}
          variant="contained"
          onClick={saveAbsent}
          disabled={
            listOfChildren.length < 1 ||
            absentReason === "" ||
            (mode === 0 && highlightedDays.length < 1) ||
            (mode === 1 && startDate === null) ||
            (mode === 2 && (startDay === null || endDay === null))
              ? true
              : false
          }
        >
          Tallenna Poissaolo
        </Button>
      </DialogActions>
      <Dialog onClose={handleClosePopUp} open={closePopUp}>
        <DialogTitle textAlign="center" style={{ backgroundColor: "#FFFFFF" }}>
          <Typography color="textSecondary" fontWeight="bold">
            {"Haluatko varmasti poistua? "}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClosePopUp}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "success",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ backgroundColor: "#FFFFFF" }}>
          <Typography sx={{ mt: 2 }} color="textSecondary" fontWeight="bold">
            {"Kaikki tekemäsi valinnat menetään"}
          </Typography>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: "center",

            backgroundColor: "#FFFFFF",
          }}
        >
          <Button
            variant="contained"
            sx={{ backgroundColor: "#0074C8" }}
            onClick={handleClosePopUp}
          >
            Peruuta
          </Button>
          <Button
            variant="contained"
            sx={{ backgroundColor: "#0074C8" }}
            onClick={handleClick}
          >
            Poistu
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default CTBNewAbsentDialog;

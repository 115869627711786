import React, { useState, useEffect } from "react";
import { useStateValue } from "../../../State";
import "../../Vasu/vasu.css";

import Moment from "moment";

import ChildSelfAssessment from "../ChildSelfAssessment";
import CommentsReadOnly from "../../Vasu/CommentsReadOnly";
import CommentsEditing from "../../Vasu/CommentsEditing";
import LeopsLocking from "./LeopsLocking";

import Languages from "../../../translations";

import SwipeableViews from "react-swipeable-views";

import SlideConfirmationNote from "../../../Components/SlideConfirmationNote";

import { Typography } from "@mui/material";
import MobileStepper from "@mui/material/MobileStepper";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import makeStyles from "@mui/styles/makeStyles";

import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LockIcon from "@mui/icons-material/Lock";

import _cloneDeep from "lodash/cloneDeep"; // deep clones an array so that the copy is not just shallow
import { types } from "../../../Reducers/actionTypes";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    //zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "#FBE4FF !important",
  },
  fixedbottom: {
    float: "right !important",
    fontSize: "x-large",
    position: "fixed",
    fontWeight: "bold",
    color: "#D500F9",
    right: "0",
    bottom: "0",
    zIndex: "1040 !important",
  },
  displaynone: {
    display: "none",
  },
}));

const SpringEvaluationSubPart = (props) => {
  const classes = useStyles();
  const { state, actions } = useStateValue();

  /*const GeneralPart = state.leops.GeneralPart;
  const Table4Rows  = state.leops.Table4.Data.TableRow;
  const Data        = state.leops.Table4.Data.AssesmentRows;*/

  //const { history, location } = props;
  const MaxSlideCount = 11;
  let slides = [];

  var Titles = [
    Languages.ManyFormsOfExpression,
    Languages.leopsRichWorldOfLanguage,
    Languages.leopsMeAndOurCommunity,
    Languages.leopsIStudyAndWork,
    Languages.leopsIGrowAndDevelop,
    Languages.leopsIHaveLearnedInPreschool,
    Languages.leopsTitle4_3,
    Languages.leopsChildStrengthsAndIterests,
    Languages.EvaluationPedagogicalActivitiesGoalsSupport,
    Languages.OtherAdditionalInfoAndCooperation,
    Languages.GreetingsFromGuardian,
    Languages.LockLeops,
  ];

  const { SelectedSlideIndex } = props;

  const [GeneralPart, setGeneralPart] = useState(state.leops.GeneralPart);
  const [Table4Rows, setTable4Rows] = useState(
    state.leops.Table4.Data.TableRow
  );
  const [Data, setData] = useState(state.leops.Table4.Data.AssesmentRows);

  const [FormChanged, setFormChanged] = useState(false);
  const [showSlideNote, setshowSlideNote] = useState(false);
  const [SlideNoteText, setSlideNoteText] = useState(false);
  const [EditingTextOpen, setEditingTextOpen] = useState(null); // häkki, kunnes useamman tekstikentän yhtäaikainen editointi ja tallennus speksattu

  const [SlideIndex, setSlideIndex] = useState(SelectedSlideIndex);
  const [BGColor, setBGColor] = useState(
    /*SelectedSlideIndex === 0 ? */ "#FBDCCE" /* : '#FBE4FF'*/
  );
  const [SavingData, setSavingData] = useState(false);
  const [SlideAssessmentData, setslideData] = useState(
    Data.find((item) => {
      return item.AssessmentItem[0].Row - 1 === SlideIndex;
    })
  );

  useEffect(() => {
    setGeneralPart(state.leops.GeneralPart);
    setTable4Rows(state.leops.Table4.Data.TableRow);
    setData(state.leops.Table4.Data.AssesmentRows);
  }, [state.leops]);

  useEffect(() => {
    //console.log("SpringEvaluationSubPart. Leops state: ", state.leops.state, " FormChanged: ", FormChanged);
    console.log("leopsin state on: ", state.leops.state);
    switch (state.leops.state) {
      case "INITIAL_STATE":
      case types.SAVING_LEOPS:
        //console.log("SpringEvaluationSubPart SAVING_LEOPS -> Set Saving ON, if not already on");
        setSavingData(true);
        break;
      case types.LEOPS_LOAD_OK:
        /*console.log("SpringEvaluationSubPart LEOPS_LOAD_OK");*/
        setslideData(
          state.leops.Table4.Data.AssesmentRows.find((item) => {
            return item.AssessmentItem[0].Row - 1 === SlideIndex;
          })
        );
        setSavingData(false);
        actions.triggerLeopsHandled();
        break;
      case types.LEOPS_SAVED_OK:
        //console.log("SpringEvaluationSubPart. LEOPS_SAVED_OK -> Show ok note and load leops");
        //setSavingData(false);
        setFormChanged(false);
        setEditingTextOpen(null);
        actions.triggerAddToSnackBarQueue({
          color: "#000000",
          message: Languages.formSaved,
        });
        actions.triggerLoadLeops(GeneralPart.Id);
        //actions.triggerLeopsHandled();
        break;
      case types.LEOPS_SAVED_FAILED:
        console.log(
          "SpringEvaluationSubPart. LEOPS_SAVED_FAILED. Code: ",
          state.leops.Code,
          " Message: ",
          state.leops.Message
        );
        var codetext =
          state.leops.Code === undefined
            ? "\n" + Languages.errorUnknown
            : "\n" + Languages.errorCode + ": " + state.leops.Code;
        var msg =
          state.leops.Message === undefined ? "" : "\n" + state.leops.Message;
        setSavingData(false);
        actions.triggerAddToSnackBarQueue({
          color: "#000000",
          message: Languages.errorChangingFailed + " " + codetext + msg,
        });
        actions.triggerLeopsHandled();
        break;
      default:
        //console.log("PreparingSubPart. Default Leops resp");
        break;
    }
  }, [state.leops.state]);

  const handlePrev = () => {
    var index = SlideIndex;
    //console.log("Current index: ", index);

    if (FormChanged) {
      setSlideNoteText(Languages.generalSaveOrCancel);
      setshowSlideNote(true);
    } else if (SlideIndex > 5) {
      index--;
      setSlideIndex(index);
    } else {
      // first 5 slides, check if question is found
      var looped = Data.filter((item, ind) => {
        return item.AssessmentItem[0].Row - 1 < index;
      });

      //console.log("looped : ", looped , "\n looped last: ", looped[looped.length-1]);
      if (looped.length === 0) {
        index = index;
      } else {
        index = looped[looped.length - 1].AssessmentItem[0].Row - 1;
        setslideData(looped[looped.length - 1]);
      }
      setSlideIndex(index);
    }
  };

  const handleNext = (maxCount) => {
    var index = SlideIndex;

    //console.log("SpringEvaluationSubPart. Handle Next. Current index: ", index);

    if (FormChanged) {
      setSlideNoteText(Languages.generalSaveOrCancel);
      setshowSlideNote(true);
    } else if (SlideIndex < MaxSlideCount - 1 && index > 4) {
      index++;
      setSlideIndex(index);
    } else {
      // first 5 slides, check if question is found
      var found = Data.find((item) => {
        return item.AssessmentItem[0].Row - 1 > index;
      });
      /*console.log("Current index: ", index,
                  "\n found next: ", found,
                  "\n next slide: ",found === undefined ? undefined : found.AssessmentItem[0].Row);*/
      if (found === undefined) {
        index = 5;
      } else {
        index = found.AssessmentItem[0].Row - 1;
        setslideData(found);
      }
      setSlideIndex(index);
    }
  };

  const handleChangeIndex = (e, index) => {
    //console.log("SpringEvaluationSubPart. Handle swipe. Nykyinen Slide: ",SlideIndex,"\n Uusi slide: ",e,"\n Edellinen : ",index, "\n Max slide count: ",Data.length + 3);

    if (FormChanged) {
      setshowSlideNote(true);
    } else if (e < index) {
      // PREV
      if (SlideIndex > 5) {
        setSlideIndex(e);
      } else {
        // first 5 slides, check if question is found
        var looped = Data.filter((item, ind) => {
          return item.AssessmentItem[0].Row - 1 < index;
        });

        if (looped.length === 0) {
          setSlideIndex(e);
        } else {
          setslideData(looped[looped.length - 1]);
          setSlideIndex(looped[looped.length - 1].AssessmentItem[0].Row - 1);
        }
      }
    } // NEXT
    else {
      if (SlideIndex < MaxSlideCount - 1 && SlideIndex > 4) {
        setSlideIndex(e);
      } else {
        // first 5 slides, check if question is found
        var found = Data.find((item) => {
          return item.AssessmentItem[0].Row - 1 > index;
        });

        /*console.log("Current index: ", index,
                    "\n found next: ", found,
                    "\n next slide: ",found === undefined ? undefined : found.AssessmentItem[0].Row);*/
        if (found === undefined) {
          setSlideIndex(5);
        } else {
          setslideData(found);
          setSlideIndex(found.AssessmentItem[0].Row - 1);
        }
      }
    }
  };

  const handleFormChange = (status, component) => {
    //console.log("PreparingSubPart. Handle change. \nStatus: ", status, " \ncomponent: ", component, "\nPrevOpen: ", EditingTextOpen);

    if (EditingTextOpen === null && status === "EDITED") {
      setFormChanged(true);
      setEditingTextOpen(component);
    } else if (
      EditingTextOpen !== null &&
      EditingTextOpen !== component &&
      status === "EDITED"
    ) {
      setSlideNoteText(Languages.generalSaveOrCancel);
      setshowSlideNote(true);
    } else if (EditingTextOpen === component && status === "EDIT CANCELED") {
      setFormChanged(false);
      setEditingTextOpen(null);
    }
  };

  const handleSlideConfirmationNoteCB = (status) => {
    //console.log("PreparingSubPart. Handle slide confnote callback. status: ", status);
    setshowSlideNote(false);
  };

  const handleSmileyChange = (status) => {
    console.log(
      "SpringEvaluationSubPart. Handle Smiley change. Status: ",
      status
    );
  };

  const handleCommentsEditingCB = (type, mode, newData) => {
    console.log(
      "SpringEvaluationSubPart.. Comments edited callback:" +
        //"\n SlideIndex: "+SlideIndex+
        "\n type: " +
        type +
        "\n mode: " +
        mode +
        "\n newData: ",
      newData
    );

    let EditedParentComment = [];
    if (mode === "ShowFormChangedNote") {
      // häkki, kunnes useamman tekstikentän yhtäaikainen editointi ja tallennus speksattu
      setshowSlideNote(true);
    } else if (type !== "Slide5" && type !== "Slide9") {
      setSlideNoteText(
        /*Languages.generalSaveOrCancel*/ "Tuntematon tekstikenttä"
      );
      setshowSlideNote(true);
    } else if (mode === "Save") {
      EditedParentComment = {
        LeopsId: GeneralPart.Id,
        Table: 4,
        Row: type === "Slide5" ? 6 : /*Slide9*/ 10,
        Text: newData,
      };
      setSavingData(true);
      setFormChanged(false);
      setEditingTextOpen(null);
      actions.triggerSaveLeopsParentComments(EditedParentComment);
    }
  };

  const SlideText = () => {
    var slides = [];
    slides = [
      { Text: Languages.ManyFormsOfExpressionInfo },
      { Text: Languages.RichWorldOfLanguageInfo },
      { Text: Languages.MeAndOurCommunityInfo },
      { Text: Languages.IStudyAndWorkInfo },
      { Text: Languages.IGrowAndDevelopInfo },
      { Text: "" },
      { Text: Languages.ChildStrengthsAndIterests },
      { Text: Languages.EvaluationPedagogicalActivitiesGoalsSupport },
      { Text: Languages.OtherAdditionalInfoAndCooperation },
      { Text: Languages.GreetingsFromGuardian },
      { Text: Languages.LockLeops },
    ];
    return slides;
  };

  if (state.leops.state === "INITIAL_STATE") {
    //console.log("SpringEvaluationSubPart. INITIAL_STATE RENDER EMPTY");

    return <div className="PreparingSubPart"></div>;
  } else {
    if (slides.length === 0) {
      //console.log("VasuPreparingSubPart. RENDER. Create slides");
      slides = SlideText();
    }
    return (
      <div className="PreparingSubPart">
        <Box>
          {SavingData || state.leops.state === types.LOADING_LEOPS ? (
            <div style={{ opacity: "0.5" }}>
              <Backdrop open={true} className={classes.backdrop}>
                <CircularProgress />
              </Backdrop>
            </div>
          ) : (
            <div>
              <SwipeableViews
                className={"col-12 textCenter slide pt-3"}
                index={SlideIndex}
                key={SlideIndex}
                onChangeIndex={handleChangeIndex}
                disabled={FormChanged ? true : false}
              >
                {slides.map((slide, index) => {
                  return (
                    <div key={index}>
                      <Box
                        className="br-top col-12"
                        sx={{ backgroundColor: BGColor }}
                      >
                        <Typography
                          color="textSecondary"
                          sx={{
                            textAlign: "center",
                            fontWeight: "Bold",
                            fontSize: 18,
                            color: "#424242",
                            pt: 1.5,
                          }}
                        >
                          {
                            Titles[
                              SlideIndex === 10
                                ? 11
                                : SlideIndex < 6
                                ? SlideIndex
                                : 6
                            ]
                          }
                        </Typography>
                        <MobileStepper
                          style={{ backgroundColor: BGColor, color: "black" }}
                          //variant="progress" <= viiva
                          variant="text"
                          steps={MaxSlideCount}
                          position="static"
                          //LinearProgressProps={{width: "100%"}}
                          activeStep={SlideIndex}
                          //className={classes.root}
                          nextButton={
                            <Button
                              size="small"
                              style={{
                                color: "black",
                                opacity:
                                  SlideIndex === MaxSlideCount - 1 ? "0.5" : "",
                              }}
                              onClick={handleNext}
                              disabled={SlideIndex === MaxSlideCount - 1}
                            >
                              {Languages.next} <KeyboardArrowRight />{" "}
                            </Button>
                          }
                          backButton={
                            <Button
                              size="small"
                              style={{
                                color: "black",
                                opacity: SlideIndex === 0 ? "0.5" : "",
                              }}
                              onClick={handlePrev}
                              disabled={SlideIndex === 0}
                            >
                              {" "}
                              <KeyboardArrowLeft /> {Languages.prev}{" "}
                            </Button>
                          }
                        />
                      </Box>
                      <Box
                        className="br-btm col-12"
                        sx={{
                          boxShadow: 2,
                          mb: 3,
                          pb: 1,
                          backgroundColor: "white",
                        }}
                      >
                        <Typography
                          color="textSecondary"
                          sx={{
                            textAlign: "center",
                            fontWeight: "Medium",
                            fontSize: 16,
                            color: "#424242",
                            pt: 1,
                          }}
                        >
                          {slide.Text}
                        </Typography>
                      </Box>
                    </div>
                  );
                })}
              </SwipeableViews>

              {showSlideNote ? (
                <SlideConfirmationNote
                  openNote={showSlideNote}
                  title={Languages.formChange}
                  text={SlideNoteText}
                  leftButton={Languages.generalOkBtn}
                  rightButton={null}
                  middleButton={null}
                  callback={(status) => handleSlideConfirmationNoteCB(status)}
                ></SlideConfirmationNote>
              ) : null}

              {SlideIndex < 5 ? (
                <ChildSelfAssessment
                  slide={SlideIndex}
                  slideData={SlideAssessmentData}
                  title={Languages.LeopsTitle4_2_1}
                  table={4}
                  color={BGColor}
                  callback={(status) => handleSmileyChange(status)}
                  callbackEdit={(status, component) =>
                    handleFormChange(status, component)
                  }
                >
                  {" "}
                </ChildSelfAssessment>
              ) : SlideIndex === 5 ? (
                <div>
                  <Box className="col-12">
                    <CommentsReadOnly
                      Title1={
                        <Typography
                          color="textSecondary"
                          sx={{
                            textAlign: "left",
                            fontWeight: "Bold",
                            fontSize: 16,
                            color: "#424242",
                            pt: 1,
                            ml: 1,
                          }}
                        >
                          {Languages.StaffNotes}{" "}
                        </Typography>
                      } // Varhaiskasvatushenkilöstön + omat kommentit
                      Title2={null}
                      Title3={null}
                      BgColor={BGColor}
                      Data={{ Text: Table4Rows[0].RowItem[0].RowText }}
                    ></CommentsReadOnly>

                    {GeneralPart.FormLocked === true ? (
                      <CommentsReadOnly
                        Title1={
                          <Typography
                            color="textSecondary"
                            sx={{
                              textAlign: "left",
                              fontWeight: "Bold",
                              fontSize: 16,
                              color: "#424242",
                              pt: 1,
                              ml: 1,
                            }}
                          >
                            {Languages.OwnNotes}{" "}
                          </Typography>
                        } // omat
                        Title2={null}
                        Title3={null}
                        BgColor={BGColor}
                        Data={{
                          Text:
                            Table4Rows[0].RowItem[1].RowText === null
                              ? null
                              : Table4Rows[0].RowItem[1].RowText +
                                "\n" +
                                Table4Rows[0].RowItem[1].Editor,
                        }}
                      ></CommentsReadOnly>
                    ) : (
                      <CommentsEditing
                        key={"ParentSpringGreetings_" + SlideIndex}
                        Type={"Slide" + SlideIndex}
                        PrevType={EditingTextOpen} // hakki
                        BgColor={BGColor}
                        Data={{
                          Text:
                            Table4Rows[0].RowItem[1].RowText === null
                              ? null
                              : Table4Rows[0].RowItem[1].RowText,
                          Saved: Table4Rows[0].RowItem[1].Editor,
                        }}
                        callback={(type, mode, newData) =>
                          handleCommentsEditingCB(type, mode, newData)
                        }
                        Title1={
                          <Typography
                            color="textSecondary"
                            sx={{
                              textAlign: "left",
                              fontWeight: "Bold",
                              fontSize: 16,
                              color: "#424242",
                              pt: 1,
                              ml: 1,
                            }}
                          >
                            {Languages.OwnNotes}{" "}
                          </Typography>
                        }
                        callbackEdit={(status, component) =>
                          handleFormChange(status, component)
                        }
                      ></CommentsEditing>
                    )}
                  </Box>
                </div>
              ) : SlideIndex === 6 ? (
                <div>
                  <CommentsReadOnly
                    Title1={
                      <Typography
                        color="textSecondary"
                        sx={{
                          textAlign: "left",
                          fontWeight: "Bold",
                          fontSize: 16,
                          color: "#424242",
                          pt: 1,
                          ml: 1,
                        }}
                      >
                        {Languages.StaffNotes}{" "}
                      </Typography>
                    } //opettajan kooste - lapsen vahvuudet
                    Title2={null}
                    Title3={null}
                    BgColor={BGColor}
                    Data={{ Text: Table4Rows[1].RowItem[0].RowText }}
                  ></CommentsReadOnly>
                </div>
              ) : SlideIndex === 7 ? (
                <div>
                  <CommentsReadOnly
                    Title1={
                      <Typography
                        color="textSecondary"
                        sx={{
                          textAlign: "left",
                          fontWeight: "Bold",
                          fontSize: 16,
                          color: "#424242",
                          pt: 1,
                          ml: 1,
                        }}
                      >
                        {Languages.StaffNotes}{" "}
                      </Typography>
                    } //opettajan kooste - pedagog arvionti
                    Title2={null}
                    Title3={null}
                    BgColor={BGColor}
                    Data={{ Text: Table4Rows[2].RowItem[0].RowText }}
                  ></CommentsReadOnly>
                </div>
              ) : SlideIndex === 8 ? (
                <div>
                  <CommentsReadOnly
                    Title1={
                      <Typography
                        color="textSecondary"
                        sx={{
                          textAlign: "left",
                          fontWeight: "Bold",
                          fontSize: 16,
                          color: "#424242",
                          pt: 1,
                          ml: 1,
                        }}
                      >
                        {Languages.StaffNotes}{" "}
                      </Typography>
                    } //opettajan kooste - muut tiedot
                    Title2={null}
                    Title3={null}
                    BgColor={BGColor}
                    Data={{ Text: Table4Rows[3].RowItem[0].RowText }}
                  ></CommentsReadOnly>
                </div>
              ) : SlideIndex === 9 ? (
                <div
                  style={{
                    opacity: GeneralPart.FormLocked === true ? "60%" : "",
                  }}
                >
                  <CommentsReadOnly
                    Title1={
                      <Typography
                        color="textSecondary"
                        sx={{
                          textAlign: "left",
                          fontWeight: "Bold",
                          fontSize: 16,
                          color: "#424242",
                          pt: 1,
                          ml: 1,
                        }}
                      >
                        {Languages.OtherNotes}{" "}
                      </Typography>
                    } //opettajan kooste - huoltajan terveiset
                    Title2={null}
                    Title3={null}
                    BgColor={BGColor}
                    Data={{ Text: Table4Rows[4].RowItem[0].RowText }}
                  ></CommentsReadOnly>

                  {GeneralPart.FormLocked === true ? (
                    <CommentsReadOnly
                      Title1={
                        <Typography
                          color="textSecondary"
                          sx={{
                            textAlign: "left",
                            fontWeight: "Bold",
                            fontSize: 16,
                            color: "#424242",
                            pt: 1,
                            ml: 1,
                          }}
                        >
                          {Languages.OwnNotes}{" "}
                        </Typography>
                      } // omat
                      Title2={null}
                      Title3={null}
                      BgColor={BGColor}
                      Data={{
                        Text:
                          Table4Rows[4].RowItem[1].RowText === null
                            ? null
                            : Table4Rows[4].RowItem[1].RowText +
                              "\n" +
                              Table4Rows[4].RowItem[1].Editor,
                      }}
                    ></CommentsReadOnly>
                  ) : (
                    <CommentsEditing
                      key={"ParentSpringGreetings_" + SlideIndex}
                      Type={"Slide" + SlideIndex}
                      PrevType={EditingTextOpen} // hakki
                      BgColor={BGColor}
                      Data={{
                        Text:
                          Table4Rows[4].RowItem[1].RowText === null
                            ? ""
                            : Table4Rows[4].RowItem[1].RowText,
                        Saved: Table4Rows[4].RowItem[1].Editor,
                      }}
                      callback={(type, mode, newData) =>
                        handleCommentsEditingCB(type, mode, newData)
                      }
                      Title1={
                        <Typography
                          color="textSecondary"
                          sx={{
                            textAlign: "left",
                            fontWeight: "Bold",
                            fontSize: 16,
                            color: "#424242",
                            pt: 1,
                            ml: 1,
                          }}
                        >
                          {Languages.OwnNotes}{" "}
                        </Typography>
                      }
                      callbackEdit={(status, component) =>
                        handleFormChange(status, component)
                      }
                    ></CommentsEditing>
                  )}
                </div>
              ) : (
                <div>
                  <LeopsLocking></LeopsLocking>
                </div>
              )}

              <div
                className={
                  GeneralPart.FormLocked === true
                    ? classes.fixedbottom
                    : classes.displaynone
                }
              >
                {Languages.logged}
                <LockIcon
                  style={{ color: "#D500F9" }}
                  fontSize="large"
                ></LockIcon>
              </div>
            </div>
          )}
        </Box>
      </div>
    );
  }
};

export default SpringEvaluationSubPart;

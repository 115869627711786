import React from "react";
import "./newmessage.css";
import SelectRecipientsView from "./Components/SelectRecipientsView";
import SearchSuggestions from "./Components/SearchSuggestions";
import ContentBoxMUI from "../../../Components/ContentBoxMUI";
import Fab from "@mui/material/Fab";
import CheckIcon from "@mui/icons-material/Check";
import FixedBottomBar from "../../../Components/FixedBottomBar";
import Languages from "../../../translations";

const SelectRecipientsViewContainer = ({allRecipients, RGroup, RPersons, currentlyCheckedList, updateCheckedList, closeSelectRecipientsView}) => {

  return(

    <>
      <div>
        <div id="recipients-search">
          <SearchSuggestions 
            allRecipients={allRecipients} 
            currentlyCheckedList={currentlyCheckedList}
            updateCheckedList={(changedList) => updateCheckedList(changedList)}
          />
        </div>
        <SelectRecipientsView 
          RGroup = {RGroup}
          RPersons = {RPersons}
          currentlyCheckedList={currentlyCheckedList}
          updateCheckedList={(changedList) => updateCheckedList(changedList)}
        />
        <br />
      </div>
      <div height="15px"></div>
      <FixedBottomBar>
        <Fab 
          id="test_NewMsg_Recipients_OkBtn" className="fabcheck" 
          style={{margin:"10px", color:"#000000"}} color= "primary" aria-label="Add" onClick={closeSelectRecipientsView}>
          <CheckIcon />
        </Fab>
      </FixedBottomBar>
    </>
  )
}

export default SelectRecipientsViewContainer;
import React, { useState,  useCallback, useEffect } from "react";
import {useStateValue} from "../../State";
import "./leops.css";

import FixedBottomBar    from "../../Components/FixedBottomBar";
import Languages      from "../../translations";

import makeStyles from '@mui/styles/makeStyles';
import Check            from '@mui/icons-material/Check';
import Undo             from '@mui/icons-material/Undo';
import Fab              from "@mui/material/Fab"
import Backdrop         from '@mui/material/Backdrop';
import CircularProgress from "@mui/material/CircularProgress";
import { Grid }         from "@mui/material";
import { Paper }        from "@mui/material";
import LockIcon         from '@mui/icons-material/Lock';

import SmileyGreen  from '../../images/smileyt-02.svg';
import SmileyYellow from '../../images/smileyt-03.svg';

import { types } from "../../Reducers/actionTypes";

import _cloneDeep         from "lodash/cloneDeep"; // deep clones an array so that the copy is not just shallow
import { Callbacks } from "jquery";
import moment from "moment";

const useStyles = color =>
makeStyles(theme => ({
  blacktext: {
    color: "black",
    textAlign:"center"
  },
  nobottomline: {
    borderBottom: "none",
  },
  selected: {
    backgroundColor: "#ffc4a9",
    borderColor: "#c67651",
    borderStyle: "solid",
    borderWidth:"3px",
    textAlign:"center",
    paddingBottom:"8px",
    paddingTop:"10px",
    // borderBottomWidth: "initial"
  },
  notselected: {
    backgroundColor: "#fbdcce94",
    textAlign:"center",
    paddingBottom:"10px",
    paddingTop:"10px",
    border: "none",
  },
  img: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "50%"
  },
  backdrop: {
    //zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: "#FBE4FF !important"
  },
  paper: {
    // backgroundColor:"#FBE4FF",
  }
}));


const ChildSelfAssessment = (props) => {

  const { slide, slideData, title, table, color, callbackEdit } = props;
  //const classes = useStyles();
  const classes = useStyles(color)();

  const { state, actions } = useStateValue();

  const [SelectedSlide, setSlide] = useState(slide);
  const [showBottomBar, setshowBottombar] = useState(false);
  const [SavingData,    setSavingData]   = useState(false);

  const GeneralPart         = state.leops.GeneralPart;

  const [CopiedData,  setData]  = useState(_cloneDeep( slideData ));

  let [isSpring, setIsSpring] = useState((moment().month() === new Date().getMonth()) < 7);

  //console.log("LeopsSelfAssessment. MOUNT.  slideData: ", slideData);
  useEffect( () => {
    
    switch(state.leops.state)
    {
      case types.LEOPS_LOAD_OK:        
        setData(slideData);
        //console.log("LeopsSelfAssessment LEOPS_LOAD_OK \n slideData: ", slideData);
        //actions.triggerLeopsHandled();
        break;  
      case types.LEOPS_SAVED_OK:
        //console.log("LeopsSelfAssessment LEOPS_SAVED_OK -> show ok note");
        actions.triggerAddToSnackBarQueue({color: "#000000", message: Languages.leopsChildEvaluationSaved});
        //actions.triggerLoadLeops(GeneralPart.Id);
        //setSavingData(false);
        break;
      case types.LEOPS_SAVED_FAILED:
        setData( slideData );
        console.log("LeopsSelfAssessment LEOPS_SAVED_FAILED" + 
                    "\n slideData: ", slideData,
                    "\n CopiedData: ", CopiedData );

        //setSavingData(false);
        var codetext = state.leops.Code === undefined ? "\n" + Languages.errorUnknown : "\n" + Languages.errorCode + ": " + state.leops.Code;
        var msg = state.leops.Message === undefined ? "" : "\n" + state.leops.Message;
        actions.triggerAddToSnackBarQueue({color: "#000000", message: Languages.leopsChildEvaluationSaveFailed + " " + codetext + msg});
        actions.triggerLeopsHandled();
        break;
      default:
        console.log("LeopsSelfAssessment ",state.leops.state);
        break;
      }
  }, [state]);

  useEffect( () => {
    if (slide !==SelectedSlide)
    {
      //console.log("ChildSelfAssessment. set slide and data effect");
      setSlide(slide); 
      setData(_cloneDeep( slideData ));
    }
  }, [slide,slide, SelectedSlide/*, Data*/]);

  const saveSelections = useCallback((status) => {
    if (status === "cancel")
    {
      //console.log("ChildSelfAssessment. saveAnswer. Cancel changes");
      setData(_cloneDeep(slideData));
      callbackEdit("EDIT CANCELED", "Smiley"+slide);
    }
    else
    {
      /*console.log("ChildSelfAssessment. saveAnswer. Save changes" + 
                  "\n CopiedData: ", CopiedData);*/
      let Data = {LeopsId: GeneralPart.Id,
                  Data: CopiedData.AssessmentItem,
                  IsSpringEval: (table === 4 ? true : false)
                  }
      console.log("DATA", Data);
      
      actions.triggerSaveLeopsChildEvaluations(Data);
      callbackEdit("EDIT SAVED", "Smiley"+slide);
      //setSavingData(true);
    }
    setshowBottombar(false);
  });
    
  const handleSmileyClick = useCallback((status, Row, SubRow) => {

    if (GeneralPart.FromLocked)
    {
      //console.log("ChildSelfAssessment. handleSmileyClick. Leops locked, do nothing");
    }
    else
    {
      /*console.log("ChildSelfAssessment. handleSmileyClick. Status: ", status, " Row: ", Row, " SubRow: ", SubRow,
                  "\n CopiedData: ",CopiedData);*/

      var selection = CopiedData.AssessmentItem.find(item => item.SubRow === SubRow);
      if (status === "Harjoittelee")
      {
        selection.Knowing = selection.Learning === false ? false : selection.Knowing;
        selection.Learning = !selection.Learning;
      }
      else //  "Osaa"
      {
        selection.Learning = selection.Knowing === false ? false : selection.Learning;
        selection.Knowing = !selection.Knowing;
      }
      setshowBottombar(true);
      callbackEdit("EDITED", "Smiley"+slide);
    }
  });

  const  doNothing = () => {
    console.log("ChildSelfAssessment. doNothing");
  };
   
  return (
<>
  <div className={classes.blacktext}>
      <h6 className="boxed" style={{marginBottom: "0px"}}>{title}</h6>
      <div className="mb-3">{ isSpring && Languages.leopsAnswerSelectingBestChoice}</div>
      
      {SavingData ?
        <div style={{opacity: "0.5"}}>
          <Backdrop open={true}> <CircularProgress/> </Backdrop>
        </div>
      :
      <div>
          {CopiedData.AssessmentItem.map((item,index) => {

          return (
            <div key={index} >
              <div className="naamat p-2">
                <div className="mt-2">{item.RowText}</div>

                <Grid container spacing={2} className="pt-4 mb-2 justify-content-center">
                  
                  <Grid item xs={6} sm={4} md={4} lg={4}>
                    <Paper id={"test_ChildSelfAssessment_Learning_"+index}
                    component="button"
                    elevation={3}
                    className={item.Learning ? classes.selected : classes.notselected} width="50%" 
                    onClick={() => GeneralPart.FormLocked === true || item.Editable === false ? doNothing() : handleSmileyClick("Harjoittelee", item.Row, item.SubRow)}>
                    <img src={SmileyYellow} alt={"SmileyYellow"} className={classes.img} ></img>

                    {item.Learning && item.Editable === false ?
                    <LockIcon style={{color: "#D500F9"}} fontSize="large"></LockIcon>
                    : null}

                    <p className={classes.blacktext}>{Languages.leopsStillTraining}</p>
                    </Paper>
                  </Grid>
                  <Grid item xs={6} sm={4} md={4} lg={4}>
                    <Paper id={"test_ChildSelfAssessment_Knowing_"+index}
                    component="button"
                    elevation={3}
                    className={item.Knowing ? classes.selected : classes.notselected} width="50%"
                    onClick={() => GeneralPart.FormLocked === true || item.Editable === false ? doNothing() : handleSmileyClick("Osaa", item.Row, item.SubRow)}>
                    <img src={SmileyGreen} alt={"SmileyGreen"} className={classes.img} ></img>
                    
                    {item.Knowing && item.Editable === false ?
                    <LockIcon style={{color: "#D500F9"}} fontSize="large"></LockIcon>
                    : null}

                    <p className={classes.blacktext}>{Languages.leopsICanAlready}</p>
                    </Paper>
                  </Grid>

                </Grid>
              </div>
            <br></br>
            </div>
          );
          })}
        </div>
      }
               
      {showBottomBar ? 
        <FixedBottomBar>
          <div className="container painikkeet row">
            <Fab id="test_NewBooking_Cancel" style={{color: "white", backgroundColor: "#FF284C", margin: "auto"/*, marginRight: "10%"*/}} onClick={() => saveSelections("cancel")}> <Undo/> </Fab>
            <Fab id="test_NewBooking_Save" style={{color: "white", backgroundColor: "#81C784", margin: "auto"}} onClick={() => saveSelections("save")}> <Check/> </Fab>
          </div>
        </FixedBottomBar>
      : null }

    </div>
    </>
  );
}

export default ChildSelfAssessment;
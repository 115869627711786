import React from 'react';
import { useAuth } from '../../../../Auth';
import { Paper, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import Languages from "../../../../translations";
const useStyles = makeStyles({
  body1: {
    marginLeft: 25,
    marginBottom: 10,
    marginTop:20
  },
  body2: {
    marginLeft: 25,
    paddingBottom: 10
  }
});
const NewUserNote = ({show}) => {
  const { user } = useAuth();
  const classes = useStyles();
  if (!show) return null;

  return (
  <Paper elevation={3} >
    <Typography variant="h5" align="center">{Languages.welcomeToUsingDF}</Typography>
    <Typography variant="body1" className={classes.body1}>
      <b>{Languages.yourUserName}:</b> {user}
    </Typography>
    <Typography variant="body2" className={classes.body2}>
      {Languages.settingNewUserNote}
    </Typography>
  </Paper>
  );
}

export default NewUserNote;
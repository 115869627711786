import React, { useState, useEffect } from "react";
import {useStateValue} from "../../State";
import {ServerApi }  from "../../internal"; 

import "./vasu.css";

import Moment from 'moment';

import Languages      from "../../translations";

import Accordion         from '@mui/material/Accordion';
import AccordionSummary  from '@mui/material/AccordionSummary';
import AccordionDetails  from '@mui/material/AccordionDetails';
import ExpandMoreIcon         from '@mui/icons-material/ExpandMore';
import Divider                from '@mui/material/Divider';
import { Typography }         from "@mui/material";
import { downloadBase64File } from "../../utils";

import Table                  from '@mui/material/Table';
import TableBody              from "@mui/material/TableBody";
import TableCell              from "@mui/material/TableCell";
//import TableHead              from "@mui/material/TableHead";
import TableRow               from "@mui/material/TableRow";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  nobottomline: {
    borderBottom: "none",
    color: "black",
    backgroundColor: "#ffffff",
    flex: "0 0 100%"
  },
}));

const GeneralSection = (props) => {

  //const { history, location } = props;
  //const { childpicture, childName, childColor/*, VasuId*/ } = location.state;
  const classes = useStyles();

  const { state/*, actions*/ } = useStateValue();

  const { childName, vasu, Form, callback, closeView, SSN } = props;

  const Table3            = state.leops.Table3;
  const Table3GeneralPart = Table3 === null ? null : Table3.GeneralPart;

  const GeneralPart = Form !== undefined ? Table3GeneralPart[Form] :
                      vasu ? state.vasu.GeneralPart : state.leops.GeneralPart;

  // console.log("GeneralSection. MOUNT. SSN: ", SSN);//vasu: ", vasu, 
              //"\nGeneralPart: ", GeneralPart, 
              //"\nTable3: ", Table3);*/

  const [PrerequisitesOpen, setPrerequisitesOpen] = useState(false);
  const [GroupVasuValuesAndGoalsOpen, setGroupVasuValuesAndGoalsOpen] = useState(false);
 
  const handlePanelChange = (panel) => {
    //console.log("GeneralPart.  handlePanelChange: "+ panel);
    if (panel === "Prerequisites")
    {
      setPrerequisitesOpen(PrerequisitesOpen ? false : true);
      setGroupVasuValuesAndGoalsOpen(false);
    }
    else if  (panel === "GroupVasuValuesAndGoals" )
    {
      setGroupVasuValuesAndGoalsOpen(GroupVasuValuesAndGoalsOpen ? false : true)
      setPrerequisitesOpen(false);
    }
    else
    {
      setGroupVasuValuesAndGoalsOpen(false);
      setPrerequisitesOpen(false);
    }
    callback("GENERALPART");
  };

  const loadattachment = async (Id, Type, Name, FileType) => {
    
    //console.log("GeneralPart.  load attachment: Id: "+ Id+ " Type: "+Type+" Name: "+Name);
    
    const response = await new ServerApi().downloadFile(Id, Type, FileType);
    downloadBase64File(response.data, FileType/*undefinedimgMimeType*/, Name);
  };

  useEffect(() => {
    //console.log("GeneralPart. useEffect. closeView: ");
      setGroupVasuValuesAndGoalsOpen(false);
      setPrerequisitesOpen(false);
  },[closeView]); 


  return (
    <React.Fragment>
      <React.Fragment>
        <Accordion /*defaultExpanded*/ 
                   //className="Vasupainikkeet"
                   className="painikkeet"
                   sx={{backgroundColor: "#d9f6ff", borderRadius: .5, mt: 1.5, boxShadow: 2}}
                   expanded={PrerequisitesOpen ? true : false}
                   onChange = {() => handlePanelChange(PrerequisitesOpen ? null : "Prerequisites")}> 
          <AccordionSummary expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="test_Prerequisites_Btn" /*id="panel1a-header" */
                            sx={{'&:hover': {bgcolor:"#7bb2c9"}}}>
            <Typography className="heading" sx={{color: "#424242", fontWeight: 'Bold', ml: 2}}>{Languages.Preinformation1}</Typography>
          </AccordionSummary>
          <div className={classes.nobottomline}>
          <AccordionDetails>
            <Table>  
              <TableBody>
                <TableRow >
                  <TableCell className={classes.nobottomline} padding="none" align="left" >{Languages.child + ":"}</TableCell> 
                  <TableCell className={classes.nobottomline} style={{paddingLeft: "20px"}} align="left" >{childName}</TableCell>
                  <TableCell className={classes.nobottomline} padding="none" align="center">  </TableCell>
                </TableRow>

                {SSN === undefined ? null :
                <TableRow >
                  <TableCell className={classes.nobottomline} padding="none" align="left" >{Languages.childsBirthdate + ":"}</TableCell> 
                  <TableCell className={classes.nobottomline} style={{paddingLeft: "20px"}} align="left" >{SSN}</TableCell>
                  <TableCell className={classes.nobottomline} padding="none" align="center">  </TableCell>
                </TableRow>
                }

                {Form === null && GeneralPart.Attachments.map((item,iindex) => {
                  return (
                    <TableRow key={"Liite"+iindex}>
                      <TableCell className={classes.nobottomline} padding="none" align="left" >{vasu  && iindex === 0 ? Languages.medicalplan + ":" : 
                                                                                                vasu  && iindex === 1 ? Languages.attachments + ":" : 
                                                                                                !vasu && iindex === 0 ? Languages.attachments + ":" : ""}</TableCell> 

                        {iindex === 0 && GeneralPart.Attachments[0].Id === null ?
                          <TableCell className={classes.nobottomline} style={{paddingLeft: "20px", textDecoration: 'underline', cursor: "pointer"}} data-testid={"ut_vasu_medplan_name"} align="left"></TableCell>
                        : <TableCell className={classes.nobottomline} style={{paddingLeft: "20px", textDecoration: 'underline', cursor: "pointer"}} data-testid={iindex=== 0 ? "ut_vasu_medplan_name" :"ut_vasu_other_name"+iindex } align="left" onClick={() => loadattachment(item.Id, item.Type, item.Name, item.FileType)}>{item.Name}</TableCell>
                        }
                          <TableCell className={classes.nobottomline} padding="none" align="left">{item.Description}</TableCell>
                      </TableRow>
                  );
                })}

                {Form === undefined ?
                  <TableRow>
                    <TableCell className={classes.nobottomline} padding="none" align="left" >{Languages.ChildrenTalkMethodUsed1 + " "} &trade; {Languages.ChildrenTalkMethodUsed2 + ":"}</TableCell> 
                    <TableCell className={classes.nobottomline} style={{paddingLeft: "20px"}} align="left" >{GeneralPart.ChildrenTalkMethodUsed}</TableCell>
                    <TableCell className={classes.nobottomline} padding="none" align="center">  </TableCell>
                  </TableRow>
                : null }
                  {Form === 0 || Form === 1 || Form === 2 ?
                  <TableRow>
                    <TableCell className={classes.nobottomline} padding="none" align="left" >{Languages.PreschoolPlanCreated + ":"}</TableCell> 
                    <TableCell className={classes.nobottomline} style={{paddingLeft: "20px"}} align="left" >{GeneralPart.PreschoolPlanMade}</TableCell>
                    <TableCell className={classes.nobottomline} padding="none" align="center">  </TableCell>
                  </TableRow>
                : null}

                {Form === 1 || Form === 2 ?
                  <TableRow>
                    <TableCell className={classes.nobottomline} padding="none" align="left" >{Languages.EnhancedSupportLearningPlanCreated + ":"}</TableCell> 
                    <TableCell className={classes.nobottomline} style={{paddingLeft: "20px"}} align="left" >{GeneralPart.EnhancedPlanMade}</TableCell>
                    <TableCell className={classes.nobottomline} padding="none" align="center">  </TableCell>
                  </TableRow>
                : null }

                {Form === 3 ?
                  <TableRow>
                    <TableCell className={classes.nobottomline} padding="none" align="left" >{Languages.GeneralCompulsoryEducation + ":"}</TableCell> 
                    <TableCell className={classes.nobottomline} style={{paddingLeft: "20px"}} align="left" >{GeneralPart.GeneralCompulsoryEducation}</TableCell>
                    <TableCell className={classes.nobottomline} padding="none" align="left">
                      {GeneralPart.GeneralCompulsoryEducation === Languages.permYes ?
                       Languages.Started.toLowerCase() + ": " + Moment(GeneralPart.GeneralCompulsoryEducationStartDate).format("DD.MM.YYYY") :
                       null}  </TableCell>
                  </TableRow>
                : null }

                {Form === 3 ?
                  <TableRow>
                    <TableCell className={classes.nobottomline} padding="none" align="left" >{Languages.ExtendedCompulsoryEducation + ":"}</TableCell> 
                    <TableCell className={classes.nobottomline} style={{paddingLeft: "20px"}} align="left" >{GeneralPart.ExtendedCompulsoryEducation}</TableCell>
                    <TableCell className={classes.nobottomline} padding="none" align="left">
                      {GeneralPart.ExtendedCompulsoryEducation === Languages.permYes ?
                       Languages.Started.toLowerCase() + ": " + Moment(GeneralPart.ExtendedCompulsoryEducationStartDate).format("DD.MM.YYYY") :
                       null}</TableCell>
                  </TableRow>
                : null }

                <TableRow>
                  <TableCell className={classes.nobottomline} padding="none" align="left" >{Languages.guardian + " 1:"}</TableCell> 
                  <TableCell className={classes.nobottomline} style={{paddingLeft: "20px"}} align="left" >{GeneralPart.Parent1Name}</TableCell>
                  <TableCell className={classes.nobottomline} padding="none" align="center">  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className={classes.nobottomline} padding="none" align="left" >{Languages.guardian + " 2:"}</TableCell> 
                  <TableCell className={classes.nobottomline} style={{paddingLeft: "20px"}} align="left" >{GeneralPart.Parent2Name}</TableCell>
                  <TableCell className={classes.nobottomline} padding="none" align="center">  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className={classes.nobottomline} colSpan={3} padding="none"align="left" >
                    <br></br>{Languages.vasuInvoledPeople}</TableCell> 
                </TableRow>

                <TableRow>
                  <TableCell className={classes.nobottomline} padding="none" align="left" >{Languages.eceTeacher+ ":"}</TableCell> 
                  <TableCell className={classes.nobottomline} style={{paddingLeft: "20px"}} align="left" >{GeneralPart.ECETeacher}</TableCell>
                  <TableCell className={classes.nobottomline} padding="none" align="center">  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className={classes.nobottomline} padding="none" align="left" >{Languages.otherParticipants + ":"}</TableCell> 
                  <TableCell className={classes.nobottomline} style={{paddingLeft: "20px"}} align="left" >{GeneralPart.OtherPersons}</TableCell>
                  <TableCell className={classes.nobottomline} padding="none" align="center">  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className={classes.nobottomline} padding="none" align="left" >{Languages.creationDate + ":"}</TableCell> 
                  <TableCell className={classes.nobottomline} style={{paddingLeft: "20px"}} align="left" >{Moment(GeneralPart.CreationDate).format('DD.MM.YYYY')}</TableCell>
                  <TableCell className={classes.nobottomline} padding="none" align="center">  </TableCell>
                </TableRow>

              </TableBody>
            </Table>

          </AccordionDetails>
          </div>

        </Accordion>
      </React.Fragment>

      {GeneralPart.GroupVasuCriteria === null && GeneralPart.GroupVasuObjectives === null ? null
      :
        <React.Fragment>
          <Accordion /*defaultExpanded*/ 
                          //className="Vasupainikkeet"
                          className="painikkeet"
                          sx={{backgroundColor: "#d9f6ff", borderRadius: .5, mt: -0.5, boxShadow: 2}}
                          expanded={GroupVasuValuesAndGoalsOpen ? true : false}
                          onChange = {() => handlePanelChange(GroupVasuValuesAndGoalsOpen ? null : "GroupVasuValuesAndGoals")}> 
            <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                   aria-controls="panel2a-content"
                                   id="test_Prerequisites_Btn" /*id="panel1a-header" */
                                   sx={{'&:hover': {bgcolor:"#7bb2c9"}}}>
              <Typography className="heading" sx={{color: "#424242", fontWeight: 'Bold', ml: 2}}>{Languages.Preinformation2}</Typography>
          </AccordionSummary>
          <div className={classes.nobottomline}>
          <AccordionDetails>
            <Table>  
              <TableBody>
                <TableRow >
                    <TableCell className={classes.nobottomline} padding="none" align="left" >{Languages.vasuValues + ":"}</TableCell>
                    <TableCell className={classes.nobottomline} style={{paddingLeft: "20px"}} align="left" ></TableCell>
                    <TableCell className={classes.nobottomline} padding="none" align="center">  </TableCell>
                  </TableRow>
                  <TableRow >
                    <TableCell className={classes.nobottomline} padding="none" align="left" >{GeneralPart.GroupVasuCriteria}</TableCell> 
                    <TableCell className={classes.nobottomline} style={{paddingLeft: "20px"}} align="left" ></TableCell>
                    <TableCell className={classes.nobottomline} padding="none" align="center">  </TableCell>
                  </TableRow>
                  <TableRow >
                    <TableCell className={classes.nobottomline} padding="none" align="left" >{Languages.groupObjectives + ":"}</TableCell>
                    <TableCell className={classes.nobottomline} style={{paddingLeft: "20px"}} align="left" ></TableCell>
                    <TableCell className={classes.nobottomline} padding="none" align="center">  </TableCell>
                  </TableRow>
                  <TableRow >
                    <TableCell className={classes.nobottomline} padding="none" align="left" >{GeneralPart.GroupVasuObjectives}</TableCell>
                    <TableCell className={classes.nobottomline} style={{paddingLeft: "20px"}} align="left" ></TableCell>
                    <TableCell className={classes.nobottomline} padding="none" align="center">  </TableCell>
                  </TableRow>
                </TableBody>
                </Table>  
              </AccordionDetails>
          </div>
          </Accordion>
        </React.Fragment>
        
      }
    </React.Fragment>
  );
}

export default GeneralSection;
import React from "react";
import { history } from "../../Router";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FollowTheSignsIcon from "@mui/icons-material/FollowTheSigns";
import EventNoteIcon from "@mui/icons-material/EventNote";
import { useStateValue } from "../../State";
import Languages from "../../translations";
import moment from "moment";
import ChildProfilePicture from "../../Components/ChildProfilePicture";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ErrorIcon from "@mui/icons-material/Error";
import LockClockIcon from "@mui/icons-material/LockClock";
import HouseboatIcon from "@mui/icons-material/Houseboat";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ReservationNotifications = ({ onClose }) => {
  const [expanded, setExpanded] = React.useState(false);
  const { state } = useStateValue();
  const { mainstats } = state;
  const {
    state: { smallRequests },
  } = useStateValue();
  const {
    vacationNotificationStatus: { data },
  } = smallRequests;

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleRedirectToCaretimes = () => {
    if (history.location.pathname === "/caretimecalendar") {
      onClose();
    } else {
      history.push("/caretimecalendar");
    }
  };

  if (
    mainstats.nursingTimesStatusAllDone === false ||
    data.ShowNotification === true
  ) {
    return (
      <>
        <Divider textAlign="left">
          <Chip
            label={Languages.bookingCareHoursTitle}
            sx={{ bgcolor: "#002E6D", textTransform: "uppercase" }}
          />
        </Divider>
        <Card
          sx={{
            bgcolor: "#FFEBD4",
            border: "1px solid #EE5711",
            borderLeft: "7px solid #EE5711",
            margin: "5px",
          }}
        >
          <CardHeader
            avatar={
              <Avatar
                sx={{ bgcolor: "#EE5711" }}
                aria-label="Nursing times icon"
              >
                <EventNoteIcon />
              </Avatar>
            }
            action={
              <Tooltip title={Languages.noteGoToBookings} arrow>
                <IconButton
                  aria-label={Languages.noteGoToBookings}
                  onClick={handleRedirectToCaretimes}
                >
                  <FollowTheSignsIcon sx={{ color: "#EE5711" }} />
                </IconButton>
              </Tooltip>
            }
            title={
              <p
                style={{ color: "black", fontSize: "large", marginBottom: "0" }}
              >
                <b>{Languages.bookingCareHoursMissing}!</b>
              </p>
            }
            subheader={Languages.awNursingTimesLock}
          />
          <CardActions disableSpacing>
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label={Languages.awShowMore}
              title={Languages.awShowMore}
            >
              <ExpandMoreIcon sx={{ color: "#EE5711" }} />
            </ExpandMore>
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <List
                /*dense={dense}*/ aria-labelledby="Missing nursing times list"
                subheader={
                  <ListSubheader
                    component="div"
                    sx={{
                      display: "flex",
                      verticalAlign: "middle",
                      bgcolor: "#FFEBD4",
                      border: "1px solid #EE5711",
                      borderLeft: "7px solid #EE5711",
                      padding: "5px",
                      margin: "5px",
                      lineHeight: "inherit",
                    }}
                  >
                    <ErrorIcon fontSize="medium" sx={{ color: "#EE5711" }} />
                    &nbsp;&nbsp;{Languages.bookingCareHoursMissingTimeSpan}
                  </ListSubheader>
                }
              >
                {mainstats.nursingTimesStatusAllDone === false &&
                  mainstats.listChildNursingTimeStatus.map((child) => {
                    return (
                      <ListItem
                        key={child.ChildPersonId}
                        button
                        divider
                        sx={{ borderBottom: "1px solid black" }}
                        onClick={handleRedirectToCaretimes}
                        secondaryAction={
                          <ChevronRightIcon sx={{ color: "#EE5711" }} />
                        }
                      >
                        <ListItemAvatar>
                          <ChildProfilePicture childId={child.ChildPersonId} />
                        </ListItemAvatar>
                        <ListItemText
                          sx={{ color: "black" }}
                          primary={child.ChildName}
                          secondary={
                            <>
                              <LockClockIcon
                                className="blink_lock_icon"
                                fontSize="medium"
                                sx={{ color: "#EE5711" }}
                              />{" "}
                              {moment(child.CheckedStartDate).format("DD.MM")} -{" "}
                              {moment(child.CheckedEndDate).format("DD.MM.YY")}
                            </>
                          }
                        />
                      </ListItem>
                    );
                  })}
                {data.ShowNotification && (
                  <ListItem
                    button
                    divider
                    sx={{ borderBottom: "1px solid black" }}
                    onClick={handleRedirectToCaretimes}
                    secondaryAction={
                      <ChevronRightIcon sx={{ color: "#EE5711" }} />
                    }
                  >
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: "#EE5711" }}>
                        <HouseboatIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      sx={{ color: "black" }}
                      primary={Languages.bookingCareHoursMissing}
                      secondary={
                        <>
                          {data.NotificationTextRows?.map((row, index) => {
                            return <Typography key={index}>{row}</Typography>;
                          })}{" "}
                        </>
                      }
                    />
                  </ListItem>
                )}
              </List>
            </CardContent>
          </Collapse>
        </Card>
      </>
    );
  } else return null;
};

export default ReservationNotifications;

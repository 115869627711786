import React, { useState, useEffect } from "react";
import {useStateValue} from "../../../State";
import Typography from "@mui/material/Typography";
import Languages              from '../../../translations';
import Radio               from "@mui/material/Radio";
import TableContainer         from "@mui/material/TableContainer";
import Table                  from "@mui/material/Table";
import TableBody              from "@mui/material/TableBody";
import TableRow               from "@mui/material/TableRow";
import TableCell              from "@mui/material/TableCell";
import FormControl            from "@mui/material/FormControl";
import FormLabel              from "@mui/material/FormLabel";
import RadioGroup             from "@mui/material/RadioGroup";
import FormControlLabel       from "@mui/material/FormControlLabel";
import AnswerEveryQuestionPopUp from "./AnswerEveryQuestionPopUp";
import _cloneDeep             from "lodash/cloneDeep";
import SaveChildPermPermsButton     from "./SaveChildPermPermsButton.jsx";
import CircularProgress       from "@mui/material/CircularProgress";
import {types}                from "../../../Reducers/actionTypes";

const ChildPermPermissionSuccess = ({muniPermData, isShown, changeEditStatusOn, changeEditStatusOff}) => {

  const { state, actions } = useStateValue();

  const postChildPermPermissionsStatus = state.municipalpermissionquestions.saveChildPermPermissions.status;

  const [copyOfData, setCopyOfData] = useState(_cloneDeep(muniPermData.MunicipalPermissionQuestions));

  const [currentlyChecked, setCurrentlyChecked] = useState(copyOfData);
  const [somethingChanged, setSomethingChanged] = useState(false);
  const [sentData, setSentData] = useState();
  const [prevStatus, setPrevStatus] = useState("NEVER_LOADED");

  const [nullWarning, setNullWarning] = useState(false);

  useEffect(() => {
    setCopyOfData(_cloneDeep(muniPermData.MunicipalPermissionQuestions));
  }, [muniPermData.MunicipalPermissionQuestions]);

  useEffect(() => {
    if(prevStatus === types.LOADING_POST_CHILD_PERMPERMISSIONS && postChildPermPermissionsStatus === types.POST_CHILD_PERMPERMISSIONS_SUCCESS) {
      setCopyOfData(_cloneDeep(sentData));
    }
    if(prevStatus === types.LOADING_POST_CHILD_PERMPERMISSIONS && postChildPermPermissionsStatus === types.POST_CHILD_PERMPERMISSIONS_FAILED) {
      setSomethingChanged(true);
    }
    setPrevStatus(postChildPermPermissionsStatus);
  }, [postChildPermPermissionsStatus, prevStatus, setCopyOfData, sentData])

  useEffect(() => {
    if(prevStatus === types.LOADING_POST_CHILD_PERMPERMISSIONS && postChildPermPermissionsStatus === types.POST_CHILD_PERMPERMISSIONS_SUCCESS) {
      actions.triggerLoadPermissionsMainStats();
      actions.triggerAddToSnackBarQueue({color: "#000000", message: Languages.permChildPermPermsSaved});
      changeEditStatusOff()
    }
    if(prevStatus === types.LOADING_POST_CHILD_PERMPERMISSIONS && postChildPermPermissionsStatus === types.POST_CHILD_PERMPERMISSIONS_FAILED) {
      actions.triggerLoadPermissionsMainStats();
    }
    setPrevStatus(postChildPermPermissionsStatus);
  }, [postChildPermPermissionsStatus, prevStatus, actions])

  


  useEffect(() => {
    return function cleanUp() {
      actions.triggerCleanPostChildPermPermissions();
    };
  }, []);

  const processCheckedThings = (isChecked, e) => {
    let updatedArray = _cloneDeep(currentlyChecked);
    updatedArray.forEach((perm) => {
      if (perm.QuestionId === e.target.value) {
        perm.AnswerStatus = isChecked;
      }
    });
    setCurrentlyChecked(updatedArray);
    console.log("currentlyChecked", currentlyChecked);

    let isDifferent = false;

    updatedArray.forEach((updated) => {
      for(let i = 0 ; i < copyOfData.length; i++){
        if (updated.QuestionId === copyOfData[i].QuestionId) {
          if (updated.AnswerStatus !== copyOfData[i].AnswerStatus){
            isDifferent = true;
          }
        }
      }
    })

    if(isDifferent){
      setSomethingChanged(true);
      changeEditStatusOn()    
    }
    else{
      setSomethingChanged(false);
      changeEditStatusOff()
    }
  }

  const checkBoxOnChangeYes = (e) => {
    processCheckedThings(true, e);
  }

  const checkBoxOnChangeNo = (e) => {
    processCheckedThings(false, e);
  }

  const saveChangesToServer = () => {
    const isValid = checkValidity(currentlyChecked);

    if (isValid) {
      actions.triggerPostChildPermPermissions(currentlyChecked);
      setNullWarning(false);
      setSomethingChanged(false);
      setSentData(_cloneDeep(currentlyChecked));
    }
    else {
      setNullWarning(true);
    }
  }

  const checkValidity = (listToSend) => {
    let isValid = true; 
    
    listToSend.forEach((item) => {
      if(item.AnswerStatus == null) {
        isValid = false;
      }
    })
    return(isValid);
  }

  const closeNullWarningPopUp = () => {
    setNullWarning(false);
  }


  if(!isShown) {
    return(<div></div>)
  }
  
  return(
    <div>

      <SaveChildPermPermsButton
        saveChanges={saveChangesToServer}
        showButton={somethingChanged}
      />

      { muniPermData && muniPermData.MunicipalAnnouncementText ?
        <React.Fragment>
          <Typography>{muniPermData.MunicipalAnnouncementText}</Typography><br/>
        </React.Fragment>
        : <div></div>
      }

      {postChildPermPermissionsStatus === "LOADING_POST_CHILD_PERMPERMISSIONS" ? 
        <div style={{margin: "auto"}}> <CircularProgress/> {Languages.permSaving} </div> : null
      }

      {postChildPermPermissionsStatus === "POST_CHILD_PERMPERMISSIONS_FAILED" ? 
        <div style={{margin: "auto", backgroundColor: "#e036da"}}> 
          <Typography>{Languages.permSavingFailed + " "} {state.municipalpermissionquestions.saveChildPermPermissions.Code}
          </Typography>
        </div> : null
      }

      {currentlyChecked && currentlyChecked.length > 0 ? 

      <React.Fragment>

          <AnswerEveryQuestionPopUp 
            isShown={nullWarning}
            closeNullWarningPopUp={closeNullWarningPopUp}
          />

          <TableContainer style={{marginLeft: "-16px"}} >
            <Table size="small" aria-label="permissions questions">
              <TableBody>

                {currentlyChecked.map((perms, index) => {
                
                  return(
                    <React.Fragment key={perms.QuestionId}>
                      <TableRow style={{backgroundColor: "#ffffff"}}>                      
                            <TableCell component="th" style={{maxWidth: "10px"}}> <div style={perms.AnswerStatus === null ? {color: "#EE5711", borderLeft: "6px solid #EE5711", height: "40px", } : {color: "#0095d8", borderLeft: "6px solid white", height: "40px"}}></div></TableCell>
                            <TableCell component="th">
                          {/* Ripple was disabled because 1.3.2021 ripple causes buggy flickering in Chrome. Changed in Sept 2021*/}
                         
                          <div style={{display:"inline-block"}}>
                            <FormControl component="fieldset">
                              
                              
                                <FormLabel component="legend" style={{color: "#000000"}}>{perms.QuestionName}</FormLabel>
                                  <RadioGroup row aria-label={perms.QuestionName} name={perms.QuestionName}>
                                    <FormControlLabel style={{color: "#000000"}}
                                      value={perms.QuestionId} 
                                      checked={perms.AnswerStatus === true ? true : false} 
                                      control={<Radio disableRipple={false}/>} 
                                      label={Languages.permYes}
                                      onChange={checkBoxOnChangeYes}/>
                                    <FormControlLabel style={{color: "#000000"}}
                                      value={perms.QuestionId} 
                                      checked={perms.AnswerStatus === false ? true : false}
                                      control={<Radio disableRipple={false}/>} 
                                      label={Languages.permNo}
                                      onChange={checkBoxOnChangeNo}/>
                                  </RadioGroup>
                              
                            </FormControl>
                          </div>
                        </TableCell>

                      </TableRow>
                    </React.Fragment>
                  )

                })}
              </TableBody>
            </Table>
          </TableContainer> 
          <br />
          <SaveChildPermPermsButton
        saveChanges={saveChangesToServer}
        showButton={somethingChanged}
      />

          <br/>
          {/*<div>
            <Button disabled={!somethingChanged} variant="contained" color="primary" onClick={saveChangesToServer}>Tallenna muutokset</Button>
          </div>*/}
        </React.Fragment>
        :
        <Typography>{Languages.permNoQuestionsToShow}</Typography>
      
      }

    </div>
  )
}

export default ChildPermPermissionSuccess;
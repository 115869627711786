import React                from "react";
import "./sendersmessage.css";
import moment               from "moment";
import Card                 from "@mui/material/Card";
import CardContent          from "@mui/material/CardContent";
import Grid                 from "@mui/material/Grid";
import Typography           from "@mui/material/Typography";
import { styled } from '@mui/material/styles';
import Languages            from "../../../translations";
import PreviewPicture       from "../PreviewPicture";
import MessagingFileDownload  from "../MessagingFileDownload";
import MessageText from "../../MessageText"
import AttachmentListMSG from "./AttachmentListMSG";

import FaceOutlinedIcon from '@mui/icons-material/FaceOutlined';

const Triangle = styled('div')(() => ({
  float:"right", 
  marginRight:"-16px", 
  marginTop:"3px", 
  height:"50%", 
  display:"inline-block", 
  width: "0", 
  height: "0", 
  borderTop: "8px solid transparent", 
  borderBottom: "8px solid transparent", 
  borderRight: "8px solid #3A67A1"
}))

const CardPrefix = 'MsgCard'
const classes = {
  text: `${CardPrefix}-text`
}

const CardRoot = styled(Card)(() => ({
  borderColor: "#3A67A1", 
  borderWidth: '2px', 
  borderStyle: "solid", 
  backgroundColor: "#3A67A1",
  '& a': {
    color: "#bfbdbd"
  },
  '& a:hover': {
    color: "#afd5ff"
  },

  [`&.${classes.text} `]: { // Example to apply styles to child element. Ex. <CardRoot><Typegraphy className={classes.text}/>
    //color: "#D6E4FF"
    color: "red"
  }
}))

const TextTypography = styled(Typography)(() => ({
  color: "#D6E4FF"
}))

const SendersMessageBox = ({showThreadSentToMasses, picturesrc, messagesender, messagetext, messagedate, 
  imageId, imgType, imgName, showFullImage, numberOfAttachments, FileName, FileMimeType, FileId, compact, attachmentList}) => {
  

  if (compact) {
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={1}>
          <Triangle />
          </Grid>
          <Grid /*item xs={9}*/ item xs={11}>
         
            <div style={{display: "contents", width: "100%"}}>
              <CardRoot width="100%">
              <div style={{marginLeft: "4px", marginRight:"4px", marginTop:"1px", marginBottom:"1px"}}>
                <div style={{marginRight:"0px"}}>
                  <span ><TextTypography variant="body2" style={{paddingBottom: "7px"}}><FaceOutlinedIcon /> {messagesender}:</TextTypography></span> 
                  <MessageText color="textPrimary" variant="body2"  style={{whiteSpace: "pre-wrap", marginRight:"10px", paddingTop: "10px"}}>
                    {messagetext !== undefined && messagetext !== null ? messagetext : null}
                  </MessageText>
                {numberOfAttachments !== 0 ?
                    <AttachmentListMSG attachmentList = {attachmentList} numberOfAttachments = {numberOfAttachments} showFullImage={showFullImage}/>
                    :null }
                  

                 
                  
        <hr />
                </div>
                <span className="messagesenderright sentdate SMBtextWrap"><TextTypography variant="body2"><i>{moment(messagedate).format("DD.MM. HH:mm")}</i></TextTypography></span>
                  
                </div>
              </CardRoot>
            </div>
            </Grid>
            <Grid item xs={2}></Grid>
            <br /> <br />
        </Grid>
      </div>
    );
  }

 
}

export default SendersMessageBox;



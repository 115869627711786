import React                  from 'react';
import IconButton             from "@mui/material/IconButton";
import "../usedcaretimesinfodrawer.css";
import Card                   from "@mui/material/Card";
import CardContent            from "@mui/material/CardContent";
import Languages              from "../../../translations";
import CancelIcon             from "@mui/icons-material/Cancel";
import RefreshIcon            from "@mui/icons-material/Refresh";  

const NTSummaryLoadFailed = ({failCode, refreshDate, onFailedLoadRetry, closeDrawer}) => {

  return (
    <Card>
      <CardContent>
        <IconButton onClick={closeDrawer} size="large">
          <CancelIcon/>
        </IconButton>
        <p>{Languages.errorNTsummary}</p>
        <p>{ Languages.errorCode + " : " +
              failCode === 400 ? failCode + " " + Languages.errorBadRequest :
                failCode === 404 ? failCode + " " + Languages.errorNotFound :  
                  failCode === 500 ? failCode + " " + Languages.errorInDB :
                    failCode === 502 ? failCode + " " + Languages.errorNetwork : 
                      Languages.errorUnknown }
        </p>
        <IconButton onClick={() => onFailedLoadRetry(refreshDate)} size="large"><RefreshIcon/></IconButton>
      </CardContent>
    </Card>
  );
}

export default NTSummaryLoadFailed;
import { types } from "./actionTypes";
import Auth, { ESUOMI_AUTH_TYPE_VALUES } from "../Auth";

export const useActions = (state, dispatch) => ({
  triggerUpdateDoNotLoadInboxVariable: (data) =>
    dispatch({ type: types.UPDATE_DO_NOT_LOAD_INBOX_VARIABLE, payload: data }),
  triggerMarkThreadReadLocally: (data) =>
    dispatch({ type: types.MARK_THREAD_READ_LOCALLY, payload: data }),

  // NEW MESSAGE DATA LOAD
  triggerLoadMessageData: (data) =>
    dispatch({ type: types.ASYNC_LOAD_MESSAGE_DATA, payload: data }),

  ////////////////////////////////
  triggerLoadMessages: (data) =>
    dispatch({ type: types.ASYNC_LOAD_MESSAGES, payload: data }),
  triggerLoadThreadFromServer: (data) =>
    dispatch({ type: types.ASYNC_LOAD_MESSAGE_THREAD, payload: data }),
  triggerSetThisThreadRead: (data) =>
    dispatch({ type: types.ASYNC_SET_THREAD_READ, payload: data }),
  triggerSetAllMessagesRead: (data) =>
    dispatch({ type: types.ASYNC_SET_ALL_MESSAGES_READ, payload: data }),
  triggerDeleteThread: (data) =>
    dispatch({ type: types.ASYNC_DELETE_MESSAGE_THREAD, payload: data }),

  triggerReplyTo_MASS_thread: (data) =>
    dispatch({ type: types.ASYNC_REPLYTO_MASS_THREAD, payload: data }),
  triggerReplyTo_NONMASS_thread: (data) =>
    dispatch({ type: types.ASYNC_REPLYTO_NONMASS_THREAD, payload: data }),
  triggerPostNewMessage: (data) =>
    dispatch({ type: types.ASYNC_POST_NEW_MESSAGE, payload: data }),
  triggerResetValues_Post_New_Message: (data) =>
    dispatch({ type: types.RESET_POST_NEW_MESSAGE, payload: data }),
  triggerLoadPotentialRecipients: (data) =>
    dispatch({ type: types.ASYNC_LOAD_POTENTIAL_RECIPIENTS, payload: data }),
  triggerOpenPostNewMessageSnackbar: (data) =>
    dispatch({ type: types.OPEN_POST_NEW_MESSAGE_SNACKBAR }),
  triggerUpdateOMScrollAssistant: (data) =>
    dispatch({ type: types.UPDATE_OM_SCROLL_ASSISTANT, payload: data }),
  triggerClearLoadThread: (data) =>
    dispatch({ type: types.CLEAR_LOAD_THREAD_DATA }),

  triggerLoadPreviewPicture: (data) =>
    dispatch({ type: types.ASYNC_LOAD_PREVIEW_PICTURE, payload: data }),
  triggerLoadFullImage: (data) =>
    dispatch({ type: types.ASYNC_LOAD_FULL_IMAGE, payload: data }),

  triggerUpdateInvoiceBasisViewState: (data) =>
    dispatch({ type: types.UPDATE_INVOICE_BASIS_VIEW_STATE, payload: data }),
  triggerLoadInvoiceSummaryTable: (data) =>
    dispatch({ type: types.ASYNC_LOAD_INVOICE_SUMMARY_TABLE, payload: data }),

  triggerLoadDefaultNursingTimes: (data) =>
    dispatch({ type: types.ASYNC_LOAD_DEFAULT_NURSINGTIMES, payload: data }),
  triggerDefaultNursingTimesHandled: (data) =>
    dispatch({ type: types.DEFAULT_NURSINGTIMES_HANDLED, payload: data }),
  triggerSaveDefaultNursingTimes: (data) =>
    dispatch({ type: types.ASYNC_SAVE_DEFAULT_NURSINGTIMES, payload: data }),
  triggerSaveClockType: (data) =>
    dispatch({ type: types.ASYNC_SAVE_CLOCK_TYPE, payload: data }),
  triggerLoadClockType: (data) =>
    dispatch({ type: types.ASYNC_LOAD_CLOCK_TYPE, payload: data }),
  triggerLoadNextNursingTimes: (data) =>
    dispatch({ type: types.ASYNC_READ_NEXTNURSINGTIMES, payload: data }),
  triggerNextNursingTimesHandled: (data) =>
    dispatch({ type: types.NEXTNURSINGTIMES_HANDLED, payload: data }),
  triggerLoadNursingTimes: (data) =>
    dispatch({ type: types.ASYNC_READ_NURSINGTIMES, payload: data }),
  triggerNursingTimesReadHandled: (data) =>
    dispatch({ type: types.NURSINGTIMES_READ_HANDLED, payload: data }),
  triggerNursingTimesFailedHandled: (data) =>
    dispatch({ type: types.NURSINGTIMES_FAILED_HANDLED, payload: data }),
  triggerSaveNursingTimes: (data) =>
    dispatch({ type: types.ASYNC_SAVE_NURSINGTIMES, payload: data }),
  triggerSaveOkNursingTimesHandled: (data) =>
    dispatch({ type: types.NURSINGTIMES_SAVED_OK_HANDLED, payload: data }),
  triggerSaveFailedNursingTimesHandled: (data) =>
    dispatch({ type: types.NURSINGTIMES_SAVED_FAILED_HANDLED, payload: data }),
  triggerDeleteNursingtimes: (data) =>
    dispatch({ type: types.ASYNC_DELETE_NURSINGTIMES, payload: data }),
  triggerDeleteNursingtimesMany: (data) =>
    dispatch({ type: types.ASYNC_DELETE_MANY_NURSINGTIMES, payload: data }),
  triggerDeleteNursingtimesHandled: (data) =>
    dispatch({ type: types.NURSINGTIMES_DELETED_HANDLED, payload: data }),

  //WIP CARETIMES
  triggerLoadCareTimes: (data) =>
    dispatch({ type: types.ASYNC_LOAD_CARETIMES, payload: data }),
  triggerLoadCareTimesNext: (data) =>
    dispatch({ type: types.ASYNC_LOAD_CARETIMES_NEXT, payload: data }),
  triggerLoadCareTimesPrev: (data) =>
    dispatch({ type: types.ASYNC_LOAD_CARETIMES_PREV, payload: data }),
  triggerLoadCareTimesQuick: (data) =>
    dispatch({ type: types.ASYNC_LOAD_CARETIMES_QUICK, payload: data }),
  triggerLoadCareTimesQuickNext: (data) =>
    dispatch({ type: types.ASYNC_LOAD_CARETIMES_QUICK_NEXT, payload: data }),
  triggerCheckCareTimes: (data) =>
    dispatch({ type: types.ASYNC_CHECK_CARETIMES, payload: data }),
  triggerSaveCareTimes: (data) =>
    dispatch({ type: types.ASYNC_SAVE_CARETIMES, payload: data }),
  triggerSaveCareTimesAbsent: (data) =>
    dispatch({ type: types.ASYNC_SAVE_ABSENT_CARETIMES, payload: data }),
  triggerDeleteCareTimes: (data) =>
    dispatch({ type: types.ASYNC_DELETE_CARETIMES, payload: data }),
  //------------------------------
  triggerLoadNursingTimesSummary: (data) =>
    dispatch({ type: types.ASYNC_LOAD_NT_SUMMARY, payload: data }),
  triggerLoadPlacementAndCentreInfo: (data) =>
    dispatch({ type: types.ASYNC_LOAD_PL_CENTRE_INFOS, payload: data }),

  triggerLoadChildFilesList: (data) =>
    dispatch({ type: types.ASYNC_LOAD_CHILD_FILES, payload: data }),

  triggerSaveHeaderTitle: (data) =>
    dispatch({ type: types.UPDATE_HEADER_TITLE, payload: data }),
  triggerSaveArrowCloseIconBooleans: (data) =>
    dispatch({ type: types.UPDATE_HEADER_ARROW, payload: data }),
  triggerUpdateMessageThreadId: (data) =>
    dispatch({ type: types.UPDATE_MESSAGE_THREAD_ID, payload: data }),

  triggerLoadChildren: (data) =>
    dispatch({ type: types.ASYNC_LOAD_CHILDREN, payload: data }),

  triggerLoadLeops: (data) =>
    dispatch({ type: types.ASYNC_LOAD_LEOPS, payload: data }),
  triggerSaveLeopsLocking: (data) =>
    dispatch({ type: types.ASYNC_SAVE_LOCKING_LEOPS, payload: data }),
  triggerSaveLeopsChildEvaluations: (data) =>
    dispatch({ type: types.ASYNC_SAVE_CHILD_EVALUATIONS_LEOPS, payload: data }),
  triggerSaveLeopsParentComments: (data) =>
    dispatch({ type: types.ASYNC_SAVE_PARENT_COMMENTS_LEOPS, payload: data }),
  triggerLeopsHandled: (data) =>
    dispatch({ type: types.LEOPS_HANDLED, payload: data }),

  triggerSaveStudyForm: (data) =>
    dispatch({ type: types.ASYNC_SAVE_STUDYFORM, payload: data }),

  triggerLoadVasu: (data) =>
    dispatch({ type: types.ASYNC_LOAD_VASU, payload: data }),
  triggerSaveVasu: (data) =>
    dispatch({ type: types.ASYNC_SAVE_VASU, payload: data }),
  triggerVasuHandled: (data) =>
    dispatch({ type: types.VASU_HANDLED, payload: data }),
  triggerLoadChildinfo: (data) =>
    dispatch({ type: types.ASYNC_LOAD_CHILDINFO, payload: data }),
  triggerSaveChildinfo: (data) =>
    dispatch({ type: types.ASYNC_SAVE_CHILDINFO, payload: data }),
  triggerChildinfoHandled: (data) =>
    dispatch({ type: types.LOAD_HANDLED_CHILDINFO, payload: data }),

  triggerLoadVasu: (data) =>
    dispatch({ type: types.ASYNC_LOAD_VASU, payload: data }),
  triggerSaveVasu: (data) =>
    dispatch({ type: types.ASYNC_SAVE_VASU, payload: data }),
  triggerVasuHandled: (data) =>
    dispatch({ type: types.VASU_HANDLED, payload: data }),

  triggerLoadDailyNotes: (data) =>
    dispatch({ type: types.ASYNC_LOAD_DAILY_NOTES, payload: data }),

  triggerSaveApproval: (data) =>
    dispatch({ type: types.ASYNC_SAVE_APPROVAL, payload: data }),
  triggerApprovalHandled: (data) =>
    dispatch({ type: types.APPROVAL_HANDLED, payload: data }),

  // triggerSavePassword:            data => dispatch({ type: types.ASYNC_SAVE_PASSWORD, payload: data }),
  triggerSettingsHandled: (data) =>
    dispatch({ type: types.SETTINGS_HANDLED, payload: data }),

  triggerLoadMainStats: (data) =>
    dispatch({ type: types.ASYNC_LOAD_MAINSTATS, payload: data }),
  triggerLoadThreadMainStats: (data) =>
    dispatch({ type: types.ASYNC_LOAD_THREAD_MAINSTATS, payload: data }),
  triggerLoadNursingtimeMainStats: (data) =>
    dispatch({ type: types.ASYNC_LOAD_NURSINGTIME_MAINSTATS, payload: data }),
  triggerLoadPermissionsMainStats: (data) =>
    dispatch({ type: types.ASYNC_LOAD_PERMISSIONS_MAINSTATS, payload: data }),

  triggerLoadDFSettings: (data) =>
    dispatch({ type: types.ASYNC_LOAD_DF_SETTINGS, payload: data }),

  triggerLoadProfilePictures: (data) =>
    dispatch({ type: types.ASYNC_LOAD_PROFILEPICTURES, payload: data }),
  triggerLoadBulletinBoards: (data) =>
    dispatch({ type: types.ASYNC_LOAD_BULLETINBOARD, data }),

  triggerPasswordResetRequest: (data) =>
    dispatch({ type: types.ASYNC_PASSWORD_RESET, payload: data }),
  triggerForgottenPasswordPageReset: (data) =>
    dispatch({ type: types.PASSWORD_RESET_NEUTRAL, payload: data }),
  triggerNewUserPageReset: (data) =>
    dispatch({ type: types.ORDER_USERNAME_RESET, payload: data }),

  triggerOrderUserNameAndPasswordRequest: (data) =>
    dispatch({ type: types.ASYNC_ORDER_USERNAME, payload: data }),

  // Action trigger to request App state reset to its initial value
  triggerAppReset: () => dispatch({ type: types.APP_RESET }),
  //GrowthFolder
  triggerLoadFolders: (data) =>
    dispatch({ type: types.ASYNC_LOAD_FOLDERS, payload: data }),
  triggerLoadImages: (data) =>
    dispatch({ type: types.ASYNC_LOAD_IMAGES, payload: data }),
  triggerLoadImage: (data) =>
    dispatch({ type: types.ASYNC_LOAD_IMAGE, payload: data }),

  //TaskFolder
  triggerLoadTaskFolder: (data) =>
    dispatch({ type: types.ASYNC_LOAD_TASKFOLDER, payload: data }),
  triggerLoadTaskThumbnail: (data) =>
    dispatch({ type: types.ASYNC_LOAD_TASK_THUMBNAIL, payload: data }),
  triggerLoadFullTaskImage: (data) =>
    dispatch({ type: types.ASYNC_LOAD_FULL_TASK_IMAGE, payload: data }),

  //Check desired commune settings
  triggerLoadCommuneSettings: (data) =>
    dispatch({ type: types.ASYNC_LOADCOMMUNESETTINGS, payload: data }),

  //Municipal perm permission questions
  triggerLoadMunicipalPermissionQuestions: (data) =>
    dispatch({ type: types.ASYNC_LOAD_CHILD_PERMPERMISSIONS, payload: data }),
  triggerCleanLoadMunicipalPermissionQuestions: (data) =>
    dispatch({
      type: types.ASYNC_CLEAN_LOAD_CHILD_PERMPERMISSIONS,
      payload: data,
    }),
  triggerPostChildPermPermissions: (data) =>
    dispatch({ type: types.ASYNC_POST_CHILD_PERMPERMISSIONS, payload: data }),
  triggerCleanPostChildPermPermissions: (data) =>
    dispatch({ type: types.CLEAN_POST_CHILD_PERMPERMISSIONS, payload: data }),
  triggerLoadGuardianConsents: (data) =>
    dispatch({ type: types.ASYNC_LOAD_GUARDIAN_CONSENTS, payload: data }),
  triggerCleanLoadGuardianConsents: (data) =>
    dispatch({ type: types.CLEAN_LOAD_GUARDIAN_CONSENTS, payload: data }),
  triggerPostGuardianConsents: (data) =>
    dispatch({ type: types.ASYNC_POST_GUARDIAN_CONSENTS, payload: data }),
  triggerCleanPostGuardianConsents: (data) =>
    dispatch({ type: types.CLEAN_POST_GUARDIAN_CONSENTS, payload: data }),
  //Kyselyt
  triggerLoadPermissionQueryList: (data) =>
    dispatch({ type: types.ASYNC_LOAD_PERMISSION_QUERIES, payload: data }),
  triggerLoadSinglePermissionQuery: (data) =>
    dispatch({ type: types.ASYNC_LOAD_SINGLE_PERMISSION_QUERY, payload: data }),
  triggerPostSinglePermissionQuery: (data) =>
    dispatch({ type: types.ASYNC_POST_SINGLE_PERMISSION_QUERY, payload: data }),

  // Snackbar
  triggerReduceSnackBarQueue: (data) =>
    dispatch({ type: types.REDUCE_SNACKBAR_QUEUE, payload: data }),
  triggerAddToSnackBarQueue: (data) =>
    dispatch({ type: types.ADDTO_SNACKBAR_QUEUE, payload: data }),
  //triggerClosePostNewMessageSnackbar: data => dispatch({ type: types.CLOSE_POST_NEW_MESSAGE_SNACKBAR})
  triggerAddBackupParent: (childId, data) =>
    dispatch({
      type: types.ASYNC_ADD_BACKUP_PARENT,
      payload: { childId: childId, data },
    }),
  triggerUpdateBackupParent: (childId, data) =>
    dispatch({
      type: types.ASYNC_UPDATE_BACKUP_PARENT,
      payload: { childId: childId, data },
    }),
  triggerDeleteBackupParent: (childId, backupParentId) =>
    dispatch({
      type: types.ASYNC_DELETE_BACKUP_PARENT,
      payload: { childId: childId, backupParentId: backupParentId },
    }),

  triggerLoadVacationNotificationStatus: (data) =>
    dispatch({ type: types.ASYNC_LOAD_VACATION_STATUS, payload: data }),

  triggerLoadDiscussionTimes: (data) =>
    dispatch({ type: types.ASYNC_LOAD_DISCUSSION_TIMES, payload: data }),
  triggerLoadAvailableDiscSlots: (data) =>
    dispatch({ type: types.ASYNC_LOAD_AVAILABLE_DISC_SLOTS, payload: data }),
  triggerSaveDiscussionBooking: (data) =>
    dispatch({ type: types.ASYNC_SAVE_DISCUSSION_BOOKING, payload: data }),
  triggerDeleteDiscussionBooking: (data) =>
    dispatch({ type: types.ASYNC_DELETE_DISCUSSION_BOOKING, payload: data }),
  triggerEditDiscussionBooking: (data) =>
    dispatch({ type: types.ASYNC_EDIT_DISCUSSION_BOOKING, payload: data }),
  triggerLoadChildDiscussions: (data) =>
    dispatch({ type: types.ASYNC_LOAD_ONE_CHILD_DISCUSSIONS, payload: data }),
  triggerGetDiscParentsForEditing: (data) =>
    dispatch({
      type: types.ASYNC_GET_PARENTS_FOR_DISCUSSION_EDITING,
      payload: data,
    }),
  triggerLoadDiscussionNotificationStats: (data) =>
    dispatch({
      type: types.ASYNC_GET_DISCUSSIONNOTIFICATION_STATS,
      payload: data,
    }),
  triggerLoadDiscussionTimeSelectionStatus: (data) =>
    dispatch({
      type: types.ASYNC_GET_DISCUSSION_TIMESELECTIONSTATUS,
      payload: data,
    }),
  triggerSaveDiscussionTimeSelectionStatus: (data) =>
    dispatch({
      type: types.ASYNC_SAVE_DISCUSSION_TIMESELECTIONSTATUS,
      payload: data,
    }),
  triggerDeleteDiscussionTimeSelectionStatuses: (data) =>
    dispatch({
      type: types.ASYNC_DELETE_DISCUSSION_TIMESELECTIONSTATUSES,
      payload: data,
    }),

  triggerSetMainNavOpen: (data) =>
    dispatch({ type: types.SET_MAINNAV_OPEN, payload: data }),
  triggerSavePersonSettings: (personId, data) =>
    dispatch({
      type: types.ASYNC_SAVE_PERSON_SETTINGS,
      payload: { personId, data },
    }),
  triggerLoadPersonSettings: (personId) =>
    dispatch({ type: types.ASYNC_LOAD_PERSON_SETTINGS, payload: { personId } }),
  triggerDownloadFile: (data) =>
    dispatch({ type: types.ASYNC_DOWNLOAD_FILE, payload: data }),
});

import React, { Component } from 'react';
import './testi.css';

import ContentBoxMUI from '../../Components/ContentBoxMUI';
import Accordion         from '@mui/material/Accordion';
import AccordionSummary  from '@mui/material/AccordionSummary';
import AccordionDetails  from '@mui/material/AccordionDetails';
import Typography             from "@mui/material/Typography";
import TextField from '@mui/material/TextField';
//import { /*ListGroup,*/ Modal, ModalBody, Input } from "reactstrap";
import IconButton             from '@mui/material/IconButton';
import Divider                from '@mui/material/Divider';
import AccordionActions  from '@mui/material/AccordionActions';
//import Edit                   from '@mui/icons-material/EditRounded';
import Check                  from '@mui/icons-material/Check';
import ExpandMoreIcon         from '@mui/icons-material/ExpandMore';
import Undo                   from '@mui/icons-material/Undo';
import Languages from "../../translations";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { List, ListItem/*, CardHeader*/ } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import ButtonMUI from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CookieNote from '../../Components/CookieNote';
import DFLogo from '../../images/Family-21.png';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';




const stylee = {
  
  media: { width:"50%",
  height:"50%",
  margin: "auto",


  }
  ,
  media2: { 
    height: 0,
    paddingTop: "56.25%",
    marginTop: "30",
    maxHeight: 20 
  }
}
/* const [state, setState] = React.useState({
  checkedA: true,
  checkedB: true,
});

const handleChange = name => event => {
  setState({ ...state, [name]: event.target.checked });
};*/

class Testi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      radiobuttonvalue: false,
      checked:false,
      infoText: "",
      infoTitle: null,
      infoModalOpen: false,
      errorModalOpen: false,
      EditElement: "",
      OpenPanel: "LogingSettings",
      infoedited: false, // change status, when some info field is changed => if retured without saving, read original info
      openBulletinBoard: false,
      Name: "",
      messages: [{id: 0, header: "Teatteri",              body: "Kiertävä teatteriesitys Harri ja Krokotilli esiintyy päiväkodissamme 7.5.", date: "2.4.2019", available: "Voimassa 15.5.2019 asti"},
                 {id: 1, header: "Suojalkapallo",         body: "Käymme katsomassa Vimpelin suojalkapallojoukkueen harjoituksia 15.4., päiväkoti tarjoaa eväät.", date: "1.4.2019", available: "Voimassa 1.6.2019 asti"},
                 {id: 2, header: "Metsäretki",            body: "Siilinpoikaset lähtevät metsäretkelle. Lapsille retkipäivänä mukaan kumpparit ja lämmintä vaatetta!", date: "3.12.2018", available: "Voimassa 3.5.2019 asti"},
                 {id: 3, header: "Ei susia",              body: "Sudet eivät ole hyväksyttäviä päiväkodin pihaan tuotavia lemmikkejä. Älkää tuoko susia enää, kiitos.", date: "4.12.2018", available: "Voimassa 4.12.2028 asti"},
                 {id: 4, header: "Poliisit esittäytyvät", body: "Poliisit Pienmäki ja Suuronen käyvät vierailemassa päiväkodissamme juhannusviikolla. Voitte lukea tarinan lapsillenne aiheesta nettisivuiltamme.", date: "3.12.2018", available: "Voimassa 26.6.2019 asti"}],
      index: 0,
      placements: null
    };
    this.strings = Languages;

  }
  componentDidUpdate() {console.log("checked state ",this.state.checked )}
  openPanel = (panel) => {

    console.log("Testi. openPanel. panel: ", panel);

    this.setState({OpenPanel: panel === this.state.OpenPanel ? "" : panel});
    
  }
  handleChange = (panel) => {
    console.log("ChildInfo. OpenPanel: ", panel);
    this.setState({checked:!this.state.checked});
  }
handleChangeRadio = () => {
  console.log("ChildInfo. OpenPanel: ");
  this.setState({radiobuttonvalue:!this.state.radiobuttonvalue});
}

  render() {
    
    return (
      <div className="Testi">
      <ContentBoxMUI
      hideHeader={true} >
                      <div className="kirjautumispaneeli">
                <Accordion /*defaultExpanded*/ 
                                style={{backgroundColor:"#4377BA"}}
                                expanded={this.state.OpenPanel === "LogingSettings" ? true : false}
                                onChange = {() => this.openPanel("LogingSettings")}> 
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}

                  
                                         aria-controls="panel1a-content"
                                         id="panel1a-header" >
                    <Typography className="heading">{this.strings.settingLoginTitle}</Typography>
                  </AccordionSummary>
                  
                  
                  <AccordionDetails>

                  <FormControlLabel
                      control={
                          <Switch
                          checked={this.state.checked}
                          onChange={()=>this.handleChange('checkedB')}
                          value="checkedB"
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}

                          />        
                      }   label="Kirjautuminen sisään sovellukseen tapahtuu aina vahvalla tunnistautumisella. (pankkitunnistautuminen, mobiilitunnistautuminen)"
                          labelPlacement="start"
                      />
                    </AccordionDetails>
              
                </Accordion>
                </div>

                <div className="salasanapaneeli">
                <Accordion /*defaultExpanded*/ 
                                style={{backgroundColor:"#4377BA"}}
                                expanded={this.state.OpenPanel === "PasswordSettings" ? true : false}
                                onChange = {() => this.openPanel("PasswordSettings")}> 
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                         aria-controls="panel2a-content"
                                         id="panel2a-header" >
                    <Typography className="heading">{this.strings.generalPassword}</Typography>
                  </AccordionSummary>

                  <AccordionDetails>                   
                    <List>
                      <ListItem>
                  <TextField
                        required
                        id="standard-required"
                        label="Uusi salasana"
                        defaultValue="Kirjoita uusi salasana"
                        margin="normal"
                       />
                       </ListItem>
                       <ListItem>
                  <TextField
                        required
                        id="standard-required"
                        label="Salasana uudelleen"
                        defaultValue="Salasana uudelleen"
                        margin="normal"
                       />
                       </ListItem>
                       </List>

                    </AccordionDetails>


                  
                  <Divider />
        
                  {this.state.EditElement !== "LogingSettings" ?
                  <AccordionActions className="painikkeet">
                    <IconButton onClick={()=>this.EditInfo("ChildPrimaryNumber")} size="large"> <Check/> </IconButton>
                  </AccordionActions>
                  :
                  <AccordionActions className="painikkeet">
                    <IconButton onClick={()=>this.EditInfo("ChildPrimaryNumber")} size="large"> <Undo/> </IconButton>
                    <IconButton onClick={()=>this.SaveInfo("ChildPrimaryNumber")} size="large"> <Check/> </IconButton>
                  </AccordionActions>
                  }
                </Accordion>
                </div>


                <div className="muistutuksetpaneeli">
                <Accordion /*defaultExpanded*/ 
                                style={{backgroundColor:"#4377BA"}}
                                expanded={this.state.OpenPanel === "RemindersSettings" ? true : false}
                                onChange = {() => this.openPanel("RemindersSettings")}> 
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                         aria-controls="panel3a-content"
                                         id="panel3a-header" >
                    <Typography className="heading">{this.strings.reminders}</Typography>
                  </AccordionSummary>
                  
                  
                  <AccordionDetails>

                  <FormControlLabel
                      control={
                          <Switch
                          checked={this.state.checked}
                          onChange={()=>this.handleChange('checkedB')}
                          value="checkedB"
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}

                          />        
                      }   label="Sähköpostimuistutukset saapuneista viesteistä ja varaamattomista hoitoajoista"
                          labelPlacement="start"
                      />
                    </AccordionDetails>                  
                </Accordion>
                </div>

                <div className="kielipaneeli">
                <Accordion /*defaultExpanded*/ 
                                style={{backgroundColor:"#4377BA"}}
                                expanded={this.state.OpenPanel === "LanguagesSettings" ? true : false}
                                onChange = {() => this.openPanel("LanguagesSettings")}> 
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                         aria-controls="panel4a-content"
                                         id="panel4a-header" >
                    <Typography className="heading">{this.stringssettingLanguagesTitle}</Typography>
                  </AccordionSummary>
                  
                  
                  <AccordionDetails>

                  <FormControl component="fieldset">
                      <FormLabel component="legend"></FormLabel>
                      <RadioGroup justify-content="center" 
                      aria-label="position" 
                      name="position" 
                      value={this.state.radiobuttonvalue} onChange={this.handleChangeRadio} row>
        
                      <FormControlLabel
                       value="top"
                       control={<Radio color="primary" />}
                        label="FI"
                        labelPlacement="top"
                      />
                      <FormControlLabel
                       value="top"
                       control={<Radio color="primary" />}
                        label="SVE"
                        labelPlacement="top"
                      />
                      <FormControlLabel
                       value="top"
                       control={<Radio color="primary" />}
                        label= "EN"
                        labelPlacement="top"
                      />
                    </RadioGroup>
                  </FormControl>
                  </AccordionDetails>
                </Accordion>
                </div>

                <div className="tyhjennatiedottpaneeli">
                <Accordion /*defaultExpanded*/ 
                                style={{backgroundColor:"#4377BA"}}
                                expanded={this.state.OpenPanel === "EmptyinfoSettings" ? true : false}
                                onChange = {() => this.openPanel("EmptyinfoSettings")}> 
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                         aria-controls="panel4a-content"
                                         id="panel4a-header" >
                    <Typography className="heading">{this.strings.settingEmptyInformation}</Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <List>
                      <ListItem>
                  <p>Voit tyhjentää sovelluksen tiedot täydellisesti tästä (kuntatiedot, kirjautumistiedot).</p>
                  </ListItem>
                  <ListItem>
                         
                  <ButtonMUI variant="contained" 
                           color="primary" 
                           id="test_Login_loginbutton" 
                           onClick={this.emptyInformation} >{this.strings.settingEmptyInformation}</ButtonMUI> 

                    </ListItem>
                    </List>
                  </AccordionDetails>
                </Accordion>
                </div>

          <div className="Cookieilmoitus">
            <CookieNote></CookieNote>
            
          </div>      
      
      <div className="LoginValinnat">
        <Card className="Tulevaisuuden varhaiskasvatus" style={{ backgroundColor:"#4377BA"}}>        
        <CardMedia className="DaisyFamilyLogo"
                        component="img"
                         style={stylee.media}
                         image={DFLogo}
                         title="Logo"/>
          <CardContent>
            <Grid>               
                <Typography style={{textAlign:"center"}}> DaisyFamily tuo tulevaisuuden varhaiskasvatuksen kotiisi! </Typography>
             </Grid>
             </CardContent>
  
            <Grid className="Valintapainikkeet"
                  item xs={12} sm={12} md={12} lg={12} xl={12}> 
              <CardContent className="ValitseKirjautuminen" > 
              <Typography style={{textAlign:"center", color:"white", marginBottom:"5%"}}> Valitse kirjautumistapa </Typography>
                <Button style={{textAlign:"center", color:"white", backgroundColor:"#000001", width:"100%"}}> Käyttäjätunnus ja salasana </Button>
                <Button style={{textAlign:"center", color:"white", backgroundColor:"#000001", width:"100%"}}> Tunnistautuminen </Button>
   
              </CardContent>
            </Grid>


        </Card>
      </div>

      <div className="LoginUsernamePassword">
        <Card className="Tulevaisuuden varhaiskasvatus" style={{ backgroundColor:"#4377BA"}}>        
        <CardMedia className="DaisyFamilyLogo"
                        component="img"
                         style={stylee.media}
                         image={DFLogo}
                         title="Logo"/>
          <CardContent>
            <Grid>               
                <Typography style={{textAlign:"center"}}> DaisyFamily tuo tulevaisuuden varhaiskasvatuksen kotiisi! </Typography>
             </Grid>
             </CardContent>
  
            <Grid className="Valintapainikkeet"
                  item xs={12} sm={12} md={12} lg={12} xl={12}> 
              <CardContent className="ValitseKirjautuminen" > 
              <Typography style={{textAlign:"center", color:"white", marginBottom:"5%"}}> Ole hyvä ja kirjaudu sisään </Typography>
                <Button style={{textAlign:"center", color:"white", backgroundColor:"#000001", width:"100%"}}> Käyttäjätunnus ja salasana </Button>
                <Button style={{textAlign:"center", color:"white", backgroundColor:"#000001", width:"100%"}}> Tunnistautuminen </Button>
   
              </CardContent>
            </Grid>


        </Card>
      </div>
      
      </ContentBoxMUI>
      </div>
    );
  }
}

export default Testi;

import React                from  'react';
import GrowthFolderFailed   from  "./Components/GrowthFolderFailed";
import GrowthFolderLoading  from  "./Components/GrowthFolderLoading";
import GrowthFolderSuccess  from  './Components/GrowthFolderSuccess';
import Languages            from  "../../translations";



const GrowthFolderViewManager = ({ growthFolder, growthFolderFunctions, errorCode }) => {

  function returnCorrectGrowthFolderView (growthFolderState) {

    const growthFolderStateOptions = {
      
      "LOADING_FOLDERS": function() {
        return (
          <GrowthFolderLoading />
        );
      },

      "LOAD_FOLDERS_SUCCESS": function() {
        return (
          <GrowthFolderSuccess
            growthFolder = {growthFolder}
            growthFolderFunctions = {growthFolderFunctions}  
          />
        );
      },

      "LOAD_FOLDERS_FAILED": function() {
        return (
          <GrowthFolderFailed
            tryReloadFolders = {growthFolderFunctions["tryReloadFolders"]}
            errorCode = {errorCode}
          />
        );
      },
      
      "default": function() {
        return (
          <div>{Languages.errorUnknown + "(777)."}</div>
        );
      }
    };

    return (growthFolderStateOptions[growthFolderState] || growthFolderStateOptions["default"])();
  }

  return(
    <React.Fragment>
      {returnCorrectGrowthFolderView(growthFolder.state)}
    </React.Fragment>
  );

}

export default GrowthFolderViewManager;
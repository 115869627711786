import React, {useState, useEffect} from "react";
import _cloneDeep             from "lodash/cloneDeep";
import Languages from "../../../translations";
import ChildProfilePicture from "../../../Components/ChildProfilePicture";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';

const PermQueryLockedStatus = ({ data }) => {

  
  const [permissions, setPermissions] = useState(_cloneDeep(data.SentTo));

    return(
      <>
        <List>
        {permissions.map((child) => {
          return(
            <ListItem key={child.PersonId} 
              sx={
                child.PermissionGranted === true && {width: "100%", backgroundColor: "#DCEEDF", color: "#006633", borderBottom: "solid 1px"} || child.PermissionGranted === false && {width: "100%", backgroundColor: "#F9E0E5", color: "#B30036", borderBottom: "solid 1px"} || child.PermissionGranted === 'null' && {width: "100%", backgroundColor: "#E5E5E5", color:"#666666", borderBottom: "solid 1px"}} 
              secondaryAction={child.PermissionGranted === true && <ThumbUpIcon sx={{color: "#006633"}} /> || child.PermissionGranted === false && <ThumbDownIcon sx={{color: "#B30036"}}/> || child.PermissionGranted === 'null' && <ThumbsUpDownIcon sx={{color: "#666666"}}/>}
              >
              <ListItemAvatar>
                  <ChildProfilePicture childId={child.PersonId} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={<b style={{color: "black"}}>{child.PersonName}</b>}
                    secondary={child.PermissionGranted === true && <b>{Languages.allowed}</b> || child.PermissionGranted === false && <b>{Languages.notAllowed}</b> || child.PermissionGranted === 'null' && <b>{Languages.noAnswer}</b>}
                    />
            </ListItem>
)})}
</List>
      </>
    )
}

export default PermQueryLockedStatus;
import React, { Component } from "react";
import "./sendnewmessage.css";
import Languages            from "../../../translations";
import MessagingPopUpModal  from "../../Modals/MessagingPopUpModal";
import MessageBodyField     from "./MessageBodyField";
import MsgSendButton        from "./MsgSendButton";
import _cloneDeep           from "lodash/cloneDeep";
import {containsMoreThanEmptySpaces} from "../../../utils";
import { isAnyPropUndefinedOrNull } from "../../../utils";


class SendNewMessage extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      undefinedOrNullProps: isAnyPropUndefinedOrNull(props),
      content: this.props.tempSavedMessageContent, 
      messagetobesent: "",     
      numberOfCharacters: (typeof this.props.tempSavedMessageContent !== "undefined" &&
        this.props.tempSavedMessageContent !== null ? this.props.tempSavedMessageContent.length : 0
        ),
      
      titleIsMissingNotification: false,
      noStaffSelectedNotification: false,
      allowFailedNotification: true,
    };
  }

componentDidUpdate() {

  if (this.state.titleIsMissingNotification === true) {
    if (this.props.sendTitle > 0) {
      this.setState({titleIsMissingNotification: false});
    }
  }

  if (this.props.snackBarState === "POST_NEW_MESSAGE_SUCCESS") {

    this.props.closeNewMessageAndOpenInbox();
  }

}

closeIncludeEmployeesNotification = () => {
  this.setState({noStaffSelectedNotification: false});
}

handleMsgBodyChange = (event) => {
  if (this.state.numberOfCharacters < 5001 ) {
    this.setState({
      content: event.target.value,
      numberOfCharacters: event.target.value.length
    });
  }
  else {
    this.setState({
      content: event.target.value,
      numberOfCharacters: event.target.value.length,
    })
  }

  if (this.props.sendTitle.length > 0){
    this.setState({titleIsMissingNotification: false});
  } 

  //saves message content in higher component in case the view is changed to something else
  this.props.saveMessageContentToHigherComponentState(event.target.value);

}

sendMessagePreCheck = () => {
  
  // Does the message include staff members?

  let includesStaff = false;

  for (let i = 0; i < this.props.sendList1withObjects.length; i++) {
    if (this.props.sendList1withObjects[i].Type === "person" || 
        this.props.sendList1withObjects[i].Type === "group" ||
        this.props.sendList1withObjects[i].Type === "centre") {
          includesStaff = true;
    }
  }


  if (includesStaff) {

    if (this.props.sendTitle.length === 0) {
      this.setState({titleIsMissingNotification: true});
    }

    else { 
      const containsMoreThanJustEmptySpaces = containsMoreThanEmptySpaces(this.state.content);
      
      const bodyIsCorrectLength =  (this.state.numberOfCharacters < 5001 && this.state.numberOfCharacters > 0);

      const titleIsLessThanMaxLength = (this.props.sendTitle.length <= this.props.maxLengthForTitle);

      if ( bodyIsCorrectLength && containsMoreThanJustEmptySpaces && titleIsLessThanMaxLength) {   
        this.sendMessageToServer();
      }

      else { 
        //do nothing
      } 
    }
  }

  else {
    this.setState({
      noStaffSelectedNotification: true
    })
  }
}


sendMessageToServer = () => {
  
  // Will only send .Id and .Type, not .Name as it is unnecessary
  let _sendList1 = _cloneDeep(this.props.sendList1withObjects);
  let sendList1justIDsAndTypes = [];
  _sendList1.forEach(function(item) {sendList1justIDsAndTypes.push({Id: item.Id, Type: item.Type})});

  let datatobesent = [
    {
    ListRecipients: sendList1justIDsAndTypes,       
    Title: this.props.sendTitle,
    Content: this.state.content,
    }
  ];
  
  this.props.sendNewMessageToServer(datatobesent);

  this.setState({
    allowFailedNotification: true
  });
}
  
closeFailedNotification = () => {
  this.setState({
    allowFailedNotification: false
  })
}

render() {

    if (this.state.undefinedOrNullProps) { return (<div data-testid="SendNewMessage-undefined-or-null"></div>); }

    else {
      return (
          
        <React.Fragment>

          {/* Popup informs user that message cannot be sent without an employee or group selected */}
          <MessagingPopUpModal 
            isOpen={this.state.noStaffSelectedNotification}
            header={Languages.msgIncludeStaffTitle}
            infoMsgText={Languages.msgIncludeStaffBody}
            button={this.closeIncludeEmployeesNotification}
            buttonText={"OK"}
          />

          <MessagingPopUpModal
            isOpen={this.props.postNewMessageState === "WAITING_POST_NEW_MESSAGE"}
            header={Languages.msgSending}
            infoMsgText={Languages.msgSentWaitingForServer}
          />

          <MessagingPopUpModal
            isOpen={this.props.postNewMessageState === "POST_NEW_MESSAGE_FAILED" && this.state.allowFailedNotification}
            header={Languages.errorSendingFailed}
            infoMsgText={Languages.errorMessageSending}
            button={() => this.closeFailedNotification()}
            buttonText={"OK"}
          />

          <MessageBodyField 
            handleMsgBodyChange={(message) => this.handleMsgBodyChange(message)}
            tempSavedMessageContent={this.props.tempSavedMessageContent}  
            numberOfCharacters={this.state.numberOfCharacters}
            sendTitleLength={this.props.sendTitle.length}
            titleMissing={this.state.titleIsMissingNotification}
          />
          
          <MsgSendButton 
            numberOfCharacters={this.state.numberOfCharacters}
            sendTitleLength={this.props.sendTitle.length}
            postNewMessageState={this.props.postNewMessageState}
            sendMessagePreCheck={this.sendMessagePreCheck}
          />

        </React.Fragment>
      );    
    } 
  }
}

export default SendNewMessage;

import React              from "react";
import AppBar             from "@mui/material/AppBar";
import ToolBar            from "@mui/material/Toolbar";
import Typography         from "@mui/material/Typography";
import Grid               from "@mui/material/Grid";
import CancelIcon         from "@mui/icons-material/Cancel";
import IconButton         from '@mui/material/IconButton';
import withStyles from '@mui/styles/withStyles';

import Languages          from "../../../../translations";


const styles = ( theme ) => ({
  appcontainer: {
    textAlign:"center",
    margin:"auto",
    position: "fixed",
    color: "primary",
    [theme.breakpoints.up('sm')]: {
      paddingRight:"10%",
      paddingLeft:"15%", 
      },
      [theme.breakpoints.up('md')]: {
        paddingRight:"10%",
        paddingLeft:"23%", 
        },
        [theme.breakpoints.up('lg')]: {
          paddingRight:"10%",
          paddingLeft:"30%", 
          },
  },
  content: {
    maxWidth: "800px",
    // [theme.breakpoints.up('sm')]: {
    //   marginRight:"15%",
    //   marginLeft:"15%", 
    //   },
      // [theme.breakpoints.down('md')]: {
      //   marginRight:"15%",
      //   marginLeft:"15%", 
      // },


  },
})

const NursingTimePopUpAppBar = ({close, classes}) => {

  const headerTitle = Languages.bookingCareHoursTitle;


  return (
    <AppBar className={classes.appcontainer}>
        <ToolBar className={classes.content}>

          <div >
           
                <Grid container spacing={2}>
                  
                  <Grid item xs={1}>
                  <IconButton style={{display: "inline-block"}} 
                      size="medium" 
                      aria-label="Back to previous" 
                      id="test_NewBooking_Return" 
                      onClick={() => close()}>
                    <Typography color="textPrimary" ><CancelIcon /></Typography>
                  </IconButton>
                  </Grid>
                  
                  <Grid item xs={9}>
                  {typeof headerTitle !== "undefined" && (headerTitle.length < 17) &&
                          
                          <div style={{ display: "inline-block", fontSize: "0.8rem", wordWrap: "break-word" }}>
                            <Typography variant="h5" style={{marginTop: "8px"}}>&nbsp; &nbsp; {headerTitle}</Typography>
                          </div>}
                  { typeof headerTitle !== "undefined" && (headerTitle.length > 17) && (headerTitle.length < 30) &&
                      
                          <div style={{display: "block", marginLeft:"12px", paddingTop:"12px", wordWrap: "break-word"}}>
                            <Typography noWrap={false}>{headerTitle.length > 50 ? headerTitle.substring(0, 51) + "..." : headerTitle} </Typography>
                          </div>}
                  { typeof headerTitle !== "undefined" && (headerTitle.length > 30) &&
                          <div style={{display: "inline-block", marginLeft:"14px", wordWrap: "break-word"}}>
                            <Typography noWrap={false}>{headerTitle.length > 50 ? headerTitle.substring(0, 51) + "..." : headerTitle} </Typography>
                          </div>}   
                  </Grid>
                  
                  <Grid item xs={2}>
                    <Grid container justifyContent="center" alignItems="center" spacing={2}>
                      <Grid item xs={6}></Grid>
                    </Grid>
                  </Grid>    
                </Grid>
          </div>

        </ToolBar>
      </AppBar>
  );
}

export default withStyles(styles)(NursingTimePopUpAppBar);
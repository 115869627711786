import React  from "react";
import "../messaging.css";
import Languages              from "../../../translations";
//import FixedBottomBar         from "../../../Components/FixedBottomBar";
import Card                   from "@mui/material/Card";
import CardContent            from "@mui/material/CardContent";
import MessagingBottomBar     from "./MessagingBottomBar";

const MessagingError = ({refresh, showNew, errorCode}) => {

  return(
    <React.Fragment>
      <Card style={{backgroundColor:"#4377BA"}}>             
        <CardContent data-testid={"messaging-error-awo9heke"}>
        <p>{Languages.msgCouldNotBeLoaded}</p>
        <p>{
            typeof errorCode === "undefined" ? Languages.errorUnknown : 
              errorCode === 400 ? Languages.errorCode + " 400 " :
              errorCode === 500 ? Languages.errorCode + " 500 " + Languages.errorInDB :
              errorCode === 502 ? " 502 " + Languages.errorNetwork : 
              errorCode === 777 ? Languages.errorUnknown : Languages.errorUnknown
        }</p>
        </CardContent>
      </Card>
        <MessagingBottomBar loading={false} refresh={refresh} showNew={showNew}/>
    </React.Fragment>
)
}

export default MessagingError;
import React from 'react';
import Languages from '../../../../translations';
import FlagFin from '../../../../images/flagsFIN.png';
import FlagEng from '../../../../images/flagsEN.png';
import FlagSv from '../../../../images/flagsSV.png';

import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import LanguageIcon from '@mui/icons-material/Language';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';

const styles = {
  color: "#4377BA",
  fontSize: "14pt",
  fontWeight: "bold",
}

export const SettingOptionPanel = ({ headerText, children, actions, showActions, expanded, onExpandClick, idForButton, ariaControls, settingsIcon }) => {

  function returnCorrectSettingsIcon(settingsIcon) {

    const settingsIconOptions = {

      "password": function () {
        return (<LockOpenIcon />);
      },

      "language": function () {
        return (<LanguageIcon />);
      },

      "notification": () => (<CircleNotificationsIcon/>),

      "default": function () {
        return null
      }
    };

    return (settingsIconOptions[settingsIcon] || settingsIconOptions["default"])();
  }

  return (
    <div>
      <Accordion /*defaultExpanded*/
        style={{ border: "5px", borderLeft: "12px", borderStyle: "solid", borderColor: "#4377BA", backgroundColor: "#ffffff", color: "#181818", marginTop: "10px" }}
        expanded={expanded}
        onChange={onExpandClick}>
        <AccordionSummary expandIcon={<ExpandMoreIcon id={idForButton} />}
          aria-controls={ariaControls}
          id="panel1a-header" >
          <Typography className="heading" style={styles}>{returnCorrectSettingsIcon(settingsIcon)} <b style={{ marginLeft: "10px", display: "inline" }}>{headerText}</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          {children}
        </AccordionDetails>
        {/*(actions && showActions) ?? <Divider />*/}
        {showActions && <React.Fragment>{actions}</React.Fragment>}
      </Accordion>
    </div>
  )
}




// params: languageSelection: string ("fi","sv","en"), onLanguageChange: callback(event) called when selection changes
export const LanguagesOption = ({ languageSelection, onLanguageChange, expanded, onExpandClick }) => {
  return (
    <SettingOptionPanel
      headerText={Languages.settingLanguagesTitle}
      expanded={expanded}
      onExpandClick={onExpandClick}
      idForButton={"test_Settings_Languages"}
      ariaControls={"panel2a-content"}
      settingsIcon="language"
    >
      <FormControl component="fieldset">
        <FormLabel component="legend"></FormLabel>
        <RadioGroup justify-content="center"
          aria-label="position"
          name="position"
          value={languageSelection} onChange={onLanguageChange} row>
          <FormControlLabel value="fi"
            control={<Radio color="secondary" />}
            id="test_Settings_fi_Rdo"
            label={<img className="flag" id="test_AppSettings_FinnishImg" src={FlagFin} alt="Fin" />}
            labelPlacement="top" />
          <FormControlLabel value="sv"
            control={<Radio color="secondary" />}
            id="test_Settings_sv_Rdo"
            label={<img className="flag" id="test_AppSettings_SwedishImg" src={FlagSv} alt="Sv" />}
            labelPlacement="top" />
          <FormControlLabel value="en"
            control={<Radio color="secondary" />}
            id="test_Settings_en_Rdo"
            label={<img className="flag" id="test_AppSettings_EnglishImg" src={FlagEng} alt="Eng" />}
            labelPlacement="top" />
        </RadioGroup>
      </FormControl>
    </SettingOptionPanel>
  );
}
import React, {useState} from "react";
import GuideAccordion from "../GuideTools/GuideAccordion";
import KeywordButton from "../GuideTools/KeywordButton";
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import Divider from '@mui/material/Divider'
import Languages from "../../../translations";


const DiscussionGuideContent = () => {
  const [expanded, setExpanded] = useState('')
  
  
  const handlePanelExpand = (panelId) => (event, expanded) => {
    setExpanded(expanded ? panelId : false)
    //window.scrollTo({top: 100, behavior: 'smooth'});
     
    //isExpanded.scrollIntoView()
    setTimeout(window.scrollTo(0, panelId.offsetTop-100), 600) //moves the newly opened panel up offeset by 
 
  };

  const expandAcco = (panelId) => () =>{
      setExpanded(panelId)
  }


    //Below are examples of how to add content to the guide. (Keywords, Titles and also the textcomponents from .AccordionText files.)
    return(
      <>
        <div className="guide-modal-keywords-container">
          <h6 className="guide-modal-keyword-header"><VpnKeyIcon color="primary"/>  {Languages.keywords}: </h6>
          <KeywordButton handleClick={expandAcco("id-guide-discussion-test")} buttonText={Languages.discussionTestKeyword} panelId="id-guide-discussion-test" buttonVariant="outlined"/>      
          <Divider variant="middle"/>
          <br />
        </div>
        <div className="booking-guide-content-conteiner">
          <GuideAccordion accordionTitle={Languages.discussionTestTitle} accordionTitleDescription={Languages.discussionTestList}  panelId="id-guide-discussion-test" handlePanelExpand={handlePanelExpand} isExpanded={expanded} accordionDetails="DISCUSSION_ACCORDION_TEXT"/>            
        </div>
      </>
    )
}

export default DiscussionGuideContent
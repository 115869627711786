import React, { useState, useEffect } from "react";
//import {useStateValue} from "../../State";
import "./vasu.css";

import Languages      from "../../translations";

import TextField      from '@mui/material/TextField';
import IconButton     from '@mui/material/IconButton';
import InputAdornment from "@mui/material/InputAdornment";
import { Typography }    from "@mui/material";
import Edit               from '@mui/icons-material/EditRounded';
import Check              from '@mui/icons-material/Check';
import Undo               from '@mui/icons-material/Undo';
import Divider        from '@mui/material/Divider';
import Box              from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import {containsMoreThanEmptySpaces}  from "../../utils";


const CommentsEditing = (props) => {

  //const { state/*, actions*/ } = useStateValue();

  const { Type, PrevType, BgColor, Data, callback, Title1, callbackEdit } = props;
  const [Comments,    setComments]    = useState(Data.Text === null ? "" : Data.Text );
  const [Editing,     setEditing]     = useState(false);
  const [ShowSaveBtn, setShowSaveBtn] = useState(false);
  
  useEffect( () => {
    //console.log("CommentsEditing. useEffect. Comments: ", Comments);
  },[Comments]);

  const ChangeNotes = (event) => {
    const newTextcontainsMoreThanJustEmptySpaces = containsMoreThanEmptySpaces(event.target.value);

    /*console.log("CommentsEditing. ChangeNotes:"+
                "\n event.target.name: ", event.target.name,
                "\n Alkuperäinen teksti: ", Data.Text,
                "\n Uusi teksti: ", event.target.value,
                "\n Uusi pelkkää tyhjää: ", newTextcontainsMoreThanJustEmptySpaces ? false : true);*/

    setShowSaveBtn(newTextcontainsMoreThanJustEmptySpaces ? true :
                   Data.Text !== null && Data.Text !== "" ? true : false);
    setComments(event.target.value);
  };

  const EditInfo = (mode) => {
    /*console.log("CommentsEditing. EditInfo:"+
                "\n mode: ", mode,
                "\nPrevType: ", PrevType,
                "\n Comments: ", Comments);/*,
                "\n Data: ", Data);*/
 
    //setEditing(mode === "Edit" ? true : false);
    if (mode=== "Edit")
    {    
      if (PrevType === null || Type === PrevType)
      {    
        if (Editing)
        {
          setEditing(false);
          callbackEdit("EDIT CANCELED", Type);
        }
        else
        {
          setEditing(true);
          callbackEdit("EDITED", Type);
        }
      }
      else if (PrevType !== null && Type !== PrevType)
      {
        callbackEdit("EDITED", Type);
      }
    }
    else if (mode === "Cancel")
    {
      {Data.Text === null ?
        setComments("")
      :
        setComments(Data.Text)
      };
      
      setShowSaveBtn(false);
      setEditing(false);
      callbackEdit("EDIT CANCELED", Type);
    }  
    else //(mode === "Save")
    {
      setEditing(false);
      callback(Type, mode , Comments);
    }
  };
  

  return (
    <div className="CommentsEditing">
      {Title1 === null ? null :
        <Divider textAlign="left">
          <Chip label={Title1 === null ? null : <h6 >{Title1}</h6> } variant="outlined"/>
        </Divider>
      }
      <div>
        <Box className="col-12" sx={{ backgroundColor: BgColor, borderRadius: 2, boxShadow: 2, whiteSpace: "pre-line", overflowWrap: 'break-word', mt: 2, pt: 2, pb: 2}}>
          <Typography color="textSecondary" sx={{textAlign:"left", fontWeight: 'bold', fontSize: 14, color: "#424242", ml: 1}}>{Languages.observations}</Typography>
          <TextField //error = {numberFound === undefined ? false : true}
                    name={"Notes"}
                    id={"Notes"}
                    sx = {{backgroundColor: "white", borderRadius: 2, mt: 1, mr: 45, "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                    },}}
                    InputLabelProps={{shrink: false}}
                    label={null}
                    multiline
                    rows = "5"
                    minRows = "3"
                    maxRows = "7"
                    disabled={Editing ? false : true}
                    fullWidth
                    onKeyDown={(e) => {
                      if(e.keyCode === 13 && Comments.split(/\r\n|\r|\n/).length >= 15) {
                        e.preventDefault();
                      }
                    }}
                    inputProps={{maxLength: 2400, style: { color: 'black',  maxWidth: "85%"}}}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          {!Editing ? 
                            <IconButton
                            id="test_VasuEditBtn"
                            className="Vasupainikkeet"         
                            sx={{ position: "absolute", top: 5, right: 5}}  
                            onClick={()=>EditInfo("Edit")}
                            size="medium"> <Edit/> </IconButton>

                          : 
                            <IconButton
                            id="test_Vasu_UndoBtn"
                            className="Vasupainikkeet"
                            sx={{ position: "absolute", top: 5, right: 5 }}
                            onClick={()=>EditInfo("Cancel")}
                            size="medium"> <Undo/> </IconButton>
                            
                          }
                          {ShowSaveBtn ?
                          <IconButton
                            id="test_Vasu_SaveBtn"
                            className="Vasupainikkeet"
                            sx={{ position: "absolute", top: 5, right: 45 }}
                            onClick={()=>EditInfo("Save")}
                            size="medium"> <Check/> </IconButton>
                          : null }
                          <Typography color="textSecondary" sx={{textAlign:"left", fontWeight: 'normal', fontSize: 14, color: "#424242", position: "absolute", bottom: 5, right: 10}}>{Comments.length + "/2400" }</Typography>
                        </InputAdornment>
                      )
                    }}
                    type="text" 
                    //placeholder={"Kirjoita havaintoja."}
                    //value={/*VasuData.Data.Table1 .. jotain */}
                    value={Comments}
                    //helperText= {"Helpperi teksti. Tarvitaanko?"}
                    onChange={ChangeNotes}
                    /*autoFocus={index === 0 ? true : false}*//>

      </Box>      
      </div>
    </div>
  );
}

export default CommentsEditing;

import { types } from './actionTypes';

const LeopsReducer = (state, action) => {
  const payload = action.data
  
  /*console.log('LEOPS REDUCER.'+
              "\n action: ", action +
              "\n action.type ", action.type+
              "\n state: ",state);*/

  switch (action.type) {
    case types.LEOPS_HANDLED:
      return {
          ...state,
          state: types.LEOPS_HANDLED
        }
    case types.LOADING_LEOPS:
      return {
          ...state,
          state: types.LOADING_LEOPS
        }
    case types.SAVING_LEOPS:
      return {
        ...state,
          state: types.SAVING_LEOPS,
      }
    case types.SAVING_STUDYFORM:
      return {
        ...state,
          state: types.SAVING_STUDYFORM,
      }
    case types.STUDYFORM_SAVED_OK:
      //console.log('LEOPS REDUCERE. LEOPS_LOAD_OK payload: ', payload);
      return {
        ...state,
        Code: 200,
        state: types.STUDYFORM_SAVED_OK,
        Table3: {
          ...state.Table3,
          StudyModel: {
            ...state.Table3.StudyModel,
            HearedParent1: payload.Parent1,
            HearedParent2: payload.Parent2,
            Locked: payload.Locked,
            Saved:  payload.Saved
          }
        }
      }
    case types.STUDYFORM_SAVED_FAILED:
      return {
        ...state,
        Code: payload.Code,
        Message: payload.Message,
        state: types.STUDYFORM_SAVED_FAILED
      }
    case types.LEOPS_LOAD_OK:
      //console.log('LEOPS_LOAD_OK payload: ', payload);
      return {
        ...state,
        Code: 200,
        state: types.LEOPS_LOAD_OK,
        GeneralPart: payload.GeneralPart,
        Table1: payload.Table1,
        Table2: payload.Table2,
        Table3: payload.Table3,
        Table4: payload.Table4,
        Table5: payload.Table5
        /*Data: { ...state.Data,
                GeneralPart: payload.GeneralPart,
                Table1: payload.Table1,
                Table2: payload.Table2,
                Table3: payload.Table3}*/
      }
    case types.LEOPS_LOAD_FAILED:
      console.log('LEOPS_LOAD_FAILED payload: ', payload);
      return {
        ...state,
        Code: payload.Code,
        Message: payload.Message,
        state: types.LEOPS_LOAD_FAILED
      }
    case types.LEOPS_SAVED_FAILED:
      console.log('LEOPS_SAVED_FAILED payload: ', payload);
      return {
        ...state,
        Code: payload.Code,
        Message: payload.Message,
        state: types.LEOPS_SAVED_FAILED
     }
    case types.LEOPS_SAVED_OK:
      //console.log('LEOPS_SAVED_OK payload: ', payload);
      return {
        ...state,
        Code: 200,
        state: types.LEOPS_SAVED_OK/*,
        Data: { ...state.Data,
                Table1: state.Data.Table1}*/
      }
    default: return state;
  }
}

export default LeopsReducer;
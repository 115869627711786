import React from "react";
import BackToUpButton from "../GuideTools/BackToUpButton";
import AddAlarmIcon from '@mui/icons-material/AddAlarm'
import Languages from "../../../translations";



const DefaultTimeAccordionText = () => {
    return(
      <div>
        <br />
        <p className="guide-accordion-details-texts">{Languages.addBookingAfterTable1} <AddAlarmIcon /> {Languages.addBookingAfterTable2} <b>{Languages.addBookingAfterTable3}</b></p>
        <br />
        <br />
        <BackToUpButton />
      </div>
    )
}

export default DefaultTimeAccordionText
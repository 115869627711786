import sessionManager from "../Auth/SessionManager";
import { ApiBase } from "../internal";


/* TODO: (backend tukee jo tätä)
  [{"PersonID":"c2bb0ea1-c5fa-4101-ba55-cb65bed1b726", "Data":"messagenursingtime"}]
  getmainstatseihin tuo data uutena, jos sitä ei aseteta niin antaa kaikki kuten ennenkin
  jos haluaa tarkemmin määrittää mitä haluaa niin kun string sisältää sanan "message", tulee viestien lukumäärä, 
  "thread" tulee thredien lukumäärä, "nursingtimes" tulee hoitoaikavarausten status
  eli jos haluaa kaikki niin data muotoon :"messagethreadnursingtime"
  järjestyksellä ei ole merkitystä, ei myöskään case sensitive 
  */

export class MainStatsApi extends ApiBase {
  constructor() {
    super();
  }

  async loadMainStats(requestedData) {
    //console.log("MAINSTATS API, loadMainStats");
    const data = { 
      PersonID: sessionManager.getPersonId(),
      Data: requestedData
    };

    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
      }
    };

    let responseData;
    try {
      const response = await this.httpClient.post("/persons/getmainstats2", data, axiosConfig);

      responseData = {
        status: 200,
        data: response.data,
      };

      return responseData;
    }
    catch (error) {
      //console.log("MAINSTATS API. loadMainStats ERROR: ", error);
      // 502 Bad Gateway

      responseData = {
        status: error.response === undefined || error.response.status === undefined ? 502 : error.response.status,

      };
      return responseData;
    }

  }

}

import React                from "react";
import Languages            from "../../../translations";
import ListItemContent from "./ListItemContent"
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';


const AttachmentListMSG = ({ numberOfAttachments, attachmentList, showFullImage }) => {

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  console.log(attachmentList)
  return(
    <>
    <hr />
    <div style={{display: "-webkit-inline-box", width: "100%"}}><AttachEmailIcon /><p sx={{marginLeft: "5px", fontSize: 35}}>  {Languages.attachments}: {numberOfAttachments}</p></div>
    <List dense sx={{/*backgroundColor: "white"*/ }}>
    <Divider variant="inset" component="li" />
      {attachmentList.map(attachment => 
        <ListItem key={attachment.Id} style={{color: "white", width: "100%", height: "100%", display: "flex"}}>
          <ListItemContent attachmentData={attachment} showFullImage={showFullImage} />
        </ListItem>
      )}
      </List>
    </>
  )
}

export default AttachmentListMSG;
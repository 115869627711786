import React from "react";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationActionDefault from "@mui/material/BottomNavigationAction";
import { withRouter } from "react-router-dom";
import ChatBubble from "@mui/icons-material/ChatBubbleRounded";
import EventNote from "@mui/icons-material/EventNoteRounded";
import Badge from "@mui/material/Badge";
import { useStateValue } from "../../State";
import LiveHelp from "@mui/icons-material/LiveHelp";
import Paper from "@mui/material/Paper";
import Languages from "../../translations";
import Fab from "@mui/material/Fab";
import { styled } from "@mui/material/styles";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
const BottomNavBar = ({ notReadThreads, history }) => {
  const { state } = useStateValue();
  const { listChildNursingTimeStatus } = state.mainstats;
  const { mainstats } = state;
  const { settings } = state;
  const styles = {};

  const GetNewCalendarEnabledSetting = () => {
    let newCalendarEnabled = settings.communeSettings.find(
      (setting) => setting.Key === "DaisyFamily/NewCaretimeCalendarEnabled"
    );
    if (
      newCalendarEnabled != null &&
      typeof newCalendarEnabled != undefined &&
      newCalendarEnabled.ValueBool === true
    ) {
      return true;
    }
    return false;
  };

  const GetUseOptimizedMessagingSetting = () => {
    let newCalendarEnabled = settings.communeSettings.find(
      (setting) => setting.Key === "Daisyfamily/UseOptimizedMessaging"
    );
    if (
      newCalendarEnabled != null &&
      typeof newCalendarEnabled != undefined &&
      newCalendarEnabled.ValueBool === true
    ) {
      return true;
    }
    return false;
  };

  const BottomNavigationAction = styled(BottomNavigationActionDefault)(
    ({ theme }) => ({
      "& .MuiBottomNavigationAction-label": {
        color: "ghostwhite",
      },
    })
  );

  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 1100 }}
    >
      <BottomNavigation showLabels>
        <BottomNavigationAction
          label={Languages.permissions}
          icon={
            <Badge
              sx={{
                "& .MuiBadge-badge": { fontSize: 9, height: 15, minWidth: 15 },
              }}
              badgeContent={
                !mainstats.TotalUnansweredPermissions
                  ? null
                  : mainstats.TotalUnansweredPermissions
              }
              color="error"
            >
              <LiveHelp sx={{ color: "ghostwhite" }} />
            </Badge>
          }
          onClick={() => history.push("/permissions")}
        />
        {GetNewCalendarEnabledSetting() ? (
          <BottomNavigationAction
            label={Languages.bookingCareHoursTitle}
            icon={
              <Badge
                variant="dot"
                color="error"
                invisible={
                  listChildNursingTimeStatus !== undefined &&
                  listChildNursingTimeStatus.length > 0
                    ? false
                    : true
                }
              >
                <CalendarTodayIcon sx={{ color: "#f0f0f0" }} />
              </Badge>
            }
            onClick={() => history.push("/caretimecalendar")}
          />
        ) : (
          <BottomNavigationAction
            label={Languages.bookingCareHoursTitle}
            icon={
              <Badge
                variant="dot"
                color="error"
                invisible={
                  listChildNursingTimeStatus !== undefined &&
                  listChildNursingTimeStatus.length > 0
                    ? false
                    : true
                }
              >
                <EventNote sx={{ color: "#f0f0f0" }} />
              </Badge>
            }
            onClick={() =>
              history.push("/nursingtimes", { readNursingTimes: true })
            }
          />
        )}

        {GetUseOptimizedMessagingSetting() ? (
          <BottomNavigationAction
            label={Languages.msgInboxTitle}
            icon={
              <Badge
                sx={{
                  "& .MuiBadge-badge": {
                    fontSize: 9,
                    height: 15,
                    minWidth: 15,
                  },
                }}
                badgeContent={notReadThreads}
                color="error"
              >
                <ChatBubble sx={{ color: "ghostwhite" }} />
              </Badge>
            }
            onClick={() => history.push("/messaging2")}
          />
        ) : (
          <BottomNavigationAction
            label={Languages.msgInboxTitle}
            icon={
              <Badge
                sx={{
                  "& .MuiBadge-badge": {
                    fontSize: 9,
                    height: 15,
                    minWidth: 15,
                  },
                }}
                badgeContent={notReadThreads}
                color="error"
              >
                <ChatBubble sx={{ color: "ghostwhite" }} />
              </Badge>
            }
            onClick={() => history.push("/messaging")}
          />
        )}
      </BottomNavigation>
    </Paper>
  );
};

export default withRouter(BottomNavBar);

// Some app global constants
export const SESSION_KEYS = {
  loginUserName: 'loginuserName',
  userName: 'userName',
  personId: 'personID',
  communeName: 'communeName',
  pAbsentCode: 'pAbsentCode',
  holidayCode: 'HolidayCode',
  companyCode: 'CompdayCode',
  restApiVersion: 'RESTAPIVersion',
  loggedInStatus: 'loggedin',
  lastLocation: 'LastLocation',
  esuomiRelayState: "esuomiRelayState",
}

export const LOCAL_STORAGE_KEYS = {
  language: 'language',
  autoLogoutTime: 'autoLogoutTime',
}

// export const SESSION_VALUES = {
//   loggedIn: "Yes",
//   notLoggedIn: "No"
// }

export const MAINSTAT_CONSTS = {
  childListMinimumTimeSecs: 60,
  childInfoMinimumTimeSecs: 60,
  backgroundLoadAllMinimumTimeSecs: 1.9 * 60 
}
import React, { useCallback, useEffect, useState } from "react";
import { useStateValue } from "../../State";
import moment from "moment";
import { types } from "../../Reducers/actionTypes";
import Typography from "@mui/material/Typography";
import CareTimeCalendarSuccess from "./CareTimeCalendarSuccess/CareTimeCalendarSuccess";
import CareTimeCalendarLoading from "./CareTimeCalendarLoading/CareTimeCalendarLoading";
import CareTimeCalendarFailed from "./CareTimeCalendarFailed/CareTimeCalendarFailed";
const CareTimeCalendar = () => {
  const { actions, state } = useStateValue();

  // Caretimes state array
  const careTimes = state.caretimes.testList;

  // init date (today)
  const [initDate, setInitDate] = useState(moment());
  //focusdate to which calendar will scroll
  const [focusdate, setFocusdate] = useState(moment());
  //Start 2 weeks before initDate
  const [startDate, setStartDate] = useState(moment(initDate).add(-6, "weeks"));
  //End 6 weeks after initDate
  const [endDate, setEndDate] = useState(moment(initDate).add(6, "weeks"));
  // CareTime Day list
  const [CTBDays, setCTBDays] = useState([]);

  // Calendar state -- Initial -- Loading -- success -- failed
  const [bookingMainStatus, setBookingMainStatus] = useState("INITIAL STATE");

  const [reload, setReload] = useState(false);

  const handleFocusDay = (day) => {
    //console.log(moment(day).format("DD.MM.YYYY"));
    setInitDate(day);
    setStartDate(moment(day).add(-2, "weeks"));
    setEndDate(moment(day).add(6, "weeks"));
  };

  const handleCalendarNavClick = (day) => {
    handleFocusDay(day);
    setBookingMainStatus("INITIAL STATE");
  };

  //First load CareTime data from backend and set the state accordingly
  useEffect(() => {
    if (bookingMainStatus === "INITIAL STATE") {
      //console.log("test1");
      actions.triggerLoadCareTimes({ date: startDate, date2: endDate });
    }
  }, [bookingMainStatus]);

  useEffect(() => {
    //console.log("test1");

    switch (careTimes.state) {
      case undefined:
        break;
      case types.LOADING_CARETIMES:
        setBookingMainStatus("CARE_TIME_BOOKING_LOADING");
        break;
      case types.CARETIMES_LOAD_OK:
        //assign Daylist to const
        //console.log("asdasdasd");
        setCTBDays(careTimes.DayList);
        setFocusdate(initDate);
        setBookingMainStatus("CARE_TIME_BOOKING_SUCCESS");
        break;

      case types.CARETIMES_LOAD_FAILED:
        setBookingMainStatus("CARE_TIME_BOOKING_FAILED");
        break;
      default:
        break;
    }
  }, [careTimes.state]);

  //Load next / previous caretimes

  const [reloadStart, setReloadStart] = useState(moment());
  const [reloadingStatus, setReloadingStatus] = useState(false);

  const getNextMonth = () => {
    //console.log("next triggered");
    actions.triggerLoadCareTimesNext({
      date: moment(endDate).add(1, "days"),
      date2: moment(endDate).add(4, "weeks"),
    });
    setEndDate(endDate.add(4, "weeks"));
  };

  const getPrevMonth = () => {
    //console.log("next triggered");
    actions.triggerLoadCareTimesPrev({
      date: moment(startDate).add(-4, "weeks"),
      date2: moment(startDate).add(-1, "days"),
    });
    setStartDate(startDate.add(-4, "weeks"));
  };

  //Load CaretimeCalendar quick info // list of holidays, and info if day contains caretime or status (sick/ absence)
  // 3 months past and 18 months into future
  const quick = state.caretimes.Quick;

  const [quickDateStart, setQuickDateStart] = useState(
    moment().add(-2, "years").startOf("month")
  );
  const [quickDateEnd, setQuickDateEnd] = useState(
    moment().add(3, "years").startOf("month")
  );

  useEffect(() => {
    if (bookingMainStatus === "INITIAL STATE") {
      actions.triggerLoadCareTimesQuick({
        startDate: quickDateStart.format("yyyy-MM-DD"),
        endDate: quickDateEnd.endOf("month").format("yyyy-MM-DD"),
      });
    }
  }, [bookingMainStatus]);

  useEffect(() => {
    switch (quick.state) {
      case undefined:
        break;
      case types.LOADING_CARETIMES_QUICK:
        break;
      case types.CARETIMES_QUICK_LOAD_OK:
        break;
      case types.CARETIMES_QUICK_LOAD_FAILED:
        break;
      default:
        break;
    }
  }, [quick.state]);

  //check profilepictures state and load pictures if not in state
  const { profilePictures } = state;
  useEffect(() => {
    if (
      profilePictures.state !== "LOAD_SUCCESS_PROFILEPICTURES" &&
      profilePictures.state !== "LOADING_PROFILEPICTURES"
    ) {
      actions.triggerLoadProfilePictures();
    }
  }, []);

  // load default nursingtimes
  useEffect(() => {
    actions.triggerLoadDefaultNursingTimes();
  }, []);

  // Load clocktype
  useEffect(() => {
    actions.triggerLoadClockType();
  }, []);

  //Save new caretimes

  const saved = state.caretimes.CTSaved;

  useEffect(() => {
    //console.log("triggered222");
    //console.log(careTimes.DayList);

    switch (saved.state) {
      case undefined:
        break;
      case types.SAVING_CARETIMES:
        break;
      case types.CARETIMES_SAVED_OK:
        setCTBDays(careTimes.DayList);
        setFocusdate(initDate);
        //console.log(careTimes.DayList);
        break;
      case types.CARETIMES_SAVED_FAILED:
        break;
      default:
        break;
    }
  }, [saved.state]);

  useEffect(() => {
    switch (state.caretimes.Deleted.state) {
      case types.CARETIMES_DELETED_OK:
        setCTBDays(careTimes.DayList);
        setFocusdate(initDate);
        break;
      case types.DELETING_CARETIMES:
        console.log("deleting");
        break;
      case types.CARETIMES_DELETED_FAILED:
        break;
      default:
        break;
    }
  }, [state.caretimes.Deleted.state]);

  useEffect(() => {
    switch (state.caretimes.Absent.state) {
      case types.CARETIMES_ABSENT_SAVED_OK:
        setCTBDays(careTimes.DayList);
        setFocusdate(initDate);
        break;
      case types.SAVING_CARETIMES_ABSENT:
        break;
      case types.CARETIMES_ABSENT_SAVED_FAILED:
        break;
      default:
        break;
    }
  }, [state.caretimes.Absent.state]);

  let mainViewStatus = bookingMainStatus;

  if (mainViewStatus === "CARE_TIME_BOOKING_SUCCESS") {
    return (
      <>
        <CareTimeCalendarSuccess
          CTBDays={CTBDays}
          focusdate={focusdate}
          handleCalendarNavClick={handleCalendarNavClick}
          getNextMonth={getNextMonth}
          getPrevMonth={getPrevMonth}
          handleFocusDay={handleFocusDay}
        />
      </>
    );
  } else if (mainViewStatus === "CARE_TIME_BOOKING_LOADING") {
    return (
      <>
        <CareTimeCalendarLoading />
      </>
    );
  } else if (mainViewStatus === "CARE_TIME_BOOKING_FAILED") {
    return (
      <>
        <CareTimeCalendarFailed errorCode="404" />
      </>
    );
  } else {
    return (
      <>
        <CareTimeCalendarFailed errorCode="404" />
      </>
    );
  }
};
export default CareTimeCalendar;

import React from "react";
import BackToUpButton from "../GuideTools/BackToUpButton";
import AddAlarmIcon from "@mui/icons-material/AddAlarm";
import Languages from "../../../translations";
import makeStyles from "@mui/styles/makeStyles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import SelectAllIcon from "@mui/icons-material/SelectAll";
import MenuIcon from "@mui/icons-material/Menu";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const useStyles = makeStyles({
  success: {
    color: "rgb(129, 199, 132)",
    fontSize: "xx-large",
  },
});

const AbsentBookingAccordionText2 = () => {
  const classes = useStyles();
  return (
    <div>
      <h4>{Languages.absentBookingIntroTitle}</h4>
      <br />
      <p className="guide-accordion-details-texts">
        {"Poissa- ja sairasmerkintöjä voit tehdä hoitoaikakalenterissa. "}
      </p>
      <br />
      <br />
      <p className="guide-accordion-details-texts">
        {
          "Poissa- ja sairasmerkinnän voit tehdä kuluvalle päivälle tai usealle päivälle tulevaisuuteen, myös lukkiutuneille päiville."
        }
      </p>
      <br />
      <br />
      <p className="guide-accordion-details-texts">
        {
          " Kun teet poissa- tai sairasmerkinnän lukittuneelle päivälle, jossa on jo hoitovaraus, jää hoitovaraus laskutusperusteeseen näkyviin."
        }
      </p>
      <br />
      <br />
      <hr className="guide-accordion-details-line" />
      <h4>{Languages.absentBookingMiddleTitle1}</h4>
      <br />
      <div>
        <ol>
          <li className="guide-list-item-styles">
            {"Valitse varauskalenterin alalaidasta vihreä kalenteripainike."}
          </li>
          <br />
          <li className="guide-list-item-styles">
            {"Valitse ”Uusi poissaolo”."}
          </li>
          <br />
          <li className="guide-list-item-styles">
            {"Valitse kenelle lapselle/lapsille merkintä tehdään."}
          </li>
          <br />
          <li className="guide-list-item-styles">
            {"Valitse poissaolon syyksi ”Poissa” tai ”Sairas”."}
          </li>
          <br />
          <li className="guide-list-item-styles">
            {
              "Valitse tehdäänkö merkinnät yksittäisille päiville, viikolle vai valitulle aikavälille."
            }
          </li>
          <br />
          <li className="guide-list-item-styles">
            {
              "Valitse halutut päivät kalenterista. Valitut päivät näkyvät sinisellä värillä."
            }
          </li>
          <br />
          <li className="guide-list-item-styles">
            {"Valitse ”Tallenna poissaolo”."}
          </li>
          <br />
          <li className="guide-list-item-styles">
            {
              "Tallennuksen jälkeen merkinnät näkyvät varauskalenterissa oranssilla värillä."
            }
          </li>
        </ol>
      </div>

      <BackToUpButton />
    </div>
  );
};

export default AbsentBookingAccordionText2;

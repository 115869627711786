import React, {useEffect, useState} from "react"
import Typography from "@mui/material/Typography"
import Moment from "moment"
import "../../Layouts/DiscussionBooking/discussion.css"
import _cloneDeep from "lodash/cloneDeep"
import IconButton from "@mui/material/IconButton"
import BlueDivider from "../BlueDivider"
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import ContentCopy from "@mui/icons-material/ContentCopy"
import Languages from "../../translations"
import { useStateValue } from '../../State';
import { types } from "../../Reducers/actionTypes"
import CircularProgress from "@mui/material/CircularProgress"
import Alert from "@mui/material/Alert"
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { SessionManager } from "../../Auth"
import Button from "@mui/material/Button"

export const SelectTime = ({childrensBookableTimeSlots, discType, activeChildId, setConfirmSaveOpen,selectedTimeSlotRadioButton, setSelectedTimeSlotRadioButton,activeDiscStats,setActiveDiscStats}) => {
  const [selectedDate, selectDate] = useState()
  const [selectedTime, selectTime] = useState()

  const [tempDiscStats, setTempDiscStats] = useState()

  const [counter, setCounter] = useState(0)
  const [intervalState, setIntervalState] = useState()

  const [disabledIds, setDisabledIds] = useState()

  const [timeSelStatCount, setTimeSelStatCount] = useState(0)
  const [data, setData] = useState()

  const {state, actions} = useStateValue()
  const {discussion} = state

  const [personId, setPersonId] = useState()
  const [is5MinLeft, setIs5MinLeft] = useState(false)

  const [correctArray, setCorrectArray] = useState([])
  const [timeCorrectArray, setTimeCorrectArray] = useState([])
  
  const style = {timeButton:{
  },
  moreTimeButton: {
  },
  alertBox:{
    display: "flex",
    justifyContent:"space-between",
    width: "100%",
    minWidth: "100%"
  }}


  useEffect(()=>{
    setPersonId(SessionManager.getPersonId())
  },[])
  
  useEffect(()=>{

  },[discussion.discSaveTimeSelection,discussion.discTimeSelectionStats])

  useEffect(()=>{
    if(activeChildId){
      setCorrectArray(childrensBookableTimeSlots.find(child => child.ChildId == activeChildId).DiscussionsByDate)
      selectDate()
      timeIsUp()
    }
  },[activeChildId])

  useEffect(()=>{
    if(discussion.discDeleteTimeSelection != types.DELETING_DISCUSSION_TIMESELECTIONSTATUSES && discussion.discSaveTimeSelection == types.SAVE_DISCUSSION_TIMESELECTIONSTATUS_SUCCESS){
      actions.triggerDeleteDiscussionTimeSelectionStatuses()
    }
  },[discType,activeChildId])

  useEffect(()=>{
    selectDate()
    timeIsUp()
  },[discType])

  useEffect(()=>{
    clearInterval(intervalState)
    if(selectedDate){
      timeIsUp()
      const tempTimeCorrectArray = correctArray.find(x=> x.Date==selectedDate && x.BookableTypesForDate.includes(discType))?.Discussions.filter(x => x.DiscussionType == discType)
      setTimeCorrectArray(tempTimeCorrectArray)
      setTimeSelStatCount(0)
      actions.triggerLoadDiscussionTimeSelectionStatus(tempTimeCorrectArray.map((date)=>{return date.DiscussionId}))
      setCounter(0)
      setIntervalState(setInterval(()=>{  
          setCounter(prevCounter => prevCounter + 1)
      },1000))
    }
  },[selectedDate])
  


  useEffect(()=>{
    if(discussion.discTimeSelectionStats.status == types.GET_DISCUSSION_TIMESELECTIONSTATUS_SUCCESS){
      setDisabledIds(discussion.discTimeSelectionStats.data.map((x)=>{return x.DiscussionId}))
      setData(discussion.discTimeSelectionStats.data)
      setTimeSelStatCount(prevCount => prevCount + 1)
    }
  },[discussion.discTimeSelectionStats.status])

  useEffect(()=>{
    if(discussion.discSaveTimeSelection.status == types.SAVE_DISCUSSION_TIMESELECTIONSTATUS_SUCCESS){
      selectTime(discussion.discSaveTimeSelection.data.DiscussionId)
      setActiveDiscStats(tempDiscStats)
      setSelectedTimeSlotRadioButton(discussion.discSaveTimeSelection.data.DiscussionId)
      actions.triggerLoadDiscussionTimeSelectionStatus(timeCorrectArray.map((date)=>{return date.DiscussionId}))
    }
    if(discussion.discSaveTimeSelection.status ==  types.SAVE_DISCUSSION_TIMESELECTIONSTATUS_CONFLICT){
      actions.triggerAddToSnackBarQueue({message: Languages.discPreBookingConflict, color: "#000000", severity: "warning"})
      actions.triggerLoadDiscussionTimeSelectionStatus(timeCorrectArray.map((date)=>{return date.DiscussionId}))
    }
    if(discussion.discSaveTimeSelection.status ==  types.SAVE_DISCUSSION_TIMESELECTIONSTATUS_FAILED){
      actions.triggerAddToSnackBarQueue({message: Languages.discPreBookingError, color: "#000000", severity: "danger"})
      actions.triggerLoadDiscussionTimeSelectionStatus(timeCorrectArray.map((date)=>{return date.DiscussionId}))
    }
  },[discussion.discSaveTimeSelection.status])

  if(counter > 10){
    setCounter(0)   
    actions.triggerLoadDiscussionTimeSelectionStatus(timeCorrectArray.map((date)=>{return date.DiscussionId}))
  }
  
  const timeIsUp = () => {
    selectTime()
    setActiveDiscStats()
    setSelectedTimeSlotRadioButton("")
    setConfirmSaveOpen(false)
  }

  const handleDateToggle = (event) => {
    if(discussion.discSaveTimeSelection.status == types.SAVE_DISCUSSION_TIMESELECTIONSTATUS_SUCCESS)
      actions.triggerDeleteDiscussionTimeSelectionStatuses()
    selectDate(event.target.value)
    selectTime()
    setActiveDiscStats()
    setSelectedTimeSlotRadioButton("")
    setTimeSelStatCount(0)
    setConfirmSaveOpen(false)
  }
  const handleTimeToggle = (time) =>{
    setTempDiscStats(time)
    actions.triggerSaveDiscussionTimeSelectionStatus(time.DiscussionId)
  }



  return(
  <>

    <ToggleButtonGroup
      value={selectedDate}
      onChange={handleDateToggle}
      exclusive
      sx={{display:"flex",flexWrap:"wrap"}}
    >
      {correctArray.map((date, index)=>{
        if(date.BookableTypesForDate.includes(discType)){
          return <ToggleButton sx={{border:"none"}} value={date.Date} key={index + "" + date.Date} color="secondary">{Moment(date.Date).format('ddd DD.MM.YYYY').toUpperCase()}</ToggleButton>
        }
      })}
    </ToggleButtonGroup>
    {selectedDate && timeSelStatCount > 0 &&
    <>
    <ToggleButtonGroup
      value={selectedTime}
      exclusive
      sx={{display:"flex",flexWrap:"wrap"}}
    >
      {timeCorrectArray.map((time)=>{
            if(data.find(x => x.DiscussionId == time.DiscussionId)?.IsPermanent)
              return null
            return <ToggleButton onClick={()=> handleTimeToggle(time)} selected={data.find(x => x.DiscussionId == time.DiscussionId)?.PersonId == personId} disabled={disabledIds.includes(time.DiscussionId)} sx={[{border:"none"},style.timeButton]} key={time.DiscussionId} value={time.DiscussionId} color="secondary">{Moment(time.DiscussionStart).format('HH:mm').toUpperCase() +  " - " + Moment(time.DiscussionEnd).format('HH:mm') + " "}</ToggleButton>
      })}
    </ToggleButtonGroup>
    </>
    }
    {selectedDate && timeSelStatCount == 0 && <CircularProgress />}
    {selectedDate && timeSelStatCount > 0 && discussion.discSaveTimeSelection.status == types.SAVE_DISCUSSION_TIMESELECTIONSTATUS_SUCCESS  && selectedTime != null && <> <br/>
      <Alert severity={is5MinLeft ? "warning":"info"} action={is5MinLeft ? <Button variant="outlined" color="warning" onClick={()=>actions.triggerSaveDiscussionTimeSelectionStatus(selectedTime)}>{Languages.discPrebookingMoreTime}</Button>:null } icon={<AccessTimeIcon fontSize="inherit"/>}>
        <DiscussionSelectionTimer discussion={discussion} timerEndDate={new Date(discussion.discSaveTimeSelection.data.Saved).getTime() + 20*60000} timeIsUp={timeIsUp} setIs5MinLeft={setIs5MinLeft} actions={actions}/>   
      </Alert> 
      </>}
  </>
  )

}

const DiscussionSelectionTimer = ({discussion, timerEndDate, timeIsUp, setIs5MinLeft, actions}) => {
  const [counterInterval, setCounterInterval] = useState()
  const [difference, setDifference] = useState(20*60000)
  useEffect(()=>{
    clearInterval(counterInterval)
    setCounterInterval(setInterval(()=>{
    setDifference( timerEndDate - Date.now())
    },1000))
  },[discussion.discSaveTimeSelection.status])
  let timeLeft = {
    hours: 0,
    minutes: 0,
    seconds: 0,
  }

  const formatter = (number) => {
    if(number < 10)
      return "0"+number
    return number
  }

  if(difference > 0){
    timeLeft = {
      hours: Math.floor(difference / (1000 * 60 * 60)),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    }
  }
  if(difference <= 5*60000)
    setIs5MinLeft(true)
  
  if(difference > 5*60000)
    setIs5MinLeft(false)

  if(difference <= 0){
    actions.triggerAddToSnackBarQueue({message: Languages.discPrebookingTimeRanOut, color: "#000000", severity: "warning"})
    timeIsUp()
  }
    

  return <>{Languages.discLeftOfPrebooking(timeLeft.minutes,timeLeft.seconds)}</>
}













export const MoreInfoModal = ({slot, copyUrlToClipboard, closeInfoModal, showMoreInfoModal}) => {
  const headerstyle = {fontWeight: "bold", fontVariant: "small-caps", /*textDecoration:"underline"*/}

  if(!slot) {
    return null;
  }
  return(
    <Card sx={{backgroundColor: "#D7E3ED",borderRadius:"0",borderLeft:"5px solid #005AA3",color:"black"}}>
      <CardContent>
      <div style={{marginTop:"16px"}}></div>
      <Typography sx={headerstyle} component="span">{Languages.discStaff.toUpperCase()}</Typography>
      {slot.Employees.map((emp, index) => {
        return(
          <span key={emp.StaffName + index}>
            <Typography component="span"> : {emp.StaffName} &nbsp; &nbsp; &nbsp;</Typography>   
          </span>
        )
      })}
      
      {slot.ExtraInfoShared &&
        <>
          <BlueDivider/>
          <Typography component="span" sx={headerstyle}>{Languages.discInformationGivenByPersonel.toUpperCase()}</Typography><Typography component="span"> : {slot.ExtraInfoShared}</Typography>
          
        </>     
      }
      {slot.DiscussionAddress && 
        <>
          <BlueDivider/>
          <Typography component="span" sx={headerstyle}>{Languages.discAddress.toUpperCase()}</Typography> <Typography component="span"> : {slot.DiscussionAddress}</Typography>
        </>}
      {slot.IsRemoteMeeting && 
        <>
          <BlueDivider/>
          <Typography component="span" sx={headerstyle}>{Languages.discRemoteMeeting.toUpperCase()}</Typography>
          <Typography component="span">
            {" : " + slot.MeetingUrl + " "}
          </Typography>
        </>
      } 
      {slot.IsRemoteMeeting &&
        <IconButton 
          sx={{marginTop: "-4px"}}
          color="secondary" 
          size="small"
          aria-label="copy link kopioi linkki" 
          onClick={() => copyUrlToClipboard(slot.MeetingUrl)}
        >
          <ContentCopy size="small"/>
        </IconButton>
      }
      {slot.CancelableUntil &&
        <>
          <BlueDivider/>
          <Typography component="span" sx={headerstyle}>{Languages.discCancelableUntil2.toUpperCase()}</Typography><Typography component="span"> : {Moment(slot.CancelableUntil).format('ddd DD.MM.YYYY HH:mm').toUpperCase()}</Typography>
        </>  
      }
      </CardContent>
    </Card>
  )
}

import React, { useEffect, useState } from "react";
import { useStateValue } from "../../../../State";
import MenuIcon from "@mui/icons-material/Menu";
import AddIcon from "@mui/icons-material/Add";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupsIcon from "@mui/icons-material/Groups";
import "./CTBBottomNav2.css";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CTBCalendarDrawer from "./CTBCalendarDrawer";
import CTBChildDrawer from "./CTBChildDrawer";
import CTBSettingsDrawer from "./CTBSettingsDrawer";
import CTBNewResDialog from "./CTBNewResDialog";
import CTBNewAbsentDialog from "./CTBNewAbsentDialog";
import CTBSummaryDrawer from "./CTBSummaryDrawer";
import CTBAddDrawer from "./CTBAddDrawer";

const CTBBottomNav2 = (
  {
    classes,
    handleInfo,
    handleSave,
    CTBList,
    nursingtimes,
    names,
    views,
    personName,
    handleNames,
    handleView,
    view,
    monthChange,
    handleFocusDay,
    handleCalendarNavClick,
    LoadCareTimesCuick,
  },
  props
) => {
  const { state, actions } = useStateValue();

  const [drawerOpen3, setDrawerOpen3] = React.useState(false);
  const [drawerOpen4, setDrawerOpen4] = React.useState(false);

  const [drawerState, setDrawerState] = React.useState({
    child: false,
    setting: false,
    calendar: false,
    reservation: false,
    billing: false,
  });

  const toggleDrawer = (anchor) => (event) => {
    if (drawerState[anchor] === true) {
      setDrawerState({ ...state, [anchor]: false });
    } else {
      setDrawerState({ ...state, [anchor]: true });
    }
  };

  const [dialogState, setDialogState] = React.useState({
    reservation: false,
    absent: false,
  });

  const toggleDialog = (anchor) => (event) => {
    if (drawerState[anchor] === true) {
      setDrawerState({ ...state, [anchor]: false });
    } else {
      setDrawerState({ ...state, [anchor]: true });
    }
  };

  const [newResDialogOpen, setNewResDialogOpen] = React.useState(false);
  const closeNewResDialog = () => {
    setNewResDialogOpen(false);
  };
  const HandleNewRes = () => {
    setNewResDialogOpen(true);
    setDrawerOpen4(!drawerOpen4);
  };

  const [newAbsentDialogOpen, setNewAbsentDialogOpen] = React.useState(false);
  const closeNewAbsDialog = () => {
    setNewAbsentDialogOpen(false);
  };
  const handleNewAbs = () => {
    setNewAbsentDialogOpen(true);
    setDrawerOpen4(!drawerOpen4);
  };

  const handleClickScroll = (date) => {
    setDrawerState({ calendar: false });
    const element = document.getElementById(date.format("MM.DD.YYYY"));
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      const y = element.getBoundingClientRect().top + window.pageYOffset - 120;

      window.scrollTo({ top: y });
    } else {
      handleCalendarNavClick(date);
    }
  };

  return (
    <>
      <AppBar
        className={classes.appBar}
        position="fixed"
        style={{ background: "transparent", boxShadow: "none" }}
        sx={{
          top: "auto",
          bottom: 0,
          height: "50px",
        }}
      >
        <Toolbar
          sx={{
            margin: "auto",
            maxWidth: 800,
            width: "100%",
            backgroundColor: "white",
            boxShadow: 5,
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          }}
        >
          <ButtonGroup
            size="large"
            fullWidth
            sx={{
              mb: 0.5,
              "& .MuiButtonGroup-grouped": {
                borderColor: "#ffffff",
              },
              "& .MuiButtonGroup-root": {
                borderColor: "#ffffff",
              },
              "& .MuiButtonGroup-outlined": {
                borderColor: "#ffffff",
              },
            }}
          >
            <Button onClick={toggleDrawer("billing")}>
              <MenuIcon style={{ color: "#002e6d" }} />
            </Button>
            <Button onClick={toggleDrawer("child")}>
              <GroupsIcon style={{ color: "#002e6d" }} />
            </Button>
            <Box sx={{ ml: 3, mr: 3 }}>
              <Button
                variant="contained"
                color="success"
                style={{ borderRadius: 4 }}
                onClick={toggleDrawer("reservation")}
              >
                <AddIcon sx={{ padding: 0 }} fontSize="medium" color="white" />
              </Button>
            </Box>

            <Button onClick={toggleDrawer("calendar")}>
              <CalendarTodayIcon style={{ color: "#002e6d" }} />
            </Button>
            <Button onClick={toggleDrawer("setting")}>
              <SettingsIcon style={{ color: "#002e6d" }} />
            </Button>
          </ButtonGroup>
        </Toolbar>
      </AppBar>

      <CTBSummaryDrawer
        CTBdrawerOpen={drawerState["billing"]}
        CTBhandleDrawerOpen={toggleDrawer("billing")}
        childList={names}
      ></CTBSummaryDrawer>

      <CTBChildDrawer
        open={drawerState["child"]}
        names={names}
        onClose={toggleDrawer("child")}
        personName={personName}
        handleNames={handleNames}
      ></CTBChildDrawer>

      <CTBAddDrawer
        open={drawerState["reservation"]}
        onClose={toggleDrawer("reservation")}
        HandleNewRes={HandleNewRes}
        handleNewAbs={handleNewAbs}
      ></CTBAddDrawer>

      <CTBCalendarDrawer
        open={drawerState["calendar"]}
        onClose={toggleDrawer("calendar")}
        handleClickScroll={handleClickScroll}
        handleCalendarNavClick={handleCalendarNavClick}
      ></CTBCalendarDrawer>

      <CTBSettingsDrawer
        open={drawerState["setting"]}
        onClose={toggleDrawer("setting")}
      ></CTBSettingsDrawer>

      <CTBNewResDialog
        handleClick={closeNewResDialog}
        personName={personName}
        newResDialogOpen={newResDialogOpen}
        names={names}
        handleFocusDay={handleFocusDay}
        LoadCareTimesCuick={LoadCareTimesCuick}
      />
      <CTBNewAbsentDialog
        handleClick={closeNewAbsDialog}
        personName={personName}
        newResDialogOpen={newAbsentDialogOpen}
        handleFocusDay={handleFocusDay}
        names={names}
      />
    </>
  );
};

export default CTBBottomNav2;

import {useState, useEffect} from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit';
import Languages from '../../translations';
import { useStateValue } from '../../State';
import { types } from '../../Reducers/actionTypes';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import _cloneDeep from "lodash/cloneDeep";
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

const ModifyDiscussionBookingButton = ({data}) => {
    const [editState, setEditState] = useState(false)

    return(
    <>
        <Button onClick={()=>setEditState(!editState)} sx={{borderColor:"#002E6D",borderWidth:"1px",borderStyle:"solid",backgroundColor:"#D7E3ED",color:"#002E6D","&:hover":{backgroundColor:"#002E6D",color:"white"}}}>
            <EditIcon fontSize="small"/>&nbsp;&nbsp;{Languages.discEdit}
        </Button>
        {editState && <EditingDialog editState={editState} setEditState={setEditState} data={data}/>}
    </>
    )
}
export default ModifyDiscussionBookingButton

const EditingDialog = ({data,editState,setEditState}) =>{
    const [parentInfo, setParentInfo] = useState(data.ExtraInfoParent)
    const [checkedParents, setCheckedParents] = useState([])
    const [disabled, setDisabled] = useState(false)
    const [saving, setSaving] = useState(false)


    const {state, actions} = useStateValue()
    const {children,discussion} = state;

    const style = {
        dialogContent:{
            backgroundColor: 'white',
            color: 'black',
            transition: 'width 1s'
        },
        dialog:{
        }
    }

    useEffect(()=>{
       actions.triggerLoadChildinfo(data.ChildId)
       actions.triggerGetDiscParentsForEditing(data.ChildId)
    },[])
    useEffect(()=>{
        if(discussion.editDiscussionBooking.status==types.EDIT_DISCUSSION_BOOKING_SUCCESS){
            actions.triggerAddToSnackBarQueue({color: "#000000", message: Languages.discEditSuccess});
            setSaving(false)
        }
        if(discussion.editDiscussionBooking.status==types.EDIT_DISCUSSION_BOOKING_FAILED){
            actions.triggerAddToSnackBarQueue({color: "#000000", message: Languages.discSomethingWentWrong})
            setSaving(false)
            setEditState(false)
        }
    },[discussion.editDiscussionBooking])

    const getHelperText = (length) => {
        if (length > 1000) {
          return length + "/1000 Over character limit";
        } 
        return length + "/1000";
    }
    const handleAdditionalInfoChange = (e) => {
        setParentInfo(e.target.value);
    }

    const handleParentCheckBox = (e) => {
        if (e.target.checked) {
            const newArray = checkedParents.concat([e.target.id]);
            setCheckedParents(newArray)
        }
        else {
            let array = _cloneDeep(checkedParents)
            const index = array.indexOf(e.target.id)
            if (index > -1) {
            array.splice(index, 1)
            }
            setCheckedParents(array)
        }
    }

    const updateDiscussion = (discData) =>{
        if(parentInfo.length <= 1000 && checkedParents.length > 0){
            let sendableParents = _cloneDeep(checkedParents)
            let parentsList = []
            sendableParents.forEach((parent)=>{
                parentsList.push({
                    ParentName: null,
                    ParentId: parent
                })
            })

            const updateData = {
                ChildId: discData.ChildId,
                DiscussionId: discData.DiscussionId,
                Parents: parentsList,
                ExtraInfoParent: parentInfo
            }
            actions.triggerEditDiscussionBooking(updateData)
            setSaving(true)
        }

    }
    

        if(discussion.parentsForEditing.status == types.GET_PARENTS_FOR_DISCUSSION_EDITING_SUCCESS && discussion.parentsForEditing.data.ChildId!=data.ChildId ){
            actions.triggerGetDiscParentsForEditing(data.ChildId)
        }
        if(discussion.parentsForEditing.status == types.GET_PARENTS_FOR_DISCUSSION_EDITING_SUCCESS && discussion.parentsForEditing.data.DiscParents.length == 1 && !disabled){
            setDisabled(true)
            handleParentCheckBox({target:{id:discussion.parentsForEditing.data.DiscParents[0].ParentId,checked:true}})
        }

        return(
            <Dialog open={editState} maxWidth='sm' fullWidth sx={style.dialog}>
            
                <DialogTitle>{Languages.discEdit}</DialogTitle>
                {discussion.parentsForEditing.status == types.GET_PARENTS_FOR_DISCUSSION_EDITING_SUCCESS && discussion.parentsForEditing.data.ChildId==data.ChildId && !saving &&
                <Fade in>
                <DialogContent sx={style.dialogContent}>
                    
                     { discussion.parentsForEditing.data.ChildId==data.ChildId && <>
                        <Typography>{Languages.discChooseParticipant}:</Typography>
                        <FormGroup>
                        {discussion.parentsForEditing.data.DiscParents.map((parent)=>{
                            return <FormControlLabel key={parent.ParentId} control={
                                <Checkbox
                                    checked={checkedParents.includes(parent.ParentId)}
                                    onChange={handleParentCheckBox} 
                                    id={parent.ParentId}
                                    disabled={disabled}
                                />
                        }
                        label={parent.ParentName}/>
                        })
                    }
                    </FormGroup>
                    </>
                    }
                    <br/>
                    <TextField
                                    error={parentInfo.length > 1000}
                                    helperText={getHelperText(parentInfo.length)}
                                    inputProps={{className:"usernamepasswordinput123"}}
                                    variant="outlined"
                                    id="outlined-multiline-flexible"
                                    label={<><FamilyRestroomIcon/>&nbsp;&nbsp;{Languages.discInformationGivenByGuardians} </>}
                                    multiline
                                    maxRows={4}
                                    value={parentInfo}
                                    onChange={handleAdditionalInfoChange}
                                    fullWidth
                    />
                    
                    
                </DialogContent>
                </Fade>}
                {discussion.parentsForEditing.status == types.LOADING_PARENTS_FOR_DISCUSSION_EDITING || saving &&
                <DialogContent sx={{textAlign:"center"}}>
                    <CircularProgress size={100}/>
                </DialogContent>       
                }
                {discussion.parentsForEditing.status == types.GET_PARENTS_FOR_DISCUSSION_EDITING_FAILED && 
                <DialogContent sx={{textAlign:"center"}}>
                    <SentimentVeryDissatisfiedIcon size={100}/>
                    <Typography>{Languages.discSomethingWentWrong}</Typography>
                </DialogContent> 
                }
                <DialogActions sx={{textAlign:"center"}}>
                <Button onClick={()=>setEditState(!editState)} sx={{border:'none',backgroundColor:'#B30036', color:'white','&:hover':{backgroundColor:'#F9E0E5',color:'#8F002B'}}}><CloseIcon/>&nbsp;&nbsp;{Languages.discStopEditing}</Button>
                {discussion.parentsForEditing.status == types.GET_PARENTS_FOR_DISCUSSION_EDITING_SUCCESS && discussion.parentsForEditing.data.ChildId==data.ChildId &&
                    <Button onClick={()=>updateDiscussion(data)} sx={{border:'none', backgroundColor:'#006633',color: 'white','&:hover':{backgroundColor:'#DCEEDF', color:'#00572B'}}}><DoneIcon/>&nbsp;&nbsp;{Languages.discSaveChanges}</Button>
                }
                    
                </DialogActions>
            </Dialog>)
}
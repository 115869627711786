import React, { useEffect, useState, useReducer } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from "@mui/material/Typography";
import Divider from '@mui/material/Divider';
import AccordionActions from '@mui/material/AccordionActions';
import IconButton from '@mui/material/IconButton';
import Edit from '@mui/icons-material/EditRounded';
import Check from '@mui/icons-material/Check';
import Undo from '@mui/icons-material/Undo';
import PersonPinIcon         from '@mui/icons-material/PersonPin';
import Languages from "../../../../translations";
import ParentsInfoReadOnly from "./ParentsInfoReadOnly";
import ParentsInfoEditable from "./ParentsInfoEditable";
import { useStateValue } from "../../../../State";
import { SessionManager } from '../../../../Auth';
import { validatePhoneNumber, isProperStreetAddressOrCity, isPostalCode, isValidEmail } from "./";
import { convertNullToString } from "../../../../utils";
import { inArray } from "jquery";
import InfoModal from "../../../../Components/Modals/InfoModal";

const styles = {
  color: "#4377BA",
  fontSize: "14pt",
  fontWeight: "bold",
}

export const formDataReducer = (state, action) => {
  const field = action.data.fieldName;
  const personId = action.data.personId;

  if (action.type === "update") {
    const newState = [...state];
    let createNew = false;
    let entityIndex = newState.findIndex(s => s.personId === personId);

    if (entityIndex === -1) {
      createNew = true;
      newState.push({
      })
      entityIndex = newState.lastIndex;
    }

    newState[entityIndex] = {
      ...newState[entityIndex],
      personId: personId,
      [field]: action.data.fieldValue
    }

    return newState;

  } else if (action.type === "backend_update") {
    const newState = [];
    const parents = action.data.filter(parent => { return parent.Type !== "BP"; })
    parents.forEach(parentInfo => {

      let parentObj = {
        personId: parentInfo.PersonID,
        Name: convertNullToString(parentInfo.Name),
        ParentEmail: convertNullToString(parentInfo.EmailAddress),
        ParentStreetAddress: convertNullToString(parentInfo.StreetAddress),
        ParentPostalCode: convertNullToString(parentInfo.PostalCode),
        ParentCity: convertNullToString(parentInfo.City),
        ParentEmailNotification: parentInfo.allowEmailMessage,
        ParentPushNotification: parentInfo.allowPushMessage,
        ParentAllowView: parentInfo.allowView,
        ParentType: parentInfo.Type,
      };
      parentInfo.Phonenumbers.forEach(ph => {
        if (ph.Type === 1) {
          parentObj["ParentWorkNumber"] = {
            ID: ph.ID,
            Number: ph.Number,
            Type: ph.Type,
          }
        }
        if (ph.Type === 0) {
          parentObj["ParentPhoneNumber"] = {
            ID: ph.ID,
            Number: ph.Number,
            Type: ph.Type,
          }
        }
      })
      if (!parentObj["ParentWorkNumber"]) {
        parentObj["ParentWorkNumber"] = {
          ID: undefined,
          Number: undefined,
          Type: 1,
        }
      }
      if (!parentObj["ParentPhoneNumber"]) {
        parentObj["ParentPhoneNumber"] = {
          ID: undefined,
          Number: undefined,
          Type: 0,
        }
      }

      newState.push(parentObj);
    });

    return newState;
  }

  return state;
}

const handleFormFieldChange = (event, dispatch, ChildInfo, extra) => {
  let CheckStatus = [];
  const personId = extra.personId;
  let eventValue = event.target.value;

  if (event.target.type === "checkbox") {
    eventValue = event.target.checked;
  }

  switch (event.target.name) {
    case "ParentEmail":
      dispatch({
        type: "update", data: {
          personId: personId,
          fieldName: event.target.name,
          fieldValue: eventValue,
        }
      });

      const validEmail = isValidEmail(eventValue);
      if (!validEmail) {
        CheckStatus.push({
          personId: personId,
          field: event.target.name,
          valid: validEmail
        })
      }
      break;
    case "ParentPhoneNumber":
    case "ParentWorkNumber":
    case "BUParentPhoneNumber":
    case "BUParentWorkNumber":
      let parent = ChildInfo.Info?.ParentsInfo.find(pi => pi.PersonID === personId);
      let parentNumberObj;

      if (event.target.name === "ParentPhoneNumber" || event.target.name === "BUParentPhoneNumber") {
        parentNumberObj = parent.Phonenumbers.find(pin => pin.ID === extra.numberId && pin.Type === 0);
      }
      else {
        parentNumberObj = parent.Phonenumbers.find(pin => pin.ID === extra.numberId && pin.Type === 1);
      }

      const parentNumber = { ...parentNumberObj }

      parentNumber.Number = eventValue;

      dispatch({
        type: "update", data: {
          personId: personId,
          fieldName: event.target.name,
          fieldValue: parentNumber,
        }
      });

      const invalidNumber = validatePhoneNumber(parentNumber.Number, true);
      if (invalidNumber) {
        CheckStatus.push({
          personId: personId,
          field: event.target.name,
          ...invalidNumber
        })
      }
      break;

    case "ParentStreetAddress":
      dispatch({
        type: "update",
        data: {
          personId: personId,
          fieldName: event.target.name,
          fieldValue: eventValue,
        }
      });

      if (!isProperStreetAddressOrCity(eventValue)) {
        CheckStatus.push({
          personId: personId,
          field: event.target.name,
          valid: false
        })
      }
      break;

    case "ParentPostalCode":
      dispatch({
        type: "update",
        data: {
          personId: personId,
          fieldName: event.target.name,
          fieldValue: eventValue,
        }
      });

      if (!isPostalCode(eventValue)) {
        CheckStatus.push({
          personId: personId,
          field: event.target.name,
          valid: false
        })
      }
      break;

    case "ParentCity":
      dispatch({
        type: "update",
        data: {
          personId: personId,
          fieldName: event.target.name,
          fieldValue: eventValue,
        }
      });
      if (!isProperStreetAddressOrCity(eventValue)) {
        CheckStatus.push({
          personId: personId,
          field: event.target.name,
          valid: false
        })
      }
      break;

    case "ParentEmailNotification":
    case "ParentPushNotification":
      dispatch({
        type: "update",
        data: {
          personId: personId,
          fieldName: event.target.name,
          fieldValue: eventValue,
        }
      });
      break;

    case "ParentAllowView":
      dispatch({
        type: "update",
        data: {
          personId: personId,
          fieldName: event.target.name,
          fieldValue: eventValue,
        }
      });
      break;

    default:
      break;
  }

  return CheckStatus;
};

const ParentsInfo = ({ childId, childColor = "#4377BA"}) => {
  const { state: { settings, children }, actions } = useStateValue();
  const { ChildList } = children; //.Info.ParentsInfo
  const [editing, setEditing] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [addressEditingEnabled, setAddressEditingEnabled] = useState(false);
  const [emailServiceEnabled, setEmailServiceEnabled] = useState(false);
  const [formFieldsData, dispatchFormFieldsUpdate] = useReducer(formDataReducer, []);
  const [viewNotiModalOpen, setViewNotiModalOpen] = useState(false);
  const [emailNotiModalOpen, setEmailNotiModalOpen] = useState(false);
  const [formValidStatus, setFormValidStatus] = useState([])
  const [initialParentDataStatus, setInitialParentDataStatus] = useState({});

  const [saveButtonEnabled, enableSaveButton] = useState(false);

  useEffect(() => {
    checkAllParentInfoFieldsForErrors();
  }, [editing, formValidStatus]);

  useEffect(() => {
    const addressEditValue = settings.communeSettings.find(setting => setting.Key === "DaisyFamily/ParentAddressEditEnabled");
    const documentsEmailServiceEnabled = settings.communeSettings.find(setting => setting.Key === "Documents/EmailNotificationEnabled");
    const eDaisyEmailServiceEnabled = settings.communeSettings.find(setting => setting.Key === "EDaisy/AppEmailEnabled");
    const emailServiceEnableStatus = settings.communeSettings.find(setting => setting.Key === "mailServiceEnabled");

    if (addressEditValue != null && typeof addressEditValue != undefined && addressEditValue.ValueBool === true) {
      setAddressEditingEnabled(true);
    }
    else {
      setAddressEditingEnabled(false);
    }

    if (emailServiceEnableStatus != null && typeof emailServiceEnableStatus != undefined && emailServiceEnableStatus.ValueBool === true ||
      documentsEmailServiceEnabled != null && typeof documentsEmailServiceEnabled != undefined && documentsEmailServiceEnabled.ValueBool === true ||
      eDaisyEmailServiceEnabled != null && typeof eDaisyEmailServiceEnabled != undefined && eDaisyEmailServiceEnabled.ValueBool === true
    ) {
      setEmailServiceEnabled(true);
    }
    else {
      setEmailServiceEnabled(false);
    }

  }, [settings])

  useEffect(() => {
    if (!editing) {
      const currentChild = ChildList.find(c => c.Id == childId);

      if (currentChild.Info) {
        dispatchFormFieldsUpdate({ type: "backend_update", data: currentChild.Info?.ParentsInfo });
      }
    }
  }, [ChildList])

  const toggleExpanded = () => {
    setExpanded(!expanded);
  }

  const onEdit = () => {
    setEditing(true);
    checkAllParentInfoFieldsForErrors();
  }

  const onUndo = () => {
    setEditing(false);
    actions.triggerLoadChildinfo(childId);
  }

  const onSave = () => {
    const buildParentsInfo = (formData) => {
      let parentsInfos = []

      formData.forEach(data => {
        const parentInfo = {
          PersonID: data.personId,
          Name: data.Name,
          EmailAddress: data.ParentEmail,
          StreetAddress: data.ParentStreetAddress,
          PostalCode: data.ParentPostalCode,
          City: data.ParentCity,
          allowEmailMessage: data.ParentEmailNotification,
          allowPushMessage: data.ParentPushNotification,
          allowView: data.ParentAllowView,
          Type: data.ParentType,
          Phonenumbers: [
            {
              Type: 1,
              Id: data.ParentWorkNumber.ID,
              Number: data.ParentWorkNumber.Number
            },
            {
              Type: 0,
              Id: data.ParentPhoneNumber.ID,
              Number: data.ParentPhoneNumber.Number
            },
          ]
        }

        parentsInfos.push(parentInfo);
      })

      return parentsInfos;
    }

    const parentData = buildParentsInfo(formFieldsData);

    const childInfoData = {
      PersonID: childId,
      Admin: SessionManager.getUser(),
      SavedInfoType: "ParentsInfo",
      ParentsInfo: parentData
    };

    actions.triggerSaveChildinfo(childInfoData);
    setEditing(false);
  }
/*
  const checkInitialParentInfoValues = () => {
    var initialParentInfoStatus = {};
    formFieldsData.forEach(parent => {
      var parentStatus = {
        personId: parent.personId,
      };

      if(isValidEmail(parent.ParentEmail)){
        parentStatus.emailIsValid = true;
      }
      else {
        parentStatus.emailIsValid = false;
      }

      if(isProperStreetAddressOrCity(parent.ParentCity)){
        parentStatus.cityIsValid = true;
      }
      else {
        parentStatus.cityIsValid = false;
      }

      if(isProperStreetAddressOrCity(parent.StreetAddress)){
        parentStatus.streetAddressIsValid = true;
      }
      else {
        parentStatus.streetAddressIsValid = false;
      }

      if(isPostalCode(parent.ParentPostalCode)){
        parentStatus.postalCodeIsValid = true;
      }
      else {
        parentStatus.postalCodeIsValid = false;
      }

      if(validatePhoneNumber(parent.ParentPhoneNumber.Number).Valid){
        parentStatus.phoneIsValid = true;
      }
      else {
        parentStatus.phoneIsValid = false;
      }

      if(validatePhoneNumber(parent.ParentWorkNumber.Number).Valid){
        parentStatus.workPhoneIsValid = true;
      }
      else {
        parentStatus.workPhoneIsValid = false;
      }

      console.log('Tässä parentStatus:');
      console.log(parentStatus);
    });
  }
 */
  const checkAllParentInfoFieldsForErrors = () => {
    setInitialParentDataStatus({});
    var initialParentDataArray = [];
    var parentDataValidArray = [];
    formFieldsData.forEach(parent => {
      var parentStatus = {
        personId: parent.personId,
      };

      if(parent.ParentEmail != null && typeof parent.ParentEmail != undefined)
      {
        parentStatus.emailIsValid = isValidEmail(parent.ParentEmail);
      }
      if(parent.ParentPhoneNumber != null && typeof parent.ParentPhoneNumber != undefined)
      {
        parentStatus.phoneNumberIsValid = validatePhoneNumber(parent.ParentPhoneNumber.Number, true).valid;
      }
      if(parent.ParentWorkNumber != null && typeof parent.ParentWorkNumber != undefined)
      {
        parentStatus.workNumberIsValid = validatePhoneNumber(parent.ParentWorkNumber.Number, true).valid;
      }
      if(addressEditingEnabled != false) {
      if(parent.ParentPostalCode != null && typeof parent.ParentPostalCode != undefined)
      {
        parentStatus.postalCodeIsValid = isPostalCode(parent.ParentPostalCode);
      }
      if(parent.ParentCity != null && typeof parent.ParentCity != undefined)
      {
        parentStatus.cityIsValid = isProperStreetAddressOrCity(parent.ParentCity);
      }      
      if(parent.ParentStreetAddress != null && typeof parent.ParentStreetAddress != undefined)
      {
        parentStatus.streetIsValid = isProperStreetAddressOrCity(parent.ParentStreetAddress);
      }
    }
    else {
      parentStatus.postalCodeIsValid = true;
      parentStatus.cityIsValid = true;
      parentStatus.streetIsValid = true;
    }

      if (parentStatus.emailIsValid       != true      ||
          parentStatus.phoneNumberIsValid != true      ||
          parentStatus.workNumberIsValid  != true      ||
          parentStatus.postalCodeIsValid  != true      ||
          parentStatus.cityIsValid        != true      ||
          parentStatus.streetIsValid      != true)
         {
          parentDataValidArray.push("false");
         }
         else
         {
          parentDataValidArray.push("true");
         }

         initialParentDataArray.push(parentStatus);
         //initialParentInfoStatus.push(parentStatus);
    });
    setInitialParentDataStatus(initialParentDataArray);

    if(inArray("false", parentDataValidArray) != -1) {
      enableSaveButton(false);
    }
    else {
      enableSaveButton(true);
    }
  }



  const onFormFieldChange = (event, extra) => {
    const currentChild = ChildList.find(c => c.Id == childId);
    const formStatus = handleFormFieldChange(event, dispatchFormFieldsUpdate, currentChild, extra);
    setFormValidStatus(formStatus);
  }

  return <>
    <Accordion className="painikkeet"
      style={{ border: "5px", borderLeft: "12px", borderStyle:"solid", borderColor: childColor, backgroundColor: "#ffffff", color: "#181818" }}
      expanded={expanded}
      onChange={() => toggleExpanded()}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}
        aria-controls="panel3a-content"
        id="test_ParentsInfo_Btn" /*id="panel3a-header" */>
        <Typography className="heading" style={styles}><PersonPinIcon /> <b style={{marginLeft: "10px", display: "inline"}}>{Languages.ciContactInformation}</b></Typography>
      </AccordionSummary>
      <div className="row">
        <AccordionDetails className="ml-3">
          {!editing &&
            <ParentsInfoReadOnly
              isEmailServiceEnabled={emailServiceEnabled}
              formStatus={formValidStatus}
              parents={formFieldsData} />
          }
          {editing &&
            <ParentsInfoEditable
              isEditingAddressAllowed={addressEditingEnabled}
              isEmailServiceEnabled={emailServiceEnabled}
              onShowAllowViewHelpModal={() => setViewNotiModalOpen(true)}
              onShowEmailHelpModal={() => setEmailNotiModalOpen(true)}
              formFieldChange={onFormFieldChange}
              parents={formFieldsData}
              formStatus={formValidStatus}
              initialParentDataStatus = {initialParentDataStatus}
            />}
        </AccordionDetails>
      </div>
      <Divider />

      {!editing &&
        <AccordionActions className="painikkeet">
          <IconButton id="test_ParentsInfo_EditBtn" onClick={onEdit} size="large"> <Edit /> </IconButton>
        </AccordionActions>
      }
      {editing &&
        <AccordionActions className="painikkeet">
          <IconButton id="test_ParentsInfo_SaveBtn" onClick={onUndo} size="large"> <Undo /> </IconButton>
          {saveButtonEnabled ? 
          <IconButton id="test_ParentsInfo_SaveBtn" onClick={onSave} size="large"> <Check /> </IconButton>
          :
          null
          }
        </AccordionActions>
      }
    </Accordion>
    <InfoModal 
      modalOpen={viewNotiModalOpen} 
      allowClose={true}
      onClose={() => setViewNotiModalOpen(false)}>
        <div className="textCenter">
            <h4 style={{ whiteSpace: "pre-line" }}>{Languages.allowViewModalTitle}</h4>
            <Divider />
            <br />
            <Typography align="left" variant="h6">{Languages.allowed}:</Typography>
            <Typography align="left">{Languages.allowViewModalYesText}</Typography>
            <hr />
            <Typography align="left" variant="h6">{Languages.notAllowed}:</Typography>
            <Typography align="left">{Languages.allowViewModalNoText}</Typography>
        </div>
    </InfoModal>
  </>;
}

export default ParentsInfo;
import React from "react";
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Typography from "@mui/material/Typography";
import Languages from "../../../../translations";
import BackupParentInfoEditField from "./BackupParentInfoEditField";

const BackupParentInfoEditable = ({ backupPersons, formFieldChange, formStatus, deleteBackupParent, deleteIsLoading }) => {
  return (
    <div>
      {backupPersons.map((parent, BUparentIndex) => {

        let invalidPhoneNumberFound = formStatus.find(status => status.field === "BUParentPhoneNumber" && status.personId === parent.personId && status.valid === false);

        return (
          <div key={parent.personId}>
            <Divider></Divider>
            <br></br>

            {invalidPhoneNumberFound !== undefined && BUparentIndex === 0 ?
              <div>
                <TextField error={true}
                  name={"BUParentPhoneNumberErrorInfoText"}
                  id="outlined-read-only-input"
                  InputProps={{ readOnly: true, disableunderline: "true", className: "marginTop" }}
                  type="text"
                  helperText={Languages.ciInfo1} />
                <br></br><br></br></div>
              : null
            }
            <BackupParentInfoEditField
              parent={parent}
              formStatus={formStatus}
              formFieldChange={formFieldChange}
              BUparentIndex={BUparentIndex}
              deleteBackupParent={(backupParentId) => deleteBackupParent(backupParentId)}
              deleteIsLoading={deleteIsLoading}
            />
          </div>
        );
      })}
    </div>
  )
}

export default BackupParentInfoEditable;
import React, { useEffect, useState, useRef } from "react";
import Languages from "../../../../translations";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardActionArea } from "@mui/material";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useStateValue } from "../../../../State";
import moment from "moment";
import { types } from "../../../../Reducers/actionTypes";
import FaceRoundedIcon from "@mui/icons-material/FaceRounded";

import Slider from "@mui/material/Slider";
import SickIcon from "@mui/icons-material/Sick";
import LoginIcon from "@mui/icons-material/Login";
import NightShelterIcon from "@mui/icons-material/NightShelter";
import ChildProfilePicture from "../../../ChildProfilePicture";
import CTBDayDialog2 from "./CTBDayDialog2";
import CTBDayDialog3 from "./CTBDayDialog3";
import { Virtuoso } from "react-virtuoso";
import CircularProgress from "@mui/material/CircularProgress";
import LockIcon from "@mui/icons-material/Lock";
const ContentManager = ({
  CTBDays,
  childList,
  selectedChildren,
  focusdate,
  getNextMonth,
  getPrevMonth,
  handleFocusDay,
}) => {
  const [showWeekEnds, setShowWeekEnds] = useState(
    childList.some((child) =>
      child.Placements.some(
        (placement) =>
          (placement.Type === "ROUND_THE_CLOCK" ||
            placement.Type === "EXTENDED") &&
          placement.OpenType === "MONSUN"
      )
    )
  );
  const [compactMode, setCompactMode] = useState(true);
  const [isScrolling, setIsScrolling] = useState(false);
  const { actions, state } = useStateValue();
  const careTimes = state.caretimes.testList;
  const saved = state.caretimes.CTSaved;
  const [testing, setTesting] = useState(false);
  const virtuoso = useRef(null);

  const [firstItemIndex, setFirstItemIndex] = useState(10000);
  const test = () => {
    if (testing === false && saved.state !== types.SAVING_CARETIMES) {
      setTesting(true);
      careTimes.state = "prööt";
      getNextMonth();
    }
  };

  const test2 = () => {
    if (testing === false && saved.state !== types.SAVING_CARETIMES) {
      setTesting(true);
      careTimes.state = "prööt";
      getPrevMonth();
    }
  };

  useEffect(() => {
    switch (careTimes.state) {
      case types.CARETIMES_NEXT_LOAD_OK:
        setTesting(false);
        break;
      case types.CARETIMES_PREV_LOAD_OK:
        setFirstItemIndex(firstItemIndex - 27);
        setTesting(false);
        break;

      case types.CARETIMES_NEXT_LOAD_FAILED:
        break;
      default:
        break;
    }
  }, [careTimes.state]);

  useEffect(() => {
    virtuoso.current.scrollToIndex({
      index: showWeekEnds
        ? CTBDays.map((d) => moment(d.Date).format("MM.DD.YYYY")).indexOf(
            moment(focusdate).format("MM.DD.YYYY")
          )
        : CTBDays.filter((day) => day.IsWeekend === false)
            .map((d) => moment(d.Date).format("MM.DD.YYYY"))
            .indexOf(moment(focusdate).format("MM.DD.YYYY")),
      align: "center",
      behavior: "instant",
    });
  }, [focusdate]);

  // Day Dialog open -- default false
  const [dayDialogOpen, setDayDialogOpen] = useState(false);
  // open Day Dialog
  const OpenDayDialog = () => {
    setDayDialogOpen(!dayDialogOpen);
  };
  // Day Dialog open -- default false
  const [dialogIndex, setDialogIndex] = useState("2023-08-17T00:00:00");
  // open Day Dialog
  const handleDialogIndex = (index) => {
    setDialogIndex(index);
  };

  const handleDialogIndexChange = (index) => {
    let prevIndex = 0;
    if (showWeekEnds) {
      prevIndex = careTimes.DayList.findIndex((d) =>
        moment(d.Date).isSame(dialogIndex)
      );
      setDialogIndex(careTimes.DayList[prevIndex + index].Date);
    } else {
      prevIndex = careTimes.DayList.findIndex(
        (day) => day.IsWeekend === false
      ).find((d) => moment(d.Date).isSame(dialogIndex));
      setDialogIndex(careTimes.DayList[prevIndex + index].Date);
    }

    if (
      (showWeekEnds && prevIndex >= careTimes.DayList.length - 5) ||
      prevIndex >=
        careTimes.DayList.filter((day) => day.IsWeekend === false).length - 5
    ) {
      test();
    }
  };
  return (
    <>
      <Virtuoso
        firstItemIndex={firstItemIndex}
        ref={virtuoso}
        style={{ paddingBottom: 0 }}
        useWindowScroll
        isScrolling={setIsScrolling}
        startReached={test2}
        endReached={test}
        data={
          showWeekEnds
            ? careTimes.DayList
            : careTimes.DayList.filter((day) => day.IsWeekend === false)
        }
        components={{
          Footer: () => {
            return (
              <div
                style={{
                  paddingBottom: 50,
                }}
              >
                <Stack
                  spacing={1}
                  alignItems="center"
                  justifyContent="center"
                  sx={{ mt: 2 }}
                >
                  <Divider>
                    <Chip
                      label={Languages.newCalendarLoadingNext}
                      sx={{ backgroundColor: "#0074C8" }}
                    ></Chip>
                  </Divider>

                  <CircularProgress sx={{ color: "#0074C8" }} />
                </Stack>
              </div>
            );
          },
          Header: () => {
            return (
              <div style={{}}>
                <Stack
                  spacing={1}
                  alignItems="center"
                  justifyContent="center"
                  sx={{ mb: 2 }}
                >
                  <CircularProgress sx={{ color: "#0074C8" }} />
                  <Divider>
                    <Chip
                      label={Languages.newCalendarLoadingPrev}
                      sx={{ backgroundColor: "#0074C8" }}
                    ></Chip>
                  </Divider>
                </Stack>
              </div>
            );
          },
        }}
        itemContent={(index, day) => (
          <>
            <CTBDay
              isScrolling={isScrolling}
              key={day.Date}
              day={day}
              selectedChildren={selectedChildren}
              compactMode={compactMode}
              showWeekEnds={showWeekEnds}
              OpenDayDialog={OpenDayDialog}
              handleDialogIndex={handleDialogIndex}
              index={index}
            ></CTBDay>
          </>
        )}
      />

      {dayDialogOpen && (
        <CTBDayDialog3
          day={
            showWeekEnds
              ? careTimes.DayList.find((d) =>
                  moment(d.Date).isSame(dialogIndex)
                )
              : careTimes.DayList.filter((day) => day.IsWeekend === false).find(
                  (d) => moment(d.Date).isSame(dialogIndex)
                )
          }
          names={childList}
          modalOpen={dayDialogOpen}
          handleFocusDay={handleFocusDay}
          handleClick={OpenDayDialog}
          showWeekEnds={showWeekEnds}
          index={dialogIndex}
          handleDialogIndex={handleDialogIndex}
          handleDialogIndexChange={handleDialogIndexChange}
          maxIndex={
            showWeekEnds
              ? careTimes.DayList.length
              : careTimes.DayList.filter((day) => day.IsWeekend === false)
                  .length
          }
        />
      )}

      {/*{CTBDays.map((day) => (
        <>
          {day.IsWeekend === false ? (
            <CTBDay
              key={day.Date}
              day={day}
              selectedChildren={selectedChildren}
              compactMode={compactMode}
            ></CTBDay>
          ) : showWeekEnds === true ? (
            <CTBDay
              key={day.Date}
              day={day}
              selectedChildren={selectedChildren}
              compactMode={compactMode}
            ></CTBDay>
          ) : null}
        </>
          ))}*/}
    </>
  );
};

export default ContentManager;

const CTBDay = ({
  day,
  compactMode,
  selectedChildren,
  isScrolling,
  showWeekEnds,
  OpenDayDialog,
  handleDialogIndex,
  index,
}) => {
  const dialogClick = () => {
    handleDialogIndex(day.Date);
    OpenDayDialog();
  };
  return (
    <>
      {day.Date.isoWeekday() === 1 && (
        <Divider
          sx={{
            mt: 1,
            "&::before, &::after": {
              borderColor: "#0074C8",
            },
          }}
          textAlign="center"
        >
          <Chip
            label={Languages.newCalendarWeek + day.Date.isoWeek()}
            sx={{ backgroundColor: "#0074C8" }}
          />
        </Divider>
      )}

      {/*<Box
        id={day.Date.format("MM.DD.YYYY")}
        sx={{
          mb: 1,
          mt: 1,
          background: "white",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "row",
          boxShadow: 2,
        }}
        onClick={OpenDayDialog}
      >
        <Box
          sx={{
            p: 1,
            mr: 0.5,
            borderTopLeftRadius: "8px",
            borderBottomLeftRadius: "8px",
            backgroundColor:
              moment(day.Date).isSame(new Date(), "day") === true
                ? "success.light"
                : "white",
          }}
        >
          <Typography color="textSecondary" fontWeight="bold">
            {day.Date.format("DD.MM")}
          </Typography>
          <Typography color="textSecondary" fontSize="small">
            {day.Date.format("ddd")}
          </Typography>
          <Typography color="textSecondary" fontSize="small">
            {"vko." + moment(day.Date).isoWeek()}
          </Typography>
        </Box>
        <Box sx={{ width: "100%" }}>
          <>
            {day.WeekHoliday !== null && (
              <>
                <Chip
                  sx={{ mt: 0.5, mb: 0.5, ml: 1 }}
                  label={
                    <Typography color="textSecondary">
                      {day.WeekHoliday}
                    </Typography>
                  }
                ></Chip>
              </>
            )}
          </>
          {day.CareTimeTest.map((child) => (
            <>
              {selectedChildren.includes(child.ChildId) && (
                <CTBChildRow
                  day={day}
                  child={child}
                  selectable={
                    day.IsLockedDate === true
                      ? false
                      : day.NotSelectable === true
                      ? false
                      : true
                  }
                  compactMode={compactMode}
                  statuses={day.Statuses.filter(
                    (status) => status.PersonId === child.ChildId
                  )}
                ></CTBChildRow>
              )}
            </>
          ))}
        </Box>
                  </Box>*/}
      <Stack direction="row" spacing={0.1} sx={{ mb: 1, mt: 1 }}>
        <Box
          onClick={dialogClick}
          id={day.Date.format("MM.DD.YYYY")}
          sx={{
            width: "20%",
            p: 1,
            mr: 0.5,
            borderRadius: "8px",
            backgroundColor: day.VacationPeriodFound
              ? "warning.light"
              : day.OnVacation
              ? "#e57373"
              : day.IsLockedDate
              ? "#dadbe0"
              : moment(day.Date).isBefore(new Date(), "day") === true
              ? "#dadbe0"
              : "white",
            boxShadow: 2,
            filter: day.isLockedDate && "grayscale(50%)",

            border:
              moment(day.Date).isSame(new Date(), "day") === true
                ? "3px solid  #4caf50"
                : "none",
          }}
        >
          <Typography color="textSecondary" fontWeight="bold" sx={{}}>
            {day.Date.format("DD.MM.")}
          </Typography>
          <Typography color="textSecondary" fontSize="small" sx={{}}>
            {day.Date.format("ddd")}
          </Typography>
          <Typography color="textSecondary" fontSize="small" sx={{}}>
            {"vko." + moment(day.Date).isoWeek()}
          </Typography>
          {day.IsLockedDate && <LockIcon sx={{ ml: "1.5", mt: 1 }}></LockIcon>}
        </Box>
        <Box
          onClick={dialogClick}
          sx={{
            width: "80%",
            borderRadius: "8px",
            backgroundColor: "white",
            boxShadow: 2,
            border:
              moment(day.Date).isSame(new Date(), "day") === true
                ? "3px solid  #4caf50"
                : "none",
          }}
        >
          <>
            {day.WeekHoliday !== null && (
              <>
                <Card
                  sx={{
                    bgcolor: "warning.light",
                    width: "95%",
                    mt: 0.5,
                    ml: 1,
                    "&:last-child": { mb: 0.5 },
                    boxShadow: 0,
                  }}
                >
                  <CardContent
                    sx={{
                      p: 0,
                      "&:last-child": { pb: 0 },
                      width: "100%",
                      mt: 0.5,
                      mb: 0.5,
                    }}
                  >
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Chip
                        variant="filled"
                        sx={{
                          mt: 0.5,
                          mb: 0.5,
                          ml: 1,
                        }}
                        size="small"
                        label={
                          <Typography color="textPrimary">
                            {day.WeekHoliday}
                          </Typography>
                        }
                      ></Chip>
                    </Box>
                  </CardContent>
                </Card>
              </>
            )}
          </>
          {day.CareTimeTest.map((child) => (
            <>
              {selectedChildren.includes(child.ChildId) && (
                <CTBChildRow
                  key={day.Date + child.ChildId}
                  day={day}
                  child={child}
                  selectable={
                    day.IsLockedDate === true
                      ? false
                      : day.NotSelectable === true
                      ? false
                      : true
                  }
                  isScrolling={isScrolling}
                  compactMode={compactMode}
                  statuses={day.Statuses.filter(
                    (status) => status.PersonId === child.ChildId
                  )}
                ></CTBChildRow>
              )}
            </>
          ))}
        </Box>
      </Stack>
    </>
  );
};

const CTBChildRow = ({ day, child, statuses, compactMode, isScrolling }) => {
  const [showRealization, setShowRealization] = useState(false);
  const [realizationStart, setRealizationStart] = useState();
  const [realizationEnd, setRealizationEnd] = useState();

  useEffect(() => {
    if (
      moment(day.Date).isBefore(new Date(), "day") === true &&
      statuses.some((status) => status.Type === "I") &&
      statuses.some((status) => status.Type === "O")
    ) {
      setRealizationStart(
        statuses.filter((status) => status.Type === "I")[0].Start
      );
      setRealizationEnd(
        statuses.filter((status) => status.Type === "O")[0].End
      );
      setShowRealization(true);
    }
  }, []);
  return (
    <>
      <Card
        variant="outlined"
        sx={{
          bgcolor: "white",
          width: "95%",
          mt: 0.5,
          ml: 1,
          "&:last-child": { mb: 0.5 },
        }}
      >
        <CardContent
          sx={{
            p: 0,
            "&:last-child": { pb: 0 },
            width: "100%",
            mt: 0.5,
            mb: 0.5,
          }}
        >
          <Stack>
            <Box>
              <Stack direction="row" spacing={2} sx={{ ml: 0.5 }}>
                <Box sx={{ width: "30%" }}>
                  <Chip
                    size={compactMode === true ? "small" : "default"}
                    sx={{
                      color: "black",
                    }}
                    avatar={
                      <Avatar>
                        {isScrolling ? (
                          <FaceRoundedIcon sx={{ color: "white" }} />
                        ) : (
                          <ChildProfilePicture childId={child.ChildId} />
                        )}
                      </Avatar>
                    }
                    label={child.Name.split(/\s+/)[0]}
                  ></Chip>
                </Box>
                <Box sx={{ width: "70%" }}>
                  {statuses.length > 0 && (
                    <StatusChip
                      statuses={statuses}
                      compactMode={compactMode}
                      day={day}
                    ></StatusChip>
                  )}
                </Box>
              </Stack>
            </Box>
            <>
              {showRealization && (
                <Box>
                  <Realization
                    realizationStart={realizationStart}
                    realizationEnd={realizationEnd}
                  ></Realization>
                </Box>
              )}
            </>

            {child.Centres.some((centre) => centre.NursingTimes.length > 0) ===
              true &&
              !showRealization && (
                <Box>
                  <ExistingReservation
                    Centres={child.Centres}
                  ></ExistingReservation>
                </Box>
              )}
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};

const StatusChip = ({ statuses, compactMode, day }) => {
  return (
    <>
      {statuses.some((s) => s.Type === "I") &&
        statuses.every((s) => s.Type !== "O") &&
        moment(day.Date).isSame(new Date(), "day") && (
          <Chip
            size={"small"}
            icon={<LoginIcon />}
            color={"success"}
            label={moment(
              statuses.filter((s) => s.Type === "I")[0].Start
            ).format("HH:mm")}
          ></Chip>
        )}
      {statuses.some((s) => s.Type === "S" || s.Type === "A") && (
        <Chip
          size={"small"}
          icon={
            statuses.filter((s) => s.Type === "S" || s.Type === "A")[0].Type ===
            "S" ? (
              <SickIcon />
            ) : (
              <NightShelterIcon />
            )
          }
          color={"warning"}
          label={
            statuses.filter((s) => s.Type === "S" || s.Type === "A")[0].Name
          }
        ></Chip>
      )}
      {statuses.some((s) => s.Type === "41") && (
        <Chip
          size={"small"}
          sx={{ ml: 1, backgroundColor: "#00b3df" }}
          label={statuses.filter((s) => s.Type === "41")[0].Name}
        ></Chip>
      )}
    </>
  );
};

const Realization = (props) => {
  const [value, setValue] = React.useState([]);
  const [marks, setMarks] = React.useState([]);
  const [shortRes, setShortRes] = React.useState(false);
  const [extraShortRes, setExtraShortRes] = React.useState(false);
  const [error, setError] = React.useState(false);
  useEffect(() => {
    const startAsMinutes = moment
      .duration(moment(props.realizationStart).format("HH:mm"))
      .asMinutes();
    const endAsMinutes = moment
      .duration(moment(props.realizationEnd).format("HH:mm"))
      .asMinutes();

    setValue([startAsMinutes, endAsMinutes]);
    setMarks([
      {
        value: startAsMinutes,
        label: moment()
          .utc()
          .startOf("day")
          .add(startAsMinutes, "minutes")
          .format("HH:mm"),
      },
      {
        value: endAsMinutes,
        label: moment()
          .utc()
          .startOf("day")
          .add(endAsMinutes, "minutes")
          .format("HH:mm"),
      },
    ]);
    setShortRes(endAsMinutes - startAsMinutes < 300 ? true : false);
    setExtraShortRes(endAsMinutes - startAsMinutes < 100 ? true : false);
  }, [props.Centres]);

  return (
    <>
      {moment(value[1]).isBefore(value[0]) ? (
        <>
          <Chip
            size="small"
            color={"warning"}
            label={"virhe kirjauksissa"}
          ></Chip>
        </>
      ) : (
        <Slider
          sx={{
            height: 8,
            borderRadius: 0,
            width: "96%",
            ml: "2%",

            "& .MuiSlider-thumb": {
              backgroundColor: "transparent",
              height: 0,
              width: 0,
            },

            "& .MuiSlider-track": {
              height: 8,
              color: "success.light",
            },
            "& .MuiSlider-rail": {
              color: "#e0e0de",
              opacity: 1,
              height: 8,
            },
            "& .MuiSlider-valueLabel": {
              fontSize: 12,
              fontWeight: "normal",
              top: 28,
              display: "none",
              backgroundColor: "unset",
              color: "black",
              "&:before": {
                display: "none",
              },

              "& *": {
                background: "transparent",
                color: "black",
              },
            },
            "& .MuiSlider-markLabel": {
              color: "black",
              fontSize: 12,
              fontWeight: "normal",
              top: 30,
            },
            "& .MuiSlider-mark": {
              display: "none",
            },
            '& .MuiSlider-markLabel[data-index="0"]': {
              marginLeft: extraShortRes ? "-15px" : shortRes ? "-8px" : "0px",
            },
            '& .MuiSlider-markLabel[data-index="1"]': {
              marginLeft: extraShortRes ? "12px" : shortRes ? "6px" : "0px",
            },
          }}
          value={value}
          marks={marks}
          min={0}
          max={1440}
          disabled
        />
      )}
    </>
  );
};

const ExistingReservation = (props) => {
  const [startTimes, setStartTimes] = useState([]);
  const [endTimes, setEndTimes] = useState([]);
  const [value, setValue] = React.useState([]);
  const [marks, setMarks] = React.useState([]);
  const [shortRes, setShortRes] = useState(false);
  const [extraShortRes, setExtraShortRes] = React.useState(false);
  const [gapInRes, setGapInRes] = React.useState(false);
  const [gapStart, setGapStart] = React.useState();
  const [gapEnd, setGapEnd] = React.useState();
  const [gapStart2, setGapStart2] = React.useState();
  const [gapEnd2, setGapEnd2] = React.useState();

  useEffect(() => {
    props.Centres.forEach((ct, index) => {
      if (ct.NursingTimes.length > 0) {
        ct.NursingTimes.forEach((time, index) => {
          startTimes.push(time.Start);
          endTimes.push(time.End);
        });
      }
    });

    const sortedStarts = startTimes.sort((a, b) => moment(a) - moment(b));
    const sortedEnds = endTimes.sort((a, b) => moment(a) - moment(b));
    let gap = false;
    let gapStartTest = 0;
    let gapEndTest = 0;
    sortedEnds.forEach((end, index) => {
      if (
        moment(end).isSame(sortedStarts[index + 1]) === false &&
        index !== sortedEnds.length - 1
      ) {
        setGapInRes(true);
        gap = true;
        setGapStart(moment.duration(moment(end).format("HH:mm")).asMinutes());
        gapStartTest = moment.duration(moment(end).format("HH:mm")).asMinutes();
        setGapEnd(
          moment
            .duration(moment(sortedStarts[index + 1]).format("HH:mm"))
            .asMinutes()
        );
        gapEndTest = moment
          .duration(moment(sortedStarts[index + 1]).format("HH:mm"))
          .asMinutes();
      }
    });
    {
      /*const startAsMinutes = moment
      .duration(moment(sortedStarts[0]).format("HH:mm"))
      .asMinutes();
    const endAsMinutes = moment
      .duration(moment(sortedEnds[sortedEnds.length - 1]).format("HH:mm"))
  .asMinutes();*/
    }

    const timePortionStart = sortedStarts[0].split("T")[1]; // Extracting the time portion

    const [hoursStart, minutesStart] = timePortionStart.split(":").map(Number);
    const startAsMinutes = hoursStart * 60 + minutesStart;

    const timePortionEnd = sortedEnds[sortedEnds.length - 1].split("T")[1]; // Extracting the time portion

    const [hours, minutes] = timePortionEnd.split(":").map(Number);
    const endAsMinutes = hours * 60 + minutes;

    setValue([startAsMinutes, endAsMinutes]);
    setMarks([
      {
        value: startAsMinutes,
        label: moment()
          .utc()
          .startOf("day")
          .add(startAsMinutes, "minutes")
          .format("HH:mm"),
      },

      {
        value: endAsMinutes >= 1439 ? 1440 : endAsMinutes,
        label:
          endAsMinutes >= 1439
            ? "24:00"
            : moment()
                .utc()
                .startOf("day")
                .add(endAsMinutes, "minutes")
                .format("HH:mm"),
      },
    ]);

    setShortRes(endAsMinutes - startAsMinutes < 300 ? true : false);
    setExtraShortRes(endAsMinutes - startAsMinutes < 100 ? true : false);
  }, [props.Centres]);

  // 270 480 600 750 Välin/eo palkin laskenta: Loppuaika * 60 - alkuaika * 60 = varauksen kesto.
  // kesto - Välin alkuaika * 60 / kesto // kesto - välin loppuaika * 60 / kesto > prosenteista gradientti.

  return (
    <>
      {gapInRes ? (
        <Slider
          sx={{
            height: 8,
            borderRadius: 0,
            width: "96%",
            ml: "2%",
            mb: 0,

            "& .MuiSlider-thumb": {
              backgroundColor: "transparent",
              height: 0,
              width: 0,
            },

            "& .MuiSlider-track": {
              height: 8,
              border: 0,
              background:
                "linear-gradient(90deg, rgba(0,179,223,1) 0%, rgba(0,179,223,1)" +
                ((gapStart - value[0]) / (value[1] - value[0])) * 100 +
                "%, rgba(224,224,222,1)" +
                ((gapStart - value[0]) / (value[1] - value[0])) * 100 +
                "%, rgba(224,224,222,1)" +
                ((gapEnd - value[0]) / (value[1] - value[0])) * 100 +
                "%, rgba(0,179,223,1)" +
                ((gapEnd - value[0]) / (value[1] - value[0])) * 100 +
                "%)",
            },
            "& .MuiSlider-rail": {
              color: "#e0e0de",
              opacity: 1,
              height: 8,
            },
            "& .MuiSlider-valueLabel": {
              fontSize: 12,
              fontWeight: "normal",
              top: 28,
              display: "none",
              backgroundColor: "unset",
              color: "black",
              "&:before": {
                display: "none",
              },

              "& *": {
                background: "transparent",
                color: "black",
              },
            },
            "& .MuiSlider-markLabel": {
              color: "black",
              fontSize: 12,
              fontWeight: "normal",
              top: 30,
            },
            "& .MuiSlider-mark": {
              display: "none",
            },
            '& .MuiSlider-markLabel[data-index="0"]': {
              marginLeft: extraShortRes ? "-15px" : shortRes ? "-8px" : "0px",
            },
            '& .MuiSlider-markLabel[data-index="1"]': {
              marginLeft: extraShortRes ? "12px" : shortRes ? "6px" : "0px",
            },
          }}
          value={value}
          marks={marks}
          min={0}
          max={1440}
          disabled
        />
      ) : (
        <Slider
          sx={{
            height: 8,
            borderRadius: 0,
            width: "96%",
            ml: "2%",
            mb: 0,

            "& .MuiSlider-thumb": {
              backgroundColor: "transparent",
              height: 0,
              width: 0,
            },

            "& .MuiSlider-track": {
              height: 8,
              border: 0,
              color: "#00b3df",
            },
            "& .MuiSlider-rail": {
              color: "#e0e0de",
              opacity: 1,
              height: 8,
            },
            "& .MuiSlider-valueLabel": {
              fontSize: 12,
              fontWeight: "normal",
              top: 28,
              display: "none",
              backgroundColor: "unset",
              color: "black",
              "&:before": {
                display: "none",
              },

              "& *": {
                background: "transparent",
                color: "black",
              },
            },
            "& .MuiSlider-markLabel": {
              color: "black",
              fontSize: 12,
              fontWeight: "normal",
              top: 30,
            },
            "& .MuiSlider-mark": {
              display: "none",
            },
            '& .MuiSlider-markLabel[data-index="0"]': {
              marginLeft: extraShortRes ? "-15px" : shortRes ? "-8px" : "0px",
            },
            '& .MuiSlider-markLabel[data-index="1"]': {
              marginLeft: extraShortRes ? "12px" : shortRes ? "6px" : "0px",
            },
          }}
          value={value}
          marks={marks}
          min={0}
          max={1440}
          disabled
        />
      )}
    </>
  );
};

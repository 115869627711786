import React, { useState, useEffect } from "react";
import {useStateValue} from "../../State";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ContentBox from "../../Components/ContentBox";
import Languages from "../../translations";
import CircularProgress from "@mui/material/CircularProgress";
import Card from "@mui/material/Card";
import { useAuth } from "../../Auth/AuthProvider";
import StrongIdentificationRedirectView from "./Components/StrongIdentificationRedirectView";
import ForgottenPasswordStartingView from "./Components/ForgottenPasswordStartingView";

const emailValidation = (email) => {
  if (/(?!.*\.\..*)^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/.test(email.toUpperCase())) {
    return true;
  }
  else {
    return false;
  }
} 

const ForgottenPassword = (props) => {

  const {actions} = useStateValue();
  const { state: { loginEmail } } = useStateValue();

  const [email, setEmail] = useState("");
  const [emailWarning, setEmailWarning] = useState(false);

//if kunta-asetus is changed while the app has been downloaded and it is not refreshed, this will not react to the change. need a refresh.
  const { loginSettings, loading } = useAuth();
  
  const [view, setView] = useState("loading");
  
  useEffect(() => {

    if (!loading) {
      if (typeof loginSettings.StrongIdentification === "undefined" || typeof loginSettings.Password === "undefined") {
        props.history.push("/");
      }
      else if (loginSettings.StrongIdentification) {
        setView("redirectToStrongIdentification");
      }
      else {
        setView("e-mailrecovery");
      }
    }
  }, [loginSettings, loading, props.history]);

  const sendForgottenPasswordRequest = () => {

      const emailValidationOk = emailValidation(email);
      
      if (emailValidationOk) {
        actions.triggerPasswordResetRequest(
          {
            EmailAddress: email,
          }
        );
      }
      else {
        if (emailValidationOk !== true) {
          setEmailWarning(true);
        }
        else {
          setEmailWarning(false);
        }
      }

  }
  

  const resetThisPage = () => {
    setEmailWarning(false);
    actions.triggerForgottenPasswordPageReset();
  }

  const resetPageAndRedirect = () => {
    actions.triggerForgottenPasswordPageReset();
    props.history.push("/login");
  }

  const goToLogin = () => {
    props.history.push("/login");
  }

  if (view === "loading") {
    return(<div data-testid="ForgottenPasswordAuthLoading">{Languages.generalLoadingTitle}</div>);
  }

  if (view === "redirectToStrongIdentification" ) {
    return(
      <StrongIdentificationRedirectView
        goToLogin={goToLogin}
      />
    );
  }

  if (view === "e-mailrecovery") {

    return (

      <React.Fragment>
        <div className="Login textCenter">
        <ContentBox header={Languages.passwordReset}>
        
        {loginEmail.passwordReset.status !== "PASSWORD_RESET_SUCCESS" 
        && loginEmail.passwordReset.status !== "PASSWORD_RESET_FAILED" &&
        loginEmail.passwordReset.status !== "LOADING_PASSWORD_RESET" ?
          <ForgottenPasswordStartingView
            setEmail={(emailaddr) => setEmail(emailaddr)}
            sendForgottenPasswordRequest={sendForgottenPasswordRequest}
            resetPageAndRedirect={resetPageAndRedirect}
            email={email}
            emailWarning={emailWarning}
          />
          : null
        }

        { loginEmail.passwordReset.status === "LOADING_PASSWORD_RESET" ?
        
          <Card>
            <Typography>{Languages.generalLoadingTitle}</Typography>
            <br/>
            <CircularProgress data-testid="ForgottenPasswordCircularProgress" color="primary"/>
          </Card>
          : null
        }

        { loginEmail.passwordReset.status === "PASSWORD_RESET_SUCCESS" ?
          <React.Fragment>
            <Typography> {Languages.passwordResetInfo1} </Typography>
            <Typography> {Languages.passwordResetInfo2} </Typography>
            <br />
            <Button
              onClick={() => resetPageAndRedirect()}
              color="primary"
              variant="outlined"
              id="test_toLoginPageButton"
            >{Languages.toTheLoginPage}
            </Button>
          </React.Fragment>
          : null
        }
        { /* Tämä ei koskaa tapahdu koska be palauttaa ok onnistu */}
        { loginEmail.passwordReset.status === "PASSWORD_RESET_FAILED" ?
          <React.Fragment>
            <Typography paragraph={true}> {Languages.requestFailed + ". "}</Typography>
            <Typography paragraph={true}> {loginEmail.passwordReset.errorType.LocalizedErrorDetails} </Typography>

            { 
            <React.Fragment>
              <Button
                style={{marginTop: "15px"}}
                color="primary"
                variant="outlined"
                onClick={() => resetThisPage()}
              >{Languages.tryAgainQ}</Button>
              <Button
                style={{marginTop: "15px"}}
                onClick={() => resetPageAndRedirect()}
                color="primary"
                variant="outlined"
                id="test_toLoginPageButton"
              >{Languages.toTheLoginPage}</Button>
            </React.Fragment>
            }

          </React.Fragment> 
          : null
        }

        </ContentBox>
        </div>
      </React.Fragment>
  );
}
}

export default ForgottenPassword;

//"Please check that you have entered the child's date of birth and your e-mail address correctly. The e-mail address must be the same as in the day care application. If the problem persists, please contact the kindergarten."
import React, { useState, useEffect } from "react";
import {useStateValue} from "../../../../State";

import Moment           from "moment";

import Languages        from "../../../../translations";

import Accordion        from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon   from '@mui/icons-material/ExpandMore';
import Typography       from "@mui/material/Typography";

import CommentsReadOnly from '../../../Vasu/CommentsReadOnly';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  nobottomline: {
    borderBottom: "none",
    color: "black",
    backgroundColor: "#ffffff",
    flex: "0 0 100%"
  },
}));

const SupportTable1 = (props) => {

  const classes = useStyles();

  const { Form, callback, closeView } = props;

  const { state/*, actions*/ } = useStateValue();
  const FormT1 = Form === 0 ? state.leops.Table3.Form1.Table[0] :
                 Form === 1 ? state.leops.Table3.Form2.Table[0]:
                 Form === 2 ? state.leops.Table3.Form3.Table[0] : 
                              state.leops.Table3.Form4.Table[0];

  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
    callback("TABLE1");
  }

  /*console.log("SupportTable1. MOUNT",
              //"\n state.leops.Table3: ",state.leops.Table3,  
              "\n Form: ", Form,
              "\nFormT1: ", FormT1);*/

  /*var Slides = [{Id: 0, Text: "Table 1. Row 1"},
                {Id: 1, Text: "Table 1. Row 2"},
                {Id: 1, Text: "Table 1. Row 3"}];*/

  useEffect(() => {
    //console.log("Table1. useEffect. Close view");
    setExpanded(false);
  },[closeView]); 

  return (
      <Accordion className="painikkeet"
                 style={{ backgroundColor: "#4377BA"/*, color: "black"*/ }} // sininen, oletus valkoinen teksti
                 //disabled = {item.Saved === "" ? true: false}
                 expanded={expanded}
                 onChange={() => toggleExpanded()}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel3a-content"
                          id="test_Leops_SupportTable1">
          <Typography className="heading"> {Form === 0 || Form === 1 ? Languages.AssessmentChildGrowthAndLearning : Languages.LeopsFormTitle2}</Typography>
        </AccordionSummary>
        <div className={classes.nobottomline}>
            <AccordionDetails>
              <CommentsReadOnly Title1  = {<Typography color="textSecondary" sx={{textAlign:"left", fontWeight: 'Bold', fontSize: 14, color: "#424242", pt:1, ml:1}}>{Form === 2 || Form === 3 ? Languages.ChildStrengthsAndIterests : Languages.OverallSituationChildGrowthAndLearning}</Typography>}
                                Title2  = {null} // kommentoja h6 riville
                                Title3  = {null}
                                BgColor = {classes.nobottomline.backgroundColor} // harmaa tausta
                                Data    = {{Text: FormT1.TableRow[0].RowItem[0].Editor + "\n" + FormT1.TableRow[0].RowItem[0].RowText}}>
              </CommentsReadOnly>
            </AccordionDetails>

            <AccordionDetails>
              <CommentsReadOnly Title1  = {<Typography color="textSecondary" sx={{textAlign:"left", fontWeight: 'Bold', fontSize: 14, color: "#424242", pt:1, ml:1}}>{Form === 0 ? Languages.GeneralSupportAndAssessmentImpact :
                                           Form === 1 ? Languages.EnhancedSupportEvaluation : Languages.LeopsFormTitle2_1}</Typography>}
                                Title2  = {null}
                                Title3  = {null}
                                BgColor = {classes.nobottomline.backgroundColor} 
                                Data    = {{Text: FormT1.TableRow[1].RowItem[0].Editor + "\n" + FormT1.TableRow[1].RowItem[0].RowText}}>
              </CommentsReadOnly>
            </AccordionDetails>

            <AccordionDetails>
              <CommentsReadOnly Title1  = {<Typography color="textSecondary" sx={{textAlign:"left", fontWeight: 'Bold', fontSize: 14, color: "#424242", pt:1, ml:1}}>{Form === 2 || Form === 3 ? Languages.LeopsFormTitle2_2 : Languages.ChildStrengthsAndInterestsLearningNeeds}</Typography>}
                                Title2  = {null}
                                Title3  = {null}
                                BgColor = {classes.nobottomline.backgroundColor} 
                                Data    = {{Text: FormT1.TableRow[2].RowItem[0].Editor + "\n" + FormT1.TableRow[2].RowItem[0].RowText}}>
              </CommentsReadOnly>
            </AccordionDetails>
            </div>

      </Accordion>
  );
}

export default SupportTable1;
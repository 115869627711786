
import { ApiBase } from "../internal";
// import { createMockResponse } from ".";
// import { createLoginOptionsResponse } from "./MockResponse";
//import * as Comlink from "comlink";

export class ServerApi extends ApiBase {
  constructor(httpClient) {
    super();
    
    if (httpClient) {
      this.httpClient = httpClient
    }
    //this.httpClient = getHttpClient();
  }

  async login(username, password, firebaseInfo) {
    //const clientToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6ImRhaXN5ZmFtaWx5IiwibmFtZWlkIjoiZGFpc3lmYW1pbHkiLCJuYmYiOjE1NTk2NDEyNzEsImV4cCI6MjUwNTcyMTI3MSwiaWF0IjoxNTU5NjQxMjcxfQ.VWXu67MZJMYIAIj6nNYAv4cfkp1ulzLnfwjcWzUCCpc";
    const data = { Username: username, Password: password };
    if (firebaseInfo) {
      data.FirebaseTokenInfo = {
        FirebaseToken: firebaseInfo.firebaseToken,
        DeviceType: firebaseInfo.deviceType,
        DeviceId: firebaseInfo.deviceId,
      }
    }

    const loginResp = await this.httpClient.post("/auth/login", data, {
      headers: {
        "content-type": "application/json; charset=utf-8",
      }
    });

    return loginResp;
  }

  async tokenLogin(token) {
    const loginResp = await this.httpClient.post("/auth/login", {}, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      }
    });

    return loginResp;
  }
  
  async logout(reason) {
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
      }
    };
    
    const data = {
      personID: sessionStorage.getItem('personID'),
      Reason: reason
    };

    const callPromise = this.httpClient.post("/auth/logout?samlBindingType=REDIRECT", data, axiosConfig);

    const resp = await callPromise;
    //super.invalidateCookie();
    return resp;
  }

  async getBulletinBoardByParent() {
    const response = await this.httpClient.get("/bulletinboards", {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        type: "parent"
      },
    });

    return response;
  }

  // Not used
  async getBulletinBoardByChild(personId) {
    const response = await this.httpClient.get("/bulletinboards/" + personId, {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        type: "child"
      },
    });

    return response;
  }

  async loadDiscussionTimes() {
    
    const data = {};

    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
      }
    };

    let responseData;
    try {
      const response = await this.httpClient.get("/discussion/discussiontimes", data, axiosConfig);

      responseData = {
        status: 200,
        data: response.data,
      };

      return responseData;
    }
    catch (error) {

      responseData = {
        status: error.response === undefined || error.response.status === undefined ? 502 : error.response.status,

      };
      return responseData;
    }
  }

  async loadDiscussionSlots() {
    const data = {};
    const axiosConfig = {
      headers: {"Content-Type": "application/json"}
    };
    let responseData;
    try {
      const response = await this.httpClient.get("/discussion/bookabletimeslots", data, axiosConfig);
      responseData = {
        status: 200,
        data: response.data,
      };
      return responseData;
    }
    catch (error) {
      responseData = {
        status: error.response === undefined || error.response.status === undefined ? 502 : error.response.status,
      };
      return responseData;
    }
  }

  async saveDiscussionBooking(data) {
    const axiosConfig = {
      headers: {"Content-Type": "application/json"}
    };
    let responseData;
    try {
      const response = await this.httpClient.post("/discussion/discussionbooking", data, axiosConfig);
      responseData = {
        status: 200,
        data: response.data
      };
      return responseData;
    }
    catch (error) {
      responseData = {
        status: error.response === undefined || error.response.status === undefined ? 502 : error.response.status,
        data: error.response.data
      };
      return responseData;
    }
  }

  async deleteDiscussionBooking(data) {
    console.log("dataaaa deletessä", data);
    
    const axiosConfig = {
      headers: {"Content-Type": "application/json"}
    };
    
    let responseData;
    try {
      const response = await this.httpClient.delete("/discussion/discussionbooking/" + data, axiosConfig);
      responseData = {
        status: 200,
        data: response.data
      };
      return responseData;
    }
    catch (error) {
      responseData = {
        status: error.response === undefined || error.response.status === undefined ? 502 : error.response.status,
        data: error.response.data
      };
      return responseData;
    }
  }

  async editDiscussionBooking(data) {
    const axiosConfig = {
      headers: {"Content-Type": "application/json"}
    };
    let responseData;
    try {
      const response = await this.httpClient.put("/discussion/updateDiscussionTime", data, axiosConfig);
      responseData = {
        status: 200,
        data: response.data
      };
      return responseData;
    }
    catch (error) {
      responseData = {
        status: error.response === undefined || error.response.status === undefined ? 502 : error.response.status,
        data: error.response.data
      };
      return responseData;
    }
  }

  async getDiscParentsForEditing(data) {
    const axiosConfig = {
      headers: {"Content-Type": "application/json"}
    }
    let responseData
    try{
      const response = await this.httpClient.get("/discussion/getDiscParentsForEditing/" + data, axiosConfig)
      responseData = {
        status: 200,
        data: response.data
      }
      return responseData
    }
    catch (error) {
      responseData = {
        status: error.response === undefined || error.response.status === undefined ? 502 : error.response.status,
        data: error.response.data
      };
      return responseData;
    }

  }

  async loadOneChildDiscussions(data) {
    const axiosConfig = {
      headers: {"Content-Type": "application/json"}
    };
    let responseData;
    try {
      const response = await this.httpClient.get("/discussion/childsbookings/" + data, {}, axiosConfig);
      responseData = {
        status: 200,
        data: response.data,
      };
      return responseData;
    }
    catch (error) {
      responseData = {
        status: error.response === undefined || error.response.status === undefined ? 502 : error.response.status,
      };
      return responseData;
    }
  }

  async loadDiscussionNotificationStats(){
    const data = {};
    const axiosConfig = {
      headers: {"Content-Type": "application/json"},
    };
    let responseData;
    try {
      const response = await this.httpClient.get("/discussion/getDiscussionNotificationStats", data, axiosConfig);
      responseData = {
        status: 200,
        data: response.data,
      };
      return responseData;
    }
    catch (error) {
      responseData = {
        status: error.response === undefined || error.response.status === undefined ? 502 : error.response.status,
      };
      return responseData;
    }
  }
  
  async loadDiscussionTimeSelectionStatus(data){
    const axiosConfig = {
      headers: {"Content-Type": "application/json"}
    }
    let responseData
    try {
      const response = await this.httpClient.post("/discussion/getDiscussionTimeSelectionStatus", data, axiosConfig)
      responseData = {
        status:200,
        data: response.data,
      }
    } catch (error) {
      responseData = {
        status:error.response === undefined || error.response.status === undefined ? 502 : error.response.status
      }
    }
    return responseData
  }

  

  async loadFolders(groups, childId, type, sorting) {
    try {
      const data = {
        groupIds: groups,
        ChildId: childId,
        type: type,
        sorting: sorting
      };
      const response = await this.httpClient.post("/GrowthFolder/Folders", data, {
        headers: {
          "Content-Type": "application/json",
          //"Accept-Language": sessionStorage.getItem('language') === "en" ? "en-US" : sessionStorage.getItem('language')
        },
        /*params: {
          personId: sessionStorage.getItem('personID'),
        }*/
      });

      let responseData = {
        status: response.status,
        data: response.data,
      }
      return responseData;
    }
    catch(error) {
      let responseData = {
        status: error.response === undefined ? 502 : 
                  error.response.status === undefined ? 502 : 
                    error.response.status
      }
      return responseData;
    }
  }

  
  async saveDiscussionTimeSelectionStatus(data){
    const axiosConfig = {
      headers: {"Content-Type": "application/json"},
    }
    let responseData
    try {
      const response = await this.httpClient.post("/discussion/saveDiscussionTimeSelectionStatus",{discussionId: data}, axiosConfig)
      responseData = {
        status: 200,
        data: response.data
      }
    } catch (error) {
      responseData = {
        status:error.response === undefined || error.response.status === undefined ? 502 : error.response.status,
        data: error.response.data
      }
    }
    return responseData
  }

  async deleteDiscussionTimeSelectionStatuses(){
    const axiosConfig = {
      headers: {"Content-Type": "application/json"},
    }
    let responseData
    try {
      const response = await this.httpClient.delete("/discussion/deleteActiveDiscussionTimeSelectionStatus", axiosConfig)
      responseData = {
        status: 200,
        data: response.data
      }
    } catch (error) {
      responseData = {
        status: error.response === undefined || error.response.status === undefined ? 502 : error.response.status,
        data: error.response.data
      }
    }
    return responseData
  }

  async loadImages(childId, folder) {
    try {
      const response = await this.httpClient.get("/GrowthFolder/Images/" + childId + "/" + folder, {
        headers: {
          "Content-Type": "application/json",
          //"Accept-Language": sessionStorage.getItem('language') === "en" ? "en-US" : sessionStorage.getItem('language')
        },
        /*params: {
          personId: sessionStorage.getItem('personID'),
        }*/
      });

      let responseData = {
        status: response.status,
        data: response.data,
      }
      return responseData;

    }
    catch(error) {
      let responseData = {
        status: error.response === undefined ? 502 : 
                  error.response.status === undefined ? 502 : 
                    error.response.status
      }
      return responseData;
    }
  }

  async loadTaskThumbnail(childId, folder) {
    try {
      const response = await this.httpClient.get("/GrowthFolder/TaskImages/" + childId + "/" + folder, {
        headers: {
          "Content-Type": "application/json",
          //"Accept-Language": sessionStorage.getItem('language') === "en" ? "en-US" : sessionStorage.getItem('language')
        },
        /*params: {
          personId: sessionStorage.getItem('personID'),
        }*/
      });

      let responseData = {
        status: response.status,
        data: response.data,
      }
      return responseData;

    }
    catch(error) {
      let responseData = {
        status: error.response === undefined ? 502 : 
                  error.response.status === undefined ? 502 : 
                    error.response.status
      }
      return responseData;
    }
  }

  async loadDownloadImages(data) {
    try {
      const response = await this.httpClient.post("/GrowthFolder/DownloadImages/", data, {
        headers: {
          "Content-Type": "application/json",
          //"Accept-Language": sessionStorage.getItem('language') === "en" ? "en-US" : sessionStorage.getItem('language')
        },
        /*params: {
          personId: sessionStorage.getItem('personID'),
        }*/
      });

      let responseData = {
        status: response.status,
        data: response.data,
      }
      return responseData;

    }
    catch(error) {
      let responseData = {
        status: error.response === undefined ? 502 : 
                  error.response.status === undefined ? 502 : 
                    error.response.status
      }
      return responseData;
    }
  }

  async loadFullImage(ChildId, Id) {
    try {
      const response = await this.httpClient.get("/GrowthFolder/FullImage/" + ChildId + "/" + Id, {
        headers: {
          "Content-Type": "application/json",
          //"Accept-Language": sessionStorage.getItem('language') === "en" ? "en-US" : sessionStorage.getItem('language')
        },
        /*params: {
          personId: sessionStorage.getItem('personID'),
        }*/
      });

      let responseData = {
        status: response.status,
        data: response.data,
      }
      return responseData;

    }
    catch(error) {
      let responseData = {
        status: error.response === undefined ? 502 : 
                  error.response.status === undefined ? 502 : 
                    error.response.status
      }
      return responseData;
    }
  }

  async loadDFSettings() {
    const response = await this.httpClient.get("settings/getDFSettings/", {
      headers: {
        "Content-Type": "application/json",
        
      }
    })

    let responseData = {
      status: response.status,
      data: response.data,
    }

    return responseData; 
  }

  async previewFile(fileId, type, FileMimeType) {
    // note: both bulletin board and messaging use this and vasu/leops
    const data = { "FileId": fileId, "FileType": type, "FileMimeType": FileMimeType};
    console.log(FileMimeType);

    if(FileMimeType === "application/pdf")
    {
      const response = await this.httpClient.post("/files/previewFile", data, {
        headers: {
          "Content-Type": "application/pdf",
          "Content-Disposition": "attachment;filename=<defaultName>",
          //"Accept": "application/json",

        },
      });
      console.log("ladataas peedeeäffää");
      console.log("Tässä response" + response);
      console.log(response.data);
      console.log(response.headers);
      //response.data =  `data:${FileMimeType};base64,${response.data}`;
      console.log(response.data);
      return response.data;
    }
    
    else {
    const response = await this.httpClient.post("/files/previewFile", data, {
      headers: {
        "Content-Type": "application/json",
        //"Accept": "application/json"
      },
    });
    //console.log(reponse.data);
    console.log(response);

    return response.data;
  }
}
  async downloadFile(fileId, type, FileMimeType) {
    // note: both bulletin board and messaging use this and vasu/leops
    const data = { "FileId": fileId, "FileType": type, "FileMimeType": FileMimeType};

    if(FileMimeType === "application/pdf")
    {
      const response = await this.httpClient.post("/files/downloadFile", data, {
        headers: {
          "Content-Type": "application/pdf",
          "Content-Disposition": "attachment;filename=<defaultName>",

        },
      });
      
      //response.data =  `data:${FileMimeType};base64,${response.data}`;
      return response;
    }
    
    else {
    const response = await this.httpClient.post("/files/downloadFile", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    //console.log(reponse.data);

    return response;
  }
  }
  async downloadFile2(fileId, type) {
    // messaging uses this as well
    try {
    const data = { "FileId": fileId, "FileType": type };

    const response = await this.httpClient.post("/files/downloadFile", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    let responseData = {
      status: response.status,
      data: response.data
    }

    return responseData;
    }
    catch(error) {
      let responseData = {
        status: error.response === undefined ? 502 : 
                  error.response.status === undefined ? 502 : 
                    error.response.status
      }
      return responseData;
    } 
  }

  async downloadThumbnail(fileId,type){
    try {
      const data = { "FileId": fileId, "FileType": type,  };
  
      const response = await this.httpClient.post("/files/downloadThumbnail", data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
  
      let responseData = {
        status: response.status,
        data: response.data
      }
  
      return responseData;
      }
      catch(error) {
        let responseData = {
          status: error.response === undefined ? 502 : 
                    error.response.status === undefined ? 502 : 
                      error.response.status
        }
        return responseData;
      } 
  } 

  async getSummary(payload) {
    try {
        const response = await this.httpClient.get("/nursingtimes/getSummary?start=" + payload.Start + "&end=" + payload.End, {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": (sessionStorage.getItem('language') === "en" ? "en-US" : sessionStorage.getItem('language'))
        },
      });

      let responseData = {
        status: response.status,
        data: response.data
      }
      return responseData;
    }
    catch(error) {
      let responseData = {
        status: error.response === undefined ? 502 : 
                  error.response.status === undefined ? 502 : 
                    error.response.status
      }
      return responseData;
    }
  }
  
  async loadChildFilesList(childId) {
    const response = await this.httpClient.get("/files/getfilelist/" + childId, {
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": (sessionStorage.getItem('language') === "en" ? "en-US" : sessionStorage.getItem('language'))
      }
    })

    return response;
  }

  async getcentreinfos(payload) {
    
    try {
      
      if (payload === null) {

        const response = await this.httpClient.get("/centre/getcentreinfos", {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": (sessionStorage.getItem('language') === "en" ? "en-US" : sessionStorage.getItem('language'))
          }
        });

        let responseData = {
          status: response.status,
          data: response.data
        }

        return responseData;
      }

      else {

        const response = await this.httpClient.get("/centre/getcentreinfos?childId=" + payload, {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": (sessionStorage.getItem('language') === "en" ? "en-US" : sessionStorage.getItem('language'))
          }
        });

        let responseData = {
          status: response.status,
          data: response.data
        }

        return responseData;
      }
    }
    catch(error) {
      let responseData = {
        status: error.response === undefined ? 502 : 
                  error.response.status === undefined ? 502 : 
                    error.response.status,
        /*message: error.response.data,*/
      }
      return responseData;
    }
     
  }
  
  async getMunicipalPermissions(childId) {
    
    try {

      const response = await this.httpClient.get("/municipalpermissionquestions/child/" + childId, {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": (sessionStorage.getItem('language') === "en" ? "en-US" : sessionStorage.getItem('language'))
          }
        });

        let responseData = {
          status: response.status,
          data: response.data
        }

        return responseData;
    }
    catch(error) {
      let responseData = {
        status: error.response === undefined ? 502 : 
                  error.response.status === undefined ? 502 : 
                    error.response.status,
        /*message: error.response.data,*/
      }
      return responseData;
    }


  }

  async postChildPermPermissions(data) {
    console.log("ServerApi data", data);
    try {
      const response = await this.httpClient.post("/municipalpermissionquestions/childpermission/", {ChildPermissionsToSave: data});

      let responseData = {
        status: response.status,
        data: response.data
      }

      return responseData;
    }
    catch(error) {
      let responseData = {
        status: error.response === undefined ? 502 : 
                  error.response.status === undefined ? 502 : 
                    error.response.status,
        /*message: error.response.data,*/
      }
      return responseData;
    }
  }

  async getGuardianConsents() {
    try {
    const response = await this.httpClient.get("/municipalpermissionquestions/parent/", {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": (sessionStorage.getItem('language') === "en" ? "en-US" : sessionStorage.getItem('language'))
        }
      });

      let responseData = {
        status: response.status,
        data: response.data
      }

      return responseData;
    }
    catch(error) {
      let responseData = {
        status: error.response === undefined ? 502 : 
                  error.response.status === undefined ? 502 : 
                    error.response.status,
        /*message: error.response.data,*/
      }
      return responseData;
    }
  }

  async postGuardianConsents(data) {
    console.log("ServerApi data", data);
    try {
    const response = await this.httpClient.post("/municipalpermissionquestions/parentconsent/", {ParentConsentsToSave: data});

      let responseData = {
        status: response.status,
        data: response.data
      }

      return responseData;
    }
    catch(error) {
      let responseData = {
        status: error.response === undefined ? 502 : 
                  error.response.status === undefined ? 502 : 
                    error.response.status,
        /*message: error.response.data,*/
      }
      return responseData;
    }
  }

  async loadPermissionQueries() {
    //console.log("ServerApi data", data);
    try {
    const response = await this.httpClient.get("/queryperm/list");

      let responseData = {
        status: response.status,
        data: response.data
      }

      return responseData;
    }
    catch(error) {
      let responseData = {
        status: error.response === undefined ? 502 : 
                  error.response.status === undefined ? 502 : 
                    error.response.status,
        /*message: error.response.data,*/
      }
      return responseData;
    }
  }

  async loadSinglePermissionQuery(permissionId) {
    //console.log("ServerApi data", permissionId);
    try {
    const response = await this.httpClient.get("/queryperm/" + permissionId);

      let responseData = {
        status: response.status,
        data: response.data
      }

      return responseData;
    }
    catch(error) {
      let responseData = {
        status: error.response === undefined ? 502 : 
                  error.response.status === undefined ? 502 : 
                    error.response.status,
        /*message: error.response.data,*/
      }
      return responseData;
    }
  }

  async postSinglePermissionQuery(permission) {
    console.log("ServerApi data", permission);
    try {
    const response = await this.httpClient.post("/queryperm", permission);

      let responseData = {
        status: response.status,
        data: response.data
      }

      return responseData;
    }
    catch(error) {
      let responseData = {
        status: error.response === undefined ? 502 : 
                  error.response.status === undefined ? 502 : 
                    error.response.status,
        /*message: error.response.data,*/
      }
      return responseData;
    }
  }


  async getVacationStatus() {
    try {
      const response = await this.httpClient.get("/nursingtimes/vacationstatus");

      let responseData = {
        status: response.status,
        data: response.data
      }

      return responseData;
    }
    catch(error) {
      let responseData = {
        status: error.response === undefined ? 502 : 
                  error.response.status === undefined ? 502 : 
                    error.response.status,
      }
      return responseData;
    }
  }
  // api/nursingtimes/vacationstatus

  async resetPassword(payload) {
    const langa = sessionStorage.getItem('language') === "en" ? "en-US" : sessionStorage.getItem('language');

    const data = {
      CreateNew: false,
      EmailAddress: payload.EmailAddress,
    };
        const response = await this.httpClient.post("auth/loginEmail", data, {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": langa
        },
      });

      let responseData = {
        status: response.status,
        data: response.data
      }
      return responseData;
  }

  async orderUsername(payload) {

    const langa = sessionStorage.getItem('language') === "en" ? "en-US" : sessionStorage.getItem('language');
  
    const data = {
      CreateNew: true,
      EmailAddress: payload.EmailAddress,
      ChildBirthday: payload.ChildBirthday,
    };
    try {
        const response = await this.httpClient.post("auth/loginEmail", data, {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": langa
        },
      });

      let responseData = {
        status: response.status,
        data: response.data
      }
      return responseData;
    }
    catch(error) {
      let responseData = {
        status: error.response === undefined ? 502 : 
                  error.response.status === undefined ? 502 : 
                    error.response.status,
        message: error.response.data,
      }
      return responseData;
    }
  }

  async loadLoginOptions() {
    const response = await this.httpClient.post("/auth/checkloginoptions");

    return response;
  }

  async getSummaryTable(payload) {
    const data = {
      personid: payload.childId,
      start: payload.timespan.start,
      end: payload.timespan.end
    }
    const response = await this.httpClient.get("/nursingtimes/getSummaryTable?personId=" + payload.childId + "&start=" + payload.timespan.start + "&end=" + payload.timespan.end, {
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": (sessionStorage.getItem('language') === "en" ? "en-US" : sessionStorage.getItem('language'))
      }
    });
    return response;
  }

  /*
  payload: {
    notificationsSettings: {
      emailEnabled: true/false,
      pushEnabled: true/false
    }
  }
  */
  async savePersonSettings(personId, payload) {
    const response = await this.httpClient.post(`/persons/${personId}/settings`, payload);

    return response;
  }

  async getPersonSettings(personId, personType = 'parent') {
    const response = await this.httpClient.get(`/persons/${personId}/settings?personType=${personType}`);

    return response;
  }
}

export const getMockPersonSettingsResponse = (email = 'test@example.com', personId = '123-123-123') => {
  return JSON.parse(`
  [
    {
      "NotificationsSettings": {
        "EmailEnabled": true,
        "SmsEnabled": false,
        "PushEnabled": true
      },
      "PersonId": "${personId}",
      "WorkEmail": null,
      "HomeEmail": "${email}",
      "PushTokens": [
        {
          "Token": "testi-token-1",
          "DeviceType": "android"
        },
        {
          "Token": "testi-token-2",
          "DeviceType": "android"
        }
      ]
    }
  ]`);
}

//Comlink.expose(ServerApi);

import React from 'react';
import WarningDialog from './warningDialog';
import { useAuth } from '../../Auth/AuthProvider';

const LogoutWarningDialog = () => {
  const { warningPopup } = useAuth();

  // Dialog ok doesn't really needed to handle here because BackgroundScheduler component listens all clicks and sets warningPopup to hide if click happens
  if (warningPopup.show) {
    return (
      <WarningDialog showWarning={warningPopup.show} logoutTime={warningPopup.logoutTime} />
    )
  }
  return (null);
}

export default LogoutWarningDialog;
import React from "react";
import BackToUpButton from "../GuideTools/BackToUpButton";
import Languages from "../../../translations";

const FAQAccordionText = () => {
  return (
    <div>
      <ol>
        <li className="guide-list-item-styles">
          {
            "Lapseni on alkupäivän hoidossa yhdessä ryhmässä ja loppupäivän vuorohoitoryhmässä. Miten teen varaukset?"
          }
          <br />
          {
            "Varaukset tulee tehdä kahdessa osassa. Tee ensin varaus alkupäivän ryhmään, minkä jälkeen tee uusi varaus vuorohoidon ryhmään. "
          }
        </li>
        <br />
        <br />
        <li className="guide-list-item-styles">
          {
            "Lapseni on esiopetuksessa ja esiopetusta täydentävässä varhaiskasvatuksessa. Miten teen varaukset?"
          }
          <br />
          {
            "Yleensä riittää, että teet yhden varauksen koko tarvittavalle ajalle, niin ohjelma erottaa automaattisesti lapsen esiopetusajan varaukseen. Osalla kunnista tai yksityisistä päiväkodeista ei ole käytössä toimintoa tätä varten, jolloin tarvittaessa varataan esim. täydentävän varhaiskasvatuksen varauspätkät erikseen. Varmistathan asian tarvittaessa lapsesi hoitopaikan henkilökunnalta."
          }
        </li>
      </ol>
      <BackToUpButton />
    </div>
  );
};

export default FAQAccordionText;

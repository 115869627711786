import React from "react";
import Typography from "@mui/material/Typography";
import Languages from "../../../../translations";
import DownloadFileButton from "../../../../Components/DownloadFileButton/DownloadFileButton";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Photo from "@mui/icons-material/Photo";
import InsertDriveFile from "@mui/icons-material/InsertDriveFile";
import Paper from "@mui/material/Paper";
import moment from "moment";
import IconButton from '@mui/material/IconButton';


const styles =  {
  //backgroundColor: "rgb(67, 119, 186)",
  "&:hover": {
    borderWidth: "5px",
    borderStyle: "solid",
    borderColor: "red"
  },
  display: "inline-flex",
  width: "100%",
  justifyContent: "end",
  //display: "grid",
  //display: "inline",
  
}

const ChildFilesSuccess = ({filesList}) => {

  const isImage = (mimeType) => {
    if (mimeType.startsWith("image")) {
      return true;
    }
    return false;
  }

  if (!filesList || filesList.length === 0) {
    return(
      <Typography>{Languages.noFilesToShow}</Typography>
    )
  }

  return(
    <div>
      <Typography>{Languages.files}</Typography>
      <TableContainer style={{marginLeft: "-12px", color:"white", width: "100%", display: ""}} component={Paper}>
        <Table size="small" aria-label="downloadable files table" style={{width: "100%"}}>
          
          {/*<TableHead>
            <TableRow>
              <TableCell size="small"></TableCell>
              <TableCell size="small">{Languages.fileFileName}{Languages.fileDescription}</TableCell>
              <TableCell>{Languages.fileSaved}</TableCell>
            </TableRow>
          </TableHead>*/}
          <TableBody>
          {filesList.map((file, index) => {
            return(
              <TableRow style={{backgroundColor: "white"}} key={file.Saved + index}>
                <TableCell /*component="th"*/ scope="row">{isImage(file.MimeType) ? <Photo data-testid="photo-test" style={{/*marginBottom: "6px",*/ color: "rgb(67, 119, 186)"}}/> : <InsertDriveFile data-testid="insertdrivefile-test" style={{/*marginBottom: "6px",*/ color: "rgb(67, 119, 186)"}}/>}<Typography style={{color: "rgb(67, 119, 186)", fontSize: "0.85rem"}} /*variant="h6"*/ component="span">{" " + file.Name}</Typography><br/><i style={{color: "rgb(67, 119, 186)", marginLeft: "5%"}}>{/*{file.Description}*/}{moment(file.Saved).format('DD-MM-YYYY')}</i></TableCell>
                <TableCell> 
                  {/*<div style={{marginRight: "3px", color: "rgb(67, 119, 186)"}}>{moment(file.Saved).format('YYYY-MM-DD')}</div>*/}
                  <div style={styles}>
                  <DownloadFileButton
                    FileName={file.Name}
                    FileMimeType={file.MimeType}
                    FileId={file.Id}
                    file={file}
                    useIconButton={false}
                    useThreeDots={true}
                    isImage={isImage(file.MimeType)}
                  />
                  </div>
                </TableCell>
              </TableRow>
            )
          })
          }
          </TableBody>
        </Table>
      </TableContainer>
        
    </div>

  
  );

}

export default ChildFilesSuccess;
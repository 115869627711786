import React, { useEffect, useState } from "react";
import { /*Modal, ModalBody,*/ /*FormGroup, Label, */ Input } from "reactstrap";
import Moment from "moment";
import { useStateValue } from "../../../../State";
import "./newreservations.css";
import Languages from "../../../../translations";
import PlacementIcon from "../../../../images/sijoitus_grey.svg";
import CalendarIcon from "../../../../images/calendar.PNG";
import ClockIcon from "../../../../images/times.svg";
import PartiallyValidIcon from "../../../../images/pinkwarning.svg";
//import PlusIcon      from "../../../images/plus.PNG"; TARVITAAN MYÖHEMMIN
import Stack from "@mui/material/Stack";
import Dropdown from "../../../../Components/DropDown";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Avatar from "@mui/material/Avatar";
import FaceRoundedIcon from "@mui/icons-material/FaceRounded";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import { getProfilePictureData } from "../../../../utils";
import ChildProfilePicture from "../../../../Components/ChildProfilePicture";

const NewReservationView = ({
  count,
  mode,
  selectedDates,
  ntDropDownData,
  listOfplacements,
  listOfChilds,
  listOfTimes,
  FirstCurrentReservation,
  ChildStats,
  handleSelection,
  handleChildSelection,
  HandleEvpr,
  getEvpr,
  handleTimeSelection,
}) => {
  const { state } = useStateValue();

  let times = listOfTimes.filter((list, index) => {
    if (index === count) {
      return list;
    }
  });

  //console.log("NewReservationView. Mount: Reservation number: ",count+ " Times[",count,"]: ", times[0]);

  const [keyPress, setKeyPress] = useState(null);
  const [startTime, setStartTime] = useState(times[0].Start);
  const [endTime, setEndTime] = useState(times[0].End);
  const [prevTimeType, setPrevTimeType] = useState(null);
  const [firstChange, setFirstChange] = useState(true);
  const [eveningPreSchoolChecked, setEveningPreSchooLChecked] = useState(false);
  const [eveningPreSchoolDisabled, setEveningPreSchoolDisabled] =
    useState(false);
  //Osittain voimassa olevan sijoituksen infokolmion klikkaus
  const [PartiallyValidInfo, setPartiallyValidInfo] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  //console.log("NewReservationView. Mount: times[0].StartNotValid: ", times[0].StartNotValid, " startTimeNotValid: ", startTimeNotValid);

  const [ntDDTitle, setntDDTitle] = useState(
    mode === "I"
      ? FirstCurrentReservation === undefined ||
        FirstCurrentReservation.Start !== null
        ? Languages.bookingTypeNT
        : FirstCurrentReservation.Text
      : Languages.bookingTypeAbsent
  );
  const [showTimes, setshowTimes] = useState(
    mode === "I"
      ? FirstCurrentReservation === undefined ||
        FirstCurrentReservation.Start !== null
        ? true
        : false
      : false
  );

  useEffect(() => {
    console.log(
      "useEffect. startTime set: ",
      Moment(startTime).format("HH:mm")
    );
    handleTimeSelection("StartTime", Moment(startTime), count);
    //HandleEveningPreschool();
    HandleEveningPreschoolChecked();
  }, [startTime]);
  useEffect(() => {
    console.log("useEffect. endTime set: ", Moment(endTime).format("HH:mm"));
    handleTimeSelection("EndTime", Moment(endTime), count);
  }, [endTime]);

  useEffect(() => {
    console.log("useEffect. keyPress: ", keyPress);
  }, [keyPress]);

  useEffect(() => {
    console.log("useEffect. firstChange: ", firstChange);
  }, [firstChange]);

  useEffect(() => {
    HandleEveningPreschoolChecked();
  }, []);

  const checkChildEveningPreschool = () => {
    const evpr = (element) => element.eveningPreSchool === true;
    if (state.nursingTimesSummary.NT.ListChildStats.some(evpr)) {
      return true;
    } else {
      return false;
    }
  };

  const CheckStartTime = () => {
    if (Moment(startTime).hour() >= 11) {
      return false;
    } else {
      return false;
    }
  };

  const HandleEveningPreschoolChecked = () => {
    if (checkChildEveningPreschool() == true && CheckStartTime() == true) {
      setEveningPreSchooLChecked(true);
      HandleEvpr(true);
    } else {
      setEveningPreSchooLChecked(false);
      HandleEvpr(false);
    }
  };

  const handleChecked = () => {
    setEveningPreSchooLChecked(!eveningPreSchoolChecked);
    HandleEvpr(!eveningPreSchoolChecked);
  };
  const handleKeyDown = (event, type) => {
    //console.log("Keypress. ",event.key," Selection:" ,type, " Prev: ",prevTimeType);

    switch (event.key) {
      case "ArrowUp":
      case "ArrowDown":
        setKeyPress(event.key);
        break;
      case "0":
        setKeyPress("Numkey: " + event.key);
        var time = event.target.value.split(":");
        var hour = time[0];
        var minutes = time[1];
        console.log("Zero. ", hour, " : ", minutes);

        if (firstChange && minutes === "00") {
          console.log("First zero -> change time");
          changeTime(event, type);
        }
        break;
      case "1":
      case "2":
      case "3":
      case "4":
      case "5":
      case "6":
      case "7":
      case "8":
      case "9":
        setKeyPress("Numkey: " + event.key);
        break;
      case "Tab":
      case "ArrowLeft":
      case "ArrowRight":
        let prevHour = Moment(startTime).format("HH");
        let prevMin = Moment(startTime).format("mm");
        let RoundedMin = roundMinutes(prevMin);
        setStartTime(Moment(startTime).hour(prevHour).minute(RoundedMin));

        prevHour = Moment(endTime).format("HH");
        prevMin = Moment(endTime).format("mm");
        RoundedMin = roundMinutes(prevMin);
        setEndTime(Moment(endTime).hour(prevHour).minute(RoundedMin));

        setFirstChange(true);
        setKeyPress(event.key);
        break;
      case "Shift":
        setKeyPress(event.key);
        break;
      default:
        setKeyPress("Default: " + event.key);
        break;
    }
  };

  const changeTime = (event, type) => {
    //const { name, value } = event.target;
    var time = event.target.value.split(":");
    var hour = time[0];
    var minutes = time[1];

    let prevHour = null;
    let prevMin = null;

    if (prevTimeType !== null && type !== prevTimeType) {
      prevHour =
        prevTimeType === "StartTime"
          ? Moment(startTime).format("HH")
          : Moment(endTime).format("HH");
      prevMin =
        prevTimeType === "StartTime"
          ? Moment(startTime).format("mm")
          : Moment(endTime).format("mm");

      console.log(
        "Selection changed. Round prev minutes: ",
        prevMin,
        " from: ",
        prevTimeType
      );

      let RoundedMin = roundMinutes(prevMin);

      //console.log("Set prev time: ", prevHour," from: ",RoundedMin );

      if (prevTimeType === "StartTime") {
        setStartTime(Moment(startTime).hour(prevHour).minute(RoundedMin));
      } else {
        setEndTime(Moment(endTime).hour(prevHour).minute(RoundedMin));
      }
      setPrevTimeType(type);
      prevHour = null;
      prevMin = null;
    }

    if (keyPress === null) {
      console.log("NewReservationsView. dropdown Change: " + type);

      let RoundedMin = roundMinutes(minutes);

      prevHour =
        type === "StartTime"
          ? Moment(startTime).format("HH")
          : Moment(endTime).format("HH");
      prevMin =
        type === "StartTime"
          ? Moment(startTime).format("mm")
          : Moment(endTime).format("mm");

      console.log("New Time ", hour, ":", minutes, " => rounded: ", RoundedMin);
      //console.log(prevHour !== hour ? "Tunti muutettu" : "Minuutti muutettu");

      if (type === "StartTime") {
        setStartTime(Moment(startTime).hour(hour).minute(RoundedMin));
      } else {
        setEndTime(Moment(endTime).hour(hour).minute(RoundedMin));
      }
      setFirstChange(true);
      setPrevTimeType(type);
    } else if (keyPress === "ArrowUp" || keyPress === "ArrowDown") {
      // arrow keys always first change 15min
      console.log(
        "NewReservationsView. Arrow change." +
          "\n hour: " +
          hour +
          " minutes: " +
          minutes +
          "\n Avauksesta suoraan end change: ",
        prevTimeType === null ? true : false,
        "\n time field changed: ",
        type !== prevTimeType ? true : false
      );

      setFirstChange(true);
      setKeyPress(null);
      let RoundedMin = roundMinutes(minutes);

      if (type === "StartTime") {
        prevHour = Moment(startTime).format("HH");
        prevMin = Moment(startTime).format("mm");
        setStartTime(Moment(startTime).hour(hour).minute(RoundedMin));
      } else {
        prevHour = Moment(endTime).format("HH");
        prevMin = Moment(endTime).format("mm");
        setEndTime(Moment(endTime).hour(hour).minute(RoundedMin));
      }
      setPrevTimeType(type);
    } else if (firstChange || type !== prevTimeType || prevTimeType === null) {
      // prev type null = reservation view just opened
      console.log(
        "NewReservationsView.  First change, wait second number" +
          "\n hour: " +
          hour +
          " minutes: " +
          minutes +
          "\n firstChange: ",
        firstChange,
        "\n keyPress: ",
        keyPress + "\n Avauksesta suoraan end change: ",
        prevTimeType === null ? true : false,
        "\n time field changed: ",
        type !== prevTimeType ? true : false
      );

      setFirstChange(false);
      setKeyPress(null);

      if (type === "StartTime") {
        prevHour = Moment(startTime).format("HH");
        prevMin = Moment(startTime).format("mm");
        setStartTime(Moment(startTime).hour(hour).minute(minutes));
      } else {
        prevHour = Moment(endTime).format("HH");
        prevMin = Moment(endTime).format("mm");
        setEndTime(Moment(endTime).hour(hour).minute(minutes));
      }
      setPrevTimeType(type);
    } else {
      console.log("NewReservationsView. Second number. Change: " + type);

      let RoundedMin = roundMinutes(minutes);

      prevHour =
        type === "StartTime"
          ? Moment(startTime).format("HH")
          : Moment(endTime).format("HH");
      prevMin =
        type === "StartTime"
          ? Moment(startTime).format("mm")
          : Moment(endTime).format("mm");

      console.log("New Time ", hour, ":", minutes, " => rounded: ", RoundedMin);
      //console.log(prevHour !== hour ? "Tunti muutettu" : "Minuutti muutettu");

      if (type === "StartTime") {
        setStartTime(Moment(startTime).hour(hour).minute(RoundedMin));
      } else {
        setEndTime(Moment(endTime).hour(hour).minute(RoundedMin));
      }
      setFirstChange(true);
      setPrevTimeType(type);
      setKeyPress(null);
    }
  };

  const roundMinutes = (min) => {
    //console.log("Pyöristä: ", min);
    if (min === "00" || (0 < min && min < 8)) {
      min = 0;
    } else if (7 < min && min < 23) {
      min = 15;
    } else if (22 < min && min < 38) {
      min = 30;
    } else if (37 < min && min < 53) {
      min = 45;
    } else if (58 < min && min < 60) {
      min = 59;
    } else {
      min = 0;
      //hour++;
    }

    //console.log("Pyöristetty:", min);

    return min;
  };

  const changeType = (type) => {
    /*console.log("changeType: ",
                "\n Type: ", type,
                "\n type.selection: ", type.selection);*/

    if (type.selection[0].id !== "I") {
      setshowTimes(false);
    } else {
      setshowTimes(true);
    }
    setntDDTitle(type.selection[0].name);
    handleSelection(type, count);
  };

  const changePlacement = (type) => {
    //console.log("changePlacement: type: ",type, " count: ", count);
    handleSelection(type, count);
  };

  const openInfo = (event, count, childId, PlId) => {
    let childPlacement = listOfplacements
      .filter((lop, lindex) => {
        return lindex === count;
      })[0]
      .filter((child) => {
        return child.ChildId === childId;
      })[0]
      .data.filter((pl) => {
        return pl.value === PlId;
      });

    /*console.log("openInfo."+
                "\n Reservation number: ", count,
                "\n ChildId: ", childId+
                "\n Placement Id: ", PlId,
                "\n All placements: ", listOfplacements,
                "\n Selected child placement: ", childPlacement,
                "\n childPlacement[0].text: ", childPlacement[0].text);*/

    setAnchorEl(event.currentTarget);
    setPartiallyValidInfo(
      childPlacement[0].text + " loppuu pvm. " + childPlacement[0].enddate
    );
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let firstSelected = true;
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className="NewReservation textCenter">
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Typography style={{ whiteSpace: "pre-line" }}>
          {Languages.bookingSavingFailedPartially2 + PartiallyValidInfo}
        </Typography>
      </Popover>
      {count > 0 ? (
        <div>
          <div className="BorderBottomBlue"></div>
          <br></br>
        </div>
      ) : null}

      {listOfChilds
        .filter((list, index) => {
          if (index === count) {
            return list;
          }
        })[0]
        .map((child, kersaindex) => {
          const profilePictureBase64 = getProfilePictureData(state, child.Id);

          return (
            <div className="container" key={child.Id + "_" + count}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={2}>
                  {!profilePictureBase64 ? (
                    <Avatar style={{ height: "40px", width: "40px" }}>
                      <ChildProfilePicture />
                    </Avatar>
                  ) : (
                    <Avatar
                      alt="picture"
                      src={"data:image/jpeg;" + profilePictureBase64}
                    />
                  )}
                </Grid>

                <Grid item xs={8}>
                  <Paper style={{ backgroundColor: child.BgColor }}>
                    <Typography
                      style={{ paddingTop: "8px", paddingBottom: "8px" }}
                    >
                      {child.FirstName}
                    </Typography>
                  </Paper>
                </Grid>

                <Grid item xs={2}>
                  {listOfplacements
                    .filter((plist, pindex) => {
                      if (pindex === count) {
                        return plist;
                      }
                    })[0]
                    .filter((pls) => {
                      return pls.ChildId === child.Id;
                    })
                    .map((pls) => {
                      if (pls.data[0].value === null) {
                        return null;
                      } else {
                        return (
                          <FormControlLabel
                            key={child.Id}
                            control={
                              <Checkbox
                                aria-label="select child"
                                //key={child.Id}
                                id={"test_NewBooking_Child" + kersaindex}
                                onChange={() =>
                                  handleChildSelection(child.Id, count)
                                }
                                checked={child.Checked}
                              />
                            }
                            label=""
                          />
                        );
                      }
                    })}
                </Grid>

                <Grid item xs={2}>
                  <Avatar
                    style={{
                      height: "40px",
                      width: "40px",
                      backgroundColor: "white",
                    }}
                  >
                    <img src={PlacementIcon} alt="PLI" height="30px" />
                  </Avatar>
                </Grid>

                {child.Checked ? (
                  <Grid item xs={8}>
                    {listOfplacements
                      .filter((plist, pindex) => {
                        if (pindex === count) {
                          return plist;
                        }
                      })[0]
                      .filter((pls) => {
                        return pls.ChildId === child.Id;
                      })
                      .map((pls) => {
                        if (pls.data[0].value === null) {
                          return Languages.errorNoPlacements;
                        } else {
                          if (firstSelected) {
                            firstSelected = false;
                            return (
                              <Dropdown
                                key={pls.ID}
                                style={{ width: "65%" }}
                                childIndex={kersaindex}
                                childID={child.Id}
                                component={Languages.bookingChoosePlacement}
                                title={pls.Title}
                                data={pls.data}
                                callbackFromDropDown={(selection) =>
                                  changePlacement({ selection })
                                }
                              />
                            );
                          } else {
                            return (
                              <Input
                                key={pls.ID}
                                type="text"
                                value={pls.Title}
                                readOnly
                                style={{ textAlign: "center" }}
                              />
                            );
                          }
                        }
                      })}
                  </Grid>
                ) : (
                  <Grid item xs={8}></Grid>
                )}

                {child.Checked ? (
                  <Grid item={true}>
                    {listOfplacements
                      .filter((plist, pindex) => {
                        if (pindex === count) {
                          return plist;
                        }
                      })[0]
                      .filter((pls) => {
                        return pls.ChildId === child.Id;
                      })
                      .map((pls) => {
                        let firstPartiallyvalid = pls.data.filter((pl) => {
                          return pl.value === pls.PlId;
                        })[0].validpartially;
                        //console.log("child: ", child.Name, "\n All Placement: ", pls, " Select/First valid: ", firstPartiallyvalid);

                        if (firstPartiallyvalid) {
                          return (
                            <img
                              key={pls.ID}
                              src={PartiallyValidIcon}
                              alt="PV"
                              width="30px"
                              onClick={(e) =>
                                openInfo(e, count, child.Id, pls.PlId)
                              }
                            />
                          );
                        } else {
                          return <div key={pls.ID}></div>;
                        }
                      })}
                  </Grid>
                ) : (
                  <Grid item xs={8}></Grid>
                )}
              </Grid>

              <div className="BorderBottomLightblue"></div>
            </div>
          );
        })}

      <div className="BorderBottomBlue"></div>
      {count === 0 ? (
        <div className="container">
          <div className="row" style={{ padding: 5 }}>
            <div className="Tyyppi col">
              <br />
              <Dropdown
                id="test_NewBooking_TypeDdl"
                component={Languages.bookingType}
                title={ntDDTitle}
                data={ntDropDownData}
                callbackFromDropDown={(selection) => changeType({ selection })}
              />
            </div>
          </div>
        </div>
      ) : null}

      <div className="container">
        <div className="row" style={{ padding: 5 }}>
          <div className="col-2">
            <img src={CalendarIcon} alt="CLDR" />
          </div>
          <div className="Paivat col-10" style={{ whiteSpace: "pre-line" }}>
            {/*<img src={PlusIcon} alt="PLUS" className="PlusIcon" onClick={this.handleCalendarChange}/>*/}
            {selectedDates.map((sel, index) => {
              return (
                <Input
                  id={"test_NewBooking_DateRange_" + index}
                  key={index}
                  type="text"
                  value={sel}
                  readOnly
                />
              );
            })}
          </div>
        </div>
      </div>

      {showTimes ? (
        <div className="container">
          <div className="row" style={{ padding: 5 }}>
            <div className="col-2">
              {" "}
              <img src={ClockIcon} alt="TIME" width="30px" />
              {/*"Times "+count*/}{" "}
            </div>
            <div className="Kello col-5">
              {times[0].StartNotValid /*startTimeNotValid*/ ? (
                <Input
                  id="test_NewBooking_Start"
                  type="time"
                  step="900"
                  style={{ color: "red" }}
                  value={Moment(startTime).format("HH:mm")}
                  onKeyDown={(e) => {
                    handleKeyDown(e, "StartTime");
                  }}
                  onChange={(e) => {
                    changeTime(e, "StartTime");
                  }}
                  required
                  invalid
                />
              ) : (
                <Input
                  id="test_NewBooking_Start"
                  type="time"
                  step="900"
                  value={Moment(startTime).format("HH:mm")}
                  onKeyDown={(e) => {
                    handleKeyDown(e, "StartTime");
                  }}
                  onChange={(e) => {
                    changeTime(e, "StartTime");
                  }}
                  required
                  valid
                />
              )}
            </div>
            <div className="Kello col-5">
              {times[0].EndNotValid ? (
                <Input
                  id="test_NewBooking_End"
                  type="time"
                  step="900"
                  style={{ color: "red" }}
                  value={Moment(endTime).format("HH:mm")}
                  onKeyDown={(e) => {
                    handleKeyDown(e, "EndTime");
                  }}
                  onChange={(e) => {
                    changeTime(e, "EndTime");
                  }}
                  required
                  invalid
                />
              ) : (
                <Input
                  id="test_NewBooking_End"
                  type="time"
                  step="900"
                  value={Moment(endTime).format("HH:mm")}
                  onKeyDown={(e) => {
                    handleKeyDown(e, "EndTime");
                  }}
                  onChange={(e) => {
                    changeTime(e, "EndTime");
                  }}
                  required
                  valid
                />
              )}
            </div>
          </div>
          {checkChildEveningPreschool() === false ? null : (
            <Box className="col-9">
              <FormGroup>
                <FormControlLabel
                  label={
                    <Typography
                      color="textSecondary"
                      sx={{
                        textAlign: "left",
                        fontWeight: "bold",
                        fontSize: 16,
                        color: "#424242",
                      }}
                    >
                      {"Esikoululaisen iltaesikoulu varaus"}
                    </Typography>
                  }
                  labelPlacement="start"
                  control={
                    <Checkbox
                      checked={eveningPreSchoolChecked}
                      onChange={handleChecked}
                      disabled={CheckStartTime() === false ? true : false}
                    />
                  }
                />
              </FormGroup>
            </Box>
          )}
        </div>
      ) : null}

      <Box m={2}></Box>
    </div> /* className="NewReservation"*/
  ); /* return child calender*/
};

export default NewReservationView;

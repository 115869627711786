import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardActionArea } from "@mui/material";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from "moment";
import React, {
  Fragment,
  useEffect,
  useState,
  useMemo,
  useRef,
  memo,
} from "react";
import ChildProfilePicture from "../../../ChildProfilePicture";
import "./CTBContentManager.css";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import LockIcon from "@mui/icons-material/Lock";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import IsoIcon from "@mui/icons-material/Iso";
import IconButton from "@mui/material/IconButton";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { useStateValue } from "../../../../State";
import CircularProgress from "@mui/material/CircularProgress";
import { types } from "../../../../Reducers/actionTypes";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import CloseIcon from "@mui/icons-material/Close";

import Switch from "@mui/material/Switch";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import CTBdayDialog from "./CTBdayDialog";
import Slider from "@mui/material/Slider";
import SickIcon from "@mui/icons-material/Sick";
import LoginIcon from "@mui/icons-material/Login";
import NightShelterIcon from "@mui/icons-material/NightShelter";

//require('dayjs/locale/fi')

const CTBContentManager = ({
  nursingtimes,
  personName,
  view,
  childList,
  handleDelReservations,
  handleScrollWeek,
  handleScrollDay,
  monthChange,
  focusDay,
  handleFocusDay,
  holidays,
}) => {
  //const [date, setDate] = React.useState(dayjs('2022-04-07'));
  const [weekOpen, setWeekOpen] = React.useState(false);
  const [dayOpen, setDayOpen] = React.useState(false);
  /*const [week, setWeek] = useState(
    nursingtimes.nursingtimes.WeekData.WeekList[0]
  );
  const [day, setDay] = useState(
    nursingtimes.nursingtimes.WeekData.WeekList[0]
  );*/
  //const locale = dayjs.locale('fi')
  const { state, actions } = useStateValue();

  console.log(state.caretimes.testList);
  useEffect(() => {
    handleClickScroll(focusDay);
  }, []);

  const handleClickScroll = (date) => {
    const element = document.getElementById(date.format("MM.DD.YYYY"));
    if (element) {
      const y = element.getBoundingClientRect().top + window.pageYOffset - 120;
      window.scrollTo({ top: y });
    }
  };
  return (
    <>
      <Box sx={{ mt: 4 }}>
        {personName.length < 1 ? (
          <Typography align="center" color="textSecondary" sx={{ mt: 20 }}>
            {"Ei valittuja lapsia"}
          </Typography>
        ) : (
          <>
            {nursingtimes.map((week, index) => (
              <CTBWeek
                week={week}
                personName={personName}
                childList={childList}
                handleDelReservations={handleDelReservations}
                handleScrollWeek={handleScrollWeek}
                reloadTest={index === nursingtimes.length - 1 ? true : false}
                reloadTest2={index === 0 ? true : false}
                monthChange={monthChange}
                handleScrollDay={handleScrollDay}
                holidays={holidays}
                handleFocusDay={handleFocusDay}
              ></CTBWeek>
            ))}
          </>
        )}
      </Box>
    </>
  );
};

export default CTBContentManager;

export const useForceRerender = () => {
  const [, setValue] = useState(0); // we don't need the value of this state.
  return () => setValue((value) => value + 1);
};

export const useIsElementVisible = (target, options) => {
  const [isVisible, setIsVisible] = useState(false);
  const forceUpdate = useForceRerender();

  useEffect(() => {
    forceUpdate(); // to ensure that ref.current is attached to the DOM element
  }, []);

  useEffect(() => {
    if (!target) return;

    const observer = new IntersectionObserver(handleVisibilityChange, options);
    observer.observe(target);

    return () => observer.unobserve(target);
  }, [target, options]);

  const handleVisibilityChange = ([entry]) =>
    setIsVisible(entry.isIntersecting);

  return isVisible;
};

export const useIsElementVisible2 = (target, options) => {
  const [isVisible, setIsVisible] = useState(false);
  const forceUpdate = useForceRerender();

  useEffect(() => {
    forceUpdate(); // to ensure that ref.current is attached to the DOM element
  }, []);

  useEffect(() => {
    if (!target) return;

    const observer = new IntersectionObserver(handleVisibilityChange, options);
    observer.observe(target);

    return () => observer.unobserve(target);
  }, [target, options]);

  const handleVisibilityChange = ([entry]) =>
    setIsVisible(entry.isIntersecting);

  return isVisible;
};

const CTBWeekChip = ({ weekNumber, handleScrollWeek }) => {
  const elementRef = useRef(null); // to hold reference to the component we need to track

  const isElementVisible = useIsElementVisible(elementRef.current, {
    rootMargin: "20% 0% -45% 0%",
  });

  if (isElementVisible) {
    handleScrollWeek(weekNumber);
  }

  return (
    <Divider
      sx={{
        mt: 1,
        "&::before, &::after": {
          borderColor: "#0074C8",
        },
      }}
      textAlign="center"
      ref={elementRef}
    >
      <Chip
        label={"Viikko " + weekNumber}
        sx={{ backgroundColor: "#0074C8" }}
      />
    </Divider>
  );
};

const CTBWeek = ({
  week,
  personName,
  childList,
  handleDelReservations,
  handleScrollWeek,
  reloadTest,
  reloadTest2,
  monthChange,
  handleScrollDay,
  handleFocusDay,
  holidays,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [reloaded, setReloaded] = useState(false);

  const handleOpen = () => {
    setModalOpen(!modalOpen);
  };

  const [scrollPos, setScrollPos] = useState(null);
  const elementRef = useRef(null); // to hold reference to the component we need to track

  const isElementVisible = useIsElementVisible(elementRef.current, {
    rootMargin: "200px",
    treshold: 0.1,
  });

  if (isElementVisible) {
    if (reloadTest && !reloaded) {
      console.log("ala");
      monthChange("next");
      setReloaded(true);
    }
    if (reloadTest2 && !reloaded) {
      console.log("ylä");
      setScrollPos(window.pageYOffset);
      monthChange("prev");
      setReloaded(true);
    }
  }

  return (
    <div>
      <Box>
        {/*{reloadTest2 && (
          <Stack spacing={1} alignItems="center" justifyContent="center">
            <CircularProgress sx={{ color: "#0074C8" }} />
            <Divider>
              <Chip
                ref={elementRef}
                label={"Ladataan edellisiä hoitoaikoja"}
                sx={{ backgroundColor: "#0074C8" }}
              ></Chip>
            </Divider>
          </Stack>
        )}*/}
        <CTBWeekChip
          weekNumber={week.WeekNumber}
          handleScrollWeek={handleScrollWeek}
        ></CTBWeekChip>
        {week.DayList.map((day) => (
          <>
            {day.IsWeekend === false && (
              <CTBDay
                key={day.Date.format("MMM MM.DD.YYYY")}
                day={day}
                personName={personName}
                weekSelected={false}
                childList={childList}
                handleDelReservations={handleDelReservations}
                handleScrollDay={handleScrollDay}
                holidays={holidays}
                handleFocusDay={handleFocusDay}
              ></CTBDay>
            )}
          </>
        ))}
        {reloadTest && (
          <Stack spacing={1} alignItems="center" justifyContent="center">
            <Divider>
              <Chip
                ref={elementRef}
                label={"Ladataan seuraavia hoitoaikoja"}
                sx={{ backgroundColor: "#0074C8" }}
              ></Chip>
            </Divider>

            <CircularProgress sx={{ color: "#0074C8" }} />
          </Stack>
        )}
      </Box>
    </div>
  );
};

const CTBDay = ({
  day,
  personName,
  weekSelected,
  childList,
  handleDelReservations,
  handleScrollDay,
  holidays,
  handleFocusDay,
}) => {
  const [daySelected, setDaySelected] = useState(false);
  const [selectedTimes, setSelectedTimes] = useState(0);

  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setDaySelected(weekSelected);
  }, [weekSelected]);

  const handleClick = () => {
    setModalOpen(!modalOpen);
  };

  const elementRef = useRef(null); // to hold reference to the component we need to track

  const isElementVisible = useIsElementVisible(elementRef.current, {
    rootMargin: "75px 0px -75% 0px",
  });

  if (isElementVisible) {
    handleScrollDay(day.Date);
  }
  return (
    <>
      <Box
        ref={elementRef}
        id={day.Date.format("MM.DD.YYYY")}
        sx={{
          mb: 1,
          mt: 1,
          backgroundColor:
            moment(day.Date).isSame(new Date(), "day") === true
              ? "success.light"
              : "white",
          borderRadius: 2,
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          divider={<Divider variant="inset" orientation="vertical" flexItem />}
        >
          <Box sx={{ mt: 0.5, ml: 0.5 }} onClick={handleClick}>
            <Typography color="textSecondary" fontWeight="bold">
              {day.Date.format("DD.MM")}
            </Typography>
            <Typography color="textSecondary" fontSize="small">
              {day.Date.format("ddd")}
            </Typography>
            <Typography color="textSecondary" fontSize="small">
              {"vko." + moment(day.Date).isoWeek()}
            </Typography>
          </Box>
          <Box sx={{ width: "100%" }}>
            <>
              {day.WeekHoliday !== null && (
                <>
                  <Chip
                    sx={{ mt: 0.5, mb: 0.5, ml: 1 }}
                    label={
                      <Typography color="textSecondary">
                        {day.WeekHoliday}
                      </Typography>
                    }
                  ></Chip>
                </>
              )}
            </>
            {day.CareTimeTest.map((nt, index) => (
              <>
                {personName.includes(nt.ChildId) && (
                  <CTBChildRow
                    day={day}
                    statuses={day.Statuses}
                    personName={personName}
                    nt={nt}
                    daySelected={daySelected}
                    date={day.Date}
                    childList={childList}
                    handleDelReservations={handleDelReservations}
                    selectable={
                      day.IsLockedDate === true
                        ? false
                        : day.NotSelectable === true
                        ? false
                        : true
                    }
                    openDayModal={handleClick}
                    lastInArray={index === personName.length - 1 ? true : false}
                  ></CTBChildRow>
                )}
              </>
            ))}
          </Box>
        </Stack>
      </Box>
      <CTBdayDialog
        day={day}
        modalOpen={modalOpen}
        handleClick={handleClick}
        names={childList}
        handleFocusDay={handleFocusDay}
      ></CTBdayDialog>
    </>
  );
};
const CTBChildRow = ({
  day,
  statuses,
  personName,
  nt,
  daySelected,
  date,
  childList,
  handleDelReservations,
  selectable,
  openDayModal,
  lastInArray,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [childId, setChildId] = useState("koira");

  //oletushoitoaika ja yksikkö
  const [centre, setCentre] = useState("Sonjan Sateenkari");
  const [startTime, setStartTime] = useState(
    moment().startOf("day").add(8, "hours")
  );
  const [endTime, setEndTime] = useState(
    moment().startOf("day").add(16, "hours")
  );
  const [value4, setValue4] = React.useState(moment());

  const [newReservations, setNewReservations] = useState([]);
  const [placements, setPlacements] = useState([]);

  useEffect(() => {
    childList.forEach((child) => {
      if (child.Id === nt.ChildId) {
        setPlacements(child.Placements);
      }
    });

    setCentre("sadsadasd");

    setIsOpen(daySelected);

    setChildId(nt.ChildId);
  }, [childList]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const testAdd = () => {
    setDialogOpen(!dialogOpen);
  };

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          bgcolor: "white",
          width: "95%",
          mt: 0.5,
          "&:last-child": { mb: 0.5 },
        }}
      >
        <CardActionArea sx={{ width: "100%" }} onClick={openDayModal}>
          <CardContent
            sx={{
              p: 0,
              "&:last-child": { pb: 0 },
              width: "100%",
              mt: 0.5,
              mb: 0.5,
            }}
          >
            <Stack>
              <Box>
                <Stack direction="row" spacing={2} sx={{ ml: 0.5 }}>
                  <Box sx={{ width: "30%" }}>
                    <Chip
                      size="small"
                      sx={{
                        color: "black",
                      }}
                      avatar={
                        <Avatar>
                          <ChildProfilePicture childId={nt.ChildId} />
                        </Avatar>
                      }
                      label={nt.Name.split(/\s+/)[0]}
                      onClick={selectable ? () => testAdd() : null}
                    ></Chip>
                  </Box>
                  <Box sx={{ width: "70%" }}>
                    {!nt.Centres.some(
                      (centre) => centre.NursingTimes.length > 0
                    ) && (
                      <>
                        {day.Statuses.length > 0 && (
                          <>
                            {statuses.some(
                              (stat) => stat.PersonId === nt.ChildId
                            ) && (
                              <>
                                <StatusChip
                                  Type={
                                    statuses.filter(
                                      (stat) => stat.PersonId === nt.ChildId
                                    )[0].Type
                                  }
                                  Name={
                                    statuses.filter(
                                      (stat) => stat.PersonId === nt.ChildId
                                    )[0].Name
                                  }
                                  Start={
                                    statuses.filter(
                                      (stat) => stat.PersonId === nt.ChildId
                                    )[0].Start
                                  }
                                ></StatusChip>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                    {nt.Centres.some(
                      (centre) => centre.NursingTimes.length > 0
                    ) && (
                      <>
                        {statuses.some(
                          (stat) =>
                            stat.PersonId === nt.ChildId && stat.Type == "A"
                        ) === true ? (
                          <>
                            <Chip
                              size="small"
                              icon={<NightShelterIcon />}
                              color="warning"
                              label={"Poissa"}
                            ></Chip>
                          </>
                        ) : (
                          <>
                            {statuses.some(
                              (stat) =>
                                stat.PersonId === nt.ChildId && stat.Type == "S"
                            ) === true ? (
                              <>
                                <Chip
                                  icon={<SickIcon />}
                                  size="small"
                                  color="warning"
                                  label={"Sairas"}
                                ></Chip>
                              </>
                            ) : (
                              <>
                                {statuses.some(
                                  (stat) =>
                                    stat.PersonId === nt.ChildId &&
                                    stat.Type == "100"
                                ) === true ? (
                                  <>
                                    <Chip
                                      icon={<NightShelterIcon />}
                                      size="small"
                                      color="warning"
                                      label={"Ennakoitu poissaolo"}
                                    ></Chip>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Box>
                </Stack>
              </Box>

              {nt.Centres.some((centre) => centre.NursingTimes.length > 0) ===
                true && (
                <Box>
                  <ExistingReservation
                    Centres={nt.Centres}
                  ></ExistingReservation>
                </Box>
              )}
            </Stack>
          </CardContent>
        </CardActionArea>
      </Card>

      <Dialog disablePadding disableGutters onClose={testAdd} open={dialogOpen}>
        <DialogTitle style={{ backgroundColor: "#FFFFFF" }}>
          <Typography color="textSecondary" fontWeight="bold">
            {date.format("ddd DD.MM")}
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            height: "600px",
          }}
          dividers
          disableGutters
          disablePadding
          style={{ backgroundColor: "#FFFFFF" }}
        >
          <Typography color="textSecondary" fontWeight="bold">
            {"Testi Näkymä"}
          </Typography>
          {nt.Centres.map((ct) => (
            <>
              <List sx={{ p: 0 }}>
                {ct.NursingTimes.length > 0 && (
                  <>
                    <ListItem sx={{ p: 0 }}>
                      <List sx={{ p: 0 }}>
                        {ct.NursingTimes.sort(
                          (a, b) => moment(a.Start) - moment(b.Start)
                        ).map((time) => (
                          <>
                            <Chip
                              sx={{ color: "black" }}
                              avatar={
                                <Avatar>
                                  <ChildProfilePicture
                                    childId={time.PersonId}
                                  />
                                </Avatar>
                              }
                              label={time.FirstName}
                            ></Chip>
                            <Chip
                              label={
                                <Typography
                                  color="textSecondary"
                                  fontSize="small"
                                >
                                  {ct.CentreName}
                                </Typography>
                              }
                              color={"primary"}
                            />
                            <Chip
                              sx={{ color: "black" }}
                              label={
                                <Typography
                                  color="textSecondary"
                                  fontSize="small"
                                >
                                  {time.Group}
                                </Typography>
                              }
                            ></Chip>
                            <Chip
                              sx={{ color: "black" }}
                              label={
                                <Typography
                                  color="textSecondary"
                                  fontSize="small"
                                >
                                  {moment(time.Start).format("HH:mm") +
                                    "-" +
                                    moment(time.End).format("HH:mm")}
                                </Typography>
                              }
                            ></Chip>
                          </>
                        ))}
                      </List>
                    </ListItem>
                  </>
                )}
              </List>
            </>
          ))}
        </DialogContent>
      </Dialog>
    </>
  );
};

const StatusChip2 = ({ statuses }) => {
  return (
    <>
      <Chip
        size="small"
        icon={
          Name.indexOf("Hoitoaika alku") !== -1 ? (
            <LoginIcon />
          ) : Type === "S" ? (
            <SickIcon />
          ) : (
            <NightShelterIcon />
          )
        }
        color={Name.indexOf("Hoitoaika alku") !== -1 ? "success" : "warning"}
        label={
          Name.indexOf("Hoitoaika alku") !== -1
            ? moment(Start).format("HH:mm")
            : Name
        }
      ></Chip>
    </>
  );
};

const StatusChip = ({ Type, Name, Start }) => {
  return (
    <>
      <Chip
        size="small"
        icon={
          Name.indexOf("Hoitoaika alku") !== -1 ? (
            <LoginIcon />
          ) : Type === "S" ? (
            <SickIcon />
          ) : (
            <NightShelterIcon />
          )
        }
        color={Name.indexOf("Hoitoaika alku") !== -1 ? "success" : "warning"}
        label={
          Name.indexOf("Hoitoaika alku") !== -1
            ? moment(Start).format("HH:mm")
            : Name
        }
      ></Chip>
    </>
  );
};
const ExistingReservation = (props) => {
  const [startTimes, setStartTimes] = useState([]);
  const [endTimes, setEndTimes] = useState([]);
  const [value, setValue] = React.useState([]);

  useEffect(() => {
    props.Centres.forEach((ct, index) => {
      if (ct.NursingTimes.length > 0) {
        ct.NursingTimes.forEach((time, index) => {
          startTimes.push(time.Start);
          endTimes.push(time.End);
        });
      }
    });

    const sortedStarts = startTimes.sort((a, b) => moment(a) - moment(b));
    const sortedEnds = endTimes.sort((a, b) => moment(a) - moment(b));

    const startAsMinutes = moment
      .duration(moment(sortedStarts[0]).format("HH:mm"))
      .asMinutes();
    const endAsMinutes = moment
      .duration(moment(sortedEnds[sortedEnds.length - 1]).format("HH:mm"))
      .asMinutes();

    setValue([startAsMinutes, endAsMinutes]);
  }, [props.Centres]);

  return (
    <>
      <Slider
        sx={{
          height: 8,
          borderRadius: 0,
          width: "96%",
          ml: "2%",

          "& .MuiSlider-thumb": {
            backgroundColor: "transparent",
            height: 0,
            width: 0,
          },

          "& .MuiSlider-track": {
            height: 8,
            color: "#00B3DF",
          },
          "& .MuiSlider-rail": {
            color: "#e0e0de",
            opacity: 1,
            height: 8,
          },
          "& .MuiSlider-valueLabel": {
            fontSize: 12,
            fontWeight: "normal",
            top: 28,
            backgroundColor: "unset",
            color: "black",
            "&:before": {
              display: "none",
            },
            "& *": {
              background: "transparent",
              color: "black",
            },
          },
        }}
        value={value}
        valueLabelDisplay="on"
        valueLabelFormat={(value) =>
          moment().startOf("day").add(value, "minutes").format("HH:mm")
        }
        min={0}
        max={1440}
        disabled
      />
    </>
  );
};

const CTBstepper = (props) => {
  const [multires, setMultiRes] = React.useState([]);
  const [value2, setValue2] = React.useState();
  const [value3, setValue3] = React.useState();
  const [value4, setValue4] = React.useState(moment());
  const [value5, setValue5] = React.useState(moment());
  const { state, actions } = useStateValue();
  const [testlist, setTestList] = React.useState([]);
  const [isOpen, setIsOpen] = React.useState(false);
  useEffect(() => {
    for (var key in props.childList) {
      testlist.push(props.childList[key].Id);
    }
  }, []);

  const handleSelectedChildren = (Id) => {
    if (testlist.some((testId) => testId === Id)) {
      var temp = testlist.filter((testId) => testId !== Id);
      setTestList(temp);
    } else {
      testlist.push(Id);
    }
  };

  const handleConfirmDeleteDialog = () => {
    setIsOpen(!isOpen);
  };

  const steps = [
    {
      label: "Valitse Lapset",
    },
    {
      label: "Valitse varauksen alku- ja loppupäivämäärä",
    },
    {
      label: "Valitse varauksen alku- ja loppuaika",
    },
    {
      label: "Tarkista ja tallenna",
    },
  ];

  const handlePreviewClick = () => {
    setIsOpen(true);

    var reservationsChilds = [];
    var childs = [];
    props.childList.forEach((child, index) => {
      if (testlist.includes(child.Id)) {
        childs.push({
          Id: child.Id,
          Name: child.FirstName,
          PlId: child.Placements[0].Id,
          PlName: child.Placements[0].Centre + "-" + child.Placements[0].Group,
        });
      }
    });

    reservationsChilds.push(childs);

    var selectedDates = [];
    for (var m = moment(value2); m.isBefore(value3); m.add(1, "days")) {
      if (m.isoWeekday() !== 6 && m.isoWeekday() !== 7) {
        selectedDates.push({ Date: m.format("YYYY-MM-DDT00:00:00") });
      }
    }

    var ListOfReservationsTimes = [
      { Start: value4, End: value5, StartNotValid: false, EndNotValid: false },
    ];

    var Types = ["I"];

    var DeletedOld = true;

    var eveningPreSchool = false;

    actions.triggerCheckCareTimes({
      reservationsChilds,
      selectedDates,
      ListOfReservationsTimes,
      Types,
      DeletedOld,
      eveningPreSchool,
    });
  };

  const handleSaveClick = () => {};

  const start = (100 / 48) * 1;
  const end = 50 - (100 / 48) * 16;
  const containerStyles = {
    height: 8,
    width: "200px",
    backgroundColor: "#e0e0de",
    borderRadius: 50,
    margin: 50,
    display: "flex",
  };

  const fillerStyles = {
    marginLeft: `${start}%`,
    marginRight: `${end}%`,
    height: "100%",
    width: `100%`,
    backgroundColor: "red",
    borderRadius: "inherit",
    textAlign: "right",
  };

  const labelStyles = {
    padding: 0,
    color: "black",
    marginLeft: "71%",
    fontSize: "12px",
    fontWeight: "bold",
  };

  const labelStyles2 = {
    padding: 0,
    color: "black",
    marginRight: "5%",
    fontSize: "12px",
    fontWeight: "bold",
  };

  return (
    <Box sx={{ maxWidth: 400 }}>
      <Stepper orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label} active={true}>
            <StepLabel
              optional={
                index === 3 ? (
                  <Typography color="textSecondary" variant="caption">
                    {
                      "Painamalla tallenna näppäintä voit tarkistaa ja tallentaa valitut ajat"
                    }
                  </Typography>
                ) : null
              }
            >
              <Typography color="textSecondary">{step.label}</Typography>
            </StepLabel>
            <StepContent disableGutters disablePadding>
              <Box sx={{ mb: 2 }}>
                <div>
                  {index === 0 && (
                    <>
                      <FormGroup>
                        {props.childList.map((child, index) => (
                          <>
                            <FormControlLabel
                              control={<Checkbox defaultChecked />}
                              label={child.Name}
                              onChange={() => handleSelectedChildren(child.Id)}
                            />
                          </>
                        ))}
                      </FormGroup>
                    </>
                  )}
                  {index === 1 && (
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <Stack spacing={1}>
                        <DatePicker
                          label="alku"
                          value={value2}
                          onChange={(newValue) => {
                            setValue2(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{ input: { color: "black" } }}
                            />
                          )}
                        />
                        <DatePicker
                          label="loppu"
                          value={value3}
                          onChange={(newValue) => {
                            setValue3(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{ input: { color: "black" } }}
                            />
                          )}
                        />
                      </Stack>
                    </LocalizationProvider>
                  )}
                  {index === 2 && (
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <Stack spacing={1}>
                        <TimePicker
                          label="alku "
                          value={value4}
                          onChange={(newValue) => {
                            setValue4(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{ input: { color: "black" } }}
                            />
                          )}
                          minutesStep={15}
                        />
                        <TimePicker
                          label="loppu"
                          value={value5}
                          onChange={(newValue) => {
                            setValue5(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{ input: { color: "black" } }}
                            />
                          )}
                          minutesStep={15}
                        />
                      </Stack>
                    </LocalizationProvider>
                  )}
                  {index === 3 && (
                    <Button
                      variant="contained"
                      sx={{ mt: 1, mr: 1 }}
                      onClick={() => handlePreviewClick()}
                    >
                      Tallenna
                    </Button>
                  )}
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      <CTBStepperSaveDialog
        isOpen={isOpen}
        handleConfirmDeleteDialog={handleConfirmDeleteDialog}
        checkResData={handlePreviewClick}
      ></CTBStepperSaveDialog>
    </Box>
  );
};

const CTBStepperSaveDialog = (props) => {
  const { actions, state } = useStateValue();
  const checkList = state.caretimes.checkList;
  return (
    <Dialog
      titleStyle={{ textAlign: "center" }}
      scroll="paper"
      open={props.isOpen}
      onClose={props.handleConfirmDeleteDialog}
    >
      <DialogTitle
        id="alert-dialog-title"
        style={{ backgroundColor: "#FFFFFF" }}
      >
        <Typography color="textSecondary">
          {"Tarkistetaan Varauksia"}
        </Typography>
      </DialogTitle>
      <DialogContent dividers style={{ backgroundColor: "#FFFFFF" }}>
        {checkList.state == types.CHECKING_CARETIMES && <CircularProgress />}

        {checkList.state == types.CARETIMES_CHECK_OK && (
          <>
            {checkList.checkData.OverTime && (
              <>
                <Typography color="textSecondary">
                  {
                    "Varauksistasi löytyi hoitoajan ylityksiä. Palaa takaisin ja lyhennä valittujen päivien pituutta tai poista lapset joiden hoitoaika ylittyy"
                  }
                </Typography>
                {checkList.checkData.OvertimeResponse.map((response, index) => (
                  <>
                    {response.Code === -2 && (
                      <>
                        <Divider sx={{ mt: 1 }} textAlign="center">
                          <Chip
                            sx={{ color: "black" }}
                            label={
                              <Typography
                                color="textSecondary"
                                fontSize="small"
                              >
                                {moment(
                                  response.GroupedErrors[0].ErrorDate
                                ).format("MMMM YYYY")}
                              </Typography>
                            }
                          ></Chip>
                        </Divider>

                        {response.GroupedErrors.map((error, index) => (
                          <>
                            <Typography color="textSecondary">
                              {error.ChildNames[0] +
                                " " +
                                error.ErrorString.split("Käytetty")[1]}
                            </Typography>
                          </>
                        ))}
                      </>
                    )}
                  </>
                ))}
              </>
            )}
            {!checkList.checkData.OverTime && (
              <>
                {checkList.checkData.HolidaysOnDates.length > 0 && (
                  <>
                    <Typography color="textSecondary">
                      {
                        "Varauksistasi löytyi seuraavat arkipyhät, Voit edelleen tallentaa valitut ajat mutta arkipyhille valittuja aikoja ei tallenneta"
                      }
                    </Typography>
                    {checkList.checkData.HolidaysOnDates.map(
                      (holiday, index) => (
                        <>
                          <Chip
                            sx={{ color: "black", mb: 1 }}
                            label={
                              <Typography
                                color="textSecondary"
                                fontSize="small"
                              >
                                {holiday.HolidayName +
                                  " " +
                                  moment(holiday.Date).format("ddd DD.MM.YYYY")}
                              </Typography>
                            }
                          ></Chip>
                        </>
                      )
                    )}
                  </>
                )}

                {checkList.checkData.NursingtimesExist && (
                  <>
                    <Typography color="textSecondary">
                      {
                        "Valituilta päiviltä löytyi olemassaolevia hoitoaikoja. Haluatko Poistaa olemassaolevat varaukset vai säilyttää ne? Jos säilytät olemassaolevat varaukset, uudet varaukset tehdään ainoastaan päiville joilla ei ole vielä varauksia."
                      }
                    </Typography>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="kyllä"
                        name="radio-buttons-group"
                      >
                        <FormControlLabel
                          value="kyllä"
                          control={<Radio />}
                          label={
                            <Typography color="textSecondary">
                              {"Kyllä"}
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value="ei"
                          control={<Radio />}
                          label={
                            <Typography color="textSecondary">
                              {"Ei"}
                            </Typography>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </>
                )}
              </>
            )}
          </>
        )}

        {checkList.state == types.CARETIMES_CHECK_FAILED && (
          <Typography color="textSecondary">
            {"hoitoaikojen lataaminen epäonnistui"}
          </Typography>
        )}
        <Box>
          <Button
            sx={{ ml: 2 }}
            variant="contained"
            color="success"
            size="medium"
            onClick={() => props.handleConfirmDeleteDialog()}
          >
            Palaa
          </Button>
          <Button
            sx={{ ml: 2 }}
            variant="contained"
            color="success"
            size="medium"
            onClick={() => props.handleConfirmDeleteDialog()}
          >
            Tallenna
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

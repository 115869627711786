import React, { useState, useEffect } from 'react';
import { useStateValue } from '../../State';
//import { Redirect } from 'react-router-dom';
import Snackbar from "@mui/material/Snackbar";
//import Messaging from '../../Layouts/Messaging';
import Alert, { AlertProps } from '@mui/material/Alert';


// note that this listener does not yet handle Consecutive Snackbars. 
// https://material-ui.com/components/snackbars/

const SnackbarListener = () => {

  const { state, actions } = useStateValue();
  const { snackbar } = state;
  const { messaging } = state;

  const [SnackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    //console.log("printing snackbar.postNewMessage.state", messaging.forSnackBar.state)
    if (messaging.forSnackBar.state === "POST_NEW_MESSAGE_SUCCESS" && SnackbarOpen === false) {
      setSnackbarOpen(true);
      setTimeout(() => {
        actions.triggerResetValues_Post_New_Message(); 
        setSnackbarOpen(false);
      }, 6000);
    }
  }, [SnackbarOpen, actions, messaging /*snackbar*/])
 
  return( 
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        //open="true"
        //message="hei"
        open={SnackbarOpen && snackbar.postNewMessage.status === "success"}
        //message={snackbar.postNewMessage.message}
        //style={{backgroundColor: "red !important"}}
        sx={{border: "solid 1px #006633", borderLeft: "solid 6px #006633"}}
      >
<Alert severity="success" sx={{backgroundColor: "#DCEEDF", /*borderLeft: "6px #006633", /*border: "solid 1px #8EC5A9"*/}}>{snackbar.postNewMessage.message}</Alert>
</Snackbar>
</>
    )
}

export default SnackbarListener;
import React, {useState} from "react";
import GuideAccordion from "../GuideTools/GuideAccordion";
import KeywordButton from "../GuideTools/KeywordButton";
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import Divider from '@mui/material/Divider'
import Languages from "../../../translations";


const MessagingGuideContent = () => {
  const [expanded, setExpanded] = useState('')
  
  
  const handlePanelExpand = (panelId) => (event, expanded) => {
    setExpanded(expanded ? panelId : false)
    //window.scrollTo({top: 100, behavior: 'smooth'});
     
    //isExpanded.scrollIntoView()
    setTimeout(window.scrollTo(0, panelId.offsetTop-100), 600) //moves the newly opened panel up offeset by 
 
  };

  const expandAcco = (panelId) => () =>{
      setExpanded(panelId)
  }



    return(
      <>
        <div className="guide-modal-keywords-container">
          <h6 className="guide-modal-keyword-header"><VpnKeyIcon color="primary"/>  {Languages.keywords}: </h6>
          <KeywordButton handleClick={expandAcco("id-guide-default-messaging")} buttonText={Languages.defaultMessagingKeywordButton} panelId="id-guide-default-messaging" buttonVariant="outlined"/>
          <KeywordButton handleClick={expandAcco("id-guide-messaging-buttons")} buttonText={Languages.messagingButtonKeywordButton} panelId="id-guide-messaging-buttons" buttonVariant="outlined" />
          <KeywordButton handleClick={expandAcco("id-guide-message-thread")} buttonText={Languages.messageThreadKeywordButton} panelId="id-guide-message-thread" buttonVariant="outlined" />
          <KeywordButton handleClick={expandAcco("id-guide-what-kind-of-messages")} buttonText={Languages.whatKindOfMessagesKeywordButton} panelId="id-guide-what-kind-of-messages" buttonVariant="outlined" />        
          <Divider variant="middle"/>
          <br />
        </div>
        <div className="booking-guide-content-conteiner">
          <GuideAccordion accordionTitle={Languages.defaultMessagingAccordionTitle} accordionTitleDescription={Languages.defaultMessagingAccordionDesc}  panelId="id-guide-default-messaging" handlePanelExpand={handlePanelExpand} isExpanded={expanded} accordionDetails="DEFAULT_MESSAGING_ACCORDION_TEXT"/>
          <GuideAccordion accordionTitle={Languages.messagingButtonsAccordionTitle} accordionTitleDescription={Languages.messagingButtonsAccordionDesc} panelId="id-guide-messaging-buttons" handlePanelExpand={handlePanelExpand} isExpanded={expanded} accordionDetails="MESSAGING_BUTTONS_ACCORDION_TEXT"/>      
          <GuideAccordion accordionTitle={Languages.messageThreadAccordionTitle} accordionTitleDescription={Languages.messageThreadAccordionDesc} panelId="id-guide-message-thread" handlePanelExpand={handlePanelExpand} isExpanded={expanded} accordionDetails="MESSAGE_THREAD_ACCORDION_TEXT"/>
          <GuideAccordion accordionTitle={Languages.whatKindOfMessagesAccordionTitle} accordionTitleDescription={Languages.whatKindOfMessagesAccordionDesc} handlePanelExpand={handlePanelExpand} panelId="id-guide-what-kind-of-messages" isExpanded={expanded} accordionDetails="WHAT_KIND_OF_MESSAGES_ACCORDION_TEXT"/>            
          <GuideAccordion accordionTitle={Languages.accordionTitleFAQ} accordionTitleDescription={Languages.accordionDescFAQ} handlePanelExpand={handlePanelExpand} panelId="id-guide-booking-faq" isExpanded={expanded} accordionDetails="NOT_READY"/>              
        </div>
      </>
    )
}

export default MessagingGuideContent
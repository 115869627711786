import React, {useState} from "react"
import ContentBoxMUI from "../../ContentBoxMUI"
import Languages from "../../../translations"
import makeStyles from '@mui/styles/makeStyles';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';

import ReplayIcon from '@mui/icons-material/Replay';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import DaisyLogo from '../../../images/Family-21.png';

//const useLightStylesDesktop = makeStyles((theme) => ({
  const errorStylesLight = makeStyles((theme) => ({
  cardActions: {
    backgroundColor: "white",
    width: "100%",
    color: "#4377BA",
    textAlign: "center",
    display: "grid",
  },
  cardContent: {
    backgroundColor: "white",
    width: "100%",
    color: "#4377BA",
    textAlign: "center",
  },
  button: {
    color: "#4377BA",
    textAlign: "center",
  },
  cardMedia: {
    backgroundColor: "#86B8FF",
    textAlign: "center",
    display: "-webkit-inline-box",
    display: "inline-box",
    maxWidth: "50%",
    borderRadius: "50px",
  },
  }))

const CareTimeBookingFailed = ({ errorCode }) => {
  const classes = errorStylesLight();
  return(
    <>
    <ContentBoxMUI>
      <Card elevation={24}>
        <CardContent className={classes.cardContent}>
          <CardMedia 
            className={classes.cardMedia}
            component="img"
            image={DaisyLogo}
            alt="DaisyFamily logo"
          />
          <br />
          <br />
          <Divider />
          <br />
          <Typography textAlign="center" variant="h5">Jotain meni pieleen!</Typography>
          <SentimentVeryDissatisfiedIcon fontSize="large" />
          <Typography textAlign="center">VIRHEKOODI: {errorCode}</Typography>
          <br />
          <Divider />
          <CardActions className={classes.cardActions}><Button className={classes.button} variant="outlined"><ReplayIcon /> Yritä uudelleen</Button></CardActions>
        </CardContent>
      </Card>
    </ContentBoxMUI>
    </>
  )
}

export default CareTimeBookingFailed
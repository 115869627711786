import React, { useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ChildProfilePicture from "../../../ChildProfilePicture";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { styled } from "@mui/material/styles";

const CareTimeCalendarChildSelectionDrawer = ({
  open,
  onClose,
  personName,
  handleNames,
  names,
}) => {
  return (
    <Drawer
      variant="persistent"
      anchor={"bottom"}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          maxWidth: 800,
          width: "100%",
          margin: "auto",
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          mb: "40px",
        },
      }}
      sx={{ zIndex: 0, backgroundColor: "white" }}
      transitionDuration={{ enter: 600, exit: 400 }}
    >
      <Box
        sx={{
          width: "auto",
          height: "auto",
          backgroundColor: "white",
          borderTopLeftRadius: "inherit",
          borderTopRightRadius: "inherit",
        }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        role="presentation"
      >
        <Puller onClick={onClose} />
        <ToggleButtonGroup
          sx={{ width: "100%", mt: 5, mb: 2 }}
          orientation="vertical"
          value={personName}
          onChange={handleNames}
        >
          {names.map((name) => (
            <ToggleButton
              key={name.Id}
              value={name.Id}
              sx={{
                width: "100%",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Box sx={{ width: "20%" }}>
                    <Avatar sx={{ width: 36, height: 36, mr: 5 }}>
                      <ChildProfilePicture childId={name.Id} />
                    </Avatar>
                  </Box>
                  <Box sx={{ width: "60%" }}>
                    <Typography
                      color="textSecondary"
                      fontSize="small"
                      textAlign="center"
                    >
                      {name.Name}
                    </Typography>
                  </Box>
                  <Box sx={{ width: "20%" }}>
                    {personName.indexOf(name.Id) > -1 && (
                      <CheckIcon color="success" />
                    )}
                    {personName.indexOf(name.Id) == -1 && (
                      <ClearIcon color="error" />
                    )}
                  </Box>
                </Stack>
              </Box>
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Box>
    </Drawer>
  );
};

export default CareTimeCalendarChildSelectionDrawer;

const Puller = styled(Box)(() => ({
  width: 30,
  height: 6,
  backgroundColor: "#cccccc",
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

import React from "react"
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Languages from "../../translations";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';



const GuideModalFooter = ({ handleClick }) => {

  const scroll = () => {
    const section = document.querySelector('#toTheTopInGuide');
    section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  };

    return(
        <div className="guide-modal-footer">
          <Divider />
          <div className="guide-modal-footer-left-column">
            <Button variant="outlined" className="close-guide-modal" color="secondary" onClick={() => handleClick()} title={Languages.generalClose} aria-label={Languages.generalClose}><CloseIcon /> {Languages.generalClose}</Button>
          </div>
          <div className="guide-modal-footer-right-column">
            <Button align="right" color="primary" onClick={() => scroll()} title={Languages.backToTop} aria-label={Languages.backToTop} ><ArrowUpwardIcon color="primary"/></Button>
          </div>
        </div>
    )
}

export default GuideModalFooter
import React, { useState } from "react";
import "./infotip.css";

import ClickAwayListener from "@mui/material/ClickAwayListener";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import HelpIcon from "@mui/icons-material/Help";

const InfoTip = ({ infotext, color }) => {
  const [isOpen, setIsOpen] = useState(false);

  //	don't remove the extra span because it is there so that one doesn't have to manually use forwardref
  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <span style={{ whiteSpace: "pre-line" }}>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={() => setIsOpen(false)}
          open={isOpen}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={infotext}
          placement="bottom-end"
        >
          <Icon color={color} onClick={() => setIsOpen(true)}>
            <HelpIcon sx={{ mt: -2 }} />
          </Icon>
        </Tooltip>
      </span>
    </ClickAwayListener>
  );
};

export default InfoTip;

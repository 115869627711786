// https://medium.com/front-end-weekly/writing-redux-like-simple-middleware-for-react-hooks-b163724a7058
import {
  MessagesApi,
  ServerApi,
  NursingTimesApi,
  ChildrenApi,
  MainStatsApi,
  ImageApi,
  SettingsApi,
  VasuApi,
  ApprovalApi,
  DailyNotesApi,
  LeopsApi,
  CareTimeApi,
} from "../internal";
import { types } from "./actionTypes";
import { ApiError, processResponse } from "../utils";
//import Auth from '../Auth';
import { LOGOUT_REASONS } from "../Api";
import translations from "../translations";
import { ATTACHMENT_TYPES } from "../Api";

// TODO: Implement axios error handling centralized here
// Check doc: https://github.com/axios/axios#handling-errors

export const ERROR_TYPES = {
  http: "HTTP",
  general: "general",
};

export const applyMiddleware = ({ dispatch, logout }) => {
  const nursingTimesApiInstance = new NursingTimesApi();
  // function which is called when api error detected
  const responseErrorFn = (response) => {};

  return async (action) => {
    const d1 = new Date();
    const timeNow = d1.getTime();

    switch (action.type) {
      case types.ASYNC_LOAD_DISCUSSION_TIMES:
        try {
          dispatch({ type: types.LOADING_DISCUSSION_TIMES });
          const response = await new ServerApi().loadDiscussionTimes(
            action.payload
          );

          processResponse(
            response,
            [200],
            "DiscussionTimes load failed",
            responseErrorFn
          );
          dispatch({
            type: types.LOAD_DISCUSSION_TIMES_SUCCESS,
            data: response.data,
          });
        } catch (error) {
          errorHandler({
            error,
            type: types.LOAD_DISCUSSION_TIMES_FAILED,
            dispatch,
            errorDescription: "DiscussionTimes Load Failed",
          });
        }
        break;

      case types.ASYNC_LOAD_AVAILABLE_DISC_SLOTS:
        try {
          dispatch({ type: types.LOADING_AVAILABLE_DISC_SLOTS });
          const response = await new ServerApi().loadDiscussionSlots(
            action.payload
          );

          processResponse(
            response,
            [200],
            "Available Discussion Slots load failed",
            responseErrorFn
          );
          dispatch({
            type: types.LOAD_AVAILABLE_DISC_SLOTS_SUCCESS,
            data: response.data,
          });
        } catch (error) {
          errorHandler({
            error,
            type: types.LOAD_AVAILABLE_DISC_SLOTS_FAILED,
            dispatch,
            errorDescription: "Available Discussion Slots Load Failed",
          });
        }
        break;

      case types.ASYNC_SAVE_DISCUSSION_BOOKING:
        try {
          dispatch({ type: types.SAVING_DISCUSSION_BOOKING });
          const response = await new ServerApi().saveDiscussionBooking(
            action.payload
          );

          processResponse(
            response,
            [200],
            "Save discussion booking failed",
            responseErrorFn
          );
          dispatch({
            type: types.SAVE_DISCUSSION_BOOKING_SUCCESS,
          });
        } catch (error) {
          errorHandler({
            error,
            type: types.SAVE_DISCUSSION_BOOKING_FAILED,
            dispatch,
            errorDescription: "Save discussion booking failed",
          });
        }
        break;

      case types.ASYNC_DELETE_DISCUSSION_BOOKING:
        try {
          dispatch({ type: types.DELETING_DISCUSSION_BOOKING });
          console.log("Middleware, deleten action", action);
          const response = await new ServerApi().deleteDiscussionBooking(
            action.payload
          );

          processResponse(
            response,
            [200],
            "Delete discussion booking failed",
            responseErrorFn
          );
          dispatch({
            type: types.DELETE_DISCUSSION_BOOKING_SUCCESS,
          });
        } catch (error) {
          errorHandler({
            error,
            type: types.DELETE_DISCUSSION_BOOKING_FAILED,
            dispatch,
            errorDescription: "Delete discussion booking failed",
          });
        }
        break;

      case types.ASYNC_EDIT_DISCUSSION_BOOKING:
        try {
          console.log("Alempana");
          console.log(action.payload);
          dispatch({ type: types.EDITING_DISCUSSION_BOOKING });
          const response = await new ServerApi().editDiscussionBooking(
            action.payload
          );

          processResponse(
            response,
            [200],
            "Edit discussion booking failed",
            responseErrorFn
          );
          dispatch({
            type: types.EDIT_DISCUSSION_BOOKING_SUCCESS,
          });
        } catch (error) {
          console.log(error);
          errorHandler({
            error,
            type: types.EDIT_DISCUSSION_BOOKING_FAILED,
            dispatch,
            errorDescription: "Edit discussion booking failed",
          });
        }
        break;

      case types.ASYNC_LOAD_ONE_CHILD_DISCUSSIONS:
        try {
          dispatch({ type: types.LOADING_ONE_CHILD_DISCUSSIONS });
          const response = await new ServerApi().loadOneChildDiscussions(
            action.payload
          );

          processResponse(
            response,
            [200],
            "Loading one child discussions failed",
            responseErrorFn
          );
          dispatch({
            type: types.LOAD_ONE_CHILD_DISCUSSIONS_SUCCESS,
            data: response.data,
          });
        } catch (error) {
          errorHandler({
            error,
            type: types.LOAD_ONE_CHILD_DISCUSSIONS_FAILED,
            dispatch,
            errorDescription: "Loading one child discussions failed",
          });
        }
        break;

      case types.ASYNC_GET_PARENTS_FOR_DISCUSSION_EDITING:
        try {
          dispatch({ type: types.LOADING_PARENTS_FOR_DISCUSSION_EDITING });
          const response = await new ServerApi().getDiscParentsForEditing(
            action.payload
          );

          processResponse(
            response,
            [200],
            "Getting parents failed",
            responseErrorFn
          );
          dispatch({
            type: types.GET_PARENTS_FOR_DISCUSSION_EDITING_SUCCESS,
            data: response.data,
          });
        } catch (error) {
          errorHandler({
            error,
            type: types.GET_PARENTS_FOR_DISCUSSION_EDITING_FAILED,
            dispatch,
            errorDescription: "Getting parents failed",
          });
        }
        break;

      case types.ASYNC_GET_DISCUSSIONNOTIFICATION_STATS:
        try {
          dispatch({ type: types.LOADING_DISCUSSIONNOTIFICATION_STATS });
          const response =
            await new ServerApi().loadDiscussionNotificationStats();

          processResponse(
            response,
            [200],
            "Loading discussion notification stats failed",
            responseErrorFn
          );
          dispatch({
            type: types.GET_DISCUSSIONNOTIFICATION_STATS_SUCCESS,
            data: response.data,
          });
        } catch (error) {
          errorHandler({
            error,
            type: GET_DISCUSSIONNOTIFICATION_STATS_FAILED,
            dispatch,
            errorDescription: "Loading discussion notification stats failed",
          });
        }
        break;

      case types.ASYNC_GET_DISCUSSION_TIMESELECTIONSTATUS:
        try {
          dispatch({ type: types.LOADING_DISCUSSION_TIMESELECTIONSTATUS });
          const response =
            await new ServerApi().loadDiscussionTimeSelectionStatus(
              action.payload
            );

          processResponse(
            response,
            [200],
            "Loading discussion timeselection status failed",
            responseErrorFn
          );
          dispatch({
            type: types.GET_DISCUSSION_TIMESELECTIONSTATUS_SUCCESS,
            data: response.data,
          });
        } catch (error) {
          errorHandler({
            error,
            type: types.GET_DISCUSSION_TIMESELECTIONSTATUS_FAILED,
            dispatch,
            errorDescription: "Loading discussion timeselection status failed",
          });
        }
        break;

      case types.ASYNC_SAVE_DISCUSSION_TIMESELECTIONSTATUS:
        try {
          dispatch({ type: types.SAVING_DISCUSSION_TIMESELECTIONSTATUS });
          const response =
            await new ServerApi().saveDiscussionTimeSelectionStatus(
              action.payload
            );

          processResponse(
            response,
            [200, 409],
            "Saving discussion timeselection status failed",
            responseErrorFn
          );
          if (response.status == 409) {
            dispatch({
              type: types.SAVE_DISCUSSION_TIMESELECTIONSTATUS_CONFLICT,
            });
          } else {
            dispatch({
              type: types.SAVE_DISCUSSION_TIMESELECTIONSTATUS_SUCCESS,
              data: response.data,
            });
          }
        } catch (error) {
          errorHandler({
            error,
            type: types.SAVE_DISCUSSION_TIMESELECTIONSTATUS_FAILED,
            dispatch,
            errorDescription: "Saving discussion timeselection status failed",
          });
        }
        break;

      case types.ASYNC_DELETE_DISCUSSION_TIMESELECTIONSTATUSES:
        try {
          dispatch({ type: types.DELETING_DISCUSSION_TIMESELECTIONSTATUSES });
          const response =
            await new ServerApi().deleteDiscussionTimeSelectionStatuses();
          process(
            response,
            [200],
            "Deleting timeselection statuses failed",
            responseErrorFn
          );
          dispatch({
            type: types.DELETE_DISCUSSION_TIMESELECTIONSTATUSES_SUCCESS,
          });
        } catch (error) {
          dispatch({
            type: types.DELETE_DISCUSSION_TIMESELECTIONSTATUSES_FAILED,
          });
        }
        break;

      case types.ASYNC_LOAD_TASKFOLDER:
        try {
          //console.log("MIDDLEWARE ASYNC_LOAD_TASKFOLDER, action.payload", action.payload);
          dispatch({ type: types.LOADING_TASKFOLDER });

          const response = await new ServerApi().loadTaskFolder(
            action.payload.childId,
            action.payload.folderId
          );
          //console.log("LOAD_TASKFOLDER response in middleware", response);
          processResponse(
            response,
            [200],
            "LOAD_TASKFOLDER loading failed",
            dispatch
          );
          dispatch({
            type: types.LOAD_TASKFOLDER_SUCCESS,
            data: response.data,
          });
        } catch (error) {
          if (error instanceof ApiError) {
            dispatch({
              type: types.LOAD_TASKFOLDER_FAILED,
              data: { Code: error.code },
            });
          } else {
            dispatch({
              type: types.LOAD_TASKFOLDER_FAILED,
              data: { Code: 777 },
            });
          }
        }
        break;

      case types.SET_MAINNAV_OPEN:
        dispatch({ type: types.SET_MAINNAV_OPEN, data: action.payload });
        break;

      case types.ASYNC_LOAD_FOLDERS:
        try {
          //console.log("MIDDLEWARE ASYNC_LOAD_FOLDERS, action.payload", action.payload);
          dispatch({ type: types.LOADING_FOLDERS });

          const response = await new ServerApi().loadFolders(
            action.payload.groups,
            action.payload.childId,
            action.payload.type,
            action.payload.sorting
          );
          //console.log("LOAD_FOLDERS response in middleware", response);
          processResponse(
            response,
            [200],
            "LOAD_FOLDERS loading failed",
            dispatch
          );
          dispatch({
            type: types.LOAD_FOLDERS_SUCCESS,
            data: response.data,
          });
        } catch (error) {
          if (error instanceof ApiError) {
            dispatch({
              type: types.LOAD_FOLDERS_FAILED,
              data: { Code: error.code },
            });
          } else {
            dispatch({
              type: types.LOAD_FOLDERS_FAILED,
              data: { Code: 777 },
            });
          }
        }
        break;

      case types.ASYNC_LOAD_IMAGES:
        try {
          //console.log("MIDDLEWARE ASYNC_LOAD_IMAGES, action.payload ", action.payload);
          dispatch({ type: types.LOADING_IMAGES });

          const response = await new ServerApi().loadImages(
            action.payload.childId,
            action.payload.folder
          );
          //console.log("LOAD_IMAGES response in middleware", response);
          processResponse(
            response,
            [200],
            "LOAD_IMAGES loading failed",
            dispatch
          );
          dispatch({
            type: types.LOAD_IMAGES_SUCCESS,
            data: response.data,
          });
        } catch (error) {
          if (error instanceof ApiError) {
            dispatch({
              type: types.LOAD_IMAGES_FAILED,
              data: { Code: error.code },
            });
          } else {
            dispatch({
              type: types.LOAD_IMAGES_FAILED,
              data: { Code: 777 },
            });
          }
        }
        break;

      case types.ASYNC_LOAD_IMAGE:
        try {
          //console.log("MIDDLEWARE ASYNC_LOAD_IMAGE, action.payload ", action.payload);
          dispatch({ type: types.LOADING_IMAGE });

          const response = await new ServerApi().loadFullImage(
            action.payload.ChildId,
            action.payload.Id
          );
          //console.log("LOAD_IMAGE response in middleware", response);
          processResponse(
            response,
            [200],
            "LOAD_IMAGE loading failed",
            dispatch
          );
          dispatch({
            type: types.LOAD_IMAGE_SUCCESS,
            data: response.data,
          });
        } catch (error) {
          if (error instanceof ApiError) {
            dispatch({
              type: types.LOAD_IMAGE_FAILED,
              data: { Code: error.code },
            });
          } else {
            dispatch({
              type: types.LOAD_IMAGE_FAILED,
              data: { Code: 777 },
            });
          }
        }
        break;

      case types.ASYNC_LOAD_DF_SETTINGS:
        try {
          dispatch({ type: types.LOADING_DF_SETTINGS });
          const response = await new ServerApi().loadDFSettings();

          processResponse(
            response,
            [200],
            "DF Settings loading failed",
            responseErrorFn
          );
          dispatch({
            type: types.LOAD_SUCCESS_DF_SETTINGS,
            data: response,
          });
        } catch (error) {
          if (error instanceof ApiError) {
            dispatch({
              type: types.LOAD_FAILED_DF_SETTINGS,
              data: { Code: error.Code },
            });
          } else {
            dispatch({
              type: types.LOAD_FAILED_DF_SETTINGS,
              data: { Code: 777 },
            });
          }
          console.error("middleware DF_SETTINGS load FAILED. Error: ", error);
        }
        break;

      case types.ASYNC_LOAD_MAINSTATS:
        try {
          dispatch({ type: types.LOADING_MAINSTATS });
          const response = await new MainStatsApi().loadMainStats(
            action.payload
          );

          processResponse(
            response,
            [200],
            "MainStats load failed",
            responseErrorFn
          );
          dispatch({
            type: types.LOAD_SUCCESS_MAINSTATS,
            data: response.data,
            lastSuccessTime: timeNow,
            lastSuccessTimeThread: timeNow,
            lastSuccessTimeNursingtime: timeNow,
            lastSuccessTimePermissions: timeNow,
          });
        } catch (error) {
          errorHandler({
            error,
            type: types.LOAD_FAILED_MAINSTATS,
            dispatch,
            errorDescription: "MainStats Load Failed",
          });
        }
        break;

      case types.ASYNC_LOAD_THREAD_MAINSTATS:
        try {
          dispatch({ type: types.LOADING_THREAD_MAINSTATS });
          const response = await new MainStatsApi().loadMainStats("thread");

          processResponse(
            response,
            [200],
            "MainStats Thread load failed",
            responseErrorFn
          );
          dispatch({
            type: types.LOAD_THREAD_MAINSTATS_SUCCESS,
            data: response.data,
            lastSuccessTimeThread: timeNow,
          });
        } catch (error) {
          errorHandler({
            error,
            type: types.LOAD_THREAD_MAINSTATS_FAILED,
            dispatch,
            errorDescription: "MainStats Thread Load Failed",
          });
        }
        break;

      case types.ASYNC_LOAD_NURSINGTIME_MAINSTATS:
        try {
          dispatch({ type: types.LOADING_NURSINGTIME_MAINSTATS });
          const response = await new MainStatsApi().loadMainStats(
            "nursingtime"
          );

          processResponse(
            response,
            [200],
            "MainStats Nursingtime load failed",
            responseErrorFn
          );
          dispatch({
            type: types.LOAD_NURSINGTIME_MAINSTATS_SUCCESS,
            data: response.data,
            lastSuccessTimeThread: timeNow,
          });
        } catch (error) {
          errorHandler({
            error,
            type: types.LOAD_NURSINGTIME_MAINSTATS_FAILED,
            dispatch,
            errorDescription: "MainStats Nursingtime Load Failed",
          });
        }
        break;

      case types.ASYNC_LOAD_PERMISSIONS_MAINSTATS:
        try {
          dispatch({ type: types.LOADING_PERMISSIONS_MAINSTATS });
          const response = await new MainStatsApi().loadMainStats(
            "permissions"
          );

          processResponse(
            response,
            [200],
            "MainStats Permissions load failed",
            responseErrorFn
          );
          dispatch({
            type: types.LOAD_PERMISSIONS_MAINSTATS_SUCCESS,
            data: response.data,
            lastSuccessTimeThread: timeNow,
          });
        } catch (error) {
          errorHandler({
            error,
            type: types.LOAD_PERMISSIONS_MAINSTATS_FAILED,
            dispatch,
            errorDescription: "MainStats Permissions Load Failed",
          });
        }
        break;

      case types.ASYNC_LOAD_PREVIEW_PICTURE:
        try {
          dispatch({ type: types.LOADING_PREVIEW_PICTURE });
          let response;
          if (action.payload.type == ATTACHMENT_TYPES.thumbnail) {
            response = await new ServerApi().downloadThumbnail(
              action.payload.imageId,
              action.payload.type
            );
          } else {
            response = await new ServerApi().downloadFile2(
              action.payload.imageId,
              action.payload.type
            );
          }

          processResponse(
            response,
            [200],
            "Loading preview picture failed",
            responseErrorFn
          );

          dispatch({
            type: types.LOAD_PREVIEW_PICTURE_SUCCESS,
            data: response.data,
            imageId: action.payload.imageId,
            code: response.status,
          });
        } catch (error) {
          if (error instanceof ApiError) {
            dispatch({
              type: types.LOAD_PREVIEW_PICTURE_FAILED,
              data: { Code: error.code },
            });
          } else {
            dispatch({
              type: types.LOAD_PREVIEW_PICTURE_FAILED,
              data: { Code: 500 },
            });
          }
        }
        break;

      case types.ASYNC_LOAD_FULL_IMAGE:
        try {
          dispatch({ type: types.LOADING_FULL_IMAGE });
          const response = await new ServerApi().downloadFile2(
            action.payload.imageId,
            action.payload.type
          );

          processResponse(
            response,
            [200],
            "Loading full image failed",
            responseErrorFn
          );

          dispatch({
            type: types.LOAD_FULL_IMAGE_SUCCESS,
            data: response.data,
            imageId: action.payload.imageId,
            code: response.status,
          });
        } catch (error) {
          if (error instanceof ApiError) {
            dispatch({
              type: types.LOAD_FULL_IMAGE_FAILED,
              data: { Code: error.code },
            });
          } else {
            dispatch({
              type: types.LOAD_FULL_IMAGE_FAILED,
              data: { Code: 777 },
            });
          }
        }
        break;

      case types.ASYNC_LOAD_CHILD_FILES:
        try {
          dispatch({ type: types.LOADING_CHILD_FILES });
          const response = await new ServerApi().loadChildFilesList(
            action.payload
          );

          processResponse(
            response,
            [200],
            "Load child files list failed",
            responseErrorFn
          );

          dispatch({
            type: types.CHILD_FILES_LOAD_SUCCESS,
            data: response.data,
            code: response.status,
          });
        } catch (error) {
          if (error instanceof ApiError) {
            dispatch({
              type: types.CHILD_FILES_LOAD_FAILED,
              code: error.response.status,
            });
          } else {
            dispatch({
              type: types.CHILD_FILES_LOAD_FAILED,
              code:
                error.response && error.response.status
                  ? error.response.status
                  : 777,
            });
          }
        }
        break;
      //NEW MESSAGE LOGIC

      case types.ASYNC_LOAD_MESSAGE_DATA:
        try {
          dispatch({ type: types.LOADING_MESSAGE_DATA });
          const response = await new MessagesApi().loadMessageData();

          processResponse(
            response,
            [200],
            "Load messages failed",
            responseErrorFn
          );

          dispatch({
            type: types.MESSAGE_DATA_LOAD_OK,
            data: response,
            lastSuccessTime: timeNow,
          });
        } catch (error) {
          // if has failed, don't erase the already existing messages []
          console.error("middleware LOAD MESSAGES FAILED error: ", error);

          if (error instanceof ApiError) {
            dispatch({
              type: types.MESSAGE_DATA_LOAD_FAILED,
              data: { Code: error.code },
              lastErrorTime: timeNow,
            });
          } else {
            dispatch({
              type: types.MESSAGE_DATA_LOAD_FAILED,
              data: { Code: 777 },
              lastErrorTime: timeNow,
            });
          }
        }
        break;
      ////////////////////////////////
      case types.ASYNC_LOAD_MESSAGES:
        try {
          dispatch({ type: types.LOADING_MESSAGES });
          const response = await new MessagesApi().loadMessages();

          processResponse(
            response,
            [200],
            "Load messages failed",
            responseErrorFn
          );

          dispatch({
            type: types.LOAD_MESSAGES_SUCCESS,
            data: response.data,
            lastSuccessTime: timeNow,
          });
        } catch (error) {
          // if has failed, don't erase the already existing messages []
          console.error("middleware LOAD MESSAGES FAILED error: ", error);

          if (error instanceof ApiError) {
            dispatch({
              type: types.LOAD_MESSAGES_FAILED,
              data: { Code: error.code },
              lastErrorTime: timeNow,
            });
          } else {
            dispatch({
              type: types.LOAD_MESSAGES_FAILED,
              data: { Code: 777 },
              lastErrorTime: timeNow,
            });
          }
        }
        break;

      //VIESTI ERROR
      case types.ASYNC_POST_NEW_MESSAGE:
        try {
          dispatch({ type: types.WAITING_POST_NEW_MESSAGE });
          const response = await new MessagesApi().PostNewMessage(
            action.payload
          );

          processResponse(
            response,
            [200],
            "Post new message failed",
            responseErrorFn
          );

          dispatch({
            type: types.POST_NEW_MESSAGE_SUCCESS,
            //data: response.data,
          });
        } catch (error) {
          if (error instanceof ApiError) {
            dispatch({
              type: types.POST_NEW_MESSAGE_FAILED,
              data: { Code: error.code },
            });
          } else {
            dispatch({
              type: types.POST_NEW_MESSAGE_FAILED,
              data: { Code: 500 },
            });
          }
        }
        break;

      case types.ASYNC_LOAD_MESSAGE_THREAD:
        try {
          dispatch({
            type: types.LOADING_MESSAGE_THREAD,
            payload: action.payload.threadId,
          });
          const response = await new MessagesApi().loadOneMessage(
            action.payload.threadId
          );
          processResponse(
            response,
            [200],
            "Load Message Thread failed ",
            responseErrorFn
          );
          dispatch({
            type: types.LOAD_MESSAGE_THREAD_SUCCESS,
            data: response.data,
            Code: response.status,
          });
        } catch (error) {
          if (error instanceof ApiError) {
            dispatch({
              type: types.LOAD_MESSAGE_THREAD_FAILED,
              data: { Code: error.code },
            });
          } else {
            dispatch({
              type: types.LOAD_MESSAGE_THREAD_FAILED,
              data: { Code: 777 },
            });
          }
        }
        break;

      case types.ASYNC_DELETE_MESSAGE_THREAD:
        try {
          dispatch({
            type: types.DELETE_MESSAGE_THREAD_LOADING,
            payload: action.payload.threadId,
          });
          const response = await new MessagesApi().deleteThread(
            action.payload.threadId
          );
          processResponse(
            response,
            [200],
            "Delete message thread from inbox failed ",
            responseErrorFn
          );
          dispatch({
            type: types.DELETE_MESSAGE_THREAD_SUCCESS,
            data: response.data,
            Code: response.status,
          });
        } catch (error) {
          errorHandler({
            error,
            type: types.DELETE_MESSAGE_THREAD_FAILED,
            dispatch,
            errorDescription: "Delete message thread Failed",
          });
          /*console.log("ERROR ON", error);
          console.log("error.responsedata", error.responsedata);
          if (error instanceof ApiError) {
            console.log("this is an api error")
            dispatch({
              type: types.DELETE_MESSAGE_THREAD_FAILED,
              data: error.responsedata,
              Code: error.code,
            })
          }
          else {
            console.log("this is a normal error")
            dispatch({
              type: types.DELETE_MESSAGE_THREAD_FAILED,
              data: error?.response.data !== null ? error.response.data : null,
              Code: 777,
            })
          }*/
        }
        break;

      case types.ASYNC_SET_THREAD_READ:
        //ei testattu tämän tallentamista yleis-stateen.
        try {
          dispatch({ type: types.WAITING_SET_THREAD_READ });
          const response = await new MessagesApi().SetThisThreadRead(
            action.payload
          );

          processResponse(
            response,
            [200],
            "Set Thread Read failed",
            responseErrorFn
          );
          dispatch({
            type: types.SET_THREAD_READ_SUCCESS,
            data: response.data,
            payload: action.payload,
          });
        } catch (error) {
          if (error instanceof ApiError) {
            dispatch({
              type: types.SET_THREAD_READ_FAILED,
              data: { Code: error.code },
            });
          } else {
            dispatch({
              type: types.SET_THREAD_READ_FAILED,
              data: { Code: 500 },
            });
          }
          console.error("middleware SET THREAD READ FAILED error: ", error);
        }
        break;

      case types.ASYNC_SET_ALL_MESSAGES_READ:
        try {
          dispatch({ type: types.LOADING_SET_ALL_MESSAGES_READ });
          const response = await new MessagesApi().setAllMessagesRead();
          console.log("EHDITTIIN TULLA TÄHÄN");
          processResponse(
            response,
            [200],
            "Set all messages read failed ",
            responseErrorFn
          );
          dispatch({
            type: types.SET_ALL_MESSAGES_READ_SUCCESS,
            //data: response.data,
            Code: response.status,
          });
        } catch (error) {
          errorHandler({
            error,
            type: types.SET_ALL_MESSAGES_READ_FAILED,
            dispatch,
            errorDescription: "Set all messages read failed",
          });
        }
        break;

      // VIESTI error
      case types.ASYNC_REPLYTO_MASS_THREAD:
        try {
          dispatch({ type: types.WAITING_REPLYTO_MASS_THREAD });
          const response = await new MessagesApi().ReplyToMassThread(
            action.payload
          );

          processResponse(
            response,
            [200],
            "Reply to MASS thread failed",
            responseErrorFn
          );
          dispatch({
            type: types.REPLYTO_MASS_THREAD_SUCCESS,
            data: response.data,
            payload: action.payload,
            ReplyToThreadLoadNewThread: response.newOrOldThread,
          });
        } catch (error) {
          if (error instanceof ApiError) {
            dispatch({
              type: types.REPLYTO_MASS_THREAD_FAILED,
              data: { Code: error.code },
            });
          } else {
            dispatch({
              type: types.REPLYTO_MASS_THREAD_FAILED,
              data: { Code: 500 },
            });
          }
        }
        break;

      case types.ASYNC_REPLYTO_NONMASS_THREAD:
        try {
          dispatch({ type: types.WAITING_REPLYTO_NONMASS_THREAD });
          const response = await new MessagesApi().ReplyToNonmassThread(
            action.payload
          );

          processResponse(
            response,
            [200],
            "Reply to NONMASS thread failed",
            responseErrorFn
          );
          dispatch({
            type: types.REPLYTO_NONMASS_THREAD_SUCCESS,
            data: response.data,
            ReplyToThreadLoadNewThread: response.newOrOldThread,
          });
        } catch (error) {
          errorHandler({
            error,
            type: types.REPLYTO_NONMASS_THREAD_FAILED,
            dispatch,
          });
        }
        break;

      case types.ASYNC_LOAD_POTENTIAL_RECIPIENTS:
        try {
          dispatch({ type: types.WAITING_LOAD_POTENTIAL_RECIPIENTS });
          const response = await new MessagesApi().LoadPotentialRecipients();
          processResponse(
            response,
            [200],
            "Load Potential Recipients failed",
            responseErrorFn
          );
          dispatch({
            type: types.LOAD_POTENTIAL_RECIPIENTS_SUCCESS,
            data: response.data,
          });
        } catch (error) {
          if (error instanceof ApiError) {
            dispatch({
              type: types.LOAD_POTENTIAL_RECIPIENTS_FAILED,
              data: { Code: error.code },
            });
          } else {
            dispatch({
              type: types.LOAD_POTENTIAL_RECIPIENTS_FAILED,
              data: { Code: 500 },
            });
          }
          //console.error("middleware LOAD POTENTIAL RECIPIENTS FAILED error: ", error);
        }
        break;

      case types.DEFAULT_NURSINGTIMES_HANDLED:
        dispatch({
          type: types.DEFAULT_NURSINGTIMES_HANDLED,
          data: action.payload,
        });
        break;
      case types.ASYNC_LOAD_DEFAULT_NURSINGTIMES:
        try {
          dispatch({ type: types.LOADING_DEFAULT_NURSINGTIMES });
          const response =
            await nursingTimesApiInstance.loadDefaultNursingTimes();
          processResponse(
            response,
            [200],
            "Load default nursingtimes failed",
            responseErrorFn
          );
          dispatch({
            type: types.LOAD_SUCCESS_DEFAULT_NURSINGTIMES,
            data: response.data,
          });
        } catch (error) {
          if (error instanceof ApiError) {
            dispatch({
              type: types.LOAD_FAILED_DEFAULT_NURSINGTIMES,
              data: { Code: error.code },
            });
          } else {
            dispatch({
              type: types.LOAD_FAILED_DEFAULT_NURSINGTIMES,
              data: { Code: 666 },
            });
          }
        }
        break;
      case types.ASYNC_SAVE_DEFAULT_NURSINGTIMES:
        try {
          dispatch({ type: types.SAVING_DEFAULT_NURSINGTIMES });
          const response =
            await nursingTimesApiInstance.saveDefaultNursingTimes(
              action.payload
            );
          //console.log("MIDDLEWARE response: ", response);
          processResponse(
            response,
            [200],
            "Saving default nursingtimes failed",
            responseErrorFn
          );

          dispatch({
            type: types.SAVING_SUCCESS_DEFAULT_NURSINGTIMES,
            data: { Start: response.Start, End: response.End },
          });
        } catch (error) {
          if (error instanceof ApiError) {
            dispatch({
              type: types.SAVING_FAILED_DEFAULT_NURSINGTIMES,
              data: { Code: error.code },
            });
          } else {
            dispatch({
              type: types.SAVING_FAILED_DEFAULT_NURSINGTIMES,
              data: { Code: 666 },
            });
          }
        }
        break;
      case types.ASYNC_LOAD_CLOCK_TYPE:
        try {
          dispatch({ type: types.LOADING_CLOCK_TYPE });
          const response = await new CareTimeApi().loadClockType();
          processResponse(
            response,
            [200],
            "Load clocktype failed",
            responseErrorFn
          );
          dispatch({
            type: types.CLOCK_TYPE_LOAD_OK,
            data: response.data,
          });
        } catch (error) {
          if (error instanceof ApiError) {
            dispatch({
              type: types.CLOCK_TYPE_LOAD_FAILED,
              data: { Code: error.code },
            });
          } else {
            dispatch({
              type: types.CLOCK_TYPE_LOAD_FAILED,
              data: { Code: 666 },
            });
          }
        }
        break;
      case types.ASYNC_SAVE_CLOCK_TYPE:
        try {
          dispatch({ type: types.SAVING_CLOCK_TYPE });
          const response = await new CareTimeApi().saveClockType(
            action.payload
          );
          //console.log("MIDDLEWARE response: ", response);
          processResponse(
            response,
            [200],
            "Saving clock type failed",
            responseErrorFn
          );

          dispatch({
            type: types.CLOCK_TYPE_SAVED_OK,
            data: response,
          });
        } catch (error) {
          if (error instanceof ApiError) {
            dispatch({
              type: types.CLOCK_TYPE_SAVED_FAILED,
              data: { Code: error.code },
            });
          } else {
            dispatch({
              type: types.CLOCK_TYPE_SAVED_FAILED,
              data: { Code: 666 },
            });
          }
        }
        break;

      case types.NEXTNURSINGTIMES_HANDLED:
        //console.log("MIDDLEWARE. NEXT NURSINGTIMES HANDLED. payload: ", action.payload);
        dispatch({
          type: types.NEXTNURSINGTIMES_HANDLED,
          data: action.payload,
        });
        break;

      case types.ASYNC_READ_NEXTNURSINGTIMES:
        try {
          dispatch({ type: types.READING_NEXTNURSINGTIMES });
          const response = await nursingTimesApiInstance.readNextNursingTimes(
            action.payload
          );
          //console.log("MIDDLEWARE. READ NEXTNURSINGTIMES response: ", response);
          processResponse(
            response,
            [200],
            "Read next nursingtimes failed",
            responseErrorFn
          );
          dispatch({ type: types.NEXTNURSINGTIMES_READ, data: response.data });
        } catch (error) {
          console.log(
            "MIDDLEWARE. READ NEXTNURSINGTIMES. CATCH ERROR: ",
            error
          );
          if (error instanceof ApiError) {
            dispatch({
              type: types.NEXTNURSINGTIMES_FAILED,
              data: { Code: error.code },
            });
          } else {
            dispatch({
              type: types.NEXTNURSINGTIMES_FAILED,
              data: { Code: 666 },
            });
          }
        }
        break;

      case types.NURSINGTIMES_READ_HANDLED:
        dispatch({
          type: types.NURSINGTIMES_READ_HANDLED,
          data: action.payload,
        });
        break;
      case types.NURSINGTIMES_FAILED_HANDLED:
        dispatch({
          type: types.NURSINGTIMES_FAILED_HANDLED,
          data: action.payload,
        });
        break;
      case types.ASYNC_READ_NURSINGTIMES:
        try {
          dispatch({ type: types.READING_NURSINGTIMES });
          const response = await nursingTimesApiInstance.readNursingTimes(
            action.payload
          );
          //console.log("MIDDLEWARE. READ NURSINGTIMES response: ", response);
          processResponse(
            response,
            [200],
            "Read next nursingtimes failed",
            responseErrorFn
          );
          dispatch({ type: types.NURSINGTIMES_READ, data: response.data });
        } catch (error) {
          //console.log("MIDDLEWARE. READ NURSINGTIMES. CATCH ERROR: ", error);
          if (error instanceof ApiError) {
            dispatch({
              type: types.NURSINGTIMES_FAILED,
              data: { Code: error.code },
            });
          } else {
            dispatch({ type: types.NURSINGTIMES_FAILED, data: { Code: 666 } });
          }
        }
        break;

      //CARETIMES WIP
      case types.ASYNC_LOAD_CARETIMES:
        try {
          dispatch({ type: types.LOADING_CARETIMES });
          const response = await new CareTimeApi().readNursingTimes2(
            action.payload
          );
          //console.log("MIDDLEWARE. READ NURSINGTIMES response: ", response);
          processResponse(
            response,
            [200],
            "Read next nursingtimes failed",
            responseErrorFn
          );
          dispatch({ type: types.CARETIMES_LOAD_OK, data: response.data });
        } catch (error) {
          //console.log("MIDDLEWARE. READ NURSINGTIMES. CATCH ERROR: ", error);
          if (error instanceof ApiError) {
            dispatch({
              type: types.CARETIMES_LOAD_FAILED,
              data: { Code: error.code },
            });
          } else {
            dispatch({
              type: types.CARETIMES_LOAD_FAILED,
              data: { Code: 666 },
            });
          }
        }
        break;

      case types.ASYNC_LOAD_CARETIMES_NEXT:
        try {
          dispatch({ type: types.LOADING_CARETIMES_NEXT });
          const response = await new CareTimeApi().readNursingTimesNext(
            action.payload
          );
          //console.log("MIDDLEWARE. READ NURSINGTIMES response: ", response);
          processResponse(
            response,
            [200],
            "Read next nursingtimes failed",
            responseErrorFn
          );
          dispatch({ type: types.CARETIMES_NEXT_LOAD_OK, data: response.data });
        } catch (error) {
          //console.log("MIDDLEWARE. READ NURSINGTIMES. CATCH ERROR: ", error);
          if (error instanceof ApiError) {
            dispatch({
              type: types.CARETIMES_NEXT_LOAD_FAILED,
              data: { Code: error.code },
            });
          } else {
            dispatch({
              type: types.CARETIMES_NEXT_LOAD_FAILED,
              data: { Code: 666 },
            });
          }
        }
        break;

      case types.ASYNC_LOAD_CARETIMES_PREV:
        try {
          dispatch({ type: types.LOADING_CARETIMES_PREV });
          const response = await new CareTimeApi().readNursingTimesPrev(
            action.payload
          );
          //console.log("MIDDLEWARE. READ NURSINGTIMES response: ", response);
          processResponse(
            response,
            [200],
            "Read next nursingtimes failed",
            responseErrorFn
          );
          dispatch({ type: types.CARETIMES_PREV_LOAD_OK, data: response.data });
        } catch (error) {
          //console.log("MIDDLEWARE. READ NURSINGTIMES. CATCH ERROR: ", error);
          if (error instanceof ApiError) {
            dispatch({
              type: types.CARETIMES_PREV_LOAD_FAILED,
              data: { Code: error.code },
            });
          } else {
            dispatch({
              type: types.CARETIMES_PREV_LOAD_FAILED,
              data: { Code: 666 },
            });
          }
        }
        break;

      case types.ASYNC_LOAD_CARETIMES_QUICK:
        try {
          dispatch({ type: types.LOADING_CARETIMES_QUICK });
          const response = await new CareTimeApi().readNursingTimesQuick(
            action.payload
          );
          //console.log("MIDDLEWARE. READ NURSINGTIMES response: ", response);
          processResponse(
            response,
            [200],
            "Read caretimes quick failed",
            responseErrorFn
          );
          dispatch({
            type: types.CARETIMES_QUICK_LOAD_OK,
            data: response.data,
          });
        } catch (error) {
          //console.log("MIDDLEWARE. READ NURSINGTIMES. CATCH ERROR: ", error);
          if (error instanceof ApiError) {
            dispatch({
              type: types.CARETIMES_QUICK_LOAD_FAILED,
              data: { Code: error.code },
            });
          } else {
            dispatch({
              type: types.CARETIMES_QUICK_LOAD_FAILED,
              data: { Code: 666 },
            });
          }
        }
        break;

      case types.ASYNC_LOAD_CARETIMES_QUICK_NEXT:
        try {
          dispatch({ type: types.LOADING_CARETIMES_QUICK_NEXT });
          const response = await new CareTimeApi().readNursingTimesQuickNext(
            action.payload
          );
          //console.log("MIDDLEWARE. READ NURSINGTIMES response: ", response);
          processResponse(
            response,
            [200],
            "Read caretimes quick failed",
            responseErrorFn
          );
          dispatch({
            type: types.CARETIMES_QUICK_LOAD_NEXT_OK,
            data: response.data,
          });
        } catch (error) {
          //console.log("MIDDLEWARE. READ NURSINGTIMES. CATCH ERROR: ", error);
          if (error instanceof ApiError) {
            dispatch({
              type: types.CARETIMES_QUICK_LOAD_NEXT_FAILED,
              data: { Code: error.code },
            });
          } else {
            dispatch({
              type: types.CARETIMES_QUICK_LOAD_NEXT_FAILED,
              data: { Code: 666 },
            });
          }
        }
        break;
      case types.ASYNC_DELETE_CARETIMES:
        try {
          dispatch({ type: types.DELETING_CARETIMES });
          const response = await new CareTimeApi().deleteCaretimes(
            action.payload
          );
          processResponse(response, [200], response.message, responseErrorFn);

          dispatch({
            type: types.CARETIMES_DELETED_OK,
            data: response.data,
          });
        } catch (error) {
          //console.error("MIDDLEWARE. ERROR Deleting nursingtimes Many. Status: ", error.code, " msg: ", error.message);
          if (error instanceof ApiError) {
            dispatch({
              type: types.CARETIMES_DELETED_FAILED,
              data: { Code: error.code, Message: error.message },
            });
          } else {
            dispatch({
              type: types.CARETIMES_DELETED_FAILED,
              data: { Code: 666 },
            });
          }
        }
        break;
      case types.ASYNC_CHECK_CARETIMES:
        try {
          dispatch({ type: types.CHECKING_CARETIMES });
          const response = await new CareTimeApi().checkCareTimes(
            action.payload
          );
          processResponse(
            response,
            [200],
            "Read next nursingtimes failed",
            responseErrorFn
          );
          dispatch({ type: types.CARETIMES_CHECK_OK, data: response.data });
        } catch (error) {
          //console.log("MIDDLEWARE. READ NURSINGTIMES. CATCH ERROR: ", error);
          if (error instanceof ApiError) {
            dispatch({
              type: types.CARETIMES_CHECK_FAILED,
              data: { Code: error.code },
            });
          } else {
            dispatch({
              type: types.CARETIMES_CHECK_FAILED,
              data: { Code: 666 },
            });
          }
        }
        break;

      case types.NURSINGTIMES_DELETED_HANDLED:
        dispatch({
          type: types.NURSINGTIMES_DELETED_HANDLED,
          data: action.payload,
        });
        break;

      case types.ASYNC_DELETE_MANY_NURSINGTIMES:
        try {
          dispatch({ type: types.DELETING_NURSINGTIMES });
          const response = await nursingTimesApiInstance.deleteNursingTimesMany(
            action.payload
          );
          processResponse(response, [200], response.message, responseErrorFn);

          dispatch({
            type: types.NURSINGTIMES_DELETED_OK,
            data: response.data,
          });
        } catch (error) {
          //console.error("MIDDLEWARE. ERROR Deleting nursingtimes Many. Status: ", error.code, " msg: ", error.message);
          if (error instanceof ApiError) {
            dispatch({
              type: types.NURSINGTIMES_DELETED_FAILED,
              data: { Code: error.code, Message: error.message },
            });
          } else {
            dispatch({
              type: types.NURSINGTIMES_DELETED_FAILED,
              data: { Code: 666 },
            });
          }
        }
        break;
      case types.ASYNC_DELETE_NURSINGTIMES:
        try {
          dispatch({ type: types.DELETING_NURSINGTIMES });
          const response = await nursingTimesApiInstance.deleteNursingTimes(
            action.payload
          );
          //console.log("MIDDLEWARE. Deleting nursingtimes response: ", response);
          processResponse(response, [200], response.message, responseErrorFn);

          dispatch({
            type: types.NURSINGTIMES_DELETED_OK,
            data: response.data,
          });
        } catch (error) {
          //console.error("MIDDLEWARE. ERROR Deleting nursingtimes. Status: ", error.code, " msg: ", error.message);
          if (error instanceof ApiError) {
            dispatch({
              type: types.NURSINGTIMES_DELETED_FAILED,
              data: { Code: error.code, Message: error.message },
            });
          } else {
            dispatch({
              type: types.NURSINGTIMES_DELETED_FAILED,
              data: { Code: 666 },
            });
          }
        }
        break;

      case types.NURSINGTIMES_SAVED_OK_HANDLED:
        dispatch({
          type: types.NURSINGTIMES_SAVED_OK_HANDLED,
          data: action.payload,
        });
        break;
      case types.NURSINGTIMES_SAVED_FAILED_HANDLED:
        dispatch({
          type: types.NURSINGTIMES_SAVED_FAILED_HANDLED,
          data: action.payload,
        });
        break;
      case types.ASYNC_SAVE_NURSINGTIMES:
        try {
          dispatch({ type: types.SAVING_NURSINGTIMES });
          const response = await nursingTimesApiInstance.saveNursingTimes(
            action.payload
          );

          //console.log("MIDDLEWARE. SAVE NURSINGTIMES OK response: ", response);

          processResponse(response, [200], response.message, responseErrorFn);
          dispatch({ type: types.NURSINGTIMES_SAVED_OK, data: response.data });
        } catch (error) {
          /*console.log("MIDDLEWARE. SAVE NURSINGTIMES. CATCH ERROR: ", error);
          console.log("MIDDLEWARE. SAVE NURSINGTIMES. CATCH error.code: ", error.code+
                      "\n message: ", error.message);*/

          if (error instanceof ApiError) {
            dispatch({
              type: types.NURSINGTIMES_SAVED_FAILED,
              data: { Code: error.code, Message: error.message },
            });
          } else {
            dispatch({
              type: types.NURSINGTIMES_SAVED_FAILED,
              data: { Code: 666 },
            });
          }
        }
        break;

      case types.ASYNC_SAVE_CARETIMES:
        try {
          dispatch({ type: types.SAVING_CARETIMES });
          const response = await new CareTimeApi().saveCareTimes(
            action.payload
          );

          //console.log("MIDDLEWARE. SAVE NURSINGTIMES OK response: ", response);

          processResponse(response, [200], response.message, responseErrorFn);
          dispatch({ type: types.CARETIMES_SAVED_OK, data: response.data });
        } catch (error) {
          /*console.log("MIDDLEWARE. SAVE NURSINGTIMES. CATCH ERROR: ", error);
          console.log("MIDDLEWARE. SAVE NURSINGTIMES. CATCH error.code: ", error.code+
                      "\n message: ", error.message);*/

          if (error instanceof ApiError) {
            dispatch({
              type: types.CARETIMES_SAVED_FAILED,
              data: { Code: error.response.status, Message: error.message },
            });
          } else {
            dispatch({
              type: types.CARETIMES_SAVED_FAILED,
              data: { Code: error.response.status, Message: error.message },
            });
          }
        }
        break;

      case types.ASYNC_SAVE_ABSENT_CARETIMES:
        try {
          dispatch({ type: types.SAVING_CARETIMES_ABSENT });
          const response = await new CareTimeApi().saveCareTimesAbsent(
            action.payload
          );

          //console.log("MIDDLEWARE. SAVE NURSINGTIMES OK response: ", response);
          console.log(action.payload);
          processResponse(response, [200], response.message, responseErrorFn);
          dispatch({
            type: types.CARETIMES_ABSENT_SAVED_OK,
            data: { responseData: response.data, payloadData: action.payload },
          });
        } catch (error) {
          /*console.log("MIDDLEWARE. SAVE NURSINGTIMES. CATCH ERROR: ", error);
            console.log("MIDDLEWARE. SAVE NURSINGTIMES. CATCH error.code: ", error.code+
                        "\n message: ", error.message);*/

          if (error instanceof ApiError) {
            dispatch({
              type: types.CARETIMES_ABSENT_SAVED_FAILED,
              data: { Code: error.code, Message: error.message },
            });
          } else {
            dispatch({
              type: types.CARETIMES_ABSENT_SAVED_FAILED,
              data: { Code: 666 },
            });
          }
        }
        break;

      case types.ASYNC_LOAD_LEOPS:
        try {
          dispatch({ type: types.LOADING_LEOPS });
          const response = await new LeopsApi().loadLeops(action.payload);
          //console.log("MIDDLEWARE. LOAD LEOPS response: ", response);
          processResponse(
            response,
            [200],
            "Loading Leops failed",
            responseErrorFn
          );
          dispatch({ type: types.LEOPS_LOAD_OK, data: response.data });
        } catch (error) {
          //console.log("MIDDLEWARE. LOAD LEOPS. CATCH ERROR: ", error);
          if (error instanceof ApiError) {
            dispatch({
              type: types.LEOPS_LOAD_FAILED,
              data: { Code: error.code },
            });
          } else {
            dispatch({
              type: types.LEOPS_LOAD_FAILED,
              data: {
                Code:
                  error.response && error.response.status
                    ? error.response.status
                    : undefined,
                Message:
                  error.response &&
                  error.response.data &&
                  error.response.data !== ""
                    ? error.response.data.message
                    : undefined,
              },
            });
          }
        }
        break;

      case types.ASYNC_SAVE_LOCKING_LEOPS:
        try {
          dispatch({ type: types.SAVING_LEOPS });
          const response = await new LeopsApi().saveLockingLeops(
            action.payload
          );
          //console.log("MIDDLEWARE. SAVE_LOCKING_LEOPS response: ", response);
          processResponse(
            response,
            [200],
            "Saving Leops failed",
            responseErrorFn
          );
          dispatch({ type: types.LEOPS_SAVED_OK, data: response.data });
        } catch (error) {
          if (error instanceof ApiError) {
            // console.log("MIDDLEWARE. SAVE_LOCKING_LEOPS. CATCH ApiError ERROR: ", error);
            dispatch({
              type: types.LEOPS_SAVED_FAILED,
              data: { Code: error.code },
            });
          } else {
            dispatch({
              type: types.LEOPS_SAVED_FAILED,
              data: {
                Code:
                  error.response && error.response.status
                    ? error.response.status
                    : undefined,
                Message:
                  error.response &&
                  error.response.data &&
                  error.response.data !== ""
                    ? error.response.data.message
                    : undefined,
              },
            });
          }
        }
        break;
      case types.ASYNC_SAVE_STUDYFORM:
        try {
          dispatch({ type: types.SAVING_STUDYFORM });
          const response = await new LeopsApi().saveStudyFormLeops(
            action.payload
          );
          processResponse(
            response,
            [200],
            "Saving Study Form failed",
            responseErrorFn
          );
          dispatch({ type: types.STUDYFORM_SAVED_OK, data: response.data });
        } catch (error) {
          if (error instanceof ApiError) {
            // console.log("MIDDLEWARE. SAVE_LOCKING_LEOPS. CATCH ApiError ERROR: ", error);
            dispatch({
              type: types.STUDYFORM_SAVED_FAILED,
              data: { Code: error.code },
            });
          } else {
            dispatch({
              type: types.STUDYFORM_SAVED_FAILED,
              data: {
                Code:
                  error.response && error.response.status
                    ? error.response.status
                    : undefined,
                Message:
                  error.response &&
                  error.response.data &&
                  error.response.data !== ""
                    ? error.response.data.message
                    : undefined,
              },
            });
          }
        }
        break;
      case types.ASYNC_SAVE_CHILD_EVALUATIONS_LEOPS:
        try {
          dispatch({ type: types.SAVING_LEOPS });
          const response = await new LeopsApi().saveChildEvaluationsLeops(
            action.payload
          );
          //console.log("MIDDLEWARE. SAVE_CHILD_EVALUATIONS_LEOPS response: ", response);
          processResponse(
            response,
            [200],
            "Saving Leops failed",
            responseErrorFn
          );
          dispatch({ type: types.LEOPS_SAVED_OK, data: response.data });
        } catch (error) {
          if (error instanceof ApiError) {
            console.log(
              "MIDDLEWARE. SAVE_CHILD_EVALUATIONS. CATCH ApiError ERROR: ",
              error
            );
            dispatch({
              type: types.LEOPS_SAVED_FAILED,
              data: { Code: error.code },
            });
          } else {
            dispatch({
              type: types.LEOPS_SAVED_FAILED,
              data: {
                Code:
                  error.response && error.response.status
                    ? error.response.status
                    : undefined,
                Message:
                  error.response &&
                  error.response.data &&
                  error.response.data !== ""
                    ? error.response.data.Message
                    : undefined,
              },
            });
          }
        }
        break;
      case types.ASYNC_SAVE_PARENT_COMMENTS_LEOPS:
        try {
          dispatch({ type: types.SAVING_LEOPS });
          const response = await new LeopsApi().saveParentCommentsLeops(
            action.payload
          );
          //console.log("MIDDLEWARE. SAVE_CHILD_EVALUATIONS_LEOPS response: ", response);
          processResponse(
            response,
            [200],
            "Saving Leops failed",
            responseErrorFn
          );
          dispatch({ type: types.LEOPS_SAVED_OK, data: response.data });
        } catch (error) {
          if (error instanceof ApiError) {
            console.log(
              "MIDDLEWARE. SAVE_CHILD_EVALUATIONS. CATCH ApiError ERROR: ",
              error
            );
            dispatch({
              type: types.LEOPS_SAVED_FAILED,
              data: { Code: error.code },
            });
          } else {
            dispatch({
              type: types.LEOPS_SAVED_FAILED,
              data: {
                Code:
                  error.response && error.response.status
                    ? error.response.status
                    : undefined,
                Message:
                  error.response &&
                  error.response.data &&
                  error.response.data !== ""
                    ? error.response.data.Message
                    : undefined,
              },
            });
          }
        }
        break;

      case types.ASYNC_LOAD_VASU:
        try {
          dispatch({ type: types.LOADING_VASU });
          const response = await new VasuApi().loadVasu(action.payload);
          //console.log("MIDDLEWARE. LOAD VASU response: ", response);
          processResponse(
            response,
            [200],
            "Loading Vasu failed",
            responseErrorFn
          );
          dispatch({ type: types.VASU_LOAD_OK, data: response.data });
        } catch (error) {
          //console.log("MIDDLEWARE. LOAD VASU. CATCH ERROR: ", error);
          if (error instanceof ApiError) {
            dispatch({
              type: types.VASU_LOAD_FAILED,
              data: { Code: error.code },
            });
          } else {
            dispatch({ type: types.VASU_LOAD_FAILED, data: { Code: 666 } });
          }
        }
        break;

      case types.ASYNC_LOAD_DAILY_NOTES:
        try {
          dispatch({ type: types.LOADING_DAILY_NOTES });
          const response = await new DailyNotesApi().LoadDailyNotes(
            action.payload
          );
          //console.log("MIDDLEWARE. LOAD VASU response: ", response);
          processResponse(
            response,
            [200],
            "Loading Daily Notes failed",
            responseErrorFn
          );
          dispatch({ type: types.DAILY_NOTES_LOAD_OK, data: response.data });
        } catch (error) {
          //console.log("MIDDLEWARE. LOAD VASU. CATCH ERROR: ", error);
          if (error instanceof ApiError) {
            dispatch({
              type: types.DAILY_NOTES_LOAD_FAILED,
              data: { Code: error.code },
            });
          } else {
            dispatch({
              type: types.DAILY_NOTES_LOAD_FAILED,
              data: { Code: 666 },
            });
          }
        }
        break;

      case types.ASYNC_SAVE_VASU:
        try {
          dispatch({ type: types.SAVING_VASU });
          const response = await new VasuApi().saveVasu(action.payload);
          processResponse(
            response,
            [200],
            "Saving Vasu failed",
            responseErrorFn
          );
          dispatch({ type: types.VASU_SAVED_OK, data: response.data });
        } catch (error) {
          //console.error("MIDDLEWARE. OTHER ERROR Loading children: ", error, "\n error.response.status: ", error.response.status);
          if (error instanceof ApiError) {
            dispatch({
              type: types.VASU_SAVED_FAILED,
              data: { Code: error.code },
            });
          } else {
            dispatch({
              type: types.VASU_SAVED_FAILED,
              data: {
                Code:
                  error.response === undefined ? 777 : error.response.status,
              },
            });
          }
        }
        break;

      case types.ASYNC_SAVE_APPROVAL:
        try {
          dispatch({ type: types.SAVING_APPROVAL });
          const response = await new ApprovalApi().saveApproval(action.payload);
          processResponse(
            response,
            [200],
            "Saving Approval failed",
            responseErrorFn
          );
          dispatch({ type: types.APPROVAL_SAVED_OK, data: response.data });
        } catch (error) {
          //console.error("MIDDLEWARE. OTHER ERROR Loading children: ", error, "\n error.response.status: ", error.response.status);
          if (error instanceof ApiError) {
            dispatch({
              type: types.APPROVAL_SAVED_FAILED,
              data: { Code: error.code },
            });
          } else {
            dispatch({
              type: types.APPROVAL_SAVED_FAILED,
              data: {
                Code:
                  error.response === undefined ? 777 : error.response.status,
              },
            });
          }
        }
        break;

      case types.ASYNC_LOAD_INVOICE_SUMMARY_TABLE:
        try {
          dispatch({ type: types.LOADING_INVOICE_SUMMARY_TABLE });
          //console.log("middleware action.payload", action.payload);
          const response = await new ServerApi().getSummaryTable(
            action.payload
          );

          processResponse(
            response,
            [200],
            "Loading invoice summary table failed",
            responseErrorFn
          );
          dispatch({
            type: types.INVOICE_SUMMARY_TABLE_LOAD_SUCCESS,
            data: response.data,
            Code: response.status,
          });
        } catch (error) {
          if (error instanceof ApiError) {
            dispatch({
              type: types.INVOICE_SUMMARY_TABLE_LOAD_FAILED,
              data: { Code: error.code },
            });
          } else {
            dispatch({
              type: types.INVOICE_SUMMARY_TABLE_LOAD_FAILED,
              data: { Code: 777 },
            });
          }
        }
        break;

      case types.ASYNC_LOAD_NT_SUMMARY:
        try {
          dispatch({ type: types.LOADING_NT_SUMMARY });
          const response = await new ServerApi().getSummary(action.payload);

          processResponse(
            response,
            [200],
            "Loading nursingtimes summary failed",
            responseErrorFn
          );
          dispatch({
            type: types.NT_SUMMARY_LOAD_SUCCESS,
            data: response.data,
            Code: response.status,
          });
        } catch (error) {
          if (error instanceof ApiError) {
            dispatch({
              type: types.NT_SUMMARY_LOAD_FAILED,
              data: { Code: error.code },
            });
          } else {
            dispatch({
              type: types.NT_SUMMARY_LOAD_FAILED,
              data: { Code: 777 },
            });
          }
        }
        break;

      case types.ASYNC_LOAD_PL_CENTRE_INFOS:
        try {
          dispatch({ type: types.LOADING_PL_CENTRE_INFOS });
          const response = await new ServerApi().getcentreinfos(action.payload);

          processResponse(
            response,
            [200],
            "Loading placement and centre infos failed",
            responseErrorFn
          );
          dispatch({
            type: types.PL_CENTRE_INFOS_LOAD_SUCCESS,
            data: response.data,
            Code: response.status,
          });
        } catch (error) {
          if (error instanceof ApiError) {
            dispatch({
              type: types.PL_CENTRE_INFOS_LOAD_FAILED,
              data: { Code: error.code },
            });
          } else {
            dispatch({
              type: types.PL_CENTRE_INFOS_LOAD_FAILED,
              data: { Code: 777 },
            });
          }
        }

        break;

      case types.ASYNC_LOAD_CHILD_PERMPERMISSIONS:
        try {
          dispatch({ type: types.LOADING_CHILD_PERMPERMISSIONS });
          const response = await new ServerApi().getMunicipalPermissions(
            action.payload
          );
          console.log("response:", response);
          processResponse(
            response,
            [200],
            "Loading municipal permission questions failed",
            responseErrorFn
          );
          dispatch({
            type: types.LOAD_CHILD_PERMPERMISSIONS_SUCCESS,
            data: response.data,
            Code: response.status,
          });
        } catch (error) {
          if (error instanceof ApiError) {
            dispatch({
              type: types.LOAD_CHILD_PERMPERMISSIONS_FAILED,
              data: { Code: error.code },
            });
          } else {
            dispatch({
              type: types.LOAD_CHILD_PERMPERMISSIONS_FAILED,
              data: { Code: 777 },
            });
          }
        }

        break;

      case types.ASYNC_POST_CHILD_PERMPERMISSIONS:
        try {
          dispatch({ type: types.LOADING_POST_CHILD_PERMPERMISSIONS });
          const response = await new ServerApi().postChildPermPermissions(
            action.payload
          );

          processResponse(
            response,
            [200],
            "Posting child perm permissions failed",
            responseErrorFn
          );
          dispatch({
            type: types.POST_CHILD_PERMPERMISSIONS_SUCCESS,
            //data: response.data,
            Code: response.status,
          });
        } catch (error) {
          if (error instanceof ApiError) {
            dispatch({
              type: types.POST_CHILD_PERMPERMISSIONS_FAILED,
              data: { Code: error.code },
            });
          } else {
            dispatch({
              type: types.POST_CHILD_PERMPERMISSIONS_FAILED,
              data: { Code: 777 },
            });
          }
        }

        break;

      case types.ASYNC_LOAD_GUARDIAN_CONSENTS:
        try {
          dispatch({ type: types.LOADING_GUARDIAN_CONSENTS });
          const response = await new ServerApi().getGuardianConsents(
            action.payload
          );

          processResponse(
            response,
            [200],
            "Loading guardian consents failed",
            responseErrorFn
          );
          dispatch({
            type: types.LOAD_GUARDIAN_CONSENTS_SUCCESS,
            data: response.data,
            Code: response.status,
          });
        } catch (error) {
          if (error instanceof ApiError) {
            dispatch({
              type: types.LOAD_GUARDIAN_CONSENTS_FAILED,
              data: { Code: error.code },
            });
          } else {
            dispatch({
              type: types.LOAD_GUARDIAN_CONSENTS_FAILED,
              data: { Code: 777 },
            });
          }
        }

        break;

      case types.ASYNC_POST_GUARDIAN_CONSENTS:
        try {
          dispatch({ type: types.LOADING_POST_GUARDIAN_CONSENTS });
          const response = await new ServerApi().postGuardianConsents(
            action.payload
          );

          processResponse(
            response,
            [200],
            "Posting guardian consents failed",
            responseErrorFn
          );
          dispatch({
            type: types.POST_GUARDIAN_CONSENTS_SUCCESS,
            //data: response.data,
            Code: response.status,
          });
        } catch (error) {
          if (error instanceof ApiError) {
            dispatch({
              type: types.POST_GUARDIAN_CONSENTS_FAILED,
              data: { Code: error.code },
            });
          } else {
            dispatch({
              type: types.POST_GUARDIAN_CONSENTS_FAILED,
              data: { Code: 777 },
            });
          }
        }

        break;

      case types.ASYNC_LOAD_PERMISSION_QUERIES:
        try {
          dispatch({ type: types.LOADING_PERMISSION_QUERIES });
          const response = await new ServerApi().loadPermissionQueries();

          processResponse(
            response,
            [200],
            "Permission queries load failed",
            responseErrorFn
          );
          dispatch({
            type: types.LOAD_PERMISSION_QUERIES_SUCCESS,
            data: response.data,
          });
        } catch (error) {
          errorHandler({
            error,
            type: types.LOAD_PERMISSION_QUERIES_FAILED,
            dispatch,
            errorDescription: "Permission Queries Load Failed",
          });
        }
        break;

      case types.ASYNC_LOAD_SINGLE_PERMISSION_QUERY:
        try {
          dispatch({ type: types.LOADING_SINGLE_PERMISSION_QUERY });
          const response = await new ServerApi().loadSinglePermissionQuery(
            action.payload
          );

          processResponse(
            response,
            [200],
            "Single permission query load failed",
            responseErrorFn
          );
          dispatch({
            type: types.LOAD_SINGLE_PERMISSION_QUERY_SUCCESS,
            data: response.data,
          });
        } catch (error) {
          errorHandler({
            error,
            type: types.LOAD_SINGLE_PERMISSION_QUERY_FAILED,
            dispatch,
            errorDescription: "Single Permission Query Load Failed",
          });
        }
        break;

      case types.ASYNC_POST_SINGLE_PERMISSION_QUERY:
        try {
          dispatch({ type: types.LOADING_POST_SINGLE_PERMISSION_QUERY });
          const response = await new ServerApi().postSinglePermissionQuery(
            action.payload
          );

          processResponse(
            response,
            [200],
            "Post single permission query failed",
            responseErrorFn
          );
          dispatch({
            type: types.POST_SINGLE_PERMISSION_QUERY_SUCCESS,
            data: response.data,
          });
        } catch (error) {
          errorHandler({
            error,
            type: types.POST_SINGLE_PERMISSION_QUERY_FAILED,
            dispatch,
            errorDescription: "Post Single Permission Query Failed",
          });
        }
        break;

      case types.ASYNC_LOAD_VACATION_STATUS:
        try {
          dispatch({ type: types.ASYNC_LOAD_VACATION_STATUS });
          const response = await new ServerApi().getVacationStatus(
            action.payload
          );

          processResponse(
            response,
            [200],
            "Loading vacation status failed",
            responseErrorFn
          );
          dispatch({
            type: types.LOAD_VACATION_STATUS_SUCCESS,
            data: response.data,
            Code: response.status,
          });
        } catch (error) {
          if (error instanceof ApiError) {
            dispatch({
              type: types.LOAD_VACATION_STATUS_FAILED,
              data: { Code: error.code },
            });
          } else {
            dispatch({
              type: types.LOAD_VACATION_STATUS_FAILED,
              data: { Code: 777 },
            });
          }
        }

        break;

      case types.ASYNC_PASSWORD_RESET:
        try {
          dispatch({ type: types.LOADING_PASSWORD_RESET });
          const response = await new ServerApi().resetPassword(action.payload);
          // resetPassword api call should throw error if error occurs so we don't hit below line in case of error
          processResponse(response, [200], response.message, responseErrorFn);
          dispatch({
            type: types.PASSWORD_RESET_SUCCESS,
            data: response.data,
            Code: response.status,
          });
        } catch (error) {
          // Check if error is http error and contains http response object
          if (error.response) {
            dispatch({
              type: types.PASSWORD_RESET_FAILED,
              data: {
                Code: error.response.status, // Http code of response
                errorType: error.response.data, // Response payload
              },
            });
          } else {
            dispatch({
              type: types.PASSWORD_RESET_FAILED,
              data: { Code: 777 },
              errorType:
                typeof error.message !== "undefined"
                  ? error.message
                  : translations.errorUnknown,
            });
          }
        }
        break;

      case types.ASYNC_ORDER_USERNAME:
        try {
          dispatch({ type: types.LOADING_ORDER_USERNAME });
          const response = await new ServerApi().orderUsername(action.payload);

          processResponse(response, [200], response.message, dispatch);
          dispatch({
            type: types.ORDER_USERNAME_SUCCESS,
            data: response.data,
            Code: response.status,
          });
        } catch (error) {
          if (error instanceof ApiError) {
            dispatch({
              type: types.ORDER_USERNAME_FAILED,
              data: { Code: error.status },
              errorType: error.message,
            });
          } else {
            dispatch({
              type: types.ORDER_USERNAME_FAILED,
              data: { Code: 777 },
              errorType:
                typeof error.message !== "undefined"
                  ? error.message
                  : "Tuntematon virhe",
            });
          }
        }
        break;

      case types.ASYNC_LOAD_CHILDREN:
        try {
          dispatch({ type: types.LOADING_CHILDREN });
          const response = await new ChildrenApi().loadChildren();
          processResponse(
            response,
            [200],
            "Load children failed",
            responseErrorFn
          );
          dispatch({ type: types.LOAD_SUCCESS_CHILDREN, data: response.data });
        } catch (error) {
          //console.error("MIDDLEWARE. OTHER ERROR Loading children: ", error, "\n error.response.status: ", error.response.status);
          if (error instanceof ApiError) {
            dispatch({
              type: types.LOAD_FAILED_CHILDREN,
              data: { Code: error.code },
            });
          } else {
            dispatch({
              type: types.LOAD_FAILED_CHILDREN,
              data: {
                Code:
                  error.response === undefined ? 777 : error.response.status,
              },
            });
          }
        }
        break;
      case types.LOAD_HANDLED_CHILDINFO:
        dispatch({ type: types.LOAD_HANDLED_CHILDINFO, data: action.payload });
        break;

      case types.ASYNC_LOAD_CHILDINFO:
        try {
          dispatch({ type: types.LOADING_CHILDINFO });
          const response = await new ChildrenApi().loadChildInfo(
            action.payload
          );
          processResponse(
            response,
            [200],
            "Load child info failed",
            responseErrorFn
          );
          // console.log("MIDDLEWARE. Load child info response: ", response.data);
          dispatch({ type: types.LOAD_SUCCESS_CHILDINFO, data: response.data });
        } catch (error) {
          //console.error("MIDDLEWARE. ERROR Loading chid info: ", error);
          if (error instanceof ApiError) {
            dispatch({
              type: types.LOAD_FAILED_CHILDINFO,
              data: { Code: error.code },
            });
          } else {
            dispatch({
              type: types.LOAD_FAILED_CHILDINFO,
              data: { Code: 666 },
            });
          }
        }
        break;
      case types.ASYNC_SAVE_CHILDINFO:
        try {
          dispatch({ type: types.SAVING_CHILDINFO });
          const response = await new ChildrenApi().saveChildInfo(
            action.payload
          );
          processResponse(
            response,
            [200],
            "Saving child info failed",
            responseErrorFn
          );

          //console.log("MIDDLEWARE. Save child info response: ", response.data);

          dispatch({ type: types.SAVE_SUCCESS_CHILDINFO });
        } catch (error) {
          if (error instanceof ApiError) {
            dispatch({
              type: types.SAVE_FAILED_CHILDINFO,
              data: { Code: error.code },
            });
          } else {
            dispatch({
              type: types.SAVE_FAILED_CHILDINFO,
              data: { Code: 666 },
            });
          }
        }
        break;
      // Deprecated
      case types.SETTINGS_HANDLED:
        //console.log("MIDDLEWARE. Settings handled: ", action.payload);
        dispatch({ type: types.SETTINGS_HANDLED, data: action.payload });
        break;
      // Deprecated
      case types.ASYNC_SAVE_PASSWORD:
        try {
          dispatch({ type: types.SAVING_PASSWORD });
          const response = await new SettingsApi().savePassword(action.payload);
          processResponse(
            response,
            [200],
            "Saving password failed",
            responseErrorFn
          );

          //console.log("MIDDLEWARE. Save password response: ", response.data);

          dispatch({ type: types.SAVE_SUCCESS_PASSWORD });
        } catch (error) {
          if (error instanceof ApiError) {
            //console.log("MIDDLEWARE. SAVE_FAILED_PASSWORD: ", error.code);
            dispatch({
              type: types.SAVE_FAILED_PASSWORD,
              data: { Code: error.code },
            });
          } else {
            //console.log("MIDDLEWARE. SAVE_FAILED_PASSWORD 666");
            dispatch({ type: types.SAVE_FAILED_PASSWORD, data: { Code: 666 } });
          }
        }
        break;

      case types.ASYNC_LOAD_PROFILEPICTURES:
        try {
          dispatch({ type: types.LOADING_PROFILEPICTURES });
          const response = await new ImageApi().loadProfilePictures();
          processResponse(
            response,
            [200],
            "Load profile pictures failed",
            responseErrorFn
          );

          dispatch({
            type: types.LOAD_SUCCESS_PROFILEPICTURES,
            data: response.data,
          });
        } catch (error) {
          dispatch({
            type: types.LOAD_FAILED_PROFILEPICTURES,
            data: { Code: error.code },
          });
          console.error("MIDDLEWARE. ERROR: ", error);
        }
        break;
      case types.ASYNC_LOAD_BULLETINBOARD:
        await actionGetBulletinBoard(dispatch, action, responseErrorFn);
        break;

      case types.ASYNC_CHECKPARENTSADDRESSSETTING:
        try {
          dispatch({ type: types.LOADING_CHECKPARENTSADDRESSSETTING });
          const response = await new SettingsApi().checkParentsAddressSettings(
            action.payload
          );
          processResponse(
            response,
            [200],
            "Loading parental address change setting failed",
            responseErrorFn
          );
          console.log(response);
          dispatch({
            type: types.LOAD_CHECKPARENTSADDRESSSETTING_SUCCESS,
            data: response.data,
          });
        } catch (error) {
          dispatch({
            type: types.LOAD_CHECKPARENTSADDRESSSETTING_FAILED,
            data: { Code: error.code },
          });
          console.error("MIDDLEWARE. ERROR: ", error);
        }
        break;

      case types.ASYNC_LOADCOMMUNESETTINGS:
        try {
          dispatch({ type: types.LOADING_COMMUNESETTINGS });
          const response = await new SettingsApi().loadCommuneSettings(
            action.payload
          );
          processResponse(
            response,
            [200],
            "Loading communesettings failed",
            responseErrorFn
          );

          dispatch({
            type: types.LOAD_COMMUNESETTINGS_SUCCESS,
            data: response.data,
          });
        } catch (error) {
          dispatch({
            type: types.LOAD_COMMUNESETTINGS_FAILED,
            data: { Code: error.code },
          });
          console.error("MIDDLEWARE. ERROR: ", error);
        }
        break;

      case types.ASYNC_DOWNLOAD_FILE:
        let fileDownloadStats = action.payload.fileDownloadStats;
        let index = fileDownloadStats.downloads.findIndex(
          (x) => x.fileId == action.payload.FileId
        );

        let download = {
          status: types.DOWNLOADING_FILE,
          response: undefined,
          fileId: action.payload.FileId,
        };

        if (index == -1) {
          fileDownloadStats.downloads.push(download);
          index = fileDownloadStats.downloads.length - 1;
        } else {
          fileDownloadStats.downloads[index] = download;
        }
        try {
          dispatch({
            type: types.DOWNLOADING_FILE,
            fileId: action.payload.FileId,
            downloads: fileDownloadStats.downloads,
          });

          const response = await new ServerApi().downloadFile(
            action.payload.FileId,
            action.payload.ATTACHMENT_TYPES,
            action.payload.FileMimeType
          );
          processResponse(
            response,
            [200],
            "Downloading file failed",
            responseErrorFn
          );

          download.status = types.DOWNLOAD_FILE_SUCCESS;
          download.response = response.data;
          fileDownloadStats.downloads[index] = download;
          dispatch({
            type: types.DOWNLOAD_FILE_SUCCESS,
            response: response.data,
            fileId: action.payload.FileId,
            downloads: fileDownloadStats.downloads,
          });
        } catch (error) {
          fileDownloadStats.downloads[index].status =
            types.DOWNLOAD_FILE_FAILED;
          dispatch({
            type: types.DOWNLOAD_FILE_FAILED,
            fileId: action.payload.FileId,
            downloads: fileDownloadStats.downloads,
          });
        }
        break;

      case types.ASYNC_ADD_BACKUP_PARENT:
        await actionAddBackupParent(dispatch, action, responseErrorFn);
        break;
      case types.ASYNC_UPDATE_BACKUP_PARENT:
        await actionUpdateBackupParent(dispatch, action, responseErrorFn);
        break;
      case types.ASYNC_DELETE_BACKUP_PARENT:
        await actionDeleteBackupParent(dispatch, action, responseErrorFn);
        break;
      case types.ASYNC_SAVE_PERSON_SETTINGS:
        await actionSavePersonSettings(dispatch, action);
        break;
      case types.ASYNC_LOAD_PERSON_SETTINGS:
        await actionGetPersonSettings(dispatch, action);
        break;

      default:
        dispatch(action);
    }
  };
};

/*
params:
  error: Error object thrown
  type: Action type to dispatch error
  dispatch: dispatch function
  errorDescription: (optional) error description
*/
const errorHandler = ({ error, type, dispatch, errorDescription = "" }) => {
  // additionalParam is an optional object that is sent to errorHandler
  //for handling specific cases
  /*
  if (typeof additionalParam.cheeseExists !== "undefined") {
   
  }
  */

  // if you use responsedata when error is thrown remember to handle the case when responsedata is null
  console.log("error ERRORHANDLERISSA", error);
  console.log("type ERRORHANDLERISSA", type);

  if (error instanceof ApiError) {
    console.log("errorHandler IF error is ApiError");
    dispatch({
      type: type,
      data: {
        errorType: ERROR_TYPES.http,
        Code: error.code,
        errorText: errorDescription,
        responsedata: error.responsedata,
      },
    });
  } else if (
    error.response &&
    error.response.status &&
    (error.response.status == "500" ||
      error.response.status == "400" ||
      error.response.status == "403" ||
      error.response.status == "404" ||
      error.response.status == "502" ||
      error.response.status == "503")
  ) {
    //comparison must be ==, not === for this to work.
    console.log(
      "errorHandler IF error is NOT ApiError but is ONE of the COMMON error codes, error.response",
      error.response
    );
    //console.log("errorHandler: error.response.data", error.response.data)
    dispatch({
      type: type,
      data: {
        errorType: ERROR_TYPES.http,
        Code: error.response.status,
        errorText: error,
        responsedata: error.response?.data ? error.response.data : null,
      },
    });
    console.error("errorHandler. error: ", error);
  } else {
    console.log(
      "errorHandler IF error is NOT ApiError, error.response",
      error.response
    );
    console.log("errorHandler IF error is NOT ApiError, error", error);
    //console.log("errorHandler: error.response.data", error.response.data)
    dispatch({
      type: type,
      data: {
        errorType: ERROR_TYPES.general,
        Code: 7777777,
        errorText: error,
        responsedata: error.response?.data ? error.response.data : null,
      },
    });
  }
  console.error("errorHandler. error: ", error);
};

export const actionGetBulletinBoard = async (
  dispatch,
  action,
  responseErrorFn
) => {
  dispatch({ type: types.LOADING_BULLETINBOARDS });
  try {
    const responseData = await new ServerApi().getBulletinBoardByParent();

    processResponse(
      responseData,
      [200],
      "Error loading bulletinboards",
      responseErrorFn
    );

    dispatch({
      type: types.LOAD_BULLETINBOARDS_SUCCESS,
      data: responseData.data,
    });
  } catch (error) {
    errorHandler({
      error,
      type: types.LOAD_BULLETINBOARDS_FAILED,
      dispatch,
      errorDescription: "Error loading bulletinboards",
    });
  }
};

export const actionAddBackupParent = async (
  dispatch,
  action,
  responseErrorFn
) => {
  try {
    dispatch({ type: types.ADDING_BACKUP_PARENT });
    const response = await new ChildrenApi().addBackupParent(
      action.payload.childId,
      action.payload.data
    );
    processResponse(
      response,
      [200],
      "Adding backup parent failed",
      responseErrorFn
    );

    dispatch({ type: types.ADD_BACKUP_PARENT_SUCCESS });
  } catch (error) {
    errorHandler({
      error,
      type: types.ADD_BACKUP_PARENT_FAILED,
      dispatch,
      errorDescription: "Error adding backup parent",
    });
  }
};

export const actionUpdateBackupParent = async (
  dispatch,
  action,
  responseErrorFn
) => {
  try {
    dispatch({ type: types.UPDATING_BACKUP_PARENT });
    const response = await new ChildrenApi().updateBackupParent(
      action.payload.childId,
      action.payload.data
    );
    processResponse(
      response,
      [200],
      "Updating backup parent failed",
      responseErrorFn
    );

    dispatch({ type: types.UPDATE_BACKUP_PARENT_SUCCESS });
  } catch (error) {
    errorHandler({
      error,
      type: types.UPDATE_BACKUP_PARENT_FAILED,
      dispatch,
      errorDescription: "Error adding backup parent",
    });
  }
};

export const actionDeleteBackupParent = async (
  dispatch,
  action,
  responseErrorFn
) => {
  try {
    dispatch({ type: types.LOADING_DELETE_BACKUP_PARENT });
    const response = await new ChildrenApi().deleteBackupParent(
      action.payload.childId,
      action.payload.backupParentId
    );
    processResponse(
      response,
      [200],
      "Deleting backup parent failed",
      responseErrorFn
    );

    dispatch({ type: types.DELETE_BACKUP_PARENT_SUCCESS });
  } catch (error) {
    errorHandler({
      error,
      type: types.DELETE_BACKUP_PARENT_FAILED,
      dispatch,
      errorDescription: "Error deleting backup parent",
    });
  }
};

export const actionSavePersonSettings = async (dispatch, action) => {
  try {
    dispatch({ type: types.SAVING_PERSON_SETTINGS });
    const response = await new ServerApi().savePersonSettings(
      action.payload.personId,
      action.payload.data
    );
    processResponse(response, [200], "Saving person settings failed");

    dispatch({ type: types.SAVE_PERSON_SETTINGS_SUCCESS, data: response.data });
  } catch (error) {
    errorHandler({
      error,
      type: types.SAVE_PERSON_SETTINGS_FAILED,
      dispatch,
      errorDescription: "Error saving person settings",
    });
  }
};

const actionGetPersonSettings = async (dispatch, action) => {
  dispatch({ type: types.LOADING_PERSON_SETTINGS });

  const response = await new ServerApi().getPersonSettings(
    action.payload.personId
  );

  processResponse(response, [200], "Loading person settings failed");

  dispatch({ type: types.LOAD_PERSON_SETTINGS_SUCCESS, data: response.data });
};

const actionGetLoginOptions = async (dispatch, action, responseErrorFn) => {
  dispatch({ type: types.LOADING_LOGIN_OPTIONS });
  try {
    const responseData = await new ServerApi().loadLoginOptions();
    processResponse(
      responseData,
      [200],
      "Error loading login options",
      responseErrorFn
    );
    dispatch({
      type: types.LOAD_LOGIN_OPTIONS_SUCCESS,
      data: responseData.data,
    });
  } catch (error) {
    errorHandler({ error, type: types.LOAD_LOGIN_OPTIONS_FAILED, dispatch });
  }
};

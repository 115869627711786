import React, { useEffect, useState } from "react";
import NewMessage from "./NewMessage/";
import { useStateValue } from "../../State";
import { withRouter } from "react-router-dom";

const NewMessageLayout = (props) => {
  const { state, actions } = useStateValue();

  const [mount, setMount] = useState(true);

  useEffect(() => {
    if (mount) {
      resetSnackBarAndPostNewMessageValues();
      loadRecipientsData();
      setMount(false);
    }
  }, [mount]);

  const loadRecipientsData = () => {
    actions.triggerLoadPotentialRecipients();
  };

  const resetSnackBarAndPostNewMessageValues = () => {
    actions.triggerResetValues_Post_New_Message();
  };

  const sendNewMessageToServer = (message) => {
    actions.triggerPostNewMessage(message);
  };

  const returnToInbox = () => {
    actions.triggerOpenPostNewMessageSnackbar();

    const previousLocation = props.history.location.state?.from;

    if (
      previousLocation === "/messaging" ||
      previousLocation === "/messaging2"
    ) {
      props.history.push(previousLocation);
    } else {
      props.history.push("/messaging");
    }
  };

  return (
    <NewMessage
      loadRecipientsData={loadRecipientsData}
      children={state.children}
      returnToInbox={returnToInbox}
      potentialRecipientsProp={
        state.messaging.loadPotentialRecipients.potentialRecipients
      }
      childList={state.children.ChildList}
      loadPotentialRecipientsState={
        state.messaging.loadPotentialRecipients.state
      }
      postNewMessageState={state.messaging.postNewMessage.state}
      snackBarState={state.messaging.forSnackBar.state}
      sendNewMessageToServer={(messagedata) =>
        sendNewMessageToServer(messagedata)
      }
    />
  );
};

export default withRouter(NewMessageLayout);

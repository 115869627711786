import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Languages from "../../translations";
import Typography from "@mui/material/Typography";
import { autoPlay } from 'react-swipeable-views-utils';
import SwipeableViews from 'react-swipeable-views';
import Note from "../Note";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import makeStyles from '@mui/styles/makeStyles';

const styles = {
  color: "#4377BA",
  fontSize: "18pt",
  fontWeight: "bold",
}

const useStyles = makeStyles(theme => ({
  note: {
    cursor: 'pointer'
  }
}));

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const BulletinBoardSlideView = (props) => {
  const { messages, onClick } = props;
  const [viewIndex, setViewIndex] = useState(0);
  const [autoPlay, setAutoPlay] = useState(true);
  const classes = useStyles();

  const handleChangeIndex = (index) => {
    setViewIndex(index);
  };

  const handleBack = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    setViewIndex(viewIndex > 0 ? viewIndex - 1 : messages.length - 1);
    setAutoPlay(false);
  }

  const handleForward = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    const calcIndex = viewIndex < messages.length - 1 ? viewIndex + 1 : 0;

    setViewIndex(calcIndex);
    setAutoPlay(false);
  }

  if (messages.length === 0) return (
    <div style={{height: "100%"}}>
      <Typography variant="h6" component="h2" style={styles}> {Languages.bbTitle} </Typography>
      <div style={{ borderColor: "#4377ba", borderWidth: '10px', borderStyle: "solid", borderRadius: "5px"}}>
      <AutoPlaySwipeableViews index={viewIndex} onChangeIndex={handleChangeIndex} enableMouseEvents>
        <div>
          <Note title={Languages.bbSlideViewNoMessages}>
          </Note>
        </div>
      </AutoPlaySwipeableViews>
      </div>
    </div>
  )

  return (
    <div style={{height: "100%"}}>
      <Typography variant="h6" component="h2" style={styles}> {Languages.bbTitle} </Typography>
      <div style={{ borderWidth: "5px 5px 12px 5px", borderColor: "#4377BA", borderStyle:"solid", /*borderTopLeftRadius: "15px",*/ borderTopRightRadius: "15px", height: "100%"}}>

      
      <AutoPlaySwipeableViews index={viewIndex} onChangeIndex={handleChangeIndex} enableMouseEvents autoplay={autoPlay}>
        {messages.map(element => {
          return (
            <div key={element.id} onClick={onClick} className={classes.note}>
              <Note
                key={element.id}
                title={element.header}
                date={element.date}
                available={Languages.formatString(Languages.bbValidUntilBBCard, element.available)}
                recipient={element.recipientType === 'group' ? Languages.bbGroup + " " + element.recipient : Languages.bbCentreShortText + " " + element.recipient}
                constantHeight={true}
                attachments={element.attachments}
                showAttachmentsAsIcon={true}
              >
                {element.body}
              </Note>

            </div>
          );
        })}
      </AutoPlaySwipeableViews>
      <MobileStepper
      style={{marginBottom:"-35px"}} 
        color="secondary"
        variant="dots"
        steps={messages.length}
        position="static"
        activeStep={viewIndex}
        nextButton={
          <Button color="secondary" size="small" onClick={handleForward} disabled={false}>
            <KeyboardArrowRight color="secondary"/>
          </Button>}
        backButton={
          <Button color="secondary" size="small" onClick={handleBack} disabled={false}>
            <KeyboardArrowLeft color="secondary" />
          </Button>}>
      </MobileStepper>
      </div>
    </div>

  );
}

BulletinBoardSlideView.propTypes = {
  messages: PropTypes.array.isRequired,
  onClick: PropTypes.func
};

export default BulletinBoardSlideView;
import React, { useState } from "react";
import "../messaging.css";
import CircularProgress from "@mui/material/CircularProgress";
import AddRounded from "@mui/icons-material/AddRounded";
import Fab from "@mui/material/Fab";
import RefreshIcon from "@mui/icons-material/Refresh";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import InfoModal from "../../../Components/Modals/InfoModal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Languages from "../../../translations";
import { types } from "../../../Reducers/actionTypes";
import { useStateValue } from "../../../State";
//import FactCheckOutlined from "@mui/icons-material/AssignmentTurnedIn";
import PlayListAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";

import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const MessagingBottomBar = ({
  loading,
  refresh,
  showNew,
  setAllRead,
  setAllReadLoading,
}) => {
  const { state, actions } = useStateValue();

  const [confirmationNote, setConfirmationNote] = useState(false);

  const markAllRead = (isOKToSend) => {
    if (isOKToSend) {
      setConfirmationNote(false);
      setAllRead();
    } else {
      setConfirmationNote(false);
    }
  };

  if (true) {
    return (
      <Box
        sx={{
          borderTopStyle: "solid",
          borderColor: "#86B8FF",
          margin: "auto",
          width: "100%",
          position: "fixed",
          bottom: "0",
          maxWidth: "804px",
          marginLeft: "-10px",
          zIndex: "20",
        }}
      >
        <BottomNavigation
          sx={{ backgroundColor: "#FAF9F6" /*"#86B8FF"*/, minHeight: "80px" }}
          showLabels
        >
          <BottomNavigationAction
            sx={{ color: "#000000" }}
            label={Languages.permRefresh}
            tabIndex={2}
            id="test_Inbox_Refresh_Btn"
            disabled={loading ? true : false}
            aria-label="Refresh"
            className="fab"
            onClick={refresh}
            icon={<RefreshIcon data-testid="test-Inbox-Refresh-Btn-98el2nas" />}
          ></BottomNavigationAction>
          <Fab
            tabIndex={1}
            id="test_Inbox_NewMsg_Btn"
            style={{
              marginTop: "-26px",
              backgroundColor: "#81C784",
              color: "#000000",
            }}
            color="secondary"
            aria-label={Languages.msgMessageNew}
            onClick={showNew}
            className="fab"
          >
            <AddRounded data-testid="test-Inbox-NewMessage-Btn-joe82nlb"></AddRounded>
          </Fab>
          <BottomNavigationAction
            sx={{ color: "#000000" }}
            label={Languages.msgMarkAllAsReadShort}
            disabled={confirmationNote || loading || setAllReadLoading}
            tabIndex={3}
            id="test_Inbox_Set_All_Messages_Read_Btn"
            style={{ margin: "10px" }}
            className="fab"
            aria-label={Languages.msgMarkAllAsRead}
            onClick={() => setConfirmationNote(true)}
            icon={
              <PlayListAddCheckIcon data-testid="test-Inbox-SetAllRead-Btn" />
            }
          ></BottomNavigationAction>
        </BottomNavigation>

        <InfoModal
          modalOpen={confirmationNote}
          headerText={Languages.msgMarkAllAsRead}
          onClose={() => markAllRead(false)}
          allowClose={true}
        >
          <Typography>
            {Languages.msgMarkAllAsReadConfirmation}
            <br />
            <Button
              style={{ marginTop: "6px", marginBottom: "3px" }}
              variant="contained"
              color="secondary"
              onClick={() => markAllRead(true)}
            >
              {Languages.permYes}
            </Button>
            &nbsp;&nbsp;
            <Button
              style={{ marginTop: "6px", marginBottom: "3px" }}
              variant="outlined"
              color="secondary"
              onClick={() => markAllRead(false)}
            >
              {Languages.permNo}
            </Button>
          </Typography>
        </InfoModal>
      </Box>
    );
  }
};

export default MessagingBottomBar;

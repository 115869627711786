import React          from  "react";
import Languages      from  "../../../translations";
import Card           from  "@mui/material/Card";
import Button         from  "@mui/material/Button";
import AutorenewIcon  from  '@mui/icons-material/Autorenew';
import                      "../growthfolder.css";


const GrowthFolderFailed = ({ tryReloadFolders, errorCode }) => {

  return (
    <React.Fragment>
      <Card style={{backgroundColor:"#4377BA"}}>
        <p className = "growthfolder-folder-error-msg">{Languages.errorLoadImageFolders}</p>
        <p className = "growthfolder-folder-error-msg">{
            typeof errorCode === "undefined" ? Languages.errorUnknown : 
              errorCode === 400 ? Languages.errorCode + " 400 " :
              errorCode === 500 ? Languages.errorCode + " 500: " + Languages.errorInDB :
              errorCode === 502 ? " 502: " + Languages.errorNetwork : 
              errorCode === 777 ? Languages.errorUnknown : Languages.errorUnknown
        }</p>
      </Card>
        <div className= "growthfolder-folder-error-msg-button"> 
           <Button
              title = "reloadButton"  
              startIcon={<AutorenewIcon />}
              style={{backgroundColor:"#4377BA", align:"center"}} 
              onClick={tryReloadFolders}>{Languages.retry}
            </Button>
        </div>
    </React.Fragment>
  )
}

export default GrowthFolderFailed;
import "./bulletinboard.css";
import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import Languages from "../../translations";
import Note from "../../Components/Note";
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import BulletinBoardBackgroundImage from '../../images/bulletinBoardBGImage.jpg'


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    /*borderColor: "#4377ba",*/ 
    borderColor: "BurlyWood",
    //borderColor: "#a06e2c",
    //borderColor: "#D1D5DA", 
    borderWidth: '10px', 
    borderStyle: "solid", 
    borderRadius: "5px",
    //backgroundColor: "white",
    backgroundColor: "#cc9966",
    //backgroundColor: "#24292E", 
    /*backgroundColor: "chocolate",*/ 
    //backgroundImage: `url(${BulletinBoardBackgroundImage})`, 
    //backgroundRepeat: "repeat",
    //backgroundPosition: "center", 
    boxShadow: "5px 10px 8px #888888"
  },
}));

const BulletinBoardCardView = ({ messages, attachmentOnClick, previewFileOnClick }) => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className={classes.root}>
      <Grid container /*direction="row"*/ spacing={2} >
        {messages.map((note, index) =>
          <Grid item md={6} key={"bbCardGrid_" + index}>
            <Note
              key={"bbCardNote_" + index}
              source="bulletinOnly"
              date={note.date}
              available={Languages.formatString(Languages.bbValidUntilBBCard, note.available)}
              recipient={note.recipientType === 'group' ? Languages.bbGroup + " " + note.recipient : Languages.bbCentreShortText + " " + note.recipient}
              attachments={note.attachments}
              title={note.header}
              attachmentOnClick={attachmentOnClick}
              previewFileOnClick={previewFileOnClick}
            >
              {note.body}
            </Note>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

BulletinBoardCardView.propTypes = {
  messages: PropTypes.array.isRequired,
  attachmentOnClick: PropTypes.func,
}

export default BulletinBoardCardView;
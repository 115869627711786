import React, { Component } from "react";
import "./openmessage.css";
import ContentBoxMUI from "../../Components/ContentBoxMUI";
import SendersMessageBox from "../../Components/Messaging/SendersMessageBox";
import OwnMessageBox from "../../Components/Messaging/OwnMessageBox";
import AnswerMessage from "../../Components/Messaging/AnswerMessage";
import Languages from "../../translations";
import Divider from "@mui/material/Divider";
import { Typography, Dialog } from "@mui/material";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import CircularProgress from "@mui/material/CircularProgress";
import FullImageModal from "../../Components/Messaging/FullImageModal";
import { StateContext } from "../../State";
import MessagingBar from "../../Components/Messaging/MessagingBar";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
//import Modal                  from "@mui/material/Modal";
//import ContentBox             from "../../Components/ContentBox";
import MessagingPopUpModal from "../../Components/Modals/MessagingPopUpModal";
import SessionManager from "../../Auth/SessionManager.js";
import DeletedBox from "./Components/DeletedBox.jsx";
import _cloneDeep from "lodash/cloneDeep";

// OpenMessageLayout gets its current/relevant threadId number from two places, varying: loadThread.threadId and currentThreadId.id

class OpenMessageLayout extends Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);

    this.FixedAppBar = React.createRef();
    this.bottomFocus = React.createRef();
    this.focusOnThisUnread = React.createRef();
    this.topFocus = React.createRef();

    this.state = {
      LMT_waiting: false,
      replyToThread_waiting: false,

      title: "",

      showContent: false, // check this later

      showAllParticipantsInDialog: false,

      msgError: "",

      replyToMessageFailedModal: false,

      previewPicsObject: {},

      fullImageModal: false,
      fullImageName: "",
      fullImageFileId: "",

      whereToFocus: "bottom",
      changeFocusIsOn: true,

      AppBarHeight: 0,

      setInterval: true,
      intervalCleared: false,

      compactModeEnabled: true,
      //round1: 0, //unknown if still needed
    };
  }

  componentDidMount() {
    const { currentThreadId } = this.context.state.messaging;

    if (
      /*typeof this.props.location.state === "undefined" && */ typeof currentThreadId.id ===
      "undefined"
    ) {
      this.props.history.push("/messaging");
    } else {
      //const { messageThread } = this.props.location.state;
      this.context.actions.triggerClearLoadThread();
      //this.context.actions.triggerUpdateMessageThreadId(currentThreadId.id);

      this.requestThreadFromServer(currentThreadId.id, "mount");

      //do not load inbox when returning from here, unless this variable has been set to false when answering to the message thread
      this.context.actions.triggerUpdateDoNotLoadInboxVariable(true);

      this.changeMessageReadStateLocally(currentThreadId.id);
    }
  }

  componentDidUpdate() {
    const { loadThread } = this.context.state.messaging;
    const { replyToThread } = this.context.state.messaging;
    const { currentThreadId } = this.context.state.messaging;

    if (currentThreadId.id && this.state.setInterval) {
      this.setAutoInterval(currentThreadId.id);
      this.setState({ setInterval: false });
    }

    // koska appstatesta on luultavasti lähtenyt tiedot pois FAILED:n myötä:
    if (
      this.state.showContent === true &&
      (loadThread.state === "LOAD_MESSAGE_THREAD_FAILED" ||
        replyToThread.state === "REPLYTO_MASS_THREAD_FAILED" ||
        replyToThread.state === "REPLYTO_NONMASS_THREAD_FAILED")
    ) {
      this.setState({
        showContent: false,
      });
    }

    // SETTING AUTO REFRESH INTERVAL WITH UPDATED THREAD ID
    // Reply to MASS thread changes the thread and threadId that is shown to the user. Auto update setInterval needs to be changed in that case
    if (replyToThread.state === "WAITING_REPLYTO_MASS_THREAD") {
      if (this.state.intervalCleared === false) {
        clearInterval(this.automaticMsgCheck);
        this.setState({ intervalCleared: true });
      }
    }
    if (this.state.intervalCleared === true) {
      if (replyToThread.state === "REPLYTO_MASS_THREAD_SUCCESS") {
        this.setAutoInterval(loadThread.threadId);
        this.setState({ intervalCleared: false });
      } else if (replyToThread.state === "REPLYTO_MASS_THREAD_FAILED") {
        this.setAutoInterval(currentThreadId.id);
        this.setState({ intervalCleared: false });
      } else {
        // do nothing
      }
    }

    //when to trigger responseHandler
    if (loadThread.state === "LOADING_MESSAGE_THREAD") {
      if (this.state.LMT_waiting === false) {
        this.setState({ LMT_waiting: true });
      }
    }

    if (this.state.LMT_waiting === true) {
      if (
        loadThread.state === "LOAD_MESSAGE_THREAD_SUCCESS" ||
        loadThread.state === "LOAD_MESSAGE_THREAD_FAILED"
      ) {
        this.responseHandler();
        this.setState({ LMT_waiting: false });
      }
    }

    //when to trigger responseHandler/download of thread data after api response
    if (
      replyToThread.state === "WAITING_REPLYTO_MASS_THREAD" ||
      replyToThread.state === "WAITING_REPLYTO_NONMASS_THREAD"
    ) {
      if (this.state.replyToThread_waiting !== true) {
        this.setState({
          replyToThread_waiting: true,
        });
      }
    }

    if (this.state.replyToThread_waiting === true) {
      if (
        replyToThread.state === "REPLYTO_MASS_THREAD_SUCCESS" ||
        replyToThread.state === "REPLYTO_MASS_THREAD_FAILED"
      ) {
        if (replyToThread.state === "REPLYTO_MASS_THREAD_FAILED") {
          this.setState({ msgError: "" });
        }
        if (replyToThread.state === "REPLYTO_MASS_THREAD_SUCCESS") {
          this.context.actions.triggerUpdateMessageThreadId(
            this.context.state.messaging.loadThread.threadId
          );
          this.context.actions.triggerUpdateOMScrollAssistant({
            requester: "repliedToThread-massThread",
          });
        }

        this.responseHandler();

        this.setState({ replyToThread_waiting: false });
      } else if (
        replyToThread.state === "REPLYTO_NONMASS_THREAD_SUCCESS" ||
        replyToThread.state === "REPLYTO_NONMASS_THREAD_FAILED"
      ) {
        if (replyToThread.state === "REPLYTO_NONMASS_THREAD_SUCCESS") {
          //console.log("STARTING LOADMESSAGE AFTER REPLYTO_NONMASS_THREAD_SUCCESS, CURRENTTHREADID.ID", currentThreadId.id);
          this.requestThreadFromServer(
            currentThreadId.id,
            "repliedToThread-NONmassThread"
          );
          this.setState({ msgError: "" });
        } else {
          this.setState({
            replyToMessageFailedModal: true,
          });
        }
        //no new data came in with the request so no responseHandler activation this.responseHandler("replied");
        this.setState({ replyToThread_waiting: false });
      } else {
        //do nothing, app is still waiting for response from the server
      }
    }

    //if (this.state.msgSentWaiting === true && this.state.DoNotDoMsgSentNowWaitForResponseTwice === false) this.msgSentNowWaitForResponse();
    //console.log("componentDidUpdate, this.state.changeFocusIsOn", this.state.changeFocusIsOn);
    if (
      this.state.changeFocusIsOn === true &&
      this.context.state.messaging.openMessageScrolling.requester !== "auto"
    ) {
      if (this.state.whereToFocus === "bottom") {
        var btm = this.bottomFocus.current;

        btm.scrollIntoView({ alignToTop: false });
        //round1 ignores the first componentDidUpdate,
        //after which the scrolling should be done (again)
        /*if (this.state.round1 === 1) {
        this.setState({changeFocusIsOn: false,
                        round1: 0
        });}
        else {*/
        this.setState({
          changeFocusIsOn: false,
          //round1: 1
        });
        //}

        console.log("FOCUSING BOTTOM of the page");
      }

      if (this.state.whereToFocus === "unread") {
        var unrd = this.focusOnThisUnread.current;
        unrd.scrollIntoView();
        this.setState({
          changeFocusIsOn: false,
        });
        console.log("FOCUSING UNREAD");
      }

      if (this.state.whereToFocus === "top") {
        var top = this.topFocus.current;
        top.scrollIntoView();
        this.setState({
          changeFocusIsOn: false,
        });
      }
    }

    if (this.FixedAppBar.current) {
      var x = this.FixedAppBar.current.getBoundingClientRect();
      //console.log("x.height ", x.height, x.top, x.bottom);
      if (this.state.AppBarHeight + 3 !== x.height)
        this.setState({
          AppBarHeight: x.height - 3, // -3 pixels so that there is no gap between AppBar and div
        });
    }

    /*
      var date = new Date();
      var timeNow = date.getTime();

        const updateThreadTimerMSecs = this.context.state.dFSettings.updateThreadTimerSecs * 1000;
        //console.log("updateThreadTimerMSecs", updateThreadTimerMSecs);
      //console.log("openMessage", timeNow - this.props.messageStor.threadFetched);
      if ((timeNow - this.props.messageStor.threadFetched) > updateThreadTimerMSecs && this.props.messageStor.OneMessageResponse === "OK") {
        console.log("OpenMessage didUpdate considering fetching message thread data from server, it has been over 60000ms since last successful fetch time from the server"); 
        if ((timeNow - this.props.messageStor.lastTriedFetchTime) > 20000 || this.props.messageStor.lastTriedFetchTime === 0) { 
          console.log("OpenMessage didUpdate, lastTriedFetchTime is over 20000ms, proceeding to refresh the message thread data from the server quietly");
          this.refreshMessageThreadQuietly("didUpdate"); // TODO: this could be an infinite loop if the if above doesn't work properly 
        }
      }
    */
  }

  componentWillUnmount() {
    this.focusHandler("reset-to-top");
    clearInterval(this.automaticMsgCheck);
    //console.log("OPENMESSAGE WILL UNMOUNT");
  }

  changeMessageReadStateLocally = (threadId) => {
    let copyOfOldArray = _cloneDeep(
      this.context.state.messaging.loadMessages.messages
    );

    copyOfOldArray.forEach((item, index) => {
      if (item.ThreadId === threadId) {
        copyOfOldArray[index].CountNotReadMessages = 0;
      }
    });
    this.context.actions.triggerMarkThreadReadLocally(copyOfOldArray);
  };

  setAutoInterval(threadId) {
    const { dFSettings } = this.context.state;

    this.automaticMsgCheck = setInterval(
      () => {
        this.refreshMessageThreadQuietly(threadId, "auto");
      },
      typeof dFSettings.updateThreadTimerSecs !== "undefined" &&
        dFSettings.updateThreadTimerSecs !== null
        ? dFSettings.updateThreadTimerSecs * 1000
        : 60000
    );
  }

  requestThreadFromServer = (threadId, requester) => {
    this.context.actions.triggerLoadThreadFromServer({ threadId: threadId });
    this.context.actions.triggerUpdateOMScrollAssistant({
      requester: requester,
    });
  };

  refreshMessageThreadQuietly = (threadId, requester, text) => {
    this.context.actions.triggerLoadThreadFromServer({ threadId: threadId });
    this.context.actions.triggerUpdateOMScrollAssistant({
      requester: requester,
    });
    //console.log("requester refreshMessageThreadQuietly", requester);
    //this.props.messageStor.loadOneMessage(this.props.messageStor.currentMessageThreadId, 60000, "auto");

    //this.responseHandler(text);
  };

  responseHandler = () => {
    const { loadThread, replyToThread, currentThreadId } =
      this.context.state.messaging;
    const { openMessageScrolling } = this.context.state.messaging;

    if (
      replyToThread.state === "REPLYTO_MASS_THREAD_FAILED" ||
      replyToThread.state === "REPLYTO_NONMASS_THREAD_FAILED"
    ) {
      this.setState({
        replyToMessageFailedModal: true,
        title:
          replyToThread.Code === 400
            ? Languages.errorNotFound
            : replyToThread.Code === 404
            ? Languages.errorNotFound
            : replyToThread.Code === 500
            ? Languages.errorInDB
            : replyToThread.Code === 502
            ? Languages.errorNetwork
            : Languages.errorUnknown,
      });
    }

    if (
      loadThread.state === "LOAD_MESSAGE_THREAD_SUCCESS" ||
      replyToThread.state === "REPLYTO_MASS_THREAD_SUCCESS"
    ) {
      const unreadMessageFound = this.findUnreadMessage(
        loadThread.threadContent
      );

      //After load success, the thread is set as having been read. Then we fetch the main stats for the drawer so that they are updated.
      if (unreadMessageFound === true) {
        this.context.actions.triggerSetThisThreadRead(currentThreadId.id);
      }

      // starting to handle where in the thread to point the focus
      if (openMessageScrolling.requester === "mount") {
        unreadMessageFound
          ? this.focusHandler("mount-found-unread")
          : this.focusHandler("mount-did-not-find-unread");
      }

      if (openMessageScrolling.requester === "auto") {
        unreadMessageFound
          ? this.focusHandler("auto-update-found-unread")
          : this.focusHandler("auto-update-did-not-find-unread");
      }

      if (openMessageScrolling.requester === "repliedToThread-NONmassThread") {
        this.focusHandler("repliedToThread");
      }

      if (openMessageScrolling.requester === "repliedToThread-massThread") {
        this.focusHandler("repliedToThread-massThread");
      }

      //this.loadPreviewPicturesIfAny(loadThread.threadContent);

      this.setState({
        title:
          loadThread.threadContent[loadThread.threadContent.length - 1]
            .Title === undefined
            ? "Otsikko"
            : loadThread.threadContent[loadThread.threadContent.length - 1]
                .Title,
        threadId: currentThreadId.id,
      });

      if (this.state.showContent === false) {
        this.setState({
          showContent: true,
        });
      }
    }
  };

  findUnreadMessage = (threadContent) => {
    // checking whether there are unread messages in the thread:
    let unreadMessageFound = false;

    for (let i = 0; i < threadContent.length; i++) {
      if (threadContent[i].OldestNotRead === true) {
        unreadMessageFound = true;
      }
    }
    //console.log("findUnreadMessage(), unreadMessageFound:", unreadMessageFound);
    return unreadMessageFound;
  };

  focusHandler = (event) => {
    const { loadThread } = this.context.state.messaging;

    console.log("requester, FOCUSHANDLER FOCUS SHIFT TO: ", event);

    switch (event) {
      case "mount-found-unread":
        // if there are 1 or 2 messages in the thread, focus is placed on top so that neither message is hidden behind the AppBars
        if (
          loadThread.threadContent !== undefined &&
          loadThread.threadContent.length < 3
        ) {
          this.setState({
            changeFocusIsOn: true,
            whereToFocus: "top",
          });
        } else {
          this.setState({
            changeFocusIsOn: true,
            whereToFocus: "unread",
          });
        }
        break;
      case "mount-did-not-find-unread":
        // if there are 1 or 2 messages in the thread, focus is placed on top so that neither message is hidden behind the AppBars
        if (
          this.state.compactModeEnabled &&
          loadThread.threadContent !== undefined &&
          loadThread.threadContent.length < 5
        ) {
          this.setState({
            changeFocusIsOn: true,
            whereToFocus: "top",
          });
        } else if (
          loadThread.threadContent !== undefined &&
          loadThread.threadContent.length < 3
        ) {
          this.setState({
            changeFocusIsOn: true,
            whereToFocus: "top",
          });
        } else {
          this.setState({
            changeFocusIsOn: true,
            whereToFocus: "bottom",
          });
        }
        break;
      case "repliedToThread":
        if (
          this.state.compactModeEnabled &&
          loadThread.threadContent !== undefined &&
          loadThread.threadContent.length < 5
        ) {
          this.setState({
            changeFocusIsOn: true,
            whereToFocus: "top",
          });
        } else {
          this.setState({
            changeFocusIsOn: true,
            whereToFocus: "bottom",
          });
        }
        break;
      case "repliedToThread-massThread":
        if (
          this.state.compactModeEnabled &&
          loadThread.threadContent !== undefined &&
          loadThread.threadContent.length < 5
        ) {
          this.setState({
            changeFocusIsOn: true,
            whereToFocus: "top",
          });
        } else {
          this.setState({
            changeFocusIsOn: true,
            whereToFocus: "bottom",
          });
        }
        break;
      case "auto-update-found-unread":
        this.setState({
          changeFocusIsOn: false,
        });
        break;
      case "auto-update-did-not-find-unread":
        this.setState({
          changeFocusIsOn: false,
        });
        break;
      case "reset-to-top":
        this.setState({
          changeFocusIsOn: true,
          whereToFocus: "top",
        });
        break;
      default:
        this.setState({});
    }
  };

  showThreadParticipantsDialog = () => {
    this.setState({
      showAllParticipantsInDialog: true,
    });
  };

  handleDialogClose = () => {
    this.setState({
      showAllParticipantsInDialog: false,
    });
  };

  passCloseMessageThreadFunctionOnwards = () => {
    this.props.closeMessageThread();
  };

  showFullImage = (imgId, imgMimeType, imgName) => {
    //console.log("showFullImage function, imgId", imgId);

    this.setState({
      fullImageFileId: imgId,
      fullImageMimeType: imgMimeType,
      fullImageName: imgName,
      fullImageModal: true,
    });
  };

  closeFullImageModal = () => {
    this.setState({
      fullImageFileId: "",
      fullImageMimeType: "",
      fullImageName: "",
      fullImageModal: false,
    });
  };

  closeReplyToMessageFailedModal = () => {
    this.setState({
      replyToMessageFailedModal: false,
      msgError: Languages.errorWithMsgSending,
    });
  };

  render() {
    const { loadThread } = this.context.state.messaging;
    const { latestApiRequestState } = this.context.state.messaging;
    const { currentThreadId } = this.context.state.messaging;
    //const { replyToThread } = this.context.state.messaging;

    const waitingOrOKShowContent =
      typeof loadThread.threadContent !== "undefined" &&
      (((latestApiRequestState.state === "LOADING_MESSAGE_THREAD" ||
        latestApiRequestState.state === "WAITING_REPLYTO_MASS_THREAD" ||
        latestApiRequestState.state === "WAITING_REPLYTO_NONMASS_THREAD") &&
        this.state.showContent === true) ||
        latestApiRequestState.state === "LOAD_MESSAGE_THREAD_SUCCESS" ||
        latestApiRequestState.state === "REPLYTO_MASS_THREAD_SUCCESS" ||
        latestApiRequestState.state === "REPLYTO_NONMASS_THREAD_SUCCESS" ||
        latestApiRequestState.state === "REPLYTO_NONMASS_THREAD_FAILED");

    return (
      <React.Fragment>
        <div ref={this.topFocus}></div>
        <ContentBoxMUI
          header={
            typeof loadThread.threadContent !== "undefined"
              ? loadThread.threadContent[loadThread.threadContent.length - 1]
                  .Title
              : null
          }
          topPositionFix={this.state.AppBarHeight - 58}
        >
          <div
            style={
              {
                /*backgroundColor:"#cedffd"}*/
              }
            }
          >
            <MessagingBar
              showParticipants={
                waitingOrOKShowContent ||
                (latestApiRequestState.state === "LOAD_MESSAGE_THREAD_FAILED" &&
                  typeof loadThread.threadContent !== "undefined")
              }
              showLoadingIcon={
                latestApiRequestState.state === "LOADING_MESSAGE_THREAD" ||
                latestApiRequestState.state === "WAITING_REPLYTO_MASS_THREAD" ||
                latestApiRequestState.state === "WAITING_REPLYTO_NONMASS_THREAD"
              }
              onButtonClick={this.showThreadParticipantsDialog}
              ref={this.FixedAppBar}
            />

            {/* LOADING */}
            {(latestApiRequestState.state === "LOADING_MESSAGE_THREAD" ||
              latestApiRequestState.state === "WAITING_REPLYTO_MASS_THREAD" ||
              latestApiRequestState.state ===
                "WAITING_REPLYTO_NONMASS_THREAD") &&
            this.state.showContent === false ? (
              <div style={{ marginTop: "50%", textAlign: "center" }}>
                <p>{Languages.msgLoadingThread}</p>
                <CircularProgress color="secondary" />
              </div>
            ) : null}

            {/*LOAD_FAILED */}
            {loadThread.state === "LOAD_MESSAGE_THREAD_FAILED" &&
            typeof loadThread.threadContent ===
              "undefined" /*this.state.showContent === false*/ ? (
              <Card style={{ backgroundColor: "#4377BA" }}>
                <CardContent>
                  <p>{Languages.msgCouldNotBeLoaded}</p>
                  <p>
                    {loadThread.Code === 400
                      ? Languages.errorCode + ": 400 "
                      : loadThread.Code === 404
                      ? Languages.errorCode + ": 404 " + Languages.errorNotFound
                      : loadThread.Code === 500
                      ? Languages.errorCode + ": 500 " + Languages.errorInDB
                      : loadThread.Code === 502
                      ? Languages.errorCode + ": 502 " + Languages.errorNetwork
                      : Languages.errorCode +
                        ": " +
                        loadThread.Code +
                        Languages.errorUnknown}
                  </p>
                </CardContent>
              </Card>
            ) : null}

            {
              /*REPLYTOTHREAD FAILED */
              (latestApiRequestState.state === "REPLYTO_MASS_THREAD_FAILED" ||
                latestApiRequestState.state ===
                  "REPLYTO_NONMASS_THREAD_FAILED") && (
                <MessagingPopUpModal
                  isOpen={this.state.replyToMessageFailedModal}
                  header={Languages.error}
                  infoMsgText={Languages.errorMsgProbablyNotSent}
                  button={() => this.closeReplyToMessageFailedModal()}
                  buttonText={"OK"}
                />
              )
            }

            {/* LOADED */}
            {(waitingOrOKShowContent ||
              (latestApiRequestState.state === "LOAD_MESSAGE_THREAD_FAILED" &&
                typeof loadThread.threadContent !== "undefined")) &&
              loadThread.threadContent.length > 0 &&
              loadThread.threadContent.map((msg, index) => {
                return (
                  <div key={msg.Id + msg.ThreadId + index}>
                    {msg.OldestNotRead && (
                      <div id="focusOnThisUnread" ref={this.focusOnThisUnread}>
                        {index !== 0 && (
                          <React.Fragment>
                            <Divider />
                            <Typography
                              color="textSecondary"
                              display="block"
                              variant="caption"
                            >
                              {Languages.msgUnreadMessages} <br />
                            </Typography>
                          </React.Fragment>
                        )}
                      </div>
                    )}

                    <DeletedBox isShown={msg.Content === null} />

                    {msg.MessageSender.Id === SessionManager.getPersonId() &&
                      msg.Content !== null && (
                        <OwnMessageBox
                          //key={msg.Id + "key"}
                          ownmessagesender={
                            /*msg.MessageSender.DisplayName !== null ? msg.MessageSender.DisplayName :*/ msg
                              .MessageSender.Name
                          }
                          ownmessagetext={msg.Content}
                          ownmessagedate={msg.Saved}
                          compact={this.state.compactModeEnabled}
                        />
                      )}

                    {msg.MessageSender.Id !== SessionManager.getPersonId() &&
                      msg.Content !== null && (
                        <SendersMessageBox
                          //key={msg.Id + "key2"}
                          attachmentList={msg.ListAttachments}
                          compact={this.state.compactModeEnabled}
                          messagesender={
                            /*msg.MessageSender.DisplayName !== null ? msg.MessageSender.DisplayName :*/ msg
                              .MessageSender.Name
                          }
                          messagetext={msg.Content}
                          messagedate={msg.Saved}
                          showThreadSentToMasses={
                            loadThread.MassThread === true &&
                            msg.Id === loadThread.threadContent[0].Id
                              ? true
                              : false
                          }
                          numberOfAttachments={
                            msg.ListAttachments !== undefined &&
                            msg.ListAttachments !== null
                              ? msg.ListAttachments.length
                              : null
                          }
                        />
                      )}
                  </div>
                );
              })}

            {(loadThread.state === "LOAD_MESSAGE_THREAD_SUCCESS" ||
              typeof loadThread.threadContent !== "undefined") && (
              <Dialog
                open={this.state.showAllParticipantsInDialog}
                onClose={this.handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                  style: {
                    backgroundColor: "white",
                  },
                }}
              >
                <DialogTitle id="alert-dialog-title" style={{ color: "black" }}>
                  {Languages.msgThreadParticipants} <br /> (
                  {loadThread.allThreadParticipantsMinusUser.length > 0
                    ? loadThread.allThreadParticipantsMinusUser.length + 1
                    : null}{" "}
                  {Languages.msgParticipants})
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <Typography component={"span"} variant={"body2"}>
                      {loadThread.allThreadParticipantsMinusUser.length > 0 &&
                        loadThread.allThreadParticipantsMinusUser.map(
                          (cTPA, index) => {
                            return (
                              <React.Fragment key={cTPA.Id + index}>
                                <span>
                                  -{" "}
                                  {typeof cTPA.Name === "undefined" ||
                                  cTPA.Name === null
                                    ? Languages.msgUnknownPersonOrGroup
                                    : cTPA.Type === "child_person"
                                    ? cTPA.Name +
                                      " (" +
                                      Languages.msgCaretakers +
                                      ")"
                                    : cTPA.Name}
                                </span>
                                <br />
                              </React.Fragment>
                            );
                          }
                        )}
                      <span key="you123">- {Languages.msgMe}</span>
                    </Typography>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={this.handleDialogClose}
                    /*color="secondary"*/ color="secondary"
                    variant="contained"
                    autoFocus
                  >
                    OK
                  </Button>
                </DialogActions>
              </Dialog>
            )}

            {this.state.fullImageModal && (
              <FullImageModal
                imageId={this.state.fullImageFileId}
                imageMimeType={this.state.fullImageMimeType}
                imagename={this.state.fullImageName}
                closefunction={() => this.closeFullImageModal()}
              />
            )}

            <div ref={this.bottomFocus} id="bottomfocus"></div>

            <div className="emptyspace"></div>

            <AnswerMessage
              threadId={currentThreadId.id}
              MsgError={this.state.msgError}
              massThreadFlag={loadThread.MassThread === true}
            />
          </div>
        </ContentBoxMUI>
      </React.Fragment>
    );
  }
}

export default OpenMessageLayout;

import React, { useEffect, useState } from "react";
import { useStateValue } from "../../../../State";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from "@mui/material/Typography";
import TextField from '@mui/material/TextField';
import ExpandMoreIcon         from '@mui/icons-material/ExpandMore';
import Divider                from '@mui/material/Divider';
import AccordionActions  from '@mui/material/AccordionActions';
import IconButton             from '@mui/material/IconButton';
import Edit                   from '@mui/icons-material/EditRounded';
import Check                  from '@mui/icons-material/Check';
import Undo                   from '@mui/icons-material/Undo';
import HealingIcon                   from '@mui/icons-material/Healing';
import Languages from '../../../../translations';
import { SessionManager } from '../../../../Auth';

const styles = {
  color: "#4377BA",
  fontSize: "14pt",
  fontWeight: "bold",
}

const AllergyInfo = ({ childId, childColor = "#4377BA"}) => {
  const { state: { children }, actions } = useStateValue();
  const { ChildList } = children;
  const [expanded, setExpanded] = useState(false);
  const [editing, setEditing] = useState(false);
  const [allergyValue, setAllergyValue] = useState("");

  useEffect(() => {
    if (!editing) {
      const currentChild = ChildList.find(c => c.Id == childId)
      setAllergyValue(currentChild.Info?.Allergy)
    }
  }, [ChildList])

  const toggleExpanded = () => {
    setExpanded(!expanded);
  }

  const onSave = () => {
      const childInfoData = {
        PersonID: childId,
        Admin: SessionManager.getUser(),
        SavedInfoType: "Allergy",
        Allergy: allergyValue.trim(),
      };

      actions.triggerSaveChildinfo(childInfoData);
      setEditing(false);
  }

  return (
    <Accordion className="painikkeet"
        style={{ border: "5px", borderLeft: "12px", borderStyle:"solid", borderColor: childColor, backgroundColor: "#ffffff", color: "#181818" }}
        expanded={expanded}
        onChange={toggleExpanded}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}
      aria-controls="panel2a-content"
      id="test_ChildAllergy_Btn" /*id="panel2a-header" */>
      <Typography className="heading" style={styles}>
        <HealingIcon />
        <b style={{marginLeft: "10px", display: "inline"}}>{Languages.ciAllergies}</b>
      </Typography>
    </AccordionSummary>
    <div style={{marginLeft: "0px", width:"100%"}}>
      <AccordionDetails>
        {editing &&
          <TextField name={"Allergy"}
          variant="standard"
            id={"test_ChildAllergy_Txt"}
            inputProps={{ attrid: null }}
            type="text"
            style={{width: "100%"}}
            placeholder={Languages.ciAllergies}
            value={allergyValue === null ? "" : allergyValue}
            onChange={(event) => { setAllergyValue(event.target.value) }}
            autoFocus /> 
        }
        {!editing && <Typography className="ml-3"> {allergyValue} </Typography>}
      </AccordionDetails>
    </div>
    <Divider />

    {!editing &&
      <AccordionActions className="painikkeet">
        <IconButton
          id="test_ChildAllergy_EditBtn"
          onClick={() =>setEditing(true)}
          size="large"><Edit /></IconButton>
      </AccordionActions>
    }
    { editing &&
      <AccordionActions className="painikkeet">
        <IconButton
          id="test_ChildAllergy_UndoBtn"
          onClick={() => setEditing(false)}
          size="large"><Undo /></IconButton>
        <IconButton id="test_ChildAllergy_SaveBtn" onClick={onSave} size="large"><Check /></IconButton>
      </AccordionActions>
    }
  </Accordion>
  );
}

export default AllergyInfo;
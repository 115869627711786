import React, { useState,  useCallback, useEffect } from "react";
import {useStateValue} from "../../../State";
import "../../Vasu/vasu.css";

import Languages      from "../../../translations";

import makeStyles from '@mui/styles/makeStyles';

import Backdrop         from '@mui/material/Backdrop';
import Box              from '@mui/material/Box'
import CircularProgress from "@mui/material/CircularProgress";
import LockIcon         from '@mui/icons-material/Lock';
import UnLockIcon       from '@mui/icons-material/LockOpen';

import _cloneDeep         from "lodash/cloneDeep"; // deep clones an array so that the copy is not just shallow
import { types } from "../../../Reducers/actionTypes";

const useStyles = makeStyles(theme => ({
  centeredtext: {
    //color: "black",
    textAlign:"center",
    marginTop:"20px",
  },
  selected: {
    backgroundColor: "#FBDCCE",
    borderColor: "#ACACAC",
    borderStyle: "solid",
    textAlign:"center",
    borderBottomWidth: "initial"
  },
  notselected: {
    backgroundColor: "#FFF8F5",
    textAlign:"center",
  },
  img: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "50%"
  },
  backdrop: {
    color: '#fff',
    backgroundColor: "#FBE4FF !important"
  },
}));

const LeopsLocking = (props) => {

  const classes = useStyles();
  const { state, actions } = useStateValue();
  
  const { } = props;

  const GeneralPart = state.leops.GeneralPart;

  const [showLocked,   setshowLocked]   = useState(GeneralPart.FormLocked);
  const [SavingData,   setSavingData]   = useState(false);

  useEffect( () => {
    //console.log("LeopsLocking. Leops state: ",state.leops.state, " SavingData: ", SavingData);
      
    switch(state.leops.state)
    {
      case types.LEOPS_LOAD_OK:
        //console.log("LeopsLocking. LEOPS_LOAD_OK");
        setSavingData(false);
        setshowLocked(GeneralPart.FormLocked);
        actions.triggerLeopsHandled();
        break;  
      case types.LEOPS_SAVED_OK:
        //console.log("LeopsLocking. LEOPS_SAVED_OK. GeneralPart.FormLocked: ",GeneralPart.FormLocked);
        actions.triggerAddToSnackBarQueue({color: "#000000", message: Languages.leopsLockingSavedOk});
        actions.triggerLoadLeops(GeneralPart.Id);
        break;
      case types.LEOPS_SAVED_FAILED:
        console.log("LeopsLocking. LEOPS_SAVED_FAILED" + "\n state.leops.state: ", state.leops, "\nFormLocked: ", GeneralPart.FormLocked);
        actions.triggerLeopsHandled();
        setSavingData(false);
        var codetext = state.leops.Code === undefined ? "\n" + Languages.errorUnknown : "\n" + Languages.errorCode + ": " + state.leops.Code;
        actions.triggerAddToSnackBarQueue({color: "#000000", message: Languages.leopsLockingSavedFailed + codetext});
        break;
      default:
        //console.log("LeopsLocking. State: ",state.leops.state);
        break;
      }
  }, [state]);
     
  const handleLocking = useCallback((status) => {
    //console.log("LeopsLocking. handleLocking. Lock editable: ", GeneralPart.FormLockEditable, " Locked: ",GeneralPart.FormLocked, " Selection: ", status);
    
    if (GeneralPart.FormLockEditable === false)
    {
      console.log("LeopsLocking. handleLocking. Lock not editable: ");
    }
    else
    {
      let LockingData = {LeopsId: state.leops.GeneralPart.Id,
                         Lock:    status === "LOCK" ? true : false};
      actions.triggerSaveLeopsLocking(LockingData);
      setSavingData(true);
    }
  });
   
  return (
    <div className={classes.centeredtext}>
      <h5>{Languages.LockLeops}</h5>
      <h6 style={{whiteSpace:"pre-line"}}>{Languages.LockLeopsInfo}</h6>

      {SavingData ?
        <div style={{opacity: "0.5"}}>
          <Backdrop open={true}> <CircularProgress/> </Backdrop>
        </div>
      :
      <div>

      {showLocked ? 
      <Box 
      
      bgcolor="#ffc4a9"
      className="boxed text-center p-5 mt-2">
        <div className="row" style={{marginBottom: "5px", justifyContent: "center"}} onClick={() => handleLocking("UNLOCK")}>
          <LockIcon id={"test_LeopsLocking_UnLock"}style={{color: "black"}} fontSize="large"></LockIcon>
          {GeneralPart.FormLockEditable === false ?
            <LockIcon style={{color: "#D500F9"}} fontSize="small"></LockIcon>
          : null}
        </div>
      </Box>
      :
      <Box
      bgcolor="#fbdcce"
      className="boxed text-center p-5 mt-2">
        <div className="row" style={{marginBottom: "5px", justifyContent: "center"}} onClick={() => handleLocking("LOCK")}>
          <UnLockIcon id={"test_LeopsLocking_Lock"}style={{color: "black"}} fontSize="large"></UnLockIcon>
        </div>
      </Box>
      }
    </div>
    }
    </div>
  );
}

export default LeopsLocking;
import React from "react";

const EmptyDivThatTakesUpSpace = ({height}) => {

  return(

    <div display="block">
      <div style={{minHeight: height}}></div>
    </div>

  );
}

export default EmptyDivThatTakesUpSpace;
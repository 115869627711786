import React               from  'react';
//import Languages            from  "../../translations";
import Help from "@mui/icons-material/HelpOutline";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import HelpTooltip from './HelpTooltip';


const HelpButton = ({ handleClick }) => {

    window.onkeydown = e => {
        switch (e.keyCode) {
            //F1
            case 112:
                handleClick();
                break;
            //Fallback to default browser behaviour
            default:
                return true;
        }
        //Returning false overrides default browser event
        return false;
    };

    return (
        <Tooltip title={<HelpTooltip />} arrow>
            <IconButton style={{marginTop: "-5px"}} onClick={() => handleClick()} size="large"><Help style={{color: "#ffffff"}} /></IconButton>
        </Tooltip>
    )
}

export default HelpButton
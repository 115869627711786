import React, { useEffect, useState } from "react";
import {useStateValue} from "../../../State/";
import ChildFilesSuccess from "./Components/ChildFilesSuccess.jsx";
import Languages from "../../../translations";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AttahcmentIcon         from '@mui/icons-material/Attachment';

const styles = {
  color: "#4377BA",
  fontSize: "13pt",
  fontWeight: "bold",
}

const ChildFiles = ({childId, childColor = "#4377BA"}) => {

  const {state, actions} = useStateValue();

  const {childFiles} = state;
  const {filesList} = childFiles;

  const [mount, setMount] = useState(true);
  const [childFilesPanelOpen, setChildFilesPanelOpen] = useState(false);

  useEffect(() => {

    if (mount && childFilesPanelOpen) {
      actions.triggerLoadChildFilesList(childId);
      setMount(false);
    }

  }, [mount, setMount, childFiles, actions, childFilesPanelOpen])

  return(
    <Accordion
      style={{ border: "5px", borderLeft: "12px", borderStyle:"solid", borderColor: childColor, backgroundColor: "#ffffff", color: "#181818", marginTop:"10px" }}
      expanded={childFilesPanelOpen}
      onChange={() => setChildFilesPanelOpen(!childFilesPanelOpen)}
      >
      
      <AccordionSummary 
          expandIcon={<ExpandMoreIcon />} 
          aria-controls="childFiles-panel-content"
          id="test_childFilesPanel"
          data-testid={"childFiles-extendable-button" + childFilesPanelOpen}>
            <Typography style={styles}><AttahcmentIcon /> <b style={{marginLeft: "10px", display: "inline"}}>{Languages.childFiles}</b></Typography>
      </AccordionSummary>

      <AccordionDetails style={{display: "block"}}>

      { filesList.status === "LOADING_CHILD_FILES" || filesList.status === "CHILD_FILES_NEVER_LOADED" ?
        
          <div data-testid="child_files_loading"><Typography>{Languages.waitingservertoloaddata}</Typography></div>
          : null
      }

      { filesList.status === "CHILD_FILES_LOAD_SUCCESS" ?

          <ChildFilesSuccess
            filesList={filesList.filesList}
          />
          : null
      }

      { filesList.status === "CHILD_FILES_LOAD_FAILED" ?
      
          <Typography>{Languages.errCouldNotLoadData + ": " + filesList.Code}</Typography>
          : null
      }

      </AccordionDetails>
      
    </Accordion>
  )
  
}

export default ChildFiles;